import gql from "graphql-tag";

export const INSIGHT_ADD_TAGS = gql`
  mutation insightAddTags($insightId: ID, $lang: LangEnum, $tagIdList: [ID!]) {
    admin {
      insight(insightId: $insightId, lang: $lang) {
        tags {
          add(tagIds: $tagIdList)
        }
      }
    }
  }
`;
