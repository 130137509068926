import gql from "graphql-tag";

export const GET_LOCATIONS_BY_IDS = gql`
  query getLocationsByIds(
    $limit: Int
    $skip: Int
    $docIds: [ID!]
    $ocIds: [ID!]
  ) {
    Location(
      ocIds: $ocIds
      skip: $skip
      limit: $limit
      docIds: $docIds
      sort: title
      sortDirection: asc
    ) {
      ocId
      title
      picCover
      addressBuilding
      addressCountry {
        tagId
        title
      }
      addressZone {
        tagId
        title
      }
      addressCity {
        tagId
        title
      }
    }
  }
`;
