import { defineMessages } from "react-intl";

export const translations = defineMessages({
  UploadImage: {
    id: "ActivityIntroComponent.UploadImage",
    defaultMessage: "Upload Image",
  },
  Theme: {
    id: "ActivityIntroComponent.Theme",
    defaultMessage: "Activity theme",
  },
  Title: {
    id: "ActivityIntroComponent.Title",
    defaultMessage: "Intro",
  },
  Description: {
    id: "ActivityIntroComponent.Description",
    defaultMessage: "Description",
  },
  CropImage: {
    id: "ActivityIntroComponent.CropImage",
    defaultMessage: "Crop Image",
  },
});
