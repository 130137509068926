import React from "react";
import PropTypes from "prop-types";
import { Icon, Icons } from "genius-ui";
import { DOCUMENT_VIEW_TYPES } from "../../../configs/constants";
import style from "./ViewSwitcher.style";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const ViewSwitcher = (props) => {
  const { changeViewType } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div>
      <Icon
        iconName={Icons.miniatureView}
        handleClick={() => changeViewType(DOCUMENT_VIEW_TYPES.GRID)}
        style={classes.gridViewButton}
      />
      <Icon
        iconName={Icons.listView}
        handleClick={() => changeViewType(DOCUMENT_VIEW_TYPES.LIST)}
        style={classes.listViewButton}
      />
    </div>
  );
};

ViewSwitcher.propTypes = {
  changeViewType: PropTypes.func,
  viewType: PropTypes.string,
};

export default ViewSwitcher;
