import gql from "graphql-tag";

export const DEGREE_SET_PRIVACY = gql`
  mutation degreeSetPrivacy($degreeId: ID, $lang: LangEnum) {
    admin {
      degree(degreeId: $degreeId, lang: $lang) {
        privacy {
          set
        }
      }
    }
  }
`;
