import { VIDEO_FORMAT } from "../../configs/constants";

export const SliderFullScreenTemplate = {
  template: "SliderFullScreenTemplate",
  data: {
    cards: [],
  },
};

export const IMAGE_FULL_SCREEN_NAME = "imageFullScreen";

export const CardTemplateImage = {
  type: IMAGE_FULL_SCREEN_NAME,
  image: {
    url: null,
    copyright: "Dior",
  },
  title: null,
  description: null,
};

export const IMAGE_FULL_SCREEN_MARKER_NAME = "imageFullScreenMarker";

export const CardTemplateMarkerImage = {
  type: IMAGE_FULL_SCREEN_MARKER_NAME,
  image: {
    url: null,
    copyright: "South Pigalle",
  },
  points: [],
};

export const CardTemplateVideo = {
  type: "videoFullScreen",
  formatType: VIDEO_FORMAT.LANDSCAPE,
  video: {
    url: "",
    copyright: "Dior",
    legend: null,
    on_pause_url: "",
    urlBackgroundFull: "",
  },
  title: null,
  description: null,
};
