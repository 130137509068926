export default {
  mainContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  DARK_GRAY_SLIGHT: {
    backgroundImage:
      "linear-gradient(to bottom, rgba(42, 42, 42, 0), rgba(42, 42, 42, 0.4))",
  },
};
