import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import Style from "../DragAndDropActivityQuestion.style.js";
import ContentEditableUncontrolled from "../../ContentEditableUncontrolled";
import DragAndDropActivityAnswerReadOnly from "../../DragAndDropActivityAnswer/dragAndDropAnswerReadOnly";
import { translations } from "../DragAndDropActivityQuestion.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const DragAndDropActivityQuestion = (props) => {
  const { text, zones } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.container}>
      <div className={classes.innerPageContainer}>
        <FormattedMessage {...translations.QuestionInputLabel}>
          {(message) => (
            <ContentEditableUncontrolled
              className={classNames([classes.questionInput, classes.textInput])}
              initialValue={text}
              placeholder={message[0]}
              disabled
            />
          )}
        </FormattedMessage>
        <div className={classes.zonesContainer}>
          {zones.map(({ zoneId, answer }, index) => (
            <div key={zoneId}>
              <FormattedMessage
                {...translations.ZoneTextInputLabel}
                values={{
                  zoneNumber: index + 1,
                }}
              >
                {(message) => (
                  <ContentEditableUncontrolled
                    className={classNames([
                      classes.zoneTextInput,
                      classes.textInput,
                    ])}
                    initialValue={answer}
                    placeholder={message[0]}
                    disabled
                  />
                )}
              </FormattedMessage>
              <div className={classes.zonePlaceholderBox} />
            </div>
          ))}
        </div>
      </div>
      {zones.map(({ zoneId, cards }, index) => (
        <DragAndDropActivityAnswerReadOnly
          key={zoneId}
          index={index + 1}
          cards={cards}
          className={classes.innerPageContainer}
        />
      ))}
    </div>
  );
};

DragAndDropActivityQuestion.propTypes = {
  question: PropTypes.string,
  zones: PropTypes.arrayOf(
    PropTypes.shape({
      zoneId: PropTypes.string.isRequired,
      answer: PropTypes.string,
      placeholder: PropTypes.string,
      cards: PropTypes.array.isRequired,
    }),
  ).isRequired,
};

export default DragAndDropActivityQuestion;
