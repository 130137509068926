import gql from "graphql-tag";

export const DEGREE_COPY_FOR_LANGUAGE = gql`
  mutation degreeCopy(
    $degreeId: ID
    $sourceLang: LangEnum
    $destLang: LangEnum!
  ) {
    admin {
      degree(degreeId: $degreeId, lang: $sourceLang) {
        copy(lang: $destLang) {
          degreeId
          versions {
            title
            lang
          }
        }
      }
    }
  }
`;
