import gql from "graphql-tag";

export const LOAD_SPECIFICITIES = gql`
  query LoadSpecificities($ocIds: [ID!]) {
    Location(ocIds: $ocIds) {
      ocId
      specOutdoorWindows {
        value
        tagId
        title
      }
      specOutdoorLightBox {
        value
        tagId
        title
      }
      specIndoorExotic {
        value
        tagId
        title
      }
      specIndoorWallWomen {
        value
        tagId
        title
      }
      specIndoorWallMen {
        value
        tagId
        title
      }
      specIndoorWallCrossMerch {
        value
        tagId
        title
      }
      specIndoorWallScarfs
      specIndoorWallEyewear
      specIndoorCustomJewellery
      specIndoorScarves {
        value
        tagId
        title
      }
      specIndoorBelts
      specIndoorLibrary
      specIndoorJewellery
      specIndoorVIP {
        value
        tagId
        title
      }
      specIndoorWallJewellery {
        value
        tagId
        title
      }
      specIndoorWallBaby {
        value
        tagId
        title
      }
      specAnimPopin {
        value
        tagId
        title
      }
      specAnimInside {
        value
        tagId
        title
      }
      specAnimCoutureService
      specAnimAbcdior {
        value
        tagId
        title
      }
      specAnimVideoWomen {
        value
        tagId
        title
      }
      specAnimVideoJewellery {
        value
        tagId
        title
      }
      specAnimVideoMen {
        value
        tagId
        title
      }
      specAnimVideoBaby {
        value
        tagId
        title
      }
      specAnimPopinPlus {
        value
        tagId
        title
      }
    }
  }
`;

export const UPDATE_LOCATION_FIELDS = (field) => gql`
mutation setSpecificitiesField ($data: LocationFieldsEventsDescriptions!, $ocId: ID) {
  Location (ocId: $ocId) {
    setFields (data: $data) {
      ocId
      ${field}
    }
  }
}`;
