import { defineMessages } from "react-intl";

export const translations = defineMessages({
  PublicationSettings: {
    id: "PublishInsight.PublicationSettings",
    defaultMessage: "Publication settings",
  },
  LanguageLabel: {
    id: "PublishInsight.LanguageLabel",
    defaultMessage: "Language",
  },
  BrandLabel: {
    id: "PublishInsight.BrandLabel",
    defaultMessage: "Brand",
  },
  EntityLabel: {
    id: "PublishInsight.EntityLabel",
    defaultMessage: "Entity",
  },
  RetailerLabel: {
    id: "PublishInsight.RetailerLabel",
    defaultMessage: "Retailer",
  },
  DivisionLabel: {
    id: "PublishInsight.DivisionLabel",
    defaultMessage: "Division",
  },
  PublicationCountriesLabel: {
    id: "PublishInsight.PublicationCountriesLabel",
    defaultMessage: "Countries",
  },
  PublicationZonesLabel: {
    id: "PublishInsight.PublicationZonesLabel",
    defaultMessage: "Zones",
  },
  ProfessionsLabel: {
    id: "PublishInsight.ProfessionsLabel",
    defaultMessage: "Profession",
  },
  AssociatedTagsLabel: {
    id: "PublishInsight.AssociatedTagsLabel",
    defaultMessage: "Associated Tags",
  },
  UserCategoryLabel: {
    id: "PublishInsight.UserCategoryLabel",
    defaultMessage: "User Category",
  },
  PublicButtonLabel: {
    id: "PublishInsight.PublicButtonLabel",
    defaultMessage: "Public",
  },
  ScheduleButtonText: {
    id: "PublishInsight.ScheduleButtonText",
    defaultMessage: "Schedule to publish",
  },
  CancelScheduleButtonText: {
    id: "PublishInsight.CancelScheduleButtonText",
    defaultMessage: "Cancel scheduling",
  },
  SaveWithoutScheduleButtonText: {
    id: "PublishInsight.SaveWithoutScheduleButtonText",
    defaultMessage: "Save without scheduling",
  },
  AddBrandPlaceholder: {
    id: "PublishInsight.AddBrandPlaceholder",
    defaultMessage: "Add a brand",
  },
  AddEntityPlaceholder: {
    id: "PublishInsight.AddEntityPlaceholder",
    defaultMessage: "Add an entity type",
  },
  AddRetailerPlaceholder: {
    id: "PublishInsight.AddRetailerPlaceholder",
    defaultMessage: "Add a retailer",
  },
  AddDivisionPlaceholder: {
    id: "PublishInsight.AddDivisionPlaceholder",
    defaultMessage: "Add a division",
  },
  SelectAssociatedTagsPlaceholder: {
    id: "PublishInsight.SelectAssociatedTagsPlaceholder",
    defaultMessage: "Select associated tags",
  },
  SelectUserCategoriesPlaceholder: {
    id: "PublishInsight.SelectUserCategoriesPlaceholder",
    defaultMessage: "Select user categories",
  },
  SelectCountriesPlaceholder: {
    id: "PublishInsight.SelectCountriesPlaceholder",
    defaultMessage: "Select countries",
  },
  SelectZonesPlaceholder: {
    id: "PublishInsight.SelectZonesPlaceholder",
    defaultMessage: "Select zones",
  },
  SelectProfessionsPlaceholder: {
    id: "PublishInsight.SelectProfessionsPlaceholder",
    defaultMessage: "Select professions",
  },
  SuccessfulPublishedMessage: {
    id: "PublishInsight.SuccessfulPublishedMessage",
    defaultMessage: "Your insight has been published",
  },
  TitlePlaceholder: {
    id: "PublishInsight.TitlePlaceholder",
    defaultMessage: "Enter title",
  },
  SummaryPlaceholder: {
    id: "PublishInsight.SummaryPlaceholder",
    defaultMessage: "Enter summary",
  },
  IncorrectDateMessage: {
    id: "PublishInsight.IncorrectDateMessage",
    defaultMessage: "End posting date can't be before the start posting date",
  },
  StartPostingDateLabel: {
    id: "PublishInsight.StartPostingDateLabel",
    defaultMessage: "Start posting date",
  },
  EndPostingDateLabel: {
    id: "PublishInsight.EndPostingDateLabel",
    defaultMessage: "End posting date",
  },
  NoEndPostingDateLabel: {
    id: "PublishInsight.NoEndPostingDateLabel",
    defaultMessage: "No end posting date",
  },
  RoleLabel: {
    id: "PublishInsight.RoleLabel",
    defaultMessage: "Roles",
  },
  AddRolesPlaceholder: {
    id: "PublishInsight.AddRolesPlaceholder",
    defaultMessage: "Select roles",
  },
  IndicateBrandsWarning: {
    id: "PublishInsight.IndicateBrandsWarning",
    defaultMessage:
      "Please indicate which brand or brands this content should be associated with before publishing.",
  },
});
