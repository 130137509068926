import gql from "graphql-tag";

export const INSIGHT_GET_TAG_IDS = gql`
  query insightGetTagIds($insightIds: [ID!]) {
    insights(insightIds: $insightIds) {
      insightId
      tags(isGranted: true) {
        tagId
      }
    }
  }
`;
