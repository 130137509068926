import WhiteAccordion from "components/common/WhiteAccordion/WhiteAccordion";
import useThemedStyle from "hooks/style/useThemedStyle";
import { GET_ACCESSIBLE_LESSONS } from "pages/badges/graphs";
import React, { useCallback, useMemo } from "react";
import { useQuery } from "react-apollo";
import { createUseStyles } from "react-jss";
import style from "./Lessons.style";

const useStyle = createUseStyles(style);

const Lang = ({ lang, ...props }) => {
  const classes = useThemedStyle(useStyle, props);
  return <div className={classes.langContainer}>{lang}</div>;
};

const Lesson = ({
  id,
  title,
  langs,
  activity,
  read,
  onSelectLesson,
  ...props
}) => {
  const classes = useThemedStyle(useStyle, props);
  const handleReadSelected = useCallback(() => {
    onSelectLesson(id, read ? undefined : 2);
  }, [id, onSelectLesson, read]);
  const handleActivitySelected = useCallback(() => {
    onSelectLesson(id, activity ? undefined : 4);
  }, [id, onSelectLesson, activity]);

  return (
    <div className={classes.lessonContainer}>
      <div>
        <div>{title}</div>
        <div style={{ display: "flex" }}>
          {langs.map((lang) => (
            <Lang key={lang} lang={lang} />
          ))}
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
            position: "relative",
            width: 60,
            height: 20,
            borderRadius: 10,
            cursor: "pointer",
            backgroundColor: read ? "blue" : "grey",
            color: "white",
          }}
          onClick={handleReadSelected}
        >
          read
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
            position: "relative",
            width: 60,
            height: 20,
            borderRadius: 10,
            cursor: "pointer",
            backgroundColor: activity ? "blue" : "grey",
            color: "white",
          }}
          onClick={handleActivitySelected}
        >
          activity
        </div>
      </div>
    </div>
  );
};

const LessonsByPrograms = ({ title, langs, lessons, selectedLessons }) => {
  const selectedLessonsNb = useMemo(() => {
    return lessons.filter(({ id }) => selectedLessons[id]).length;
  }, [lessons, selectedLessons]);
  return (
    <div>
      <div
        style={{ width: 700, display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ width: 500 }}>{title}</div>
        <div style={{ width: 100 }}>{langs.join()}</div>
        <div style={{ width: 100 }}>{selectedLessonsNb}</div>
      </div>
    </div>
  );
};

const DisplayLessons = ({ lessons, selectedLessons, onSelectLesson }) => {
  return (
    <div style={{ backgroundColor: "#fbfbfb" }}>
      {lessons.map((lesson) => (
        <Lesson
          key={lesson.id}
          {...lesson}
          read={selectedLessons[lesson.id] === 2}
          activity={selectedLessons[lesson.id] === 4}
          onSelectLesson={onSelectLesson}
        />
      ))}
    </div>
  );
};

const Lessons = ({
  brandId,
  marketId,
  selectedLessons,
  onSelectLesson,
  ...props
}) => {
  const classes = useThemedStyle(useStyle, props);
  const { data, loading } = useQuery(GET_ACCESSIBLE_LESSONS, {
    variables: { marketId, brandId },
  });

  const currentLanguage = "en";
  const lessonsByProgram = useMemo(() => {
    if (!data) return null;
    return data.admin.degrees[0].programs
      .map(({ programId, versions, lessons }) => {
        let programVersion = versions.find(
          ({ lang }) => lang === currentLanguage,
        );
        if (!programVersion) programVersion = versions[0];
        const programLanguages = versions.map(({ lang }) => lang);
        const lessonsVersion = lessons.reduce((acc, current) => {
          let lesson = current.versions.find(
            ({ lang }) => lang === currentLanguage,
          );
          const lessonLanguages = current.versions
            .filter(({ workflow }) => workflow === "PUBLISHED")
            .map(({ lang }) => lang);
          if (!lessonLanguages.length) return acc;
          if (!lesson) lesson = current.versions[0];
          acc.push({
            id: current.lessonId,
            langs: lessonLanguages,
            ...lesson,
          });
          return acc;
        }, []);
        if (!lessonsVersion.length) return null;
        return {
          id: programId,
          ...programVersion,
          langs: programLanguages,
          lessons: lessonsVersion,
        };
      })
      .filter((_) => _);
  }, [data]);

  if (loading) return null;
  return (
    <div>
      <div className={classes.container}>
        <div className={classes.tableHeader}>
          <div style={{ width: 500 }}>Programs</div>
          <div style={{ width: 100 }}>Languages</div>
          <div style={{ width: 100 }}>Score</div>
        </div>
        <WhiteAccordion
          items={lessonsByProgram}
          renderHeading={(program) => (
            <LessonsByPrograms
              key={program.programId}
              {...program}
              selectedLessons={selectedLessons}
            />
          )}
          renderContent={({ lessons, programId }) => (
            <DisplayLessons
              key={programId}
              lessons={lessons}
              selectedLessons={selectedLessons}
              onSelectLesson={onSelectLesson}
            />
          )}
        />
      </div>
    </div>
  );
};

export default Lessons;
