export default (theme) => ({
  content: {
    fontFamily: theme.FontFamily,
    fontSize: 18,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: 0.06,
    color: theme.DeleteModal.contentColor,
  },
  modal: {
    width: "40%",
  },
  buttonContainer: {
    "& > button": {
      marginRight: 20,
      marginTop: 10,
    },
  },
});
