import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import Style from "./Library.style";
import useThemedStyle from "../../hooks/style/useThemedStyle";
import Navigation from "components/Navigation";
import { Icon, Icons, Dropdown } from "genius-ui";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { GetMarketFilterTags } from "store/degree/actions";
import { DOCUMENT_LEVEL } from "configs/constants";
import {
  SetCurrentDocumentLevel,
  SetCurrentLanguage,
  SetCurrentMarket,
} from "store/document/actions";
import { translations } from "./Library.translations";
import FixedContainer from "components/common/FixedContainer";
import Spinner from "components/common/Spinner";
import RoundButton from "components/common/RoundButton";
import { SetWarningMessage } from "store/notification/actions";
import { getPath } from "core/paths";
import { GetPdfs, ResetPdf } from "store/pdf/actions";
import SendNotificationModal from "components/common/SendNotificationModal";
import {
  SetNotificationData,
  SetShowModal,
} from "store/sendNotification/actions";
import { useHistory } from "react-router-dom";
import FilterLanguages, {
  getCurrentLanguage,
} from "components/FilterLanguages";
import FilterHeaderContainer from "components/FilterHeaderContainer/FilterHeaderContainer";
import { useLazyQuery, useQuery } from "react-apollo";
import { GET_TAGS } from "pages/badges/graphs";
import gql from "graphql-tag";
import { LANGUAGES } from "configs/referential";
import File from "./partials/File/File";

const useStyle = createUseStyles(Style);

const GET_CONFIG = gql`
  query {
    appConfig {
      config
      type
      value
    }
  }
`;

export const useGetFileConfig = () => {
  const configs = useQuery(GET_CONFIG);
  const fileConfig = useMemo(() => {
    if (!configs.data) return undefined;
    return JSON.parse(
      configs.data.appConfig.find(({ config }) => config === "fileLibrary")
        .value,
    ).files;
  }, [configs.data]);

  const tagIds = useMemo(() => {
    if (!fileConfig) return [];
    return Object.values(fileConfig).reduce((acc, categories) => {
      categories.forEach((category) => {
        if (category.id) acc.push(category.id);
        category.subCategories.forEach(({ id }) => {
          acc.push(id);
        });
      });
      return acc;
    }, []);
  }, [fileConfig]);
  const [loadTags, tags] = useLazyQuery(GET_TAGS, { variables: { tagIds } });

  const categoriesByCountryId = useMemo(() => {
    if (!tags.data) return undefined;
    return Object.entries(fileConfig).reduce((acc, [key, categories]) => {
      const tagsById = tags.data.tags.reduce((acc, { tagId, title }) => {
        acc[tagId] = title;
        return acc;
      }, {});
      acc[key] = categories.flatMap((category) => {
        if (!category.id) {
          return category.subCategories.map((subCategory) => {
            return {
              ...subCategory,
              value: subCategory.id,
              label: tagsById[subCategory.id],
            };
          });
        }
        return {
          ...category,
          value: category.id,
          label: tagsById[category.id],
          fileTypes: category.subCategories.flatMap(
            ({ fileTypes }) => fileTypes,
          ),
          subCategories: category.subCategories.map((subCategory) => {
            return {
              ...subCategory,
              value: subCategory.id,
              label: tagsById[subCategory.id],
            };
          }),
        };
      });
      return acc;
    }, {});
  }, [tags, fileConfig]);
  useEffect(() => {
    if (!fileConfig) return;
    if (!tags.called && tagIds.length) loadTags();
  }, [fileConfig, loadTags, tags.called, tagIds.length]);
  return {
    categoriesByCountryId,
  };
};

const usePdfInformations = () => {
  const profile = useSelector((state) => state.auth.profileInfo);
  const files = useSelector((state) => state.pdf.pdfs);
  const selectedMarket = useSelector((state) => state.documents.selectedMarket);
  const selectedLanguage = useSelector(
    (state) => state.documents.currentLanguage,
  );
  const markets = useSelector((state) => state.degrees.markets);
  const isCreating = useSelector((state) => state.common.saveIndicator);
  const isTrainer = useMemo(
    () => profile.roles.some((role) => role === "trainer"),
    [profile],
  );
  const userMarketTagId = useMemo(
    () => profile.tags.find(({ clusterId }) => clusterId === "2")?.tagId,
    [profile],
  );
  const { categoriesByCountryId } = useGetFileConfig();

  const categories =
    categoriesByCountryId?.[selectedMarket?.value] ||
    categoriesByCountryId?.default;

  return {
    roles: profile.roles,
    files,
    selectedMarket,
    markets,
    isCreating,
    isTrainer,
    userMarketTagId,
    selectedLanguage,
    categories,
  };
};

const Library = () => {
  const {
    roles,
    isTrainer,
    userMarketTagId,
    markets,
    files,
    selectedMarket,
    isCreating,
    selectedLanguage,
    categories,
  } = usePdfInformations();
  const dispatch = useDispatch();

  const { formatMessage } = useIntl();
  const history = useHistory();
  const classes = useThemedStyle(useStyle, {});

  useEffect(() => {
    dispatch(ResetPdf());
    dispatch(GetMarketFilterTags());
  }, [dispatch]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  useEffect(() => {
    if (!roles.length || (isTrainer && !userMarketTagId)) return;
    dispatch(ResetPdf());
    let selectedTagId = selectedMarket?.value;

    if (isTrainer) {
      dispatch(
        SetCurrentMarket(
          markets.find((market) => userMarketTagId === market.value),
        ),
      );
      selectedTagId = userMarketTagId;
    }
    const tagIds = [];

    if (selectedTagId) tagIds.push({ tagIds: selectedTagId });
    if (selectedCategory?.value)
      tagIds.push({ tagIds: selectedCategory?.value });
    if (selectedSubCategory?.value)
      tagIds.push({ tagIds: selectedSubCategory?.value });
    dispatch(GetPdfs(tagIds, selectedLanguage));
  }, [
    roles.length,
    isTrainer,
    userMarketTagId,
    selectedCategory?.value,
    selectedSubCategory?.value,
    selectedLanguage,
    selectedMarket?.value,
  ]);

  const handleSelectMarket = useCallback(
    (selectedOption) => {
      dispatch(SetCurrentMarket(selectedOption));
    },
    [dispatch],
  );

  const setSelectedLanguage = useCallback(
    (language) => {
      dispatch(SetCurrentLanguage(language?.value));
    },
    [dispatch],
  );

  const handleClickCreateNew = useCallback(() => {
    if (!selectedMarket?.value) {
      dispatch(
        SetWarningMessage(formatMessage(translations.MarketMissingWarning)),
      );
      return;
    }
    dispatch(SetCurrentDocumentLevel(DOCUMENT_LEVEL.NORMAL));
    history.push(
      `${getPath("createFile")}?marketId=${
        selectedMarket?.value
      }&lang=${selectedLanguage}`,
    );
  }, [selectedMarket?.value]);

  const showNotificationModal = useCallback(
    (item) => {
      dispatch(
        SetNotificationData({
          docId: item.docId,
          lang: item.lang,
          docType: "file",
        }),
      );
      dispatch(SetShowModal(item));
    },
    [dispatch],
  );

  const marketsList = useMemo(
    () => [
      {
        value: undefined,
        label: formatMessage(translations.AllMarketOptions),
      },
      ...markets,
    ],
    [markets],
  );

  const [activeMenuDocumentId, setActiveMenuDocumentId] = useState(undefined);

  const langOpt = useMemo(
    () =>
      LANGUAGES.reduce((acc, { Code, Name }) => {
        if (Code === selectedLanguage) return { value: Code, label: Name };
        return acc;
      }, null),
    [selectedLanguage],
  );

  return (
    <div className={classes.rootContainer}>
      <Navigation />
      <FixedContainer className={classes.createButtonContainer}>
        {isCreating ? (
          <Spinner size={30} />
        ) : (
          <RoundButton onClick={handleClickCreateNew}>
            <Icon iconName={Icons.plus} />
          </RoundButton>
        )}
      </FixedContainer>
      <FilterHeaderContainer>
        <div style={{ display: "flex", width: "100%" }}>
          <div
            className={classes.marketSelector}
            style={{
              border: "1px solid rgb(230, 230, 230)",
              borderRadius: 5,
              height: "38px !important",
              "& > div > div > div": {
                minHeight: "38px !important",
              },
            }}
          >
            <Dropdown
              selectedOption={selectedMarket}
              onChange={(selectedOption) => {
                setSelectedLanguage(
                  getCurrentLanguage(selectedOption.value, selectedLanguage),
                );
                handleSelectMarket(selectedOption);
              }}
              options={marketsList}
              placeholder={formatMessage(translations.AllMarketOptions)}
              disabled={!marketsList.length || isTrainer}
            />
          </div>
          <div
            className={classes.languageSelector}
            style={{
              width: 180,
              border: "1px solid rgb(230, 230, 230)",
              borderRadius: 5,
              margin: "0 10px",
            }}
          >
            <FilterLanguages
              selectedMarket={selectedMarket}
              currentLanguage={selectedLanguage}
              setCurrentLanguage={setSelectedLanguage}
              convertToValueLabel
            >
              {(languages) => (
                <Dropdown
                  selectedOption={langOpt}
                  onChange={(selectedOption) => {
                    setSelectedLanguage(selectedOption);
                  }}
                  options={languages.map((language) => ({
                    value: language.Code,
                    label: language.Name,
                  }))}
                  disabled={languages.length === 1}
                />
              )}
            </FilterLanguages>
          </div>
          <div style={{ width: 180, marginRight: 10 }}>
            <Dropdown
              selectedOption={selectedCategory}
              onChange={(category) => {
                setSelectedCategory(category);
                setSelectedSubCategory(
                  (category.subCategories?.length || 0) === 1
                    ? category.subCategories[0]
                    : null,
                );
              }}
              options={categories}
              label="Categories"
            />
          </div>
          <div style={{ width: 180 }}>
            <Dropdown
              selectedOption={selectedSubCategory}
              onChange={setSelectedSubCategory}
              options={selectedCategory?.subCategories}
              placeholder="Subcategories"
              disabled={(selectedCategory?.subCategories?.length || 0) < 2}
            />
          </div>
        </div>
      </FilterHeaderContainer>
      <div className={classes.pdfCards}>
        {files.map((file) => (
          <File
            key={file.docId}
            file={file}
            setActiveMenuDocumentId={setActiveMenuDocumentId}
            activeMenuDocumentId={activeMenuDocumentId}
            showNotificationModal={showNotificationModal}
          />
        ))}
      </div>
      <SendNotificationModal
        title={formatMessage(translations.NotificationModalTitle)}
        description={formatMessage(translations.NotificationModalDescription)}
        placeholder={formatMessage(
          translations.NotificationModalMessagePlaceholder,
        )}
      />
    </div>
  );
};

export default Library;
