import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Style from "../EditQuickQuizPage.style";
import QuickQuizPageEditorReadOnly from "../../../components/QuickQuizPageEditor/readOnly";
import { Notification } from "genius-ui";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import { FormattedMessage } from "react-intl";
import { translations } from "../EditQuickQuiz.translations";
import SideBarNavigation from "../../../components/SideBarNavigation";

const useStyle = createUseStyles(Style);

const EditQuickQuizPage = (props) => {
  const {
    NavigateToPreviousPage,
    pageId,
    answers,
    endMessage,
    image,
    question,
  } = props;
  const classes = useThemedStyle(useStyle, props);
  const [isNotificationOpen, setNotificationStatus] = useState(true);

  return (
    <>
      <Notification
        message={<FormattedMessage {...translations.ReadOnlyMessage} />}
        status="warning"
        isOpen={isNotificationOpen}
        handleClose={setNotificationStatus}
      />
      <SideBarNavigation handleBackClick={NavigateToPreviousPage} />
      {pageId && (
        <div className={classes.container}>
          <QuickQuizPageEditorReadOnly
            pageId={pageId}
            answers={answers}
            endMessage={endMessage}
            question={question}
            image={image}
          />
        </div>
      )}
    </>
  );
};

export default EditQuickQuizPage;
