import React from "react";
import Style from "./NinjaWidget.style";
import { FormattedMessage } from "react-intl";
import uuidV4 from "uuid/dist/v4";
import { translations } from "./NinjaWidget.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import NinjaRow from "./NinjaRow";

const useStyle = createUseStyles(Style);

const NinjaWidget = (props) => {
  const { ninjas, exportNinja } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <FormattedMessage {...translations.PdfLabel} />
        <FormattedMessage {...translations.NumberOfParticipantsLabel} />
      </div>
      {ninjas.map((ninja) => (
        <NinjaRow
          key={uuidV4()}
          ninjaId={ninja.ninjaId}
          brand={ninja.brand}
          title={ninja.title}
          nbParticipants={ninja.nbParticipants}
          exportNinja={exportNinja}
        />
      ))}
    </div>
  );
};

export default NinjaWidget;
