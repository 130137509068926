import gql from "graphql-tag";

export const GET_DEGREE_SHORT_DETAILS = gql`
  query getDegreeShortDetails($degreeId: ID) {
    admin {
      degrees(degreeId: $degreeId) {
        versions {
          degreeId
          lang
          title
          tags {
            tagId
            title
            clusterId
          }
        }
      }
    }
  }
`;
