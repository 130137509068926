import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";
import TextInputStyle from "./MultiLineTextInput.style";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(TextInputStyle);

const MultiLineTextInput = (props) => {
  const {
    label,
    className,
    containerClassName,
    errors,
    LengthComponent,
    ...restProps
  } = props;
  const classes = useThemedStyle(useStyle, props);
  const containerClass = classnames([classes.container, containerClassName]);

  const error = errors && errors.length > 0;

  const MultiLineTextInputClassName = classnames([
    classes.input,
    className,
    {
      invalid: error,
    },
  ]);

  return (
    <div className={containerClass}>
      <textarea
        className={MultiLineTextInputClassName}
        placeholder={label}
        {...restProps}
      />
      {LengthComponent && <LengthComponent />}
    </div>
  );
};

MultiLineTextInput.propTypes = {
  /**
   * Label acting as a placeholder when no text is entered into the input, then appearing above the input when the
   * input has value entered.
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Class added to the container. if className is provided, it is added to the input itself.
   */
  containerClassName: PropTypes.any,
  /**
   * If error messages are provided, the input will display them and and will be styled accordingly
   */
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  ),
  /**
   * If true, the placeholder will move above the input when @prop value is not falsy
   */
  /**
   * + All the props accepted by ReactDOM.placeholder
   */
};

export default MultiLineTextInput;
