import { useSelector } from "react-redux";
import { ENV } from "../../configs/constants";
import { useMemo } from "react";
import useAcessiblePaths from "./useAccessiblePaths";

/**
 * Filters out paths that are inaccessible by user based on user role and returns a function that can be used to
 * imperatively filter paths based on the paths accessible by the user.
 * @param paths {[]}: Paths subject to filtering
 * @param autoFilter {boolean}: If true, the input paths are filtered and returned through the filteredPaths attribute.
 *  If false, the paths aren't automatically filtered and only the returned FilterPaths utility function can be used to
 *  filter some paths
 * @returns {{
 *   filteredPaths: {[]},
 *   FilterPaths: {func(paths: [])}
 * }}:
 *     * filteredPaths: Filtered paths.
 *     * FilterPaths: function that can be used to imperatively filter paths.
 */
const useFilterPaths = (paths, autoFilter = true) => {
  const { roles } = useSelector((state) => state.auth.profileInfo);
  const canPathsBeFiltered = autoFilter && !!roles.length;
  const accessiblePaths = useAcessiblePaths();
  const FilterPaths = (paths) =>
    canPathsBeFiltered
      ? paths.filter((path) => {
          const isPathAccessible = accessiblePaths.includes(path.path);
          if (!isPathAccessible && ENV.IS_DEV) {
            console.info(
              `useFilterPaths: Path ${path.path} was deleted from the accessible paths.`,
            );
          }

          return isPathAccessible;
        })
      : paths;
  const filteredPaths = useMemo(
    () => FilterPaths(paths),
    [accessiblePaths, paths],
  );

  if (!canPathsBeFiltered) {
    if (ENV.IS_DEV) {
      console.warn(
        "useFilterPaths: No roles associated with the user's profile",
      );
    }

    return {
      filteredPaths: paths,
      FilterPaths,
    };
  }

  return {
    filteredPaths,
    FilterPaths,
  };
};

export default useFilterPaths;
