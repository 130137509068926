import gql from "graphql-tag";

export const CARD_UPDATE_PAGE_DATA = gql`
  mutation cardUpdatePageData(
    $cardId: ID
    $lang: LangEnum
    $data: String!
    $pageId: ID
  ) {
    admin {
      card(cardId: $cardId, lang: $lang) {
        pages(pageId: $pageId) {
          data(data: $data)
        }
      }
    }
  }
`;
