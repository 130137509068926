import React from "react";
import PropTypes from "prop-types";
import DefaultUserProfilePage from "./defaultUserProfilePage";
import { connect } from "react-redux";
import { GetUserType } from "../../store/userProfile/actions";

const UserProfile = ({
  match: {
    params: { id },
  },
  history,
  userType,
  GetUserType,
}) => {
  return <DefaultUserProfilePage userId={id} history={history} />;
};

UserProfile.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  userType: state.userProfile.userType,
});

const mapDispatchToProps = (dispatch) => ({
  GetUserType: (userId) => {
    dispatch(GetUserType(userId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
