import { defineMessages } from "react-intl";

export const DocumentTypeTranslations = defineMessages({
  lesson: {
    id: "DocumentType.lesson",
    defaultMessage: "Lesson",
  },
  program: {
    id: "DocumentType.program",
    defaultMessage: "Program",
  },
  insight: {
    id: "DocumentType.insight",
    defaultMessage: "Insight",
  },
  card: {
    id: "DocumentType.card",
    defaultMessage: "Card",
  },
  battle: {
    id: "DocumentType.battle",
    defaultMessage: "Challenge",
  },
  poll: {
    id: "DocumentType.poll",
    defaultMessage: "Poll",
  },
  ninja: {
    id: "DocumentType.ninja",
    defaultMessage: "Ninja",
  },
  feedQuizz: {
    id: "DocumentType.feedQuizz",
    defaultMessage: "Feed Quiz",
  },
  degree: {
    id: "DocumentType.degree",
    defaultMessage: "Degree",
  },
});
