import clientConfig, { isShiseido } from "../../configs/client";
import { USER_STATUS } from "../../constants/domain_items/users";
import { CLUSTER_DEEPNESS_IDS, STATUS_ENUM } from "../../configs/constants";
import {
  GetTagByDeepness,
  GetTagNameByClusterId,
  GetTagsByDeepness,
} from "../../utils/dataTransformation/tags";

import { DefaultUserImage } from "assets/icons";
import { LANGUAGES } from "../../configs/referential";
import { formatDate as formatDateUsersProfiles } from "../../utils/dateUtils";
/**
 * Calculates the total number of users by summing the number of users of each status.
 * @param users
 * @returns {number}
 */
export const countTheTotalNumberOfUsers = (users) =>
  users
    .filter(
      (x) =>
        x.status !== USER_STATUS.ARCHIVED && x.status !== USER_STATUS.REJECTED,
    )
    .reduce(
      (totalNrOfUsers, userByStatus) => totalNrOfUsers + userByStatus.nb,
      0,
    );

export const getUsersByStatuses = (users) => {
  const active = users.find((user) => user.status === STATUS_ENUM.ACTIVE);
  const deleted = users.find((user) => user.status === STATUS_ENUM.ARCHIVED);
  const inactive = users.find((user) => user.status === STATUS_ENUM.DRAFT);
  return {
    [STATUS_ENUM.ACTIVE]: active ? active.nb : 0,
    [STATUS_ENUM.ARCHIVED]: deleted ? deleted.nb : 0,
    [STATUS_ENUM.DRAFT]: inactive ? inactive.nb : 0,
  };
};

/**
 * Extracts specified attribute from the graphql User document and converts it to the form used by the users table.
 * @param user {UserDocument}
 * @param attributeName {string}
 */
const extractConvertedUserAttribute = (user, columnConfiguration) => {
  try {
    const findProfileCustomAttribute = (user, columnConfiguration) =>
      user.profileCustom.find(
        ({ name }) => name === columnConfiguration.profileCustomDataAttribute,
      );

    const getTagsFromProfileCustom = (user, columnConfiguration) => {
      const profileCustomAttribute = findProfileCustomAttribute(
        user,
        columnConfiguration,
      );
      return (
        (profileCustomAttribute &&
          profileCustomAttribute.tags.map(({ title }) => title).join(", ")) ||
        ""
      );
    };

    const getTagsFromProfileCustomFilterByClusterIds =
      (clusterIds) => (user, columnConfiguration) => {
        const profileCustomAttribute = findProfileCustomAttribute(
          user,
          columnConfiguration,
        );
        return (
          (profileCustomAttribute &&
            profileCustomAttribute.tags
              .filter(({ clusterId }) =>
                clusterIds.includes(parseInt(clusterId)),
              )
              .map(({ title }) => title)
              .join(", ")) ||
          ""
        );
      };

    const getTagsByClusterIds = (clusterIds) => (user) =>
      user.tags
        .filter(({ clusterId }) => clusterIds.includes(parseInt(clusterId)))
        .map(({ title }) => title)
        .join(", ") || "";

    const getTagsByClusterDeepness = (clusterDeepnessFromConfig) => (user) =>
      user.tags
        .filter(
          ({ clusterDeepness }) =>
            clusterDeepness === clusterDeepnessFromConfig,
        )
        .map(({ title }) => title)
        .join(", ") || "";

    const getDescriptionFromProfileCustom = (user, columnConfiguration) => {
      const profileCustomAttribute = findProfileCustomAttribute(
        user,
        columnConfiguration,
      );
      return (
        (profileCustomAttribute && profileCustomAttribute.description) || ""
      );
    };

    const getBadgesFromProfileCustom = (user, columnConfiguration) => {
      const profileCustomAttribute = findProfileCustomAttribute(
        user,
        columnConfiguration,
      );
      return profileCustomAttribute && profileCustomAttribute.badges
        ? profileCustomAttribute.badges
        : [];
    };

    const getTags = (user) => user.tags.map(({ title }) => title).join(", ");

    const getBadgeNewScore = (user) =>
      user.profileCustom.reduce((total, pc) => {
        total += pc.badges.reduce((total, badge) => {
          total += badge.score || 0;
          return total;
        }, 0);
        return total;
      }, 0);

    const extractors = {
      email: (user) => user.profile.mail,
      firstName: (user) => user.profile.firstName,
      lastName: (user) => user.profile.lastName,
      name: (user) => `${user.profile.firstName} ${user.profile.lastName}`,
      relationship: getTagsFromProfileCustom,
      market: isShiseido
        ? getTagsByClusterIds(clientConfig.USER_MARKET_CLUSTER_IDS)
        : getTagsFromProfileCustom,
      retailer: getTagsFromProfileCustom,
      city: isShiseido
        ? getDescriptionFromProfileCustom
        : (user) => GetTagByDeepness(user.tags, CLUSTER_DEEPNESS_IDS.CITY),
      last_connection_date: (user) => formatDate(user.lastLogin),
      badges: getBadgesFromProfileCustom,
      old_score: getDescriptionFromProfileCustom,
      new_score: () => user.lessonPts,
      tags: getTags,
      interests: getTagsByClusterIds(clientConfig.INTEREST_TAGS_CLUSTER_IDS),
      brands: getTagsByClusterIds(clientConfig.BRAND_TAGS_CLUSTER_IDS),
      point_of_sales: getDescriptionFromProfileCustom,
      point_of_sales_address: (user) => user.profile.posAddress,
      home_building: getDescriptionFromProfileCustom,
      home_street: getDescriptionFromProfileCustom,
      home_zip: getDescriptionFromProfileCustom,
      home_city: getDescriptionFromProfileCustom,
      home_country: getDescriptionFromProfileCustom,
      birthday: getDescriptionFromProfileCustom,
      lang: (user) => user.profile.lang && user.profile.lang.toUpperCase(),
      pos: "",
      zone: (user) =>
        GetTagNameByClusterId(user.tags, clientConfig.ZONE_CLUSTER_IDS),
      country: (user) =>
        GetTagsByDeepness(user.tags, CLUSTER_DEEPNESS_IDS.COUNTRY),
      region: "",
      boutique: "",
      entity: getTagsByClusterIds(clientConfig.ENTITY_CLUSTER_IDS),
      division: getTagsByClusterIds(clientConfig.DIVISION_CLUSTER_IDS),
      profession: getTagsByClusterIds(clientConfig.PROFESSION_CLUSTER_IDS),
      job: getTagsByClusterIds(clientConfig.JOB_CLUSTER_IDS),
      experience: getTagsByClusterIds(clientConfig.EXPERIENCE_CLUSTER_IDS),
      registration_code: (user) => user.profile.inviteCode,
      created_date: (user) => formatDate(user.creationDate),
      department: getTagsByClusterIds(clientConfig.DEPARTMENT_CLUSTER_IDS),
      service: getTagsByClusterIds(clientConfig.SERVICE_CLUSTER_IDS),
      role: getTagsByClusterDeepness(clientConfig.ROLE_CLUSTER_DEEPNESS),
      status: (user) => user.status,
      userId: (user) => user.userId,
    };
    return extractors[columnConfiguration.name](user, columnConfiguration);
  } catch (e) {
    return "";
  }
};

const formatDate = (date) => {
  let formattedDate = "";
  try {
    formattedDate = new Date(date).toLocaleDateString();
  } catch (e) {}
  return formattedDate;
};

/**
 * Transforms an array of user documents from graphql to an array of user domain items that can be interpreted by the
 * application.
 * @param users {[UserDocument]}
 * @returns {[User]}
 */
export const userDocumentArrayToUserDomainItemArray = (users) =>
  users.map((user) =>
    clientConfig.USER_MANAGEMENT_DETAIL_COLUMNS.reduce(
      (userData, columnConfiguration) =>
        columnConfiguration.notDataBound
          ? userData
          : {
              ...userData,
              [columnConfiguration.name]: extractConvertedUserAttribute(
                user,
                columnConfiguration,
              ),
            },
      {
        userId: user.userId,
        pic: user.profile.pic,
      },
    ),
  );

/**
 * Transforms an array of user documents from graphql to an array of arrays that is ready to be exported.
 * @param users {[UserDocument]}
 * @returns {[]}
 */
export const userDocumentArrayToUserExportArray = (users) =>
  users.map((user) =>
    clientConfig.USER_MANAGEMENT_EXPORT_COLUMNS.filter(
      (columnConfiguration) => columnConfiguration.name !== "badges",
    ).map((columnConfiguration) =>
      extractConvertedUserAttribute(user, columnConfiguration),
    ),
  );

const tagsFilter = (tags, clusterIds) => {
  if (!tags || !tags.length || !clusterIds) return [];
  return tags
    .filter(({ clusterId }) => clusterIds.includes(Number(clusterId)))
    .map((tag) => tag.title);
};

export const getFormattedUsersProfilesData = ({
  profile,
  userId,
  tags,
  ...props
}) => {
  const brands = tagsFilter(tags, clientConfig.BRAND_TAGS_CLUSTER_IDS);
  const relationship = tagsFilter(
    tags,
    clientConfig.USER_RELATIONSHIP_CLUSTER_IDS,
  );
  const retailers = tagsFilter(tags, clientConfig.RETAILER_CLUSTER_IDS);

  const computedProfileInfo = {
    user: {
      name: `${profile.firstName} ${profile.lastName}`,
      picture: profile.pic || DefaultUserImage,
      position: profile.position,
      email: profile.mail || "-",
      location: profile.localization,
      locationUrl: profile.link || "#",
      birthday: profile.birthday || "-",
      pointOfSale: profile.pointOfSales || "-",
      creationDate: props.creationDate
        ? formatDateUsersProfiles(props.creationDate, "dd/MM/yyyy")
        : "-",
      lang: profile.lang
        ? LANGUAGES.find((lang) => lang.Code === profile.lang).Name
        : undefined,
      market:
        tags && tags.length && clientConfig.USER_MARKET_CLUSTER_IDS.length
          ? tags.find((tag) =>
              clientConfig.USER_MARKET_CLUSTER_IDS.includes(
                parseInt(tag.clusterId),
              ),
            )?.title
          : undefined,
      mailingAddress: profile.posAddress || "-",
    },
    brands,
    relationship,
    retailers,
  };
  return computedProfileInfo;
};
