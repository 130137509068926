import React, { useState } from "react";
import LessonContentHandlerReadOnly from "./LessonContentHandlerReadOnly";
import SideBar from "../../../components/SideBarNavigation";
import { Notification } from "genius-ui";
import { translations } from "./LessonEditorReadOnly.translations";
import { FormattedMessage } from "react-intl";

const LessonPageEditor = (props) => {
  const { lessonId, lang } = props.match.params;
  const [notificationIsOpen, setNotificationIsOpen] = useState(true);

  const handleBackClick = () => {
    const { lang, degreeId } = props.match.params;
    props.history.push(`/degree/build/${degreeId}/${lang}`);
  };

  return (
    <div>
      <Notification
        message={<FormattedMessage {...translations.ReadOnlyMessage} />}
        status="warning"
        isOpen={notificationIsOpen}
        handleClose={() => setNotificationIsOpen(false)}
      />
      <SideBar handleBackClick={handleBackClick} />
      <LessonContentHandlerReadOnly
        lessonId={lessonId}
        language={lang}
        activePageId={props.currentPageId}
        classes={props.classes}
        onDataChanged={(lessonId, lang, data, pageId) =>
          props.UpdateLessonPageData(
            lessonId,
            lang,
            data,
            pageId,
            props.updateLessonPageDataRequest,
          )
        }
        onNewPageCreated={(lessonId, lang, data, pageType) =>
          props.CreateLessonPage(
            lessonId,
            lang,
            data,
            pageType,
            props.createLessonPageRequest,
          )
        }
        onRemovePage={(lessonId, lang, pageId) =>
          props.RemoveLessonPage(lessonId, lang, pageId, props.removePage)
        }
        onLoad={props.UpdateLessonPublicationInfo}
        onPageChange={props.UpdateCurrentPageId}
        onOrderDown={(lessonId, lang, pageId) =>
          props.OnOrderDown(lessonId, lang, pageId, props.orderDownRequest)
        }
        onOrderUp={(lessonId, lang, pageId) =>
          props.OnOrderUp(lessonId, lang, pageId, props.orderUpRequest)
        }
        currentOpenedMarker={props.currentOpenedMarker}
        UpdateOpenedMarker={props.UpdateOpenedMarker}
        publishLessonData={props.publishLessonData}
        isLoaderActive={props.isLoaderActive}
      />
    </div>
  );
};

export default LessonPageEditor;
