import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import Style from "./ChangeTemplate.style";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import { createUseStyles } from "react-jss";

const useStyle = createUseStyles(Style);

const ChangeTemplate = (props) => {
  const { templates, selectedTemplateId, onTemplateSelect, className } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classNames([classes.container, className])}>
      {templates.map(({ id, name, image }) => (
        <div
          key={id}
          className={classNames([
            classes.templateItem,
            { [classes.selectedTemplateItem]: id === selectedTemplateId },
          ])}
          onClick={() =>
            id !== selectedTemplateId &&
            onTemplateSelect &&
            onTemplateSelect(id)
          }
        >
          <img src={image} alt="" />
          <div>
            {(typeof name === "object" && <FormattedMessage {...name} />) ||
              name}
          </div>
        </div>
      ))}
    </div>
  );
};

const idPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

ChangeTemplate.propTypes = {
  /** Selectable templates **/
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: idPropType.isRequired,
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.object,
      ]).isRequired,
      image: PropTypes.string.isRequired,
    }),
  ).isRequired,
  /** Id of the selected template **/
  selectedTemplateId: idPropType,
  /** Function executed when a template is selected **/
  onTemplateSelect: PropTypes.func,
  /** Custom class for the container component **/
  className: PropTypes.string,
};

export default ChangeTemplate;
