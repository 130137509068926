import React, { useState } from "react";
import { useIntl } from "react-intl";
import { translations } from "../../publish.translate";
import { useTitle } from "store/publish/hooks";

export const TitleClients = ({ type }) => {
  const intl = useIntl();
  const { title, EditTitle } = useTitle();
  const [text, setText] = useState(title);
  const [rows, setRows] = useState(1);
  const minRows = 1;
  const maxRows = 20;

  const handleChange = (event) => {
    const textareaLineHeight = 24;
    const previousRows = event.target.rows;
    event.target.rows = minRows;
    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }
    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }
    setRows(currentRows < maxRows ? currentRows : maxRows);
    setText(event.target.value);
  };

  return (
    <div className="titleContainer">
      <form className="inputSubmitText">
        <textarea
          className="inputTitle"
          type="text"
          name="title"
          value={text || ""}
          placeholder={intl.formatMessage(translations.TitlePlaceholder)}
          onChange={handleChange}
          onBlur={(event) => EditTitle(type, event.target.value)}
          rows={rows}
        />
      </form>
    </div>
  );
};

export default React.memo(TitleClients);
