import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { decodeNewLines } from "../../../utils/string";
import ActivityIntroReadOnly from "../../../components/common/ActivityIntroComponent/activityIntroReadOnly";
import SimpleQuizQuestionReadOnly from "../../../components/common/SimpleQuizQuestion/simpleQuizQuestionReadOnly";
import Style from "../SimpleQuizPageEditor.style";
import uuidv4 from "uuid/dist/v4";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import DocumentPageHeader from "../../common/DocumentPageHeader";

const useStyle = createUseStyles(Style);

const decodeContent = (encodedContent) => {
  const contentObject = JSON.parse(encodedContent);

  return {
    ...contentObject,
    data: {
      ...contentObject.data,
      activityDescription: decodeNewLines(
        contentObject.data.activityDescription,
      ),
      title: decodeNewLines(contentObject.data.title),
      description: decodeNewLines(contentObject.data.description),
      questions: contentObject.data.questions.map((question) => ({
        ...question,
        text: decodeNewLines(question.text),
        answers: question.answers.map(({ answer, isTrue, message }) => ({
          answer: decodeNewLines(answer),
          isTrue,
          message: decodeNewLines(message),
        })),
        endMessage: {
          ...question.endMessage,
          text: decodeNewLines(question.endMessage.text),
        },
      })),
    },
  };
};

const SimpleQuizPageEditor = (props) => {
  const { pageId, data: dataProp, orderId } = props;
  const classes = useThemedStyle(useStyle, props);
  // EXTRACT VARIABLES
  const { data } = decodeContent(dataProp);

  const { activityDescription, title, description, background, questions } =
    data;

  const enhancedQuestions = questions.map((question) => ({
    uid: uuidv4(),
    ...question,
  }));

  return (
    <div className={classes.pageBox}>
      <DocumentPageHeader pageNumber={orderId} showOrderButtons={false} />
      <ActivityIntroReadOnly
        activityId={pageId}
        theme={activityDescription}
        title={title}
        description={description}
        image={background.url}
      />
      {enhancedQuestions.map(({ uid, ...questionProps }, index) => (
        <SimpleQuizQuestionReadOnly
          isEditable={false}
          key={uid}
          uniqueId={`${pageId}-${index}`}
          id={index}
          {...questionProps}
        />
      ))}
    </div>
  );
};

SimpleQuizPageEditor.propTypes = {
  /** page Id  */
  pageId: PropTypes.string,
  /** current page JSON data */
  data: PropTypes.string,
};

export default SimpleQuizPageEditor;
