import React, { useCallback } from "react";
import { translations } from "../../PublishInsight.translation";
import { FormattedMessage } from "react-intl";
import useThemedStyle from "../../../../hooks/style/useThemedStyle";
import { usePublishInsightStyle } from "../../PublishInsight.style";
import { useDispatch, useSelector } from "react-redux";
import { SetInsightSummary } from "../../../../store/insight/actions";
import { useCurrentInsightId } from "../../hooks/useCurrentInsightId";
import { useCurrentInsightLang } from "../../hooks/useCurrentInsightLang";
import ContentEditableUncontrolled from "../../../../components/common/ContentEditableUncontrolled";

const selectors = {
  summary: (state) => state.insights.publishInsight.summary,
};

const EditInsightSummary = (props) => {
  const dispatch = useDispatch();
  const summary = useSelector(selectors.summary);
  const currentInsightId = useCurrentInsightId();
  const currentInsightLang = useCurrentInsightLang();
  const classes = useThemedStyle(usePublishInsightStyle, props);

  const UpdateSummary = useCallback(
    (summary) =>
      dispatch(
        SetInsightSummary(currentInsightId, currentInsightLang, summary),
      ),
    [currentInsightId, currentInsightLang, dispatch],
  );

  return (
    <FormattedMessage {...translations.SummaryPlaceholder}>
      {(message) => (
        <ContentEditableUncontrolled
          initialValue={summary || ""}
          onChange={UpdateSummary}
          className={classes.textInputSummary}
          placeholder={message[0]}
          tagName="article"
        />
      )}
    </FormattedMessage>
  );
};

export default React.memo(EditInsightSummary);
