import React from "react";
import Style from "./KPIPageContainer.style.js";
import Navigation from "../../Navigation";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import classNames from "classnames";
import PropTypes from "prop-types";
import FilterHeader from "../FilterHeader";
import Spinner from "../../common/Spinner";
import { useIsLoading } from "../../../store/kpiFilter/hooks";

const useStyle = createUseStyles(Style);

const KPIPageContainer = (props) => {
  const {
    children,
    screenContainer,
    contentContainer,
    onFilterChange,
    exportFunction,
    useIntervals,
  } = props;
  const { showLoader } = useIsLoading();
  const classes = useThemedStyle(useStyle, { ...props, showLoader });

  return (
    <div className={classes.container}>
      {!!onFilterChange && (
        <>
          <FilterHeader
            onFilterChange={onFilterChange}
            exportFunction={exportFunction}
            showRangeSelector
            useIntervals={useIntervals}
            hideExportButton={props.hideExportButton}
            hideDateSelector={props.hideDateSelector}
            goBack={props.goBack}
            onSearch={props.onSearch}
          />
          <div className={classes.spinner}>
            <Spinner size={30} />
          </div>
        </>
      )}
      <div className={classNames(classes.screenContainer, screenContainer)}>
        <Navigation />
        <div
          className={classNames([classes.contentContainer, contentContainer])}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

KPIPageContainer.propTypes = {
  screenContainer: PropTypes.string,
  contentContainer: PropTypes.string,
};

export default KPIPageContainer;
