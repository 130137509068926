import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";
import style from "./GeneralDetail.style";
import {
  WidgetSection,
  WidgetContainer,
  KeyDetailWidget,
  QuantityWidget,
  KPIPageContainer,
} from "components/KPI";
import { connect } from "react-redux";
import { GetGeneralDetails } from "store/kpiGeneralDetails/actions";
import { FormattedMessage, useIntl } from "react-intl";
import { exportGeneral } from "utils/excelExport/kpi/general";
import { translations } from "./GeneralDetail.translations";
import { ColumnChart } from "genius-charts";
import useThemedStyle from "hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const GeneralDetailPage = (props) => {
  const intl = useIntl();
  const { data, GetGeneralDetails } = props;
  const classes = useThemedStyle(useStyle, props);

  const formatChartValues = (total, value) =>
    `${((value * 100) / total).toFixed(1)} %`;

  const { generalDetails: allData } = data;
  const { application, learning, feed, ask } = allData || {};
  const exportFunction = useCallback(
    (filterData, intl) =>
      exportGeneral(
        {
          application: application,
          learning: learning,
          feed: feed,
          ask: ask,
        },
        filterData,
        intl,
      ),
    [application, learning, feed, ask],
  );

  return (
    <div className={classes.container}>
      <KPIPageContainer
        onFilterChange={GetGeneralDetails}
        exportFunction={exportFunction}
      >
        {!!data.generalDetails && (
          <>
            <div className={classes.activeUser}>
              <WidgetSection
                width={35}
                title={<FormattedMessage {...translations.ApplicationTitle} />}
              >
                <WidgetContainer width={33.33}>
                  <KeyDetailWidget
                    title={
                      <FormattedMessage {...translations.ActiveUsersTitle} />
                    }
                    primaryData={`${
                      application.activeUsers ? application.activeUsers : 0
                    }/${application.totalUsers ? application.totalUsers : 0}`}
                    secondaryData={
                      application.previousPeriod.activeUsers
                        ? application.previousPeriod.activeUsers
                        : 0
                    }
                    percentData={Math.abs(allData.activeUsersPercentData)}
                    lowPercent={allData.activeUsersPercentData < 0}
                    isAvailable={!!application}
                  />
                </WidgetContainer>
                {/* <WidgetContainer width={33.33}>
              <KeyDetailWidget
                title={<FormattedMessage {...translations.ActiveNumberOfConnectionTitle} />}
                primaryData={application.connections}
                secondaryData={application.previousPeriod.connections}
                percentData={Math.abs(allData.connectionsPercentData)}
                lowPercent={allData.connectionsPercentData < 0}
                isAvailable={!!application}
              />
            </WidgetContainer> */}
                {/* <WidgetContainer width={33.33}>
              <FormattedMessage {...translations.MinLabel}>
                {message => (
                  <KeyDetailWidget
                    title={<FormattedMessage {...translations.AverageTimeSpentTitle} />}
                    primaryData={`${allData.actualAverageMin}${message[0]}`}
                    secondaryData={allData.previousAverageMin}
                    percentData={Math.abs(allData.dailyUsagePercentData)}
                    lowPercent={allData.dailyUsagePercentData < 0}
                    isAvailable={!!application}
                  />)}
              </FormattedMessage>
            </WidgetContainer> */}
              </WidgetSection>
            </div>
            <WidgetSection
              width={50}
              title={<FormattedMessage {...translations.LearningTitle} />}
            >
              <WidgetContainer width={45}>
                <QuantityWidget
                  counter={learning.programsNb}
                  title={<FormattedMessage {...translations.ProgramsTitle} />}
                />
              </WidgetContainer>
              <WidgetContainer width={45}>
                <QuantityWidget
                  counter={learning.lessonsNb}
                  title={<FormattedMessage {...translations.LessonTitle} />}
                />
              </WidgetContainer>
            </WidgetSection>
            <WidgetSection
              width={100}
              title={<FormattedMessage {...translations.FeedTitle} />}
            >
              <WidgetContainer width={100}>
                <ColumnChart
                  total={allData.feedTotal}
                  title={intl.formatMessage({
                    ...translations.TotalContentNumberTitle,
                  })}
                  data={[
                    {
                      name: intl.formatMessage({
                        ...translations.InsightsLabel,
                      }),
                      upperValue: feed.insights,
                      loverValue: formatChartValues(
                        allData.feedTotal,
                        feed.insights,
                      ),
                    },
                    {
                      name: intl.formatMessage({
                        ...translations.LessonsLabel,
                      }),
                      upperValue: feed.lessons,
                      loverValue: formatChartValues(
                        allData.feedTotal,
                        feed.lessons,
                      ),
                    },
                    {
                      name: intl.formatMessage({
                        ...translations.SocialMediaLabel,
                      }),
                      upperValue: feed.socialMedias,
                      loverValue: formatChartValues(
                        allData.feedTotal,
                        feed.socialMedias,
                      ),
                    },
                    {
                      name: intl.formatMessage({
                        ...translations.SlidersLabel,
                      }),
                      upperValue: feed.sliders,
                      loverValue: formatChartValues(
                        allData.feedTotal,
                        feed.sliders,
                      ),
                    },
                  ]}
                  columnTooltipText={intl.formatMessage(
                    translations.ColumnTooltipContent,
                  )}
                />
              </WidgetContainer>
            </WidgetSection>
            <WidgetSection
              width={100}
              title={<FormattedMessage {...translations.AskTitle} />}
            >
              <WidgetContainer width={25}>
                <QuantityWidget
                  counter={ask.geniusQuestion}
                  title={
                    <FormattedMessage
                      {...translations.QuestionAskedGeniusTitle}
                    />
                  }
                />
              </WidgetContainer>
              <WidgetContainer width={25}>
                <QuantityWidget
                  counter={ask.expertQuestion}
                  title={
                    <FormattedMessage
                      {...translations.QuestionAskedExpertsTitle}
                    />
                  }
                />
              </WidgetContainer>
              <WidgetContainer width={25}>
                <QuantityWidget
                  counter={ask.expertAnswer}
                  title={
                    <FormattedMessage
                      {...translations.AnsweredQuestionsExpertsTitle}
                    />
                  }
                />
              </WidgetContainer>
              <WidgetContainer width={25}>
                <QuantityWidget
                  counter={ask.expertQuestion - ask.expertAnswer}
                  title={
                    <FormattedMessage
                      {...translations.PendingQuestionsExpertsTitle}
                    />
                  }
                />
              </WidgetContainer>
            </WidgetSection>
          </>
        )}
      </KPIPageContainer>
    </div>
  );
};

const mapStateToProp = (state) => ({
  data: state.kpi.generalDetails,
  isExportLoading: state.kpi.filters.isExportLoading,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  GetGeneralDetails: () => {
    dispatch(GetGeneralDetails());
  },
});

export default connect(mapStateToProp, mapDispatchToProps)(GeneralDetailPage);
