import {
  resultsContentPadding,
  resultsContentWidth,
} from "../poll/PollResults.style";
import { navigationBarWidth } from "../../../components/Navigation/Navigation.style";

export const resultsHeaderHeight = 60;

export const defaultStyles = (theme) => ({
  root: {
    height: resultsHeaderHeight,
    backgroundColor: theme.Header.containerBackground,
    boxShadow: `0 2px 20px 0 ${theme.Header.boxShadowColor}`,
    boxSizing: "border-box",
  },
  content: {
    height: "100%",
    maxWidth: resultsContentWidth,
    padding: `0 ${resultsContentPadding}px`,
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    "& > div": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  backButton: {
    cursor: "pointer",
    fontSize: 18,
  },
  periodSelector: {
    width: 280,
    marginRight: 15,
  },
  intervalSelector: {
    width: 160,
    border: `solid 1px ${theme.DefaultFilterHeader.periodFilterBorderColor}`,
    borderRadius: 5,
    paddingLeft: 2,
    marginRight: 10,
    height: "38px !important",
    "& > div > div > div": {
      minHeight: "38px !important",
    },
  },
});
