import gql from "graphql-tag";

export const INSIGHT_CLONE_TO_MARKET = gql`
  mutation cloneToMarket(
    $insightId: ID
    $lang: LangEnum!
    $marketTagId: Int
    $marketTagIds: [Int!]
  ) {
    admin {
      insight(insightId: $insightId) {
        clone(
          lang: $lang
          marketTagId: $marketTagId
          marketTagIds: $marketTagIds
          cloneTags: false
          cloneTagsFeature: true
        ) {
          insightId
        }
      }
    }
  }
`;
