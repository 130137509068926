import client from "../../graphql/client";
import { GET_BATTLE_BY_ID } from "../../graphql/remote/battle/queries/GetBattleInfo";
import { SetErrorMessage } from "../notification/actions";
import { SetFetchIndicator, SetUpdateIndicator } from "../common/actions";
import {
  UPDATE_PUBLISH_DATA,
  RESET_STORE,
} from "../../constants/store/publishBattle";
import { formatBattlePublishData } from "./transformers";
import { UPDATE_BATTLE_INFO } from "../../graphql/remote/battle/mutations/UpdateBattleInfo.graphql";
import { EDIT_BATTLE_PAGE } from "../../graphql/remote/battle/mutations/EditBattlePage";
import { BATTLE_UPDATE_WORFLOW } from "../../graphql/remote/battle/mutations/UpdateBattleWorkflow";
import { DOC_WORKFLOW_ENUM } from "../../configs/constants";
import { ADD_TAG } from "../../graphql/remote/battle/mutations/AddTag.graphql";
import { REMOVE_TAG } from "../../graphql/remote/battle/mutations/RemoveTag.graphql";
import { isAfter, parseISODate } from "../../utils/dateUtils";

export const UpdatePublishData = (data) => (dispatch) =>
  dispatch({
    type: UPDATE_PUBLISH_DATA,
    data,
  });

export const ResetStore = () => (dispatch) => dispatch({ type: RESET_STORE });

export const GetBattleInfo = (battleId, language) => async (dispatch) => {
  try {
    dispatch(SetFetchIndicator(true));
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_BATTLE_BY_ID,
      variables: {
        battleId,
      },
    });

    const currentLanguageBattleVersion =
      result.data.admin.battles[0].versions.find(
        (version) => version.lang === language,
      );
    dispatch(
      UpdatePublishData(formatBattlePublishData(currentLanguageBattleVersion)),
    );
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while fetching the battle info for battle with id ${battleId}`,
      ),
    );
  }
  dispatch(SetFetchIndicator(false));
};

export const UpdateBattleInfo =
  (type, data, onSuccess) => async (dispatch, getState) => {
    const {
      publishData: { battleId, lang },
    } = getState().publishBattle;
    try {
      await client.mutate({
        mutation: UPDATE_BATTLE_INFO,
        variables: { battleId, lang, type, data },
      });
      onSuccess && onSuccess();
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while updating the battle info with id ${battleId}`,
        ),
      );
    }
  };

export const UpdateBattlePage =
  (newPage, onSuccess) => async (dispatch, getState) => {
    const {
      publishData: { pageId, battleId, lang },
    } = getState().publishBattle;
    try {
      await client.mutate({
        mutation: EDIT_BATTLE_PAGE,
        variables: {
          battleId: battleId,
          lang: lang,
          pageId: pageId,
          data: newPage,
        },
      });
      onSuccess && onSuccess();
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while updating the page of the battle ${battleId}`,
          e,
        ),
      );
    }
  };

export const UpdateBattleWorkflow =
  (onSuccess) => async (dispatch, getState) => {
    const {
      publishData: { battleId, lang, startPostingDate, endPostingDate },
    } = getState().publishBattle;
    const status = isAfter(parseISODate(startPostingDate), new Date())
      ? DOC_WORKFLOW_ENUM.DRAFT
      : DOC_WORKFLOW_ENUM.PUBLISHED;

    try {
      dispatch(SetUpdateIndicator(true));
      await client.mutate({
        mutation: BATTLE_UPDATE_WORFLOW,
        variables: {
          battleId,
          lang,
          status,
          dateIn: startPostingDate,
          dateOut: endPostingDate,
        },
      });
      onSuccess && onSuccess();
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while updating the workflow of the battle ${battleId}`,
          e,
        ),
      );
    } finally {
      dispatch(SetUpdateIndicator(false));
    }
  };

export const UpdateMasterBattleWorkflow = () => async (dispatch, getState) => {
  const {
    publishData: { workflow, battleId, lang },
  } = getState().publishBattle;
  const status =
    workflow === DOC_WORKFLOW_ENUM.DRAFT
      ? DOC_WORKFLOW_ENUM.PUBLISHED
      : DOC_WORKFLOW_ENUM.DRAFT;

  try {
    await client.mutate({
      mutation: BATTLE_UPDATE_WORFLOW,
      variables: {
        battleId,
        lang,
        status,
      },
    });
    dispatch(UpdatePublishData({ workflow: status }));
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while updating the workflow of the battle ${battleId}`,
        e,
      ),
    );
  }
};

export const AddTags = (tagIds, onSuccess) => async (dispatch, getState) => {
  const {
    publishData: { battleId, lang },
  } = getState().publishBattle;
  try {
    await client.mutate({
      mutation: ADD_TAG,
      variables: {
        battleId,
        lang,
        tagIds,
      },
    });
    onSuccess && onSuccess();
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while adding tagIds to battle with id ${battleId}`,
        e,
      ),
    );
  }
};

export const AddBattleTags =
  (battleId, lang, tagIds, onSuccess) => async (dispatch) => {
    try {
      await client.mutate({
        mutation: ADD_TAG,
        variables: {
          battleId,
          lang,
          tagIds,
        },
      });
      onSuccess && onSuccess();
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while adding tagIds to battle with id ${battleId}`,
          e,
        ),
      );
    }
  };

export const RemoveTags = (tagIds, onSuccess) => async (dispatch, getState) => {
  const {
    publishData: { battleId, lang },
  } = getState().publishBattle;
  try {
    await client.mutate({
      mutation: REMOVE_TAG,
      variables: {
        battleId,
        lang,
        tagIds,
      },
    });
    onSuccess && onSuccess();
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while removing tagIds from battle with id ${battleId}`,
        e,
      ),
    );
  }
};
