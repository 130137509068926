export const RESET_LESSON_STORE = "RESET_LESSON_STORE";
export const SET_PUBLISH_LESSON_DATA = "SET_PUBLISH_LESSON_DATA";
export const UPDATE_EDIT_LESSON_DATA = "UPDATE_EDIT_LESSON_DATA";
export const UPDATE_PUBLISH_LESSON_DATA = "UPDATE_PUBLISH_LESSON_DATA";
export const UPDATE_LESSON_LOCATIONS_LIMIT = "UPDATE_LESSON_LOCATIONS_LIMIT";
export const SET_LESSON_MASTER_FLAG = "SET_LESSON_MASTER_FLAG";
export const SET_BADGES = "SET_BADGES";
export const SET_BADGE_LESSON_IDS = "SET_BADGE_LESSON_IDS";
export const SET_LESSON_BADGES = "SET_LESSON_BADGES";
export const SET_ALL_LESSONS = "SET_ALL_LESSONS";
export const RESET_BADGES = "RESET_BADGES";
export const SET_SELECTED_SHOPS = "SET_SELECTED_SHOPS";
export const SET_LESSON_PAGE_DATA = "SET_LESSON_PAGE_DATA";
