import gql from "graphql-tag";

export const CARD_REMOVE_PRIVACY = gql`
  mutation cardRemovePrivacy($cardId: ID, $lang: LangEnum) {
    admin {
      card(cardId: $cardId, lang: $lang) {
        privacy {
          remove
        }
      }
    }
  }
`;
