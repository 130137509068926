const BadgeVariationMap = {
  completed: {
    opacity: 1,
  },
  uncompleted: {
    opacity: 0.2,
  },
};

export default (theme) => ({
  container: {
    position: "relative",
    display: "inline-block",
    textAlign: "center",
    fontFamily: theme.FontFamily,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
  badge: {
    width: ({ size }) => size,
    height: ({ size }) => size,
    opacity: ({ variation }) => BadgeVariationMap[variation].opacity,
  },
  label: {
    marginTop: 5,
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1.38,
    color: theme.UserProfileBadge.labelColor,
    opacity: ({ variation }) => BadgeVariationMap[variation].opacity,
  },
  date: {
    fontFamily: theme.FontFamily,
    fontSize: 11,
    lineHeight: 1.91,
    letterSpacing: -0.22,
    color: theme.UserProfileBadge.dateColor,
  },
  checkmark: {
    position: "absolute",
    top: 5,
    right: 5,
    "& > svg > g > circle": {
      fill: theme.UserProfileBadge.checkmarkCircleFill,
    },
    "& > svg > g > path": {
      fill: theme.UserProfileBadge.checkmarkPathFill,
    },
  },
});
