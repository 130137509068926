import { defineMessages } from "react-intl";

export const translations = defineMessages({
  UploadButtonLabel: {
    id: "VerticalMarkerSlide.UploadButtonLabel",
    defaultMessage: "Upload Image",
  },
  DeleteButtonLabel: {
    id: "VerticalMarkerSlide.DeleteButtonLabel",
    defaultMessage: "Delete",
  },
  PartPossiblyCropped: {
    id: "VerticalMarkerSlide.PartPossiblyCropped",
    defaultMessage:
      "Partie possiblement croppée en fonction de la résolution de l’écran",
  },
  ScrollDown: {
    id: "VerticalMarkerSlide.ScrollDown",
    defaultMessage: "Scroll down",
  },
});
