import { RESET_STORE } from "../../../constants/store/auth";
import {
  RESET_SELECTED_VALUES,
  SET_AVAILABLE_VALUES,
  SET_SELECTED_VALUES,
} from "../../../constants/store/creators/filters";

const setValueWithDefault = (value) =>
  typeof value !== "undefined" ? value : [];

export default (prefix, filters = []) => {
  const initialState = filters.reduce(
    (
      initialState,
      { name, initialState: providedInitialState, availableValues },
    ) => {
      initialState[name] = {
        selectedValues: setValueWithDefault(providedInitialState),
        availableValues: setValueWithDefault(availableValues),
      };
      return initialState;
    },
    {},
  );

  return (state = initialState, action = {}) => {
    const { data, type } = action;
    switch (type) {
      case SET_SELECTED_VALUES(prefix):
        return {
          ...state,
          [data.filterName]: {
            ...state[data.filterName],
            selectedValues: setValueWithDefault(data.data),
          },
        };
      case RESET_SELECTED_VALUES(prefix):
        return {
          ...state,
          [data.filterName]: {
            ...state[data.filterName],
            selectedValues: initialState[data.filterName].selectedValues,
          },
        };
      case SET_AVAILABLE_VALUES(prefix):
        return {
          ...state,
          [data.filterName]: {
            ...state[data.filterName],
            availableValues: setValueWithDefault(data.data),
          },
        };
      case RESET_STORE: {
        return { ...initialState };
      }
      default:
        return state;
    }
  };
};
