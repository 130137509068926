export default (theme) => ({
  rootContainer: {
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 15,
    "& .icon-slim-close-icon": {
      cursor: "pointer",
    },
  },
  noFileText: {
    color: "#a0a0a0",
    marginBottom: 15,
  },
  uploadBtnWrapper: {
    overflow: "hidden",
    display: "inline-block",
    position: "relative",
  },
  uploadInput: {
    padding: 100,
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0,
    display: "block",
    cursor: "pointer",
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    "& > button:first-of-type": {
      marginLeft: 20,
    },
  },
});
