import React from "react";
import PropTypes from "prop-types";
import messages from "../PollQuestionChoiceEditor.translations";
import Style from "../PollQuestionChoiceEditor.style.js";
import classNames from "classnames";
import ContentEditableUncontrolled from "../../../common/ContentEditableUncontrolled";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const PollQuestionChoiceEditor = (props) => {
  const { text, isAddAChoice } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <label
      className={classNames([
        classes.choice,
        {
          [classes.addNewAnswerChoice]: isAddAChoice,
        },
      ])}
    >
      {isAddAChoice ? (
        text
      ) : (
        <FormattedMessage {...messages.PollQuestionChoicePlaceHolder}>
          {(placeHolder) => (
            <ContentEditableUncontrolled
              className={classes.choiceInput}
              initialValue={text}
              onChange={() => null}
              placeholder={placeHolder[0]}
              onClick={() => null}
              disabled
            />
          )}
        </FormattedMessage>
      )}
    </label>
  );
};

PollQuestionChoiceEditor.propTypes = {
  multiAnswerManagement: PropTypes.bool,
};

export default PollQuestionChoiceEditor;
