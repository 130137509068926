const dotCommonStyles = (theme) => ({
  cursor: "pointer",
  width: 7,
  height: 7,
  borderRadius: 7,
  marginRight: 7,
  backgroundColor: theme.UserProfileBadgeSlide.dotCommonBackground,
  border: `solid 1px ${theme.UserProfileBadgeSlide.dotCommonBorderColor}`,
});

export default (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    fontFamily: theme.FontFamily,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    padding: "20px 25px 20px 30px",
    borderBottom: `solid 1px ${theme.UserProfileBadgeSlide.containerBorderBottomColor}`,
    overflow: "hidden",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 30,
  },
  title: {
    fontSize: 18,
    color: theme.UserProfileBadgeSlide.titleColor,
    fontWeight: "bold",
  },
  points: {
    fontSize: 16,
    color: theme.UserProfileBadgeSlide.pointsColor,
  },
  badges: {
    float: "left",
    display: "flex",
    flexDirection: "row",
    marginBottom: 30,
    minHeight: 211,
  },
  badge: {
    width: 130,
    height: 130,
    borderRadius: 130,
    border: `solid 3px ${theme.UserProfileBadgeSlide.badgeColor}`,
    textAlign: "center",
  },
  badgeContainer: {
    marginRight: 30,
  },
  sliderDots: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  dotFull: {
    ...dotCommonStyles(theme),
  },
  dotEmpty: {
    ...dotCommonStyles(theme),
    backgroundColor: theme.UserProfileBadgeSlide.dotEmptyBackground,
  },
});
