import React from "react";
import {
  GetAvailableDegrees,
  UpdateFilterSelectedValues,
} from "../../../store/kpiLearningDetails/actions";
import { GetUserProfileData } from "../../../store/userProfile/actions";
import KPIDegreeFilter from "../../../components/KPI/Filters/DegreeFilter";

const ProfileDegreeFilter = ({ userId }) => (
  <KPIDegreeFilter
    filterContainerStoreName="learningDetails"
    GetPageData={() => GetUserProfileData(userId)}
    GetAvailableDegrees={GetAvailableDegrees}
    UpdateFilterSelectedValues={UpdateFilterSelectedValues}
  />
);

export default ProfileDegreeFilter;
