// USERS
export const UPDATE_USERS = "UPDATE_USERS";
export const DELETE_USER_BY_ID = "DELETE_USER_BY_ID";
export const UPDATE_USER_COUNT = "UPDATE_USER_COUNT";
export const UPDATE_USER_LIST = "UPDATE_USER_LIST";
export const SET_USERS_LOADING = "SET_USERS_LOADING";
export const UPDATE_PAGINATION_META_DATA = "UPDATE_PAGINATION_META_DATA";
export const SET_FILTER_SELECTED_TAGS = "SET_FILTER_SELECTED_TAGS";
export const RESET_FILTER_SELECTED_TAGS = "RESET_FILTER_SELECTED_TAGS";
export const UPDATE_FILTER_CLUSTERIDS = "UPDATE_FILTER_CLUSTERIDS";
export const RESET_USER_LIST = "RESET_USER_LIST";
export const UPDATE_USERS_PROFILES_DATA = "UPDATE_USERS_PROFILES_DATA";
