import React, { useCallback } from "react";
import { connect } from "react-redux";
import { calculateTagRank } from "../../utils/transformers";
import {
  KPIPageContainer,
  WidgetContainer,
  WidgetSection,
} from "../../components/KPI";
import { FormattedMessage } from "react-intl";
import QuantityWidget from "../../components/KPI/QuantityWidget";
import TagCloudWidget from "../../components/KPI/TagCloudWidget";
import { GetPageData } from "../../store/kpiAskDetails/actions";
import { exportAsk } from "../../utils/excelExport/kpi/ask";
import { translations } from "./Ask.translations";
import { createUseStyles } from "react-jss";
import style from "./Ask.style";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const KpiAskShiseido = (props) => {
  const {
    questionsToGenius,
    questionsToExperts,
    answeredByExperts,
    pendingQuestionsToExperts,
    tagsInQuestionsToGenius,
    tagsInQuestionsToExperts,
    GetPageData,
    Export,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  const exportFunction = useCallback(
    (filters, intl) =>
      Export(
        {
          questionsToGenius: questionsToGenius,
          questionsToExperts: questionsToExperts,
          answeredByExperts: answeredByExperts,
          pendingQuestionsToExperts: pendingQuestionsToExperts,
          tagsInQuestionsToGenius: tagsInQuestionsToGenius,
          tagsInQuestionsToExperts: tagsInQuestionsToExperts,
        },
        filters,
        intl,
      ),
    [
      questionsToGenius,
      questionsToExperts,
      answeredByExperts,
      pendingQuestionsToExperts,
      tagsInQuestionsToGenius,
      tagsInQuestionsToExperts,
    ],
  );

  return (
    <div className={classes.container}>
      <KPIPageContainer
        onFilterChange={GetPageData}
        exportFunction={exportFunction}
      >
        <WidgetSection title=" ">
          <FormattedMessage {...translations.QuestionToGeniusLabel}>
            {(message) => (
              <WidgetContainer width={25}>
                <QuantityWidget
                  title={message[0]}
                  counter={questionsToGenius}
                />
              </WidgetContainer>
            )}
          </FormattedMessage>
          <FormattedMessage {...translations.QuestionToExpertsLabel}>
            {(message) => (
              <WidgetContainer width={25}>
                <QuantityWidget
                  title={message[0]}
                  counter={questionsToExperts}
                />
              </WidgetContainer>
            )}
          </FormattedMessage>
          <FormattedMessage {...translations.AnsweredByExpertsLabel}>
            {(message) => (
              <WidgetContainer width={25}>
                <QuantityWidget
                  title={message[0]}
                  counter={answeredByExperts}
                />
              </WidgetContainer>
            )}
          </FormattedMessage>
          <FormattedMessage {...translations.PendingToExpertsLabel}>
            {(message) => (
              <WidgetContainer width={25}>
                <QuantityWidget
                  title={message[0]}
                  counter={pendingQuestionsToExperts}
                />
              </WidgetContainer>
            )}
          </FormattedMessage>
        </WidgetSection>
        <WidgetSection>
          <WidgetContainer width={50}>
            <TagCloudWidget
              title={
                <FormattedMessage
                  {...translations.TagsInQuestionsToGeniusLabel}
                />
              }
              tags={tagsInQuestionsToGenius}
            />
          </WidgetContainer>
          <WidgetContainer width={50}>
            <TagCloudWidget
              title={
                <FormattedMessage
                  {...translations.TagsInQuestionsToExpertsLabel}
                />
              }
              tags={tagsInQuestionsToExperts}
            />
          </WidgetContainer>
        </WidgetSection>
      </KPIPageContainer>
    </div>
  );
};

const mapAskDocumentTagToCloudTagTag = ({ id, name, rank }) => ({
  id,
  content: name,
  rank,
});

const mapStateToProps = (state) => {
  const geniusTags = calculateTagRank(state.kpi.askDetails.topTags.genius);
  const expertTags = calculateTagRank(state.kpi.askDetails.topTags.expert);

  return {
    questionsToGenius: state.kpi.askDetails.geniusQuestion,
    questionsToExperts: state.kpi.askDetails.expertQuestion,
    answeredByExperts: state.kpi.askDetails.expertAnswer,
    pendingQuestionsToExperts: state.kpi.askDetails.pendingQuestionsToExperts,
    tagsInQuestionsToGenius: geniusTags.map(mapAskDocumentTagToCloudTagTag),
    tagsInQuestionsToExperts: expertTags.map(mapAskDocumentTagToCloudTagTag),
    isExportLoading: state.kpi.filters.isExportLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  GetPageData: () => dispatch(GetPageData()),
  Export: (exportData, filters, intl) => {
    exportAsk(exportData, filters, intl);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(KpiAskShiseido);
