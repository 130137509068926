import React, { useState } from "react";
import Style from "./ProgramsWidgetShiseido.style.js";
import { FormattedMessage } from "react-intl";
import WhiteAccordion from "../../common/WhiteAccordion/WhiteAccordion";
import CompletionRate from "../../common/CompletionRate";
import ProgramsWidgetLessonRowShiseido from "./LessonRow";
import { programsWidgetShiseidoTranslations as translations } from "./ProgramsWidgetShiseido.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import { Icon, Icons } from "genius-ui";

const useStyle = createUseStyles(Style);

const formatOrdersFromPrograms = (programs) =>
  programs.reduce((acc, program) => {
    acc[program.id] = true;
    return acc;
  }, {});

const ProgramsWidgetShiseido = (props) => {
  const {
    programs,
    sortByLessonCompletion,
    sortByUserCompletion,
    exportLesson,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  const [orders, setOrderDirection] = useState(
    formatOrdersFromPrograms(programs),
  );

  const handleSortByUserCompletion = (programId) => {
    sortByUserCompletion(orders[programId], programId);
    setOrderDirection((prev) => {
      const ret = { ...prev };
      ret[programId] = !ret[programId];
      return ret;
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <FormattedMessage {...translations.Programs} />
        <span
          className={classes.sortableColumn}
          onClick={sortByLessonCompletion}
        >
          <FormattedMessage {...translations.Completion} />
          <Icon iconName={Icons.sort} />
        </span>
      </div>
      <WhiteAccordion
        items={programs}
        renderHeading={(program) => {
          // TODO: Fix program.stats.totalLessonNb in back and apply it here
          const allowedUsersNb = program.lessons?.reduce(
            (acc, { allowedUsersNb }) => acc + allowedUsersNb,
            0,
          );

          return (
            <div className={classes.accordionHeading}>
              <span>{`${program.degreeName} - ${program.name}`}</span>
              <CompletionRate
                completed={program.stats.validatedLessonNb}
                total={allowedUsersNb}
                formatLabel={() => (
                  <FormattedMessage {...translations.LessonHeadingCRLabel} />
                )}
                className={classes.headingCompletionRate}
              />
            </div>
          );
        }}
        renderContent={(program) => (
          <div className={classes.accordionContentContainer}>
            {!!program.lessons.length && (
              <div className={classes.lessonHeader}>
                <div>
                  <FormattedMessage {...translations.LessonNameLabel} />
                </div>
                <div onClick={() => handleSortByUserCompletion(program.id)}>
                  <div>
                    <FormattedMessage
                      {...translations.LessonCompletionRateLabel}
                    />
                  </div>
                  <div>
                    <Icon iconName={Icons.sort} />
                  </div>
                </div>
                <div>
                  <FormattedMessage {...translations.LessonUserStoppedLabel} />
                </div>
                <div>
                  <FormattedMessage {...translations.TotalPageLabel} />
                </div>
              </div>
            )}
            {program.lessons.length ? (
              program.lessons.map((lesson) => (
                <ProgramsWidgetLessonRowShiseido
                  key={lesson.id}
                  lesson={lesson}
                  exportLesson={() => exportLesson(lesson)}
                />
              ))
            ) : (
              <div className={classes.noLesson}>
                <FormattedMessage {...translations.NoOneLessonText} />
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default ProgramsWidgetShiseido;
