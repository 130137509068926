import { defineMessages } from "react-intl";

export const translations = defineMessages({
  InsightTitle: {
    id: "ContentType.InsightTitle",
    defaultMessage: "Insight",
  },
  InsightDescription: {
    id: "ContentType.InsightDescription",
    defaultMessage:
      "Create an Insight in the feed using the different template to create content (Slider, pictures etc...)",
  },
  SocialReportTitle: {
    id: "ContentType.SocialReportTitle",
    defaultMessage: "Social Report",
  },
  SocialReportDescription: {
    id: "ContentType.SocialReportDescription",
    defaultMessage:
      "Add a specific Instagram link to push an instagram post directly in the feed of the application",
  },
  SliderTitle: {
    id: "ContentType.SliderTitle",
    defaultMessage: "Slider",
  },
  SliderDescription: {
    id: "ContentType.SliderDescription",
    defaultMessage:
      "Create a slider directly in the feed with or without text to push smalls and illustrated contents in the application",
  },
  QuickQuizTitle: {
    id: "ContentType.QuickQuizTitle",
    defaultMessage: "Quick quiz",
  },
  QuickQuizDescription: {
    id: "ContentType.QuickQuizDescription",
    defaultMessage:
      "Create a one page quizz to test a specific question for your community",
  },
  BattleTitle: {
    id: "ContentType.BattleTitle",
    defaultMessage: "Battle",
  },
  BattleDescription: {
    id: "ContentType.BattleDescription",
    defaultMessage:
      "Create a battle with multi questions and let you community run this challenge",
  },
  NewDraftInsightTitle: {
    id: "ContentType.NewDraftInsightTitle",
    defaultMessage: "NEW DRAFT INSIGHT",
  },
  NewDraftCardTitle: {
    id: "ContentType.NewDraftCardTitle",
    defaultMessage: "NEW DRAFT CARD",
  },
  NewDraftQuickQuizTitle: {
    id: "ContentType.NewDraftQuickQuizTitle",
    defaultMessage: "NEW DRAFT QUICK QUIZ",
  },
  NewDraftBattleTitle: {
    id: "ContentType.NewDraftBattleTitle",
    defaultMessage: "NEW DRAFT BATTLE",
  },
  PollTitle: {
    id: "ContentType.PollTitle",
    defaultMessage: "Poll",
  },
  PollDescription: {
    id: "ContentType.PollDescription",
    defaultMessage:
      "Create a poll to ask a specific question to all your community",
  },
  NewDraftPollTitle: {
    id: "ContentType.NewDraftPollTitle",
    defaultMessage: "NEW DRAFT POLL",
  },
  NinjaTitle: {
    id: "ContentType.NinjaTitle",
    defaultMessage: "The Right Match",
  },
  NinjaDescription: {
    id: "ContentType.NinjaDescription",
    defaultMessage: "Create a Right Match game for your community",
  },
  NewDraftNinjaTitle: {
    id: "ContentType.NewDraftNinjaTitle",
    defaultMessage: "NEW DRAFT THE RIGHT MATCH",
  },
});
