import * as constants from "../../constants/store/navigation";
import * as constantsDegrees from "../../constants/store/degrees";
import * as constantsInsights from "../../constants/store/insights";

export const SetActiveNavigationMenuItem = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_ACTIVE_NAVIGATION_MENU,
    data: data,
  });
  dispatch({
    type: constantsDegrees.SET_ACTIVE_MENU_FOR_DEGREE_ID,
    data: undefined,
  });
  dispatch({
    type: constantsInsights.SET_ACTIVE_MENU_FOR_INSIGHT_ID,
    data: undefined,
  });
};

export const SetExpandedNavigationMenuItem = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_EXPANDED_NAVIGATION_MENU,
    data: data,
  });
  dispatch({
    type: constantsDegrees.SET_ACTIVE_MENU_FOR_DEGREE_ID,
    data: undefined,
  });
  dispatch({
    type: constantsInsights.SET_ACTIVE_MENU_FOR_INSIGHT_ID,
    data: undefined,
  });
};

export const SetLoaderActive = () => (dispatch) => {
  dispatch({
    type: constants.SET_LOADER_STATUS,
    data: true,
  });
};

export const SetLoaderInactive = () => (dispatch) => {
  dispatch({
    type: constants.SET_LOADER_STATUS,
    data: false,
  });
};
