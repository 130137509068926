import gql from "graphql-tag";

export const EDIT_NINJA_PAGE = gql`
  mutation editNinjaPage(
    $ninjaId: ID
    $lang: LangEnum
    $pageId: ID
    $data: String!
  ) {
    admin {
      ninja(ninjaId: $ninjaId, lang: $lang) {
        pages(pageId: $pageId) {
          data(data: $data)
        }
      }
    }
  }
`;
