export default {
  container: {
    width: 800,
    backgroundColor: "white",
  },
  tableHeader: {
    display: "flex",
    padding: 20,
    width: 700,
    justifyContent: "space-between",
  },
  lessonContainer: {
    width: 780,
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
  },
  langContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    position: "relative",
    width: 30,
    height: 20,
    backgroundColor: "blue",
    borderRadius: 10,
    backgroundColor: "#f4f4f4",
  },
};
