import { defineMessages } from "react-intl";

export const translations = defineMessages({
  Title: {
    id: "LoginScreen.Title",
    defaultMessage: "Welcome to Genius Backoffice",
  },
  TitlePart2: {
    id: "LoginScreen.TitlePart2",
    defaultMessage: "",
  },
  TitlePart3: {
    id: "LoginScreen.TitlePart3",
    defaultMessage: "",
  },
  SubTitle: {
    id: "LoginScreen.SubTitle",
    defaultMessage:
      "Empower your team by creating relevant and inspiring contents!",
  },
  EmailInputLabel: {
    id: "LoginScreen.EmailInputLabel",
    defaultMessage: "Email",
  },
  PasswordInputLabel: {
    id: "LoginScreen.PasswordInputLabel",
    defaultMessage: "Password",
  },
  ForgotPasswordText: {
    id: "LoginScreen.ForgotPasswordText",
    defaultMessage: "Forgot your password ?",
  },
  LoginButtonText: {
    id: "LoginScreen.LoginButtonText",
    defaultMessage: "Log In",
  },
});
