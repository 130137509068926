import gql from "graphql-tag";

export const REMOVE_TAG = gql`
  mutation removeTag($pollId: ID, $lang: LangEnum, $tagIds: [ID!]) {
    admin {
      poll(pollId: $pollId, lang: $lang) {
        tags {
          remove(tagIds: $tagIds)
        }
      }
    }
  }
`;
