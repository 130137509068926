import uuidV4 from "uuid/dist/v4";
import { EmptyPollPageTemplate } from "../../utils/ui-generator/poll.template";
import { cleanUpAndEncodeText, decodeNewLines } from "../../utils/string";

export const PollDocumentQuestionChoiceToPollItemQuestionChoice = ({
  id,
  answer,
}) => ({
  id: id || uuidV4(),
  text: decodeNewLines(answer),
});

export const PollDocumentQuestionToPollItemQuestion = (
  pollDocumentQuestion,
) => {
  const { id, type, text, image, description, answers, timer } =
    pollDocumentQuestion;

  return {
    timer,
    id: id || uuidV4(),
    type,
    title: decodeNewLines(text),
    image,
    description: decodeNewLines(description),
    choices: answers.map(PollDocumentQuestionChoiceToPollItemQuestionChoice),
    originalData: pollDocumentQuestion,
  };
};

export const PollDocumentToPollItem = (pollDocument) => {
  const editedPageJSONData = JSON.parse(pollDocument.pages[0].data).data;

  return {
    editedPageId: pollDocument.pages[0].pageId,
    editedPageJSONData,
    questions: editedPageJSONData.questions.map(
      PollDocumentQuestionToPollItemQuestion,
    ),
  };
};

export const PollItemQuestionChoiceToPollDocumentQuestionChoice = ({
  id,
  text,
}) => ({
  id: id || uuidV4(),
  answer: cleanUpAndEncodeText(text),
});

export const PollItemQuestionToPollDocumentQuestion = ({
  id,
  type,
  title,
  image,
  description,
  choices,
  timer,
  originalData,
}) => ({
  ...originalData,
  id,
  type,
  text: cleanUpAndEncodeText(title),
  image,
  description: cleanUpAndEncodeText(description),
  answers: choices.map(PollItemQuestionChoiceToPollDocumentQuestionChoice),
  timer,
});

export const PollItemToPollDocument = (pollItem) =>
  JSON.stringify({
    ...EmptyPollPageTemplate,
    data: {
      ...EmptyPollPageTemplate.data,
      questions: pollItem.questions.map(PollItemQuestionToPollDocumentQuestion),
    },
  });
