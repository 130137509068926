import { InstagramLogo } from "assets/icons";

export const ENV = {
  IS_DEV: process.env.NODE_ENV !== "production",
  IS_TEST: process.env.NODE_ENV !== "test",
};

export const DELAY = 2000;

// Queries
export const DOC_WORKFLOW_ENUM = {
  DRAFT: "DRAFT",
  PUBLISHED: "PUBLISHED",
  ARCHIVED: "ARCHIVED",
  DELETED: "DELETED",
  REJECTED: "REJECTED",
  SCHEDULED: "SCHEDULED",
  UNPUBLISHED: "UNPUBLISHED",
};

export const CONTROL_TYPES = {
  TITLE: "title",
  SUBTITLE: "subTitle",
  REGULAR: "regular",
  IMAGE: "image",
  VIDEO: "video",
  LINK: "link",
};

export const IMAGE_CONTROL_POSITION = {
  BOXED: "boxed",
  FULLWIDTH: "fullWidth",
  FULLSCREEN: "fullScreen",
};

export const VIDEO_CONTROL_POSITION = {
  BOXED: "boxed",
  FULLWIDTH: "fullWidth",
  FULLSCREEN: "videoFullScreen",
};

export const PAGE_TYPES = {
  SUMMARY: "summary",
  SUBTITLE: "subTitle",
  PICCOVER: "picCover",
  PICTHUMB: "picThumb",
  PICCARD: "picCard",
  COPYRIGHT: "copyright",
  VIDEOFULLSCREEN: "videoFullScreen",
  ARTICLE: "article",
  FLASHCARD: "flashCard",
  PEANUT: "peanut",
  AMENDEDQUESTION: "amendedQuestion",
  SEARCHABLE: "searchable",
  ANSWER: "answer",
  COMMENT: "comment",
  MADLIB: "madLib",
  DRAGANDDROP: "dragAndDrop",
  VIDEOQUIZZ: "videoQuizz",
  FEEDQUIZZ: "feedQuizz",
  LESSONRECAP: "lessonRecap",
  INSTAGRAM: "instagram",
  SIMPLEQUIZZ: "simpleQuizz",
  VIDEOCOVER: "videoCover",
  PICLESSONRECAP: "picLessonRecap",
  SLIDERFULLSCREEN: "sliderFullScreen",
  FULLSCREENSCROLL: "fullScreenScroll",
  IMAGEFULLSCREEN: "ImageFullScreenTemplate",
  BATTLE_QUIZ: "battleQuiz",
  POLL: "poll",
  NINJA: "ninja",
};

export const PAGE_TEMPLATES = {
  ARTICLE_TEMPLATE: "ArticleTemplate",
  SLIDERFULLSCREEN_TEMPLATE: "SliderFullScreenTemplate",
  FULLSCREENSCROLL_TEMPLATE: "FullScreenScrollTemplate",
  SIMPLE_QUIZ_TEMPLATE: "SimpleQuizzTemplate",
  MADLIB_TEMPLATE: "MadLibTemplate",
  DRAG_AND_DROP_TEMPLATE: "DragDropTemplate",
  NINJA_TEMPLATE: "NinjaTemplate",
};

export const VISIBLE_TAG_SELECTOR = {
  LANGUAGE: 1,
  ASSOCIATEDTAGS: 2,
  COUNTRIES: 3,
  USERCATEGORY: 4,
};

export const DATE_SELECTOR_TYPE = {
  NOW: 1,
  TOMORROW: 2,
  SCHEDULE: 3,
};

export const PEANUT_CARD_TYPES = {
  WITH_TEXT: "with_text",
  WITHOUT_TEXT: "without_text",
  WITH_LEGEND: "with_legend",
  WITH_TOP_TEXT: "flex-start",
  WITH_MIDDLE_TEXT: "center",
  WITH_BOTTOM_TEXT: "flex-end",
};

export const EMPTY_CONTENT = "EMPTY CONTENT";

export const DOCUMENT_TYPES = {
  INSIGHT: "insight",
  CARD: "card",
  BATTLE: "battle",
  POLL: "poll",
};

export const VIDEO_FORMAT = {
  PORTRAIT: "portrait",
  LANDSCAPE: "paysage",
};

export const FILE_TYPES = {
  VIDEO: "video",
  IMAGE: "image",
  UNKNOWN: "UNKNOWN",
};

export const CROP_VARIATION = {
  PROFILE: {
    width: 50,
    height: 50,
  },
  SMALL_THUMB: {
    width: 50,
    height: 50,
  },
  THUMB: {
    width: 180,
    height: 250,
  },
  CARD: {
    width: 400,
    height: 400,
  },
  BACKGROUND: {
    width: 414,
    height: 736,
  },
  WIDTH_ONLY: {
    width: 414,
    height: "auto",
  },
  HEIGHT_ONLY: {
    width: "auto",
    height: 736,
  },
  PUBLISH_CARD: {
    width: 306,
    height: 391,
  },
  PEANUT: {
    width: 384,
    height: 487,
  },
  IMAGE_FULL_WIDTH: {
    width: 414,
    height: 312,
  },
  MAKER_IMAGE: {
    width: 420,
    height: 270,
  },
  IMAGE_BOXED: {
    width: 374,
    height: 276,
  },
  IMAGE_FULL_SCREEN: {
    width: 414,
    height: 735,
  },
  VIDEO_FULL_WIDTH: {
    width: 414,
    height: 233,
  },
  VIDEO_BOXED: {
    width: 374,
    height: 210,
  },
  SIMPLE_QUIZ_COVER: {
    width: 414,
    height: 220,
  },
  DRAG_AND_DROP_ACTIVITY_IMAGE: {
    width: 110,
    height: 110,
  },
  SLIDE_MARKER: {
    width: 140,
    height: 90,
  },
  LESSON_RECAP_PICTURE: {
    width: 171,
    height: 120,
  },
  EVENT_PHOTO: {
    width: 450,
    height: 300,
  },
  MEDIA: {
    width: 400,
    height: 400,
  },
  BATTLE: {
    width: 374,
    height: 255,
  },
  POLL: {
    width: 374,
    height: 255,
  },
  PDF: {
    width: 300,
    height: 300,
  },
};

export const DEGREE_PARTS = {
  DEGREE: "degree",
  PROGRAM: "program",
  LESSON: "lesson",
};

/**
 * Valid video formats
 * */
export const VIDEO_VALID_FORMATS = [".mp4", ".mov", ".mkv"];

export const DOCUMENT_WORKFLOW_IDS = {
  DRAFT: 1,
  PUBLISHED: 2,
  MODIFIED: 3,
  ARCHIVED: 4,
  DELETED: 5,
  REJECTED: 6,
  UNPUBLISHED: 7,
};

export const DOCUMENT_VIEW_TYPES = {
  GRID: "GRID",
  LIST: "LIST",
};

export const CARD_TYPES = {
  PEANUT: "peanut",
  INSTAGRAM: "instagram",
  QUICK_QUIZ: "feedQuizz",
  TWITTER: "twitter",
};

export const CARD_TEMPLATES = {
  PEANUT_TEMPLATE: "PeanutTemplate",
  INSTAGRAM_TEMPLATE: "InstagramTemplate",
  TWITTER_TEMPLATE: "TwitterTemplate",
};

export const IMAGE_CONTROL_CROP_VARIATIONS = {
  [IMAGE_CONTROL_POSITION.FULLWIDTH]: CROP_VARIATION.IMAGE_FULL_WIDTH,
  [IMAGE_CONTROL_POSITION.BOXED]: CROP_VARIATION.IMAGE_BOXED,
  [IMAGE_CONTROL_POSITION.FULLSCREEN]: CROP_VARIATION.IMAGE_FULL_SCREEN,
};

export const VIDEO_CONTROL_CROP_VARIATIONS = {
  [VIDEO_CONTROL_POSITION.FULLWIDTH]: CROP_VARIATION.VIDEO_FULL_WIDTH,
  [VIDEO_CONTROL_POSITION.BOXED]: CROP_VARIATION.VIDEO_BOXED,
};

export const STATUS_ENUM = {
  ACTIVE: "ACTIVE",
  UNDEFINED: "UNDEFINED",
  INACTIVE: "INACTIVE",
  DRAFT: "DRAFT",
  DUPLICATE: "DUPLICATE",
  ARCHIVED: "ARCHIVED",
  REJECT: "REJECT",
};

export const OBJECT_ACTION_ENUM = {
  SET: "set",
  REMOVE: "remove",
};

export const UNIT_TYPE_ENUM = {
  NO_UNIT: "noUnit",
  METER: "meter",
  SQ_METER: "sqMeter",
};

export const VMPIC_OBJECT_TYPE_ENUM = {
  VMPIC: "vmPic",
};

export const EVENT_TYPE_OBJECT_ENUM = {
  NO_EVENT_TYPE: "noEventType",
  RELOCATION_OR_EXPANSION: "relocationOrExpansion",
  RENOVATION: "renovation",
  PREDICTIVE_DATA: "predictiveData",
  CLOSING: "closing",
  CAPSULES: "capsules",
  SPECIAL_PROJECTS: "specialProjects",
  POP_INS: "popIns",
  POP_UPS: "popsUps",
  OUTSIDE_VENUES: "outsideVenues",
  OPENINGS: "openings",
  TEMP_STORES: "tempStores",
  CONCEPT_STORES: "conceptStores",
  GENERAL_EVENTS: "generalEvents",
  GENERAL_EVENT: "generalEvent",
  COLLECTIONS_IN_STORE: "collectionsInStore",
  WINDOW_DISPLAYS: "windowDisplays",
  OUTSIDE_VENUE: "outsideVenue",
  VILLE: "villa",
  VILLA_HIGH_JEWELRY: "villaHighJewelry",
  PRESS_PRESENTATION: "pressPresentation",
  UBER_LUXURY_HIGH_JEWELRY: "uberLuxuryHighJewelry",
  SHOWROOM: "showroom",
  BABY_SHOWER: "babyShower",
  PREVIEW: "preview",
  OTO: "oto",
  CHASSE_AUX_OEUFS: "chasseAuxOeufs",
  KIDS_PARTY: "kidsParty",
  POP_IN_CAPSULE_LAUNCH: "popInCapsuleLaunch",
  POP_INS_JEWELRY: "popInsJewelry",
  POPS_UPS_JEWELRY: "popsUpsJewelry",
  UBER_LUXURY: "uberLuxury",
  STAYING_CLASS: "stayingClass",
};

export const JOURNALIST_EVENT_TYPE_OBJECT_ENUM = {
  NO_EVENT_TYPE: "noEventType",
  JOURNALIST: "journalist",
  INTERNATIONAL_EVENT: "internationalEvent",
  ECOLE_EVENT: "ecoleEvent",
  EXTERNAL_EVENT: "externalEvent",
  LOCAL_EVENT: "localEvent",
};

export const BOUTIQUE_TYPE_OBJECT_ENUM = {
  store: {
    value: "store",
    label: "Store",
  },
  conceptStore: {
    value: "conceptStore",
    label: "Concept store",
  },
  popUp: {
    value: "popUp",
    label: "Pop-up",
  },
  themeStore: {
    value: "themeStore",
    label: "Temporary store",
  },
  other: {
    value: "other",
    label: "Other",
  },
  showroom: {
    value: "wholesale",
    label: "Showroom",
  },
  wholesale: {
    value: "wholesale",
    label: "Wholesale",
  },
};

export const LOCATION_OBJECT_USER_TYPE_ENUM = {
  WORKING_AT: "workingAt",
  RELATED_TO: "relatedTo",
};

export const USER_TYPES = {
  TRAINER: "trainer",
  TRAINEE: "trainee",
};

export const SHOP_AREA_TYPES = {
  menSurfaceDetail: {
    label: "Men",
    value: "menSurfaceDetail",
  },
  womenSurfaceDetail: {
    label: "Women",
    value: "womenSurfaceDetail",
  },
  homeSurfaceDetail: {
    label: "Home",
    value: "homeSurfaceDetail",
  },
  babySurfaceDetail: {
    label: "Baby",
    value: "babySurfaceDetail",
  },
  othersSurfaceDetail: {
    label: "Other",
    value: "othersSurfaceDetail",
  },
};

export const USER_ROLE_NAMES = {
  ADMIN: "admin",
  TRAINER: "trainer",
  HQ: "HQ",
};

export const NOTION_TYPE_ENUM = {
  USER: "User",
  TAG: "Tag",
  INVITE: "Invite",
  ASK: "Ask",
  DOC: "Doc",
  NONE: "none",
  LOCATION_FLOOR: "LocationFloor",
  EVENT: "Event",
  LOCATION: "Location",
  SETUP: "Setup",
  VM_PIC: "VmPic",
};

export const MOCK_TYPE_ENUM = {
  FILTER: "filter",
  CREATE: "create",
  DISPLAY: "display",
};

export const BATTLE_QUESTION_TYPE_ENUM = {
  SIMPLE: "simple",
  LONG: "long",
  PICTURE: "picture",
};

export const POLL_QUESTION_TYPE_ENUM = {
  SIMPLE: "simple",
  LONG: "long",
  PICTURE: "picture",
};

export const NINJA_QUESTION_TYPE_ENUM = {
  NOIMAGE: "no image",
  COVER: "cover",
  BACKGROUND: "background",
};

export const NINJA_QUESTION_IMAGE_TYPE_ENUM = {
  COVER: "cover",
  BACKGROUND: "background",
};

export const SOCIAL_MEDIA_TYPES = {
  facebookAccount: {
    name: "facebookAccount",
    logo: "",
  },
  instagramAccount: {
    name: "instagramAccount",
    logo: InstagramLogo,
  },
  twitterAccount: {
    name: "twitterAccount",
    logo: "",
  },
};

export const SORT_DIRECTION_ENUM = {
  ASC: "asc",
  DESC: "desc",
};

export const VM_PIC_SORTABLE_FIELDS_ENUM = {
  picFileName: "picFileName",
  picDescription: "picDescription",
  urlOriginal: "urlOriginal",
  urlAmended: "urlAmended",
  ocId: "ocId",
  title: "title",
  eventDateStart: "eventDateStart",
  updateDate: "updateDate",
};

export const CLUSTER_DEEPNESS_IDS = {
  ZONE: 4,
  COUNTRY: 1,
  CITY: 2,
};

export const ADD_TO_MARKET_CONTEXT = {
  DEGREE: "degree",
  LESSON: "lesson",
};

export const DOCUMENT_LEVEL = {
  NORMAL: "normal",
  MASTER: "master",
};

export const STATUS_BUTTON_VARIATIONS = {
  COMPLETE: "complete",
  INCOMPLETE: "incomplete",
  INACTIVE: "inactive",
};

export const AGGREGATED_KPI_TYPE_ENUM = {
  YESTERDAY: "yesterday",
  LAST_WEEK: "lastWeek",
  LAST_MONTH: "lastMonth",
  LAST_THREE_MONTHS: "lastThreeMonths",
  LAST_SIX_MONTHS: "lastSixMonths",
  LAST_NINE_MONTHS: "lastNinceMonths",
  LAST_YEAR: "lastYear",
  YEAR_TO_DATE: "yearToDate",
};
