import { REACT_APP_CLIENT_NAME } from "../../../configs/client";
import {
  shiseidoFilterArrayToExport,
  defaultFilterArrayToExport,
} from "./filterEnvironments";

export const filterExportArray = (data, intl) => {
  switch (REACT_APP_CLIENT_NAME) {
    case "SHISEIDO":
    case "SHISEIDO-PREPROD":
    case "SHISEIDO-PROD":
      return shiseidoFilterArrayToExport(data, intl);
    default:
      return defaultFilterArrayToExport(data, intl);
  }
};
