import * as constants from "../../constants/store/cards";
import client from "../../graphql/client";
import { SetErrorMessage } from "../notification/actions";
import { GET_CARD_BY_ID } from "../../graphql/remote/cards/queries";
import clientConfig, {
  CONFIG_CLUSTER,
  isShiseido,
  TAG_TYPE,
} from "../../configs/client";
import {
  CARD_TEMPLATES,
  DOC_WORKFLOW_ENUM,
  PEANUT_CARD_TYPES,
} from "../../configs/constants";
import { PeanutDefaultImage1 } from "assets/icons";
import { QuickQuizTemplate } from "../../utils/ui-generator/quickQuiz.template";
import {
  CARD_ADD_TAGS,
  CARD_REMOVE_PRIVACY,
  CARD_REMOVE_TAGS,
  CARD_SET_COVER_IMAGE,
  CARD_SET_PRIVACY,
  CARD_SET_TITLE,
  CARD_UPDATE_WORKFLOW,
} from "../../graphql/remote/cards/mutations";
import { GET_TAGS_BY_CLUSTER_ID } from "../../graphql/remote/referential/queries";
import { TAG_CREATE } from "../../graphql/remote/tags/mutations";
import { GetTagsForClusters } from "../../utils/dataTransformation/tags";
import { cleanUpAndEncodeText } from "../../utils/string";

export const SetCurrentCardLanguage = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_CARD_LANGUAGE,
    data: data,
  });
};

export const SetCurrentCardPageId = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_CARD_PAGEID,
    data: data,
  });
};

export const SetCurrentCardId = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_CARD_ID,
    data: data,
  });
};

export const SetCards = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CARDS,
    data: data,
  });
};

export const SetCurrentActiveSlide = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_ACTIVE_SLIDE,
    data: data,
  });
};

export const SetCardLanguages = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CARD_LANGUAGES,
    data: data,
  });
};

export const SetPublishData = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_PUBLISH_DATA,
    data: data,
  });
};

export const UpdateCardStatus =
  (cardId, lang, status, startDate, endDate, onCompletedHandler) => () => {
    client.mutate({
      mutation: CARD_UPDATE_WORKFLOW,
      variables: {
        cardId: cardId,
        lang: lang,
        status: status,
        dateIn: startDate,
        dateOut: endDate,
      },
      update: () => onCompletedHandler(),
    });
  };

export const UpdateMasterCardStatus = () => (dispatch, getState) => {
  const { currentCardId, currentLanguage, publishData } = getState().cards;
  const status =
    publishData.workflow === DOC_WORKFLOW_ENUM.DRAFT
      ? DOC_WORKFLOW_ENUM.PUBLISHED
      : DOC_WORKFLOW_ENUM.DRAFT;

  client.mutate({
    mutation: CARD_UPDATE_WORKFLOW,
    variables: {
      cardId: currentCardId,
      lang: currentLanguage,
      status: status,
    },
    update: () =>
      dispatch(SetPublishData({ ...publishData, workflow: status })),
  });
};

export const RemoveCardTags =
  (cardId, lang, tagIdList, onCompletedHandler) => () => {
    client.mutate({
      mutation: CARD_REMOVE_TAGS,
      variables: {
        cardId: cardId,
        lang: lang,
        tagIdList: tagIdList,
      },
      update: () => onCompletedHandler(),
    });
  };

export const AddCardTags =
  (cardId, lang, tagIdList, onCompletedHandler) => () => {
    client.mutate({
      mutation: CARD_ADD_TAGS,
      variables: {
        cardId: cardId,
        lang: lang,
        tagIdList: tagIdList,
      },
      update: (dataProxy, result) =>
        onCompletedHandler(result.data.admin.card.tags.add),
    });
  };

export const UpdateCardTags =
  (
    tagList,
    publishData,
    id,
    language,
    tagType,
    removeZoneRelatedCountries = false,
  ) =>
  (dispatch) => {
    const beforeTagIds = publishData[tagType].map((x) => x.Id);
    const afterTagIds = tagList.map((x) => x.Id);
    let removeTagIds = beforeTagIds.filter(
      (beforeTagId) => !afterTagIds.includes(beforeTagId),
    );
    const newTagIds = afterTagIds.filter(
      (afterTagId) => !beforeTagIds.includes(afterTagId),
    );

    const updatedCountries = [];
    if (
      removeTagIds.length &&
      removeZoneRelatedCountries &&
      tagType === TAG_TYPE.ZONE
    ) {
      removeTagIds = removeTagIds.concat(
        // also remove countries belonging to removed zones
        publishData[TAG_TYPE.COUNTRY]
          .filter((countryTag) => {
            const countryIsRemoved = removeTagIds.includes(
              countryTag.originalTagData.parentTag.tagId,
            );
            if (!countryIsRemoved) updatedCountries.push(countryTag);

            return countryIsRemoved;
          })
          .map((x) => x.Id),
      );
    }

    if (publishData.tagsIsEditable) {
      const updateData = {
        ...publishData,
        [tagType]: tagList,
        tagsIsEditable: false,
      };
      if (updatedCountries.length)
        updateData[TAG_TYPE.COUNTRY] = updatedCountries;
      dispatch(SetPublishData(updateData));
      const updateCallback = (success) => {
        const updateData = { ...publishData, tagsIsEditable: true };
        if (success) {
          // if update was successful keep selected tags, else roll back
          updateData[tagType] = tagList;
          if (updatedCountries.length)
            updateData[TAG_TYPE.COUNTRY] = updatedCountries;
        }
        dispatch(SetPublishData(updateData));
      };
      if (removeTagIds.length) {
        dispatch(
          RemoveCardTags(id, language, removeTagIds, () => {
            if (newTagIds.length) {
              dispatch(AddCardTags(id, language, newTagIds, updateCallback));
            } else {
              updateCallback(true);
            }
          }),
        );
      } else if (newTagIds.length) {
        dispatch(AddCardTags(id, language, newTagIds, updateCallback));
      }
    }
  };

export const UpdateCardTitle = () => (dispatch, getState) => {
  const { currentCardId, currentLanguage, publishData } = getState().cards;
  client.mutate({
    mutation: CARD_SET_TITLE,
    variables: {
      title: cleanUpAndEncodeText(publishData.title),
      cardId: currentCardId,
      lang: currentLanguage,
    },
  });
};

export const SetCardPrivacy = (cardId, lang, onSuccess) => () => {
  client.mutate({
    mutation: CARD_SET_PRIVACY,
    variables: {
      cardId: cardId,
      lang: lang,
    },
    update: () => onSuccess(),
  });
};

export const RemoveCardPrivacy = (cardId, lang, onSuccess) => () => {
  client.mutate({
    mutation: CARD_REMOVE_PRIVACY,
    variables: {
      cardId: cardId,
      lang: lang,
    },
    update: () => onSuccess(),
  });
};

export const CreateCardTag =
  (tag, onSuccess = () => null) =>
  () => {
    client.mutate({
      mutation: TAG_CREATE,
      variables: { tag },
      update: (cache, data) => onSuccess(data),
      refetchQueries: (_fetchResult) => [
        {
          query: GET_TAGS_BY_CLUSTER_ID,
          variables: { clusterIds: clientConfig[CONFIG_CLUSTER.EXPERTISE] },
        },
      ],
    });
  };

export const ResetCardsStore = (_data) => (dispatch) => {
  dispatch({ type: constants.RESET_CARDS_STORE });
};

const GetCardType = (card) => {
  if (
    card.title === null &&
    card.description === null &&
    card.header.legend === null
  ) {
    return PEANUT_CARD_TYPES.WITHOUT_TEXT;
  }
  if (
    (card.title !== null || card.description !== null) &&
    card.header.legend === null
  ) {
    if (card.position) return card.position;
    return PEANUT_CARD_TYPES.WITH_TEXT;
  }
  if (
    card.header.legend !== null &&
    card.title === null &&
    card.description === null
  ) {
    return PEANUT_CARD_TYPES.WITH_LEGEND;
  }
};

const formatPeanutCardData = (peanutCardData) => {
  const dataObject = JSON.parse(peanutCardData);
  const cardsArray =
    dataObject.data && dataObject.data.cards ? dataObject.data.cards : [];
  if (cardsArray !== undefined && cardsArray.length > 0) {
    return cardsArray.map((x) => ({
      title: x.title,
      description: x.description,
      image: x.header.url,
      legend: x.header.legend,
      defaultImage: PeanutDefaultImage1,
      cardType: GetCardType(x),
    }));
  } else {
    return [
      {
        title: "",
        description: "",
        image: "",
        defaultImage: PeanutDefaultImage1,
        legend: "",
        cardType: PEANUT_CARD_TYPES.WITHOUT_TEXT,
      },
    ];
  }
};

const formatInstagramCardData = (instagramCardData) => {
  const dataObject = JSON.parse(instagramCardData);
  const mediaType = dataObject.data.media_type;
  return [
    {
      mediaType: mediaType,
      image: mediaType === "image" ? dataObject.data.content[0].url : undefined,
      video: mediaType === "video" ? dataObject.data.content[0].url : undefined,
      description: dataObject.data.description,
      likes: dataObject.data.like,
      profileName: dataObject.data.profile.profileName,
      profileUrl: dataObject.data.profile.url,
      instagramLink: dataObject.data.instagramlink,
    },
  ];
};

const formatTwitterCardData = (twitterCardData) => {
  const dataObject = JSON.parse(twitterCardData);
  let image = "";
  let video = "";
  let mediaType = "image";
  if (dataObject.data.content.length) {
    mediaType = dataObject.data.content[0].type;
    image =
      dataObject.data.content[0].type === "image"
        ? dataObject.data.content[0].url
        : undefined;
    video =
      dataObject.data.content[0].type === "video"
        ? dataObject.data.content[0].url
        : undefined;
  }
  return [
    {
      image,
      video,
      description: dataObject.data.description,
      likes: dataObject.data.like,
      profileName: dataObject.data.profile.userName,
      profileUrl: dataObject.data.profile.url,
      twitterLink: dataObject.data.twitterLink,
      mediaType,
    },
  ];
};

const GetDecodedCardsArray = (currentCard) => {
  switch (currentCard.template) {
    case CARD_TEMPLATES.INSTAGRAM_TEMPLATE:
      return formatInstagramCardData(currentCard.data);
    case CARD_TEMPLATES.PEANUT_TEMPLATE:
      return formatPeanutCardData(currentCard.data);
    case CARD_TEMPLATES.TWITTER_TEMPLATE:
      return formatTwitterCardData(currentCard.data);
    default:
      return [];
  }
};

export const GetCardById = (cardId, lang) => async (dispatch) => {
  try {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_CARD_BY_ID,
      variables: { cardId: cardId },
    });

    const currentCardData = result.data.admin.cards[0].versions.find(
      (x) => x.lang === lang,
    );
    const languages = result.data.admin.cards[0].versions.map((x) => x.lang);
    const formattedArrayCards = currentCardData.card
      ? GetDecodedCardsArray(currentCardData.card)
      : [];
    const pageId = currentCardData.card ? currentCardData.card.pageId : null;

    const newTags = GetTagsForClusters(
      clientConfig[CONFIG_CLUSTER.EXPERTISE],
      currentCardData.tags,
    );
    const newZones = GetTagsForClusters(
      clientConfig[CONFIG_CLUSTER.ZONE],
      currentCardData.tags,
    );
    let newCountries;
    if (isShiseido) {
      newCountries = GetTagsForClusters(
        clientConfig[CONFIG_CLUSTER.COUNTRY],
        currentCardData.tags,
      );
    } else {
      newCountries = GetTagsForClusters(
        newZones.map(({ Id }) => parseInt(Id)),
        currentCardData.tags,
        true,
      );
    }
    const newUserCategories = GetTagsForClusters(
      clientConfig[CONFIG_CLUSTER.USER_CATEGORY],
      currentCardData.tags,
    );
    const newBrands = GetTagsForClusters(
      clientConfig[CONFIG_CLUSTER.BRAND],
      currentCardData.tags,
    );
    const newRetailers = GetTagsForClusters(
      clientConfig[CONFIG_CLUSTER.RETAILER],
      currentCardData.tags,
    );
    const newEntities = GetTagsForClusters(
      clientConfig[CONFIG_CLUSTER.ENTITY],
      currentCardData.tags,
    );
    const newDivisions = GetTagsForClusters(
      clientConfig[CONFIG_CLUSTER.DIVISION],
      currentCardData.tags,
    );
    const newProfessions = GetTagsForClusters(
      clientConfig[CONFIG_CLUSTER.PROFESSION],
      currentCardData.tags,
    );
    const newRoles = GetTagsForClusters(
      clientConfig[CONFIG_CLUSTER.ROLE],
      currentCardData.tags,
    );

    if (
      currentCardData.tags.findIndex(
        (x) => parseInt(x.tagId) === clientConfig.MASTER_DEGREE_TAG_ID,
      ) >= 0
    ) {
      dispatch(SetCardIsMasterFlag(true));
    } else {
      dispatch(SetCardIsMasterFlag(false));
    }

    let publishDataImage = currentCardData.picCover;
    if (!publishDataImage) {
      if (
        currentCardData.card &&
        currentCardData.card.template === QuickQuizTemplate.template
      ) {
        const data = JSON.parse(currentCardData.card.data).data;
        publishDataImage =
          data && data.image && data.image.url ? data.image.url : null;
      } else if (formattedArrayCards.length) {
        const firstCardWithImage = formattedArrayCards.find(
          ({ image }) => image,
        );
        publishDataImage = firstCardWithImage ? firstCardWithImage.image : null;
      }

      // save the publish data image if it's not saved yet
      if (publishDataImage) {
        client.mutate({
          mutation: CARD_SET_COVER_IMAGE,
          variables: {
            image: publishDataImage,
            cardId: cardId,
            lang: currentCardData.lang,
          },
        });
      }
    }

    const publishData = {
      title: currentCardData.title,
      summary: currentCardData.summary,
      tags: newTags,
      countries: newCountries,
      userCategories: newUserCategories,
      brands: newBrands,
      retailers: newRetailers,
      zones: newZones,
      entities: newEntities,
      divisions: newDivisions,
      professions: newProfessions,
      roles: newRoles,
      startPostingDate: currentCardData.workflowScheduledIn,
      endPostingDate: currentCardData.workflowScheduledOut,
      tagsIsEditable: true,
      isPublic: !currentCardData.isPrivate,
      image: publishDataImage,
      workflow: currentCardData.workflow,
    };

    dispatch(SetCards(formattedArrayCards));
    dispatch(SetPublishData(publishData));
    dispatch(SetCurrentCardPageId(pageId));
    dispatch(SetCardLanguages(languages));
  } catch (e) {
    dispatch(
      SetErrorMessage("An error occurred while fetching the card data", e),
    );
  }
};

export const SetCardIsMasterFlag = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CARD_IS_MASTER_FLAG,
    data: data,
  });
};
