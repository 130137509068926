export default (theme) => ({
  choice: {
    display: "flex !important",
    justifyContent: "space-between",
    "& span.icon-recycle": {
      fontSize: 14,
      color: ({ isBGDark }) =>
        isBGDark
          ? theme.PollQuestionChoiceEditor.darkChoice
          : theme.PollQuestionChoiceEditor.notDarkChoice,
      marginLeft: 20,
      marginRight: 10,
    },
  },
  choiceInput: {
    fontSize: 18,
    minHeight: 21,
    maxHeight: 45,
    marginBottom: 20,
    overflow: "hidden",
    outline: "unset",
    color: ({ isBGDark }) =>
      isBGDark
        ? theme.PollQuestionChoiceEditor.darkChoiceInput
        : theme.PollQuestionChoiceEditor.notDarkChoiceInput,
    "&[contenteditable]:empty:before": {
      content: "attr(placeholder)",
      display: "block",
    },
  },
  addNewAnswerChoice: {
    fontFamily: theme.FontFamily,
    fontSize: "16px !important",
    fontWeight: "normal !important",
    fontStyle: "normal !important",
    fontStretch: "condensed !important",
    lineHeight: "normal !important",
    letterSpacing: "normal !important",
    textDecoration: "underline",
    textDecorationColor: ({ isBGDark }) =>
      isBGDark
        ? theme.PollQuestionChoiceEditor.darkAddNewAnswerChoice
        : theme.PollQuestionChoiceEditor.notDarkdAddNewAnswerChoice,
    "& > span": {
      color: ({ isBGDark }) =>
        isBGDark
          ? theme.PollQuestionChoiceEditor.darkAddNewAnswerChoice
          : theme.PollQuestionChoiceEditor.notDarkdAddNewAnswerChoice,
    },
  },
});
