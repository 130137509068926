import {
  UPDATE_FILTERS,
  RESET_STORE,
  UPDATE_TEMPLATES,
  SET_EXISTING_DEGREE_MARKET_TAG_IDS,
  SET_SAVE_DEGREE_MARKET_IDS,
  SET_BADGES,
  SET_DEGREE_DETAILS,
  SET_DEGREE_LESSONS,
  SET_BADGE_LESSON_IDS,
  SET_CURRENT_CLONE_CONTEXT_IDS,
  SET_CURRENT_SET_BADGES_CONTEXT,
} from "../../constants/store/masterTemplates";
import { SetFetchIndicator, SetSaveIndicator } from "../common/actions";
import client from "../../graphql/client";
import { SetErrorMessage } from "../notification/actions";
import {
  DEGREES_GET_LIST_BY_STATUSES,
  GET_DEGREE_CLONED_CHILDREN,
  GET_DEGREE_LESSONS,
  GET_DEGREE_SHORT_DETAILS,
} from "../../graphql/remote/degrees/queries";
import { DOC_WORKFLOW_ENUM } from "../../configs/constants";
import {
  getExistingMarketTagIds,
  getFormattedDegreeBadges,
  getFormattedDegreeDetails,
  getFormattedDegreeLessons,
  GetFormattedLesson,
  getFormattedMasterTemplatesData,
} from "./transformers";
import {
  DEGREE_ADD_TAGS,
  DEGREE_CLONE_TO_MARKETS,
  DEGREE_COPY_ALL_FOR_LANGUAGE,
  DEGREE_CREATE,
  DEGREE_UPDATE_WORFLOW,
} from "../../graphql/remote/degrees/mutations";
import clientConfig from "../../configs/client";
import { DEGREE_CLONE } from "../../graphql/remote/degrees/mutations/DegreeClone.graphql";
import { SET_BADGE_TO_LESSONS } from "../../graphql/remote/badges/mutations/SetBadgeToLessons.graphql";
import {
  LESSON_CLONE_TO_MARKET,
  LESSONS_SET_BADGES_AS_IN_MASTER,
} from "../../graphql/remote/lessons/mutations";
import {
  GET_LESSON_CLONED_CHILDREN,
  LESSON_GET_BY_ID,
} from "../../graphql/remote/lessons/queries";
import { PROGRAM_GET_CLONED_CHILDREN } from "../../graphql/remote/programs/queries";
import { PROGRAM_CLONE_TO_MARKET } from "../../graphql/remote/programs/mutations";
import { GET_MARKET_BRAND_BADGES } from "../../graphql/remote/badges/queries/GetMarketBrandBadges.graphql";
import { INSIGHT_CLONE_TO_MARKET } from "../../graphql/remote/insights/mutations";
import { CARD_CLONE_TO_MARKET } from "../../graphql/remote/cards/mutations";
import { BATTLE_CLONE_TO_MARKET } from "../../graphql/remote/battle/mutations/CloneToMarket.graphql";
import { POLL_CLONE_TO_MARKET } from "../../graphql/remote/poll/mutations/CloneToMarket.graphql";
import { GET_CLONED_DOCUMENTS_TAGS } from "../../graphql/remote/documents/queries";

export const UpdateFilters = (type, value) => (dispatch) => {
  dispatch({
    type: UPDATE_FILTERS,
    data: { type, value },
  });
};

export const ResetStore = () => (dispatch) => {
  dispatch({ type: RESET_STORE });
};

export const UpdateTemplates = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_TEMPLATES,
    data,
  });
};

export const GetMasterTemplates = () => async (dispatch, getState) => {
  const { filters } = getState().masterTemplates;
  try {
    dispatch(SetFetchIndicator(true));
    const result = await client.query({
      fetchPolicy: "network-only",
      query: DEGREES_GET_LIST_BY_STATUSES,
      variables: {
        statusPublished: DOC_WORKFLOW_ENUM.PUBLISHED,
        statusDraft: DOC_WORKFLOW_ENUM.DRAFT,
        tagIds: clientConfig.MASTER_DEGREE_TAG_ID
          ? [clientConfig.MASTER_DEGREE_TAG_ID]
          : [],
      },
    });
    dispatch(UpdateTemplates(getFormattedMasterTemplatesData(result, filters)));
  } catch (e) {
    dispatch(
      SetErrorMessage("An error occurred while fetching the master templates"),
    );
  } finally {
    dispatch(SetFetchIndicator(false));
  }
};

export const CreateMasterTemplate =
  (title, onSuccess) => async (dispatch, getState) => {
    const {
      filters: { language },
    } = getState().masterTemplates;
    dispatch(SetSaveIndicator(true));
    await client.mutate({
      mutation: DEGREE_CREATE,
      variables: {
        title: title,
        lang: language.selectedValue,
      },
      update: async (cache, { data }) => {
        const degreeId = data.admin.degree.create.degreeId;
        const lang = language.selectedValue;

        await client.mutate({
          mutation: DEGREE_ADD_TAGS,
          variables: {
            degreeId,
            lang,
            tagIdList: clientConfig.MASTER_DEGREE_TAG_ID || undefined,
          },
          update: (dataProxy, result) => {
            onSuccess(degreeId, lang);
            dispatch(SetSaveIndicator(false));
          },
        });
      },
    });
  };

export const CloneDegree =
  (degreeId, callback) => async (dispatch, getState) => {
    const {
      filters: { language },
    } = getState().masterTemplates;
    const lang = language.selectedValue;

    await client.mutate({
      mutation: DEGREE_CLONE,
      variables: { degreeId, lang },
      update: () => callback && callback(),
    });
  };

export const DeleteDegree = (degreeId, lang, callback) => async () => {
  await client.mutate({
    mutation: DEGREE_UPDATE_WORFLOW,
    variables: { degreeId, lang, status: DOC_WORKFLOW_ENUM.ARCHIVED },
    update: () => callback && callback(),
  });
};

export const TranslateDegree =
  (degreeId, sourceLang, destLang, callback) => async (dispatch, getState) => {
    await client.mutate({
      mutation: DEGREE_COPY_ALL_FOR_LANGUAGE,
      variables: { degreeId, sourceLang, destLang },
      update: () => callback && callback(),
    });
  };

export const GetMasterDegreeTagIds =
  (documentId, userMarketTagIds, callback) => async (dispatch, getState) => {
    const {
      filters: { language },
    } = getState().masterTemplates;
    const degreeId = parseInt(documentId, 10);
    try {
      const result = await client.query({
        fetchPolicy: "network-only",
        query: GET_DEGREE_CLONED_CHILDREN,
        variables: { degreeId },
      });
      dispatch({
        type: SET_EXISTING_DEGREE_MARKET_TAG_IDS,
        data: getExistingMarketTagIds(result.data.admin.documents, language),
      });
      callback && callback();
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while fetching the master template tagIds",
        ),
      );
    }
  };

export const GetMasterLessonTagIds =
  (lessonId, userMarketTagIds, callback) => async (dispatch, getState) => {
    const {
      filters: { language },
    } = getState().masterTemplates;
    try {
      const result = await client.query({
        fetchPolicy: "network-only",
        query: GET_LESSON_CLONED_CHILDREN,
        variables: { lessonId: parseInt(lessonId) },
      });
      dispatch({
        type: SET_EXISTING_DEGREE_MARKET_TAG_IDS,
        data: getExistingMarketTagIds(result.data.admin.documents, language),
      });
      callback && callback();
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while fetching the master lesson tagIds",
        ),
      );
    }
  };

export const AddRemoveDegreeToMarket = (marketId) => async (dispatch) => {
  dispatch({
    type: SET_SAVE_DEGREE_MARKET_IDS,
    data: marketId,
  });
};

export const AddDegreeToMarket =
  (degreeId, callback) => async (dispatch, getState) => {
    const {
      filters: {
        language: { selectedValue: lang },
      },
      saveDegreeMarketTagIds: tagIds,
    } = getState().masterTemplates;

    await client.mutate({
      mutation: DEGREE_CLONE_TO_MARKETS,
      variables: { degreeId, lang, marketTagId: tagIds[0] },
      update: (dataProxy, result) => {
        callback && callback(result.data.admin.degree.cloneDegree);
      },
    });
  };

export const AddLessonToMarket =
  (lessonId, lang, programId, degreeId, tagIds, callback) =>
  async (dispatch, getState) => {
    try {
      // Check if program exists
      const result = await client.query({
        fetchPolicy: "network-only",
        query: PROGRAM_GET_CLONED_CHILDREN,
        variables: {
          programId: parseInt(programId),
          lang: lang,
          tagIds: tagIds,
        },
      });

      let destinationProgramId = 0;
      if (
        result.data.admin.documents &&
        result.data.admin.documents.length > 0
      ) {
        destinationProgramId = parseInt(result.data.admin.documents[0].docId);
      } else {
        // We need to create then new program but first we need the parent destination degree
        const result = await client.query({
          fetchPolicy: "network-only",
          query: GET_DEGREE_CLONED_CHILDREN,
          variables: { degreeId: parseInt(degreeId) },
        });

        const marketDegree = result.data.admin.documents.find(({ versions }) =>
          versions.some(
            ({ lang: langDegree, tags: tagsDegree }) =>
              langDegree === lang &&
              tagsDegree.some(
                ({ tagId: tagIdDegree }) => Number(tagIdDegree) === tagIds[0],
              ),
          ),
        );

        let marketDegreeId = marketDegree ? marketDegree.docId : null;

        if (!marketDegreeId) {
          throw new Error("Missing clone degree");
        }

        const cloneProgramResult = await client.mutate({
          mutation: PROGRAM_CLONE_TO_MARKET,
          variables: {
            programId,
            lang,
            marketTagId: tagIds[0],
            parentLearningId: marketDegreeId,
          },
        });

        destinationProgramId = parseInt(
          cloneProgramResult.data.admin.program.clone[0].programId,
        );
      }

      // now we have the new program and we can do the actual clone to market for a lesson
      const cloneLessonResult = await client.mutate({
        mutation: LESSON_CLONE_TO_MARKET,
        variables: {
          lessonId: parseInt(lessonId),
          lang,
          marketTagId: tagIds[0],
          parentLearningId: destinationProgramId,
        },
      });

      callback &&
        callback(
          cloneLessonResult.data.admin.lesson.clone[0].lessonId,
          degreeId,
        );
    } catch (e) {
      dispatch(
        SetErrorMessage("An error occurred while adding lesson to market", e),
      );
    }
  };

export const SetBadgesAsMaster =
  (degreeId, lang, callback) => async (dispatch, getState) => {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_DEGREE_LESSONS,
      variables: { degreeId },
    });
    const lessonsId = getFormattedDegreeLessons(
      result.data.admin.degrees[0],
      lang,
    ).map((x) => x.id);

    await client.mutate({
      mutation: LESSONS_SET_BADGES_AS_IN_MASTER,
      variables: { lessonsId },
      update: (dataProxy, result) => {
        callback && callback(degreeId);
      },
    });
  };

export const GetMasterDegreeBadges =
  (masterId, language, callback) => async (dispatch, getState) => {
    const { saveDegreeMarketTagIds } = getState().masterTemplates;
    try {
      /*    const result = await client.query({
      fetchPolicy: 'network-only',
      query: GET_DEGREE_BADGES,
      variables: {
        degreeIds: [masterId],
        tagIds: saveDegreeMarketTagIds
      }
    }) */

      let filterBadgesIds = saveDegreeMarketTagIds;

      const masterInfo = await client.query({
        fetchPolicy: "network-only",
        query: GET_DEGREE_SHORT_DETAILS,
        variables: { degreeId: masterId },
      });

      const actualVersion = masterInfo.data.admin.degrees[0].versions.find(
        (version) => version.lang === language,
      );

      filterBadgesIds = [
        ...filterBadgesIds,
        ...actualVersion.tags
          .filter(
            (x) =>
              clientConfig.BRAND_TAGS_CLUSTER_IDS.findIndex(
                (clusterId) => clusterId === parseInt(x.clusterId),
              ) >= 0,
          )
          .map((x) => x.tagId),
      ];

      const degreeBadges = await client.query({
        fetchPolicy: "network-only",
        query: GET_MARKET_BRAND_BADGES,
        variables: { tagIds: filterBadgesIds },
      });

      dispatch({
        type: SET_BADGES,
        data: getFormattedDegreeBadges(degreeBadges.data.admin.badges),
      });
      callback && callback();
    } catch (e) {
      dispatch(
        SetErrorMessage("An error occurred while fetching the degree's badges"),
      );
    }
  };

export const GetDegreeDetails =
  (degreeId, lang, callback) => async (dispatch, getState) => {
    try {
      const result = await client.query({
        fetchPolicy: "network-only",
        query: GET_DEGREE_SHORT_DETAILS,
        variables: { degreeId },
      });
      dispatch({
        type: SET_DEGREE_DETAILS,
        data: getFormattedDegreeDetails(result.data.admin.degrees, lang),
      });
      callback && callback();
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while fetching the degree's details",
          e,
        ),
      );
    }
  };

export const getNewDegreeLessons =
  (degreeId, lang, callback) => async (dispatch) => {
    try {
      const result = await client.query({
        fetchPolicy: "network-only",
        query: GET_DEGREE_LESSONS,
        variables: { degreeId },
      });
      const lessons = getFormattedDegreeLessons(
        result.data.admin.degrees[0],
        lang,
      );
      dispatch({
        type: SET_DEGREE_LESSONS,
        data: lessons,
      });
      callback && callback();
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while fetching the degree's lessons",
          e,
        ),
      );
    }
  };

export const GetNewLessons = (lessonId, lang, callback) => async (dispatch) => {
  try {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: LESSON_GET_BY_ID,
      variables: { lessonId },
    });
    const lessons = GetFormattedLesson(result.data.admin.lessons[0], lang);
    dispatch({
      type: SET_DEGREE_LESSONS,
      data: lessons,
    });
    callback && callback();
  } catch (e) {
    dispatch(
      SetErrorMessage(
        "An error occurred while fetching  lesson information ",
        e,
      ),
    );
  }
};

export const SetBadgeLessonIds = (badgeId, lessonId) => async (dispatch) => {
  dispatch({
    type: SET_BADGE_LESSON_IDS,
    data: { badgeId, lessonId },
  });
};

export const SetBadgesToLessons = (callback) => async (dispatch, getState) => {
  const { badges } = getState().masterTemplates;
  try {
    await Promise.all(
      badges.map((badge) => {
        if (badge.lessonIds.length) {
          client.mutate({
            mutation: SET_BADGE_TO_LESSONS,
            variables: {
              badgeIds: [badge.badgeId],
              lessonIds: badge.lessonIds,
            },
          });
        }
      }),
    ).then(() => {
      callback && callback();
    });
  } catch (e) {
    dispatch(
      SetErrorMessage(
        "An error occurred while making the badge-lesson associations",
        e,
      ),
    );
  }
};

export const SetCurrentCloningIds =
  (degreeId, programId, lessonId) => async (dispatch) => {
    try {
      dispatch({
        type: SET_CURRENT_CLONE_CONTEXT_IDS,
        data: {
          degreeId: degreeId,
          programId: programId,
          lessonId: lessonId,
        },
      });
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while making the badge-lesson associations",
          e,
        ),
      );
    }
  };

export const SetCurrentSetBadgesContext = (context) => async (dispatch) => {
  try {
    dispatch({
      type: SET_CURRENT_SET_BADGES_CONTEXT,
      data: context,
    });
  } catch (e) {
    dispatch(
      SetErrorMessage(
        "An error occurred while making the badge-lesson associations",
        e,
      ),
    );
  }
};

export const AddInsightToMarket =
  (insightId, lang, tagIds, callback) => async (dispatch) => {
    try {
      const cloneResult = await client.mutate({
        mutation: INSIGHT_CLONE_TO_MARKET,
        variables: {
          insightId: parseInt(insightId),
          lang,
          marketTagIds: tagIds,
        },
      });

      callback && callback(cloneResult.data.admin.insight.clone.insightId);
    } catch (e) {
      dispatch(
        SetErrorMessage("An error occurred while adding insight to market", e),
      );
    }
  };

export const AddCardToMarket =
  (cardId, lang, tagIds, callback) => async (dispatch) => {
    try {
      const cloneResult = await client.mutate({
        mutation: CARD_CLONE_TO_MARKET,
        variables: { cardId: parseInt(cardId), lang, marketTagIds: tagIds },
      });

      callback && callback(cloneResult.data.admin.card.clone.cardId);
    } catch (e) {
      dispatch(
        SetErrorMessage("An error occurred while adding card to market", e),
      );
    }
  };

export const AddBattleToMarket =
  (battleId, lang, tagIds, callback) => async (dispatch) => {
    try {
      const cloneResult = await client.mutate({
        mutation: BATTLE_CLONE_TO_MARKET,
        variables: { battleId: parseInt(battleId), lang, marketTagIds: tagIds },
      });

      callback && callback(cloneResult.data.admin.battle.clone.battleId);
    } catch (e) {
      dispatch(
        SetErrorMessage("An error occurred while adding battle to market", e),
      );
    }
  };

export const AddPollToMarket =
  (pollId, lang, tagIds, callback) => async (dispatch) => {
    try {
      const cloneResult = await client.mutate({
        mutation: POLL_CLONE_TO_MARKET,
        variables: { pollId: parseInt(pollId), lang, marketTagIds: tagIds },
      });

      callback && callback(cloneResult.data.admin.poll.clone.pollId);
    } catch (e) {
      dispatch(
        SetErrorMessage("An error occurred while adding poll to market", e),
      );
    }
  };

export const GetMasterDocumentTagIds =
  (documentId, userMarketTagIds, callback) => async (dispatch, getState) => {
    const {
      filters: { language },
    } = getState().masterTemplates;
    try {
      const result = await client.query({
        fetchPolicy: "network-only",
        query: GET_CLONED_DOCUMENTS_TAGS,
        variables: { documentId: parseInt(documentId) },
      });
      dispatch({
        type: SET_EXISTING_DEGREE_MARKET_TAG_IDS,
        data: getExistingMarketTagIds(result.data.admin.documents, language),
      });
      callback && callback();
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while fetching the master document tagIds",
        ),
      );
    }
  };
