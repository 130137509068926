import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { VideoPageEditorStyle } from "../VideoPageEditor.style";
import {
  VIDEO_CONTROL_CROP_VARIATIONS,
  VIDEO_CONTROL_POSITION,
} from "../../../configs/constants";
import Video from "../../common/Video";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(VideoPageEditorStyle);

const VideoPageEditor = (props) => {
  const { type, onVideoUpload, ...restProps } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.videoContainer}>
      <Video
        {...restProps}
        {...VIDEO_CONTROL_CROP_VARIATIONS[type]}
        isReadOnly
      />
    </div>
  );
};

VideoPageEditor.propTypes = {
  /** image variation **/
  type: PropTypes.oneOf([
    VIDEO_CONTROL_POSITION.FULLWIDTH,
    VIDEO_CONTROL_POSITION.BOXED,
  ]).isRequired,
  /** + All the props of VideoSelector except:
   * - onVideoChange
   * - width
   * - height
   * **/
};

export default VideoPageEditor;
