import gql from "graphql-tag";

const GetInsight = gql`
  query GetInsight($id: ID) {
    admin {
      insights(insightId: $id) {
        versions {
          lang
          title
          summary
          workflowScheduledIn
          workflowScheduledOut
          workflow
          tags {
            tagId
            title
            clusterId
            clusterMetaTagId
          }
          picCover
          videoCover
          isPrivate
        }
      }
    }
  }
`;

const GetCard = gql`
  query GetCard($id: ID) {
    admin {
      cards(cardId: $id) {
        versions {
          lang
          title
          workflowScheduledIn
          workflowScheduledOut
          workflow
          tags {
            tagId
            title
            clusterId
            clusterMetaTagId
          }
          picCover
          isPrivate
        }
      }
    }
  }
`;

const GetDegree = gql`
  query GetDegree($id: ID) {
    admin {
      degrees(degreeId: $id) {
        versions {
          lang
          title
          summary
          workflowScheduledIn
          workflowScheduledOut
          workflow
          tags {
            tagId
            title
            clusterId
            clusterMetaTagId
          }
          picCover
          isPrivate
        }
      }
    }
  }
`;

const GetLesson = gql`
  query GetLesson($id: ID) {
    admin {
      lessons(lessonId: $id) {
        versions {
          lang
          title
          summary
          workflowScheduledIn
          workflowScheduledOut
          workflow
          tags {
            tagId
            title
            clusterId
            clusterMetaTagId
          }
          picCover
          picLessonRecap
          lessonRecap
          isPrivate
          objectsCore {
            ocId
          }
          attachment {
            id: uploadId
            link
            name
            type
          }
        }
      }
    }
  }
`;

const GetFile = gql`
  query getFile($fileId: [Int!]) {
    admin {
      files(fileIds: $fileId) {
        fileId
        versions {
          title
          lang
          workflowScheduledIn
          tags {
            title
            tagId
            clusterId
          }
          pages {
            pageId
            type
            data
          }
        }
      }
    }
  }
`;

export const GetDocument = (documentType) => {
  switch (documentType) {
    case "insight":
      return GetInsight;
    case "card":
      return GetCard;
    case "degree":
      return GetDegree;
    case "lesson":
      return GetLesson;
    case "file":
      return GetFile;
    default:
      return null;
  }
};

export const CREATE_DOCUMENT = (documentType) => gql`
mutation Create${documentType}($lang: LangEnum, $title: String!) {
  admin {
    ${documentType}(lang: $lang) {
      create(title: $title) {
        ${documentType}Id
      }
    }
  }
}`;

export const CREATE_DOCUMENT_PAGE = (documentType) => gql`
 mutation Create${documentType}Page($${documentType}Id: ID, $lang: LangEnum, $data: String!, $type: PageTypeEnum!) {
  admin {
    ${documentType}(${documentType}Id: $${documentType}Id, lang: $lang) {
      pages {
        create(type: $type, data: $data) {
          pageId
        }
      }
    }
  }
}`;

export const EDIT_DOCUMENT_FIELD = (documentType, fieldType) => gql`
mutation Edit${documentType}${fieldType}($id: ID, $lang: LangEnum, $data: String!) {
  admin {
    ${documentType}(${documentType}Id: $id, lang: $lang) {
      ${fieldType}(data: $data)
    }
  }
}
`;

export const EDIT_DOCUMENT_PAGE = (documentType) => gql`
mutation Edit${documentType}Page($${documentType}Id: ID, $lang: LangEnum, $pageId: ID!, $data: String!) {
  admin {
    ${documentType}(${documentType}Id: $${documentType}Id, lang: $lang) {
      pages(pageId: $pageId) {
        data(data: $data)
      }
    }
  }
}
`;

export const SWITCH_PRIVACY_DOCUMENT = (documentType, command) => gql`
mutation Edit${documentType}${command}($id: ID, $lang: LangEnum) {
  admin {
    ${documentType}(${documentType}Id: $id, lang: $lang) {
      privacy{
        ${command}
      }
    }
  }
}
`;

const arrayVariables = (array) => {
  const reducer = (accumulator, { name, gqlType }) =>
    `${accumulator}, $${name}: ${gqlType}`;

  return array.reduce(reducer, "");
};

const arrayFields = (array) => {
  const reducer = (accumulator, { type, variableName }) => `${accumulator}
      ${type}(data: $${variableName})`;

  return array.reduce(reducer, "");
};

export const EDIT_DOCUMENT_FIELDS = (documentType, variables, fields) => gql`
mutation Edit${documentType}Fields($id: ID, $lang: LangEnum${arrayVariables(
  variables,
)}) {
  admin {
    ${documentType}(${documentType}Id: $id, lang: $lang) {${arrayFields(fields)}
    }
  }
}
`;

const EDIT_DOCUMENT_TAGS = (documentType, mutationType) => gql`
mutation ${mutationType}${documentType}Tag($id: ID, $lang: LangEnum, $tagIds: [ID!]){
  admin {
    ${documentType}(${documentType}Id: $id, lang: $lang){
        tags {
          ${mutationType}(tagIds: $tagIds)
        }
    }
  }
}
`;

export const ADD_DOCUMENT_TAGS = (documentType) =>
  EDIT_DOCUMENT_TAGS(documentType, "add");

export const DELETE_DOCUMENT_TAGS = (documentType) =>
  EDIT_DOCUMENT_TAGS(documentType, "remove");

export const UPDATE_WORFLOW = (documentType) => gql`
mutation Update${documentType}Workflow($id: ID, $lang: LangEnum, $status: DocWorkflowEnum!, $dateIn: Date, $dateOut: Date) {
  admin {
    ${documentType}(${documentType}Id: $id, lang: $lang) {
      workflow {
        set(status: $status, dateIn: $dateIn, dateOut: $dateOut)
      }
    }
  }
}`;

export const SET_RECAP = (type, recapType) => gql`
mutation set${recapType}($id: ID, $lang: LangEnum, $data: String!) {
  admin {
    ${type}(${type}Id: $id, lang: $lang) {
      set(type: ${recapType}, data: $data)
    }
  }
}
`;

export const GET_LOCATIONS_INFOS = () => gql`
  query getLocationsByIds(
    $limit: Int
    $skip: Int
    $docIds: [ID!]
    $ocIds: [ID!]
  ) {
    Location(
      ocIds: $ocIds
      skip: $skip
      limit: $limit
      docIds: $docIds
      sort: title
      sortDirection: asc
    ) {
      ocId
      title
      picCover
      addressBuilding
      addressCountry {
        tagId
        title
      }
      addressZone {
        tagId
        title
      }
      addressCity {
        tagId
        title
      }
    }
  }
`;
