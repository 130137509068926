import gql from "graphql-tag";

export const LESSON_UPDATE_RECAP_PICTURE = gql`
  mutation lessonUpdateRecapPicture(
    $lessonId: ID
    $lang: LangEnum
    $data: String!
  ) {
    admin {
      lesson(lessonId: $lessonId, lang: $lang) {
        set(type: picLessonRecap, data: $data)
      }
    }
  }
`;
