import { publishBannerHeight } from "../../components/PublishPreviewBanner/PublishPreviewBanner.style";

const pageTopPadding = 30;

export default ({ EditQuickQuizScreen }) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: pageTopPadding + publishBannerHeight,
  },
  closeIcon: {
    position: "absolute",
    left: 20,
    color: EditQuickQuizScreen.closeIconColor,
    "&::before": {
      color: EditQuickQuizScreen.closeIconColor,
      fontSize: 20,
    },
    "&:hover::before": {
      color: EditQuickQuizScreen.closeIconHoverColor,
      cursor: "pointer",
    },
  },
});
