import { defineMessages } from "react-intl";

export const translations = defineMessages({
  ActivityTitle: {
    id: "UserProfilePage.ActivityTitle",
    defaultMessage: "Activity",
  },
  LearningTitle: {
    id: "UserProfilePage.LearningTitle",
    defaultMessage: "Learning",
  },
  BrandsTitle: {
    id: "UserProfilePage.BrandsTitle",
    defaultMessage: "Brands",
  },
  InterestsTitle: {
    id: "UserProfilePage.InterestsTitle",
    defaultMessage: "Interests",
  },
  QuestionAsked: {
    id: "UserProfilePage.QuestionAsked",
    defaultMessage: "Question asked",
  },
  QuestionAskedToExpert: {
    id: "UserProfilePage.QuestionAskedToExpert",
    defaultMessage: "Question asked to expert",
  },
  LessonFinished: {
    id: "UserProfilePage.LessonFinished",
    defaultMessage: "Lesson finished",
  },
  LessonStarted: {
    id: "UserProfilePage.LessonStarted",
    defaultMessage: "Lesson started",
  },
  TotalPointsLabel: {
    id: "UserProfilePage.TotalPointsLabel",
    defaultMessage: "My Fragrance Training points",
  },
  EmailAddressLabel: {
    id: "UserProfilePage.EmailAddressLabel",
    defaultMessage: "Email address",
  },
  PointOfSaleLabel: {
    id: "UserProfilePage.PointOfSaleLabel",
    defaultMessage: "Point of sale",
  },
  BirthDayLabel: {
    id: "UserProfilePage.BirthDayLabel",
    defaultMessage: "Birthday",
  },
  RegistrationDateLabel: {
    id: "UserProfilePage.RegistrationDateLabel",
    defaultMessage: "Registration: {date}",
  },
  MailingAddress: {
    id: "UserProfilePage.MailingAddress",
    defaultMessage: "Mailing address",
  },
  YearsText: {
    id: "UserProfilePage.YearsText",
    defaultMessage: "year(s)",
  },
  MonthsText: {
    id: "UserProfilePage.MonthsText",
    defaultMessage: "month(s)",
  },
  AndText: {
    id: "UserProfilePage.AndText",
    defaultMessage: "and",
  },
});
