import { defineMessages } from "react-intl";

export const translations = defineMessages({
  ScrollDown: {
    id: "VerticalSlider.ScrollDown",
    defaultMessage: "Scroll down",
  },
  UploadImageVideoText: {
    id: "VerticalSlider.UploadImageVideoText",
    defaultMessage: "Upload Image/Video",
  },
  UploadImageText: {
    id: "VerticalSlider.UploadImageText",
    defaultMessage: "Upload Image",
  },
});
