import * as constants from "../../constants/store/degrees";
import client from "../../graphql/client";
import {
  DEGREE_COPY_ALL_FOR_LANGUAGE,
  DEGREE_UPDATE_WORFLOW,
  LESSON_UPDATE_PAGE_DATA,
  REMOVE_DEGREE_BADGES,
  ADD_DEGREE_ACTIVITY_BADGES,
  ADD_DEGREE_READ_BADGES,
} from "../../graphql/remote/degrees/mutations";
import { SetErrorMessage } from "../notification/actions";
import { DOC_WORKFLOW_ENUM, PAGE_TEMPLATES } from "../../configs/constants";
import {
  DEGREES_GET_LIST_BY_STATUSES,
  GET_DEGREE_BY_ID,
  GET_DEGREE_SHORT_DETAILS,
  GET_DEGREE_BADGES,
  GET_DEGREE_BADGES_LESSONS,
} from "../../graphql/remote/degrees/queries";
import { PROGRAM_GET_LESSONS_BY_ID } from "../../graphql/remote/programs/queries";
import { SetLoaderActive, SetLoaderInactive } from "../navigation/actions";
import clientConfig, { CONFIG_CLUSTER } from "../../configs/client";
import uuidV4 from "uuid/dist/v4";
import {
  MOVE_UP,
  MOVE_DOWN,
  LESSON_UPDATE_WORFLOW,
} from "../../graphql/remote/lessons/mutations";
import { SetFetchIndicator } from "../common/actions";
import { getFormattedDegrees } from "./transformers";
import { GetTags } from "../tag/actions";
import { UPDATE_WORKFLOW } from "../../graphql/remote/programs/mutations/ProgramUpdateWorkflow.graphql";
import {
  formatBadgesToMutate,
  getLessonsToRemove,
  getLessonsToAdd,
} from "../../utils/store/badges";

const TEMPLATES = [
  PAGE_TEMPLATES.SIMPLE_QUIZ_TEMPLATE,
  PAGE_TEMPLATES.MADLIB_TEMPLATE,
  PAGE_TEMPLATES.DRAG_AND_DROP_TEMPLATE,
];

export const SetCurrentLanguage = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_DEGREE_LANGUAGE,
    data: data,
  });
};

export const SetCurrentPageId = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_DEGREE_PAGEID,
    data: data,
  });
};

export const SetCurrentDegreeId = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_DEGREE_ID,
    data: data,
  });
};

export const SetPublishDegree = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_PUBLISH_DEGREE_INFO,
    data: data,
  });
};

export const ChangeTagSelectorVisibility = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_VISIBLE_DEGREE_TAG_SELECTOR,
    data: data,
  });
};

export const SetCurrentEditProgramInfo = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_EDIT_PROGRAM_INFO,
    data: data,
  });
};

export const SetCurrentEditProgramId = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_EDIT_PROGRAM_ID,
    data: data,
  });
};

export const ChangeCurrentEditProgramInfoVisibility = (data) => (dispatch) => {
  dispatch({
    type: constants.CHANGE_CURRENT_EDIT_PROGRAM_INFO_VISIBILITY,
    data: data,
  });
};

export const SetCurrentDegreeProgramContent = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_DEGREE_PROGRAM_CONTENT,
    data: data,
  });
};

export const SetActiveMenuDegreeId = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_ACTIVE_MENU_FOR_DEGREE_ID,
    data: data,
  });
};

export const SetActiveMenuLessonId = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_ACTIVE_MENU_FOR_LESSON_ID,
    data: data,
  });
};

export const SetDegreePublicationStatus = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_DEGREE_PUBLICATION_STATUS,
    data: data,
  });
};

export const SetCurrentLessonId = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_LESSON_ID,
    data: data,
  });
};

export const SetPublishLessonInformation = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_PUBLISH_LESSON_INFO,
    data: data,
  });
};

export const SetCurrentLessonPageId = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_LESSON_PAGEID,
    data: data,
  });
};

export const UpdateCurrentOpenedMarker = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_CURRENT_OPENED_MARKER_LESSON,
    data: data,
  });
};

export const SetLastExpandedProgramId = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_LAST_EXPANDED_PROGRAM_ID,
    data: data,
  });
};

export const setDegreeFilter = (name, value) => async (dispatch) => {
  dispatch({
    type: constants.SET_DEGREE_FILTER,
    data: { name, value },
  });
};

export const SetProgramExpandedStatus =
  (programId, isExpanded) => (dispatch, getState) => {
    const { programContent } = getState().degrees;
    dispatch(
      SetCurrentDegreeProgramContent(
        programContent.reduce((programs, program) => {
          program.expanded =
            program.programId === programId ? isExpanded : program.expanded;
          programs.push(program);
          return programs;
        }, []),
      ),
    );
  };

export const ResetDegreeStore = () => (dispatch) => {
  dispatch({ type: constants.RESET_DEGREE_STORE });
};

const formatDegreeData = (rawDegreeData, currentLanguage) => {
  const validWorkflowList = [
    DOC_WORKFLOW_ENUM.PUBLISHED,
    DOC_WORKFLOW_ENUM.DRAFT,
  ];
  const degree = rawDegreeData.admin.degrees[0].versions.find(
    (x) => x.lang === currentLanguage,
  );
  const tagIds = degree.tags.map((tag) => parseInt(tag.tagId));
  degree.isMasterDegree = tagIds.includes(clientConfig.MASTER_DEGREE_TAG_ID);
  if (degree) {
    const availableDegreeLanguages =
      rawDegreeData.admin.degrees[0].versions.map((x) => x.lang);
    if (rawDegreeData.admin.degrees[0].programs.length) {
      degree.programs = rawDegreeData.admin.degrees[0].programs.reduce(
        (acc, program) => {
          const programsTemp = program.versions.find(
            (version) =>
              version.lang === currentLanguage &&
              validWorkflowList.includes(version.workflow),
          );
          if (programsTemp) {
            const availableProgramLanguages = program.versions.map(
              ({ lang }) => lang,
            );
            programsTemp.availableProgramLanguages = availableProgramLanguages;
            programsTemp.availableDegreeLanguages = availableDegreeLanguages;
            programsTemp.lessons = [];
            programsTemp.expanded = false;
            acc.push(programsTemp);
          }
          return acc;
        },
        [],
      );
    }
  }
  return degree;
};

const getLessons = async (programId, lang) => {
  const validWorkflowList = [
    DOC_WORKFLOW_ENUM.PUBLISHED,
    DOC_WORKFLOW_ENUM.DRAFT,
    DOC_WORKFLOW_ENUM.UNPUBLISHED,
  ];
  const lessons = [];
  const result = await client.query({
    fetchPolicy: "network-only",
    query: PROGRAM_GET_LESSONS_BY_ID,
    variables: { programId },
  });

  result.data.admin.programs[0].lessons.forEach((lesson) => {
    const availableLanguages = lesson.versions.map((version) => version.lang);
    lesson.versions.forEach((lessonVersion) => {
      if (
        lessonVersion.lang === lang &&
        validWorkflowList.includes(lessonVersion.workflow)
      ) {
        lessonVersion.availableLanguages = availableLanguages;
        lessonVersion.marketTags =
          lessonVersion.tags && lessonVersion.tags.length
            ? lessonVersion.tags
                .filter((x) =>
                  clientConfig.USER_MARKET_CLUSTER_IDS.includes(
                    parseInt(x.clusterId),
                  ),
                )
                .map((x) => ({
                  key: uuidV4(),
                  id: x.tagId,
                  label: x.title,
                }))
            : [];
        lessons.push(lessonVersion);
      }
    });
  });
  return lessons;
};

export const SetProgramLessons =
  (programId, lang, onSuccess) => async (dispatch, getState) => {
    try {
      const { programContent } = getState().degrees;
      const currentProgram = programContent.find(
        (program) => program.programId === programId,
      );
      let lessons = currentProgram.lessons;
      const hasLessons = lessons.length;

      if (onSuccess && hasLessons) {
        onSuccess();
      }
      lessons = await getLessons(programId, lang);
      const newProgramContent = programContent.reduce((programs, program) => {
        program.lessons =
          program.programId === programId ? lessons : program.lessons;
        programs.push(program);
        return programs;
      }, []);

      dispatch(SetCurrentDegreeProgramContent(newProgramContent));
      if (onSuccess && !hasLessons) {
        onSuccess();
      }
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while fetching the program's lessons",
          e,
        ),
      );
    }
  };

export const ReFetchProgramLessons =
  (programId, lang) => async (dispatch, getState) => {
    const { programContent } = getState().degrees;
    const lessons = await getLessons(programId, lang);
    const newProgramContent = programContent.reduce((programs, program) => {
      program.lessons =
        program.programId === programId ? lessons : program.lessons;
      programs.push(program);
      return programs;
    }, []);
    dispatch(SetCurrentDegreeProgramContent(newProgramContent));
  };

export const GetDegreePrograms =
  (degreeId, lang, onSuccess) => async (dispatch, getState) => {
    try {
      dispatch(SetLoaderActive());
      const { publishDegree } = getState().degrees;
      const result = await client.query({
        fetchPolicy: "network-only",
        query: GET_DEGREE_BY_ID,
        variables: { degreeId },
      });

      const degree = formatDegreeData(result.data, lang);
      dispatch(SetCurrentDegreeProgramContent(degree.programs));
      dispatch(
        SetPublishDegree({
          ...publishDegree,
          title: degree.title,
          id: degree.degreeId,
          lang: degree.lang,
          isMasterDegree: degree.isMasterDegree,
          tags: degree.tags,
        }),
      );
      dispatch(
        SetCurrentEditProgramInfo({
          title: "",
          summary: "",
          programId: undefined,
        }),
      );
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while fetching the degree's programs",
          e,
        ),
      );
    } finally {
      dispatch(SetLoaderInactive());
    }
  };

export const UpdatePublishedLessonPageDataWithProgramQuizInfo =
  (callback) => async (dispatch, getState) => {
    try {
      const { isProgramQuiz, id, lang, pages } =
        getState().lesson.publishLessonData;
      await Promise.all(
        pages
          // update only activity pages with program quiz attribute
          .filter((page) => TEMPLATES.includes(page.template))
          .map((page) => {
            const pageData = JSON.parse(page.data);
            return client.mutate({
              mutation: LESSON_UPDATE_PAGE_DATA,
              variables: {
                lessonId: id,
                pageId: page.pageId,
                lang,
                data: JSON.stringify({
                  ...pageData,
                  data: {
                    ...pageData.data,
                    isProgramQuiz,
                  },
                }),
              },
            });
          }),
      );

      callback && callback();
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while updating page data of a lesson with program quiz data",
          e,
        ),
      );
    }
  };

export const LessonMoveUp =
  (lang, programId, lessonId, onSuccess) => async (dispatch) => {
    try {
      await client.mutate({
        mutation: MOVE_UP,
        variables: { lang, programId, lessonId },
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while fetching the degree's programs",
          e,
        ),
      );
    }
  };

export const LessonMoveDown =
  (lang, programId, lessonId, onSuccess) => async (dispatch) => {
    try {
      await client.mutate({
        mutation: MOVE_DOWN,
        variables: { lang, programId, lessonId },
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while fetching the degree's programs",
          e,
        ),
      );
    }
  };

export const GetDegrees = () => async (dispatch, getState) => {
  const { degreeFilters, userMarketIds } = getState().degrees;
  const marketTagId = degreeFilters.market.selectedValue
    ? [degreeFilters.market.selectedValue.value]
    : userMarketIds;

  try {
    dispatch(SetFetchIndicator(true));
    const result = await client.query({
      fetchPolicy: "network-only",
      query: DEGREES_GET_LIST_BY_STATUSES,
      variables: {
        statusPublished: DOC_WORKFLOW_ENUM.PUBLISHED,
        statusDraft: DOC_WORKFLOW_ENUM.DRAFT,
        tagIds: marketTagId,
      },
    });
    dispatch(UpdateDegrees(getFormattedDegrees(result, degreeFilters)));
  } catch (e) {
    dispatch(SetErrorMessage("An error occurred while fetching degrees"));
  } finally {
    dispatch(SetFetchIndicator(false));
  }
};

export const UpdateDegrees = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_DEGREES,
    data,
  });
};

export const UpdateDegreeFilters = (type, value) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_DEGREE_FILTERS,
    data: { type, value },
  });
};

export const GetMarketFilterTags = (callback) => async (dispatch) => {
  await dispatch(
    GetTags(clientConfig[CONFIG_CLUSTER.MARKET], (result) => {
      dispatch({
        type: constants.SET_MARKETS,
        data: result.data.tags.map((tag) => ({
          value: tag.tagId,
          label: tag.title,
        })),
      });
      callback && callback();
    }),
  );
};

export const DeleteDocument =
  (degreeId, lang, callback) => async (dispatch) => {
    try {
      await client.mutate({
        mutation: DEGREE_UPDATE_WORFLOW,
        variables: { degreeId, lang, status: DOC_WORKFLOW_ENUM.ARCHIVED },
      });
      callback && callback();
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while updating the degree's status",
          e,
        ),
      );
    }
  };

export const TranslateDocument =
  (degreeId, sourceLang, destLang, callback) => async (dispatch) => {
    try {
      await client.mutate({
        mutation: DEGREE_COPY_ALL_FOR_LANGUAGE,
        variables: { degreeId, sourceLang, destLang },
      });
      callback && callback();
    } catch (e) {
      dispatch(
        SetErrorMessage("An error occurred while translating the degree", e),
      );
    }
  };

export const DeleteProgram =
  (programId, lang, callback) => async (dispatch) => {
    try {
      await client.mutate({
        mutation: UPDATE_WORKFLOW,
        variables: { programId, lang, status: DOC_WORKFLOW_ENUM.ARCHIVED },
      });
      callback && callback();
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while updating the program's status",
          e,
        ),
      );
    }
  };

export const DeleteLesson = (lessonId, lang, callback) => async (dispatch) => {
  try {
    await client.mutate({
      mutation: LESSON_UPDATE_WORFLOW,
      variables: { lessonId, lang, status: DOC_WORKFLOW_ENUM.ARCHIVED },
    });
    callback && callback();
  } catch (e) {
    dispatch(
      SetErrorMessage(
        "An error occurred while updating the lesson's status",
        e,
      ),
    );
  }
};

export const UpdateUserMarketIds = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_USER_MARKET_IDS,
    data: data,
  });
};

export const GetDegreeData =
  (degreeId, callback) => async (dispatch, getState) => {
    try {
      const result = await client.query({
        fetchPolicy: "network-only",
        query: GET_DEGREE_SHORT_DETAILS,
        variables: { degreeId },
      });

      callback && callback(result.data.admin.degrees[0].versions[0]);
    } catch (e) {
      console.error("error degree/actions.js GetDegreeData", e);
    }
  };

const formatBadgesByType = (programs, type, badgeId) =>
  programs.flatMap(({ lessons }) =>
    lessons
      .flatMap(({ versions: [{ badges, docId }] }) =>
        badges.some(
          ({ badgeId: versionBadgeId, audit }) =>
            Number(versionBadgeId) === Number(badgeId) && audit === type,
        )
          ? Number(docId)
          : null,
      )
      .filter((docId) => docId),
  );

const getFormatedDegreeBadges = (badges, programs) =>
  badges.map((badge) => ({
    badgeId: parseInt(badge.badgeId),
    badgeImage: badge.pic,
    badgeName: badge.title,
    lessonIds: formatBadgesByType(programs, "read", badge.badgeId),
    quizz: formatBadgesByType(programs, "activity", badge.badgeId),
  }));

export const GetDegreeBadges =
  (degreeId, tags, callback) => async (dispatch) => {
    try {
      const result = await client.query({
        fetchPolicy: "network-only",
        query: GET_DEGREE_BADGES,
        variables: {
          tagIds: tags
            .filter(
              ({ clusterId, tagId }) =>
                [
                  ...clientConfig[CONFIG_CLUSTER.MARKET],
                  ...clientConfig[CONFIG_CLUSTER.BRAND],
                ].includes(Number(clusterId)) ||
                Number(tagId) === clientConfig.MASTER_DEGREE_TAG_ID,
            )
            .map(({ tagId }) => Number(tagId)),
        },
      });
      const resultLessons = await client.query({
        fetchPolicy: "network-only",
        query: GET_DEGREE_BADGES_LESSONS,
        variables: { degreeId },
      });
      const {
        data: {
          admin: {
            degrees: [{ programs }],
          },
        },
      } = resultLessons;
      const {
        data: {
          admin: { badges },
        },
      } = result;
      const allLessons = programs.flatMap(({ lessons }) =>
        lessons
          .filter(({ versions: [{ workflow }] }) =>
            [DOC_WORKFLOW_ENUM.PUBLISHED, DOC_WORKFLOW_ENUM.DRAFT].includes(
              workflow,
            ),
          )
          .map(
            ({
              versions: [{ docId, picThumb, summary, title, workflow }],
            }) => ({
              id: Number(docId),
              lessonImage: picThumb,
              lessonTitle: title,
              lessonDescription: summary,
              status: workflow,
            }),
          ),
      );

      dispatch({
        type: constants.SET_DEGREE_ALL_LESSONS,
        data: allLessons,
      });

      dispatch({
        type: constants.SET_DEGREE_BADGES,
        data: getFormatedDegreeBadges(badges, programs),
      });
      callback && callback();
    } catch (e) {
      console.error("error: degree/actions.js GetDegreeBadges", e);
    }
  };

export const SetDegreeBadgeLessonIds =
  (badgeId, lessonId, isQuizz) => (dispatch) => {
    dispatch({
      type: constants.SET_DEGREE_BADGE_LESSON_IDS,
      data: { badgeId, lessonId, isQuizz },
    });
  };

export const ResetDegreeBadges = () => (dispatch) => {
  dispatch({
    type: constants.RESET_DEGREE_BADGES,
    data: null,
  });
};

export const SaveDegreeLessonBadges =
  (score = 1) =>
  async (dispatch, getState) => {
    const { badges, initialBadges } = getState().degrees;
    const toRemove = getLessonsToRemove(badges, initialBadges);
    const { lessonsIdsToAdd, quizzIdsToAdd } = getLessonsToAdd(
      badges,
      initialBadges,
    );

    try {
      await client.mutate({
        mutation: REMOVE_DEGREE_BADGES,
        variables: { data: formatBadgesToMutate(toRemove, "doc") },
      });
      await client.mutate({
        mutation: ADD_DEGREE_ACTIVITY_BADGES,
        variables: { data: formatBadgesToMutate(quizzIdsToAdd, "quiz", score) },
      });
      await client.mutate({
        mutation: ADD_DEGREE_READ_BADGES,
        variables: {
          data: formatBadgesToMutate(lessonsIdsToAdd, "lesson", score),
        },
      });
      dispatch({
        type: constants.SET_DEGREE_BADGES,
        data: badges,
      });
    } catch (e) {
      console.error("error degree/actions.js SaveDegreeLessonBadges", e);
    }
  };
