import React from "react";
import PeanutPageEditorReadOnly from "../PeanutPageEditorReadOnly";
import PeanutReadOnly from "../../common/Peanut/readOnly";

const renderSlideItem = (
  slide,
  index,
  key,
  isSelected,
  addAfter,
  addBefore,
  removeSlide,
  handleChange,
  selectSlide,
  cardsNumber,
) => {
  return (
    <PeanutReadOnly
      key={key}
      title={slide.title}
      description={slide.description}
      image={slide.image}
      defaultSliderImage={slide.defaultImage}
      legend={slide.legend}
      allCardNumber={cardsNumber}
      cardIndex={index + 1}
      cardType={slide.cardType}
    />
  );
};

const PreparedPeanutPageEditor = ({ cards, ...props }) => (
  <PeanutPageEditorReadOnly
    cards={cards}
    renderSlideItem={renderSlideItem}
    {...props}
  />
);

PreparedPeanutPageEditor.propTypes = {
  /**
   * All the props accepted by SliderPageEditor
   */
};

export default PreparedPeanutPageEditor;
