import React, { useEffect } from "react";
import PublishPreviewBanner from "../../components/PublishPreviewBanner";
import { getPath } from "../../core/paths";
import { connect } from "react-redux";
import { createUseStyles } from "react-jss";
import { Icon, Icons, FullscreenLoader } from "genius-ui";
import style from "./createCardTwitter.style";
import { GetCardById, ResetCardsStore } from "../../store/card/actions";
import uuIdV4 from "uuid/dist/v4";
import TwitterCard from "../../components/common/TwitterCard";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const CardCreateTwitter = (props) => {
  const {
    navigateToPublishPage,
    match: { params },
    navigateBack,
    getCurrentCardData,
    cards,
    resetCardsStore,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  useEffect(() => {
    /** ComponentDidMount */
    getCurrentCardData(params.cardId, params.lang);

    /** ComponentWillUnmount */
    return () => {
      resetCardsStore();
    };
  }, []);

  return (
    <div>
      <div className={classes.back} onClick={navigateBack}>
        <Icon iconName={Icons.arrowLeft} alt="" />
      </div>
      <PublishPreviewBanner
        onPublishButtonClick={() =>
          navigateToPublishPage(params.cardId, params.lang)
        }
        containerClassName={classes.headerContainer}
      />
      {!cards.length ? (
        <FullscreenLoader />
      ) : (
        <div className={classes.cardEditorContainer}>
          {cards.map((card) => (
            <TwitterCard
              key={uuIdV4()}
              description={card.description}
              image={card.image}
              video={card.video}
              likes={card.likes}
              profileName={card.profileName}
              profileUrl={card.profileUrl}
              twitterLink={card.twitterLink}
              mediaType={card.mediaType}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProp = (state) => ({
  currentCardData: state.cards.currentCardData,
  cards: state.cards.cards,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCurrentCardData: (cardId, lang) => {
    dispatch(GetCardById(cardId, lang));
  },
  resetCardsStore: () => {
    dispatch(ResetCardsStore());
  },
  navigateToPublishPage: (cardId, language) => {
    ownProps.history.push(getPath("cardPublish", cardId, language));
  },
  navigateBack: () => ownProps.history.goBack(),
});

export default connect(mapStateToProp, mapDispatchToProps)(CardCreateTwitter);
