import gql from "graphql-tag";

export const PROGRAM_GET_LESSONS_BY_ID = gql`
  query programGetLessonsById($programId: ID) {
    admin {
      programs(programId: $programId) {
        programId
        versions {
          programId
          lang
          title
        }
        lessons {
          lessonId
          versions {
            tags {
              tagId
              clusterId
              title
              label
              clusterName
            }
            isPrivate
            lessonId
            title
            lang
            workflow
            picCover
            workflowScheduledIn
            author {
              profile {
                firstName
                lastName
                mail
              }
            }
          }
        }
      }
    }
  }
`;
