export const VideoPageEditorStyle = ({ VideoPageEditor }) => ({
  videoContainer: {
    position: "relative",
    backgroundColor: VideoPageEditor.backgroundColor,
    width: 414,
    minHeight: 233,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  templateSelector: {
    minWidth: 240,
  },
});
