export const kpiApplicationDocumentToKpiApplicationPageData = ({
  data: { applicationKPI },
}) => ({
  activeNumberOfConnections: {
    isAvailable: true,
    numberOfConnections: applicationKPI.connections,
    previousValue: applicationKPI.previousPeriod.connections,
    tendency: (
      100 -
      (applicationKPI.previousPeriod.connections * 100) /
        applicationKPI.connections
    ).toFixed(),
  },
  activeUsers: {
    isAvailable: true,
    active: applicationKPI.activeUsers,
    previousValue: applicationKPI.previousPeriod.activeUsers,
    tendency: (
      100 -
      (applicationKPI.previousPeriod.activeUsers * 100) /
        applicationKPI.activeUsers
    ).toFixed(),
    total: applicationKPI.totalUsers,
  },
  averageTimeSpentPerDay: {
    isAvailable: true,
    averageTimeMin: applicationKPI.dailyUsage,
    previousValue: applicationKPI.previousPeriod.dailyUsage,
    tendency: (
      100 -
      (applicationKPI.previousPeriod.dailyUsage * 100) /
        applicationKPI.dailyUsage
    ).toFixed(),
  },
  nrOfUsersChartData: {
    nrOfUsers: applicationKPI.graphConnections
      ? applicationKPI.graphConnections
          .map((connection) => [
            parseInt(connection.lastAccess * 1000),
            connection.data,
          ])
          .sort((x, y) => x[0] - y[0])
      : [],
  },
});
