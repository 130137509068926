import { defineMessages } from "react-intl";

export default defineMessages({
  NinjaQuestionChoicePlaceHolder: {
    id: "Ninja.NinjaQuestionChoicePlaceHolder",
    defaultMessage: "Enter choice text here...",
  },
  AnswerImageInputLabel: {
    id: "Ninja.ImageInputLabel",
    defaultMessage: "Upload Image",
  },
});
