import React from "react";
import Navigation from "components/Navigation";
import { navigationBarWidth } from "components/Navigation/Navigation.style";

const PageContainer = ({ children }) => {
  return (
    <div style={{ paddingLeft: navigationBarWidth }}>
      <Navigation />
      {children}
    </div>
  );
};

export default PageContainer;
