import clientConfig from "../../configs/client";
import { BadgeVariations } from "genius-ui";
import groupBy from "lodash/groupBy";
import uuidV4 from "uuid/dist/v4";
import { DefaultUserImage } from "assets/icons";
import { LANGUAGES } from "../../configs/referential";
import { formatDate, isAfter } from "../../utils/dateUtils";

export const getFormattedUserProfileData = ({
  profile,
  badges,
  profileCustom,
  kpi,
  lessonPts,
  ...props
}) => {
  const userBadgeIds = profileCustom.flatMap(({ badges }) => badges);

  /** Badges group by degreeId */
  const properBadges =
    badges && badges.length
      ? badges.filter((x) => x.degreeRef !== null)
      : undefined;
  const groupedBadges =
    properBadges && properBadges.length
      ? Object.values(
          groupBy(properBadges, (badge) => {
            return badge.degreeRef.degreeId;
          }),
        )
      : [];

  /** Format degree badges array */
  const degrees = groupedBadges.map((badgeList) => {
    const mappedBadgeList = badgeList.map((item) => {
      const matchedBadge = userBadgeIds.find(
        (badge) => badge.badgeId === item.badgeId,
      );
      return {
        id: item.badgeId,
        picture: item.pic,
        status: matchedBadge?.isUnlocked
          ? BadgeVariations.completed
          : BadgeVariations.uncompleted,
        score: matchedBadge?.score || 0,
        name: item.title,
        key: uuidV4(),
        date: matchedBadge?.isUnlocked
          ? formatDate(matchedBadge.updateDate, "dd/MM/yyyy")
          : undefined,
      };
    });
    return {
      name: badgeList[0].degreeRef.title,
      totalPoints: badgeList.reduce((total, item) => total + item.score, 0),
      completedPoints: mappedBadgeList.reduce(
        (total, item) => total + item.score,
        0,
      ),
      badgeList: mappedBadgeList,
    };
  });

  const brands =
    props.tags && props.tags.length && clientConfig.BRAND_TAGS_CLUSTER_IDS
      ? props.tags
          .filter((x) =>
            clientConfig.BRAND_TAGS_CLUSTER_IDS.includes(parseInt(x.clusterId)),
          )
          .map((x) => ({
            key: uuidV4(),
            label: x.title,
          }))
      : [];

  const interests =
    props.tags && props.tags.length && clientConfig.INTEREST_TAGS_CLUSTER_IDS
      ? props.tags
          .filter((x) =>
            clientConfig.INTEREST_TAGS_CLUSTER_IDS.includes(
              parseInt(x.clusterId),
            ),
          )
          .map((x) => ({
            key: uuidV4(),
            label: x.title,
          }))
      : [];

  const getMaxLessonUpdateDate = (lessons) => {
    let maxDate = lessons[0].stats.date;
    lessons.forEach((lesson) => {
      if (
        (!maxDate && lesson.stats.date) ||
        (maxDate && lesson.stats.date && isAfter(lesson.stats.date, maxDate))
      ) {
        maxDate = lesson.stats.date;
      }
    });
    return maxDate || "-";
  };

  const computedProfileInfo = {
    lastConnection: formatDate(props.lastLogin, "dd/MM/yyyy"),
    user: {
      name: `${profile.firstName} ${profile.lastName}`,
      picture: profile.pic || DefaultUserImage,
      position: profile.position,
      email: profile.mail || "-",
      location: profile.localization,
      locationUrl: profile.link || "#",
      birthday: profile.birthday ? profile.birthday : "-",
      pointOfSale: profile.pointOfSales ? profile.pointOfSales : "-",
      creationDate: props.creationDate
        ? formatDate(props.creationDate, "dd/MM/yyyy")
        : "-",
      lang: profile.lang
        ? LANGUAGES.find((x) => x.Code === profile.lang).Name
        : undefined,
      market:
        props.tags &&
        props.tags.length &&
        clientConfig.USER_MARKET_CLUSTER_IDS.length
          ? props.tags.find((x) =>
              clientConfig.USER_MARKET_CLUSTER_IDS.includes(
                parseInt(x.clusterId),
              ),
            ).title
          : undefined,
      mailingAddress: profile.posAddress ? profile.posAddress : "-",
    },
    brands,
    interests,
    totalPoints: lessonPts,
    statistics: {
      questionAsked: kpi && kpi.activity ? kpi.activity.geniusQuestionNb : "-",
      questionAskedToExpert:
        kpi && kpi.activity ? kpi.activity.expertQuestionNb : "-",
      lessonFinished: kpi && kpi.activity ? kpi.activity.finishedLessonNb : "-",
      lessonStarted: kpi && kpi.activity ? kpi.activity.startedLessonNb : "-",
    },
    questions:
      kpi && kpi.expertPendingQuestions
        ? kpi.expertPendingQuestions.map((question) => ({
            questioner: question.expertName,
            question: question.question,
            created: question.date,
          }))
        : [],
    programs:
      kpi &&
      kpi.learningPrograms.map((program) => ({
        name: program.name,
        latestUpdate: getMaxLessonUpdateDate(program.lessons),
        allLessons: program.lessons.length,
        completedLessons: program.lessons.filter(
          (lesson) => lesson.stats.percent && lesson.stats.percent === 100,
        ).length,
        points: program.lessons.reduce((points, lesson) => {
          points += lesson.stats.score ? lesson.stats.score : 0;
          return points;
        }, 0),
        lessons: program.lessons.map((lesson) => ({
          name: lesson.name,
          pages: lesson.pageNb,
          completedPages: lesson.stats.leftPage ? lesson.stats.leftPage : 0,
          percent: lesson.stats.percent ? lesson.stats.percent : 0,
          latestUpdate: lesson.stats.date || "-",
          points: lesson.stats.score ? lesson.stats.score : 0,
        })),
      })),
    degrees,
  };

  // Sort all lessons alphabetically// noinspection DuplicatedCode
  if (computedProfileInfo.programs && computedProfileInfo.programs.length > 0) {
    computedProfileInfo.programs.forEach((program) => {
      program.lessons = program.lessons.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (b.name < a.name) {
          return 1;
        }
        return 0;
      });
    });
  }

  return computedProfileInfo;
};
