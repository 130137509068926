import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import uuidv4 from "uuid/dist/v4";
import { FormattedMessage } from "react-intl";
import {
  SIMPLE_QUIZ_COVER,
  SIMPLE_QUIZ_FULL_SCREEN,
  SIMPLE_QUIZ_NO_IMAGE,
} from "../../../../utils/ui-generator/activity.template";
import {
  SimpleQuizActivityQuestionTemplateEmpty,
  QuizWithCoverActivityQuestionTemplateEmpty,
  QuizImageFullScreenActivityQuestionTemplateEmpty,
} from "../../../../utils/ui-generator/defaultTemplates";
import ContentEditable from "react-contenteditable";
import RadioGroup from "../../RadioGroup";
import SimpleQuizAnswer from "../../SimpleQuizAnswer";
import Style, { backgroundGradient } from "../SimpleQuizQuestion.style";
import { translations } from "../SimpleQuizQuestion.translations";
import useThemedStyle from "../../../../hooks/style/useThemedStyle";
import {
  SimpleQuizCoverThumbnail,
  SimpleQuizNoImageThumbnail,
  SimpleQuizFullScreenThumbnail,
} from "assets/icons";

const useStyle = createUseStyles(Style);

const availableTemplates = [
  {
    id: SIMPLE_QUIZ_NO_IMAGE,
    name: translations.NoImage,
    image: SimpleQuizNoImageThumbnail,
    defaultProps: SimpleQuizActivityQuestionTemplateEmpty,
  },
  {
    id: SIMPLE_QUIZ_COVER,
    name: translations.Cover,
    image: SimpleQuizCoverThumbnail,
    defaultProps: QuizWithCoverActivityQuestionTemplateEmpty,
  },
  {
    id: SIMPLE_QUIZ_FULL_SCREEN,
    name: translations.Background,
    image: SimpleQuizFullScreenThumbnail,
    defaultProps: QuizImageFullScreenActivityQuestionTemplateEmpty,
  },
];

const MAX_NR_OF_ANSWERS = 4;
const ADD_NEW_OPTION_VALUE = "addNewOption";

const SimpleQuizQuestion = (props) => {
  const {
    answers: answersProp,
    background,
    endMessage,
    text,
    type,
    id,
    onChange,
    pictureManagementOnEndtranslationscreen,
    multiAnswerManagement,
  } = props;
  const classes = useThemedStyle(useStyle, props);
  const [question] = useState(text);
  const [explanations] = useState(endMessage.text);
  const [backgroundImage] = useState(() =>
    background ? background.url : null,
  );
  const [endMessageBackgroundImage] = useState(() =>
    endMessage && endMessage.background ? endMessage.background.url : null,
  );
  const [isTyping] = useState(false);
  const [selectedTemplateId] = useState(() => {
    let selectedTemplateId = SIMPLE_QUIZ_NO_IMAGE;
    if (background) {
      if (
        background.theme ===
        QuizWithCoverActivityQuestionTemplateEmpty.background.theme
      ) {
        selectedTemplateId = SIMPLE_QUIZ_COVER;
      } else if (
        background.theme ===
        QuizImageFullScreenActivityQuestionTemplateEmpty.background.theme
      ) {
        selectedTemplateId = SIMPLE_QUIZ_FULL_SCREEN;
      }
    }

    return selectedTemplateId;
  });
  const [answers] = useState(answersProp);
  const answerOptions = answers.map(({ answer }) => ({
    value: uuidv4(),
    label: answer,
  }));
  if (multiAnswerManagement) {
    answerOptions.push({
      value: ADD_NEW_OPTION_VALUE,
      label: (
        <FormattedMessage
          {...translations.AddAChoiceButtonLabel}
          values={{ maxNrOfAnswers: MAX_NR_OF_ANSWERS }}
        />
      ),
    });
  }
  const [correctAnswerIndex] = useState(
    // if correct answer was selected, it is the correct answer, else the first added answer is correct, else undefined
    () => {
      const selectedIndex = answers.findIndex(({ isTrue }) => isTrue);
      return selectedIndex >= 0
        ? selectedIndex
        : answers.length > 0
        ? 0
        : undefined;
    },
  );
  const correctAnswerValue =
    answerOptions[correctAnswerIndex] &&
    answerOptions[correctAnswerIndex].value;
  const [successMessage] = useState(
    // the default success message is the message of the answer marked as correct or ''
    () =>
      (answers[correctAnswerIndex] && answers[correctAnswerIndex].message) ||
      "",
  );
  const [failedMessage] = useState(() => {
    // the default failed message is the message of the first incorrect answer or ''
    const firstIncorrectAnswer = answers.find(({ isTrue }) => !isTrue);
    return (firstIncorrectAnswer && firstIncorrectAnswer.message) || "";
  });

  // call the onChange callback when answers, background, endMessage, text, type or the selected template is
  // changed
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      // don't call the onChange on the first render
      return;
    }

    if (isTyping) {
      // don't call the onChange callback while typing
      return;
    }

    // get default backgrounds of the selected template
    const {
      defaultProps: {
        background: selectedTemplateBackground,
        endMessage: { background: selectedTemplateEndMessageBackground },
      },
    } = availableTemplates.find(({ id }) => id === selectedTemplateId);

    const finalBackground = selectedTemplateBackground
      ? {
          ...selectedTemplateBackground,
          // use background from empty template if no background image was uploaded
          url: backgroundImage || selectedTemplateBackground.url,
        }
      : selectedTemplateBackground;

    const finalEndMessage = {
      ...endMessage,
      text: explanations,
      background: selectedTemplateEndMessageBackground
        ? {
            ...selectedTemplateEndMessageBackground,
            // use background from empty template if no background image was uploaded
            url:
              endMessageBackgroundImage ||
              selectedTemplateEndMessageBackground.url,
          }
        : selectedTemplateEndMessageBackground,
    };

    onChange(id, {
      answers: answers.map((answer, index) => ({
        ...answer,
        isTrue: index === correctAnswerIndex,
        message: index === correctAnswerIndex ? successMessage : failedMessage,
      })),
      background: finalBackground,
      endMessage: finalEndMessage,
      text: question,
      type,
    });
  }, [
    answers,
    correctAnswerIndex,
    background,
    endMessage,
    text,
    type,
    selectedTemplateId,
    isTyping,
    backgroundImage,
    endMessageBackgroundImage,
    successMessage,
    failedMessage,
  ]);

  const displayedEndMessageBackgroundImage =
    pictureManagementOnEndtranslationscreen
      ? endMessageBackgroundImage
      : backgroundImage;

  return (
    <div className={classes.container}>
      <div
        className={classNames([
          classes.innerPageContainer,
          classes.questionContainer,
        ])}
        style={{
          backgroundImage:
            (backgroundImage &&
              `${backgroundGradient}, url("${backgroundImage}")`) ||
            null,
        }}
      >
        <FormattedMessage {...translations.QuestionPlaceholder}>
          {(message) => (
            <ContentEditable
              key="question"
              html={question}
              disabled
              tagName="article"
              className={classNames([classes.questionInput, classes.textInput])}
              placeholder={message[0]}
            />
          )}
        </FormattedMessage>
        <div className={classes.answerContainer}>
          <div className={classes.radioHeaderContainer}>
            <div className={classes.correctLabel}>
              <FormattedMessage {...translations.CorrectColumnLabel} />
            </div>
            <div className={classes.choiceTextLabel}>
              <FormattedMessage {...translations.ChoiceTextColumnLabel} />
            </div>
          </div>
          <div className={classes.radioContainer}>
            <RadioGroup
              options={answerOptions}
              onChange={() => null}
              value={correctAnswerValue}
              renderOptionLabel={(option, index, options, value, classes) => (
                <SimpleQuizAnswer
                  multiAnswerManagement={false}
                  option={option}
                  index={index}
                  options={options}
                  value={value}
                  isAddAChoice={option.value === ADD_NEW_OPTION_VALUE}
                  classes={classes}
                  disabled
                  isBGDark={selectedTemplateId === SIMPLE_QUIZ_FULL_SCREEN}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div
        className={classNames([
          classes.innerPageContainer,
          classes.resultContainer,
        ])}
        style={{
          backgroundImage:
            (displayedEndMessageBackgroundImage &&
              `${backgroundGradient}, url("${displayedEndMessageBackgroundImage}")`) ||
            null,
        }}
      >
        <FormattedMessage {...translations.SuccessMessageInputPlaceholder}>
          {(message) => (
            <ContentEditable
              key="success_message"
              html={successMessage}
              disabled
              tagName="article"
              className={classNames([
                classes.successMessageInput,
                classes.textInput,
              ])}
              placeholder={message[0]}
            />
          )}
        </FormattedMessage>
        <FormattedMessage {...translations.FailedMessageInputPlaceholder}>
          {(message) => (
            <ContentEditable
              key="failed_message"
              html={failedMessage}
              disabled
              tagName="article"
              className={classNames([
                classes.failedMessageInput,
                classes.textInput,
              ])}
              placeholder={message[0]}
            />
          )}
        </FormattedMessage>
        <FormattedMessage {...translations.ExplanationsInputPlaceholder}>
          {(message) => (
            <ContentEditable
              key="explanations"
              html={explanations}
              disabled
              tagName="article"
              className={classNames([
                classes.explanationsInput,
                classes.textInput,
              ])}
              placeholder={message[0]}
            />
          )}
        </FormattedMessage>
      </div>
    </div>
  );
};

SimpleQuizQuestion.propTypes = {
  answers: PropTypes.array.isRequired,
  background: PropTypes.object,
  endMessage: PropTypes.shape({
    background: PropTypes.object,
    text: PropTypes.string.isRequired,
  }).isRequired,
  /** an id the question can be identified with **/
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

SimpleQuizQuestion.defaultProps = {
  pictureManagementOnEndtranslationscreen: false,
  multiAnswerManagement: false,
};

export default SimpleQuizQuestion;
