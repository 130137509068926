import { defineMessages } from "react-intl";

export const translations = defineMessages({
  PublicationSettings: {
    id: "PublishDegree.PublicationSettings",
    defaultMessage: "Publication settings",
  },
  LanguageLabel: {
    id: "PublishDegree.LanguageLabel",
    defaultMessage: "Language",
  },
  BrandLabel_REST_OF_CLIENTS: {
    id: "PublishDegree.REST_OF_CLIENTS.BrandLabel",
    defaultMessage: "Brand",
  },
  BrandLabel_LOREAL: {
    id: "PublishDegree.LOREAL.BrandLabel",
    defaultMessage: "Entity Type",
  },
  RetailerLabel_REST_OF_CLIENTS: {
    id: "PublishDegree.REST_OF_CLIENTS.RetailerLabel",
    defaultMessage: "Retailer",
  },
  RetailerLabel_LOREAL: {
    id: "PublishDegree.LOREAL.RetailerLabel",
    defaultMessage: "Division",
  },
  EntityLabel: {
    id: "PublishDegree.EntityLabel",
    defaultMessage: "Entity",
  },
  RetailerLabel: {
    id: "PublishDegree.RetailerLabel",
    defaultMessage: "Retailer",
  },
  DivisionLabel: {
    id: "PublishDegree.DivisionLabel",
    defaultMessage: "Division",
  },
  PublicationCountriesLabel: {
    id: "PublishDegree.PublicationCountriesLabel",
    defaultMessage: "Countries",
  },
  PublicationZonesLabel: {
    id: "PublishDegree.PublicationZonesLabel",
    defaultMessage: "Zones",
  },
  ProfessionsLabel: {
    id: "PublishDegree.ProfessionsLabel",
    defaultMessage: "Profession",
  },
  AssociatedTagsLabel: {
    id: "PublishDegree.AssociatedTagsLabel",
    defaultMessage: "Associated Tags",
  },
  UserCategoryLabel: {
    id: "PublishDegree.UserCategoryLabel",
    defaultMessage: "User Category",
  },
  UniverseLabel: {
    id: "PublishDegree.UniverseLabel",
    defaultMessage: "Universe",
  },
  PublicationCountriesLabel_REST_OF_CLIENTS: {
    id: "PublishDegree.REST_OF_CLIENTS.PublicationCountriesLabel",
    defaultMessage: "Publication Countries",
  },
  PublicationCountriesLabel_LOREAL: {
    id: "PublishDegree.LOREAL.PublicationCountriesLabel",
    defaultMessage: "Zones and countries",
  },
  PublicButtonLabel: {
    id: "PublishDegree.PublicButtonLabel",
    defaultMessage: "Public",
  },
  ScheduleButtonText: {
    id: "PublishDegree.ScheduleButtonText",
    defaultMessage: "Schedule to publish",
  },
  CancelScheduleButtonText: {
    id: "PublishDegree.CancelScheduleButtonText",
    defaultMessage: "Cancel scheduling",
  },
  SaveWithoutScheduleButtonText: {
    id: "PublishDegree.SaveWithoutScheduleButtonText",
    defaultMessage: "Save without scheduling",
  },
  AddBrandPlaceholder_REST_OF_CLIENTS: {
    id: "PublishDegree.REST_OF_CLIENTS.AddBrandPlaceholder",
    defaultMessage: "Add a brand",
  },
  AddBrandPlaceholder_LOREAL: {
    id: "PublishDegree.LOREAL.AddBrandPlaceholder",
    defaultMessage: "Add an entity type",
  },
  AddRetailerPlaceholder_REST_OF_CLIENTS: {
    id: "PublishDegree.REST_OF_CLIENTS.AddRetailerPlaceholder",
    defaultMessage: "Add a retailer",
  },
  AddRetailerPlaceholder_LOREAL: {
    id: "PublishDegree.LOREAL.AddRetailerPlaceholder",
    defaultMessage: "Add a division",
  },
  AddEntityPlaceholder: {
    id: "PublishDegree.AddEntityPlaceholder",
    defaultMessage: "Add an entity type",
  },
  AddRetailerPlaceholder: {
    id: "PublishDegree.AddRetailerPlaceholder",
    defaultMessage: "Add a retailer",
  },
  AddDivisionPlaceholder: {
    id: "PublishDegree.AddDivisionPlaceholder",
    defaultMessage: "Add a division",
  },
  SelectAssociatedTagsPlaceholder: {
    id: "PublishDegree.SelectAssociatedTagsPlaceholder",
    defaultMessage: "Select associated tags",
  },
  SelectCountriesPlaceholder_REST_OF_CLIENTS: {
    id: "PublishDegree.REST_OF_CLIENTS.SelectCountriesPlaceholder",
    defaultMessage: "Select countries",
  },
  SelectCountriesPlaceholder_LOREAL: {
    id: "PublishDegree.LOREAL.SelectCountriesPlaceholder",
    defaultMessage: "Select zones and countries",
  },
  SelectUserCategoriesPlaceholder: {
    id: "PublishDegree.SelectUserCategoriesPlaceholder",
    defaultMessage: "Select user categories",
  },
  SelectUniversePlaceholder: {
    id: "PublishDegree.SelectUniversePlaceholder",
    defaultMessage: "Select universes",
  },
  SelectCountriesPlaceholder: {
    id: "PublishDegree.SelectCountriesPlaceholder",
    defaultMessage: "Select countries",
  },
  SelectZonesPlaceholder: {
    id: "PublishDegree.SelectZonesPlaceholder",
    defaultMessage: "Select zones",
  },
  SelectProfessionsPlaceholder: {
    id: "PublishDegree.SelectProfessionsPlaceholder",
    defaultMessage: "Select professions",
  },
  SuccessfulPublishedMessage: {
    id: "PublishDegree.SuccessfulPublishedMessage",
    defaultMessage: "Your degree has been published",
  },
  TitlePlaceholder: {
    id: "PublishDegree.TitlePlaceholder",
    defaultMessage: "Enter title",
  },
  SummaryPlaceholder: {
    id: "PublishDegree.SummaryPlaceholder",
    defaultMessage: "Enter summary",
  },
  IncorrectDateMessage: {
    id: "PublishDegree.IncorrectDateMessage",
    defaultMessage: "End posting date can't be before the start posting date",
  },
  StartPostingDateLabel: {
    id: "PublishDegree.StartPostingDateLabel",
    defaultMessage: "Start posting date",
  },
  EndPostingDateLabel: {
    id: "PublishDegree.EndPostingDateLabel",
    defaultMessage: "End posting date",
  },
  NoEndPostingDateLabel: {
    id: "PublishDegree.NoEndPostingDateLabel",
    defaultMessage: "No end posting date",
  },
  RoleLabel: {
    id: "PublishDegree.RoleLabel",
    defaultMessage: "Roles",
  },
  AddRolesPlaceholder: {
    id: "PublishDegree.AddRolesPlaceholder",
    defaultMessage: "Select roles",
  },
  IndicateBrandsWarning: {
    id: "PublishDegree.IndicateBrandsWarning",
    defaultMessage:
      "Please indicate which brand or brands this content should be associated with before publishing.",
  },
});
