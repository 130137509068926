import React from "react";
import PropTypes from "prop-types";
import { ImagePageEditorStyle } from "./ImagePageEditor.style";
import {
  IMAGE_CONTROL_CROP_VARIATIONS,
  IMAGE_CONTROL_POSITION,
} from "../../configs/constants";
import { imageTypeNames } from "../SideComponentBar/templates/image";
import ChangeTemplate from "../common/ChangeTemplate";
import ImageSelector from "../common/ImageSelector";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../hooks/style/useThemedStyle";
import {
  ImageTypeBoxed,
  SimpleQuizCoverThumbnail,
  SimpleQuizFullScreenThumbnail,
} from "assets/icons";
const useStyle = createUseStyles(ImagePageEditorStyle);

const availableTemplates = [
  {
    id: IMAGE_CONTROL_POSITION.FULLWIDTH,
    name: imageTypeNames.FullWidthText,
    image: SimpleQuizCoverThumbnail,
    defaultProps: {},
  },
  {
    id: IMAGE_CONTROL_POSITION.BOXED,
    name: imageTypeNames.InContainerText,
    image: ImageTypeBoxed,
    defaultProps: {},
  },
  {
    id: IMAGE_CONTROL_POSITION.FULLSCREEN,
    name: imageTypeNames.FullScreenText,
    image: SimpleQuizFullScreenThumbnail,
    defaultProps: {},
  },
];

const ImagePageEditor = (props) => {
  const { type, onImageChange, ...restProps } = props;
  const cropVariation = IMAGE_CONTROL_CROP_VARIATIONS[type];
  const classes = useThemedStyle(useStyle, props);

  const handleImageChange = (url, id, imageType) => {
    onImageChange(url, id, imageType);
  };

  return (
    <div className={classes.imageContainer}>
      {restProps.isSelected && (
        <ChangeTemplate
          selectedTemplateId={type}
          templates={availableTemplates}
          onTemplateSelect={(imageType) =>
            handleImageChange(restProps.sourceImage, restProps.id, imageType)
          }
          className={classes.templateSelector}
        />
      )}
      <ImageSelector
        {...restProps}
        onImageChange={(url, id) => handleImageChange(url, id, type)}
        {...cropVariation}
        cropSize={cropVariation}
      />
    </div>
  );
};

ImagePageEditor.propTypes = {
  /** On upload completed image change */
  onImageChange: PropTypes.func,
  /** image variation **/
  type: PropTypes.oneOf([
    IMAGE_CONTROL_POSITION.FULLWIDTH,
    IMAGE_CONTROL_POSITION.BOXED,
    IMAGE_CONTROL_POSITION.FULLSCREEN,
  ]).isRequired,
  /** + All the props of ImageSelector except:
   * - onImageChange
   * - width
   * - height
   * - cropSize
   * **/
};

export default ImagePageEditor;
