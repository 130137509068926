import gql from "graphql-tag";

export const COPY_POLL = gql`
  mutation pollCopy($pollId: ID, $sourceLang: LangEnum, $destLang: LangEnum!) {
    admin {
      poll(pollId: $pollId, lang: $sourceLang) {
        copy(lang: $destLang) {
          pollId
          versions {
            title
            lang
          }
        }
      }
    }
  }
`;
