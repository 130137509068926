import React, { useState, useEffect } from "react";
import { MasterTemplateStyle } from "../MasterTemplates.style";
import withThemedStyle from "../../../components/hoc/withThemedStyle";
import Navigation from "../../../components/Navigation";
import { translations } from "../MasterTemplates.translations";
import { FormattedDate, FormattedMessage } from "react-intl";
import Filters from "../filters";
import { Icons, VerticalCard, FullscreenLoader } from "genius-ui";
import SettingsMenu from "../../../components/common/SettingsMenu";
import { connect } from "react-redux";
import compose from "lodash/fp/compose";
import {
  GetMasterTemplates,
  ResetStore,
} from "../../../store/masterTemplate/actions";
import { getPath } from "../../../core/paths";
import { DEGREE_PARTS, DOC_WORKFLOW_ENUM } from "../../../configs/constants";

const MasterTemplates = ({
  classes,
  GetMasterTemplates,
  ResetStore,
  isFetching,
  templates,
  history,
  filters: { language },
}) => {
  const [activeMenuTemplateId, setActiveMenuTemplateId] = useState();

  useEffect(() => {
    GetMasterTemplates();
    // TO DO Review proper store data flow and  reset
    // return () => ResetStore()
  }, []);

  const viewMasterTemplate = (id) => () => {
    history.push(getPath("editMasterTemplate", id, language.selectedValue));
  };

  const navigateToDegreeBuilderScreen = (id) => () => {
    history.push(getPath("degreeBuild", id, language.selectedValue));
  };

  const navigateToDegreeAddToMarketScreen = (id) => () => {
    history.push(
      getPath(
        "documentAddToMarket",
        DEGREE_PARTS.DEGREE,
        id,
        language.selectedValue,
      ),
    );
  };

  return (
    <div className={classes.rootContainer}>
      {isFetching && <FullscreenLoader />}
      <Navigation />
      <div className={classes.masterTemplatesContainer}>
        <div className={classes.actionContainer}>
          <div className={classes.title}>
            <FormattedMessage {...translations.Title} />
          </div>
          <Filters />
        </div>
        <div className={classes.cardsContainer}>
          {templates.map((template) => (
            <div key={template.id} className={classes.cardContainer}>
              <VerticalCard
                image_path={template.picCover}
                title={template.title}
                footertext1={
                  template.workflow === DOC_WORKFLOW_ENUM.PUBLISHED ? (
                    <FormattedMessage {...translations.StatusPublished} />
                  ) : (
                    <FormattedMessage {...translations.StatusDraft} />
                  )
                }
                footertext2={
                  <FormattedMessage
                    {...translations.Counters}
                    values={{
                      programs: template.programs,
                      lessons: template.lessons,
                    }}
                  />
                }
                footertext3={
                  <FormattedDate
                    value={template.date}
                    year="numeric"
                    month="long"
                    day="2-digit"
                  />
                }
                handleOnClick={navigateToDegreeBuilderScreen(template.id)}
                handleOnButtonClick={(e) => {
                  e.stopPropagation();
                  setActiveMenuTemplateId(template.id);
                }}
              />
              <SettingsMenu
                handleFocusLeave={() => setActiveMenuTemplateId(undefined)}
                isVisible={activeMenuTemplateId === template.id}
                className={classes.vCardSettingsMenu}
                menuItems={[
                  {
                    label: <FormattedMessage {...translations.ViewOption} />,
                    icon: Icons.info,
                    onItemClick: viewMasterTemplate(template.id),
                  },
                  {
                    label: (
                      <FormattedMessage {...translations.AddToMarketOption} />
                    ),
                    icon: Icons.reply,
                    onItemClick: navigateToDegreeAddToMarketScreen(template.id),
                    disabled: template.workflow === DOC_WORKFLOW_ENUM.DRAFT,
                  },
                ]}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.common.fetchIndicator,
  templates: state.masterTemplates.templates,
  filters: state.masterTemplates.filters,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  GetMasterTemplates: () => dispatch(GetMasterTemplates()),
  ResetStore: () => dispatch(ResetStore()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withThemedStyle(MasterTemplateStyle),
)(MasterTemplates);
