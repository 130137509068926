export const customDropdownStyle = {
  option: (provided, state) => {
    return {
      ...provided,
      borderBottom: "1px solid rgb(245, 245, 245)",
      color: state.isSelected ? "blue" : "#7C7C7C",
      backgroundColor: "white",
      fontSize: "16px",
      padding: "8px",
      "&:hover": {
        backgroundColor: "rgba(70, 142, 227, 0.1)",
      },
      "&:first-of-type": {
        color:
          state.selectProps.isCreatable && state.label.search("Create") !== -1
            ? "rgb(0, 0, 0)"
            : "#7C7C7C",
        fontSize:
          state.selectProps.isCreatable && state.label.search("Create") !== -1
            ? "18px"
            : "16px",
      },
    };
  },
  container: () => ({
    position: "relative",
    boxSizing: "border-box",
  }),
  control: (styles, { isDisabled }) => ({
    alignItems: "center",
    backgroundColor: isDisabled ? "hsl(0,0%,90%)" : "hsl(0,0%,100%)",
    borderColor: "hsl(0,0%,80%)",
    borderRadius: "4px",
    borderStyle: "solid",
    borderWidth: "1px",
    cursor: "default",
    display: "flex",
    flexWrap: "wrap",
    webkitBoxPack: "justify",
    webkitJustifyContent: "space-between",
    msFlexPack: "justify",
    justifyContent: "space-between",
    outline: "0 !important",
    position: "relative",
    webkitTransition: "all 100ms",
    transition: "all 100ms",
    boxSizing: "border-box",
    paddingBottom: "4px",
    minHeight: "47px",
  }),
  multiValue: () => ({
    backgroundColor: "rgb(250, 250, 250)",
    border: "1px solid rgb(240, 240, 240)",
    borderRadius: "2px",
    padding: "6px",
    fontSize: "16px",
    display: "flex",
    marginTop: "4px",
    marginRight: "2px",
  }),
  valueContainer: () => ({
    alignItems: "center",
    display: "flex",
    flex: "1",
    flexWrap: "wrap",
    padding: "2px 8px",
    position: "relative",
    overflow: "hidden",
    boxSizing: "border-box",
    maxWidth: "80%",
  }),
  multiValueRemove: () => ({
    padding: "2px 2px 0 2px",
    borderRadius: "2px",
    marginLeft: "2px",
    "&:hover": {
      color: "rgb(70, 142, 227)",
      backgroundColor: "rgba(70, 142, 227, 0.1)",
    },
  }),
  clearIndicator: () => ({
    display: "hidden",
    wdth: "0",
    height: "0",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};
