import gql from "graphql-tag";

export const GET_CLONED_DOCUMENTS_TAGS = gql`
  query getClonedDocumentsTags($documentId: Int) {
    admin {
      documents(cloneParentId: $documentId) {
        docId
        versions {
          lang
          tags {
            tagId
          }
          workflow
        }
      }
    }
  }
`;
