import gql from "graphql-tag";

export const CARD_CREATE_PAGE = gql`
  mutation cardCreatePage(
    $cardId: ID
    $lang: LangEnum
    $type: PageTypeEnum!
    $data: String!
  ) {
    admin {
      card(cardId: $cardId, lang: $lang) {
        pages {
          create(type: $type, data: $data) {
            pageId
            type
            template
            data
          }
        }
      }
    }
  }
`;
