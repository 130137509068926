import { useSelector } from "react-redux";
import { useMemo } from "react";
import uniq from "lodash/fp/uniq";
import {
  MODULE_CONFIGURATION,
  USER_ROLE_MODULE_ACCESS,
} from "../../core/modules";
import { ENV } from "../../configs/constants";

/**
 * Returns all the paths that the user can access based on it's role
 */
const useAcessiblePaths = () => {
  const { roles } = useSelector((state) => state.auth.profileInfo);
  const accessibleModules = useMemo(
    () =>
      uniq(
        roles.reduce((accessibleModules, roleName) => {
          if (ENV.IS_DEV) {
            if (USER_ROLE_MODULE_ACCESS[roleName]) {
              console.info(
                `useAcessiblePaths: Accessible module configuration found for the user role "${roleName}"`,
              );
            } else {
              console.warn(
                `useAcessiblePaths: Can't find accessible modules for the user role "${roleName}"`,
              );
            }
          }

          return USER_ROLE_MODULE_ACCESS[roleName]
            ? accessibleModules.concat(USER_ROLE_MODULE_ACCESS[roleName])
            : accessibleModules;
        }, []),
      ),
    [roles],
  );

  return useMemo(
    () =>
      accessibleModules.reduce(
        (accessiblePaths, moduleName) =>
          accessiblePaths.concat(MODULE_CONFIGURATION[moduleName].PATHS),
        [],
      ),
    [accessibleModules],
  );
};

export default useAcessiblePaths;
