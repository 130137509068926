import { RESET_STORE } from "../../constants/store/auth";
import {
  UPDATE_PAGE_DATA,
  UPDATE_PAGE_DATA_EXTRA,
  UPDATE_DATA_SESSION,
  UPDATE_UNTRACKED,
  UPDATE_REGISTERED,
  UPDATE_PAGE_DATA_LOADER,
} from "../../constants/store/kpiAppAnalytics";

const initialState = {
  avgNbSessionsPerUserPerDay: [],
  avgSessionTimePerDay: [],
  nbSessionsPerDurationInterval: [],
  sessionsPerDay: [],
  prevTotalConnections: 0,
  prevAverageSessionsNumberPerPeriod: 0,
  prevAverageSessionLengthPerPeriod: 0,
  extraData: {},
  sessionExtract: [],
  totalUntracked: 0,
  totalRegistered: 0,
  prevTotalRegistered: 0,
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;

  switch (type) {
    case UPDATE_PAGE_DATA: {
      const {
        metrics: {
          avgNbSessionsPerUserPerDay,
          avgSessionTimePerDay,
          totalNbSessions,
          sessionsPerDay,
          totalNbUsers,
          nbSessionsPerDurationInterval,
        },
        prevTotalConnections,
        prevAverageSessionsNumberPerPeriod,
        prevAverageSessionLengthPerPeriod,
        prevSessionPerMonthPerUser,
        prevTotalUniqueUsers,
      } = data;

      return {
        ...state,
        avgNbSessionsPerUserPerDay,
        avgSessionTimePerDay,
        nbSessionsPerDurationInterval,
        sessionsPerDay,
        prevSessionPerMonthPerUser,
        totalConnections: totalNbSessions,
        totalUniqueUsers: totalNbUsers,
        prevTotalConnections,
        prevAverageSessionsNumberPerPeriod,
        prevAverageSessionLengthPerPeriod,
        prevTotalUniqueUsers,
      };
    }
    case UPDATE_PAGE_DATA_LOADER: {
      return {
        ...state,
        isLoading: data,
      };
    }
    case UPDATE_PAGE_DATA_EXTRA: {
      const {
        iosUsers,
        iosWebUsers,
        iosAppUsers,
        androidUsers,
        androidWebUsers,
        androidAppUsers,
        webUsers,
        activeUsers,
      } = data;
      return {
        ...state,
        extraData: {
          iosUsers,
          iosWebUsers,
          iosAppUsers,
          androidUsers,
          androidWebUsers,
          androidAppUsers,
          webUsers,
          activeUsers,
        },
      };
    }
    case UPDATE_DATA_SESSION: {
      return {
        ...state,
        sessionExtract: data,
      };
    }
    case UPDATE_UNTRACKED: {
      return {
        ...state,
        totalUntracked: data,
      };
    }
    case UPDATE_REGISTERED: {
      return {
        ...state,
        totalRegistered: data.usersNb,
        prevTotalRegistered: data.prevUsersNb,
      };
    }
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
