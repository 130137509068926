import { defineMessages } from "react-intl";

export const translations = defineMessages({
  DeleteButtonLabel: {
    id: "DragAndDropActivityQuestion.DeleteButtonLabel",
    defaultMessage: "Delete",
  },
  QuestionInputLabel: {
    id: "DragAndDropActivityQuestion.QuestionInputLabel",
    defaultMessage: "Enter a question title here...",
  },
  ZoneTextInputLabel: {
    id: "DragAndDropActivityQuestion.ZoneTextInputLabel",
    defaultMessage: "Enter zone {zoneNumber} placeholder text here...",
  },
});
