import { useSelector } from "react-redux";

export const useIsLoading = () => {
  const showLoader = useSelector(
    (state) => state.kpi.filters.isLoading || state.kpi.filters.isExportLoading,
  );

  return {
    showLoader,
  };
};
