import gql from "graphql-tag";

export const LESSON_REMOVE_PRIVACY = gql`
  mutation lessonsRemovePrivacy($lessonId: ID, $lang: LangEnum) {
    admin {
      lesson(lessonId: $lessonId, lang: $lang) {
        privacy {
          remove
        }
      }
    }
  }
`;
