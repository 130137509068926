import * as constants from "../../constants/store/lesson";
import { RESET_STORE } from "../../constants/store/auth";

const initialState = {
  lessonInfo: {
    id: undefined,
    lang: undefined,
    tags: [],
    brands: [],
    retailers: [],
    title: "",
    summary: "",
    picCover: "",
    lessonRecap: "",
    picLessonRecap: "",
    isPublic: false,
    tagsIsEditable: true,
    showProgramQuizCheckbox: false,
    isProgramQuiz: false,
    pages: [],
    workflow: null,
    startPublishDate: null,
    endPublishDate: null,
  },
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.UPDATE_EDIT_LESSON_DATA: {
      return {
        ...state,
        lessonInfo: {
          ...state.lessonInfo,
          ...data,
        },
      };
    }
    case constants.RESET_LESSON_STORE: {
      return { ...initialState };
    }
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
