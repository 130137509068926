import gql from "graphql-tag";

export const REMOVE_ATTACHMENT = gql`
  mutation removeAttachment($lessonId: ID, $lang: LangEnum, $uploadId: ID!) {
    admin {
      lesson(lessonId: $lessonId, lang: $lang) {
        attachment {
          delete(uploadId: $uploadId)
        }
      }
    }
  }
`;
