import gql from "graphql-tag";

export const PROGRAM_CREATE = gql`
  mutation adminCreateProgram($lang: LangEnum, $title: String!, $degreeId: ID) {
    admin {
      program(lang: $lang) {
        create(title: $title, degreeId: $degreeId) {
          programId
        }
      }
    }
  }
`;
