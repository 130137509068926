import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";
import Style from "./EditNinja.style";
import { Icon, Icons } from "genius-ui";
import NinjaEditor from "../../components/Ninja/NinjaEditor";
import PublishPreviewBanner from "../../components/PublishPreviewBanner";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const EditNinja = (props) => {
  const classes = useThemedStyle(useStyle, props);
  const { questions, NavigateToPreviousPage } = props;

  const onQuestionsChange = useCallback(() => {
    console.log("TODO modifying questions");
  }, []);

  return (
    <>
      <PublishPreviewBanner
        onPublishButtonClick={() => console.log("TODO publishing")}
      >
        <Icon
          iconName={Icons.arrowLeft}
          style={classes.closeIcon}
          handleClick={NavigateToPreviousPage}
        />
      </PublishPreviewBanner>
      <div className={classes.ninjaEditorContainer}>
        <NinjaEditor
          className={classes.ninjaEditor}
          questions={questions}
          onQuestionsChange={onQuestionsChange}
          isActive
        />
      </div>
    </>
  );
};

export default EditNinja;
