export const PublicationButtonsStyles = (theme) => ({
  switchBtn: {
    marginLeft: 30,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 14,
    "& > div": {
      marginLeft: 10,
    },
  },
  buttons: {
    marginTop: 45,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});
