import { REACT_APP_CLIENT_NAME } from "configs/client";

export const EmptyNinjaPageTemplate = {
  template: "WebviewGameTemplate",
  data: {
    url:
      REACT_APP_CLIENT_NAME === "SHISEIDO"
        ? "https://fruit-ninja-dev.southpigalle.io/"
        : REACT_APP_CLIENT_NAME === "SHISEIDO-PREPROD"
        ? "https://fruit-ninja-preprod.southpigalle.io/"
        : "https://fruit-ninja-prod.southpigalle.io/",
    activityName: "Activity",
    activityDescription:
      "Challenge your knowledge by answering the following questions",
    title: "",
    description: "",
    background: {
      type: "image",
      url: "https://cdn-mediastoresopi.azureedge.net/superapp-static/media/ActivityIntroDefaultBG.png",
      theme: "fullScreen",
    },
    // bonusConfig: {
    //   comboBonus: true,
    //   comboAccumulationBonus: true,
    //   timerBonus: true
    // },
    questions: [
      {
        image_type: null,
        image: null,
        text: "",
        timer: 30,
        answers: [],
      },
    ],
  },
};
