export const LessonItemStyle = (theme) => ({
  rootContainer: {
    fontFamily: theme.FontFamily,
    marginBottom: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "& > div:nth-of-type(1)": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    "& .status": {
      fontSize: 14,
      fontWeight: 300,
      letterSpacing: 0.08,
      color: theme.DegreeBadgeLessonScreen.lessonStatusColor,
    },
    "& .image": {
      marginLeft: 10,
      marginRight: 10,
    },
    "& .details": {
      display: "flex",
      flexDirection: "row",
    },
    "& .lessonTitle": {
      fontSize: 16,
      letterSpacing: 0.05,
      color: theme.DegreeBadgeLessonScreen.lessonTitleColor,
      marginBottom: 10,
    },
    "& .lessonDescription": {
      fontSize: 14,
      letterSpacing: 0.08,
      fontWeight: 300,
    },
  },
});
