import * as constants from "../../constants/store/users";
import { RESET_STORE } from "../../constants/store/auth";
import { USER_MANAGEMENT_FILTERS } from "../../configs/client";

const initialState = {
  users: [],
  userCount: 0,
  usersByStatuses: {},
  paginationMetaData: {
    userSearchTerm: "",
    sortState: {},
    usersOnPage: 12,
    page: 0,
    isLoading: false,
  },
  filters: USER_MANAGEMENT_FILTERS.map((filter) => ({
    ...filter,
    tags: [],
    selectedTags: [],
    tagClusterIds: filter.clusters,
  })),
  profiles: {
    // [userId]: {
    //   user: null,
    //   brands: [],
    //   relationship,
    //   retailers,
    // }
  },
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.UPDATE_USERS: {
      return {
        ...state,
        users: data,
      };
    }
    case constants.UPDATE_USER_COUNT: {
      return {
        ...state,
        userCount: data,
      };
    }
    case constants.UPDATE_USER_LIST: {
      return {
        ...state,
        users: [...state.users, ...data.users],
        userCount: data.userCount,
        usersByStatuses: data.usersByStatuses,
      };
    }
    case constants.RESET_USER_LIST: {
      return {
        ...state,
        users: data,
      };
    }
    case constants.UPDATE_PAGINATION_META_DATA: {
      if (state.paginationMetaData.userSearchTerm !== data.userSearchTerm) {
        return {
          ...state,
          paginationMetaData: {
            ...state.paginationMetaData,
            userSearchTerm: data.userSearchTerm,
            page: 0,
          },
          users: [],
        };
      } else {
        return {
          ...state,
          paginationMetaData: data,
        };
      }
    }
    case constants.SET_FILTER_SELECTED_TAGS: {
      return {
        ...state,
        filters: state.filters.map((filter) =>
          filter.name !== data.filterName
            ? filter
            : {
                ...filter,
                selectedTags: data.selectedTags,
              },
        ),
        paginationMetaData: {
          ...state.paginationMetaData,
          page: 0,
        },
        users: [],
      };
    }
    case constants.RESET_FILTER_SELECTED_TAGS: {
      return data;
    }
    case constants.DELETE_USER_BY_ID: {
      return {
        ...state,
        users: state.users.filter(({ userId }) => userId !== data),
      };
    }
    case constants.SET_USERS_LOADING: {
      return {
        ...state,
        paginationMetaData: {
          ...state.paginationMetaData,
          isLoading: data,
        },
      };
    }
    case constants.UPDATE_FILTER_CLUSTERIDS: {
      return {
        ...state,
        filters: state.filters.map((filter) => {
          return filter.name === data.filterName
            ? {
                ...filter,
                clusters: data.clusterIds,
              }
            : filter;
        }),
      };
    }
    case RESET_STORE: {
      return { ...initialState };
    }
    case constants.UPDATE_USERS_PROFILES_DATA: {
      return {
        ...state,
        profiles: data,
      };
    }
    default:
      return state;
  }
}
