import { defineMessages } from "react-intl";

export const translations = defineMessages({
  PollResultLabel: {
    id: "PollResultsScreen.PollResultLabel",
    defaultMessage: "Poll result",
  },
  ParticipantsLabel: {
    id: "PollResultsScreen.ParticipantsLabel",
    defaultMessage: "Number of participants",
  },
  InformUsers: {
    id: "ExportPollResult.InformUsers",
    defaultMessage:
      "Dates selected before September, 2019 will hold no data, and data before end of January 2020 includes only testing or pre-launch training. I also cannot select dates in the future.",
  },
});

export const exportTranslations = defineMessages({
  NameColumnTitle: {
    id: "ExportPollResult.NameColumnTitle",
    defaultMessage: "Name",
  },
  StatusColumnTitle: {
    id: "ExportPollResult.StatusColumnTitle",
    defaultMessage: "Status",
  },
  CreationDateColumnTitle: {
    id: "ExportPollResult.CreationDateColumnTitle",
    defaultMessage: "Creation date",
  },
  PublicationDateColumnTitle: {
    id: "ExportPollResult.PublicationDateColumnTitle",
    defaultMessage: "Publication date",
  },
  ParticipantsColumnTitle: {
    id: "ExportPollResult.ParticipantsColumnTitle",
    defaultMessage: "Number of participants",
  },
  MarketsColumnTitle: {
    id: "ExportPollResult.MarketsColumnTitle",
    defaultMessage: "Markets",
  },
  BrandsColumnTitle: {
    id: "ExportPollResult.BrandsColumnTitle",
    defaultMessage: "Brands",
  },
  QuestionTitle: {
    id: "ExportPollResult.QuestionTitle",
    defaultMessage: "Question",
  },
  AnswersTitle: {
    id: "ExportPollResult.AnswersTitle",
    defaultMessage: "Answers",
  },
  AnswersNumberTitle: {
    id: "ExportPollResult.AnswersNumberTitle",
    defaultMessage: "Answers number",
  },
});
