import React, { useEffect } from "react";
import DefaultNinjaEditor from "./EditNinja";
import NinjaEditorReadOnly from "./ninjaEditorReadOnly";
import clientConfig from "../../configs/client";
import { USER_ROLE_NAMES } from "../../configs/constants";
import { connect } from "react-redux";
import { FullscreenLoader } from "genius-ui";
import { GetNinjaInfo } from "../../store/editNinja/actions";
import { getPath } from "../../core/paths";

const EditNinja = (props) => {
  const { isLoading, isMasterDocument, userRoles, GetNinjaInfo } = props;

  useEffect(() => {
    GetNinjaInfo();
  }, []);

  if (!userRoles || !userRoles.length || isLoading) {
    return <FullscreenLoader />;
  }

  if (
    clientConfig.IS_ROLE_READ_ONLY_MODE_ACTIVE &&
    userRoles.includes(USER_ROLE_NAMES.TRAINER) &&
    isMasterDocument
  ) {
    return <NinjaEditorReadOnly {...props} />;
  }

  return <DefaultNinjaEditor {...props} />;
};

const mapStateToProps = (state) => ({
  userRoles: state.auth.profileInfo.roles,
  isLoading: state.common.fetchIndicator,
  questions: state.editNinja.questions,
  isMasterDocument: state.editNinja.isMasterDocument,
  documentLevel: state.documents.documentLevel,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  GetNinjaInfo: () =>
    dispatch(
      GetNinjaInfo(ownProps.match.params.id, ownProps.match.params.lang),
    ),
  NavigateToPreviousPage: () => {
    ownProps.history.push(getPath("contentManager"));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(EditNinja);
