export default (theme) => ({
  cardContainer: {
    display: "flex",
    position: "relative",
    minWidth: 374,
    maxWidth: 374,
    minHeight: 500,
    alignItems: "center",
    borderRadius: 10,
    flexDirection: "column",
    justifyContent: "flex-start",
    overflow: "hidden",
    fontStyle: "normal",
    lineHeight: 1.33,
    fontWeight: "normal",
    fontFamily: theme.FontFamily,
    fontStretch: "normal",
    letterSpacing: "normal",
    backgroundColor: theme.InstagramCardComponent.containerBackgroundColor,
    boxShadow: `0 2px 8px 0 ${theme.InstagramCardComponent.containerBoxShadow}`,
  },
  image: {
    position: "relative",
    width: "100%",
    height: 350,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundImage: ({ image }) => `url('${image}')`,
  },
  description: {
    minHeight: 100,
    width: "100%",
    fontSize: 16,
    padding: 20,
    boxSizing: "border-box",
  },
  header: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 50,
    fontSize: 13,
    fontWeight: "bold",
    padding: "0 20px",
    boxSizing: "border-box",
  },
  likes: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: theme.InstagramCardComponent.likesColor,
    position: "absolute",
    top: 20,
    left: 20,
    textShadow: `1px 1px ${theme.InstagramCardComponent.likesTextShadow}`,
    fontSize: 13,
    "& > span:before": {
      fontSize: 20,
      marginRight: 5,
    },
  },
  profileContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > div": {
      marginRight: 10,
    },
  },
  logo: {
    textDecoration: "none",
    fontSize: 15,
    color: "rgba(29,161,242,1.00)",
  },
});
