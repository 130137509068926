import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";
import MaskStyle from "./Mask.style";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(MaskStyle);

const Mask = (props) => {
  const { type, className } = props;
  const classes = useThemedStyle(useStyle, props);
  const maskClassName = classnames([
    className,
    classes.mainContainer,
    classes[type],
  ]);

  return <div className={maskClassName} />;
};

Mask.TYPES = {
  DARK_GRAY_SLIGHT: "DARK_GRAY_SLIGHT",
};

Mask.propTypes = {
  type: PropTypes.oneOf(Object.values(Mask.TYPES)),
  className: PropTypes.string,
};

Mask.defaultProps = {
  type: Mask.TYPES.DARK_GRAY_SLIGHT,
};

export default Mask;
