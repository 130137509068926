import { navigationBarWidth } from "../../../components/Navigation/Navigation.style";
import { resultsHeaderHeight } from "../resultsHeader/ResultsHeader.style";

export const resultsContentWidth = 1260;
export const resultsContentPadding = 30;

export const defaultStyles = (theme) => ({
  root: {
    paddingLeft: navigationBarWidth,
    fontFamily: theme.FontFamily,
  },
  content: {
    maxWidth: resultsContentWidth,
    padding: `${
      resultsHeaderHeight - 20
    }px ${resultsContentPadding}px 40px ${resultsContentPadding}px`,
    margin: "auto",
  },
  resultTableHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "40px 0 10px 0",
    "& > .resultTableTitle": {
      fontSize: 23,
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    "& > .numberOfParticipant": {
      fontSize: 14,
      fontWeight: 500,
      color: theme.PollResultsScreen.numberOfParticipantLabelColor,
      "& > span:nth-of-type(2)": {
        color: theme.PollResultsScreen.participantCounterColor,
      },
    },
  },
  shiseidoContainerPlaceHolder: {
    display: "flex",
    justifyContent: "end",
    height: 60,
  },
  shiseidoPlaceHolder: {
    width: 445,
    paddingBottom: 10,
    color: "#a0a0a0",
  },
});
