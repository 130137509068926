import { GetTagsForClusters } from "../../utils/dataTransformation/tags";
import clientConfig, { CONFIG_CLUSTER, isShiseido } from "../../configs/client";
import { PAGE_TEMPLATES } from "../../configs/constants";
import { decodeNewLines } from "../../utils/string";

export const getFormattedLessonData = (
  response,
  lesson,
  lessonId,
  currentLang,
) => {
  const newTags = GetTagsForClusters(
    clientConfig[CONFIG_CLUSTER.EXPERTISE],
    lesson.tags,
  );
  const newBrands = GetTagsForClusters(
    clientConfig[CONFIG_CLUSTER.BRAND],
    lesson.tags,
  );
  const newRetailers = GetTagsForClusters(
    clientConfig[CONFIG_CLUSTER.RETAILER],
    lesson.tags,
  );

  // extract is program quiz data
  let showProgramQuizCheckbox = false;
  let isProgramQuiz = false;
  if (isShiseido) {
    const activityTemplateNames = [
      PAGE_TEMPLATES.SIMPLE_QUIZ_TEMPLATE,
      PAGE_TEMPLATES.MADLIB_TEMPLATE,
      PAGE_TEMPLATES.DRAG_AND_DROP_TEMPLATE,
    ];
    for (
      let i = 0;
      i < lesson.pages.length && (!showProgramQuizCheckbox || !isProgramQuiz);
      i++
    ) {
      const currentPage = lesson.pages[i];
      showProgramQuizCheckbox =
        showProgramQuizCheckbox ||
        activityTemplateNames.includes(currentPage.template);
      if (!isProgramQuiz) {
        const pageContent = JSON.parse(currentPage.data);
        isProgramQuiz = !!pageContent.data.isProgramQuiz;
      }
    }
  }

  return {
    id: lessonId,
    lang: currentLang,
    tags: newTags,
    brands: newBrands,
    retailers: newRetailers,
    title: lesson.title ? decodeNewLines(lesson.title) : "",
    summary: lesson.summary ? decodeNewLines(lesson.summary) : "",
    picCover: lesson.picCover,
    lessonRecap: lesson.lessonRecap ? lesson.lessonRecap : "",
    picLessonRecap: lesson.picLessonRecap,
    isPublic: !lesson.isPrivate,
    tagsIsEditable: true,
    showProgramQuizCheckbox,
    isProgramQuiz,
    pages: lesson.pages,
    workflow: lesson.workflow,
    startPublishDate: lesson.workflowScheduledIn,
    endPublishDate: lesson.workflowScheduledOut,
  };
};
