import React, { useEffect, useMemo } from "react";
import { FullscreenLoader } from "genius-ui";
import PublishSwitchClient from "./clients/SwitchClient";
import { useLoadDocument } from "store/publish/hooks";
import UploadCover from "./components/componentsPage/UploadCover";
import TitleClients from "./components/componentsPage/Title";
import SummaryClients from "./components/componentsPage/Summary";
import "constants/scss/style.scss";

const PublishPage = (props) => {
  const { LoadDocument, loaded } = useLoadDocument();
  const type = useMemo(() => props.location.pathname.match(/\/(.+?)s?\//)[1]);
  useEffect(() => {
    LoadDocument(
      props.match.params[`${type}Id`],
      type,
      props.match.params.lang,
    );
  }, []);
  if (!loaded) {
    return <FullscreenLoader />;
  }

  return (
    <div className="publishContainer">
      <TitleClients type={type} />
      <SummaryClients type={type} />
      <div className="leftContainer">
        <UploadCover type={type} isVideo />
      </div>
      <div className="rightContainer">
        <button className="close" onClick={() => props.history.goBack()} />
        <PublishSwitchClient type={type} {...props} />
      </div>
    </div>
  );
};

export default React.memo(PublishPage);
