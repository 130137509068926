import {
  CONTROL_TYPES,
  EMPTY_CONTENT,
  FILE_TYPES,
  PAGE_TYPES,
  PEANUT_CARD_TYPES,
} from "../../../configs/constants";
import { ArticleTemplate } from "../article.template";
import {
  SliderFullScreenTemplate,
  CardTemplateImage,
  CardTemplateVideo,
  CardTemplateMarkerImage,
  IMAGE_FULL_SCREEN_MARKER_NAME,
} from "../sliderFullScreen.template";
import {
  FullScreenScrollTemplate,
  IMAGE_FULL_SCREEN_MARKER_NAME as IMAGE_FULL_SCREEN_MARKER_NAME_VSLIDER,
  CardTemplateImage as FullScreenScrollCardTemplateImage,
  CardTemplateVideo as FullScreenScrollCardTemplateVideo,
  CardTemplateMarkerImage as FullScreenScrollCardTemplateMarkerImage,
} from "../imageScrollVertical.template";
import { PeanutCardTemplate, PeanutTemplate } from "../peanut.template";
import {
  SimpleQuizTemplate,
  MadlibTemplate,
  DragAndDropActivityTemplate,
} from "../activity.template";
import { DefaultVideoCover } from "assets/icons";
import {
  cleanupHtml,
  encodeNewLines,
  cleanUpAndEncodeText,
  encapsulateWithSpaces,
  excludeBreakLines,
} from "../../string";

export const CleanupTextValue = (value) => {
  const regEx = /&nbsp;/gi;
  return cleanupHtml(value).replace(regEx, " ");
};

export const GetControlJsonValue = (control) => {
  switch (control.ControlType) {
    case CONTROL_TYPES.TITLE: {
      return {
        type: "text",
        content: `${
          control.ControlValue === ""
            ? EMPTY_CONTENT
            : CleanupTextValue(control.ControlValue)
        }`,
        theme: "title",
      };
    }
    case CONTROL_TYPES.SUBTITLE: {
      return {
        type: "text",
        content: `${CleanupTextValue(control.ControlValue)}`,
        theme: "subTitle",
      };
    }
    case CONTROL_TYPES.REGULAR: {
      return {
        type: "text",
        content: `${CleanupTextValue(control.ControlValue)}`,
        theme: "regular",
      };
    }
    case CONTROL_TYPES.IMAGE: {
      return {
        type: "image",
        url: control.ControlValue,
        copyright: "southpigalle",
        legend: null,
        theme: control.ControlVariation,
      };
    }
    case CONTROL_TYPES.VIDEO: {
      return {
        type: "video",
        url: control.ControlValue.sourceVideo,
        copyright: "southpigalle",
        on_pause_url: control.ControlValue.videoCover,
        legend: null,
        theme: control.ControlVariation,
      };
    }
    case CONTROL_TYPES.LINK: {
      return {
        content: control.ControlValue.content,
        text: control.ControlValue.text,
        type: "link",
        theme: "extra",
      };
    }
    default:
      return {};
  }
};

const SAFE_AREA_SIZE = {
  width: 334,
  height: 560,
};

const SAFE_AREA_POSITION = {
  x: 40,
  y: 50,
};

const calcPointsByImage = (point) => ({
  ...point,
  x: (point.x * SAFE_AREA_SIZE.width + SAFE_AREA_POSITION.x + 15) / 414,
  y: (point.y * SAFE_AREA_SIZE.height + SAFE_AREA_POSITION.y + 15) / 737,
});

export const GetSliderImageCardJsonValue = (cardData) => {
  let cardSlideJson;

  switch (cardData.type) {
    case IMAGE_FULL_SCREEN_MARKER_NAME:
      cardSlideJson = { ...CardTemplateMarkerImage };
      cardSlideJson.image = { ...cardSlideJson.image };
      cardSlideJson.image.url = cardData.image;
      cardSlideJson.points = cardData.points.map((point) => ({
        ...point,
        description: point.description
          ? CleanupTextValue(encodeNewLines(point.description))
          : null,
      }));
      cardSlideJson.pointsByImage = cardData.points.map((point) => ({
        ...calcPointsByImage(point),
        description: point.description
          ? CleanupTextValue(encodeNewLines(point.description))
          : null,
      }));
      break;
    default:
      if (cardData.fileType === FILE_TYPES.VIDEO) {
        cardSlideJson = JSON.parse(JSON.stringify(CardTemplateVideo));
        cardSlideJson.video = { ...cardSlideJson.video };
        cardSlideJson.video.url = cardData.video.videoSource;
        cardSlideJson.video.on_pause_url = cardData.video.posterCover
          ? cardData.video.posterCover
          : DefaultVideoCover;
        cardSlideJson.video.urlBackgroundFull = cardData.video.posterCover
          ? cardData.video.posterCover
          : DefaultVideoCover;
        cardSlideJson.formatType = cardData.video.formatType;
      } else {
        cardSlideJson = { ...CardTemplateImage };
        cardSlideJson.image = { ...cardSlideJson.image };
        cardSlideJson.image.url = cardData.image;
      }

      cardSlideJson.description =
        cardData.description && cardData.description !== ""
          ? CleanupTextValue(encodeNewLines(cardData.description))
          : null;
      cardSlideJson.title =
        cardData.title && cardData.title !== ""
          ? CleanupTextValue(encodeNewLines(cardData.title))
          : null;
      break;
  }

  return cardSlideJson;
};

export const GetFullScreenScrollImageCardJsonValue = (cardData) => {
  let cardSlideJson;

  switch (cardData.type) {
    case IMAGE_FULL_SCREEN_MARKER_NAME_VSLIDER:
      cardSlideJson = { ...FullScreenScrollCardTemplateMarkerImage };
      cardSlideJson.image = { ...cardSlideJson.image };
      cardSlideJson.image.url = cardData.image;
      cardSlideJson.points = cardData.points.map((point) => ({
        ...point,
        description: point.description
          ? CleanupTextValue(encodeNewLines(point.description))
          : null,
      }));
      cardSlideJson.pointsByImage = cardData.points.map((point) => ({
        ...calcPointsByImage(point),
        description: point.description
          ? CleanupTextValue(encodeNewLines(point.description))
          : null,
      }));
      break;
    default:
      if (cardData.fileType === FILE_TYPES.VIDEO) {
        cardSlideJson = JSON.parse(
          JSON.stringify(FullScreenScrollCardTemplateVideo),
        );
        cardSlideJson.video = { ...cardSlideJson.video };
        cardSlideJson.video.url = cardData.video.videoSource;
        cardSlideJson.video.on_pause_url = cardData.video.posterCover
          ? cardData.video.posterCover
          : DefaultVideoCover;
        cardSlideJson.video.urlBackgroundFull = cardData.video.posterCover
          ? cardData.video.posterCover
          : DefaultVideoCover;
        cardSlideJson.formatType = cardData.video.formatType;
      } else {
        cardSlideJson = { ...FullScreenScrollCardTemplateImage };
        cardSlideJson.image = { ...cardSlideJson.image };
        cardSlideJson.image.url = cardData.image;
      }

      cardSlideJson.description =
        cardData.description && cardData.description !== ""
          ? CleanupTextValue(encodeNewLines(cardData.description))
          : null;
      cardSlideJson.title =
        cardData.title && cardData.title !== ""
          ? CleanupTextValue(encodeNewLines(cardData.title))
          : null;
      break;
  }

  return cardSlideJson;
};

const deepCopy = (value) => JSON.parse(JSON.stringify(value));

const cleanText = (text) =>
  text ? CleanupTextValue(encodeNewLines(text)) : null;

export const GetPeanutCardJsonValue = (cardData) => {
  const peanutCardJson = deepCopy(PeanutCardTemplate);

  switch (cardData.cardType) {
    case PEANUT_CARD_TYPES.WITH_TEXT:
    case PEANUT_CARD_TYPES.WITH_TOP_TEXT:
    case PEANUT_CARD_TYPES.WITH_MIDDLE_TEXT:
    case PEANUT_CARD_TYPES.WITH_BOTTOM_TEXT:
      peanutCardJson.header.url = cardData.image;
      peanutCardJson.description = cleanText(cardData.description);
      peanutCardJson.title = cleanText(cardData.title);
      if (cardData.cardType !== PEANUT_CARD_TYPES.WITH_TEXT) {
        peanutCardJson.position = cardData.cardType;
      }
      break;
    case PEANUT_CARD_TYPES.WITHOUT_TEXT:
      peanutCardJson.header.url = cardData.image;
      break;
    case PEANUT_CARD_TYPES.WITH_LEGEND:
      peanutCardJson.header.url = cardData.image;
      peanutCardJson.header.legend = cleanText(cardData.legend);
      break;
    default:
      break;
  }
  return peanutCardJson;
};

export const GetActivityIntroJsonValue = ({
  activityDescription,
  title,
  description,
}) => ({
  activityDescription: activityDescription
    ? CleanupTextValue(encodeNewLines(activityDescription))
    : "",
  title: title ? CleanupTextValue(encodeNewLines(title)) : "",
  description: description ? CleanupTextValue(encodeNewLines(description)) : "",
});

export const GetPageJsonValue = (pageType) => {
  switch (pageType) {
    case PAGE_TYPES.ARTICLE:
      return deepCopy(ArticleTemplate);
    case PAGE_TYPES.SLIDERFULLSCREEN:
      return deepCopy(SliderFullScreenTemplate);
    case PAGE_TYPES.FULLSCREENSCROLL:
      return deepCopy(FullScreenScrollTemplate);
    case PAGE_TYPES.PEANUT:
      return deepCopy(PeanutTemplate);
    case PAGE_TYPES.SIMPLEQUIZZ:
      return deepCopy(SimpleQuizTemplate);
    case PAGE_TYPES.MADLIB:
      return deepCopy(MadlibTemplate);
    case PAGE_TYPES.DRAGANDDROP:
      return deepCopy(DragAndDropActivityTemplate);
    default:
      return {};
  }
};

export const GetArticlePageEncodedContent = (controls, pageType) => {
  const jsonFormattedValues = controls
    .map(GetControlJsonValue)
    .filter((y) => y !== {});
  const pageJsonFormat = GetPageJsonValue(pageType);

  if (jsonFormattedValues[0] && jsonFormattedValues[0].type === "image") {
    pageJsonFormat.data.header = jsonFormattedValues[0];
    jsonFormattedValues.splice(0, 1);
  } else if (
    jsonFormattedValues[0] &&
    jsonFormattedValues[0].type === "video"
  ) {
    pageJsonFormat.data.header = jsonFormattedValues[0];
    jsonFormattedValues.splice(0, 1);
  } else {
    pageJsonFormat.data.header = {};
  }

  pageJsonFormat.data.content = jsonFormattedValues;
  return JSON.stringify(pageJsonFormat);
};

export const GetSliderPageEncodedContent = (cards, pageType) => {
  const jsonFormattedValues = cards.map(GetSliderImageCardJsonValue);
  const pageJsonFormat = GetPageJsonValue(pageType);

  pageJsonFormat.data.cards = jsonFormattedValues;
  return JSON.stringify(pageJsonFormat);
};

export const GetFullScreenScrollPageEncodedContent = (cards, pageType) => {
  const jsonFormattedValues = cards.map(GetFullScreenScrollImageCardJsonValue);

  const pageJsonFormat = GetPageJsonValue(pageType);
  pageJsonFormat.data.content = jsonFormattedValues;

  return JSON.stringify(pageJsonFormat);
};

export const GetPeanutPageEncodedContent = (cards, pageType) => {
  const jsonFormattedValues = cards.map(GetPeanutCardJsonValue);
  const pageJsonFormat = GetPageJsonValue(pageType);

  pageJsonFormat.data.cards = jsonFormattedValues;
  return JSON.stringify(pageJsonFormat);
};

export const GetSimpleQuizzEncodedContent = (data, pageType) => {
  const jsonFormattedValues = {
    ...data,
    questions: data.questions.map((question) => ({
      ...question,
      text: CleanupTextValue(encodeNewLines(question.text)),
      answers: question.answers.map(({ answer, isTrue, message }) => ({
        answer: excludeBreakLines(CleanupTextValue(encodeNewLines(answer))),
        isTrue,
        message: CleanupTextValue(encodeNewLines(message)),
      })),
      endMessage: {
        ...question.endMessage,
        text: CleanupTextValue(encodeNewLines(question.endMessage.text)),
      },
    })),
    ...GetActivityIntroJsonValue(data),
  };

  const pageJsonFormat = GetPageJsonValue(pageType);
  pageJsonFormat.data = jsonFormattedValues;
  return JSON.stringify(pageJsonFormat);
};

export const GetMadlibPageEncodedContent = (data, pageType) => {
  const jsonFormattedValues = {
    ...data,
    questions: data.questions.map(({ text, answers }) => ({
      text: encapsulateWithSpaces(
        CleanupTextValue(cleanUpAndEncodeText(text)),
        "[#]",
      ),
      answers: answers.map(({ answer, isTrue }) => ({
        answer: excludeBreakLines(
          CleanupTextValue(cleanUpAndEncodeText(answer)),
        ),
        isTrue,
      })),
    })),
    ...GetActivityIntroJsonValue(data),
  };
  const pageJsonFormat = GetPageJsonValue(pageType);
  pageJsonFormat.data = jsonFormattedValues;
  return JSON.stringify(pageJsonFormat);
};

export const GetDragAndDropActivityEncodedContent = (data, pageType) => {
  const jsonFormattedValues = {
    ...data,
    questions: data.questions.map((question) => ({
      ...question,
      text: question.text && CleanupTextValue(encodeNewLines(question.text)),
      answers: question.answers.map((answer) => ({
        ...answer,
        answer:
          answer.answer &&
          excludeBreakLines(CleanupTextValue(encodeNewLines(answer.answer))),
      })),
      card: {
        ...question.card,
        text:
          question.card.text &&
          excludeBreakLines(
            CleanupTextValue(encodeNewLines(question.card.text)),
          ),
      },
    })),
    ...GetActivityIntroJsonValue(data),
  };

  const pageJsonFormat = GetPageJsonValue(pageType);
  pageJsonFormat.data = jsonFormattedValues;
  return JSON.stringify(pageJsonFormat);
};

export const GetDesignPageEncodeData = (controls, pageType) => {
  switch (pageType) {
    case PAGE_TYPES.ARTICLE:
      return GetArticlePageEncodedContent(controls, pageType);
    case PAGE_TYPES.SLIDERFULLSCREEN:
      return GetSliderPageEncodedContent(controls, pageType);
    case PAGE_TYPES.FULLSCREENSCROLL:
      return GetFullScreenScrollPageEncodedContent(controls, pageType);
    case PAGE_TYPES.PEANUT:
      return GetPeanutPageEncodedContent(controls, pageType);
    case PAGE_TYPES.SIMPLEQUIZZ:
      return GetSimpleQuizzEncodedContent(controls, pageType);
    case PAGE_TYPES.MADLIB:
      return GetMadlibPageEncodedContent(controls, pageType);
    case PAGE_TYPES.DRAGANDDROP:
      return GetDragAndDropActivityEncodedContent(controls, pageType);
    default:
      return "";
  }
};
