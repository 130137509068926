export const isNanOrNull = (value) =>
  value === null ||
  (typeof value !== "string" && isNaN(value)) ||
  (typeof value === "string" && value.includes("null"));

export const calculateValueChangePercentage = (previousValue, currentValue) =>
  ((currentValue - previousValue) * 100) / previousValue;

export const calculateAverage = (...args) =>
  args.reduce((sum, value) => sum + value, 0) / args.length;
