import React from "react";
import { createCustomSelectStyle } from "../../../utils/styling";
import { RSDropdown } from "genius-ui";
import Style from "./GrayStrokedRSDropdown.style";
import { useTheme } from "react-jss";

/** Style wrapper around genius-ui.RSDropdown **/
const GrayStrokedRSDropdown = (props) => {
  const theme = useTheme();
  const ThemedStyle = Style(theme);
  const GrayStrokedRSDropdownStyle = {
    container: createCustomSelectStyle(ThemedStyle.container),
    control: createCustomSelectStyle(ThemedStyle.control),
    placeholder: createCustomSelectStyle(ThemedStyle.placeholder),
    dropdownIndicator: createCustomSelectStyle(ThemedStyle.dropdownIndicator),
  };

  return (
    <RSDropdown
      {...props}
      styles={{
        ...GrayStrokedRSDropdownStyle,
        ...props.styles,
      }}
    />
  );
};

GrayStrokedRSDropdown.propTypes = {
  /** Accepts the same props as genius-ui.RSDropdown as it's just a style wrapper around it **/
};

export default GrayStrokedRSDropdown;
