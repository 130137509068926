import { onError } from "apollo-link-error";
import { store } from "../../store/config/index";
import { SetErrorMessage } from "../../store/notification/actions";
import { ResetRequestErrorData } from "../../store/common/actions";
import { ENV } from "../../configs/constants";
import AuthService from "../../core/authService";
import promiseToObservable from "./promiseToObservable";
import client from "../client";

const auth = new AuthService();

const logoutOnError = () => {
  auth.signOut();
  // history.push('/login')
  // return true
  // Crappy solution for lougout to fix apollo error link handling
  const logoutLocation = window.location;
  window.location = logoutLocation;
};

let isRefreshing = false;
let pendingRequests = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const refreshAccessTokenOnError = () => {
  return auth.refreshToken();
};

// Error Handling
export default onError(
  ({ operation, graphQLErrors, networkError, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (message === "jwt expired") {
          pendingRequests.push(() => {
            if (operation.query.definitions[0].operation === "query")
              client.query(operation);
            else if (operation.query.definitions[0].operation === "mutate")
              client.mutate(operation);
          });

          if (!isRefreshing) {
            isRefreshing = true;
            return promiseToObservable(
              refreshAccessTokenOnError()
                .catch(() => {
                  pendingRequests = [];
                  return logoutOnError();
                })
                .finally(() => {
                  isRefreshing = false;
                  resolvePendingRequests();
                }),
            ).filter((value) => Boolean(value));
          }
        } else {
          if (path && path[0] === "media" && path[1] === "upload") {
            store.dispatch(
              SetErrorMessage(
                "This asset is too heavy or  the file type is not supported",
              ),
            );
          } else {
            store.dispatch(SetErrorMessage(message));
          }
          if (ENV.IS_DEV) {
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            );
          }
          store.dispatch(ResetRequestErrorData());
        }
      });
    }
    if (networkError) {
      if (ENV.IS_DEV) {
        console.error(`[Network error]: ${networkError}`);
      }
      switch (networkError.statusCode) {
        case 401:
        case 403:
          return promiseToObservable(logoutOnError()).flatMap(() =>
            forward(operation),
          );
        default:
          if (ENV.IS_DEV) {
            console.warn(networkError.statusCode, "error not handled");
          }
      }
    }
  },
);
