import client from "../../graphql/client";
import {
  GET_TAGS,
  GET_TAGS_BY_CLUSTER_DEEPNESS,
  GET_TAGS_BY_CLUSTER_ID_PAGINATED,
  GET_TAGS_BY_PARENT_META_CLUSTER_IDS,
} from "../../graphql/remote/tags/queries";
import { SetErrorMessage } from "../notification/actions";
import { mapTagToGetTagId } from "../../utils/transformers";
import {
  SET_ALL_TAGS,
  SET_BRAND_TAGS,
  SET_CITIES_TAGS,
  SET_COUNTRY_TAGS,
  SET_DEPARTMENT_TAGS,
  SET_DIVISION_TAGS,
  SET_ENTITY_TAGS,
  SET_MARKET_TAGS,
  SET_RELATIONSHIP_TAGS,
  SET_RETAILER_TAGS,
  SET_ROLE_TAGS,
  SET_SERVICE_TAGS,
  SET_SHOPS_TAGS,
  SET_USER_TAGS,
  SET_ZONE_TAGS,
  SET_UNIVERSE_TAGS,
  SET_VMSEGMENTATION_TAGS,
  SET_JOURNAL_TAGS,
  SET_OCCUPATION_TAGS,
  RESET_TAGS,
} from "../../constants/store/tags";
import clientConfig, { REACT_APP_CLIENT_NAME } from "../../configs/client";
import {
  ResetCitiesFilters,
  ResetCountriesFilters,
  ResetShopsFilters,
  ResetRetailerFilters,
  ResetRolesFilters,
  ResetServicesFilters,
} from "../kpiFilter/actions";
import { GET_SHOPS_TITLE } from "../../graphql/remote/shops/queries";
import { TAG_CREATE } from "../../graphql/remote/tags/mutations";
import { ENV } from "../../configs/constants";
import { ADD_DOCUMENT_TAG, REMOVE_DOCUMENT_TAG } from "./graphs";

const TAGS_LIMIT = 50;
const SHOP_LIMIT = 100;

export const SetTags = (data) => (dispatch) => {
  dispatch({
    type: SET_ALL_TAGS,
    data,
  });
};

export const GetCountriesByZoneIds = (zoneIds) => async (dispatch) => {
  if (zoneIds.length) {
    dispatch(
      GetTagsByParentMetaClusterIds(zoneIds, (result) =>
        dispatch(SetCountryTags(result.data.tags)),
      ),
    );
  } else {
    dispatch(SetCountryTags([]));
  }
  dispatch(SetCitiesTags([]));
  dispatch(SetShopsTags([]));
  dispatch(ResetCountriesFilters());
  dispatch(ResetCitiesFilters());
  dispatch(ResetShopsFilters());
};

export const GetRetailersByCountryIds =
  (countryIds, loadAll = false) =>
  async (dispatch) => {
    dispatch(
      GetTagsByParentMetaClusterIds(
        countryIds,
        (result) => dispatch(SetRetailerTags(result.data.tags)),
        loadAll,
      ),
    );
    dispatch(ResetRetailerFilters());
  };

export const GetCitiesByCountriesIds =
  (countriesIds, loadAll = false) =>
  async (dispatch) => {
    if (countriesIds.length) {
      dispatch(
        GetTagsByParentMetaClusterIds(
          countriesIds,
          (result) => dispatch(SetCitiesTags(result.data.tags)),
          loadAll,
        ),
      );
    } else {
      dispatch(SetCitiesTags([]));
    }
    dispatch(SetShopsTags([]));
    dispatch(ResetCitiesFilters());
    dispatch(ResetShopsFilters());
  };

export const GetServicesByDepartmentIds =
  (departmentsIds) => async (dispatch) => {
    if (departmentsIds.length) {
      dispatch(
        GetTagsByParentMetaClusterIds(departmentsIds, (result) =>
          dispatch(SetServiceTags(result.data.tags)),
        ),
      );
    } else {
      dispatch(SetServiceTags([]));
    }
    dispatch(SetRoleTags([]));
    dispatch(ResetServicesFilters());
    dispatch(ResetRolesFilters());
  };

export const GetRolesByServicesIds = (servicesIds) => async (dispatch) => {
  if (servicesIds.length) {
    dispatch(
      GetTagsByParentMetaClusterIds(servicesIds, (result) =>
        dispatch(SetRoleTags(result.data.tags)),
      ),
    );
  } else {
    dispatch(SetRoleTags([]));
  }
  dispatch(ResetRolesFilters());
};

export const SetRoleTags = (data) => (dispatch) => {
  dispatch({
    type: SET_ROLE_TAGS,
    data,
  });
};

export const GetShopsByCitiesIds =
  (filters, loadAll = false) =>
  async (dispatch) => {
    try {
      const tagIds = filters.map(mapTagToGetTagId);

      if (tagIds.length) {
        let moreShop;
        let skip = 0;
        let locations = [];

        do {
          const result = await client.query({
            fetchPolicy: "network-only",
            query: GET_SHOPS_TITLE,
            variables: {
              tagIds,
              limit: SHOP_LIMIT,
              skip,
            },
          });

          skip += SHOP_LIMIT;
          locations = [...locations, ...result.data.Location];
          moreShop = loadAll && result.data.Location.length === SHOP_LIMIT;
        } while (moreShop);
        dispatch(
          SetShopsTags(
            locations.map((location) => ({
              tagId: location.locationId,
              title: location.title,
              addressCityTagId: location.addressCity.tagId,
            })),
          ),
        );
      } else {
        dispatch(SetShopsTags([]));
      }
    } catch (e) {
      dispatch(SetErrorMessage("An error occurred while fetching shops", e));
    }
    dispatch(ResetShopsFilters());
  };

export const SetUserTags = (data) => (dispatch) => {
  dispatch({
    type: SET_USER_TAGS,
    data,
  });
};

export const GetUserTags = () => async (dispatch) => {
  dispatch(
    GetTags(clientConfig.USER_TAGS_CLUSTER_IDS, (result) =>
      dispatch(SetUserTags(result.data.tags)),
    ),
  );
};

export const SetCountryTags = (data) => (dispatch) => {
  dispatch({
    type: SET_COUNTRY_TAGS,
    data,
  });
};

export const GetCountryTags = () => async (dispatch) => {
  if (
    ![
      "LOREAL",
      "LOREAL-PROD",
      "DIOR-VM-PREPROD",
      "DIOR-VM",
      "DIOR-VM-PROD",
      "VCA",
    ].includes(REACT_APP_CLIENT_NAME)
  ) {
    dispatch(
      GetTags(clientConfig.USER_COUNTRIES_CLUSTER_IDS, (result) =>
        dispatch(SetCountryTags(result.data.tags)),
      ),
    );
  }
};

export const SetRelationshipTags = (data) => (dispatch) => {
  dispatch({
    type: SET_RELATIONSHIP_TAGS,
    data,
  });
};

export const GetRelationshipTags = () => async (dispatch) => {
  dispatch(
    GetTags(clientConfig.USER_RELATIONSHIP_CLUSTER_IDS, (result) =>
      dispatch(SetRelationshipTags(result.data.tags)),
    ),
  );
};

export const GetDivisionTags = () => async (dispatch) => {
  dispatch(
    GetTags(clientConfig.DIVISION_CLUSTER_IDS, (result) =>
      dispatch(SetDivisionsTags(result.data.tags)),
    ),
  );
};

export const GetEntityTags = () => async (dispatch) => {
  dispatch(
    GetTags(clientConfig.ENTITY_CLUSTER_IDS, (result) =>
      dispatch(SetEntityTags(result.data.tags)),
    ),
  );
};

export const SetMarketTags = (data) => (dispatch) => {
  dispatch({
    type: SET_MARKET_TAGS,
    data,
  });
};

export const GetMarketTags = () => async (dispatch) => {
  dispatch(
    GetTags(clientConfig.USER_MARKET_CLUSTER_IDS, (result) =>
      dispatch(SetMarketTags(result.data.tags)),
    ),
  );
};

export const SetZoneTags = (data) => (dispatch) => {
  dispatch({
    type: SET_ZONE_TAGS,
    data,
  });
};

export const SetUniverseTags = (data) => (dispatch) => {
  dispatch({
    type: SET_UNIVERSE_TAGS,
    data,
  });
};

export const SetJournalTags = (data) => (dispatch) => {
  dispatch({
    type: SET_JOURNAL_TAGS,
    data,
  });
};

export const SetOccupationTags = (data) => (dispatch) => {
  dispatch({
    type: SET_OCCUPATION_TAGS,
    data,
  });
};

export const SetVMSegmentationTags = (data) => (dispatch) => {
  dispatch({
    type: SET_VMSEGMENTATION_TAGS,
    data,
  });
};

export const GetZoneTags = () => async (dispatch) => {
  dispatch(
    GetTags(clientConfig.ZONE_CLUSTER_IDS, (result) =>
      dispatch(SetZoneTags(result.data.tags)),
    ),
  );
};

export const GetCityTags = () => async (dispatch) => {
  dispatch(
    GetTags(clientConfig.CITY_CLUSTER_IDS, (result) =>
      dispatch(SetCitiesTags(result.data.tags)),
    ),
  );
};

export const GetUniverseTags = () => async (dispatch) => {
  dispatch(
    GetTags(clientConfig.UNIVERSE_CLUSTER_IDS, (result) =>
      dispatch(SetUniverseTags(result.data.tags)),
    ),
  );
};

export const GetJournalTags = () => async (dispatch) => {
  dispatch(
    GetTags(clientConfig.JOURNAL_CLUSTER_IDS, (result) =>
      dispatch(SetJournalTags(result.data.tags)),
    ),
  );
};

export const GetOccupationTags = () => async (dispatch) => {
  dispatch(
    GetTags(clientConfig.OCCUPATION_CLUSTER_IDS, (result) =>
      dispatch(SetOccupationTags(result.data.tags)),
    ),
  );
};

export const GetVMSegmentationTags = () => async (dispatch) => {
  dispatch(
    GetTags(clientConfig.VMSEGMENTATION_CLUSTER_IDS, (result) =>
      dispatch(SetVMSegmentationTags(result.data.tags)),
    ),
  );
};

export const SetRetailerTags = (data) => (dispatch) => {
  dispatch({
    type: SET_RETAILER_TAGS,
    data,
  });
};

export const GetRetailerTags = () => async (dispatch) => {
  if (
    ["SHISEIDO", "SHISEIDO-PREPROD", "SHISEIDO-PROD"].includes(
      REACT_APP_CLIENT_NAME,
    )
  ) {
    dispatch(SetRetailerTags([]));
  } else {
    dispatch(
      GetTags(clientConfig.RETAILER_CLUSTER_IDS, (result) =>
        dispatch(SetRetailerTags(result.data.tags)),
      ),
    );
  }
};

export const SetBrandTags = (data) => (dispatch) => {
  dispatch({
    type: SET_BRAND_TAGS,
    data,
  });
};

export const SetDepartmentTags = (data) => (dispatch) => {
  dispatch({
    type: SET_DEPARTMENT_TAGS,
    data,
  });
};

export const GetDepartmentTags = () => async (dispatch) => {
  dispatch(
    GetTags(clientConfig.DEPARTMENT_CLUSTER_IDS, (result) =>
      dispatch(SetDepartmentTags(result.data.tags)),
    ),
  );
};

export const GetBrandTags = () => async (dispatch) => {
  /*
  Change by Jalel commit b56e7c9 but victor ask to come back

  const { filters } = getState().kpi
  const generalFilterValues = extractFilterValues(filters)
  const tagIds = generalFilterValues?.market?.tagIds

  if (tagIds?.length) {
    const brandResult = await client.query({
      fetchPolicy: 'network-only',
      query: GET_BRAND_TAGS_BY_MARKET_ID,
      variables: {
        clusterIds: clientConfig.COUNTRY_TAGS_CLUSTERIDS,
        tagIds
      }
    })
    dispatch(
      SetBrandTags(brandResult.data.tags.flatMap(country => country.tagsToCopyMultiple))
    )
  } else {
    dispatch(
      GetTags(
        clientConfig.BRAND_TAGS_CLUSTER_IDS,
        result => dispatch(
          SetBrandTags(result.data.tags)
        )
      )
    )
  }
  */
  dispatch(
    GetTags(clientConfig.BRAND_TAGS_CLUSTER_IDS, (result) =>
      dispatch(SetBrandTags(result.data.tags)),
    ),
  );
};

export const GetRoleTags = () => async () => {};

export const GetAllTags = () => (dispatch) => {
  dispatch(GetUserTags());
  dispatch(GetCountryTags());
  dispatch(GetRelationshipTags());
  dispatch(GetMarketTags());
  dispatch(GetZoneTags());
  dispatch(GetRetailerTags());
  dispatch(GetBrandTags());
  dispatch(GetDivisionTags());
  dispatch(GetEntityTags());
  dispatch(GetDepartmentTags());
  dispatch(GetRoleTags());
};

export const GetTags = (clusterIds, onSuccess) => async (dispatch) => {
  try {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_TAGS,
      variables: {
        clusterIds,
      },
    });

    onSuccess(result);
  } catch (e) {
    dispatch(SetErrorMessage("An error occurred while fetching the tags", e));
  }
};

export const GetTagsByParentMetaClusterIds =
  (clusterMetaTagIds, onSuccess, loadAll = false) =>
  async (dispatch) => {
    try {
      const globalResult = {
        data: {
          tags: [],
        },
      };
      let moreTags;
      let skip = 0;

      do {
        const result = await client.query({
          fetchPolicy: "network-only",
          query: GET_TAGS_BY_PARENT_META_CLUSTER_IDS,
          variables: {
            clusterMetaTagIds,
            limit: TAGS_LIMIT,
            skip: skip,
          },
        });

        skip += TAGS_LIMIT;
        globalResult.data.tags = [
          ...globalResult.data.tags,
          ...result.data.tags,
        ];
        moreTags = loadAll && result.data.tags.length === TAGS_LIMIT;
      } while (moreTags);
      onSuccess(globalResult);
    } catch (e) {
      dispatch(SetErrorMessage("An error occurred while fetching the tags", e));
    }
  };

export const SetCitiesTags = (data) => (dispatch) => {
  dispatch({
    type: SET_CITIES_TAGS,
    data,
  });
};

export const SetShopsTags = (data) => (dispatch) => {
  dispatch({
    type: SET_SHOPS_TAGS,
    data,
  });
};

export const SetDivisionsTags = (data) => (dispatch) => {
  dispatch({
    type: SET_DIVISION_TAGS,
    data,
  });
};

export const SetEntityTags = (data) => (dispatch) => {
  dispatch({
    type: SET_ENTITY_TAGS,
    data,
  });
};

export const SetServiceTags = (data) => (dispatch) => {
  dispatch({
    type: SET_SERVICE_TAGS,
    data,
  });
};

export const ResetTags = () => (dispatch) => {
  dispatch({
    type: RESET_TAGS,
  });
};

export const GetTagsByClusterDeepness =
  (clusterDeepnesses, onSuccess) => async (dispatch) => {
    try {
      const result = await client.query({
        fetchPolicy: "network-only",
        query: GET_TAGS_BY_CLUSTER_DEEPNESS,
        variables: {
          clusterDeepnesses,
        },
      });

      onSuccess(result);
    } catch (e) {
      dispatch(SetErrorMessage("An error occurred while fetching the tags", e));
    }
  };

const fetchTags = async (search, page, props) => {
  try {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_TAGS_BY_CLUSTER_ID_PAGINATED,
      variables: {
        clusterMetaTagIds: props.clusterMetaTagIds,
        clusterIds: props.clusterIds,
        autocomplete: search || undefined,
        skip: page * props.tagsOnPage,
        limit: props.tagsOnPage,
      },
    });

    return result.data.tags;
  } catch (e) {
    if (ENV.IS_DEV) {
      console.error(`[Application error]: ${e}`);
    }
  }

  return [];
};

const formatTags = (tags) =>
  tags.map((tag) => ({
    value: tag.tagId,
    label: tag.title,
  }));

export const loadTags = async (
  search,
  prevOptions,
  { page, clusterIds, clusterMetaTagIds, tagsOnPage },
) => {
  const tags = await fetchTags(search, page, {
    clusterIds,
    clusterMetaTagIds,
    tagsOnPage: tagsOnPage || 10,
  });
  const options = formatTags(tags);

  return {
    options,
    hasMore: options.length === (tagsOnPage || 10),
    additional: {
      page: page + 1,
      clusterMetaTagIds,
      clusterIds,
      tagsOnPage,
    },
  };
};

export const createTag = async (clusterId, label) => {
  try {
    const result = await client.mutate({
      mutation: TAG_CREATE,
      variables: {
        tag: label,
        clusterId,
      },
    });
    return result.data.tag.createCustom.tagId;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const DocumentTagsAction =
  (addAction, documentType, docId, lang, tagIds, onError) =>
  async (dispatch) => {
    try {
      await client.mutate({
        mutation: addAction
          ? ADD_DOCUMENT_TAG(documentType)
          : REMOVE_DOCUMENT_TAG(documentType),
        variables: {
          docId,
          lang,
          tagIds,
        },
      });
    } catch (e) {
      onError && onError();
      dispatch(
        SetErrorMessage(
          `An error occurred while updating the tags of the ${documentType} ${docId}`,
          e,
        ),
      );
    }
  };
