import React from "react";
import PropTypes from "prop-types";
import uuidv4 from "uuid/dist/v4";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import SlideMarker, {
  SLIDE_MARKER_SIZE,
  SLIDE_MARKER_DRAG_AREA_SIZE,
} from "../SlideMarker";
import DragAndDropLayer from "../DragAndDropLayer";
import { SetWarningMessage } from "../../../store/notification/actions";
import { CROP_VARIATION } from "../../../configs/constants";
import { translations } from "./MarkerLayer.translations";

class MarkerLayer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      markers: [],
      croppedMarkerId: null,
      isCropVisible: false,
      image: "",
    };

    this.dndLayerId = `DNDLayer${uuidv4()}`;
  }

  componentDidMount() {
    this.setState({
      markers: this.props.markers.map(this.pointToMarker),
      image: this.props.image,
    });
  }

  handleChange = () => {
    const points = this.state.markers.map(this.markerToPoint);

    this.props.onChange(points);
  };

  changeMarkerDescription = (markerId, description) => {
    this.setState((state) => ({
      markers: this.changePropsOfMarker(
        markerId,
        { description },
        state.markers,
      ),
    }));
  };

  changeMarkerImage = (markerId, image) => {
    this.setState(
      (state) => ({
        markers: this.changePropsOfMarker(markerId, { image }, state.markers),
      }),
      this.handleChange,
    );
  };

  markerToPoint = ({ x, y, image, description }) => ({
    x: x / this.props.safeAreaSize.width, // convert css coordinate to value between 0 and 1
    y: y / this.props.safeAreaSize.height, // convert css coordinate to value between 0 and 1
    image,
    description,
  });

  pointToMarker = (point) => ({
    id: uuidv4(),
    width: SLIDE_MARKER_SIZE.width,
    height: SLIDE_MARKER_SIZE.height,
    dragAreaWidth: SLIDE_MARKER_DRAG_AREA_SIZE.width,
    dragAreaHeight: SLIDE_MARKER_DRAG_AREA_SIZE.height,
    ...point,
    isStatic: point.isOpened || false,
    isOpened: point.isOpened || false,
    x: point.x * this.props.safeAreaSize.width, // convert value between 0 and 1 to css coordinate
    y: point.y * this.props.safeAreaSize.height, // convert value between 0 and 1 to css coordinate
  });

  addMarker = (marker) => {
    if (
      this.props.maxNrOfMarkers === 0 ||
      (this.props.maxNrOfMarkers &&
        this.state.markers.length >= this.props.maxNrOfMarkers)
    ) {
      this.props.WarningAlert(
        this.props.intl.formatMessage(translations.MaximumMarkersWarning, {
          maximumMarkers: this.props.maxNrOfMarkers,
        }),
      );
      return;
    }

    /** add new marker only if the user clicked inside the safe area and if marker isn't overlaying other markers **/
    if (!this.isMarkerPositionSafe(marker)) {
      this.props.WarningAlert(
        this.props.intl.formatMessage(
          translations.InvalidMarkerPositionWarning,
        ),
      );
      return;
    }

    this.setState(
      (state) => ({
        markers: [...state.markers, marker],
      }),
      this.handleChange,
    );
  };

  removeMarker = (markerId) => {
    this.setState(
      (state) => ({
        markers: state.markers.filter(({ id }) => id !== markerId),
      }),
      this.handleChange,
    );
  };

  addNewMarker = (e) => {
    /** TO-DO: best solution would be to avoid the memory leak and not verifying the existence of
     * this.dragLayerMouseDownCoordinates. maybe after the refactor **/
    if (this.props.isLayerActive && this.dragLayerMouseDownCoordinates) {
      // add new marker to slide if the marker layer is active
      /** don't add a new marker when the mouse was dragged before the click button was released.
       * With this we prevent adding a new marker to the slide after the user moved/tried to move an other marker  **/
      const dragTolerance = 10;
      if (
        Math.abs(e.clientX - this.dragLayerMouseDownCoordinates.x) <=
          dragTolerance &&
        Math.abs(e.clientY - this.dragLayerMouseDownCoordinates.y) <=
          dragTolerance
      ) {
        /** calculate the position of the click relative to the top-left corner of the drag layer and add marker to that
         * position **/
        // drag layer box parameters(absolute position & size)
        const rect = document
          .getElementById(this.dndLayerId)
          .getBoundingClientRect();
        // position x of the click relative to the top-left corner of the drag layer
        const clickX = e.clientX - rect.left;
        // position y of the click relative to the top-left corner of the drag layer
        const clickY = e.clientY - rect.top;
        const marker = {
          // x position of the marker inside the safe area
          x: clickX - this.props.safeAreaPosition.x - 15,
          // y position of the marker inside the safe area
          y: clickY - this.props.safeAreaPosition.y - 15,
          width: SLIDE_MARKER_SIZE.width,
          height: SLIDE_MARKER_SIZE.height,
          dragAreaWidth: SLIDE_MARKER_DRAG_AREA_SIZE.width,
          dragAreaHeight: SLIDE_MARKER_DRAG_AREA_SIZE.height,
        };

        this.addMarker(this.createNewMarker(marker));
      }
    }
  };

  setMarkerPosition = (changedMarker, x, y) =>
    this.setState(
      (state) => ({
        markers: this.changePropsOfMarker(
          changedMarker.id,
          { x, y },
          state.markers,
        ),
      }),
      this.handleChange,
    );

  toggleMarkerStaticState = (changedMarkerId) =>
    this.setState(
      (state) => ({
        markers: this.changePropsOfMarker(
          changedMarkerId,
          (currentState) => ({
            isStatic: !currentState.isStatic,
            isOpened: !currentState.isOpened,
          }),
          state.markers,
          (marker) => ({
            // toggling the state of other markers to false(don't allow to open tow markers at the same time)
            isStatic: false,
            isOpened: false,
          }),
        ),
      }),
      () => {
        const changedMarkerIndex = this.state.markers.findIndex(
          (marker) => marker.id === changedMarkerId,
        );
        const changedMarker = this.state.markers[changedMarkerIndex];
        this.props.onMarkerOpened &&
          this.props.onMarkerOpened(changedMarkerIndex, changedMarker.isOpened);
      },
    );

  changePropsOfMarker = (
    changedMarkerId,
    changedProps,
    markers,
    unchangedMarkerCallback = null,
  ) =>
    markers.map((marker) =>
      changedMarkerId !== marker.id
        ? {
            ...marker,
            ...(typeof unchangedMarkerCallback === "function"
              ? unchangedMarkerCallback(marker)
              : {}),
          }
        : {
            ...marker,
            ...(typeof changedProps !== "function"
              ? changedProps
              : changedProps(marker)),
          },
    );

  createNewMarker = ({
    x = 0,
    y = 0,
    image = null,
    description = null,
    isStatic = false,
    isOpened = false,
    width = SLIDE_MARKER_SIZE.width,
    height = SLIDE_MARKER_SIZE.height,
    dragAreaWidth = SLIDE_MARKER_DRAG_AREA_SIZE.width,
    dragAreaHeight = SLIDE_MARKER_DRAG_AREA_SIZE.height,
  }) => ({
    id: uuidv4(),
    x,
    y,
    image,
    description,
    isStatic,
    isOpened,
    width,
    height,
    dragAreaWidth,
    dragAreaHeight,
  });

  isMarkerPositionSafe = (marker) =>
    this.isMarkerInSafeArea(marker) &&
    !this.isMarkerOverLayingOtherMarker(marker);

  /** check if marker position is inside the safe area **/
  isMarkerInSafeArea = (marker) => {
    const dragAreaCoordinates = this.getMarkerDragAreaCoordinates(marker);
    // verifying whether the drag area around the marker exceeds the safe area's coordinates
    return (
      dragAreaCoordinates.x >= 0 &&
      dragAreaCoordinates.x + dragAreaCoordinates.width <=
        this.props.safeAreaSize.width &&
      dragAreaCoordinates.y >= 0 &&
      dragAreaCoordinates.y + dragAreaCoordinates.height <=
        this.props.safeAreaSize.height
    );
  };

  /** check if marker isn't overlaying other markers **/
  isMarkerOverLayingOtherMarker = (subjectMarker) => {
    const dragAreaCoordinates =
      this.getMarkerDragAreaCoordinates(subjectMarker);
    const dragAreaMaxCoords = this.getRectMaxCoordinates(dragAreaCoordinates);

    /** iterate over the markers and check if the subjectMarker's drag area intersects with any other marker's area.
     * return true when the first intersecting marker is found **/
    return this.state.markers.some((marker) => {
      if (subjectMarker.id !== marker.id) {
        const markerMaxCoords = this.getRectMaxCoordinates(marker);
        /** 2 rects collide when they have at least one common point.
         * here we check the cases when they don't intersect at all.
         * if they don't intersect on one of the axes(x or y), it means, that they don't have a common point so they
         * don't collide. **/
        if (
          !(
            dragAreaMaxCoords.x < marker.x ||
            dragAreaCoordinates.x > markerMaxCoords.x ||
            dragAreaCoordinates.y > markerMaxCoords.y ||
            dragAreaMaxCoords.y < marker.y
          )
        ) {
          // all the cases are false, so the 2 rects collide
          return true;
        }
      }
      return false;
    });
  };

  /** get the maximum coordinates of a rect(object having {x, y, width, height} coordinates) **/
  getRectMaxCoordinates = (rect) => ({
    x: rect.x + rect.width,
    y: rect.y + rect.height,
  });

  /** Returns width and height of the drag area respectively the x and y coordinates of the drag area calculated based
   * on the markerPosition**/
  getMarkerDragAreaCoordinates = (marker) => ({
    width: marker.dragAreaWidth,
    height: marker.dragAreaHeight,
    // marker is always in the middle of the drag area. that's where the substraction of the width&height / 2 comes from
    x: marker.x - (marker.dragAreaWidth - marker.width) / 2,
    y: marker.y - (marker.dragAreaHeight - marker.height) / 2,
  });

  validateMarkerDragPosition = (marker, dragPosition) => {
    const markerWithNewPosition = {
      ...marker,
      ...dragPosition,
    };
    return this.isMarkerPositionSafe(markerWithNewPosition);
  };

  getMarkerContainerProps = (marker) =>
    marker.isOpened
      ? {
          style: {
            zIndex: 1,
          },
        }
      : undefined;

  handleUploadSuccess = (url) =>
    this.setState({ image: url }, this.handleChangeImage);

  changeCropVisibility = (visible, markerId) => () =>
    this.setState({ isCropVisible: visible, markerId });

  renderMarker = (
    marker,
    isDragged = false,
    isDropTargetSafe = true,
    uniqueId = "slideMarker",
  ) => (
    <span onMouseUp={(e) => e.stopPropagation()}>
      {" "}
      {/* prevent adding a new marker when clicking on the marker */}
      <SlideMarker
        uniqueId={`${this.props.uniqueId}-${uniqueId}`}
        id={marker.id}
        isOpened={marker.isOpened}
        isEditable={this.props.isLayerActive}
        onOpenedStateToggled={this.toggleMarkerStaticState}
        onDelete={this.removeMarker}
        image={marker.image}
        onImageUploadSuccess={this.changeMarkerImage}
        description={marker.description}
        onDescriptionChanged={this.changeMarkerDescription}
        onDescriptionEdited={this.handleChange}
        isDragged={isDragged}
        isDropTargetSafe={isDropTargetSafe}
        cropSize={CROP_VARIATION.MAKER_IMAGE}
        isCropVisible={
          this.state.isCropVisible && marker.id === this.state.markerId
        }
        onCropClick={this.changeCropVisibility(true, marker.id)}
        onCancel={this.changeCropVisibility(false)}
      />
    </span>
  );

  render() {
    let props = this.props;
    let { safeAreaSize, safeAreaPosition, layerSize } = props;

    return (
      <>
        <DragAndDropLayer
          id={this.dndLayerId}
          elements={this.state.markers}
          renderElement={this.renderMarker}
          getDraggedElementContainerProps={this.getMarkerContainerProps}
          setElementPosition={this.setMarkerPosition}
          validateElementPosition={this.validateMarkerDragPosition}
          size={layerSize}
          safeAreaSize={safeAreaSize}
          safeAreaPosition={safeAreaPosition}
          onMouseDown={(e) =>
            (this.dragLayerMouseDownCoordinates = {
              x: e.clientX,
              y: e.clientY,
            })
          }
          onMouseUp={
            this.props.isCropped ? this.addNewMarker : this.props.onChange
          }
          isActive={this.props.isLayerActive}
        />
        {!!this.state.croppedMarkerId && this.renderMarkerImageCropper()}
      </>
    );
  }
}

MarkerLayer.propTypes = {
  /** String that uniquely identifies marker layer on the same page and it's consistent across multiple page refreshes **/
  uniqueId: PropTypes.string.isRequired,
  /** Function fired when marker data has been changed **/
  onChange: PropTypes.func.isRequired,
  /** Whether the markers are editable or not **/
  isLayerActive: PropTypes.bool.isRequired,
  /** Size of the marker layer **/
  layerSize: PropTypes.object.isRequired,
  /** Size of the safe area(area in which markers are allowed to be dragged) **/
  safeAreaSize: PropTypes.object.isRequired,
  /** Position of the safe area inside the marker layer **/
  safeAreaPosition: PropTypes.object.isRequired,
  /** The initial markers **/
  markers: PropTypes.array.isRequired,
  /** Maximum number of markers, that can be added to the marker layer. Unlimited number of markers if omitted **/
  maxNrOfMarkers: PropTypes.number,
  /** Injected react-intl object **/
  intl: PropTypes.object.isRequired,
  /** function executed when a marker is opened or closed **/
  onMarkerOpened: PropTypes.func,
  isCropped: PropTypes.bool,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  WarningAlert: (message) => dispatch(SetWarningMessage(message)),
});

export default compose(
  injectIntl,
  connect(undefined, mapDispatchToProps),
)(MarkerLayer);
