import React from "react";
import {
  Button,
  ButtonSize,
  ButtonVariation,
  Icon,
  Icons,
  OrderArrow,
  Tag,
} from "genius-ui";
import SettingsMenu from "../../../../../components/common/SettingsMenu";
import {
  DEGREE_PARTS,
  DOC_WORKFLOW_ENUM,
} from "../../../../../configs/constants";
import { FormattedMessage, injectIntl } from "react-intl";
import style from "./ProgramInfo.style";
import { createUseStyles } from "react-jss";
import uuidV4 from "uuid/dist/v4";
import { translations } from "./ProgramInfo.translations";
import FilterCountries from "../../../../../components/FilterCountries";
import useThemedStyle from "../../../../../hooks/style/useThemedStyle";
import BulletTextInput from "../../../../../components/common/BulletTextInput";

const useStyle = createUseStyles(style);

const showNotificationModal = (props, currentItem) => () => {
  props.setNotificationData({
    docId: currentItem.lessonId,
    lang: currentItem.lang,
    docType: DEGREE_PARTS.LESSON,
    selectableTags: currentItem.marketTags,
  });
  props.setShowNotificationModal(true);
};

const ProgramInfo = (props) => {
  const { program, intl } = props;
  const classes = useThemedStyle(useStyle, props);
  const currentTitle =
    props.program && props.program.title ? props.program.title : "";
  const currentSummary =
    props.program && props.program.summary ? props.program.summary : "";
  const expandCollapseIconName = props.program.expanded
    ? Icons.arrowOrderUp
    : Icons.arrowOrderDown;

  const displayStatus = (status) => {
    return props.isMasterContent && status === DOC_WORKFLOW_ENUM.PUBLISHED ? (
      <FormattedMessage {...translations.AvailableToUseLabel} />
    ) : (
      status.toLowerCase()
    );
  };

  return (
    <div className={classes.center}>
      <div className={classes.left}>
        <div className={classes.programBox}>
          <div className={classes.programTitleWrap}>
            <input
              type="text"
              value={currentTitle}
              className={classes.textInputEditTitle}
              onChange={(e) => {
                props.onTitleChange(e.target.value);
              }}
              onBlur={props.handleProgramInfoSave}
              readOnly={props.isMasterContent}
            />
            <div className={classes.programActionContainer}>
              <div>
                {!props.isMasterContent && (
                  <Icon
                    iconName={Icons.roundMore}
                    style={classes.settingsIcon}
                    handleClick={() =>
                      props.setActiveMenuProgramId(program.programId)
                    }
                  />
                )}
                <SettingsMenu
                  handleFocusLeave={() =>
                    props.setActiveMenuProgramId(undefined)
                  }
                  isVisible={
                    props.currentEditingProgramId &&
                    props.currentEditingProgramId === program.programId
                  }
                  className={classes.programSettingsMenu}
                  menuItems={[
                    {
                      label: intl.formatMessage({
                        ...translations.DeleteOption,
                      }),
                      icon: Icons.delete,
                      onItemClick: () =>
                        props.setDeleteItemData(
                          program.programId,
                          DEGREE_PARTS.PROGRAM,
                        ),
                    },
                  ]}
                />
                <Icon
                  iconName={expandCollapseIconName}
                  style={classes.expandCollapseIcon}
                  handleClick={() => {
                    props.setActiveMenuProgramId(undefined);
                    props.expandCollapseProgram();
                  }}
                />
              </div>
            </div>
          </div>
          {!!props.program.expanded && (
            <div className={classes.lessonContainer}>
              {props.program.lessons.map((x, index) => {
                const lessonsNumber = props.program.lessons.length;
                let lessonMenuItems = [];

                if (props.isTraineRole && !props.isMasterContent) {
                  lessonMenuItems = [
                    ...lessonMenuItems,
                    ...[
                      {
                        label: (
                          <FormattedMessage {...translations.PublishOption} />
                        ),
                        icon: Icons.settings,
                        onItemClick: () => {
                          props.navigateToLessonPublishPage(x.lessonId);
                        },
                      },
                      {
                        label: (
                          <FormattedMessage {...translations.DeleteOption} />
                        ),
                        icon: Icons.delete,
                        onItemClick: () =>
                          props.setDeleteItemData(
                            x.lessonId,
                            DEGREE_PARTS.LESSON,
                            program.programId,
                          ),
                      },
                      {
                        label: intl.formatMessage({
                          ...translations.MoveToProgramOption,
                        }),
                        icon: Icons.reply,
                        onItemClick: () =>
                          props.showLessonRelocationModal(
                            x.lessonId,
                            program.programId,
                          ),
                        disabled: props.programCnt < 2,
                      },
                      /*{
                          label: <FormattedMessage {...translations.BadgeOption} />,
                          icon: Icons.reward,
                          onItemClick: () => props.goToBadgesScreen(x.lessonId)
                        },*/
                      {
                        label: intl.formatMessage({
                          ...translations.SendNotificationOption,
                        }),
                        icon: Icons.notification,
                        onItemClick: showNotificationModal(props, x),
                      },
                    ],
                  ];
                }

                if (
                  props.isMasterContent &&
                  x.workflow !== DOC_WORKFLOW_ENUM.DRAFT
                ) {
                  lessonMenuItems = [
                    ...lessonMenuItems,
                    {
                      label: intl.formatMessage({
                        ...translations.AddToMarketOption,
                      }),
                      icon: Icons.duplicate,
                      onItemClick: () => {
                        props.navigateToAddToMarketPage(
                          x.lessonId,
                          props.programId,
                          props.degreeId,
                        );
                      },
                    },
                  ];
                }

                return (
                  <div key={uuidV4()} className={classes.lessonItemContainer}>
                    <div key={x.lessonId} className={classes.lessonListItem}>
                      <div className={classes.inputStyle}>
                        <span className={classes.inputIcon}>
                          <Icon iconName={Icons.fullCircle} />
                        </span>
                        <div>
                          <input
                            type="text"
                            className={classes.inputWithIcon}
                            readOnly
                            value={x.title}
                          />
                          <div className={classes.lessonAuthor}>
                            <FormattedMessage
                              {...translations.LessonAuthorLabel}
                              values={{
                                name: `${x.author.profile.firstName || ""} ${
                                  x.author.profile.lastName || ""
                                }`,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        {index > 0 &&
                          lessonsNumber > 1 &&
                          !props.isMasterContent && (
                            <OrderArrow
                              iconName={Icons.arrowOrderUp}
                              clickEvent={() => props.lessonMoveUp(x.lessonId)}
                              className={classes.orderArrow}
                            />
                          )}
                        {index < lessonsNumber - 1 &&
                          lessonsNumber > 1 &&
                          !props.isMasterContent && (
                            <OrderArrow
                              iconName={Icons.arrowOrderDown}
                              clickEvent={() =>
                                props.lessonMoveDown(x.lessonId)
                              }
                              className={classes.orderArrow}
                            />
                          )}
                        {x.isPrivate && (
                          <Icon
                            iconName={Icons.private}
                            style={classes.privateIcon}
                          />
                        )}
                        <span
                          className={
                            x.workflow === DOC_WORKFLOW_ENUM.PUBLISHED
                              ? classes.statusPublished
                              : classes.statusDraft
                          }
                        >
                          {displayStatus(x.workflow)}
                        </span>
                        <Button
                          variation={ButtonVariation.primary}
                          size={ButtonSize.small}
                          handleClick={() => props.handleLessonEdit(x.lessonId)}
                        >
                          {props.isTraineRole && props.isMasterContent ? (
                            <FormattedMessage
                              {...translations.ViewContentLabel}
                            />
                          ) : (
                            <FormattedMessage
                              {...translations.EditContentLabel}
                            />
                          )}
                        </Button>
                        {!!lessonMenuItems.length && (
                          <Icon
                            iconName={Icons.roundMore}
                            style={classes.roundMoreIcon}
                            handleClick={() =>
                              props.setActiveMenuLessonId(x.lessonId)
                            }
                          />
                        )}
                        <SettingsMenu
                          handleFocusLeave={() => {
                            props.setActiveMenuLessonId(undefined);
                          }}
                          isVisible={
                            props.activeMenuLessonId &&
                            props.activeMenuLessonId === x.lessonId
                          }
                          className={classes.lessonSettingsMenu}
                          menuItems={lessonMenuItems}
                        />
                      </div>
                    </div>
                    <FilterCountries
                      countries={x.marketTags.map((tag) => ({
                        ...tag,
                        Id: tag.id,
                      }))}
                    >
                      {({ filteredCountries: marketTags }) =>
                        marketTags && marketTags.length ? (
                          <div className={classes.tags}>
                            {marketTags.map((tag) => (
                              <Tag key={tag.key} label={tag.label} readOnly />
                            ))}
                          </div>
                        ) : (
                          ""
                        )
                      }
                    </FilterCountries>
                  </div>
                );
              })}
              {!props.program.lessons.length && (
                <div>
                  <FormattedMessage {...translations.NoOneLessonText} />
                </div>
              )}
              {!props.isMasterContent && (
                <BulletTextInput
                  handleOnBlur={(e) => props.handleLessonCreate(e.target.value)}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ProgramInfo);
