import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { SelectedValueSectionDropdown } from "genius-ui";
import { GetUsers, SetFilterSelectedTags } from "../../store/user/actions";
import { filterLabels } from "./Users.messages";
import useTagAutocomplete, {
  fetchTagsByParentMetaClusterId,
} from "../../hooks/inputs/useTagAutocomplete";
import { isShiseido } from "configs/client";

const UserTableTagFilter = ({
  filter,
  SetFilterSelectedTags,
  filters,
  isEnabled,
  isTrainer,
  userProfileInfo,
  GetUserOnly,
  ...restProps
}) => {
  const zoneFilter = filters.find(({ name }) => name === "ZONES");
  const countryFilter = filters.find(({ name }) => name === "COUNTRIES");
  const zoneFilterSelectedTags =
    zoneFilter && zoneFilter.selectedTags.length
      ? zoneFilter.selectedTags.map(({ value }) => value)
      : null;
  const countryFilterSelectedTags =
    countryFilter && countryFilter.selectedTags.length
      ? countryFilter.selectedTags.map(({ value }) => value)
      : null;

  let clusterIds = filter.tagClusterIds;
  let key = filter.name;
  switch (filter.name) {
    case "COUNTRIES":
      clusterIds = zoneFilterSelectedTags || clusterIds;
      key = clusterIds ? clusterIds.map((value) => value).join("_") : key;
      break;
    case "CITIES":
      clusterIds = countryFilterSelectedTags || clusterIds;
      key = clusterIds ? clusterIds.map((value) => value).join("_") : key;
      break;
  }
  const checkIsTrainer = () => {
    if (isTrainer) {
      const countryTag = userProfileInfo.tags.find(
        ({ clusterId }) => clusterId === "2",
      );

      SetFilterSelectedTags([
        {
          label: countryTag.title,
          value: countryTag.tagId,
          originalTagData: countryTag,
        },
      ]);
    } else {
      GetUserOnly();
    }
  };

  const fetchMethod =
    filter.name === "COUNTRIES" || filter.name === "CITIES"
      ? fetchTagsByParentMetaClusterId
      : undefined;
  useEffect(() => {
    if (filter.name === "MARKET" && isShiseido && userProfileInfo.ready) {
      checkIsTrainer();
    }
  }, [isTrainer, userProfileInfo.ready]);
  const fetch = useTagAutocomplete(
    clusterIds,
    undefined,
    undefined,
    fetchMethod,
  );

  return (
    <FormattedMessage {...filterLabels[`${filter.name}_FILTER`]}>
      {(message) => (
        <SelectedValueSectionDropdown
          key={key}
          isAsync
          placeholder={message[0]}
          onChange={(filteredTags) => SetFilterSelectedTags(filteredTags || [])}
          value={filter.selectedTags}
          {...restProps}
          loadOptions={fetch}
          additional={{ page: 0 }}
          isDisabled={!isEnabled}
        />
      )}
    </FormattedMessage>
  );
};

UserTableTagFilter.propTypes = {
  filter: PropTypes.shape({
    name: PropTypes.string.isRequired,
    tagClusterIds: PropTypes.array,
    selectedTags: PropTypes.array.isRequired,
  }).isRequired,
  filters: PropTypes.array.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  SetFilterSelectedTags: (selectedTags) => {
    const filterName = ownProps.filter.name;

    dispatch(SetFilterSelectedTags({ filterName, selectedTags }));
    dispatch(GetUsers());
  },
  GetUserOnly: () => {
    dispatch(GetUsers());
  },
});

export default connect(undefined, mapDispatchToProps)(UserTableTagFilter);
