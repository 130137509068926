import { defineMessages } from "react-intl";

export const translations = defineMessages({
  ActiveUsersLabel: {
    id: "KpiApplicationShiseido.ActiveUsersLabel",
    defaultMessage: "Active users",
  },
  ActiveNumberOfConnectionsLabel: {
    id: "KpiApplicationShiseido.ActiveNumberOfConnectionsLabel",
    defaultMessage: "Active number of connection by user",
  },
  AverageTimeSpentOnTheAppLabel: {
    id: "KpiApplicationShiseido.AverageTimeSpentOnTheAppLabel",
    defaultMessage: "Average time spent on the app per day",
  },
  NrOfUsersChartLabel: {
    id: "KpiApplicationShiseido.NrOfUsersChartLabel",
    defaultMessage: "Number of Users",
  },
});
