// CARDS
export const SET_CURRENT_CARD_PAGEID = "SET_CURRENT_CARD_PAGEID";
export const SET_CURRENT_CARD_ID = "SET_CURRENT_CARD_ID";
export const SET_CURRENT_CARD_LANGUAGE = "SET_CURRENT_CARD_LANGUAGE";
export const SET_CARDS = "SET_CARDS";
export const SET_CURRENT_ACTIVE_SLIDE = "SET_CURRENT_ACTIVE_SLIDE";
export const SET_CARD_LANGUAGES = "SET_CARD_LANGUAGES";
export const SET_PUBLISH_DATA = "SET_PUBLISH_DATA";
export const RESET_CARDS_STORE = "RESET_CARDS_STORE";
export const SET_CARD_IS_MASTER_FLAG = "SET_CARD_IS_MASTER_FLAG";
