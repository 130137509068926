export const ContentTypeCardStyle = (theme) => ({
  container: {
    width: 254,
    borderRadius: 6,
    backgroundColor: theme.ContentTypeCard.containerBackground,
    cursor: (props) => (props.disabled ? "auto" : "pointer"),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
  },
  infoContent: {
    padding: "10px 20px 20px 20px",
  },
  imageContent: {
    marginTop: 24,
    marginBottom: 21,
    width: 174,
    height: 240,
    maxWidth: 174,
    maxHeight: 240,
    backgroundImage: (props) => `url("${props.image}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  title: {
    fontFamily: theme.FontFamily,
    fontSize: 18,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "condensed",
    lineHeight: "normal",
    letterSpacing: 0.1,
    color: theme.ContentTypeCard.titleColor,
    textTransform: "uppercase",
    marginBottom: 5,
  },
  description: {
    height: 68,
    fontFamily: theme.FontFamily,
    fontSize: 14,
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.ContentTypeCard.descriptionColor,
  },
  layer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.ContentTypeCard.layerBackground,
  },
});
