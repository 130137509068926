import {
  DefaultSlider1,
  DefaultVSlider1,
  SimpleQuizCoverDefault,
  SimpleQuizFullScreenDefault,
  ActivityIntroDefault,
} from "assets/icons";
import { EMPTY_CONTENT } from "../../configs/constants";
import { merge } from "lodash";
import uuidv4 from "uuid/dist/v4";
import activityTranslations from "../../configs/translation/activityTranslations";

export const EmptyArticleTemplatePage = {
  template: "ArticleTemplate",
  data: {
    header: null,
    content: [
      {
        type: "text",
        content: EMPTY_CONTENT,
        theme: "title",
      },
    ],
  },
};

export const SliderFullScreenTemplateEmpty = {
  template: "SliderFullScreenTemplate",
  data: {
    cards: [
      {
        type: "imageFullScreen",
        image: {
          url: DefaultSlider1,
          copyright: "South Pigalle",
        },
        title: null,
        description: null,
      },
    ],
  },
};

export const SliderFullScreenMarkerTemplateEmpty = {
  template: "SliderFullScreenTemplate",
  data: {
    cards: [
      {
        type: "imageFullScreenMarker",
        image: {
          url: DefaultSlider1,
          copyright: "South Pigalle",
        },
        points: [],
      },
    ],
  },
};

export const FullScreenScrollTemplateEmpty = {
  template: "FullScreenScrollTemplate",
  data: {
    content: [
      {
        type: "imageFullScreen",
        image: {
          url: DefaultVSlider1,
          copyright: "South Pigalle",
        },
        title: null,
        description: null,
      },
    ],
  },
};

export const FullScreenScrollMarkerTemplateEmpty = {
  template: "FullScreenScrollTemplate",
  data: {
    content: [
      {
        type: "imageFullScreenMarker",
        image: {
          url: DefaultVSlider1,
          copyright: "South Pigalle",
        },
        points: [],
      },
    ],
  },
};

// ACTIVITIES
const ActivityTemplate = {
  activityName: "Activity!",
  activityDescription: "Test your knowledge going through this case",
  activitySkip: true,
  title: "",
  description: "",
};

const QuizTemplate = (lang) => ({
  template: "SimpleQuizzTemplate",
  data: {
    ...ActivityTemplate,
    activityName: activityTranslations[lang].SimpleQuiz.activityName,
    activityDescription:
      activityTranslations[lang].SimpleQuiz.activityDescription,
    background: {
      type: "image",
      url: ActivityIntroDefault,
      copyright: "Shiseido",
      theme: "fullScreen",
    },
    score: 0,
  },
});

export const emptyAnswerTemplate = {
  answer: "",
  isTrue: false,
  message: null,
};

// simple quiz
export const SimpleQuizActivityQuestionTemplateEmpty = {
  type: "simple",
  text: "",
  background: null,
  answers: [],
  endMessage: {
    text: "",
    background: null,
  },
};

export const SimpleQuizActivityTemplateEmpty = (lang) =>
  merge({}, QuizTemplate(lang), {
    data: {
      questions: [SimpleQuizActivityQuestionTemplateEmpty],
    },
  });

// quiz with cover
export const QuizWithCoverActivityQuestionTemplateEmpty = {
  type: "simple",
  text: "",
  background: {
    type: "image",
    url: null,
    copyright: "South Pigalle",
    theme: "header",
  },
  answers: [],
  endMessage: {
    text: "",
    background: {
      type: "image",
      // no end image
      url: null,
      copyright: "South Pigalle",
      theme: "header",
    },
  },
};

export const QuizWithCoverActivityTemplateEmpty = (lang) =>
  merge({}, QuizTemplate(lang), {
    data: {
      questions: [QuizWithCoverActivityQuestionTemplateEmpty],
    },
  });

// full screen background quiz
export const QuizImageFullScreenActivityQuestionTemplateEmpty = {
  type: "simple",
  text: "",
  background: {
    type: "image",
    url: SimpleQuizFullScreenDefault,
    copyright: "South Pigalle",
    theme: "fullScreen",
  },
  answers: [],
  endMessage: {
    text: "",
    background: {
      type: "image",
      url: SimpleQuizFullScreenDefault,
      copyright: "South Pigalle",
      theme: "fullScreen",
    },
  },
};

export const QuizImageFullScreenActivityTemplateEmpty = (lang) =>
  merge({}, QuizTemplate(lang), {
    data: {
      questions: [QuizImageFullScreenActivityQuestionTemplateEmpty],
    },
  });

// madlib
export const MadLibActivityTemplateEmpty = (lang) => ({
  template: "MadLibTemplate",
  data: {
    ...ActivityTemplate,
    activityName: activityTranslations[lang].Madlibs.activityName,
    activityDescription: activityTranslations[lang].Madlibs.activityDescription,
    image: {
      type: "image",
      url: ActivityIntroDefault,
      copyright: "Shiseido",
      legend: "",
      theme: "fullScreen",
    },
    questions: [
      {
        text: " [#] ",
        answers: [],
      },
    ],
  },
});

// drag and drop
export const createEmptyDragAndDropActivityCard = () => ({
  uid: uuidv4(),
  image: null,
  text: null,
});

const zone1ID = "zone1";
const zone2ID = "zone2";

export const createDragAndDropActivityQuestion = (questionId, lang) => [
  {
    text: "",
    id: questionId,
    uid: uuidv4(),
    answers: [
      {
        answer: null,
        placeholder: activityTranslations[lang].DragAndDrop.placeholder,
        isTrue: true,
        zoneId: zone1ID,
      },
      {
        answer: null,
        placeholder: activityTranslations[lang].DragAndDrop.placeholder,
        isTrue: false,
        zoneId: zone2ID,
      },
    ],
    card: createEmptyDragAndDropActivityCard(),
  },
  {
    text: "",
    id: questionId,
    answers: [
      {
        answer: null,
        placeholder: "Drop image here",
        isTrue: false,
        zoneId: zone1ID,
      },
      {
        answer: null,
        placeholder: "Drop image here",
        isTrue: true,
        zoneId: zone2ID,
      },
    ],
    card: createEmptyDragAndDropActivityCard(),
  },
];

export const DragAndDropActivityTemplateEmpty = (lang) => ({
  template: "DragAndDropTemplate",
  data: {
    ...ActivityTemplate,
    activityName: activityTranslations[lang].DragAndDrop.activityName,
    activityDescription:
      activityTranslations[lang].DragAndDrop.activityDescription,
    image: {
      type: "image",
      url: ActivityIntroDefault,
    },
    questions: createDragAndDropActivityQuestion(1, lang),
  },
});

export const NinjaActivityTemplateEmpty = () => ({
  template: "WebviewGameTemplate",
  data: {
    activityName: "Activity",
    activityDescription: "Play The Right Match to challenge your knowledge",
    title: "",
    description: "",
    background: {
      type: "image",
      url: "https://cdn-mediastoresopi.azureedge.net/superapp-static/media/ActivityIntroDefaultBG.png",
      theme: "fullScreen",
    },
    url: "https://fruit-ninja-dev.southpigalle.io/",
    questions: [
      {
        id: uuidv4(),
        image_type: null,
        image: null,
        text: "",
        timer: 30,
        answers: [],
      },
    ],
  },
});
