import React from "react";
import PropTypes from "prop-types";
import SimpleQuizQuestionReadOnly from "../../../components/common/SimpleQuizQuestion/simpleQuizQuestionReadOnly";
import { memoize } from "lodash";
import { QuizWithCoverActivityQuestionTemplateEmpty } from "../../../utils/ui-generator/defaultTemplates";

const quickQuizAnswerToSimpleQuizAnswer = ({ isTrue, title, message }) => ({
  answer: title,
  isTrue,
  message,
});

const quickQuizEndMessageToSimpleQuizEndMessage = (background, endMessage) => ({
  background,
  text: endMessage,
});

const memoizeEndMessage = memoize(quickQuizEndMessageToSimpleQuizEndMessage);

const quickQuizBackgroundToSimpleQuizBackground = ({
  url,
  copyright,
  legend,
}) => ({
  url,
  copyright,
  theme: QuizWithCoverActivityQuestionTemplateEmpty.background.theme,
});

const memoizeBackground = memoize(quickQuizBackgroundToSimpleQuizBackground);

const QuickQuizPageEditor = ({
  pageId,
  image,
  endMessage: endMessageProp,
  answers,
  question,
  onDataChanged,
}) => {
  /** Memoize endMessage value because the useEffect hook inside SimpleQuizQuestion receives a new endMessage object and
   * unnecessarily re-executes on each re-render.
   * It is intentional, that not React.useMemo hook is used here, because it is not recommended to use the hook as a
   * "semantic guarantee":
   * https://reactjs.org/docs/hooks-reference.html#usememo
   * **/
  const background = memoizeBackground(image);
  const endMessage = memoizeEndMessage(background, endMessageProp);

  return (
    <SimpleQuizQuestionReadOnly
      uniqueId="simpleQuizQuestion"
      id={pageId}
      background={background}
      endMessage={endMessage}
      answers={answers.map(quickQuizAnswerToSimpleQuizAnswer)}
      text={question}
      type="simple"
      multiQuestionsManagement={false}
      pictureManagementOnEndMessageScreen={false}
      multiAnswerManagement={false}
      changeTemplateAllowed={false}
    />
  );
};

QuickQuizPageEditor.propTypes = {
  /** page Id  */
  pageId: PropTypes.string,
  /** background image of the page **/
  image: PropTypes.object,
  /** end message of the page **/
  endMessage: PropTypes.string,
  /** quiz answers **/
  answers: PropTypes.array,
  /** question **/
  question: PropTypes.string,
};

export default QuickQuizPageEditor;
