import client from "../../graphql/client";
import {
  CLEAR_CITIES_TAGS,
  CLEAR_COUNTRY_TAGS,
  CLEAR_SHOPS_TAGS,
  REMOVE_FILTER_SELECTED_VALUES,
  RESET_FILTERS,
  UPDATE_FILTER_SELECTED_VALUES,
  UPDATE_FILTER_ORDER_BY,
  UPDATE_FILTER_SKIP,
  UPDATE_INTERVALS,
  UPDATE_SELECTED_INTERVAL,
  CLEAR_RETAILER_TAGS,
  CLEAR_SERVICES_TAGS,
  CLEAR_ROLES_TAGS,
  SET_IS_EXPORT_LOADING,
  SET_IS_LOADING,
} from "../../constants/store/kpiFilters";
import { SET_SELECTED_PERIOD_RESULT } from "../../constants/store/battleResults";
import { SET_SELECTED_PERIOD_POLL_RESULT } from "../../constants/store/pollResults";
import { GET_INTERVALS } from "./graphs";

export const SetIsLoading = (isLoading) => (dispatch) => {
  dispatch({
    type: SET_IS_LOADING,
    data: isLoading,
  });
};

export const UpdateFilterSelectedValues =
  (filterName, selectedValues) => (dispatch) => {
    dispatch({
      type: UPDATE_FILTER_SELECTED_VALUES,
      data: { filterName, selectedValues },
    });
  };

export const RemoveFilterSelectedValue =
  (filterName, removedValue) => (dispatch) => {
    dispatch({
      type: REMOVE_FILTER_SELECTED_VALUES,
      data: { filterName, removedValue },
    });
  };

export const ResetFilters = () => (dispatch) => {
  dispatch({
    type: RESET_FILTERS,
  });
};

export const ResetCountriesFilters = () => (dispatch) => {
  dispatch({
    type: CLEAR_COUNTRY_TAGS,
  });
};
export const ResetCitiesFilters = () => (dispatch) => {
  dispatch({
    type: CLEAR_CITIES_TAGS,
  });
};
export const ResetShopsFilters = () => (dispatch) => {
  dispatch({
    type: CLEAR_SHOPS_TAGS,
  });
};

export const ResetRetailerFilters = () => (dispatch) => {
  dispatch({
    type: CLEAR_RETAILER_TAGS,
  });
};

export const ResetServicesFilters = () => (dispatch) => {
  dispatch({
    type: CLEAR_SERVICES_TAGS,
  });
};

export const ResetRolesFilters = () => (dispatch) => {
  dispatch({
    type: CLEAR_ROLES_TAGS,
  });
};

export const SetIsExportLoading = (isExportLoading) => (dispatch) => {
  dispatch({
    type: SET_IS_EXPORT_LOADING,
    data: isExportLoading,
  });
};

export const SetSortBy = (sortBy) => (dispatch, getState) => {
  const sortOrder =
    getState().kpi.filters.sortBy === sortBy &&
    getState().kpi.filters.sortOrder === "desc"
      ? "asc"
      : "desc";
  dispatch({
    type: RESET_FILTERS,
  });
  dispatch({
    type: UPDATE_FILTER_ORDER_BY,
    data: {
      sortBy,
      sortOrder,
    },
  });
};

export const SetSkip = (skip) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_FILTER_SKIP,
    data: skip || getState().kpi.filters.skip + 20,
  });
};

export const GetIntervals = () => async (dispatch, getState) => {
  const result = await client.query({
    fetchPolicy: "network-only",
    query: GET_INTERVALS,
    variables: {},
  });
  const { selectedValues } = getState().kpi.filters.interval;
  const formattedResult = result?.data?.allCoreIntervals?.map((interval) => ({
    value: interval.id,
    label: interval.name,
  }));
  let selectedInterval =
    formattedResult.find((interval) => interval.label.includes("3 months")) ||
    formattedResult?.[2];

  if (selectedValues[0]) {
    selectedInterval = selectedValues[0];
  } else if (!selectedValues[0] && selectedValues.length) {
    selectedInterval = undefined;
  }

  dispatch(UpdateFilterSelectedValues("interval", [selectedInterval]));
  dispatch({
    type: UPDATE_SELECTED_INTERVAL,
    data: selectedInterval,
  });
  dispatch({
    type: UPDATE_INTERVALS,
    data: formattedResult,
  });
  dispatch({
    type: SET_SELECTED_PERIOD_RESULT,
    data: selectedInterval,
  });
  dispatch({
    type: SET_SELECTED_PERIOD_POLL_RESULT,
    data: selectedInterval,
  });
};

export const SetSelectedInterval = (interval) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_INTERVAL,
    data: interval,
  });
};
