import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Icon, Icons } from "genius-ui";
import classNames from "classnames";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import Style from "./UploadAttachments.style";
import { createUseStyles } from "react-jss";
import { ENV } from "../../../configs/constants";
import MediaService from "../../../core/mediaService";
import uuidV4 from "uuid/dist/v4";
import Spinner from "../Spinner";
import { translations } from "./UploadAttachments.translations";
import { FormattedMessage } from "react-intl";

const mediaService = new MediaService();

const useStyle = createUseStyles(Style);

const UploadAttachments = (props) => {
  const { containerClassName, files, onRemoveFile, onSaveFile } = props;
  const [selectedFiles, setSelectedFiles] = useState(files);
  const [isSpinnerActive, setSpinnerActive] = useState(false);
  const classes = useThemedStyle(useStyle, props);

  useEffect(() => {
    setSelectedFiles(files);
  }, [files]);

  const removeFileHandler = (removeFile) => () => {
    if (removeFile.id) {
      onRemoveFile(removeFile.id);
    }
    setSelectedFiles(
      selectedFiles.filter((file) => file.key !== removeFile.key),
    );
  };

  const saveFilesHandler = () => {
    setSpinnerActive(true);
    onSaveFile(
      selectedFiles.filter((file) => !file.id),
      () => {
        setSpinnerActive(false);
      },
    );
  };

  const onSelectFiles = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (validity.valid && file.type === "application/pdf") {
      await handleMediaUpload(file);
    }
  };

  const handleMediaUpload = async (file) => {
    const fileName = file.name;
    try {
      setSpinnerActive(true);
      const result = await mediaService.uploadMedia(file);
      if (result.status === 200) {
        setSelectedFiles([
          ...selectedFiles,
          {
            key: uuidV4(),
            name: fileName,
            url: result.data,
            type: "pdf",
          },
        ]);
      }
      setSpinnerActive(false);
    } catch (e) {
      if (ENV.IS_DEV) {
        console.error("Couldn't upload file", e);
      }
    }
  };

  return (
    <div className={classNames([classes.rootContainer, containerClassName])}>
      {selectedFiles.map((file, key) => (
        <div key={file.key} className={classes.row}>
          <div>{file.name}</div>
          <div>
            <Icon
              iconName={Icons.slimCloseIcon}
              handleClick={removeFileHandler(file)}
            />
          </div>
        </div>
      ))}
      {!selectedFiles.length && (
        <div className={classes.noFileText}>
          <FormattedMessage {...translations.NoFileSelectedMessage} />
        </div>
      )}
      <div className={classes.actions}>
        {isSpinnerActive ? (
          <Spinner size={30} />
        ) : (
          <>
            <div className={classes.uploadBtnWrapper}>
              <Button size="small" variation="primary">
                <FormattedMessage {...translations.SelectFileButtonText} />
              </Button>
              <input
                className={classes.uploadInput}
                required
                onChange={onSelectFiles}
                type="file"
                accept="application/pdf,application/vnd.ms-excel"
              />
            </div>
            <Button
              size="small"
              variation="primary"
              handleClick={saveFilesHandler}
              disabled={!selectedFiles.length}
            >
              <FormattedMessage {...translations.SaveFilesButtonText} />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

UploadAttachments.propTypes = {
  containerClassName: PropTypes.string,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string,
    }),
  ).isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  onSaveFile: PropTypes.func.isRequired,
};

export default UploadAttachments;
