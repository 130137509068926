import { defineMessages } from "react-intl";

export const translations = defineMessages({
  TemplateTitle: {
    id: "SideBarSliderTemplate.TemplateTitle",
    defaultMessage: "Slider",
  },
  SliderSimpleText: {
    id: "SideBarSliderTemplate.SliderSimpleText",
    defaultMessage: "Slider simple",
  },
  SliderDottedText: {
    id: "SideBarSliderTemplate.SliderDottedText",
    defaultMessage: "Slider dotted",
  },
});
