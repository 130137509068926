import React from "react";
import PropTypes from "prop-types";
import messages from "./PollQuestionChoiceEditor.translations";
import Style from "./PollQuestionChoiceEditor.style.js";
import classNames from "classnames";
import ContentEditableUncontrolled from "../../common/ContentEditableUncontrolled";
import { Icon, Icons } from "genius-ui";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const PollQuestionChoiceEditor = (props) => {
  const {
    text,
    isAddAChoice,
    onAddNewChoice,
    onTextChange,
    onRemove,
    disabled,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <label
      className={classNames([
        classes.choice,
        {
          [classes.addNewAnswerChoice]: isAddAChoice,
        },
      ])}
      onClick={(e) => {
        if (isAddAChoice && !disabled) {
          e.preventDefault();
          e.stopPropagation();
          onAddNewChoice();
        }
      }}
    >
      {isAddAChoice ? (
        text
      ) : (
        <FormattedMessage {...messages.PollQuestionChoicePlaceHolder}>
          {(placeHolder) => (
            <ContentEditableUncontrolled
              className={classes.choiceInput}
              initialValue={text}
              onChange={(newText) => onTextChange(newText)}
              placeholder={placeHolder[0]}
              onClick={(e) => {
                if (!disabled) {
                  e.stopPropagation();
                }
              }}
              disabled={disabled}
            />
          )}
        </FormattedMessage>
      )}
      {!isAddAChoice && (
        <span
          onClick={(e) => {
            if (!disabled) {
              e.stopPropagation();
              onRemove();
            }
          }}
        >
          <Icon iconName={Icons.delete} />
        </span>
      )}
    </label>
  );
};

PollQuestionChoiceEditor.propTypes = {
  multiAnswerManagement: PropTypes.bool,
};

export default PollQuestionChoiceEditor;
