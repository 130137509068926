import React, { useEffect } from "react";
import compose from "lodash/fp/compose";
import Navigation from "../../components/Navigation";
import UsersStyle from "./Users.style";
import { GetUsers, PatchPaginationMetaData } from "../../store/user/actions";
import { connect } from "react-redux";
import UsersTable from "./UsersTable";
import UserTableFilters from "./UserTableFilters";
import { getPath } from "../../core/paths";
import clientConfig, { isShiseido } from "../../configs/client";
import { STATUS_ENUM } from "../../configs/constants";
import Spinner from "../../components/common/Spinner";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../hooks/style/useThemedStyle";
import { Button, ButtonVariation, ButtonSize } from "genius-ui";
import { FormattedMessage } from "react-intl";
import { translations } from "./Users.translations";
import messages from "./Users.messages";

const useStyle = createUseStyles(UsersStyle);

const UsersScreen = (props) => {
  const {
    GetUsers,
    isPageLoading,
    users,
    usersOnPage,
    page,
    SetPage,
    isLoading,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  useEffect(() => {
    if (!isShiseido) {
      GetUsers();
    }
  }, []);

  const navigateToProfilePage =
    ({ userId, status }) =>
    () => {
      clientConfig.MODULES.IS_USER_PROFILE_ACTIVE &&
        status === STATUS_ENUM.ACTIVE &&
        window.open(getPath("userProfile", userId), "_blank");
    };
  const displaySeeMoreButton =
    !isPageLoading && users.length >= usersOnPage * page + usersOnPage;
  const onNextPage = () => SetPage(page + 1);

  return (
    <div className={classes.userScreensContainer}>
      <Navigation />
      <UserTableFilters />
      <div className={classes.spinner}>
        <Spinner size={30} />
      </div>
      <div className={classes.usersTable}>
        <UsersTable navigateToProfilePage={navigateToProfilePage} />
      </div>
      <div className={classes.loadMoreButton}>
        {displaySeeMoreButton && onNextPage && (
          <Button
            variation={ButtonVariation.primary}
            size={ButtonSize.small}
            handleClick={onNextPage}
          >
            <FormattedMessage {...translations.SeeMoreButtonTitle} />
          </Button>
        )}
        {isLoading && (
          <div className={classes.loader}>
            <FormattedMessage {...messages.LoadingText} />
            <span>...</span>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isPageLoading: state.users.paginationMetaData.isLoading,
  filters: state.users.filters.map((element) => element.name),
  users: state.users.users,
  isLoading: state.users.paginationMetaData.isLoading,
  page: state.users.paginationMetaData.page,
  usersOnPage: state.users.paginationMetaData.usersOnPage,
});

const mapDispatchToProps = (dispatch) => ({
  GetUsers: () => {
    dispatch(GetUsers());
  },
  SetPage: (page) => {
    dispatch(PatchPaginationMetaData({ page }));
    dispatch(GetUsers());
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UsersScreen,
);
