import React from "react";
import { PublishInsightStyle } from "./PublishMasterInsight.style";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";
import {
  INSIGHT_UPDATE_TITLE,
  INSIGHT_UPDATE_SUMMARY,
  INSIGHT_UPDATE_PIC_COVER,
  INSIGHT_UPDATE_VIDEO_COVER,
  INSIGHT_UPDATE_PIC_THUMB,
  INSIGHT_ADD_TAGS,
  INSIGHT_REMOVE_TAGS,
  INSIGHT_SET_PRIVACY,
  INSIGHT_REMOVE_PRIVACY,
} from "../../../graphql/remote/insights/mutations";
import { connect } from "react-redux";
import {
  GetInsightData,
  SetCurrentInsightId,
  SetCurrentLanguage,
  SetPublishInsight,
  UpdateInsightWorkflow,
  UpdatePublishInsight,
} from "../../../store/insight/actions";
import { SetCurrentTags } from "../../../store/referential/actions";
import { Icons, Icon, Tag, FullscreenLoader } from "genius-ui";
import { withRouter } from "react-router";
import SelectTagsByClusterId from "../../../containers/Apollo/SelectTagsByClusterId";
import {
  DATE_SELECTOR_TYPE,
  DOC_WORKFLOW_ENUM,
} from "../../../configs/constants";
import clientConfig, {
  CONFIG_CLUSTER,
  REACT_APP_CLIENT_NAME,
} from "../../../configs/client";
import { LANGUAGES } from "../../../configs/referential";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import ContentEditable from "react-contenteditable";
import { cleanUpAndEncodeText } from "../../../utils/string";
import { FormattedMessage, injectIntl } from "react-intl";
import { TAG_CREATE } from "../../../graphql/remote/tags/mutations";
import { GET_TAGS_BY_CLUSTER_ID } from "../../../graphql/remote/referential/queries";
import ImageVideoUploader from "../../../components/common/ImageVideoUploader";
import { translations } from "./PublishMasterInsight.translation";
import FilterLanguages from "../../../components/FilterLanguages";
import withThemedStyle from "../../../components/hoc/withThemedStyle";
import PublicationButtons from "../../../components/PublicationMasterDocuments/PublicationButtons";
import { getPath } from "../../../core/paths";
import {
  formatOptionsForSave,
  formatOptionsForSelect,
  withMasterTag,
} from "../../../utils/dataTransformation/tags";
import { SetWarningMessage } from "../../../store/notification/actions";

const clientBasedTranslationNameSpace = {
  SHISEIDO: "REST_OF_CLIENTS",
  "SHISEIDO-PREPROD": "REST_OF_CLIENTS",
  "SHISEIDO-PROD": "REST_OF_CLIENTS",
};

const updateInsightTitle = graphql(INSIGHT_UPDATE_TITLE, {
  props: ({ mutate }) => ({
    updateTitle: (insightId, lang, data) =>
      mutate({
        variables: { insightId: insightId, lang: lang, data: data },
      }),
  }),
});

const updateInsightSumarry = graphql(INSIGHT_UPDATE_SUMMARY, {
  props: ({ mutate }) => ({
    updateSummary: (insightId, lang, data) =>
      mutate({
        variables: { insightId: insightId, lang: lang, data: data },
      }),
  }),
});

const updateInsightPicCover = graphql(INSIGHT_UPDATE_PIC_COVER, {
  props: ({ mutate }) => ({
    updatePicCover: (insightId, lang, data) =>
      mutate({
        variables: { insightId: insightId, lang: lang, data: data },
      }),
  }),
});

const updateInsightVideoCover = graphql(INSIGHT_UPDATE_VIDEO_COVER, {
  props: ({ mutate }) => ({
    updateVideoCover: (insightId, lang, data) =>
      mutate({
        variables: { insightId: insightId, lang: lang, data: data },
      }),
  }),
});

const updateInsightPicThumb = graphql(INSIGHT_UPDATE_PIC_THUMB, {
  props: ({ mutate }) => ({
    updatePicThumb: (insightId, lang, data) =>
      mutate({
        variables: { insightId: insightId, lang: lang, data: data },
      }),
  }),
});

const addInsightTags = graphql(INSIGHT_ADD_TAGS, {
  props: ({ mutate }) => ({
    addTags: (insightId, lang, tagIdList, onUpdate) => {
      mutate({
        variables: { insightId: insightId, lang: lang, tagIdList: tagIdList },
        update: (dataProxy, result) =>
          onUpdate(result.data.admin.insight.tags.add),
      });
    },
  }),
});

const removeInsightTags = graphql(INSIGHT_REMOVE_TAGS, {
  props: ({ mutate }) => ({
    removeTags: (insightId, lang, tagIdList, onSuccess) =>
      mutate({
        variables: { insightId: insightId, lang: lang, tagIdList: tagIdList },
        update: () => onSuccess(),
      }),
  }),
});

const setInsightPrivacy = graphql(INSIGHT_SET_PRIVACY, {
  props: ({ mutate }) => ({
    setPrivacy: (insightId, lang, onSuccess) =>
      mutate({
        variables: { insightId, lang },
        update: () => onSuccess(),
      }),
  }),
});

const removeInsightPrivacy = graphql(INSIGHT_REMOVE_PRIVACY, {
  props: ({ mutate }) => ({
    removePrivacy: (insightId, lang, onSuccess) =>
      mutate({
        variables: { insightId, lang },
        update: () => onSuccess(),
      }),
  }),
});

const createTag = graphql(TAG_CREATE, {
  props: ({ mutate }) => ({
    createTag: (tag, onSuccess) =>
      mutate({
        variables: { tag },
        update: (cache, data) => onSuccess(data),
        refetchQueries: (fetchResult) => [
          {
            query: GET_TAGS_BY_CLUSTER_ID,
            variables: { clusterIds: clientConfig.EXPERTISE_CLUSTERIDS },
          },
        ],
      }),
  }),
});

class PublishInsight extends React.Component {
  state = {
    classNameError: null,
  };

  componentDidMount() {
    const { insightId, lang } = this.props.match.params;
    this.props.GetInsightData(insightId, lang);
    this.props.UpdateCurrentInsightId(insightId);
    this.props.SetCurrentLanguage(lang);
  }

  componentWillUnmount() {
    this.props.SetScreenIsReady(false);
  }

  updateTagsBrand = (handlers) => {
    if (this.props.publishInsight.brands.length > 0)
      this.state.classNameError = null;
    return (selection) =>
      this.props.UpdateTags(
        formatOptionsForSave(selection),
        { ...this.props.publishInsight },
        this.props.currentInsightId,
        this.props.currentLanguage,
        handlers,
        "brands",
      );
  };

  handleClick = () => {
    if (this.props.publishInsight.brands.length)
      this.props.history.push(getPath("masterContentManager"));
    else {
      this.props.WarningAlert(
        this.props.intl.formatMessage(translations.IndicateBrandsWarning),
      );
      this.setState({
        classNameError: this.props.classes.tagSelectContainerError,
      });
    }
  };

  render() {
    const { isScreenReady, isLoaderActive, classes } = this.props;
    const props = this.props;

    if (!isScreenReady || isLoaderActive) {
      return <FullscreenLoader />;
    }

    const handlers = {
      updateTitle: props.updateTitle,
      updateSummary: props.updateSummary,
      updatePicCover: props.updatePicCover,
      updateVideoCover: props.updateVideoCover,
      updatePicThumb: props.updatePicThumb,
      addTags: props.addTags,
      removeTags: props.removeTags,
      setPrivacy: props.setPrivacy,
      removePrivacy: props.removePrivacy,
      createTag: props.createTag,
    };

    const currentLang = props.publishInsight.lang
      ? props.publishInsight.lang
      : clientConfig.DEFAULT_LANGUAGE_CODE;

    const navigateToTheMasterContentsPage = () =>
      props.history.push(getPath("masterContentManager"));

    return (
      <div className={classes.rootContainer}>
        <div className={classes.center}>
          <div className={classes.left}>
            <FormattedMessage {...translations.TitlePlaceholder}>
              {(message) => (
                <ContentEditable
                  html={
                    props.publishInsight.title ? props.publishInsight.title : ""
                  }
                  onChange={({ target }) =>
                    props.UpdateInsightTitle(
                      target.value,
                      { ...props.publishInsight },
                      handlers,
                    )
                  }
                  className={classes.textInputTitle}
                  placeholder={message[0]}
                  tagName="article"
                />
              )}
            </FormattedMessage>
            <br />
            <FormattedMessage {...translations.SummaryPlaceholder}>
              {(message) => (
                <ContentEditable
                  html={
                    props.publishInsight.summary
                      ? props.publishInsight.summary
                      : ""
                  }
                  onChange={({ target }) =>
                    props.UpdateInsightSummary(
                      target.value,
                      { ...props.publishInsight },
                      handlers,
                    )
                  }
                  className={classes.textInputSummary}
                  placeholder={message[0]}
                  tagName="article"
                />
              )}
            </FormattedMessage>
            <br />
            <div className={classes.detailsContainer}>
              <div className={classes.leftPart}>
                <ImageVideoUploader
                  id="publishInsightImage"
                  video={props.publishInsight.videoCover}
                  image={props.publishInsight.cover}
                  updateVideo={(video) =>
                    props.UpdateVideoCover(
                      video,
                      { ...props.publishInsight },
                      handlers,
                    )
                  }
                  updateImage={(image) =>
                    props.UpdatePicCover(
                      image,
                      { ...props.publishInsight },
                      handlers,
                    )
                  }
                />
              </div>
              <div className={classes.rightPart}>
                <div className={classes.langPublicContainer}>
                  <div className={classes.firstTitle}>
                    <FormattedMessage {...translations.LanguageLabel} />
                  </div>
                </div>
                <div className={classes.tagList}>
                  <FilterLanguages languages={LANGUAGES}>
                    {(filteredLanguages) => {
                      const languages = filteredLanguages.find(
                        (x) => x.Code === currentLang,
                      );

                      return languages ? (
                        <Tag
                          key={languages.Code}
                          label={languages.Name}
                          readOnly
                        />
                      ) : (
                        ""
                      );
                    }}
                  </FilterLanguages>
                </div>
                {clientConfig.SHOW_BRAND_AND_RETAILER ? (
                  <div className={classes.brandAndRetailer}>
                    <div className={classes.brandTagsContainer}>
                      <div className={classes.sectionTitle}>
                        <FormattedMessage
                          {...translations[
                            `BrandLabel_${clientBasedTranslationNameSpace[REACT_APP_CLIENT_NAME]}`
                          ]}
                        />
                      </div>
                      <FormattedMessage
                        {...translations[
                          `AddBrandPlaceholder_${clientBasedTranslationNameSpace[REACT_APP_CLIENT_NAME]}`
                        ]}
                      >
                        {(message) => (
                          <SelectTagsByClusterId
                            tags={formatOptionsForSelect(props.brands)}
                            saveTagsToState={props.SaveTagsToState}
                            className={classes.tagSelectContainer}
                            classNameError={this.state.classNameError}
                            id="brand"
                            clusterIds={clientConfig.BRAND_TAGS_CLUSTER_IDS}
                            tagType={CONFIG_CLUSTER.BRAND}
                            selectedItems={formatOptionsForSelect(
                              props.publishInsight.brands,
                            )}
                            placeholder={message[0]}
                            onSelectionChanged={this.updateTagsBrand(handlers)}
                          />
                        )}
                      </FormattedMessage>
                    </div>
                    <div className={classes.retailerTagsContainer}>
                      <div className={classes.sectionTitle}>
                        <FormattedMessage
                          {...translations[
                            `RetailerLabel_${clientBasedTranslationNameSpace[REACT_APP_CLIENT_NAME]}`
                          ]}
                        />
                      </div>
                      <FormattedMessage
                        {...translations[
                          `AddRetailerPlaceholder_${clientBasedTranslationNameSpace[REACT_APP_CLIENT_NAME]}`
                        ]}
                      >
                        {(message) => (
                          <SelectTagsByClusterId
                            tags={formatOptionsForSelect(props.retailers)}
                            saveTagsToState={props.SaveTagsToState}
                            className={classes.tagSelectContainer}
                            id="retailer"
                            clusterIds={clientConfig.RETAILER_TAGS_CLUSTER_IDS}
                            tagType={CONFIG_CLUSTER.RETAILER}
                            selectedItems={formatOptionsForSelect(
                              props.publishInsight.retailers,
                            )}
                            placeholder={message[0]}
                            onSelectionChanged={(selection) =>
                              props.UpdateTags(
                                formatOptionsForSave(selection),
                                { ...props.publishInsight },
                                props.currentInsightId,
                                props.currentLanguage,
                                handlers,
                                "retailers",
                              )
                            }
                          />
                        )}
                      </FormattedMessage>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={classes.sectionTitle}>
                      <FormattedMessage {...translations.UserCategoryLabel} />
                    </div>
                    <FormattedMessage
                      {...translations.SelectUserCategoriesPlaceholder}
                    >
                      {(message) => (
                        <SelectTagsByClusterId
                          tags={formatOptionsForSelect(props.userCategories)}
                          saveTagsToState={props.SaveTagsToState}
                          className={classes.tagSelectContainer}
                          id="usercategory"
                          clusterIds={clientConfig.USER_CATEGORY_CLUSTERIDS}
                          tagType={CONFIG_CLUSTER.USER_CATEGORY}
                          selectedItems={formatOptionsForSelect(
                            props.publishInsight.userCategories,
                          )}
                          placeholder={message[0]}
                          onSelectionChanged={(selection) =>
                            props.UpdateTags(
                              formatOptionsForSave(selection),
                              { ...props.publishInsight },
                              props.currentInsightId,
                              props.currentLanguage,
                              handlers,
                              "userCategories",
                            )
                          }
                        />
                      )}
                    </FormattedMessage>
                  </div>
                )}
                <span className={classes.sectionTitle}>
                  <FormattedMessage {...translations.AssociatedTagsLabel} />
                </span>
                <FormattedMessage
                  {...translations.SelectAssociatedTagsPlaceholder}
                >
                  {(message) => (
                    <SelectTagsByClusterId
                      isEditable
                      tags={formatOptionsForSelect(props.tags)}
                      saveTagsToState={props.SaveTagsToState}
                      className={classes.tagSelectContainer}
                      id="expertise"
                      clusterIds={clientConfig.EXPERTISE_CLUSTERIDS}
                      tagType={CONFIG_CLUSTER.EXPERTISE}
                      selectedItems={formatOptionsForSelect(
                        props.publishInsight.tags,
                      )}
                      placeholder={message[0]}
                      onSelectionChanged={(selection) =>
                        props.UpdateTags(
                          formatOptionsForSave(withMasterTag(selection)),
                          { ...props.publishInsight },
                          props.currentInsightId,
                          props.currentLanguage,
                          handlers,
                          "tags",
                        )
                      }
                      onCreateOption={(inputValue) =>
                        props.CreateNewTag(
                          inputValue,
                          handlers,
                          props.publishInsight,
                          props.currentInsightId,
                          props.currentLanguage,
                        )
                      }
                      fetchSuggestedTags={() =>
                        props.publishInsight.predictTags
                      }
                    />
                  )}
                </FormattedMessage>
                <PublicationButtons
                  btnClickHandler={this.handleClick}
                  switchHandler={props.UpdateInsightWorkflow}
                  isOn={
                    props.publishInsight.workflow ===
                    DOC_WORKFLOW_ENUM.PUBLISHED
                  }
                />
              </div>
            </div>
          </div>
          <div
            className={classes.right}
            onClick={navigateToTheMasterContentsPage}
          >
            <Icon iconName={Icons.slimCloseIcon} style={classes.closeIcon} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  currentInsightId: state.insights.currentInsightId,
  currentPageId: state.insights.currentPageId,
  publishInsight: state.insights.publishInsight,
  currentLanguage: state.insights.currentLanguage,
  visibleTagSelector: state.insights.visibleTagSelector,
  isScreenReady: state.insights.publishInsight.isScreenReady,
  tags: state.referential.tags,
  countries: state.referential.countries,
  brands: state.referential.brands,
  retailers: state.referential.retailers,
  userCategories: state.referential.userCategories,
  isLoaderActive: state.navigation.isLoaderActive,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const SetScreenIsReady = (isScreenReady) =>
    dispatch(UpdatePublishInsight({ isScreenReady }));
  return {
    UpdateCurrentInsightId: (insightId) => {
      dispatch(SetCurrentInsightId(insightId));
    },
    SetCurrentLanguage: (language) => {
      dispatch(SetCurrentLanguage(language));
    },
    UpdateInsightTitle: (title, currentInsight, handlers) => {
      handlers.updateTitle(
        currentInsight.id,
        currentInsight.lang,
        cleanUpAndEncodeText(title),
      );
      dispatch(SetPublishInsight({ ...currentInsight, title }));
    },
    UpdateInsightSummary: (summary, currentInsight, handlers) => {
      handlers.updateSummary(
        currentInsight.id,
        currentInsight.lang,
        cleanUpAndEncodeText(summary),
      );
      dispatch(SetPublishInsight({ ...currentInsight, summary }));
    },
    UpdatePicCover: (image, currentInsight, handlers) => {
      currentInsight.cover = image;
      currentInsight.picThumb = image;
      if (image) {
        handlers.updatePicCover(currentInsight.id, currentInsight.lang, image);
        handlers.updatePicThumb(currentInsight.id, currentInsight.lang, image);
      }
      dispatch(SetPublishInsight({ ...currentInsight }));
    },
    UpdateVideoCover: (videoCover, currentInsight, handlers) => {
      currentInsight.videoCover = videoCover;
      handlers.updateVideoCover(
        currentInsight.id,
        currentInsight.lang,
        videoCover,
      );
      dispatch(SetPublishInsight({ ...currentInsight }));
    },
    UpdateTags: (tagList, publishData, id, language, handlers, tagType) => {
      const beforeTagIds = publishData[tagType].map((x) => x.Id);
      const afterTagIds = tagList.map((x) => x.Id);
      const removeTagIds = beforeTagIds.filter(
        (beforeTagId) => !afterTagIds.includes(beforeTagId),
      );
      const newTagIds = afterTagIds.filter(
        (afterTagId) => !beforeTagIds.includes(afterTagId),
      );

      if (publishData.tagsIsEditable) {
        dispatch(
          SetPublishInsight({
            ...publishData,
            [tagType]: tagList,
            tagsIsEditable: false,
          }),
        );
        const updateCallback = (success) => {
          const updateData = { ...publishData, tagsIsEditable: true };
          if (success) {
            // if update was successful keep selected tags, else roll back
            updateData[tagType] = tagList;
          }
          dispatch(SetPublishInsight(updateData));
        };
        if (removeTagIds.length) {
          handlers.removeTags(id, language, removeTagIds, () => {
            if (newTagIds.length) {
              handlers.addTags(id, language, newTagIds, updateCallback);
            } else {
              updateCallback(true);
            }
          });
        } else if (newTagIds.length) {
          handlers.addTags(id, language, newTagIds, updateCallback);
        }
      }
    },
    UpdateStartPublicationDate: (currentVersion, scheduledDate) => {
      currentVersion.startPostingDate = scheduledDate;
      currentVersion.dateSelectorType = DATE_SELECTOR_TYPE.SCHEDULE;
      dispatch(SetPublishInsight({ ...currentVersion }));
    },
    UpdateEndPublicationDate: (currentVersion, scheduledDate) => {
      currentVersion.endPostingDate = scheduledDate;
      currentVersion.dateSelectorType = DATE_SELECTOR_TYPE.SCHEDULE;
      dispatch(SetPublishInsight({ ...currentVersion }));
    },
    UpdatePrivacy: (
      insightId,
      insightLang,
      publishData,
      isPublic,
      handlers,
    ) => {
      if (isPublic) {
        handlers.removePrivacy(insightId, insightLang, () => {
          dispatch(SetPublishInsight({ ...publishData, isPublic }));
        });
      } else {
        handlers.setPrivacy(insightId, insightLang, () => {
          dispatch(SetPublishInsight({ ...publishData, isPublic }));
        });
      }
    },
    SaveTagsToState: (tagList, tagType) => {
      dispatch(SetCurrentTags([...tagList], tagType));
    },
    CreateNewTag: (tagName, handlers, currentVersion, id, language) => {
      handlers.createTag(tagName, (data) => {
        const newTag = {
          Id: data.data.tag.createCustom.tagId,
          Value: data.data.tag.createCustom.title,
        };
        currentVersion.tags = [...currentVersion.tags, newTag];
        handlers.addTags(id, language, [newTag.Id], () => {
          dispatch(SetPublishInsight({ ...currentVersion }));
        });
      });
    },
    GetInsightData: (insightId, languageId) => {
      dispatch(
        GetInsightData(insightId, languageId, () => SetScreenIsReady(true)),
      );
    },
    SetScreenIsReady,
    UpdateInsightWorkflow: () => dispatch(UpdateInsightWorkflow()),
    WarningAlert: (message) => dispatch(SetWarningMessage(message)),
  };
};

const Container = compose(
  updateInsightTitle,
  updateInsightSumarry,
  updateInsightPicCover,
  updateInsightVideoCover,
  updateInsightPicThumb,
  addInsightTags,
  removeInsightTags,
  setInsightPrivacy,
  removeInsightPrivacy,
  createTag,
  injectIntl,
)(withThemedStyle(PublishInsightStyle)(PublishInsight));

export default withRouter(
  connect(mapStateToProp, mapDispatchToProps)(Container),
);
