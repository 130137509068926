import gql from "graphql-tag";

export const GET_ASK_DATA = gql`
  query kpiGetAskData(
    $fromDate: String
    $toDate: String
    $tags: [kpiTagsType!]
  ) {
    askKPI(fromDate: $fromDate, toDate: $toDate, tags: $tags) {
      geniusQuestionNb
      expertQuestionNb
      expertAnswerNb
      popularExpertTags {
        nb
        label
      }
      popularGeniusTags {
        nb
        label
      }
    }
  }
`;
