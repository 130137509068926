import React from "react";
import { DropTarget } from "react-dnd";
import ItemTypes from "./ItemTypes";
import DraggableBox, { DraggableBoxUnconnected } from "./DraggableBox";
import { calculateItemDragPosition } from "./utils";

const styles = {
  position: "relative",
};

class Container extends React.PureComponent {
  render() {
    const { connectDropTarget, elements, containerSize } = this.props;

    const content = (
      <div style={{ ...styles, ...containerSize }}>
        {elements.map((element, index) => this.renderBox(element, index))}
      </div>
    );

    return connectDropTarget ? connectDropTarget(content) : content;
  }

  renderBox(element, index) {
    const { id } = element;
    const customParentProps =
      (this.props.getDraggedElementContainerProps &&
        this.props.getDraggedElementContainerProps(element)) ||
      undefined;

    const DraggableBoxComponent = this.props.connectDropTarget
      ? DraggableBox
      : DraggableBoxUnconnected;

    return (
      <DraggableBoxComponent
        key={id}
        id={id}
        element={element}
        customProps={customParentProps}
      >
        {this.props.renderDraggedElement(element, index)}
      </DraggableBoxComponent>
    );
  }
}

export const ContainerUnconnected = Container;

export default DropTarget(
  ItemTypes.BOX,
  {
    drop(props, monitor, component) {
      if (!component) {
        return;
      }
      const delta = monitor.getDifferenceFromInitialOffset();
      const item = monitor.getItem();
      let left = Math.round(item.x + delta.x);
      let top = Math.round(item.y + delta.y);

      if (item.respectBounds) {
        // recalculate coordinates to respect the bounds of the container
        left = Math.max(0, left);
        top = Math.max(0, top);

        left = Math.min(props.containerSize.width - item.width, left);
        top = Math.min(props.containerSize.height - item.height, top);
      }

      props.setElementPosition(item, left, top);
    },
    canDrop({ validateElementPosition }, monitor) {
      if (typeof validateElementPosition === "function") {
        const item = monitor.getItem();
        const initialOffset = monitor.getInitialSourceClientOffset();
        const currentOffset = monitor.getSourceClientOffset();

        const itemDragPosition = calculateItemDragPosition(
          item,
          initialOffset,
          currentOffset,
        );

        return validateElementPosition(item, itemDragPosition);
      }
      return true;
    },
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(Container);
