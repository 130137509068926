import { defineMessages } from "react-intl";

export default defineMessages({
  BattleEmptyQuestionTitle: {
    id: "BattleQuestionEditor.BattleEmptyQuestionTitle",
    defaultMessage: "Enter a question title here...",
  },
  BattleEmptyDescriptionTitle: {
    id: "BattleQuestionEditor.BattleEmptyDescriptionTitle",
    defaultMessage: "Enter a description here...",
  },
  CorrectColumnLabel: {
    id: "BattleQuestionEditor.CorrectColumnLabel",
    defaultMessage: "Correct",
  },
  ChoiceTextColumnLabel: {
    id: "BattleQuestionEditor.ChoiceTextColumnLabel",
    defaultMessage: "Choice text",
  },
  AddAChoiceButtonLabel: {
    id: "BattleQuestionEditor.AddAChoiceButtonLabel",
    defaultMessage: "Add a Choice (max {maxNrOfAnswers} answers)",
  },
});
