export default (theme) => ({
  modalContent: {
    padding: "15px 20px 15px 20px",
    zIndex: 99999,
    maxWidth: 700,
    maxHeight: 500,
    backgroundColor: theme.CloneModal.modalContentBackgroundColor,
    borderRadius: 4,
    fontFamily: theme.Fonts.baseFontFamily,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 0.07,
    "& .icon-slim-close-icon": {
      cursor: "pointer",
    },
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: 22,
    fontWeight: "bold",
    color: theme.CloneModal.titleColor,
  },
  messageLabel: {
    fontSize: 17,
    fontWeight: "300",
    letterSpacing: 0.06,
    color: theme.CloneModal.messageLabelColor,
    textAlign: "center",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // width: '200px',
    gap: 10,
    padding: "15px 0px 5px",
  },
  cancelBtn: {
    marginRight: 30,
  },
});
