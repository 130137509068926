import gql from "graphql-tag";

export const LESSON_CLONE = gql`
  mutation LessonClone($lessonId: ID, $lang: LangEnum!, $parentLearningId: ID) {
    admin {
      lesson(lessonId: $lessonId, lang: $lang) {
        clone(
          lang: $lang
          cloneTags: false
          cloneTagsFeature: true
          parentLearningId: $parentLearningId
        ) {
          lessonId
        }
      }
    }
  }
`;
