import { defineMessages } from "react-intl";

export const translations = defineMessages({
  LeaderboardTitle: {
    id: "BattleResultsScreen.LeaderboardTitle",
    defaultMessage: "Battle Leaderboard",
  },
  NumberOfParticipantsLabel: {
    id: "BattleResultsScreen.NumberOfParticipantsLabel",
    defaultMessage: "Number of participants",
  },
  AverageParticipantsLabel: {
    id: "BattleResultsScreen.AverageParticipantsLabel",
    defaultMessage: "Participants average score",
  },
  InformUsers: {
    id: "ExportKpiBattleInfo.InformUsers",
    defaultMessage:
      "Dates selected before September, 2019 will hold no data, and data before end of January 2020 includes only testing or pre-launch training. I also cannot select dates in the future.",
  },
});

export const exportTranslations = defineMessages({
  NameColumnTitle: {
    id: "ExportKpiBattleInfo.NameColumnTitle",
    defaultMessage: "Name",
  },
  StatusColumnTitle: {
    id: "ExportKpiBattleInfo.StatusColumnTitle",
    defaultMessage: "Status",
  },
  CreationDateColumnTitle: {
    id: "ExportKpiBattleInfo.CreationDateColumnTitle",
    defaultMessage: "Creation date",
  },
  PublicationDateColumnTitle: {
    id: "ExportKpiBattleInfo.PublicationDateColumnTitle",
    defaultMessage: "Publication date",
  },
  ParticipantsColumnTitle: {
    id: "ExportKpiBattleInfo.ParticipantsColumnTitle",
    defaultMessage: "Number of participants",
  },
  AverageScoreColumnTitle: {
    id: "ExportKpiBattleInfo.AverageScoreColumnTitle",
    defaultMessage: "Participants average score",
  },
  MarketsColumnTitle: {
    id: "ExportKpiBattleInfo.MarketsColumnTitle",
    defaultMessage: "Markets",
  },
  BrandsColumnTitle: {
    id: "ExportKpiBattleInfo.BrandsColumnTitle",
    defaultMessage: "Brands",
  },
});
