import React from "react";
import { DragLayer } from "react-dnd";
import ItemTypes from "./ItemTypes";
import { calculateItemDragPosition } from "./utils";

const layerStyles = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
};

function getItemStyles(props) {
  const { initialOffset, currentOffset } = props;
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }
  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

const CustomDragLayer = (props) => {
  const {
    item,
    itemType,
    isDragging,
    renderDraggedElement,
    initialOffset,
    currentOffset,
    validateElementPosition,
  } = props;

  if (!isDragging) {
    return null;
  }

  let isPositionValid = true;
  if (currentOffset && initialOffset) {
    const dragPosition = calculateItemDragPosition(
      item,
      initialOffset,
      currentOffset,
    );
    isPositionValid = validateElementPosition(item, dragPosition);
  }

  function renderItem() {
    switch (itemType) {
      case ItemTypes.BOX:
        return (
          <div>{renderDraggedElement(item, isDragging, isPositionValid)}</div>
        );
      default:
        return null;
    }
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(props)}>{renderItem()}</div>
    </div>
  );
};

export default DragLayer((monitor) => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  initialOffset: monitor.getInitialSourceClientOffset(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
}))(CustomDragLayer);
