export const restTagsToDropDown = (tags = []) =>
  tags.map(({ id, name }) => ({ value: id, label: name }));

export const idTitleToDropDown = ({ id, title }) => ({
  value: id,
  label: title,
});

export const idNameToDropDown = ({ id, name }) => ({ value: id, label: name });

export const docIdTitleToDropDown = ({ docId, title }) => ({
  value: docId,
  label: title,
});

export const tagIdTitleToDropDown = ({ tagId, title }) => ({
  value: tagId,
  label: title,
});

export const degreeIdTitleToDropDown = ({ degreeId, title }) => ({
  value: degreeId,
  label: title,
});
