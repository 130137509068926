import * as constants from "../../constants/store/sendNotification";
import uuidV4 from "uuid/dist/v4";

const initialProfile = {
  selectedDepartments: [],
  selectedRoles: [],
  selectedServices: [],
  selectedZones: [],
  selectedCountries: [],
  selectedCities: [],
  selectedLocations: [],
  selectedBrands: [],
  selectedMarkets: [],
  fullChecked: [],
};

const initialState = {
  showModal: false,
  notificationData: {
    docType: null,
    docId: null,
    ocId: null,
    lang: null,
    tagIds: [],
    selectableTags: [],
    userIds: [],
    message: "",
    send: true,
    profiles: [
      {
        ...initialProfile,
        uuid: "1",
      },
    ],
  },
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.SET_SHOW_MODAL:
      return {
        ...state,
        showModal: data,
      };
    case constants.SET_NOTIFICATION_DATA:
      return {
        ...state,
        notificationData: {
          ...initialState.notificationData,
          ...data,
        },
      };
    case constants.ADD_NEW_PROFILE: {
      return {
        ...state,
        notificationData: {
          ...state.notificationData,
          profiles: [
            ...state.notificationData.profiles,
            {
              ...initialProfile,
              uuid: uuidV4(),
            },
          ],
        },
      };
    }
    case constants.REMOVE_PROFILE: {
      return {
        ...state,
        notificationData: {
          ...state.notificationData,
          profiles: state.notificationData.profiles.filter(
            (profile) => profile.uuid !== data,
          ),
        },
      };
    }
    case constants.RESET_PROFILE: {
      return {
        ...state,
        notificationData: {
          ...state.notificationData,
          profiles: state.notificationData.profiles.map((profile) => {
            if (profile.uuid !== data) return profile;

            return {
              ...initialProfile,
              uuid: data,
            };
          }),
        },
      };
    }
    case constants.RESET_PROFILES: {
      return {
        ...state,
        notificationData: {
          ...state.notificationData,
          profiles: [
            {
              ...initialProfile,
              uuid: "1",
            },
          ],
        },
      };
    }
    case constants.REMOVE_TAG: {
      return {
        ...state,
        notificationData: {
          ...state.notificationData,
          profiles: state.notificationData.profiles.reduce(
            (profiles, profile) => {
              if (profile.uuid === data.uuid) {
                profile = {
                  ...profile,
                  [data.key]: profile[data.key].filter(
                    (tag) => tag.value !== data.value,
                  ),
                };
              }
              profiles = [...profiles, profile];
              return profiles;
            },
            [],
          ),
        },
      };
    }
    case constants.UPDATE_PROFILE_SELECTED_TAGS: {
      return {
        ...state,
        notificationData: {
          ...state.notificationData,
          profiles: state.notificationData.profiles.reduce(
            (profiles, profile) => {
              if (profile.uuid === data.uuid) {
                profile = {
                  ...profile,
                  [data.type]: data.selections,
                  fullChecked: data.fullChecked
                    ? [...profile.fullChecked, data.type]
                    : profile.fullChecked.filter((type) => type !== data.type),
                };
              }
              profiles = [...profiles, profile];
              return profiles;
            },
            [],
          ),
        },
      };
    }
    case constants.RESET_STORE: {
      return { ...initialState };
    }
    case constants.SET_MARKET_BRAND_TAGS: {
      return {
        ...state,
        notificationData: {
          ...state.notificationData,
          profiles: state.notificationData.profiles.map((profile) => ({
            ...profile,
            selectedBrands: data.brands,
            selectedMarkets: data.markets,
          })),
        },
      };
    }
    default:
      return state;
  }
}
