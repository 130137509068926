export const AddToMarketHeaderStyle = (theme) => ({
  header: {
    zIndex: 1,
    position: "fixed",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: "#ffffff",
    height: 60,
    paddingLeft: 96,
    paddingRight: 22,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .right": {
      "& > button:nth-of-type(1)": {
        marginRight: 20,
      },
    },
    "& .left": {
      fontSize: 18,
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    "& .backIcon": {
      cursor: "pointer",
      marginRight: 20,
      fontSize: 18,
    },
  },
});
