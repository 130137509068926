import gql from "graphql-tag";

export const GET_TAGS_BY_ID = gql`
  query ($tagIds: [ID!]) {
    tags(tagIds: $tagIds) {
      tagId
      title
    }
  }
`;
