import gql from "graphql-tag";

export const DEGREES_GET_LIST_BY_STATUS = gql`
  query degreesFilterByStatus($status: DocWorkflowEnum) {
    admin {
      degrees(workflow: $status) {
        degreeId
        picCover
        versions {
          title
          updateDate
          lang
          picCover
          workflow
          programs {
            programId
            lessons {
              lessonId
            }
          }
        }
      }
    }
  }
`;
