import PropTypes from "prop-types";
import uuidV4 from "uuid/dist/v4";

export const CreatePollQuestionChoice = (text = "") => ({
  id: uuidV4(),
  text,
});

export const PollQuestionChoicePropTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
