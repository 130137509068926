export default {
  container: {
    position: "relative",
  },
  addBox: {
    position: "absolute",
    zIndex: 1,
    cursor: "pointer",
  },
  addBoxToLeft: {
    top: "calc(100%/2 - 24px)",
    left: -24,
  },
  addBoxToRight: {
    top: "calc(100%/2 - 24px)",
    right: -24,
  },
  addBoxToTop: {
    left: "calc(100%/2 - 24px)",
    top: -24,
  },
  addBoxToBottom: {
    left: "calc(100%/2 - 24px)",
    bottom: -24,
  },
};
