import gql from "graphql-tag";

export const GET_BATTLE_BY_ID = gql`
  query getBattleById($battleId: ID) {
    admin {
      battles(battleId: $battleId) {
        versions {
          battleId
          title
          lang
          picCover
          isPrivate
          workflow
          workflowScheduledIn
          workflowScheduledOut
          summary
          tags {
            tagId
            title
            clusterId
          }
          pages {
            pageId
            data
          }
        }
      }
    }
  }
`;
