import { defineMessages } from "react-intl";

export const translations = defineMessages({
  ModalButtonLabel: {
    id: "HorizontalMarkerSlide.ModalButtonLabel",
    defaultMessage: "Crop",
  },
  ModalMessage: {
    id: "HorizontalSlider.ModalMessage",
    defaultMessage: "You need to crop this image before adding a marker.",
  },
});
