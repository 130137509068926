import React from "react";
import PageEditor from "../../components/PageEditor";
import { PAGE_TYPES, PAGE_TEMPLATES } from "../../configs/constants";
import { connect } from "react-redux";
import HorizontalSliderPageEditor from "../../components/SliderPageEditor/Horizontal";
import VerticalSliderPageEditor from "../../components/SliderPageEditor/Vertical";
import ContentNotHandled from "../../components/common/ContentNotHandled";
import { EmptyArticleTemplatePage } from "../../utils/ui-generator/defaultTemplates";
import SimpleQuizPageEditor from "../../components/SimpleQuizPageEditor/index";
import MadlibPageEditor from "../../components/MadlibPageEditor";
import DragAndDropActivityPageEditor from "../DragAndDropActivityPageEditor";

const EditPages = ({ pages, ...props }) => (
  <div className={props.classes.rootContainer}>
    {pages.map((item) => {
      switch (item.template) {
        case PAGE_TEMPLATES.SLIDERFULLSCREEN_TEMPLATE:
          return (
            <div className={props.classes.pageItemBox} key={item.pageId}>
              <HorizontalSliderPageEditor
                pageId={item.pageId}
                data={item.data}
                orderId={item.index}
                documentId={props.insightId}
                language={props.language}
                onDataChanged={props.onDataChanged}
                onRemovePage={props.onRemovePage}
                onOrderDownClick={props.onOrderDown}
                onOrderUpClick={props.onOrderUp}
                currentOpenedMarker={props.currentOpenedMarker}
                UpdateOpenedMarker={(...args) =>
                  props.UpdateOpenedMarker(item.pageId, ...args)
                }
                isActivePage={props.activePageId === item.pageId}
                onFocus={() => props.onPageChange(item.pageId)}
              />
            </div>
          );
        case PAGE_TEMPLATES.FULLSCREENSCROLL_TEMPLATE:
          return (
            <div className={props.classes.pageItemBox} key={item.pageId}>
              <VerticalSliderPageEditor
                pageId={item.pageId}
                data={item.data}
                orderId={item.index}
                documentId={props.insightId}
                language={props.language}
                onDataChanged={props.onDataChanged}
                onRemovePage={props.onRemovePage}
                onOrderDownClick={props.onOrderDown}
                onOrderUpClick={props.onOrderUp}
                currentOpenedMarker={props.currentOpenedMarker}
                UpdateOpenedMarker={(...args) =>
                  props.UpdateOpenedMarker(item.pageId, ...args)
                }
                isActivePage={props.activePageId === item.pageId}
                onFocus={() => props.onPageChange(item.pageId)}
              />
            </div>
          );
        case PAGE_TEMPLATES.ARTICLE_TEMPLATE:
          return (
            <div className={props.classes.pageItemBox} key={item.pageId}>
              <PageEditor
                pageId={item.pageId}
                data={item.data}
                orderId={item.index}
                documentId={props.insightId}
                language={props.language}
                onDataChanged={props.onDataChanged}
                onPicChanged={props.onPicChanged}
                onRemovePage={props.onRemovePage}
                onNewPageCreated={() =>
                  props.onNewPageCreated(
                    props.insightId,
                    props.language,
                    JSON.stringify(EmptyArticleTemplatePage),
                    PAGE_TYPES.ARTICLE,
                  )
                }
                onPageChange={props.onPageChange}
                isActivePage={props.activePageId === item.pageId}
                onOrderDownClick={props.onOrderDown}
                onOrderUpClick={props.onOrderUp}
              />
            </div>
          );
        case PAGE_TEMPLATES.SIMPLE_QUIZ_TEMPLATE:
          return (
            <div className={props.classes.pageItemBox} key={item.pageId}>
              <SimpleQuizPageEditor
                pageId={item.pageId}
                data={item.data}
                orderId={item.index}
                documentId={props.insightId}
                language={props.language}
                onDataChanged={props.onDataChanged}
                onRemovePage={props.onRemovePage}
                isActivePage={props.activePageId === item.pageId}
                onOrderDownClick={props.onOrderDown}
                onOrderUpClick={props.onOrderUp}
              />
            </div>
          );
        case PAGE_TEMPLATES.MADLIB_TEMPLATE:
          return (
            <div className={props.classes.pageItemBox} key={item.pageId}>
              <MadlibPageEditor
                pageId={item.pageId}
                data={item.data}
                orderId={item.index}
                documentId={props.insightId}
                language={props.language}
                onDataChanged={props.onDataChanged}
                onRemovePage={props.onRemovePage}
                isActivePage={props.activePageId === item.pageId}
                onOrderDownClick={props.onOrderDown}
                onOrderUpClick={props.onOrderUp}
              />
            </div>
          );
        case PAGE_TEMPLATES.DRAG_AND_DROP_TEMPLATE:
          return (
            <div className={props.classes.pageItemBox} key={item.pageId}>
              <DragAndDropActivityPageEditor
                pageId={item.pageId}
                data={item.data}
                orderId={item.index}
                documentId={props.insightId}
                language={props.language}
                onDataChanged={props.onDataChanged}
                onRemovePage={props.onRemovePage}
                isActivePage={props.activePageId === item.pageId}
                onOrderDownClick={props.onOrderDown}
                onOrderUpClick={props.onOrderUp}
              />
            </div>
          );
        default:
          return (
            <div className={props.classes.pageItemBox} key={item.pageId}>
              <ContentNotHandled orderId={item.index} />
            </div>
          );
      }
    })}
  </div>
);

const mapStateToProp = (state) => ({
  pages: state.insights.currentInsightPages,
});

export default connect(mapStateToProp)(EditPages);
