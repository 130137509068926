import React from "react";
import PropTypes from "prop-types";
import { Icon, Icons } from "genius-ui";
import { FormattedMessage } from "react-intl";
import { translations } from "./UserProfileHeader.translations";
import styles from "./UserProfileHeader.style";
import DownloadButton from "../../../components/KPI/DownloadButton";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(styles);

const UserProfileHeader = (props) => {
  const { handleBack, lastConnection, userName, onExport } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.header}>
      <div>
        <div>
          <span onClick={handleBack} className={classes.back}>
            <Icon iconName={Icons.arrowLeft} alt="" />
          </span>
          <span className={classes.name}>{userName}</span>
        </div>
        <div className={classes.lastConnection}>
          {lastConnection ? (
            <FormattedMessage
              {...translations.LastConnectionLabel}
              values={{ date: lastConnection }}
            />
          ) : (
            <FormattedMessage {...translations.NotConnected} />
          )}
          {onExport && (
            <div className={classes.exportButton}>
              <DownloadButton onClickHandler={onExport} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

UserProfileHeader.propTypes = {
  handleBack: PropTypes.func.isRequired,
  lastConnection: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  onExport: PropTypes.func,
};

export default UserProfileHeader;
