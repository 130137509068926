import React from "react";
import PropTypes from "prop-types";
import { sizePropType, positionPropType } from "../../../utils/customPropTypes";

const HoledMask = ({
  color,
  size: { width: maskWidth, height: maskHeight },
  holeSize: { width, height },
  holePosition: { x, y },
  id,
}) => (
  <svg width={maskWidth} height={maskHeight}>
    <defs>
      <mask id={`hole_${id}`}>
        <rect width="100%" height="100%" fill="white" />
        <rect width={width} height={height} x={x} y={y} fill="black" />
      </mask>
    </defs>
    <rect
      id={`mask_${id}`}
      width="100%"
      height="100%"
      mask={`url(#hole_${id})`}
      style={{ fill: color }}
    />
  </svg>
);

HoledMask.propTypes = {
  /** Mask color(any valid css color string) **/
  color: PropTypes.string,
  /** Mask size **/
  size: sizePropType.isRequired,
  /** Hole size in the mask. The size of the transparent area inside the mask **/
  holeSize: sizePropType.isRequired,
  /** The position where the (hole)transparent area begins relative to the top left corner of the mask **/
  holePosition: positionPropType.isRequired,
};

HoledMask.defaultProps = {
  color: "rgba(74, 144, 226, 0.3)",
  size: {
    width: 414,
    height: 735,
  },
  holeSize: {
    width: 334,
    height: 560,
  },
  holePosition: {
    x: 40,
    y: 50,
  },
  id: 1,
};

export default HoledMask;
