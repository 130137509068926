import {
  UPDATE_PAGE_DATA,
  UPDATE_FILTER_AVAILABLE_VALUES,
  UPDATE_FILTER_SELECTED_VALUES,
} from "../../constants/store/kpiFeedDetails";
import { SetErrorMessage } from "../notification/actions";
import {
  extractFilterValues,
  getFormattedTagList,
} from "../actions/common/kpi";
import { kpiFeedDocumentToKpiFeedPageData } from "./transformers";
import client from "../../graphql/client";
import { DEGREES_GET_SHORT_DETAIL_LIST } from "../../graphql/remote/degrees/queries/GetShortDetailList";
import { GET_FEED_DATA } from "../../graphql/remote/kpi/queries/feed.graphql";
import { isShiseido } from "../../configs/client";
import {
  extendTagsWithUserTags,
  filterTagsByTrainerTags,
} from "../../utils/transformers";
import { USER_ROLE_NAMES } from "../../configs/constants";
import { getFormattedSopiDate } from "../../utils/dateUtils";
import { SetIsLoading } from "store/kpiFilter/actions";

export const UpdatePageData = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PAGE_DATA,
    data,
  });
};

export const UpdateFilterAvailableValues =
  (filterName, availableValues) => (dispatch) => {
    dispatch({
      type: UPDATE_FILTER_AVAILABLE_VALUES,
      data: { filterName, availableValues },
    });
  };

export const UpdateFilterSelectedValues =
  (filterName, selectedValues) => (dispatch) => {
    dispatch({
      type: UPDATE_FILTER_SELECTED_VALUES,
      data: { filterName, selectedValues },
    });
  };

export const GetPageData = (callback) => async (dispatch, getState) => {
  const { profileInfo } = getState().auth;
  const { filters } = getState().kpi;
  if (filters.isLoading) return;

  try {
    if (!filters.interval?.selectedValues[0]?.value && !filters.startDate) {
      callback && callback();
      return;
    }
    dispatch(SetIsLoading(true));

    const generalFilterValues = extractFilterValues(filters);

    /** If user is a trainer always get data from own markets */
    if (isShiseido && profileInfo.roles.includes(USER_ROLE_NAMES.TRAINER)) {
      const markets = filterTagsByTrainerTags(
        profileInfo.tags,
        getState().tags.markets,
      );
      generalFilterValues.market = extendTagsWithUserTags(
        generalFilterValues.market,
        markets,
      );
    }
    const tagList = getFormattedTagList(generalFilterValues);
    const variables = {
      tags: tagList.length ? tagList : undefined,
    };

    if (filters.interval?.selectedValues[0]?.value)
      variables.intervalId = filters.interval.selectedValues[0].value;
    else {
      variables.fromDate = getFormattedSopiDate(
        filters.startDate.selectedValues,
      );
      variables.toDate = getFormattedSopiDate(filters.endDate.selectedValues);
    }

    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_FEED_DATA,
      variables,
    });

    dispatch(
      UpdatePageData(kpiFeedDocumentToKpiFeedPageData(result.data.feedKPI)),
    );
    dispatch(SetIsLoading(false));
    callback && callback();
  } catch (e) {
    dispatch(
      SetErrorMessage("An error occurred while fetching the kpi feed data", e),
    );
  }
};

export const GetAvailableDegrees = () => async (dispatch) => {
  try {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: DEGREES_GET_SHORT_DETAIL_LIST,
    });

    dispatch(
      UpdateFilterAvailableValues("degree", [
        ...new Set(result.data.admin.degrees),
      ]),
    );
  } catch (e) {
    dispatch(
      SetErrorMessage(
        "An error occurred while fetching the available degrees for the kpi feed page degree filter",
        e,
      ),
    );
  }
};
