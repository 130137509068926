import React from "react";
// Sample
// import { isDiorVM } from 'configs/client'
// import DiorVmInsightCard from './DIORVM/InsightCard'
// import DiorVmDegree from './DIORVM/Degree'
// import DiorVmLesson from './DIORVM/Lesson'

const PublishSwitchClient = ({ type, ...props }) => {
  // if (isDiorVM) {
  //   switch (type) {
  //     case 'insight':
  //     case 'card':
  //       return <DiorVmInsightCard type={type} {...props} />
  //     case 'degree':
  //       return <DiorVmDegree type={type} {...props} />
  //     case 'lesson':
  //       return <DiorVmLesson type={type} {...props} />
  //     default :
  //       return null
  //   }
  // }
  return null;
};

export default React.memo(PublishSwitchClient);
