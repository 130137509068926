export const CHANGE_VIEW_TYPE = "CHANGE_VIEW_TYPE";
export const RESET_STORE = "RESET_SHOPS_STORE";
export const SET_SHOPS = "SET_SHOPS";
export const UPDATE_SHOP_TABLE_META_DATA = "UPDATE_SHOP_TABLE_META_DATA";
export const UPDATE_FILTER_SELECTED_VALUES = "UPDATE_FILTER_SELECTED_VALUES";
export const RESET_FILTERS = "RESET_FILTERS";
export const RESET_SHOPS_WITH_DATA = "RESET_SHOPS_WITH_DATA";
export const UPDATE_EXPORTING_STATUS = "UPDATE_EXPORTING_STATUS";
export const UPDATE_HAS_MORE_ITEM = "UPDATE_HAS_MORE_ITEM";
export const UPDATE_SEARCH_TEXT = "UPDATE_SEARCH_TEXT";
export const UPDATE_FILTER_LOCATION_TYPE_SELECTED_VALUES =
  "UPDATE_FILTER_LOCATION_TYPE_SELECTED_VALUES";
export const SET_SPECIFICITIES = "SET_SPECIFICITIES";
export const SET_SPECIFICITIES_FIELD = "SET_SPECIFICITIES_FIELD";
