import gql from "graphql-tag";

export const GET_DOCUMENTS_NUMBER = gql`
  query getDocumentsNumber {
    admin {
      dashboard {
        documents {
          nb
          type
          lang
          workflow
        }
      }
    }
  }
`;
