import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import compose from "lodash/fp/compose";
import { mapTagToCheckDropdownOption } from "../../../utils/transformers";
import {
  CheckDropdown,
  Dropdown,
  Tag,
  TagVariation,
  Icon,
  Icons,
  SearchInput,
} from "genius-ui";
import { FormattedMessage, injectIntl } from "react-intl";
import DownloadButton from "../DownloadButton";
import { connect } from "react-redux";
import style from "./FilterHeader.style";
import {
  UpdateFilterSelectedValues,
  RemoveFilterSelectedValue,
  ResetFilters,
  SetIsExportLoading,
  GetIntervals,
} from "../../../store/kpiFilter/actions";
import {
  GetRetailersByCountryIds,
  SetRetailerTags,
  GetBrandTags,
} from "../../../store/tag/actions";
import { shiseidoFilterHeaderTranslations as translations } from "./FilterHeader.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import PeriodSelector from "../PeriodSelector";
import SearchButton from "../../common/SearchButton";

const useStyle = createUseStyles(style);
const Tags = React.memo(({ selected, handleRemove, readOnly }) =>
  selected.map((tag, key) => (
    <Tag
      readOnly={readOnly}
      key={key}
      label={tag.label}
      variation={TagVariation.blue}
      handleRemoveClick={() => handleRemove(tag)}
    />
  )),
);

const ShiseidoFilterHeader = (props) => {
  const [showSearchInput, setSearchInputVisibility] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const classes = useThemedStyle(useStyle, { ...props });

  const {
    selectedPeriod,
    periods,
    SetPeriodFilter,
    ResetFilters,
    brands,
    selectedBrands,
    SetBrandsFilter,
    RemoveBrandFilteredTag,
    relationship,
    selectedRelationships,
    SetRelationshipFilter,
    RemoveRelationshipFilteredTag,
    retailers,
    SetRetailerFilter,
    selectedRetailers,
    RemoveRetailerFilteredTag,
    SetMarketFilter,
    selectedMarkets,
    RemoveMarketFilteredTag,
    exportFunction,
    intl,
    SetIsDownloadLoading,
    showRangeSelector,
    SetStartDateFilter,
    SetEndDateFilter,
    startDate,
    endDate,
    onFilterChange,
    userProfileInfo,
    hideExportButton,
    goBack,
    onSearch,
    hideDateSelector,
    isTrainer,
    GetBrandTags,
    isLoading,
    GetIntervals,
    doNotLoad,
  } = props;

  /** if user role is trainer filter the markets **/
  const markets = props.markets.map(mapTagToCheckDropdownOption);
  // Reset filters when the filter component mounts(when entering a KPI page)

  const trainerInit = () => {
    if (isTrainer) {
      const countryTag = userProfileInfo.tags.find(
        ({ clusterId }) => clusterId === "2",
      );
      if (countryTag) {
        SetMarketFilter(
          markets.filter(
            (market) =>
              market.value === `${countryTag.tagId}-${countryTag.clusterId}`,
          ),
        );
      }
    }
  };

  const resetFiltersWithTrainerCheck = () => {
    ResetFilters();
    trainerInit();
  };

  useEffect(() => {
    trainerInit();
  }, [isTrainer]);

  useEffect(() => {
    if (!doNotLoad) {
      onFilterChange();
    }
    GetIntervals();
  }, []);

  useEffect(() => {
    GetBrandTags();
  }, [selectedMarkets]);

  const filterCounter =
    selectedBrands.length +
    selectedRelationships.length +
    selectedRetailers.length +
    selectedMarkets.length;

  const searchInputOnChange = ({ target: { value } }) => {
    setSearchInputValue(value);
    onSearch(value);
  };

  return (
    <div className={classes.headerContainer}>
      <div className={classes.header}>
        <div className={classes.filterContainer}>
          <div className={classes.leftSide}>
            {goBack && (
              <Icon
                iconName={Icons.arrowLeft}
                style={classes.backButton}
                handleClick={goBack}
              />
            )}
            {showSearchInput && (
              <FormattedMessage {...translations.SearchAUserInputLabel}>
                {(message) => (
                  <SearchInput
                    value={searchInputValue}
                    onChange={searchInputOnChange}
                    onClear={() => setSearchInputVisibility(false)}
                    clearOnLeft
                    placeholder={message[0]}
                  />
                )}
              </FormattedMessage>
            )}
            {!showSearchInput && (
              <div className={classes.filters}>
                <CheckDropdown
                  key="markets"
                  onChangeHandler={SetMarketFilter}
                  options={markets}
                  selectedValues={selectedMarkets}
                  disabled={isTrainer}
                  label={
                    <FormattedMessage {...translations.MarketFilterLabel} />
                  }
                />
                <CheckDropdown
                  key="relation"
                  onChangeHandler={SetRelationshipFilter}
                  options={relationship}
                  selectedValues={selectedRelationships}
                  label={
                    <FormattedMessage
                      {...translations.RelationshipFilterLabel}
                    />
                  }
                />
                <CheckDropdown
                  key="retailers"
                  onChangeHandler={SetRetailerFilter}
                  options={retailers}
                  selectedValues={selectedRetailers}
                  label={
                    <FormattedMessage {...translations.RetailerFilterLabel} />
                  }
                />
                <CheckDropdown
                  key="brand"
                  onChangeHandler={SetBrandsFilter}
                  options={brands}
                  selectedValues={selectedBrands}
                  label={
                    <FormattedMessage {...translations.BrandFilterLabel} />
                  }
                />
                <div
                  className={classes.buttonContainer}
                  onClick={onFilterChange}
                >
                  <FormattedMessage {...translations.ApplyFilters} />
                </div>
              </div>
            )}
          </div>
          <div className={classes.rightSide}>
            {onSearch && !showSearchInput && (
              <SearchButton
                onClick={() => setSearchInputVisibility(true)}
                className={classes.searchButton}
              />
            )}
            {showRangeSelector && !hideDateSelector && (
              <div className={classes.rangeFilter}>
                <PeriodSelector
                  options={periods}
                  selectOptionHandler={SetPeriodFilter}
                  selectStartDateHandler={SetStartDateFilter}
                  selectEndDateHandler={SetEndDateFilter}
                  selectedOption={selectedPeriod}
                  startDate={startDate}
                  endDate={endDate}
                  onFilterChange={onFilterChange}
                />
              </div>
            )}
            {!showRangeSelector && !hideDateSelector && (
              <div className={classes.periodFilter}>
                <Dropdown
                  options={periods}
                  onChange={SetPeriodFilter}
                  selectedOption={selectedPeriod}
                />
              </div>
            )}
            {!hideExportButton && (
              <DownloadButton
                isLoading={isLoading}
                onClickHandler={() => {
                  SetIsDownloadLoading(true);
                  exportFunction(
                    {
                      selectedMarkets,
                      selectedRelationships,
                      selectedRetailers,
                      selectedBrands,
                      selectedInterval: selectedPeriod,
                      startDate,
                      endDate,
                    },
                    intl,
                  );
                  SetIsDownloadLoading(false);
                }}
              />
            )}
          </div>
        </div>
        <div className={classes.filterTags}>
          <Tags
            selected={selectedMarkets}
            handleRemove={RemoveMarketFilteredTag}
            readOnly={isTrainer}
          />
          <Tags
            selected={selectedRetailers}
            handleRemove={RemoveRetailerFilteredTag}
          />
          <Tags
            selected={selectedBrands}
            handleRemove={RemoveBrandFilteredTag}
          />
          <Tags
            selected={selectedRelationships}
            handleRemove={RemoveRelationshipFilteredTag}
          />
          {!!(filterCounter - (isTrainer ? 1 : 0)) && (
            <div
              className={classes.clearButton}
              onClick={resetFiltersWithTrainerCheck}
            >
              <FormattedMessage {...translations.ClearAllLabel} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ShiseidoFilterHeader.propTypes = {
  /** function executed after any of the filters change **/
  onFilterChange: PropTypes.func,
  /** data export handler function **/
  exportFunction: PropTypes.func,
  /** show/hide the extended date selector **/
  showRangeSelector: PropTypes.bool,
  /** show/hide the export button */
  hideExportButton: PropTypes.bool,
  /** executed by clicking on the back arrow button **/
  goBack: PropTypes.func,
  /** executed by typing in the search input **/
  onSearch: PropTypes.func,
  /** show/hide date selectors **/
  hideDateSelector: PropTypes.bool,
  /** use external intervals **/
  useIntervals: PropTypes.bool,
};

const mapStateToProps = (state, props) => ({
  selectedPeriod: props.useIntervals
    ? state.kpi.filters.interval?.selectedValues[0]
    : state.kpi.filters.period.selectedValues[0],
  selectedBrands: state.kpi.filters.brands.selectedValues,
  selectedRelationships: state.kpi.filters.relationship.selectedValues,
  selectedRetailers: state.kpi.filters.retailer.selectedValues,
  selectedMarkets: state.kpi.filters.market.selectedValues,
  periods: props.useIntervals
    ? state.kpi.filters.intervals
    : state.kpi.filters.availablePeriods,
  startDate: state.kpi.filters.startDate.selectedValues,
  endDate: state.kpi.filters.endDate.selectedValues,
  filters: state.kpi.filters,
  brands: state.tags.brands.map(mapTagToCheckDropdownOption),
  relationship: state.tags.relationships.map(mapTagToCheckDropdownOption),
  retailers: state.tags.retailers.map(mapTagToCheckDropdownOption),
  markets: state.tags.markets,
  userProfileInfo: state.auth.profileInfo,
  isTrainer: state.auth.profileInfo.roles.some((role) => role === "trainer"),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  SetPeriodFilter: (newPeriod) => {
    if (ownProps.useIntervals)
      dispatch(UpdateFilterSelectedValues("interval", [newPeriod]));
    else dispatch(UpdateFilterSelectedValues("period", [newPeriod]));
  },
  SetStartDateFilter: (startDate) => {
    dispatch(UpdateFilterSelectedValues("startDate", startDate));
  },
  SetEndDateFilter: (endDate) => {
    dispatch(UpdateFilterSelectedValues("endDate", endDate));
  },
  SetBrandsFilter: (brands) => {
    dispatch(UpdateFilterSelectedValues("brands", brands));
  },
  RemoveBrandFilteredTag: (filteredTag) => {
    dispatch(RemoveFilterSelectedValue("brands", filteredTag));
  },
  ResetFilters: () => {
    dispatch(ResetFilters());
    dispatch(SetRetailerTags([]));
  },
  SetRelationshipFilter: (relationship) => {
    dispatch(UpdateFilterSelectedValues("relationship", relationship));
  },
  RemoveRelationshipFilteredTag: (filteredTag) => {
    dispatch(RemoveFilterSelectedValue("relationship", filteredTag));
  },
  SetRetailerFilter: (retailers) => {
    dispatch(UpdateFilterSelectedValues("retailer", retailers));
  },
  RemoveRetailerFilteredTag: (filteredTag) => {
    dispatch(RemoveFilterSelectedValue("retailer", filteredTag));
  },
  SetMarketFilter: (markets) => {
    dispatch(UpdateFilterSelectedValues("market", markets));
    if (markets.length > 0) {
      dispatch(
        GetRetailersByCountryIds(markets.map((x) => x.value.split("-")[0])),
      );
    } else {
      dispatch(SetRetailerTags([]));
    }
  },
  GetBrandTags: () => {
    dispatch(GetBrandTags());
  },
  RemoveMarketFilteredTag: (filteredTag) => {
    dispatch(RemoveFilterSelectedValue("market", filteredTag));
    dispatch(SetRetailerTags([]));
  },
  SetIsDownloadLoading: (isLoading) => {
    dispatch(SetIsExportLoading(isLoading));
  },
  GetIntervals: () => {
    dispatch(GetIntervals());
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ShiseidoFilterHeader);
