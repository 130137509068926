export const ProgressItemStyle = ({
  MultiProgressChart: { progressItem },
  FontFamily,
}) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 40,
    fontFamily: FontFamily,
    fontSize: 14,
    fontWeight: 500,
    color: progressItem.color,
    "& > span": {
      marginRight: 15,
    },
  },
  progressBarContainer: {
    width: "50%",
  },
  progressBar: {
    height: 10,
    borderRadius: 2,
    backgroundColor: progressItem.progressBarBackgroundColor,
    overflow: "hidden",
  },
  filler: {
    borderRadius: 0,
    opacity: ({ progress }) => (progress / 100).toFixed(2),
  },
  percentageLabel: {
    fontSize: 12,
    color: progressItem.color,
    marginLeft: 18,
    marginRight: 0,
    textAlign: "left",
  },
});
