import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";
import style from "./RoundedProfilePicture.style";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const RoundedProfilePicture = (props) => {
  const { className, onClickHandler } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div
      className={classnames([classes.container, className])}
      onClick={onClickHandler}
    />
  );
};

RoundedProfilePicture.propTypes = {
  /** Image url */
  imageUrl: PropTypes.string.isRequired,
  /** className for custom styles */
  className: PropTypes.string,
  /** Image size in pixels */
  size: PropTypes.number,
  /** On image click event handler */
  onClickHandler: PropTypes.func,
};

RoundedProfilePicture.defaultProps = {
  size: 30,
};

export default RoundedProfilePicture;
