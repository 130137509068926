import React, { useEffect } from "react";
import { SelectBadgeLessonScreenStyle } from "./SelectBadgeLessons.style";
import withThemedStyle from "../../../components/hoc/withThemedStyle";
import Navigation from "../../../components/Navigation";
import Header from "../addToMarketHeader";
import { FormattedMessage } from "react-intl";
import { translations } from "./SelectBadgeLessons.translations";
import { getPath } from "../../../core/paths";
import { connect } from "react-redux";
import Badge from "./badge";
import {
  GetMasterDegreeBadges,
  GetDegreeDetails,
  getNewDegreeLessons,
  SetBadgeLessonIds,
  SetBadgesToLessons,
  GetNewLessons,
} from "../../../store/masterTemplate/actions";
import { FullscreenLoader } from "genius-ui";
import { SetFetchIndicator } from "../../../store/common/actions";
import {
  ADD_TO_MARKET_CONTEXT,
  DEGREE_PARTS,
} from "../../../configs/constants";

const SelectBadgeLessons = ({
  classes,
  match: {
    params: { lang, id: documentId, masterId },
  },
  history,
  markets,
  selectedMarketIds,
  GetDegreeCloneDetails,
  GetLessonCloneDetails,
  badges,
  degreeDetails,
  isFetching,
  BadgeLessonValidation,
  lessons,
  SetBadgeLessonIds,
  cloneContextType,
}) => {
  useEffect(() => {
    if (cloneContextType === ADD_TO_MARKET_CONTEXT.DEGREE) {
      GetDegreeCloneDetails(documentId, masterId, lang);
    } else {
      GetLessonCloneDetails(documentId, masterId, lang);
    }
  }, []);

  const goBack = () => {
    if (cloneContextType === ADD_TO_MARKET_CONTEXT.DEGREE) {
      history.push(
        getPath("documentAddToMarket", DEGREE_PARTS.DEGREE, documentId, lang),
      );
    } else {
      history.push(
        getPath("documentAddToMarket", DEGREE_PARTS.LESSON, documentId, lang),
      );
    }
  };

  const onCancel = () => history.push(getPath("masterTemplates"));
  const marketNames = markets
    .filter((market) => selectedMarketIds.includes(parseInt(market.value)))
    .map((market) => market.label)
    .join(", ");

  if (!selectedMarketIds.length) {
    // goBack()
  }

  return (
    <div className={classes.rootContainer}>
      {isFetching && <FullscreenLoader />}
      <Header
        goBack={goBack}
        cancelBtnEventHandler={onCancel}
        submitBtnEventHandler={BadgeLessonValidation}
        title={<FormattedMessage {...translations.Title} />}
        cancelBtnText={<FormattedMessage {...translations.CancelBtnText} />}
        submitBtnText={<FormattedMessage {...translations.ValidationBtnText} />}
        // submitBtnDisabled={!selectedMarketIds.length}
      />
      <div className={classes.contentContainer}>
        <div className="content">
          <div className="title">
            <FormattedMessage
              {...translations.ContentTitle}
              values={{
                markets: marketNames,
                degreeName: degreeDetails.title,
              }}
            />
          </div>
          <div className="subtitle">
            <FormattedMessage {...translations.ContentSubtitle} />
          </div>
          <div className={classes.badges}>
            {badges.length ? (
              badges.map((badge) => (
                <Badge
                  {...badge}
                  key={badge.badgeId}
                  onCheck={SetBadgeLessonIds}
                  lessons={lessons}
                />
              ))
            ) : (
              <div>
                <FormattedMessage {...translations.NoOneBadgeText} />
              </div>
            )}
          </div>
        </div>
      </div>
      <Navigation />
    </div>
  );
};

const mapStateToProp = (state) => ({
  markets: state.degrees.markets,
  selectedMarketIds: state.masterTemplates.saveDegreeMarketTagIds,
  badges: state.masterTemplates.badges,
  degreeDetails: state.masterTemplates.selectedDegreeDetails,
  isFetching: state.common.fetchIndicator,
  lessons: state.masterTemplates.newDegreeLessons,
  cloneContextType: state.masterTemplates.cloneContextType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  GetDegreeCloneDetails: (degreeId, masterId, lang) => {
    dispatch(SetFetchIndicator(true));
    dispatch(
      GetDegreeDetails(masterId, lang, () => {
        dispatch(
          GetMasterDegreeBadges(masterId, lang, () => {
            dispatch(
              getNewDegreeLessons(degreeId, lang, () => {
                dispatch(SetFetchIndicator(false));
              }),
            );
          }),
        );
      }),
    );
  },
  GetLessonCloneDetails: (lessonId, masterDegreeId, lang) => {
    dispatch(SetFetchIndicator(true));
    dispatch(
      GetDegreeDetails(masterDegreeId, lang, () => {
        dispatch(
          GetMasterDegreeBadges(masterDegreeId, lang, () => {
            dispatch(
              GetNewLessons(lessonId, lang, () => {
                dispatch(SetFetchIndicator(false));
              }),
            );
          }),
        );
      }),
    );
  },
  BadgeLessonValidation: () => {
    dispatch(SetFetchIndicator(true));
    dispatch(
      SetBadgesToLessons(() => {
        dispatch(SetFetchIndicator(false));
        ownProps.history.push(getPath("masterTemplates"));
      }),
    );
  },
  SetBadgeLessonIds: (badgeId, lessonId) => {
    dispatch(SetBadgeLessonIds(badgeId, lessonId));
  },
});

export default connect(
  mapStateToProp,
  mapDispatchToProps,
)(withThemedStyle(SelectBadgeLessonScreenStyle)(SelectBadgeLessons));
