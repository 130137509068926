export default ({ ProgramInfo, FontFamily }) => {
  const commonIconStyles = {
    cursor: "pointer",
    paddingLeft: 20,
    "&::before": {
      color: ProgramInfo.iconColor,
      fontSize: 4,
    },
    "&:hover::before": {
      color: ProgramInfo.iconHoverColor,
    },
  };

  return {
    center: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    left: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    programBox: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      margin: "30px 0 50px",
    },
    programTitleWrap: {
      width: "100%",
      paddingBottom: 10,
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      justifyContent: "space-between",
    },
    textInputEditTitle: {
      border: 0,
      outline: 0,
      background: "transparent",
      fontFamily: FontFamily,
      fontSize: 22,
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: 0.09,
      color: ProgramInfo.textInputColor,
      marginBottom: 10,
      flex: 2,
      paddingRight: 20,
    },
    programActionContainer: {
      display: "inline-flex",
      "& > div": {
        position: "relative",
      },
    },
    settingsIcon: {
      ...commonIconStyles,
      "&::before": {
        color: ProgramInfo.iconColor,
        fontSize: 6,
      },
    },
    expandCollapseIcon: {
      ...commonIconStyles,
      "&::before": {
        color: ProgramInfo.iconColor,
        fontSize: 10,
      },
    },
    programSettingsMenu: {
      width: 185,
      left: 40,
      position: "absolute",
      top: 10,
      zIndex: 1,
    },
    lessonSettingsMenu: {
      width: 200,
      right: -185,
      position: "absolute",
      top: 50,
      zIndex: 1,
    },
    textInputEditSummary: {
      border: 0,
      outline: 0,
      background: "transparent",
      fontFamily: FontFamily,
      fontSize: "20px !important",
      fontWeight: "300 !important",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "0.05px",
      color: ProgramInfo.textInputColor,
      marginTop: 10,
      marginBottom: 11,
      flex: 2,
      paddingRight: 20,
    },
    lessonListItem: {
      position: "relative",
      paddingBottom: 10,
      paddingTop: 30,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    tags: {
      marginLeft: 35,
      "& > div": {
        float: "left",
      },
    },
    lessonItemContainer: {
      display: "flex",
      flexDirection: "column",
      borderBottom: "solid 1px #efefef",
      paddingBottom: 20,
    },
    inputIcon: {
      background: "transparent",
      paddingRight: 25,
      marginTop: 7,
      "& > span": {
        "&::before": {
          color: ProgramInfo.inputIconColor,
          fontSize: 10,
        },
      },
    },
    inputWithIcon: {
      border: 0,
      outline: 0,
      background: "transparent",
      fontFamily: FontFamily,
      fontSize: 17,
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: 0.06,
      color: ProgramInfo.inputIconColor,
      flex: 1,
      width: 450,
      overflow: "hidden",
      textOverflow: "ellipsis",
      minWidth: "400px",
    },
    statusPublished: {
      fontFamily: FontFamily,
      fontSize: 14,
      fontWeight: "300",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: 0.05,
      color: ProgramInfo.statusPublishedColor,
      textTransform: "capitalize",
      paddingLeft: 20,
      paddingRight: 20,
    },
    statusDraft: {
      fontFamily: FontFamily,
      fontSize: 14,
      fontWeight: "300",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: 0.05,
      color: ProgramInfo.statusDraftColor,
      textTransform: "capitalize",
      paddingLeft: 20,
      paddingRight: 20,
    },
    roundMoreIcon: {
      cursor: "pointer",
      paddingLeft: 20,
      "&::before": {
        color: ProgramInfo.iconColor,
        fontSize: "5px !important",
      },
      "&:hover::before": {
        color: ProgramInfo.iconHoverColor,
      },
    },
    privateIcon: {
      color: ProgramInfo.iconHoverColor,
    },
    lessonAuthor: {
      color: ProgramInfo.lessonAuthorColor,
      margin: "5px 0 0 35px",
      fontSize: 15,
    },
    orderArrow: {
      backgroundColor: `${ProgramInfo.orderArrowBackgroundColor}!important`,
      "&:before": {
        color: ProgramInfo.orderArrowColor,
      },
    },
    lessonContainer: {
      backgroundColor: "white",
      padding: "20px 60px",
      borderRadius: "3px",
      marginTop: "20px",
      boxShadow: "2px 1px 12px #dfdfdf",
    },
    iconContainer: {
      display: "flex",
      margin: "4px",
    },
    inputStyle: {
      display: "flex",
      flexDirection: "row",
    },
  };
};
