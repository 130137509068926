import { defineMessages } from "react-intl";

export const translations = defineMessages({
  QuestionToGeniusLabel: {
    id: "KPI.Ask.QuestionToGeniusLabel",
    defaultMessage: "Questions asked to Genius",
  },
  QuestionToExpertsLabel: {
    id: "KPI.Ask.QuestionToExpertsLabel",
    defaultMessage: "Questions Asked to Experts",
  },
  AnsweredByExpertsLabel: {
    id: "KPI.Ask.AnsweredByExpertsLabel",
    defaultMessage: "Answered Questions by Experts",
  },
  PendingToExpertsLabel: {
    id: "KPI.Ask.PendingToExpertsLabel",
    defaultMessage: "Pending Questions to Experts",
  },
  TagsInQuestionsToGeniusLabel: {
    id: "KPI.Ask.TagsInQuestionsToGeniusLabel",
    defaultMessage: "Top TAGs used in Questions to Genius",
  },
  TagsInQuestionsToExpertsLabel: {
    id: "KPI.Ask.TagsInQuestionsToExpertsLabel",
    defaultMessage: "Top TAGs used in Questions to Experts",
  },
});
