import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icons, Icon } from "genius-ui";
import ImageCropper from "../../common/ImageCropper";
import { CropImageStyle } from "./CropImage.style";
import { FormattedMessage } from "react-intl";
import { CustomCropperMaskFullScreen } from "assets/icons";
import { CROP_VARIATION } from "../../../configs/constants";
import { translations } from "./CropImage.translations";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import { createUseStyles } from "react-jss";

const useStyle = createUseStyles(CropImageStyle);

const CropImage = (props) => {
  const { className, disabled, guides, classes: classesProp, children } = props;
  let customCropperViewBoxBG;
  if (
    props.cropSize === CROP_VARIATION.IMAGE_FULL_SCREEN ||
    props.cropSize === CROP_VARIATION.BACKGROUND
  ) {
    customCropperViewBoxBG = CustomCropperMaskFullScreen;
  }
  const ownClasses = useThemedStyle(useStyle, props);
  const classes = {
    ...ownClasses,
    ...(classesProp || {}),
  };

  return (
    <div
      className={classNames([
        ...(children ? [] : [classes.uploadBtnWrapper]),
        className,
      ])}
    >
      {children ? (
        <div onClick={props.onCropClick}>{children}</div>
      ) : (
        <button
          className={classes.btnUpload}
          onClick={props.onCropClick}
          disabled={disabled}
        >
          <Icon iconName={Icons.crop} style={classes.iconAction} />
          <span>
            <FormattedMessage {...translations.CropImageText} />
          </span>
        </button>
      )}
      <ImageCropper
        sourceImage={props.sourceImage}
        id={props.id}
        cropSize={props.cropSize}
        onCancel={props.onCancel}
        onCropImage={props.onCropCompleted}
        isCropVisible={props.isCropVisible}
        customCropperViewBoxBG={customCropperViewBoxBG}
        guides={guides}
        onHideCropper={props.onCancel}
      />
    </div>
  );
};

CropImage.propTypes = {
  /** the  unique id of the image selector control */
  id: PropTypes.string,
  /** The current selected image */
  sourceImage: PropTypes.string,
  /** On crop button click function */
  onCropClick: PropTypes.func,
  /** flag to indicate if crop is visible **/
  isCropVisible: PropTypes.bool,
  /** on crop completed event to handle output */
  onCropCompleted: PropTypes.func,
  /** on cancel event */
  onCancel: PropTypes.func,
  /** Custom className for the container **/
  className: PropTypes.string,
  /** Whether the button is disabled or not **/
  disabled: PropTypes.bool,
  /** pass a custom background image for the cropper view box **/
  customCropperViewBoxBG: PropTypes.string,
  /** show dashed lines in cropper viewBox **/
  guides: PropTypes.bool,
};

export default CropImage;
