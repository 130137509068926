import { defineMessages } from "react-intl";

export const translations = defineMessages({
  NewInsightTitle: {
    id: "Navigation.NewInsightTitle",
    defaultMessage: "NEW DRAFT INSIGHT",
  },
  NewDegreeTitle: {
    id: "Navigation.NewDegreeTitle",
    defaultMessage: "NEW DRAFT DEGREE",
  },
  ContentManagerHeader: {
    id: "Navigation.ContentManagerHeader",
    defaultMessage: "Content Builder",
  },
  AllContentsMenuText: {
    id: "Navigation.AllContentsMenuText",
    defaultMessage: "All Contents",
  },
  ContentPlannerMenuText: {
    id: "Navigation.ContentPlannerMenuText",
    defaultMessage: "Content Planner",
  },
  NewContentMenuText: {
    id: "Navigation.NewContentMenuText",
    defaultMessage: "New Content",
  },
  DeletedContentsMenuText: {
    id: "Navigation.DeletedContentsMenuText",
    defaultMessage: "Deleted Contents",
  },
  LearningBuilderHeader: {
    id: "Navigation.LearningBuilderHeader",
    defaultMessage: "Learning Builder",
  },
  AllDegreeMenuText: {
    id: "Navigation.AllDegreeMenuText",
    defaultMessage: "All Degree",
  },
  NewDegreeMenuText: {
    id: "Navigation.NewDegreeMenuText",
    defaultMessage: "New Degree",
  },
  DeletedCoursesMenuText: {
    id: "Navigation.DeletedCoursesMenuText",
    defaultMessage: "Deleted Courses",
  },
  UsersHeader: {
    id: "Navigation.UsersHeader",
    defaultMessage: "Users",
  },
  UsersMenuText: {
    id: "Navigation.UsersMenuText",
    defaultMessage: "Users",
  },
  KPIHeader: {
    id: "Navigation.KPIHeader",
    defaultMessage: "KPI",
  },
  GeneralMenuText: {
    id: "Navigation.GeneralMenuText",
    defaultMessage: "General",
  },
  ApplicationMenuText: {
    id: "Navigation.ApplicationMenuText",
    defaultMessage: "Application",
  },
  LearningMenuText: {
    id: "Navigation.LearningMenuText",
    defaultMessage: "Learning",
  },
  FeedMenuText: {
    id: "Navigation.FeedMenuText",
    defaultMessage: "Feed",
  },
  AskMenuText: {
    id: "Navigation.AskMenuText",
    defaultMessage: "Ask",
  },
  SessionAnalyticsText: {
    id: "Navigation.SessionAnalyticsText",
    defaultMessage: "Session Analytics",
  },
  MasterTemplatesMenuText: {
    id: "Navigation.MasterTemplatesMenuText",
    defaultMessage: "Master",
  },
  BattleMenuText: {
    id: "Navigation.BattleMenuText",
    defaultMessage: "Challenge",
  },
  NinjaGameText: {
    id: "Navigation.NinjaGameText",
    defaultMessage: "The Right Match",
  },
  KpiDocumentMenuText: {
    id: "Navigation.KpiDocumentMenuText",
    defaultMessage: "Document",
  },
  KpiProductMenuText: {
    id: "Navigation.KpiProductMenuText",
    defaultMessage: "Product",
  },
  PinnedMenuText: {
    id: "Navigation.PinnedMenuText",
    defaultMessage: "Pinned",
  },
  MasterBadges: {
    id: "Navigation.MasterBadges",
    defaultMessage: "Master",
  },
  Badges: {
    id: "Navigation.Badges",
    defaultMessage: "All Badges",
  },
  BadgesHeader: {
    id: "Navigation.BadgesHeader",
    defaultMessage: "Badges",
  },
});
