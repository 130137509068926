import gql from "graphql-tag";

export const GET_MARKET_BRAND_BADGES = gql`
  query getMarketBrandBadges($tagIds: [ID!]) {
    admin {
      badges(tagIds: $tagIds) {
        badgeId
        title
        pic
      }
    }
  }
`;

export const GET_LESSON_BADGES = gql`
  query getLessonBadges($lessonIds: [Int!]) {
    admin {
      lessons(lessonIds: $lessonIds) {
        lessonId
        badges {
          audit
          badgeId
          title
          pic
          documents {
            docId
          }
        }
      }
    }
  }
`;
