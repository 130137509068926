import { RESET_STORE } from "../../constants/store/auth";
import {
  SET_EDITED_POLL_PAGE_ID,
  SET_POLL_MASTER_FLAG,
  SET_POLL_QUESTIONS,
} from "../../constants/store/editPoll";

const initialState = {
  editedPageId: null,
  isMasterDocument: false,
  questions: [
    {
      id: "1",
      type: "long",
      title: "",
      image: null,
      description: "",
      choices: [],
    },
    {
      id: "2",
      type: "simple",
      title: "Title 2",
      image: null,
      description: "description 2",
      choices: [
        {
          id: "3",
          text: "Answer 1",
        },
        {
          id: "4",
          text: "Answer 2",
        },
      ],
    },
    {
      id: "3",
      type: "picture",
      title: "Title 3",
      image:
        "https://www.beach-backgrounds.com/wallpapers/endless-beach-shore-background-360x640-200.jpg",
      description: "description 3",
      choices: [
        {
          id: "5",
          text: "Answer 1",
        },
        {
          id: "6",
          text: "Answer 2",
        },
      ],
    },
  ],
};

export default (state = initialState, action = {}) => {
  const { data, type } = action;

  switch (type) {
    case SET_EDITED_POLL_PAGE_ID: {
      return {
        ...state,
        editedPageId: data,
      };
    }
    case SET_POLL_QUESTIONS: {
      return {
        ...state,
        questions: data,
      };
    }
    case SET_POLL_MASTER_FLAG: {
      return {
        ...state,
        isMasterDocument: data,
      };
    }
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
