import React, { useEffect } from "react";
import DefaultCardEditor from "./CreateCard";
import CardEditorReadOnly from "./readOnly";
import clientConfig from "../../configs/client";
import { USER_ROLE_NAMES } from "../../configs/constants";
import { connect } from "react-redux";
import { FullscreenLoader } from "genius-ui";
import { GetCardById, ResetCardsStore } from "../../store/card/actions";

const EditBattle = (props) => {
  const {
    match: {
      params: { cardId, lang },
    },
    userRoles,
    isMasterDocument,
    GetCurrentCardData,
    ResetStore,
  } = props;

  useEffect(() => {
    GetCurrentCardData(cardId, lang);
  }, [cardId, lang]);

  // reset store data when leaving the page
  useEffect(() => ResetStore, []);

  if (!userRoles || !userRoles.length) {
    return <FullscreenLoader />;
  }

  if (
    clientConfig.IS_ROLE_READ_ONLY_MODE_ACTIVE &&
    userRoles.includes(USER_ROLE_NAMES.TRAINER) &&
    isMasterDocument
  ) {
    return <CardEditorReadOnly {...props} />;
  }

  return <DefaultCardEditor {...props} />;
};

const mapStateToProps = (state) => ({
  userRoles: state.auth.profileInfo.roles,
  isMasterDocument: state.cards.isMasterDocument,
});

const mapDispatchToProps = (dispatch) => ({
  GetCurrentCardData: (cardId, lang) => {
    dispatch(GetCardById(cardId, lang));
  },
  ResetStore: () => {
    dispatch(ResetCardsStore());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBattle);
