import gql from "graphql-tag";

export const LESSONS_SET_BADGES_AS_IN_MASTER = gql`
  mutation setBadgesAsInMaster($lessonsId: [Int!]) {
    admin {
      lessons(lessonIds: $lessonsId) {
        setBadgesAsInMaster
      }
    }
  }
`;
