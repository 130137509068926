import React from "react";
import PropTypes from "prop-types";
import { Icon, Icons } from "genius-ui";
import { SearchButtonStyle } from "./SearchButton.style";
import withThemedStyle from "../../hoc/withThemedStyle";
import classNames from "classnames";

const SearchButton = ({ classes, onClick, className }) => {
  return (
    <div
      className={classNames([classes.searchButton, className])}
      onClick={onClick}
    >
      <Icon iconName={Icons.search} />
    </div>
  );
};

SearchButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default withThemedStyle(SearchButtonStyle)(SearchButton);
