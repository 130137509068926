import gql from "graphql-tag";

export const UPDATE_LOCATIONS = gql`
  mutation updateLocations(
    $lessonId: ID
    $lang: LangEnum
    $sens: ObjectActionEnumID!
    $locationIds: [ID!]!
  ) {
    admin {
      lesson(lessonId: $lessonId, lang: $lang) {
        objectsCore(
          sens: $sens
          ocIds: $locationIds
          skipRefreshCache: true
          clearCache: true
        ) {
          lessonId
        }
      }
    }
  }
`;
