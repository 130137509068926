import React from "react";
import PropTypes from "prop-types";
import { defaultStyles } from "./ResultDetails.style";
import withThemedStyle from "../../../components/hoc/withThemedStyle";
import Image from "../../../components/common/Image";
import { FormattedMessage } from "react-intl";
import { translations } from "./ResultDetails.translations";
import { Tag } from "genius-ui";
import { upperFirst } from "lodash";
import { DOC_WORKFLOW_ENUM } from "../../../configs/constants";

const ResultDetailsCard = (props) => {
  const { classes, photoUrl, name, status, creationDate, markets, brands } =
    props;

  return (
    <div className={classes.root}>
      <Image url={photoUrl} height={300} width={450} borderRadius={0} />
      <div className={classes.content}>
        <span className="name">{name}</span>
        <span className="status">{upperFirst(status.toLowerCase())}</span>
        <div className="creationDate">
          {status === DOC_WORKFLOW_ENUM.PUBLISHED ? (
            <FormattedMessage
              {...translations.CreationDateLabel}
              values={{ date: creationDate }}
            />
          ) : (
            <FormattedMessage {...translations.NotPublishedText} />
          )}
        </div>
        {/* <div className='publicationDate'>
          <FormattedMessage {...translations.PublicationDateLabel} values={{ date: publicationDate }} />
        </div> */}
        <div className="markets">
          {markets.map((market) => (
            <Tag key={market.key} label={market.label} readOnly />
          ))}
        </div>
        <div className="brands">
          {brands.map((brand) => (
            <Tag key={brand.key} label={brand.label} readOnly />
          ))}
        </div>
      </div>
    </div>
  );
};

ResultDetailsCard.propTypes = {
  photoUrl: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  creationDate: PropTypes.string,
  publicationDate: PropTypes.string,
  markets: PropTypes.array,
  brands: PropTypes.array,
};

export default withThemedStyle(defaultStyles)(ResultDetailsCard);
