import React from "react";
import PropTypes from "prop-types";
import style from "./DownloadButton.style";
import { Icon, Icons } from "genius-ui";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import Spinner from "components/common/Spinner";

const useStyle = createUseStyles(style);

const DownloadButton = (props) => {
  const { onClickHandler, isLoading } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div
      className={classes.container}
      onClick={!isLoading ? onClickHandler : undefined}
    >
      {isLoading ? (
        <Spinner size={20} />
      ) : (
        <Icon iconName={Icons.exportExcel} />
      )}
    </div>
  );
};

DownloadButton.propTypes = {
  onClickHandler: PropTypes.func,
};

export default DownloadButton;
