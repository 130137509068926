export default (theme) => {
  return {
    main: {
      width: 520,
      height: 220,
      backgroundColor: "white",
      marginTop: 20,
    },
    importDocument: {
      cursor: "pointer",
      color: "rgb(70, 142, 227)",
      textDecoration: "underline",
    },
    textCenter: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
  };
};
