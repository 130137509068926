import React from "react";
import { CreateInsightStyle } from "./CreateInsight.style";
import SideComponentBar from "../../components/SideComponentBar";
import PublishPreviewBanner from "../../components/PublishPreviewBanner";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";
import {
  INSIGHT_CREATE_PAGE,
  INSIGHT_DELETE_PAGE,
  INSIGHT_MOVE_PAGE_ORDER_DOWN,
  INSIGHT_MOVE_PAGE_ORDER_UP,
  INSIGHT_UPDATE_PAGE_DATA,
} from "../../graphql/remote/insights/mutations";
import { get } from "lodash";
import EditPages from "../../components/EditPages";
import { PAGE_TYPES, CONTROL_TYPES } from "../../configs/constants";
import { animateScroll } from "react-scroll";
import { GetDesignPageEncodeData } from "../../utils/ui-generator/generator";
import { FormattedMessage } from "react-intl";
import { getPath } from "../../core/paths";
import { DOCUMENT_LIST_WORKFLOWS } from "../../store/document/reducers";
import { translations } from "./CreateInsight.translations";
import withThemedStyle from "../../components/hoc/withThemedStyle";

const updateInsightPageData = graphql(INSIGHT_UPDATE_PAGE_DATA, {
  props: ({
    mutate,
    ownProps: { UpdateInsightPageData, SetWarningMessage },
  }) => ({
    insightUpdatePageData: (insightId, lang, data, pageId) => {
      UpdateInsightPageData(insightId, lang, data, pageId);
      return mutate({
        variables: {
          insightId: insightId,
          lang: lang,
          data: data,
          pageId: pageId,
        },
        update: (cache, result) => {
          // on success update page data in store
          if (get(result, "data.admin.insight.pages.data")) {
            return;
          }
          // on fail show a warning message
          SetWarningMessage(
            <FormattedMessage {...translations.RefreshUIWarningMessage} />,
          );
        },
      });
    },
  }),
});

const createInsightPageRequest = graphql(INSIGHT_CREATE_PAGE, {
  props: ({
    mutate,
    ownProps: { AddCurrentInsightPage, SetWarningMessage },
  }) => ({
    insightCreatePage: (insightId, lang, data, pageType) =>
      mutate({
        variables: {
          insightId: insightId,
          lang: lang,
          type: pageType,
          data: data,
        },
        update: (cache, result) => {
          // on success update insight data
          const newPageData = get(result, "data.admin.insight.pages.create");
          if (newPageData) {
            animateScroll.scrollToBottom();
            AddCurrentInsightPage(newPageData);
          } else {
            // on fail show a warning message
            SetWarningMessage(
              <FormattedMessage {...translations.RefreshUIWarningMessage} />,
            );
          }
        },
      }),
  }),
});

const removeInsightPage = graphql(INSIGHT_DELETE_PAGE, {
  props: ({
    mutate,
    ownProps: { RemoveCurrentInsightPage, SetWarningMessage },
  }) => ({
    removePage: (insightId, lang, pageId) =>
      mutate({
        variables: { insightId: insightId, lang: lang, pageId: pageId },
        update: (cache, result) => {
          // on success update insight data
          if (get(result, "data.admin.insight.pages.delete")) {
            RemoveCurrentInsightPage(pageId);
          } else {
            // on fail show a warning message
            SetWarningMessage(
              <FormattedMessage {...translations.RefreshUIWarningMessage} />,
            );
          }
        },
      }),
  }),
});

const orderDown = graphql(INSIGHT_MOVE_PAGE_ORDER_DOWN, {
  props: ({ mutate, ownProps: { GetInsightData } }) => ({
    orderDown: (insightId, lang, pageId) =>
      mutate({
        variables: { insightId: insightId, lang: lang, pageId: pageId },
        update: (cache, result) => GetInsightData(insightId, lang),
      }),
  }),
});

const orderUp = graphql(INSIGHT_MOVE_PAGE_ORDER_UP, {
  props: ({ mutate, ownProps: { GetInsightData } }) => ({
    orderUp: (insightId, lang, pageId) =>
      mutate({
        variables: { insightId: insightId, lang: lang, pageId: pageId },
        update: (cache, result) => GetInsightData(insightId, lang),
      }),
  }),
});

class CreateInsight extends React.Component {
  createArticle = (controlType, controlVariation = "") => {
    const template = GetDesignPageEncodeData(
      [
        {
          ControlType: controlType,
          ControlValue: "",
          ControlVariation: controlVariation,
          Index: 0,
        },
      ],
      PAGE_TYPES.ARTICLE,
    );

    this.props.insightCreatePage(
      this.props.match.params.insightId,
      this.props.match.params.lang,
      template,
      PAGE_TYPES.ARTICLE,
    );
  };

  render() {
    return (
      <div>
        <SideComponentBar
          handleBackClick={() =>
            this.props.history.push(
              this.props.documentListWorkflow ===
                DOCUMENT_LIST_WORKFLOWS.DELETED
                ? getPath("deletedContent")
                : getPath("contentManager"),
            )
          }
          handleTextClick={(controlType) => this.createArticle(controlType)}
          handleImageClick={(controlVariation) =>
            this.createArticle(CONTROL_TYPES.IMAGE, controlVariation)
          }
          handleVideoClick={(controlVariation) =>
            this.createArticle(CONTROL_TYPES.VIDEO, controlVariation)
          }
          handleSliderClick={(template) =>
            this.props.insightCreatePage(
              this.props.match.params.insightId,
              this.props.match.params.lang,
              template,
              PAGE_TYPES.SLIDERFULLSCREEN,
            )
          }
          handleScrollImagesClick={(template) =>
            this.props.insightCreatePage(
              this.props.match.params.insightId,
              this.props.match.params.lang,
              template,
              PAGE_TYPES.FULLSCREENSCROLL,
            )
          }
          handleActivityClick={(template, pageType) =>
            this.props.insightCreatePage(
              this.props.match.params.insightId,
              this.props.match.params.lang,
              template,
              pageType,
            )
          }
          handlePeanutClick={() => null}
          {...this.props}
        />
        <PublishPreviewBanner
          onPreviewButtonClick={() => {}}
          onPublishButtonClick={() =>
            this.props.NavigateToPublishPage(
              this.props.match.params.insightId,
              this.props.match.params.lang,
              this.props.documentLevel,
            )
          }
        />
        <EditPages
          insightId={this.props.match.params.insightId}
          language={this.props.match.params.lang}
          activePageId={this.props.currentPageId}
          classes={this.props.classes}
          onDataChanged={this.props.insightUpdatePageData}
          onNewPageCreated={this.props.insightCreatePage}
          onRemovePage={this.props.removePage}
          onLoad={this.props.UpdateInsightPublicaitonInfo}
          onPageChange={this.props.UpdateCurrentPageId}
          onOrderDown={this.props.orderDown}
          onOrderUp={this.props.orderUp}
          currentOpenedMarker={this.props.currentOpenedMarker}
          UpdateOpenedMarker={this.props.UpdateOpenedMarker}
        />
      </div>
    );
  }
}

export default compose(
  updateInsightPageData,
  createInsightPageRequest,
  removeInsightPage,
  orderDown,
  orderUp,
)(withThemedStyle(CreateInsightStyle)(CreateInsight));
