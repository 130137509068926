import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Icons } from "genius-ui";
import { AddToMarketHeaderStyle } from "./AddToMarketHeader.style";
import withThemedStyle from "../../../components/hoc/withThemedStyle";

const AddToMarketHeader = ({
  classes,
  goBack,
  submitBtnEventHandler,
  submitBtnDisabled,
  title,
  submitBtnText,
  cancelBtnText,
  cancelBtnEventHandler,
}) => {
  return (
    <div className={classes.header}>
      <div className="left">
        <Icon
          iconName={Icons.arrowLeft}
          style="backIcon"
          handleClick={goBack}
        />
        {title}
      </div>
      <div className="right">
        <Button
          variation="secondary"
          size="big"
          handleClick={cancelBtnEventHandler}
        >
          {cancelBtnText}
        </Button>
        <Button
          variation="primary"
          size="big"
          handleClick={submitBtnEventHandler}
          disabled={submitBtnDisabled}
        >
          {submitBtnText}
        </Button>
      </div>
    </div>
  );
};

AddToMarketHeader.propTypes = {
  goBack: PropTypes.func.isRequired,
  submitBtnEventHandler: PropTypes.func.isRequired,
  submitBtnDisabled: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  cancelBtnText: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  submitBtnText: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  cancelBtnEventHandler: PropTypes.func.isRequired,
};

AddToMarketHeader.defaultProps = {
  submitBtnDisabled: false,
};

export default withThemedStyle(AddToMarketHeaderStyle)(AddToMarketHeader);
