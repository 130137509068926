import gql from "graphql-tag";

export const GET_APPLICATION_DATA = gql`
  query kpiGetApplicationData(
    $fromDate: String
    $toDate: String
    $tags: [kpiTagsType!]
  ) {
    applicationKPI(fromDate: $fromDate, toDate: $toDate, tags: $tags) {
      activeUsers
      connections
      dailyUsage
      totalUsers
      previousPeriod {
        activeUsers
        connections
        dailyUsage
      }
      graphConnections {
        data
        lastAccess
      }
    }
  }
`;

export const GET_APP_ANALYTICS_METRICS = gql`
  query getAppAnalyticsMetrics(
    $startDate: String!
    $endDate: String!
    $tagIds: [[Int!]] = []
    $devices: [String!] = []
    $os: [String!] = []
    $osversions: [String!] = []
    $builds: [String!] = []
    $durationIntervals: [IntervalParam!]!
  ) {
    admin {
      sessions {
        metrics(
          filter: {
            startDate: $startDate
            endDate: $endDate
            tagIds: $tagIds
            devices: $devices
            os: $os
            osversions: $osversions
            builds: $builds
          }
        ) {
          totalNbSessions
          totalNbUsers
          sessionsPerDay {
            date
            sessionNb
          }
          avgSessionTimePerDay {
            name
            value
            fValue
          }
          avgNbSessionsPerUserPerDay {
            name
            value
            fValue
          }
          nbSessionsPerDurationInterval(durationIntervals: $durationIntervals) {
            start
            end
            value
            fValue
          }
        }
        previousMetrics: metrics(
          filter: {
            startDate: $startDate
            endDate: $endDate
            devices: $devices
            os: $os
            osversions: $osversions
            builds: $builds
          }
        ) {
          totalNbSessions
          totalNbUsers
          avgSessionTimePerDay {
            name
            value
            fValue
          }
          avgNbSessionsPerUserPerDay {
            name
            value
            fValue
          }
        }
      }
    }
  }
`;

export const GET_EXTRA_APP_ANALYTICS_METRICS = gql`
  query getExtraAppAnalyticsMetrics(
    $startDate: String!
    $endDate: String!
    $minimalSessionsNumber: Int
    $minimalSessionsTotalTime: Int
  ) {
    admin {
      sessions {
        metrics(filter: { startDate: $startDate, endDate: $endDate }) {
          nbUsersPerDevice {
            name
            value
            fValue
          }
          nbUsersPerOS {
            name
            value
            fValue
          }
          nbUsersPerOSAndBrowserTMP {
            name
            value
          }
          nbUsersPerOSVersion {
            name
            value
            fValue
          }
          nbUsersPerBuild {
            name
            value
            fValue
          }
          activeNbUsers(
            minimalSessionsNumber: $minimalSessionsNumber
            minimalSessionsTotalTime: $minimalSessionsTotalTime
          )
        }
      }
    }
  }
`;

export const GET_SESSION_EXTRACT = gql`
  query getSessionExtract($startDate: String!, $endDate: String!) {
    admin {
      sessionsExtractTmp(startDate: $startDate, endDate: $endDate) {
        id
        userId
        userEmail
        device
        os
        osVersion
        buildId
        startDate
        endDate
        deviceNature
      }
    }
  }
`;

export const GET_USERS = gql`
  query getUsersNb($fromDate: String, $toDate: String, $tagIdsList: [[Int!]!]) {
    admin {
      dashboard {
        usersNb(
          tagIdsList: $tagIdsList
          creationDate: { from: $fromDate, to: $toDate }
        )
        prevUsersNb: usersNb(creationDate: { from: $fromDate, to: $toDate })
      }
    }
  }
`;
