import gql from "graphql-tag";

export const GET_USERS = gql`
  query getUsers(
    $search: String! = ""
    $tagIds: [ID!]
    $tagMultipleIntersectIds: [QueryInputMultipleTags!]
    $limit: Int
    $skip: Int
    $orderBy: UserSortingFieldsEnum
    $sanitizer: SanitizerEnum
    $skipCache: Boolean
  ) {
    admin {
      countUsers: dashboard {
        users {
          nb
          status
        }
      }

      users: users(
        search: $search
        tagIds: $tagIds
        tagMultipleIntersectIds: $tagMultipleIntersectIds
        limit: $limit
        skip: $skip
        orderBy: $orderBy
        sanitizer: $sanitizer
        skipCache: $skipCache
      ) {
        creationDate
        userId
        status
        lastLogin
        lessonPts
        tags {
          clusterDeepness
          tagId
          title
          clusterId
        }
        profile {
          mail
          firstName
          lastName
          lang
          city
          posAddress
          inviteCode
          pic
        }
        profileCustom {
          name
          description
          isExportable
          tags {
            tagId
            title
            description
            clusterId
          }
          badges {
            updateDate
            isUnlocked
            badgeId
            title
            description
            degreeRef {
              title
            }
            score
          }
        }
      }
    }
  }
`;
