import { FontFamily } from "../../../../../../constants";

export default {
  container: {
    width: 950,
    backgroundColor: "#fff",
    "& table": {
      width: "100%",
      "& thead tr th": {
        padding: 10,
        borderBottom: "1px solid #f0f0f0",
        "& span": {
          float: "left",
          fontFamily: FontFamily,
          fontSize: 14,
          fontWeight: 600,
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "normal",
          letterSpacing: 0.09,
          color: "#252525",
        },
      },
      "& tbody tr td": {
        padding: 10,
      },
    },
  },
  docSettingsMenu: {
    width: 200,
    left: 15,
    position: "absolute",
    top: 10,
    zIndex: 2,
  },
  loadMoreContainer: {
    margin: "40px 0",
    float: "left",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  settingsButton: {
    cursor: "pointer",
    "&::before": {
      color: "#b2b3b3",
      fontSize: 4,
    },
    "&:hover::before": {
      color: "#468ee3",
      fontSize: 4,
    },
  },
  settingsContainer: {
    position: "relative",
  },
  title: {
    fontFamily: FontFamily,
    fontSize: 16,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 0.1,
    color: "#252525",
    cursor: "pointer",
    maxWidth: 260,
    "& > div": {
      display: "flex",
      alignItems: "center",
      wordBreak: "break-word",
      "& > div": {
        float: "left",
      },
    },
  },
  type: {
    fontFamily: FontFamily,
    fontSize: 14,
    fontWeight: "bold",
    fontStretch: "condensed",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 0.08,
    color: "#a0a0a0",
    textTransform: "uppercase",
  },
  date: {
    fontFamily: FontFamily,
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "condensed",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 0.08,
    color: "#a0a0a0",
  },
  status: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "condensed",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 0.08,
    color: "#468ee3",
  },
  statistic: {
    fontFamily: FontFamily,
    fontSize: 10,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#a0a0a0",
  },
  image: {
    marginRight: 15,
  },
  kpiIcon: {
    "&:before": {
      fontSize: 30,
      paddingRight: 10,
      width: 30,
      marginLeft: -10,
      color: "rgb(146, 146, 146)",
    },
  },
};
