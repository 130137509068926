import React, { useCallback, useMemo } from "react";
import PageContainer from "components/PageContainer/PageContainer";
import { Icon, Icons } from "genius-ui";
import RoundButton from "components/common/RoundButton";
import FixedContainer from "components/common/FixedContainer";
import { useHistory } from "react-router-dom";
import { getPath } from "core/paths";
import FilterHeader from "../partials/FilterHeader/FilterHeader";
import FilterHeaderContainer from "components/FilterHeaderContainer/FilterHeaderContainer";
import MasterBadges from "../partials/MasterBadges/MasterBadges";

const MasterBadgesList = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const history = useHistory();
  const { brandId } = useMemo(() => {
    const brandId = queryParams.get("brandId");

    return { brandId };
  }, [queryParams]);

  const handleParamsChange = useCallback(
    (type, value) => {
      queryParams.delete(type);
      if (value) queryParams.append(type, value);
      history.push({ search: queryParams.toString() });
    },
    [queryParams, history],
  );

  return (
    <PageContainer>
      <FilterHeaderContainer>
        <FilterHeader
          handleParamsChange={handleParamsChange}
          brandId={brandId}
          brand
        />
      </FilterHeaderContainer>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "30px 0" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: 940,
          }}
        >
          <MasterBadges brandId={brandId} />
        </div>
      </div>
      <FixedContainer
        style={{
          bottom: 35,
          right: 35,
          zIndex: 1,
        }}
      >
        <RoundButton onClick={() => history.push(getPath("createMasterBadge"))}>
          <Icon iconName={Icons.plus} />
        </RoundButton>
      </FixedContainer>
    </PageContainer>
  );
};

export default MasterBadgesList;
