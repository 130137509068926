export const PeanutTemplate = {
  template: "PeanutTemplate",
  data: {
    cards: [],
  },
};

export const PeanutCardTemplate = {
  header: {
    type: "image",
    url: null,
    copyright: null,
    legend: null,
  },
  title: null,
  description: null,
};
