import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, ButtonVariation, ButtonSize } from "genius-ui";
import style from "./DeleteModal.style";
import { FormattedMessage } from "react-intl";
import { translations } from "./DeleteModal.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const DeleteModal = (props) => {
  const { showModal, setShowModal, actionHandler, deleteMessage } = props;
  const hideModal = () => setShowModal(false);
  const classes = useThemedStyle(useStyle, props);

  return (
    <Modal
      className={classes.modal}
      show={showModal}
      onClose={hideModal}
      content={<span className={classes.content}>{deleteMessage}</span>}
      action={
        <div className={classes.buttonContainer}>
          <Button
            variation={ButtonVariation.primary}
            size={ButtonSize.big}
            handleClick={actionHandler}
          >
            <FormattedMessage {...translations.DeleteYesOptionText} />
          </Button>
          <Button
            variation={ButtonVariation.primary}
            size={ButtonSize.big}
            handleClick={hideModal}
          >
            <FormattedMessage {...translations.DeleteNoOptionText} />
          </Button>
        </div>
      }
    />
  );
};

DeleteModal.propTypes = {
  /** Show modal flag variable */
  showModal: PropTypes.bool.isRequired,
  /** Show/hide modal handler function */
  setShowModal: PropTypes.func.isRequired,
  /** Action handler function */
  actionHandler: PropTypes.func.isRequired,
  /** Delete confirmation message */
  deleteMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default DeleteModal;
