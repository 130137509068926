import gql from "graphql-tag";
import { UserFragment } from "../fragments/users";

export const GET_USERS_PROFILES_DATA = gql`
  query getUserProfileData($search: String!, $userIds: [ID!]) {
    admin {
      users(search: $search, userIds: $userIds) {
        ...UserFields
      }
    }
  }
  ${UserFragment}
`;
