import { defineMessages } from "react-intl";

export const translations = defineMessages({
  ProgramNamePlaceholderText: {
    id: "ProgramCreateInfo.ProgramNamePlaceholderText",
    defaultMessage: "Type your program name",
  },
  ProgramDescriptionPlaceholderText: {
    id: "ProgramCreateInfo.ProgramDescriptionPlaceholderText",
    defaultMessage: "Type your program description",
  },
});
