import React from "react";
import PageEditor from "../../../components/PageEditor/pageEditorReadOnly";
import { PAGE_TEMPLATES } from "../../../configs/constants";
import { connect } from "react-redux";
import HorizontalSliderPageEditor from "../../../components/SliderPageEditor/Horizontal/horizontalReadOnly";
import VerticalSliderPageEditor from "../../../components/SliderPageEditor/Vertical/verticalReadOnly";
import ContentNotHandled from "../../../components/common/ContentNotHandled";
import SimpleQuizPageEditor from "../../../components/SimpleQuizPageEditor/simpleQuizPageEditorReadOnly";
import MadlibPageEditor from "../../../components/MadlibPageEditor/madlibReadOnly";
import DragAndDropActivityPageEditor from "../../DragAndDropActivityPageEditor/dragAndDropPageEditorReadOnly";

const EditPages = ({ pages, ...props }) => (
  <div className={props.classes.rootContainer}>
    {pages.map((item) => {
      switch (item.template) {
        case PAGE_TEMPLATES.SLIDERFULLSCREEN_TEMPLATE:
          return (
            <div className={props.classes.pageItemBox} key={item.pageId}>
              <HorizontalSliderPageEditor
                pageId={item.pageId}
                data={item.data}
                currentOpenedMarker={props.currentOpenedMarker}
                isActivePage={false}
              />
            </div>
          );
        case PAGE_TEMPLATES.FULLSCREENSCROLL_TEMPLATE:
          return (
            <div className={props.classes.pageItemBox} key={item.pageId}>
              <VerticalSliderPageEditor
                pageId={item.pageId}
                data={item.data}
                currentOpenedMarker={props.currentOpenedMarker}
                isActivePage={false}
              />
            </div>
          );
        case PAGE_TEMPLATES.ARTICLE_TEMPLATE:
          return (
            <div className={props.classes.pageItemBox} key={item.pageId}>
              <PageEditor
                pageId={item.pageId}
                data={item.data}
                onPageChange={() => null}
              />
            </div>
          );
        case PAGE_TEMPLATES.SIMPLE_QUIZ_TEMPLATE:
          return (
            <div className={props.classes.pageItemBox} key={item.pageId}>
              <SimpleQuizPageEditor pageId={item.pageId} data={item.data} />
            </div>
          );
        case PAGE_TEMPLATES.MADLIB_TEMPLATE:
          return (
            <div className={props.classes.pageItemBox} key={item.pageId}>
              <MadlibPageEditor pageId={item.pageId} data={item.data} />
            </div>
          );
        case PAGE_TEMPLATES.DRAG_AND_DROP_TEMPLATE:
          return (
            <div className={props.classes.pageItemBox} key={item.pageId}>
              <DragAndDropActivityPageEditor
                pageId={item.pageId}
                data={item.data}
              />
            </div>
          );
        default:
          return (
            <div className={props.classes.pageItemBox} key={item.pageId}>
              <ContentNotHandled />
            </div>
          );
      }
    })}
  </div>
);

const mapStateToProp = (state) => ({
  pages: state.insights.currentInsightPages,
});

export default connect(mapStateToProp)(EditPages);
