import React, { useEffect, useState } from "react";
import PublishFile from "./partials/PublishFile";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { tagIdTitleToDropDown } from "utils/transformers/tags";
import { getFormattedSopiDate } from "utils/dateUtils";
import { PlaceHolderDegree } from "assets/icons";
import { getPath } from "core/paths";
import { useHistory } from "react-router-dom";

export const GET_TAG = gql`
  query queryTagById($tagId: ID) {
    tags(tagId: $tagId) {
      pic
      tagId
      title
      clusterName
      clusterId
    }
  }
`;

const CreateFile = (props) => {
  const params = new URLSearchParams(props.location.search);
  const marketId = params.get("marketId");
  const history = useHistory();
  const lang = params.get("lang");
  const { data } = useQuery(GET_TAG, { variables: { tagId: marketId } });

  const [title, setTitle] = useState("");
  const [cover, setCover] = useState(PlaceHolderDegree);
  const [url, setUrl] = useState(null);
  const [publicationDate, setPublicationDate] = useState(
    getFormattedSopiDate(new Date()),
  );
  const [brandTags, setBrandTags] = useState([]);
  const [fileType, setFileType] = useState();

  useEffect(() => {
    if (!marketId || !lang) {
      history.push(getPath("library"));
    }
  }, [marketId, lang]);
  if (!data) return null;

  const {
    tags: [marketTag],
  } = data;

  return (
    <PublishFile
      title={title}
      setTitle={setTitle}
      cover={cover}
      setCover={setCover}
      url={url}
      setUrl={setUrl}
      publicationDate={publicationDate}
      setPublicationDate={setPublicationDate}
      selectedMarket={tagIdTitleToDropDown(marketTag)}
      brandTags={brandTags}
      setBrandTags={setBrandTags}
      lang={lang}
      fileType={fileType}
      setFileType={setFileType}
    />
  );
};

export default CreateFile;
