export default (theme) => ({
  container: {
    fontFamily: theme.FontFamily,
    overflow: "auto",
  },
  title: {
    fontSize: 18,
    fontWeight: "500",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    color: theme.DragAndDropActivityAnswer.titleColor,
    marginBottom: 10,
  },
  cardContainer: {
    display: "flex",
    padding: "20px 0",
    "padding-right": "20px",
    borderBottom: `1px solid ${theme.DragAndDropActivityAnswer.cardContainerBorderBottomColor}`,
    alignItems: "center",
    "&:hover": {
      "box-shadow": "2px 2px 3px gray",
      transition: "all 300ms ease-out",
    },
  },
  editedCard: {
    "box-shadow": "2px 2px 3px gray",
    transition: "all 300ms ease-out",
  },
  cardContainerLast: {
    borderBottom: "none",
  },
  cardImageEditor: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    padding: 10,
    width: 100,
    height: 100,
    backgroundColor:
      theme.DragAndDropActivityAnswer.cardImageEditorBackgroundColor,
    flexShrink: 0,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  cardImageUpload: {
    textAlign: "center",
    color: theme.DragAndDropActivityAnswer.cardImageUploadColor,
    cursor: "pointer",
    "& > span.icon-upload": {
      fontSize: 16,
    },
    "& > div": {
      fontSize: 14,
      marginTop: 10,
    },
    "& > input": {
      width: "100%",
      padding: 40,
    },
  },
  cardImageUploaded: {
    position: "absolute",
    left: 10,
    bottom: 8,
    "& > span.icon-upload:before": {
      color: theme.DragAndDropActivityAnswer.cardImageUploadedSpanIconColor,
    },
    "& > div": {
      display: "none",
    },
    "& > input": {
      padding: 10,
    },
  },
  removeCardImageIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    color: theme.DragAndDropActivityAnswer.removeCardImageIconColor,
    fontSize: 12,
    cursor: "pointer",
  },
  cardTextInput: {
    fontSize: 18,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.DragAndDropActivityAnswer.cardTextInputColor,
    outline: "unset",
    cursor: "pointer",
    "&[contenteditable]:empty:before": {
      content: "attr(placeholder)",
      display: "block",
    },
    maxHeight: 110,
    overflow: "hidden",
  },
  cardRemoveButton: {
    marginLeft: "auto",
    fontSize: 14,
    color: theme.DragAndDropActivityAnswer.cardRemoveButtonColor,
    paddingLeft: 10,
    cursor: "pointer",
  },
  cropCardImageButton: {
    position: "absolute",
    left: 25,
    bottom: 5,
    "& > button": {
      backgroundColor: "transparent",
      border: "none",
      "& > span.icon-crop:before": {
        color: theme.DragAndDropActivityAnswer.cropCardImageButtonIconColor,
        fontSize: 16,
        cursor: "pointer",
      },
      "& > span:last-of-type": {
        display: "none",
      },
    },
  },
  addItemButton: {
    fontFamily: theme.Fonts.fontFamilyCondensed,
    marginTop: 10,
    fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "condensed",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.DragAndDropActivityAnswer.addItemButtonColor,
    textAlign: "center",
    textDecoration: "underline",
    cursor: "pointer",
  },
  disabledItemButton: {
    color: theme.DragAndDropActivityAnswer.disabledButtonColor,
    cursor: "not-allowed",
  },
});
