import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../hooks/style/useThemedStyle";
import Navigation from "../../components/Navigation";
import Header from "./lessonBadgesHeader";
import { translations } from "./LessonBadges.translations";
import { connect } from "react-redux";
import {
  GetLessonBadges,
  GetLessonData,
  SetBadgeLessonIds,
  SaveLessonBadges,
  ResetBadges,
} from "../../store/lesson/actions";
import {
  GetDegreeData,
  GetDegreeBadges,
  SetDegreeBadgeLessonIds,
  ResetDegreeBadges,
  SaveDegreeLessonBadges,
} from "../../store/degree/actions";
import { FullscreenLoader } from "genius-ui";
import { SetFetchIndicator } from "../../store/common/actions";
import styles from "./LessonsBadges.styles";
import { FormattedMessage } from "react-intl";
import Badge from "../masterTemplates/selectBadgeLessons/badge";
import { SetSuccessMessage } from "../../store/notification/actions";
import { encodeNewLines } from "../../utils/string";
import clientConfig, { CONFIG_CLUSTER } from "../../configs/client";

const useStyle = createUseStyles(styles);

const LessonsBadges = ({
  GetAll,
  history,
  match,
  badges,
  degreeDetails,
  isFetching,
  SetBadgeLessonIds,
  CancelLessonBadges,
  SaveLessonBadges,
  SetBadgeQuizzLessonIds,
  allLessons,
  title,
  isMaster,
}) => {
  const classes = useThemedStyle(useStyle, {});
  const [degreeDatas, setDegreeDatas] = useState(null);
  const goBack = () => history.goBack();

  useEffect(() => {
    GetAll(match.params.lessonId, match.params.lang, (datas) =>
      setDegreeDatas(datas),
    );
  }, []);

  return (
    <div className={classes.container}>
      {isFetching && <FullscreenLoader />}
      <Header
        goBack={goBack}
        cancelBtnEventHandler={CancelLessonBadges}
        submitBtnEventHandler={SaveLessonBadges}
        title={encodeNewLines(title || degreeDatas?.title)}
        cancelBtnText={<FormattedMessage {...translations.CancelBtnText} />}
        submitBtnText={<FormattedMessage {...translations.ValidationBtnText} />}
      />
      {!isFetching && (
        <div className={classes.contentContainer}>
          <div className="content">
            <div className="title">
              <FormattedMessage
                {...translations[
                  isMaster ? "ContentMasterTitle" : "ContentTitle"
                ]}
                values={{
                  markets: degreeDatas?.tags.filter(({ clusterId }) =>
                    clientConfig[CONFIG_CLUSTER.MARKET].includes(
                      Number(clusterId),
                    ),
                  )[0]?.title,
                  degreeName: degreeDetails?.title || degreeDatas?.title,
                }}
              />
            </div>
            <div className="subtitle">
              <FormattedMessage {...translations.ContentSubtitle} />
            </div>
            <div className={classes.badges}>
              {!!badges && badges.length ? (
                badges.map((badge) => {
                  return (
                    <Badge
                      {...badge}
                      key={badge.badgeId}
                      onCheck={SetBadgeLessonIds}
                      onCheckQuizz={SetBadgeQuizzLessonIds}
                      lessons={allLessons}
                    />
                  );
                })
              ) : (
                <div>
                  {!isFetching && (
                    <FormattedMessage {...translations.NoOneBadgeText} />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Navigation />
    </div>
  );
};

const mapStateToProp = (state, props) => ({
  markets: state.degrees.markets,
  badges: props.match.params.lessonId
    ? state.lesson.badges
    : state.degrees.badges,
  degreeDetails: state.degrees.degrees.filter(
    ({ degreeId }) => props.match.params.degreeId == degreeId,
  )[0],
  isMaster: state.lesson.publishLessonData.tags.find(
    ({ Id }) => Id == clientConfig.MASTER_DEGREE_TAG_ID,
  ),
  isFetching: state.common.fetchIndicator,
  title: props.match.params.lessonId
    ? state.lesson.publishLessonData.title
    : state.degrees.degrees.filter(
        ({ degreeId }) => props.match.params.degreeId == degreeId,
      )[0]?.title,
  lesson: state.lesson.publishLessonData,
  allLessons: props.match.params.lessonId
    ? state.lesson.allLessons
    : state.degrees.allLessons,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  if (!!ownProps.match.params.lessonId)
    return {
      GetAll: (lessonId, lang, callback) => {
        dispatch(SetFetchIndicator(true));
        dispatch(
          GetDegreeData(ownProps.match.params.degreeId, (datas) =>
            callback(datas),
          ),
        );
        dispatch(
          GetLessonData(lessonId, lang, () => {
            dispatch(
              GetLessonBadges(lang, () => dispatch(SetFetchIndicator(false))),
            );
          }),
        );
      },
      SetBadgeLessonIds: (badgeId, lessonId) => {
        dispatch(SetBadgeLessonIds(badgeId, lessonId, false));
      },
      SetBadgeQuizzLessonIds: (badgeId, lessonId) => {
        dispatch(SetBadgeLessonIds(badgeId, lessonId, true));
      },
      CancelLessonBadges: () => {
        ResetBadges();
        ownProps.history.goBack();
      },
      SaveLessonBadges: () => {
        dispatch(SaveLessonBadges());
        dispatch(
          SetSuccessMessage(
            <FormattedMessage
              {...translations[ownProps.location.state.wordingModalKey]}
            />,
          ),
        );
        ownProps.history.push(ownProps.location.state.backTo);
      },
    };
  else
    return {
      GetAll: (lessonId, lang, callback) => {
        dispatch(SetFetchIndicator(true));
        dispatch(
          GetDegreeData(ownProps.match.params.degreeId, (datas) =>
            callback(datas),
          ),
        );
        dispatch(
          GetDegreeData(ownProps.match.params.degreeId, ({ tags }) =>
            dispatch(
              GetDegreeBadges(ownProps.match.params.degreeId, tags, () =>
                dispatch(SetFetchIndicator(false)),
              ),
            ),
          ),
        );
      },
      SetBadgeLessonIds: (badgeId, lessonId) => {
        dispatch(SetDegreeBadgeLessonIds(badgeId, lessonId, false));
      },
      SetBadgeQuizzLessonIds: (badgeId, lessonId) => {
        dispatch(SetDegreeBadgeLessonIds(badgeId, lessonId, true));
      },
      CancelLessonBadges: () => {
        ResetDegreeBadges();
        ownProps.history.goBack();
      },
      SaveLessonBadges: () => {
        dispatch(SaveDegreeLessonBadges());
        dispatch(
          SetSuccessMessage(
            <FormattedMessage
              {...translations[ownProps.location.state.wordingModalKey]}
            />,
          ),
        );
        ownProps.history.push(ownProps.location.state.backTo);
      },
    };
};

export default connect(mapStateToProp, mapDispatchToProps)(LessonsBadges);
