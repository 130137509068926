import gql from "graphql-tag";

export const SEARCH_USERS = gql`
  query SearchUsers(
    $search: String!
    $limit: Int
    $orderBy: UserSortingFieldsEnum
    $tagIds: [ID!]
  ) {
    admin {
      users: users(
        search: $search
        limit: $limit
        orderBy: $orderBy
        tagIds: $tagIds
      ) {
        userId
        status
        profile {
          pic
          position
          firstName
          lastName
        }
      }
    }
  }
`;
