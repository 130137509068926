import gql from "graphql-tag";

const DOCUMENT_TAG_MUTATION = (action, documentType) => gql`
mutation addTag(
  $docId: ID,
  $lang: LangEnum,
  $tagIds: [ID!]
) {
  admin {
    ${documentType}(${documentType}Id: $docId lang: $lang) {
      tags {
        ${action}(tagIds: $tagIds)
      }
    }
  }
}`;

export const REMOVE_DOCUMENT_TAG = (documentType) =>
  DOCUMENT_TAG_MUTATION("remove", documentType);

export const ADD_DOCUMENT_TAG = (documentType) =>
  DOCUMENT_TAG_MUTATION("add", documentType);
