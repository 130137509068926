import { RESET_STORE } from "../../constants/store/auth";
import {
  APP_ANALYTICS_SET_ACTIVE_DEVICES,
  APP_ANALYTICS_SET_DAILY_SESSIONS_PER_DEVICE,
  APP_ANALYTICS_SET_SESSIONS_DURATION_DISTRIBUTION,
} from "../../constants/store/appAnalytics";

const initialState = {
  activeDevices: {
    ios: {
      monthlyTotal: null,
      monthlyTotalChangePercentage: null,
      weeklyTotal: null,
      weeklyTotalChangePercentage: null,
      dailyTotal: null,
      dailyTotalChangePercentage: null,
      fullData: {
        monthly: [],
        weekly: [],
        daily: [],
      },
    },
    android: {
      monthlyTotal: null,
      monthlyTotalChangePercentage: null,
      weeklyTotal: null,
      weeklyTotalChangePercentage: null,
      dailyTotal: null,
      dailyTotalChangePercentage: null,
      fullData: {
        monthly: [],
        weekly: [],
        daily: [],
      },
    },
    allOS: {
      monthlyTotal: null,
      monthlyTotalChangePercentage: null,
      weeklyTotal: null,
      weeklyTotalChangePercentage: null,
      dailyTotal: null,
      dailyTotalChangePercentage: null,
      fullData: {
        monthly: [],
        weekly: [],
        daily: [],
      },
    },
  },
  dailySessionsPerDevice: {
    ios: {
      totalSessions: null,
      totalSessionsChangePercentage: null,
      averageSessionsPerUser: null,
      averageSessionsPerUserChangePercentage: null,
      fullData: [],
    },
    android: {
      totalSessions: null,
      totalSessionsChangePercentage: null,
      averageSessionsPerUser: null,
      averageSessionsPerUserChangePercentage: null,
      fullData: [],
    },
    allOS: {
      totalSessions: null,
      totalSessionsChangePercentage: null,
      averageSessionsPerUser: null,
      averageSessionsPerUserChangePercentage: null,
      fullData: [],
    },
  },
  sessionDurationDistribution: {
    ios: {
      averageSessionLength: null,
      averageSessionLengthChangePercentage: null,
      fullData: [],
    },
    android: {
      averageSessionLength: null,
      averageSessionLengthChangePercentage: null,
      fullData: [],
    },
    allOS: {
      averageSessionLength: null,
      averageSessionLengthChangePercentage: null,
      fullData: [],
    },
  },
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;

  switch (type) {
    case APP_ANALYTICS_SET_ACTIVE_DEVICES: {
      return {
        ...state,
        activeDevices: {
          ...state.activeDevices,
          ...data,
        },
      };
    }
    case APP_ANALYTICS_SET_DAILY_SESSIONS_PER_DEVICE: {
      return {
        ...state,
        dailySessionsPerDevice: {
          ...state.dailySessionsPerDevice,
          ...data,
        },
      };
    }
    case APP_ANALYTICS_SET_SESSIONS_DURATION_DISTRIBUTION: {
      return {
        ...state,
        sessionDurationDistribution: {
          ...state.sessionDurationDistribution,
          ...data,
        },
      };
    }
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
