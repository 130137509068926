import React, { useState } from "react";
import PropTypes from "prop-types";
import { ImageSelectorStyle } from "./imageSelector.style";
import { Icon, Icons, FullscreenLoader } from "genius-ui";
import CropImage from "../../common/CropImage";
import { FormattedMessage } from "react-intl";
import UploadMedia from "../UploadMedia";
import { translations } from "./ImageSelectior.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(ImageSelectorStyle);

const ImageSelector = (props) => {
  const {
    uniqueId,
    uploadButtonLabel,
    enableMediaSelectionPopup,
    firstUpload,
    id,
    setFirstUpload,
  } = props;
  const [isCropVisible, setIsCropVisible] = useState(false);
  const [pictureLoading, setPictureLoading] = useState(false);
  const classes = useThemedStyle(useStyle, props);
  const handleImageChange = (url, id, callback) => {
    setIsCropVisible(false);
    if (callback) {
      callback(url, id);
    } else {
      props.onImageChange(url, id);
    }
  };

  const onFileTypeDetected = () => setPictureLoading(true);
  const onUploadSuccess = (url) => {
    handleImageChange(url, id);
    setPictureLoading(false);
    setFirstUpload && setFirstUpload(false);
  };

  return (
    <div className={classes.imageContainer} onClick={props.onClick}>
      {pictureLoading && (
        <div className={classes.loader}>
          <FullscreenLoader isPositionFixed={false} />
        </div>
      )}
      {!firstUpload && !pictureLoading && (
        <div className={classes.uploadControlsBox}>
          <div className={classes.buttonsContainer}>
            <UploadMedia
              id={`select-image-${uniqueId}`}
              onUploadSuccess={onUploadSuccess}
              isVideoUploadEnabled={false}
              className={classes.uploadBtnWrapper}
              enableMediaSelectionPopup={enableMediaSelectionPopup}
              onFileTypeDetected={onFileTypeDetected}
              accept="image/*"
              setLoading={setPictureLoading}
            >
              <Icon iconName={Icons.upload} style={classes.iconAction} />
              <span>
                {uploadButtonLabel || (
                  <FormattedMessage {...translations.UploadImageText} />
                )}
              </span>
            </UploadMedia>
            <CropImage
              {...props}
              classes={classes}
              sourceImage={props.cropImageSource || props.sourceImage}
              isCropVisible={isCropVisible}
              cropSize={props.cropSize}
              onCropClick={() => setIsCropVisible(true)}
              onCancel={() => setIsCropVisible(false)}
              onCropCompleted={(url, id) =>
                handleImageChange(url, id, props.onImageCropped)
              }
            />
          </div>
        </div>
      )}
      {firstUpload && !pictureLoading && (
        <div className={classes.centerDisplay}>
          <div className={classes.upload}>
            <UploadMedia
              id={`select-image-${uniqueId}`}
              onUploadSuccess={onUploadSuccess}
              isVideoUploadEnabled={false}
              enableMediaSelectionPopup={enableMediaSelectionPopup}
              onFileTypeDetected={onFileTypeDetected}
              accept="image/*"
            >
              <div className={classes.text}>Change cover</div>
            </UploadMedia>
          </div>
        </div>
      )}
    </div>
  );
};

ImageSelector.propTypes = {
  /** the  unique id of the image selector control which is consistent across page loads */
  uniqueId: PropTypes.string,
  /** The current selected image */
  sourceImage: PropTypes.string,
  /** The display format */
  backgroundSize: PropTypes.string,
  /** Label for the change image selection button */
  uploadButtonLabel: PropTypes.node,
  /** On upload completed image change */
  onImageChange: PropTypes.func,
  /** the  unique id of the image selector control */
  id: PropTypes.string,
  /** on key press event */
  onClick: PropTypes.func,
  /** isSelected */
  isSelected: PropTypes.bool,
  /** Crop image source */
  cropImageSource: PropTypes.string,
  /** Cropped image handler */
  onImageCropped: PropTypes.func,
};

ImageSelector.defaultProps = {
  uploadButtonLabel: <FormattedMessage {...translations.UploadImageText} />,
  backgroundSize: "cover",
};

export default ImageSelector;
