import React from "react";
import DegreeBuilderDefault from "./DegreeBuilderDefault";
import { isShiseido } from "../../configs/client";
import DegreeBuilderShiseidoTrainer from "./Shiseido/trainer";
import { connect } from "react-redux";
import { FullscreenLoader } from "genius-ui";
import { USER_ROLE_NAMES } from "../../configs/constants";

const DegreeBuilder = ({ userRoles, rest }) => {
  if (!userRoles || !userRoles.length) {
    return <FullscreenLoader />;
  }

  if (isShiseido && userRoles.includes(USER_ROLE_NAMES.TRAINER)) {
    return <DegreeBuilderShiseidoTrainer {...rest} />;
  }
  return <DegreeBuilderDefault {...rest} />;
};

const mapStateToProps = (state) => ({
  userRoles: state.auth.profileInfo.roles,
});

export default connect(mapStateToProps)(DegreeBuilder);
