import React from "react";
import { FormattedMessage } from "react-intl";
import { lessonRowTranslations as translations } from "./ProgramsWidgetShiseido.translations";

const QuitAt = ({
  quitPageNumber,
  getUserQuitPagePercentage,
  totalUsers,
  usersQuit,
}) => {
  if (isNaN(usersQuit)) return <span>NA</span>;
  if (!usersQuit) return <span>0 users quit</span>;
  return (
    <span>
      <FormattedMessage
        {...translations.UsersQuitLabel}
        values={{
          ratio: `${getUserQuitPagePercentage(totalUsers, usersQuit)}%`,
        }}
      />
      <span>{quitPageNumber}</span>
    </span>
  );
};

export default QuitAt;
