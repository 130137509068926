import { LANGUAGES } from "../../configs/referential";
import { GetTagsForClusters } from "../../utils/dataTransformation/tags";
import clientConfig, { CONFIG_CLUSTER } from "../../configs/client";
import {
  PollDocumentToPollItem,
  PollItemToPollDocument,
} from "../editPoll/transformers";

export const formatPollPublishData = (data) => ({
  pollId: data.pollId,
  lang: data.lang,
  title: data.title ? data.title : "",
  summary: data.summary ? data.summary : "",
  picCover: data.picCover,
  language: LANGUAGES.find((x) => x.Code === data.lang).Name,
  startPostingDate: data.workflowScheduledIn,
  endPostingDate: data.workflowScheduledOut,
  countries: GetTagsForClusters(
    clientConfig[CONFIG_CLUSTER.COUNTRY],
    data.tags,
  ),
  retailers: GetTagsForClusters(
    clientConfig[CONFIG_CLUSTER.RETAILER],
    data.tags,
  ),
  brands: GetTagsForClusters(clientConfig[CONFIG_CLUSTER.BRAND], data.tags),
  tags: GetTagsForClusters(clientConfig[CONFIG_CLUSTER.EXPERTISE], data.tags),
  pages: PollDocumentToPollItem(data),
  pageId: data.pages[0].pageId,
  workflow: data.workflow,
});

export const getPageWithNewTimer = (pages, second) => {
  const questionsWithNewTimer = {
    ...pages,
    questions: pages.questions.map((question) => ({
      ...question,
      timer: second,
    })),
  };
  return PollItemToPollDocument(questionsWithNewTimer);
};

export const getPageWithNewScore = (pages, score) => {
  const questionsWithNewScore = {
    ...pages,
    questions: pages.questions.map((question) => ({
      ...question,
      score: score,
    })),
  };
  return PollItemToPollDocument(questionsWithNewScore);
};
