import * as constants from "../../constants/store/common";

const initialState = {
  saveIndicator: false,
  updateIndicator: false,
  deleteIndicator: false,
  fetchIndicator: false,
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.SET_SAVE_INDICATOR:
      return {
        ...state,
        saveIndicator: data,
      };
    case constants.SET_UPDATE_INDICATOR:
      return {
        ...state,
        updateIndicator: data,
      };
    case constants.SET_DELETE_INDICATOR:
      return {
        ...state,
        deleteIndicator: data,
      };
    case constants.SET_FETCH_INDICATOR:
      return {
        ...state,
        fetchIndicator: data,
      };
    case constants.RESET_REQUEST_ERROR_DATA:
      return initialState;
    default:
      return state;
  }
}
