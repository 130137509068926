import gql from "graphql-tag";

export const GET_ALL_BADGES = gql`
  query getAllBadges($tagIds: [ID!]) {
    admin {
      badges(tagIds: $tagIds) {
        badgeId
        title
        description
        score
        type
        status
        pic
        degreeRef {
          title
          degreeId
        }
        ranges {
          rangeId
          mention
          score
          pic
        }
      }
    }
  }
`;
