import * as constants from "../../constants/store/ninja";

const initialState = {
  ninjas: [],
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.SET_NINJAS:
      return {
        ...state,
        ninjas: data,
      };
    default:
      return state;
  }
}
