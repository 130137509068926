export const ToolbarStyle = ({ Toolbar, Colors, Fonts }) => ({
  toolbarContainer: {
    display: "flex",
    flexDirection: "row",
    height: 50,
    width: "100%",
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  barItems: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
    alignItems: "center",
  },
  barItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    cursor: "pointer",
    height: 50,
    margin: 10,
    marginRight: 30,
    "&:hover": {
      color: Colors.lightBlue,
      cursor: "pointer",
    },
    "& > span": {
      "&:hover::before": {
        color: Colors.lightBlue,
        cursor: "pointer",
      },
    },
    "&:hover *": {
      color: Colors.lightBlue,
      cursor: "pointer",
      "&::before": {
        color: Colors.lightBlue,
        cursor: "pointer",
      },
    },
  },
  iconItem: {
    fontFamily: Fonts.iconsFontFamily,
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 16,
    },
    "&::before": {
      color: Toolbar.iconColor,
      fontSize: 16,
    },
  },
  iconSubtitle: {
    fontFamily: Fonts.iconsFontFamily,
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 14,
    },
    "&::before": {
      color: Toolbar.iconColor,
      fontSize: 14,
    },
  },
  addIconBox: {
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    paddingRight: 25,
    "&:hover": {
      "&:before": {
        color: `${Colors.lightBlue}`,
      },
      svg: {
        color: `${Colors.lightBlue}`,
      },
    },
    "& > span": {
      paddingLeft: 10,
      fontFamily: Fonts.fontFamilyCondensed,
      fontSize: 20,
      fontWeight: 300,
      fontStyle: 400,
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: 0.07,
      color: Toolbar.iconColor,
    },
  },
  removeIconBox: {
    transition: "background 400ms",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: 25,
    cursor: "pointer",
    "&:hover": {
      "&:before": {
        color: `${Colors.lightBlue}`,
      },
      svg: {
        color: `${Colors.lightBlue}`,
      },
    },
    "&  a": {
      textDecoration: "none",
    },
  },
  iconMenuSelected: {
    color: `${Colors.lightBlue}`,
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 25,
    },
    "&::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 25,
    },
  },
  iconAddContent: {
    paddingRight: 25,
    color: `${Colors.lightBlue}`,
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 25,
    },
    "&::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 25,
    },
  },
  itemText: {
    fontFamily: Fonts.fontFamilyCondensed,
    fontSize: 15,
    fontWeight: 400,
    fontStyle: "normal",
    fontStretch: "condensed",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: Toolbar.iconColor,
    textTransform: "uppercase",
    "&:hover": {
      color: `${Colors.lightBlue}`,
    },
  },
  iconVideo: {
    fontFamily: Fonts.iconsFontFamily,
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 10,
    },
    "&::before": {
      color: Toolbar.iconColor,
      fontSize: 10,
    },
  },
  iconHideToolbar: {
    paddingLeft: 10,
    color: `${Colors.lightBlue}`,
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 25,
    },
    "&::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 25,
    },
  },
});
