import gql from "graphql-tag";

export const CARD_COPY_FOR_LANGUAGE = gql`
  mutation cardCopy($cardId: ID, $sourceLang: LangEnum, $destLang: LangEnum!) {
    admin {
      card(cardId: $cardId, lang: $sourceLang) {
        copy(lang: $destLang) {
          cardId
          versions {
            title
            lang
          }
        }
      }
    }
  }
`;
