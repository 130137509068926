import DotsMenu from "components/common/DotsMenu";
import SettingsMenu from "components/common/SettingsMenu";
import { getPath } from "core/paths";
import useThemedStyle from "hooks/style/useThemedStyle";
import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import Style from "./Badge.style";
import { Icons } from "genius-ui";
import client from "graphql/client";
import { UPDATE_BADGE_STATUS } from "pages/badges/graphs";

const useStyle = createUseStyles(Style);

const updateBadgeStatus = async (badgeId, status) => {
  await client.mutate({
    mutation: UPDATE_BADGE_STATUS,
    variables: {
      badgeId,
      status,
    },
  });
};

const MASTER_MENU = (handleOnCloneToMarketClick) => [
  {
    label: "Clone to market", //formatMessage(translations.SendNotificationOption),
    icon: Icons.notification,
    onItemClick: () => {
      handleOnCloneToMarketClick();
    },
  },
];

const MARKET_MENU = (handleSetBadgeActive, handleSetBadgeArchived, status) => [
  {
    label: "Set to ACTIVE", //formatMessage(translations.SendNotificationOption),
    icon: Icons.notification,
    disabled: status !== "DRAFT",
    onItemClick: () => {
      handleSetBadgeActive();
    },
  },
  {
    label: "Set to ARCHIVED", //formatMessage(translations.SendNotificationOption),
    icon: Icons.notification,
    disabled: status !== "ACTIVE",
    onItemClick: () => {
      handleSetBadgeArchived();
    },
  },
];

const Badge = ({
  badgeId,
  title,
  tags,
  isMaster,
  activeMenuBadgeId,
  setActiveMenuBadgeId,
  score,
  status,
  degreeRef,
  ...props
}) => {
  const classes = useThemedStyle(useStyle, props);
  const history = useHistory();
  const handleOnCardClick = useCallback(() => {
    if (isMaster) return;
    history.push(getPath("editBadge", badgeId));
  }, [history, badgeId, isMaster]);
  const handleOnCloneToMarketClick = useCallback(() => {
    history.push(getPath("cloneBadgeToMarket", badgeId));
  }, [history, badgeId]);
  const handleSetBadgeActive = useCallback(() => {
    updateBadgeStatus(badgeId, "ACTIVE");
  }, [badgeId]);
  const handleSetBadgeArchived = useCallback(() => {
    updateBadgeStatus(badgeId, "ARCHIVED");
  }, [badgeId]);

  return (
    <div className={classes.cardContainer} onClick={handleOnCardClick}>
      <div className={classes.card}>
        <div>{title}</div>
        <div>{degreeRef?.title}</div>
        <div>{score}</div>
        <div>{status}</div>
      </div>
      <div className={classes.cardRight}>
        <DotsMenu
          handleDelete={() => setActiveMenuBadgeId(badgeId)}
          docId={badgeId}
        />
        <SettingsMenu
          handleFocusLeave={() => setActiveMenuBadgeId(undefined)}
          isVisible={activeMenuBadgeId && activeMenuBadgeId === badgeId}
          className={classes.docSettingsMenu}
          menuItems={
            isMaster
              ? MASTER_MENU(handleOnCloneToMarketClick)
              : MARKET_MENU(
                  handleSetBadgeActive,
                  handleSetBadgeArchived,
                  status,
                )
          }
        />
      </div>
    </div>
  );
};

export default Badge;
