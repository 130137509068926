import PropTypes from "prop-types";

export const cssSizePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]); // any valid css size value

export const sizePropType = PropTypes.shape({
  width: cssSizePropType.isRequired,
  height: cssSizePropType.isRequired,
});

export const positionPropType = PropTypes.shape({
  x: cssSizePropType.isRequired,
  y: cssSizePropType.isRequired,
});
