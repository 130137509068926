import format from "date-fns/format";
import startOfHour from "date-fns/startOfHour";
import endOfHour from "date-fns/endOfHour";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import startOfYear from "date-fns/startOfYear";
import endOfYear from "date-fns/endOfYear";
import addYears from "date-fns/addYears";
import addMonths from "date-fns/addMonths";
import addWeeks from "date-fns/addWeeks";
import addDays from "date-fns/addDays";
import addHours from "date-fns/addHours";
import areDatesEqual from "date-fns/isEqual";
import isDate1BeforeDate2 from "date-fns/isBefore";
import isDate1AfterDate2 from "date-fns/isAfter";
import isWithinInterval from "date-fns/isWithinInterval";
import formatDurationDateFNS from "date-fns/formatDuration";
import addDuration from "date-fns/add";
import parseISO from "date-fns/parseISO";
import diffInCalendarDays from "date-fns/differenceInCalendarDays";
import { calculateValueChangePercentage } from "../general";
import diffInMonths from "date-fns/differenceInMonths";
import diffInYears from "date-fns/differenceInYears";

export const getFormattedPublicationDate = (date) =>
  formatDate(date || new Date(), "dd.MM.yy");

export const getFormattedSopiDate = (date) =>
  formatDate(date || new Date(), "yyyy-MM-dd");

export const getFormattedPublicationDateTime = (date) =>
  formatDate(date || new Date(), "MMMM d, yyyy h:mm a");

export const formatDateTimeForSave = (date) => {
  let dateToFormat = new Date();
  if (date) {
    dateToFormat = date;
    if (typeof date === "string") {
      dateToFormat = parseISODate(dateToFormat);
    }
  }

  return dateToFormat.toISOString();
};

// usability wrapper around date-fns.format
export const formatDate = (date, formatString, ...args) => {
  if (!date) return "";

  const dateObject = typeof date === "string" ? parseISODate(date) : date;

  return format(dateObject, formatString, ...args);
};

export const getHourBeginningDate = (date) => startOfHour(date);

export const getHourEndDate = (date) => endOfHour(date);

export const getDayBeginningDate = (date) => startOfDay(date);

export const getDayEndDate = (date) => endOfDay(date);

export const getMonthBeginningDate = (date) => startOfMonth(date);

export const getMonthEndDate = (date) => endOfMonth(date);

export const getYearBeginningDate = (date) => startOfYear(date);

export const getYearEndDate = (date) => endOfYear(date);

export const addYear = (date, amount) => addYears(date, amount);

export const addMonth = (date, amount) => addMonths(date, amount);

export const addWeek = (date, amount) => addWeeks(date, amount);

export const addDay = (date, amount) => addDays(date, amount);

export const addHour = (date, amount) => addHours(date, amount);

export const addDurationToDate = (...args) => addDuration(...args);

export const isEqual = (date1, date2) => areDatesEqual(date1, date2);

export const isEpoch = (date) => isEqual(date, new Date(0));

export const isBefore = (date1, date2) => isDate1BeforeDate2(date1, date2);

export const isAfter = (date1, date2) => isDate1AfterDate2(date1, date2);

export const isDateInRange = (
  date,
  rangeStart,
  rangeEnd,
  inclusivity = "[]",
  granularity = null,
) => {
  let getRangeBeginningDate;

  switch (granularity) {
    case "day":
      getRangeBeginningDate = getDayBeginningDate;
      break;
    case "hour":
      getRangeBeginningDate = getHourBeginningDate;
      break;
    default:
      getRangeBeginningDate = null;
      break;
  }

  const start = getRangeBeginningDate
    ? getRangeBeginningDate(rangeStart)
    : rangeStart;
  const end = rangeEnd;

  if ((inclusivity === "[)" || inclusivity === "()") && isEqual(date, end)) {
    return false;
  }

  if ((inclusivity === "(]" || inclusivity === "()") && isEqual(date, start)) {
    return false;
  }

  return isWithinInterval(date, {
    start,
    end,
  });
};

export const parseISODate = (isoString) => parseISO(isoString);

// duration utilities
export const parseISODuration = (duration) => {
  // https://github.com/pke/date-fns-duration/blob/master/index.js
  const ISO_PERIOD =
    /^(-|\+)?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;
  duration = duration.trim();
  const matches = ISO_PERIOD.exec(duration);
  if (!matches || duration.length < 3) {
    throw new TypeError(
      `invalid duration: "${duration}". Must be an ISO 8601 duration. See https://en.wikipedia.org/wiki/ISO_8601#Durations`,
    );
  }

  return {
    years: matches[2] && parseInt(matches[2]),
    months: matches[3] && parseInt(matches[3]),
    weeks: matches[4] && parseInt(matches[4]),
    days: matches[5] && parseInt(matches[5]),
    hours: matches[6] && parseInt(matches[6]),
    minutes: matches[7] && parseInt(matches[7]),
    seconds: matches[8] && parseFloat(matches[8]),
  };
};

// TODO: refactor calculation by converting durations to number with durationToNumber and than back to duration
export const sumDurations = (duration1, duration2) => {
  const addMembers = (member1, member2) =>
    (member1 || 0) + (member2 || 0) || undefined;
  let years = addMembers(duration1.years, duration2.years);
  let months = addMembers(duration1.months, duration2.months) || undefined;
  let weeks = addMembers(duration1.weeks, duration2.weeks) || undefined;
  let days = addMembers(duration1.days, duration2.days) || undefined;
  let hours = addMembers(duration1.hours, duration2.hours) || undefined;
  let minutes = addMembers(duration1.minutes, duration2.minutes) || undefined;
  let seconds = addMembers(duration1.seconds, duration2.seconds) || undefined;

  if (seconds >= 60) {
    seconds -= 60;
    minutes++;
  }

  if (minutes >= 60) {
    minutes -= 60;
    hours++;
  }

  if (hours >= 24) {
    hours -= 24;
    days++;
  }

  if (days >= 7) {
    days -= 7;
    weeks++;
  }

  if (months >= 12) {
    months -= 12;
    years++;
  }

  return {
    years,
    months,
    weeks,
    days,
    hours,
    minutes,
    seconds,
  };
};

const getDurationMember = (duration, memberName) => duration[memberName] || 0;

export const durationToNumber = (duration) =>
  getDurationMember(duration, "seconds") +
  getDurationMember(duration, "minutes") * 60 +
  getDurationMember(duration, "hours") * 60 * 60 +
  getDurationMember(duration, "days") * 24 * 60 * 60 +
  getDurationMember(duration, "weeks") * 7 * 24 * 60 * 60 +
  getDurationMember(duration, "months") * 30 * 24 * 60 * 60 +
  getDurationMember(duration, "years") * 12 * 365 * 24 * 60 * 60;

export const calculateDurationChangePercentage = (
  previousDuration,
  currentDuration,
) =>
  calculateValueChangePercentage(
    durationToNumber(previousDuration),
    durationToNumber(currentDuration),
  );

export const formatDuration = formatDurationDateFNS;

export const differenceInCalendarDays = (date1, date2) =>
  diffInCalendarDays(date1, date2);

export const differenceInMonths = (date1, date2) => diffInMonths(date1, date2);

export const differenceInYears = (date1, date2) => diffInYears(date1, date2);
