import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Style from "../EditPoll.style";
import PollEditorReadOnly from "../../../components/Poll/PollEditor/readOnly";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import SideBarNavigation from "../../../components/SideBarNavigation";
import { Notification } from "genius-ui";
import { FormattedMessage } from "react-intl";
import { translations } from "../EditPoll.translations";

const useStyle = createUseStyles(Style);

const EditPoll = (props) => {
  const classes = useThemedStyle(useStyle, props);
  const { questions, NavigateToPreviousPage } = props;
  const [isNotificationOpen, setNotificationStatus] = useState(true);

  return (
    <>
      <Notification
        message={<FormattedMessage {...translations.ReadOnlyMessage} />}
        status="warning"
        isOpen={isNotificationOpen}
        handleClose={setNotificationStatus}
      />
      <SideBarNavigation handleBackClick={NavigateToPreviousPage} />
      <div className={classes.pollEditorContainer}>
        <PollEditorReadOnly
          className={classes.pollEditor}
          questions={questions}
        />
      </div>
    </>
  );
};

export default EditPoll;
