import { defineMessages } from "react-intl";

export const translations = defineMessages({
  InvalidFormat: {
    id: "UploadMedia.InvalidFormat",
    defaultMessage: "Picture format not valid",
  },
  NetworkError: {
    id: "UploadMedia.UploadFail",
    defaultMessage: "Upload fail",
  },
});
