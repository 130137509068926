import gql from "graphql-tag";

export const CARD_SET_TITLE = gql`
  mutation cardSetTitle($title: String!, $cardId: ID, $lang: LangEnum) {
    admin {
      card(cardId: $cardId, lang: $lang) {
        title(data: $title)
      }
    }
  }
`;
