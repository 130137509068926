import { defineMessages } from "react-intl";

export const translations = defineMessages({
  TranslateOption: {
    id: "DocListView.TranslateOption",
    defaultMessage: "Translate",
  },
  DeleteOption: {
    id: "DocListView.DeleteOption",
    defaultMessage: "Delete",
  },
  SeeMoreButtonDefaultTitle: {
    id: "DocListView.SeeMoreButtonDefaultTitle",
    defaultMessage: "Load more",
  },
  NameHeaderTitle: {
    id: "DocListView.NameHeaderTitle",
    defaultMessage: "Name",
  },
  AuthorHeaderTitle: {
    id: "DocListView.AuthorHeaderTitle",
    defaultMessage: "Author",
  },
  AnnouncementHeaderTitle: {
    id: "DocListView.AnnouncementHeaderTitle",
    defaultMessage: "Announcement",
  },
  TypeHeaderTitle: {
    id: "DocListView.TypeHeaderTitle",
    defaultMessage: "Type",
  },
  DateHeaderTitle: {
    id: "DocListView.DateHeaderTitle",
    defaultMessage: "Date",
  },
  StatusHeaderTitle: {
    id: "DocListView.StatusHeaderTitle",
    defaultMessage: "Status",
  },
  SendNotificationOption: {
    id: "DocListView.SendNotificationOption",
    defaultMessage: "Send Notification",
  },
  PublishOption: {
    id: "DocListView.PublishOption",
    defaultMessage: "Publication settings",
  },
});
