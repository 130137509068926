import React from "react";
import PropTypes from "prop-types";
import Style from "./MultiProgressWidget.style.js";
import MultiProgressChart from "../../common/MultiProgressChart";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import InfoTooltip from "../InfoTooltip";
import { FormattedMessage } from "react-intl";
import { translations } from "./MultiProgressWidget.translations";

const useStyle = createUseStyles(Style);

const MultiProgressWidget = (props) => {
  const { title, titleRight, progressItems } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        {title}
        <div className={classes.titleRight}>
          <div>{titleRight}</div>
          <InfoTooltip>
            <div className={classes.tooltipContent}>
              <FormattedMessage {...translations.ContentDataDescription} />
            </div>
          </InfoTooltip>
        </div>
      </div>
      <div className={classes.chartContainer}>
        <MultiProgressChart progressItems={progressItems} />
      </div>
    </div>
  );
};

MultiProgressWidget.propTypes = {
  title: PropTypes.node.isRequired,
  titleRight: PropTypes.node,
  progressItems: PropTypes.array.isRequired,
};

export default MultiProgressWidget;
