import React from "react";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";
import { Button, ButtonSize, ButtonVariation } from "genius-ui";
import style from "./CreateProgram.style";
import { translations } from "./CreateProgram.translations";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const ProgramCreateInfo = (props) => {
  const classes = useThemedStyle(useStyle, props);
  const currentTitle =
    props.program && props.program.title ? props.program.title : "";
  const currentSummary =
    props.program && props.program.summary ? props.program.summary : "";

  return (
    <div className={classes.center}>
      <div className={classes.left}>
        <div className="InputContainer">
          <FormattedMessage {...translations.ProgramNamePlaceholderText}>
            {(message) => (
              <input
                type="text"
                value={currentTitle}
                className={classes.textInputAddTitle}
                onChange={(e) => {
                  props.onTitleChange(e.target.value);
                }}
                placeholder={message[0]}
              />
            )}
          </FormattedMessage>
          <FormattedMessage {...translations.ProgramDescriptionPlaceholderText}>
            {(message) => (
              <input
                type="text"
                value={currentSummary}
                className={classes.textInputAddSummary}
                onChange={(e) => {
                  props.onSummaryChange(e.target.value);
                }}
                placeholder={message[0]}
              />
            )}
          </FormattedMessage>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variation={ButtonVariation.primary}
            size={ButtonSize.small}
            handleClick={props.handleClick}
          >
            Validate
          </Button>
          <Button
            variation={ButtonVariation.secondary}
            size={ButtonSize.small}
            handleClick={props.handleCreateProgram}
            className={classes.buttonStyle}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProgramCreateInfo;
