// DOCUMENTS

export const SET_CURRENT_DOCUMENT_LANGUAGE = "SET_CURRENT_DOCUMENT_LANGUAGE";
export const SET_ACTIVE_MENU_FOR_DOCUMENT_ID =
  "SET_ACTIVE_MENU_FOR_DOCUMENT_ID";
export const SET_CURRENT_WORKFLOW_STATUS = "SET_CURRENT_WORKFLOW_STATUS";
export const SET_CURRENT_DOCUMENT_TYPE = "SET_CURRENT_DOCUMENT_TYPE";
export const SET_CURRENT_DOCUMENT_MARKET = "SET_CURRENT_DOCUMENT_MARKET";
export const SET_VIEW_TYPE = "SET_VIEW_TYPE";
export const SET_LIST_WORKFLOW = "SET_LIST_WORKFLOW";
export const SET_CURRENT_DOCUMENT_LEVEL = "SET_CURRENT_DOCUMENT_LEVEL";
