import React from "react";
import { createUseStyles } from "react-jss";
import { MainStyle } from "./Main.style";
import { connect } from "react-redux";
import { Notification } from "genius-ui";
import { CloseNotification } from "../../store/notification/actions";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(MainStyle);

const Main = (props) => {
  const { children } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.content}>
      <Notification
        timeOut={5000}
        message={props.currentNotification.message}
        status={props.currentNotification.status}
        isOpen={props.currentNotification.isOpen}
        handleTimeOut={props.HandleCloseNotification}
        handleClose={props.HandleCloseNotification}
      />
      {children}
    </div>
  );
};

const mapStateToProp = (state) => ({
  currentNotification: state.notification,
});

const mapDispatchToProps = (dispatch) => ({
  HandleCloseNotification: () => {
    dispatch(CloseNotification());
  },
});

export default connect(mapStateToProp, mapDispatchToProps)(Main);
