import { defineMessages } from "react-intl";

export const translations = defineMessages({
  CropText: {
    id: "ImageCropper.CropText",
    defaultMessage: "Crop",
  },
  CancelText: {
    id: "ImageCropper.CancelText",
    defaultMessage: "Cancel",
  },
});
