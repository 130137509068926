import React from "react";

class LazyLoadedModuleErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    /** TODO: log the error to an error reporting service **/
  }

  render() {
    if (this.state.hasError) {
      return <h1>Failed to load module</h1>;
    }

    return this.props.children;
  }
}

export default LazyLoadedModuleErrorBoundary;
