import gql from "graphql-tag";

export const CARD_SET_COVER_IMAGE = gql`
  mutation cardSetCoverImage($image: String!, $cardId: ID, $lang: LangEnum) {
    admin {
      card(cardId: $cardId, lang: $lang) {
        picCard(data: $image)
        picCover(data: $image)
        picThumb(data: $image)
      }
    }
  }
`;
