import * as constants from "../../constants/store/users";
import {
  GET_USERS,
  GET_USERS_PROFILES_DATA,
  GET_BADGES,
} from "../../graphql/remote/users/queries";
import client from "../../graphql/client";
import { SetErrorMessage } from "../notification/actions";
import {
  countTheTotalNumberOfUsers,
  getUsersByStatuses,
  userDocumentArrayToUserDomainItemArray,
  userDocumentArrayToUserExportArray,
  getFormattedUsersProfilesData,
} from "./transformers";
import clientConfig, {
  isShiseido,
  USER_MANAGEMENT_FILTERS,
} from "../../configs/client";
import { DELETE_USER } from "../../graphql/remote/users/mutations";
import { chunkArray } from "../../utils/arrays";
import { createXml } from "../../utils/excelExport/excelFactory";

const PAGE_LIMIT = 1000;

export const UpdateUsersProfilesData = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_USERS_PROFILES_DATA,
    data: data,
  });
};

export const UpdateUsers = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_USERS,
    data,
  });
};

export const UpdateUserCount = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_USER_COUNT,
    data,
  });
};

export const UpdateUserList = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_USER_LIST,
    data,
  });
};

export const ResetUserList = (data) => (dispatch) => {
  dispatch({
    type: constants.RESET_USER_LIST,
    data,
  });
};

export const PatchPaginationMetaData = (data) => (dispatch, getState) => {
  const { paginationMetaData } = getState().users;

  dispatch({
    type: constants.UPDATE_PAGINATION_META_DATA,
    data: {
      ...paginationMetaData,
      ...data,
    },
  });
};

export const SetFilterSelectedTags = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_FILTER_SELECTED_TAGS,
    data,
  });
};

export const ReSetFilterSelectedTags = (isTrainer) => (dispatch, getState) => {
  const usersState = getState().users;
  const initialFilterState = USER_MANAGEMENT_FILTERS.map((filter) => ({
    ...filter,
    tags: [],
    selectedTags: [],
    tagClusterIds: filter.clusters,
  }));
  const data = {
    ...usersState,
    filters: usersState.filters.map((filter, index) => {
      if (isTrainer && isShiseido && filter.name === "MARKET") {
        return filter;
      }
      return {
        ...filter,
        selectedTags: initialFilterState[index].selectedTags,
      };
    }),
    paginationMetaData: {
      ...usersState.paginationMetaData,
      page: 0,
    },
    users: [],
  };

  dispatch({
    type: constants.RESET_FILTER_SELECTED_TAGS,
    data,
  });
};

export const SetUsersLoading = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_USERS_LOADING,
    data,
  });
};

const getTagMultipleIntersectIds = (filters) => {
  return filters.reduce(
    (tagIds, { selectedTags }) =>
      selectedTags.length
        ? [...tagIds, { tagIds: selectedTags.map(({ value }) => value) }]
        : tagIds,
    [],
  );
};

export const GetUsers =
  (reset = false) =>
  async (dispatch, getState) => {
    const {
      paginationMetaData: { userSearchTerm, page, usersOnPage, sortState },
      filters,
    } = getState().users;

    const searchVariables = {
      search: userSearchTerm,
      tagMultipleIntersectIds: getTagMultipleIntersectIds(filters),
      limit: usersOnPage,
      skip: usersOnPage * page,
    };

    const sortByColumns = Object.keys(sortState);
    if (sortByColumns.length > 0) {
      // currently it is possible to sort by a single column, so we get the first column from this.props.sortState
      searchVariables.orderBy = sortByColumns[0];
      searchVariables.orderMethod = sortState[sortByColumns[0]];
    }

    dispatch(SetUsersLoading(true));
    try {
      const result = await client.query({
        fetchPolicy: "network-only",
        query: GET_USERS,
        variables: searchVariables,
      });

      dispatch(
        reset
          ? ResetUserList(
              userDocumentArrayToUserDomainItemArray(result.data.admin.users),
            )
          : UpdateUserList({
              users: userDocumentArrayToUserDomainItemArray(
                result.data.admin.users,
              ),
              userCount: countTheTotalNumberOfUsers(
                result.data.admin.countUsers.users,
              ),
              usersByStatuses: getUsersByStatuses(
                result.data.admin.countUsers.users,
              ),
            }),
      );
    } catch (e) {
      dispatch(
        SetErrorMessage("An error occurred while fetching the users", e),
      );
    }
    dispatch(SetUsersLoading(false));
  };

export const DeleteUserById = (userId) => (dispatch) => {
  dispatch({
    type: constants.DELETE_USER_BY_ID,
    data: userId,
  });
};

export const DeleteUser = (userId) => async (dispatch) => {
  try {
    await client.mutate({
      mutation: DELETE_USER,
      variables: {
        userId,
      },
    });

    dispatch(DeleteUserById(userId));
  } catch (e) {
    dispatch(SetErrorMessage("An error occurred while deleting the user", e));
  }
};

const GetData = async (searchVariables, groupedBadges) => {
  let isMoreDataAvailable = true;
  let currentPage = 0;
  let allResults = [];
  let usersInfo = [];
  let usersBadges = [];

  while (isMoreDataAvailable) {
    searchVariables.limit = PAGE_LIMIT;
    searchVariables.skip = currentPage * PAGE_LIMIT;
    searchVariables.sanitizer = "CSV";

    const currentResult = await client.query({
      fetchPolicy: "no-cache",
      query: GET_USERS,
      variables: searchVariables,
    });
    usersInfo = userDocumentArrayToUserExportArray(
      currentResult.data.admin.users,
    );
    usersBadges = currentResult.data.admin.users.map((user) =>
      groupedBadges.map((badge) => {
        const foundBadge = user.profileCustom
          .find((category) => category.name === "badges")
          .badges.find((userBadge) =>
            badge.ids.find(
              (id) => id === userBadge.badgeId && userBadge.isUnlocked,
            ),
          );
        return foundBadge?.updateDate
          ? new Date(foundBadge?.updateDate).toLocaleString()
          : "";
      }),
    );
    allResults = [
      ...allResults,
      ...usersInfo.map((info, index) => [...info, ...usersBadges[index]]),
    ];
    if (currentResult.data.admin.users.length) {
      currentPage++;
    } else {
      isMoreDataAvailable = false;
    }
  }
  return allResults;
};

export const ExportUsers = (columnNames) => async (dispatch, getState) => {
  try {
    dispatch(SetUsersLoading(true));
    const {
      paginationMetaData: { userSearchTerm, sortState },
      filters,
    } = getState().users;

    const searchVariables = {
      search: userSearchTerm,
      tagMultipleIntersectIds: getTagMultipleIntersectIds(filters),
      limit: null,
      skipCache: true,
    };

    const sortByColumns = Object.keys(sortState);
    if (sortByColumns.length > 0) {
      // currently it is possible to sort by a single column, so we get the first column from this.props.sortState
      searchVariables.orderBy = sortByColumns[0];
      searchVariables.orderMethod = sortState[sortByColumns[0]];
    }
    let resultBadges = [];
    resultBadges = await client.query({
      fetchPolicy: "no-cache",
      query: GET_BADGES,
      variables: {
        tagIds:
          filters
            .find(({ name }) => name === "MARKET")
            ?.selectedTags.map(({ value }) => value) || [],
      },
    });

    const groupedBadges =
      resultBadges?.data?.admin?.badges
        .filter((badge) => badge.degreeRef !== null)
        ?.map((badge) => ({
          id: badge.badgeId,
          title: `${badge.degreeRef?.title} - ${badge.title}`,
        }))
        .reduce((acc, curr) => {
          const found = acc.find((badge) => badge.title === curr.title);
          if (found) {
            found.ids.push(curr.id);
          } else {
            acc.push({ title: curr.title, ids: [curr.id] });
          }
          return acc;
        }, [])
        .sort((a, b) => a.title.localeCompare(b.title)) || [];

    const titleLine = [
      ...clientConfig.USER_MANAGEMENT_EXPORT_COLUMNS.filter(
        (columnConfiguration) => columnConfiguration.name !== "badges",
      ).map((columnConfiguration) => columnNames[columnConfiguration.name]),
      ...groupedBadges.map((badge) => badge.title),
    ];

    const allResults = await GetData(searchVariables, groupedBadges);
    const usersArrayContent = [titleLine, [], ...allResults];
    // export it with a secure an formatted excel export xml function
    createXml(usersArrayContent, "ExportUserManagement.xlsx");
  } catch (e) {
    dispatch(SetErrorMessage("An error occurred while exporting the users", e));
  } finally {
    dispatch(SetUsersLoading(false));
  }
};

const MAX_PROFILES_PER_QUERY = 30;

export const GetUsersProfilesData = (userIds) => async (dispatch) => {
  const chunckedUserIds = chunkArray(userIds, MAX_PROFILES_PER_QUERY);
  try {
    const promises = chunckedUserIds.map(getDefaultUserData);
    const results = await Promise.all(promises);
    const profiles = results.flat().reduce((acc, user) => {
      acc[user.userId] = getFormattedUsersProfilesData(user);
      return acc;
    }, {});
    dispatch(UpdateUsersProfilesData(profiles));
  } catch (e) {
    dispatch(
      SetErrorMessage("An error occurred while fetching users profiles data"),
    );
  }
};

export const getDefaultUserData = async (userIds) => {
  const result = await client.query({
    fetchPolicy: "network-only",
    query: GET_USERS_PROFILES_DATA,
    variables: {
      userIds: userIds,
      search: "",
    },
    notifyOnNetworkStatusChange: true,
  });
  return result.data.admin.users;
};
