import React, { useMemo } from "react";
import classNames from "classnames";
import Style from "../PollQuestionEditor.style";
import { FormattedMessage } from "react-intl";
import PollQuestionChoiceEditorReadOnly from "../../PollQuestionChoiceEditor/readOnly";
import translations from "../PollQuestionEditor.translations";
import ContentEditableUncontrolled from "../../../common/ContentEditableUncontrolled";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../../hooks/style/useThemedStyle";
import { PollQuestionPropTypes } from "../../../../constants/domain_items/poll/poll_question";

const useStyle = createUseStyles(Style);

const PollQuestionEditor = (props) => {
  const { title, description, choices } = props;
  const classes = useThemedStyle(useStyle, props);

  // manage choices
  const choiceOptions = useMemo(
    () => [
      ...choices.map((choice) => ({
        value: choice.id,
        label: choice.text,
        originalData: choice,
      })),
    ],
    [choices],
  );

  return (
    <div className={classes.container}>
      <div className={classes.innerPageContainer}>
        <div className={classes.deleteButton} />
        <div className={classes.media} />
        <div>
          <div className={classes.questionContainer}>
            <FormattedMessage {...translations.PollEmptyQuestionTitle}>
              {(placeHolder) => (
                <ContentEditableUncontrolled
                  key={title || "title"}
                  initialValue={title}
                  disabled
                  tagName="article"
                  placeholder={placeHolder[0]}
                  className={classes.questionInput}
                />
              )}
            </FormattedMessage>
          </div>
          <div className={classes.descriptionContainer}>
            <FormattedMessage {...translations.PollEmptyDescriptionTitle}>
              {(placeHolder) => (
                <ContentEditableUncontrolled
                  key={description || "description"}
                  initialValue={description}
                  disabled
                  tagName="article"
                  placeholder={placeHolder[0]}
                  className={classes.descriptionInput}
                />
              )}
            </FormattedMessage>
          </div>
        </div>
        <div className={classes.options}>
          <div className={classes.radioHeaderContainer}>
            <div
              className={classNames([
                classes.radioHeaderLabel,
                classes.choiceTextLabel,
              ])}
            >
              <FormattedMessage {...translations.ChoiceTextColumnLabel} />
            </div>
          </div>
          <div className={classes.radioContainer}>
            {choiceOptions.map(({ originalData }, index) => (
              <PollQuestionChoiceEditorReadOnly
                key={originalData.id}
                {...originalData}
                disabled
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

PollQuestionEditor.propTypes = {
  ...PollQuestionPropTypes,
};

export default PollQuestionEditor;
