import { defineMessages } from "react-intl";

export const userTranslations = defineMessages({
  BadgeSheetTitle: {
    id: "UserProfileExport.Badge.BadgeSheetTitle",
    defaultMessage: "Badges",
  },
  UserSheetTitle: {
    id: "UserProfileExport.User.UserSheetTitle",
    defaultMessage: "UserSheetTitle",
  },
  QuestionSheetTitle: {
    id: "UserProfileExport.PendingQuestions.Title",
    defaultMessage: "Pending questions",
  },
  ActivitiesSheetTitle: {
    id: "UserProfileExport.Activity.ActivitiesSheetTitle",
    defaultMessage: "Activities",
  },
  LearningSheetTitle: {
    id: "UserProfileExport.Lessons.LearningSheetTitle",
    defaultMessage: "Learning",
  },
});

export const userFactoryTranslations = defineMessages({
  // BADGE
  BadgeName: {
    id: "UserProfileExport.Badge.Name",
    defaultMessage: "Name",
  },
  BadgeCompletedPoints: {
    id: "UserProfileExport.Badge.CompletedPoints",
    defaultMessage: "Completed points",
  },
  BadgeTotalPoints: {
    id: "UserProfileExport.Badge.TotalPoints",
    defaultMessage: "Total points",
  },
  BadgeStatus: {
    id: "UserProfileExport.Badge.Status",
    defaultMessage: "Status",
  },
  BadgeScore: {
    id: "UserProfileExport.Badge.Score",
    defaultMessage: "Score",
  },

  // USER
  UserTotalPoints: {
    id: "UserProfileExport.User.TotalPoints",
    defaultMessage: "My Fragrance Training points",
  },
  UserName: {
    id: "UserProfileExport.User.Name",
    defaultMessage: "Name",
  },
  UserPosition: {
    id: "UserProfileExport.User.Position",
    defaultMessage: "Position",
  },
  UserEmail: {
    id: "UserProfileExport.User.Email",
    defaultMessage: "Email",
  },
  UserLocation: {
    id: "UserProfileExport.User.Location",
    defaultMessage: "Location",
  },
  UserLocationUrl: {
    id: "UserProfileExport.User.LocationUrl",
    defaultMessage: "LocationUrl",
  },
  UserBirthday: {
    id: "UserProfileExport.User.Birthday",
    defaultMessage: "Birthday",
  },
  UserPointOfSale: {
    id: "UserProfileExport.User.PointOfSale",
    defaultMessage: "PointOfSale",
  },
  // PENDING QUESTIONS
  Questioner: {
    id: "UserProfileExport.PendingQuestions.Questioner",
    defaultMessage: "Questionert",
  },
  Question: {
    id: "UserProfileExport.PendingQuestions.Question",
    defaultMessage: "Questiont",
  },
  NoQuestionYet: {
    id: "UserProfileExport.PendingQuestions.NoQuestionYet",
    defaultMessage: "No one pending question yet",
  },

  // ACTIVITY
  ActivitiesTitle: {
    id: "UserProfileExport.Activity.ActivitiesTitle",
    defaultMessage: "ACTIVITIES",
  },
  QuestionAsked: {
    id: "UserProfileExport.Activity.QuestionAsked",
    defaultMessage: "Question asked",
  },
  QuestionAskedToExperts: {
    id: "UserProfileExport.Activity.QuestionAskedToExperts",
    defaultMessage: "Question asked to expert",
  },
  LessonFinished: {
    id: "UserProfileExport.Activity.LessonFinished",
    defaultMessage: "Lesson finished",
  },
  LessonStarted: {
    id: "UserProfileExport.Activity.LessonStarted",
    defaultMessage: "Lesson starte",
  },
  // LEARNING
  LearningTitle: {
    id: "UserProfileExport.Lessons.Programs",
    defaultMessage: "PROGRAMS",
  },
  ProgramName: {
    id: "UserProfileExport.Lessons.ProgramName",
    defaultMessage: "Program name",
  },
  ProgressionPercentage: {
    id: "UserProfileExport.Lessons.Progression.Percentage",
    defaultMessage: "Progression percentage",
  },
  Completed: {
    id: "UserProfileExport.Lessons.Progression.Completed",
    defaultMessage: "Completed",
  },
  TotalNumberOfLessons: {
    id: "UserProfileExport.Lessons.Progression.LessonsTotal",
    defaultMessage: "Total number of lessons",
  },
  TotalNumberOfPages: {
    id: "UserProfileExport.Lessons.Progression.PagesTotal",
    defaultMessage: "Total number of pages",
  },
  LatestUpdate: {
    id: "UserProfileExport.Lessons.LatestUpdate",
    defaultMessage: "Latest Update",
  },
  Points: {
    id: "UserProfileExport.Lessons.Points",
    defaultMessage: "Points",
  },
  Lesson: {
    id: "UserProfileExport.Lessons.Lesson",
    defaultMessage: "Lesson",
  },
  Pages: {
    id: "UserProfileExport.Lessons.Pages",
    defaultMessage: "pages",
  },
  Lessons: {
    id: "UserProfileExport.Lessons.Lessons",
    defaultMessage: "lessons",
  },
});
