import React from "react";
import { REACT_APP_CLIENT_NAME } from "../../configs/client";
import DefaultPublishPage from "./PublishCard";
import PublishPage from "../publish/index";
import PublishCardShiseido from "./SHISEIDO";

const PublishCard = () => {
  switch (REACT_APP_CLIENT_NAME) {
    case "SHISEIDO":
    case "SHISEIDO-PREPROD":
    case "SHISEIDO-PROD":
      return <PublishCardShiseido />;
    default:
      // TODO: We should use this one
      // return (<PublishPage {...props} />)
      return <DefaultPublishPage />;
  }
};

export default PublishCard;
