import gql from "graphql-tag";

export const GET_USER_TAGS = gql`
  query getUserProfileData($search: String!, $userId: ID!) {
    admin {
      users(search: $search, userIds: [$userId]) {
        tags {
          tagId
          title
          label
          clusterName
          clusterId
        }
      }
    }
  }
`;
