import gql from "graphql-tag";

export const CARD_GET_TAG_IDS = gql`
  query cardGetTagIds($cardIds: [ID!]) {
    cards(cardIds: $cardIds) {
      cardId
      tags(isGranted: true) {
        tagId
      }
    }
  }
`;
