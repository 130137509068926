import { defineMessages } from "react-intl";

export const translations = defineMessages({
  Public: {
    id: "Public",
    defaultMessage: "Public",
  },
  Private: {
    id: "Private",
    defaultMessage: "Private",
  },
  EditInsightOption: {
    id: "Contents.EditInsightOption",
    defaultMessage: "Edit insight",
  },
  EditCardOption: {
    id: "Contents.EditCardOption",
    defaultMessage: "Edit card",
  },
  EditBattleOption: {
    id: "Contents.EditBattleOption",
    defaultMessage: "Edit battle",
  },
  EditOption: {
    id: "Contents.EditOption",
    defaultMessage: "Edit",
  },
  NotificationModalTitle: {
    id: "Contents.NotificationModalTitle",
    defaultMessage: "Send a Notification",
  },
  NotificationModalDescription: {
    id: "Contents.NotificationModalDescription",
    defaultMessage:
      "Send a Push Notification to all users that this content is broadcast to. " +
      "This notification will only be sent once",
  },
  NotificationModalMessagePlaceholder: {
    id: "Contents.NotificationModalMessagePlaceholder",
    defaultMessage: "There’s something new to see in Beauty Genius",
  },
  DeleteMessage: {
    id: "Contents.DeleteMessage",
    defaultMessage: "Are you sure you want to delete this item{mark}",
  },
  NoContentMessage: {
    id: "Contents.NoContentMessage",
    defaultMessage: "There is no content created in this language yet",
  },
});
