export default (theme) => ({
  container: {},
  completionRate: {
    marginTop: 0,
    marginBottom: -2,
    fontFamily: theme.Fonts.baseFontFamily,
    fontSize: 10,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: theme.CompletionRate.completionRateColor,
  },
  progressBar: {
    width: "100%",
    height: "5px!important",
    borderRadius: "7px!important",
    backgroundColor: theme.CompletionRate.progressBarBackgroundColor,
  },
});
