export default {
  contentsContainer: {
    maxWidth: 1000,
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
};
