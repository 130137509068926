import {
  SET_PUBLISH_LOADED,
  SET_PUBLISH_DOCUMENT,
  SET_DOCUMENT_LOCATIONS,
  UPDATE_PUBLISH_DOCUMENT,
  UPDATE_LOCATIONS,
  DELETE_TAG,
  UPDATE_LESSON_LOCATIONS_SKIP,
  UPDATE_ATTACHMENTS,
} from "constants/store/publish";

const initialState = {
  loaded: false,
  id: null,
  title: null,
  summary: null,
  workflowScheduledIn: null,
  workflowScheduledOut: null,
  workflow: null,
  zones: [],
  countries: [],
  brand: [],
  retailer: [],
  associated: [],
  universe: [],
  isPivate: false,
  picCover: null,
  picLessonRecap: null,
  lessonRecap: null,
  picThumb: null,
  videoCover: null,
  objectsCore: [],
  locations: [],
  lessonLocationsSkip: 0,
  attachment: [],
};

const deleteTag = (state, { tagIds }) => {
  const zones = state.zones.filter((tag) => !tagIds.includes(tag.tagId));
  const countries = state.countries.filter(
    (tag) => !tagIds.includes(tag.tagId),
  );
  const brand = state.brand.filter((tag) => !tagIds.includes(tag.tagId));
  const retailer = state.retailer.filter((tag) => !tagIds.includes(tag.tagId));
  const associated = state.associated.filter(
    (tag) => !tagIds.includes(tag.tagId),
  );
  const universe = state.universe.filter((tag) => !tagIds.includes(tag.tagId));
  const locations = state.locations.filter(({ id }) => !tagIds.includes(id));

  return {
    ...state,
    zones,
    countries,
    brand,
    retailer,
    associated,
    universe,
    locations,
  };
};

const updateAttachments = (state, { add, remove }) => {
  const attachment = state.attachment
    ? state.attachment.filter(({ id }) => !remove.includes(id))
    : [];
  if (add && add.length) {
    add.map((file) => attachment.push(file));
  }

  return { ...state, attachment };
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;

  switch (type) {
    case SET_PUBLISH_LOADED:
      return {
        ...state,
        loaded: data,
      };
    case SET_PUBLISH_DOCUMENT:
      return {
        ...state,
        ...data,
      };
    case SET_DOCUMENT_LOCATIONS:
      return {
        ...state,
        ...data,
      };
    case UPDATE_PUBLISH_DOCUMENT:
      return {
        ...state,
        ...data,
      };
    case UPDATE_LOCATIONS:
      return {
        ...state,
        ...data,
      };
    case DELETE_TAG:
      return deleteTag(state, data);
    case UPDATE_LESSON_LOCATIONS_SKIP:
      return {
        ...state,
        lessonLocationsSkip: state.lessonLocationsSkip + 5,
      };
    case UPDATE_ATTACHMENTS:
      return updateAttachments(state, data);
    default:
      return state;
  }
}
