import React, { useCallback } from "react";
import { translations } from "../../PublishInsight.translation";
import { FormattedMessage } from "react-intl";
import useThemedStyle from "../../../../hooks/style/useThemedStyle";
import { usePublishInsightStyle } from "../../PublishInsight.style";
import { useDispatch, useSelector } from "react-redux";
import { SetInsightPrivacy } from "../../../../store/insight/actions";
import { useCurrentInsightId } from "../../hooks/useCurrentInsightId";
import { useCurrentInsightLang } from "../../hooks/useCurrentInsightLang";
import SwitchButton from "../../../../components/common/SwitchButton";

const selectors = {
  isPublic: (state) => state.insights.publishInsight.isPublic,
};

const EditInsightPrivacy = (props) => {
  const dispatch = useDispatch();
  const isPublic = useSelector(selectors.isPublic);
  const currentInsightId = useCurrentInsightId();
  const currentInsightLang = useCurrentInsightLang();
  const classes = useThemedStyle(usePublishInsightStyle, props);

  const UpdatePublicState = useCallback(
    (isPublic) =>
      dispatch(
        SetInsightPrivacy(currentInsightId, currentInsightLang, isPublic),
      ),
    [currentInsightId, currentInsightLang, dispatch],
  );

  return (
    <div className={classes.publicSwitcherContainer}>
      <div className={classes.publicLabel}>
        <FormattedMessage {...translations.PublicButtonLabel} />
      </div>
      <SwitchButton isOn={!!isPublic} handleToggle={UpdatePublicState} />
    </div>
  );
};

export default React.memo(EditInsightPrivacy);
