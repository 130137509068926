import gql from "graphql-tag";

export const DEGREE_COPY_ALL_FOR_LANGUAGE = gql`
  mutation degreeCopyAll(
    $degreeId: ID
    $sourceLang: LangEnum
    $destLang: LangEnum!
  ) {
    admin {
      degree(degreeId: $degreeId, lang: $sourceLang) {
        copyAllDegree(lang: $destLang) {
          degreeId
        }
      }
    }
  }
`;
