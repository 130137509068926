import gql from "graphql-tag";

export const CARD_SET_DATA = gql`
  mutation cardSetData(
    $cardId: ID
    $lang: LangEnum
    $type: ContentTypeEnum!
    $data: String!
  ) {
    admin {
      card(cardId: $cardId, lang: $lang) {
        set(type: $type, data: $data)
      }
    }
  }
`;
