export const InfoTooltipStyle = (theme) => ({
  rootContainer: {
    position: "relative",
    fontFamily: theme.FontFamily,
  },
  infoIcon: {
    color: theme.KpiInfoTooltipStyle.infoIconColor,
    fontSize: 24,
    cursor: "pointer",
  },
  details: {
    minWidth: 440,
    position: "absolute",
    top: 34,
    left: 34,
    backgroundColor: theme.KpiInfoTooltipStyle.detailsBackgroundColor,
    borderRadius: 10,
    padding: 20,
    boxShadow: "0 10px 20px 0 rgba(0, 0, 0, 0.05)",
    color: theme.KpiInfoTooltipStyle.detailsColor,
    fontWeight: 500,
    fontSize: 14,
    zIndex: 1,
  },
  title: {
    textAlign: "center",
  },
});
