import gql from "graphql-tag";

export const DEGREES_GET_HISTORY = gql`
  query degreesGetHistory(
    $statusDeleted: DocWorkflowEnum
    $statusArchived: DocWorkflowEnum
  ) {
    admin {
      deleted: degrees(workflow: $statusDeleted) {
        versions {
          degreeId
          workflow
          title
          summary
          lang
          picCover
          author {
            userId
          }
          tags {
            tagId
            title
            clusterId
          }
        }
      }
      archived: degrees(workflow: $statusArchived) {
        versions {
          degreeId
          workflow
          title
          summary
          lang
          picCover
          author {
            userId
          }
          tags {
            tagId
            title
            clusterId
          }
        }
      }
    }
  }
`;
