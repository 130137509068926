import gql from "graphql-tag";

export const ADD_ATTACHMENT = gql`
  mutation addAttachment(
    $lessonId: ID
    $lang: LangEnum
    $link: String
    $name: String
    $uploadId: ID
    $type: ChatAttachementTypeEnum
  ) {
    admin {
      lesson(lessonId: $lessonId, lang: $lang) {
        attachment {
          add(link: $link, name: $name, type: $type, uploadId: $uploadId)
        }
      }
    }
  }
`;
