export const SwitchButtonStyles = ({ SwitchButton }) => {
  const commonStyles = {
    content: "",
    position: "absolute",
    top: 5,
    left: 0,
    width: 20,
    height: 20,
    borderRadius: 45,
    transition: "0.2s",
    background: SwitchButton.backgroundColor,
    boxShadow: `0 0 2px 0 ${SwitchButton.boxShadowColor}`,
  };

  return {
    switchCheckbox: {
      height: 0,
      width: 0,
      visibility: "hidden",
      "&:checked + .react-switch-label .react-switch-button": {
        left: "calc(100% - 0px)",
        transform: "translateX(-100%)",
      },
    },
    switchLabel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
      borderRadius: 100,
      position: "relative",
      transition: "background-color .2s",
      ...commonStyles,
      width: 40,
      backgroundColor: (props) =>
        props.isOn ? SwitchButton.labelColorOn : SwitchButton.labelColorOff,
      height: 10,
    },
    switchButton: {
      ...commonStyles,
      boxShadow: `0 0 8px 0 ${SwitchButton.buttonBoxShadowColor}`,
      backgroundColor: SwitchButton.buttonBackgroundColor,
      top: -5,
    },
    switchContainer: {
      position: "relative",
      width: 40,
    },
  };
};
