import { SetErrorMessage } from "../notification/actions";
import {
  UPDATE_QUICK_QUIZ_DATA,
  SET_QUICK_QUIZ_ID,
  SET_QUICK_QUIZ_LANGUAGE_ID,
  RESET_QUICK_QUIZ_DATA,
  SET_QUICK_QUIZ_MASTER_FLAG,
} from "../../constants/store/quickQuiz";
import client from "../../graphql/client";
import { GET_CARD_BY_ID } from "../../graphql/remote/cards/queries";
import { QuickQuizTemplate } from "../../utils/ui-generator/quickQuiz.template";
import {
  CardDocumentToQuickQuizData,
  EncodeQuickQuizData,
} from "./transformers";
import { CARD_UPDATE_PAGE_DATA } from "../../graphql/remote/cards/mutations";
import clientConfig from "../../configs/client";

export const ResetPageData = (data) => (dispatch) => {
  dispatch({
    type: RESET_QUICK_QUIZ_DATA,
    data,
  });
};

export const SetQuickQuizId = (data) => (dispatch) => {
  dispatch({
    type: SET_QUICK_QUIZ_ID,
    data,
  });
};

export const SetQuickQuizLanguageId = (data) => (dispatch) => {
  dispatch({
    type: SET_QUICK_QUIZ_LANGUAGE_ID,
    data,
  });
};

export const SetQuickQuizMasterFlag = (data) => (dispatch) => {
  dispatch({
    type: SET_QUICK_QUIZ_MASTER_FLAG,
    data,
  });
};

export const UpdateData = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_QUICK_QUIZ_DATA,
    data,
  });
};

export const GetPageData = (quickQuizId, lang) => async (dispatch) => {
  try {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_CARD_BY_ID,
      variables: { cardId: quickQuizId },
    });

    const currentLanguageVersion = result.data.admin.cards[0].versions.find(
      (x) => x.lang === lang,
    );
    if (currentLanguageVersion.card.template !== QuickQuizTemplate.template) {
      dispatch(SetErrorMessage(`Invalid quick quiz id ${quickQuizId}`));
      return;
    }

    if (
      result.data.admin.cards[0].versions
        .find((x) => x.lang === lang)
        .tags.findIndex(
          (x) => parseInt(x.tagId) === clientConfig.MASTER_DEGREE_TAG_ID,
        ) >= 0
    ) {
      dispatch(SetQuickQuizMasterFlag(true));
    } else {
      dispatch(SetQuickQuizMasterFlag(false));
    }

    dispatch(
      UpdateData(
        CardDocumentToQuickQuizData(
          result.data.admin.cards[0],
          currentLanguageVersion,
        ),
      ),
    );
    dispatch(SetQuickQuizId(quickQuizId));
    dispatch(SetQuickQuizLanguageId(lang));
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while fetching the quick quiz data for quick quiz ${quickQuizId}`,
        e,
      ),
    );
  }
};

export const SaveCardData =
  (cardId, lang, pageId, rawCardData) => async (dispatch) => {
    const errorMessage =
      "An error occurred while updating the quick quiz card data";
    try {
      const data = EncodeQuickQuizData(rawCardData);
      const result = await client.mutate({
        mutation: CARD_UPDATE_PAGE_DATA,
        variables: { cardId, lang, data, pageId },
      });
      if (!result.data.admin.card.pages.data) {
        SetErrorMessage(errorMessage);
      }
    } catch (e) {
      dispatch(SetErrorMessage(errorMessage, e));
    }
  };
