import gql from "graphql-tag";

export const SET_BADGE_TO_LESSONS = gql`
  mutation setBadgeToLessons($badgeIds: [Int!], $lessonIds: [Int!]!) {
    admin {
      badges(badgeIds: $badgeIds) {
        setToLessons(lessonIds: $lessonIds)
      }
    }
  }
`;
