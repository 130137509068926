export default ({ PublishPollScreen, FontFamily }) => {
  const customInputStyle = {
    outline: "none",
    border: "none!important",
    cursor: "pointer",
    backgroundColor: PublishPollScreen.customInputStyle.backgroundColor,
    padding: "0!important",
    fontSize: "22px!important",
    fontWeight: "bold!important",
    letterSpacing: "0.07!important",
    color: `${PublishPollScreen.customInputStyle.color}!important`,
    "&:focus": {
      border: "none",
    },
    "&::placeholder": {
      color: `${PublishPollScreen.customInputStyle.color}!important`,
    },
  };

  return {
    rootContainer: {
      fontFamily: FontFamily,
      display: "flex",
      justifyContent: "center",
    },
    content: {
      paddingTop: 116,
      width: 1214,
      display: "flex",
      flexDirection: "row",
    },
    rightSide: {
      width: 533,
    },
    photo: {
      marginRight: 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 414,
      height: 736,
      backgroundImage: ({ publishData }) => `url('${publishData.picCover}')`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundColor: PublishPollScreen.photo.backgroundColor,
    },
    uploadBtn: {
      width: 108,
      height: 36,
      borderRadius: 3,
      cursor: "pointer",
      backgroundColor: PublishPollScreen.uploadBtn.backgroundColor,
      border: `solid 1px ${PublishPollScreen.uploadBtn.borderColor}`,
      letterSpacing: 0.07,
      color: PublishPollScreen.uploadBtn.color,
      fontSize: 14,
      outline: "none",
    },
    close: {
      textAlign: "right",
      "& > span": {
        fontSize: 17,
        color: PublishPollScreen.close.color,
        cursor: "pointer",
      },
      marginBottom: 75,
    },
    pollTitle: customInputStyle,
    pollDescription: {
      ...customInputStyle,
      fontWeight: 300,
      fontSize: 17,
      letterSpacing: 0.06,
      marginBottom: 45,
    },
    title: {
      marginBottom: 10,
      fontSize: 16,
      color: PublishPollScreen.title.color,
    },
    simpleDropdown: {
      marginRight: "0!important",
      height: "unset!important",
      marginBottom: 30,
    },
    tagSelectContainer: {
      position: "relative",
      marginBottom: 30,
    },
    tagSelectContainerError: {
      position: "relative",
      "& > div": {
        border: "1px solid #c60000",
        borderRadius: 2,
      },
    },
    languages: {
      display: "inline-block",
      "& > div": {
        float: "left",
      },
    },
    dateSelectors: {
      marginBottom: 30,
    },
    postingDate: {
      display: "flex",
      width: "100%",
      marginTop: 45,
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      zIndex: 10,
    },
    postingDateButton: {
      paddingRight: 10,
    },
    simpleInput: {
      marginBottom: 30,
    },
    brandAndRetailer: {
      display: "inline-block",
      width: "100%",
      "& > div": {
        width: 255,
      },
      "& > div:nth-of-type(1)": {
        float: "left",
      },
      "& > div:nth-of-type(2)": {
        float: "right",
      },
    },
  };
};
