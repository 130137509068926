import * as constants from "../../constants/store/insights";
import { DATE_SELECTOR_TYPE } from "../../configs/constants";
import { RESET_STORE } from "../../constants/store/auth";
import clientConfig from "../../configs/client";

const initialState = {
  currentLanguage: clientConfig.DEFAULT_LANGUAGE_CODE,
  currentInsightId: null,
  currentPageId: null,
  currentOpenedMarker: {
    pageId: null,
    slideIndex: null,
    markerIndex: null,
  },
  currentInsightPages: [],
  publishInsight: {
    id: "",
    lang: "",
    dateSelectorType: DATE_SELECTOR_TYPE.NOW,
    publishDate: "",
    languages: [],
    tags: [],
    predictTags: [],
    countries: [],
    userCategories: [],
    brands: [],
    retailers: [],
    roles: [],
    title: "",
    summary: "",
    cover: "",
    picThumb: "",
    tagsIsEditable: true,
    isScreenReady: false,
    isPublic: false,
    workflow: null,
  },
  visibleTagSelector: null,
  activeMenuInsightId: undefined,
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.SET_CURRENT_INSIGHT_LANGUAGE:
      return {
        ...state,
        currentLanguage: data,
      };
    case constants.SET_CURRENT_INSIGHT_ID:
      return {
        ...state,
        currentInsightId: data,
      };
    case constants.SET_CURRENT_INSIGHT_PAGEID:
      return {
        ...state,
        currentPageId: data,
      };
    case constants.SET_CURRENT_INSIGHT_PAGES:
      return {
        ...state,
        currentInsightPages: data,
      };
    case constants.UPDATE_CURRENT_INSIGHT_PAGE:
      return {
        ...state,
        currentInsightPages: state.currentInsightPages.map((page) =>
          page.pageId === data.pageId
            ? {
                ...page,
                ...data,
              }
            : page,
        ),
      };
    case constants.ADD_CURRENT_INSIGHT_PAGE:
      return {
        ...state,
        currentInsightPages: [...state.currentInsightPages, data],
      };
    case constants.REMOVE_CURRENT_INSIGHT_PAGE:
      return {
        ...state,
        currentInsightPages: state.currentInsightPages.filter(
          (page) => page.pageId !== data,
        ),
      };
    case constants.UPDATE_CURRENT_OPENED_MARKER:
      return {
        ...state,
        currentOpenedMarker: data,
      };
    case constants.SET_PUBLISH_INSIGHT_INFO:
      return {
        ...state,
        publishInsight: data,
      };
    case constants.UPDATE_PUBLISH_INSIGHT_INFO:
      return {
        ...state,
        publishInsight: {
          ...state.publishInsight,
          ...data,
        },
      };
    case constants.UPDATE_VISIBLE_TAG_SELECTOR:
      return {
        ...state,
        visibleTagSelector: data,
      };
    case constants.SET_ACTIVE_MENU_FOR_INSIGHT_ID:
      return {
        ...state,
        activeMenuInsightId: data,
      };
    case constants.SET_INSIGHT_MASTER_FLAG:
      return {
        ...state,
        isMasterDocument: data,
      };
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
