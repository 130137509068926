import React from "react";
import ClassicModal from "components/common/ClassicModal/ClassicModal";
import { translations } from "./CropWarningModal.translations";

const CropWarningModal = (props) => (
  <ClassicModal
    {...props}
    buttonLabel={translations.ModalButtonLabel}
    modalMessage={translations.ModalMessage}
  />
);

export default CropWarningModal;
