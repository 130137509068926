import uuidv4 from "uuid/dist/v4";
import {
  SET_NINJA_QUESTIONS,
  SET_EDITED_NINJA_PAGE_ID,
  SET_NINJA_MASTER_FLAG,
  SET_NINJA_DOCUMENT_ID,
  SET_NINJA_DOCUMENT_LANG,
  SET_NINJA_INTRO,
} from "../../constants/store/editNinja";
import { SetErrorMessage } from "../notification/actions";
import client from "../../graphql/client";
import {
  NinjaDocumentToNinjaItem,
  NinjaItemToNinjaDocument,
} from "./transformers";
import { SetFetchIndicator } from "../common/actions";
import { GET_NINJA_BY_ID } from "../../graphql/remote/ninja/queries/GetNinjaInfo.graphql";
import { EDIT_NINJA_PAGE } from "../../graphql/remote/ninja/mutations/EditNinjaPage.graphql";
import clientConfig from "../../configs/client";
import { decodeNewLines } from "../../utils/string";

const decodeContent = (encodedContent) => {
  const contentObject = JSON.parse(encodedContent);

  const { text, title, activityDescription, background, questions } =
    contentObject.data;

  return {
    ...contentObject,
    data: {
      ...contentObject.data,
      text: decodeNewLines(text),
      intro: {
        title: title,
        description: activityDescription,
        theme: background.theme,
        image: background.url,
      },
      questions: questions.map((question) => ({
        id: question.id,
        text: decodeNewLines(question.text),
        choices: question.answers.map(({ id, answer, isTrue, type, url }) => ({
          id: id,
          text: decodeNewLines(answer),
          isTrue,
          type: decodeNewLines(type),
          url: url,
        })),
      })),
    },
  };
};

export const SetEditedNinjaPageId = (data) => (dispatch) =>
  dispatch({
    type: SET_EDITED_NINJA_PAGE_ID,
    data,
  });

export const SetNinjaQuestions = (data) => (dispatch) =>
  dispatch({
    type: SET_NINJA_QUESTIONS,
    data,
  });

export const SetNinjaDocumentId = (data) => (dispatch) =>
  dispatch({
    type: SET_NINJA_DOCUMENT_ID,
    data,
  });

export const SetNinjaDocumentLang = (data) => (dispatch) =>
  dispatch({
    type: SET_NINJA_DOCUMENT_LANG,
    data,
  });

export const SetNinjaIntro = (data) => (dispatch) =>
  dispatch({
    type: SET_NINJA_INTRO,
    data,
  });

export const SetNinjaMasterFlag = (data) => (dispatch) =>
  dispatch({
    type: SET_NINJA_MASTER_FLAG,
    data,
  });

export const SetNinjaFromData =
  (rawData, documentId, language) => (dispatch) => {
    dispatch(SetNinjaQuestions([]));
    const data = decodeContent(rawData);

    dispatch(SetNinjaQuestions(data.data.questions));
    dispatch(SetNinjaDocumentId(documentId));
    dispatch(SetNinjaDocumentLang(language));
    dispatch(SetNinjaIntro(data.data.intro));
  };

export const GetNinjaInfo = (ninjaId, language) => async (dispatch) => {
  dispatch(SetFetchIndicator(true));
  dispatch(SetEditedNinjaPageId(null));
  dispatch(SetNinjaQuestions([]));

  try {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_NINJA_BY_ID,
      variables: {
        ninjaId,
      },
    });

    const currentLanguageNinjaVersion =
      result.data.admin.ninjas[0].versions.find(
        (version) => version.lang === language,
      );
    const ninjaItem = NinjaDocumentToNinjaItem(currentLanguageNinjaVersion);

    if (
      result.data.admin.ninjas[0].versions
        .find((version) => version.lang === language)
        .tags.findIndex(
          (x) => parseInt(x.tagId) === clientConfig.MASTER_DEGREE_TAG_ID,
        ) >= 0
    ) {
      dispatch(SetNinjaMasterFlag(true));
    } else {
      dispatch(SetNinjaMasterFlag(false));
    }

    dispatch(SetEditedNinjaPageId(ninjaItem.editedPageId));
    dispatch(SetNinjaQuestions(ninjaItem.questions));
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while fetching the ninja info for ninja with id ${ninjaId}`,
        e,
      ),
    );
  }

  dispatch(SetFetchIndicator(false));
};

export const AddNinjaQuestionBefore = (id) => async (dispatch, getState) => {
  const { editNinja, documentId, lang } = getState();
  let { questions, editedPageId } = editNinja;

  const index = questions.findIndex((question) => question.id === id);
  const addedQuestion = {
    timer: 30,
    id: uuidv4(),
    text: "",
    choices: [],
    description: null,
    image: null,
    originalData: {},
    type: null,
  };
  questions.splice(index, 0, addedQuestion);
  questions = [...questions];

  dispatch(SetNinjaQuestions(questions));

  try {
    await client.mutate({
      mutation: EDIT_NINJA_PAGE,
      variables: {
        ninjaId: documentId,
        lang,
        pageId: editedPageId,
        data: NinjaItemToNinjaDocument({
          questions,
        }),
      },
    });
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while updating the questions of the ninja ${documentId}`,
        e,
      ),
    );
  }
};

export const onRemove = (id) => async (dispatch, getState) => {
  const { editNinja, documentId, lang } = getState();
  let { questions, editedPageId } = editNinja;
  const index = questions.findIndex((question) => question.id === id);
  questions.splice(index, 1);
  questions = [...questions];

  dispatch(SetNinjaQuestions(questions));

  try {
    await client.mutate({
      mutation: EDIT_NINJA_PAGE,
      variables: {
        ninjaId: documentId,
        lang,
        pageId: editedPageId,
        data: NinjaItemToNinjaDocument({
          questions,
        }),
      },
    });
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while updating the questions of the ninja ${documentId}`,
        e,
      ),
    );
  }
};

export const changeTitle = (id, value) => async (dispatch, getState) => {
  const { editNinja, documentId, lang } = getState();
  const { questions, editedPageId } = editNinja;
  const question = questions.find((question) => question.id === id);
  question.text = value;

  dispatch(SetNinjaQuestions(questions));

  try {
    await client.mutate({
      mutation: EDIT_NINJA_PAGE,
      variables: {
        ninjaId: documentId,
        lang,
        pageId: editedPageId,
        data: NinjaItemToNinjaDocument({
          questions,
        }),
      },
    });
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while updating the questions of the ninja ${documentId}`,
        e,
      ),
    );
  }
};

export const addChoice = (id) => async (dispatch, getState) => {
  const { editNinja, documentId, lang } = getState();
  let { questions, editedPageId } = editNinja;
  const addedChoice = {
    id: uuidv4(),
    isTrue: false,
    text: "",
    type: "text",
  };

  const question = questions.find((question) => question.id === id);
  question.choices = [...question.choices, addedChoice];
  questions = [...questions];

  dispatch(SetNinjaQuestions(questions));

  try {
    await client.mutate({
      mutation: EDIT_NINJA_PAGE,
      variables: {
        ninjaId: documentId,
        lang,
        pageId: editedPageId,
        data: NinjaItemToNinjaDocument({
          questions,
        }),
      },
    });
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while updating the questions of the ninja ${documentId}`,
        e,
      ),
    );
  }
};

export const removeChoice = (id, choiceIndex) => async (dispatch, getState) => {
  const { editNinja, documentId, lang } = getState();
  let { questions, editedPageId } = editNinja;

  const question = questions.find((question) => question.id === id);
  question.choices = question.choices.filter(
    (choice) => choice.id !== choiceIndex,
  );
  questions = [...questions];

  dispatch(SetNinjaQuestions(questions));

  try {
    await client.mutate({
      mutation: EDIT_NINJA_PAGE,
      variables: {
        ninjaId: documentId,
        lang,
        pageId: editedPageId,
        data: NinjaItemToNinjaDocument({
          questions,
        }),
      },
    });
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while updating the questions of the ninja ${documentId}`,
        e,
      ),
    );
  }
};

export const onChangeCorrect =
  (id, choiceIndex) => async (dispatch, getState) => {
    const { editNinja, documentId, lang } = getState();
    let { questions, editedPageId } = editNinja;

    const question = questions.find((question) => question.id === id);
    const choice = question.choices.find((choice) => choice.id === choiceIndex);
    choice.isTrue = !choice.isTrue;
    question.choices = [...question.choices];
    questions = [...questions];

    dispatch(SetNinjaQuestions(questions));

    try {
      await client.mutate({
        mutation: EDIT_NINJA_PAGE,
        variables: {
          ninjaId: documentId,
          lang,
          pageId: editedPageId,
          data: NinjaItemToNinjaDocument({
            questions,
          }),
        },
      });
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while updating the questions of the ninja ${documentId}`,
          e,
        ),
      );
    }
  };

export const changeChoice =
  (id, choiceIndex, value) => async (dispatch, getState) => {
    const { editNinja, documentId, lang } = getState();
    let { questions, editedPageId } = editNinja;

    const question = questions.find((question) => question.id === id);
    const choice = question.choices.find((choice) => choice.id === choiceIndex);
    choice.text = value;

    question.choices = [...question.choices];
    questions = [...questions];

    dispatch(SetNinjaQuestions(questions));

    try {
      await client.mutate({
        mutation: EDIT_NINJA_PAGE,
        variables: {
          ninjaId: documentId,
          lang,
          pageId: editedPageId,
          data: NinjaItemToNinjaDocument({
            questions,
          }),
        },
      });
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while updating the questions of the ninja ${documentId}`,
          e,
        ),
      );
    }
  };

export const changeImage =
  (id, choiceIndex, url) => async (dispatch, getState) => {
    const { editNinja, documentId, lang } = getState();
    let { questions, editedPageId } = editNinja;

    const question = questions.find((question) => question.id === id);
    const choice = question.choices.find((choice) => choice.id === choiceIndex);
    choice.url = url;

    question.choices = [...question.choices];
    questions = [...questions];

    dispatch(SetNinjaQuestions(questions));

    try {
      await client.mutate({
        mutation: EDIT_NINJA_PAGE,
        variables: {
          ninjaId: documentId,
          lang,
          pageId: editedPageId,
          data: NinjaItemToNinjaDocument({
            questions,
          }),
        },
      });
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while updating the questions of the ninja ${documentId}`,
          e,
        ),
      );
    }
  };

export const changeType = (id, choiceIndex) => async (dispatch, getState) => {
  const { editNinja, documentId, lang } = getState();
  let { questions, editedPageId } = editNinja;

  const question = questions.find((question) => question.id === id);
  const choice = question.choices.find((choice) => choice.id === choiceIndex);

  if (choice.type === "text") {
    choice.type = "image";
  } else {
    choice.type = "text";
  }

  question.choices = [...question.choices];
  questions = [...questions];

  dispatch(SetNinjaQuestions(questions));

  try {
    await client.mutate({
      mutation: EDIT_NINJA_PAGE,
      variables: {
        ninjaId: documentId,
        lang,
        pageId: editedPageId,
        data: NinjaItemToNinjaDocument({
          questions,
        }),
      },
    });
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while updating the questions of the ninja ${documentId}`,
        e,
      ),
    );
  }
};

export const AddNinjaQuestionAfter = (id) => async (dispatch, getState) => {
  const { editNinja, documentId, lang } = getState();
  let { questions, editedPageId } = editNinja;

  const index = questions.findIndex((question) => question.id === id);
  const addedQuestion = {
    timer: 30,
    id: uuidv4(),
    text: "",
    choices: [],
    description: null,
    image: null,
    originalData: {},
    type: null,
  };
  questions.splice(index + 1, 0, addedQuestion);
  questions = [...questions];

  dispatch(SetNinjaQuestions(questions));

  try {
    await client.mutate({
      mutation: EDIT_NINJA_PAGE,
      variables: {
        ninjaId: documentId,
        lang,
        pageId: editedPageId,
        data: NinjaItemToNinjaDocument({
          questions,
        }),
      },
    });
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while updating the questions of the ninja ${documentId}`,
        e,
      ),
    );
  }
};
