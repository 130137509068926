import { defineMessages } from "react-intl";

export const translations = defineMessages({
  NoContentText: {
    id: "BattlePublishedTable.NoContentText",
    defaultMessage: "No data yet",
  },
  BattleNameColumnTitle: {
    id: "BattlePublishedTable.BattleNameColumnTitle",
    defaultMessage: "Battles",
  },
  NumberOfParticipatedColumnTitle: {
    id: "BattlePublishedTable.NumberOfParticipatedColumnTitle",
    defaultMessage: "Number of users participated",
  },
  AverageNumberOfPointsColumnTitle: {
    id: "BattlePublishedTable.AverageNumberOfPointsColumnTitle",
    defaultMessage: "Average number of points",
  },
});
