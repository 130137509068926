import gql from "graphql-tag";

export const INSIGHT_DELETE_PAGE = gql`
  mutation insightDeletePage($insightId: ID!, $lang: LangEnum, $pageId: ID) {
    admin {
      insight(insightId: $insightId, lang: $lang) {
        pages(pageId: $pageId) {
          delete
        }
      }
    }
  }
`;
