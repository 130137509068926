import { defineMessages } from "react-intl";

export const translations = defineMessages({
  BattleTitlePlaceholder: {
    id: "PublishMasterBattle.BattleTitlePlaceholder",
    defaultMessage: "Enter your title",
  },
  UploadImage: {
    id: "PublishMasterBattle.UploadImage",
    defaultMessage: "Upload Image",
  },
  BattleDescriptionPlaceholder: {
    id: "PublishMasterBattle.BattleDescriptionPlaceholder",
    defaultMessage: "Describe your battle",
  },
  TimePerQuestion: {
    id: "PublishMasterBattle.TimePerQuestion",
    defaultMessage: "Time per question",
  },
  AddCountry: {
    id: "PublishMasterBattle.AddCountry",
    defaultMessage: "Add a country",
  },
  PublicationCountries: {
    id: "PublishMasterBattle.PublicationCountries",
    defaultMessage: "Publication Countries",
  },
  AssociatedTags: {
    id: "PublishMasterBattle.AssociatedTags",
    defaultMessage: "Associated Tags",
  },
  AddTag: {
    id: "PublishMasterBattle.AddTag",
    defaultMessage: "Add a tag",
  },
  Language: {
    id: "PublishMasterBattle.Language",
    defaultMessage: "Language",
  },
  StartPostingDateLabel: {
    id: "PublishMasterBattle.StartPostingDateLabel",
    defaultMessage: "Start posting date",
  },
  EndPostingDateLabel: {
    id: "PublishMasterBattle.EndPostingDateLabel",
    defaultMessage: "End posting date",
  },
  NoEndPostingDateLabel: {
    id: "PublishMasterBattle.NoEndPostingDateLabel",
    defaultMessage: "No end posting date",
  },
  ScheduleButtonText: {
    id: "PublishMasterBattle.ScheduleButtonText",
    defaultMessage: "Schedule to publish",
  },
  CancelScheduleButtonText: {
    id: "PublishMasterBattle.CancelScheduleButtonText",
    defaultMessage: "Cancel scheduling",
  },
  ScorePerQuestion: {
    id: "PublishMasterBattle.ScorePerQuestion",
    defaultMessage: "Score per question",
  },
  ScorePlaceholder: {
    id: "PublishMasterBattle.ScorePlaceholder",
    defaultMessage: "Add score",
  },
  BrandLabel: {
    id: "PublishMasterBattle.BrandLabel",
    defaultMessage: "Brand",
  },
  AddBrandPlaceholder: {
    id: "PublishMasterBattle.AddBrandPlaceholder",
    defaultMessage: "Add a brand",
  },
  IndicateBrandsWarning: {
    id: "PublishMasterBattle.IndicateBrandsWarning",
    defaultMessage:
      "Please indicate which brand or brands this content should be associated with before publishing.",
  },
});
