import gql from "graphql-tag";

export const PUBLISH_LESSON_DEFAULT = gql`
  fragment LessonFields on AdminLesson {
    lessonId
    versions {
      isPrivate
      title
      lang
      summary
      lessonRecap
      picLessonRecap
      picCard
      picCover
      videoCover
      picThumb
      tags {
        tagId
        title
        clusterId
        parentTag {
          tagId
        }
      }
      predictTags {
        tagId
        title
        clusterId
      }
      author {
        userId
      }
      pages {
        pageId
        index
        template
        data
      }
      workflow
      workflowScheduledIn
      workflowScheduledOut
      attachment {
        uploadId
        name
        link
        type
      }
    }
  }
`;
