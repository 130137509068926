/* global File */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ImageCropperStyle } from "./imageCropper.style";
import { Button, ButtonSize, ButtonVariation, StylelessModal } from "genius-ui";
import TransparentButton from "../TransparentButton";
import { CROP_VARIATION, ENV } from "../../../configs/constants";
import { FormattedMessage } from "react-intl";
import Cropper from "react-cropper";
import "./Cropper.scss";
import { dataURItoBlob } from "../../../utils/file";
import { translations } from "./ImageCropper.translations";
import withThemedStyle from "../../hoc/withThemedStyle";
import MediaService from "../../../core/mediaService";

const mediaService = new MediaService();

const CropExit = ({ onCancel }) => {
  const handleEscape = (event) => event.key === "Escape" && onCancel();

  useEffect(() => {
    window.addEventListener("keydown", handleEscape, true);
    return () => window.removeEventListener("keydown", handleEscape, true);
  });

  return <></>;
};

class ImageCropper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCropperReady: false,
      cropperContainerSize: {
        width: null,
        height: null,
      },
      isModalOpen: true,
    };

    this.cropperElement = React.createRef();
  }

  onHide = () => {
    this.props.onCancel();
  };

  uploadFile = async (file) => {
    try {
      const result = await mediaService.uploadMedia(file);
      if (result.status === 200) {
        this.props.onCropImage(result.data, this.props.id);
      }
    } catch (e) {
      if (ENV.IS_DEV) {
        console.error("Couldn't upload file", e);
      }
    }
  };

  onCropperReady = (e) => {
    this.setState({
      isCropperReady: true,
      cropperContainerSize: {
        width: e.target.cropper.containerData.width,
        height: e.target.cropper.containerData.height,
      },
    });
  };

  onCropButtonClick = (uploadCallback) => {
    try {
      const imageDataUrl = this.cropperElement.current
        .getCroppedCanvas()
        .toDataURL();
      let file = new File([dataURItoBlob(imageDataUrl)], "croppedImage.png", {
        type: "image/png",
      });

      uploadCallback(file);
      this.props.onHideCropper();
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    let { guides, classes } = this.props;
    const cropBoxData = this.state.isCropperReady
      ? {
          ...this.props.cropSize,
          // center crop box
          left:
            (this.state.cropperContainerSize.width -
              this.props.cropSize.width) /
            2,
          top:
            (this.state.cropperContainerSize.height -
              this.props.cropSize.height) /
            2,
        }
      : {};

    return (
      <div
        className={classes.container}
        onMouseMove={(e) => e.stopPropagation()}
      >
        <CropExit onCancel={this.props.onCancel} />
        <StylelessModal
          className={classes.modalFrameStyle}
          show={this.props.isCropVisible}
          onClose={this.onHide}
          centered
        >
          <div className={classes.modalContent}>
            <div className={classes.cropContainer}>
              <div className="cropper-modal-react-image-cropper-container">
                <Cropper
                  ref={this.cropperElement}
                  src={this.props.sourceImage}
                  ready={this.onCropperReady}
                  style={{ height: "100%", width: "100%" }}
                  cropBoxData={cropBoxData}
                  background={false}
                  cropBoxMovable
                  cropBoxResizable={false}
                  toggleDragModeOnDblclick={false}
                  dragMode="move"
                  guides={guides}
                />
              </div>
            </div>
            <div className={classes.actionButtons}>
              <TransparentButton handleClick={this.onHide}>
                <FormattedMessage {...translations.CancelText} />
              </TransparentButton>
              <Button
                handleClick={() => {
                  this.onCropButtonClick(this.uploadFile);
                }}
                variation={ButtonVariation.primary}
                size={ButtonSize.small}
              >
                <FormattedMessage {...translations.CropText} />
              </Button>
            </div>
          </div>
        </StylelessModal>
      </div>
    );
  }
}

ImageCropper.propTypes = {
  /** The  unique id of the image selector control */
  id: PropTypes.string,
  /** The current selected image */
  sourceImage: PropTypes.string,
  /** On upload completed image change */
  onCropImage: PropTypes.func,
  /** On cancel press event */
  onCancel: PropTypes.func,
  /** Crop size */
  cropSize: PropTypes.oneOf([
    CROP_VARIATION.BACKGROUND,
    CROP_VARIATION.CARD,
    CROP_VARIATION.THUMB,
    CROP_VARIATION.HEIGHT_ONLY,
    CROP_VARIATION.WIDTH_ONLY,
    CROP_VARIATION.PROFILE,
    CROP_VARIATION.SMALL_THUMB,
    CROP_VARIATION.PEANUT,
    CROP_VARIATION.PUBLISH_CARD,
    CROP_VARIATION.IMAGE_FULL_WIDTH,
    CROP_VARIATION.IMAGE_BOXED,
    CROP_VARIATION.IMAGE_FULL_SCREEN,
    CROP_VARIATION.SIMPLE_QUIZ_COVER,
    CROP_VARIATION.DRAG_AND_DROP_ACTIVITY_IMAGE,
    CROP_VARIATION.SLIDE_MARKER,
    CROP_VARIATION.LESSON_RECAP_PICTURE,
    CROP_VARIATION.MEDIA,
    CROP_VARIATION.BATTLE,
    CROP_VARIATION.PDF,
  ]),
  /** pass a custom background image for the cropper view box **/
  customCropperViewBoxBG: PropTypes.string,
  /** show dashed lines in cropper viewbox **/
  guides: PropTypes.bool,
};

ImageCropper.defaultProps = {
  guides: true,
};

export default withThemedStyle(ImageCropperStyle)(ImageCropper);
