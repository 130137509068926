import React from "react";
import PropTypes from "prop-types";
import AuthFlowFormContainerStyle from "./AuthFlowFormContainer.style";
import { reduxForm } from "redux-form";
import { REACT_APP_VERSION, isNotProd, isDev } from "../../configs/client";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(AuthFlowFormContainerStyle);

const AuthFlowFormContainer = (props) => {
  const {
    handleSubmit,
    title,
    titlePart2,
    titlePart3,
    subTitle,
    children,
    actionSubmit,
  } = props;
  const classes = useThemedStyle(useStyle, props);
  const [version, beta, devNb] = REACT_APP_VERSION.split("/");

  return (
    <div className={classes.rootContainer}>
      <div className={classes.leftImage} />
      <div className={classes.formContainer}>
        <form className={classes.form} onSubmit={handleSubmit(actionSubmit)}>
          {title && (
            <h1 className={classes.title}>
              {title}
              <br />
              {titlePart2}
            </h1>
          )}
          {subTitle && <p className={classes.subTitle}>{subTitle}</p>}
          {children}
        </form>
      </div>
      <div className={classes.versionNumber}>
        <label>{`V. ${version}${
          isNotProd ? ` Beta ${beta}${isDev ? `.${devNb}` : ""}` : ""
        } - Powered by Southpigalle`}</label>
      </div>
    </div>
  );
};

AuthFlowFormContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  titlePart2: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default reduxForm({
  form: "SignInForm",
})(AuthFlowFormContainer);
