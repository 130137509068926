import { MarkerSlideStyle as MarkerSlideStyleFunc } from "../MarkerSlideHorizontal/MarkerSlideHorizontal.style";

export default (theme) => {
  const { MarkerSlideVertical, Colors, FontFamily } = theme;
  const MarkerSlideStyle = MarkerSlideStyleFunc(theme);

  return {
    sliderContainer: MarkerSlideStyle.sliderContainer,
    addBoxTop: {
      ...MarkerSlideStyle.addBoxLeft,
      left: "calc(100%/2 + 5)",
      top: -25,
    },
    addBoxBottom: {
      ...MarkerSlideStyle.addBoxLeft,
      left: "calc(100%/2+ 5)",
      bottom: -24,
      top: undefined,
    },
    scrollDownIndicator: {
      position: "absolute",
      bottom: 72.5,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: Colors.text.white,
      textTransform: "uppercase",
    },
    scrollDownIcon: {
      fontSize: 12,
      marginTop: 13,
    },
    imageActionContainer: {
      display: ({ isSelected }) => (isSelected ? "block" : "none"),
    },
    actionButton: {
      fontFamily: FontFamily,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "baseline",
      alignSelf: "flex-start",
      position: "absolute",
      cursor: "pointer",
      "& > span": {
        fontSize: 14,
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: 0.07,
        color: MarkerSlideVertical.actionButtonColor,
        "&::before": {
          color: MarkerSlideVertical.actionButtonColor,
          fontSize: 15,
          padding: 5,
        },
      },
      "&:hover *": {
        color: MarkerSlideVertical.actionButtonHoverColor,
        "&::before": {
          color: MarkerSlideVertical.actionButtonHoverColor,
        },
      },
    },
    uploadButton: {
      top: 20,
      left: 10,
    },
    deleteBox: {
      top: 20,
      right: 10,
    },
    partWillBeCropped: {
      position: "absolute",
      left: 91,
      bottom: 31,
      color: MarkerSlideVertical.croppedPartColor,
      width: 232,
      textAlign: "center",
    },
    uploadBtnWrapper: {
      position: "relative",
      overflow: "hidden",
      display: "inline-block",
      cursor: "pointer",
      "&:hover": {
        cursor: "pointer",
      },
    },
    btnUpload: {
      border: "none",
      fontFamily: FontFamily,
      fontSize: 14,
      background: "transparent",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "0.1px",
      color: MarkerSlideVertical.actionButtonColor,
      cursor: "pointer",
      "&:hover": {
        cursor: "pointer",
      },
      paddingLeft: 15,
      marginRight: 10,
      "& > span": {
        paddingRight: 5,
        "&:hover::before": {
          color: MarkerSlideVertical.actionButtonHoverColor,
          cursor: "pointer",
        },
      },
      "&:hover *": {
        color: MarkerSlideVertical.actionButtonHoverColor,
        cursor: "pointer",
        "&::before": {
          color: MarkerSlideVertical.actionButtonHoverColor,
          cursor: "pointer",
        },
      },
      outline: "none",
    },
    iconAction: {
      cursor: "pointer",
      "&:hover::before": {
        color: MarkerSlideVertical.actionButtonHoverColor,
        fontSize: 15,
        cursor: "pointer",
      },
      "&::before": {
        color: MarkerSlideVertical.actionButtonColor,
        fontSize: 15,
        cursor: "pointer",
      },
    },
    cropContainer: {
      position: "absolute",
      top: 20,
      left: 130,
    },
  };
};
