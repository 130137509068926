import { LANGUAGES } from "../../configs/referential";
import { GetTagsForClusters } from "../../utils/dataTransformation/tags";
import clientConfig, { CONFIG_CLUSTER, isShiseido } from "../../configs/client";
import { PAGE_TEMPLATES } from "../../configs/constants";
import { decodeNewLines } from "../../utils/string";
import uuidV4 from "uuid/dist/v4";

export const formatPublishLessonData = (
  response,
  lesson,
  lessonId,
  currentLang,
  locations,
) => {
  const langCodes = response.data.admin.lessons[0].versions.map((x) => x.lang);
  const languages = LANGUAGES.filter((lang) =>
    langCodes.includes(lang.Code),
  ).map((lang) => ({
    Id: lang.Code,
    Value: lang.Name,
    readOnly: true,
  }));

  const newTags = GetTagsForClusters(
    clientConfig[CONFIG_CLUSTER.EXPERTISE],
    lesson.tags,
  );
  const newZones = GetTagsForClusters(
    clientConfig[CONFIG_CLUSTER.ZONE],
    lesson.tags,
  );
  let newCountries = GetTagsForClusters(
    newZones.map(({ Id }) => parseInt(Id)),
    lesson.tags,
    true,
  );
  const newUserCategories = GetTagsForClusters(
    clientConfig[CONFIG_CLUSTER.USER_CATEGORY],
    lesson.tags,
  );
  const newBrands = GetTagsForClusters(
    clientConfig[CONFIG_CLUSTER.BRAND],
    lesson.tags,
  );
  const newRetailers = GetTagsForClusters(
    clientConfig[CONFIG_CLUSTER.RETAILER],
    lesson.tags,
  );
  const newEntities = GetTagsForClusters(
    clientConfig[CONFIG_CLUSTER.ENTITY],
    lesson.tags,
  );
  const newDivisions = GetTagsForClusters(
    clientConfig[CONFIG_CLUSTER.DIVISION],
    lesson.tags,
  );
  const newProfessions = GetTagsForClusters(
    clientConfig[CONFIG_CLUSTER.PROFESSION],
    lesson.tags,
  );
  const newRoles = GetTagsForClusters(
    clientConfig[CONFIG_CLUSTER.ROLE],
    lesson.tags,
  );

  // extract is program quiz data
  let showProgramQuizCheckbox = false;
  let isProgramQuiz = false;
  if (isShiseido) {
    newCountries = GetTagsForClusters(
      clientConfig[CONFIG_CLUSTER.COUNTRY],
      lesson.tags,
    );
    const activityTemplateNames = [
      PAGE_TEMPLATES.SIMPLE_QUIZ_TEMPLATE,
      PAGE_TEMPLATES.MADLIB_TEMPLATE,
      PAGE_TEMPLATES.DRAG_AND_DROP_TEMPLATE,
    ];
    for (
      let i = 0;
      i < lesson.pages.length && (!showProgramQuizCheckbox || !isProgramQuiz);
      i++
    ) {
      const currentPage = lesson.pages[i];
      showProgramQuizCheckbox =
        showProgramQuizCheckbox ||
        activityTemplateNames.includes(currentPage.template);
      if (!isProgramQuiz) {
        const pageContent = JSON.parse(currentPage.data);
        isProgramQuiz = !!pageContent.data.isProgramQuiz;
      }
    }
  }

  const formatLocations = (locations) =>
    locations
      ? locations.map(
          ({
            ocId,
            picCover,
            title,
            addressZone,
            addressCity,
            addressCountry,
            addressBuilding,
          }) => ({
            id: ocId,
            thumbnail: picCover,
            name: title,
            location: `${addressZone ? addressZone.title : ""}${
              addressCountry ? ` - ${addressCountry.title}` : ""
            }${addressCity ? ` - ${addressCity.title}` : ""}`,
            address: addressBuilding,
          }),
        )
      : [];

  return {
    id: lessonId,
    lang: currentLang,
    languages: languages,
    tags: newTags,
    predictTags: lesson.predictTags,
    countries: newCountries,
    userCategories: newUserCategories,
    brands: newBrands,
    retailers: newRetailers,
    zones: newZones,
    entities: newEntities,
    divisions: newDivisions,
    professions: newProfessions,
    roles: newRoles,
    title: decodeNewLines(lesson.title),
    summary: decodeNewLines(lesson.summary),
    cover: lesson.picCover,
    videoCover: lesson.videoCover,
    picThumb: lesson.picCard,
    lessonRecap: lesson.lessonRecap ? lesson.lessonRecap : "",
    picLessonRecap: lesson.picLessonRecap,
    startPostingDate: lesson.workflowScheduledIn,
    endPostingDate: lesson.workflowScheduledOut,
    isPublic: !lesson.isPrivate,
    tagsIsEditable: true,
    pages: lesson.pages,
    showProgramQuizCheckbox,
    isProgramQuiz,
    locations: locations ? formatLocations(locations.data.Location) : locations,
    workflow: lesson.workflow,
    files: lesson.attachment.map((file) => ({
      id: file.uploadId,
      key: uuidV4(),
      name: file.name,
      url: file.link,
    })),
  };
};
