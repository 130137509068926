import React from "react";
import { REACT_APP_CLIENT_NAME, CLIENTS } from "../../configs/client";
import DefaultPublishPage from "./PublishBattle";

const PublishBattle = (props) => {
  switch (REACT_APP_CLIENT_NAME) {
    default:
      return <DefaultPublishPage {...props} />;
  }
};

export default PublishBattle;
