import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import PageContainer from "components/PageContainer/PageContainer";
import FilterHeader from "../partials/FilterHeader/FilterHeader";
import FilterHeaderContainer from "../../../components/FilterHeaderContainer/FilterHeaderContainer";
import Badges from "../partials/Badges/Badges";

const BadgesList = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const history = useHistory();
  const { brandId, marketId } = useMemo(() => {
    const marketId = queryParams.get("marketId");
    const brandId = queryParams.get("brandId");

    return {
      brandId,
      marketId,
    };
  }, [queryParams]);

  const handleParamsChange = useCallback(
    (type, value) => {
      queryParams.delete(type);
      if (value) queryParams.append(type, value);
      history.push({ search: queryParams.toString() });
    },
    [queryParams, history],
  );
  const [statuses, setStatus] = useState([]);
  return (
    <PageContainer>
      <FilterHeaderContainer>
        <FilterHeader
          handleParamsChange={handleParamsChange}
          brandId={brandId}
          marketId={marketId}
          setStatus={setStatus}
          brand
          market
          status
        />
      </FilterHeaderContainer>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: 940,
            padding: "30px 0",
          }}
        >
          <Badges brandId={brandId} marketId={marketId} statuses={statuses} />
        </div>
      </div>
    </PageContainer>
  );
};

export default BadgesList;
