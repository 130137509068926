import { defaultTranslations as translations } from "./FilterEnvironments.translations";

export const defaultFilterArrayToExport = (
  {
    selectedMarkets,
    selectedRelationships,
    selectedRetailers,
    selectedPeriod,
    selectedDegree,
  },
  intl,
) => {
  var markets = "";
  var relationships = "";
  var retailers = "";
  var period = "";
  var degrees;

  if (selectedDegree !== undefined) {
    degrees = [intl.formatMessage(translations.Degree)];
    let degreeValue = "";
    selectedDegree.forEach((element) => {
      degreeValue = degreeValue + ", " + element;
    });
    if (degreeValue) degreeValue = degreeValue.substr(2, degreeValue.length);
    degrees.push(degreeValue);
  }

  selectedMarkets.forEach((element) => {
    markets = markets + ", " + element.label;
  });
  selectedRelationships.forEach((element) => {
    relationships = relationships + ", " + element.label;
  });
  selectedRetailers.forEach((element) => {
    retailers = retailers + ", " + element.label;
  });

  period = intl.formatMessage({
    id: selectedPeriod.label.props.id,
    defaultMessage: selectedPeriod.label.props.defaultMessage,
  });

  markets = markets.substr(2, markets.length);
  relationships = relationships.substr(2, relationships.length);
  retailers = retailers.substr(2, retailers.length);

  var returnValue = [
    [intl.formatMessage(translations.Title)],
    [intl.formatMessage(translations.Market), markets],
    [intl.formatMessage(translations.Relationship), relationships],
    [intl.formatMessage(translations.Retailer), retailers],
  ];
  if (degrees) returnValue.push(degrees);
  returnValue.push([intl.formatMessage(translations.Period), period]);
  returnValue.push([]);
  returnValue.push([]);

  return returnValue;
};
