import React from "react";
import PropTypes from "prop-types";
import MultiProgressChartProgressItem from "./MultiProgressChartProgressItem";

const MultiProgressChart = ({ progressItems }) => {
  return (
    <>
      {progressItems.map(({ id, name, progress }) => (
        <MultiProgressChartProgressItem
          key={id}
          name={name}
          progress={progress}
        />
      ))}
    </>
  );
};

MultiProgressChart.propTypes = {
  progressItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
    }),
  ).isRequired,
};

export default MultiProgressChart;
