import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import uuidv4 from "uuid/dist/v4";
import { FormattedMessage, useIntl } from "react-intl";
import ChangeTemplate from "../ChangeTemplate";
import {
  SIMPLE_QUIZ_COVER,
  SIMPLE_QUIZ_FULL_SCREEN,
  SIMPLE_QUIZ_NO_IMAGE,
} from "../../../utils/ui-generator/activity.template";
import {
  emptyAnswerTemplate,
  SimpleQuizActivityQuestionTemplateEmpty,
  QuizWithCoverActivityQuestionTemplateEmpty,
  QuizImageFullScreenActivityQuestionTemplateEmpty,
} from "../../../utils/ui-generator/defaultTemplates";
import ContentEditable from "react-contenteditable";
import RadioGroup from "../RadioGroup";
import UploadImageButton from "../UploadImageButton";
import { Icon, Icons } from "genius-ui";
import AddNewContainer, { DIRECTION_VERTICAL } from "../AddNewContainer";
import SimpleQuizAnswer from "../SimpleQuizAnswer";
import Style, { backgroundGradient } from "./SimpleQuizQuestion.style";
import CropImage from "../../common/CropImage";
import { CROP_VARIATION } from "../../../configs/constants";
import { translations } from "./SimpleQuizQuestion.translations";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import {
  SimpleQuizCoverDefault,
  SimpleQuizCoverThumbnail,
  SimpleQuizNoImageThumbnail,
  SimpleQuizFullScreenThumbnail,
} from "assets/icons";

const useStyle = createUseStyles(Style);

const availableTemplates = [
  {
    id: SIMPLE_QUIZ_NO_IMAGE,
    name: translations.NoImage,
    image: SimpleQuizNoImageThumbnail,
    defaultProps: SimpleQuizActivityQuestionTemplateEmpty,
  },
  {
    id: SIMPLE_QUIZ_COVER,
    name: translations.Cover,
    image: SimpleQuizCoverThumbnail,
    defaultProps: QuizWithCoverActivityQuestionTemplateEmpty,
  },
  {
    id: SIMPLE_QUIZ_FULL_SCREEN,
    name: translations.Background,
    image: SimpleQuizFullScreenThumbnail,
    defaultProps: QuizImageFullScreenActivityQuestionTemplateEmpty,
  },
];

const MAX_NR_OF_ANSWERS = 4;
const ADD_NEW_OPTION_VALUE = "addNewOption";

// half of fixed height
const ADD_BUTTON_SWITCH_BREAKPOINT = 368;

const createNewAnswer = () => emptyAnswerTemplate;

const SimpleQuizQuestion = (props) => {
  const {
    uniqueId,
    answers: answersProp,
    background,
    endMessage,
    text,
    type,
    id,
    isEditable,
    onChange,
    onAddQuestionBefore,
    onAddQuestionAfter,
    onClick,
    onRemove,
    multiQuestionsManagement,
    pictureManagementOnEndtranslationscreen,
    multiAnswerManagement,
    changeTemplateAllowed,
    quickQuizEndImage,
  } = props;
  const intl = useIntl();
  const [question, setQuestion] = useState(text);
  const [explanations, setExplanations] = useState(endMessage.text);
  const [backgroundImage, setBackgroundImage] = useState(
    background?.url || null,
  );
  const [endMessageBackgroundImage, setEndMessageBackgroundImage] = useState(
    quickQuizEndImage?.url || endMessage?.background?.url || null,
  );
  const [isTyping, setIsTyping] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(() => {
    let selectedTemplateId = SIMPLE_QUIZ_NO_IMAGE;
    if (background) {
      if (
        background.theme ===
        QuizWithCoverActivityQuestionTemplateEmpty.background.theme
      ) {
        selectedTemplateId = SIMPLE_QUIZ_COVER;
      } else if (
        background.theme ===
        QuizImageFullScreenActivityQuestionTemplateEmpty.background.theme
      ) {
        selectedTemplateId = SIMPLE_QUIZ_FULL_SCREEN;
      }
    }

    return selectedTemplateId;
  });
  const [backgroudTheme, setBackgroundTheme] = useState(background?.theme);
  const classes = useThemedStyle(useStyle, {
    ...props,
    background: {
      ...props.background,
      theme: backgroudTheme,
    },
    endMessage: {
      ...props.endMessage,
      background: {
        ...props.endMessage.background,
        theme: backgroudTheme,
      },
    },
  });
  useEffect(() => {
    if (selectedTemplateId === SIMPLE_QUIZ_COVER) {
      setBackgroundTheme(
        QuizWithCoverActivityQuestionTemplateEmpty.background.theme,
      );
    } else if (selectedTemplateId === SIMPLE_QUIZ_FULL_SCREEN) {
      setBackgroundTheme(
        QuizImageFullScreenActivityQuestionTemplateEmpty.background.theme,
      );
    } else {
      setBackgroundTheme(undefined);
    }
  }, [selectedTemplateId]);

  const [answers, setAnswers] = useState(answersProp);

  // answers
  const addNewAnswer = useCallback(
    () =>
      setAnswers((answers) =>
        answers.length < MAX_NR_OF_ANSWERS
          ? [...answers, createNewAnswer()]
          : answers,
      ),
    [setAnswers, answers, createNewAnswer],
  );

  const [answerOptions, setOptions] = useState([]);

  const handleUpdateOptions = useCallback(() => {
    setOptions(() => {
      const options = answers.map(({ answer }) => ({
        value: uuidv4(),
        label: answer,
      }));
      if (multiAnswerManagement && options.length < MAX_NR_OF_ANSWERS) {
        options.push({
          value: ADD_NEW_OPTION_VALUE,
          label: (
            <div onClick={addNewAnswer}>
              <FormattedMessage
                {...translations.AddAChoiceButtonLabel}
                values={{ maxNrOfAnswers: MAX_NR_OF_ANSWERS }}
              />
            </div>
          ),
        });
      }
      return options;
    });
  }, [answers, multiAnswerManagement, translations, addNewAnswer]);

  useEffect(() => {
    const withNoAdd = answerOptions.filter(
      ({ value }) => value !== ADD_NEW_OPTION_VALUE,
    );
    if (!withNoAdd.length || withNoAdd.length !== answers.length)
      handleUpdateOptions();
  }, [answers]);

  const [correctAnswerIndex, setCorrectAnswerIndex] = useState(
    // if correct answer was selected, it is the correct answer, else the first added answer is correct, else undefined
    () => {
      const selectedIndex = answers.findIndex(({ isTrue }) => isTrue);
      return selectedIndex >= 0
        ? selectedIndex
        : answers.length > 0
        ? 0
        : undefined;
    },
  );
  const correctAnswerValue =
    answerOptions[correctAnswerIndex] &&
    answerOptions[correctAnswerIndex].value;
  const [successMessage, setSuccessMessage] = useState(
    // the default success message is the message of the answer marked as correct or ''
    () =>
      (answers[correctAnswerIndex] && answers[correctAnswerIndex].message) ||
      "",
  );
  const [failedMessage, setFailedMessage] = useState(() => {
    // the default failed message is the message of the first incorrect answer or ''
    const firstIncorrectAnswer = answers.find(({ isTrue }) => !isTrue);
    return (firstIncorrectAnswer && firstIncorrectAnswer.message) || "";
  });
  const [isCropVisible, setIsCropVisible] = useState(false);
  const [isExplanationCropVisible, setIsExplanationCropVisible] =
    useState(false);
  const cropSizes = {
    [SIMPLE_QUIZ_COVER]: CROP_VARIATION.SIMPLE_QUIZ_COVER,
    [SIMPLE_QUIZ_FULL_SCREEN]: CROP_VARIATION.BACKGROUND,
  };

  const setCropVisibleState = (visible) => () => setIsCropVisible(visible);
  const setExplanationCropVisibleState = (visible) => () =>
    setIsExplanationCropVisible(visible);

  // call the onChange callback when answers, background, endMessage, text, type or the selected template is
  // changed
  const memoisedData = useRef(null);

  useEffect(() => {
    if (isTyping) {
      // don't call the onChange callback while typing
      return;
    }

    // get default backgrounds of the selected template
    const {
      defaultProps: {
        background: selectedTemplateBackground,
        endMessage: { background: selectedTemplateEndMessageBackground },
      },
    } = availableTemplates.find(({ id }) => id === selectedTemplateId);

    const finalBackground = selectedTemplateBackground
      ? {
          ...selectedTemplateBackground,
          // use background from empty template if no background image was uploaded
          url: backgroundImage || selectedTemplateBackground.url,
        }
      : selectedTemplateBackground;

    const finalEndMessage = {
      ...endMessage,
      text: explanations,
      background: selectedTemplateEndMessageBackground
        ? {
            ...selectedTemplateEndMessageBackground,
            // use background from empty template if no background image was uploaded
            url:
              endMessageBackgroundImage ||
              selectedTemplateEndMessageBackground.url,
          }
        : selectedTemplateEndMessageBackground,
    };

    const endImage = quickQuizEndImage
      ? {
          ...quickQuizEndImage,
          url:
            endMessageBackgroundImage ||
            selectedTemplateEndMessageBackground.url,
        }
      : quickQuizEndImage;

    const data = {
      answers: answers.map((answer, index) => ({
        ...answer,
        isTrue: index === correctAnswerIndex,
        message: index === correctAnswerIndex ? successMessage : failedMessage,
      })),
      endImage,
      background: finalBackground,
      endMessage: finalEndMessage,
      text: question,
      type,
    };
    if (!memoisedData.current) {
      memoisedData.current = JSON.stringify(data);
      return;
    }
    if (memoisedData.current !== JSON.stringify(data)) {
      memoisedData.current = JSON.stringify(data);
      onChange(id, data);
    }
  }, [
    answers,
    correctAnswerIndex,
    background,
    endMessage,
    text,
    type,
    selectedTemplateId,
    isTyping,
    backgroundImage,
    endMessageBackgroundImage,
    successMessage,
    failedMessage,
  ]);

  const handleTypingFinished = () => setIsTyping(false);

  // question(text)
  const handleQuestionInput = useCallback(
    (e) => {
      setIsTyping(true);
      setQuestion(e.target.value);
    },
    [setIsTyping, setQuestion],
  );

  // success message of selected choice
  const handleSuccessMessageInput = useCallback(
    (e) => {
      setIsTyping(true);
      setSuccessMessage(e.target.value);
    },
    [setIsTyping, setSuccessMessage],
  );

  // failed message of selected choice
  const handleFailedMessageInput = useCallback(
    (e) => {
      setIsTyping(true);
      setFailedMessage(e.target.value);
    },
    [setIsTyping, setFailedMessage],
  );

  // explanations
  const handleExplanationInput = useCallback(
    (e) => {
      setIsTyping(true);
      setExplanations(e.target.value);
    },
    [setIsTyping, setExplanations],
  );

  const removeAnswer = useCallback((answerIndex) => {
    setAnswers((answers) =>
      answers.filter((answer, index) => index !== answerIndex),
    );
  }, []);

  const changeAnswerContent = (newContent, changedAnswerIndex) =>
    setAnswers((answers) =>
      answers.map((answer, index) =>
        index === changedAnswerIndex
          ? {
              ...answer,
              answer: newContent,
            }
          : answer,
      ),
    );
  const displayedEndMessageBackgroundImage = endMessageBackgroundImage
    ? cropSizes[selectedTemplateId] && endMessageBackgroundImage
    : cropSizes[selectedTemplateId] && SimpleQuizCoverDefault;

  return (
    <div className={classes.container} onClick={onClick}>
      <AddNewContainer
        className={classNames([
          classes.innerPageContainer,
          classes.questionContainer,
        ])}
        style={{
          backgroundImage:
            cropSizes[selectedTemplateId] &&
            `${backgroundGradient}, url("${
              backgroundImage || SimpleQuizCoverDefault
            }")`,
        }}
        enabled={isEditable && multiQuestionsManagement}
        direction={DIRECTION_VERTICAL}
        beforeAfterBreakPoint={ADD_BUTTON_SWITCH_BREAKPOINT}
        onAddBefore={() => onAddQuestionBefore(id)}
        onAddAfter={() => onAddQuestionAfter(id)}
      >
        {isEditable && changeTemplateAllowed && (
          <ChangeTemplate
            selectedTemplateId={selectedTemplateId}
            templates={availableTemplates}
            onTemplateSelect={setSelectedTemplateId}
            className={classes.changeTemplate}
          />
        )}
        <div
          className={classNames({
            [classes.coverContainerHidden]: !(
              isEditable &&
              (selectedTemplateId === SIMPLE_QUIZ_COVER ||
                selectedTemplateId === SIMPLE_QUIZ_FULL_SCREEN)
            ),
          })}
        >
          <UploadImageButton
            id={`${uniqueId}-mainImageSelector`}
            containerClass={classes.uploadImageButton}
            disabled={!isEditable}
            onUploadSuccess={(image) => setBackgroundImage(image)}
          />
          <CropImage
            classes={classes}
            sourceImage={backgroundImage}
            isCropVisible={isCropVisible}
            cropSize={cropSizes[selectedTemplateId]}
            onCropClick={setCropVisibleState(true)}
            onCancel={setCropVisibleState(false)}
            onCropCompleted={(image) => setBackgroundImage(image)}
          />
        </div>
        {isEditable && multiQuestionsManagement && (
          <div
            className={classNames([
              classes.deleteButton,
              {
                [classes.deleteButtonDark]:
                  selectedTemplateId === SIMPLE_QUIZ_NO_IMAGE,
              },
            ])}
            onClick={() => onRemove(id)}
          >
            <Icon iconName={Icons.delete} />
            <FormattedMessage {...translations.DeleteButtonLabel} />
          </div>
        )}
        <ContentEditable
          key="question"
          html={question}
          disabled={!isEditable}
          onChange={handleQuestionInput}
          onBlur={handleTypingFinished}
          tagName="article"
          className={classNames([classes.questionInput, classes.textInput])}
          placeholder={intl.formatMessage(translations.QuestionPlaceholder)}
        />
        <div className={classes.answerContainer}>
          <div className={classes.radioHeaderContainer}>
            <div className={classes.correctLabel}>
              <FormattedMessage {...translations.CorrectColumnLabel} />
            </div>
            <div className={classes.choiceTextLabel}>
              <FormattedMessage {...translations.ChoiceTextColumnLabel} />
            </div>
          </div>
          <div className={classes.radioContainer}>
            <RadioGroup
              options={answerOptions}
              onChange={(selectedOption) =>
                setCorrectAnswerIndex(
                  answerOptions.findIndex(
                    ({ value }) => value === selectedOption.value,
                  ),
                )
              }
              value={correctAnswerValue}
              renderOptionLabel={(
                option,
                index,
                options,
                value,
                onChange,
                classes,
              ) => (
                <SimpleQuizAnswer
                  option={option}
                  key={option.value}
                  index={index}
                  options={options}
                  value={value}
                  isAddAChoice={option.value === ADD_NEW_OPTION_VALUE}
                  classes={classes}
                  addNewAnswer={addNewAnswer}
                  onChange={changeAnswerContent}
                  onChangeSelectedAnswer={onChange}
                  onRemove={removeAnswer}
                  disabled={!isEditable}
                  canAddAnswer={answers.length < MAX_NR_OF_ANSWERS}
                  isBGDark={selectedTemplateId === SIMPLE_QUIZ_FULL_SCREEN}
                  multiAnswerManagement={multiAnswerManagement}
                />
              )}
            />
          </div>
        </div>
      </AddNewContainer>
      <div
        className={classNames([
          classes.innerPageContainer,
          classes.resultContainer,
        ])}
        style={{
          backgroundImage:
            (displayedEndMessageBackgroundImage &&
              `${backgroundGradient}, url("${displayedEndMessageBackgroundImage}")`) ||
            null,
        }}
      >
        <div
          className={classNames({
            [classes.coverContainerHidden]: !(
              isEditable &&
              pictureManagementOnEndtranslationscreen &&
              (selectedTemplateId === SIMPLE_QUIZ_COVER ||
                selectedTemplateId === SIMPLE_QUIZ_FULL_SCREEN)
            ),
          })}
        >
          <UploadImageButton
            id={`${uniqueId}-endMessageImageSelector`}
            containerClass={classes.uploadImageButton}
            disabled={!isEditable}
            onUploadSuccess={(image) => setEndMessageBackgroundImage(image)}
          />
          <CropImage
            classes={classes}
            sourceImage={endMessageBackgroundImage}
            isCropVisible={isExplanationCropVisible}
            cropSize={cropSizes[selectedTemplateId]}
            onCropClick={setExplanationCropVisibleState(true)}
            onCancel={setExplanationCropVisibleState(false)}
            onCropCompleted={(image) => setEndMessageBackgroundImage(image)}
          />
        </div>
        <ContentEditable
          key="success_message"
          html={successMessage}
          disabled={!isEditable}
          onChange={handleSuccessMessageInput}
          onBlur={handleTypingFinished}
          tagName="article"
          className={classNames([
            classes.successMessageInput,
            classes.textInput,
          ])}
          placeholder={intl.formatMessage(
            translations.SuccessMessageInputPlaceholder,
          )}
        />
        <ContentEditable
          key="failed_message"
          html={failedMessage}
          disabled={!isEditable}
          onChange={handleFailedMessageInput}
          onBlur={handleTypingFinished}
          tagName="article"
          className={classNames([
            classes.failedMessageInput,
            classes.textInput,
          ])}
          placeholder={intl.formatMessage(
            translations.FailedMessageInputPlaceholder,
          )}
        />
        <ContentEditable
          key="explanations"
          html={explanations}
          disabled={!isEditable}
          onChange={handleExplanationInput}
          onBlur={handleTypingFinished}
          tagName="article"
          className={classNames([classes.explanationsInput, classes.textInput])}
          placeholder={intl.formatMessage(
            translations.ExplanationsInputPlaceholder,
          )}
        />
      </div>
    </div>
  );
};

SimpleQuizQuestion.propTypes = {
  /** string that uniquely identifies the question component on a single page  **/
  uniqueId: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
  background: PropTypes.object,
  endMessage: PropTypes.shape({
    background: PropTypes.object,
    text: PropTypes.string.isRequired,
  }).isRequired,
  /** an id the question can be identified with **/
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** whether the question is editable or not **/
  isEditable: PropTypes.bool.isRequired,
  /** function executed when an @answer, @background, @endMessage, @text or the selected template is changed **/
  onChange: PropTypes.func.isRequired,
  /** function executed when clicked on the question **/
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  /** function executed when the 'Add before' button is clicked **/
  onAddQuestionBefore: PropTypes.func,
  /** function executed when the 'Add after' button is clicked **/
  onAddQuestionAfter: PropTypes.func,
  /** function executed when the 'Delete' button is clicked **/
  onRemove: PropTypes.func,
  /** show add new question buttons and delete question buttons or not **/
  multiQuestionsManagement: PropTypes.bool,
  /** show upload image and crop image buttons **/
  pictureManagementOnEndtranslationscreen: PropTypes.bool,
  /** show add & delete answer buttons **/
  multiAnswerManagement: PropTypes.bool,
  /** show template selection menu**/
  changeTemplateAllowed: PropTypes.bool,
};

SimpleQuizQuestion.defaultProps = {
  multiQuestionsManagement: true,
  pictureManagementOnEndtranslationscreen: true,
  multiAnswerManagement: true,
  changeTemplateAllowed: true,
};

export default SimpleQuizQuestion;
