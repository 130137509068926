import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Style from "../NinjaEditor.style";
import NinjaQuestionEditorReadOnly from "../../NinjaQuestionEditor/readOnly";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../../hooks/style/useThemedStyle";
import { NinjaQuestionPropTypes } from "../../../../constants/domain_items/ninja/ninja_question";

const useStyle = createUseStyles(Style);

const NinjaEditor = (props) => {
  const { questions, className } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classNames([classes.container, className])}>
      {questions.map((question) => (
        <NinjaQuestionEditorReadOnly key={question.id} {...question} />
      ))}
    </div>
  );
};

NinjaEditor.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape(NinjaQuestionPropTypes))
    .isRequired,
  /** provide a custom style to the question container **/
  className: PropTypes.string,
};

export default NinjaEditor;
