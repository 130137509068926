import { publishBannerHeight } from "../../components/PublishPreviewBanner/PublishPreviewBanner.style";

export default ({ EditNinjaScreen }) => ({
  ninjaEditorContainer: {
    textAlign: "center",
    "& > div": {
      textAlign: "left",
    },
  },
  ninjaEditor: {
    paddingTop: publishBannerHeight + 133,
    display: "inline-flex",
  },
  closeIcon: {
    position: "absolute",
    left: 20,
    color: EditNinjaScreen.closeIconColor,
    "&::before": {
      color: EditNinjaScreen.closeIconColor,
      fontSize: 20,
    },
    "&:hover::before": {
      color: EditNinjaScreen.closeIconHoverColor,
      cursor: "pointer",
    },
  },
});
