import gql from "graphql-tag";

export const GET_DEGREE_BADGES_LESSONS = gql`
  query allLessonWithBadge($degreeId: ID) {
    admin {
      degrees(degreeId: $degreeId) {
        programs {
          lessons {
            versions {
              lang
              title
              badges {
                degreeId
                badgeId
                audit
              }
              docId
              picThumb
              summary
              workflow
            }
          }
        }
      }
    }
  }
`;
