import { defineMessages } from "react-intl";

export const translations = defineMessages({
  TitleText: {
    id: "Toolbar.TitleText",
    defaultMessage: "Title",
  },
  SubtitleText: {
    id: "Toolbar.SubtitleText",
    defaultMessage: "Subtitle",
  },
  ParagraphText: {
    id: "Toolbar.ParagraphText",
    defaultMessage: "Paragraph",
  },
  PictureText: {
    id: "Toolbar.PictureText",
    defaultMessage: "Picture",
  },
  VideoText: {
    id: "Toolbar.VideoText",
    defaultMessage: "Video",
  },
  LinkText: {
    id: "Toolbar.LinkText",
    defaultMessage: "Link",
  },
  NewPageText: {
    id: "Toolbar.NewPageText",
    defaultMessage: "New Page",
  },
  ContinueWritingText: {
    id: "Toolbar.ContinueWritingText",
    defaultMessage: "Continue writing your story",
  },
});
