import gql from "graphql-tag";
import { PAGE_TYPES } from "../../../../configs/constants";

export const BATTLE_CREATE_PAGE = gql`
    mutation createBattlePage ($battleId: ID, $lang: LangEnum, $data: String!){
        admin {
            battle(battleId: $battleId, lang: $lang){
                pages{
                    create(type: ${PAGE_TYPES.BATTLE_QUIZ}, data: $data){
                        pageId
                    }
                }
            }
        }
    }`;
