import React from "react";
import { PeanutDefaultImage1, PeanutDefaultImage2 } from "assets/icons";
import PeanutPageEditor from "./PeanutPageEditor";
import Peanut from "../common/Peanut";
import { PEANUT_CARD_TYPES } from "../../configs/constants";

const DefaultSliderImages = [
  PeanutDefaultImage1,
  PeanutDefaultImage2,
  PeanutDefaultImage1,
];

const defaultNewElement = {
  title: "",
  description: "",
  image: "",
  defaultImage: PeanutDefaultImage1,
  legend: "",
  cardType: PEANUT_CARD_TYPES.WITHOUT_TEXT,
};

const renderSlideItem = (
  slide,
  index,
  key,
  isSelected,
  addAfter,
  addBefore,
  removeSlide,
  handleChange,
  selectSlide,
  cardsNumber,
  pageId,
) => {
  return (
    <Peanut
      key={key}
      isSelected={isSelected}
      title={slide.title}
      description={slide.description}
      image={slide.image}
      defaultSliderImage={slide.defaultImage}
      onAddToRightHandle={addAfter}
      onAddToLeftHandle={addBefore}
      onRemoveClick={removeSlide}
      onChange={handleChange}
      onClick={selectSlide}
      legend={slide.legend}
      allCardNumber={cardsNumber}
      cardIndex={index + 1}
      cardType={slide.cardType}
      uniqueId={`${pageId}-${index}`}
    />
  );
};

const PreparedPeanutPageEditor = ({ cards, ...props }) => (
  <PeanutPageEditor
    cards={cards}
    defaultNewElement={defaultNewElement}
    defaultSliderImages={DefaultSliderImages}
    renderSlideItem={renderSlideItem}
    {...props}
  />
);

PreparedPeanutPageEditor.propTypes = {
  /**
   * All the props accepted by SliderPageEditor
   */
};

export default PreparedPeanutPageEditor;
