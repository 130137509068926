import gql from "graphql-tag";

export const DELETE_USER = gql`
  mutation deleteUser($userId: ID) {
    admin {
      users(userId: $userId) {
        updateStatus(status: ARCHIVED) {
          status
        }
      }
    }
  }
`;
