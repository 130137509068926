import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { calculateTagRank } from "../../utils/transformers";
import { getFormattedSopiDate } from "../../utils/dateUtils";
import {
  KPIPageContainer,
  WidgetContainer,
  WidgetSection,
} from "../../components/KPI";
import { FormattedMessage, injectIntl } from "react-intl";
import TagCloudWidget from "../../components/KPI/TagCloudWidget";
import MultiProgressWidget from "../../components/KPI/MultiProgressWidget";
import { GetPageData } from "../../store/kpiFeedDetails/actions";
import { exportFeed } from "../../utils/excelExport/kpi/feed";
import { translations } from "./Feed.translations";
import { createUseStyles } from "react-jss";
import style from "./Feed.style";
import { ColumnChart } from "genius-charts";
import useThemedStyle from "../../hooks/style/useThemedStyle";
import { useFeedKPIs } from "../../hooks/KPI/hooks";
import { UPDATE_PAGE_DATA_LOADING } from "../../constants/store/kpiFeedDetails";
import { DOC_WORKFLOW_ENUM, DOCUMENT_TYPES } from "configs/constants";
import client from "../../graphql/client";
import {
  GET_FEED_KPI_DATA,
  GET_DAA_WITH_INTERVAL,
  GET_DAA_WITH_DATES,
} from "../../graphql/remote/kpi/queries/feed.graphql";
import {
  extractFilterValues,
  getFormattedTagList,
} from "../../store/actions/common/kpi";
import { FullscreenLoader } from "genius-ui";

const useStyle = createUseStyles(style);

const LIMIT = 300;

const downloadFeedExportData = async (feedFilters, SetLoading, interval) => {
  SetLoading(true);
  const generalFilterValues = extractFilterValues(feedFilters);
  const tagList = getFormattedTagList(generalFilterValues);
  let resultContents = [];
  let daaContents = [];
  let daaContentsByDate = [];
  let lengthResult = 0;
  let skip = 0;
  const {
    startDate: { selectedValues: startDate },
    endDate: { selectedValues: endDate },
  } = feedFilters;

  do {
    const result = await client.query({
      fetchPolicy: "no-cache",
      query: GET_FEED_KPI_DATA,
      variables: {
        workflows: [DOC_WORKFLOW_ENUM.PUBLISHED],
        types: Object.values(DOCUMENT_TYPES),
        skip: skip,
        limit: LIMIT,
        tagMultipleIntersectIds: tagList.map(({ tagIds }) => ({ tagIds })),
        from: getFormattedSopiDate(startDate),
        to: getFormattedSopiDate(endDate),
      },
    });
    resultContents = resultContents.concat(result.data.admin.documents);
    lengthResult = result.data.admin.documents.length;
    skip += lengthResult;
  } while (lengthResult === LIMIT);

  lengthResult = 0;
  skip = 0;

  if (interval?.selectedValues[0]?.value) {
    do {
      const resultDaa = await client.query({
        fetchPolicy: "no-cache",
        query: GET_DAA_WITH_INTERVAL,
        variables: {
          intervalId: interval.selectedValues[0].value,
          skip: skip,
          limit: LIMIT,
        },
      });
      daaContents = daaContents.concat(resultDaa.data.admin.daaWithInterval);
      lengthResult = resultDaa.data.admin.daaWithInterval.length;
      skip += lengthResult;
    } while (lengthResult === LIMIT);
  } else {
    do {
      const resultContentsIds = resultContents.map(({ docId }) =>
        Number(docId),
      );
      const resultDaa = await client.query({
        fetchPolicy: "no-cache",
        query: GET_DAA_WITH_DATES,
        variables: {
          docIds: resultContentsIds,
          from: getFormattedSopiDate(feedFilters.startDate.selectedValues),
          to: getFormattedSopiDate(feedFilters.endDate.selectedValues),
          skip: skip,
          limit: LIMIT,
        },
      });
      daaContentsByDate = daaContentsByDate.concat(
        resultDaa.data.admin.documents,
      );
      lengthResult = resultDaa.data.admin.documents.length;
      skip += lengthResult;
    } while (lengthResult === LIMIT);
  }
  SetLoading(false);

  const resultContentsMapped = resultContents.map((content) => {
    if (daaContents.length) {
      const matchingDaa = daaContents.find(
        ({ document }) => document.id === content.docId,
      );

      return {
        ...content,
        versions: content.versions.map((version) => ({
          ...version,
          all: {
            ...version.all,
            bookmark: matchingDaa?.nbBookmarks || 0,
            vote: matchingDaa?.nbVotes || 0,
            read: matchingDaa?.nbReads || 0,
          },
        })),
      };
    } else if (daaContentsByDate.length) {
      const matchingDaa = daaContentsByDate.find(
        ({ docId }) => docId === content.docId,
      );

      return {
        ...content,
        versions: content.versions.map((version, index) => ({
          ...version,
          all: {
            ...version.all,
            bookmark: matchingDaa
              ? matchingDaa.versions[index].all.bookmark
              : 0,
            vote: matchingDaa ? matchingDaa.versions[index].all.vote : 0,
            read: matchingDaa ? matchingDaa.versions[index].all.read : 0,
            comment: matchingDaa ? matchingDaa.versions[index].all.comment : 0,
          },
        })),
      };
    }
    return undefined;
  });

  return resultContentsMapped;
};

const KpiFeedShiseido = (props) => {
  const {
    totalNumberOfContent,
    contentNumberByContentType,
    contentPopularity,
    topTagsInContent,
    GetPageData,
    Export,
    intl,
    SetLoading,
    filters: feedFilters,
    isFetching,
  } = props;
  const classes = useThemedStyle(useStyle, props);
  const { isLoading } = useFeedKPIs();

  useEffect(() => {
    GetPageData();
  }, []);

  const exportFunction = useCallback(
    async (filters, intl) => {
      try {
        const allContent = await downloadFeedExportData(
          feedFilters,
          SetLoading,
          feedFilters.interval,
        );

        Export(
          {
            totalNumberOfContent: totalNumberOfContent,
            contentNumberByContentType: contentNumberByContentType,
            contentPopularity: contentPopularity,
            topTagsInContent: topTagsInContent,
            allContent,
          },
          filters,
          intl,
        );
      } catch (e) {
        console.error("issue export kpi feed", e);
        // I know there is an error !!
      }
    },
    [
      feedFilters,
      SetLoading,
      feedFilters.interval,
      totalNumberOfContent,
      contentNumberByContentType,
      contentPopularity,
      topTagsInContent,
    ],
  );

  return (
    <div className={classes.container}>
      {isFetching && <FullscreenLoader />}
      <KPIPageContainer
        onFilterChange={GetPageData}
        exportFunction={exportFunction}
        useIntervals
      >
        <WidgetSection>
          <WidgetContainer>
            <ColumnChart
              title={intl.formatMessage(translations.ContentNumberChartLabel)}
              total={totalNumberOfContent}
              data={contentNumberByContentType}
              columnTooltipText={intl.formatMessage(
                translations.ColumnTooltipContent,
              )}
            />
          </WidgetContainer>
        </WidgetSection>
        <WidgetSection>
          <WidgetContainer width={50}>
            <MultiProgressWidget
              title={
                <FormattedMessage {...translations.PopularContentWidgetLabel} />
              }
              titleRight={
                <FormattedMessage
                  {...translations.PopularContentWidgetTitleRightLabel}
                />
              }
              progressItems={contentPopularity}
            />
          </WidgetContainer>
          <WidgetContainer width={50}>
            <TagCloudWidget
              title={<FormattedMessage {...translations.TopTagsWidgetLabel} />}
              tags={topTagsInContent}
            />
          </WidgetContainer>
        </WidgetSection>
      </KPIPageContainer>
    </div>
  );
};

const formatChartValues = (total, value) =>
  `${total ? ((value * 100) / total).toFixed(1) : "0"} %`;

const mapStateToProps = (state) => ({
  totalNumberOfContent: state.kpi.feedDetails.totalNumberOfContent,
  contentNumberByContentType: [
    {
      name: "Insights",
      upperValue: state.kpi.feedDetails.insight || 0,
      loverValue: formatChartValues(
        state.kpi.feedDetails.totalNumberOfContent,
        state.kpi.feedDetails.insight || 0,
      ),
    },
    {
      name: "Lessons",
      upperValue: state.kpi.feedDetails.lesson || 0,
      loverValue: formatChartValues(
        state.kpi.feedDetails.totalNumberOfContent,
        state.kpi.feedDetails.lesson || 0,
      ),
    },
    {
      name: "Social Media",
      upperValue: state.kpi.feedDetails.socialMedia || 0,
      loverValue: formatChartValues(
        state.kpi.feedDetails.totalNumberOfContent,
        state.kpi.feedDetails.socialMedia || 0,
      ),
    },
    {
      name: "Sliders",
      upperValue: state.kpi.feedDetails.slider || 0,
      loverValue: formatChartValues(
        state.kpi.feedDetails.totalNumberOfContent,
        state.kpi.feedDetails.slider || 0,
      ),
    },
  ],
  contentPopularity: state.kpi.feedDetails.popularContent.map((content) => ({
    ...content,
    name: content.label,
    progress: content.percent,
  })),
  topTagsInContent: calculateTagRank(state.kpi.feedDetails.topTags).map(
    ({ id, label: name, rank }) => ({
      id,
      content: name,
      rank,
    }),
  ),
  isExportLoading: state.kpi.filters.isExportLoading,
  filters: state.kpi.filters,
  isFetching: state.common.fetchIndicator,
});

const mapDispatchToProps = (dispatch) => ({
  GetPageData: () => {
    dispatch(GetPageData());
  },
  Export: (exportData, filters, intl) => {
    exportFeed(exportData, filters, intl);
  },
  SetLoading: (isLoading) => {
    dispatch({ type: UPDATE_PAGE_DATA_LOADING, data: isLoading });
  },
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(KpiFeedShiseido),
);
