export default (theme) => ({
  container: {
    display: "flex",
    width: 1302,
    justifyContent: "space-between",
    paddingRight: 30,
    cursor: ({ isEditable }) => (isEditable ? "default" : "pointer"),
  },
  innerPageContainer: {
    position: "relative",
    margin: "40px 0",
    border: ({ isEditable }) =>
      isEditable
        ? `1px solid ${theme.DragAndDropActivityQuestion.innerPageContainerBorderColor}`
        : "none",
    padding: 20,
    width: 414,
    height: 737,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.DragAndDropActivityQuestion.innerPageContainerBackgroundColor,
  },
  deleteButton: {
    fontFamily: theme.FontFamily,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "baseline",
    alignSelf: "flex-start",
    position: "absolute",
    top: 20,
    right: 10,
    cursor: "pointer",
    "& > span": {
      fontSize: 14,
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: 0.07,
      color: theme.DragAndDropActivityQuestion.deleteButtonColor,
      "&::before": {
        color: theme.DragAndDropActivityQuestion.deleteButtonColor,
        fontSize: 15,
        padding: 5,
      },
    },
    "&:hover *": {
      color: theme.DragAndDropActivityQuestion.deleteButtonHoverColor,
      "&::before": {
        color: theme.DragAndDropActivityQuestion.deleteButtonHoverColor,
      },
    },
  },
  textInput: {
    fontFamily: theme.FontFamily,
    fontSize: 23,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.17,
    letterSpacing: "normal",
    color: theme.DragAndDropActivityQuestion.textInputColor,
    textAlign: "center",
    outline: "unset",
    cursor: "pointer",
    "&[contenteditable]:empty:before": {
      content: "attr(placeholder)",
      display: "block",
    },
    overflow: "hidden",
  },
  questionInput: {
    width: 374,
    height: 80,
    margin: "70px 0",
    textAlign: "center",
  },
  zonesContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "100%",
  },
  zoneTextInput: {
    maxHeight: 80,
    marginBottom: 10,
    maxWidth: 180,
    overflow: "hidden",
    overflowWrap: "break-word",
  },
  zonePlaceholderBox: {
    display: "inline-block",
    width: 180,
    height: 180,
    borderRadius: 5,
    border: `1px solid ${theme.DragAndDropActivityQuestion.zonePlaceholderBoxBorderColor}`,
  },
});
