import { PlaceHolder } from "assets/icons";

const contentEditablePlaceholderStyle = {
  "&[contenteditable]:empty:before": {
    content: "attr(placeholder)",
    display: "block",
    color: "#a0a0a0",
  },
};

const cursorPointer = {
  cursor: (props) => (props.isSelected ? "pointer" : "default"),
};

export const markerSize = {
  width: 29,
  height: 29,
};

export const dragAreaSize = {
  width: 58,
  height: 58,
};

export const popupSize = {
  width: 140,
  minHeight: 126,
};

const imageContainerSize = {
  width: "100%",
  height: 90,
};

export default ({ SlideMarker, FontFamily }) => ({
  container: {
    position: "relative",
  },
  markerCircle: {
    display: "flex",
    width: markerSize.width,
    height: markerSize.height,
    borderRadius: "100%",
    backgroundColor: SlideMarker.backgroundColor,
    color: SlideMarker.iconColor,
    cursor: "pointer",
  },
  markerIcon: ({ isOpened }) => ({
    fontSize: isOpened ? 12 : 15,
    margin: "auto",
  }),
  dragArea: ({ isDragged, isDropTargetSafe }) => ({
    position: "absolute",
    top: (markerSize.width - dragAreaSize.width) / 2,
    left: (markerSize.height - dragAreaSize.height) / 2,
    display: isDragged ? "inline-block" : "none",
    ...dragAreaSize,
    backgroundColor: isDropTargetSafe
      ? SlideMarker.safeDropTargetColor
      : SlideMarker.unSafeDropTargetColor,
  }),
  popUp: {
    display: "none",
    position: "absolute",
    top: markerSize.height,
    left: markerSize.width,
    ...popupSize,
    borderRadius: 6,
    boxShadow: `0 3px 14px 1px ${SlideMarker.popUpBoxShadowColor}`,
    backgroundColor: SlideMarker.popUpBackgroundColor,
    overflow: "hidden",
    cursor: "default",
  },
  popUpVisible: {
    display: "inline-block",
  },
  popUpError: {
    border: "1px solid red",
  },
  deleteButton: {
    position: "absolute",
    top: 5,
    right: 7,
    "& > span:first-of-type:before": {
      color: SlideMarker.actionButtonColor,
    },
    fontSize: 11,
    cursor: "pointer",
    zIndex: 1,
  },
  imageContainer: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: imageContainerSize.height,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: SlideMarker.imageContainerBackgroundColor,
    backgroundImage: ({ image }) => `url("${image || PlaceHolder}")`,
    backgroundSize: "contain",
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
  },
  uploadButton: {
    position: "absolute",
    top: 5,
    left: 5,
    fontSize: 14,
    letterSpacing: 0.07,
    "& > span:first-of-type:before": {
      color: SlideMarker.actionButtonColor,
      fontSize: 15,
      marginLeft: 5,
      marginRight: 5,
    },
  },
  cropButton: {
    position: "absolute",
    left: 30,
    top: 7,
    cursor: "pointer",
    color: SlideMarker.actionButtonColor,
    fontSize: 14,
    marginLeft: 5,
    marginRight: 5,
  },
  descriptionInputContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: popupSize.height - imageContainerSize.height,
    maxHeight: "50px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "0 .24rem",
  },
  descriptionInput: {
    width: "95%",
    maxHeight: "100%",
    fontFamily: FontFamily,
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: SlideMarker.descriptionInputColor,
    outline: "unset",
    overflow: "hidden",
    ...contentEditablePlaceholderStyle,
    ...cursorPointer,
  },
});
