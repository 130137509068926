import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { SubTitleStyle } from "./SubTitle.style";
import ContentEditable from "react-contenteditable";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(SubTitleStyle);

const SubTitle = (props) => {
  const classes = useThemedStyle(useStyle, props);

  return (
    <ContentEditable
      html={props.value}
      disabled={false}
      onBlur={props.handleChange}
      onKeyDown={props.handleKeyDown}
      tagName="article"
      className={classes.input}
    />
  );
};

SubTitle.propTypes = {
  /**  value by default shiped with the input */
  value: PropTypes.string,
  /**  place holder value */
  placeholder: PropTypes.string,
  /**  handle input change value */
  handleChange: PropTypes.func,
  /** the method that controls the keydown of the input */
  handleKeyDown: PropTypes.func,
  /** font family, that overrides the default font-family used by the editor **/
  fontFamily: PropTypes.string,
};

SubTitle.defaultProps = {
  value: "",
};

export default SubTitle;
