import { createMultipleSheetXml } from "../excelFactory";
import { translations as leaderBoardTranslations } from "../../../pages/results/battle/battleLeaderboard/BattleLeaderboard.translations";
import { translations as publishedBattlesTranslations } from "../../../pages/kpiBattle/battlePublishedTable/BattlePublishedTable.translations";
import { exportTranslations as battleInfoTranslations } from "../../../pages/results/battle/BattleResults.translations";
import translations from "./kpiBattleExport.translations";
import { getFormattedSopiDate } from "../../dateUtils";

const constructDataLeaderboard = (
  intl,
  leaderBoardTranslations,
  leaderBoardData,
  profiles,
) => [
  [
    [intl.formatMessage(leaderBoardTranslations.UsersColumnTitle)],
    [intl.formatMessage(leaderBoardTranslations.AttemptsColumnTitle)],
    [intl.formatMessage(leaderBoardTranslations.TotalScoreColumnTitle)],
    [intl.formatMessage(leaderBoardTranslations.HighestScore)],
    [intl.formatMessage(leaderBoardTranslations.AverageScoreColumnTitle)],
    [intl.formatMessage(leaderBoardTranslations.EmailTitle)],
    [intl.formatMessage(leaderBoardTranslations.RelationshipTitle)],
    [intl.formatMessage(leaderBoardTranslations.MarketTitle)],
    [intl.formatMessage(leaderBoardTranslations.RetailerTitle)],
    [intl.formatMessage(leaderBoardTranslations.BrandsTitle)],
  ],
  ...leaderBoardData.map((user) => [
    [user.userName],
    [user.attempts],
    [user.totalScore],
    [user.highestScore],
    [user.averageScore],
    [profiles[user.key]?.user.email || "-"],
    [profiles[user.key]?.relationship || "-"],
    [profiles[user.key]?.user.market || "-"],
    [profiles[user.key]?.retailers || "-"],
    [profiles[user.key]?.brands || "-"],
  ]),
];

export const exportLeaderBoardAndPublishedBattles = (
  leaderBoardData,
  profiles,
  publishedBattles,
  intl,
) => {
  const sheets = [
    {
      name: intl.formatMessage(translations.LeaderboardTitle),
      data: constructDataLeaderboard(
        intl,
        leaderBoardTranslations,
        leaderBoardData,
        profiles,
      ),
    },
    {
      name: intl.formatMessage(translations.PublishedTitle),
      data: [
        [
          [
            intl.formatMessage(
              publishedBattlesTranslations.BattleNameColumnTitle,
            ),
          ],
          [
            intl.formatMessage(
              publishedBattlesTranslations.NumberOfParticipatedColumnTitle,
            ),
          ],
          [
            intl.formatMessage(
              publishedBattlesTranslations.AverageNumberOfPointsColumnTitle,
            ),
          ],
        ],
        ...publishedBattles.map((battle) => [
          [battle.battleName],
          [battle.numberOfParticipatedUsers],
          [battle.averageNumberOfPoints],
        ]),
      ],
    },
  ];

  getFormattedSopiDate(new Date());
  createMultipleSheetXml(
    sheets,
    intl.formatMessage(translations.FileName, {
      date: getFormattedSopiDate(new Date()),
    }),
  );
};

export const exportSpecifiedKpiBattleData = (
  leaderBoardData,
  profiles,
  battleInfo,
  intl,
) => {
  const sheets = [
    {
      name: intl.formatMessage(translations.LeaderboardTitle),
      data: constructDataLeaderboard(
        intl,
        leaderBoardTranslations,
        leaderBoardData,
        profiles,
      ),
    },
    {
      name: intl.formatMessage(translations.DetailsTitle),
      data: [
        [
          [intl.formatMessage(battleInfoTranslations.NameColumnTitle)],
          [intl.formatMessage(battleInfoTranslations.StatusColumnTitle)],
          [intl.formatMessage(battleInfoTranslations.CreationDateColumnTitle)],
          [intl.formatMessage(battleInfoTranslations.ParticipantsColumnTitle)],
          [intl.formatMessage(battleInfoTranslations.AverageScoreColumnTitle)],
          [intl.formatMessage(battleInfoTranslations.MarketsColumnTitle)],
          [intl.formatMessage(battleInfoTranslations.BrandsColumnTitle)],
        ],
        [
          [battleInfo.name],
          [battleInfo.status],
          [battleInfo.creationDate],
          [battleInfo.participants],
          [battleInfo.participantsAverage],
          [battleInfo.markets.map((market) => market.label).join(", ")],
          [battleInfo.brands.map((brand) => brand.label).join(", ")],
        ],
      ],
    },
  ];

  createMultipleSheetXml(
    sheets,
    intl.formatMessage(translations.FileNameWithDocument, {
      date: getFormattedSopiDate(new Date()),
      docTitle: battleInfo.name,
    }),
  );
};
