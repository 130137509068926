import { defineMessages } from "react-intl";

export const imageTypeNamesTranslations = defineMessages({
  FullWidthText: {
    id: "SideBarImageTemplate.FullWidthText",
    defaultMessage: "Full width",
  },
  InContainerText: {
    id: "SideBarImageTemplate.InContainerText",
    defaultMessage: "In container",
  },
  FullScreenText: {
    id: "SideBarImageTemplate.FullScreenText",
    defaultMessage: "Full screen",
  },
});

export const translations = defineMessages({
  TemplateTitle: {
    id: "SideBarImageTemplate.TemplateTitle",
    defaultMessage: "Image",
  },
});
