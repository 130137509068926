import React from "react";
import { SideComponentBarStyle } from "./SideComponentBar.style";
import { Icon, Icons } from "genius-ui";
import classnames from "classnames";
import { FormattedMessage, injectIntl } from "react-intl";
import compose from "lodash/fp/compose";
import {
  TextTemplate,
  ImageTemplate,
  MultimediaTemplate,
  SliderTemplate,
  ScrollImagesTemplate,
  ActivitiesTemplate,
} from "./templates";
import uuidv4 from "uuid/dist/v4";
import { connect } from "react-redux";
import { translations } from "./SideComponentBar.translations";
import withThemedStyle from "../hoc/withThemedStyle";

const ComponentItems = (props) => [
  {
    Key: 1,
    Name: props.intl.formatMessage({ ...translations.TextMenuText }),
    Icon: Icons.text,
    Style: props.classes.iconText,
  },
  {
    Key: 2,
    Name: props.intl.formatMessage({ ...translations.ImageMenuText }),
    Icon: Icons.images,
    Style: props.classes.iconImage,
  },
  {
    Key: 3,
    Name: props.intl.formatMessage({ ...translations.MultimediaMenuText }),
    Icon: Icons.multimedia,
    Style: props.classes.iconVideo,
  },
];

class SideComponentBar extends React.PureComponent {
  state = {
    selectedTemplateId: "",
  };

  componentTemplates = {
    ScrollImages: ScrollImagesTemplate(this.props),
    Slider: SliderTemplate(this.props),
    Activities: ActivitiesTemplate(this.props),
    Text: TextTemplate(this.props),
    Image: ImageTemplate(this.props),
    Multimedia: MultimediaTemplate(this.props),
  };

  setSelectedTemplateId = (templateId) => {
    if (templateId === this.state.selectedTemplateId && templateId !== "") {
      // if clicking twice on the same menu item, hide the template selection bar of the menu item
      return this.resetSelectedTemplateId();
    }

    this.setState({
      selectedTemplateId: templateId,
    });
  };

  resetSelectedTemplateId = () => this.setSelectedTemplateId("");

  handleSliderClick = () => this.setSelectedTemplateId("Slider");

  handleScrollImagesClick = () => this.setSelectedTemplateId("ScrollImages");

  handleActivitiesClick = () => this.setSelectedTemplateId("Activities");

  render() {
    const props = this.props;
    let { classes } = props;

    const selectedTemplate =
      this.componentTemplates[this.state.selectedTemplateId];

    const selectedTemplateClass = classnames([
      classes.selectTemplate,
      {
        [classes.selectTemplateAnimateIn]: !!selectedTemplate,
        [classes.selectTemplateAnimateOut]: !selectedTemplate,
      },
    ]);

    const verticalScrollContainerClassName = classnames([
      classes.li,
      classes.scrollImages,
    ]);

    return (
      <nav className={classes.navigation}>
        <div className={classes.mainContainer}>
          <div className="NavMainContent">
            <div className={classes.back} onClick={props.handleBackClick}>
              <Icon
                iconName={Icons.arrowLeft}
                alt=""
                className={classes.iconMenu}
              />
            </div>
            <ul className={classes.ul}>
              {ComponentItems(props).map((menuItem) => (
                <li
                  className={classes.li}
                  key={menuItem.Key}
                  onClick={() => {
                    this.setSelectedTemplateId(menuItem.Name);
                    menuItem.OnClick && menuItem.OnClick();
                  }}
                >
                  <Icon iconName={menuItem.Icon} style={menuItem.Style} />
                  <span className={classes.iconLabel}>{menuItem.Name}</span>
                </li>
              ))}
              <li
                className={classes.li}
                key={4}
                onClick={this.handleSliderClick}
              >
                <span className={Icons.slider + " " + classes.iconSlider}>
                  <span className={classnames("path1", classes.sliderPath1)} />
                  <span className={classnames("path2", classes.sliderPath2)} />
                  <span className={classnames("path3", classes.sliderPath3)} />
                </span>
                <span className={classes.iconLabel}>
                  <FormattedMessage {...translations.SliderMenuText} />
                </span>
              </li>
              <li
                className={verticalScrollContainerClassName}
                key={5}
                onClick={this.handleScrollImagesClick}
              >
                <span className={Icons.scrollImages}>
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                </span>
                <span className={classes.iconLabel}>
                  <FormattedMessage {...translations.ScrollImagesMenuText} />
                </span>
              </li>
              <li
                className={verticalScrollContainerClassName}
                key={6}
                onClick={this.handleActivitiesClick}
              >
                <Icon iconName={Icons.settings} style={classes.iconSettings} />
                <span className={classes.iconLabel}>
                  <FormattedMessage {...translations.ActivitiesMenuText} />
                </span>
              </li>
            </ul>
          </div>
          <div className={selectedTemplateClass}>
            <div className="selectTemplateTitle">
              {selectedTemplate && selectedTemplate.title}
            </div>
            <div className="scrollableTemplateContainer">
              {selectedTemplate &&
                selectedTemplate.items.map((template) => (
                  <div
                    key={uuidv4()}
                    onClick={() => {
                      this.resetSelectedTemplateId();
                      template.onSelect();
                    }}
                    className="selectTemplateItem"
                  >
                    <div>
                      <p>{template.name}</p>
                      <FormattedMessage
                        {...translations.TemplateThumbnailAltMessage}
                      >
                        {(message) => (
                          <img src={template.thumbnail} alt={message[0]} />
                        )}
                      </FormattedMessage>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProp = (state) => ({
  currentDocLang: state.documents.currentLanguage,
});

export default compose(
  injectIntl,
  connect(mapStateToProp),
  withThemedStyle(SideComponentBarStyle),
)(SideComponentBar);
