import React from "react";
import { TextPreviews } from "assets/icons";
import { CONTROL_TYPES } from "../../../../configs/constants";
import { FormattedMessage } from "react-intl";
import { translations } from "./Text.translations";

const TextTemplate = (props) => ({
  title: <FormattedMessage {...translations.TemplateTitle} />,
  items: [
    {
      name: <FormattedMessage {...translations.TitleText} />,
      thumbnail: TextPreviews.title,
      onSelect: () => {
        props.handleTextClick(CONTROL_TYPES.TITLE);
      },
    },
    {
      name: <FormattedMessage {...translations.SubtitleText} />,
      thumbnail: TextPreviews.subtitle,
      onSelect: () => {
        props.handleTextClick(CONTROL_TYPES.SUBTITLE);
      },
    },
    {
      name: <FormattedMessage {...translations.ParagraphText} />,
      thumbnail: TextPreviews.paragraph,
      onSelect: () => {
        props.handleTextClick(CONTROL_TYPES.REGULAR);
      },
    },
    {
      name: <FormattedMessage {...translations.LinkText} />,
      thumbnail: TextPreviews.link,
      onSelect: () => {
        props.handleTextClick(CONTROL_TYPES.LINK);
      },
    },
  ],
});

export default TextTemplate;
