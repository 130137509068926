import { NoImageAvailable } from "assets/icons";

export const getFormattedMasterTemplatesData = (
  {
    data: {
      admin: { draft, published },
    },
  },
  { language },
) => {
  return [...published, ...draft]
    .reduce((templates, { versions }) => {
      const actualVersion = versions.find(
        (version) => version.lang === language.selectedValue,
      );
      const availableLanguages = versions.map((version) => version.lang);
      if (actualVersion) {
        templates.push({
          ...actualVersion,
          id: actualVersion.degreeId,
          picCover: actualVersion.picCover || NoImageAvailable,
          date: actualVersion.updateDate,
          // TODO: get program and lesson numbers from the graphql
          programs: 6,
          lessons: 30,
          availableLanguages,
        });
      }
      return templates;
    }, [])
    .sort((x, y) => (Number(x.id) > Number(y.id) ? -1 : 1));
};

export const getExistingMarketTagIds = (documents, language) => {
  const existingTags = documents.reduce((marketTagIds, { versions }) => {
    const actualVersion = versions.find(
      (version) => version.lang === language.selectedValue,
    );
    marketTagIds =
      actualVersion && actualVersion.tags
        ? [
            ...marketTagIds,
            ...actualVersion.tags.map((tag) => parseInt(tag.tagId)),
          ]
        : [...marketTagIds];
    return marketTagIds;
  }, []);

  return [...new Set(existingTags)];
};

export const getFormattedDegreeBadges = (badges) => {
  return badges.map((badge) => ({
    badgeId: parseInt(badge.badgeId),
    badgeImage: badge.pic,
    badgeName: badge.title,
    lessonIds: [],
  }));
};

export const getFormattedDegreeDetails = (degrees, language) => {
  const actualVersion = degrees[0].versions.find(
    (version) => version.lang === language,
  );
  return {
    title: actualVersion.title,
  };
};

export const getFormattedDegreeLessons = (degree, language) => {
  const actualVersion = degree.versions.find(
    (version) => version.lang === language,
  );
  if (actualVersion) {
    return actualVersion.programs.reduce((lessons, program) => {
      lessons = [
        ...lessons,
        ...program.lessons.map((lesson) => ({
          id: parseInt(lesson.lessonId),
          status: lesson.workflow,
          lessonImage: lesson.picCover,
          lessonTitle: lesson.title,
          lessonDescription: lesson.summary,
        })),
      ];
      return lessons;
    }, []);
  }
  return [];
};

export const GetFormattedLesson = (lesson, language) => {
  const actualVersion = lesson.versions.find(
    (version) => version.lang === language,
  );
  if (actualVersion) {
    return [
      {
        id: parseInt(lesson.lessonId),
        status: actualVersion.workflow,
        lessonImage: actualVersion.picCover,
        lessonTitle: actualVersion.title,
        lessonDescription: actualVersion.summary,
      },
    ];
  }
  return [];
};
