export const formatBadgesToMutate = (badges, type, score) =>
  Object.keys(badges).flatMap((key) =>
    badges[key].map((lessonId) =>
      type === "doc"
        ? { docId: lessonId, badgeId: Number(key) }
        : { [`${type}Id`]: lessonId, badgeId: Number(key), score },
    ),
  );

const badgeFilter = (toFilter, filterIds) =>
  toFilter.filter((id) => !filterIds.includes(id));
const badgeCompareTypes = (toFilter, filterIds) =>
  toFilter.filter((id) => filterIds.includes(id));

export const getLessonsToRemove = (badges, initialBadges) =>
  badges.reduce((toRemove, { badgeId, lessonIds, quizz: quizzIds }, index) => {
    const idsToRemove = [
      ...badgeCompareTypes(initialBadges[index].lessonIds, quizzIds),
      ...badgeFilter(initialBadges[index].lessonIds, lessonIds),
      ...badgeFilter(initialBadges[index].quizz, quizzIds),
    ];

    if (idsToRemove.length) toRemove[badgeId] = idsToRemove;
    return toRemove;
  }, {});

export const getLessonsToAdd = (badges, initialBadges) =>
  initialBadges.reduce(
    (
      { lessonsIdsToAdd, quizzIdsToAdd },
      { badgeId, lessonIds, quizz: quizzIds },
      index,
    ) => {
      const lessonsIdsToAddTmp = badgeFilter(
        badges[index].lessonIds,
        lessonIds,
      );
      const quizzIdsToAddTmp = badgeFilter(badges[index].quizz, quizzIds);

      if (lessonsIdsToAddTmp.length)
        lessonsIdsToAdd[badgeId] = lessonsIdsToAddTmp;
      if (quizzIdsToAddTmp.length) quizzIdsToAdd[badgeId] = quizzIdsToAddTmp;
      return { lessonsIdsToAdd, quizzIdsToAdd };
    },
    { lessonsIdsToAdd: {}, quizzIdsToAdd: {} },
  );

export const handleBadgeSelection = (state, data) => {
  const { lessonId, badgeId, isQuizz } = data;

  return state.badges.map((badge) => {
    const { lessonIds, quizz, badgeId: stateBadgeId } = badge;

    if (badgeId !== stateBadgeId) return badge;

    const clicked = isQuizz ? quizz : lessonIds;
    const inactive = isQuizz ? lessonIds : quizz;
    const included = clicked.includes(lessonId);

    return {
      ...badge,
      [isQuizz ? "quizz" : "lessonIds"]: included
        ? clicked.filter((docId) => docId !== lessonId)
        : [...clicked, lessonId],
      [isQuizz ? "lessonIds" : "quizz"]: !isQuizz
        ? inactive
        : included
        ? inactive.filter((docId) => docId !== lessonId)
        : inactive.includes(lessonId)
        ? inactive
        : [...inactive, lessonId],
    };
  });
};
