export const DegreesBuilderStyle = ({ DegreesBuilderBackupScreen }) => ({
  rootContainer: {
    padding: "150px 0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    height: "100%",
  },
  rootContent: {
    width: "50%",
    minWidth: "950px",
  },
  closePage: {
    position: "fixed",
    top: 40,
    left: 40,
  },
  closeIcon: {
    color: DegreesBuilderBackupScreen.closeIconColor,
    "&::before": {
      color: DegreesBuilderBackupScreen.closeIconColor,
      fontSize: 20,
    },
    "&:hover::before": {
      color: DegreesBuilderBackupScreen.closeIconHoverColor,
      cursor: "pointer",
    },
  },
});
