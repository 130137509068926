export default (theme) => ({
  container: {
    cursor: "pointer",
    width: 40,
    height: 38,
    borderRadius: 5,
    border: `solid 1px ${theme.DownloadButton.borderColor}`,
    backgroundColor: theme.DownloadButton.background,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > span:before": {
      fontSize: 18,
      color: theme.DownloadButton.icon,
    },
  },
});
