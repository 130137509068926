import gql from "graphql-tag";

export const GET_NINJAS_KPI = gql`
  query getNinjas(
    $workflow: DocWorkflowEnum
    $tagIds: [[ID!]!]
    $skip: Int
    $limit: Int
  ) {
    admin {
      ninjas(workflow: $workflow, tagIds: $tagIds, skip: $skip, limit: $limit) {
        versions {
          ninjaId
          title
          allUserData {
            activity {
              users {
                userId
              }
            }
          }
          tags {
            title
            clusterId
          }
        }
      }
    }
  }
`;
