export const UPDATE_FILTER_SELECTED_VALUES = "UPDATE_FILTER_SELECTED_VALUES";
export const UPDATE_FILTER_ORDER_BY = "UPDATE_FILTER_ORDER_BY";
export const UPDATE_FILTER_SKIP = "UPDATE_FILTER_SKIP";
export const UPDATE_INTERVALS = "UPDATE_INTERVALS";
export const UPDATE_SELECTED_INTERVAL = "UPDATE_SELECTED_INTERVAL";
export const REMOVE_FILTER_SELECTED_VALUES = "REMOVE_FILTER_SELECTED_VALUES";
export const RESET_FILTERS = "RESET_FILTERS";
export const CLEAR_COUNTRY_TAGS = "CLEAR_COUNTRY_TAGS";
export const CLEAR_CITIES_TAGS = "CLEAR_CITIES_TAGS";
export const CLEAR_SHOPS_TAGS = "CLEAR_SHOPS_TAGS";
export const CLEAR_RETAILER_TAGS = "CLEAR_RETAILER_TAGS";
export const CLEAR_SERVICES_TAGS = "CLEAR_SERVICES_TAGS";
export const CLEAR_ROLES_TAGS = "CLEAR_ROLES_TAGS";
export const SET_IS_EXPORT_LOADING = "SET_IS_EXPORT_LOADING";
export const SET_IS_LOADING = "SET_IS_LOADING";
