import gql from "graphql-tag";

export const DEGREE_UPDATE_WORFLOW = gql`
  mutation degreeUpdateWorkflow(
    $degreeId: ID
    $lang: LangEnum
    $status: DocWorkflowEnum!
    $dateIn: Date
    $dateOut: Date
  ) {
    admin {
      degree(degreeId: $degreeId, lang: $lang) {
        workflow {
          set(status: $status, dateIn: $dateIn, dateOut: $dateOut)
        }
      }
    }
  }
`;
