import client from "../../graphql/client";
import {
  UPDATE_FILTER_SELECTED_VALUES,
  UPDATE_PAGE_DATA,
  UPDATE_FILTER_AVAILABLE_VALUES,
  UPDATE_ALL_LESSONS,
  RESET_LEARNING_DATA,
} from "../../constants/store/kpiLearningDetails";
import { SetErrorMessage } from "../notification/actions";
import { kpiLearningDocumentToKpiLearningPageData } from "./transformers";
import { DEGREES_GET_SHORT_DETAIL_LIST } from "../../graphql/remote/degrees/queries/GetShortDetailList";
import { SetFetchIndicator } from "../common/actions";
import clientConfig, { isShiseido } from "../../configs/client";
import {
  extractFilterValues,
  getFormattedTagList,
  getPeriodValue,
} from "../actions/common/kpi";
import { GET_LEARNING_KPI } from "../../graphql/remote/kpi/queries/learning.graphql";
import { USER_ROLE_NAMES } from "../../configs/constants";
import {
  extendTagsWithUserTags,
  filterTagsByTrainerTags,
} from "../../utils/transformers";
import { SetIsLoading } from "store/kpiFilter/actions";

export const UpdatePageData = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PAGE_DATA,
    data,
  });
};

export const UpdateAllLessons = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_ALL_LESSONS,
    data,
  });
};

export const UpdateFilterAvailableValues =
  (filterName, availableValues) => (dispatch) => {
    dispatch({
      type: UPDATE_FILTER_AVAILABLE_VALUES,
      data: { filterName, availableValues },
    });
  };

export const UpdateFilterSelectedValues =
  (filterName, selectedValues) => (dispatch) => {
    dispatch({
      type: UPDATE_FILTER_SELECTED_VALUES,
      data: { filterName, selectedValues },
    });
  };

export const GetPageData = () => async (dispatch, getState) => {
  const { profileInfo } = getState().auth;

  try {
    dispatch(SetFetchIndicator(true));
    dispatch(SetIsLoading(true));
    const {
      filters,
      learningDetails: {
        filters: {
          degree: { selectedValues: degreeSelectedValues },
        },
      },
    } = getState().kpi;
    const learningFilterValues = extractFilterValues(filters);

    /** If user is a trainer always get data from own markets */
    if (isShiseido && profileInfo.roles.includes(USER_ROLE_NAMES.TRAINER)) {
      const markets = filterTagsByTrainerTags(
        profileInfo.tags,
        getState().tags.markets,
      );
      learningFilterValues.market = extendTagsWithUserTags(
        learningFilterValues.market,
        markets,
      );
    }

    const tagList = getFormattedTagList(learningFilterValues);
    const period = getPeriodValue(filters.period, false);
    const aggregatedPeriodType = getPeriodValue(filters.period, true);
    const degrees = degreeSelectedValues.map(({ value }) => parseInt(value));

    const variables = {
      fromDate: period || learningFilterValues.startDate,
      toDate: learningFilterValues.endDate,
      tags: tagList.length ? tagList : undefined,
      degrees: degrees && degrees.length > 0 ? degrees : undefined,
    };

    if (isShiseido && aggregatedPeriodType) {
      variables.aggregatedType = aggregatedPeriodType;
      variables.date = period;
      variables.fromDate = undefined;
      variables.toDate = undefined;
    }

    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_LEARNING_KPI,
      variables,
    });
    if (result.data.learningKPI) {
      dispatch(
        UpdatePageData(
          kpiLearningDocumentToKpiLearningPageData(result.data.learningKPI),
        ),
      );
    } else {
      dispatch({ type: RESET_LEARNING_DATA });
    }
    dispatch(SetIsLoading(false));
  } catch (e) {
    dispatch(
      SetErrorMessage(
        "An error occurred while fetching the kpi learning data",
        e,
      ),
    );
  } finally {
    dispatch(SetFetchIndicator(false));
  }
};

export const GetAvailableDegrees = () => async (dispatch, getState) => {
  try {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: DEGREES_GET_SHORT_DETAIL_LIST,
      variables: {
        tagIds: clientConfig.MASTER_DEGREE_TAG_ID
          ? [clientConfig.MASTER_DEGREE_TAG_ID]
          : [],
      },
    });

    dispatch(
      UpdateFilterAvailableValues("degree", [
        ...new Set(result.data.admin.degrees),
      ]),
    );
  } catch (e) {
    dispatch(
      SetErrorMessage(
        "An error occurred while fetching the available degrees for the kpi learning page degree filter",
        e,
      ),
    );
  }
};

export const sort2ProgramsByValidationNb = (a, b, orderDesc) => {
  const totalUsersNbForA = a.lessons?.reduce(
    (acc, { allowedUsersNb }) => acc + allowedUsersNb,
    0,
  );
  const totalUsersNbForB = b.lessons?.reduce(
    (acc, { allowedUsersNb }) => acc + allowedUsersNb,
    0,
  );

  // Case 0 totalUsersNb, put it at the end or the list and avoid / 0
  if (!totalUsersNbForB) return 1;
  if (!totalUsersNbForA) return -1;

  // Case both validationNb are null, sort by allowedUsersNb
  if (!a.stats.validatedLessonNb && !b.stats.validatedLessonNb)
    return orderDesc
      ? totalUsersNbForA - totalUsersNbForB
      : totalUsersNbForB - totalUsersNbForA;

  return orderDesc
    ? b.stats.validatedLessonNb / totalUsersNbForB -
        a.stats.validatedLessonNb / totalUsersNbForA
    : a.stats.validatedLessonNb / totalUsersNbForA -
        b.stats.validatedLessonNb / totalUsersNbForB;
};

export const OrderProgramsByLessonsCompletion =
  (orderDesc) => (dispatch, getState) => {
    let { programs } = getState().kpi.learningDetails;
    programs = programs.sort((a, b) =>
      sort2ProgramsByValidationNb(a, b, orderDesc),
    );
    dispatch(UpdatePageData(programs));
  };

export const sort2LessonsByValidationNb = (a, b, orderDesc) => {
  // Case 0 allowedUserNb, put it at the end or the list and avoid / 0
  if (!b.allowedUsersNb) return 1;
  if (!a.allowedUsersNb) return -1;
  // Case both validationNb are null, sort by allowedUsersNb
  if (!a.stats.validationNb && !b.stats.validationNb)
    return orderDesc
      ? a.allowedUsersNb - b.allowedUsersNb
      : b.allowedUsersNb - a.allowedUsersNb;

  return orderDesc
    ? b.stats.validationNb / b.allowedUsersNb -
        a.stats.validationNb / a.allowedUsersNb
    : a.stats.validationNb / a.allowedUsersNb -
        b.stats.validationNb / b.allowedUsersNb;
};

export const OrderLessonsByUsersCompletion =
  (orderDesc, programId) => (dispatch, getState) => {
    let { programs } = getState().kpi.learningDetails;
    programs = programs.map((program) => {
      return program.id === programId
        ? program.lessons.sort((a, b) =>
            sort2LessonsByValidationNb(a, b, orderDesc),
          )
        : program;
    });
    dispatch(UpdatePageData(programs));
  };

export const OrderAllLessonsByUsersCompletion =
  (orderDesc) => (dispatch, getState) => {
    const { allLessons } = getState().kpi.learningDetails;
    allLessons[0].lessons.sort((a, b) =>
      sort2LessonsByValidationNb(a, b, orderDesc),
    );
    dispatch(UpdateAllLessons(allLessons));
  };
