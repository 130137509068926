import React from "react";
import PropTypes from "prop-types";
import { LessonItemStyle } from "./LessonItem.style";
import withThemedStyle from "../../../../../components/hoc/withThemedStyle";
import { Checkbox } from "genius-ui";
import Image from "../../../../../components/common/Image";
import { PlaceHolderDegree } from "assets/icons";
import { FormattedMessage } from "react-intl";
import { translations } from "../../../../../components/SideComponentBar/templates/text/Text.translations";
import { encodeNewLines } from "../../../../../utils/string";

const LessonItem = ({
  classes,
  status,
  id,
  onCheck,
  lessonImage,
  lessonTitle,
  lessonDescription,
  checked,
  onCheckQuizz,
  quizzChecked,
}) => {
  return (
    <div className={classes.rootContainer}>
      <div>
        <Checkbox label="" handleChange={() => onCheck(id)} checked={checked} />
        <div className="details">
          <Image width={50} height={65} url={lessonImage} className="image" />
          <div>
            <div className="lessonTitle">{encodeNewLines(lessonTitle)}</div>
            <div className="lessonDescription">
              {encodeNewLines(lessonDescription)}
            </div>
            <Checkbox
              label={<FormattedMessage {...translations.IsActivityCheckbox} />}
              handleChange={() => onCheckQuizz(id)}
              checked={quizzChecked}
            />
          </div>
        </div>
      </div>
      <div className="status">{status}</div>
    </div>
  );
};

LessonItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onCheck: PropTypes.func.isRequired,
  lessonImage: PropTypes.string,
  lessonTitle: PropTypes.string,
  lessonDescription: PropTypes.string,
  checked: PropTypes.bool,
  quizzChecked: PropTypes.bool,
};

LessonItem.defaultProps = {
  status: "Status",
  lessonImage: PlaceHolderDegree,
  lessonTitle: "Lesson tile",
  lessonDescription: "Lesson description",
};

export default withThemedStyle(LessonItemStyle)(LessonItem);
