import { createXml } from "../excelFactory";
import { filterExportArray } from "./filter";
import { learningTranslations as translations } from "./Kpi.translations";
import { SetErrorMessage } from "../../../store/notification/actions";
import client from "../../../graphql/client";
import { GET_USERS_BASED_ON_LESSONS } from "../../../graphql/remote/users/queries/GetUsersBasedOnLessons";
import { extractFilterValues } from "../../../store/actions/common/kpi";

const exportFileName = "kpi-learning.xls";

export const exportLearning = (
  { programsNb, lessonsNb, programs },
  filterData,
  intl,
) => {
  let programsArray = concatProgramsArray(
    programs.map((element) => {
      return [
        [
          element.name,
          element.completedLessons,
          element.totalLessons,
          "",
          "",
          "",
        ],
      ].concat(
        element.lessons.map((lessonElement) => {
          return [
            "",
            "",
            "",
            lessonElement.name,
            lessonElement.stats.validationNb,
            lessonElement.allowedUsersNb,
          ];
        }),
      );
    }),
  );

  if (!programsArray) programsArray = [];

  const data = filterExportArray(filterData, intl).concat(
    [
      [intl.formatMessage(translations.PROGRAMS)],
      [intl.formatMessage(translations.NumberOfPrograms), programsNb],
      [],
      [intl.formatMessage(translations.LESSONS)],
      [intl.formatMessage(translations.NumberOfLessons), lessonsNb],
      [],
      [intl.formatMessage(translations.PROGRAMS)],
      [
        intl.formatMessage(translations.Program),
        intl.formatMessage(translations.CompletedLessons),
        intl.formatMessage(translations.TotalLessons),
        intl.formatMessage(translations.Lesson),
        intl.formatMessage(translations.Completed),
        intl.formatMessage(translations.AllowedUsers),
      ],
    ].concat(programsArray),
  );

  createXml(data, exportFileName);
};

const concatProgramsArray = (array) => {
  const concattedArray = array[0];
  for (let i = 1; i < array.length; i++) {
    for (let j = 0; j < array[i].length; j++) {
      concattedArray.push(array[i][j]);
    }
  }
  return concattedArray;
};

export const exportProgramsWithLessons = (programs, intl) => {
  const data = [
    [
      [intl.formatMessage(translations.DegreeName)],
      [intl.formatMessage(translations.ProgramName)],
      [intl.formatMessage(translations.ProgramCompletion)],
      [intl.formatMessage(translations.LessonName)],
      [intl.formatMessage(translations.CompletedUsers)],
      [intl.formatMessage(translations.AllUsers)],
    ],
    ...programs.reduce((lessons, program) => {
      lessons = [
        ...lessons,
        [
          program.degreeName,
          program.name,
          program.lessonCompletionPercentage + "%",
        ],
        ...program.lessons.map((lesson) => [
          [""],
          [""],
          [lesson.usersCompletionPercentage],
          [lesson.name],
          [lesson.stats.validationNb],
          [lesson.allowedUsersNb],
        ]),
      ];
      return lessons;
    }, []),
  ];
  createXml(data, exportFileName);
};

export const exportLessons = (lessons, intl) => {
  const data = [
    [
      [intl.formatMessage(translations.DegreeName)],
      [intl.formatMessage(translations.ProgramName)],
      [intl.formatMessage(translations.LessonName)],
      [intl.formatMessage(translations.CompletedUsers)],
      [intl.formatMessage(translations.AllUsers)],
      [intl.formatMessage(translations.CompletionRate)],
    ],
    ...lessons.map((lesson) => [
      [lesson.degreeName],
      [lesson.programName],
      [lesson.name],
      [lesson.stats.validationNb],
      [lesson.allowedUsersNb],
      [
        `${((lesson.stats.validationNb / lesson.allowedUsersNb) * 100).toFixed(
          2,
        )}%`,
      ],
    ]),
  ];
  createXml(data, exportFileName);
};

export const exportSelectedLesson =
  (lesson, intl) => async (dispatch, getState) => {
    const generalData = [
      [
        intl.formatMessage(translations.DegreeName),
        intl.formatMessage(translations.ProgramName),
        intl.formatMessage(translations.LessonName),
        intl.formatMessage(translations.CompletedUsers),
        intl.formatMessage(translations.AllUsers),
        intl.formatMessage(translations.TotalPages),
      ],
      [
        lesson.degreeName,
        lesson.programName,
        lesson.name,
        lesson.stats.validationNb,
        lesson.allowedUsersNb,
        lesson.pageNb,
      ],
      [],
      [],
    ];
    let usersHeader;
    let users;
    try {
      const { filters } = getState().kpi;
      const {
        market,
        relationship,
        retailer,
        zone,
        roles,
        countries,
        cities,
        shops,
        brands,
        division,
        entity,
        department,
        service,
        startDate,
        endDate,
        period,
      } = extractFilterValues(filters);

      const tags = [
        market,
        relationship,
        retailer,
        zone,
        roles,
        countries,
        cities,
        shops,
        brands,
        division,
        entity,
        department,
        service,
      ].filter((r) => r.tagIds.length > 0);

      const result = await client.query({
        fetchPolicy: "network-only",
        query: GET_USERS_BASED_ON_LESSONS,
        variables: {
          lessonId: lesson.id,
          fromDate: period || startDate,
          toDate: endDate,
          tags,
        },
      });
      usersHeader = [
        [
          intl.formatMessage(translations.LessonExportName),
          intl.formatMessage(translations.LessonExportEmail),
          intl.formatMessage(translations.LessonExportCompletionDate),
          intl.formatMessage(translations.LessonExportMarkets),
          intl.formatMessage(translations.LessonExportRelationships),
          intl.formatMessage(translations.LessonExportRetailers),
        ],
      ];
      users = result.data.lessonKPI.users.map((user) => [
        user.name,
        user.email,
        user.completionDate,
        (user.market && user.market.length && user.market.join(", ")) || "",
        (user.relationship &&
          user.relationship.length &&
          user.relationship.join(", ")) ||
          "",
        (user.retailer && user.retailer.length && user.retailer.join(", ")) ||
          "",
      ]);
    } catch (e) {
      dispatch(
        SetErrorMessage("An error occurred while fetching users for lesson", e),
      );
    }
    createXml(
      generalData.concat(usersHeader || []).concat(users || []),
      exportFileName,
    );
  };
