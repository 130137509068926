export default ({ CreateCardInstagramScreen, Colors }) => ({
  back: {
    zIndex: 99999,
    position: "fixed",
    top: 22,
    left: 40,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      color: Colors.lightBlue,
      cursor: "pointer",
    },
    "& > span": {
      "&::before": {
        color: CreateCardInstagramScreen.backButtonColor,
        fontSize: 17,
      },
      "&:hover::before": {
        color: Colors.lightBlue,
        cursor: "pointer",
      },
    },
    "&:hover *": {
      color: Colors.lightBlue,
      cursor: "pointer",
      "&::before": {
        color: Colors.lightBlue,
        cursor: "pointer",
      },
    },
  },
  headerContainer: {
    left: 0,
    "& > div": {
      width: 20,
    },
  },
  cardEditorContainer: {
    width: "100%",
    display: "flex",
    marginTop: 150,
    flexDirection: "row",
    justifyContent: "center",
  },
});
