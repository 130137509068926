import { PlaceHolderDegree } from "assets/icons";

export const ImageSelectorStyle = (theme) => ({
  imageContainer: {
    width: (props) => props.width,
    height: (props) => props.height,
    display: "flex",
    flexDirection: "column",
    border: (props) =>
      props.isSelected
        ? `solid 2px ${theme.ImageSelector.borderSelectedColor}`
        : "none",
    backgroundImage: (props) =>
      props.sourceImage && props.sourceImage !== ""
        ? `url("${props.sourceImage}")`
        : `url("${PlaceHolderDegree}")`,
    backgroundPosition: "center",
    backgroundSize: (props) => props.backgroundSize,
    backgroundRepeat: "no-repeat",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "start",
    textShadow: "white 1px 1px 2px, white 0px 0px 1em, white 0px 0px 0.2em",
  },
  loader: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  uploadControlsBox: {
    position: "relative",
    fontFamily: theme.FontFamily,
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: 0.07,
    color: "black",
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    paddingTop: 10,
    backgroundImage: `linear-gradient(to bottom, ${theme.ImageSelector.uploadControlsBoxBackgroundImageFirst}, ${theme.ImageSelector.uploadControlsBoxBackgroundImageSecond})`,
  },
  uploadBtnWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    "&:hover": {
      color: theme.ImageSelector.hoverColor,
      "& > span:before": {
        color: theme.ImageSelector.hoverColor,
      },
    },
    paddingLeft: 10,
  },
  uploadInput: {
    top: 0,
    left: 0,
    padding: 100,
    opacity: 0,
    display: ({ firstUpload }) => (firstUpload ? "none" : "block"),
    position: "absolute",
    cursor: "pointer",
  },
  btnUpload: {
    border: "none",
    fontSize: "14px",
    background: theme.ImageSelector.btnUploadBackgroundColor,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    color: "black",
    textShadow: "white 1px 1px 2px, white 0px 0px 1em, white 0px 0px 0.2em",
    cursor: "pointer",
    "&:hover": {
      color: theme.ImageSelector.hoverColor,
      cursor: "pointer",
    },
    paddingRight: 10,
    "& > span": {
      "&:hover::before": {
        color: theme.ImageSelector.hoverColor,
        cursor: "pointer",
      },
    },
    "&:hover *": {
      color: theme.ImageSelector.hoverColor,
      cursor: "pointer",
      "&::before": {
        color: theme.ImageSelector.hoverColor,
        cursor: "pointer",
      },
    },
  },
  iconAction: {
    marginRight: 5,
    cursor: "pointer",
    "&:hover::before": {
      color: `${theme.ImageSelector.hoverColor}`,
      fontSize: 15,
      cursor: "pointer",
    },
    "&::before": {
      color: "black",
      fontSize: 15,
      cursor: "pointer",
    },
  },
  remove: {
    cursor: "pointer",
    color: theme.ImageSelector.removeColor,
    fontSize: 18,
    bottom: 10,
    right: 10,
  },
  upload: {
    border: "solid 1px #ffffff",
    borderRadius: 3,
    backgroundColor: "rgba(42, 42, 42, 0.59)",
    "& > div": {
      overflow: "inherit",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "white",
        "& > div": {
          color: theme.ImageSelector.hoverColor,
        },
      },
      "& > input": {
        width: "100%",
        height: "100%",
        padding: 0,
        display: ({ firstUpload }) => (firstUpload ? "none" : "block"),
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  },
  centerDisplay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: 450,
    height: 300,
  },
  text: {
    padding: "10px 12px",
    textAlign: "center",
    color: "white",
  },
});
