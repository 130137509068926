import React from "react";
import { REACT_APP_CLIENT_NAME } from "../../configs/client";
import DefaultPublishPage from "./PublishLesson";
import ShiseidoPublishPage from "./SHISEIDO/PublishLesson";
import PublishPage from "../publish/index";

const PublishLesson = (props) => {
  switch (REACT_APP_CLIENT_NAME) {
    case "SHISEIDO":
    case "SHISEIDO-PREPROD":
    case "SHISEIDO-PROD":
      return <ShiseidoPublishPage />;
    default:
      // We should use this one
      // return (<PublishPage {...props} />)
      return <DefaultPublishPage />;
  }
};

export default PublishLesson;
