import { defineMessages } from "react-intl";
import clientConfig from "../../configs/client";

export default defineMessages({
  SearchAUserInputLabel: {
    id: "UsersTable.SearchAUserInputLabel",
    defaultMessage: "Search a user",
  },
  TotalUsers: {
    id: "UsersTable.TotalUsers",
    defaultMessage: "Total of registered users: ",
  },
  LoadingText: {
    id: "UsersTable.LoadingText",
    defaultMessage: "Loading users",
  },
  NoDataText: {
    id: "UsersTable.NoDataText",
    defaultMessage: "No data available",
  },
  DeleteUserMessage: {
    id: "UsersTable.DeleteUserMessage",
    defaultMessage: "Are you sure you want to delete this user{mark}",
  },
  TooltipCompleteStatusLabel: {
    id: "UsersTable.TooltipCompleteStatusLabel",
    defaultMessage: "Complete profiles",
  },
  TooltipDeleteStatusLabel: {
    id: "UsersTable.TooltipDeleteStatusLabel",
    defaultMessage: "Deleted profiles",
  },
  TooltipIncompleteStatusLabel: {
    id: "UsersTable.TooltipIncompleteStatusLabel",
    defaultMessage: "Incomplete profiles",
  },
});

export const filterLabels = defineMessages({
  TAGS_FILTER: {
    id: "UsersTable.TagFilterLabel",
    defaultMessage: "Tag filter",
  },
  ZONES_FILTER: {
    id: "UsersTable.ZONES_FILTER",
    defaultMessage: "Zones filter",
  },
  COUNTRIES_FILTER: {
    id: "UsersTable.TagCountriesFilterLabel",
    defaultMessage: "Countries filter",
  },
  RELATIONSHIP_FILTER: {
    id: "UsersTable.RelationshipFilterLabel",
    defaultMessage: "Filter by relationship",
  },
  MARKET_FILTER: {
    id: "UsersTable.MarketFilterLabel",
    defaultMessage: "Filter by market",
  },
  ZONE_FILTER: {
    id: "UsersTable.ZoneFilterLabel",
    defaultMessage: "Filter by zone",
  },
  DIVISION_FILTER: {
    id: "UsersTable.DivisionFilterLabel",
    defaultMessage: "Filter by division",
  },
  CITIES_FILTER: {
    id: "UsersTable.TagCitiesFilterLabel",
    defaultMessage: "Cities filter",
  },
});

const allEnvColumnLabels = {
  SHISEIDO: defineMessages({
    created_date: {
      id: "UserExportTableColumnLabels.SHISEIDO.created_date",
      defaultMessage: "Date Account Created",
    },
    email: {
      id: "UserExportTableColumnLabels.SHISEIDO.email",
      defaultMessage: "Email",
    },
    name: {
      id: "UserExportTableColumnLabels.SHISEIDO.name",
      defaultMessage: "Full Name",
    },
    relationship: {
      id: "UserExportTableColumnLabels.SHISEIDO.relationship",
      defaultMessage: "Relationship with Shiseido",
    },
    market: {
      id: "UserExportTableColumnLabels.SHISEIDO.market",
      defaultMessage: "Market",
    },
    city: {
      id: "UserExportTableColumnLabels.SHISEIDO.city",
      defaultMessage: "City",
    },
    retailer: {
      id: "UserExportTableColumnLabels.SHISEIDO.retailer",
      defaultMessage: "Retailer",
    },
    last_connection_date: {
      id: "UserExportTableColumnLabels.SHISEIDO.last_connection_date",
      defaultMessage: "Last co.",
    },
    badges: {
      id: "UserExportTableColumnLabels.SHISEIDO.badges",
      defaultMessage: "Badges",
    },
    old_score: {
      id: "UserExportTableColumnLabels.SHISEIDO.old_score",
      defaultMessage: "Old score",
    },
    new_score: {
      id: "UserExportTableColumnLabels.SHISEIDO.new_score",
      defaultMessage: "New score",
    },
    interests: {
      id: "UserExportTableColumnLabels.SHISEIDO.interests",
      defaultMessage: "Interests",
    },
    brands: {
      id: "UserExportTableColumnLabels.SHISEIDO.brands",
      defaultMessage: "Brands",
    },
    point_of_sales: {
      id: "UserExportTableColumnLabels.SHISEIDO.point_of_sales",
      defaultMessage: "Point of sales",
    },
    point_of_sales_address: {
      id: "UserExportTableColumnLabels.SHISEIDO.point_of_sales_address",
      defaultMessage: "Point of Sale Address",
    },
    home_building: {
      id: "UserExportTableColumnLabels.SHISEIDO.home_building",
      defaultMessage: "Mailing Address - building",
    },
    home_street: {
      id: "UserExportTableColumnLabels.SHISEIDO.home_street",
      defaultMessage: "Mailing Address - Street",
    },
    home_zip: {
      id: "UserExportTableColumnLabels.SHISEIDO.home_zip",
      defaultMessage: "Mailing Address - Post Code",
    },
    home_city: {
      id: "UserExportTableColumnLabels.SHISEIDO.home_city",
      defaultMessage: "Mailing Address - City",
    },
    home_country: {
      id: "UserExportTableColumnLabels.SHISEIDO.home_country",
      defaultMessage: "Mailing Address - Country",
    },
    birthday: {
      id: "UserExportTableColumnLabels.SHISEIDO.birthday",
      defaultMessage: "Birthday",
    },
    lang: {
      id: "UserExportTableColumnLabels.SHISEIDO.lang",
      defaultMessage: "Lang",
    },
    registration_code: {
      id: "UserExportTableColumnLabels.SHISEIDO.registration_code",
      defaultMessage: "Registration code",
    },
    pos: {
      id: "UserExportTableColumnLabels.SHISEIDO.pos",
      defaultMessage: "POS",
    },
    status: {
      id: "UserExportTableColumnLabels.SHISEIDO.status",
      defaultMessage: "Status",
    },
  }),
};

export const columnLabels =
  allEnvColumnLabels[clientConfig.TRANSLATION_NAMESPACE];

export const UserStatuses = defineMessages({
  CompleteStatusLabel: {
    id: "UsersTable.CompleteStatusLabel",
    defaultMessage: "Complete",
  },
  IncompleteStatusLabel: {
    id: "UsersTable.IncompleteStatusLabel",
    defaultMessage: "Incomplete",
  },
  DeletedStatusLabel: {
    id: "UsersTable.DeletedStatusLabel",
    defaultMessage: "Deleted",
  },
});
