import gql from "graphql-tag";

export const UPDATE_BATTLE_INFO = gql`
  mutation updateBattleInfo(
    $battleId: ID
    $lang: LangEnum
    $type: ContentTypeEnum!
    $data: String!
  ) {
    admin {
      battle(battleId: $battleId, lang: $lang) {
        set(type: $type, data: $data)
      }
    }
  }
`;
