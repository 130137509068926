import { defineMessages } from "react-intl";

export const REACTION_TRAD_HACK = {
  heart: "love",
  thumbsup: "like",
  clap: "celebrate",
  sad: "sad",
  worried: "confused",
};

export const EMPTY_EXCEL_LINE = [];

export const applicationTranslations = defineMessages({
  ActiveUsers: {
    id: "KPIExport.Application.ActiveUsers",
    defaultMessage: "Active Users",
  },
  Current: {
    id: "KPIExport.Application.Current",
    defaultMessage: "Current",
  },
  Previous: {
    id: "KPIExport.Application.Previous",
    defaultMessage: "Previous",
  },
  Total: {
    id: "KPIExport.Application.Total",
    defaultMessage: "Total",
  },
  Users: {
    id: "KPIExport.Application.Users",
    defaultMessage: "Users",
  },
  NumberOfConnections: {
    id: "KPIExport.Application.NumberOfConnections",
    defaultMessage: "Number Of Connections",
  },
  AverageTimeSpentPerDay: {
    id: "KPIExport.Application.AverageTimeSpentPerDay",
    defaultMessage: "Average Time Spent Per Day",
  },
  AverageTime: {
    id: "KPIExport.Application.AverageTime",
    defaultMessage: "Average Time",
  },
  NumberOfUsers: {
    id: "KPIExport.Application.NumberOfUsers",
    defaultMessage: "Number Of Users",
  },
  Date: {
    id: "KPIExport.Application.Date",
    defaultMessage: "Date",
  },
  UserCount: {
    id: "KPIExport.Application.UserCount",
    defaultMessage: "User Count",
  },
});

export const appAnalyticsTranslations = defineMessages({
  NumberOfSessions: {
    id: "KPIExport.AppAnalytics.NumberOfSessions",
    defaultMessage: "Average # of sessions per day during period",
  },
  NumberOfSessionsPerMonth: {
    id: "KPIExport.AppAnalytics.NumberOfSessionsPerMonth",
    defaultMessage: "Average # of sessions per month per user",
  },
  SessionDuration: {
    id: "KPIExport.AppAnalytics.SessionDuration",
    defaultMessage: "Session Duration",
  },
  AverageSessionDuration: {
    id: "KPIExport.AppAnalytics.AverageSessionDuration",
    defaultMessage: "Average Session Duration",
  },
  EngagementPercent: {
    id: "KPIExport.AppAnalytics.EngagementPercent",
    defaultMessage: "% of engagement during the period",
  },
  TotalNumberOfUsers: {
    id: "KPIExport.Application.TotalNumberOfUsers",
    defaultMessage: "Total Number Of Users",
  },
  IosUsers: {
    id: "KPIExport.AppAnalytics.IosUsers",
    defaultMessage: "Access on IOS",
  },
  IosWebUsers: {
    id: "KPIExport.AppAnalytics.IosWebUsers",
    defaultMessage: "Access on IOS web",
  },
  IosAppUsers: {
    id: "KPIExport.AppAnalytics.IosAppUsers",
    defaultMessage: "Access on IOS app",
  },
  AndroidUsers: {
    id: "KPIExport.AppAnalytics.AndroidUsers",
    defaultMessage: "Access on android",
  },
  AndroidWebUsers: {
    id: "KPIExport.AppAnalytics.AndroidWebUsers",
    defaultMessage: "Access on android web",
  },
  AndroidAppUsers: {
    id: "KPIExport.AppAnalytics.AndroidAppUsers",
    defaultMessage: "Access on android app",
  },
  WebUsers: {
    id: "KPIExport.AppAnalytics.WebUsers",
    defaultMessage: "Access on web",
  },
  ActiveUsers: {
    id: "KPIExport.AppAnalytics.ActiveUsers",
    defaultMessage: "Active Users",
  },
  SessionId: {
    id: "KPIExport.AppAnalytics.SessionId",
    defaultMessage: "Session Id",
  },
  UserId: {
    id: "KPIExport.AppAnalytics.UserId",
    defaultMessage: "User ID",
  },
  UserEmail: {
    id: "KPIExport.AppAnalytics.UserEmail",
    defaultMessage: "User Email",
  },
  Device: {
    id: "KPIExport.AppAnalytics.Device",
    defaultMessage: "Device",
  },
  OS: {
    id: "KPIExport.AppAnalytics.OS",
    defaultMessage: "OS",
  },
  Platform: {
    id: "KPIExport.AppAnalytics.Platform",
    defaultMessage: "Platform",
  },
  OSVersion: {
    id: "KPIExport.AppAnalytics.OSVersion",
    defaultMessage: "OS version",
  },
  BuildId: {
    id: "KPIExport.AppAnalytics.Build Id",
    defaultMessage: "Build Id",
  },
  SessionStartDate: {
    id: "KPIExport.AppAnalytics.SessionStartDate",
    defaultMessage: "Session start date",
  },
  SessionEndDate: {
    id: "KPIExport.AppAnalytics.SessionEndDate",
    defaultMessage: "Session end date",
  },
  Market: {
    id: "KPIExport.AppAnalytics.Market",
    defaultMessage: "Market",
  },
  Retailer: {
    id: "KPIExport.AppAnalytics.Retailer",
    defaultMessage: "Retailer",
  },
  Brand: {
    id: "KPIExport.AppAnalytics.Brand",
    defaultMessage: "Brand",
  },
  Relationship: {
    id: "KPIExport.AppAnalytics.Relationship",
    defaultMessage: "Relationship with Shiseido",
  },
  DateRange: {
    id: "KPIExport.AppAnalytics.DateRange",
    defaultMessage: "Date Range",
  },
  DateStart: {
    id: "KPIExport.AppAnalytics.DateStart",
    defaultMessage: "Start Date",
  },
  DateEnd: {
    id: "KPIExport.AppAnalytics.DateEnd",
    defaultMessage: "End Date",
  },
  Untracked: {
    id: "KPIExport.AppAnalytics.Untracked",
    defaultMessage: "Number of untracked users",
  },
  TotalSessions: {
    id: "KPIExport.AppAnalytics.TotalSessions",
    defaultMessage: "Total # of sessions during the period",
  },
  TotalRegister: {
    id: "KPIExport.AppAnalytics.TotalRegister",
    defaultMessage: "Total # of users registered during the period",
  },
  Sessions: {
    id: "KPIExport.AppAnalytics.Sessions",
    defaultMessage: "# of sessions during the period",
  },
});

export const documentTranslations = defineMessages({
  DocumentName: {
    id: "KPIExport.Document.DocumentName",
    defaultMessage: "Document Name",
  },
  DocumentType: {
    id: "KPIExport.Document.DocumentType",
    defaultMessage: "Document Type",
  },
  UserId: {
    id: "KPIExport.Document.UserId",
    defaultMessage: "User ID",
  },
  ThemeName: {
    id: "KPIExport.Document.ThemeName",
    defaultMessage: "Theme Name",
  },
  PillarName: {
    id: "KPIExport.Document.PillarName",
    defaultMessage: "Pillar Name",
  },
  TotalRead: {
    id: "KPIExport.Document.TotalRead",
    defaultMessage: "Total # of reads",
  },
  TotalSeen: {
    id: "KPIExport.Document.TotalSeen",
    defaultMessage: "Total # of seens",
  },
  TotalComment: {
    id: "KPIExport.Document.TotalComment",
    defaultMessage: "Total # of comments",
  },
  TotalLike: {
    id: "KPIExport.Document.TotalLike",
    defaultMessage: "Total # of likes",
  },
  TotalBookmark: {
    id: "KPIExport.Document.TotalBookmark",
    defaultMessage: "Total # of bookmarks",
  },
  Period: {
    id: "KPIExport.Document.Period",
    defaultMessage: "Period",
  },
  Email: {
    id: "KPIExport.Document.Email",
    defaultMessage: "User email",
  },
  Title: {
    id: "KPIExport.Document.Title",
    defaultMessage: "Document Title",
  },
  Theme: {
    id: "KPIExport.Document.Theme",
    defaultMessage: "Theme Title",
  },
  Pillar: {
    id: "KPIExport.Document.Pillar",
    defaultMessage: "Pillar Title",
  },
  ActionType: {
    id: "KPIExport.Document.ActionType",
    defaultMessage: "Action type",
  },
  Date: {
    id: "KPIExport.Document.Date",
    defaultMessage: "Date",
  },
  Url: {
    id: "KPIExport.Document.Url",
    defaultMessage: "Url",
  },
  Status: {
    id: "KPIExport.AppAnalytics.Status",
    defaultMessage: "Status",
  },
});

export const productTranslations = defineMessages({
  ProductName: {
    id: "KPIExport.Product.ProductName",
    defaultMessage: "Product Name",
  },
  UserId: {
    id: "KPIExport.Product.UserId",
    defaultMessage: "User ID",
  },
  CategoryName: {
    id: "KPIExport.Product.CategoryName",
    defaultMessage: "Category Name",
  },
  BrandName: {
    id: "KPIExport.Product.BrandName",
    defaultMessage: "Brand Name",
  },
  TotalRead: {
    id: "KPIExport.Product.TotalRead",
    defaultMessage: "Total # of reads",
  },
  TotalLike: {
    id: "KPIExport.Product.TotalLike",
    defaultMessage: "Total # of likes",
  },
  TotalBookmark: {
    id: "KPIExport.Product.TotalBookmark",
    defaultMessage: "Total # of bookmarks",
  },
  Period: {
    id: "KPIExport.Product.Period",
    defaultMessage: "Period",
  },
  Email: {
    id: "KPIExport.Product.Email",
    defaultMessage: "User email",
  },
  Gtin: {
    id: "KPIExport.Product.Gtin",
    defaultMessage: "Product Gtin",
  },
  ActionType: {
    id: "KPIExport.Product.ActionType",
    defaultMessage: "Action type",
  },
  Date: {
    id: "KPIExport.Product.Date",
    defaultMessage: "Date",
  },
  Manufacturer: {
    id: "KPIExport.Product.Manufacturer",
    defaultMessage: "Manufacturer Brand",
  },
  Url: {
    id: "KPIExport.Product.Url",
    defaultMessage: "Url",
  },
});

export const askTranslations = defineMessages({
  ASK: {
    id: "KPIExport.Ask.ASK",
    defaultMessage: "ASK",
  },
  QuestionsAskedToGenius: {
    id: "KPIExport.Ask.QuestionsAskedToGenius",
    defaultMessage: "Questions asked to Genius",
  },
  QuestionsAskedToExperts: {
    id: "KPIExport.Ask.QuestionsAskedToExperts",
    defaultMessage: "Questions asked to Experts",
  },
  AnsweredQuestionsByExperts: {
    id: "KPIExport.Ask.AnsweredQuestionsByExperts",
    defaultMessage: "Answered Questions by Experts",
  },
  PendingQuestionsToExperts: {
    id: "KPIExport.Ask.PendingQuestionsToExperts",
    defaultMessage: "Pending Questions to Experts",
  },
  TAGS: {
    id: "KPIExport.Ask.TAGS",
    defaultMessage: "TAGS",
  },
  ToGenius: {
    id: "KPIExport.Ask.ToGenius",
    defaultMessage: "To Genius",
  },
  Name: {
    id: "KPIExport.Ask.Name",
    defaultMessage: "Name",
  },
  Rank: {
    id: "KPIExport.Ask.Rank",
    defaultMessage: "Rank",
  },
  ToExperts: {
    id: "KPIExport.Ask.ToExperts",
    defaultMessage: "To Experts",
  },
});

export const feedTranslations = defineMessages({
  FEED: {
    id: "KPIEExport.Feed.FEED",
    defaultMessage: "FEED",
  },
  TotalContent: {
    id: "KPIEExport.Feed.TotalContent",
    defaultMessage: "Total content",
  },
  Name: {
    id: "KPIEExport.Feed.Name",
    defaultMessage: "Name",
  },
  Value: {
    id: "KPIEExport.Feed.Value",
    defaultMessage: "Value",
  },
  CONTENTPOPULARITY: {
    id: "KPIEExport.Feed.CONTENTPOPULARITY",
    defaultMessage: "CONTENT POPULARITY",
  },
  Percent: {
    id: "KPIEExport.Feed.Percent",
    defaultMessage: "Percent",
  },
  TOPTAGS: {
    id: "KPIEExport.Feed.TOPTAGS",
    defaultMessage: "TOP TAGS",
  },
  Rank: {
    id: "KPIEExport.Feed.Rank",
    defaultMessage: "Rank",
  },
  Title: {
    id: "KPIEExport.Feed.Title",
    defaultMessage: "Title",
  },
  Brands: {
    id: "KPIEExport.Feed.Brands",
    defaultMessage: "Brands",
  },
  PublicationDate: {
    id: "KPIEExport.Feed.PublicationDate",
    defaultMessage: "Publication Date",
  },
  ArchiveDate: {
    id: "KPIEExport.Feed.ArchiveDate",
    defaultMessage: "Archive Date",
  },
  Status: {
    id: "KPIEExport.Feed.Status",
    defaultMessage: "Status (published, archived)",
  },
  StatusShiseido: {
    id: "KPIEExport.Feed.StatusShiseido",
    defaultMessage: "Status (published, archived) on {date}",
  },
  Market: {
    id: "KPIEExport.Feed.Market",
    defaultMessage: "Market",
  },
  Language: {
    id: "KPIEExport.Feed.Language",
    defaultMessage: "Language",
  },
  Template: {
    id: "KPIEExport.Feed.Template",
    defaultMessage: "Template (Quick Quiz, Slider, Poll, Challenge, Insight)",
  },
  Reads: {
    id: "KPIEExport.Feed.Reads",
    defaultMessage: "# of Reads",
  },
  Likes: {
    id: "KPIEExport.Feed.Likes",
    defaultMessage: "# of Likes",
  },
  Bookmarks: {
    id: "KPIEExport.Feed.Bookmarks",
    defaultMessage: "# of Bookmarks",
  },
  Comments: {
    id: "KPIEExport.Feed.Comments",
    defaultMessage: "# of Comments",
  },
});

export const generalTranslations = defineMessages({
  Application: {
    id: "KPIExport.General.Application",
    defaultMessage: "APPLICATION",
  },
  CurrentPeriod: {
    id: "KPIExport.General.CurrentPeriod",
    defaultMessage: "CurrentPeriod",
  },
  PreviousPeriod: {
    id: "KPIExport.General.PreviousPeriod",
    defaultMessage: "PreviousPeriod",
  },
  ActiveUsers: {
    id: "KPIExport.General.ActiveUsers",
    defaultMessage: "ActiveUsers",
  },
  ActiveConnections: {
    id: "KPIExport.General.ActiveConnections",
    defaultMessage: "ActiveConnections",
  },
  AverageTimeSpent: {
    id: "KPIExport.General.AverageTimeSpent",
    defaultMessage: "Average Time Spent",
  },
  LEARNING: {
    id: "KPIExport.General.LEARNING",
    defaultMessage: "LEARNING",
  },
  Programs: {
    id: "KPIExport.General.Programs",
    defaultMessage: "Programs",
  },
  Lessons: {
    id: "KPIExport.General.Lessons",
    defaultMessage: "Lessons",
  },
  FEED: {
    id: "KPIExport.General.FEED",
    defaultMessage: "FEED",
  },
  Insights: {
    id: "KPIExport.General.Insights",
    defaultMessage: "Insights",
  },
  SocialMedias: {
    id: "KPIExport.General.SocialMedias",
    defaultMessage: "Social medias",
  },
  ASK: {
    id: "KPIExport.General.ASK",
    defaultMessage: "ASK",
  },
  QuestionAskedToGenius: {
    id: "KPIExport.General.QuestionAskedToGenius",
    defaultMessage: "Question asked to genius",
  },
  QuestionAskedToExperts: {
    id: "KPIExport.General.QuestionAskedToExperts",
    defaultMessage: "Question asked to experts",
  },
  AnsweredAskedByExperts: {
    id: "KPIExport.General.AnsweredAskedByExperts",
    defaultMessage: "Answered asked by experts",
  },
  PendingQuestionsByExperts: {
    id: "KPIExport.General.PendingQuestionsByExperts",
    defaultMessage: "Pending questions by expert",
  },
});

export const learningTranslations = defineMessages({
  PROGRAMS: {
    id: "KPIExport.Learning.PROGRAMS",
    defaultMessage: "PROGRAMS",
  },
  NumberOfPrograms: {
    id: "KPIExport.Learning.NumberOfPrograms",
    defaultMessage: "Number of programs",
  },
  LESSONS: {
    id: "KPIExport.Learning.LESSONS",
    defaultMessage: "LESSONS",
  },
  NumberOfLessons: {
    id: "KPIExport.Learning.NumberOfLessons",
    defaultMessage: "Number of lessons",
  },
  Program: {
    id: "KPIExport.Learning.Program",
    defaultMessage: "Program",
  },
  CompletedLessons: {
    id: "KPIExport.Learning.CompletedLessons",
    defaultMessage: "Completed lessons",
  },
  ProgramCompletion: {
    id: "KPIExport.Learning.ProgramCompletion",
    defaultMessage: "Program completion",
  },
  TotalLessons: {
    id: "KPIExport.Learning.TotalLessons",
    defaultMessage: "Total lessons",
  },
  Lesson: {
    id: "KPIExport.Learning.Lesson",
    defaultMessage: "Lesson",
  },
  Completed: {
    id: "KPIExport.Learning.Completed",
    defaultMessage: "Completed",
  },
  AllowedUsers: {
    id: "KPIExport.Learning.AllowedUsers",
    defaultMessage: "Allowed users",
  },
  ProgramName: {
    id: "KPIExport.Learning.ProgramName",
    defaultMessage: "Program name",
  },
  DegreeName: {
    id: "KPIExport.Learning.DegreeName",
    defaultMessage: "Degree name",
  },
  LessonName: {
    id: "KPIExport.Learning.LessonName",
    defaultMessage: "Lesson name",
  },
  CompletedUsers: {
    id: "KPIExport.Learning.CompletedUsers",
    defaultMessage: "Completed users",
  },
  CompletionRate: {
    id: "KPIExport.Learning.CompletionRate",
    defaultMessage: "% of completion",
  },
  AllUsers: {
    id: "KPIExport.Learning.AllUsers",
    defaultMessage: "Total users",
  },
  TotalPages: {
    id: "KPIExport.Learning.TotalPages",
    defaultMessage: "Total pages",
  },
  LessonExportName: {
    id: "KPIExport.Learning.Lesson.Name",
    defaultMessage: "Name",
  },
  LessonExportEmail: {
    id: "KPIExport.Learning.Lesson.Email",
    defaultMessage: "Email",
  },
  LessonExportCompletionDate: {
    id: "KPIExport.Learning.Lesson.CompletionDate",
    defaultMessage: "Completion date",
  },
  LessonExportMarkets: {
    id: "KPIExport.Learning.Lesson.Markets",
    defaultMessage: "Markets",
  },
  LessonExportRelationships: {
    id: "KPIExport.Learning.Lesson.Relationships",
    defaultMessage: "Relationships",
  },
  LessonExportRetailers: {
    id: "KPIExport.Learning.Lesson.Retailers",
    defaultMessage: "Retailers",
  },
});

export const ninjaTranslations = defineMessages({
  NinjaGameName: {
    id: "KPIExport.Ninja.GameName",
    defaultMessage: "Game name",
  },
  NinjaMarket: {
    id: "KPIExport.Ninja.Market",
    defaultMessage: "Market",
  },
  NinjaBrand: {
    id: "KPIExport.Ninja.Brand",
    defaultMessage: "Brand",
  },
  NinjaProgramName: {
    id: "KPIExport.Ninja.ProgramName",
    defaultMessage: "Program name",
  },
  NinjaUserName: {
    id: "KPIExport.Ninja.UserName",
    defaultMessage: "User name",
  },
  NinjaUserMail: {
    id: "KPIExport.Ninja.UserMail",
    defaultMessage: "User mail",
  },
  NinjaBestScore: {
    id: "KPIExport.Ninja.BestScore",
    defaultMessage: "Best score(# pts)",
  },
  NinjaDateBestScore: {
    id: "KPIExport.Ninja.DateBestScore",
    defaultMessage: "Date of best score (DD/MM/YY)",
  },
});
