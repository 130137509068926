import { defineMessages } from "react-intl";

export const translations = defineMessages({
  ActiveUsersDescription: {
    id: "KeyDetailsWidgetInfo.ActiveUsersDescription",
    defaultMessage: "Active users out of total registered users.",
  },
  PositiveAndNegativePercentageDescription: {
    id: "KeyDetailsWidgetInfo.PositiveAndNegativePercentageDescription",
    defaultMessage:
      "Positive or negative percentage of chance for this population’s activity in comparison to the previous period.",
  },
  DifferenceNumberDescription: {
    id: "KeyDetailsWidgetInfo.DifferenceNumberDescription",
    defaultMessage:
      "Exact difference in the number of active users for this population in comparison to the previous period.",
  },
  TooltipTitle: {
    id: "KeyDetailsWidgetInfo.TooltipTitle",
    defaultMessage:
      "Active users are users that have been connected at least once in the time period selected.",
  },
});
