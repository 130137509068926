import gql from "graphql-tag";
import { UserFragment } from "../fragments/users";

export const GET_USER_PROFILE_DATA = gql`
  query getUserProfileData($search: String!, $userId: ID!) {
    admin {
      users(search: $search, userIds: [$userId]) {
        ...UserFields
      }
    }
  }
  ${UserFragment}
`;
