import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, ButtonSize, ButtonVariation, Card, Icons } from "genius-ui";
import SettingsMenu from "components/common/SettingsMenu";
import { FormattedMessage, useIntl } from "react-intl";
import injectSheet from "react-jss";
import gridStyles from "./Grid.style";
import { DOCUMENT_TYPES, STATUS_ENUM } from "configs/constants";
import { LANGUAGES } from "configs/referential";
import { translations } from "./Grid.translations";
import { DocumentTypeTranslations } from "constants/domain_items/documents/document_types";
import { useTags } from "store/tag/hooks";

const getType = ({ type }) => {
  return type;
};

const getContentTypeTitle = (intl, item) => {
  const contentTypeTitle = intl.formatMessage(
    DocumentTypeTranslations[getType(item)],
  );

  return contentTypeTitle;
};

const CardWithMenu = ({
  item,
  props,
  editDocument,
  getEditLabel,
  showDuplicateModal,
  showNotificationModal,
  allowDuplication,
  allowNotification,
  allowDelete,
  showDeleteModal,
  setDeleteDocumentData,
  navigateToInsightPublishPage,
  navigateToCardPublishPage,
  navigateToBattlePublishPage,
  navigateToPollPublishPage,
  classes,
  allowEdit,
}) => {
  const [hasAnnouncementTag, setHasAnnouncementTag] = useState(
    item.hasAnnouncementTag,
  );
  const { handleTag } = useTags();
  const intl = useIntl();
  const handleIconStatusClick = useCallback(() => {
    setHasAnnouncementTag(!hasAnnouncementTag);
    handleTag(!hasAnnouncementTag, item.type, item.id, item.language, [6], () =>
      setHasAnnouncementTag(hasAnnouncementTag),
    );
  }, [hasAnnouncementTag]);
  const statusIcon = useMemo(() => {
    return undefined;
  });

  return (
    <div className={classes.cardContainer}>
      <Card
        title={item.title}
        image_path={item.picCover}
        date={item.updateDate}
        contentType={getContentTypeTitle(intl, item)}
        status={item.workflow}
        handleOnClick={editDocument(props, item)}
        handleOnClickSettingsButton={() => props.onItemSettingsClicked(item.id)}
        vote={item.vote}
        read={item.read}
        comment={item.comment}
        isCommentCountVisible={false}
        visibility={item.visibility}
        isReadCountVisible={item.type !== DOCUMENT_TYPES.CARD}
        statusIcon={statusIcon}
        statusIconColor={
          hasAnnouncementTag ? "rgb(70, 142, 227)" : "rgb(153, 162, 172)"
        }
      />
      <SettingsMenu
        handleFocusLeave={() => props.onItemSettingsClicked(undefined)}
        isVisible={
          props.activeMenuDocumentId && props.activeMenuDocumentId === item.id
        }
        className={classes.docSettingsMenu}
        menuItems={[
          {
            label: <FormattedMessage {...translations.PublishOption} />,
            icon: Icons.settings,
            onItemClick: () => {
              props.onItemSettingsClicked(undefined);
              switch (item.type) {
                case DOCUMENT_TYPES.INSIGHT:
                  navigateToInsightPublishPage(item.id);
                  break;
                case DOCUMENT_TYPES.CARD:
                  navigateToCardPublishPage(item.id);
                  break;
                case DOCUMENT_TYPES.BATTLE:
                  navigateToBattlePublishPage(item.id);
                  break;
                case DOCUMENT_TYPES.POLL:
                  navigateToPollPublishPage(item.id);
                  break;
                default:
              }
            },
          },
          {
            label: getEditLabel(item.type),
            icon: Icons.settings,
            onItemClick: editDocument(props, item),
            disabled: !allowEdit,
          },
          {
            label: <FormattedMessage {...translations.TranslateOption} />,
            icon: Icons.translate,
            onItemClick: showDuplicateModal(props, item),
            disabled: !(LANGUAGES.length > 1) || !allowDuplication,
          },
          {
            label: (
              <FormattedMessage {...translations.SendNotificationOption} />
            ),
            icon: Icons.notification,
            onItemClick: showNotificationModal(props, item),
            hidden:
              item.type === DOCUMENT_TYPES.BATTLE ||
              item.type === DOCUMENT_TYPES.POLL ||
              item.workflow === STATUS_ENUM.DRAFT,
            disabled: !allowNotification,
          },
          {
            label: <FormattedMessage {...translations.DeleteOption} />,
            icon: Icons.delete,
            onItemClick: () => {
              props.onItemSettingsClicked(undefined);
              setDeleteDocumentData(item);
              showDeleteModal();
            },
            disabled: !allowDelete,
          },
        ]}
      />
    </div>
  );
};

const GridView = (allProps) => {
  const {
    items,
    classes,
    showLoadMore,
    loading,
    loadMore,
    allowEdit,
    ...restProps
  } = allProps;

  return (
    <>
      {items.map((item, key) => (
        <CardWithMenu
          key={key}
          item={item}
          {...restProps}
          classes={classes}
          allowEdit={allowEdit}
        />
      ))}
      {showLoadMore && (
        <div className={classes.loadMoreContainer}>
          <Button
            disabled={loading}
            variation={ButtonVariation.primary}
            size={ButtonSize.small}
            handleClick={loadMore}
          >
            <FormattedMessage {...translations.SeeMoreButtonDefaultTitle} />
          </Button>
        </div>
      )}
    </>
  );
};

GridView.propTypes = {
  allowEdit: PropTypes.bool,
  allowDuplication: PropTypes.bool,
  allowNotification: PropTypes.bool,
  allowDelete: PropTypes.bool,
};

GridView.defaultProps = {
  allowEdit: true,
  allowDuplication: true,
  allowNotification: true,
  allowDelete: true,
};

export default injectSheet(gridStyles)(GridView);
