import { DefaultVideoCover } from "assets/icons";
import { VIDEO_FORMAT } from "../../../configs/constants";

export default ({ SliderVideoPlayer, FontFamily }) => ({
  videoContainer: {
    width: "100%",
    height: (props) =>
      props.isActive
        ? props.formatType === VIDEO_FORMAT.PORTRAIT
          ? "100%"
          : "250px"
        : "auto",
    position: "absolute",
  },
  progressContainer: {
    backgroundImage: ({ backgroundImage }) =>
      backgroundImage !== "none" ? `url("${DefaultVideoCover}")` : undefined,
    width: "100%",
    height: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    zIndex: 99999,
    position: ({ progressBarContainerPosition }) =>
      progressBarContainerPosition || "absolute",
  },
  titleProgress: {
    fontFamily: FontFamily,
    fontSize: 24,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: 0.08,
    textAlign: "center",
    color: ({ titleColor }) =>
      titleColor || SliderVideoPlayer.titleProgressColor,
  },
  descriptionProgress: {
    fontFamily: FontFamily,
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: 0.07,
    color: SliderVideoPlayer.descriptionProgressColor,
  },
});
