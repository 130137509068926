import React from "react";
import PropTypes from "prop-types";
import UploadAttachments from "../../../components/common/UploadAttachments";
import { connect } from "react-redux";
import {
  AddAttachments,
  RemoveAttachments,
} from "../../../store/lesson/actions";
import { SetSuccessMessage } from "../../../store/notification/actions";
import { translations } from "../PublishLesson.translation";
import { FormattedMessage } from "react-intl";
import clientConfig from "../../../configs/client";

const UploadAttachmentToLesson = ({
  containerClassName,
  files,
  AddAttachments,
  RemoveAttachment,
}) => {
  return clientConfig.UPLOAD_ATTACHMENTS_ACTIVE ? (
    <UploadAttachments
      containerClassName={containerClassName}
      onRemoveFile={RemoveAttachment}
      onSaveFile={AddAttachments}
      files={files}
    />
  ) : null;
};

UploadAttachmentToLesson.propTypes = {
  containerClassName: PropTypes.string,
};

const mapStateToProp = (state) => ({
  files: state.lesson.publishLessonData.files,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  AddAttachments: (files, callback) =>
    dispatch(
      AddAttachments(files, () => {
        callback();
        dispatch(
          SetSuccessMessage(
            <FormattedMessage {...translations.SavedAttachmentMessage} />,
          ),
        );
      }),
    ),
  RemoveAttachment: (id) => dispatch(RemoveAttachments(id)),
});

export default connect(
  mapStateToProp,
  mapDispatchToProps,
)(UploadAttachmentToLesson);
