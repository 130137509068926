import gql from "graphql-tag";

export const DEGREE_CREATE = gql`
  mutation adminCreateDegree($lang: LangEnum, $title: String!) {
    admin {
      degree(lang: $lang) {
        create(title: $title) {
          degreeId
          versions {
            title
            lang
          }
        }
      }
    }
  }
`;
