import gql from "graphql-tag";

export const ADD_TAG = gql`
  mutation addTag($battleId: ID, $lang: LangEnum, $tagIds: [ID!]) {
    admin {
      battle(battleId: $battleId, lang: $lang) {
        tags {
          add(tagIds: $tagIds)
        }
      }
    }
  }
`;
