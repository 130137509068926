import gql from "graphql-tag";

export const BATTLE_UPDATE_WORFLOW = gql`
  mutation battleUpdateWorkflow(
    $battleId: ID
    $lang: LangEnum
    $status: DocWorkflowEnum!
    $dateIn: Date
    $dateOut: Date
  ) {
    admin {
      battle(battleId: $battleId, lang: $lang) {
        workflow {
          set(status: $status, dateIn: $dateIn, dateOut: $dateOut)
        }
      }
    }
  }
`;
