import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Style from "../Madlib.style";
import ContentEditable from "react-contenteditable";
import RadioGroup from "../../../common/RadioGroup";
import { FormattedMessage } from "react-intl";
import { translations } from "../Madlib.translations";
import useThemedStyle from "../../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const MadLib = (props) => {
  const { onDataChanged, partOne, partTwo, options } = props;
  const classes = useThemedStyle(useStyle, props);

  const rightAnswer = options.find((option) => option.isTrue === true);
  const [stateChoice] = useState(rightAnswer || {});
  const [stateOptions] = useState(options);
  const [isTyping] = useState(false);
  const [statePartOne] = useState(partOne);
  const [statePartTwo] = useState(partTwo);

  const firstRender = useRef(true);
  useEffect(() => {
    // componentDidMount
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (isTyping) {
      return;
    }

    // componentDidUpdate
    if (!isTyping) {
      const newValues = {
        partOne: statePartOne,
        partTwo: statePartTwo,
        options: stateOptions,
        rightChoice: stateChoice,
      };
      onDataChanged(newValues);
    }
  }, [statePartOne, statePartTwo, isTyping, stateOptions, stateChoice]);

  return (
    <div className={classes.madlibContainer}>
      <FormattedMessage {...translations.PartOne}>
        {(message) => (
          <ContentEditable
            key="partOne"
            html={statePartOne}
            disabled
            tagName="article"
            className={classes.sentencePartOne}
            placeholder={message[0]}
          />
        )}
      </FormattedMessage>
      <FormattedMessage {...translations.CorrectChoicePlaceholder}>
        {(message) => (
          <ContentEditable
            key="choice"
            html={stateChoice.label ? stateChoice.label : ""}
            disabled
            tagName="article"
            className={classes.choice}
            placeholder={message[0]}
          />
        )}
      </FormattedMessage>
      <FormattedMessage {...translations.PartTwo}>
        {(message) => (
          <ContentEditable
            key="partTwo"
            html={statePartTwo}
            disabled
            tagName="article"
            className={classes.sentencePartTwo}
            placeholder={message[0]}
          />
        )}
      </FormattedMessage>
      <div className={classes.radioHeaderContainer}>
        <div className={classes.correctLabel}>
          <FormattedMessage {...translations.Correct} />
        </div>
        <div className={classes.choiceTextLabel}>
          <FormattedMessage {...translations.ChoiceText} />
        </div>
      </div>
      <div className={classes.radioContainer}>
        <RadioGroup
          onChange={() => null}
          options={stateOptions}
          renderOptionLabel={(option, index) => (
            <label key={index} className={classes.radioLabel}>
              <div className={classes.RadioLabelContainer}>
                <FormattedMessage {...translations.EnterChoiceText}>
                  {(message) => (
                    <ContentEditable
                      html={option.label}
                      disabled
                      tagName="article"
                      className={classes.radioChoiceLabel}
                      placeholder={message[0]}
                    />
                  )}
                </FormattedMessage>
              </div>
            </label>
          )}
          value={stateChoice.value ? stateChoice.value : ""}
        />
      </div>
    </div>
  );
};

MadLib.propTypes = {
  /** Sentence part one */
  partOne: PropTypes.string,
  /** Sentence part two */
  partTwo: PropTypes.string,
  /** Answers list */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  /** Right choice */
  choice: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    isTrue: PropTypes.bool,
  }),
};

MadLib.defaultProps = {
  partOne: "",
  partTwo: "",
  options: [],
  choice: {},
  isSelected: false,
};

export default MadLib;
