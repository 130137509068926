import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Style from "./BattleEditor.style";
import BattleQuestionEditor from "../BattleQuestionEditor";
import {
  BattleQuestionPropTypes,
  CreateBattleQuestion,
} from "../../../constants/domain_items/battle/battle_question";
import useQuestions from "../../../hooks/activity/useQuestions";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const CreateNewQuestion = (type) => [CreateBattleQuestion(type)];

const BattleEditor = (props) => {
  const {
    questions: questionProps,
    isActive,
    onQuestionsChange,
    className,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  const [enhancedQuestions, setQuestions] = useState(questionProps);

  const questionsRef = useRef(questionProps);
  const handleChange = useCallback(
    ({ questions }) => {
      if (enhancedQuestions.length !== questions.length) {
        setQuestions(questions);
      } else if (
        enhancedQuestions.some(
          (question, qIndex) =>
            questions[qIndex]?.choices?.length !== question.choices.length,
        )
      ) {
        setQuestions((prev) =>
          prev.map((question, index) => ({
            ...question,
            choices: questions[index]?.choices || [],
          })),
        );
      } else if (
        enhancedQuestions.some(
          (question, qIndex) =>
            questions[qIndex]?.type !== question.choices.type ||
            questions[qIndex]?.image !== question.choices.image,
        )
      ) {
        setQuestions(questions);
      }
      onQuestionsChange(questions);
    },
    [enhancedQuestions],
  );

  useEffect(() => {
    questionsRef.current = questionProps;
  }, [questionProps]);

  const {
    editedQuestionIndex,
    setEditedQuestionIndex,
    handleQuestionChange,
    removeQuestion,
    addQuestionBefore,
    addQuestionAfter,
  } = useQuestions(
    questionProps,
    () =>
      CreateNewQuestion(
        editedQuestionIndex !== null && editedQuestionIndex !== undefined
          ? questionProps[editedQuestionIndex].type
          : undefined,
      ),
    handleChange,
  );

  const handleUpdateQuestions = useCallback(
    (index, params) => {
      handleQuestionChange(index, params, questionsRef.current);
    },
    [questionProps, enhancedQuestions, handleQuestionChange],
  );

  return (
    <div className={classNames([classes.container, className])}>
      {enhancedQuestions.map((question, index) => (
        <BattleQuestionEditor
          key={question.id}
          {...question}
          isSelected={isActive && editedQuestionIndex === index}
          onTypeChange={(questionId, type) =>
            handleUpdateQuestions(index, { type })
          }
          onTitleChange={(questionId, title) =>
            handleUpdateQuestions(index, { title })
          }
          onImageChange={(questionId, image) =>
            handleUpdateQuestions(index, { image })
          }
          onDescriptionChange={(questionId, description) =>
            handleUpdateQuestions(index, { description })
          }
          onChoicesChange={(questionId, choices) =>
            handleUpdateQuestions(index, { choices })
          }
          onAddQuestionBefore={() => addQuestionBefore(index)}
          onAddQuestionAfter={() => addQuestionAfter(index)}
          onRemove={() => removeQuestion(index)}
          onSelect={() => setEditedQuestionIndex(index)}
        />
      ))}
    </div>
  );
};

BattleEditor.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape(BattleQuestionPropTypes))
    .isRequired,
  /** editing battle is enabled **/
  isActive: PropTypes.bool,
  /** func(changedQuestions): function executed when the questions change **/
  onQuestionsChange: PropTypes.func,
  /** provide a custom style to the question container **/
  className: PropTypes.string,
};

export default BattleEditor;
