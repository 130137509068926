import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { ActivityIntroComponentStyles } from "./ActivityIntroComponentStyle";
import { Icon, Icons } from "genius-ui";
import UploadMedia from "../UploadMedia";
import { FormattedMessage } from "react-intl";
import { DefaultSlider1 } from "assets/icons";
import ContentEditable from "react-contenteditable";
import CropImage from "../../common/CropImage";
import { CROP_VARIATION } from "../../../configs/constants";
import { translations } from "./ActivityIntroComponent.translations";
import withThemedStyle from "../../hoc/withThemedStyle";

class ActivityIntroComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      theme: "",
      title: "",
      description: "",
      cropVisible: false,
    };
  }

  componentDidMount() {
    this.setState({
      image: this.props.image ? this.props.image : "",
      theme: this.props.theme ? this.props.theme : "",
      title: this.props.title ? this.props.title : "",
      description: this.props.description ? this.props.description : "",
    });
  }

  handleUploadSuccess = (url) => {
    this.setState(
      {
        image: url,
        cropVisible: false,
      },
      this.handleChange,
    );
  };
  changeThemeValue = (evt) => this.setState({ theme: evt.target.value });
  changeTitleValue = (evt) => this.setState({ title: evt.target.value });
  changeDescriptionValue = (evt) =>
    this.setState({ description: evt.target.value });

  handleChange = () => {
    let changedValue = {
      image: this.state.image,
      theme: this.state.theme,
      title: this.state.title,
      description: this.state.description,
    };
    this.props.onChange(changedValue);
  };

  changeCropVisibility = (newValue) => () =>
    this.setState({ cropVisible: newValue });

  render() {
    const props = this.props;
    const { isSelected, activityId, classes } = props;

    return (
      <div className={classes.introContainer} onClick={props.onClick}>
        {isSelected && (
          <div className={classes.imageHandlerContainer}>
            <UploadMedia
              id={`activityIntroImage-${activityId}`}
              onUploadSuccess={this.handleUploadSuccess}
              className={classes.imageActionContainer}
              isVideoUploadEnabled={false}
            >
              <Icon iconName={Icons.upload} style={classes.actionIcon} />
              <span className={classes.actionLabel}>
                <FormattedMessage {...translations.UploadImage} />
              </span>
            </UploadMedia>
            <CropImage
              classes={classes}
              sourceImage={this.state.image}
              isCropVisible={this.state.cropVisible}
              cropSize={CROP_VARIATION.BACKGROUND}
              onCropClick={this.changeCropVisibility(true)}
              onCancel={this.changeCropVisibility(false)}
              onCropCompleted={(url, id) => this.handleUploadSuccess(url)}
            />
          </div>
        )}
        <div className={classes.descriptionContainer}>
          <FormattedMessage {...translations.Theme}>
            {(message) => (
              <ContentEditable
                key="theme"
                html={this.state.theme}
                disabled={!isSelected}
                onChange={this.changeThemeValue}
                onBlur={this.handleChange}
                tagName="article"
                className={props.classes.themeInput}
                placeholder={message[0]}
              />
            )}
          </FormattedMessage>
          <div className={classes.titleAndDescriptionContainer}>
            <FormattedMessage {...translations.Title}>
              {(message) => (
                <ContentEditable
                  key="title"
                  html={this.state.title}
                  disabled={!isSelected}
                  onChange={this.changeTitleValue}
                  onBlur={this.handleChange}
                  tagName="article"
                  className={props.classes.titleInput}
                  placeholder={message[0]}
                />
              )}
            </FormattedMessage>
            <FormattedMessage {...translations.Description}>
              {(message) => (
                <ContentEditable
                  key="description"
                  html={this.state.description}
                  disabled={!isSelected}
                  onChange={this.changeDescriptionValue}
                  onBlur={this.handleChange}
                  tagName="article"
                  className={props.classes.descriptionInput}
                  placeholder={message[0]}
                />
              )}
            </FormattedMessage>
          </div>
        </div>
      </div>
    );
  }
}

ActivityIntroComponent.propTypes = {
  /** Unique identifier of the activity */
  activityId: PropTypes.string.isRequired,
  /** The current selected image */
  image: PropTypes.string,
  /** onValueChange handler */
  onChange: PropTypes.func.isRequired,
  /** isSelected value */
  isSelected: PropTypes.bool,
  /** On intro card click event handler */
  onClick: PropTypes.func,
  /** Intro theme */
  theme: PropTypes.string,
  /** Intro title */
  title: PropTypes.string,
  /** Intro description */
  description: PropTypes.string,
};

ActivityIntroComponent.defaultProps = {
  defaultImage: DefaultSlider1,
  isSelected: true,
};

export default withThemedStyle(ActivityIntroComponentStyles)(
  ActivityIntroComponent,
);
