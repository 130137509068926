import gql from "graphql-tag";

export const LOCATIONS_BY_ID_DEFAULT = gql`
  fragment LocationFields on LocationObject {
    locationId
    title
    type
    retailArea {
      value
      unit
    }
    addressBuilding
    dateOpening
    boutiqueType {
      tagId
      clusterId
      title
    }
    picCover
    users {
      userId
      profile {
        pic
        position
        firstName
        lastName
      }
    }
    architectureConcept {
      tagId
      title
      clusterId
    }
    infoOpening
    vmSegmentation {
      tagId
      clusterId
      title
    }
    addressCountry {
      tagId
      clusterId
      title
    }
    addressZone {
      title
      clusterId
      tagId
    }
    addressCity {
      title
      tagId
      clusterId
    }
    gpsLat
    gpsLong
    menSurfaceDetail {
      tagId
      pic
      title
      value
      unit
    }
    womenSurfaceDetail {
      tagId
      pic
      title
      value
      unit
    }
    homeSurfaceDetail {
      tagId
      pic
      title
      value
      unit
    }
    babySurfaceDetail {
      tagId
      pic
      title
      value
      unit
    }
    othersSurfaceDetail {
      tagId
      pic
      title
      value
      unit
    }
    tags {
      tagId
      title
      pic
      clusterId
    }
  }
`;
