import PageContainer from "components/PageContainer/PageContainer";
import client from "graphql/client";
import FixedTagsContainer from "pages/publish/components/containers/FixedTagsContainer";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-apollo";
import { useHistory } from "react-router-dom";
import Lessons from "../partials/Lessons/Lessons";
import { Button } from "genius-ui";
import {
  ADD_LESSONS_TO_BADGE,
  ADD_QUIZZS_TO_BADGE,
  GET_BADGE_BY_ID,
  GET_TAGS,
  REMOVE_LESSONS_TO_BADGE,
} from "../graphs";
import FilterHeaderContainer from "components/FilterHeaderContainer/FilterHeaderContainer";

export const useGetTags = (variables) => {
  return useQuery(GET_TAGS, { variables });
};

const BadgeCard = ({ badge, brandId, marketId }) => {
  const { data, loading } = useQuery(GET_TAGS, {
    variables: { tagIds: [brandId, marketId] },
  });
  const { brand, market } = useMemo(() => {
    if (!data) return {};
    return {
      brand: data.tags.find(({ tagId }) => tagId === brandId).title,
      market: data.tags.find(({ tagId }) => tagId === marketId).title,
    };
  });
  if (loading) return null;
  return (
    <div style={{ width: 800, padding: 30 }}>
      <FixedTagsContainer label="Title">
        <div
          className="framedGrey"
          style={{ backgroundColor: "rgb(160, 160, 160)" }}
        >
          {badge.title}
        </div>
      </FixedTagsContainer>
      <FixedTagsContainer label="Country">
        <div
          className="framedGrey"
          style={{ backgroundColor: "rgb(160, 160, 160)" }}
        >
          {market}
        </div>
      </FixedTagsContainer>
      <FixedTagsContainer label="Brand">
        <div
          className="framedGrey"
          style={{ backgroundColor: "rgb(160, 160, 160)" }}
        >
          {brand}
        </div>
      </FixedTagsContainer>
      <div>Score {badge.score}</div>
    </div>
  );
};

const updateLessonsBadge = async (selectedLessons, badge) => {
  const selectedLessonIds = Object.keys(selectedLessons);
  const oldLessonIds = badge.badgeDocuments.map(({ docId }) => docId);

  const toChange = oldLessonIds.filter(
    (lessonId) =>
      selectedLessons[lessonId] &&
      selectedLessons[lessonId] !==
        badge.badgeDocuments.find((lesson) => lesson.docId === lessonId)
          .auditTypeId,
  );
  const toDelete = oldLessonIds
    .filter(
      (lessonId) =>
        !selectedLessonIds.includes(lessonId) || toChange.includes(lessonId),
    )
    .map(Number);
  const toAdd = selectedLessonIds.filter(
    (lessonId) =>
      !oldLessonIds.includes(lessonId) || toChange.includes(lessonId),
  );
  const newQuizzs = toAdd
    .filter((lessonId) => selectedLessons[lessonId] === 4)
    .map(Number);
  const newLessons = toAdd
    .filter((lessonId) => selectedLessons[lessonId] === 2)
    .map(Number);

  if (toDelete.length) {
    await client.mutate({
      mutation: REMOVE_LESSONS_TO_BADGE,
      variables: { badgeIds: Number(badge.badgeId), lessonIds: toDelete },
    });
  }
  if (newLessons.length) {
    await client.mutate({
      mutation: ADD_LESSONS_TO_BADGE,
      variables: { badgeIds: Number(badge.badgeId), lessonIds: newLessons },
    });
  }
  if (newQuizzs.length) {
    await client.mutate({
      mutation: ADD_QUIZZS_TO_BADGE,
      variables: { badgeIds: Number(badge.badgeId), lessonIds: newQuizzs },
    });
  }
};

const EditMarketBadge = ({
  match: {
    params: { id },
  },
}) => {
  const history = useHistory();
  const handleGoBack = history.goBack;
  const { data, loading, error } = useQuery(GET_BADGE_BY_ID, {
    variables: { badgeId: id },
  });
  const [selectedLessons, setSelectedLessons] = useState({});
  const { badge, brandId, marketId } = useMemo(() => {
    if (!data) return {};
    const badge = data.admin.badges[0];
    const brandId = badge.tags.find(
      ({ clusterId }) => clusterId === "18",
    ).tagId;
    const marketId = badge.tags.find(
      ({ clusterId }) => clusterId === "2",
    ).tagId;

    return {
      brandId,
      marketId,
      badge,
    };
  }, [data]);
  useEffect(() => {
    if (!badge) return;
    setSelectedLessons(
      badge.badgeDocuments.reduce((acc, { docId, auditTypeId }) => {
        acc[docId] = auditTypeId;
        return acc;
      }, {}),
    );
  }, [badge, setSelectedLessons]);

  const handleSelectLesson = useCallback(
    (docId, auditTypeId) => {
      setSelectedLessons((prevState) => {
        if (auditTypeId) return { ...prevState, [docId]: auditTypeId };
        delete prevState[docId];
        return { ...prevState };
      });
    },
    [setSelectedLessons],
  );
  const handleUpdateLessonsBadge = useCallback(() => {
    updateLessonsBadge(selectedLessons, badge);
  }, [selectedLessons, badge]);

  if (loading) return null;
  return (
    <PageContainer>
      <FilterHeaderContainer canGoBack />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <BadgeCard badge={badge} brandId={brandId} marketId={marketId} />
        <Lessons
          brandId={brandId}
          marketId={marketId}
          selectedLessons={selectedLessons}
          onSelectLesson={handleSelectLesson}
        />
        <Button
          variation="primary"
          size="small"
          handleClick={handleUpdateLessonsBadge}
        >
          Save
        </Button>
      </div>
    </PageContainer>
  );
};

export default EditMarketBadge;
