import {
  SET_BATTLE_LOADING,
  SET_BATTLE_MASTER_FLAG,
  SET_BATTLE_QUESTIONS,
  SET_EDITED_BATTLE_PAGEID,
} from "../../constants/store/editBattle";
import { SetErrorMessage } from "../notification/actions";
import client from "../../graphql/client";
import { GET_BATTLE_BY_ID } from "../../graphql/remote/battle/queries/GetBattleInfo";
import { EDIT_BATTLE_PAGE } from "../../graphql/remote/battle/mutations/EditBattlePage";
import {
  BattleDocumentToBattleItem,
  BattleItemToBattleDocument,
} from "./transformers";
import clientConfig from "../../configs/client";

export const SetBattleIsLoading = (data) => (dispatch) =>
  dispatch({
    type: SET_BATTLE_LOADING,
    data,
  });

export const SetEditedBattlePageId = (data) => (dispatch) =>
  dispatch({
    type: SET_EDITED_BATTLE_PAGEID,
    data,
  });

export const SetBattleQuestions = (data) => (dispatch) =>
  dispatch({
    type: SET_BATTLE_QUESTIONS,
    data,
  });

export const SetBattleDocumentMasterFlag = (data) => (dispatch) =>
  dispatch({
    type: SET_BATTLE_MASTER_FLAG,
    data,
  });

export const GetBattleInfo = (battleId, language) => async (dispatch) => {
  dispatch(SetBattleIsLoading(true));
  dispatch(SetEditedBattlePageId(null));
  dispatch(SetBattleQuestions([]));

  try {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_BATTLE_BY_ID,
      variables: {
        battleId,
      },
    });

    const currentLanguageBattleVersion =
      result.data.admin.battles[0].versions.find(
        (version) => version.lang === language,
      );
    const battleItem = BattleDocumentToBattleItem(currentLanguageBattleVersion);

    if (
      result.data.admin.battles[0].versions
        .find((version) => version.lang === language)
        .tags.findIndex(
          (x) => parseInt(x.tagId) === clientConfig.MASTER_DEGREE_TAG_ID,
        ) >= 0
    ) {
      dispatch(SetBattleDocumentMasterFlag(true));
    } else {
      dispatch(SetBattleDocumentMasterFlag(false));
    }

    dispatch(SetEditedBattlePageId(battleItem.editedPageId));
    dispatch(SetBattleQuestions(battleItem.questions));
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while fetching the battle info for battle with id ${battleId}`,
        e,
      ),
    );
  }

  dispatch(SetBattleIsLoading(false));
};

export const SaveBattleQuestions =
  (battleId, language, questions) => async (dispatch, getState) => {
    const {
      editBattle: { editedPageId, questions: previousState },
    } = getState();
    const Revert = () => dispatch(SetBattleQuestions(previousState));

    try {
      dispatch(SetBattleQuestions(questions));

      const result = await client.mutate({
        mutation: EDIT_BATTLE_PAGE,
        variables: {
          battleId: battleId,
          lang: language,
          pageId: editedPageId,
          data: BattleItemToBattleDocument({
            questions,
          }),
        },
      });

      if (!result.data.admin.battle.pages.data) {
        Revert();
      }
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while updating the questions of the battle ${battleId}`,
          e,
        ),
      );
      Revert();
    }
  };
