import { navigationBarWidth } from "../../Navigation/Navigation.style";

const globalPadding = 30;
const globalWidth = 1280 + 2 * globalPadding;

export default {
  container: {
    opacity: ({ showLoader }) => (showLoader ? 0.5 : 1),
  },
  screenContainer: {
    paddingLeft: navigationBarWidth + 1,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "auto",
    padding: `60px ${globalPadding}px`,
    marginTop: ({ headerHeight }) => headerHeight + 28,
    maxWidth: globalWidth,
  },
  spinner: {
    position: "absolute",
    top: 400,
    left: "50%",
    right: "50%",
    zIndex: 1,
    opacity: ({ showLoader }) => (showLoader ? 1 : 0),
  },
};
