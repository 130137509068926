import React from "react";
import PropTypes from "prop-types";
import style from "./FilterHeaderContainer.style";
import { createUseStyles } from "react-jss";
import useThemedStyle from "hooks/style/useThemedStyle";
import { Icon, Icons } from "genius-ui";
import { useHistory } from "react-router-dom";

const useStyle = createUseStyles(style);

const FilterHeaderContainer = ({ canGoBack, children }) => {
  const classes = useThemedStyle(useStyle, {});
  const history = useHistory();

  return (
    <div className={classes.headerContainer}>
      <div className={classes.header}>
        <div className={classes.filterContainer}>
          <div className={classes.leftSide}>
            {!!canGoBack && (
              <Icon
                iconName={Icons.arrowLeft}
                style={classes.backButton}
                handleClick={history.goBack}
              />
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

FilterHeaderContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default FilterHeaderContainer;
