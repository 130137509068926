import gql from "graphql-tag";

export const UPDATE_WORKFLOW = gql`
  mutation programUpdateWorkflow(
    $programId: ID
    $lang: LangEnum
    $status: DocWorkflowEnum!
  ) {
    admin {
      program(programId: $programId, lang: $lang) {
        workflow {
          set(status: $status)
        }
      }
    }
  }
`;
