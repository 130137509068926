import React, { useState } from "react";
import PropTypes from "prop-types";
import style from "../Link.style";
import { Icon, Icons } from "genius-ui";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const Link = (props) => {
  const {
    titlePlaceholder,
    linkPlaceholder,
    linkTitleValue,
    linkValue,
    onItemClick,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  const [linkTitle] = useState(linkTitleValue);
  const [link] = useState(linkValue);
  const [linkPopupIsVisible, setLinkPopup] = useState(false);

  return (
    <div className={classes.container} onClick={onItemClick}>
      {linkPopupIsVisible && (
        <div className={classes.popup}>
          <div>
            <input
              placeholder={linkPlaceholder}
              value={link}
              className={classes.link}
              disabled
            />
          </div>
          <Icon
            iconName={Icons.mediumCloseIcon}
            handleClick={() => setLinkPopup(false)}
          />
        </div>
      )}
      <div onClick={() => setLinkPopup(true)}>
        <input
          placeholder={titlePlaceholder}
          value={linkTitle}
          className={classes.linkTitle}
          disabled
        />
      </div>
    </div>
  );
};

const StringOrObject = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
]);

Link.propTypes = {
  /** Placeholder text for the link title */
  titlePlaceholder: StringOrObject,
  /** Placeholder text for the link */
  linkPlaceholder: StringOrObject,
  /** Value of the link title */
  linkTitleValue: PropTypes.string,
  /** Value of the link */
  linkValue: PropTypes.string,
};

Link.defaultProps = {
  titlePlaceholder: "Link Title",
  linkPlaceholder: "Paste or type a link",
  linkTitleValue: "",
  linkValue: "",
};

export default Link;
