export const defaultStyles = (theme) => {
  const dateStyles = {
    fontSize: 16,
    letterSpacing: 0.1,
    color: theme.ResultDetailsCard.dateColor,
  };

  return {
    root: {
      fontFamily: theme.FontFamily,
      width: "100%",
      minHeight: 300,
      borderRadius: 10,
      overflow: "hidden",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor: theme.ResultDetailsCard.containerBackgroundColor,
    },
    content: {
      width: 810,
      padding: 30,
      boxSizing: "border-box",
      "& > .name": {
        fontSize: 23,
        fontWeight: "bold",
        letterSpacing: 0.14,
        color: theme.ResultDetailsCard.documentNameColor,
      },
      "& > .status": {
        marginLeft: 20,
        fontSize: 14,
        fontWeight: 300,
        letterSpacing: 0.08,
        color: theme.ResultDetailsCard.documentStatusColor,
      },
      "& > .creationDate": {
        ...dateStyles,
        marginTop: 30,
        marginBottom: 30,
      },
      "& > .publicationDate": {
        ...dateStyles,
        marginBottom: 30,
      },
      "& > .markets": {
        width: "100%",
        float: "left",
        marginBottom: 20,
        "& > div": {
          float: "left",
        },
      },
      "& > .brands": {
        width: "100%",
        float: "left",
        "& > div": {
          float: "left",
        },
      },
    },
  };
};
