import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { AddSlide } from "assets/icons";
import Style from "./AddNewContainer.style.js";
import { FormattedMessage } from "react-intl";
import { translations } from "./AddNewContainer.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const ADD_POSITION_BEFORE = -1;
const ADD_POSITION_AFTER = 1;

const useStyle = createUseStyles(Style);

const AddNewContainer = (props) => {
  const {
    direction,
    enabled,
    onAddBefore,
    onAddAfter,
    beforeAfterBreakPoint,
    children,
    className,
    onMouseMove,
    ...restProps
  } = props;
  const [addPosition, setAddPosition] = useState(0);
  const classes = useThemedStyle(useStyle, props);

  const onAdd = addPosition === ADD_POSITION_BEFORE ? onAddBefore : onAddAfter;
  const addButtonClass = classNames([
    classes.addBox,
    {
      [classes.addBoxToLeft]:
        addPosition === ADD_POSITION_BEFORE &&
        direction === DIRECTION_HORIZONTAL,
      [classes.addBoxToRight]:
        addPosition === ADD_POSITION_AFTER &&
        direction === DIRECTION_HORIZONTAL,
      [classes.addBoxToTop]:
        addPosition === ADD_POSITION_BEFORE && direction === DIRECTION_VERTICAL,
      [classes.addBoxToBottom]:
        addPosition === ADD_POSITION_AFTER && direction === DIRECTION_VERTICAL,
    },
  ]);
  const addButtonAltMessage =
    addPosition === ADD_POSITION_BEFORE ? (
      <FormattedMessage {...translations.AddBeforeAltMessage} />
    ) : (
      <FormattedMessage {...translations.AddAfterAltMessage} />
    );

  const handleMouseMove = (e) => {
    if (enabled) {
      const mainAxisMousePosition =
        direction === DIRECTION_HORIZONTAL
          ? e.nativeEvent.offsetX
          : e.nativeEvent.offsetY;
      if (mainAxisMousePosition < beforeAfterBreakPoint) {
        setAddPosition(ADD_POSITION_BEFORE);
      } else {
        setAddPosition(ADD_POSITION_AFTER);
      }
    }
    onMouseMove && onMouseMove(e);
  };

  return (
    <div
      className={classNames([classes.container, className])}
      onMouseMove={handleMouseMove}
      {...restProps}
    >
      {enabled &&
        (addPosition === ADD_POSITION_BEFORE ||
          addPosition === ADD_POSITION_AFTER) && (
          <div
            className={addButtonClass}
            onClick={onAdd}
            onMouseMove={(event) => event.stopPropagation()}
          >
            <img src={AddSlide} alt={addButtonAltMessage} />
          </div>
        )}
      {children}
    </div>
  );
};

export const DIRECTION_HORIZONTAL = "HORIZONTAL";
export const DIRECTION_VERTICAL = "VERTICAL";

AddNewContainer.propTypes = {
  /** Whether the new element is added horizontally or vertically before or after the current element (main axis)**/
  direction: PropTypes.oneOf([DIRECTION_HORIZONTAL, DIRECTION_VERTICAL])
    .isRequired,
  /** If disabled(enabled = false), the add new element buttons are not displayed on hover **/
  enabled: PropTypes.bool,
  /** Function called when clicked on the add new button, that adds an element before the current one **/
  onAddBefore: PropTypes.func,
  /** Function called when clicked on the add new button, that adds an element after the current one **/
  onAddAfter: PropTypes.func,
  /** When the user moves the mouse over this container, it must be decided which add new element button has to be
   * displayed. It is decided which button is displayed based on the current mouse position on the main axis, compared
   * to the given breakPoint. When the position of the mouse is bellow this breakpoint, the 'Add before' button is
   * displayed, if the mouse is above the breakpoint, the 'Add after' button is displayed. **/
  beforeAfterBreakPoint: PropTypes.number.isRequired,
  /** + all the props accepted by ReactDOM.div **/
};

export default AddNewContainer;
