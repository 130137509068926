export default {
  menu: {
    display: "flex",
    backgroundColor: "white",
    position: "absolute",
    right: -100,
    bottom: -10,
    width: 100,
    height: 30,
    zIndex: 5,
    borderRadius: 2,
    cursor: "pointer",
    color: "grey",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgb(146, 146, 146)",
      color: "rgb(70, 142, 227)",
    },
  },
  dots: {
    position: "absolute",
    right: 0,
    bottom: 0,
    padding: 10,
    cursor: "pointer",
    color: "grey",
    fontSize: 5,
  },
  garbage: {
    paddingRight: 5,
  },
};
