import React, { useEffect } from "react";
import DefaultInsightEditor from "./CreateInsight";
import InsightEditorReadOnly from "./readOnly";
import clientConfig from "../../configs/client";
import { DOCUMENT_LEVEL, USER_ROLE_NAMES } from "../../configs/constants";
import { connect } from "react-redux";
import { FullscreenLoader } from "genius-ui";
import { getPath } from "../../core/paths";
import {
  AddCurrentInsightPage,
  GetInsightData,
  RemoveCurrentInsightPage,
  SetCurrentInsightId,
  SetCurrentLanguage,
  SetCurrentPageId,
  SetPublishInsight,
  UpdateCurrentInsightPage,
  UpdateCurrentOpenedMarker,
  UpdateInsightData,
} from "../../store/insight/actions";
import { SetWarningMessage } from "../../store/notification/actions";

const EditInsight = (props) => {
  const { userRoles, GetInsightData, isMasterDocument } = props;

  useEffect(() => {
    GetInsightData(props.match.params.insightId, props.match.params.lang);
  }, []);

  if (!userRoles || !userRoles.length) {
    return <FullscreenLoader />;
  }

  if (
    clientConfig.IS_ROLE_READ_ONLY_MODE_ACTIVE &&
    userRoles.includes(USER_ROLE_NAMES.TRAINER) &&
    isMasterDocument
  ) {
    return <InsightEditorReadOnly {...props} />;
  } else {
    return <DefaultInsightEditor {...props} />;
  }
};

const mapStateToProps = (state) => ({
  userRoles: state.auth.profileInfo.roles,
  currentPageId: state.insights.currentPageId,
  currentOpenedMarker: state.insights.currentOpenedMarker,
  documentListWorkflow: state.documents.listWorkflow,
  documentLevel: state.documents.documentLevel,
  isMasterDocument: state.insights.isMasterDocument,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  NavigateToPublishPage: (insighthId, language, documentLevel) => {
    if (documentLevel === DOCUMENT_LEVEL.MASTER) {
      ownProps.history.push(
        getPath("publishMasterInsight", insighthId, language),
      );
    } else {
      ownProps.history.push(getPath("publishInsight", insighthId, language));
    }
  },
  UpdateCurrentInsightId: (insightId) => {
    dispatch(SetCurrentInsightId(insightId));
  },
  SetCurrentLanguage: (language) => {
    dispatch(SetCurrentLanguage(language));
  },
  UpdateInsightPublicaitonInfo: (insightInfo) => {
    dispatch(SetPublishInsight(insightInfo));
  },
  UpdateInsightPageData: (insightId, lang, data, pageId) => {
    dispatch(
      UpdateCurrentInsightPage({
        pageId,
        data,
      }),
    );
  },
  UpdateInsightData: (insightId, lang, data) => {
    dispatch(UpdateInsightData(insightId, lang, data));
  },
  AddCurrentInsightPage: (data) => {
    dispatch(AddCurrentInsightPage(data));
  },
  RemoveCurrentInsightPage: (data) => {
    dispatch(RemoveCurrentInsightPage(data));
  },
  SetWarningMessage: (message) => dispatch(SetWarningMessage(message)),
  GetInsightData: (insightId, languageId) => {
    dispatch(GetInsightData(insightId, languageId));
  },
  UpdateCurrentPageId: (pageId) => {
    dispatch(SetCurrentPageId(pageId));
  },
  UpdateOpenedMarker: (pageId, slideIndex, markerIndex, openedState) => {
    const props = openedState
      ? { pageId: pageId, markerIndex, slideIndex }
      : { pageId: null, markerIndex: null, slideIndex: null };
    dispatch(UpdateCurrentOpenedMarker(props));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditInsight);
