import gql from "graphql-tag";

export const LESSON_DELETE_PAGE = gql`
  mutation lessonDeletePage($lessonId: ID!, $lang: LangEnum, $pageId: ID) {
    admin {
      lesson(lessonId: $lessonId, lang: $lang) {
        pages(pageId: $pageId) {
          delete
        }
      }
    }
  }
`;
