import React, { useCallback } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  KPIPageContainer,
  WidgetSection,
  WidgetContainer,
} from "../../components/KPI";
import DataNotAvailable from "../../components/KPI/DataNotAvailable";
import { translations } from "./kpiNinja.translations";
import { createUseStyles } from "react-jss";
import style from "./kpiNinja.style";
import useThemedStyle from "../../hooks/style/useThemedStyle";
import NinjaWidget from "components/KPI/NinjaWidget";
import { useKpiNinja } from "store/pdf/hook";

const useStyle = createUseStyles(style);

const KpiNinja = (props) => {
  const { ninjas, getPageData, exportNinjaXls } = useKpiNinja();
  const { intl } = props;
  const classes = useThemedStyle(useStyle, props);

  const exportNinja = useCallback((ninjaId) => {
    exportNinjaXls(ninjaId, intl);
  }, []);
  return (
    <div className={classes.container}>
      <KPIPageContainer
        onFilterChange={getPageData}
        hideExportButton
        hideDateSelector
      >
        <WidgetSection>
          <div className={classes.topTitle}>
            <FormattedMessage {...translations.NinjaGamesPublished} />
          </div>
          <WidgetContainer width={100} isMarginRightEnabled={false}>
            {ninjas && ninjas.length > 0 ? (
              <NinjaWidget ninjas={ninjas} exportNinja={exportNinja} />
            ) : (
              <DataNotAvailable />
            )}
          </WidgetContainer>
        </WidgetSection>
      </KPIPageContainer>
    </div>
  );
};

export default injectIntl(KpiNinja);
