import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import uuidv4 from "uuid/dist/v4";
import { createEmptyDragAndDropActivityCard } from "../../../utils/ui-generator/defaultTemplates";
import { FormattedMessage } from "react-intl";
import Style from "./DragAndDropActivityAnswer.style.js";
import ContentEditableUncontrolled from "../ContentEditableUncontrolled";
import { Icon, Icons } from "genius-ui";
import CropImage from "../CropImage";
import { CROP_VARIATION } from "../../../configs/constants";
import UploadMedia from "../UploadMedia";
import { translations } from "./DragAndDropActivityAnswer.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const MAX_NR_OF_CARDS = 4;

const createNewCard = () => createEmptyDragAndDropActivityCard();

const CropCardImage = (props) => {
  const [isCropVisible, setCropVisibleState] = useState(false);
  return (
    <CropImage
      {...props}
      isCropVisible={isCropVisible}
      onCropClick={() => setCropVisibleState(true)}
      onCancel={() => setCropVisibleState(false)}
    />
  );
};

const DragAndDropActivityAnswer = (props) => {
  const {
    id,
    index,
    cards: cardsProp,
    onChange,
    isEditable,
    className,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  const cardsRef = useRef([]);

  const [cardIndexInEdition, setCardIndexInEdition] = useState(null);

  const cards = cardsProp.map((card) => ({
    ...card,
    uid: card.uid || uuidv4(), // generate a uid for backward compatibility purposes(old cards don't have an uid)
  }));

  cardsRef.current = cards;

  // handle card changes
  const handleChange = (changedData) => onChange(id, changedData);

  const handleCardChange = (changedCardIndex, changedProps) => {
    console.debug(
      "DragAndDropActivityAnswer.handleCardChange",
      changedCardIndex,
      changedProps,
    );

    const cards = cardsRef.current;
    return handleChange(
      cards.map((card, index) =>
        changedCardIndex === index
          ? {
              ...card,
              ...changedProps,
            }
          : card,
      ),
    );
  };
  // handle card text changes
  const handleCardTextChange = (changedCardIndex, text) => {
    console.debug(
      "DragAndDropActivityAnswer.handleCardTextChange",
      changedCardIndex,
      text,
    );
    return handleCardChange(changedCardIndex, { text });
  };

  const handleCardEditionInProgress = useCallback(
    (changedCardIndex) => {
      return (isCardEditionInProgress) => {
        console.debug(
          "DragAndDropActivityAnswer.handleCardEditionInProgress",
          changedCardIndex,
          isCardEditionInProgress,
        );
        setCardIndexInEdition(
          isCardEditionInProgress ? changedCardIndex : null,
        );
      };
    },
    [setCardIndexInEdition],
  );

  // handle card image changes
  const handleCardImageChange = (changedCardIndex, image) =>
    handleCardChange(changedCardIndex, { image });

  // add card
  const addCard = () =>
    cards.length < MAX_NR_OF_CARDS && handleChange([...cards, createNewCard()]);
  // remove card
  const removeCard = (removedCardIndex) =>
    handleChange(cards.filter((card, index) => index !== removedCardIndex));

  return (
    <div className={classNames([classes.container, className])}>
      <p className={classes.title}>
        <FormattedMessage
          {...translations.ZoneTitle}
          values={{ zoneIndex: index }}
        />
      </p>
      {cards.map(({ uid, image, text }, index, cards) => {
        const imageEditorStyle = {};
        if (image) {
          imageEditorStyle.backgroundImage = `url('${image}')`;
        }
        return (
          <div
            key={uid}
            className={classNames([
              classes.cardContainer,
              { [classes.cardContainerLast]: index === cards.length - 1 },
              { [classes.editedCard]: index === cardIndexInEdition },
            ])}
          >
            <div className={classes.cardImageEditor} style={imageEditorStyle}>
              <FormattedMessage {...translations.AnswerImageInputLabel}>
                {(message) => (
                  <UploadMedia
                    id={`dnd-activity-answer-${uid}-upload-image`}
                    className={classNames([
                      classes.cardImageUpload,
                      {
                        [classes.cardImageUploaded]: image,
                      },
                    ])}
                    disabled={!isEditable}
                    onUploadSuccess={(newImageUrl) =>
                      handleCardImageChange(index, newImageUrl)
                    }
                    isVideoUploadEnabled={false}
                  >
                    <Icon iconName={Icons.upload} title={message[0]} />
                    <div>{message[0]}</div>
                  </UploadMedia>
                )}
              </FormattedMessage>
              {image && (
                <FormattedMessage {...translations.AnswerImageRemoveLabel}>
                  {(message) => (
                    <span
                      className={classes.removeCardImageIcon}
                      onClick={() => handleCardImageChange(index, null)}
                    >
                      <Icon
                        iconName={Icons.mediumCloseIcon}
                        title={message[0]}
                      />
                    </span>
                  )}
                </FormattedMessage>
              )}
              {image && (
                <CropCardImage
                  sourceImage={image}
                  cropSize={CROP_VARIATION.DRAG_AND_DROP_ACTIVITY_IMAGE}
                  onCropCompleted={(croppedImageUrl) =>
                    handleCardImageChange(index, croppedImageUrl)
                  }
                  className={classes.cropCardImageButton}
                  disabled={!isEditable}
                />
              )}
            </div>
            <FormattedMessage {...translations.AnswerTextInputLabel}>
              {(message) => (
                <ContentEditableUncontrolled
                  className={classes.cardTextInput}
                  initialValue={text}
                  onChange={(text) => handleCardTextChange(index, text)}
                  setContentOnChange
                  placeholder={message[0]}
                  disabled={!isEditable}
                  onEdition={handleCardEditionInProgress(index)}
                />
              )}
            </FormattedMessage>
            <span
              className={classes.cardRemoveButton}
              onClick={() => isEditable && removeCard(index)}
            >
              <Icon iconName={Icons.delete} />
            </span>
          </div>
        );
      })}
      {cards.length < MAX_NR_OF_CARDS && (
        <span
          className={classNames([
            classes.addItemButton,
            { [classes.disabledItemButton]: cardIndexInEdition !== null },
          ])}
          onClick={() => {
            if (cardIndexInEdition === null && isEditable) addCard();
          }}
        >
          {cardIndexInEdition === null ? (
            <FormattedMessage
              {...translations.AddNewItemButtonLabel}
              values={{ maxNrOfItems: MAX_NR_OF_CARDS }}
            />
          ) : (
            <FormattedMessage {...translations.ButtonBlockedByEditionLabel} />
          )}
        </span>
      )}
    </div>
  );
};

DragAndDropActivityAnswer.propTypes = {
  /** Id of the answer **/
  id: PropTypes.string.isRequired,
  /** Answer index **/
  index: PropTypes.number,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  /** Function executed when a card is added, modified or removed **/
  onChange: PropTypes.func.isRequired,
  /** Whether editing the answer is allowed or not **/
  isEditable: PropTypes.bool,
  /** Container extra className **/
  className: PropTypes.string,
};

export default DragAndDropActivityAnswer;
