import gql from "graphql-tag";

export const GET_LESSON_PAGINATED = gql`
  query LessonsPaginated(
    $skip: Int
    $limit: Int
    $tagIds: [ID!]
    $tagMultipleIntersectIds: [QueryInputMultipleTags!]
    $search: String
  ) {
    admin {
      lessons(
        skip: $skip
        limit: $limit
        tagIds: $tagIds
        workflow: PUBLISHED
        sort: asc
        lang: en
        tagMultipleIntersectIds: $tagMultipleIntersectIds
        search: $search
      ) {
        lessonId
        versions {
          title
          langId
        }
      }
    }
  }
`;
