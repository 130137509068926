import gql from "graphql-tag";

export const DEGREE_UPDATE_SUMMARY = gql`
  mutation degreeUpdateSummary($degreeId: ID, $lang: LangEnum, $data: String!) {
    admin {
      degree(degreeId: $degreeId, lang: $lang) {
        summary(data: $data)
      }
    }
  }
`;
