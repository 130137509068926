import gql from "graphql-tag";
import { LocationsByIdFragment } from "../fragments/locationById";

export const GET_SHOP_BY_ID = gql`
  query getShopByID($limit: Int, $skip: Int, $locationId: [ID!]) {
    Location(skip: $skip, limit: $limit, ocIds: $locationId) {
      ...LocationFields
    }
  }
  ${LocationsByIdFragment}
`;
