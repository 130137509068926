import { defineMessages } from "react-intl";

export const lessonsWidgetShiseidoTranslations = defineMessages({
  LessonNameLabel: {
    id: "LessonsWidgetShiseido.Header.LessonNameLabel",
    defaultMessage: "Lesson Name",
  },
  ProgramNameLabel: {
    id: "LessonsWidgetShiseido.Header.ProgramNameLabel",
    defaultMessage: "Program Name",
  },
  DegreeNameLabel: {
    id: "LessonsWidgetShiseido.Header.DegreeNameLabel",
    defaultMessage: "Degree Name",
  },
  LessonCompletionRateLabel: {
    id: "LessonsWidgetShiseido.LessonCompletionRateLabel",
    defaultMessage:
      "# of users who have completed the lesson/users who have attempted the lesson",
  },
  LessonUserStoppedLabel: {
    id: "LessonsWidgetShiseido.LessonUserStoppedLabel",
    defaultMessage:
      "% of users that have stopped the lesson and its page number",
  },
  TotalPageLabel: {
    id: "LessonsWidgetShiseido.TotalPageLabel",
    defaultMessage: "Total number of pages",
  },
});

export const lessonRowTranslations = defineMessages({
  LessonDetailCRLabel: {
    id: "LessonsWidgetShiseido.LessonDetailCR",
    defaultMessage: "{completionRate} Users",
  },
  UsersQuitLabel: {
    id: "LessonsWidgetShiseido.UsersQuitLabel",
    defaultMessage: "{ratio} users quit at page",
  },
});
