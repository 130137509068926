import React from "react";
import DegreesQuery from "./Degrees.query";
import { DOC_WORKFLOW_ENUM } from "../../configs/constants";
import { DegreesStyle } from "./Degrees.style";
import {
  DEGREE_COPY_ALL_FOR_LANGUAGE,
  DEGREE_CREATE,
  DEGREE_UPDATE_WORFLOW,
} from "../../graphql/remote/degrees/mutations";
import { graphql, Mutation } from "react-apollo";
import compose from "lodash/flowRight";
import { Icon, Icons } from "genius-ui";
import {
  SetCurrentDegreeId,
  SetActiveMenuDegreeId,
} from "../../store/degree/actions";
import { SetSuccessMessage } from "../../store/notification/actions";
import { connect } from "react-redux";
import Navigation from "../../components/Navigation";
import {
  SetDuplicationData,
  SetShowModal,
} from "../../store/duplication/actions";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  SetNotificationData,
  SetShowModal as SetShowNotificationModal,
} from "../../store/sendNotification/actions";
import { SetListWorkflow } from "../../store/document/actions";
import { DOCUMENT_LIST_WORKFLOWS } from "../../store/document/reducers";
import { DEGREE_CLONE } from "../../graphql/remote/degrees/mutations/DegreeClone.graphql";
import { translations } from "./Degrees.translations";
import DegreeFilters from "./filters";
import withThemedStyle from "../../components/hoc/withThemedStyle";
import { Permissions } from "../../core/permissions/permissions";
import CanExecuteAction from "../../core/permissions/canExecuteAction";

const AddDegree = (props) => {
  const voidLink = "#"; // variable used to trick the linter

  return (
    <Mutation
      mutation={DEGREE_CREATE}
      onCompleted={(data) => {
        const degreeId = data.admin.degree.create.degreeId;
        props.navigateToPublishPage(degreeId, props.currentLanguage);
      }}
    >
      {(createDegree, { data }) => (
        <a
          href={voidLink}
          onClick={(e) => {
            e.preventDefault();
            createDegree({
              variables: {
                title: props.intl.formatMessage({
                  ...translations.NewDraftDegreeTitle,
                }),
                lang: props.currentLanguage,
              },
            });
          }}
          className={props.classes.createLink}
        >
          <Icon iconName={Icons.roundSmallPlus} />
          <span>
            <FormattedMessage {...translations.NewDegreeButtonText} />
          </span>
        </a>
      )}
    </Mutation>
  );
};

const deleteDegree = graphql(DEGREE_UPDATE_WORFLOW, {
  props: ({ mutate }) => ({
    degreeDelete: (degreeId, lang, status, refetch) =>
      mutate({
        variables: { degreeId: degreeId, lang: lang, status: status },
        update: (cache, result) => {
          refetch();
        },
      }),
  }),
});

const cloneDegree = graphql(DEGREE_CLONE, {
  props: ({ mutate }) => ({
    degreeClone: (degreeId, lang, refetch) =>
      mutate({
        variables: { degreeId: degreeId, lang: lang },
        update: () => refetch(),
      }),
  }),
});

const copyForLanguage = graphql(DEGREE_COPY_ALL_FOR_LANGUAGE, {
  props: ({ mutate }) => ({
    copyForLanguage: (degreeId, sourceLang, destLang, onSuccess) =>
      mutate({
        variables: {
          degreeId: degreeId,
          sourceLang: sourceLang,
          destLang: destLang,
        },
        update: (cache, result) => {
          onSuccess();
        },
      }),
  }),
});

class Degrees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      match: {
        params: { history: isHistory },
      },
    } = this.props;
    this.props.SetListWorkflow(
      isHistory
        ? DOCUMENT_LIST_WORKFLOWS.DELETED
        : DOCUMENT_LIST_WORKFLOWS.ACTIVE,
    );
  }

  render() {
    const {
      classes,
      match: {
        params: { history: isHistory },
      },
    } = this.props;

    return (
      <div className={classes.rootContainer}>
        <Navigation />
        <div className={classes.degreesContainer}>
          <div className={classes.actionContainer}>
            <DegreeFilters />
            <div className={classes.addDegree}>
              <CanExecuteAction
                permissionName={Permissions.PAGE_DEGREE.CREATE_BUTTON}
              >
                <AddDegree {...this.props} />
              </CanExecuteAction>
            </div>
          </div>
          <DegreesQuery
            workflowStatus={DOC_WORKFLOW_ENUM.PUBLISHED}
            language={this.props.currentLanguage}
            onItemClick={(item) =>
              this.props.navigateToPublishPage(item.Id, item.Language)
            }
            onItemSettingsClicked={this.props.SetActiveMenuDegreeId}
            handleRedirect={(redirectLocation) => {
              this.props.SetActiveMenuDegreeId(undefined);
              this.props.history.push(redirectLocation);
            }}
            activeMenuDegreeId={this.props.activeMenuDegreeId}
            currentDegreeId={this.props.currentDegreeId}
            classes={this.props.classes}
            handleDelete={(degreeId, language, refetch) =>
              this.props.degreeDelete(
                degreeId,
                language,
                DOC_WORKFLOW_ENUM.ARCHIVED,
                refetch,
              )
            }
            handleDuplicate={(degreeId, currentLanguage, destLang) =>
              this.props.DuplicateDegree(
                degreeId,
                currentLanguage,
                destLang,
                this.props.copyForLanguage,
              )
            }
            showDuplicationModal={this.props.showDuplicationModal}
            duplicationData={this.props.duplicationData}
            setShowModal={this.props.SetShowModal}
            setDuplicationData={this.props.SetDuplicationData}
            setShowNotificationModal={this.props.SetShowNotificationModal}
            setNotificationData={this.props.SetNotificationData}
            isHistory={isHistory}
            handleCloneDegree={(degreeId, language, refetch) =>
              this.props.degreeClone(degreeId, language, refetch)
            }
            filters={this.props.filters}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  currentPageId: state.degrees.currentPageId,
  currentDegreeId: state.degrees.currentDegreeId,
  currentLanguage: state.degrees.currentLanguage,
  activeMenuDegreeId: state.degrees.activeMenuDegreeId,
  isDegreePublishedSuccesfully: state.degrees.isDegreePublishedSuccesfully,
  showDuplicationModal: state.duplication.showModal,
  duplicationData: state.duplication.duplicationData,
  showNotificationModal: state.sendNotification.showModal,
  filters: state.degrees.filters,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  navigateToPublishPage: (degreeId, language) => {
    dispatch(SetCurrentDegreeId(degreeId));
    ownProps.history.push(`/degree/create/${degreeId}/${language}`);
  },
  UpdateCurrentDegreeId: (degreeId) => {
    dispatch(SetCurrentDegreeId(degreeId));
  },
  SetActiveMenuDegreeId: (activeDegreeId) => {
    dispatch(SetActiveMenuDegreeId(activeDegreeId));
  },
  DuplicateDegree: (degreeId, currentLanguage, destLang, copyForLanguage) => {
    copyForLanguage(degreeId, currentLanguage, destLang, () => {
      ownProps.history.push(`/degree/create/${degreeId}/${destLang}`);
      dispatch(
        SetSuccessMessage(
          <FormattedMessage
            {...translations.DuplicatedDegreeMessage}
            values={{ lang: destLang }}
          />,
        ),
      );
    });
  },
  SetShowModal: (newValue) => {
    dispatch(SetShowModal(newValue));
  },
  SetDuplicationData: (newValue) => {
    dispatch(SetDuplicationData(newValue));
  },
  SetNotificationData: (newValue) => {
    dispatch(SetNotificationData(newValue));
  },
  SetShowNotificationModal: (newValue) => {
    dispatch(SetShowNotificationModal(newValue));
  },
  SetListWorkflow: (newValue) => {
    dispatch(SetListWorkflow(newValue));
  },
});

const Container = compose(
  deleteDegree,
  copyForLanguage,
  cloneDegree,
)(withThemedStyle(DegreesStyle)(Degrees));

export default injectIntl(
  connect(mapStateToProp, mapDispatchToProps)(Container),
);
