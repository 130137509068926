import gql from "graphql-tag";

export const GET_POLL_BY_ID = gql`
  query getPollById($pollId: ID) {
    admin {
      polls(pollId: $pollId) {
        versions {
          pollId
          title
          lang
          picCover
          isPrivate
          workflow
          workflowScheduledIn
          workflowScheduledOut
          summary
          tags {
            tagId
            title
            clusterId
          }
          pages {
            pageId
            data
          }
        }
      }
    }
  }
`;
