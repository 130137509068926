import React from "react";
import { SliderStyle } from "./Slider.style";
import PropTypes from "prop-types";
import { Icons, Icon } from "genius-ui";
import ContentEditable from "react-contenteditable";
import {
  DefaultSlider1,
  AddSlide,
  DefaultVideo,
  DefaultVideoCover,
} from "assets/icons";
import ChangeTemplate from "../ChangeTemplate";
import {
  FILE_TYPES,
  VIDEO_FORMAT,
  CROP_VARIATION,
} from "../../../configs/constants";
import CropImage from "../../common/CropImage";
import SliderVideoPlayer from "../SliderVideoPlayer";
import { FormattedMessage } from "react-intl";
import UploadMedia from "../UploadMedia";
import { translations } from "./Silder.translations";
import withThemedStyle from "../../hoc/withThemedStyle";

// half of fixed width
const BUTTONSWITCH_BREAKPOINT = 214;

class Slider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      addPosition: "right",
      title: "",
      description: "",
      image: "",
      video: {
        videoSource: DefaultVideo,
        posterCover: DefaultVideoCover,
        formatType: VIDEO_FORMAT.PORTRAIT,
      },
      fileType: FILE_TYPES.IMAGE,
      currentUploadProgress: 0,
      currentConversionProgress: 0,
      isUploading: false,
      isConverting: false,
      isCropVisible: false,
    };

    this.handleCoverUploadCompleted =
      this.handleCoverUploadCompleted.bind(this);
    this.handleVideoFormatChanged = this.handleVideoFormatChanged.bind(this);
  }

  checkToChangeAddPosition = (position) => {
    if (position < BUTTONSWITCH_BREAKPOINT && position > 1) {
      this.setState({ addPosition: "left" });
    } else {
      this.setState({ addPosition: "right" });
    }
  };

  changeTemplate = (selectedTemplateId) => {
    this.setState((state, { availableTemplates, onChange }) => {
      const selectedTemplate = availableTemplates.find(
        ({ id }) => id === selectedTemplateId,
      );
      const changedState = {
        ...selectedTemplate.defaultProps,
        // we're changing the template so we should reset the title and description
        title: "",
        description: "",
      };

      onChange({
        ...changedState,
        image: state.image, // keeping the old image
      });

      return changedState;
    });
  };

  changeTitleValue = (evt) => {
    this.setState({ title: evt.target.value });
  };

  changeDescriptionValue = (evt) => {
    this.setState({ description: evt.target.value });
  };

  handleUploadSuccess = (url, fileType = FILE_TYPES.IMAGE) => {
    let changedValue = {
      title: this.state.title,
      description: this.state.description,
      image: fileType === FILE_TYPES.IMAGE ? url : this.state.image,
      fileType: this.state.fileType,
      video: fileType === FILE_TYPES.VIDEO ? url : this.state.video,
    };

    const changedStateAttribute =
      fileType === FILE_TYPES.IMAGE ? "image" : "video";

    this.setState({ [changedStateAttribute]: url });
    this.props.onChange(changedValue);
  };

  handleValuesChanged = () => {
    let changedValue = {
      title: this.state.title,
      description: this.state.description,
      image: this.state.image,
      fileType: this.state.fileType,
      video: this.state.video,
    };
    this.props.onChange(changedValue);
  };

  handleCoverUploadCompleted = (imageFile) => {
    let newVideo = {
      ...this.state.video,
      posterCover: imageFile,
    };

    this.setState({
      image: imageFile,
      video: newVideo,
    });

    let changedValue = {
      title: this.state.title,
      description: this.state.description,
      image: imageFile,
      fileType: this.state.fileType,
      video: newVideo,
    };

    this.props.onChange(changedValue);
  };

  handleVideoFormatChanged = (videoFormat) => {
    let newVideo = {
      ...this.state.video,
      formatType: videoFormat,
    };

    this.setState({ video: newVideo });

    let changedValue = {
      title: this.state.title,
      description: this.state.description,
      image: this.state.image,
      fileType: this.state.fileType,
      video: newVideo,
    };

    this.props.onChange(changedValue);
  };

  changeCropVisibility = (state) => {
    this.setState({ isCropVisible: state });
  };

  componentDidMount() {
    this.setState({
      title: this.props.title,
      description: this.props.description,
      image: this.props.image,
      fileType: this.props.fileType,
      video:
        this.props.video !== null && this.props.video
          ? this.props.video
          : {
              videoSource: DefaultVideo,
              posterCover: DefaultVideoCover,
              formatType: VIDEO_FORMAT.PORTRAIT,
            },
    });
  }

  render() {
    let props = this.props;
    let { slideId, classes, availableTemplates, type, isSelected } = props;

    return (
      <div
        className={classes.sliderContainer}
        onMouseMove={(e) =>
          props.isSelected
            ? this.checkToChangeAddPosition(e.nativeEvent.offsetX)
            : {}
        }
        onClick={props.onClick}
      >
        <div className={classes.backgroundSlider} />

        {isSelected && (
          <ChangeTemplate
            selectedTemplateId={type}
            templates={availableTemplates}
            onTemplateSelect={this.changeTemplate}
          />
        )}

        <div className={classes.uploadControlsBox}>
          <div className={classes.leftButtonsBox}>
            <UploadMedia
              id={slideId}
              className={classes.uploadBtnWrapper}
              onUploadSuccess={this.handleUploadSuccess}
              onFileTypeDetected={(fileType) => this.setState({ fileType })}
              onVideoUploading={(isUploading) => this.setState({ isUploading })}
              onVideoUploadProgress={(currentUploadProgress) =>
                this.setState({ currentUploadProgress })
              }
              onVideoConverting={(isConverting) =>
                this.setState({ isConverting })
              }
              onVideoConversionProgress={(currentConversionProgress) =>
                this.setState({ currentConversionProgress })
              }
              isVideoUploadEnabled
            >
              <button className={classes.btnUpload}>
                <Icon iconName={Icons.upload} style={classes.iconAction} />
                <FormattedMessage {...translations.UploadImageVideoText} />
              </button>
            </UploadMedia>
            {this.state.fileType === FILE_TYPES.VIDEO ? (
              <UploadMedia
                id={`thumbnail-${slideId}`}
                className={classes.uploadBtnWrapper}
                onUploadSuccess={this.handleCoverUploadCompleted}
              >
                <button className={classes.btnUpload}>
                  <Icon iconName={Icons.thumbnail} style={classes.iconAction} />
                  <FormattedMessage {...translations.AddThumbnailText} />
                </button>
              </UploadMedia>
            ) : (
              <CropImage
                {...props}
                sourceImage={this.state.image}
                isCropVisible={this.state.isCropVisible}
                cropSize={CROP_VARIATION.BACKGROUND}
                onCropClick={() => this.changeCropVisibility(true)}
                onCancel={() => this.changeCropVisibility(false)}
                onCropCompleted={(url, id) => this.handleUploadSuccess(url)}
              />
            )}
          </div>
          <button className={classes.btnUpload} onClick={props.onRemoveClick}>
            <span>
              <FormattedMessage {...translations.DeleteText} />
            </span>
            <Icon iconName={Icons.delete} style={classes.iconAction} />
          </button>
        </div>

        {this.state.addPosition === "left" && props.isSelected ? (
          <div
            className={classes.addBoxLeft}
            onClick={props.onAddToLeftHandle}
            onMouseMove={(event) => event.stopPropagation()}
          >
            <FormattedMessage {...translations.AddSlideLeftAltText}>
              {(message) => <img src={AddSlide} alt={message[0]} />}
            </FormattedMessage>
          </div>
        ) : (
          ""
        )}

        {this.state.fileType === FILE_TYPES.VIDEO ? (
          <SliderVideoPlayer
            source={this.state.video.videoSource}
            poster={
              this.state.video.posterCover
                ? this.state.video.posterCover
                : DefaultVideoCover
            }
            onClick={this.props.onClick}
            onAspectRatioChanged={this.handleVideoFormatChanged}
            formatType={
              this.state.video.formatType
                ? this.state.video.formatType
                : VIDEO_FORMAT.PORTRAIT
            }
            isConverting={this.state.isConverting}
            isUploading={this.state.isUploading}
            progress={
              this.state.isConverting
                ? this.state.currentConversionProgress
                : this.state.currentUploadProgress
            }
            isActive={props.isSelected}
          />
        ) : (
          ""
        )}

        <div className={classes.textEditing}>
          <ContentEditable
            key="title"
            html={this.state.title ? this.state.title : ""}
            disabled={!props.isSelected}
            onChange={this.changeTitleValue}
            onBlur={this.handleValuesChanged}
            tagName="article"
            className={props.classes.titleInput}
          />
          <br />
          <ContentEditable
            key="description"
            html={this.state.description ? this.state.description : ""}
            disabled={!props.isSelected}
            onChange={this.changeDescriptionValue}
            onBlur={this.handleValuesChanged}
            tagName="article"
            className={props.classes.descriptionInput}
          />
        </div>

        {this.state.addPosition === "right" && props.isSelected ? (
          <div
            className={classes.addBoxRight}
            onClick={props.onAddToRightHandle}
            onMouseMove={(event) => event.stopPropagation()}
          >
            <FormattedMessage {...translations.AddSlideRightAltText}>
              {(message) => <img src={AddSlide} alt={message[0]} />}
            </FormattedMessage>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

Slider.propTypes = {
  /** unique id of the slide **/
  slideId: PropTypes.string.isRequired,
  /** The current selected image */
  image: PropTypes.string,
  /** Slide type **/
  type: PropTypes.string,
  /** title value */
  title: PropTypes.string,
  /** description */
  description: PropTypes.string,
  /** Label for the chnage image selection button */
  buttonLabel: PropTypes.string,
  /** On upload completed image change */
  onAddToRightHandle: PropTypes.func,
  /** On handle addLeft event */
  onAddToLeftHandle: PropTypes.func,
  /** on remove click event */
  onRemoveClick: PropTypes.func,
  /** onValueChange */
  onChange: PropTypes.func,
  /** is selected */
  isSelected: PropTypes.bool,
  /** on click */
  onClick: PropTypes.func,
  /** default sliderImage */
  defaultSliderImage: PropTypes.string,
  /** templates available to change the current slide with **/
  availableTemplates: PropTypes.array,
};

Slider.defaultProps = {
  defaultSliderImage: DefaultSlider1,
};

export default withThemedStyle(SliderStyle)(Slider);
