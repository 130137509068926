import { ComponentStyles } from "../../../constants";

const marginRight = ComponentStyles.kpi.widgetContainer.marginRightPx;

export default (theme) => ({
  container: {
    flex: "1 1 200px",
    marginBottom: 20,
    backgroundColor: theme.WidgetContainer.containerBackground,
    borderRadius: 10,
    marginRight: ({ isMarginRightEnabled }) =>
      isMarginRightEnabled && marginRight,
    width: ({ width, isMarginRightEnabled }) =>
      isMarginRightEnabled ? `calc(${width}% - ${marginRight}px)` : `${width}%`,
    // overflow: 'hidden'
  },
});
