import client from "graphql/client";
import { GET_FILES } from "graphql/remote/file/queries/GetFiles.graphql";
import { getFormattedSopiDate } from "utils/dateUtils";
import {
  SET_PDF_TITLE,
  SET_PDF_COVER,
  SET_PDF_URL,
  RESET_PDF,
  SET_PDF_DATE,
  ADD_PDFS,
} from "../../constants/store/pdf";
import clientConfig from "configs/client";
import {
  ADD_DOCUMENT_TAGS,
  CREATE_DOCUMENT,
  CREATE_DOCUMENT_PAGE,
  EDIT_DOCUMENT_FIELDS,
  UPDATE_WORFLOW,
} from "store/publish/graphs";
import { useMutation } from "react-apollo";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { DefaultVideoCover } from "assets/icons";
import { getPath } from "core/paths";

export const SetPdfTitle = (data) => (dispatch) =>
  dispatch({
    type: SET_PDF_TITLE,
    data,
  });

export const SetPdfCover = (data) => (dispatch) =>
  dispatch({
    type: SET_PDF_COVER,
    data,
  });

export const SetPdfUrl = (data) => (dispatch) =>
  dispatch({
    type: SET_PDF_URL,
    data,
  });

export const SetPdfDate = (data) => (dispatch) =>
  dispatch({
    type: SET_PDF_DATE,
    data,
  });

export const ResetPdf = (data) => (dispatch) =>
  dispatch({
    type: RESET_PDF,
    data,
  });

export const GetPdfs = (tagMultipleIntersectIds, lang) => async (dispatch) => {
  try {
    const res = await client.query({
      fetchPolicy: "no-cache",
      query: GET_FILES,
      variables: {
        skip: 0,
        limit: 100,
        tagMultipleIntersectIds: tagMultipleIntersectIds.length
          ? tagMultipleIntersectIds
          : undefined,
        lang,
      },
    });
    const pdfs = res.data?.admin?.documents?.map(({ versions, docId }) => {
      const brands = versions[0].tags
        .filter((tag) =>
          clientConfig.BRAND_TAGS_CLUSTER_IDS.includes(Number(tag.clusterId)),
        )
        .map((tag) => tag.title);
      const brandIds = versions[0].tags
        .filter((tag) =>
          clientConfig.BRAND_TAGS_CLUSTER_IDS.includes(Number(tag.clusterId)),
        )
        .map((tag) => tag.tagId);
      const marketIds = versions[0].tags
        .filter((tag) =>
          clientConfig.USER_MARKET_CLUSTER_IDS.includes(Number(tag.clusterId)),
        )
        .map((tag) => tag.tagId);
      const parsedPageData = versions[0].pages[0]?.data
        ? JSON.parse(versions[0].pages[0]?.data)
        : undefined;

      return {
        docId,
        name: versions[0].title,
        brands,
        brandIds,
        marketIds,
        lang: versions[0].lang,
        publicationDate: getFormattedSopiDate(versions[0].workflowScheduledIn),
        cover: parsedPageData?.cover,
        type: versions[0].pages[0]?.type,
        pageData: parsedPageData,
      };
    });
    dispatch({
      type: ADD_PDFS,
      data: pdfs,
    });
  } catch (e) {
    console.error(e);
  }
};

export const useCreateInsight = () => {
  const [createInsight] = useMutation(CREATE_DOCUMENT("insight"));
  const [addTags] = useMutation(ADD_DOCUMENT_TAGS("insight"));
  const [createInsightPage] = useMutation(CREATE_DOCUMENT_PAGE("insight"));
  const [updatePicCover] = useMutation(
    EDIT_DOCUMENT_FIELDS(
      "insight",
      [{ name: "data", gqlType: "String!" }],
      [
        { type: "picCard", variableName: "data" },
        { type: "picCover", variableName: "data" },
        { type: "picThumb", variableName: "data" },
      ],
    ),
  );
  const history = useHistory();

  const handleCreateInsightFromFileDocument = useCallback(
    async (file) => {
      const { lang } = file;
      const res = await createInsight({
        variables: { title: file.name, lang },
      });
      const insightId = res.data.admin.insight.create.insightId;

      await Promise.all([
        addTags({
          variables: {
            id: insightId,
            lang,
            tagIds: [...file.brandIds, ...file.marketIds],
          },
        }),
        createInsightPage({
          variables: {
            insightId,
            lang,
            type: "article",
            data: JSON.stringify({
              template: "ArticleTemplate",
              data: {
                header: {
                  type: "video",
                  url: file.pageData.url,
                  copyright: "southpigalle",
                  on_pause_url: DefaultVideoCover,
                  legend: null,
                  theme: "fullWidth",
                },
                content: [],
              },
            }),
          },
        }),
        updatePicCover({
          variables: { id: insightId, lang, data: file.cover },
        }),
      ]);
      history.push(getPath("createInsight", insightId, lang));
    },
    [createInsight, addTags, createInsightPage, updatePicCover, history],
  );
  return {
    handleCreateInsightFromFileDocument,
  };
};

const getStatus = (publicationDate) =>
  new Date(getFormattedSopiDate(new Date())) - new Date(publicationDate) < 0
    ? "DRAFT"
    : "PUBLISHED";

export const useCreateFile = () => {
  const [createFile] = useMutation(CREATE_DOCUMENT("file"));
  const [addTags] = useMutation(ADD_DOCUMENT_TAGS("file"));
  const [createFilePage] = useMutation(CREATE_DOCUMENT_PAGE("file"));
  const [updatePicCover] = useMutation(
    EDIT_DOCUMENT_FIELDS(
      "file",
      [{ name: "data", gqlType: "String!" }],
      [
        { type: "picCard", variableName: "data" },
        { type: "picCover", variableName: "data" },
        { type: "picThumb", variableName: "data" },
      ],
    ),
  );
  const [updateFileWorkflow] = useMutation(UPDATE_WORFLOW("file"));

  const handleCreateFile = useCallback(
    async (
      fileType,
      lang,
      title,
      tagIds,
      cover,
      data,
      publicationDate,
      callback,
    ) => {
      const res = await createFile({ variables: { title, lang } });
      const fileId = res.data.admin.file.create.fileId;

      await Promise.all([
        addTags({ variables: { id: fileId, lang, tagIds } }),
        createFilePage({
          variables: {
            fileId,
            lang,
            type: fileType,
            data,
          },
        }),
        updatePicCover({ variables: { id: fileId, lang, data: cover } }),
        updateFileWorkflow({
          variables: {
            id: fileId,
            lang,
            status: getStatus(publicationDate),
            dateIn: publicationDate,
          },
        }),
      ]);
      !!callback && callback();
    },
    [createFile, addTags, createFilePage, updatePicCover, updateFileWorkflow],
  );
  return {
    handleCreateFile,
  };
};

export const useArchiveFile = () => {
  const [updateFileWorkflow] = useMutation(UPDATE_WORFLOW("file"));

  const handleDeleteFile = useCallback(async (fileId, lang, callback) => {
    await updateFileWorkflow({
      variables: {
        id: fileId,
        lang,
        status: "ARCHIVED",
      },
    });
    callback && callback();
  });
  return {
    handleDeleteFile,
  };
};
