import { defineMessages } from "react-intl";

export const translations = defineMessages({
  PeanutWithText: {
    id: "Peanut.PeanutWithText",
    defaultMessage: "WITH TEXT",
  },
  PeanutWithoutText: {
    id: "Peanut.PeanutWithoutText",
    defaultMessage: "WITHOUT TEXT",
  },
  PeanutWithLegend: {
    id: "Peanut.PeanutWithLegend",
    defaultMessage: "WITH LEGEND",
  },
  UploadImageText: {
    id: "Peanut.UploadImageText",
    defaultMessage: "Upload Image",
  },
  DeleteText: {
    id: "Peanut.DeleteText",
    defaultMessage: "Delete",
  },
  AddSlideLeftText: {
    id: "Peanut.AddSlideLeftText",
    defaultMessage: "Add Slide Left",
  },
  AddSlideRightText: {
    id: "Peanut.AddSlideRightText",
    defaultMessage: "Add Slide Right",
  },
});
