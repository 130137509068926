import React, { useMemo } from "react";
import { CheckDropdown } from "genius-ui";

const OPTIONS = [
  { value: "DRAFT", label: "DRAFT" },
  { value: "ACTIVE", label: "ACTIVE" },
  { value: "ARCHIVED", label: "ARCHIVED" },
];

const StatusFilter = ({ handleChange }) => {
  // const defaultValue = useMemo(() => {
  //   if (!data) return undefined
  //   return tagIdTitleToDropDown(data?.tags?.[0])
  // }, [data])

  return (
    <div style={{ width: 200 }}>
      <CheckDropdown
        key="status"
        onChangeHandler={handleChange}
        options={OPTIONS}
        label={"Status"}
      />
    </div>
  );
};

export default StatusFilter;
