import React from "react";
import PropTypes from "prop-types";
import { isUndefined, isNull } from "lodash";
import { LessonRowStyle } from "./LessonsWidgetShiseido.style";
import CompletionRate from "../../common/CompletionRate";
import { FormattedMessage } from "react-intl";
import { lessonRowTranslations as translations } from "./LessonsWidgetShiseido.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import { Icon, Icons } from "genius-ui";
import QuitAt from "../ProgramsWidgetShiseido/QuitAt";

const useStyle = createUseStyles(LessonRowStyle);

const isDefined = (number) => !isUndefined(number) && !isNull(number);

const ProgramsWidgetLessonRowShiseido = (props) => {
  const { lesson, exportLesson } = props;
  const classes = useThemedStyle(useStyle, props);

  const usersQuit = isDefined(lesson.stats.mostLeftPage.usersNb)
    ? lesson.stats.mostLeftPage.usersNb
    : "-";

  const totalUsers = lesson.allowedUsersNb - lesson.stats.unstartedNb;

  const quitPageNumber =
    isDefined(lesson.stats.mostLeftPage.page) && lesson.pageNb
      ? lesson.stats.mostLeftPage.page
      : undefined;
  const totalPageNumber =
    isDefined(lesson.pageNb) && lesson.pageNb ? lesson.pageNb : "NA";

  const getUserQuitPagePercentage = (totalUsers, usersQuit) => {
    return !!totalUsers && !!usersQuit
      ? Math.ceil((usersQuit / totalUsers) * 100)
      : 0;
  };

  return (
    <div className={classes.container}>
      <span>{lesson.name || "-"}</span>
      <span>{lesson.programName || "-"}</span>
      <span>{lesson.degreeName || "-"}</span>
      <span>
        <CompletionRate
          completionPercentage={lesson.usersCompletionPercentage}
          progressBarClassname={classes.progressBar}
          className={classes.completionRateContainer}
          completed={lesson.stats.validationNb}
          total={lesson.allowedUsersNb}
          formatLabel={() => (
            <FormattedMessage
              {...translations.LessonDetailCRLabel}
              values={{
                completionRate: `${lesson.stats.validationNb} / ${lesson.allowedUsersNb}`,
              }}
            />
          )}
        />
      </span>
      <QuitAt
        quitPageNumber={quitPageNumber}
        getUserQuitPagePercentage={getUserQuitPagePercentage}
        totalUsers={totalUsers}
        usersQuit={usersQuit}
      />
      <span style={{ color: totalPageNumber === "NA" ? "#cbcbcb" : undefined }}>
        {totalPageNumber}
      </span>
      <Icon iconName={Icons.exportExcel} handleClick={exportLesson} />
    </div>
  );
};

ProgramsWidgetLessonRowShiseido.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    pageNb: PropTypes.number,
    stats: PropTypes.shape({
      validationNb: PropTypes.number,
      mostLeftPage: PropTypes.shape({
        usersNb: PropTypes.number,
        page: PropTypes.number,
      }).isRequired,
    }).isRequired,
    allowedUsersNb: PropTypes.number,
  }),
};

export default ProgramsWidgetLessonRowShiseido;
