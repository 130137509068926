import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonSize,
  ButtonVariation,
  Icon,
  Icons,
  StylelessModal,
} from "genius-ui";
import { createUseStyles } from "react-jss";
import style from "./CloneModal.style";
import { FormattedMessage } from "react-intl";
import TransparentButton from "../TransparentButton";
import { translations } from "./CloneModal.translations";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const DuplicationModal = (props) => {
  const { visible, hideModal, cloneHandler, cloneMessage, cloneModalTitle } =
    props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <StylelessModal show={visible} onClose={hideModal} centered>
      <div className={classes.modalContent}>
        <div className={classes.header}>
          <div className={classes.title}>{cloneModalTitle}</div>
          <Icon
            handleClick={hideModal}
            iconName={Icons.slimCloseIcon}
            style={classes.close}
          />
        </div>
        <div className={classes.body}>
          <div className={classes.messageLabel}>{cloneMessage}</div>
          <div className={classes.actions}>
            <TransparentButton
              handleClick={hideModal}
              className={classes.cancelBtn}
            >
              <FormattedMessage {...translations.CancelBtnLabel} />
            </TransparentButton>
            <Button
              handleClick={cloneHandler}
              variation={ButtonVariation.primary}
              size={ButtonSize.small}
            >
              <FormattedMessage {...translations.CreateCloneBtnLabel} />
            </Button>
          </div>
        </div>
      </div>
    </StylelessModal>
  );
};

DuplicationModal.propTypes = {
  /** Show or hide modal */
  visible: PropTypes.bool,
  /** Hide modal event handler */
  hideModal: PropTypes.func.isRequired,
  /** Clone handler function */
  cloneHandler: PropTypes.func,
  /** Clone message */
  cloneMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Clone modal title */
  cloneModalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

DuplicationModal.defaultProps = {
  visible: false,
};

export default DuplicationModal;
