import * as constants from "../../constants/store/referential";
import { RESET_STORE } from "../../constants/store/auth";

const initialState = {
  tags: [],
  countries: [],
  userCategories: [],
  brands: [],
  retailers: [],
  eventTypes: [],
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.SET_REFERENTIAL_TAGS:
      return {
        ...state,
        tags: data,
      };
    case constants.SET_REFERENTIAL_COUNTRIES:
      return {
        ...state,
        countries: data,
      };
    case constants.SET_REFERENTIAL_USERCATEGORIES:
      return {
        ...state,
        userCategories: data,
      };
    case constants.SET_REFERENTIAL_BRANDS:
      return {
        ...state,
        brands: data,
      };
    case constants.SET_REFERENTIAL_RETAILERS:
      return {
        ...state,
        retailers: data,
      };
    case constants.SET_REFERENTIAL_EVENT_TYPES:
      return {
        ...state,
        eventTypes: data,
      };
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
