import * as constants from "../../constants/store/lesson";
import { handleBadgeSelection } from "../../utils/store/badges";
import { injectUUID } from "./utils";

const initialState = {
  lessonLocationsLimit: 5,
  lessonLocationsSkip: 0,
  isMasterDocument: false,
  publishLessonData: {
    id: undefined,
    lang: undefined,
    languages: [],
    tags: [],
    predictTags: [],
    countries: [],
    userCategories: [],
    brands: [],
    retailers: [],
    roles: [],
    title: "",
    summary: "",
    cover: "",
    videoCover: "",
    picThumb: "",
    lessonRecap: "",
    picLessonRecap: "",
    startPostingDate: "",
    endPostingDate: "",
    isPublic: false,
    tagsIsEditable: true,
    pages: [],
    showProgramQuizCheckbox: false,
    isProgramQuiz: false,
    isScreenReady: false,
    locations: [],
    files: [],
    badges: [],
    initialBadges: [],
    lessonsBadges: {},
    allLessons: [],
    selectedShops: [],
  },
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.SET_PUBLISH_LESSON_DATA: {
      const locationsIds = state.publishLessonData.locations.map(
        (shop) => shop.id,
      );
      const locationsToAdd = data.locations
        ? data.locations.filter((shop) => !locationsIds.includes(shop.id))
        : [];
      return {
        ...state,
        publishLessonData: {
          ...injectUUID(data),
          locations: state.publishLessonData.locations.concat(locationsToAdd),
        },
      };
    }
    case constants.SET_LESSON_PAGE_DATA: {
      return {
        ...state,
        publishLessonData: {
          ...state.publishLessonData,
          pages: state.publishLessonData.pages.map((page) => {
            if (page.pageId === data.pageId) {
              return {
                ...page,
                data: data.pageData,
              };
            }
            return page;
          }),
        },
      };
    }
    case constants.UPDATE_PUBLISH_LESSON_DATA: {
      return {
        ...state,
        publishLessonData: {
          ...state.publishLessonData,
          ...injectUUID(data),
        },
      };
    }
    case constants.UPDATE_LESSON_LOCATIONS_LIMIT: {
      return {
        ...state,
        lessonLocationsLimit: state.lessonLocationsLimit + 5,
        lessonLocationsSkip: state.lessonLocationsSkip + 5,
      };
    }
    case constants.SET_SELECTED_SHOPS:
      return {
        ...state,
        selectedShops: data,
      };
    case constants.SET_LESSON_MASTER_FLAG:
      return {
        ...state,
        isMasterDocument: data,
      };
    case constants.SET_BADGE_LESSON_IDS:
      return {
        ...state,
        badges: handleBadgeSelection(state, data),
      };
    case constants.SET_LESSON_BADGES: {
      return {
        ...state,
        lessonsBadges: data,
      };
    }
    case constants.SET_BADGES: {
      return {
        ...state,
        badges: data,
        initialBadges: data,
      };
    }
    case constants.RESET_BADGES: {
      return {
        ...state,
        badges: state.initialBadges,
      };
    }
    case constants.SET_ALL_LESSONS: {
      return {
        ...state,
        allLessons: data,
      };
    }
    case constants.RESET_LESSON_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
