import React, { useEffect } from "react";
import { LANGUAGES } from "../../../configs/referential";
import { Dropdown } from "genius-ui";
import style from "./Filters.style";
import { createUseStyles } from "react-jss";
import { connect } from "react-redux";
import clientConfig, { isShiseido } from "../../../configs/client";
import FilterLanguages from "../../../components/FilterLanguages";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import {
  GetMasterTemplates,
  UpdateFilters,
} from "../../../store/masterTemplate/actions";

const useStyle = createUseStyles(style);

const ShityBugFix = ({ currentLanguage, handleOnChange, languages }) => {
  let selectedValue = languages.find((x) => x.Code === currentLanguage);

  if (!selectedValue) {
    selectedValue = languages.find(
      (x) => x.Code === clientConfig.DEFAULT_LANGUAGE_CODE,
    );
    if (!selectedValue) {
      selectedValue = languages[0];
    }
  }
  useEffect(() => {
    handleOnChange({ value: selectedValue.Code });
  }, []);
  return (
    <Dropdown
      selectedOption={{ value: selectedValue.Code, label: selectedValue.Name }}
      onChange={handleOnChange}
      disabled={languages?.length === 1}
      options={languages.map((x) => ({ value: x.Code, label: x.Name }))}
    />
  );
};

const DefaultFilters = (props) => {
  const { SetCurrentLanguage, currentLanguage } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.rootContainer}>
      <FilterLanguages languages={LANGUAGES}>
        {(languages) =>
          languages.length > 1 || isShiseido ? (
            <ShityBugFix
              currentLanguage={currentLanguage}
              handleOnChange={(selectedOption) => {
                SetCurrentLanguage(selectedOption.value);
              }}
              disabled={languages.length === 1}
              languages={languages}
            />
          ) : null
        }
      </FilterLanguages>
    </div>
  );
};

const mapStateToProp = (state) => ({
  currentLanguage: state.masterTemplates.filters.language.selectedValue,
});

const mapDispatchToProps = (dispatch) => ({
  SetCurrentLanguage: (lang) => {
    dispatch(UpdateFilters("language", lang));
    dispatch(GetMasterTemplates());
  },
});

export default connect(mapStateToProp, mapDispatchToProps)(DefaultFilters);
