import React from "react";
import { CreateInsightStyle } from "../CreateInsight.style";
import EditPagesReadOnly from "../../../components/EditPages/readOnly";
import withThemedStyle from "../../../components/hoc/withThemedStyle";
import { DOCUMENT_LIST_WORKFLOWS } from "../../../store/document/reducers";
import { getPath } from "../../../core/paths";
import SideBarNavigation from "../../../components/SideBarNavigation";

class CreateInsight extends React.Component {
  componentDidMount() {
    this.props.GetInsightData(
      this.props.match.params.insightId,
      this.props.match.params.lang,
    );
  }

  goBack = () => {
    this.props.history.push(
      this.props.documentListWorkflow === DOCUMENT_LIST_WORKFLOWS.DELETED
        ? getPath("deletedContent")
        : getPath("contentManager"),
    );
  };

  render() {
    return (
      <div>
        <SideBarNavigation handleBackClick={this.goBack} />
        <EditPagesReadOnly
          classes={this.props.classes}
          currentOpenedMarker={this.props.currentOpenedMarker}
        />
      </div>
    );
  }
}

export default withThemedStyle(CreateInsightStyle)(CreateInsight);
