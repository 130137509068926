import gql from "graphql-tag";

export const CARD_REMOVE_TAGS = gql`
  mutation cardRemoveTags($cardId: ID, $lang: LangEnum, $tagIdList: [ID!]) {
    admin {
      card(cardId: $cardId, lang: $lang) {
        tags {
          remove(tagIds: $tagIdList)
        }
      }
    }
  }
`;
