import PropTypes from "prop-types";
import { NINJA_QUESTION_TYPE_ENUM } from "../../../configs/constants";
import { NinjaQuestionChoicePropTypes } from "./ninja_question_choice";
import uuidV4 from "uuid/dist/v4";
import { NinjaMessages } from "./index";

export const NinjaQuestionPropTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(NINJA_QUESTION_TYPE_ENUM)).isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  description: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.shape(NinjaQuestionChoicePropTypes))
    .isRequired,
};

export const CreateNinjaQuestion = (
  type = NINJA_QUESTION_TYPE_ENUM.NOIMAGE,
  title = "",
  description = "",
) => ({
  id: uuidV4(),
  type,
  title,
  description,
  image: null,
  choices: [],
});

export const NinjaQuestionTemplates = [
  {
    id: NINJA_QUESTION_TYPE_ENUM.COVER,
    name: NinjaMessages.NinjaCoverQuestionTemplateTitle,
    image: "",
  },
  {
    id: NINJA_QUESTION_TYPE_ENUM.NOIMAGE,
    name: NinjaMessages.NinjaNoImageQuestionTemplateTitle,
    image: "",
  },
  {
    id: NINJA_QUESTION_TYPE_ENUM.BACKGROUND,
    name: NinjaMessages.NinjaBackgroundQuestionTemplateTitle,
    image: "",
  },
];
