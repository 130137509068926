import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Style from "./BattleEditor.style";
import BattleQuestionEditorReadOnly from "../BattleQuestionEditor/readOnly";
import { BattleQuestionPropTypes } from "../../../constants/domain_items/battle/battle_question";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const BattleEditor = (props) => {
  const { questions, className } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classNames([classes.container, className])}>
      {questions.map((question) => (
        <BattleQuestionEditorReadOnly key={question.id} {...question} />
      ))}
    </div>
  );
};

BattleEditor.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape(BattleQuestionPropTypes))
    .isRequired,
  /** provide a custom style to the question container **/
  className: PropTypes.string,
};

export default BattleEditor;
