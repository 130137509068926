import { createXml } from "../excelFactory";
import { filterExportArray } from "./filter";
import { generalTranslations as translations } from "./Kpi.translations";

export const exportGeneral = (
  { application, learning, feed, ask },
  filterData,
  intl,
) => {
  const data = filterExportArray(filterData, intl).concat([
    [],
    [
      intl.formatMessage(translations.Application),
      intl.formatMessage(translations.CurrentPeriod),
      intl.formatMessage(translations.PreviousPeriod),
    ],
    [
      intl.formatMessage(translations.ActiveUsers),
      application.activeUsers,
      application.previousPeriod.activeUsers,
    ],
    [
      intl.formatMessage(translations.ActiveConnections),
      application.connections,
      application.previousPeriod.connections,
    ],
    [
      intl.formatMessage(translations.AverageTimeSpent),
      application.dailyUsage,
      application.previousPeriod.dailyUsage,
    ],
    [],
    [intl.formatMessage(translations.LEARNING)],
    [intl.formatMessage(translations.Programs), learning.programsNb],
    [intl.formatMessage(translations.Lessons), learning.lessonsNb],
    [],
    [intl.formatMessage(translations.FEED)],
    [intl.formatMessage(translations.Insights), feed.insights],
    [intl.formatMessage(translations.Lessons), feed.lessons],
    [intl.formatMessage(translations.SocialMedias), feed.socialMedias],
    [intl.formatMessage(translations.Lessons), feed.sliders],
    [],
    [intl.formatMessage(translations.ASK)],
    [
      intl.formatMessage(translations.QuestionAskedToGenius),
      ask.geniusQuestion,
    ],
    [
      intl.formatMessage(translations.QuestionAskedToExperts),
      ask.expertQuestion,
    ],
    [intl.formatMessage(translations.AnsweredAskedByExperts), ask.expertAnswer],
    [
      intl.formatMessage(translations.PendingQuestionsByExperts),
      ask.expertQuestion - ask.expertAnswer,
    ],
  ]);

  createXml(data, "kpi-general-export.xlsx");
};
