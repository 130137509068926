import MenuIconProfile from "./menu/profile.png";
import AddSlide from "../icons/addSlide.svg";

// These assets are availables in the genius-bo-resources repository

const CDN_LOCATION = "https://cdn-mediastoresopi.azureedge.net/superapp-static";

const DefaultSlider1 = `${CDN_LOCATION}/sliders/Placeholder_Slider_1.jpg`;
const DefaultSlider2 = `${CDN_LOCATION}/sliders/Placeholder_Slider_2.jpg`;
const DefaultSlider3 = `${CDN_LOCATION}/sliders/Placeholder_Slider_3.jpg`;
const DefaultVSlider1 = `${CDN_LOCATION}/sliders/Placeholder_VSlider_1.png`;
const DefaultVSlider2 = `${CDN_LOCATION}/sliders/Placeholder_VSlider_2.png`;
const DefaultVideo = `${CDN_LOCATION}/video/DefaultVideo.mp4`;
const DefaultVideoCover = `${CDN_LOCATION}/video/DefaultCover.png`;
const NoImageAvailable = `${CDN_LOCATION}/media/no-photo-available.png`;
const InsightContentPreview = `${CDN_LOCATION}/content_type_previews/insight_preview.png`;
const QuickQuizContentPreview = `${CDN_LOCATION}/content_type_previews/quick_quiz_preview.png`;
const SliderContentPreview = `${CDN_LOCATION}/content_type_previews/slider_preview.png`;
const SocialRepostContentPreview = `${CDN_LOCATION}/content_type_previews/social_repost_preview.png`;
const BattleContentPreview = `${CDN_LOCATION}/content_type_previews/battle_preview.jpg`;
const PollContentPreview = `${CDN_LOCATION}/content_type_previews/poll_preview.png`;
const PeanutWithTextPreview = `${CDN_LOCATION}/media/peanut_with_text.png`;
const PeanutWithoutTextPreview = `${CDN_LOCATION}/media/peanut_without_text.png`;
const PeanutWithLegendPreview = `${CDN_LOCATION}/media/peanut_with_legend.png`;
const SimpleQuizCoverDefault = `${CDN_LOCATION}/media/SimpleQuizCoverDefault.png`;
const SimpleQuizFullScreenDefault = `${CDN_LOCATION}/media/SimpleQuizFullScreenDefault.png`;
const ActivityIntroDefault = `${CDN_LOCATION}/media/ActivityIntroDefaultBG.png`;
const Instagram = `${CDN_LOCATION}/media/instagram.png`;
const InstagramNoContent = `${CDN_LOCATION}/media/instagram-nocontent.png`;
const InstagramLogo = `${CDN_LOCATION}/media/instagram-logo.png`;

export {
  MenuIconProfile,
  NoImageAvailable,
  DefaultSlider1,
  DefaultSlider2,
  DefaultSlider3,
  DefaultVSlider1,
  DefaultVSlider2,
  AddSlide,
  InsightContentPreview,
  QuickQuizContentPreview,
  SliderContentPreview,
  SocialRepostContentPreview,
  BattleContentPreview,
  PeanutWithTextPreview,
  PeanutWithoutTextPreview,
  PeanutWithLegendPreview,
  DefaultVideo,
  DefaultVideoCover,
  ActivityIntroDefault,
  SimpleQuizCoverDefault,
  SimpleQuizFullScreenDefault,
  Instagram,
  InstagramNoContent,
  InstagramLogo,
  PollContentPreview,
};

const TextParagraphPreview = `${CDN_LOCATION}/text_type_previews/text-paragraph.svg`;
const TextTitlePreview = `${CDN_LOCATION}/text_type_previews/text-title.svg`;
const TextSubtitlePreview = `${CDN_LOCATION}/text_type_previews/text-subtitle.svg`;
const TextQuotePreview = `${CDN_LOCATION}/text_type_previews/text-quote.svg`;
const ImageFullWidthPreview = `${CDN_LOCATION}/image_type_previews/image-full-width.png`;
const ImageInContainerPreview = `${CDN_LOCATION}/image_type_previews/image-in-container.png`;
const ImageFullScreenPreview = `${CDN_LOCATION}/image_type_previews/image-full-screen.png`;
const VideoFullWidthPreview = `${CDN_LOCATION}/multimedia_type_previews/video-full-width.png`;
const VideoInContainer = `${CDN_LOCATION}/multimedia_type_previews/video-in-container.png`;
const TextLinkPreview = `${CDN_LOCATION}/text_type_previews/text-link.png`;

const TextPreviews = {
  paragraph: TextParagraphPreview,
  title: TextTitlePreview,
  subtitle: TextSubtitlePreview,
  quote: TextQuotePreview,
  link: TextLinkPreview,
};

const ImagePreviews = {
  fullWidth: ImageFullWidthPreview,
  inContainer: ImageInContainerPreview,
  fullScreen: ImageFullScreenPreview,
};

const MultimediaPreviews = {
  videoFullWidth: VideoFullWidthPreview,
  videoInContainer: VideoInContainer,
};

export { TextPreviews, ImagePreviews, MultimediaPreviews };

const AuthFlowLeftImg = `${CDN_LOCATION}/login/login_left.jpg`;
const AuthFlowLeftImgShiseido = `${CDN_LOCATION}/login/login_left_shiseido.png`;

export { AuthFlowLeftImg, AuthFlowLeftImgShiseido };

const BlueSpinner = `${CDN_LOCATION}/media/blue-spinner.gif`;
const CustomCropperMaskFullScreen = `${CDN_LOCATION}/media/full_screen_image_mask.svg`;
const ImageTypeBoxed = `${CDN_LOCATION}/media/ImageTypeBoxed.png`;
const NoContentImage = `${CDN_LOCATION}/media/nocontent.svg`;
const PeanutDefaultImage1 = `${CDN_LOCATION}/media/peanut_default_image1.png`;
const PeanutDefaultImage2 = `${CDN_LOCATION}/media/peanut_default_image2.png`;
const PeanutWithTextSwitcherImage = `${CDN_LOCATION}/media/peanut_with_text_switcher_image.png`;
const PeanutWithoutTextSwitcherImage = `${CDN_LOCATION}/media/peanut_without_text_switcher_image.png`;
const PlaceHolder = `${CDN_LOCATION}/media/marker_popup_image_placeholder.png`;
const SelectFromComputerIcon = `${CDN_LOCATION}/media/SelectMediaFromComputerIcon.png`;
const SelectFromLibraryIcon = `${CDN_LOCATION}/media/SelectMediaFromLibraryIcon.png`;
const SimpleQuizCoverThumbnail = `${CDN_LOCATION}/media/SimpleQuizzCoverThumbnail.png`;
const SimpleQuizFullScreenThumbnail = `${CDN_LOCATION}/media/SimpleQuizzFullScreenThumbnail.png`;
const SimpleQuizNoImageThumbnail = `${CDN_LOCATION}/media/SimpleQuizzNoImageThumbnail.png`;
const SimpleSlideTemplateImage = `${CDN_LOCATION}/media/SimpleSlideTemplateImage.png`;
const SlideWithMarkerTemplateImage = `${CDN_LOCATION}/media/SlideWithMarkerTemplateImage.png`;

const SimpleQuizActivityTemplateImage = `${CDN_LOCATION}/media/SimpleQuizActivityTemplateImage.png`;
const QuizWithCoverActivityTemplateImage = `${CDN_LOCATION}/media/QuizWithCoverActivityTemplateImage.png`;
const FullImageQuizActivityTemplateImage = `${CDN_LOCATION}/media/FullImageQuizActivityTemplateImage.png`;
const MadLibsActivityTemplateImage = `${CDN_LOCATION}/media/MadLibsActivityTemplateImage.png`;
const DragAndDropActivityTemplateImage = `${CDN_LOCATION}/media/DragAndDropActivityTemplateImage.png`;

const ImageScrollTemplate1Thumbnail = `${CDN_LOCATION}/media/imagescroll_slider - template1.png`;
const ImageScrollTemplate2Thumbnail = `${CDN_LOCATION}/media/imagescroll_slider - template2.jpg`;

const PDFThumbnail = `${CDN_LOCATION}/attachment_types/pdf_attachment_icon.png`;
const PPTThumbnail = `${CDN_LOCATION}/attachment_types/ppt_attachment_icon.png`;
const XLSThumbnail = `${CDN_LOCATION}/attachment_types/xls_attachment_icon.png`;
const BattleLongTypeThumbnail = `${CDN_LOCATION}/battle_templates/long.png`;
const BattleSmallTypeThumbnail = `${CDN_LOCATION}/battle_templates/small.png`;
const BattleMediaTypeThumbnail = `${CDN_LOCATION}/battle_templates/media.png`;

const PlaceholderImage = `${CDN_LOCATION}/media/Placeholder_image.jpg`;
const DefaultUserImage = `${CDN_LOCATION}/media/default_user_image.png`;
const DefaultThumbnail = `${CDN_LOCATION}/attachment_types/document_placeholder.png`;
const HikerManGrey = `${CDN_LOCATION}/media/hiker-man-grey.png`;
const NoPhotoAvailable = `${CDN_LOCATION}/media/no-photo-available.png`;
const RegistrationEventBitMap = `${CDN_LOCATION}/registration_event/bitmap@3x.png`;
const PlaceHolderDegree = `${CDN_LOCATION}/media/Placeholder_Degree.jpg`;

export {
  PlaceHolderDegree,
  BlueSpinner,
  RegistrationEventBitMap,
  NoPhotoAvailable,
  HikerManGrey,
  DefaultThumbnail,
  CustomCropperMaskFullScreen,
  ImageTypeBoxed,
  NoContentImage,
  PeanutDefaultImage1,
  PeanutDefaultImage2,
  PeanutWithTextSwitcherImage,
  PeanutWithoutTextSwitcherImage,
  PlaceHolder,
  SelectFromComputerIcon,
  SelectFromLibraryIcon,
  SimpleQuizCoverThumbnail,
  SimpleQuizFullScreenThumbnail,
  SimpleQuizNoImageThumbnail,
  SimpleSlideTemplateImage,
  SlideWithMarkerTemplateImage,
  SimpleQuizActivityTemplateImage,
  QuizWithCoverActivityTemplateImage,
  FullImageQuizActivityTemplateImage,
  MadLibsActivityTemplateImage,
  DragAndDropActivityTemplateImage,
  ImageScrollTemplate1Thumbnail,
  ImageScrollTemplate2Thumbnail,
  PDFThumbnail,
  PPTThumbnail,
  XLSThumbnail,
  BattleLongTypeThumbnail,
  BattleSmallTypeThumbnail,
  BattleMediaTypeThumbnail,
  PlaceholderImage,
  DefaultUserImage,
};
