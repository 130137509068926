import { defineMessages } from "react-intl";

export default defineMessages({
  WithTextOption: {
    id: "TemplateSelector.WithTextOption",
    defaultMessage: "With Text",
  },
  WithoutTextOption: {
    id: "TemplateSelector.WithoutTextOption",
    defaultMessage: "Without Text",
  },
  WithLegendOption: {
    id: "TemplateSelector.WithLegendOption",
    defaultMessage: "With Legend",
  },
  TemplateThumbnailAltMessage: {
    id: "TemplateSelector.TemplateThumbnailAltMessage",
    defaultMessage: "Template thumbnail image",
  },
  TopText: {
    id: "TemplateSelector.TopText",
    defaultMessage: "Top Text",
  },
  MiddleText: {
    id: "TemplateSelector.MiddleText",
    defaultMessage: "Middle Text",
  },
  BottomText: {
    id: "TemplateSelector.BottomText",
    defaultMessage: "Bottom Text",
  },
});
