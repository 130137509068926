import * as constants from "../../constants/store/masterTemplates";
import clientConfig from "../../configs/client";
import { ADD_TO_MARKET_CONTEXT } from "../../configs/constants";

const initialState = {
  filters: {
    language: {
      selectedValue: clientConfig.DEFAULT_LANGUAGE_CODE,
    },
  },
  templates: [],
  existingDegreeMarketTagIds: [],
  saveDegreeMarketTagIds: [],
  badges: [],
  selectedDegreeDetails: {
    title: "Degree title",
  },
  newDegreeLessons: [],
  originalCloneIds: {
    degreeId: null,
    programId: null,
    lessonId: null,
  },
  cloneContextType: ADD_TO_MARKET_CONTEXT.DEGREE,
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.UPDATE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [data.type]: {
            selectedValue: data.value,
          },
        },
      };
    case constants.UPDATE_TEMPLATES:
      return {
        ...state,
        templates: data,
      };
    case constants.SET_EXISTING_DEGREE_MARKET_TAG_IDS:
      return {
        ...state,
        existingDegreeMarketTagIds: data,
      };
    case constants.SET_SAVE_DEGREE_MARKET_IDS:
      return {
        ...state,
        saveDegreeMarketTagIds: state.saveDegreeMarketTagIds.includes(data)
          ? state.saveDegreeMarketTagIds.filter((tagId) => tagId !== data)
          : [...state.saveDegreeMarketTagIds, data],
      };
    case constants.SET_BADGES:
      return {
        ...state,
        badges: data,
      };
    case constants.SET_DEGREE_DETAILS:
      return {
        ...state,
        selectedDegreeDetails: data,
      };
    case constants.SET_DEGREE_LESSONS:
      return {
        ...state,
        newDegreeLessons: data,
      };
    case constants.SET_BADGE_LESSON_IDS:
      return {
        ...state,
        badges: state.badges.reduce((badges, badge) => {
          let lessonIds = badge.lessonIds;
          if (badge.badgeId === data.badgeId) {
            if (lessonIds.includes(data.lessonId)) {
              lessonIds = lessonIds.filter(
                (lessonId) => lessonId !== data.lessonId,
              );
            } else {
              lessonIds = [...lessonIds, data.lessonId];
            }
          }
          return [
            ...badges,
            {
              ...badge,
              lessonIds,
            },
          ];
        }, []),
      };
    case constants.SET_ALL_BADGE_LESSON_IDS:
      return {
        ...state,
        badges: state.badges.map((badge) => ({
          ...badge,
          lessonIds: data,
        })),
      };
    case constants.SET_CURRENT_CLONE_CONTEXT_IDS:
      return {
        ...state,
        originalCloneIds: data,
      };
    case constants.SET_CURRENT_SET_BADGES_CONTEXT:
      return {
        ...state,
        cloneContextType: data,
      };
    case constants.RESET_STORE:
      return {
        ...initialState,
        saveDegreeMarketTagIds: state.saveDegreeMarketTagIds,
      };
    default:
      return state;
  }
}
