export default (theme) => ({
  option: {
    position: "relative",
    height: 38.5,
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  optionInput: {
    "&:checked, &:not(:checked)": {
      position: "absolute",
      left: -9999,
    },
    "&:checked + label, &:not(:checked) + label": {
      position: "relative",
      paddingLeft: 28,
      cursor: "pointer",
      display: "inline-block",
      height: 21,
      fontFamily: theme.FontFamily,
      fontSize: 18,
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.RadioGroup.checkedLabelColor,
      "&:before": {
        content: '""',
        position: "absolute",
        left: 0,
        top: 0,
        width: 20,
        height: 20,
        borderRadius: "100%",
      },
      "&:after": {
        content: '""',
        position: "absolute",
        top: 5,
        left: 5,
        width: 10,
        height: 10,
        background: theme.RadioGroup.checkedColor,
        borderRadius: "100%",
        WebkitTransition: "all 0.2s ease",
        transition: "all 0.2s ease",
      },
    },
    "&:not(:checked) + label": {
      "&:before": {
        border: `1px solid ${theme.RadioGroup.unCheckedBorderColor}`,
        backgroundColor: theme.RadioGroup.unCheckedBackgroundColor,
      },
      "&:after": {
        opacity: 0,
        WebkitTransform: "scale(0)",
        transform: "scale(0)",
      },
    },
    "&:checked + label": {
      "&:before": {
        border: "none",
        backgroundColor: theme.RadioGroup.checkedBackgroundColor,
      },
      "&:after": {
        opacity: 1,
        WebkitTransform: "scale(1)",
        transform: "scale(1)",
      },
    },
  },
});
