import { defineMessages } from "react-intl";

export const translations = defineMessages({
  ProgramNamePlaceholderText: {
    id: "ProgramInfo.ProgramNamePlaceholderText",
    defaultMessage: "Type your program name",
  },
  ProgramDescriptionPlaceholderText: {
    id: "ProgramInfo.ProgramDescriptionPlaceholderText",
    defaultMessage: "Type your program description",
  },
  TranslateOption: {
    id: "ProgramInfo.TranslateOption",
    defaultMessage: "Translate",
  },
  CloneOption: {
    id: "ProgramInfo.CloneOption",
    defaultMessage: "Clone",
  },
  SendNotificationOption: {
    id: "ProgramInfo.SendNotificationTitle",
    defaultMessage: "Send Notification",
  },
  DeleteOption: {
    id: "ProgramInfo.DeleteOption",
    defaultMessage: "Delete",
  },
  AddLessonPlaceholderText: {
    id: "ProgramInfo.AddLessonPlaceholderText",
    defaultMessage: "Add a lesson",
  },
  EditContentButtonText: {
    id: "ProgramInfo.EditContentButtonText",
    defaultMessage: "Edit content",
  },
  ExpandOption: {
    id: "ProgramInfo.ExpandOption",
    defaultMessage: "Expand",
  },
  CollapseOption: {
    id: "ProgramInfo.CollapseOption",
    defaultMessage: "Collapse",
  },
  AddProgramLabel: {
    id: "ProgramInfo.AddProgramLabel",
    defaultMessage: "Add a program",
  },
  EditContentLabel: {
    id: "ProgramInfo.EditContentLabel",
    defaultMessage: "Edit content",
  },
  PublishOption: {
    id: "ProgramInfo.PublishOption",
    defaultMessage: "Publication settings",
  },
  LessonAuthorLabel: {
    id: "ProgramInfo.LessonAuthorLabel",
    defaultMessage: "Author: {name}",
  },
  MoveToProgramOption: {
    id: "ProgramInfo.MoveToProgramOption",
    defaultMessage: "Change Program",
  },
  SetAsDraftOption: {
    id: "ProgramInfo.SetAsDraftOption",
    defaultMessage: "Set as draft",
  },
  SetAsReadyOption: {
    id: "ProgramInfo.SetAsReadyOption",
    defaultMessage: "Set as ready",
  },
  AddToMarketOption: {
    id: "ProgramInfo.AddToMarketOption",
    defaultMessage: "Add to market",
  },
  AvailableLabel: {
    id: "ProgramInfo.AvailableLabel",
    defaultMessage: "Available",
  },
  EditBadgesOption: {
    id: "programInfo.EditBadgesOption",
    defaultMessage: "Edit Badges",
  },
});
