import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Style from "../PollEditor.style";
import PollQuestionEditorReadOnly from "../../PollQuestionEditor/readOnly";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../../hooks/style/useThemedStyle";
import { PollQuestionPropTypes } from "../../../../constants/domain_items/poll/poll_question";

const useStyle = createUseStyles(Style);

const PollEditor = (props) => {
  const { questions, className } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classNames([classes.container, className])}>
      {questions.map((question) => (
        <PollQuestionEditorReadOnly key={question.id} {...question} />
      ))}
    </div>
  );
};

PollEditor.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape(PollQuestionPropTypes))
    .isRequired,
  /** provide a custom style to the question container **/
  className: PropTypes.string,
};

export default PollEditor;
