import { defineMessages } from "react-intl";

export default defineMessages({
  LeaderboardTitle: {
    id: "KPIBattleExport.LeaderboardTitle",
    defaultMessage: "Leaderboard",
  },
  PublishedTitle: {
    id: "KPIBattleExport.PublishedTitle",
    defaultMessage: "Published battles",
  },
  DetailsTitle: {
    id: "KPIBattleExport.DetailsTitle",
    defaultMessage: "Battle details",
  },
  FileNameWithDocument: {
    id: "KPIBattleExport.FileNameWithDocument",
    defaultMessage: "kpi-battle-{date}-{docTitle}.xlsx",
  },
  FileName: {
    id: "KPIBattleExport.FileName",
    defaultMessage: "kpi-battles-{date}.xlsx",
  },
});
