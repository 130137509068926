import React from "react";
import { FormattedMessage } from "react-intl";
import {
  ImageScrollTemplate1Thumbnail,
  ImageScrollTemplate2Thumbnail,
} from "assets/icons";
import {
  FullScreenScrollTemplateEmpty,
  FullScreenScrollMarkerTemplateEmpty,
} from "../../../../utils/ui-generator/defaultTemplates";
import { translations } from "./ScrollImages.translations";

const ScrollImagesTemplate = (props) => ({
  title: <FormattedMessage {...translations.TemplateTitle} />,
  items: [
    {
      name: <FormattedMessage {...translations.FullScreenScrollSimpleText} />,
      thumbnail: ImageScrollTemplate1Thumbnail,
      onSelect: () => {
        props.handleScrollImagesClick(
          JSON.stringify(FullScreenScrollTemplateEmpty),
        );
      },
    },
    {
      name: <FormattedMessage {...translations.FullScreenScrollDottedText} />,
      thumbnail: ImageScrollTemplate2Thumbnail,
      onSelect: () => {
        props.handleScrollImagesClick(
          JSON.stringify(FullScreenScrollMarkerTemplateEmpty),
        );
      },
    },
  ],
});

export default ScrollImagesTemplate;
