import gql from "graphql-tag";

export const MOVE_UP = gql`
  mutation lessonMoveUp($lang: LangEnum, $programId: ID, $lessonId: ID!) {
    admin {
      program(programId: $programId, lang: $lang) {
        upLesson(lessonId: $lessonId) {
          programId
        }
      }
    }
  }
`;
