import React, { useCallback, useMemo } from "react";
import { PlaceHolderDegree } from "assets/icons";
import { createUseStyles } from "react-jss";
import { Icons } from "genius-ui";
import Style from "../../Library.style";
import useThemedStyle from "hooks/style/useThemedStyle";
import { getUri } from "components/common/ImageOpti/ImageOpti";
import { useHistory } from "react-router-dom";
import { getPath } from "core/paths";
import DotsMenu from "components/common/DotsMenu";
import SettingsMenu from "components/common/SettingsMenu";
import { translations } from "../../Library.translations";
import { useIntl } from "react-intl";
import { useArchiveFile, useCreateInsight } from "store/pdf/actions";

const useStyle = createUseStyles(Style);

const File = ({
  file,
  setActiveMenuDocumentId,
  activeMenuDocumentId,
  showNotificationModal,
}) => {
  const classes = useThemedStyle(useStyle, {});
  const history = useHistory();
  const handleEditFile = useCallback(() => {
    history.push(getPath("editFile", file.docId));
  }, [history.push, file.docId]);
  const { formatMessage } = useIntl();
  const objectFit = useMemo(
    () => (file.cover !== PlaceHolderDegree ? "contain" : undefined),
    [file.cover],
  );
  const { handleCreateInsightFromFileDocument } = useCreateInsight();

  const { handleDeleteFile } = useArchiveFile();
  const menuItems = useMemo(() => {
    const menuItems = [
      {
        label: formatMessage(translations.SendNotificationOption),
        icon: Icons.notification,
        onItemClick: () => showNotificationModal(file),
      },
    ];
    if (file.type === "video") {
      menuItems.push({
        label: formatMessage(translations.SendAsAnInsight),
        icon: Icons.reply,
        onItemClick: () => handleCreateInsightFromFileDocument(file),
      });
    }
    menuItems.push({
      label: formatMessage(translations.DeleteOption),
      icon: Icons.delete,
      onItemClick: () =>
        handleDeleteFile(file.docId, file.lang, () => {
          window.location.reload(false);
        }),
    });
    return menuItems;
  }, [
    file,
    file.type,
    file.docId,
    file.lang,
    formatMessage,
    showNotificationModal,
    handleCreateInsightFromFileDocument,
  ]);

  return (
    <div className={classes.card} key={file.docId} onClick={handleEditFile}>
      <img
        className={classes.cover}
        style={{ objectFit }}
        src={getUri(file.cover, "thumb", "hd")}
      />
      <div className={classes.cardMain}>
        <div className={classes.cardMainTop}>
          <span className={classes.cardBrand}>{file.brands[0]}</span>
          <span className={classes.cardDate}> - {file.publicationDate}</span>
        </div>
        <div className={classes.cardMainBottom}>{file.name}</div>
      </div>
      <div className={classes.cardRight}>
        <DotsMenu
          handleDelete={() => setActiveMenuDocumentId(file.docId)}
          docId={file.docId}
        />
        <SettingsMenu
          handleFocusLeave={() => setActiveMenuDocumentId(undefined)}
          isVisible={
            activeMenuDocumentId && activeMenuDocumentId === file.docId
          }
          className={classes.docSettingsMenu}
          menuItems={menuItems}
        />
      </div>
    </div>
  );
};

export default File;
