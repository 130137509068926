import gql from "graphql-tag";

export const GET_NINJA_BY_ID = gql`
  query getNinjaById($ninjaId: ID) {
    admin {
      ninjas(ninjaId: $ninjaId) {
        versions {
          ninjaId
          title
          lang
          picCover
          isPrivate
          workflow
          workflowScheduledIn
          workflowScheduledOut
          summary
          tags {
            tagId
            title
            clusterId
          }
          pages {
            pageId
            data
          }
        }
      }
    }
  }
`;
