import { defineMessages } from "react-intl";

export const translations = defineMessages({
  NewDraftDegreeTitle: {
    id: "Degrees.NewDraftDegreeTitle",
    defaultMessage: "NEW DRAFT DEGREE",
  },
  NewDegreeButtonText: {
    id: "Degrees.NewDegreeButtonText",
    defaultMessage: "New Degree",
  },
  DuplicatedDegreeMessage: {
    id: "Degrees.DuplicatedDegreeMessage",
    defaultMessage:
      "Your degree has been successfully duplicated for the language {lang}",
  },
});
