import { defineMessages } from "react-intl";

export const translations = defineMessages({
  ZoneTitle: {
    id: "DragAndDropActivityAnswer.ZoneTitle",
    defaultMessage: "Zone {zoneIndex} content",
  },
  AnswerTextInputLabel: {
    id: "DragAndDropActivityAnswer.AnswerTextInputLabel",
    defaultMessage: "Introduce item text here...",
  },
  AnswerImageInputLabel: {
    id: "DragAndDropActivityAnswer.AnswerImageInputLabel",
    defaultMessage: "Upload Image",
  },
  AnswerImageRemoveLabel: {
    id: "DragAndDropActivityAnswer.AnswerImageRemoveLabel",
    defaultMessage: "Remove image",
  },
  AddNewItemButtonLabel: {
    id: "DragAndDropActivityAnswer.AddNewItemButtonLabel",
    defaultMessage: "Add an item (max {maxNrOfItems})",
  },
  ButtonBlockedByEditionLabel: {
    id: "DragAndDropActivityAnswer.ButtonBlockedByEditionLabel",
    defaultMessage: "Modifications in progress...",
  },
});
