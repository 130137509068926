import React from "react";
import PropTypes from "prop-types";
import SimpleQuizQuestion from "../common/SimpleQuizQuestion";
import { memoize } from "lodash";
import { QuizWithCoverActivityQuestionTemplateEmpty } from "../../utils/ui-generator/defaultTemplates";
import { SimpleQuizCoverDefault } from "assets/icons";

const quickQuizAnswerToSimpleQuizAnswer = ({ isTrue, title, message }) => ({
  answer: title,
  isTrue,
  message,
});

const simpleQuizAnswerToQuickQuizAnswer = ({ isTrue, answer, message }) => ({
  isTrue,
  title: answer,
  message,
});

const quickQuizEndMessageToSimpleQuizEndMessage = (background, endMessage) => ({
  background,
  text: endMessage,
});

const simpleQuizEndMessageToQuickQuizEndMessage = ({ text }) => text;

const memoizeEndMessage = memoize(quickQuizEndMessageToSimpleQuizEndMessage);

const quickQuizBackgroundToSimpleQuizBackground = ({
  url,
  copyright,
  _legend,
}) => ({
  url,
  copyright,
  theme: QuizWithCoverActivityQuestionTemplateEmpty.background.theme,
});

const simpleQuizBackgroundToQuickQuizBackground = (
  { url, copyright },
  { legend },
) => ({
  url,
  copyright,
  legend,
});

const defaultEndImage = (background) => ({
  url: background?.url || null,
  copyright: "South Pigalle",
  legend: "Quick quizz",
});

const memoizeBackground = memoize(quickQuizBackgroundToSimpleQuizBackground);

const QuickQuizPageEditor = ({
  pageId,
  image,
  endMessage: endMessageProp,
  endImage: endImageParam,
  answers,
  question,
  onDataChanged,
}) => {
  /** Memoize endMessage value because the useEffect hook inside SimpleQuizQuestion receives a new endMessage object and
   * unnecessarily re-executes on each re-render.
   * It is intentional, that not React.useMemo hook is used here, because it is not recommended to use the hook as a
   * "semantic guarantee":
   * https://reactjs.org/docs/hooks-reference.html#usememo
   * **/
  const background = memoizeBackground(image);
  const endMessage = memoizeEndMessage(background, endMessageProp);

  const handleChange = (
    documentId,
    { background, endMessage, endImage, text, answers },
  ) => {
    onDataChanged({
      image: simpleQuizBackgroundToQuickQuizBackground(background, image),
      endMessage: simpleQuizEndMessageToQuickQuizEndMessage(endMessage),
      question: text,
      endImage,
      answers: answers.map(simpleQuizAnswerToQuickQuizAnswer),
    });
  };
  if (endImageParam.url?.includes("SimpleQuizCoverDefault")) {
    endImageParam.url = null;
  }

  return (
    <SimpleQuizQuestion
      uniqueId="simpleQuizQuestion"
      id={pageId}
      onChange={handleChange}
      isEditable
      background={background}
      endMessage={endMessage}
      answers={answers.map(quickQuizAnswerToSimpleQuizAnswer)}
      text={question}
      type="simple"
      multiQuestionsManagement={false}
      pictureManagementOnEndMessageScreen
      multiAnswerManagement={false}
      changeTemplateAllowed={false}
      quickQuizEndImage={endImageParam || defaultEndImage(background)}
    />
  );
};

QuickQuizPageEditor.propTypes = {
  /** page Id  */
  pageId: PropTypes.string,
  /** on data changed handler */
  onDataChanged: PropTypes.func,
  /** background image of the page **/
  image: PropTypes.object,
  /** end message of the page **/
  endMessage: PropTypes.string,
  /** quiz answers **/
  answers: PropTypes.array,
  /** question **/
  question: PropTypes.string,
};

export default QuickQuizPageEditor;
