import { defineMessages } from "react-intl";

export const translations = defineMessages({
  BattleTitlePlaceholder: {
    id: "PublishBattle.BattleTitlePlaceholder",
    defaultMessage: "Enter your title",
  },
  UploadImage: {
    id: "PublishBattle.UploadImage",
    defaultMessage: "Upload Image",
  },
  BattleDescriptionPlaceholder: {
    id: "PublishBattle.BattleDescriptionPlaceholder",
    defaultMessage: "Describe your battle",
  },
  TimePerQuestion: {
    id: "PublishBattle.TimePerQuestion",
    defaultMessage: "Time per question",
  },
  AddCountry: {
    id: "PublishBattle.AddCountry",
    defaultMessage: "Add a country",
  },
  PublicationCountries: {
    id: "PublishBattle.PublicationCountries",
    defaultMessage: "Publication Countries",
  },
  AssociatedTags: {
    id: "PublishBattle.AssociatedTags",
    defaultMessage: "Associated Tags",
  },
  AddTag: {
    id: "PublishBattle.AddTag",
    defaultMessage: "Add a tag",
  },
  Language: {
    id: "PublishBattle.Language",
    defaultMessage: "Language",
  },
  StartPostingDateLabel: {
    id: "PublishBattle.StartPostingDateLabel",
    defaultMessage: "Start posting date",
  },
  EndPostingDateLabel: {
    id: "PublishBattle.EndPostingDateLabel",
    defaultMessage: "End posting date",
  },
  NoEndPostingDateLabel: {
    id: "PublishBattle.NoEndPostingDateLabel",
    defaultMessage: "No end posting date",
  },
  ScheduleButtonText: {
    id: "PublishBattle.ScheduleButtonText",
    defaultMessage: "Schedule to publish",
  },
  CancelScheduleButtonText: {
    id: "PublishBattle.CancelScheduleButtonText",
    defaultMessage: "Cancel scheduling",
  },
  SaveWithoutScheduleButtonText: {
    id: "PublishBattle.SaveWithoutScheduleButtonText",
    defaultMessage: "Save without scheduling",
  },
  PublicationMessage: {
    id: "PublishBattle.PublicationMessage",
    defaultMessage: "Your battle document has been published",
  },
  ScorePerQuestion: {
    id: "PublishBattle.ScorePerQuestion",
    defaultMessage: "Score per question",
  },
  ScorePlaceholder: {
    id: "PublishBattle.ScorePlaceholder",
    defaultMessage: "Add score",
  },
  BrandLabel: {
    id: "PublishBattle.BrandLabel",
    defaultMessage: "Brand",
  },
  AddBrandPlaceholder: {
    id: "PublishLesson.AddBrandPlaceholder",
    defaultMessage: "Add a brand",
  },
  IndicateBrandsWarning: {
    id: "PublishBattle.IndicateBrandsWarning",
    defaultMessage:
      "Please indicate which brand or brands this content should be associated with before publishing.",
  },
});
