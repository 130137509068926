import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Style from "./NinjaEditor.style";
import NinjaQuestionEditor from "../NinjaQuestionEditor";
import useQuestions from "../../../hooks/activity/useQuestions";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import {
  CreateNinjaQuestion,
  NinjaQuestionPropTypes,
} from "../../../constants/domain_items/ninja/ninja_question";

const useStyle = createUseStyles(Style);

const CreateNewQuestion = (type) => [CreateNinjaQuestion(type)];

const NinjaEditor = (props) => {
  const { questions, isActive, onQuestionsChange, className } = props;
  const classes = useThemedStyle(useStyle, props);
  const { editedQuestionIndex, setEditedQuestionIndex } = useQuestions(
    questions,
    () =>
      CreateNewQuestion(
        editedQuestionIndex !== null && editedQuestionIndex !== undefined
          ? questions[editedQuestionIndex].type
          : undefined,
      ),
    ({ questions }) => onQuestionsChange(questions),
  );

  return (
    <div className={classNames([classes.container, className])}>
      {questions.map((question, index) => (
        <NinjaQuestionEditor
          key={question.id}
          {...question}
          isSelected={isActive && editedQuestionIndex === index}
          onSelect={() => setEditedQuestionIndex(index)}
        />
      ))}
    </div>
  );
};

NinjaEditor.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape(NinjaQuestionPropTypes))
    .isRequired,
  /** editing ninja is enabled **/
  isActive: PropTypes.bool,
  /** func(changedQuestions): function executed when the questions change **/
  onQuestionsChange: PropTypes.func,
  /** provide a custom style to the question container **/
  className: PropTypes.string,
};

export default NinjaEditor;
