import * as constants from "../../constants/store/documents";
import { RESET_STORE } from "../../constants/store/auth";
import { DOCUMENT_LEVEL, DOCUMENT_VIEW_TYPES } from "../../configs/constants";
import clientConfig from "../../configs/client";

export const DOCUMENT_LIST_WORKFLOWS = {
  DELETED: "DELETED_DOCS",
  ACTIVE: "ACTIVE_DOCS",
};

const initialState = {
  currentLanguage: clientConfig.DEFAULT_LANGUAGE_CODE,
  activeMenuDocumentId: undefined,
  currentWorkflowStatus: undefined,
  currentDocumentType: undefined,
  viewType: DOCUMENT_VIEW_TYPES.GRID,
  listWorkflow: undefined,
  selectedMarket: undefined,
  markets: [],
  documentLevel: DOCUMENT_LEVEL.NORMAL,
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.SET_CURRENT_DOCUMENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: data,
      };
    case constants.SET_ACTIVE_MENU_FOR_DOCUMENT_ID:
      return {
        ...state,
        activeMenuDocumentId: data,
      };
    case constants.SET_CURRENT_WORKFLOW_STATUS:
      return {
        ...state,
        currentWorkflowStatus: data,
      };
    case constants.SET_CURRENT_DOCUMENT_TYPE:
      return {
        ...state,
        currentDocumentType: data,
      };
    case constants.SET_CURRENT_DOCUMENT_MARKET:
      return {
        ...state,
        selectedMarket: data,
      };
    case constants.SET_VIEW_TYPE:
      return {
        ...state,
        viewType: data,
      };
    case constants.SET_LIST_WORKFLOW:
      return {
        ...state,
        listWorkflow: data,
      };
    case constants.SET_CURRENT_DOCUMENT_LEVEL:
      return {
        ...state,
        documentLevel: data,
      };
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
