import { defineMessages } from "react-intl";

export const translations = defineMessages({
  Title: {
    id: "MasterTemplates.Title",
    defaultMessage: "Master files",
  },
  EditOption: {
    id: "MasterTemplates.EditOption",
    defaultMessage: "Edit Degree",
  },
  CloneOption: {
    id: "MasterTemplates.CloneOption",
    defaultMessage: "Clone",
  },
  AddToMarketOption: {
    id: "MasterTemplates.AddToMarketOption",
    defaultMessage: "Add to Market",
  },
  TranslateOption: {
    id: "MasterTemplates.TranslateOption",
    defaultMessage: "Translate",
  },
  DeleteOption: {
    id: "MasterTemplates.DeleteOption",
    defaultMessage: "Delete",
  },
  ViewOption: {
    id: "MasterTemplates.ViewOption",
    defaultMessage: "View the Degree",
  },
  Counters: {
    id: "MasterTemplates.Counters",
    defaultMessage: "{programs} Programs {lessons} Lessons",
  },
  StatusDraft: {
    id: "MasterTemplates.StatusDraft",
    defaultMessage: "Draft",
  },
  StatusPublished: {
    id: "MasterTemplates.StatusPublished",
    defaultMessage: "Ready to use",
  },
  DegreeCloneModalTitle: {
    id: "MasterTemplates.DegreeCloneModalTitle",
    defaultMessage: "You are about to make a clone of this degree",
  },
  DegreeCloneMessage: {
    id: "MasterTemplates.DegreeCloneMessage",
    defaultMessage:
      "You are about to make a clone of this degree. " +
      "This clone will keep the tags of Brand and Associated Tags.",
  },
  DegreeSuccessfulCloneMessage: {
    id: "MasterTemplates.DegreeSuccessfulCloneMessage",
    defaultMessage: "Degree has been successfully cloned",
  },
  DeleteMessage: {
    id: "MasterTemplates.DeleteMessage",
    defaultMessage: "Are you sure you want to delete this item?",
  },
  DuplicatedDegreeMessage: {
    id: "MasterTemplates.DuplicatedDegreeMessage",
    defaultMessage:
      "Your degree has been successfully duplicated for the language {lang}",
  },
  BadgesOption: {
    id: "MasterTemplates.BadgesOption",
    defaultMessage: "Edit badges",
  },
});
