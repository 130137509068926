import { LANGUAGES } from "configs/referential";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import clientConfig, {
  COUNTRY_AVAILABLE_LANGUAGES,
  isShiseido,
} from "../../configs/client";
import { USER_ROLE_NAMES } from "../../configs/constants";

export const getCurrentLanguage = (marketId, currentLanguage) => {
  if (!isShiseido) return LANGUAGES[0];

  const marketLanguages = COUNTRY_AVAILABLE_LANGUAGES[marketId];
  const availableLanguages = marketLanguages
    ? LANGUAGES.filter(({ Code }) => marketLanguages.includes(Code))
    : LANGUAGES;
  const newLanguage =
    availableLanguages.find(({ Code }) => Code === currentLanguage?.value) ||
    availableLanguages[0];

  return {
    value: newLanguage.Code,
    label: newLanguage.Name,
  };
};

const FilterLanguages = ({
  userCountryTagIds,
  userRoles,
  languages,
  selectedMarket,
  currentLanguage,
  setCurrentLanguage,
  children,
  convertToValueLabel,
}) => {
  const filterLanguages = (languages) => {
    if (!isShiseido) return languages;

    if (selectedMarket?.value) {
      const marketLanguages = COUNTRY_AVAILABLE_LANGUAGES[selectedMarket.value];
      const availableLanguages = languages.filter(({ Code }) =>
        marketLanguages.includes(Code),
      );

      if (!availableLanguages.some(({ Code }) => Code === currentLanguage)) {
        if (convertToValueLabel) {
          setCurrentLanguage({
            value: availableLanguages[0].Code,
            label: availableLanguages[0].Name,
          });
        } else {
          setCurrentLanguage(availableLanguages[0].Code);
        }
      }
      return availableLanguages;
    }
    if (userRoles.includes(USER_ROLE_NAMES.TRAINER)) {
      const userAvailableLanguageCodes = userCountryTagIds.reduce(
        (availableLanguageCodes, countryTagId) => {
          for (const languageId of COUNTRY_AVAILABLE_LANGUAGES[countryTagId]) {
            if (!availableLanguageCodes.includes(languageId)) {
              availableLanguageCodes.push(languageId);
            }
          }
          return availableLanguageCodes;
        },
        [],
      );

      return languages.filter((language) => {
        return userAvailableLanguageCodes.includes(language.Code);
      });
    }
    return languages;
  };

  return children(filterLanguages(languages), filterLanguages);
};

FilterLanguages.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      Code: PropTypes.string.isRequired,
    }),
  ),
  children: PropTypes.func.isRequired,
};

FilterLanguages.defaultProps = {
  languages: LANGUAGES,
};

const mapStateToProps = (state) => ({
  userCountryTagIds: state.auth.profileInfo.tags
    .filter((tag) =>
      clientConfig.COUNTRY_TAGS_CLUSTERIDS.includes(parseInt(tag.clusterId)),
    )
    .map((tag) => parseInt(tag.tagId)),
  userRoles: state.auth.profileInfo.roles,
});

export default connect(mapStateToProps)(FilterLanguages);
