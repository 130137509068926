import { AuthFlowLeftImg, AuthFlowLeftImgShiseido } from "assets/icons";
import { isShiseido } from "../../configs/client";

const getLeftSideImage = () => {
  if (isShiseido) return AuthFlowLeftImgShiseido;
  return AuthFlowLeftImg;
};

export default (theme) => ({
  rootContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: "100vh",
    margin: "auto",
    alignItems: "center",
    backgroundColor: theme.AuthFlowFormContainer.rootContainerBackgroundColor,
  },
  leftImage: {
    width: isShiseido ? 700 : 500,
    height: "100vh",
    backgroundImage: `url(${getLeftSideImage()})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  formContainer: {
    display: "flex",
    width: "65.2%",
    padding: 20,
    flex: "1 1",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: isShiseido ? 600 : 500,
  },
  title: {
    fontFamily: theme.FontFamily,
    fontSize: 30,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: 0.1,
    textAlign: "center",
    color: theme.AuthFlowFormContainer.titleColor,
    marginBottom: 15,
  },
  subTitle: {
    fontFamily: theme.FontFamily,
    fontSize: 17,
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: 0.06,
    textAlign: isShiseido ? "left" : "center",
    color: theme.AuthFlowFormContainer.subTitleColor,
    marginTop: 0,
    marginBottom: 0,
  },
  versionNumber: {
    position: "absolute",
    bottom: 10,
    right: 10,
    color: theme.AuthFlowFormContainer.versionNumberColor,
    fontSize: 15,
    fontStyle: "normal",
    fontFamily: theme.FontFamily,
    fontWeight: 300,
    lineHeight: "normal",
    fontStretch: "normal",
    letterSpacing: 0.06,
  },
});
