import React from "react";
import PropTypes from "prop-types";
import SettingsMenu from "../../../components/common/SettingsMenu";
import { FormattedMessage } from "react-intl";
import injectSheet from "react-jss";
import listStyle from "./list.style";
import { Button, ButtonSize, ButtonVariation, Icons, Icon } from "genius-ui";
import uuidV4 from "uuid/dist/v4";
import Image from "../../../components/common/Image";
import { translations } from "./List.translations";
import { DocumentTypeTranslations } from "../../../constants/domain_items/documents/document_types";

const ListView = (allProps) => {
  const {
    items,
    editDocument,
    classes,
    showLoadMore,
    loading,
    loadMore,
    onItemSettingsClicked,
    activeMenuDocumentId,
    menuItems,
  } = allProps;

  return (
    <div className={classes.container}>
      <table cellSpacing="0">
        <thead>
          <tr>
            <th>
              <FormattedMessage {...translations.NameHeaderTitle} />
            </th>
            <th>
              <FormattedMessage {...translations.TypeHeaderTitle} />
            </th>
            <th>
              <FormattedMessage {...translations.DateHeaderTitle} />
            </th>
            <th>
              <FormattedMessage {...translations.StatusHeaderTitle} />
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={uuidV4()}>
              <td className={classes.title} onClick={editDocument(item)}>
                <div>
                  <div>
                    <Image
                      width={40}
                      height={40}
                      url={item.picCover}
                      className={classes.image}
                    />
                  </div>
                  <div>{item.title}</div>
                </div>
              </td>
              <td className={classes.type}>
                <FormattedMessage {...DocumentTypeTranslations[item.type]} />
              </td>
              <td className={classes.date}>{item.updateDate}</td>
              <td className={classes.status}>{item.workflow}</td>
              <td>
                <div className={classes.settingsContainer}>
                  <Icon
                    iconName={Icons.roundMore}
                    style={classes.settingsButton}
                    handleClick={() => onItemSettingsClicked(item.id)}
                  />
                  <SettingsMenu
                    handleFocusLeave={() => onItemSettingsClicked(undefined)}
                    isVisible={
                      activeMenuDocumentId && activeMenuDocumentId === item.id
                    }
                    className={classes.docSettingsMenu}
                    menuItems={menuItems(item)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showLoadMore && (
        <div className={classes.loadMoreContainer}>
          <Button
            disabled={loading}
            variation={ButtonVariation.primary}
            size={ButtonSize.small}
            handleClick={loadMore}
          >
            <FormattedMessage {...translations.SeeMoreButtonDefaultTitle} />
          </Button>
        </div>
      )}
    </div>
  );
};

ListView.propTypes = {
  allowEdit: PropTypes.bool,
  allowDuplication: PropTypes.bool,
  allowNotification: PropTypes.bool,
  allowDelete: PropTypes.bool,
};

ListView.defaultProps = {
  allowEdit: true,
  allowDuplication: true,
  allowNotification: true,
  allowDelete: true,
};

export default injectSheet(listStyle)(ListView);
