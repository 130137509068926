export default {
  cardContainer: {
    float: "left",
    maxWidth: "465px",
    position: "relative",
    height: 100,
    borderRadius: "5px",
    backgroundColor: "rgb(255, 255, 255)",
    padding: 10,
  },
  docSettingsMenu: {
    width: 200,
    left: 265,
    position: "absolute",
    top: 100,
    zIndex: 1,
  },
  cardRight: {
    display: "flex",
    width: 10,
  },
  dots: {
    position: "absolute",
    right: 0,
    bottom: 0,
    padding: 10,
    cursor: "pointer",
    color: "grey",
    fontSize: 5,
  },
};
