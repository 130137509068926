import React from "react";
import { Link } from "react-router-dom";
import useThemedStyle from "../../hooks/style/useThemedStyle";
import { createUseStyles } from "react-jss";
import Style from "./NotFound.style";

const useStyle = createUseStyles(Style);

const NotFound = () => {
  const classes = useThemedStyle(useStyle);

  return (
    <div className={classes.content}>
      <h1>404 - Not Found!</h1>
      <Link to="/">Go Home</Link>
    </div>
  );
};

export default NotFound;
