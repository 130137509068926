import { mapCheckDropdownOptionToTKpiTagParameter } from "../../../utils/transformers";
import {
  addMonth,
  addWeek,
  addYear,
  formatDate,
  getYearBeginningDate,
} from "../../../utils/dateUtils";
import { AGGREGATED_KPI_TYPE_ENUM } from "../../../configs/constants";

const dateFormat = "yyyy-MM-dd";

export const getPeriodDate = (value, formatString = false) => {
  const today = new Date();
  let periodStart = today;

  switch (value) {
    case 1:
      periodStart = today;
      break;
    case 2:
      periodStart = addWeek(today, -1);
      break;
    case 3:
      periodStart = addMonth(today, -1);
      break;
    case 4:
      periodStart = addMonth(today, -3);
      break;
    case 5:
      periodStart = addMonth(today, -6);
      break;
    case 6:
      periodStart = addMonth(today, -9);
      break;
    case 7:
      periodStart = addYear(today, -1);
      break;
    case 8:
      periodStart = getYearBeginningDate(today);
      break;
    default:
      break;
  }

  if (formatString) {
    return Object.values(AGGREGATED_KPI_TYPE_ENUM)[value - 1];
  }

  return formatDate(periodStart, dateFormat);
};

export const getPeriodValue = (period, formatString) => {
  if (period.selectedValues[0]) {
    return getPeriodDate(period.selectedValues[0].value, formatString);
  }
  return undefined;
};

export const extractFilterValues = (filters) => {
  return {
    period: filters.period.selectedValues[0]
      ? getPeriodDate(filters.period.selectedValues[0].value)
      : undefined,
    zone: mapCheckDropdownOptionToTKpiTagParameter(filters.zone.selectedValues),
    market: mapCheckDropdownOptionToTKpiTagParameter(
      filters.market.selectedValues,
    ),
    relationship: mapCheckDropdownOptionToTKpiTagParameter(
      filters.relationship.selectedValues,
    ),
    retailer: mapCheckDropdownOptionToTKpiTagParameter(
      filters.retailer.selectedValues,
    ),
    roles: mapCheckDropdownOptionToTKpiTagParameter(
      filters.roles.selectedValues,
    ),
    countries: mapCheckDropdownOptionToTKpiTagParameter(
      filters.countries.selectedValues,
    ),
    cities: mapCheckDropdownOptionToTKpiTagParameter(
      filters.cities.selectedValues,
    ),
    shops: mapCheckDropdownOptionToTKpiTagParameter(
      filters.shops.selectedValues,
    ),
    brands: mapCheckDropdownOptionToTKpiTagParameter(
      filters.brands.selectedValues,
    ),
    division: mapCheckDropdownOptionToTKpiTagParameter(
      filters.division.selectedValues,
    ),
    entity: mapCheckDropdownOptionToTKpiTagParameter(
      filters.entity.selectedValues,
    ),
    department: mapCheckDropdownOptionToTKpiTagParameter(
      filters.department.selectedValues,
    ),
    service: mapCheckDropdownOptionToTKpiTagParameter(
      filters.service.selectedValues,
    ),
    startDate: filters.startDate.selectedValues
      ? formatDate(filters.startDate.selectedValues, dateFormat)
      : undefined,
    endDate: filters.endDate.selectedValues
      ? formatDate(filters.endDate.selectedValues, dateFormat)
      : undefined,
  };
};

export const getFormattedTagList = (tags) => [
  ...(tags.market.tagIds.length ? [tags.market] : []),
  ...(tags.relationship.tagIds.length ? [tags.relationship] : []),
  ...(tags.retailer.tagIds.length ? [tags.retailer] : []),
  ...(tags.roles.tagIds.length ? [tags.roles] : []),
  ...(tags.countries.tagIds.length ? [tags.countries] : []),
  ...(tags.cities.tagIds.length ? [tags.cities] : []),
  ...(tags.zone.tagIds.length ? [tags.zone] : []),
  ...(tags.shops.tagIds.length ? [tags.shops] : []),
  ...(tags.brands.tagIds.length ? [tags.brands] : []),
  ...(tags.division.tagIds.length ? [tags.division] : []),
  ...(tags.entity.tagIds.length ? [tags.entity] : []),
  ...(tags.department.tagIds.length ? [tags.department] : []),
  ...(tags.service.tagIds.length ? [tags.service] : []),
];
