import React, { useMemo, useState } from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import DataTable from "../../components/common/Table";
import messages, { columnLabels } from "./Users.messages";
import UsersTableRow from "./UsersTableRow";
import {
  GetUsers,
  PatchPaginationMetaData,
  DeleteUser,
} from "../../store/user/actions";
import UsersStyle from "./Users.style";
import clientConfig from "../../configs/client";
import DeleteModal from "./../../components/common/DeleteModal";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(UsersStyle);

const columns = clientConfig.USER_MANAGEMENT_DETAIL_COLUMNS.map((column) => ({
  ...column,
  label: columnLabels[column.name] || undefined,
}));

const UsersTable = (props) => {
  const {
    users,
    isLoading,
    sortState,
    page,
    SetPage,
    HandleSort,
    DeleteUser,
    navigateToProfilePage,
    usersOnPage,
  } = props;
  const classes = useThemedStyle(useStyle, props);
  // don't go to the next page while the current page is loading or there are no more pages
  const displaySeeMoreButton =
    !isLoading && users.length >= usersOnPage * page + usersOnPage;
  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();
  const deleteUser = () => {
    DeleteUser(selectedUserId);
    setShowModal(false);
  };

  const renderUserRow = (user) => (
    <UsersTableRow
      key={user.userId}
      user={user}
      onDelete={(user) => DeleteUser(user.userId)}
      navigateToProfilePage={() => navigateToProfilePage(user)}
      setShowModal={setShowModal}
      setSelectedUserId={setSelectedUserId}
    />
  );

  const columnsTable = useMemo(() => {
    return columns.filter(({ name }) => name !== "actions");
  }, [columns]);

  return (
    <>
      <DataTable
        className={classes.usersTableContainer}
        columns={columnsTable}
        data={users}
        renderItem={renderUserRow}
        noDataContent={
          <div className={classes.loader}>
            <FormattedMessage {...messages.NoDataText} />
          </div>
        }
        isLoading={isLoading}
        onSort={HandleSort}
        columnSort={sortState}
        displaySeeMoreButton={displaySeeMoreButton}
        onNextPage={() => SetPage(page + 1)}
      />
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        actionHandler={deleteUser}
        deleteMessage={
          <FormattedMessage
            {...messages.DeleteUserMessage}
            values={{ mark: "?" }}
          />
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  users: state.users.users,
  totalUsers: state.users.userCount,
  isLoading: state.users.paginationMetaData.isLoading,
  sortState: state.users.paginationMetaData.sortState,
  page: state.users.paginationMetaData.page,
  usersOnPage: state.users.paginationMetaData.usersOnPage,
});

const mapDispatchToProps = (dispatch) => ({
  SetPage: (page) => {
    dispatch(PatchPaginationMetaData({ page }));
    dispatch(GetUsers());
  },
  HandleSort: (columnName, sortMethod) => {
    dispatch(
      PatchPaginationMetaData({
        // with this approach we can sort by a single column
        [columnName]: sortMethod,
      }),
    );
    dispatch(GetUsers());
  },
  DeleteUser: (userId) => {
    dispatch(DeleteUser(userId));
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UsersTable,
);
