import { defineMessages } from "react-intl";

export const translations = defineMessages({
  CreationDateLabel: {
    id: "ResultDetailsCard.CreationDateLabel",
    defaultMessage: "Creation date: {date}",
  },
  PublicationDateLabel: {
    id: "ResultDetailsCard.PublicationDateLabel",
    defaultMessage: "Publication date: {date}",
  },
  NotPublishedText: {
    id: "ResultDetailsCard.NotPublishedText",
    defaultMessage: "Not published",
  },
});
