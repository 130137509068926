export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  const byteString = window.atob(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new window.Blob([ab], { type: mimeString });
};

export const downloadDataUri = (dataUri, fileName) => {
  var link = document.createElement("a");
  link.href = dataUri;
  link.download = fileName;
  link.innerHTML = "";
  document.body.appendChild(link);
  link.click();
};
