import React, { Component } from "react";
import { ContentTypeStyle } from "../ContentType.style";
import { Icon, Icons } from "genius-ui";
import ContentTypeCard from "../../../components/ContentTypeCard";
import {
  InsightContentPreview,
  QuickQuizContentPreview,
  SliderContentPreview,
  BattleContentPreview,
  PollContentPreview,
} from "assets/icons";
import { connect } from "react-redux";
import { graphql, Mutation } from "react-apollo";
import compose from "lodash/flowRight";
import {
  INSIGHT_CREATE,
  INSIGHT_CREATE_PAGE,
} from "../../../graphql/remote/insights/mutations";
import {
  CARD_CREATE,
  CARD_CREATE_PAGE,
} from "../../../graphql/remote/cards/mutations";
import { EmptyArticleTemplatePage } from "../../../utils/ui-generator/defaultTemplates";
import { PeanutTemplate } from "../../../utils/ui-generator/peanut.template";
import { QuickQuizTemplate } from "../../../utils/ui-generator/quickQuiz.template";
import {
  DOCUMENT_LEVEL,
  DOCUMENT_TYPES,
  PAGE_TYPES,
} from "../../../configs/constants";
import {
  SetCurrentInsightId,
  SetCurrentPageId as SetCurrentInsightPageId,
  SetCurrentLanguage as SetCurrentInsightLanguage,
  AddInsightTags,
} from "../../../store/insight/actions";
import {
  SetCurrentCardId,
  SetCurrentCardPageId,
  SetCurrentCardLanguage,
  AddCardTags,
} from "../../../store/card/actions";
import { getPath } from "../../../core/paths";
import { injectIntl } from "react-intl";
import { translations } from "../ContentType.translations";
import { CREATE_BATTLE } from "../../../graphql/remote/battle/mutations/CreateBattle";
import { BATTLE_CREATE_PAGE } from "../../../graphql/remote/battle/mutations/BattleCreatePage";
import { EmptyBattlePageTemplate } from "../../../utils/ui-generator/battle.template";
import clientConfig from "../../../configs/client";
import withThemedStyle from "../../../components/hoc/withThemedStyle";
import { CREATE_POLL } from "../../../graphql/remote/poll/mutations/CreatePoll.graphql";
import { POLL_CREATE_PAGE } from "../../../graphql/remote/poll/mutations/PollCreatePage.graphql";
import { EmptyPollPageTemplate } from "../../../utils/ui-generator/poll.template";
import { AddBattleTags } from "../../../store/publishBattle/actions";
import { AddPollTags } from "../../../store/publishPoll/actions";

const createInsightPageRequest = graphql(INSIGHT_CREATE_PAGE, {
  props: ({ mutate }) => ({
    insightCreatePage: (
      insightId,
      lang,
      data,
      pageType,
      history,
      updateIndex,
    ) =>
      mutate({
        variables: {
          insightId: insightId,
          lang: lang,
          type: pageType,
          data: data,
        },
        update: (cache, result) => {
          let redirectLocation = `/insight/create/${insightId}/${lang}`;
          updateIndex(result.data.admin.insight.pages.create.pageId);

          history.push(redirectLocation);
        },
      }),
  }),
});

const createCardPageRequest = graphql(CARD_CREATE_PAGE, {
  props: ({ mutate }) => ({
    cardCreatePage: (
      cardId,
      lang,
      data,
      pageType,
      history,
      updateIndex,
      redirectPath,
    ) =>
      mutate({
        variables: { cardId: cardId, lang: lang, type: pageType, data: data },
        update: (cache, result) => {
          let redirectLocation = getPath(redirectPath, cardId, lang);
          updateIndex(result.data.admin.card.pages.create.pageId);
          history.push(redirectLocation);
        },
      }),
  }),
});

const createBattlePageRequest = graphql(BATTLE_CREATE_PAGE, {
  props: ({ mutate }) => ({
    battleCreatePage: (battleId, lang, data, history) =>
      mutate({
        variables: { battleId, lang, data },
        update: (cache, result) => {
          if (result.data.admin.battle.pages.create.pageId) {
            history.push(getPath("editBattle", battleId, lang));
          }
        },
      }),
  }),
});

const createPollPageRequest = graphql(POLL_CREATE_PAGE, {
  props: ({ mutate }) => ({
    pollCreatePage: (pollId, lang, data, history) =>
      mutate({
        variables: { pollId, lang, data },
        update: (cache, result) => {
          if (result.data.admin.poll.pages.create.pageId) {
            history.push(getPath("editPoll", pollId, lang));
          }
        },
      }),
  }),
});

class ContentType extends Component {
  componentDidMount() {
    this.props.updateCurrentInsightLanguage(
      this.props.selectedDocumentLanguage,
    );
    this.props.updateCurrentCardLanguage(this.props.selectedDocumentLanguage);
  }

  handleBack = () => this.props.history.goBack();

  render() {
    const props = this.props;
    const { classes, intl } = props;

    return (
      <div className={classes.rootContainer}>
        <div className={classes.back} onClick={this.handleBack}>
          <Icon iconName={Icons.arrowLeft} alt="" />
        </div>
        <Mutation
          mutation={INSIGHT_CREATE}
          onCompleted={(data) => {
            let insightId = data.admin.insight.create.insightId;
            props.UpdateCurrentInsightId(insightId);

            if (props.documentLevel === DOCUMENT_LEVEL.MASTER) {
              props.SetDocumentAsMaster(
                insightId,
                DOCUMENT_TYPES.INSIGHT,
                props.currentInsightLanguage,
              );
            } else {
              if (props.selectedMarket && props.selectedMarket.value)
                props.SetDocumentMarketTag(
                  insightId,
                  DOCUMENT_TYPES.INSIGHT,
                  props.currentInsightLanguage,
                  props.selectedMarket.value,
                );
            }

            props.insightCreatePage(
              insightId,
              props.currentInsightLanguage,
              JSON.stringify(EmptyArticleTemplatePage),
              PAGE_TYPES.ARTICLE,
              props.history,
              props.UpdateCurrentInsightPageId,
            );
          }}
        >
          {(createInsight, { data }) => (
            <ContentTypeCard
              containerClassName={classes.cardContainerStyle}
              title={intl.formatMessage({ ...translations.InsightTitle })}
              description={intl.formatMessage({
                ...translations.InsightDescription,
              })}
              image={InsightContentPreview}
              eventHandler={() =>
                createInsight({
                  variables: {
                    title: intl.formatMessage({
                      ...translations.NewDraftInsightTitle,
                    }),
                    lang: props.currentInsightLanguage,
                  },
                })
              }
            />
          )}
        </Mutation>
        <Mutation
          mutation={CARD_CREATE}
          onCompleted={(data) => {
            let cardId = data.admin.card.create.cardId;
            props.UpdateCurrentCardId(cardId);

            if (props.documentLevel === DOCUMENT_LEVEL.MASTER) {
              props.SetDocumentAsMaster(
                cardId,
                DOCUMENT_TYPES.CARD,
                props.currentCardLanguage,
              );
            } else {
              if (props.selectedMarket && props.selectedMarket.value)
                props.SetDocumentMarketTag(
                  cardId,
                  DOCUMENT_TYPES.CARD,
                  props.currentCardLanguage,
                  props.selectedMarket.value,
                );
            }

            props.cardCreatePage(
              cardId,
              props.currentCardLanguage,
              JSON.stringify(PeanutTemplate),
              PAGE_TYPES.PEANUT,
              props.history,
              props.UpdateCurrentCardPageId,
              "createCardPeanut",
            );
          }}
        >
          {(createCard, { data }) => (
            <ContentTypeCard
              containerClassName={classes.cardContainerStyle}
              title={intl.formatMessage({ ...translations.SliderTitle })}
              description={intl.formatMessage({
                ...translations.SliderDescription,
              })}
              image={SliderContentPreview}
              eventHandler={() =>
                createCard({
                  variables: {
                    title: intl.formatMessage({
                      ...translations.NewDraftCardTitle,
                    }),
                    lang: props.currentCardLanguage,
                  },
                })
              }
            />
          )}
        </Mutation>
        <Mutation
          mutation={CARD_CREATE}
          onCompleted={(data) => {
            if (props.documentLevel === DOCUMENT_LEVEL.MASTER) {
              props.SetDocumentAsMaster(
                data.admin.card.create.cardId,
                DOCUMENT_TYPES.CARD,
                props.currentCardLanguage,
              );
            } else {
              if (props.selectedMarket && props.selectedMarket.value)
                props.SetDocumentMarketTag(
                  data.admin.card.create.cardId,
                  DOCUMENT_TYPES.CARD,
                  props.currentCardLanguage,
                  props.selectedMarket.value,
                );
            }
            props.cardCreatePage(
              data.admin.card.create.cardId,
              props.currentCardLanguage,
              JSON.stringify(QuickQuizTemplate),
              PAGE_TYPES.FEEDQUIZZ,
              props.history,
              () => {},
              "quickQuizEdit",
            );
          }}
        >
          {(createCard, { data }) => (
            <ContentTypeCard
              containerClassName={classes.cardContainerStyle}
              title={intl.formatMessage({ ...translations.QuickQuizTitle })}
              description={intl.formatMessage({
                ...translations.QuickQuizDescription,
              })}
              image={QuickQuizContentPreview}
              eventHandler={() =>
                createCard({
                  variables: {
                    title: intl.formatMessage({
                      ...translations.NewDraftQuickQuizTitle,
                    }),
                    lang: props.currentCardLanguage,
                  },
                })
              }
            />
          )}
        </Mutation>
        {clientConfig.MODULES.IS_BATTLE_MANAGEMENT_AVAILABLE && (
          <Mutation
            mutation={CREATE_BATTLE}
            onCompleted={(data) => {
              if (data.admin.battle.create.battleId) {
                if (props.documentLevel === DOCUMENT_LEVEL.MASTER) {
                  props.SetDocumentAsMaster(
                    data.admin.battle.create.battleId,
                    DOCUMENT_TYPES.BATTLE,
                    props.currentCardLanguage,
                  );
                } else {
                  if (props.selectedMarket && props.selectedMarket.value)
                    props.SetDocumentMarketTag(
                      data.admin.battle.create.battleId,
                      DOCUMENT_TYPES.BATTLE,
                      props.currentCardLanguage,
                      props.selectedMarket.value,
                    );
                }

                props.battleCreatePage(
                  data.admin.battle.create.battleId,
                  props.currentCardLanguage,
                  JSON.stringify(EmptyBattlePageTemplate),
                  props.history,
                );
              }
            }}
          >
            {(createBattle) => (
              <ContentTypeCard
                containerClassName={classes.cardContainerStyle}
                title={intl.formatMessage({ ...translations.BattleTitle })}
                description={intl.formatMessage({
                  ...translations.BattleDescription,
                })}
                image={BattleContentPreview}
                eventHandler={() =>
                  createBattle({
                    variables: {
                      title: intl.formatMessage(
                        translations.NewDraftBattleTitle,
                      ),
                      lang: props.currentCardLanguage,
                    },
                  })
                }
              />
            )}
          </Mutation>
        )}
        <Mutation
          mutation={CREATE_POLL}
          onCompleted={(data) => {
            if (data.admin.poll.create.pollId) {
              if (props.documentLevel === DOCUMENT_LEVEL.MASTER) {
                props.SetDocumentAsMaster(
                  data.admin.poll.create.pollId,
                  DOCUMENT_TYPES.POLL,
                  props.currentCardLanguage,
                );
              } else {
                if (props.selectedMarket && props.selectedMarket.value)
                  props.SetDocumentMarketTag(
                    data.admin.poll.create.pollId,
                    DOCUMENT_TYPES.POLL,
                    props.currentCardLanguage,
                    props.selectedMarket.value,
                  );
              }

              props.pollCreatePage(
                data.admin.poll.create.pollId,
                props.currentCardLanguage,
                JSON.stringify(EmptyPollPageTemplate),
                props.history,
              );
            }
          }}
        >
          {(createPoll) => (
            <ContentTypeCard
              disabled={!clientConfig.MODULES.IS_POLL_MANAGEMENT_AVAILABLE}
              containerClassName={classes.cardContainerStyle}
              title={intl.formatMessage({ ...translations.PollTitle })}
              description={intl.formatMessage({
                ...translations.PollDescription,
              })}
              image={PollContentPreview}
              eventHandler={() =>
                createPoll({
                  variables: {
                    title: intl.formatMessage(translations.NewDraftPollTitle),
                    lang: props.currentCardLanguage,
                  },
                })
              }
            />
          )}
        </Mutation>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  selectedDocumentLanguage: state.documents.currentLanguage,
  currentInsightLanguage: state.insights.currentLanguage,
  currentCardLanguage: state.cards.currentLanguage,
  documentLevel: state.documents.documentLevel,
  selectedMarket: state.documents.selectedMarket,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateCurrentInsightLanguage: (lang) => {
    dispatch(SetCurrentInsightLanguage(lang));
  },
  updateCurrentCardLanguage: (lang) => {
    dispatch(SetCurrentCardLanguage(lang));
  },
  UpdateCurrentInsightPageId: (pageId) => {
    dispatch(SetCurrentInsightPageId(pageId));
  },
  UpdateCurrentInsightId: (insightId) => {
    dispatch(SetCurrentInsightId(insightId));
  },
  UpdateCurrentCardPageId: (pageId) => {
    dispatch(SetCurrentCardPageId(pageId));
  },
  UpdateCurrentCardId: (insightId) => {
    dispatch(SetCurrentCardId(insightId));
  },
  SetDocumentAsMaster: (documentId, documentType, lang) => {
    switch (documentType) {
      case DOCUMENT_TYPES.CARD:
        dispatch(
          AddCardTags(
            documentId,
            lang,
            clientConfig.MASTER_DEGREE_TAG_ID
              ? [clientConfig.MASTER_DEGREE_TAG_ID]
              : [],
            () => {},
          ),
        );
        break;
      case DOCUMENT_TYPES.POLL:
        dispatch(
          AddPollTags(
            documentId,
            lang,
            clientConfig.MASTER_DEGREE_TAG_ID
              ? [clientConfig.MASTER_DEGREE_TAG_ID]
              : [],
            () => {},
          ),
        );
        break;
      case DOCUMENT_TYPES.BATTLE:
        dispatch(
          AddBattleTags(
            documentId,
            lang,
            clientConfig.MASTER_DEGREE_TAG_ID
              ? [clientConfig.MASTER_DEGREE_TAG_ID]
              : [],
            () => {},
          ),
        );
        break;
      case DOCUMENT_TYPES.INSIGHT:
      default:
        dispatch(
          AddInsightTags(
            documentId,
            lang,
            clientConfig.MASTER_DEGREE_TAG_ID
              ? [clientConfig.MASTER_DEGREE_TAG_ID]
              : [],
            () => {},
          ),
        );
        break;
    }
  },
  SetDocumentMarketTag: (documentId, documentType, lang, tag) => {
    switch (documentType) {
      case DOCUMENT_TYPES.CARD:
        dispatch(AddCardTags(documentId, lang, [tag], () => {}));
        break;
      case DOCUMENT_TYPES.POLL:
        dispatch(AddPollTags(documentId, lang, [tag], () => {}));
        break;
      case DOCUMENT_TYPES.BATTLE:
        dispatch(AddBattleTags(documentId, lang, [tag], () => {}));
        break;
      case DOCUMENT_TYPES.INSIGHT:
      default:
        dispatch(AddInsightTags(documentId, lang, [tag], () => {}));
        break;
    }
  },
});

const Container = compose(
  createInsightPageRequest,
  createCardPageRequest,
  createBattlePageRequest,
  createPollPageRequest,
)(withThemedStyle(ContentTypeStyle)(ContentType));

export default injectIntl(
  connect(mapStateToProp, mapDispatchToProps)(Container),
);
