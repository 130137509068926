import { defineMessages } from "react-intl";

export const translations = defineMessages({
  TitlePlaceholder: {
    id: "EditMasterTemplateLessonScreen.TitlePlaceholder",
    defaultMessage: "Name Your Degree",
  },
  SummaryPlaceholder: {
    id: "EditMasterTemplateLessonScreen.SummaryPlaceholder",
    defaultMessage: "Describe your Degree",
  },
  LanguageLabel: {
    id: "EditMasterTemplateLessonScreen.LanguageLabel",
    defaultMessage: "Language",
  },
  BrandTagsTitle: {
    id: "EditMasterTemplateLessonScreen.BrandTagsTitle",
    defaultMessage: "Brand",
  },
  RetailerTagsTitle: {
    id: "EditMasterTemplateLessonScreen.RetailerTagsTitle",
    defaultMessage: "Retailer",
  },
  BrandPlaceholder: {
    id: "EditMasterTemplateLessonScreen.BrandPlaceholder",
    defaultMessage: "Add a brand",
  },
  RetailerPlaceholder: {
    id: "EditMasterTemplateLessonScreen.RetailerPlaceholder",
    defaultMessage: "Add a retailer",
  },
  TagsTitle: {
    id: "EditMasterTemplateLessonScreen.TagsTitle",
    defaultMessage: "Associated Tags",
  },
  TagsPlaceholder: {
    id: "EditMasterTemplateLessonScreen.TagsPlaceholder",
    defaultMessage: "Add a tag",
  },
  ScheduleButtonText: {
    id: "EditMasterTemplateLessonScreen.ScheduleButtonText",
    defaultMessage: "Schedule availability",
  },
  NoOneTagMessage: {
    id: "EditMasterTemplateLessonScreen.NoOneTagMessage",
    defaultMessage: "No tag selected",
  },
  PublicButtonLabel: {
    id: "EditMasterTemplateLessonScreen.PublicButtonLabel",
    defaultMessage: "Public",
  },
  AddCustomRecapLabel: {
    id: "EditMasterTemplateLessonScreen.AddCustomRecapLabel",
    defaultMessage: "Add a custom recap",
  },
  WhatRememberLabel: {
    id: "EditMasterTemplateLessonScreen.WhatRememberLabel",
    defaultMessage: "What to Remember",
  },
  WhatRememberPlaceholder: {
    id: "EditMasterTemplateLessonScreen.WhatRememberPlaceholder",
    defaultMessage: "Enter remarks",
  },
  ProgramQuizCheckboxLabel: {
    id: "EditMasterTemplateLessonScreen.ProgramQuizCheckboxLabel",
    defaultMessage: "Set this lesson as Program Quiz",
  },
  ReadyToUseLabel: {
    id: "EditMasterTemplateLessonScreen.ReadyToUseLabel",
    defaultMessage: "Ready to use",
  },
  StartPostingDateLabel: {
    id: "EditMasterTemplateLessonScreen.StartPostingDateLabel",
    defaultMessage: "Start availability date",
  },
  EndPostingDateLabel: {
    id: "EditMasterTemplateLessonScreen.EndPostingDateLabel",
    defaultMessage: "End availability date",
  },
  NoEndPostingDateLabel: {
    id: "EditMasterTemplateLessonScreen.NoEndPostingDateLabel",
    defaultMessage: "No end availability date",
  },
  CancelScheduleButtonText: {
    id: "EditMasterTemplateLessonScreen.CancelScheduleButtonText",
    defaultMessage: "Cancel",
  },
  SaveWithoutScheduleButtonText: {
    id: "EditMasterTemplateLessonScreen.SaveWithoutScheduleButtonText",
    defaultMessage: "Save without scheduling",
  },
  SuccessfulScheduleMessage: {
    id: "EditMasterTemplateLessonScreen.SuccessfulScheduleMessage",
    defaultMessage: "Your lesson has been scheduled",
  },
  IndicateBrandsWarning: {
    id: "EditMasterTemplateLessonScreen.IndicateBrandsWarning",
    defaultMessage:
      "Please indicate which brand or brands this content should be associated with before publishing.",
  },
});
