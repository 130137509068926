import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SetInsightCoverImage,
  SetInsightCoverVideo,
} from "../../../../store/insight/actions";
import { useCurrentInsightId } from "../../hooks/useCurrentInsightId";
import { useCurrentInsightLang } from "../../hooks/useCurrentInsightLang";
import ImageVideoUploader from "../../../../components/common/ImageVideoUploader";

const selectors = {
  videoCover: (state) => state.insights.publishInsight.videoCover,
  cover: (state) => state.insights.publishInsight.cover,
};

const EditInsightCover = () => {
  const dispatch = useDispatch();
  const videoCover = useSelector(selectors.videoCover);
  const cover = useSelector(selectors.cover);
  const currentInsightId = useCurrentInsightId();
  const currentInsightLang = useCurrentInsightLang();

  const updateVideo = useCallback(
    (video) =>
      dispatch(
        SetInsightCoverVideo(currentInsightId, currentInsightLang, video),
      ),
    [currentInsightId, currentInsightLang, dispatch],
  );

  const updateImage = useCallback(
    (image) =>
      dispatch(
        SetInsightCoverImage(currentInsightId, currentInsightLang, image),
      ),
    [currentInsightId, currentInsightLang, dispatch],
  );

  return (
    <ImageVideoUploader
      id="publishInsightImage"
      video={videoCover}
      image={cover}
      updateVideo={updateVideo}
      updateImage={updateImage}
    />
  );
};

export default React.memo(EditInsightCover);
