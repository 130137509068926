import { RESET_STORE } from "../../constants/store/auth";
import {
  UPDATE_FILTER_AVAILABLE_VALUES,
  UPDATE_FILTER_SELECTED_VALUES,
  UPDATE_PAGE_DATA,
  RESET_LEARNING_DATA,
  UPDATE_ALL_LESSONS,
} from "../../constants/store/kpiLearningDetails";

const initialState = {
  programsNb: null,
  lessonsNb: null,
  programs: [],
  filters: {
    brand: {
      selectedValues: [],
    },
    degree: {
      availableValues: [],
      selectedValues: [],
    },
  },
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case UPDATE_PAGE_DATA: {
      return {
        ...state,
        ...data,
      };
    }
    case UPDATE_FILTER_SELECTED_VALUES: {
      return getMergedFilterState(
        state,
        data.filterName,
        "selectedValues",
        data.selectedValues,
      );
    }
    case UPDATE_FILTER_AVAILABLE_VALUES: {
      return getMergedFilterState(
        state,
        data.filterName,
        "availableValues",
        data.availableValues,
      );
    }
    case UPDATE_ALL_LESSONS: {
      return {
        ...state,
        allLessons: data,
      };
    }
    case RESET_LEARNING_DATA: {
      return {
        ...state,
        programsNb: null,
        lessonsNb: null,
        programs: [],
      };
    }
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}

const getMergedFilterState = (
  state,
  filterName,
  filterAttributeName,
  filterAttributeNewValue,
) => ({
  ...state,
  filters: {
    ...state.filters,
    [filterName]: {
      ...state.filters[filterName],
      [filterAttributeName]: filterAttributeNewValue,
    },
  },
});
