import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonSize, ButtonVariation, Card, Icons } from "genius-ui";
import SettingsMenu from "components/common/SettingsMenu";
import { FormattedMessage, useIntl } from "react-intl";
import injectSheet from "react-jss";
import gridStyles from "./Grid.style";
import uuidV4 from "uuid/dist/v4";
import {
  DOC_WORKFLOW_ENUM,
  DOCUMENT_TYPES,
  STATUS_ENUM,
} from "configs/constants";
import { translations } from "./Grid.translations";
import { DocumentTypeTranslations } from "constants/domain_items/documents/document_types";
import { getUri } from "components/common/ImageOpti/ImageOpti";

const GridView = (allProps) => {
  const {
    props,
    items,
    editDocument,
    getEditLabel,
    classes,
    showLoadMore,
    loading,
    loadMore,
    allowEdit,
    allowDelete,
    showDeleteModal,
    setDeleteDocumentData,
    navigateToInsightPublishPage,
    navigateToCardPublishPage,
    navigateToBattlePublishPage,
    navigateToPollPublishPage,
    allowNotification,
    showNotificationModal,
    navigateToTheBattleKpiPage,
    navigateToThePollKpiPage,
  } = allProps;
  const { formatMessage } = useIntl();

  return (
    <>
      {items.map((item) => {
        const isPublishedPoll =
          item.type === DOCUMENT_TYPES.POLL &&
          item.workflow === DOC_WORKFLOW_ENUM.PUBLISHED;
        const isPublishedBattle =
          item.type === DOCUMENT_TYPES.BATTLE &&
          item.workflow === DOC_WORKFLOW_ENUM.PUBLISHED;
        const hideSocialButtons = isPublishedBattle || isPublishedPoll;
        const workflow =
          item.workflow !== "UNPUBLISHED" ? item.workflow : "EXPIRED";
        const seeResultActionHandler = isPublishedPoll
          ? () => navigateToThePollKpiPage(item.id)
          : isPublishedBattle
          ? () => navigateToTheBattleKpiPage(item.id)
          : undefined;

        return (
          <div key={uuidV4()} className={classes.cardContainer}>
            <Card
              title={item.title}
              image_path={getUri(item.picCover, "thumb", "hd")}
              date={
                item.publicationDate || formatMessage(translations.NotScheduled)
              }
              contentType={
                <FormattedMessage {...DocumentTypeTranslations[item.type]} />
              }
              status={workflow}
              handleOnClick={editDocument(props, item)}
              handleOnClickSettingsButton={() =>
                props.onItemSettingsClicked(item.id)
              }
              vote={item.vote}
              read={item.read}
              comment={item.comment}
              isCommentCountVisible={false}
              isVoteCountVisible={!hideSocialButtons}
              isReadCountVisible={
                item.type !== DOCUMENT_TYPES.CARD && !hideSocialButtons
              }
              handleViewButtonClick={seeResultActionHandler}
              viewButtonLabel={
                <FormattedMessage {...translations.SeeResultButtonLabel} />
              }
            />
            <SettingsMenu
              handleFocusLeave={() => props.onItemSettingsClicked(undefined)}
              isVisible={
                props.activeMenuDocumentId &&
                props.activeMenuDocumentId === item.id
              }
              className={classes.docSettingsMenu}
              menuItems={[
                {
                  label: <FormattedMessage {...translations.PublishOption} />,
                  icon: Icons.settings,
                  onItemClick: () => {
                    props.onItemSettingsClicked(undefined);
                    switch (item.type) {
                      case DOCUMENT_TYPES.INSIGHT:
                        navigateToInsightPublishPage(item.id);
                        break;
                      case DOCUMENT_TYPES.CARD:
                        navigateToCardPublishPage(item.id);
                        break;
                      case DOCUMENT_TYPES.BATTLE:
                        navigateToBattlePublishPage(item.id);
                        break;
                      case DOCUMENT_TYPES.POLL:
                        navigateToPollPublishPage(item.id);
                        break;
                      default:
                    }
                  },
                },
                {
                  label: getEditLabel(item.type),
                  icon: Icons.settings,
                  onItemClick: editDocument(props, item),
                  disabled: !allowEdit,
                },
                {
                  label: (
                    <FormattedMessage
                      {...translations.SendNotificationOption}
                    />
                  ),
                  icon: Icons.notification,
                  onItemClick: showNotificationModal(item),
                  disabled: !allowNotification,
                  hidden: item.workflow === STATUS_ENUM.DRAFT,
                },
                {
                  label: <FormattedMessage {...translations.DeleteOption} />,
                  icon: Icons.delete,
                  onItemClick: () => {
                    props.onItemSettingsClicked(undefined);
                    setDeleteDocumentData(item);
                    showDeleteModal();
                  },
                  disabled: !allowDelete,
                },
              ]}
            />
          </div>
        );
      })}
      {showLoadMore && (
        <div className={classes.loadMoreContainer}>
          <Button
            disabled={loading}
            variation={ButtonVariation.primary}
            size={ButtonSize.small}
            handleClick={loadMore}
          >
            <FormattedMessage {...translations.SeeMoreButtonDefaultTitle} />
          </Button>
        </div>
      )}
    </>
  );
};

GridView.propTypes = {
  allowEdit: PropTypes.bool,
  allowDuplication: PropTypes.bool,
  allowNotification: PropTypes.bool,
  allowDelete: PropTypes.bool,
};

GridView.defaultProps = {
  allowEdit: true,
  allowDuplication: true,
  allowNotification: true,
  allowDelete: true,
};

export default injectSheet(gridStyles)(GridView);
