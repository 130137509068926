import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import uuidv4 from "uuid/dist/v4";
import { FormattedMessage } from "react-intl";
import Style from "../DragAndDropActivityAnswer.style.js";
import ContentEditableUncontrolled from "../../ContentEditableUncontrolled";
import { translations } from "../DragAndDropActivityAnswer.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const DragAndDropActivityAnswer = (props) => {
  const { index, cards: cardsProp, className } = props;
  const classes = useThemedStyle(useStyle, props);

  const cards = cardsProp.map((card) => ({
    ...card,
    uid: card.uid || uuidv4(), // generate a uid for backward compatibility purposes(old cards don't have an uid)
  }));

  return (
    <div className={classNames([classes.container, className])}>
      <p className={classes.title}>
        <FormattedMessage
          {...translations.ZoneTitle}
          values={{ zoneIndex: index }}
        />
      </p>
      {cards.map(({ uid, image, text }, index, cards) => {
        const imageEditorStyle = {};
        if (image) {
          imageEditorStyle.backgroundImage = `url('${image}')`;
        }
        return (
          <div
            key={uid}
            className={classNames([
              classes.cardContainer,
              {
                [classes.cardContainerLast]: index === cards.length - 1,
              },
            ])}
          >
            <div className={classes.cardImageEditor} style={imageEditorStyle} />
            <FormattedMessage {...translations.AnswerTextInputLabel}>
              {(message) => (
                <ContentEditableUncontrolled
                  className={classes.cardTextInput}
                  initialValue={text}
                  placeholder={message[0]}
                  disabled
                />
              )}
            </FormattedMessage>
          </div>
        );
      })}
    </div>
  );
};

DragAndDropActivityAnswer.propTypes = {
  /** Answer index **/
  index: PropTypes.number,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  /** Container extra className **/
  className: PropTypes.string,
};

export default DragAndDropActivityAnswer;
