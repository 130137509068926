import React from "react";
import PropTypes from "prop-types";
import { LinkButtonStyle } from "./LinkButton.style.js";
import classnames from "classnames";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(LinkButtonStyle);

const LinkButton = (props) => {
  const { children, handleClick, disabled, className, ...restProps } = props;
  const classes = useThemedStyle(useStyle, props);
  const btnClassName = classnames([classes.linkButton, className]);

  return (
    <button
      type="button"
      className={btnClassName}
      onClick={handleClick}
      disabled={disabled}
      {...restProps}
    >
      {children}
    </button>
  );
};

LinkButton.propTypes = {
  /** the method that handle the click event */
  handleClick: PropTypes.func,
};

export default LinkButton;
