import React from "react";
import PropTypes from "prop-types";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import KPIDropDownFilter from "../DropdownFilter";
import { translations } from "./DegreeFilter.translations";

const KpiLearningDegreeFilter = ({
  filterContainerStoreName,
  ChangeSelectedDegrees,
  GetAvailableDegrees,
  ...restOfProps
}) => {
  const extractFilterObject = (state) => ({
    allValues: state.kpi[
      filterContainerStoreName
    ].filters.degree.availableValues.map(({ degreeId, title }) => ({
      value: degreeId,
      label: title,
    })),
    selectedValues:
      state.kpi[filterContainerStoreName].filters.degree.selectedValues,
  });
  return (
    <KPIDropDownFilter
      getAvailableValues={GetAvailableDegrees}
      extractFilterObjectFromState={extractFilterObject}
      onChange={ChangeSelectedDegrees}
      label={<FormattedMessage {...translations.label} />}
      {...restOfProps}
    />
  );
};

KpiLearningDegreeFilter.propTypes = {
  filterContainerStoreName: PropTypes.string.isRequired,
  GetAvailableDegrees: PropTypes.func.isRequired,
  UpdateFilterSelectedValues: PropTypes.func.isRequired,
  GetPageData: PropTypes.func.isRequired,
};

const mapDispatchToProps = (
  dispatch,
  { GetAvailableDegrees, UpdateFilterSelectedValues, GetPageData },
) => ({
  ChangeSelectedDegrees: (selectedDegrees) => {
    dispatch(UpdateFilterSelectedValues("degree", selectedDegrees));
    dispatch(GetPageData());
  },
  GetAvailableDegrees: () => {
    dispatch(GetAvailableDegrees());
  },
});

export default compose(connect(undefined, mapDispatchToProps))(
  KpiLearningDegreeFilter,
);
