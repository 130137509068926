import { defineMessages } from "react-intl";

export const translations = defineMessages({
  ErrorMessage: {
    id: "DegreeBuilder.ErrorMessageBackup",
    defaultMessage: "Error! {errorMsg}",
  },
  LessonDuplicatedMessage: {
    id: "DegreeBuilder.LessonDuplicatedMessageBackup",
    defaultMessage: "Your lesson has been successfully duplicated",
  },
  LessonSuccessfulCloneMessage: {
    id: "DegreeBuilder.LessonSuccessfulCloneMessageBackup",
    defaultMessage: "Your lesson has been successfully cloned",
  },
  ProgramSuccessfulCloneMessage: {
    id: "DegreeBuilder.ProgramSuccessfulCloneMessageBackup",
    defaultMessage: "Your program has been successfully cloned",
  },
  ProgramDuplicatedMessage: {
    id: "DegreeBuilder.ProgramDuplicatedMessageBackup",
    defaultMessage: "Your program has been successfully duplicated",
  },
  NotificationModalTitle: {
    id: "DegreeBuilder.NotificationModalTitleBackup",
    defaultMessage: "Send a Notification",
  },
  NotificationModalDescription: {
    id: "DegreeBuilder.NotificationModalDescriptionBackup",
    defaultMessage:
      "Send a Push Notification to all users that this content is broadcast to. " +
      "This notification will only be sent once",
  },
  NotificationModalMessagePlaceholder: {
    id: "DegreeBuilder.NotificationModalMessagePlaceholderBackup",
    defaultMessage: "There’s something new to see in Beauty Genius",
  },
  SuccessfullyDeleteProgramMessage: {
    id: "DegreeBuilder.SuccessfullyDeleteProgramMessageBackup",
    defaultMessage: "The program has been successfully deleted",
  },
  LessonCloneMessage: {
    id: "DegreeBuilder.LessonCloneMessageBackup",
    defaultMessage:
      "You are about to make a clone of this lesson. It will appear in the same Language, " +
      "within the Degree and Program as it was originally published. " +
      "This clone will keep the tags of Brand and Associated Tags. " +
      "We recommend that the original and clone lessons are not displayed for the same Market",
  },
  ProgramCloneMessage: {
    id: "DegreeBuilder.ProgramCloneMessageBackup",
    defaultMessage:
      "You are about to make a clone of this program. " +
      "This clone will keep the tags of Brand and Associated Tags. " +
      "We recommend that the original and clone programs are not displayed for the same Market",
  },
  DeleteMessage: {
    id: "DegreeBuilder.DeleteMessageBackup",
    defaultMessage: "Are you sure you want to delete this item{mark}",
  },
  CloneModalTitle: {
    id: "DegreeBuilder.CloneModalTitleBackup",
    defaultMessage: "You are about to make a clone of this lesson",
  },
  LessonRelocationMessage: {
    id: "DegreeBuilder.LessonRelocationMessage",
    defaultMessage: "Lesson was successfully relocated",
  },
  StatusUpdateMessage: {
    id: "DegreeBuilder.StatusUpdateMessage",
    defaultMessage: "Lesson's status successfully updated",
  },
});
