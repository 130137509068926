import React from "react";
import { PageEditorStyle } from "../PageEditor.style";
import TitleReadOnly from "../../../components/common/Title/titleReadOnly";
import RegularReadOnly from "../../../components/common/Regular/regularReadOnly";
import SubTitleReadOnly from "../../../components/common/SubTitle/subTitleReadOnly";
import { decodeNewLines, encodeNewLines } from "../../../utils/string";
import uuidv4 from "uuid/dist/v4";
import { GetDesignPageEncodeData } from "../../../utils/ui-generator/generator";
import {
  CONTROL_TYPES,
  EMPTY_CONTENT,
  PAGE_TYPES,
  VIDEO_CONTROL_POSITION,
} from "../../../configs/constants";
import PropTypes from "prop-types";
import { EditorFontFamily } from "../../../constants";
import LinkReadOnly from "../../common/Link/linkReadOnly";
import { DefaultVideo, DefaultVideoCover } from "assets/icons";
import { FormattedMessage, injectIntl } from "react-intl";
import ImagePageEditorReadOnly from "../../ImagePageEditor/imagePageEditorReadOnly";
import VideoPageEditorReadOnly from "../../VideoPageEditor/videoPageEditorReadOnly";
import { translations } from "../PageEditor.translations";
import withThemedStyle from "../../hoc/withThemedStyle";
import DocumentPageHeader from "../../common/DocumentPageHeader";

const DELETE_KEY = 46;
const BACKSPACE_KEY = 8;
const placeholder = document.createElement("div");

const GetControlType = (controlValue) => {
  switch (controlValue.type) {
    case "text":
      switch (controlValue.theme) {
        case "title":
          return CONTROL_TYPES.TITLE;
        case "subTitle":
          return CONTROL_TYPES.SUBTITLE;
        case "regular":
          return CONTROL_TYPES.REGULAR;
        default:
          return "notdefined";
      }
    case "image":
      return CONTROL_TYPES.IMAGE;
    case "video":
      return CONTROL_TYPES.VIDEO;
    case "link":
      return CONTROL_TYPES.LINK;
    default:
      return "notdefined";
  }
};

const GetValueForControlType = (controlValue) => {
  switch (controlValue.type) {
    case "video":
      return {
        sourceVideo: controlValue.url,
        videoCover: controlValue.on_pause_url,
      };
    case "image":
      return controlValue.url;
    case "link":
      return {
        content: controlValue.content,
        text: controlValue.text,
        type: "link",
        theme: "extra",
      };
    default:
      return controlValue.content === EMPTY_CONTENT
        ? ""
        : decodeNewLines(controlValue.content);
  }
};

class PageEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      children: [],
      componentValues: {},
      currentSelectedChildrenId: "",
      currentPageDocument: "",
      currentActiveIconId: "",
    };

    this.handleAddComponentClick = this.handleAddComponentClick.bind(this);
    this.handleTextComponentValueChange =
      this.handleTextComponentValueChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleNewPageClick = this.handleNewPageClick.bind(this);
  }

  getControlsToRenderFromData(data, intl) {
    let dataObject = JSON.parse(data);
    let contentArray =
      dataObject.data && dataObject.data.content ? dataObject.data.content : [];
    if (dataObject.data && dataObject.data.header) {
      contentArray.splice(0, 0, dataObject.data.header);
    }
    if (contentArray !== undefined) {
      return contentArray
        .map((x, index) => {
          let control = {};
          control.RenderItem = this.getNewComponent(
            GetControlType(x),
            GetValueForControlType(x),
            intl,
            x.theme,
            index,
          );
          control.ValueItem = {};
          control.ValueItem.ControlType = GetControlType(x);
          control.ValueItem.ControlValue = GetValueForControlType(x);
          control.ValueItem.ControlVariation = x.theme;
          control.ValueItem.Index = index;

          return control;
        })
        .filter((x) => x.ValueItem.ControlType !== "notdefined");
    } else {
      return [];
    }
  }

  getNewComponent(controlType, currentValue, intl, controlVariation, index) {
    let newComponent = {};
    let newId = uuidv4();
    newComponent.Id = newId;
    newComponent.ControlType = controlType;

    switch (controlType) {
      case CONTROL_TYPES.TITLE:
        newComponent.RenderControl = (
          <FormattedMessage {...translations.TitlePlaceholderText}>
            {(message) => (
              <TitleReadOnly
                key={newId}
                id={newId}
                value={currentValue}
                placeholder={message}
                handleChange={this.handleTextComponentValueChange(newId)}
                handleKeyDown={this.handleKeyDown(newId)}
                fontFamily={EditorFontFamily}
              />
            )}
          </FormattedMessage>
        );
        break;
      case CONTROL_TYPES.SUBTITLE:
        newComponent.RenderControl = (
          <FormattedMessage {...translations.SubTitlePlaceholderText}>
            {(message) => (
              <SubTitleReadOnly
                key={newId}
                id={newId}
                value={currentValue}
                placeholder={message}
                handleChange={this.handleTextComponentValueChange(newId)}
                handleKeyDown={this.handleKeyDown(newId)}
                fontFamily={EditorFontFamily}
              />
            )}
          </FormattedMessage>
        );
        break;
      case CONTROL_TYPES.REGULAR:
        newComponent.RenderControl = (
          <FormattedMessage {...translations.RegularPlaceholderText}>
            {(message) => (
              <RegularReadOnly
                key={newId}
                id={newId}
                value={currentValue}
                placeholder={message}
                handleChange={this.handleTextComponentValueChange(newId)}
                handleKeyDown={this.handleKeyDown(newId)}
                fontFamily={EditorFontFamily}
              />
            )}
          </FormattedMessage>
        );
        break;
      case CONTROL_TYPES.IMAGE:
        newComponent.RenderControl = () => (
          <FormattedMessage {...translations.ImageSelectorButtonLabel}>
            {(message) => (
              <ImagePageEditorReadOnly
                key={newId}
                uniqueId={`${this.props.pageId}-${index}`}
                id={newId}
                buttonLabel={message}
                sourceImage={currentValue}
                onClick={() => this.handleItemClick(newId)}
                isSelected={
                  this.props.isActivePage &&
                  this.state.currentSelectedChildrenId === newId
                }
                type={controlVariation}
              />
            )}
          </FormattedMessage>
        );
        break;
      case CONTROL_TYPES.VIDEO:
        newComponent.ControlVariation = VIDEO_CONTROL_POSITION.FULLWIDTH;
        newComponent.RenderControl = () => (
          <VideoPageEditorReadOnly
            key={newId}
            uniqueId={`${this.props.pageId}-${index}`}
            id={newId}
            sourceVideo={
              currentValue && currentValue.sourceVideo
                ? currentValue.sourceVideo
                : DefaultVideo
            }
            sourcePosterImage={
              currentValue && currentValue.videoCover
                ? currentValue.videoCover
                : DefaultVideoCover
            }
            onClick={() => this.handleItemClick(newId)}
            isSelected={
              this.props.isActivePage &&
              this.state.currentSelectedChildrenId === newId
            }
            showErrorMessageHandler={this.props.showErrorMessageHandler}
            type={controlVariation}
          />
        );
        break;
      case CONTROL_TYPES.LINK:
        newComponent.RenderControl = (
          <LinkReadOnly
            key={newId}
            id={newId}
            titlePlaceholder={intl.formatMessage({
              ...translations.LinkTitlePlaceholder,
            })}
            linkPlaceholder={intl.formatMessage({
              ...translations.LinkPlaceholder,
            })}
            linkTitleValue={currentValue ? currentValue.text : ""}
            linkValue={currentValue ? currentValue.content : ""}
          />
        );
        break;
      default:
        break;
    }

    return newComponent;
  }

  handleAddComponentClick(controlType, intl, theme) {
    let currentChildrens = [...this.state.children];
    let currentValues = { ...this.state.componentValues };
    let component = this.getNewComponent(
      controlType,
      undefined,
      intl,
      theme,
      currentChildrens.length,
    );
    currentChildrens.push(component);
    currentValues[component.Id] = { Index: currentChildrens.length - 1 };
    this.setState({
      children: currentChildrens,
      componentValues: currentValues,
    });
    this.props.onPageChange(this.props.pageId);
  }

  handleTextComponentValueChange = (id) => (evt) => {
    let currentValues = { ...this.state.componentValues };

    if (!currentValues[id]) {
      currentValues[id] = {};
    }

    let currentControl = this.state.children.find((x) => x.Id === id);
    currentValues[id].ControlValue = evt.target.innerHTML;
    currentValues[id].ControlType = currentControl.ControlType;
    currentValues[id].ControlVariation = currentControl.ControlVariation;

    currentValues = Object.keys(currentValues).reduce(
      (currentValuesNew, key) => {
        const currentControl = currentValues[key];
        if (
          [
            CONTROL_TYPES.REGULAR,
            CONTROL_TYPES.SUBTITLE,
            CONTROL_TYPES.TITLE,
          ].includes(currentValues[key].ControlType)
        ) {
          currentControl.ControlValue = encodeNewLines(
            currentControl.ControlValue,
          );
        }

        currentValuesNew[key] = currentControl;
        return currentValuesNew;
      },
      {},
    );

    this.setState({
      componentValues: currentValues,
      currentSelectedChildrenId: id,
    });

    this.handleSave(currentValues);
  };

  handleLinkChange = (newValues, id) => {
    let currentValues = { ...this.state.componentValues };

    if (!currentValues[id]) {
      currentValues[id] = {};
    }

    currentValues[id].ControlValue = {
      content: newValues.content,
      text: newValues.text,
      type: "link",
      theme: "extra",
    };
    currentValues[id].ControlType = CONTROL_TYPES.LINK;

    this.setState({
      componentValues: currentValues,
      currentSelectedChildrenId: id,
    });

    this.handleSave(currentValues);
  };

  handleKeyDown = (id) => (evt) => {
    this.props.onPageChange(this.props.pageId);
    this.setState({ currentSelectedChildrenId: id });

    let currentValues = { ...this.state.componentValues };
    const currentChildrens = [...this.state.children];
    const currentControl = currentValues[id];
    const innerHtml = evt.currentTarget.innerHTML;
    const targetValue = evt.target.value;

    if (
      (evt.keyCode === DELETE_KEY || evt.keyCode === BACKSPACE_KEY) &&
      (!currentControl || (!innerHtml && !targetValue))
    ) {
      delete currentValues[id];
      this.setState({
        children: currentChildrens.filter((x) => x.Id !== id),
        componentValues: currentValues,
      });
      this.handleSave(currentValues);
    }
  };

  handleItemClick(id) {
    this.props.onPageChange(this.props.pageId);
    this.setState({ currentSelectedChildrenId: id });
  }

  handleSave(controlValues) {
    if (!Object.entries(controlValues).length) {
      this.props.onRemovePage(
        this.props.documentId,
        this.props.language,
        this.props.pageId,
      );
    } else {
      let controlArray = [];
      for (const [key, value] of Object.entries(controlValues)) {
        if (
          value.hasOwnProperty("ControlValue") &&
          (!!value.ControlValue || value.ControlValue === "")
        ) {
          controlArray.push(value);
        }
      }
      controlArray = controlArray.sort((x, y) => (x.Index > y.Index ? 1 : -1));
      let formattedSaveValue = GetDesignPageEncodeData(
        controlArray,
        PAGE_TYPES.ARTICLE,
      );
      this.setState({ currentPageDocument: formattedSaveValue });

      this.props.onDataChanged(
        this.props.documentId,
        this.props.language,
        formattedSaveValue,
        this.props.pageId,
      );
    }
  }

  onDocumentKeyDown = (event) => {
    if (this.props.isActivePage) {
      let currentChildIdToDelete = this.state.currentSelectedChildrenId;
      let currentChildrens = [...this.state.children];
      let currentValues = { ...this.state.componentValues };
      switch (event.keyCode) {
        case DELETE_KEY:
        case BACKSPACE_KEY:
          if (
            currentChildIdToDelete &&
            currentChildIdToDelete !== "" &&
            currentValues[currentChildIdToDelete]
          ) {
            const deletedValue = currentChildrens.find(
              (x) => x.Id === currentChildIdToDelete,
            );
            if (
              deletedValue.ControlType === "image" ||
              deletedValue.ControlType === "video"
            ) {
              currentChildrens = currentChildrens.filter(
                (x) => x.Id !== currentChildIdToDelete,
              );
              delete currentValues[currentChildIdToDelete];
              this.setState({
                children: currentChildrens,
                componentValues: currentValues,
                currentSelectedChildrenId: "",
              });
              this.handleSave(currentValues);
            }
          }
          break;
        default:
          break;
      }
      return true;
    } else {
      return false;
    }
  };

  handleNewPageClick = () => {
    this.setState({ currentSelectedChildrenId: "" });
    this.props.onPageChange(this.props.pageId);
  };

  componentDidMount() {
    let controlsToRender = this.getControlsToRenderFromData(
      this.props.data,
      this.props.intl,
    );
    let children = controlsToRender.map((x) => x.RenderItem);
    let componentValues = {};
    controlsToRender.forEach((x) => {
      componentValues[x.RenderItem.Id] = x.ValueItem;
    });

    this.setState({
      children: children,
      componentValues: componentValues,
    });
    document.addEventListener("keydown", this.onDocumentKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onDocumentKeyDown);
  }

  render() {
    let { classes } = this.props;
    placeholder.className = classes.placeholder;

    return (
      <div className={classes.pageBox}>
        <DocumentPageHeader
          pageNumber={this.props.orderId}
          showOrderButtons={false}
        />
        <div className={classes.componentsBox}>
          {this.state.children.map((x) => (
            <div key={x.Id}>
              {(typeof x.RenderControl === "function" && x.RenderControl()) ||
                x.RenderControl}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

PageEditor.propTypes = {
  /** page Id  */
  pageId: PropTypes.string,
  /** current page data */
  data: PropTypes.string,
  /** on data changed handler */
  onDataChanged: PropTypes.func,
  /** id of current document */
  documentId: PropTypes.string,
  /** current language */
  language: PropTypes.string,
  /**  event to trigger when a new page is deleted */
  onRemovePage: PropTypes.func,
  /** handle for editing page change */
  onPageChange: PropTypes.func,
  /** is active page  */
  isActivePage: PropTypes.bool,
  /** Show error message handler  */
  showErrorMessageHandler: PropTypes.func,
};

export default injectIntl(withThemedStyle(PageEditorStyle)(PageEditor));
