import gql from "graphql-tag";

export const PROGRAM_UPDATE_TITLE = gql`
  mutation programUpdateTitle($programId: ID, $lang: LangEnum, $data: String!) {
    admin {
      program(programId: $programId, lang: $lang) {
        title(data: $data)
      }
    }
  }
`;
