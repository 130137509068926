import gql from "graphql-tag";

export const DEGREE_CLONE = gql`
  mutation DegreeClone($degreeId: ID, $lang: LangEnum!) {
    admin {
      degree(degreeId: $degreeId) {
        cloneDegree(
          lang: $lang
          cloneDegreeItself: true
          cloneTags: false
          cloneTagsFeature: true
        )
      }
    }
  }
`;
