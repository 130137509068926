import gql from "graphql-tag";

export const GET_ALL_DOCUMENTS = gql`
  # Write your query or mutation here
  query getAllDocuments(
    $skip: Int
    $limit: Int
    $lang: LangEnum
    $workflowIds: [ID!]
    $tagIds: [ID!]
    $excludeTagIds: [ID!]
  ) {
    admin {
      documents(
        skip: $skip
        limit: $limit
        lang: $lang
        skipCache: true
        workflowIds: $workflowIds
        tagIds: $tagIds
        excludeTagIds: $excludeTagIds
      ) {
        docId
        versions {
          tags {
            tagId
            clusterId
          }
          title
          pages {
            type
          }
          author {
            profile {
              lastName
              firstName
            }
          }
          lang
          type
          docId
          isPrivate
          picCard
          picCover
          picThumb
          updateDate
          publicationDate
          workflow
          all {
            vote
            read
            comment
            bookmark
            validate
            activity
            answer
          }
        }
      }
    }
  }
`;
