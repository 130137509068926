import { NoContentImage } from "assets/icons";

export default (theme) => ({
  rootContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 50,
  },
  noContentImage: {
    width: 175,
    height: 120,
    backgroundImage: `url("${NoContentImage}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  noContentText: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "condensed",
    lineHeight: "normal",
    letterSpacing: 0.09,
    color: theme.DataNotAvailable.noContentText,
    marginTop: 20,
  },
});
