import gql from "graphql-tag";

export const PROGRAM_GET_CLONED_CHILDREN = gql`
  query getChildProgram($programId: Int, $lang: LangEnum, $tagIds: [ID!]) {
    admin {
      documents(
        cloneParentId: $programId
        lang: $lang
        tagIds: $tagIds
        type: program
      ) {
        docId
      }
    }
  }
`;
