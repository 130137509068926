import { defineMessages } from "react-intl";

export const translations = defineMessages({
  Title: {
    id: "LessonBadges.Title",
    defaultMessage: "Badges",
  },
  CancelBtnText: {
    id: "LessonBadges.CancelBtnText",
    defaultMessage: "Cancel",
  },
  ValidationBtnText: {
    id: "LessonBadges.ValidationBtnText",
    defaultMessage: "Validate",
  },
  ContentTitle: {
    id: "LessonBadges.ContentTitle",
    defaultMessage: "Add your new lessons to {markets}'s {degreeName} badges",
  },
  ContentMasterTitle: {
    id: "LessonBadges.ContentMasterTitle",
    defaultMessage: "Add your new lessons to {degreeName} badges",
  },
  ContentSubtitle: {
    id: "LessonBadges.ContentSubtitle",
    defaultMessage:
      "Adding these new lessons to your badges will let users gain points for completing them! This is the only page on which you can set this lesson as a Program Quiz. Program Quizzes are only completed successfully with 100% correct answers.",
  },
  NoOneLessonText: {
    id: "LessonBadges.NoOneLessonText",
    defaultMessage: "No one lesson",
  },
  NoOneBadgeText: {
    id: "LessonBadges.NoOneBadgeText",
    defaultMessage: "No one badge associated with this degree",
  },
  SuccessfulScheduleMessage: {
    id: "LessonBadges.SuccessfulScheduleMessage",
    defaultMessage: "Your lesson has been scheduled",
  },
  SuccessfulPublishedMessage: {
    id: "LessonBadges.SuccessfulPublishedMessage",
    defaultMessage: "Your lesson has been published",
  },
});
