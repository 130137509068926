import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { ButtonStyle } from "./TransparentButton.style";
import classNames from "classnames";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(ButtonStyle);

const TransparentButton = (props) => {
  const { children, handleClick, className, ...restOfProps } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <button
      onClick={handleClick}
      className={classNames(classes.button, className)}
      {...restOfProps}
    >
      {children}
    </button>
  );
};

TransparentButton.propTypes = {
  /** the method that handle the click event */
  handleClick: PropTypes.func.isRequired,
  /** className for custom styles */
  className: PropTypes.string,
};

export default TransparentButton;
