import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDocument,
  EditDocument,
  EditMultipleDocument,
  SwitchPrivacyDocument,
  AddTags,
  DeleteTags,
  setEndDate,
  setStartDate,
  setWorkflow,
  setRecap,
  GetLocation,
  DeleteLocations,
  UpdateLessonLocationsSkip,
  UpdateLocations,
  AddAttachments,
  RemoveAttachments,
} from "./actions";
import { UPDATE_PUBLISH_DOCUMENT } from "constants/store/publish";
import { createTag as CreateTag } from "store/tag/actions";
import { SetErrorMessage } from "../notification/actions";

export const useLang = () => {
  const lang = useSelector((state) => state.publish.lang);

  return { lang };
};

export const useDocumentInfo = () => {
  const id = useSelector((state) => state.publish.id);
  const { lang } = useLang();

  return { id, lang };
};

export const useLoadDocument = () => {
  const dispatch = useDispatch();
  const LoadDocument = useCallback(
    (id, type, lang) => dispatch(GetDocument(id, type, lang)),
    [],
  );
  const loaded = useSelector((state) => state.publish.loaded);

  return { LoadDocument, loaded };
};

export const useCover = () => {
  const { id, lang } = useDocumentInfo();
  const dispatch = useDispatch();
  const picCover = useSelector((state) => state.publish.picCover);
  const videoCover = useSelector((state) => state.publish.videoCover);
  const EditImage = useCallback(
    (type, data) => {
      dispatch(
        EditMultipleDocument(
          id,
          lang,
          type,
          data,
          [{ name: "url", gqlType: "String!" }],
          [{ type: "picCover", variableName: "url" }],
        ),
      );
      if (data.url) {
        dispatch({
          type: UPDATE_PUBLISH_DOCUMENT,
          data: { picCover: data.url },
        });
      }
    },
    [id, lang],
  );
  const EditVideo = useCallback(
    (type, data) => {
      dispatch(
        EditMultipleDocument(
          id,
          lang,
          type,
          data,
          [{ name: "url", gqlType: "String!" }],
          [{ type: "videoCover", variableName: "url" }],
        ),
      );
      if (data.url) {
        dispatch({
          type: UPDATE_PUBLISH_DOCUMENT,
          data: { videoCover: data.url },
        });
      }
    },
    [id, lang],
  );

  return { picCover, videoCover, EditImage, EditVideo };
};

export const usePrivacy = () => {
  const { id, lang } = useDocumentInfo();
  const dispatch = useDispatch();
  const isPrivate = useSelector((state) => state.publish.isPrivate);
  const EditPrivacy = useCallback(
    (type, isPrivate) =>
      dispatch(SwitchPrivacyDocument(id, lang, type, isPrivate)),
    [id, lang],
  );

  return { isPrivate, EditPrivacy };
};

export const useTitle = () => {
  const { id, lang } = useDocumentInfo();
  const dispatch = useDispatch();
  const title = useSelector((state) => state.publish.title);
  const EditTitle = useCallback(
    (type, data) => dispatch(EditDocument(id, lang, type, "title", data)),
    [id, lang],
  );

  return { title, EditTitle };
};

export const useSummary = () => {
  const { id, lang } = useDocumentInfo();
  const dispatch = useDispatch();
  const summary = useSelector((state) => state.publish.summary);
  const EditSummary = useCallback(
    (type, data) => dispatch(EditDocument(id, lang, type, "summary", data)),
    [id, lang],
  );

  return { summary, EditSummary };
};

export const useTag = () => {
  const { id, lang } = useDocumentInfo();
  const dispatch = useDispatch();
  const addTag = useCallback(
    (type, storeType, tag) => dispatch(AddTags(id, lang, type, storeType, tag)),
    [id, lang],
  );
  const deleteTag = useCallback(
    (type, storeType, tagIds) =>
      dispatch(DeleteTags(id, lang, type, storeType, tagIds)),
    [id, lang],
  );
  const createTag = useCallback(async (type, storeType, clusterId, title) => {
    const newTagId = await CreateTag(clusterId, title);
    if (!newTagId) {
      dispatch(
        SetErrorMessage(`An error occurred while creating the tag ${title}`),
      );
    } else {
      addTag(type, storeType, { value: newTagId, label: title });
    }
  }, []);

  return { addTag, deleteTag, createTag };
};

export const useGlobal = () => {
  const { id, lang } = useDocumentInfo();
  const dispatch = useDispatch();
  const zones = useSelector((state) => state.publish.zones);
  const countries = useSelector((state) => state.publish.countries);
  const clearGlobal = useCallback(
    (type, storeType, tagIds) => {
      let tagIdsToDelete = tagIds;

      if (storeType === "zones") {
        tagIdsToDelete = tagIdsToDelete.concat(
          countries
            .filter(({ clusterMetaTagId }) =>
              tagIdsToDelete.includes(clusterMetaTagId),
            )
            .map((country) => country.tagId),
        );
      }
      dispatch(DeleteTags(id, lang, type, storeType, tagIdsToDelete));
    },
    [id, lang],
  );

  return { zones, countries, clearGlobal };
};

export const useZones = () => {
  const zones = useSelector((state) => state.publish.zones);

  return { zones };
};

export const useCountries = () => {
  const countries = useSelector((state) => state.publish.countries);

  return { countries };
};

export const useAssociated = () => {
  const associated = useSelector((state) => state.publish.associated);

  return { associated };
};

export const useBrand = () => {
  const brand = useSelector((state) => state.publish.brand);

  return { brand };
};

export const useRetailer = () => {
  const retailer = useSelector((state) => state.publish.retailer);

  return { retailer };
};

export const useUniverse = () => {
  const universe = useSelector((state) => state.publish.universe);

  return { universe };
};

export const useWorkflowScheduledIn = () => {
  const dispatch = useDispatch();
  const { id, lang } = useDocumentInfo();
  const workflowScheduledIn = useSelector(
    (state) => state.publish.workflowScheduledIn,
  );
  const setWorkflowScheduledIn = useCallback(
    (type, publishSetting, workflowScheduledIn) =>
      dispatch(
        setStartDate(id, lang, type, publishSetting, workflowScheduledIn),
      ),
    [id, lang],
  );

  return { workflowScheduledIn, setWorkflowScheduledIn };
};

export const useWorkflowScheduledOut = () => {
  const dispatch = useDispatch();
  const { id, lang } = useDocumentInfo();
  const workflowScheduledOut = useSelector(
    (state) => state.publish.workflowScheduledOut,
  );
  const setWorkflowScheduledOut = useCallback(
    (type, workflowScheduledOut) =>
      dispatch(setEndDate(id, lang, type, workflowScheduledOut)),
    [id, lang],
  );

  return { workflowScheduledOut, setWorkflowScheduledOut };
};

export const useWorkflow = () => {
  const dispatch = useDispatch();
  const { id, lang } = useDocumentInfo();
  const workflow = useSelector((state) => state.publish.workflow);
  const updateWorkflow = useCallback(
    (type, publishSetting) =>
      dispatch(setWorkflow(id, lang, type, publishSetting)),
    [id, lang],
  );

  return { workflow, updateWorkflow };
};

export const useRecap = () => {
  const dispatch = useDispatch();
  const { id, lang } = useDocumentInfo();
  const picLessonRecap = useSelector((state) => state.publish.picLessonRecap);
  const lessonRecap = useSelector((state) => state.publish.lessonRecap);
  const updatePicLessonRecap = useCallback(
    (type, data) => {
      dispatch(setRecap(id, lang, type, "picLessonRecap", data));
    },
    [id, lang],
  );
  const updateLessonRecap = useCallback(
    (type, data) => {
      dispatch(setRecap(id, lang, type, "lessonRecap", data));
    },
    [id, lang],
  );

  return {
    picLessonRecap,
    lessonRecap,
    updatePicLessonRecap,
    updateLessonRecap,
  };
};

export const useLocation = () => {
  const dispatch = useDispatch();
  const { id, lang } = useDocumentInfo();
  const locations = useSelector((state) => state.publish.locations);
  const getLocations = useCallback(() => dispatch(GetLocation()), []);
  const deleteLocation = useCallback(
    (deleteLocationIds) => {
      dispatch(DeleteLocations(id, lang, deleteLocationIds));
    },
    [id, lang],
  );
  const updateLessonLocationSkip = useCallback(
    () => dispatch(UpdateLessonLocationsSkip()),
    [],
  );
  const updateLocations = (locations, selected) => {
    const selectedIds = selected.map(({ id }) => id);
    const previewsLocationIds = locations.map(({ id }) => id);
    const deleteIds = selectedIds
      ? previewsLocationIds.filter((id) => !selectedIds.includes(id))
      : previewsLocationIds;
    const addIds = previewsLocationIds
      ? selectedIds.filter((id) => !previewsLocationIds.includes(id))
      : selectedIds;
    if (deleteIds.length || addIds.length) {
      dispatch(UpdateLocations(addIds, deleteIds));
    }
  };

  return {
    locations,
    getLocations,
    deleteLocation,
    updateLocations,
    updateLessonLocationSkip,
  };
};

export const useFiles = () => {
  const dispatch = useDispatch();
  const { id, lang } = useDocumentInfo();
  const attachment = useSelector((state) => state.publish.attachment);
  const addAttachments = useCallback(
    (files, callback) => dispatch(AddAttachments(id, lang, files, callback)),
    [id, lang],
  );
  const removeAttachments = useCallback(
    (files) => dispatch(RemoveAttachments(id, lang, files)),
    [id, lang],
  );

  return { attachment, addAttachments, removeAttachments };
};
