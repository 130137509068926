import React from "react";
import PropTypes from "prop-types";
import DataTable from "../../../components/common/Table";
import { defaultStyles } from "./BattlePublishedTable.style";
import withThemedStyle from "../../../components/hoc/withThemedStyle";
import { FormattedMessage } from "react-intl";
import { translations } from "./BattlePublishedTable.translations";
import { Icon, Icons } from "genius-ui";
import { roundPtsNbr } from "utils/string";

const columnsConfiguration = [
  {
    name: "battleName",
    label: translations.BattleNameColumnTitle,
    sortable: false,
    isDisplayed: true,
  },
  {
    name: "numberOfParticipatedUsers",
    label: translations.NumberOfParticipatedColumnTitle,
    sortable: true,
    isDisplayed: true,
  },
  {
    name: "averageNumberOfPoints",
    label: translations.AverageNumberOfPointsColumnTitle,
    sortable: true,
    isDisplayed: true,
    dataSuffix: "pts",
  },
  {
    name: "details",
    label: "",
    sortable: false,
    isDisplayed: false,
  },
];

const BattlePublishedTable = (props) => {
  const { classes, data, onSort, columnSort, goToResultsPage } = props;

  const renderItem = (item, index) => {
    return (
      <tr key={item.key} className={classes.row}>
        <td>{item.battleName}</td>
        <td>{item.numberOfParticipatedUsers}</td>
        <td>
          {roundPtsNbr(item.averageNumberOfPoints)}
          {columnsConfiguration[2].dataSuffix}
        </td>
        <td className="detailIconContainer">
          <Icon
            iconName={Icons.private}
            handleClick={() => goToResultsPage(item.id)}
          />
        </td>
      </tr>
    );
  };

  const noDataContent = (
    <div className={classes.noContent}>
      <FormattedMessage {...translations.NoContentText} />
    </div>
  );

  return (
    <DataTable
      className={classes.dataTableContainer}
      columns={columnsConfiguration}
      data={data}
      renderItem={renderItem}
      loadingIndicator={<div>Loading...</div>}
      noDataContent={noDataContent}
      columnSort={columnSort}
      onSort={onSort}
      isLoading={false}
      displaySeeMoreButton={false}
    />
  );
};

const numberOrString = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);

BattlePublishedTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      battleName: PropTypes.string,
      numberOfParticipatedUsers: numberOrString,
      averageNumberOfPoints: numberOrString,
    }),
  ).isRequired,
  onSort: PropTypes.func,
  columnSort: PropTypes.object,
  goToResultsPage: PropTypes.func,
};

export default withThemedStyle(defaultStyles)(BattlePublishedTable);
