import { PlaceholderImage } from "assets/icons";
import { formatDate } from "../../utils/dateUtils";
import clientConfig from "../../configs/client";

export const GetFormattedPollQuestions = (instance) =>
  instance.questions.map((question) => ({
    uuid: question.id,
    heading: question.title,
    content: question.choices.map((choice) => ({
      key: choice.id,
      answer: choice.title,
      completedPercentage: instance.participantsNumber
        ? parseInt(choice.answersNumber / (instance.participantsNumber * 0.01))
        : 0,
      answersNumber: choice.answersNumber,
    })),
  }));

const filterTagsByClusterIds = (tags, clusterIds) =>
  tags
    .filter((tag) => clusterIds.includes(parseInt(tag.clusterId)))
    .map((tag) => ({
      key: tag.tagId,
      label: tag.title,
    }));

export const GetFormattedPollInfo = ({ versions, stats: { instances } }) => ({
  photo: versions[0].picCover || PlaceholderImage,
  name: versions[0].title,
  status: versions[0].workflow,
  creationDate: formatDate(versions[0].updateDate, "dd MMM yyyy"),
  publicationDate: formatDate(versions[0].workflowScheduledIn, "dd MMM yyyy"),
  markets: filterTagsByClusterIds(
    versions[0].tags,
    clientConfig.MARKET_CLUSTER_IDS,
  ),
  brands: filterTagsByClusterIds(
    versions[0].tags,
    clientConfig.BRAND_TAGS_CLUSTER_IDS,
  ),
  participants: instances?.length ? instances[0].participantsNumber : 0,
});
