export default (theme) => ({
  container: {
    borderRadius: "100%",
    border: "none",
    padding: () => {
      return 0;
    },
    width: () => {
      return 50;
    },
    height: () => {
      return 50;
    },
    backgroundColor: ({ variation }) =>
      theme.RoundButton.backgroundColor[variation],
    color: ({ variation }) => theme.RoundButton.color[variation],
    boxShadow: ({ variation }) =>
      `0 10px 26px 0 ${theme.RoundButton.boxShadowColor[variation]}`,
    fontSize: () => {
      return 25;
    },
    cursor: "pointer",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outlineColor: "transparent",
    "&:hover": {
      opacity: 0.85,
      boxShadow: "none",
    },
  },
});
