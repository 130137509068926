import { defineMessages } from "react-intl";

export const translations = defineMessages({
  BrandFilterLabel: {
    id: "KPI.Feed.BrandFilterLabel",
    defaultMessage: "All Brands",
  },
  ContentNumberChartLabel: {
    id: "KPI.Feed.ContentNumberChartLabel",
    defaultMessage: "Total number of content",
  },
  PopularContentWidgetLabel: {
    id: "KPI.Feed.PopularContentWidgetLabel",
    defaultMessage: "Most Popular Content",
  },
  PopularContentWidgetTitleRightLabel: {
    id: "KPI.Feed.PopularContentWidgetTitleRightLabel",
    defaultMessage: "hit ratio for content",
  },
  TopTagsWidgetLabel: {
    id: "KPI.Feed.TopTagsWidgetLabel",
    defaultMessage: "Top TAGs used in content",
  },
  ColumnTooltipContent: {
    id: "KPI.Feed.ColumnTooltipContent",
    defaultMessage:
      "Number of content published in the feed & percentage of the total feed content it represents",
  },
});
