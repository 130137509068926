export default {
  rootContainer: {
    "& > div": {
      float: "left",
      width: 225,
      zIndex: 997,
      marginRight: 15,
    },
  },
};
