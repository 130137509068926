export const CreateCardStyle = ({
  CreateCardScreen,
  Colors,
  Fonts,
  TextSizes,
}) => ({
  selectTemplate: {
    boxShadow: `0 2px 14px 0 ${CreateCardScreen.selectTemplate.boxShadowColor}`,
    zIndex: 99999,
    width: 360,
    minWidth: 360,
    maxWidth: 360,
    backgroundColor: Colors.selectTemplate.backgroundColor,
    fontFamily: Fonts.fontFamilyCondensed,
    fontSize: TextSizes.Regular,
    fontStretch: "condensed",
    "& > .selectTemplateTitle": {
      backgroundColor: Colors.lightBlue,
      color: Colors.text.white,
      fontWeight: "bold",
      letterSpacing: 0.05,
      padding: "21px 0 20px 22px",
      height: 19,
      zIndex: "99999 !important",
    },
    "& > .scrollableTemplateContainer": {
      "& > .selectTemplateItem": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 40,
        "& img": {
          width: 200,
          height: "auto",
        },
        "& p": {
          lineHeight: 1.25,
          fontWeight: 300,
          margin: "0 0 10px 0",
        },
        "& > div": {
          cursor: "pointer",
        },
      },
    },
  },
  backButton: {
    marginRight: 20,
    cursor: "pointer",
  },
  cardEditorContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    marginTop: 270,
  },
  closeIcon: {
    position: "absolute",
    left: 20,
    "&::before": {
      fontSize: 20,
    },
    "&:hover::before": {
      cursor: "pointer",
    },
  },
});
