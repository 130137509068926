import React, { useMemo, useState } from "react";
import Badge from "../Badge/Badge";
import { useQuery } from "react-apollo";
import { GET_BADGES } from "pages/badges/graphs";
import { createUseStyles } from "react-jss";
import Style from "./Badges.style";
import useThemedStyle from "hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const Badges = ({ brandId, marketId, statuses, ...props }) => {
  const { data, loading } = useQuery(GET_BADGES, {
    variables: { tagIds: [brandId, marketId].filter((_) => _) },
  });
  const classes = useThemedStyle(useStyle, props);
  const [activeMenuBadgeId, setActiveMenuBadgeId] = useState(undefined);
  const badges = useMemo(() => {
    if (!data) return [];
    const filteredByStatus = statuses.length
      ? data.admin.badges.filter((badge) => statuses.includes(badge.status))
      : data.admin.badges;
    return filteredByStatus.filter(({ tags }) =>
      tags.every(({ tagId }) => tagId !== "1114"),
    );
  }, [data, statuses]);

  if (loading) return null;
  return (
    <div className={classes.badgesContainer}>
      {badges.map((badge, index) => (
        <Badge
          key={index}
          {...badge}
          activeMenuBadgeId={activeMenuBadgeId}
          setActiveMenuBadgeId={setActiveMenuBadgeId}
        />
      ))}
    </div>
  );
};

export default Badges;
