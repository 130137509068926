import client from "../../graphql/client";
import { SetErrorMessage } from "../notification/actions";
import { GET_DEGREE_BY_ID } from "../../graphql/remote/degrees/queries";
import { SetFetchIndicator } from "../common/actions";
import {
  UPDATE_MASTER_TEMPLATE_INFO,
  RESET_EDIT_MASTER_STORE,
} from "../../constants/store/editMasterTemplate";
import { GetFormattedMasterTemplateInfo } from "./transformers";
import {
  DEGREE_ADD_TAGS,
  DEGREE_REMOVE_TAGS,
  DEGREE_UPDATE_PIC_COVER,
  DEGREE_UPDATE_SUMMARY,
  DEGREE_UPDATE_TITLE,
  DEGREE_UPDATE_WORFLOW,
} from "../../graphql/remote/degrees/mutations";
import clientConfig from "../../configs/client";

export const ResetStore = () => (dispatch) => {
  dispatch({ type: RESET_EDIT_MASTER_STORE });
};

export const UpdateMasterTemplateInfo = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_MASTER_TEMPLATE_INFO,
    data,
  });
};

export const GetMasterTemplateInfo = (degreeId, lang) => async (dispatch) => {
  try {
    dispatch(SetFetchIndicator(true));
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_DEGREE_BY_ID,
      variables: { degreeId },
    });
    dispatch(
      UpdateMasterTemplateInfo(GetFormattedMasterTemplateInfo(result, lang)),
    );
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while fetching the masterTemplate info with id ${degreeId}`,
        e,
      ),
    );
  } finally {
    dispatch(SetFetchIndicator(false));
  }
};

export const UpdateTitle = (title) => async (dispatch, getState) => {
  const { id, lang } = getState().editMasterTemplate.masterTemplateInfo;
  await client.mutate({
    mutation: DEGREE_UPDATE_TITLE,
    variables: { degreeId: id, lang, data: title },
  });
};

export const UpdateSummary = (summary) => async (dispatch, getState) => {
  const { id, lang } = getState().editMasterTemplate.masterTemplateInfo;
  await client.mutate({
    mutation: DEGREE_UPDATE_SUMMARY,
    variables: { degreeId: id, lang, data: summary },
  });
};

export const UpdatePicCover = (src) => async (dispatch, getState) => {
  const { id, lang } = getState().editMasterTemplate.masterTemplateInfo;
  await client.mutate({
    mutation: DEGREE_UPDATE_PIC_COVER,
    variables: { degreeId: id, lang, data: src },
  });
};

const addTags = async (degreeId, lang, tagIdList, onSuccess) => {
  await client.mutate({
    mutation: DEGREE_ADD_TAGS,
    variables: { degreeId, lang, tagIdList },
    update: (dataProxy, result) => onSuccess(result.data.admin.degree.tags.add),
  });
};

const removeTags = async (degreeId, lang, tagIdList, onSuccess) => {
  await client.mutate({
    mutation: DEGREE_REMOVE_TAGS,
    variables: { degreeId, lang, tagIdList },
    update: () => onSuccess(),
  });
};

export const UpdateTags = (tagList, tagType) => async (dispatch, getState) => {
  const { masterTemplateInfo } = getState().editMasterTemplate;
  const { id, lang } = masterTemplateInfo;

  const beforeTagIds = masterTemplateInfo[tagType].map((x) => x.Id);
  const afterTagIds = clientConfig.MASTER_DEGREE_TAG_ID
    ? [
        ...tagList.map((x) => x.Id),
        clientConfig.MASTER_DEGREE_TAG_ID.toString(),
      ]
    : tagList.map((x) => x.Id);
  const removeTagIds = beforeTagIds.filter(
    (beforeTagId) => !afterTagIds.includes(beforeTagId),
  );
  const newTagIds = afterTagIds.filter(
    (afterTagId) => !beforeTagIds.includes(afterTagId),
  );

  if (masterTemplateInfo.tagsIsEditable) {
    dispatch(
      UpdateMasterTemplateInfo({ [tagType]: tagList, tagsIsEditable: false }),
    );
    const updateCallback = (success) => {
      const updateData = { tagsIsEditable: true };
      if (success) {
        updateData[tagType] = tagList;
      }
      dispatch(UpdateMasterTemplateInfo(updateData));
    };
    if (removeTagIds.length) {
      await removeTags(id, lang, removeTagIds, () => {
        if (newTagIds.length) {
          addTags(id, lang, newTagIds, updateCallback);
        } else {
          updateCallback(true);
        }
      });
    } else if (newTagIds.length) {
      await addTags(id, lang, newTagIds, updateCallback);
    }
  }
};

export const UpdateWorkflow =
  (status, callback) => async (dispatch, getState) => {
    const { masterTemplateInfo } = getState().editMasterTemplate;
    const { id, lang } = masterTemplateInfo;
    await client.mutate({
      mutation: DEGREE_UPDATE_WORFLOW,
      variables: { degreeId: id, lang, status },
      update: () => callback && callback(),
    });
  };
