import PropTypes from "prop-types";
import uuidV4 from "uuid/dist/v4";
import { BattleQuestionChoicePropTypes } from "./battle_question_choice";
import { BATTLE_QUESTION_TYPE_ENUM } from "../../../configs/constants";
import {
  BattleLongTypeThumbnail,
  BattleSmallTypeThumbnail,
  BattleMediaTypeThumbnail,
} from "assets/icons";
import { BattleMessages } from "./index";

export const CreateBattleQuestion = (
  type = BATTLE_QUESTION_TYPE_ENUM.SIMPLE,
  title = "",
  description = "",
) => ({
  id: uuidV4(),
  type,
  title,
  description,
  image: null,
  choices: [],
});

export const BattleQuestionPropTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(BATTLE_QUESTION_TYPE_ENUM)).isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  description: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.shape(BattleQuestionChoicePropTypes))
    .isRequired,
};

export const BattleQuestionTemplates = [
  {
    id: BATTLE_QUESTION_TYPE_ENUM.LONG,
    name: BattleMessages.BattleLongQuestionTemplateTitle,
    image: BattleLongTypeThumbnail,
  },
  {
    id: BATTLE_QUESTION_TYPE_ENUM.SIMPLE,
    name: BattleMessages.BattleSimpleQuestionTemplateTitle,
    image: BattleSmallTypeThumbnail,
  },
  {
    id: BATTLE_QUESTION_TYPE_ENUM.PICTURE,
    name: BattleMessages.BattleQuestionWithMediaTemplateTitle,
    image: BattleMediaTypeThumbnail,
  },
];
