import React from "react";
import { PublishCardStyle } from "../PublishCard.style";
import { connect } from "react-redux";
import compose from "lodash/flowRight";
import { SetCurrentTags } from "../../../store/referential/actions";
import { Button, Icons, Icon, Tag, FullscreenLoader } from "genius-ui";
import { withRouter } from "react-router";
import SelectTagsByClusterId from "../../../containers/Apollo/SelectTagsByClusterId";
import clientConfig, {
  CONFIG_CLUSTER,
  TAG_TYPE,
} from "../../../configs/client";
import { LANGUAGES } from "../../../configs/referential";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  CreateCardTag,
  GetCardById,
  RemoveCardPrivacy,
  ResetCardsStore,
  SetCardPrivacy,
  UpdateCardTitle,
  SetCurrentCardId,
  SetCurrentCardLanguage,
  SetPublishData,
  UpdateCardStatus,
  UpdateCardTags,
} from "../../../store/card/actions";
import { DOC_WORKFLOW_ENUM } from "../../../configs/constants";
import {
  SetErrorMessage,
  SetSuccessMessage,
} from "../../../store/notification/actions";
import { NoPhotoAvailable } from "assets/icons";
import { FormattedMessage, injectIntl } from "react-intl";
import { translations } from "../PublishCard.translation";
import PublishDateHandler from "../../../components/common/PublishDateHandler";
import {
  formatOptionsForSave,
  formatOptionsForSelect,
} from "../../../utils/dataTransformation/tags";
import {
  formatDateTimeForSave,
  isAfter,
  isBefore,
  parseISODate,
} from "../../../utils/dateUtils";
import ContentEditable from "react-contenteditable";
import FilterLanguages from "../../../components/FilterLanguages";
import FilterCountries from "../../../components/FilterCountries";
import withThemedStyle from "../../../components/hoc/withThemedStyle";
import { SetWarningMessage } from "../../../store/notification/actions";

export class PublishCard extends React.Component {
  state = {
    classNameError: null,
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.props.SetCurrentCardId(params.cardId);
    this.props.SetCurrentCardLanguage(params.lang);
    this.props.GetCurrentCardData(params.cardId, params.lang);
  }

  componentWillUnmount() {
    this.props.ResetCardsStore();
  }

  handleClickSave = () => {
    if (this.props.publishData.brands.length)
      this.props.PublishCard(
        this.props.currentCardId,
        this.props.currentCardLanguage,
        this.props.publishData.startPostingDate,
        this.props.publishData.endPostingDate,
      );
    else {
      this.props.WarningAlert(
        this.props.intl.formatMessage(translations.IndicateBrandsWarning),
      );
      this.setState({
        classNameError: this.props.classes.tagSelectContainerError,
      });
    }
  };

  updateTagsBand = () => {
    if (this.props.publishData.brands.length > 0)
      this.state.classNameError = null;
    return (selection) =>
      this.props.UpdateTags(
        formatOptionsForSave(selection),
        { ...this.props.publishData },
        this.props.currentCardId,
        this.props.currentLanguage,
        TAG_TYPE.BRAND,
      );
  };

  render() {
    const { classes } = this.props;
    const props = this.props;

    if (!props.cardLanguages.length) {
      return <FullscreenLoader />;
    }

    return (
      <div className={classes.rootContainer}>
        <div className={classes.center}>
          <div className={classes.left}>
            <FormattedMessage {...translations.TitlePlaceholder}>
              {(message) => (
                <ContentEditable
                  html={props.publishData.title}
                  onChange={({ target }) =>
                    props.SetTitle(target.value, props.publishData)
                  }
                  onBlur={() => props.UpdateTitle()}
                  className={classes.textInputTitle}
                  placeholder={message[0]}
                  tagName="article"
                />
              )}
            </FormattedMessage>
            <br />
            <div className={classes.detailsContainer}>
              <div className={classes.leftPart}>
                <div className={classes.publishCardImage} />
              </div>
              <div className={classes.rightPart}>
                <div className={classes.langPublicContainer}>
                  <div className={classes.firstTitle}>
                    <FormattedMessage {...translations.LanguageLabel} />
                  </div>
                  <div className={classes.publicSwitcherContainer}></div>
                </div>
                <div className={classes.tagList}>
                  <FilterLanguages languages={LANGUAGES}>
                    {(filteredLanguages) => {
                      const languages = filteredLanguages
                        .filter((x) => props.cardLanguages.includes(x.Code))
                        .map((y) => ({
                          Id: y.Code,
                          Value: y.Name,
                        }));

                      return languages.map((x) => (
                        <Tag key={x.Value} label={x.Value} readOnly />
                      ));
                    }}
                  </FilterLanguages>
                </div>
                <span className={classes.sectionTitle}>
                  <FormattedMessage
                    {...translations.PublicationCountriesLabel}
                  />
                </span>
                <div className={classes.tagList}>
                  <FilterCountries countries={props.publishData.countries}>
                    {({ filteredCountries }) =>
                      filteredCountries.map((x) => (
                        <Tag key={x.Value} label={x.Value} readOnly />
                      ))
                    }
                  </FilterCountries>
                </div>
                {clientConfig.SHOW_BRAND_AND_RETAILER ? (
                  <div className={classes.brandAndRetailer}>
                    <div className={classes.brandTagsContainer}>
                      <div className={classes.sectionTitle}>
                        <FormattedMessage {...translations.BrandLabel} />
                      </div>
                      <FormattedMessage {...translations.AddBrandPlaceholder}>
                        {(message) => (
                          <SelectTagsByClusterId
                            saveTagsToState={props.SaveTagsToState}
                            className={classes.tagSelectContainer}
                            classNameError={this.state.classNameError}
                            clusterIds={clientConfig[CONFIG_CLUSTER.BRAND]}
                            selectedItems={formatOptionsForSelect(
                              props.publishData[TAG_TYPE.BRAND],
                            )}
                            placeholder={message[0]}
                            onSelectionChanged={this.updateTagsBand()}
                            mustHaveAtLeastOneChoice
                          />
                        )}
                      </FormattedMessage>
                    </div>
                    <div className={classes.retailerTagsContainer}>
                      <div className={classes.sectionTitle}>
                        <FormattedMessage {...translations.RetailerLabel} />
                      </div>
                      <FormattedMessage
                        {...translations.AddRetailerPlaceholder}
                      >
                        {(message) => (
                          <SelectTagsByClusterId
                            saveTagsToState={props.SaveTagsToState}
                            className={classes.tagSelectContainer}
                            clusterMetaTag={props.publishData.countries[0].Id}
                            selectedItems={formatOptionsForSelect(
                              props.publishData[TAG_TYPE.RETAILER],
                            )}
                            placeholder={message[0]}
                            onSelectionChanged={(selection) =>
                              props.UpdateTags(
                                formatOptionsForSave(selection),
                                { ...props.publishData },
                                props.currentCardId,
                                props.currentLanguage,
                                TAG_TYPE.RETAILER,
                              )
                            }
                          />
                        )}
                      </FormattedMessage>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={classes.sectionTitle}>
                      <FormattedMessage {...translations.UserCategoryLabel} />
                    </div>
                    <FormattedMessage
                      {...translations.SelectUserCategoriesPlaceholder}
                    >
                      {(message) => (
                        <SelectTagsByClusterId
                          className={classes.tagSelectContainer}
                          clusterIds={
                            clientConfig[CONFIG_CLUSTER.USER_CATEGORY]
                          }
                          tagType={CONFIG_CLUSTER.USER_CATEGORY}
                          selectedItems={formatOptionsForSelect(
                            props.publishData[TAG_TYPE.USER_CATEGORY],
                          )}
                          placeholder={message[0]}
                          onSelectionChanged={(selection) =>
                            props.UpdateTags(
                              formatOptionsForSave(selection),
                              { ...props.publishData },
                              props.currentCardId,
                              props.currentLanguage,
                              TAG_TYPE.USER_CATEGORY,
                            )
                          }
                        />
                      )}
                    </FormattedMessage>
                  </div>
                )}
                <span className={classes.sectionTitle}>
                  <FormattedMessage {...translations.AssociatedTagsLabel} />
                </span>
                <FormattedMessage
                  {...translations.SelectAssociatedTagsPlaceholder}
                >
                  {(message) => (
                    <SelectTagsByClusterId
                      isEditable
                      className={classes.tagSelectContainer}
                      clusterIds={clientConfig[CONFIG_CLUSTER.EXPERTISE]}
                      selectedItems={formatOptionsForSelect(
                        props.publishData[TAG_TYPE.EXPERTISE],
                      )}
                      placeholder={message[0]}
                      onSelectionChanged={(selection) =>
                        props.UpdateTags(
                          formatOptionsForSave(selection),
                          { ...props.publishData },
                          props.currentCardId,
                          props.currentCardLanguage,
                          TAG_TYPE.EXPERTISE,
                        )
                      }
                      onCreateOption={(inputValue) =>
                        props.CreateNewTag(inputValue)
                      }
                    />
                  )}
                </FormattedMessage>
                <PublishDateHandler
                  startPostingDate={props.publishData.startPostingDate}
                  endPostingDate={props.publishData.endPostingDate}
                  updateStartPublicationDate={(date) =>
                    props.UpdateStartPublicationDate(
                      { ...props.publishData },
                      date,
                    )
                  }
                  updateEndPublicationDate={(date) =>
                    props.UpdateEndPublicationDate(
                      { ...props.publishData },
                      date,
                    )
                  }
                  startDateLabel={
                    <FormattedMessage {...translations.StartPostingDateLabel} />
                  }
                  endDateLabel={
                    <FormattedMessage {...translations.EndPostingDateLabel} />
                  }
                  noEndDateLabel={
                    <FormattedMessage {...translations.NoEndPostingDateLabel} />
                  }
                />
                <div className={classes.postingDate}>
                  <div className={classes.postingDateButton}>
                    <Button
                      variation="primary"
                      size="small"
                      handleClick={this.handleClickSave}
                    >
                      <FormattedMessage {...translations.ScheduleButtonText} />
                    </Button>
                  </div>
                  <div className={classes.postingDateButton}>
                    <Button
                      variation="secondary"
                      size="small"
                      handleClick={() => props.history.push("/")}
                    >
                      <FormattedMessage
                        {...translations.SaveWithoutScheduleButtonText}
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.right} onClick={() => props.history.goBack()}>
            <Icon iconName={Icons.slimCloseIcon} style={classes.closeIcon} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  currentCardId: state.cards.currentCardId,
  currentCards: state.cards.cards,
  firstCardImagePath: state.cards.publishData.image || NoPhotoAvailable,
  currentCardLanguage: state.cards.currentLanguage,
  cardLanguages: state.cards.cardLanguages,
  publishData: state.cards.publishData,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  GetCurrentCardData: (cardId, lang) => {
    dispatch(GetCardById(cardId, lang));
  },
  ResetCardsStore: () => {
    dispatch(ResetCardsStore());
  },
  SetCurrentCardId: (cardId) => {
    dispatch(SetCurrentCardId(cardId));
  },
  SetCurrentCardLanguage: (language) => {
    dispatch(SetCurrentCardLanguage(language));
  },
  SetTitle: (title, publishData) =>
    dispatch(SetPublishData({ ...publishData, title })),
  UpdateTitle: () => dispatch(UpdateCardTitle()),
  UpdateTags: (tagList, publishData, id, language, tagType) => {
    dispatch(UpdateCardTags(tagList, publishData, id, language, tagType));
  },
  UpdateStartPublicationDate: (publishData, date) => {
    publishData.startPostingDate = date;
    dispatch(SetPublishData(publishData));
  },
  UpdateEndPublicationDate: (publishData, date) => {
    publishData.endPostingDate = date;
    dispatch(SetPublishData(publishData));
  },
  PublishCard: (id, language, startPostingDate, endPostingDate) => {
    const startDate = formatDateTimeForSave(startPostingDate);
    const endDate = endPostingDate
      ? formatDateTimeForSave(endPostingDate)
      : null;
    const workflow = isAfter(parseISODate(startPostingDate), new Date())
      ? DOC_WORKFLOW_ENUM.DRAFT
      : DOC_WORKFLOW_ENUM.PUBLISHED;

    if (
      endDate &&
      isBefore(parseISODate(endPostingDate), parseISODate(startPostingDate))
    ) {
      dispatch(
        SetErrorMessage(
          <FormattedMessage {...translations.IncorrectDateMessage} />,
        ),
      );
    } else {
      dispatch(
        UpdateCardStatus(id, language, workflow, startDate, endDate, () => {
          ownProps.history.push("/");
          dispatch(
            SetSuccessMessage(
              <FormattedMessage {...translations.SuccessfulPublishedMessage} />,
            ),
          );
        }),
      );
    }
  },
  UpdatePrivacy: (cardId, cardLang, publishData, isPublic) => {
    if (isPublic) {
      dispatch(
        RemoveCardPrivacy(cardId, cardLang, () =>
          dispatch(SetPublishData({ ...publishData, isPublic })),
        ),
      );
    } else {
      dispatch(
        SetCardPrivacy(cardId, cardLang, () =>
          dispatch(SetPublishData({ ...publishData, isPublic })),
        ),
      );
    }
  },
  SaveTagsToState: (tagList, tagType) => {
    dispatch(SetCurrentTags([...tagList], tagType));
  },
  CreateNewTag: (tagName) => {
    dispatch(CreateCardTag(tagName));
  },
  WarningAlert: (message) => dispatch(SetWarningMessage(message)),
});

const Container = compose(injectIntl)(
  withThemedStyle(PublishCardStyle)(PublishCard),
);

export default withRouter(
  connect(mapStateToProp, mapDispatchToProps)(Container),
);
