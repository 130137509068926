import * as constants from "../../constants/store/notification";
import { ENV } from "../../configs/constants";

export const SetSuccessMessage = (message) => (dispatch) => {
  dispatch({
    type: constants.SET_SUCCESS_MESSAGE,
    data: message,
  });
};

export const SetErrorMessage = (message, stackException) => (dispatch) => {
  dispatch({
    type: constants.SET_ERROR_MESSAGE,
    data: message,
    error: stackException,
  });

  console.error(`[Application error]: ${stackException}`);
};

export const SetWarningMessage = (message) => (dispatch) => {
  dispatch({
    type: constants.SET_WARNING_MESSAGE,
    data: message,
  });
};

export const CloseNotification = () => (dispatch) => {
  dispatch({
    type: constants.CLOSE_AND_RESET_NOTIFICATION,
    data: "",
  });
};
