import { defineMessages } from "react-intl";

export const translations = defineMessages({
  TemplateTitle: {
    id: "ActivitiesTemplate.TemplateTitle",
    defaultMessage: "Activities",
  },
  QuizText: {
    id: "ActivitiesTemplate.QuizText",
    defaultMessage: "Quiz",
  },
  QuizWithCoverText: {
    id: "ActivitiesTemplate.QuizWithCoverText",
    defaultMessage: "Quiz With Cover",
  },
  QuizImageFullScreenText: {
    id: "ActivitiesTemplate.QuizImageFullScreenText",
    defaultMessage: "Quiz Image Full Screen",
  },
  MadlibText: {
    id: "ActivitiesTemplate.MadlibText",
    defaultMessage: "Madlib",
  },
  DragAndDropText: {
    id: "ActivitiesTemplate.DragAndDropText",
    defaultMessage: "Drag & Drop",
  },
  NinjaText: {
    id: "ActivitiesTemplate.NinjaText",
    defaultMessage: "The Right Match",
  },
});
