export const AddToMarketScreenStyle = (theme) => ({
  rootContainer: {
    fontFamily: theme.FontFamily,
  },
  contentContainer: {
    paddingTop: 150,
    paddingLeft: 62,
    marginLeft: "auto",
    marginRight: "auto",
    width: 975,
    "& .title": {
      fontSize: 18,
      fontWeight: "bold",
      textTransform: "uppercase",
      marginBottom: 12,
    },
    "& .subtitle": {
      fontSize: 17,
      fontWeight: 300,
      color: "#252525",
      letterSpacing: 0.06,
    },
    "& .markets": {
      marginTop: 50,
      "& > div": {
        float: "left",
        minWidth: 240,
      },
    },
  },
});
