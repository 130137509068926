import { defineMessages } from "react-intl";

export default defineMessages({
  NinjaEmptyQuestionTitle: {
    id: "NinjaQuestionEditor.NinjaEmptyQuestionTitle",
    defaultMessage: "Enter a question title here...",
  },
  NinjaEmptyDescriptionTitle: {
    id: "NinjaQuestionEditor.NinjaEmptyDescriptionTitle",
    defaultMessage: "Enter a description here...",
  },
  CorrectColumnLabel: {
    id: "NinjaQuestionEditor.CorrectColumnLabel",
    defaultMessage: "Correct",
  },
  ChoiceTextColumnLabel: {
    id: "NinjaQuestionEditor.ChoiceTextColumnLabel",
    defaultMessage: "Type",
  },
  AddAChoiceButtonLabel: {
    id: "NinjaQuestionEditor.AddAChoiceButtonLabel",
    defaultMessage: "Add a Choice",
  },
});
