import gql from "graphql-tag";

export const EDIT_BATTLE_PAGE = gql`
  mutation editBattlePage(
    $battleId: ID
    $lang: LangEnum
    $pageId: ID
    $data: String!
  ) {
    admin {
      battle(battleId: $battleId, lang: $lang) {
        pages(pageId: $pageId) {
          data(data: $data)
        }
      }
    }
  }
`;
