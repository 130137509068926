import { defineMessages } from "react-intl";

export const translations = defineMessages({
  ProgramsLabel: {
    id: "KpiNinja.ProgramsWidgetLabel",
    defaultMessage: "Programs",
  },
  LessonsLabel: {
    id: "KpiNinja.LessonsWidgetLabel",
    defaultMessage: "Lessons",
  },
  InfosLabel: {
    id: "KpiNinja.InfosLabel",
    defaultMessage:
      "This is the number of lessons published during the period and available to the population selected.",
  },
  ViewSwitcherLabel: {
    id: "KpiNinja.ViewSwitcherLabel",
    defaultMessage: "Group lesson per program",
  },
  NinjaGamesPublished: {
    id: "KpiNinja.NinjaGamesPublished",
    defaultMessage: "THE RIGHT MATCH PUBLISHED",
  },
});
