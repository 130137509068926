import gql from "graphql-tag";

export const REMOVE_DEGREE_BADGES = gql`
  mutation removeDegreeBadges($data: [InputDocumentBadgeLink!]) {
    admin {
      removeDocumentsFromBadges(data: $data)
    }
  }
`;

export const ADD_DEGREE_ACTIVITY_BADGES = gql`
  mutation addDegreeBadges($data: [InputQuizBadgeLink!]) {
    admin {
      setQuizToBadges(data: $data)
    }
  }
`;

export const ADD_DEGREE_READ_BADGES = gql`
  mutation addDegreeBadges($data: [InputLessonBadgeLink!]) {
    admin {
      setLessonsToBadges(data: $data)
    }
  }
`;
