import { SliderStyle as SliderStyleStyle } from "../Slider.style";

export default (theme) => {
  const { Colors } = theme;
  const SliderStyle = SliderStyleStyle(theme);

  return {
    sliderContainer: SliderStyle.sliderContainer,
    addBoxTop: {
      ...SliderStyle.addBoxLeft,
      left: "calc(100%/2 + 5)",
      top: -25,
    },
    addBoxBottom: {
      ...SliderStyle.addBoxLeft,
      left: "calc(100%/2+ 5)",
      bottom: -24,
      top: undefined,
    },
    uploadControlsBox: SliderStyle.uploadControlsBox,
    uploadBtnWrapper: SliderStyle.uploadBtnWrapper,
    btnUpload: SliderStyle.btnUpload,
    uploadInput: SliderStyle.uploadInput,
    iconAction: SliderStyle.iconAction,
    leftButtonsBox: SliderStyle.leftButtonsBox,
    backgroundSlider: SliderStyle.backgroundSlider,
    scrollDownIndicator: {
      position: "absolute",
      bottom: 20,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: Colors.text.white,
      textTransform: "uppercase",
    },
    scrollDownIcon: {
      fontSize: 12,
      marginTop: 13,
    },
    textEditing: {
      position: "absolute",
      bottom: 73,
      height: 220,
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginLeft: 30,
      marginRight: 30,
      justifyContent: "flex-end",
    },
    titleInput: SliderStyle.titleInput,
    descriptionInput: SliderStyle.descriptionInput,
  };
};
