import gql from "graphql-tag";

export const CREATE_POLL = gql`
  mutation createPoll($lang: LangEnum, $title: String!) {
    admin {
      poll(lang: $lang) {
        create(title: $title) {
          pollId
        }
      }
    }
  }
`;
