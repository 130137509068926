import { defineMessages } from "react-intl";

export const translations = defineMessages({
  AllMarketOptions: {
    id: "Library.AllMarketOptions",
    defaultMessage: "All Markets",
  },
  MarketMissingWarning: {
    id: "Library.MarketMissingWarning",
    defaultMessage: "Please select a market before creating a document",
  },
  DeleteOption: {
    id: "Library.DeleteOption",
    defaultMessage: "Delete",
  },
  SendAsAnInsight: {
    id: "Library.SendAsAnInsight",
    defaultMessage: "Send as an insight",
  },
  SendNotificationOption: {
    id: "Library.SendNotificationTitle",
    defaultMessage: "Send Notification",
  },
  NotificationModalTitle: {
    id: "Library.NotificationModalTitle",
    defaultMessage: "Send a Notification",
  },
  NotificationModalDescription: {
    id: "Library.NotificationModalDescription",
    defaultMessage:
      "Send a Push Notification to all users that this content is broadcast to. " +
      "This notification will only be sent once",
  },
  NotificationModalMessagePlaceholder: {
    id: "Library.NotificationModalMessagePlaceholder",
    defaultMessage: "There’s something new to see in Beauty Genius",
  },
});
