import React, { useMemo } from "react";
import classNames from "classnames";
import Style from "../BattleQuestionEditor.style";
import { FormattedMessage } from "react-intl";
import { BattleQuestionPropTypes } from "../../../../constants/domain_items/battle/battle_question";
import BattleQuestionChoiceEditorReadOnly from "../../BattleQuestionChoiceEditor/readOnly";
import RadioGroup from "../../../common/RadioGroup";
import translations from "../BattleQuestionEditor.translations";
import ContentEditableUncontrolled from "../../../common/ContentEditableUncontrolled";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);
const ADD_NEW_OPTION_VALUE = "addNewOption";

const BattleQuestionEditor = (props) => {
  const { title, description, choices } = props;
  const classes = useThemedStyle(useStyle, props);

  // manage choices
  const choiceOptions = useMemo(
    () => [
      ...choices.map((choice) => ({
        value: choice.id,
        label: choice.text,
        originalData: choice,
      })),
    ],
    [choices],
  );

  const correctChoice = useMemo(
    () => choices.find((choice) => choice.isCorrect),
    [choices],
  );

  return (
    <div className={classes.container}>
      <div className={classes.innerPageContainer}>
        <div className={classes.media} />
        <div className={classes.spacer} />
        <div className={classes.questionContainer}>
          <FormattedMessage {...translations.BattleEmptyQuestionTitle}>
            {(placeHolder) => (
              <ContentEditableUncontrolled
                key={title || "title"}
                initialValue={title}
                disabled
                tagName="article"
                placeholder={placeHolder[0]}
                className={classes.questionInput}
              />
            )}
          </FormattedMessage>
        </div>
        <div className={classes.descriptionContainer}>
          <FormattedMessage {...translations.BattleEmptyDescriptionTitle}>
            {(placeHolder) => (
              <ContentEditableUncontrolled
                key={description || "description"}
                initialValue={description}
                disabled
                tagName="article"
                placeholder={placeHolder[0]}
                className={classes.descriptionInput}
              />
            )}
          </FormattedMessage>
        </div>
        <div className={classes.spacer} />
        <div>
          <div className={classes.radioHeaderContainer}>
            <div
              className={classNames([
                classes.radioHeaderLabel,
                classes.correctLabel,
              ])}
            >
              <FormattedMessage {...translations.CorrectColumnLabel} />
            </div>
            <div
              className={classNames([
                classes.radioHeaderLabel,
                classes.choiceTextLabel,
              ])}
            >
              <FormattedMessage {...translations.ChoiceTextColumnLabel} />
            </div>
          </div>
          <div className={classes.radioContainer}>
            <RadioGroup
              options={choiceOptions}
              onChange={() => null}
              value={correctChoice && correctChoice.id}
              renderOptionLabel={({ originalData }, index) => (
                <BattleQuestionChoiceEditorReadOnly
                  key={originalData.id}
                  {...originalData}
                  isAddAChoice={originalData.id === ADD_NEW_OPTION_VALUE}
                  onTextChange={(text) => null}
                  onRemove={() => null}
                  onAddNewChoice={() => null}
                  disabled
                />
              )}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BattleQuestionEditor.propTypes = {
  ...BattleQuestionPropTypes,
};

export default BattleQuestionEditor;
