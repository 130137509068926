import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CROP_VARIATION } from "../../../configs/constants";
import useQuestions from "../../../hooks/activity/useQuestions";
import Style from "./PollQuestionEditor.style";
import { FormattedMessage } from "react-intl";
import PollQuestionChoiceEditor from "../PollQuestionChoiceEditor";
import AddNewContainer, {
  DIRECTION_HORIZONTAL,
} from "../../common/AddNewContainer";
import ChangeTemplate from "../../common/ChangeTemplate";
import UploadImageButton from "../../common/UploadImageButton";
import CropImage from "../../common/CropImage";
import { Icon, Icons } from "genius-ui";
import translations from "./PollQuestionEditor.translations";
import ContentEditableUncontrolled from "../../common/ContentEditableUncontrolled";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import {
  PollQuestionPropTypes,
  PollQuestionTemplates,
} from "../../../constants/domain_items/poll/poll_question";
import { CreatePollQuestionChoice } from "../../../constants/domain_items/poll/poll_question_choice";

const useStyle = createUseStyles(Style);

// half of fixed height
const ADD_BUTTON_SWITCH_BREAKPOINT = 368;
const ADD_NEW_OPTION_VALUE = "addNewOption";

const CreateNewChoice = () => [CreatePollQuestionChoice()];

const PollQuestionEditor = (props) => {
  const {
    id,
    type,
    title,
    image,
    description,
    choices,
    isSelected,
    onSelect,
    onTypeChange,
    onTitleChange,
    onImageChange,
    onDescriptionChange,
    onChoicesChange,
    onAddQuestionBefore,
    onAddQuestionAfter,
    onRemove,
  } = props;
  const classes = useThemedStyle(useStyle, props);
  // manage crop visibility
  const [isCropVisible, setIsCropVisible] = useState(false);

  const showCropper = useCallback(
    () => setIsCropVisible(true),
    [setIsCropVisible],
  );
  const hideCropper = useCallback(
    () => setIsCropVisible(false),
    [setIsCropVisible],
  );

  // manage add question
  const handleAddQuestionBefore = useCallback(
    () => onAddQuestionBefore(id),
    [onAddQuestionBefore, id],
  );
  const handleAddQuestionAfter = useCallback(
    () => onAddQuestionAfter(id),
    [onAddQuestionAfter, id],
  );

  // manage title
  const handleTitleChange = useCallback(
    (newTitle) => onTitleChange(id, newTitle, title),
    [onTitleChange, id, title],
  );

  // manage image
  const handleImageChange = useCallback(
    (newImage) => onImageChange(id, newImage, image),
    [onImageChange, id, image],
  );

  // manage description
  const handleDescriptionChange = useCallback(
    (newDescription) => onDescriptionChange(id, newDescription, description),
    [onDescriptionChange, id, description],
  );

  // manage choices
  const choiceOptions = useMemo(
    () => [
      ...choices.map((choice) => ({
        value: choice.id,
        label: choice.text,
        originalData: choice,
      })),
      {
        value: ADD_NEW_OPTION_VALUE,
        label: <FormattedMessage {...translations.AddAChoiceButtonLabel} />,
        originalData: {
          id: ADD_NEW_OPTION_VALUE,
          text: <FormattedMessage {...translations.AddAChoiceButtonLabel} />,
        },
      },
    ],
    [choices],
  );

  const handleChoicesChange = useCallback(
    (newChoices) => onChoicesChange(id, newChoices, choices),
    [onChoicesChange, id, choices],
  );

  const {
    handleQuestionChange: handleChoiceChange,
    removeQuestion: removeChoice,
    addQuestionAfter: addChoiceAfter,
  } = useQuestions(choices, CreateNewChoice, ({ questions: newChoices }) =>
    handleChoicesChange(newChoices),
  );

  return (
    <div className={classes.container} onClick={() => onSelect(id)}>
      <AddNewContainer
        className={classes.innerPageContainer}
        enabled={isSelected}
        direction={DIRECTION_HORIZONTAL}
        beforeAfterBreakPoint={ADD_BUTTON_SWITCH_BREAKPOINT}
        onAddBefore={handleAddQuestionBefore}
        onAddAfter={handleAddQuestionAfter}
      >
        <ChangeTemplate
          selectedTemplateId={type}
          templates={PollQuestionTemplates}
          onTemplateSelect={(newType) => onTypeChange(id, newType, type)}
          className={classes.changeTemplate}
        />
        <div
          className={classes.deleteButton}
          onClick={() => isSelected && onRemove(id)}
        >
          <Icon iconName={Icons.delete} />
        </div>
        <div className={classes.media}>
          <div className={classes.mediaOverlay} />
          <UploadImageButton
            id={`PollQuestion-${id}-image`}
            containerClass={classes.uploadImageButton}
            disabled={!isSelected}
            onUploadSuccess={handleImageChange}
          />
          <CropImage
            sourceImage={image}
            isCropVisible={isCropVisible}
            cropSize={CROP_VARIATION.POLL}
            onCropClick={showCropper}
            onCancel={hideCropper}
            onCropCompleted={handleImageChange}
            className={classes.cropImageButton}
            disabled={!isSelected}
          />
        </div>
        <div>
          <div className={classes.questionContainer}>
            <FormattedMessage {...translations.PollEmptyQuestionTitle}>
              {(placeHolder) => (
                <ContentEditableUncontrolled
                  key={title || "title"}
                  initialValue={title}
                  disabled={!isSelected}
                  onChange={handleTitleChange}
                  tagName="article"
                  placeholder={placeHolder[0]}
                  className={classes.questionInput}
                />
              )}
            </FormattedMessage>
          </div>
          <div className={classes.descriptionContainer}>
            <FormattedMessage {...translations.PollEmptyDescriptionTitle}>
              {(placeHolder) => (
                <ContentEditableUncontrolled
                  key={description || "description"}
                  initialValue={description}
                  disabled={!isSelected}
                  onChange={handleDescriptionChange}
                  tagName="article"
                  placeholder={placeHolder[0]}
                  className={classes.descriptionInput}
                />
              )}
            </FormattedMessage>
          </div>
        </div>
        <div className={classes.options}>
          <div className={classes.radioHeaderContainer}>
            <div
              className={classNames([
                classes.radioHeaderLabel,
                classes.choiceTextLabel,
              ])}
            >
              <FormattedMessage {...translations.ChoiceTextColumnLabel} />
            </div>
          </div>
          <div className={classes.radioContainer}>
            {choiceOptions.map(({ originalData }, index) => (
              <PollQuestionChoiceEditor
                key={originalData.id}
                {...originalData}
                isAddAChoice={originalData.id === ADD_NEW_OPTION_VALUE}
                onTextChange={(text) => handleChoiceChange(index, { text })}
                onRemove={() => removeChoice(index)}
                onAddNewChoice={() => addChoiceAfter(index)}
                disabled={!isSelected}
              />
            ))}
          </div>
        </div>
      </AddNewContainer>
    </div>
  );
};

PollQuestionEditor.propTypes = {
  ...PollQuestionPropTypes,
  /** if selected, the question is editable: "template selection"- and "question add"- buttons, the inputs are enabled
   * and question is highlighted with a border **/
  isSelected: PropTypes.bool,
  /** func(questionId): function executed when a question is selected **/
  onSelect: PropTypes.func,
  /** func(questionId, newType, oldType): function executed when an option inside the type selector is clicked **/
  onTypeChange: PropTypes.func,
  /** func(questionId, newTitle, oldTitle): function executed when the title input is blurred **/
  onTitleChange: PropTypes.func,
  /** func(questionId, newDescription, oldDescription): function executed when the description input is blurred **/
  onDescriptionChange: PropTypes.func,
  /** func(questionId, newImage, oldImage): function executed when the image is changed **/
  onImageChange: PropTypes.func,
  /** func(questionId, newChoices, oldChoices): function executed when the choices are changed **/
  onChoicesChange: PropTypes.func,
  /** func(questionId): function executed when the "Add question before" button is clicked **/
  onAddQuestionBefore: PropTypes.func,
  /** func(questionId): function executed when the "Add question after" button is clicked **/
  onAddQuestionAfter: PropTypes.func,
  /** func(questionId): function executed when the "Remove question" button is clicked **/
  onRemove: PropTypes.func,
};

export default PollQuestionEditor;
