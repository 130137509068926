import { defineMessages } from "react-intl";

export const translations = defineMessages({
  TemplateTitle: {
    id: "ScrollImagesTemplate.TemplateTitle",
    defaultMessage: "Full Screen Scroll",
  },
  FullScreenScrollSimpleText: {
    id: "ScrollImagesTemplate.FullScreenScrollSimpleText",
    defaultMessage: "Full screen scroll simple",
  },
  FullScreenScrollDottedText: {
    id: "ScrollImagesTemplate.FullScreenScrollDottedText",
    defaultMessage: "Full screen scroll dotted",
  },
});
