import { getPath } from "./paths";
import { USER_ROLE_NAMES } from "../configs/constants";

export const MODULE_NAMES = {
  ALL: "ALL",
  DEGREES: "DEGREES",
  INSIGHTS: "INSIGHTS",
  CONTENT_MANAGER: "CONTENT_MANAGER",
  PEANUTS: "PEANUTS",
  INSTAGRAM: "INSTAGRAM",
  TWITTER: "TWITTER",
  QUICK_QUIZ: "QUICK_QUIZ",
  BATTLE: "BATTLE",
  KPI: "KPI",
  KPI_COMPONENTS: "KPI_COMPONENTS",
  KPI_GENERAL: "KPI_GENERAL",
  KPI_LEARNING: "KPI_LEARNING",
  KPI_APPLICATION: "KPI_APPLICATION",
  KPI_FEED: "KPI_FEED",
  KPI_ASK: "KPI_ASK",
  PROFILE: "PROFILE",
  USERS: "USERS",
  APP_ANALYTICS: "APP_ANALYTICS",
  POLL: "POLL",
  NINJA: "NINJA",
  LIBRARY: "LIBRARY",
  MASTER_TEMPLATES: "MASTER_TEMPLATES",
  RESULTS: "RESULTS",
  KPI_BATTLE: "KPI_BATTLE",
};

export const MODULE_CONFIGURATION = {
  [MODULE_NAMES.ALL]: {
    SUBMODULES: [
      MODULE_NAMES.CONTENT_MANAGER,
      MODULE_NAMES.KPI,
      MODULE_NAMES.PROFILE,
      MODULE_NAMES.USERS,
      MODULE_NAMES.APP_ANALYTICS,
      MODULE_NAMES.MASTER_TEMPLATES,
      MODULE_NAMES.RESULTS,
      MODULE_NAMES.LIBRARY,
    ],
    PATHS: [
      getPath("root"),
      getPath("login"),
      getPath("logout"),
      getPath("redirection"),
      getPath("forgotPassword"),
      getPath("pageNotFound"),
      getPath("test"),
    ],
  },
  [MODULE_NAMES.DEGREES]: {
    SUBMODULES: [],
    PATHS: [
      getPath("createDegree"),
      getPath("degreeBuild"),
      getPath("buildDegree"),
      getPath("degrees"),
      getPath("degreesHistory"),
      getPath("degreesHistoryParametered"),
      getPath("lessons"),
      getPath("publishLesson"),
      getPath("lessonBadges"),
      getPath("degreeBadges"),
      getPath("lessonPublishPreview"),
      getPath("badges"),
      getPath("masterBadges"),
      getPath("cloneBadgeToMarket"),
      getPath("createMasterBadge"),
      getPath("editBadge"),
    ],
  },
  [MODULE_NAMES.MASTER_TEMPLATES]: {
    SUBMODULES: [],
    PATHS: [
      getPath("masterTemplates"),
      getPath("editMasterTemplate"),
      getPath("editMasterTemplateLesson"),
      getPath("documentAddToMarket"),
      getPath("masterDegreeBadges"),
      getPath("masterContentManager"),
      getPath("publishMasterInsight"),
      getPath("publishMasterBattle"),
      getPath("publishMasterCard"),
      getPath("publishMasterPoll"),
    ],
  },
  [MODULE_NAMES.INSIGHTS]: {
    SUBMODULES: [],
    PATHS: [getPath("createInsight"), getPath("publishInsight")],
  },
  [MODULE_NAMES.PEANUTS]: {
    SUBMODULES: [],
    PATHS: [getPath("createCardPeanut")],
  },
  [MODULE_NAMES.INSTAGRAM]: {
    SUBMODULES: [],
    PATHS: [getPath("createCardInstagram")],
  },
  [MODULE_NAMES.TWITTER]: {
    SUBMODULES: [],
    PATHS: [getPath("createCardTwitter")],
  },
  [MODULE_NAMES.QUICK_QUIZ]: {
    SUBMODULES: [],
    PATHS: [getPath("quickQuizEdit")],
  },
  [MODULE_NAMES.BATTLE]: {
    SUBMODULES: [],
    PATHS: [getPath("editBattle"), getPath("publishBattle")],
  },
  [MODULE_NAMES.POLL]: {
    SUBMODULES: [],
    PATHS: [getPath("editPoll"), getPath("publishPoll")],
  },
  [MODULE_NAMES.NINJA]: {
    SUBMODULES: [],
    PATHS: [getPath("editNinja")],
  },
  [MODULE_NAMES.KPI]: {
    SUBMODULES: [
      MODULE_NAMES.KPI_COMPONENTS,
      MODULE_NAMES.KPI_GENERAL,
      MODULE_NAMES.KPI_LEARNING,
      MODULE_NAMES.KPI_APPLICATION,
      MODULE_NAMES.KPI_FEED,
      MODULE_NAMES.KPI_ASK,
      MODULE_NAMES.KPI_BATTLE,
    ],
    PATHS: [getPath("kpiDocument"), getPath("kpiProduct")],
  },
  [MODULE_NAMES.KPI_COMPONENTS]: {
    SUBMODULES: [],
    PATHS: [getPath("kpiComponents")],
  },
  [MODULE_NAMES.KPI_GENERAL]: {
    SUBMODULES: [],
    PATHS: [getPath("kpiGeneral")],
  },
  [MODULE_NAMES.KPI_LEARNING]: {
    SUBMODULES: [],
    PATHS: [getPath("kpiLearning")],
  },
  [MODULE_NAMES.KPI_APPLICATION]: {
    SUBMODULES: [],
    PATHS: [getPath("kpiApplication")],
  },
  [MODULE_NAMES.KPI_FEED]: {
    SUBMODULES: [],
    PATHS: [getPath("kpiFeed")],
  },
  [MODULE_NAMES.KPI_ASK]: {
    SUBMODULES: [],
    PATHS: [getPath("kpiAsk")],
  },
  [MODULE_NAMES.KPI_BATTLE]: {
    SUBMODULES: [],
    PATHS: [
      getPath("kpiBattleHome"),
      getPath("kpiBattleGlobal"),
      getPath("kpiNinja"),
    ],
  },
  [MODULE_NAMES.CONTENT_MANAGER]: {
    SUBMODULES: [
      MODULE_NAMES.DEGREES,
      MODULE_NAMES.INSIGHTS,
      MODULE_NAMES.PEANUTS,
      MODULE_NAMES.INSTAGRAM,
      MODULE_NAMES.QUICK_QUIZ,
      MODULE_NAMES.BATTLE,
      MODULE_NAMES.POLL,
      MODULE_NAMES.NINJA,
      MODULE_NAMES.TWITTER,
    ],
    PATHS: [
      getPath("contentType"),
      getPath("cardPublish"),
      getPath("contentManager"),
      getPath("deletedContent"),
    ],
  },
  [MODULE_NAMES.PROFILE]: {
    SUBMODULES: [],
    PATHS: [getPath("userProfile")],
  },
  [MODULE_NAMES.USERS]: {
    SUBMODULES: [],
    PATHS: [getPath("users")],
  },
  [MODULE_NAMES.LIBRARY]: {
    SUBMODULES: [],
    PATHS: [getPath("library"), getPath("createFile"), getPath("editFile")],
  },
  [MODULE_NAMES.APP_ANALYTICS]: {
    SUBMODULES: [],
    PATHS: [getPath("kpiMobileAnalytics")],
  },
  [MODULE_NAMES.RESULTS]: {
    SUBMODULES: [],
    PATHS: [getPath("pollResults"), getPath("battleResults")],
  },
};

/**
 * Modifies module paths to meet the following criteria:
 * - Parent modules have access to all the child module paths
 * - Child modules have access to all ancestor module paths
 */
const ConfigureModulePaths = () => {
  const modulePaths = {};

  const GetModulePaths = (moduleName, parentModulePaths) => {
    let mergedModules;
    if (!modulePaths[moduleName]) {
      mergedModules = MODULE_CONFIGURATION[moduleName].PATHS;
      if (MODULE_CONFIGURATION[moduleName].SUBMODULES.length) {
        mergedModules = mergedModules.concat(
          MODULE_CONFIGURATION[moduleName].SUBMODULES.reduce(
            (subModulePaths, subModuleName) =>
              subModulePaths.concat(
                GetModulePaths(
                  subModuleName,
                  MODULE_CONFIGURATION[moduleName].PATHS,
                ),
              ),
            [],
          ),
        );
      }
      modulePaths[moduleName] = mergedModules.concat(parentModulePaths);
    } else {
      mergedModules = modulePaths[moduleName];
    }

    return mergedModules;
  };

  modulePaths[MODULE_NAMES.ALL] = GetModulePaths(
    MODULE_NAMES.ALL,
    MODULE_CONFIGURATION[MODULE_NAMES.ALL].PATHS,
  );

  Object.keys(MODULE_CONFIGURATION).forEach((moduleName) => {
    MODULE_CONFIGURATION[moduleName].PATHS = modulePaths[moduleName];
  });
};

ConfigureModulePaths();

export const USER_ROLE_MODULE_ACCESS = {
  [USER_ROLE_NAMES.ADMIN]: [MODULE_NAMES.ALL],
  [USER_ROLE_NAMES.TRAINER]: [MODULE_NAMES.ALL],
  [USER_ROLE_NAMES.HQ]: [
    MODULE_NAMES.CONTENT_MANAGER,
    MODULE_NAMES.USERS,
    MODULE_NAMES.JOURNALISTS,
    MODULE_NAMES.EVENTS,
    MODULE_NAMES.MASTER_TEMPLATES,
  ],
};
