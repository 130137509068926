import { BlueSpinner } from "assets/icons";

export const FallbackStyle = {
  spinner: {
    backgroundImage: `url('${BlueSpinner}')`,
    backgroundSize: `${30}px ${30}px`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
};
