import { defineMessages } from "react-intl";

export const translations = defineMessages({
  PartOne: {
    id: "Madlib.PartOne",
    defaultMessage: "Enter sentence part one",
  },
  PartTwo: {
    id: "Madlib.PartTwo",
    defaultMessage: "Enter sentence part two",
  },
  Correct: {
    id: "Madlib.Correct",
    defaultMessage: "Correct",
  },
  ChoiceText: {
    id: "Madlib.ChoiceText",
    defaultMessage: "Choice text",
  },
  AddChoice: {
    id: "Madlib.AddChoice",
    defaultMessage: "Add a Choice (max 4 answers)",
  },
  EnterChoiceText: {
    id: "Madlib.EnterChoiceText",
    defaultMessage: "Enter choice text",
  },
  CorrectChoicePlaceholder: {
    id: "Madlib.CorrectChoicePlaceholder",
    defaultMessage: "place of correct choice",
  },
});
