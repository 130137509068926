import React from "react";
import { createUseStyles } from "react-jss";
import Style from "./EditPoll.style";
import { Icon, Icons } from "genius-ui";
import PollEditor from "../../components/Poll/PollEditor";
import PublishPreviewBanner from "../../components/PublishPreviewBanner";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const EditPoll = (props) => {
  const classes = useThemedStyle(useStyle, props);
  const {
    questions,
    isMasterDocument,
    NavigateToPreviousPage,
    SetQuestions,
    NavigateToPollPublishPage,
  } = props;

  return (
    <>
      <PublishPreviewBanner
        onPublishButtonClick={() => NavigateToPollPublishPage(isMasterDocument)}
      >
        <Icon
          iconName={Icons.arrowLeft}
          style={classes.closeIcon}
          handleClick={NavigateToPreviousPage}
        />
      </PublishPreviewBanner>
      <div className={classes.pollEditorContainer}>
        <PollEditor
          className={classes.pollEditor}
          questions={questions}
          onQuestionsChange={SetQuestions}
          isActive
        />
      </div>
    </>
  );
};

export default EditPoll;
