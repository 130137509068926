export default {
  container: {
    height: "100%",
    width: "100%",
  },
  contentContainer: {
    paddingTop: 150,
    marginLeft: "auto",
    marginRight: "auto",
    width: 975,
    "& .title": {
      fontSize: 18,
      fontWeight: "bold",
      textTransform: "uppercase",
      marginBottom: 12,
    },
    "& .subtitle": {
      fontSize: 17,
      fontWeight: 300,
      color: "#252525",
      letterSpacing: 0.06,
    },
  },
  badges: {
    marginTop: 44,
  },
};
