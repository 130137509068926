import {
  SET_POLL_QUESTIONS,
  SET_EDITED_POLL_PAGE_ID,
  SET_POLL_MASTER_FLAG,
} from "../../constants/store/editPoll";
import { SetErrorMessage } from "../notification/actions";
import client from "../../graphql/client";
import { PollDocumentToPollItem, PollItemToPollDocument } from "./transformers";
import { SetFetchIndicator } from "../common/actions";
import { GET_POLL_BY_ID } from "../../graphql/remote/poll/queries/GetPollInfo.graphql";
import { EDIT_POLL_PAGE } from "../../graphql/remote/poll/mutations/EditPollPage.graphql";
import clientConfig from "../../configs/client";

export const SetEditedPollPageId = (data) => (dispatch) =>
  dispatch({
    type: SET_EDITED_POLL_PAGE_ID,
    data,
  });

export const SetPollQuestions = (data) => (dispatch) =>
  dispatch({
    type: SET_POLL_QUESTIONS,
    data,
  });

export const SetPollMasterFlag = (data) => (dispatch) =>
  dispatch({
    type: SET_POLL_MASTER_FLAG,
    data,
  });

export const GetPollInfo = (pollId, language) => async (dispatch) => {
  dispatch(SetFetchIndicator(true));
  dispatch(SetEditedPollPageId(null));
  dispatch(SetPollQuestions([]));

  try {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_POLL_BY_ID,
      variables: {
        pollId,
      },
    });

    const currentLanguagePollVersion = result.data.admin.polls[0].versions.find(
      (version) => version.lang === language,
    );
    const pollItem = PollDocumentToPollItem(currentLanguagePollVersion);

    if (
      result.data.admin.polls[0].versions
        .find((version) => version.lang === language)
        .tags.findIndex(
          (x) => parseInt(x.tagId) === clientConfig.MASTER_DEGREE_TAG_ID,
        ) >= 0
    ) {
      dispatch(SetPollMasterFlag(true));
    } else {
      dispatch(SetPollMasterFlag(false));
    }

    dispatch(SetEditedPollPageId(pollItem.editedPageId));
    dispatch(SetPollQuestions(pollItem.questions));
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while fetching the poll info for poll with id ${pollId}`,
        e,
      ),
    );
  }

  dispatch(SetFetchIndicator(false));
};

export const SavePollQuestions =
  (pollId, language, questions) => async (dispatch, getState) => {
    const {
      editPoll: { editedPageId, questions: previousState },
    } = getState();
    const Revert = () => dispatch(SetPollQuestions(previousState));

    try {
      dispatch(SetPollQuestions(questions));

      const result = await client.mutate({
        mutation: EDIT_POLL_PAGE,
        variables: {
          pollId: pollId,
          lang: language,
          pageId: editedPageId,
          data: PollItemToPollDocument({
            questions,
          }),
        },
      });

      if (!result.data.admin.poll.pages.data) {
        Revert();
      }
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while updating the questions of the poll ${pollId}`,
          e,
        ),
      );
      Revert();
    }
  };
