export default (theme) => ({
  container: {
    position: "relative",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
    padding: "5px 20px",
    minHeight: 50,
    backgroundColor: theme.MultiProgressWidget.titleBackground,
    color: theme.MultiProgressWidget.titleColor,
    fontFamily: theme.FontFamily,
    fontSize: 16,
    fontWeight: "bold",
  },
  titleRight: {
    fontFamily: theme.FontFamily,
    fontSize: 12,
    fontWeight: 500,
    color: theme.MultiProgressWidget.titleRightColor,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > div:nth-of-type(1)": {
      marginRight: 10,
    },
  },
  chartContainer: {
    padding: "0 14px 20px 20px",
  },
});
