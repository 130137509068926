export const RESET_STORE = "RESET_MASTER_TEMPLATES_STORE";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_TEMPLATES = "UPDATE_TEMPLATES";
export const SET_EXISTING_DEGREE_MARKET_TAG_IDS =
  "SET_EXISTING_DEGREE_MARKET_TAG_IDS";
export const SET_SAVE_DEGREE_MARKET_IDS = "SET_SAVE_DEGREE_MARKET_IDS";
export const SET_BADGES = "SET_BADGES";
export const SET_DEGREE_DETAILS = "SET_DEGREE_DETAILS";
export const SET_DEGREE_LESSONS = "SET_DEGREE_LESSONS";
export const SET_BADGE_LESSON_IDS = "SET_BADGE_LESSON_IDS";
export const SET_ALL_BADGE_LESSON_IDS = "SET_ALL_BADGE_LESSON_IDS";
export const SET_CURRENT_CLONE_CONTEXT_IDS = "SET_CURRENT_CLONE_CONTEXT_IDS";
export const SET_CURRENT_SET_BADGES_CONTEXT = "SET_CURRENT_SET_BADGES_CONTEXT";
