export default ({ TextSizes, FontFamily }) => ({
  inputContainer: {
    marginTop: 30,
  },
  backToLoginButton: {
    alignSelf: "flex-end",
    fontFamily: FontFamily,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "right",
    fontSize: TextSizes.Regular,
    marginTop: 20,
    marginBottom: 60,
    textDecoration: "none",
  },
  sendButton: {
    textTransform: "none !important",
    fontFamily: FontFamily,
    padding: "0 !important",
    borderRadius: "27px",
    width: 105,
    height: 34,
    fontSize: 18,
    fontStretch: "condensed",
    letterSpacing: 0.2,
    fontWeight: "normal",
  },
  errorMessage: {
    fontSize: TextSizes.Regular,
    color: "red",
    marginTop: 20,
    marginBottom: 20,
  },
  forgotMessage: {
    fontSize: TextSizes.Regular,
    color: "green",
    marginTop: 20,
    marginBottom: 20,
  },
});
