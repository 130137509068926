import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import style from "./PublishMasterPoll.style";
import {
  Icon,
  Icons,
  InputText,
  Tag,
  TagVariation,
  FullscreenLoader,
} from "genius-ui";
import { FormattedMessage, useIntl } from "react-intl";
import { translations } from "./PublishMasterPoll.translations";
import UploadMedia from "../../../components/common/UploadMedia";
import SelectTagsByClusterId from "../../../containers/Apollo/SelectTagsByClusterId";
import clientConfig, {
  CONFIG_CLUSTER,
  TAG_TYPE,
} from "../../../configs/client";
import { getPath } from "../../../core/paths";
import { connect, useDispatch } from "react-redux";
import {
  ResetStore,
  GetPollInfo,
  AddTags,
  RemoveTags,
  UpdatePollInfo,
  UpdateMasterPollWorkflow,
  UpdatePublishData,
} from "../../../store/publishPoll/actions";
import {
  formatOptionsForSave,
  formatOptionsForSelect,
  withMasterTag,
} from "../../../utils/dataTransformation/tags";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import PublicationButtons from "../../../components/PublicationMasterDocuments/PublicationButtons";
import { DOC_WORKFLOW_ENUM } from "../../../configs/constants";
import { SetWarningMessage } from "../../../store/notification/actions";

const useStyle = createUseStyles(style);

const PublishPoll = (props) => {
  const {
    history,
    match,
    fetchPublishData,
    resetStore,
    publishData,
    isFetching,
    updatePollInfo,
    isUpdating,
    UpdateTags,
    UpdateWorkflow,
  } = props;
  const classes = useThemedStyle(useStyle, props);
  const [publishState, setPublishState] = useState(publishData);
  const intl = useIntl();
  const dispatch = useDispatch();
  const [classNameError, setClassNameError] = useState(null);

  useEffect(() => {
    fetchPublishData(match.params.id, match.params.lang);
    return () => {
      resetStore();
    };
  }, []);

  useEffect(() => {
    setPublishState(publishData);
  }, [publishData]);

  const navigateToTheMasterContentsPage = () =>
    history.push(getPath("masterContentManager"));

  const handleClick = () => {
    if (publishData.brands.length) navigateToTheMasterContentsPage();
    else {
      dispatch(
        SetWarningMessage(
          intl.formatMessage(translations.IndicateBrandsWarning),
        ),
      );
      setClassNameError(classes.tagSelectContainerError);
    }
  };

  const updateTagsBrand = () => {
    if (publishData.brands.length > 0) setClassNameError(null);
    return (tags) => UpdateTags(publishData, tags, TAG_TYPE.BRAND);
  };

  return (
    <div className={classes.rootContainer}>
      {(isFetching || isUpdating) && <FullscreenLoader />}
      <div className={classes.content}>
        <div className={classes.photo}>
          <UploadMedia
            id="pollImage"
            isVideoUploadEnabled={false}
            onUploadSuccess={(src) => updatePollInfo("picCover", src)}
          >
            <button className={classes.uploadBtn}>
              <FormattedMessage {...translations.UploadImage} />
            </button>
          </UploadMedia>
        </div>
        <div className={classes.rightSide}>
          <div className={classes.close}>
            <Icon
              iconName={Icons.slimCloseIcon}
              handleClick={navigateToTheMasterContentsPage}
            />
          </div>
          <FormattedMessage {...translations.PollTitlePlaceholder}>
            {(message) => (
              <InputText
                className={classes.pollTitle}
                label={message[0]}
                onChange={(e) =>
                  setPublishState({ ...publishState, title: e.target.value })
                }
                onBlur={() =>
                  publishState.title &&
                  updatePollInfo("title", publishState.title)
                }
                value={publishState.title}
                floatingLabel={false}
              />
            )}
          </FormattedMessage>
          <FormattedMessage {...translations.PollDescriptionPlaceholder}>
            {(message) => (
              <InputText
                className={classes.pollDescription}
                label={`${message[0]}...`}
                onChange={(e) =>
                  setPublishState({ ...publishState, summary: e.target.value })
                }
                onBlur={() => updatePollInfo("summary", publishState.summary)}
                value={publishState.summary}
                floatingLabel={false}
              />
            )}
          </FormattedMessage>
          <div className={classes.brandAndRetailer}>
            <div>
              <div className={classes.title}>
                <FormattedMessage {...translations.BrandLabel} />
              </div>
              <FormattedMessage {...translations.AddBrandPlaceholder}>
                {(message) => (
                  <SelectTagsByClusterId
                    className={classes.tagSelectContainer}
                    classNameError={classNameError}
                    clusterIds={clientConfig[CONFIG_CLUSTER.BRAND]}
                    selectedItems={formatOptionsForSelect(
                      publishData[TAG_TYPE.BRAND],
                    )}
                    placeholder={`${message}...`}
                    onSelectionChanged={updateTagsBrand()}
                  />
                )}
              </FormattedMessage>
            </div>
            <div>
              <div className={classes.title}>
                <FormattedMessage {...translations.RetailerLabel} />
              </div>
              <FormattedMessage {...translations.AddRetailerPlaceholder}>
                {(message) => (
                  <SelectTagsByClusterId
                    className={classes.tagSelectContainer}
                    clusterIds={clientConfig[CONFIG_CLUSTER.RETAILER]}
                    selectedItems={formatOptionsForSelect(
                      publishData[TAG_TYPE.RETAILER],
                    )}
                    placeholder={`${message}...`}
                    onSelectionChanged={(tags) =>
                      UpdateTags(publishData, tags, TAG_TYPE.RETAILER)
                    }
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
          <div className={classes.title}>
            <FormattedMessage {...translations.AssociatedTags} />
          </div>
          <FormattedMessage {...translations.AddTag}>
            {(message) => (
              <SelectTagsByClusterId
                className={classes.tagSelectContainer}
                clusterIds={clientConfig[CONFIG_CLUSTER.EXPERTISE]}
                selectedItems={formatOptionsForSelect(
                  publishData[TAG_TYPE.EXPERTISE],
                )}
                placeholder={`${message}...`}
                onSelectionChanged={(tags) =>
                  UpdateTags(
                    publishData,
                    withMasterTag(tags),
                    TAG_TYPE.EXPERTISE,
                  )
                }
              />
            )}
          </FormattedMessage>
          <div className={classes.title}>
            <FormattedMessage {...translations.Language} />
          </div>
          <div className={classes.languages}>
            {publishData.language && (
              <Tag
                label={publishData.language}
                variation={TagVariation.gray}
                readOnly
              />
            )}
          </div>
          <PublicationButtons
            btnClickHandler={handleClick}
            switchHandler={UpdateWorkflow}
            isOn={publishData.workflow === DOC_WORKFLOW_ENUM.PUBLISHED}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProp = (state) => ({
  publishData: state.publishPoll.publishData,
  isFetching: state.common.fetchIndicator,
  isUpdating: state.common.updateIndicator,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchPublishData: (id, lang) => {
    dispatch(GetPollInfo(id, lang));
  },
  UpdatePublishData: (type, data) => {
    dispatch(UpdatePublishData({ [type]: data }));
  },
  resetStore: () => dispatch(ResetStore()),
  updatePollInfo: (type, data) => {
    dispatch(
      UpdatePollInfo(type, data, () => {
        dispatch(UpdatePublishData({ [type]: data }));
      }),
    );
  },
  UpdateTags: (publishData, tagList, tagType) => {
    const saveFormatTags = formatOptionsForSave(tagList);
    const beforeTagIds = publishData[tagType].map((x) => x.Id);
    const afterTagIds =
      tagList && tagList.length ? tagList.map((x) => x.value) : [];
    const removeTagIds = beforeTagIds.filter(
      (beforeTagId) => !afterTagIds.includes(beforeTagId),
    );
    const newTagIds = afterTagIds.filter(
      (afterTagId) => !beforeTagIds.includes(afterTagId),
    );

    if (removeTagIds.length) {
      dispatch(
        RemoveTags(removeTagIds, () => {
          if (newTagIds.length) {
            dispatch(
              AddTags(newTagIds, () => {
                dispatch(UpdatePublishData({ [tagType]: saveFormatTags }));
              }),
            );
          } else {
            dispatch(UpdatePublishData({ [tagType]: saveFormatTags }));
          }
        }),
      );
    } else {
      if (newTagIds.length) {
        dispatch(
          AddTags(newTagIds, () => {
            dispatch(UpdatePublishData({ [tagType]: saveFormatTags }));
          }),
        );
      }
    }
  },
  UpdateWorkflow: () => dispatch(UpdateMasterPollWorkflow()),
});

export default connect(mapStateToProp, mapDispatchToProps)(PublishPoll);
