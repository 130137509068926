import gql from "graphql-tag";

export const GET_FILES = gql`
  query GetFiles(
    $skip: Int
    $limit: Int
    $tagMultipleIntersectIds: [QueryInputMultipleTags!]
    $lang: LangEnum
  ) {
    admin {
      documents(
        skip: $skip
        limit: $limit
        lang: $lang
        skipCache: true
        workflowIds: [1, 2, 7]
        type: file
        tagMultipleIntersectIds: $tagMultipleIntersectIds
      ) {
        docId
        versions {
          title
          lang
          workflowScheduledIn
          tags {
            title
            tagId
            clusterId
          }
          pages {
            type
            data
          }
          workflow
        }
      }
    }
  }
`;
