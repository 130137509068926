import { defineMessages } from "react-intl";
import PropTypes from "prop-types";

const messages = defineMessages({
  statusPending: {
    id: "USER_STATUS.PENDING",
    defaultMessage: "Pending",
  },
  statusValidated: {
    id: "USER_STATUS.VALIDATED",
    defaultMessage: "Validated",
  },
  statusRefused: {
    id: "USER_STATUS.REFUSED",
    defaultMessage: "Refused",
  },
});

export const USER_STATUSES = {
  PENDING: {
    label: messages.statusPending,
  },
  ACTIVE: {
    label: messages.statusValidated,
  },
  REJECT: {
    label: messages.statusRefused,
  },
};

export const USER_STATUS = {
  ACTIVE: "ACTIVE",
  ARCHIVED: "ARCHIVED",
  DRAFT: "DRAFT",
  REJECTED: "REJECT",
};

export const UserShortInfoPropType = {
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  image: PropTypes.string,
};
