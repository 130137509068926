import React, { Component } from "react";
import PropTypes from "prop-types";
import { ContentManagerStyle } from "../ContentManager.style";
import ContentsQuery from "./contentsQuery/Contents.query";
import { LANGUAGES } from "../../../configs/referential";
import { DOC_WORKFLOW_ENUM, DOCUMENT_TYPES } from "../../../configs/constants";
import {
  SetActiveMenuDocumentId,
  SetCurrentLanguage,
  SetCurrentDocumentType,
  SetViewType,
  SetListWorkflow,
} from "../../../store/document/actions";
import { SetCurrentInsightId } from "../../../store/insight/actions";
import { connect } from "react-redux";
import Navigation from "../../../components/Navigation";
import { Dropdown, FullscreenLoader, Icon, Icons } from "genius-ui";
import { getPath } from "../../../core/paths";
import { graphql, Query } from "react-apollo";
import compose from "lodash/flowRight";
import {
  INSIGHT_COPY_FOR_LANGUAGE,
  INSIGHT_UPDATE_WORFLOW,
} from "../../../graphql/remote/insights/mutations";
import {
  CARD_UPDATE_WORKFLOW,
  CARD_COPY_FOR_LANGUAGE,
} from "../../../graphql/remote/cards/mutations";
import { SetSuccessMessage } from "../../../store/notification/actions";
import {
  SetDuplicationData,
  SetShowModal as SetShowDuplicationModal,
} from "../../../store/duplication/actions";
import {
  SetNotificationData,
  SetShowModal as SetShowNotificationModal,
} from "../../../store/sendNotification/actions";
import { FormattedMessage, injectIntl } from "react-intl";
import { GET_DOCUMENTS_NUMBER } from "../../../graphql/remote/documents/queries";
import clientConfig from "../../../configs/client";
import { SEND_NOTIFICATION } from "../../../graphql/remote/notifications/mutations";
import { DOCUMENT_LIST_WORKFLOWS } from "../../../store/document/reducers";
import ViewSwitcher from "../../../components/common/ViewSwitcher";
import { translations } from "../ContentManager.translations";
import FilterLanguages from "../../../components/FilterLanguages";
import { DocumentTypeTranslations } from "../../../constants/domain_items/documents/document_types";
import { BATTLE_UPDATE_WORFLOW } from "../../../graphql/remote/battle/mutations/UpdateBattleWorkflow";
import { COPY_BATTLE } from "../../../graphql/remote/battle/mutations/CopyBattle";
import withThemedStyle from "../../../components/hoc/withThemedStyle";
import { POLL_UPDATE_WORKFLOW } from "../../../graphql/remote/poll/mutations/UpdatePollWorkflow";
import { COPY_POLL } from "../../../graphql/remote/poll/mutations/CopyPoll.graphql";

const deleteInsightPageRequest = graphql(INSIGHT_UPDATE_WORFLOW, {
  props: ({ mutate }) => ({
    insightDelete: (insightId, lang, status, refetch) =>
      mutate({
        variables: { insightId: insightId, lang: lang, status: status },
        update: (cache, result) => {
          refetch();
        },
      }),
  }),
});

const deleteCardPageRequest = graphql(CARD_UPDATE_WORKFLOW, {
  props: ({ mutate }) => ({
    cardDelete: (cardId, lang, status, refetch) =>
      mutate({
        variables: { cardId: cardId, lang: lang, status: status },
        update: (cache, result) => {
          refetch();
        },
      }),
  }),
});

const deleteBattlePageRequest = graphql(BATTLE_UPDATE_WORFLOW, {
  props: ({ mutate }) => ({
    battleDelete: (battleId, lang, status, refetch) =>
      mutate({
        variables: { battleId, lang, status },
        update: (cache, result) => {
          refetch();
        },
      }),
  }),
});

const deletePollPageRequest = graphql(POLL_UPDATE_WORKFLOW, {
  props: ({ mutate }) => ({
    pollDelete: (pollId, lang, status, refetch) =>
      mutate({
        variables: { pollId, lang, status },
        update: (cache, result) => {
          refetch();
        },
      }),
  }),
});

const copyInsightForLanguage = graphql(INSIGHT_COPY_FOR_LANGUAGE, {
  props: ({ mutate }) => ({
    copyInsightForLanguage: (insightId, sourceLang, destLang, update) =>
      mutate({
        variables: {
          insightId: insightId,
          sourceLang: sourceLang,
          destLang: destLang,
        },
        update: (data) => {
          update();
        },
      }),
  }),
});

const copyBattleForLanguage = graphql(COPY_BATTLE, {
  props: ({ mutate }) => ({
    copyBattleForLanguage: (battleId, sourceLang, destLang, update) =>
      mutate({
        variables: { battleId, sourceLang, destLang },
        update: (data) => {
          update();
        },
      }),
  }),
});

const copyPollForLanguage = graphql(COPY_POLL, {
  props: ({ mutate }) => ({
    copyPollForLanguage: (pollId, sourceLang, destLang, update) =>
      mutate({
        variables: { pollId, sourceLang, destLang },
        update: (data) => {
          update();
        },
      }),
  }),
});

const sendNotificationMessage = graphql(SEND_NOTIFICATION, {
  props: ({ mutate }) => ({
    sendNotificationMessage: (
      { docId, lang, send, tagIds, userIds },
      message,
      onSuccess,
    ) =>
      mutate({
        variables: {
          docId: docId,
          lang: lang,
          message: message,
          send: send,
          tagIds: tagIds,
          userIds: userIds,
        },
        update: () => onSuccess(),
      }),
  }),
});

const copyCardForLanguage = graphql(CARD_COPY_FOR_LANGUAGE, {
  props: ({ mutate }) => ({
    copyCardForLanguage: (cardId, sourceLang, destLang, update) =>
      mutate({
        variables: {
          cardId: cardId,
          sourceLang: sourceLang,
          destLang: destLang,
        },
        update: (data) => {
          update();
        },
      }),
  }),
});

class ContentManager extends Component {
  newContentHandler = () => this.props.history.push(getPath("contentType"));

  componentDidMount() {
    this.props.SetListWorkflow(
      this.props.isArchivePage
        ? DOCUMENT_LIST_WORKFLOWS.DELETED
        : DOCUMENT_LIST_WORKFLOWS.ACTIVE,
    );
  }

  navigateToInsightPublishPage = (insightId) =>
    this.props.history.push(
      `/insight/publish/${insightId}/${this.props.currentLanguage}`,
    );

  navigateToCardPublishPage = (cardId) =>
    this.props.history.push(
      `/card/publish/${cardId}/${this.props.currentLanguage}`,
    );

  navigateToBattlePublishPage = (battleId) =>
    this.props.history.push(
      getPath("publishBattle", battleId, this.props.currentLanguage),
    );

  navigateToPollPublishPage = (pollId) =>
    this.props.history.push(
      getPath("publishPoll", pollId, this.props.currentLanguage),
    );

  render() {
    const props = this.props;
    const { classes, isArchivePage = false } = props;
    let selectedValue = LANGUAGES.find((x) => x.Code === props.currentLanguage);

    if (!selectedValue) {
      selectedValue = LANGUAGES.find(
        (x) => x.Code === clientConfig.DEFAULT_LANGUAGE_CODE,
      );
    }
    const docTypeOptions = Object.keys(DOCUMENT_TYPES)
      .map((x) => ({
        value: DOCUMENT_TYPES[x],
        label: (
          <FormattedMessage {...DocumentTypeTranslations[DOCUMENT_TYPES[x]]} />
        ),
      }))
      .filter(
        ({ value }) =>
          value !== DOCUMENT_TYPES.BATTLE ||
          clientConfig.MODULES.IS_BATTLE_MANAGEMENT_AVAILABLE,
      )
      .filter(
        ({ value }) =>
          value !== DOCUMENT_TYPES.POLL ||
          clientConfig.MODULES.IS_POLL_MANAGEMENT_AVAILABLE,
      );
    docTypeOptions.unshift({
      value: undefined,
      label: props.intl.formatMessage({ ...translations.AllContentOption }),
    });

    return (
      <div className={classes.rootContainer}>
        <Navigation />
        <div className={classes.actionsContainer}>
          <div className={classes.filtersContainer}>
            <FilterLanguages languages={LANGUAGES}>
              {(languages) =>
                languages.length > 1 && (
                  <div className={classes.languageSelector}>
                    <Dropdown
                      selectedOption={{
                        value: selectedValue.Code,
                        label: selectedValue.Name,
                      }}
                      onChange={(selectedOption) => {
                        props.SetCurrentLanguage(selectedOption.value);
                      }}
                      options={languages.map((x) => ({
                        value: x.Code,
                        label: x.Name,
                      }))}
                    />
                  </div>
                )
              }
            </FilterLanguages>
            <div className={classes.typeSelector}>
              <Dropdown
                selectedOption={{
                  value: props.currentDocumentType,
                  label: props.currentDocumentType
                    ? props.currentDocumentType
                    : props.intl.formatMessage({
                        ...translations.AllContentOption,
                      }),
                }}
                onChange={(selectedOption) =>
                  props.SetCurrentDocumentType(selectedOption.value)
                }
                options={docTypeOptions}
              />
            </div>
            <ViewSwitcher
              viewType={props.viewType}
              changeViewType={props.SetViewType}
            />
          </div>
          <div onClick={this.newContentHandler} className={classes.createLink}>
            <Icon iconName={Icons.roundSmallPlus} />
            <span>
              <FormattedMessage {...translations.NewContentText} />
            </span>
          </div>
        </div>
        <Query
          fetchPolicy="cache-and-network"
          query={GET_DOCUMENTS_NUMBER}
          notifyOnNetworkStatusChange
        >
          {({ loading, error, data, refetch, networkStatus }) => {
            if (loading) return <FullscreenLoader />;
            const docsNumber = data.admin.dashboard.documents;

            return (
              <ContentsQuery
                language={props.currentLanguage}
                activeMenuDocumentId={props.activeMenuDocumentId}
                onItemSettingsClicked={props.SetActiveMenuDocumentId}
                docType={props.currentDocumentType}
                history={props.history}
                handleDeleteInsight={(insightId, language, refetch) =>
                  props.insightDelete(
                    insightId,
                    language,
                    DOC_WORKFLOW_ENUM.ARCHIVED,
                    refetch,
                  )
                }
                handleDeleteCard={(cardId, language, refetch) =>
                  props.cardDelete(
                    cardId,
                    language,
                    DOC_WORKFLOW_ENUM.ARCHIVED,
                    refetch,
                  )
                }
                handleDeleteBattle={(battleId, language, refetch) =>
                  props.battleDelete(
                    battleId,
                    language,
                    DOC_WORKFLOW_ENUM.ARCHIVED,
                    refetch,
                  )
                }
                handleDeletePoll={(pollId, language, refetch) =>
                  props.pollDelete(
                    pollId,
                    language,
                    DOC_WORKFLOW_ENUM.ARCHIVED,
                    refetch,
                  )
                }
                handleDuplicateInsight={(
                  insightId,
                  currentLanguage,
                  destLang,
                ) =>
                  props.DuplicateInsight(
                    insightId,
                    currentLanguage,
                    destLang,
                    props.copyInsightForLanguage,
                  )
                }
                handleDuplicateCard={(cardId, currentLanguage, destLang) =>
                  props.DuplicateCard(
                    cardId,
                    currentLanguage,
                    destLang,
                    props.copyCardForLanguage,
                  )
                }
                handleDuplicateBattle={(battleId, currentLanguage, destLang) =>
                  props.DuplicateBattle(
                    battleId,
                    currentLanguage,
                    destLang,
                    props.copyBattleForLanguage,
                  )
                }
                handleDuplicatePoll={(pollId, currentLanguage, destLang) =>
                  props.DuplicatePoll(
                    pollId,
                    currentLanguage,
                    destLang,
                    props.copyPollForLanguage,
                  )
                }
                showDuplicationModal={props.showDuplicationModal}
                duplicationData={props.duplicationData}
                setNotificationData={props.SetNotificationData}
                setShowDuplicationModal={props.SetShowDuplicationModal}
                setShowNotificationModal={props.SetShowNotificationModal}
                setDuplicationData={props.SetDuplicationData}
                allDocNumber={docsNumber}
                viewType={props.viewType}
                archivedContents={isArchivePage}
                navigateToInsightPublishPage={this.navigateToInsightPublishPage}
                navigateToCardPublishPage={this.navigateToCardPublishPage}
                navigateToBattlePublishPage={this.navigateToBattlePublishPage}
                navigateToPollPublishPage={this.navigateToPollPublishPage}
              />
            );
          }}
        </Query>
      </div>
    );
  }
}

ContentManager.propTypes = {
  isArchivePage: PropTypes.bool,
};

ContentManager.defaultProps = {
  isArchivePage: false,
};

const mapStateToProp = (state) => ({
  currentLanguage: state.documents.currentLanguage,
  currentDocumentType: state.documents.currentDocumentType,
  activeMenuDocumentId: state.documents.activeMenuDocumentId,
  viewType: state.documents.viewType,
  showDuplicationModal: state.duplication.showModal,
  duplicationData: state.duplication.duplicationData,
  showNotificationModal: state.sendNotification.showModal,
  currentInsightTagIds: state.insights.currentInsightTagIds,
  currentCardTagIds: state.cards.currentCardTagIds,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  SetCurrentLanguage: (language) => {
    dispatch(SetCurrentLanguage(language));
  },
  SetActiveMenuDocumentId: (activeDocumentId) => {
    dispatch(SetActiveMenuDocumentId(activeDocumentId));
  },
  SetCurrentDocumentType: (type) => {
    dispatch(SetCurrentDocumentType(type));
  },
  UpdateCurrentInsightId: (insightId) => {
    dispatch(SetCurrentInsightId(insightId));
  },
  DuplicateInsight: (
    insightId,
    currentLanguage,
    destLang,
    copyInsightForLanguage,
  ) => {
    copyInsightForLanguage(insightId, currentLanguage, destLang, () => {
      dispatch(SetCurrentLanguage(destLang));
      ownProps.history.push(`/insight/create/${insightId}/${destLang}`);
      dispatch(
        SetSuccessMessage(
          <FormattedMessage
            {...translations.InsightDuplicationMessage}
            values={{ lang: destLang }}
          />,
        ),
      );
    });
  },
  DuplicateCard: (cardId, currentLanguage, destLang, copyCardForLanguage) => {
    copyCardForLanguage(cardId, currentLanguage, destLang, () => {
      dispatch(SetCurrentLanguage(destLang));
      ownProps.history.push(getPath("createCardPeanut", cardId, destLang));
      dispatch(
        SetSuccessMessage(
          <FormattedMessage
            {...translations.CardDuplicationMessage}
            values={{ lang: destLang }}
          />,
        ),
      );
    });
  },
  DuplicateBattle: (
    battleId,
    currentLanguage,
    destLang,
    copyBattleForLanguage,
  ) => {
    copyBattleForLanguage(battleId, currentLanguage, destLang, () => {
      dispatch(SetCurrentLanguage(destLang));
      ownProps.history.push(getPath("editBattle", battleId, destLang));
      dispatch(
        SetSuccessMessage(
          <FormattedMessage
            {...translations.BattleDuplicationMessage}
            values={{ lang: destLang }}
          />,
        ),
      );
    });
  },
  DuplicatePoll: (pollId, currentLanguage, destLang, copyPollForLanguage) => {
    copyPollForLanguage(pollId, currentLanguage, destLang, () => {
      dispatch(SetCurrentLanguage(destLang));
      ownProps.history.push(getPath("editPoll", pollId, destLang));
      dispatch(
        SetSuccessMessage(
          <FormattedMessage
            {...translations.PollDuplicationMessage}
            values={{ lang: destLang }}
          />,
        ),
      );
    });
  },
  SetShowDuplicationModal: (newValue) => {
    dispatch(SetShowDuplicationModal(newValue));
  },
  SetShowNotificationModal: (newValue) => {
    dispatch(SetShowNotificationModal(newValue));
  },
  SetDuplicationData: (newValue) => {
    dispatch(SetDuplicationData(newValue));
  },
  SetNotificationData: (newValue) => {
    dispatch(SetNotificationData(newValue));
  },
  SetViewType: (newValue) => {
    dispatch(SetViewType(newValue));
  },
  SetListWorkflow: (newValue) => {
    dispatch(SetListWorkflow(newValue));
  },
});

const Container = compose(
  deleteInsightPageRequest,
  deleteCardPageRequest,
  deleteBattlePageRequest,
  copyInsightForLanguage,
  copyCardForLanguage,
  copyBattleForLanguage,
  sendNotificationMessage,
  deletePollPageRequest,
  copyPollForLanguage,
)(withThemedStyle(ContentManagerStyle)(ContentManager));

export default injectIntl(
  connect(mapStateToProp, mapDispatchToProps)(Container),
);
