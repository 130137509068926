import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import useQuestions from "../../hooks/activity/useQuestions";
import { GetDesignPageEncodeData } from "../../utils/ui-generator/generator";
import { decodeNewLines } from "../../utils/string";
import { SimpleQuizActivityQuestionTemplateEmpty } from "../../utils/ui-generator/defaultTemplates";
import { PAGE_TYPES } from "../../configs/constants";
import DocumentPageHeader from "../../components/common/DocumentPageHeader";
import ActivityIntro from "../../components/common/ActivityIntroComponent";
import SimpleQuizQuestion from "../../components/common/SimpleQuizQuestion";
import Style from "./SimpleQuizPageEditor.style";
import activityTranslations from "../../configs/translation/activityTranslations";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const decodeContent = (encodedContent) => {
  const contentObject = JSON.parse(encodedContent);

  return {
    ...contentObject,
    data: {
      ...contentObject.data,
      activityDescription: decodeNewLines(
        contentObject.data.activityDescription,
      ),
      title: decodeNewLines(contentObject.data.title),
      description: decodeNewLines(contentObject.data.description),
      questions: contentObject.data.questions.map((question) => ({
        ...question,
        text: decodeNewLines(question.text),
        answers: question.answers.map(({ answer, isTrue, message }) => ({
          answer: decodeNewLines(answer),
          isTrue,
          message: decodeNewLines(message),
        })),
        endMessage: {
          ...question.endMessage,
          text: decodeNewLines(question.endMessage.text),
        },
      })),
    },
  };
};

const encodeContent = (unEncodedContent, language) => {
  const data = {
    ...unEncodedContent,
    activityName: activityTranslations[language].SimpleQuiz.activityName,
  };
  return GetDesignPageEncodeData(data, PAGE_TYPES.SIMPLEQUIZZ);
};

const createNewQuestion = () => [SimpleQuizActivityQuestionTemplateEmpty];

const SimpleQuizPageEditor = (props) => {
  const {
    orderId,
    documentId,
    pageId,
    data: dataProp,
    onDataChanged,
    onRemovePage,
    onOrderDownClick,
    onOrderUpClick,
    language,
  } = props;
  const classes = useThemedStyle(useStyle, props);
  // EXTRACT VARIABLES
  const { data } = decodeContent(dataProp);

  const { activityDescription, title, description, background, questions } =
    data;

  const [enhancedQuestions, setQuestions] = useState(
    () =>
      questions.map((question, index) => ({
        uid: `${index}-${JSON.stringify(question)}`,
        ...question,
      })),
    [questions],
  );

  // CALLBACKS
  const handleChange = useCallback(
    (changedProps, refresh = true) => {
      const encodedContent = encodeContent(
        {
          ...data,
          ...changedProps,
        },
        language,
      );

      if (
        changedProps.questions &&
        enhancedQuestions.length !== changedProps.questions.length
      ) {
        setQuestions(
          changedProps.questions.map((question, index) => ({
            uid: `${index}-${JSON.stringify(question)}`,
            ...question,
          })),
        );
      }
      onDataChanged(documentId, language, encodedContent, pageId, refresh);
    },
    [data, language, enhancedQuestions, onDataChanged, documentId, pageId],
  );

  useEffect(() => {
    if (props.isProgramQuiz && data.isProgramQuiz !== props.isProgramQuiz) {
      handleChange({ ...data, isProgramQuiz: true }, true);
    }
  }, []);

  const handleIntroChange = ({ image, theme, title, description }) =>
    handleChange({
      activityDescription: theme,
      title,
      description,
      background: {
        ...background,
        url: image,
      },
    });

  const {
    editedQuestionIndex,
    setEditedQuestionIndex,
    handleQuestionChange,
    removeQuestion,
    addQuestionBefore,
    addQuestionAfter,
  } = useQuestions(questions, createNewQuestion, handleChange);

  return (
    <div className={classes.pageBox}>
      <DocumentPageHeader
        pageNumber={orderId}
        onRemoveClick={() => onRemovePage(documentId, language, pageId)}
        onOrderDownClick={() => onOrderDownClick(documentId, language, pageId)}
        onOrderUpClick={() => onOrderUpClick(documentId, language, pageId)}
      />
      <ActivityIntro
        activityId={pageId}
        theme={activityDescription}
        title={title}
        description={description}
        image={background.url}
        isSelected
        onChange={handleIntroChange}
      />
      {enhancedQuestions.map(({ uid, ...questionProps }, index) => (
        <SimpleQuizQuestion
          key={uid}
          uniqueId={`${pageId}-${index}`}
          id={index}
          {...questionProps}
          onChange={handleQuestionChange}
          isEditable={index === editedQuestionIndex}
          onClick={() => setEditedQuestionIndex(index)}
          onAddQuestionBefore={addQuestionBefore}
          onAddQuestionAfter={addQuestionAfter}
          onRemove={removeQuestion}
        />
      ))}
    </div>
  );
};

SimpleQuizPageEditor.propTypes = {
  /** page Id  */
  pageId: PropTypes.string,
  /** current page JSON data */
  data: PropTypes.string,
  /** page order id */
  orderId: PropTypes.number,
  /** id of current document */
  documentId: PropTypes.string,
  /** current language */
  language: PropTypes.string,
  /** on data changed handler */
  onDataChanged: PropTypes.func,
  /**  event to trigger when a new page is added */
  onRemovePage: PropTypes.func,
  /** whether we are currently editing this page or not **/
  isActivePage: PropTypes.bool,
  /** order up page handler */
  onOrderUpClick: PropTypes.func,
  /** order down page handler */
  onOrderDownClick: PropTypes.func,
};

export default SimpleQuizPageEditor;
