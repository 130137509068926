import gql from "graphql-tag";

export const DEGREE_UPDATE_PIC_THUMB = gql`
  mutation degreeUpdatePicThumb(
    $degreeId: ID
    $lang: LangEnum
    $data: String!
  ) {
    admin {
      degree(degreeId: $degreeId, lang: $lang) {
        picThumb(data: $data)
      }
    }
  }
`;
