import React from "react";
import { LANGUAGES } from "../../../configs/referential";
import { Dropdown } from "genius-ui";
import style from "./Filters.style";
import { createUseStyles } from "react-jss";
import { SetCurrentLanguage } from "../../../store/degree/actions";
import { connect } from "react-redux";
import clientConfig from "../../../configs/client";
import FilterLanguages from "../../../components/FilterLanguages";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const DefaultFilters = (props) => {
  const { SetCurrentLanguage, currentLanguage } = props;
  const classes = useThemedStyle(useStyle, props);
  let selectedValue = LANGUAGES.find((x) => x.Code === currentLanguage);
  if (!selectedValue) {
    selectedValue = LANGUAGES.find(
      (x) => x.Code === clientConfig.DEFAULT_LANGUAGE_CODE,
    );
  }

  return (
    <div className={classes.rootContainer}>
      <FilterLanguages languages={LANGUAGES}>
        {(languages) =>
          languages.length > 1 ? (
            <Dropdown
              selectedOption={{
                value: selectedValue.Code,
                label: selectedValue.Name,
              }}
              onChange={(selectedOption) => {
                SetCurrentLanguage(selectedOption.value);
              }}
              options={languages.map((x) => ({ value: x.Code, label: x.Name }))}
            />
          ) : null
        }
      </FilterLanguages>
    </div>
  );
};

const mapStateToProp = (state) => ({
  currentLanguage: state.degrees.currentLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  SetCurrentLanguage: (language) => {
    dispatch(SetCurrentLanguage(language));
  },
});

export default connect(mapStateToProp, mapDispatchToProps)(DefaultFilters);
