import React, { useCallback } from "react";
import Style from "./DotsMenu.style";
import { createUseStyles } from "react-jss";
import useThemedStyle from "hooks/style/useThemedStyle";

import { Icon, Icons } from "genius-ui";

const useStyle = createUseStyles(Style);

const DotsMenu = ({ handleDelete, docId, ...props }) => {
  const classes = useThemedStyle(useStyle, props);

  const handleDotsClick = useCallback(
    (e) => {
      e.stopPropagation();
      handleDelete(docId);
    },
    [handleDelete, docId],
  );

  return (
    <div>
      <Icon
        iconName={Icons.roundMore}
        style={classes.dots}
        handleClick={handleDotsClick}
      />
    </div>
  );
};

export default DotsMenu;
