import { defineMessages } from "react-intl";

export const translations = defineMessages({
  ModalTitle: {
    id: "LessonRelocationModal.ModalTitle",
    defaultMessage: "Choose new Program",
  },
  SelectProgramPlaceholder: {
    id: "LessonRelocationModal.SelectProgramPlaceholder",
    defaultMessage: "Select a program",
  },
  ConfirmButton: {
    id: "LessonRelocationModal.ConfirmButton",
    defaultMessage: "Move",
  },
  ProgramLabel: {
    id: "LessonRelocationModal.ProgramLabel",
    defaultMessage: "Program",
  },
  Introduction: {
    id: "LessonRelocationModal.Introduction",
    defaultMessage:
      "Move this lesson to another Program in the Degree {degreeName} in the language {degreeLanguage}",
  },
});
