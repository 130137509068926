import React, { useState, useEffect } from "react";
import { DegreesStyle } from "./DegreesShiseido.style";
import withThemedStyle from "../../../components/hoc/withThemedStyle";
import Navigation from "../../../components/Navigation";
import { translations } from "./DegreesShiseido.translations";
import { FormattedMessage, FormattedDate } from "react-intl";
import Filters from "./filters";
import { Icons, VerticalCard, FullscreenLoader } from "genius-ui";
import SettingsMenu from "../../../components/common/SettingsMenu";
import { connect } from "react-redux";
import compose from "lodash/fp/compose";
import { getPath } from "../../../core/paths";
import {
  DeleteDocument,
  GetDegrees,
  SetCurrentDegreeId,
} from "../../../store/degree/actions";
import DeleteModal from "../../../components/common/DeleteModal";
import DuplicationModal from "../../../components/common/DuplicationModal";
import { LANGUAGES } from "../../../configs/referential";
import { TranslateDegree } from "../../../store/masterTemplate/actions";
import { SetSuccessMessage } from "../../../store/notification/actions";

const Degrees = ({
  classes,
  ResetStore,
  RedirectToPublish,
  isFetching,
  degrees,
  history,
  filters: { language },
  DeleteDocument,
  TranslateDocument,
}) => {
  const [activeMenuTemplateId, setActiveMenuTemplateId] = useState();
  const [deleteModalIsVisible, setDeleteModalVisibility] = useState(false);
  const [translateModalIsVisible, setTranslateModalVisibility] =
    useState(false);
  const [operationDocumentData, setOperationDocumentData] = useState({
    availableLanguages: null,
  });

  useEffect(() => {
    // return () => ResetStore()
  }, []);

  const editDegree = (id) => () => {
    RedirectToPublish(id, language.selectedValue);
  };

  const navigateToDegreeBuilderScreen = (id) => () => {
    history.push(getPath("degreeBuild", id, language.selectedValue));
  };

  const showDeleteModal = (docId) => () => {
    setOperationDocumentData({ docId, lang: language.selectedValue });
    setDeleteModalVisibility(true);
  };

  const showTranslateModal = (docId, availableLanguages) => () => {
    setOperationDocumentData({
      docId,
      lang: language.selectedValue,
      availableLanguages: LANGUAGES.filter(
        (lang) => !availableLanguages.includes(lang.Code),
      ).map((lang) => lang.Code),
    });
    setTranslateModalVisibility(true);
  };

  const deleteDocumentHandler = () => {
    DeleteDocument(operationDocumentData);
    setDeleteModalVisibility(false);
  };

  const translateDocumentHandler = (destLang) =>
    TranslateDocument({ ...operationDocumentData, destLang });

  return (
    <div className={classes.rootContainer}>
      {isFetching && <FullscreenLoader />}
      <Navigation />
      <div className={classes.degreesContainer}>
        <div className={classes.actionContainer}>
          <Filters />
        </div>
        <div className={classes.cardsContainer}>
          {degrees.map((template) => (
            <div key={template.id} className={classes.cardContainer}>
              <VerticalCard
                image_path={template.picCover}
                title={template.title}
                footertext1={template.workflow}
                footertext2={
                  <FormattedMessage
                    {...translations.Counters}
                    values={{
                      programs: template.programs,
                      lessons: template.lessons,
                    }}
                  />
                }
                footertext3={
                  <FormattedDate
                    value={template.date}
                    year="numeric"
                    month="long"
                    day="2-digit"
                  />
                }
                handleOnClick={navigateToDegreeBuilderScreen(template.id)}
                handleOnButtonClick={(e) => {
                  e.stopPropagation();
                  setActiveMenuTemplateId(template.id);
                }}
              />
              <SettingsMenu
                handleFocusLeave={() => setActiveMenuTemplateId(undefined)}
                isVisible={activeMenuTemplateId === template.id}
                className={classes.vCardSettingsMenu}
                menuItems={[
                  {
                    label: <FormattedMessage {...translations.EditOption} />,
                    icon: Icons.settings,
                    onItemClick: editDegree(template.id),
                  },
                  {
                    label: <FormattedMessage {...translations.CloneOption} />,
                    icon: Icons.duplicate,
                    onItemClick: showTranslateModal(
                      template.id,
                      template.availableLanguages,
                    ),
                  },
                  /*{
                    label: <FormattedMessage {...translations.EditBadges} />,
                    icon: Icons.reward,
                    onItemClick: () => history.push(
                      getPath('degreeBadges', template.id, language.selectedValue),
                      { backTo: `/degrees`, wordingModalKey: 'SuccessfulScheduleMessage' }
                    )
                  },*/
                  {
                    label: <FormattedMessage {...translations.DeleteOption} />,
                    icon: Icons.delete,
                    onItemClick: showDeleteModal(template.id),
                  },
                ]}
              />
            </div>
          ))}
        </div>
      </div>
      <DeleteModal
        deleteMessage={<FormattedMessage {...translations.DeleteMessage} />}
        actionHandler={deleteDocumentHandler}
        setShowModal={setDeleteModalVisibility}
        showModal={deleteModalIsVisible}
      />
      <DuplicationModal
        hideModal={() => setTranslateModalVisibility(false)}
        visible={translateModalIsVisible}
        sourceLang={language.selectedValue}
        availableItemLanguages={operationDocumentData.availableLanguages}
        duplicationHandler={translateDocumentHandler}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.common.fetchIndicator,
  isCreating: state.common.saveIndicator,
  degrees: state.degrees.degrees,
  filters: state.degrees.degreeFilters,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  // ResetStore: () => dispatch(ResetDegreeStore()),
  RedirectToPublish: (degreeId, lang) => {
    dispatch(SetCurrentDegreeId(degreeId));
    ownProps.history.push(`/degree/create/${degreeId}/${lang}`);
  },
  DeleteDocument: ({ docId, lang }) => {
    dispatch(DeleteDocument(docId, lang, () => dispatch(GetDegrees())));
  },
  TranslateDocument: ({ docId, lang, destLang }) => {
    dispatch(
      TranslateDegree(docId, lang, destLang, () => {
        dispatch(GetDegrees());
        dispatch(
          SetSuccessMessage(
            <FormattedMessage
              {...translations.DuplicatedDegreeMessage}
              values={{ lang: destLang }}
            />,
          ),
        );
      }),
    );
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withThemedStyle(DegreesStyle),
)(Degrees);
