import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonSize, ButtonVariation, StylelessModal } from "genius-ui";
import { createUseStyles } from "react-jss";
import style from "./ClassicModal.style";
import { FormattedMessage } from "react-intl";
import { translations } from "./ClassicModal.translations";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const ClassicModal = (props) => {
  const {
    visible,
    hideModal,
    actionHandler,
    modalMessage,
    modalTitle,
    buttonLabel,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <StylelessModal show={visible} onClose={hideModal} centered>
      <div className={classes.modalContent}>
        {modalTitle && (
          <div className={classes.header}>
            <div className={classes.title}>{modalTitle}</div>
          </div>
        )}
        <div className={classes.body}>
          <div className={classes.messageLabel}>
            <FormattedMessage {...modalMessage} />
          </div>
          <div className={classes.actions}>
            <Button
              handleClick={hideModal}
              variation={ButtonVariation.secondary}
              size={ButtonSize.small}
            >
              <FormattedMessage {...translations.CancelBtnLabel} />
            </Button>
            <Button
              handleClick={actionHandler}
              variation={ButtonVariation.primary}
              size={ButtonSize.small}
            >
              <FormattedMessage {...buttonLabel} />
            </Button>
          </div>
        </div>
      </div>
    </StylelessModal>
  );
};

ClassicModal.propTypes = {
  /** Show or hide modal */
  visible: PropTypes.bool,
  /** Hide modal event handler */
  hideModal: PropTypes.func.isRequired,
  /** Clone handler function */
  actionHandler: PropTypes.func,
  /** Clone message */
  modalMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Clone modal title */
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ClassicModal.defaultProps = {
  visible: false,
};

export default ClassicModal;
