import gql from "graphql-tag";

export const CARD_CLONE_TO_MARKET = gql`
  mutation cloneToMarket(
    $cardId: ID
    $lang: LangEnum!
    $marketTagId: Int
    $marketTagIds: [Int!]
  ) {
    admin {
      card(cardId: $cardId) {
        clone(
          lang: $lang
          marketTagId: $marketTagId
          cloneTags: false
          marketTagIds: $marketTagIds
          cloneTagsFeature: true
        ) {
          cardId
        }
      }
    }
  }
`;
