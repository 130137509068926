import React from "react";
import LazyLoadedModuleWrapper from "./Wrapper";

/**
 * Creates a component that lazy loads the component based on the promise returned by @importFunction, displays a
 * spinner while the component is being loaded and displays an error message if an error occured during the component
 * load.
 * @param {function(*): *} importFunction: function responsible for dynamic importing the component.
 * @returns {function(*): *}: the component which renders the lazy loaded component wrapped with LazyLoadedModuleWrapper
 */
export const CreateLazyLoadedClientComponent = (importFunction) => {
  const Module = React.lazy(importFunction);

  return (props) => (
    <LazyLoadedModuleWrapper>
      <Module {...props} />
    </LazyLoadedModuleWrapper>
  );
};
