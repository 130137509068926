import { createMultipleSheetXml } from "../excelFactory";
import { exportTranslations } from "../../../pages/results/poll/PollResults.translations";

export const exportSpecifiedKpiPollData = (questions, pollInfo, intl) => {
  const sheets = [
    {
      name: "Result",
      data: questions.reduce((dataArray, question) => {
        dataArray = [
          ...dataArray,
          [intl.formatMessage({ ...exportTranslations.QuestionTitle })],
          [question.heading],
          [
            [intl.formatMessage({ ...exportTranslations.AnswersTitle })],
            [intl.formatMessage({ ...exportTranslations.AnswersNumberTitle })],
          ],
          ...question.content.map((answer) => [
            [answer.answer],
            [answer.answersNumber],
          ]),
          [],
        ];
        return dataArray;
      }, []),
    },
    {
      name: "Poll details",
      data: [
        [
          [intl.formatMessage({ ...exportTranslations.NameColumnTitle })],
          [intl.formatMessage({ ...exportTranslations.StatusColumnTitle })],
          [
            intl.formatMessage({
              ...exportTranslations.CreationDateColumnTitle,
            }),
          ],
          [
            intl.formatMessage({
              ...exportTranslations.PublicationDateColumnTitle,
            }),
          ],
          [
            intl.formatMessage({
              ...exportTranslations.ParticipantsColumnTitle,
            }),
          ],
          [intl.formatMessage({ ...exportTranslations.MarketsColumnTitle })],
          [intl.formatMessage({ ...exportTranslations.BrandsColumnTitle })],
        ],
        [
          [pollInfo.name],
          [pollInfo.status],
          [pollInfo.creationDate],
          [pollInfo.publicationDate],
          [pollInfo.participants],
          [pollInfo.markets.map((market) => market.label).join(", ")],
          [pollInfo.brands.map((brand) => brand.label).join(", ")],
        ],
      ],
    },
  ];

  createMultipleSheetXml(sheets, "kpiPoll.xlsx");
};
