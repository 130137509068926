import React, { useState, useEffect } from "react";
import { MasterTemplateStyle } from "./MasterTemplates.style";
import withThemedStyle from "../../components/hoc/withThemedStyle";
import Navigation from "../../components/Navigation";
import { translations } from "./MasterTemplates.translations";
import { FormattedMessage, FormattedDate } from "react-intl";
import Filters from "./filters";
import FixedContainer from "../../components/common/FixedContainer";
import RoundButton from "../../components/common/RoundButton";
import { Icon, Icons, VerticalCard, FullscreenLoader } from "genius-ui";
import SettingsMenu from "../../components/common/SettingsMenu";
import { connect } from "react-redux";
import compose from "lodash/fp/compose";
import {
  CreateMasterTemplate,
  GetMasterTemplates,
  ResetStore,
  CloneDegree,
  DeleteDegree,
  TranslateDegree,
} from "../../store/masterTemplate/actions";
import { getPath } from "../../core/paths";
import Spinner from "../../components/common/Spinner";
import { DEGREE_PARTS, DOC_WORKFLOW_ENUM } from "../../configs/constants";
import CloneModal from "../../components/common/CloneModal";
import { SetSuccessMessage } from "../../store/notification/actions";
import DeleteModal from "../../components/common/DeleteModal";
import DuplicationModal from "../../components/common/DuplicationModal";
import { LANGUAGES } from "../../configs/referential";

const MasterTemplates = ({
  classes,
  GetMasterTemplates,
  ResetStore,
  isFetching,
  isCreating,
  templates,
  CreateMasterTemplate,
  history,
  filters: { language },
  CloneDegree,
  DeleteDegree,
  TranslateDegree,
}) => {
  const [activeMenuTemplateId, setActiveMenuTemplateId] = useState();
  const [cloneDegreeId, setCloneDegreeId] = useState();
  const [translateDegreeData, setTranslateDegreeData] = useState({
    availableLanguages: null,
  });
  const [deleteDegreeId, setDeleteDegreeId] = useState();

  const deleteDocumentHandler = () => {
    DeleteDegree(deleteDegreeId, language.selectedValue);
    setDeleteDegreeId(null);
  };

  const translateDocumentHandler = (destLang) => {
    TranslateDegree(
      translateDegreeData.degreeId,
      language.selectedValue,
      destLang,
    );
  };

  useEffect(() => {
    GetMasterTemplates();
    // TO DO Review proper store data flow and  reset
    // return () => ResetStore()
  }, []);

  const editMasterTemplate = (id) => () => {
    history.push(getPath("editMasterTemplate", id, language.selectedValue));
  };

  const navigateToDegreeBuilderScreen = (id) => () => {
    history.push(getPath("degreeBuild", id, language.selectedValue));
  };

  const navigateToDegreeAddToMarketScreen = (id) => () => {
    history.push(
      getPath(
        "documentAddToMarket",
        DEGREE_PARTS.DEGREE,
        id,
        language.selectedValue,
      ),
    );
  };

  const showTranslationModal = (degreeId, existingLanguages) => {
    const availableLanguages = LANGUAGES.filter(
      (lang) => !existingLanguages.includes(lang.Code),
    ).map((lang) => lang.Code);
    setTranslateDegreeData({ degreeId, availableLanguages });
  };

  return (
    <div className={classes.rootContainer}>
      {isFetching && <FullscreenLoader />}
      <Navigation containerClassName={classes.navigationContainer} />
      <FixedContainer className={classes.createButtonContainer}>
        {isCreating ? (
          <Spinner size={30} />
        ) : (
          <RoundButton onClick={CreateMasterTemplate}>
            <Icon iconName={Icons.plus} />
          </RoundButton>
        )}
      </FixedContainer>
      <div className={classes.masterTemplatesContainer}>
        <div className={classes.actionContainer}>
          <div className={classes.title}>
            <FormattedMessage {...translations.Title} />
          </div>
          <Filters />
        </div>
        <div className={classes.cardsContainer}>
          {templates.map((template) => (
            <div key={template.id} className={classes.cardContainer}>
              <VerticalCard
                image_path={template.picCover}
                title={template.title}
                footertext1={
                  template.workflow === DOC_WORKFLOW_ENUM.PUBLISHED ? (
                    <FormattedMessage {...translations.StatusPublished} />
                  ) : (
                    <FormattedMessage {...translations.StatusDraft} />
                  )
                }
                footertext2={
                  <FormattedMessage
                    {...translations.Counters}
                    values={{
                      programs: template.programs,
                      lessons: template.lessons,
                    }}
                  />
                }
                footertext3={
                  <FormattedDate
                    value={template.date}
                    year="numeric"
                    month="long"
                    day="2-digit"
                  />
                }
                handleOnClick={navigateToDegreeBuilderScreen(template.id)}
                handleOnButtonClick={(e) => {
                  e.stopPropagation();
                  setActiveMenuTemplateId(template.id);
                }}
              />
              <SettingsMenu
                handleFocusLeave={() => setActiveMenuTemplateId(undefined)}
                isVisible={activeMenuTemplateId === template.id}
                className={classes.vCardSettingsMenu}
                menuItems={[
                  {
                    label: <FormattedMessage {...translations.EditOption} />,
                    icon: Icons.settings,
                    onItemClick: editMasterTemplate(template.id),
                  },
                  {
                    label: <FormattedMessage {...translations.CloneOption} />,
                    icon: Icons.duplicate,
                    onItemClick: () => setCloneDegreeId(template.id),
                  },
                  {
                    label: (
                      <FormattedMessage {...translations.AddToMarketOption} />
                    ),
                    icon: Icons.reply,
                    onItemClick: navigateToDegreeAddToMarketScreen(template.id),
                    disabled: template.workflow === DOC_WORKFLOW_ENUM.DRAFT,
                  },
                  {
                    label: (
                      <FormattedMessage {...translations.TranslateOption} />
                    ),
                    icon: Icons.translate,
                    onItemClick: () =>
                      showTranslationModal(
                        template.id,
                        template.availableLanguages,
                      ),
                  },
                  {
                    label: <FormattedMessage {...translations.DeleteOption} />,
                    icon: Icons.delete,
                    onItemClick: () => setDeleteDegreeId(template.id),
                  },
                ]}
              />
            </div>
          ))}
        </div>
      </div>
      <CloneModal
        hideModal={() => setCloneDegreeId(null)}
        cloneHandler={() => {
          CloneDegree(cloneDegreeId);
          setCloneDegreeId(null);
        }}
        visible={!!cloneDegreeId}
        cloneMessage={<FormattedMessage {...translations.DegreeCloneMessage} />}
        cloneModalTitle={
          <FormattedMessage {...translations.DegreeCloneModalTitle} />
        }
      />
      <DeleteModal
        deleteMessage={<FormattedMessage {...translations.DeleteMessage} />}
        actionHandler={deleteDocumentHandler}
        setShowModal={() => setDeleteDegreeId(null)}
        showModal={!!deleteDegreeId}
      />
      <DuplicationModal
        hideModal={() => setTranslateDegreeData({ availableLanguages: null })}
        visible={!!translateDegreeData.availableLanguages}
        sourceLang={language.selectedValue}
        availableItemLanguages={translateDegreeData.availableLanguages}
        duplicationHandler={translateDocumentHandler}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.common.fetchIndicator,
  isCreating: state.common.saveIndicator,
  templates: state.masterTemplates.templates,
  filters: state.masterTemplates.filters,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  GetMasterTemplates: () => dispatch(GetMasterTemplates()),
  ResetStore: () => dispatch(ResetStore()),
  CreateMasterTemplate: () => {
    dispatch(
      CreateMasterTemplate("NEW DRAFT DEGREE", (id, lang) => {
        ownProps.history.push(getPath("editMasterTemplate", id, lang));
      }),
    );
  },
  CloneDegree: (degreeId) =>
    dispatch(
      CloneDegree(degreeId, () => {
        dispatch(GetMasterTemplates());
        dispatch(
          SetSuccessMessage(
            <FormattedMessage {...translations.DegreeSuccessfulCloneMessage} />,
          ),
        );
      }),
    ),
  DeleteDegree: (degreeId, lang) => {
    dispatch(
      DeleteDegree(degreeId, lang, () => dispatch(GetMasterTemplates())),
    );
  },
  TranslateDegree: (degreeId, srcLang, destLang) => {
    dispatch(
      TranslateDegree(degreeId, srcLang, destLang, () => {
        ownProps.history.push(`/degree/create/${degreeId}/${destLang}`);
        dispatch(
          SetSuccessMessage(
            <FormattedMessage
              {...translations.DuplicatedDegreeMessage}
              values={{ lang: destLang }}
            />,
          ),
        );
      }),
    );
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withThemedStyle(MasterTemplateStyle),
)(MasterTemplates);
