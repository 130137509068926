import gql from "graphql-tag";

export const ADD_LESSON_TO_PROGRAM = gql`
  mutation addLessonToProgram(
    $programId: ID
    $lang: LangEnum
    $lessonIds: [ID!]!
  ) {
    admin {
      program(programId: $programId, lang: $lang) {
        addLessons(lessonIds: $lessonIds) {
          programId
        }
      }
    }
  }
`;
