import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Icon, Icons, SearchInput } from "genius-ui";
import UsersStyle from "./Users.style";
import {
  GetUsers,
  PatchPaginationMetaData,
  ReSetFilterSelectedTags,
  SetFilterSelectedTags,
} from "../../store/user/actions";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import messages from "./Users.messages";
import UserTableTagFilter from "./UserTableTagFilter";
import ExportUsers from "./Users.export";
import { STATUS_ENUM } from "../../configs/constants";
import SelectedFilterValues from "../../components/SelectedFilterValues";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../hooks/style/useThemedStyle";
import classNames from "classnames";
import SearchButton from "../../components/common/SearchButton";

const useStyle = createUseStyles(UsersStyle);

const UserTableFilters = (props) => {
  const {
    userSearchTerm,
    filters,
    SetUserSearchTerm,
    RemoveFilterValue,
    ClearFilters,
    usersByStatuses,
    isTrainer,
    userProfileInfo,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  const [showStatusTooltip, setStatusTooltip] = useState(false);
  const [searchInputIsExpanded, setSearchInputVisibility] = useState(false);

  const tagFilterCustomStyle = {
    control: (providedProps) => ({
      ...providedProps,
      ...UsersStyle.tagFilter,
    }),
  };

  const setUserSearchTerm = (e) => SetUserSearchTerm(e.target.value);

  const selectedFilterValues = filters.reduce(
    (filterSelectedValues, filter) =>
      filterSelectedValues.concat(
        filter.selectedTags
          ? filter.selectedTags.map((selectedTag) => ({
              ...selectedTag,
              filter,
            }))
          : [],
      ),
    [],
  );

  const getFilterAvailability = (filter) => {
    const zoneFilter = filters.find(({ name }) => name === "ZONES");
    const countryFilter = filters.find(({ name }) => name === "COUNTRIES");
    const zoneFilterSelectedTags =
      zoneFilter && zoneFilter.selectedTags.length
        ? zoneFilter.selectedTags.map(({ value }) => value)
        : null;
    const countryFilterSelectedTags =
      countryFilter && countryFilter.selectedTags.length
        ? countryFilter.selectedTags.map(({ value }) => value)
        : null;
    switch (filter.name) {
      case "COUNTRIES":
        return !!zoneFilterSelectedTags;
      case "CITIES":
        return !!countryFilterSelectedTags;
      default:
        return true;
    }
  };

  const clearFilters = useCallback(() => ClearFilters(isTrainer), [isTrainer]);

  return (
    <div className={classes.globalFilterContainer}>
      <div>
        {searchInputIsExpanded && (
          <FormattedMessage {...messages.SearchAUserInputLabel}>
            {(message) => (
              <SearchInput
                value={userSearchTerm}
                onChange={setUserSearchTerm}
                onClear={() => setSearchInputVisibility(false)}
                containerClassName={classes.searchContainer}
                clearOnLeft
                placeholder={message[0]}
              />
            )}
          </FormattedMessage>
        )}
        {!searchInputIsExpanded &&
          filters.map((filter) => {
            let isEnabled = getFilterAvailability(filter);

            if (filter.name === "MARKET" && isTrainer) {
              isEnabled = false;
            }
            return (
              <div
                key={filter.name}
                className={classNames([
                  isEnabled ? classes.dropdown : "",
                  "tagFilterContainer",
                ])}
              >
                <UserTableTagFilter
                  filter={filter}
                  filters={filters}
                  styles={tagFilterCustomStyle}
                  isEnabled={isEnabled}
                  isTrainer={isTrainer}
                  userProfileInfo={userProfileInfo}
                />
              </div>
            );
          })}
        {!searchInputIsExpanded && (
          <div className={classes.totalUsers}>
            <div>
              <FormattedMessage {...messages.TotalUsers} />
              {` ${usersByStatuses[STATUS_ENUM.ACTIVE]}`}
            </div>
            <div
              onMouseOver={() => setStatusTooltip(true)}
              onMouseLeave={() => setStatusTooltip(false)}
            >
              <Icon iconName={Icons.info} style={classes.infoIcon} />
            </div>
            {showStatusTooltip && (
              <div className={classes.userStatusesTooltip}>
                <div>
                  <FormattedMessage {...messages.TooltipCompleteStatusLabel} />:{" "}
                  <span>{usersByStatuses[STATUS_ENUM.ACTIVE]}</span>
                </div>
                <div>
                  <FormattedMessage {...messages.TooltipDeleteStatusLabel} />:{" "}
                  <span>{usersByStatuses[STATUS_ENUM.ARCHIVED]}</span>
                </div>
                <div>
                  <FormattedMessage
                    {...messages.TooltipIncompleteStatusLabel}
                  />
                  : <span>{usersByStatuses[STATUS_ENUM.DRAFT]}</span>
                </div>
              </div>
            )}
          </div>
        )}
        {!searchInputIsExpanded && (
          <SearchButton
            onClick={() => setSearchInputVisibility(!searchInputIsExpanded)}
          />
        )}
        <ExportUsers>
          {(exportUsers) => (
            <span className={classes.exportIcon} onClick={exportUsers}>
              <Icon iconName={Icons.exportExcel} />
            </span>
          )}
        </ExportUsers>
      </div>
      <SelectedFilterValues
        className={classes.selectedTagsContainer}
        filterValues={selectedFilterValues}
        onRemove={(removedFilterValue) =>
          RemoveFilterValue(removedFilterValue.filter, removedFilterValue)
        }
        onClearAll={clearFilters}
        isTrainer={isTrainer}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  totalUsers: state.users.userCount,
  userSearchTerm: state.users.paginationMetaData.userSearchTerm,
  filters: state.users.filters,
  usersByStatuses: state.users.usersByStatuses,
  userProfileInfo: state.auth.profileInfo,
  isTrainer: state.auth.profileInfo.roles.some((role) => role === "trainer"),
});

const mapDispatchToProps = (dispatch) => ({
  SetUserSearchTerm: (searchTerm) => {
    dispatch(
      PatchPaginationMetaData({
        userSearchTerm: searchTerm,
      }),
    );
    dispatch(GetUsers(true));
  },
  RemoveFilterValue: (filter, removedValue) => {
    dispatch(
      SetFilterSelectedTags({
        filterName: filter.name,
        selectedTags: filter.selectedTags.filter(
          (selectedTag) => selectedTag.value !== removedValue.value,
        ),
      }),
    );
    dispatch(GetUsers());
  },
  ClearFilters: (isTrainer) => {
    dispatch(ReSetFilterSelectedTags(isTrainer));
    dispatch(GetUsers());
  },
  SetFilter: (tags) => {
    dispatch(SetFilterSelectedTags(tags));
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UserTableFilters,
);
