import { createXml } from "../excelFactory";
import { filterExportArray } from "./filter";
import { applicationTranslations as translations } from "./Kpi.translations";

export const exportApplication = (
  {
    activeUsers,
    activeNumberOfConnections,
    averageTimeSpentPerDay,
    nrOfUsersChartData,
  },
  filterData,
  intl,
) => {
  const data = filterExportArray(filterData, intl).concat(
    [
      [
        intl.formatMessage(translations.ActiveUsers),
        intl.formatMessage(translations.Current),
        intl.formatMessage(translations.Previous),
        intl.formatMessage(translations.Total),
      ],
      [
        intl.formatMessage(translations.Users),
        activeUsers.active,
        activeUsers.previousValue,
        activeUsers.total,
      ],
      [],
      [],
      [
        intl.formatMessage(translations.NumberOfConnections).toUpperCase(),
        intl.formatMessage(translations.Current),
        intl.formatMessage(translations.Previous),
      ],
      [
        intl.formatMessage(translations.NumberOfConnections),
        activeNumberOfConnections.numberOfConnections,
        activeNumberOfConnections.previousValue,
      ],
      [],
      [],
      [
        intl.formatMessage(translations.AverageTimeSpentPerDay),
        intl.formatMessage(translations.Current),
        intl.formatMessage(translations.Previous),
      ],
      [
        intl.formatMessage(translations.AverageTime),
        averageTimeSpentPerDay.averageTimeMin,
        averageTimeSpentPerDay.previousValue,
      ],
      [],
      [],
      [intl.formatMessage(translations.NumberOfUsers)],
      [
        intl.formatMessage(translations.Date),
        intl.formatMessage(translations.UserCount),
      ],
    ].concat(
      nrOfUsersChartData[0].data.map((element) => [
        new Date(element[0]).toString(),
        element[1],
      ]),
    ),
  );

  createXml(data, "kpi-application-export.xlsx");
};
