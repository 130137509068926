import { defineMessages } from "react-intl";

export const translations = defineMessages({
  LastConnectionLabel: {
    id: "UserProfileHeader.LastConnectionLabel",
    defaultMessage: "Last connection: {date}",
  },
  NotConnected: {
    id: "UserProfileHeader.NotConnected",
    defaultMessage: "Last connection: Not connected",
  },
});
