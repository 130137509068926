export default ({ MultiLineTextInput }) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    border: "1px solid transparent",
    boxSizing: "border-box",
    backgroundColor: MultiLineTextInput.backgroundColor,
    width: "100%",
    height: "100%",
    resize: "none",
    overflow: "hidden",
    padding: "13px 15px",
    borderRadius: 2,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "condensed",
    lineHeight: "normal",
    letterSpacing: 0.05,
    color: MultiLineTextInput.inputColor,
    "&:focus": {
      border: `1px solid ${MultiLineTextInput.focusedBorderColor}`,
      outline: "none",
    },
    "&::placeholder": {
      color: MultiLineTextInput.placeHolderColor,
    },
    "": {
      color: MultiLineTextInput.placeHolderColor,
    },
    "&::-ms-input-placeholder": {
      color: MultiLineTextInput.placeHolderColor,
    },
    "&.invalid": {
      border: `1px solid ${MultiLineTextInput.invalidBorderColor}`,
    },
  },
});
