import React from "react";
import ImageVideoUploader from "components/common/ImageVideoUploader";
import { useCover } from "store/publish/hooks";
import "constants/scss/style.scss";

const UploadCover = ({ type }) => {
  const { picCover, videoCover, EditImage, EditVideo } = useCover();
  const ids = {
    battle: "battleImage",
    card: "cardImage",
    degree: "degreeImage",
    insight: "insightImage",
    lesson: "lessonImage",
    poll: "pollImage",
  };
  const size = {
    battle: { width: 414, height: 736 },
    card: { width: 306, height: 425 },
    degree: { width: 306, height: 425 },
    insight: { width: 306, height: 425 },
    lesson: { width: 306, height: 425 },
    poll: { width: 414, height: 200 },
  };

  const updateImage = (url) => {
    EditImage(type, { url });
  };
  const updateVideo = (url) => {
    EditVideo(type, { url });
  };

  return (
    <div
      className="pictureContainer"
      style={{ width: size[`${type}`].width, height: size[`${type}`].height }}
    >
      <ImageVideoUploader
        id={ids[`${type}`]}
        video={videoCover}
        image={picCover}
        updateVideo={updateVideo}
        updateImage={updateImage}
      />
    </div>
  );
};

export default React.memo(UploadCover);
