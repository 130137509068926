import { defineMessages } from "react-intl";

export const translations = defineMessages({
  ProgramsLabel: {
    id: "KpiLearningShiseido.ProgramsWidgetLabel",
    defaultMessage: "Programs",
  },
  LessonsLabel: {
    id: "KpiLearningShiseido.LessonsWidgetLabel",
    defaultMessage: "Lessons",
  },
  InfosLabel: {
    id: "KpiLearningShiseido.InfosLabel",
    defaultMessage:
      "This is the number of lessons published during the period and available to the population selected.",
  },
  ViewSwitcherLabel: {
    id: "KpiLearningShiseido.ViewSwitcherLabel",
    defaultMessage: "Group lesson per program",
  },
});
