import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Style from "./RadioGroup.style";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const RadioGroup = (props) => {
  const { options, name, value, onChange, renderOption, renderOptionLabel } =
    props;
  const classes = useThemedStyle(useStyle, props);
  const defaultRenderOption = (option, index, options, value) => (
    <div key={index} className={classes.option}>
      <input
        type="radio"
        value={option.value}
        name={name}
        className={classes.optionInput}
        checked={option.value === value}
      />
      {(renderOptionLabel &&
        renderOptionLabel(option, index, options, value, onChange)) || (
        <label>{option.label}</label>
      )}
    </div>
  );

  return (
    <>
      {options.map((option, index, options) =>
        renderOption
          ? renderOption(option, index, options, value, defaultRenderOption)
          : defaultRenderOption(option, index, options, value),
      )}
    </>
  );
};

const OptionShape = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element,
  ]).isRequired,
});

RadioGroup.propTypes = {
  /** the options the user can choose from **/
  options: PropTypes.arrayOf(OptionShape).isRequired,
  /** name of the input group **/
  name: PropTypes.string,
  /** the selected option. must equal the value attribute of the selected option **/
  value: PropTypes.any,
  /** callback executed when a value was clicked **/
  onChange: PropTypes.func.isRequired,
  /** can be used for custom rendering of the options **/
  renderOption: PropTypes.func,
  /** can be used for custom rendering of the option label **/
  renderOptionLabel: PropTypes.func,
};

export default RadioGroup;
