import React from "react";
import PropTypes from "prop-types";
import SettingsMenu from "components/common/SettingsMenu";
import { FormattedMessage, useIntl } from "react-intl";
import injectSheet from "react-jss";
import listStyle from "./List.style";
import { Button, ButtonSize, ButtonVariation, Icons, Icon } from "genius-ui";
import uuidV4 from "uuid/dist/v4";
import Image from "components/common/Image";
import {
  DOC_WORKFLOW_ENUM,
  DOCUMENT_TYPES,
  STATUS_ENUM,
} from "configs/constants";
import { translations } from "./List.translations";
import { formatDate } from "utils/dateUtils";
import { DocumentTypeTranslations } from "constants/domain_items/documents/document_types";
import { getUri } from "components/common/ImageOpti/ImageOpti";

const ListView = (allProps) => {
  const {
    props,
    items,
    editDocument,
    getEditLabel,
    classes,
    showLoadMore,
    loading,
    loadMore,
    allowEdit,
    allowDelete,
    setDeleteDocumentData,
    showDeleteModal,
    navigateToInsightPublishPage,
    navigateToCardPublishPage,
    navigateToBattlePublishPage,
    navigateToPollPublishPage,
    allowNotification,
    showNotificationModal,
    navigateToTheBattleKpiPage,
    navigateToThePollKpiPage,
  } = allProps;
  const { formatMessage } = useIntl();

  return (
    <div className={classes.container}>
      <table cellSpacing="0">
        <thead>
          <tr>
            <th>
              <FormattedMessage {...translations.NameHeaderTitle} />
            </th>
            <th>
              <FormattedMessage {...translations.TypeHeaderTitle} />
            </th>
            <th>
              <FormattedMessage {...translations.DateHeaderTitle} />
            </th>
            <th>
              <FormattedMessage {...translations.StatusHeaderTitle} />
            </th>
            <th>
              <Icon iconName={Icons.read} />
            </th>
            <th>
              <Icon iconName={Icons.vote} />
            </th>
            <th>
              <Icon iconName={Icons.comment} />
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {items.map((item) => {
            const isPublishedPoll =
              item.type === DOCUMENT_TYPES.POLL &&
              item.workflow === DOC_WORKFLOW_ENUM.PUBLISHED;
            const isPublishedBattle =
              item.type === DOCUMENT_TYPES.BATTLE &&
              item.workflow === DOC_WORKFLOW_ENUM.PUBLISHED;
            const workflow =
              item.workflow !== "UNPUBLISHED" ? item.workflow : "EXPIRED";
            const showResultMenuOption = isPublishedBattle || isPublishedPoll;
            const seeResultActionHandler = isPublishedPoll
              ? () => navigateToThePollKpiPage(item.id)
              : isPublishedBattle
              ? () => navigateToTheBattleKpiPage(item.id)
              : undefined;

            return (
              <tr key={uuidV4()}>
                <td
                  className={classes.title}
                  onClick={editDocument(props, item)}
                >
                  <div>
                    <div>
                      <Image
                        width={40}
                        height={40}
                        url={getUri(item.picCover, "profile", "hd")}
                        className={classes.image}
                      />
                    </div>
                    <div>{item.title}</div>
                  </div>
                </td>
                <td className={classes.type}>
                  <FormattedMessage {...DocumentTypeTranslations[item.type]} />
                </td>
                <td className={classes.date}>
                  {item.publicationDate
                    ? formatDate(new Date(item.publicationDate), "dd/MM/yy")
                    : formatMessage(translations.NotScheduled)}
                </td>
                <td className={classes.status}>{workflow}</td>
                <td className={classes.statistic}>{item.read}</td>
                <td className={classes.statistic}>{item.vote}</td>
                <td className={classes.statistic}>{item.comment}</td>
                <td>
                  <div className={classes.settingsContainer}>
                    <Icon
                      iconName={Icons.roundMore}
                      style={classes.settingsButton}
                      handleClick={() => props.onItemSettingsClicked(item.id)}
                    />
                    <SettingsMenu
                      handleFocusLeave={() =>
                        props.onItemSettingsClicked(undefined)
                      }
                      isVisible={
                        props.activeMenuDocumentId &&
                        props.activeMenuDocumentId === item.id
                      }
                      className={classes.docSettingsMenu}
                      menuItems={[
                        {
                          label: (
                            <FormattedMessage {...translations.PublishOption} />
                          ),
                          icon: Icons.settings,
                          onItemClick: () => {
                            props.onItemSettingsClicked(undefined);
                            switch (item.type) {
                              case DOCUMENT_TYPES.INSIGHT:
                                navigateToInsightPublishPage(item.id);
                                break;
                              case DOCUMENT_TYPES.CARD:
                                navigateToCardPublishPage(item.id);
                                break;
                              case DOCUMENT_TYPES.BATTLE:
                                navigateToBattlePublishPage(item.id);
                                break;
                              case DOCUMENT_TYPES.POLL:
                                navigateToPollPublishPage(item.id);
                                break;
                              default:
                            }
                          },
                        },
                        {
                          label: getEditLabel(item.type),
                          icon: Icons.settings,
                          onItemClick: editDocument(props, item),
                          disabled: !allowEdit,
                        },
                        {
                          label: (
                            <FormattedMessage
                              {...translations.SendNotificationOption}
                            />
                          ),
                          icon: Icons.notification,
                          onItemClick: showNotificationModal(item),
                          disabled: !allowNotification,
                          hidden: item.workflow === STATUS_ENUM.DRAFT,
                        },
                        {
                          label: (
                            <FormattedMessage {...translations.DeleteOption} />
                          ),
                          icon: Icons.delete,
                          onItemClick: () => {
                            props.onItemSettingsClicked(undefined);
                            setDeleteDocumentData(item);
                            showDeleteModal();
                          },
                          disabled: !allowDelete,
                        },
                        {
                          label: (
                            <FormattedMessage
                              {...translations.SeeResultOption}
                            />
                          ),
                          icon: Icons.stats,
                          onItemClick: seeResultActionHandler,
                          hidden: !showResultMenuOption,
                          iconStyle: classes.kpiIcon,
                        },
                      ]}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {showLoadMore && (
        <div className={classes.loadMoreContainer}>
          <Button
            disabled={loading}
            variation={ButtonVariation.primary}
            size={ButtonSize.small}
            handleClick={loadMore}
          >
            <FormattedMessage {...translations.SeeMoreButtonDefaultTitle} />
          </Button>
        </div>
      )}
    </div>
  );
};

ListView.propTypes = {
  allowEdit: PropTypes.bool,
  allowDuplication: PropTypes.bool,
  allowNotification: PropTypes.bool,
  allowDelete: PropTypes.bool,
};

ListView.defaultProps = {
  allowEdit: true,
  allowDuplication: true,
  allowNotification: true,
  allowDelete: true,
};

export default injectSheet(listStyle)(ListView);
