import gql from "graphql-tag";

export const GET_MARKET_AND_BRAND_TAGS = gql`
  query GetMarketAndBrandTags(
    $docIds: [Int!]
    $clusterIds: [ID!]
    $types: [DocTypeEnum!]
  ) {
    admin {
      documents(docIds: $docIds, types: $types) {
        versions {
          tags(clusterIds: $clusterIds) {
            tagId
            clusterId
          }
        }
      }
    }
  }
`;
