import * as constants from "../../constants/store/publishBattle";
import { RESET_STORE } from "../../constants/store/auth";

const intervals = [
  {
    value: 5,
    label: "5s",
  },
  {
    value: 10,
    label: "10s",
  },
  {
    value: 15,
    label: "15s",
  },
  {
    value: 20,
    label: "20s",
  },
  {
    value: 25,
    label: "25s",
  },
  {
    value: 30,
    label: "30s",
  },
];

const initialState = {
  intervals,
  publishData: {
    battleId: null,
    lang: null,
    title: "",
    summary: "",
    picCover: null,
    language: null,
    startPostingDate: null,
    endPostingDate: null,
    countries: [],
    retailers: [],
    brands: [],
    timePerQuestion: null,
    pages: null,
    pageId: null,
    scorePerQuestions: 0,
    workflow: null,
  },
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.UPDATE_PUBLISH_DATA:
      return {
        ...state,
        publishData: {
          ...state.publishData,
          ...data,
        },
      };
    case constants.RESET_STORE:
      return { ...initialState };
    case RESET_STORE:
      return { ...initialState };
    default:
      return state;
  }
}
