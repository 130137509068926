import clientConfig from "../../configs/client";

export const formatOptionsForSelect = (options, filterMasterTags = true) =>
  options
    ? (filterMasterTags
        ? options.filter(
            (x) => parseInt(x.Id) !== clientConfig.MASTER_DEGREE_TAG_ID,
          )
        : options
      ).map((option) => ({
        value: option.Id,
        label: option.Value,
        originalTagData: option.originalTagData,
      }))
    : [];

export const formatOptionsForSave = (options) =>
  options
    ? options.map((option) => ({
        Id: option.value,
        Value: option.label,
        originalTagData: option.originalTagData,
      }))
    : [];

export const GetTagsForClusters = (clustersIds, tags) => {
  if (clustersIds && clustersIds.length) {
    const filteredTags = tags.filter((x) =>
      clustersIds.includes(parseInt(x.clusterId)),
    );

    return filteredTags.map((x) => ({
      Id: x.tagId,
      Value: x.title,
      originalTagData: x,
    }));
  } else {
    return [];
  }
};

export const GetTagsForTagList = (clustersIds, tags) => {
  if (clustersIds && clustersIds.length > 0) {
    let filteredTags = [];
    if (clustersIds) {
      filteredTags = tags.filter((x) =>
        clustersIds.includes(parseInt(x.clusterId)),
      );
    }

    return filteredTags.map((x) => ({
      label: x.title,
    }));
  } else {
    return [];
  }
};

export const GetTagByDeepness = (tags, deepness) => {
  const actualTag = tags.find(
    (tag) => parseInt(tag.clusterDeepness) === deepness,
  );
  return actualTag ? actualTag.title : "-";
};

export const GetTagNameByClusterId = (tags, clusterId) => {
  let actualTag = null;
  if (typeof clusterId === "number") {
    actualTag = tags.find((tag) => parseInt(tag.clusterId) === clusterId);
  } else {
    actualTag = tags.find((tag) => clusterId.includes(parseInt(tag.clusterId)));
  }
  return actualTag ? actualTag.title : "-";
};

export const GetTagsByDeepness = (tags, deepness) => {
  const actualTag = tags.filter(
    (tag) => parseInt(tag.clusterDeepness) === deepness,
  );
  var countries = "";
  actualTag.forEach((element, index, array) => {
    countries = `${countries}${
      !countries ? element.title : `, ${element.title}`
    }`;
  });
  return countries;
};

export const GetTagObjectByDeepnessId = (tags, deepnessId) => {
  return tags.find((tag) => parseInt(tag.clusterDeepness) === deepnessId);
};

export const withMasterTag = (tags) =>
  tags
    ? [...tags, { value: clientConfig.MASTER_DEGREE_TAG_ID }]
    : [{ value: clientConfig.MASTER_DEGREE_TAG_ID }];
