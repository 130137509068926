import React from "react";
import PropTypes from "prop-types";
import { ImagePageEditorStyle } from "../ImagePageEditor.style";
import {
  IMAGE_CONTROL_CROP_VARIATIONS,
  IMAGE_CONTROL_POSITION,
} from "../../../configs/constants";
import ImageSelectorReadOnly from "../../common/ImageSelector/imageSelectorReadOnly";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(ImagePageEditorStyle);

const ImagePageEditor = (props) => {
  const { type, onImageChange, ...restProps } = props;
  const cropVariation = IMAGE_CONTROL_CROP_VARIATIONS[type];
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.imageContainer}>
      <ImageSelectorReadOnly
        {...restProps}
        {...cropVariation}
        cropSize={cropVariation}
      />
    </div>
  );
};

ImagePageEditor.propTypes = {
  /** image variation **/
  type: PropTypes.oneOf([
    IMAGE_CONTROL_POSITION.FULLWIDTH,
    IMAGE_CONTROL_POSITION.BOXED,
    IMAGE_CONTROL_POSITION.FULLSCREEN,
  ]).isRequired,
  /** + All the props of ImageSelector except:
   * - onImageChange
   * - width
   * - height
   * - cropSize
   * **/
};

export default ImagePageEditor;
