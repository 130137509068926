import { BATTLE_QUESTION_TYPE_ENUM } from "../../configs/constants";

export const EmptyBattlePageTemplate = {
  template: "BattleQuizTemplate",
  data: {
    questions: [
      {
        type: BATTLE_QUESTION_TYPE_ENUM.SIMPLE,
        score: 1,
        text: "",
        description: "",
        image: null,
        tip: {
          cover: null,
          title: "",
          description: "",
        },
        timer: 20,
        answers: [],
      },
    ],
  },
};
