import gql from "graphql-tag";

export const PROGRAM_UPDATE_SUMMARY = gql`
  mutation programUpdateSumarry(
    $programId: ID
    $lang: LangEnum
    $data: String!
  ) {
    admin {
      program(programId: $programId, lang: $lang) {
        summary(data: $data)
      }
    }
  }
`;
