import { defineMessages } from "react-intl";

export const translations = defineMessages({
  TitlePlaceholder: {
    id: "EditMasterTemplateScreen.TitlePlaceholder",
    defaultMessage: "Name Your Degree",
  },
  SummaryPlaceholder: {
    id: "EditMasterTemplateScreen.SummaryPlaceholder",
    defaultMessage: "Describe your Degree",
  },
  LanguageLabel: {
    id: "EditMasterTemplateScreen.LanguageLabel",
    defaultMessage: "Language",
  },
  BrandTagsTitle: {
    id: "EditMasterTemplateScreen.BrandTagsTitle",
    defaultMessage: "Brand",
  },
  RetailerTagsTitle: {
    id: "EditMasterTemplateScreen.RetailerTagsTitle",
    defaultMessage: "Retailer",
  },
  BrandPlaceholder: {
    id: "EditMasterTemplateScreen.BrandPlaceholder",
    defaultMessage: "Add a brand",
  },
  RetailerPlaceholder: {
    id: "EditMasterTemplateScreen.RetailerPlaceholder",
    defaultMessage: "Add a retailer",
  },
  TagsTitle: {
    id: "EditMasterTemplateScreen.TagsTitle",
    defaultMessage: "Associated Tags",
  },
  TagsPlaceholder: {
    id: "EditMasterTemplateScreen.TagsPlaceholder",
    defaultMessage: "Add a tag",
  },
  SaveButton: {
    id: "EditMasterTemplateScreen.SaveButton",
    defaultMessage: "Save",
  },
  UploadImage: {
    id: "EditMasterTemplateScreen.UploadImage",
    defaultMessage: "Change cover",
  },
  NoOneTagMessage: {
    id: "EditMasterTemplateScreen.NoOneTagMessage",
    defaultMessage: "No tag selected",
  },
  ReadyToUseLabel: {
    id: "EditMasterTemplateScreen.ReadyToUseLabel",
    defaultMessage: "Ready to use",
  },
  IndicateBrandsWarning: {
    id: "EditMasterTemplateScreen.IndicateBrandsWarning",
    defaultMessage:
      "Please indicate which brand or brands this content should be associated with before publishing.",
  },
});
