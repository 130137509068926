import React from "react";
import { createUseStyles } from "react-jss";
import hoistNonReactStatics from "hoist-non-react-statics";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const defaultUpdateStyleMethod = () => ({});

const withThemedStyle =
  (Style, UpdateStyle = defaultUpdateStyleMethod) =>
  (DecoratedComponent) => {
    const useStyle = createUseStyles(Style);
    const useUpdateStyle = createUseStyles(UpdateStyle);

    const WithThemedStyle = (props) => {
      const classes = {
        ...useThemedStyle(useStyle, props),
        ...useThemedStyle(useUpdateStyle, props),
      };

      return <DecoratedComponent {...props} classes={classes} />;
    };

    hoistNonReactStatics(WithThemedStyle, DecoratedComponent);

    return WithThemedStyle;
  };

export default withThemedStyle;
