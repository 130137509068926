const rowHeight = 50;
const rowPadding = "0 65px 0 17px";

export default (theme) => ({
  container: {
    fontFamily: theme.FontFamily,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.UserProfilePrograms.containerColor,
  },
  accordion: {
    border: "none",
    borderRadius: 2,
    "& .accordion__item + .accordion__item": {
      borderTop: "none",
    },

    "& .accordion__button": {
      padding: rowPadding,
      color: theme.UserProfilePrograms.accordionColor,
      fontSize: 14,
      borderBottom: `solid 1px ${theme.UserProfilePrograms.accordionBorderBottom}`,

      "& > div": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: rowHeight,
      },

      "&:after": {
        top: "25px!important",
        right: "20px!important",
        height: 5,
        width: 5,
        borderBottom: `2px solid ${theme.UserProfilePrograms.accordionAfterBorderBottom}!important`,
        borderRight: `2px solid ${theme.UserProfilePrograms.accordionAfterBorderRight}!important`,
      },
    },

    "& .accordion__button:hover": {
      backgroundColor: theme.UserProfilePrograms.accordionHoverColor,
    },

    "& .accordion__panel": {
      "& > div": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: rowPadding,
        height: rowHeight,
        backgroundColor: theme.UserProfilePrograms.accordionPanelBackground,
        "& > div:first-of-type": {
          paddingLeft: 20,
          boxSizing: "border-box",
        },
      },
    },
  },
  header: {
    width: "100%",
    height: 50,
    borderBottom: `solid 1px ${theme.UserProfilePrograms.headerBorderBottom}`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 65,
    boxSizing: "border-box",
    fontSize: 14,
    lineHeight: 1.07,
  },

  column_name: {
    width: "30%",
  },
  column_progress: {
    width: "30%",
    textAlign: "center",
    color: theme.UserProfilePrograms.columnProgressColor,
    "& > div > span": {
      fontSize: 12,
    },
    "& > div": {
      fontSize: 10,
    },
  },
  column_update: {
    width: "20%",
    textAlign: "center",
    fontSize: 12,
    color: theme.UserProfilePrograms.columnUpdateColor,
  },
  column_points: {
    width: "20%",
    textAlign: "center",
    fontSize: 12,
    color: theme.UserProfilePrograms.columnPointsColor,
  },
  progressBar: {
    height: 4,
    borderRadius: 7,
    backgroundColor: theme.UserProfilePrograms.progressBarBackground,
  },
  noContent: {
    justifyContent: "center",
    color: theme.UserProfilePrograms.noContentColor,
  },
});
