import React, { useState } from "react";
import { Query } from "react-apollo";
import {
  DEGREES_GET_LIST_BY_STATUSES,
  DEGREES_GET_HISTORY,
} from "../../graphql/remote/degrees/queries";
import { NoImageAvailable } from "assets/icons";
import {
  Icons,
  VerticalCard,
  Button,
  ButtonVariation,
  ButtonSize,
  FullscreenLoader,
} from "genius-ui";
import { DEGREE_PARTS, DOC_WORKFLOW_ENUM } from "../../configs/constants";
import { uniqWith, isEqual, capitalize } from "lodash";
import DuplicationModal from "../../components/common/DuplicationModal";
import { LANGUAGES } from "../../configs/referential";
import { defineMessages, FormattedMessage } from "react-intl";
import SendNotificationModal from "../../components/common/SendNotificationModal";
import SettingsMenu from "../../components/common/SettingsMenu";
import DeleteModal from "../../components/common/DeleteModal";
import CloneModal from "../../components/common/CloneModal";
import { formatDate } from "../../utils/dateUtils";

const messages = defineMessages({
  AvailableInText: {
    id: "Degrees.AvailableInText",
    defaultMessage: "Available in {languages}",
  },
  ProgramsAndLessonsNumber: {
    id: "Degrees.ProgramsAndLessonsNumber",
    defaultMessage: "{programsNr} Programs {lessonsNr} Lessons",
  },
  DegreesInOtherLanguageText: {
    id: "Degrees.DegreesInOtherLanguageText",
    defaultMessage: "Degrees in other language",
  },
  EditDegreeOption: {
    id: "Degrees.EditDegreeOption",
    defaultMessage: "Edit degree",
  },
  TranslateOption: {
    id: "Degrees.TranslateOption",
    defaultMessage: "Translate",
  },
  CloneOption: {
    id: "Degrees.CloneOption",
    defaultMessage: "Clone",
  },
  DeleteOption: {
    id: "Degrees.DeleteOption",
    defaultMessage: "Delete",
  },
  SendNotificationOption: {
    id: "Degrees.SendNotificationTitle",
    defaultMessage: "Send Notification",
  },
  CreateProgramButtonText: {
    id: "Degrees.CreateProgramButtonText",
    defaultMessage: "CREATE PROGRAM",
  },
  TranslateInButtonText: {
    id: "Degrees.TranslateInButtonText",
    defaultMessage: "TRANSLATE IN {lang}",
  },
  NotificationModalTitle: {
    id: "Degrees.NotificationModalTitle",
    defaultMessage: "Send a Notification",
  },
  NotificationModalDescription: {
    id: "Degrees.NotificationModalDescription",
    defaultMessage:
      "Send a Push Notification to all users that this content is broadcast to. " +
      "This notification will only be sent once",
  },
  NotificationModalMessagePlaceholder: {
    id: "Degrees.NotificationModalMessagePlaceholder",
    defaultMessage: "There’s something new to see in Beauty Genius",
  },
  PublishOption: {
    id: "Degrees.PublishOption",
    defaultMessage: "Publication settings",
  },
  DeleteMessage: {
    id: "Degrees.DeleteMessage",
    defaultMessage: "Are you sure you want to delete this item{mark}",
  },
  DegreeCloneMessage: {
    id: "Degrees.DegreeCloneMessage",
    defaultMessage:
      "You are about to make a clone of this degree. " +
      "This clone will keep the tags of Brand and Associated Tags. " +
      "We recommend that the original and clone degree are not displayed for the same Market",
  },
  CloneModalTitle: {
    id: "Degrees.CloneModalTitle",
    defaultMessage: "You are about to make a clone of this degree",
  },
});

const GetReformatedItem = (degree, currentVersion) => {
  const availableLanguages = degree.versions
    .filter(
      (y) =>
        y.lang !== currentVersion.lang &&
        y.workflow !== DOC_WORKFLOW_ENUM.ARCHIVED,
    )
    .map((x) => x.lang);
  const subtitle = (
    <FormattedMessage
      {...messages.AvailableInText}
      values={{
        languages:
          availableLanguages.length > 0
            ? [currentVersion.lang]
                .concat(availableLanguages)
                .join(",")
                .toUpperCase()
            : [currentVersion.lang].join(",").toUpperCase(),
      }}
    />
  );
  const numberOfPrograms = 0; // TODO: extract value from degree when it will be returned by the query
  const numberOfLessons = 0; // TODO: extract value from degree when it will be returned by the query

  const now = new Date();
  const newItem = {};
  newItem.MenuId = `${currentVersion.degreeId}_${currentVersion.lang}`;
  newItem.Id = currentVersion.degreeId;
  newItem.Language = currentVersion.lang;
  newItem.title = currentVersion.title;
  newItem.subTitle = subtitle;
  newItem.updateDate = formatDate(
    currentVersion.updateDate || now,
    "MMMM dd, yyyy",
  );
  newItem.picCover = currentVersion.picCover
    ? currentVersion.picCover
    : NoImageAvailable;
  newItem.status = capitalize(currentVersion.workflow);
  newItem.numberOfPrograms = numberOfPrograms;
  newItem.numberOfLessons = numberOfLessons;
  newItem.availableLanguages = availableLanguages;
  newItem.tags = currentVersion.tags;
  return newItem;
};

const GetFormattedFilteredResults = (props, data) => {
  const { language, isHistory, filters } = props;
  const {
    admin: { draft, deleted, published, archived },
  } = data;
  const fullResultList = isHistory
    ? deleted.concat(archived)
    : published.concat(draft);

  /** format degree elements */
  let formattedList = fullResultList
    .map((degree) =>
      degree.versions.map((version) => GetReformatedItem(degree, version)),
    )
    .flat();

  /** filtering by workflow and prevent the element duplications */
  const filterStatuses = isHistory
    ? [DOC_WORKFLOW_ENUM.ARCHIVED, DOC_WORKFLOW_ENUM.DELETED]
    : [DOC_WORKFLOW_ENUM.PUBLISHED, DOC_WORKFLOW_ENUM.DRAFT];
  formattedList = uniqWith(
    formattedList.filter((x) =>
      filterStatuses.includes(x.status.toUpperCase()),
    ),
    isEqual,
  );

  /** filtering by universe */
  if (filters.universe) {
    const universeTagId = parseInt(filters.universe.value.split("-")[0]);
    formattedList = formattedList.filter((degree) =>
      degree.tags.find((tag) => parseInt(tag.tagId) === universeTagId),
    );
  }

  /** filtering by language */
  const finalList = {
    degreesInCurrentLanguage: formattedList.filter(
      (x) => x.Language === language,
    ),
  };
  finalList.degreesInOtherLanguages = formattedList.filter(
    (x) =>
      x.Language !== language &&
      finalList.degreesInCurrentLanguage.findIndex((y) => y.Id === x.Id) < 0,
  );

  return finalList;
};

/**
 * Degree's destination language is available if doesn't exist the current degree
 * on the destination language
 * */
const showDuplicationModal = (props, currentItem) => () => {
  props.onItemSettingsClicked(undefined);
  props.setDuplicationData({
    itemId: currentItem.Id,
    availableLanguages: LANGUAGES.filter(
      (lang) => !currentItem.availableLanguages.includes(lang.Code),
    ).map((lang) => lang.Code),
  });
  props.setShowModal(true);
};

const hideModal = (props) => () => props.setShowModal(false);

const DegreesQuery = (props) => {
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [deleteDocumentData, setDeleteDocumentData] = useState();
  const [cloneDegreeData, setCloneDegreeData] = useState();
  const [cloneDegreeModalVisibility, setCloneModalVisibility] = useState(false);

  const changeDeleteModalVisibility = (value) => () =>
    setDeleteModalVisibility(value);

  const deleteDocument = (refetch) => () => {
    setDeleteModalVisibility(false);
    props.handleDelete(
      deleteDocumentData.Id,
      deleteDocumentData.Language,
      refetch,
    );
  };

  const cloneDegree = (refetch) => () => {
    setCloneModalVisibility(false);
    props.handleCloneDegree(
      cloneDegreeData.Id,
      cloneDegreeData.Language,
      refetch,
    );
  };

  return (
    <Query
      fetchPolicy="network-only"
      query={
        props.isHistory ? DEGREES_GET_HISTORY : DEGREES_GET_LIST_BY_STATUSES
      }
      variables={
        props.isHistory
          ? {
              statusDeleted: DOC_WORKFLOW_ENUM.DELETED,
              statusArchived: DOC_WORKFLOW_ENUM.ARCHIVED,
            }
          : {
              statusPublished: DOC_WORKFLOW_ENUM.PUBLISHED,
              statusDraft: DOC_WORKFLOW_ENUM.DRAFT,
            }
      }
      notifyOnNetworkStatusChange
    >
      {({ loading, data, refetch }) => {
        if (loading) {
          return <FullscreenLoader />;
        }

        const { classes } = props;
        const formattedResults = GetFormattedFilteredResults(props, data);

        return (
          <div className={classes.cardsRootContainer}>
            <div className={classes.cardsContainer}>
              {formattedResults.degreesInCurrentLanguage
                .sort((x, y) => (Number(x.Id) > Number(y.Id) ? -1 : 1))
                .map((item, index) => (
                  <div key={index} className={classes.cardContainer}>
                    <VerticalCard
                      image_path={item.picCover}
                      title={item.title}
                      subTitle={item.subTitle}
                      footertext1={`${item.status}`}
                      footertext2={
                        (item.numberOfPrograms > 0 &&
                          item.numberOfLessons > 0 && (
                            <FormattedMessage
                              {...messages.ProgramsAndLessonsNumber}
                              values={{
                                programsNr: item.numberOfPrograms,
                                lessonsNr: item.numberOfLessons,
                              }}
                            />
                          )) ||
                        ""
                      }
                      footertext3={item.updateDate}
                      handleOnClick={(event) => {
                        event.preventDefault();
                        props.handleRedirect(
                          `/degree/build/${item.Id}/${item.Language}`,
                        );
                      }}
                      handleOnButtonClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        props.onItemSettingsClicked(item.MenuId);
                      }}
                    />
                    <SettingsMenu
                      handleFocusLeave={() =>
                        props.onItemSettingsClicked(undefined)
                      }
                      isVisible={
                        props.activeMenuDegreeId &&
                        props.activeMenuDegreeId === item.MenuId
                      }
                      className={classes.degreeSettingsMenu}
                      menuItems={[
                        {
                          label: (
                            <FormattedMessage {...messages.PublishOption} />
                          ),
                          icon: Icons.settings,
                          onItemClick: () => {
                            props.onItemSettingsClicked(undefined);
                            props.onItemClick({
                              Id: item.Id,
                              Language: item.Language,
                            });
                          },
                        },
                        {
                          label: (
                            <FormattedMessage {...messages.TranslateOption} />
                          ),
                          icon: Icons.translate,
                          onItemClick: showDuplicationModal(props, item),
                          disabled:
                            !(LANGUAGES.length > 1) || !!props.isHistory,
                        },
                        {
                          label: <FormattedMessage {...messages.CloneOption} />,
                          icon: Icons.duplicate,
                          onItemClick: () => {
                            props.onItemSettingsClicked(undefined);
                            setCloneDegreeData(item);
                            setCloneModalVisibility(true);
                          },
                        },
                        {
                          label: (
                            <FormattedMessage {...messages.DeleteOption} />
                          ),
                          icon: Icons.delete,
                          onItemClick: () => {
                            props.onItemSettingsClicked(undefined);
                            setDeleteDocumentData(item);
                            setDeleteModalVisibility(true);
                          },
                          disabled: !!props.isHistory,
                        },
                        {
                          label: (
                            <FormattedMessage {...messages.EditDegreeOption} />
                          ),
                          icon: Icons.edit,
                          onItemClick: () => {
                            props.onItemSettingsClicked(undefined);
                            props.handleRedirect(
                              `/degree/build/${item.Id}/${item.Language}`,
                            );
                          },
                        },
                      ]}
                    />
                    {props.currentDegreeId &&
                    props.currentDegreeId === item.Id &&
                    props.language === item.Language ? (
                      <div className={classes.createProgramContainer}>
                        <Button
                          variation={ButtonVariation.primary}
                          size={ButtonSize.small}
                          handleClick={() =>
                            props.handleRedirect(
                              `/degree/build/${item.Id}/${item.Language}`,
                            )
                          }
                        >
                          <FormattedMessage
                            {...messages.CreateProgramButtonText}
                          />
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
            </div>
            {!props.isHistory &&
              formattedResults.degreesInOtherLanguages.length > 0 && (
                <div>
                  <div className={classes.otherDegreeTitle}>
                    <FormattedMessage
                      {...messages.DegreesInOtherLanguageText}
                    />
                  </div>
                  <div className={classes.cardsContainer}>
                    {formattedResults.degreesInOtherLanguages
                      .sort((x, y) => (Number(x.Id) > Number(y.Id) ? -1 : 1))
                      .map((item, index) => (
                        <div key={index} className={classes.cardContainer}>
                          <VerticalCard
                            image_path={item.picCover}
                            title={item.title}
                            subTitle={item.subTitle}
                            footertext1={`${item.status}`}
                            footertext2={
                              <FormattedMessage
                                {...messages.ProgramsAndLessonsNumber}
                                values={{
                                  programsNr: item.numberOfPrograms,
                                  lessonsNr: item.numberOfLessons,
                                }}
                              />
                            }
                            footertext3={item.updateDate}
                            handleOnClick={() => {}}
                            handleOnButtonClick={() => {}}
                            translateHandler={() => {
                              props.handleDuplicate(
                                item.Id,
                                item.Language,
                                props.language,
                              );
                              props.onItemSettingsClicked(undefined);
                            }}
                            translateButtonLabel={
                              <FormattedMessage
                                {...messages.TranslateInButtonText}
                                values={{ lang: props.language.toUpperCase() }}
                              />
                            }
                            showLayer
                          />
                        </div>
                      ))}
                  </div>
                </div>
              )}
            <DuplicationModal
              hideModal={hideModal(props)}
              visible={props.showDuplicationModal}
              sourceLang={props.language}
              availableItemLanguages={props.duplicationData.availableLanguages}
              currentItemId={props.duplicationData.itemId}
              duplicationHandler={(destLang) =>
                props.handleDuplicate(
                  props.duplicationData.itemId,
                  props.language,
                  destLang,
                )
              }
            />
            <DeleteModal
              deleteMessage={
                <FormattedMessage
                  {...messages.DeleteMessage}
                  values={{ mark: "?" }}
                />
              }
              actionHandler={deleteDocument(refetch)}
              setShowModal={changeDeleteModalVisibility(false)}
              showModal={deleteModalVisibility}
            />
            <CloneModal
              hideModal={() => setCloneModalVisibility(false)}
              cloneHandler={cloneDegree(refetch)}
              visible={cloneDegreeModalVisibility}
              cloneMessage={
                <FormattedMessage {...messages.DegreeCloneMessage} />
              }
              cloneModalTitle={
                <FormattedMessage {...messages.CloneModalTitle} />
              }
            />
            <FormattedMessage {...messages.NotificationModalMessagePlaceholder}>
              {(message) => (
                <SendNotificationModal
                  title={
                    <FormattedMessage {...messages.NotificationModalTitle} />
                  }
                  description={
                    <FormattedMessage
                      {...messages.NotificationModalDescription}
                    />
                  }
                  placeholder={message[0]}
                />
              )}
            </FormattedMessage>
          </div>
        );
      }}
    </Query>
  );
};

export default DegreesQuery;
