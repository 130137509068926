/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */

function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

/**
 * Parse string to get last word, or last letter if string is not divided by space char
 *
 * @returns {String} The last word or char
 * @param {String} str The string to be parsed.
 *
 */

const anyLastWord = (str) => {
  const words = str.split(" ");
  if (words.length === 1) return str.at(-1);
  return words[words.length - 1];
};

export function getTextInfos(text, font, maxWidth, divider) {
  let str = "";
  let totalStr = "";
  let numberOfLines = 1;

  for (let i = 0; i < text.length; i++) {
    while (text[i] && text[i].charCodeAt() === divider) {
      if (numberOfLines <= 3) {
        if (i < text.length - 1) {
          numberOfLines++;
        }
        totalStr += text[i];
        str = "";
      }
      i++;
    }
    if (i >= text.length) break;
    str += text[i];
    const width = getTextWidth(str, font);
    if (width > maxWidth) {
      numberOfLines++;
      if (numberOfLines <= 3) {
        str = anyLastWord(str);
      }
    }
    if (numberOfLines <= 3) {
      totalStr += text[i] || "";
    }
  }
  return { croppedString: totalStr, isOverflowing: numberOfLines > 3 };
}

function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export const transformString = (str) => {
  if (!str) return "";
  // replace empty lines by \n
  let strfinal = str
    ?.replaceAll("<div><br></div>", "\n")
    .replaceAll("<br>", "\n");
  // remove start or successive <div> and replace by \n
  strfinal = strfinal.replaceAll("<div>", "\n");
  // remove </div> for count
  strfinal = strfinal.replaceAll("</div>", "");
  // clean special characters
  strfinal = decodeHtml(strfinal);
  return strfinal;
};
