export const BadgeStyle = (theme) => ({
  rootContainer: {
    width: 1026,
    backgroundColor:
      theme.DegreeBadgeLessonScreen.badgeContainerBackgroundColor,
    borderRadius: 6,
    padding: "0 30px",
    marginBottom: 20,
    "& .header": {
      height: 87,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      "& > div:nth-of-type(1)": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      "& .badgeName": {
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 1.11,
        letterSpacing: -0.29,
        marginLeft: 10,
      },
    },
    "& .arrowIcon": {
      cursor: "pointer",
      fontSize: 12,
      "& :before": {
        color: theme.DegreeBadgeLessonScreen.badgeArrowIconColor,
      },
    },
  },
  content: {
    paddingTop: 20,
    borderTop: `solid 1px ${theme.DegreeBadgeLessonScreen.badgeContentBorderTopColor}`,
    display: "flex",
    flexDirection: "column",
  },
  noLessonText: {
    marginBottom: 20,
  },
});
