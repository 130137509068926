import React, { useEffect, useState } from "react";
import Style from "./EditMasterTemplate.style";
import withThemedStyle from "../../components/hoc/withThemedStyle";
import { Button, Icon, Icons, Tag, FullscreenLoader } from "genius-ui";
import { FormattedMessage, useIntl } from "react-intl";
import { translations } from "./EditMasterTemplate.translations";
import ContentEditable from "react-contenteditable";
import FilterLanguages from "../../components/FilterLanguages";
import { LANGUAGES } from "../../configs/referential";
import clientConfig, { TAG_TYPE } from "../../configs/client";
import SelectTagsByClusterId from "../../containers/Apollo/SelectTagsByClusterId";
import {
  formatOptionsForSave,
  formatOptionsForSelect,
  withMasterTag,
} from "../../utils/dataTransformation/tags";
import UploadMedia from "../../components/common/UploadMedia";
import { connect, useDispatch } from "react-redux";
import compose from "lodash/fp/compose";
import {
  GetMasterTemplateInfo,
  ResetStore,
  UpdateMasterTemplateInfo,
  UpdatePicCover,
  UpdateSummary,
  UpdateTags,
  UpdateTitle,
  UpdateWorkflow,
} from "../../store/editMasterTemplate/actions";
import { cleanUpAndEncodeText } from "../../utils/string";
import SwitchButton from "../../components/common/SwitchButton";
import { DOC_WORKFLOW_ENUM } from "../../configs/constants";
import { SetWarningMessage } from "../../store/notification/actions";

const EditMasterTemplate = ({
  classes,
  history,
  isFetching,
  GetMasterTemplateInfo,
  match: {
    params: { id, lang },
  },
  ResetStore,
  masterTemplateInfo,
  UpdateMasterTemplateInfo,
  UpdateTitleRequest,
  UpdateSummaryRequest,
  UpdatePicCoverRequest,
  UpdateTags,
  UpdateWorkflow,
}) => {
  useEffect(() => {
    GetMasterTemplateInfo(id, lang);
    return () => ResetStore();
  }, []);

  const { title, summary, workflow } = masterTemplateInfo;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [classNameError, setClassNameError] = useState(null);
  const goBack = () => history.goBack();

  const getMasterDegreeTags = () =>
    formatOptionsForSelect(masterTemplateInfo[TAG_TYPE.EXPERTISE]).filter(
      (tag) => parseInt(tag.value) !== clientConfig.MASTER_DEGREE_TAG_ID,
    );

  const handleClickSave = () => {
    if (masterTemplateInfo.brands.length) goBack();
    else {
      dispatch(
        SetWarningMessage(
          intl.formatMessage(translations.IndicateBrandsWarning),
        ),
      );
      setClassNameError(classes.tagSelectContainerError);
    }
  };

  const updateTagsBrand = () => {
    if (masterTemplateInfo.brands.length > 0) setClassNameError(null);
    return (selection) =>
      UpdateTags(formatOptionsForSave(selection), TAG_TYPE.BRAND);
  };

  return (
    <div className={classes.rootContainer}>
      {isFetching && <FullscreenLoader />}
      <div className={classes.center}>
        <div className={classes.left}>
          <FormattedMessage {...translations.TitlePlaceholder}>
            {(message) => (
              <ContentEditable
                html={title}
                onChange={({ target }) => {
                  UpdateMasterTemplateInfo("title", target.value);
                }}
                onBlur={({ target }) => UpdateTitleRequest(target.innerHTML)}
                className={classes.textInputTitle}
                placeholder={message}
                tagName="article"
              />
            )}
          </FormattedMessage>
          <br />
          <FormattedMessage {...translations.SummaryPlaceholder}>
            {(message) => (
              <ContentEditable
                html={summary}
                onChange={({ target }) => {
                  UpdateMasterTemplateInfo("summary", target.value);
                }}
                onBlur={({ target }) => UpdateSummaryRequest(target.innerHTML)}
                className={classes.textInputSummary}
                placeholder={`${message}...`}
                tagName="article"
              />
            )}
          </FormattedMessage>
          <br />
          <div className={classes.detailsContainer}>
            <div className={classes.leftPart}>
              <div className={classes.photo}>
                <UploadMedia
                  isVideoUploadEnabled={false}
                  onUploadSuccess={UpdatePicCoverRequest}
                >
                  <button className={classes.uploadBtn}>
                    <FormattedMessage {...translations.UploadImage} />
                  </button>
                </UploadMedia>
              </div>
            </div>
            <div className={classes.rightPart}>
              <div className={classes.langPublicContainer}>
                <span className={classes.firstTitle}>
                  <FormattedMessage {...translations.LanguageLabel} />
                </span>
              </div>
              <div className={classes.tagList}>
                <FilterLanguages languages={LANGUAGES}>
                  {(filteredLanguages) => {
                    const languages = [
                      filteredLanguages
                        .map((x) => ({
                          Id: x.Code,
                          Value: x.Name,
                        }))
                        .find((x) => x.Id === lang),
                    ];

                    return languages.map((x) => (
                      <Tag key={x.Value} label={x.Value} readOnly />
                    ));
                  }}
                </FilterLanguages>
              </div>
              <div className={classes.brandAndRetailer}>
                <div className={classes.brandTagsContainer}>
                  <div className={classes.sectionTitle}>
                    <FormattedMessage {...translations.BrandTagsTitle} />
                  </div>
                  <FormattedMessage {...translations.BrandPlaceholder}>
                    {(message) => (
                      <SelectTagsByClusterId
                        className={classes.tagSelectContainer}
                        classNameError={classNameError}
                        clusterIds={clientConfig.BRAND_TAGS_CLUSTER_IDS}
                        selectedItems={formatOptionsForSelect(
                          masterTemplateInfo[TAG_TYPE.BRAND],
                        )}
                        placeholder={`${message}...`}
                        onSelectionChanged={updateTagsBrand()}
                      />
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <span className={classes.sectionTitle}>
                <FormattedMessage {...translations.TagsTitle} />
              </span>
              <FormattedMessage {...translations.TagsPlaceholder}>
                {(message) => (
                  <SelectTagsByClusterId
                    className={classes.tagSelectContainer}
                    clusterIds={clientConfig.EXPERTISE_CLUSTERIDS}
                    selectedItems={getMasterDegreeTags()}
                    placeholder={`${message}...`}
                    onSelectionChanged={(selection) => {
                      UpdateTags(
                        formatOptionsForSave(withMasterTag(selection)),
                        TAG_TYPE.EXPERTISE,
                      );
                    }}
                  />
                )}
              </FormattedMessage>
              <div className={classes.buttons}>
                <Button
                  variation="primary"
                  size="big"
                  handleClick={handleClickSave}
                >
                  <FormattedMessage {...translations.SaveButton} />
                </Button>
                <div className={classes.switchBtn}>
                  <SwitchButton
                    isOn={workflow === DOC_WORKFLOW_ENUM.PUBLISHED}
                    handleToggle={UpdateWorkflow}
                  />
                  <div>
                    <FormattedMessage {...translations.ReadyToUseLabel} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.right} onClick={goBack}>
          <Icon iconName={Icons.slimCloseIcon} style={classes.closeIcon} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.common.fetchIndicator,
  masterTemplateInfo: state.editMasterTemplate.masterTemplateInfo,
});

const mapDispatchToProps = (dispatch, _ownProps) => ({
  ResetStore: () => dispatch(ResetStore()),
  GetMasterTemplateInfo: (id, lang) =>
    dispatch(GetMasterTemplateInfo(id, lang)),
  UpdateMasterTemplateInfo: (type, data) => {
    dispatch(UpdateMasterTemplateInfo({ [type]: data }));
  },
  UpdateTitleRequest: (data) => {
    dispatch(UpdateTitle(cleanUpAndEncodeText(data || "NEW DRAFT DEGREE")));
  },
  UpdateSummaryRequest: (data) => {
    dispatch(
      UpdateSummary(cleanUpAndEncodeText(data || "NEW DRAFT DEGREE'S SUMMARY")),
    );
  },
  UpdatePicCoverRequest: (src) => {
    dispatch(UpdateMasterTemplateInfo({ picCover: src }));
    dispatch(UpdatePicCover(src));
  },
  UpdateTags: (tagList, tagType) => {
    dispatch(UpdateTags(tagList, tagType));
  },
  UpdateWorkflow: (isReady) => {
    const workflow = isReady
      ? DOC_WORKFLOW_ENUM.PUBLISHED
      : DOC_WORKFLOW_ENUM.DRAFT;
    dispatch(
      UpdateWorkflow(workflow, () => {
        dispatch(UpdateMasterTemplateInfo({ workflow }));
      }),
    );
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withThemedStyle(Style),
)(EditMasterTemplate);
