import { defineMessages } from "react-intl";

export const translations = defineMessages({
  CreateCloneBtnLabel: {
    id: "ClassicModal.CreateCloneBtnLabel",
    defaultMessage: "Create clone",
  },
  CancelBtnLabel: {
    id: "ClassicModal.CancelBtnLabel",
    defaultMessage: "Cancel",
  },
});
