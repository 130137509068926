import { UPDATE_PAGE_DATA } from "../../constants/store/kpiApplicationDetails";
import { SetErrorMessage } from "../notification/actions";
import {
  extractFilterValues,
  getFormattedTagList,
} from "../actions/common/kpi";
import client from "../../graphql/client";
import { GET_APPLICATION_DATA } from "../../graphql/remote/kpi/queries/application.graphql";
import { kpiApplicationDocumentToKpiApplicationPageData } from "./transformers";
import { isShiseido } from "../../configs/client";
import {
  extendTagsWithUserTags,
  filterTagsByTrainerTags,
} from "../../utils/transformers";
import { USER_ROLE_NAMES } from "../../configs/constants";
import { SetIsLoading } from "store/kpiFilter/actions";

export const UpdatePageData = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PAGE_DATA,
    data,
  });
};

export const GetPageData = () => async (dispatch, getState) => {
  const { profileInfo } = getState().auth;
  const { filters } = getState().kpi;
  if (filters.isLoading) return;
  const generalFilterValues = extractFilterValues(filters);

  try {
    dispatch(SetIsLoading(true));
    /** If user is a trainer always get data from own markets */
    if (isShiseido && profileInfo.roles.includes(USER_ROLE_NAMES.TRAINER)) {
      const markets = filterTagsByTrainerTags(
        profileInfo.tags,
        getState().tags.markets,
      );
      generalFilterValues.market = extendTagsWithUserTags(
        generalFilterValues.market,
        markets,
      );
    }

    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_APPLICATION_DATA,
      variables: {
        fromDate: generalFilterValues.startDate,
        toDate: generalFilterValues.endDate,
        tags: getFormattedTagList(generalFilterValues),
      },
    });
    const generalFilterValuesNew = extractFilterValues(getState().kpi.filters);

    /** If user is a trainer always get data from own markets */
    if (isShiseido && profileInfo.roles.includes(USER_ROLE_NAMES.TRAINER)) {
      const markets = filterTagsByTrainerTags(
        profileInfo.tags,
        getState().tags.markets,
      );
      generalFilterValuesNew.market = extendTagsWithUserTags(
        generalFilterValuesNew.market,
        markets,
      );
    }
    if (
      JSON.stringify(generalFilterValues) ===
      JSON.stringify(generalFilterValuesNew)
    ) {
      dispatch(
        UpdatePageData(kpiApplicationDocumentToKpiApplicationPageData(result)),
      );
      dispatch(SetIsLoading(false));
    }
  } catch (e) {
    dispatch(
      SetErrorMessage(
        "An error occurred while fetching the kpi application data",
        e,
      ),
    );
  }
};
