import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Navigation from "../../../components/Navigation";
import styles from "./UserProfile.style";
import {
  ProfileCard,
  DetailsCard,
  TagList,
  TotalPoints,
  FullscreenLoader,
} from "genius-ui";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import compose from "lodash/fp/compose";
import QuantityWidget from "../../../components/KPI/QuantityWidget";
import Programs from "./programs";
import PendingQuestions from "./pendingQuestions";
import BadgeSlider from "./badgeSlider";
import uuidV4 from "uuid/dist/v4";
import {
  GetUserProfileData,
  ResetStore,
} from "../../../store/userProfile/actions";
import { UpdateFilterSelectedValues } from "../../../store/kpiFilter/actions";
import ProfileDegreeFilter from "./ProfileDegreeFilter";
import { exportUser } from "../../../utils/excelExport/userProfile/user";
import { translations } from "./UserProfile.translations";
import UserProfileHeader from "../userProfileHeader";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import PeriodSelector from "../../../components/KPI/PeriodSelector";
import { addMonth } from "../../../utils/dateUtils";
import { getPath } from "../../../core/paths";
import { getUri } from "components/common/ImageOpti/ImageOpti";

const useStyle = createUseStyles(styles);

const UserProfile = (props) => {
  const classes = useThemedStyle(useStyle, props);

  useEffect(() => {
    SetStartDateFilter(addMonth(new Date(), -6));
    props.GetUserProfileData(props.userId);

    return () => {
      props.ResetStore();
    };
  }, []);

  const {
    periods,
    profileData,
    isLoading,
    selectedPeriod,
    SetPeriodFilter,
    SetStartDateFilter,
    SetEndDateFilter,
    startDate,
    endDate,
  } = props;
  const handleBack = () => props.history.push(getPath("users"));

  if (isLoading) {
    return <FullscreenLoader />;
  }

  return (
    <div className={classes.container}>
      <Navigation />
      <UserProfileHeader
        handleBack={handleBack}
        lastConnection={profileData.lastConnection}
        userName={profileData.user.name}
        onExport={() => exportUser(profileData, props.intl)}
      />
      <div className={classes.content}>
        <div className={classes.leftSide}>
          <div>
            <ProfileCard
              imagePath={getUri(profileData.user.picture, "thumb", "hd")}
              name={profileData.user.name}
              position={profileData.user.position}
              locationDesc={profileData.user.location}
              locationUrl={profileData.user.locationUrl}
              email={profileData.user.email}
              registrationDate={
                <FormattedMessage
                  {...translations.RegistrationDateLabel}
                  values={{ date: profileData.user.creationDate }}
                />
              }
              language={profileData.user.lang}
              market={profileData.user.market}
            />
          </div>
          <div>
            <DetailsCard
              details={[
                {
                  key: uuidV4(),
                  title: (
                    <FormattedMessage {...translations.PointOfSaleLabel} />
                  ),
                  value: profileData.user.pointOfSale,
                },
                {
                  key: uuidV4(),
                  title: <FormattedMessage {...translations.MailingAddress} />,
                  value: profileData.user.mailingAddress,
                },
                {
                  key: uuidV4(),
                  title: (
                    <FormattedMessage {...translations.EmailAddressLabel} />
                  ),
                  value: profileData.user.email,
                },
                {
                  key: uuidV4(),
                  title: <FormattedMessage {...translations.BirthDayLabel} />,
                  value: profileData.user.birthday,
                },
              ]}
              className={classes.detailsCard}
            />
          </div>
          <div>
            <TotalPoints
              title={<FormattedMessage {...translations.TotalPointsLabel} />}
              value={profileData.totalPoints}
            />
          </div>
          <div>
            <BadgeSlider degrees={profileData.degrees} />
          </div>
          <div>
            <TagList
              title={<FormattedMessage {...translations.BrandsTitle} />}
              list={profileData.brands}
            />
          </div>
          <div>
            <TagList
              title={<FormattedMessage {...translations.InterestsTitle} />}
              list={profileData.interests}
            />
          </div>
        </div>
        <div className={classes.rightSide}>
          <div>
            <div className={classes.titleRow}>
              <div className={classes.title}>
                <FormattedMessage {...translations.ActivityTitle} />
              </div>
              <div className={classes.dropdown}>
                <PeriodSelector
                  options={periods}
                  selectOptionHandler={(period) =>
                    SetPeriodFilter(period, props.userId)
                  }
                  selectStartDateHandler={SetStartDateFilter}
                  selectEndDateHandler={SetEndDateFilter}
                  selectedOption={selectedPeriod}
                  startDate={startDate}
                  endDate={endDate}
                  onFilterChange={() => props.GetUserProfileData(props.userId)}
                />
              </div>
            </div>
            <div className={classes.quantityWidgetContainer}>
              <div className={classes.quantityWidget}>
                <QuantityWidget
                  title={<FormattedMessage {...translations.QuestionAsked} />}
                  counter={profileData.statistics.questionAsked}
                />
              </div>
              <div className={classes.quantityWidget}>
                <QuantityWidget
                  title={
                    <FormattedMessage {...translations.QuestionAskedToExpert} />
                  }
                  counter={profileData.statistics.questionAskedToExpert}
                />
              </div>
              <div className={classes.quantityWidget}>
                <QuantityWidget
                  title={<FormattedMessage {...translations.LessonFinished} />}
                  counter={profileData.statistics.lessonFinished}
                />
              </div>
              <div className={classes.quantityWidget}>
                <QuantityWidget
                  title={<FormattedMessage {...translations.LessonStarted} />}
                  counter={profileData.statistics.lessonStarted}
                />
              </div>
            </div>
          </div>
          <div>
            <div className={classes.titleRow}>
              <div className={classes.title}>
                <FormattedMessage {...translations.LearningTitle} />
              </div>
              <div className={classes.dropdown}>
                <ProfileDegreeFilter userId={props.userId} />
              </div>
            </div>
            <div>
              <div className={classes.learning}>
                <Programs programs={profileData.programs} />
              </div>
            </div>
          </div>
          <div>
            <PendingQuestions questions={profileData.questions} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  periods: state.kpi.filters.availablePeriods,
  selectedPeriod: state.kpi.filters.period.selectedValues[0],
  startDate: state.kpi.filters.startDate.selectedValues,
  endDate: state.kpi.filters.endDate.selectedValues,
  learningTypes: state.userProfile.learningTypes,
  filters: state.userProfile.filters,
  profileData: state.userProfile.profileData,
  isLoading: state.userProfile.isLoading,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  GetUserProfileData: (userId) => {
    dispatch(GetUserProfileData(userId));
  },
  SetPeriodFilter: (newPeriod, userId) => {
    dispatch(UpdateFilterSelectedValues("period", [newPeriod]));
    dispatch(GetUserProfileData(userId));
  },
  ResetStore: () => {
    dispatch(ResetStore());
  },
  SetStartDateFilter: (startDate) => {
    dispatch(UpdateFilterSelectedValues("startDate", startDate));
  },
  SetEndDateFilter: (endDate) => {
    dispatch(UpdateFilterSelectedValues("endDate", endDate));
  },
});

UserProfile.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(UserProfile);
