import gql from "graphql-tag";

export const REMOVE_TAG = gql`
  mutation removeTag($battleId: ID, $lang: LangEnum, $tagIds: [ID!]) {
    admin {
      battle(battleId: $battleId, lang: $lang) {
        tags {
          remove(tagIds: $tagIds)
        }
      }
    }
  }
`;
