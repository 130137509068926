import { useEffect } from "react";
import { connect } from "react-redux";
import { FetchCommonData } from "../../store/common/actions";
import { getAccessTokenV2 } from "../../utils/authUtils";

const GetCommonData = ({ isAuthSuccessful, GetCommonData }) => {
  // if user is already authenticated, fetch common data
  useEffect(() => {
    const accessToken = window.localStorage.getItem("access_token");
    let mounted = true;
    const fetchData = async () => {
      if (accessToken) {
        await getAccessTokenV2();
        if (!mounted) return;
        GetCommonData();
      }
    };

    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // if user was noth authenticated, fetch common data after user got authenticated
  useEffect(() => {
    isAuthSuccessful && GetCommonData();
  }, [isAuthSuccessful]);

  return null;
};

const mapStateToProps = (state) => ({
  isAuthSuccessful: state.auth.authenticated,
});

const mapDispatchToProps = (dispatch) => ({
  GetCommonData: () => dispatch(FetchCommonData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GetCommonData);
