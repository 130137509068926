import useThemedStyle from "hooks/style/useThemedStyle";
import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";
import { Icon, Icons } from "genius-ui";

import { NinjaRowStyle } from "./NinjaWidget.style";

const useStyle = createUseStyles(NinjaRowStyle);

const NinjaRow = (props) => {
  const classes = useThemedStyle(useStyle, props);
  const { ninjaId, brand, title, nbParticipants, exportNinja } = props;

  const handleExport = useCallback(() => {
    exportNinja(ninjaId);
  }, [ninjaId, exportNinja]);

  return (
    <div className={classes.container}>
      <span className={classes.left}>
        {brand} - {title}
      </span>
      <span className={classes.right}>{nbParticipants}</span>
      <div className={classes.export}>
        <Icon iconName={Icons.exportExcel} handleClick={handleExport} />
      </div>
    </div>
  );
};

export default NinjaRow;
