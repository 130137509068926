import React, { useState, useEffect } from "react";
import { usePublishInsightStyle } from "../PublishInsight.style";
import { useDispatch, useSelector } from "react-redux";
import { Icons, Icon, Tag, FullscreenLoader } from "genius-ui";
import clientConfig from "../../../configs/client";
import { LANGUAGES } from "../../../configs/referential";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { FormattedMessage, useIntl } from "react-intl";
import { translations } from "../PublishInsight.translation";
import FilterLanguages from "../../../components/FilterLanguages";
import FilterCountries from "../../../components/FilterCountries";
import EditInsightTitle from "../components/editInsightTitle/EditInsightTitle";
import EditInsightSummary from "../components/editInsightSummary/EditInsightSummary";
import EditInsightCover from "../components/editInsightCover/EditInsightCover";
import EditInsightBrandAndRetailer from "../components/editInsightBrandAndRetailer/EditInsightBrandAndRetailer";
import EditInsightAssociatedTags from "../components/editInsightAssociatedTags/EditInsightAssociatedTags";
import EditInsightPublishDate from "../components/editInsightPublishDate/EditInsightPublishDate";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import { useCurrentInsightGeneralInfo } from "../hooks/useCurrentInsightGeneralInfo";
import { SetWarningMessage } from "../../../store/notification/actions";

const selectors = {
  selectedCountries: (state) => state.insights.publishInsight.countries,
  selectedBrands: (state) => state.insights.publishInsight.brands,
};

const PublishInsightShiseido = (props) => {
  const { isScreenReady, isLoaderActive, lang } =
    useCurrentInsightGeneralInfo(props);
  const selectedCountries = useSelector(selectors.selectedCountries);
  const selectedBrands = useSelector(selectors.selectedBrands);
  const dispatch = useDispatch();
  const classes = useThemedStyle(usePublishInsightStyle, props);
  const intl = useIntl();
  const [classNameError, setClassNameError] = useState(null);

  const currentLang = lang || clientConfig.DEFAULT_LANGUAGE_CODE;

  useEffect(() => {
    if (selectedBrands.length > 0) setClassNameError(null);
  }, [selectedBrands]);

  if (!isScreenReady || isLoaderActive) {
    return <FullscreenLoader />;
  }

  const handleCkeckBrands = () => !!selectedBrands.length;

  const handleAlertBrands = () => {
    dispatch(
      SetWarningMessage(intl.formatMessage(translations.IndicateBrandsWarning)),
    );
    setClassNameError(classes.tagSelectContainerError);
  };

  return (
    <div className={classes.rootContainer}>
      <div className={classes.center}>
        <div className={classes.left}>
          <EditInsightTitle />
          <br />
          <EditInsightSummary />
          <br />
          <div className={classes.detailsContainer}>
            <div className={classes.leftPart}>
              <EditInsightCover />
            </div>
            <div className={classes.rightPart}>
              <div className={classes.langPublicContainer}>
                <div className={classes.firstTitle}>
                  <FormattedMessage {...translations.LanguageLabel} />
                </div>
              </div>
              <div className={classes.tagList}>
                <FilterLanguages languages={LANGUAGES}>
                  {(filteredLanguages) => {
                    const languages = [
                      filteredLanguages
                        .map((x) => ({
                          Id: x.Code,
                          Value: x.Name,
                        }))
                        .find((x) => x.Id === currentLang),
                    ];

                    return languages.map((x) => (
                      <Tag key={x.Value} label={x.Value} readOnly />
                    ));
                  }}
                </FilterLanguages>
              </div>
              <span className={classes.sectionTitle}>
                <FormattedMessage {...translations.PublicationCountriesLabel} />
              </span>
              <div className={classes.tagList}>
                <FilterCountries countries={selectedCountries}>
                  {({ filteredCountries }) =>
                    filteredCountries.map((x) => (
                      <Tag key={x.Value} label={x.Value} readOnly />
                    ))
                  }
                </FilterCountries>
              </div>
              <EditInsightBrandAndRetailer
                classNameError={classNameError}
                metaTagFilter
              />
              <span className={classes.sectionTitle}>
                <FormattedMessage {...translations.AssociatedTagsLabel} />
              </span>
              <EditInsightAssociatedTags />
              <EditInsightPublishDate
                checkBrands={handleCkeckBrands}
                alertBrands={handleAlertBrands}
              />
            </div>
          </div>
        </div>
        <div className={classes.right} onClick={() => props.history.goBack()}>
          <Icon iconName={Icons.slimCloseIcon} style={classes.closeIcon} />
        </div>
      </div>
    </div>
  );
};

export default PublishInsightShiseido;
