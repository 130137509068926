import { defineMessages } from "react-intl";

export const translations = defineMessages({
  ApplicationTitle: {
    id: "ShiseidoGeneralDetails.ApplicationTitle",
    defaultMessage: "APPLICATION",
  },
  LearningTitle: {
    id: "ShiseidoGeneralDetails.LearningTitle",
    defaultMessage: "LEARNING",
  },
  FeedTitle: {
    id: "ShiseidoGeneralDetails.FeedTitle",
    defaultMessage: "FEED",
  },
  AskTitle: {
    id: "ShiseidoGeneralDetails.AskTitle",
    defaultMessage: "ASK",
  },
  ActiveUsersTitle: {
    id: "ShiseidoGeneralDetails.ActiveUsersTitle",
    defaultMessage: "Active users",
  },
  ActiveNumberOfConnectionTitle: {
    id: "ShiseidoGeneralDetails.ActiveNumberOfConnectionTitle",
    defaultMessage: "Active number of connection by user",
  },
  AverageTimeSpentTitle: {
    id: "ShiseidoGeneralDetails.AverageTimeSpentTitle",
    defaultMessage: "Average time spent on the app per day",
  },
  MinLabel: {
    id: "ShiseidoGeneralDetails.MinLabel",
    defaultMessage: "min",
  },
  ProgramsTitle: {
    id: "ShiseidoGeneralDetails.ProgramsTitle",
    defaultMessage: "Programs",
  },
  LessonTitle: {
    id: "ShiseidoGeneralDetails.LessonTitle",
    defaultMessage: "Lessons",
  },
  TotalContentNumberTitle: {
    id: "ShiseidoGeneralDetails.TotalContentNumberTitle",
    defaultMessage: "Total number of content",
  },
  InsightsLabel: {
    id: "ShiseidoGeneralDetails.InsightsLabel",
    defaultMessage: "Insights",
  },
  LessonsLabel: {
    id: "ShiseidoGeneralDetails.LessonsLabel",
    defaultMessage: "Lessons",
  },
  SocialMediaLabel: {
    id: "ShiseidoGeneralDetails.SocialMediaLabel",
    defaultMessage: "Social Media",
  },
  SlidersLabel: {
    id: "ShiseidoGeneralDetails.SlidersLabel",
    defaultMessage: "Sliders",
  },
  QuestionAskedGeniusTitle: {
    id: "ShiseidoGeneralDetails.QuestionAskedGeniusTitle",
    defaultMessage: "Question asked to Genius",
  },
  QuestionAskedExpertsTitle: {
    id: "ShiseidoGeneralDetails.QuestionAskedExpertsTitle",
    defaultMessage: "Question asked to Experts",
  },
  AnsweredQuestionsExpertsTitle: {
    id: "ShiseidoGeneralDetails.AnsweredQuestionsExpertsTitle",
    defaultMessage: "Answered Questions by Experts",
  },
  PendingQuestionsExpertsTitle: {
    id: "ShiseidoGeneralDetails.PendingQuestionsExpertsTitle",
    defaultMessage: "Pending Questions by Experts",
  },
  ColumnTooltipContent: {
    id: "ShiseidoGeneralDetails.ColumnTooltipContent",
    defaultMessage:
      "Number of content published in the feed & percentage of the total feed content it represents",
  },
});
