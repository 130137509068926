const contentEditablePlaceholderStyle = {
  "&[contenteditable]:empty:before": {
    content: "attr(placeholder)",
    display: "block",
    color: "#fff",
  },
};

const cursorPointer = {
  cursor: (props) => (props.isSelected ? "pointer" : "default"),
};

export const ActivityIntroComponentStyles = (theme) => ({
  introContainer: {
    width: 414,
    height: 737,
    backgroundImage: (props) =>
      props.image && props.image !== ""
        ? `linear-gradient(to bottom, rgba(42, 42, 42, 0) 50%, rgba(42, 42, 42, 0.4)), url("${props.image}")`
        : `linear-gradient(to bottom, rgba(42, 42, 42, 0) 50%, rgba(42, 42, 42, 0.4)), url("${props.defaultImage}")`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  imageHandlerContainer: {
    backgroundImage: `linear-gradient(to bottom, ${theme.ActivityIntroComponentStyle.imageHandlerContainerBackgroundImageFirst}, ${theme.ActivityIntroComponentStyle.imageHandlerContainerBackgroundImageSecond})`,
    display: "flex",
    flexDirection: "row",
    color: theme.ActivityIntroComponentStyle.imageHandlerContainerColor,
    alignItems: "center",
    paddingLeft: 18,
    height: 53,
  },
  actionIcon: {
    marginRight: 5.5,
    "&:before": {
      color: theme.ActivityIntroComponentStyle.actionIconBeforeColor,
      fontSize: 18,
    },
  },
  actionLabel: {
    fontFamily: theme.Fonts.baseFontFamily,
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: 0.07,
  },
  imageActionContainer: {
    ...cursorPointer,
    "&:hover": {
      color: theme.ActivityIntroComponentStyle.imageActionContainerHoverColor,
      "& > span:before": {
        color:
          theme.ActivityIntroComponentStyle
            .imageActionContainerHoverSpanBeforeColor,
      },
    },
  },
  themeInput: {
    minHeight: 50,
    maxHeight: 300,
    overflow: "hidden",
    fontFamily: theme.Fonts.baseFontFamily,
    fontSize: 17.5,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    color: theme.ActivityIntroComponentStyle.themeInputColor,
    ...contentEditablePlaceholderStyle,
    ...cursorPointer,
  },
  titleInput: {
    minHeight: 50,
    maxHeight: 100,
    overflow: "hidden",
    fontFamily: theme.Fonts.baseFontFamily,
    fontSize: 44,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    color: theme.ActivityIntroComponentStyle.titleInputColor,
    ...contentEditablePlaceholderStyle,
    ...cursorPointer,
  },
  descriptionInput: {
    marginTop: 10,
    minHeight: 25,
    maxHeight: 100,
    marginBottom: 30,
    overflow: "hidden",
    fontFamily: theme.Fonts.baseFontFamily,
    fontSize: 18,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    color: theme.ActivityIntroComponentStyle.descriptionInputColor,
    ...contentEditablePlaceholderStyle,
    ...cursorPointer,
  },
  descriptionContainer: {
    padding: "45px 30px 0 30px",
    color: theme.ActivityIntroComponentStyle.descriptionContainerColor,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  titleAndDescriptionContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  uploadBtnWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
    },
  },
  btnUpload: {
    border: "none",
    fontFamily: theme.Fonts.baseFontFamily,
    fontSize: 14,
    background: "transparent",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    color: theme.ActivityIntroComponentStyle.btnUploadColor,
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
    },
    paddingLeft: 15,
    marginRight: 10,
    "& > span": {
      paddingRight: 5,
      "&:hover::before": {
        color: theme.ActivityIntroComponentStyle.lightBlue,
        cursor: "pointer",
      },
    },
    "&:hover *": {
      color: theme.ActivityIntroComponentStyle.lightBlue,
      cursor: "pointer",
      "&::before": {
        color: theme.ActivityIntroComponentStyle.lightBlue,
        cursor: "pointer",
      },
    },
    outline: "none",
  },
  iconAction: {
    cursor: "pointer",
    "&:hover::before": {
      color: `${theme.ActivityIntroComponentStyle.lightBlue}`,
      fontSize: 15,
      cursor: "pointer",
    },
    "&::before": {
      color: `${theme.ActivityIntroComponentStyle.white}`,
      fontSize: 15,
      cursor: "pointer",
    },
  },
});
