import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CheckDropdown } from "genius-ui";

const KPIDropDownFilter = ({
  label,
  allValues,
  selectedValues,
  onChange,
  getAvailableValues,
  className,
}) => {
  useEffect(() => {
    getAvailableValues();
  }, []);

  return (
    <div className={className}>
      <CheckDropdown
        onChangeHandler={onChange}
        options={allValues}
        selectedValues={selectedValues}
        label={label}
      />
    </div>
  );
};

KPIDropDownFilter.propTypes = {
  label: PropTypes.node,
  /** Function that should return the filter object which stores the available and selected values of the degree filter.
   * It should return an object with the keys: availableValues, selectedValues **/
  extractFilterObjectFromState: PropTypes.func.isRequired,
  /** Function called when the selected value is changed */
  onChange: PropTypes.func.isRequired,
  /** Function called to fetch the available values for the degree filter **/
  getAvailableValues: PropTypes.func.isRequired,
  /** class name for custom styles **/
  className: PropTypes.string,
};

const mapStateToProps = (state, ownProps) =>
  ownProps.extractFilterObjectFromState(state);

export default connect(mapStateToProps)(KPIDropDownFilter);
