import gql from "graphql-tag";

export const GET_BATTLE_RESULT = gql`
  query GetBattleResult(
    $intervalId: Int
    $battleId: ID
    $sortBestScore: SortDirectionEnum
    $sortTotalScore: SortDirectionEnum
    $sortAverageScore: SortDirectionEnum
    $sortNumberOfAttempts: SortDirectionEnum
    $startDate: String
    $endDate: String
  ) {
    admin {
      battles(
        intervalId: $intervalId
        battleId: $battleId
        sortBestScore: $sortBestScore
        sortTotalScore: $sortTotalScore
        sortAverageScore: $sortAverageScore
        sortNumberOfAttempts: $sortNumberOfAttempts
        startDate: $startDate
        endDate: $endDate
      ) {
        battleId
        versions {
          tags {
            tagId
            clusterId
            title
          }
          updateDate
          picCover
          workflow
          title
        }
        gameTemplate {
          docId
          title
          coverPicURL
          maxScore
          participantsNumber
          averageScore
          totalScore
          leaderboard {
            user {
              userId
              profile {
                pic
                firstName
                lastName
                position
              }
            }
            totalScore
            averageScore
            bestScore
            numberOfAttempts
          }
        }
      }
    }
  }
`;
