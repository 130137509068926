import gql from "graphql-tag";

export const INSIGHT_REMOVE_TAGS = gql`
  mutation insightRemoveTags(
    $insightId: ID
    $lang: LangEnum
    $tagIdList: [ID!]
  ) {
    admin {
      insight(insightId: $insightId, lang: $lang) {
        tags {
          remove(tagIds: $tagIdList)
        }
      }
    }
  }
`;
