import { defineMessages } from "react-intl";

export const translations = defineMessages({
  TranslateOption: {
    id: "DocListViewMarket.TranslateOption",
    defaultMessage: "Translate",
  },
  DeleteOption: {
    id: "DocListViewMarket.DeleteOption",
    defaultMessage: "Delete",
  },
  SeeMoreButtonDefaultTitle: {
    id: "DocListViewMarket.SeeMoreButtonDefaultTitle",
    defaultMessage: "Load more",
  },
  NameHeaderTitle: {
    id: "DocListViewMarket.NameHeaderTitle",
    defaultMessage: "Name",
  },
  TypeHeaderTitle: {
    id: "DocListViewMarket.TypeHeaderTitle",
    defaultMessage: "Type",
  },
  DateHeaderTitle: {
    id: "DocListViewMarket.DateHeaderTitle",
    defaultMessage: "Date",
  },
  StatusHeaderTitle: {
    id: "DocListViewMarket.StatusHeaderTitle",
    defaultMessage: "Status",
  },
  SendNotificationOption: {
    id: "DocListViewMarket.SendNotificationOption",
    defaultMessage: "Send Notification",
  },
  PublishOption: {
    id: "DocListViewMarket.PublishOption",
    defaultMessage: "Publication settings",
  },
  SeeResultOption: {
    id: "DocListViewMarket.SeeResultOption",
    defaultMessage: "See result",
  },
  NotScheduled: {
    id: "DocListViewMarket.NotScheduled",
    defaultMessage: "Not scheduled",
  },
});
