import * as constants from "../../constants/store/editJournalistProfile";

const initialState = {
  jobTitleOptions: [],
  mediaOptions: [],
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.UPDATE_ALL_DATA:
      return {
        ...state,
        ...data,
      };
    case constants.RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
