import gql from "graphql-tag";

export const GET_USERS_BASED_ON_LESSONS = gql`
  query lessonKPI(
    $lessonId: Int
    $fromDate: String
    $toDate: String
    $tags: [kpiTagsType!]
  ) {
    lessonKPI(
      lessonId: $lessonId
      fromDate: $fromDate
      toDate: $toDate
      tags: $tags
    ) {
      users {
        id
        name
        email
        market
        retailer
        relationship
        completionDate
      }
    }
  }
`;
