import { combineReducers } from "redux";
import filters from "../kpiFilter/reducers";
import generalDetails from "../kpiGeneralDetails/reducers";
import learningDetails from "../kpiLearningDetails/reducers";
import applicationDetails from "../kpiApplicationDetails/reducers";
import feedDetails from "../kpiFeedDetails/reducers";
import askDetails from "../kpiAskDetails/reducers";
import appAnalytics from "../kpiAppAnalytics/reducers";

export default combineReducers({
  filters,
  generalDetails,
  learningDetails,
  applicationDetails,
  feedDetails,
  askDetails,
  appAnalytics,
});
