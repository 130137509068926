import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import _ from "lodash";
import useFilterPaths from "../../hooks/accessControl/useFilterPaths";
import NotFound from "../../pages/notFound/NotFound";

const Router = ({ routes }) => {
  const { filteredPaths: filteredRoutes } = useFilterPaths(
    Array.isArray(routes) ? routes : [],
  );

  return (
    <Switch>
      {_.map(
        Array.isArray(routes) ? filteredRoutes : routes,
        ({ component: Component, ...rest }) => {
          const key = `page-${rest.path}`;
          return rest.isPrivate ? (
            <PrivateRoute key={key} component={Component} {...rest} />
          ) : (
            <Route
              key={key}
              {...rest}
              render={(props) => <Component {...props} {...rest} />}
            />
          );
        },
      )}
      <Route component={NotFound} />
    </Switch>
  );
};

export default Router;
