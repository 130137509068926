import React, { useState } from "react";
import { connect } from "react-redux";
import {
  GetPageData,
  OrderAllLessonsByUsersCompletion,
  OrderLessonsByUsersCompletion,
  OrderProgramsByLessonsCompletion,
} from "../../store/kpiLearningDetails/actions";
import { FormattedMessage, injectIntl } from "react-intl";
import ProgramsWidgetShiseido from "../../components/KPI/ProgramsWidgetShiseido";
import LessonsWidgetShiseido from "../../components/KPI/LessonsWidgetShiseido";
import {
  KPIPageContainer,
  WidgetSection,
  WidgetContainer,
} from "../../components/KPI";
import QuantityWidget from "../../components/KPI/QuantityWidget";
import DataNotAvailable from "../../components/KPI/DataNotAvailable";
import {
  exportLessons,
  exportProgramsWithLessons,
  exportSelectedLesson,
} from "../../utils/excelExport/kpi/learning";
import { translations } from "./KpiLearningShiseido.translations";
import { createUseStyles } from "react-jss";
import style from "./KpiLearningShiseido.style";
import { Checkbox } from "genius-ui";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const KpiLearningShiseido = (props) => {
  const {
    programsNb,
    lessonsNb,
    programs,
    GetPageData,
    ExportProgramsWithLessons,
    OrderProgramsByLessonsCompletion,
    OrderLessonsByUsersCompletion,
    allLessons,
    OrderAllLessonsByUsersCompletion,
    ExportLessons,
    ExportSelectedLesson,
    intl,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  const [groupLessonByProgram, setGroupLessonByProgram] = useState(false);
  const [orderProgramDesc, setProgramOrderDirection] = useState(true);
  const [orderLessonDesc, setLessonOrderDirection] = useState(true);

  const changeTableView = () => setGroupLessonByProgram(!groupLessonByProgram);

  const sortProgramsByLessonCompletion = () => {
    OrderProgramsByLessonsCompletion(!orderProgramDesc);
    setProgramOrderDirection(!orderProgramDesc);
  };

  const sortProgramLessonsByUserCompletion = (order, programId) => {
    OrderLessonsByUsersCompletion(!order, programId);
  };

  const sortAllLessonByUserCompletion = () => {
    OrderAllLessonsByUsersCompletion(!orderLessonDesc);
    setLessonOrderDirection(!orderLessonDesc);
  };

  const exportLesson = (lesson) => ExportSelectedLesson(lesson, intl);

  const table = groupLessonByProgram ? (
    <ProgramsWidgetShiseido
      programs={programs}
      sortByLessonCompletion={sortProgramsByLessonCompletion}
      sortByUserCompletion={sortProgramLessonsByUserCompletion}
      exportLesson={exportLesson}
    />
  ) : (
    <LessonsWidgetShiseido
      allLessons={allLessons}
      exportLesson={exportLesson}
      sortAllLessonByUserCompletion={sortAllLessonByUserCompletion}
    />
  );

  const globalExport = (filters, intl) =>
    groupLessonByProgram
      ? ExportProgramsWithLessons(programs, intl)
      : ExportLessons(allLessons[0].lessons, intl);

  return (
    <div className={classes.container}>
      <KPIPageContainer
        onFilterChange={GetPageData}
        exportFunction={globalExport}
      >
        <WidgetSection width={50}>
          <FormattedMessage {...translations.ProgramsLabel}>
            {(message) => (
              <WidgetContainer width={50}>
                <QuantityWidget title={message[0]} counter={programsNb} />
              </WidgetContainer>
            )}
          </FormattedMessage>
          <FormattedMessage {...translations.LessonsLabel}>
            {(message) => (
              <WidgetContainer width={50}>
                <QuantityWidget
                  title={message[0]}
                  counter={lessonsNb}
                  info={translations.InfosLabel}
                />
              </WidgetContainer>
            )}
          </FormattedMessage>
        </WidgetSection>
        <WidgetSection>
          <div className={classes.viewSwitcherCheckbox}>
            <Checkbox
              label={<FormattedMessage {...translations.ViewSwitcherLabel} />}
              handleChange={changeTableView}
              checked={groupLessonByProgram}
            />
          </div>
          <WidgetContainer width={100} isMarginRightEnabled={false}>
            {programs && programs.length > 0 ? table : <DataNotAvailable />}
          </WidgetContainer>
        </WidgetSection>
      </KPIPageContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  programsNb: state.kpi.learningDetails.programsNb,
  lessonsNb: state.kpi.learningDetails.lessonsNb,
  programs: state.kpi.learningDetails.programs,
  allLessons: state.kpi.learningDetails.allLessons,
  degreeFilter: state.kpi.learningDetails.filters.degree,
  isExportLoading: state.kpi.filters.isExportLoading,
  isFetching: state.common.fetchIndicator,
});

const mapDispatchToProps = (dispatch) => ({
  GetPageData: () => dispatch(GetPageData()),
  ExportProgramsWithLessons: (programs, intl) => {
    exportProgramsWithLessons(programs, intl);
  },
  OrderProgramsByLessonsCompletion: (orderDesc) => {
    dispatch(OrderProgramsByLessonsCompletion(orderDesc));
  },
  OrderLessonsByUsersCompletion: (orderDesc, programId) => {
    dispatch(OrderLessonsByUsersCompletion(orderDesc, programId));
  },
  OrderAllLessonsByUsersCompletion: (orderDesc) => {
    dispatch(OrderAllLessonsByUsersCompletion(orderDesc));
  },
  ExportLessons: (lessons, intl) => {
    exportLessons(lessons, intl);
  },
  ExportSelectedLesson: (lesson, intl) => {
    dispatch(exportSelectedLesson(lesson, intl));
  },
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(KpiLearningShiseido),
);
