import gql from "graphql-tag";

export const DELETE_LESSON_RECAP = gql`
  mutation deleteLessonRecap($lessonId: ID, $lang: LangEnum) {
    admin {
      lesson(lessonId: $lessonId, lang: $lang) {
        delete(type: lessonRecap)
      }
    }
  }
`;
