import { defineMessages } from "react-intl";

export const translations = defineMessages({
  DragAndDropText: {
    id: "UploadPdf.dragAndDrop",
    defaultMessage: "Drag and Drop document",
  },
  OrText: {
    id: "UploadPdf.Or",
    defaultMessage: "Or",
  },
  ImportText: {
    id: "UploadPdf.import",
    defaultMessage: "Import Document",
  },
  VideoUpload: {
    id: "UploadPdf.VideoUpload",
    defaultMessage: "Video upload",
  },
  Import: {
    id: "UploadPdf.Import",
    defaultMessage: "Import",
  },
  Optimising: {
    id: "UploadPdf.Optimising",
    defaultMessage: "Improve video quality",
  },
  OptimisingFile: {
    id: "UploadPdf.OptimisingFile",
    defaultMessage: "Optimising",
  },
  VideoConversion: {
    id: "UploadPdf.VideoConversion",
    defaultMessage: "Video conversion",
  },
  Compression: {
    id: "UploadPdf.Compression",
    defaultMessage: "Compression",
  },
});
