import * as constants from "../../constants/store/kpiBattle";
import client from "../../graphql/client";
import { GET_GLOBAL_BATTLE_LEADER_BOARD_DATA } from "../../graphql/remote/kpi/queries/battleGlobal.graphql";
import {
  GetFormattedGlobalBattleData,
  GetFormattedPublishedBattleData,
} from "./transformers";
import { GET_PUBLISHED_BATTLES } from "../../graphql/remote/battle/queries/GetPublishedBattleIds.graphql";
import { SetErrorMessage } from "../notification/actions";
import { extractFilterValues } from "../actions/common/kpi";
import { getFormattedSopiDate } from "utils/dateUtils";

export const setLoadingStatus =
  (isFetching, isFullLoaded, skip) => (dispatch) => {
    dispatch({
      type: constants.LOADING,
      data: {
        isFetching,
        isFullLoaded,
        skip,
      },
    });
  };

export const UpdateKPIBattleResults = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_BATTLE_RESULTS,
    data,
  });
};

export const BackupBattleResults = (data) => (dispatch) => {
  dispatch({
    type: constants.BACKUP_RESULTS,
    data,
  });
};

export const UpdatePublishedBattlesList = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_PUBLISHED_BATTLE_LIST,
    data,
  });
};

export const ResetStore = () => (dispatch) => {
  dispatch({ type: constants.RESET_STORE });
};

export const getFilterTagIds = (filters) => {
  const { market, relationship, retailer, brands } =
    extractFilterValues(filters);
  return [
    ...market.tagIds,
    ...relationship.tagIds,
    ...retailer.tagIds,
    ...brands.tagIds,
  ];
};

export const extendWithGlobalSortVariables = (
  variables,
  leaderBoardColumnSort,
) => {
  const columnName = leaderBoardColumnSort
    ? Object.keys(leaderBoardColumnSort)[0]
    : null;
  const sortMethod =
    columnName && leaderBoardColumnSort[columnName]
      ? leaderBoardColumnSort[columnName].toLowerCase()
      : null;
  if (!sortMethod || !columnName) return variables;
  let sortVariableName = "";
  switch (columnName) {
    case "averageScore":
      sortVariableName = "sortAverageScore";
      break;
    case "highestScore":
      sortVariableName = "sortBestScore";
      break;
    case "attempts":
      sortVariableName = "sortNumberOfAttempts";
      break;
    case "totalScore":
      sortVariableName = "sortTotalScore";
      break;
    default:
      return variables;
  }
  return {
    ...variables,
    [sortVariableName]: sortMethod,
  };
};

export const extendWithPublishedBattleSortVariables = (
  variables,
  publishedBattlesColumnSort,
) => {
  const columnName = publishedBattlesColumnSort
    ? Object.keys(publishedBattlesColumnSort)[0]
    : null;
  const sortMethod =
    columnName && publishedBattlesColumnSort[columnName]
      ? publishedBattlesColumnSort[columnName].toLowerCase()
      : null;
  if (!sortMethod || !columnName) return variables;
  let sortVariableName = "";
  switch (columnName) {
    case "averageNumberOfPoints":
      sortVariableName = "sortAverageScore";
      break;
    case "numberOfParticipatedUsers":
      sortVariableName = "sortParticipantsNumber";
      break;
    default:
      return variables;
  }
  return {
    ...variables,
    [sortVariableName]: sortMethod,
  };
};

export const UpdateLeaderBoardColumnSort =
  (columnName, sortMethod) => (dispatch) =>
    dispatch({
      type: constants.UPDATE_LEADER_BOARD_COLUMN_SORT,
      data: { [columnName]: sortMethod },
    });

export const UpdatePublishedBattleColumnSort =
  (columnName, sortMethod) => (dispatch) =>
    dispatch({
      type: constants.UPDATE_PUBLISHED_BATTLE_COLUMN_SORT,
      data: { [columnName]: sortMethod },
    });

export const GetKPIBattleData = (callback) => async (dispatch, getState) => {
  try {
    const { globalBattleLimit, globalBattleSkip, leaderBoardColumnSort } =
      getState().kpiBattle;
    const { filters } = getState().kpi;
    let variables = {
      tagIds: getFilterTagIds(filters),
      skip: globalBattleSkip,
      limit: globalBattleLimit,
      intervalId: filters.interval.selectedValues[0]?.value,
    };
    if (!filters.interval.selectedValues[0]) {
      variables.startDate = getFormattedSopiDate(
        filters.startDate.selectedValues,
      );
      variables.endDate = getFormattedSopiDate(filters.endDate.selectedValues);
    }
    variables = extendWithGlobalSortVariables(variables, leaderBoardColumnSort);
    const response = await client.query({
      fetchPolicy: "network-only",
      query: GET_GLOBAL_BATTLE_LEADER_BOARD_DATA,
      variables,
      notifyOnNetworkStatusChange: true,
    });
    const formattedData = GetFormattedGlobalBattleData(
      response.data.admin.globalLeaderboard,
    );
    dispatch(UpdateKPIBattleResults(formattedData));
    dispatch(BackupBattleResults(formattedData));
    callback && callback();
  } catch (e) {
    console.error("e", e);
    dispatch(
      SetErrorMessage("An error occurred while fetching kpi battle data", e),
    );
  }
};

export const GetKPIBattlePublished =
  (callback) => async (dispatch, getState) => {
    try {
      if (getState().kpiBattle.publishedBattlesLoadingStatus.isFetching) return;
      dispatch(setLoadingStatus(true, false, 0));

      const {
        publishedBattleLimit,
        publishedBattlesColumnSort,
        publishedBattlesLoadingStatus,
      } = getState().kpiBattle;
      const { filters } = getState().kpi;
      const variables = {
        tagIds: getFilterTagIds(filters),
        skip: publishedBattlesLoadingStatus.skip,
        limit: publishedBattleLimit,
        intervalId: filters.interval.selectedValues[0]?.value,
        ...extendWithPublishedBattleSortVariables(
          {},
          publishedBattlesColumnSort,
        ),
      };
      if (!filters.interval.selectedValues[0]) {
        variables.startDate = getFormattedSopiDate(
          filters.startDate.selectedValues,
        );
        variables.endDate = getFormattedSopiDate(
          filters.endDate.selectedValues,
        );
      }
      const response = await client.query({
        fetchPolicy: "network-only",
        query: GET_PUBLISHED_BATTLES,
        variables,
        notifyOnNetworkStatusChange: true,
      });

      const publishedBattleData = GetFormattedPublishedBattleData(
        response.data.admin.battles,
      );
      dispatch(UpdatePublishedBattlesList(publishedBattleData));
      callback && callback();
      dispatch(
        setLoadingStatus(
          false,
          publishedBattleData.length < publishedBattleLimit,
          publishedBattleLimit,
        ),
      );
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while fetching kpi published battle data",
          e,
        ),
      );
    }
  };

export const ClearKPIBattlePublished = (callback) => async (dispatch) => {
  dispatch({ type: constants.CLEAR_PUBLISHED_BATTLE });
  callback && callback();
};

export const UpdateGlobalBattleLeaderBoardDataLimit = (limit) => (dispatch) =>
  dispatch({
    type: constants.UPDATE_GLOBAL_BATTLE_LIMIT,
    data: limit,
  });

export const SearchUserOnBattleLeaderBoard = (text) => (dispatch, getState) => {
  const { ogResults: usersOriginal } = getState().kpiBattle;
  const users = text
    ? usersOriginal.filter((user) =>
        user.userName.toLowerCase().includes(text.toLowerCase()),
      )
    : usersOriginal;
  dispatch(UpdateKPIBattleResults(users));
};
