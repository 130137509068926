import gql from "graphql-tag";

export const SEND_NOTIFICATION = gql`
  mutation AddNotification(
    $docId: ID
    $lang: LangEnum!
    $tagIds: [ID!]
    $userIds: [ID!]
    $message: String
    $send: Boolean!
  ) {
    admin {
      notifications(
        userIds: $userIds
        tagIds: $tagIds
        lang: $lang
        message: $message
        send: $send
        docId: $docId
      ) {
        message
      }
    }
  }
`;
