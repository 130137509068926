import React from "react";
import SliderStyle from "../VerticalSlider.style";
import VerticalReadOnlyStyle from "./verticalReadOnly.style";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Icons, Icon } from "genius-ui";
import { DefaultVSlider1, DefaultVideo, DefaultVideoCover } from "assets/icons";
import Mask from "../../../../common/Mask";
import ContentEditable from "react-contenteditable";
import { FILE_TYPES, VIDEO_FORMAT } from "../../../../../configs/constants";
import SliderVideoPlayer from "../../../SliderVideoPlayer";
import { translations } from "../Vertical.translations";
import withThemedStyle from "../../../../hoc/withThemedStyle";

class VerticalSlider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      image: "",
      video: {
        videoSource: DefaultVideo,
        posterCover: DefaultVideoCover,
        formatType: VIDEO_FORMAT.PORTRAIT,
      },
      fileType: FILE_TYPES.IMAGE,
    };
  }

  componentDidMount() {
    this.setState({
      title: this.props.title,
      description: this.props.description,
      image: this.props.image,
      fileType: this.props.fileType,
      video:
        this.props.video !== null && this.props.video
          ? this.props.video
          : {
              videoSource: DefaultVideo,
              posterCover: DefaultVideoCover,
              formatType: VIDEO_FORMAT.PORTRAIT,
            },
    });
  }

  render() {
    const props = this.props;
    const { classes, isFirst } = props;

    return (
      <div className={classes.sliderContainer} onClick={props.onClick}>
        <div className={classes.backgroundSlider} />

        {!props.image && <Mask />}

        {this.state.fileType === FILE_TYPES.VIDEO ? (
          <SliderVideoPlayer
            source={this.state.video.videoSource}
            poster={
              this.state.video.posterCover
                ? this.state.video.posterCover
                : DefaultVideoCover
            }
            onClick={this.props.onClick}
            formatType={
              this.state.video.formatType
                ? this.state.video.formatType
                : VIDEO_FORMAT.PORTRAIT
            }
            isConverting={this.state.isConverting}
            isUploading={this.state.isUploading}
            progress={
              this.state.isConverting
                ? this.state.currentConversionProgress
                : this.state.currentUploadProgress
            }
            isActive={props.isSelected}
          />
        ) : (
          ""
        )}

        <div className={classes.textEditing}>
          <ContentEditable
            key="title"
            html={this.state.title ? this.state.title : ""}
            tagName="article"
            className={props.classes.titleInput}
            disabled
          />
          <br />
          <ContentEditable
            key="description"
            html={this.state.description ? this.state.description : ""}
            disabled
            tagName="article"
            className={props.classes.descriptionInput}
          />
        </div>

        {isFirst && (
          <div className={classes.scrollDownIndicator}>
            <FormattedMessage {...translations.ScrollDown} />
            <span className={classes.scrollDownIcon}>
              <Icon iconName={Icons.dropdownIndicator} />
            </span>
          </div>
        )}
      </div>
    );
  }
}

VerticalSlider.propTypes = {
  /** unique id of the slide **/
  slideId: PropTypes.string.isRequired,
  /** The current selected image */
  image: PropTypes.string,
  /** Slide type **/
  type: PropTypes.string,
  /** title value */
  title: PropTypes.string,
  /** description */
  description: PropTypes.string,
  /** Label for the chnage image selection button */
  buttonLabel: PropTypes.string,
  /** is selected */
  isSelected: PropTypes.bool,
  /** on click */
  onClick: PropTypes.func,
  /** default sliderImage */
  defaultSliderImage: PropTypes.string,
  /** if it is the first slide, we display the scroll down indicator **/
  isFirst: PropTypes.bool,
};

VerticalSlider.defaultProps = {
  defaultSliderImage: DefaultVSlider1,
  isFirst: false,
};

export default withThemedStyle(
  SliderStyle,
  VerticalReadOnlyStyle,
)(VerticalSlider);
