import gql from "graphql-tag";

export const DEGREE_REMOVE_PRIVACY = gql`
  mutation degreeRemovePrivacy($degreeId: ID, $lang: LangEnum) {
    admin {
      degree(degreeId: $degreeId, lang: $lang) {
        privacy {
          remove
        }
      }
    }
  }
`;
