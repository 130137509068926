import gql from "graphql-tag";

export const DEGREE_GET_TAG_IDS = gql`
  query degreeGetTagIds($degreeId: ID) {
    degrees(degreeId: $degreeId) {
      degreeId
      tags(isGranted: true) {
        tagId
      }
    }
  }
`;
