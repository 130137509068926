import { defineMessages } from "react-intl";

export const defaultFilterHeaderTranslations = defineMessages({
  ZoneFilterLabel: {
    id: "KPIFilterHeader.ZoneFilterLabel",
    defaultMessage: "Zone",
  },
  MarketFilterLabel: {
    id: "KPIFilterHeader.MarketFilterLabel",
    defaultMessage: "Market",
  },
  RelationshipFilterLabel: {
    id: "KPIFilterHeader.RelationshipFilterLabel",
    defaultMessage: "Relationship",
  },
  RetailerFilterLabel: {
    id: "KPIFilterHeader.RetailerFilterLabel",
    defaultMessage: "Retailer",
  },
  ClearAllLabel: {
    id: "KPIFilterHeader.ClearAllLabel",
    defaultMessage: "Clear all",
  },
});

export const shiseidoFilterHeaderTranslations = defineMessages({
  RelationshipFilterLabel: {
    id: "KPIShiseidoFilterHeader.RelationshipFilterLabel",
    defaultMessage: "Relationship",
  },
  RetailerFilterLabel: {
    id: "KPIShiseidoFilterHeader.RetailerFilterLabel",
    defaultMessage: "Retailer",
  },
  ClearAllLabel: {
    id: "KPIShiseidoFilterHeader.ClearAllLabel",
    defaultMessage: "Clear all",
  },
  CountryFilterLabel: {
    id: "KPIShiseidoFilterHeader.CountryFilterLabel",
    defaultMessage: "Country",
  },
  BrandFilterLabel: {
    id: "KPIShiseidoFilterHeader.BrandFilterLabel",
    defaultMessage: "Brand",
  },
  MarketFilterLabel: {
    id: "KPIShiseidoFilterHeader.MarketFilterLabel",
    defaultMessage: "Market",
  },
  SearchAUserInputLabel: {
    id: "KPIFilterHeader.SearchAUserInputLabel",
    defaultMessage: "Search a user",
  },
  ApplyFilters: {
    id: "KPIFilterHeader.ApplyFilters",
    defaultMessage: "Apply Filters",
  },
});
