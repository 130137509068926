import { SET_NINJAS } from "constants/store/ninja";
import { GET_NINJAS_KPI } from "graphql/remote/ninja/queries/GetNinjas.graphql";
import { SetErrorMessage } from "store/notification/actions";
import client from "../../graphql/client";

const getTagId = (value) => value.value.split("-")[0];

const getTagIds = (item) => {
  if (!item?.selectedValues) return;
  return item.selectedValues.map((value) => getTagId(value));
};

export const GetAllNinjas = () => async (dispatch, getState) => {
  const brandClusterId = "18";
  const marketClusterId = "2";
  const { market, relationship, retailer, brands } = getState().kpi.filters;
  const marketTags = getTagIds(market);
  const relationshipTags = getTagIds(relationship);
  const retailerTags = getTagIds(retailer);
  const brandTags = getTagIds(brands);
  const allTags = [marketTags, relationshipTags, retailerTags, brandTags];
  try {
    const response = await client.query({
      fetchPolicy: "network-only",
      query: GET_NINJAS_KPI,
      variables: {
        workflow: "PUBLISHED",
        tagIds: allTags.length ? allTags : undefined,
        skip: 0,
        limit: 100,
      },
    });
    const ninjas = response?.data?.admin?.ninjas.map((ninja) => {
      const version = ninja.versions[0];
      return {
        ninjaId: version.ninjaId,
        title: version.title,
        brand: version?.tags.find((tag) => tag.clusterId === brandClusterId)
          ?.title,
        market: version?.tags.find((tag) => tag.clusterId === marketClusterId)
          ?.title,
        nbParticipants: version.allUserData.activity.users.length,
      };
    });
    dispatch({
      type: SET_NINJAS,
      data: ninjas,
    });
  } catch (e) {
    dispatch(
      SetErrorMessage(
        "An error occurred while fetching The Right Match data",
        e,
      ),
    );
  }
};
