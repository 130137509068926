export default (theme) => ({
  choice: {
    display: "flex !important",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "28px !important",
    "& span.icon-recycle": {
      fontSize: 14,
      color: ({ isBGDark }) =>
        isBGDark
          ? theme.BattleQuestionChoiceEditor.darkChoice
          : theme.BattleQuestionChoiceEditor.notDarkChoice,
      marginLeft: 20,
    },
  },
  choiceInput: {
    minHeight: 21,
    maxHeight: 45,
    overflow: "hidden",
    outline: "unset",
    color: ({ isBGDark }) =>
      isBGDark
        ? theme.BattleQuestionChoiceEditor.darkChoiceInput
        : theme.BattleQuestionChoiceEditor.notDarkChoiceInput,
    "&[contenteditable]:empty:before": {
      content: "attr(placeholder)",
      display: "block",
    },
    width: 243,
  },
  addNewAnswerChoice: {
    fontFamily: theme.FontFamily,
    fontSize: "16px !important",
    fontWeight: "normal !important",
    fontStyle: "normal !important",
    fontStretch: "condensed !important",
    lineHeight: "normal !important",
    letterSpacing: "normal !important",
    textDecoration: "underline",
    textDecorationColor: ({ isBGDark }) =>
      isBGDark
        ? theme.BattleQuestionChoiceEditor.darkAddNewAnswerChoice
        : theme.BattleQuestionChoiceEditor.notDarkdAddNewAnswerChoice,
    "& > span": {
      color: ({ isBGDark }) =>
        isBGDark
          ? theme.BattleQuestionChoiceEditor.darkAddNewAnswerChoice
          : theme.BattleQuestionChoiceEditor.notDarkdAddNewAnswerChoice,
    },
  },
  label: {
    position: "relative",
    paddingLeft: 65,
    cursor: "pointer",
    display: "inline-block",
    fontSize: 18,
  },
});
