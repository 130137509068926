import React from "react";
import classNames from "classnames";
import SliderStyle from "./MarkerSlideVertical.style";
import PropTypes from "prop-types";
import { DefaultVSlider1, AddSlide } from "assets/icons";
import { FormattedMessage } from "react-intl";
import UploadMedia from "../UploadMedia";
import { Icons, Icon } from "genius-ui";
import Mask from "../../common/Mask";
import MarkerLayer from "../MarkerLayer";
import ChangeTemplate from "../ChangeTemplate";
import CropImage from "../../common/CropImage";
import { CROP_VARIATION } from "../../../configs/constants";
import { translations } from "./MarkerSlideVertical.translations";
import withThemedStyle from "../../hoc/withThemedStyle";
import CropWarningModal from "components/CropWarningModal/CropWarningModal";

// half of fixed height
const BUTTONSWITCH_BREAKPOINT = 368;

const SLIDE_SIZE = {
  width: 414,
  height: 735,
};

const SAFE_AREA_SIZE = {
  width: 334,
  height: 560,
};

const SAFE_AREA_POSITION = {
  x: 40,
  y: 50,
};

const MAX_NR_OF_MARKERS_ON_SLIDE = 10;

class MarkerSlideVertical extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      addPosition: "top",
      image: "",
      points: [],
      isCropVisible: false,
      isModalVisible: false,
    };
  }

  componentDidMount() {
    this.setState({
      type: this.props.type,
      image: this.props.image,
      points: this.props.points,
      pointsByImage: this.props.pointsByImage,
      isCropped: this.props.image.includes("croppedImage.png"),
    });
  }

  handleChange = () => {
    let changedValue = {
      type: this.state.type,
      points: this.state.points,
      image: this.state.image,
    };

    this.props.onChange(changedValue);
  };

  checkToChangeAddPosition = (position) => {
    if (position < BUTTONSWITCH_BREAKPOINT && position > 1) {
      this.setState({ addPosition: "top" });
    } else {
      this.setState({ addPosition: "bottom" });
    }
  };

  changeTemplate = (selectedTemplateId) => {
    this.setState((state, { availableTemplates, onChange }) => {
      const selectedTemplate = availableTemplates.find(
        ({ id }) => id === selectedTemplateId,
      );
      const changedState = {
        points: [], // we're changing the template so we should reset the markers
        ...selectedTemplate.defaultProps,
      };

      onChange({
        ...changedState,
        image: state.image, // keeping the old image
      });

      return changedState;
    });
  };

  handleUploadSuccess = (url) =>
    this.setState({ image: url }, this.handleChange);

  changeCropVisibility = (visible) => () =>
    this.setState({ isCropVisible: visible });

  changeModalVisibility = (visible) => () =>
    this.setState({ isModalVisible: visible });

  handleMarkersChanged = (points) => {
    if (this.state.isCropped) {
      this.setState((state) => ({ points }), this.handleChange);
    } else {
      this.setState({ isModalVisible: true });
    }
  };

  render() {
    let props = this.props;
    let {
      classes,
      slideId,
      type,
      isFirst,
      isSelected,
      availableTemplates,
      points,
      onMarkerOpened,
    } = props;

    const deleteButtonClass = classNames([
      classes.deleteBox,
      classes.actionButton,
    ]);

    const uploadButtonClass = classNames([
      classes.uploadButton,
      classes.actionButton,
    ]);

    return (
      <div
        className={classes.sliderContainer}
        onMouseMove={(e) =>
          isSelected ? this.checkToChangeAddPosition(e.nativeEvent.offsetY) : {}
        }
        onClick={props.onClick}
      >
        {isSelected && (
          <ChangeTemplate
            selectedTemplateId={type}
            templates={availableTemplates}
            onTemplateSelect={this.changeTemplate}
          />
        )}
        {!props.image && <Mask />}
        <CropWarningModal
          visible={this.state.isModalVisible}
          hideModal={() => this.setState({ isModalVisible: false })}
          actionHandler={() => {
            this.setState({ isModalVisible: false });
            this.setState({ isCropVisible: true });
          }}
        />
        <MarkerLayer
          uniqueId={slideId}
          isLayerActive={isSelected}
          layerSize={SLIDE_SIZE}
          onChange={this.handleMarkersChanged}
          safeAreaSize={SAFE_AREA_SIZE}
          safeAreaPosition={SAFE_AREA_POSITION}
          maxNrOfMarkers={MAX_NR_OF_MARKERS_ON_SLIDE}
          markers={points}
          onMarkerOpened={onMarkerOpened}
          isCropped={this.state.isCropped ? true : false}
        />
        {this.state.addPosition === "top" && props.isSelected ? (
          <div
            className={classes.addBoxTop}
            onClick={props.onAddToTopHandle}
            onMouseMove={(event) => event.stopPropagation()}
          >
            <img src={AddSlide} alt="add slide before" />
          </div>
        ) : (
          ""
        )}

        {this.state.addPosition === "bottom" && props.isSelected ? (
          <div
            className={classes.addBoxBottom}
            onClick={props.onAddToBottomHandle}
            onMouseMove={(event) => event.stopPropagation()}
          >
            <img src={AddSlide} alt="add slide after" />
          </div>
        ) : (
          ""
        )}

        <div className={classes.imageActionContainer}>
          <UploadMedia
            id={slideId}
            onUploadSuccess={this.handleUploadSuccess}
            className={uploadButtonClass}
            isVideoUploadEnabled={false}
          >
            <Icon iconName={Icons.upload} />
            <FormattedMessage {...translations.UploadButtonLabel} />
          </UploadMedia>
          <div className={classes.cropContainer}>
            <CropImage
              classes={classes}
              sourceImage={this.state.image}
              isCropVisible={this.state.isCropVisible}
              cropSize={CROP_VARIATION.BACKGROUND}
              onCropClick={this.changeCropVisibility(true)}
              onCancel={this.changeCropVisibility(false)}
              onCropCompleted={(url, id) => this.handleUploadSuccess(url)}
            />
          </div>
        </div>

        {props.isSelected ? (
          <div className={deleteButtonClass} onClick={props.onRemoveClick}>
            <FormattedMessage {...translations.DeleteButtonLabel} />
            <Icon iconName={Icons.delete} />
          </div>
        ) : (
          ""
        )}

        {isFirst && (
          <div className={classes.scrollDownIndicator}>
            <FormattedMessage {...translations.ScrollDown} />
            <span className={classes.scrollDownIcon}>
              <Icon iconName={Icons.dropdownIndicator} />
            </span>
          </div>
        )}

        <span className={classes.partWillBeCropped}>
          <FormattedMessage {...translations.PartPossiblyCropped} />
        </span>
      </div>
    );
  }
}

MarkerSlideVertical.propTypes = {
  /** unique id of the slide **/
  slideId: PropTypes.string.isRequired,
  /** The current selected image */
  image: PropTypes.string,
  /** Slide type **/
  type: PropTypes.string,
  /** Marker array **/
  points: PropTypes.array,
  /** title value */
  title: PropTypes.string,
  /** description */
  description: PropTypes.string,
  /** Label for the chnage image selection button */
  buttonLabel: PropTypes.string,
  /** On upload completed image change */
  onAddToBottomHandle: PropTypes.func,
  /** On handle addLeft event */
  onAddToTopHandle: PropTypes.func,
  /** on remove click event */
  onRemoveClick: PropTypes.func,
  /** onValueChange */
  onChange: PropTypes.func,
  /** is selected */
  isSelected: PropTypes.bool,
  /** on click */
  onClick: PropTypes.func,
  /** default sliderImage */
  defaultSliderImage: PropTypes.string,
  /** if it is the first slide, we display the scroll down indicator **/
  isFirst: PropTypes.bool,
  /** templates available to change the current slide with **/
  availableTemplates: PropTypes.array,
  /** function executed when a marker is opened or closed **/
  onMarkerOpened: PropTypes.func,
};

MarkerSlideVertical.defaultProps = {
  defaultSliderImage: DefaultVSlider1,
  isFirst: false,
};

export default withThemedStyle(SliderStyle)(MarkerSlideVertical);
