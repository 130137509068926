import gql from "graphql-tag";

export const LESSON_UPDATE_PIC_THUMB = gql`
  mutation lessonUpdatePicThumb(
    $lessonId: ID
    $lang: LangEnum
    $data: String!
  ) {
    admin {
      lesson(lessonId: $lessonId, lang: $lang) {
        picThumb(data: $data)
      }
    }
  }
`;
