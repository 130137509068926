import gql from "graphql-tag";
import { PublishLessonFragment } from "../fragments/lessonPublish";

export const LESSON_GET_BY_ID = gql`
  query lessonGetById($lessonId: ID) {
    admin {
      lessons(lessonId: $lessonId) {
        ...LessonFields
      }
    }
  }
  ${PublishLessonFragment}
`;
