import { PEANUT_CARD_TYPES } from "configs/constants";
import { useIntl } from "react-intl";
import translations from "./TemplateSelector.translations";
import { PeanutDefaultImage1 } from "assets/icons";
import {
  PeanutWithTextPreview,
  PeanutWithoutTextPreview,
  PeanutWithLegendPreview,
} from "assets/icons/index";
import React from "react";

const DefaultNewElement = {
  title: "",
  description: "",
  image: "",
  defaultImage: PeanutDefaultImage1,
  legend: "",
  cardType: PEANUT_CARD_TYPES.WITHOUT_TEXT,
};

const TemplateSample = ({ addCard, cardType, title, image }) => {
  const intl = useIntl();

  return (
    <div className="selectTemplateItem">
      <div onClick={addCard({ ...DefaultNewElement, cardType })}>
        <p>{intl.formatMessage(title)}</p>
        <img
          src={image}
          alt={intl.formatMessage(translations.TemplateThumbnailAltMessage)}
        />
      </div>
    </div>
  );
};

const DefaultTemplates = [
  {
    cardType: PEANUT_CARD_TYPES.WITH_TEXT,
    title: translations.WithTextOption,
    image: PeanutWithTextPreview,
  },
  {
    cardType: PEANUT_CARD_TYPES.WITHOUT_TEXT,
    title: translations.WithoutTextOption,
    image: PeanutWithoutTextPreview,
  },
  {
    cardType: PEANUT_CARD_TYPES.WITH_LEGEND,
    title: translations.WithLegendOption,
    image: PeanutWithLegendPreview,
  },
];

const TemplateSelector = ({ addCard }) => {
  return (
    <div className="scrollableTemplateContainer">
      {DefaultTemplates.map((card, key) => (
        <TemplateSample addCard={addCard} key={key} {...card} />
      ))}
    </div>
  );
};

export default TemplateSelector;
