import { BlueSpinner } from "assets/icons";

export default {
  spinner: {
    width: ({ size }) => size,
    height: ({ size }) => size,
    backgroundImage: `url('${BlueSpinner}')`,
    backgroundSize: ({ size }) => `${size}px ${size}px`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
};
