import React from "react";
import ContentTypeDefault from "./ContentTypeDefault";
import ContentTypeShiseido from "./Shiseido";
import { isShiseido } from "../../configs/client";

const ContentType = (props) => {
  if (isShiseido) {
    return <ContentTypeShiseido {...props} />;
  }
  return <ContentTypeDefault {...props} />;
};

export default ContentType;
