export default {
  container: {
    height: "100vh",
    position: "relative",
    opacity: ({ isExportLoading }) => (isExportLoading ? 0.5 : 1),
    pointerEvents: ({ isExportLoading }) => (isExportLoading ? "none" : "auto"),
  },
  spinner: {
    position: "absolute",
    top: 400,
    left: "50%",
    right: "50%",
    zIndex: 1,
    opacity: ({ isExportLoading }) => (isExportLoading ? 1 : 0),
  },
  viewSwitcherCheckbox: {
    width: "100%",
    marginBottom: 5,
    "& > div": {
      float: "right",
    },
  },
  topTitle: {
    width: "100%",
    marginBottom: 5,
    float: "left",
    fontSize: 25,
    fontWeight: "bold",
  },
};
