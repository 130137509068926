import { USERS_DEFAULT } from "./Users.DEFAULT.graphql";
import { REACT_APP_CLIENT_NAME } from "../../../../../configs/client";

const GetActualFragment = () => {
  switch (REACT_APP_CLIENT_NAME) {
    default:
      return USERS_DEFAULT;
  }
};

export const UserFragment = GetActualFragment();
