import { defineMessages } from "react-intl";

export const translations = defineMessages({
  Title: {
    id: "SelectDegreeBadgeLessonsScreen.Title",
    defaultMessage: "Badges",
  },
  CancelBtnText: {
    id: "SelectDegreeBadgeLessonsScreen.CancelBtnText",
    defaultMessage: "Cancel",
  },
  ValidationBtnText: {
    id: "SelectDegreeBadgeLessonsScreen.ValidationBtnText",
    defaultMessage: "Validate",
  },
  ContentTitle: {
    id: "SelectDegreeBadgeLessonsScreen.ContentTitle",
    defaultMessage: "Add your new lessons to {markets}'s {degreeName} badges",
  },
  ContentSubtitle: {
    id: "SelectDegreeBadgeLessonsScreen.ContentSubtitle",
    defaultMessage:
      "Adding these new lessons to your badges will let users gain points for completing them!",
  },
  NoOneLessonText: {
    id: "SelectDegreeBadgeLessonsScreen.NoOneLessonText",
    defaultMessage: "No one lesson",
  },
  NoOneBadgeText: {
    id: "SelectDegreeBadgeLessonsScreen.NoOneBadgeText",
    defaultMessage: "No one badge associated with this degree",
  },
});
