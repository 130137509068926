import { createMultipleSheetXml } from "../excelFactory";
import { userTranslations as translations } from "./UserProfile.translations";
import {
  createBadgeArray,
  createUserArray,
  createPendingArray,
  createActivityArray,
  createLearningArray,
} from "./userArrayFactory";
import { getFormattedSopiDate } from "utils/dateUtils";

export const exportUser = (profileData, intl) => {
  createMultipleSheetXml(
    [
      {
        name: intl.formatMessage(translations.UserSheetTitle),
        data: createUserArray(profileData.user, profileData.totalPoints, intl),
      },
      {
        name: intl.formatMessage(translations.ActivitiesSheetTitle),
        data: createActivityArray(profileData.statistics, intl),
      },
      {
        name: intl.formatMessage(translations.LearningSheetTitle),
        data: createLearningArray(profileData.programs, intl),
      },
      {
        name: intl.formatMessage(translations.QuestionSheetTitle),
        data: createPendingArray(profileData.questions, intl),
      },
      {
        name: intl.formatMessage(translations.BadgeSheetTitle),
        data: createBadgeArray(profileData.degrees, intl),
      },
    ],
    `Profile-${profileData.user.name}-${getFormattedSopiDate(new Date())}.xlsx`,
  );
};
