export default ({ UploadImageButton, FontFamily }) => ({
  imageHandlerContainer: {
    display: "flex",
    flexDirection: "row",
    color: UploadImageButton.baseColor,
    alignItems: "center",
    paddingLeft: 18,
    height: 53,
  },
  uploadIcon: {
    marginRight: 5.5,
    "&:before": {
      color: UploadImageButton.baseColor,
      fontSize: 18,
    },
  },
  uploadLabel: {
    fontFamily: FontFamily,
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: 0.07,
  },
  uploadContainer: {
    cursor: "pointer",
    "&:hover": {
      color: UploadImageButton.hoverColor,
      "& > span:before": {
        color: UploadImageButton.hoverColor,
      },
    },
  },
  uploadContainerDisabled: {
    cursor: "default",
  },
});
