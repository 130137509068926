import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import parseISO from "date-fns/parseISO";
import { Button, ButtonSize, Icon, Icons } from "genius-ui";
import styles from "./PublishDateHandler.style";
import {
  getFormattedPublicationDate,
  getFormattedSopiDate,
} from "../../../utils/dateUtils";
import CheckboxCustom from "components/CheckBoxCustom/CheckBoxCustom";
import withThemedStyle from "../../hoc/withThemedStyle";
import "constants/scss/style.scss";

class PublishDateHandler extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isStartDateCalendarOpen: false,
      isEndDateCalendarOpen: false,
    };
  }

  toggleStartDateCalendar = (e) => {
    e && e.preventDefault();
    this.setState({
      isStartDateCalendarOpen: !this.state.isStartDateCalendarOpen,
    });
  };

  toggleEndDateCalendar = (e) => {
    e && e.preventDefault();
    this.setState({ isEndDateCalendarOpen: !this.state.isEndDateCalendarOpen });
  };

  endDateHandler = () => {
    if (this.props.endPostingDate) {
      this.props.updateEndPublicationDate(null);
    } else {
      this.props.updateEndPublicationDate(
        this.props.startPostingDate || new Date().toISOString(),
      );
    }
  };

  render() {
    const { props } = this;
    const {
      classes,
      startPostingDate,
      endPostingDate,
      updateStartPublicationDate,
      updateEndPublicationDate,
      startDateLabel,
      endDateLabel,
      noEndDateLabel,
      disabled,
    } = props;

    const formattedStartPostingDate =
      getFormattedPublicationDate(startPostingDate);
    const formattedEndPostingDate = getFormattedPublicationDate(endPostingDate);

    return (
      <div className="PostingDate">
        <div className={classes.postingDateSwitchers}>
          <div className="startPostingDate">
            <div className={classes.sectionTitle}>{startDateLabel}</div>
            <div>
              <Button
                variation={"secondary"}
                size={ButtonSize.small}
                handleClick={this.toggleStartDateCalendar}
                disabled={disabled}
              >
                <div className={classes.postingDateBtnContent}>
                  <div>
                    <Icon
                      iconName={Icons.calendar}
                      style={
                        disabled
                          ? classes.postingDateDisabledBtnIcon
                          : classes.postingDateBtnIcon
                      }
                    />
                  </div>
                  <div>{formattedStartPostingDate}</div>
                </div>
              </Button>
            </div>
          </div>
          <div className="endPostingDate">
            <div className={classes.sectionTitle}>{endDateLabel}</div>
            <div>
              <Button
                variation={"secondary"}
                size={ButtonSize.small}
                handleClick={this.toggleEndDateCalendar}
                disabled={!this.props.endPostingDate || disabled}
              >
                <div className={classes.postingDateBtnContent}>
                  <div>
                    <Icon
                      iconName={Icons.calendar}
                      style={
                        !this.props.endPostingDate || disabled
                          ? classes.postingDateDisabledBtnIcon
                          : classes.postingDateBtnIcon
                      }
                    />
                  </div>
                  <div>{formattedEndPostingDate}</div>
                </div>
              </Button>
            </div>
          </div>
          <div className={classes.noEndPostingDate}>
            <CheckboxCustom
              label={noEndDateLabel}
              handleChange={this.endDateHandler}
              checked={!this.props.endPostingDate}
              disabled={disabled}
            />
          </div>
        </div>
        <div>
          {this.state.isStartDateCalendarOpen && (
            <DatePicker
              selected={
                startPostingDate && startPostingDate !== ""
                  ? parseISO(startPostingDate)
                  : ""
              }
              onChange={(date) => {
                updateStartPublicationDate(
                  date ? getFormattedSopiDate(date) : date,
                );
                this.toggleStartDateCalendar();
              }}
              withPortal
              inline
              minDate={new Date()}
              maxDate={endPostingDate ? parseISO(endPostingDate) : undefined}
              onClickOutside={() =>
                this.setState({
                  isStartDateCalendarOpen: !this.state.isStartDateCalendarOpen,
                })
              }
            />
          )}
          {this.state.isEndDateCalendarOpen && (
            <DatePicker
              selected={
                endPostingDate && endPostingDate !== ""
                  ? parseISO(endPostingDate)
                  : ""
              }
              onChange={(date) => {
                updateEndPublicationDate(
                  date ? getFormattedSopiDate(date) : date,
                );
                this.toggleEndDateCalendar();
              }}
              withPortal
              inline
              minDate={
                startPostingDate ? parseISO(startPostingDate) : new Date()
              }
              onClickOutside={() =>
                this.setState({
                  isEndDateCalendarOpen: !this.state.isEndDateCalendarOpen,
                })
              }
            />
          )}
        </div>
      </div>
    );
  }
}

const StringOrObject = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
]);

PublishDateHandler.propTypes = {
  startPostingDate: PropTypes.string,
  endPostingDate: PropTypes.string,
  updateStartPublicationDate: PropTypes.func.isRequired,
  updateEndPublicationDate: PropTypes.func.isRequired,
  startDateLabel: StringOrObject.isRequired,
  endDateLabel: StringOrObject.isRequired,
  noEndDateLabel: StringOrObject.isRequired,
};

export default withThemedStyle(styles)(PublishDateHandler);
