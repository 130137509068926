import React from "react";
import PropTypes from "prop-types";
import Style from "./WidgetSection.style.js";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const WidgetSection = (props) => {
  const { title, children } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.container}>
      {title && <h2 className={classes.title}>{title}</h2>}
      {children}
    </div>
  );
};

WidgetSection.propTypes = {
  /** Display a title for the section **/
  title: PropTypes.node,
  /** How many % of the container's width it occupies **/
  width: PropTypes.number.isRequired,
};

WidgetSection.defaultProps = {
  width: 100,
};

export default WidgetSection;
