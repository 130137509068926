import { defineMessages } from "react-intl";

export const translations = defineMessages({
  TranslateOption: {
    id: "MasterContentManagerListView.TranslateOption",
    defaultMessage: "Translate",
  },
  DeleteOption: {
    id: "MasterContentManagerListView.DeleteOption",
    defaultMessage: "Delete",
  },
  SeeMoreButtonDefaultTitle: {
    id: "MasterContentManagerListView.SeeMoreButtonDefaultTitle",
    defaultMessage: "Load more",
  },
  NameHeaderTitle: {
    id: "MasterContentManagerListView.NameHeaderTitle",
    defaultMessage: "Name",
  },
  TypeHeaderTitle: {
    id: "MasterContentManagerListView.TypeHeaderTitle",
    defaultMessage: "Type",
  },
  DateHeaderTitle: {
    id: "MasterContentManagerListView.DateHeaderTitle",
    defaultMessage: "Date",
  },
  StatusHeaderTitle: {
    id: "MasterContentManagerListView.StatusHeaderTitle",
    defaultMessage: "Status",
  },
  PublishOption: {
    id: "MasterContentManagerListView.PublishOption",
    defaultMessage: "Publication settings",
  },
  AddToMarketOption: {
    id: "MasterContentManagerListView.AddToMarketOption",
    defaultMessage: "Add to market",
  },
});
