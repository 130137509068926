import { defineMessages } from "react-intl";

export const translations = defineMessages({
  TranslateOption: {
    id: "DocGridViewMarket.TranslateOption",
    defaultMessage: "Translate",
  },
  DeleteOption: {
    id: "DocGridViewMarket.DeleteOption",
    defaultMessage: "Delete",
  },
  SeeMoreButtonDefaultTitle: {
    id: "DocGridViewMarket.SeeMoreButtonDefaultTitle",
    defaultMessage: "Load more",
  },
  SendNotificationOption: {
    id: "DocGridViewMarket.SendNotificationTitle",
    defaultMessage: "Send Notification",
  },
  PublishOption: {
    id: "DocGridViewMarket.PublishOption",
    defaultMessage: "Publication settings",
  },
  SeeResultButtonLabel: {
    id: "DocGridViewMarket.SeeResultButtonLabel",
    defaultMessage: "See Result",
  },
  NotScheduled: {
    id: "DocGridViewMarket.NotScheduled",
    defaultMessage: "Not scheduled",
  },
});
