import React from "react";
import { InputText } from "genius-ui";

const renderInputField = ({
  input,
  label,
  value,
  meta: { touched, error },
  required,
  containerClassName,
  ...rest
}) => (
  <InputText
    containerClassName={containerClassName}
    label={label}
    required
    {...input}
    {...rest}
  />
);

export { renderInputField };
