import { defineMessages } from "react-intl";

export const translations = defineMessages({
  TemplateTitle: {
    id: "SideBarTextTemplate.TemplateTitle",
    defaultMessage: "Text",
  },
  TitleText: {
    id: "SideBarTextTemplate.TitleText",
    defaultMessage: "Title",
  },
  SubtitleText: {
    id: "SideBarTextTemplate.SubtitleText",
    defaultMessage: "Subtitle",
  },
  ParagraphText: {
    id: "SideBarTextTemplate.ParagraphText",
    defaultMessage: "Paragraph",
  },
  LinkText: {
    id: "SideBarTextTemplate.LinkText",
    defaultMessage: "Link",
  },
  IsActivityCheckbox: {
    id: "SideBarTextTemplate.IsActivityCheckbox",
    defaultMessage: "Set this lesson as program quiz",
  },
});
