import gql from "graphql-tag";

export const GET_DEGREE_BY_ID = gql`
  query degreeGetById($degreeId: ID) {
    admin {
      degrees(degreeId: $degreeId) {
        versions {
          workflow
          workflowScheduledIn
          workflowScheduledOut
          isPrivate
          degreeId
          title
          summary
          lang
          picCover
          author {
            userId
          }
          tags {
            tagId
            title
            clusterId
          }
        }
        programs {
          versions {
            programId
            title
            summary
            lang
            workflow
          }
        }
      }
    }
  }
`;
