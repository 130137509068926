import React from "react";
import classNames from "classnames";
import Style from "./FixedContainer.style.js";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

/** style wrapper around ReactDOM.div **/
const FixedContainer = (props) => {
  const { className, ...restProps } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div
      {...restProps}
      className={classNames([classes.container, className])}
    />
  );
};

export default FixedContainer;
