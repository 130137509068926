import React from "react";
import { DegreesBuilderStyle } from "./DegreeBuilder.style";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";
import {
  ADD_LESSON_TO_PROGRAM,
  LESSON_CREATE,
  PROGRAM_CREATE,
  PROGRAM_UPDATE_SUMMARY,
  PROGRAM_UPDATE_TITLE,
  REMOVE_LESSON_FROM_PROGRAM,
} from "../../graphql/remote/degrees/mutations";
import { connect } from "react-redux";
import {
  SetCurrentEditProgramInfo,
  SetCurrentDegreeProgramContent,
  SetCurrentLessonId,
  ChangeCurrentEditProgramInfoVisibility,
  SetActiveMenuLessonId,
  SetCurrentEditProgramId,
  GetDegreePrograms,
  SetProgramLessons,
  SetLastExpandedProgramId,
  SetProgramExpandedStatus,
  ReFetchProgramLessons,
  LessonMoveUp,
  LessonMoveDown,
} from "../../store/degree/actions";
import { withRouter } from "react-router";
import { Icon, Icons, FullscreenLoader } from "genius-ui";
import { DOC_WORKFLOW_ENUM, DEGREE_PARTS } from "../../configs/constants";
import {
  LESSON_COPY_FOR_LANGUAGE,
  LESSON_UPDATE_WORFLOW,
  LESSON_CLONE,
  LESSON_ADD_TAGS,
  LESSON_UPDATE_PIC_COVER,
  LESSON_UPDATE_PIC_CARD,
  LESSON_UPDATE_PIC_THUMB,
} from "../../graphql/remote/lessons/mutations";
import { SetSuccessMessage } from "../../store/notification/actions";
import DuplicationModal from "../../components/common/DuplicationModal";
import CloneModal from "../../components/common/CloneModal";
import {
  SetDuplicationData,
  SetShowModal,
} from "../../store/duplication/actions";
import ProgramInfo from "./programInfo";
import ProgramCreateInfo from "./createProgram";
import { PROGRAM_COPY_FOR_LANGUAGE } from "../../graphql/remote/programs/mutations/Copy.graphql";
import { FormattedMessage } from "react-intl";
import {
  SetNotificationData,
  SetShowModal as SetShowNotificationModal,
} from "../../store/sendNotification/actions";
import SendNotificationModal from "../../components/common/SendNotificationModal";
import { UPDATE_WORKFLOW } from "../../graphql/remote/programs/mutations/ProgramUpdateWorkflow.graphql";
import { DOCUMENT_LIST_WORKFLOWS } from "../../store/document/reducers";
import { getPath } from "../../core/paths";
import DeleteModal from "../../components/common/DeleteModal";
import { PROGRAM_CLONE } from "../../graphql/remote/programs/mutations/ProgramClone.graphql";
import { translations } from "./DegreeBuilderBackup.translations";
import LessonRelocationModal from "../../components/common/lessonRelocationModal";
import { LANGUAGES } from "../../configs/referential";
import withThemedStyle from "../../components/hoc/withThemedStyle";
import clientConfig, { isShiseido } from "../../configs/client";
import { SetCurrentCloningIds } from "../../store/masterTemplate/actions";
import { PROGRAM_ADD_TAGS } from "../../graphql/remote/degrees/mutations/ProgramAddTags.graphql";
import { PlaceHolderDegree } from "assets/icons";

const updateTitle = graphql(PROGRAM_UPDATE_TITLE, {
  props: ({ mutate }) => ({
    updateTitle: (programId, lang, data) =>
      mutate({
        variables: { programId: programId, lang: lang, data: data },
      }),
  }),
});

const updateSummary = graphql(PROGRAM_UPDATE_SUMMARY, {
  props: ({ mutate }) => ({
    updateSummary: (programId, lang, data) =>
      mutate({
        variables: { programId: programId, lang: lang, data: data },
      }),
  }),
});

const createProgram = graphql(PROGRAM_CREATE, {
  props: ({ mutate }) => ({
    createProgram: (degreeId, lang, title) =>
      mutate({
        variables: { degreeId: degreeId, lang: lang, title: title },
      }),
  }),
});

const createLesson = graphql(LESSON_CREATE, {
  props: ({ mutate }) => ({
    createLesson: (programId, lang, title) =>
      mutate({
        variables: { programId: programId, lang: lang, title: title },
      }),
  }),
});

const updatePicCover = graphql(LESSON_UPDATE_PIC_COVER, {
  props: ({ mutate }) => ({
    updatePicCover: (lessonId, lang, data) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, data: data },
      }),
  }),
});

const updatePicCard = graphql(LESSON_UPDATE_PIC_CARD, {
  props: ({ mutate }) => ({
    updatePicCard: (lessonId, lang, data) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, data: data },
      }),
  }),
});

const updatePicThumb = graphql(LESSON_UPDATE_PIC_THUMB, {
  props: ({ mutate }) => ({
    updatePicThumb: (lessonId, lang, data) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, data: data },
      }),
  }),
});

const addLessonToProgram = graphql(ADD_LESSON_TO_PROGRAM, {
  props: ({ mutate }) => ({
    addLessonToProgram: (programId, lang, lessonId, onSuccess) =>
      mutate({
        variables: { programId, lang, lessonIds: [lessonId] },
        update: () => onSuccess(),
      }),
  }),
});

const removeLessonFromProgram = graphql(REMOVE_LESSON_FROM_PROGRAM, {
  props: ({ mutate }) => ({
    removeLessonFromProgram: (programId, lang, lessonId) =>
      mutate({
        variables: { programId, lang, lessonIds: [lessonId] },
      }),
  }),
});

const updateLessonStatus = graphql(LESSON_UPDATE_WORFLOW, {
  props: ({ mutate }) => ({
    updateLessonStatus: (lang, lessonId, status, onSuccess) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, status },
        update: () => onSuccess(),
      }),
  }),
});

const deleteProgram = graphql(UPDATE_WORKFLOW, {
  props: ({ mutate }) => ({
    deleteProgram: (programId, lang, onSuccess) =>
      mutate({
        variables: {
          programId: programId,
          lang: lang,
          status: DOC_WORKFLOW_ENUM.ARCHIVED,
        },
        update: () => onSuccess(),
      }),
  }),
});

const duplicateLessonRequest = graphql(LESSON_COPY_FOR_LANGUAGE, {
  props: ({ mutate }) => ({
    duplicateLessonRequest: (lessonId, sourceLang, destLang, onSuccess) =>
      mutate({
        variables: { lessonId, sourceLang, destLang },
        update: () => onSuccess(),
      }),
  }),
});

const cloneLessonRequest = graphql(LESSON_CLONE, {
  props: ({ mutate }) => ({
    cloneLesson: (lessonId, lang, parentLearningId, onSuccess) =>
      mutate({
        variables: { lessonId, lang, parentLearningId },
        update: () => onSuccess(),
      }),
  }),
});

const cloneProgramRequest = graphql(PROGRAM_CLONE, {
  props: ({ mutate }) => ({
    cloneProgram: (programId, lang, onSuccess) =>
      mutate({
        variables: { programId, lang },
        update: () => onSuccess(),
      }),
  }),
});

const duplicateProgramRequest = graphql(PROGRAM_COPY_FOR_LANGUAGE, {
  props: ({ mutate }) => ({
    duplicateProgramRequest: (programId, sourceLang, destLang, onSuccess) =>
      mutate({
        variables: { programId, sourceLang, destLang },
        update: () => onSuccess(),
      }),
  }),
});

const updateProgramStatus = graphql(UPDATE_WORKFLOW, {
  props: ({ mutate }) => ({
    updateProgramStatus: (programId, lang, status, onSuccess) =>
      mutate({
        variables: { programId, lang, status },
        update: () => onSuccess && onSuccess(),
      }),
  }),
});

const addTagsToLesson = graphql(LESSON_ADD_TAGS, {
  props: ({ mutate }) => ({
    addLessonTags: (lessonId, lang, tagIdList) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, tagIdList: tagIdList },
      }),
  }),
});

const updateProgramTags = graphql(PROGRAM_ADD_TAGS, {
  props: ({ mutate }) => ({
    updateProgramTags: (programId, lang, tagIds) =>
      mutate({
        variables: { programId, lang, tagIds },
      }),
  }),
});

const getMarketTagId = (tags) => {
  const marketTag = tags.find(({ clusterId }) =>
    clientConfig.USER_MARKET_CLUSTER_IDS.includes(Number(clusterId)),
  );

  return marketTag?.tagId;
};

class DegreeBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      degreeIsUpdating: false,
      cloneDocumentData: {
        type: DEGREE_PARTS.LESSON,
      },
      showCloneModal: false,
      deleteModalVisibility: false,
      deleteDocumentData: undefined,
      showLessonRelocationModal: false,
      lessonRelocationData: {
        lessonId: null,
        fromProgramId: null,
      },
    };
  }

  componentDidMount() {
    const { props } = this;
    const {
      match: { params },
    } = this.props;
    props.GetDegreePrograms(params.degreeId, params.lang);
  }

  setLessonRelocationData(lessonId, fromProgramId) {
    this.setState(
      {
        lessonRelocationData: {
          lessonId,
          fromProgramId,
        },
      },
      () => this.setState({ showLessonRelocationModal: true }),
    );
  }

  setDeleteModalVisibility = (value) => () =>
    this.setState({ deleteModalVisibility: value });

  setDeleteDocumentData = (value) =>
    this.setState({ deleteDocumentData: value });

  deleteDocument = () => {
    const {
      match: { params },
    } = this.props;
    this.setState({ deleteModalVisibility: false });
    switch (this.state.deleteDocumentData.type) {
      case DEGREE_PARTS.PROGRAM: {
        this.props.DeleteProgram(
          this.state.deleteDocumentData.id,
          params.lang,
          this.props.deleteProgram,
          this.refetch,
        );
        break;
      }
      case DEGREE_PARTS.LESSON: {
        this.props.DeleteLesson(
          this.state.deleteDocumentData.id,
          this.state.deleteDocumentData.program,
          params.lang,
          this.props.updateLessonStatus,
        );
        break;
      }
      default:
        break;
    }
  };

  showModal = () => this.props.SetShowModal(true);
  hideModal = () => this.props.SetShowModal(false);

  changeCloneModalVisibility = (value) => () =>
    this.setState({ showCloneModal: value });

  updateLessonRelocationModalVisibility = (showLessonRelocationModal) => () =>
    this.setState({ showLessonRelocationModal });

  setupCloneLessonData = (dataObj) =>
    this.setState({ cloneDocumentData: dataObj });

  cloneLesson = () => {
    const {
      match: { params },
    } = this.props;
    this.setState({ showCloneModal: false });
    switch (this.state.cloneDocumentData.type) {
      case DEGREE_PARTS.LESSON:
        this.props.CloneLesson(
          this.state.cloneDocumentData.lessonId,
          params.lang,
          this.state.cloneDocumentData.programId,
          this.props.cloneLesson,
          this.refetch,
        );
        break;
      case DEGREE_PARTS.PROGRAM:
        this.props.CloneProgram(
          this.state.cloneDocumentData.programId,
          params.lang,
          this.props.cloneProgram,
          this.refetch,
        );
        break;
      default:
        break;
    }
  };

  refetch = (onSuccess) => {
    const {
      match: { params },
    } = this.props;
    this.props.GetDegreePrograms(params.degreeId, params.lang, onSuccess);
  };

  handleBack = () => {
    if (isShiseido && clientConfig.MODULES.MASTER_TEMPLATES.AVAILABLE) {
      this.props.history.goBack();
    } else {
      const endpoint =
        this.props.documentListWorkflow === DOCUMENT_LIST_WORKFLOWS.DELETED
          ? "degreesHistory"
          : "degrees";
      this.props.history.push(getPath(endpoint));
    }
  };

  navigateToLessonPublishPage = (lessonId) => {
    const {
      match: { params },
    } = this.props;
    const endpoint =
      isShiseido && this.props.publishDegree.isMasterDegree
        ? "editMasterTemplateLesson"
        : "publishLesson";
    this.props.history.push(
      getPath(endpoint, params.degreeId, lessonId, params.lang),
    );
  };

  moveLessonToProgram = (toProgramId) => {
    this.setState({ degreeIsUpdating: true });
    this.props.MoveLessonToProgram(
      this.state.lessonRelocationData.lessonId,
      this.state.lessonRelocationData.fromProgramId,
      toProgramId,
      this.props.match.params.lang,
      this.props.addLessonToProgram,
      this.props.removeLessonFromProgram,
      () => {
        this.setState({ showLessonRelocationModal: false });
        this.setState({ degreeIsUpdating: false });
      },
    );
  };

  setAsDraftEventHandler = (programId, lessonId) => () => {
    const {
      match: {
        params: { lang },
      },
    } = this.props;
    this.props.SetAsDraftLesson(
      programId,
      lessonId,
      lang,
      this.props.updateLessonStatus,
    );
  };

  setAsReadyEventHandler = (programId, lessonId) => () => {
    const {
      match: {
        params: { lang },
      },
    } = this.props;
    this.props.SetAsReadyLesson(
      programId,
      lessonId,
      lang,
      this.props.updateLessonStatus,
    );
  };

  navigateToAddToMarketPage = (lessonId, programId, degreeId) => {
    this.props.SetCloningContext(degreeId, programId, lessonId);
    const {
      match: {
        params: { lang },
      },
    } = this.props;
    this.props.history.push(
      getPath("documentAddToMarket", DEGREE_PARTS.LESSON, lessonId, lang),
    );
  };

  render() {
    const props = this.props;
    const {
      classes,
      match: {
        params: { degreeId, lang },
      },
    } = props;
    const handlers = {
      updateTitle: props.updateTitle,
      updateSummary: props.updateSummary,
      createProgram: props.createProgram,
      createLesson: props.createLesson,
      addLessonToProgram: props.addLessonToProgram,
      addLessonTags: props.addLessonTags,
      updateProgramTags: props.updateProgramTags,
      updatePicCover: props.updatePicCover,
      updatePicCard: props.updatePicCard,
      updatePicThumb: props.updatePicThumb,
    };
    const marketTagId = getMarketTagId(props.publishDegree.tags);

    return (
      <div className={classes.rootContainer}>
        {!!this.props.isLoaderActive && !props.programContent.length && (
          <FullscreenLoader />
        )}
        {this.state.degreeIsUpdating && <FullscreenLoader />}
        <div className={classes.rootContent}>
          <div className={classes.closePage}>
            <Icon
              iconName={Icons.arrowLeft}
              style={classes.closeIcon}
              handleClick={this.handleBack}
            />
          </div>
          {props.isProgramEditorVisible || props.programContent.length === 0 ? (
            <ProgramCreateInfo
              program={props.currentEditingProgram}
              onTitleChange={(value) =>
                props.UpdateProgramTitle(value, props.currentEditingProgram)
              }
              onSummaryChange={(value) =>
                props.UpdateProgramSumarry(value, props.currentEditingProgram)
              }
              handleClick={() =>
                props.AddOrUpdateProgram(
                  degreeId,
                  lang,
                  props.currentEditingProgram,
                  handlers,
                  this.refetch,
                  props.publishDegree.isMasterDegree,
                )
              }
              handleCreateProgram={() =>
                props.ToggleProgramEditorVisibility(
                  !props.isProgramEditorVisible,
                )
              }
              handleLessonCreate={(title) =>
                props.AddOrUpdateLesson(
                  props.currentEditingProgram,
                  lang,
                  title,
                  degreeId,
                  handlers,
                  this.refetch,
                  props.publishDegree.isMasterDegree,
                )
              }
            />
          ) : (
            ""
          )}
          <div>
            {props.programContent.map((x, index) => (
              <ProgramInfo
                key={x.programId}
                orderId={index}
                language={lang}
                program={x}
                onTitleChange={(value) =>
                  props.EditProgramTitle(value, x, props.programContent)
                }
                onSummaryChange={(value) =>
                  props.EditProgramSummary(value, x, props.programContent)
                }
                handleProgramInfoSave={() =>
                  props.UpdateProgramInfo(x, lang, handlers)
                }
                handleLessonCreate={(lessonTitle) =>
                  props.AddProgramLesson(
                    lessonTitle,
                    x,
                    props.programContent,
                    lang,
                    handlers,
                    props.publishDegree.isMasterDegree,
                    marketTagId,
                  )
                }
                handleLessonEdit={(lessonId) => {
                  props.HandleEditLesson(degreeId, lessonId, lang);
                }}
                handleCreateProgram={() =>
                  props.ToggleProgramEditorVisibility(
                    !props.isProgramEditorVisible,
                  )
                }
                setActiveMenuLessonId={(lessonId) =>
                  props.setActiveMenuLessonId(lessonId)
                }
                activeMenuLessonId={props.activeMenuLessonId}
                showModal={this.showModal}
                setDuplicationData={props.SetDuplicationData}
                setActiveMenuProgramId={props.setActiveMenuProgramId}
                currentEditingProgramId={props.currentEditingProgramId}
                setNotificationData={props.SetNotificationData}
                setShowNotificationModal={props.SetShowNotificationModal}
                expandCollapseProgram={() =>
                  props.ExpandCollapseProgram(x.programId, lang, x.expanded)
                }
                setShowCloneModal={this.changeCloneModalVisibility(true)}
                setupCloneLessonData={this.setupCloneLessonData}
                showDeleteModal={this.setDeleteModalVisibility(true)}
                setDeleteDocumentData={this.setDeleteDocumentData}
                navigateToLessonPublishPage={this.navigateToLessonPublishPage}
                showLessonRelocationModal={(lessonId, fromProgramId) =>
                  this.setLessonRelocationData(lessonId, fromProgramId)
                }
                programCnt={props.programContent.length}
                lessonMoveUp={(lessonId) =>
                  props.LessonMoveUp(lang, x.programId, lessonId)
                }
                lessonMoveDown={(lessonId) =>
                  props.LessonMoveDown(lang, x.programId, lessonId)
                }
                setAsReadyEventHandler={this.setAsReadyEventHandler}
                setAsDraftEventHandler={this.setAsDraftEventHandler}
                navigateToAddToMarketPage={this.navigateToAddToMarketPage}
                isMasterContent={this.props.publishDegree.isMasterDegree}
                programId={x.programId}
                degreeId={degreeId}
                goToBadgesScreen={(lessonId) =>
                  props.history.push(
                    getPath("lessonBadges", degreeId, lessonId, lang),
                    {
                      wordingModalKey: "SuccessfulPublishedMessage",
                      backTo: `/degree/build/${degreeId}/${lang}`,
                    },
                  )
                }
              />
            ))}
          </div>
          <DuplicationModal
            hideModal={this.hideModal}
            visible={props.showDuplicationModal}
            sourceLang={props.duplicationData.sourceLang}
            availableItemLanguages={props.duplicationData.availableLanguages}
            currentItemId={props.duplicationData.itemId}
            duplicationHandler={(destLang) => {
              switch (props.duplicationData.type) {
                case DEGREE_PARTS.LESSON:
                  props.DuplicateLesson(
                    props.duplicationData.itemId,
                    props.duplicationData.sourceLang,
                    destLang,
                    props.duplicateLessonRequest,
                    this.refetch,
                  );
                  break;
                case DEGREE_PARTS.PROGRAM:
                  props.DuplicateProgram(
                    props.duplicationData.itemId,
                    props.duplicationData.sourceLang,
                    destLang,
                    props.duplicateProgramRequest,
                    props.updateProgramStatus,
                    this.refetch,
                  );
                  break;
                default:
                  break;
              }
            }}
          />
          <CloneModal
            hideModal={this.changeCloneModalVisibility(false)}
            cloneHandler={this.cloneLesson}
            visible={this.state.showCloneModal}
            cloneMessage={
              this.state.cloneDocumentData.type === DEGREE_PARTS.LESSON ? (
                <FormattedMessage {...translations.LessonCloneMessage} />
              ) : (
                <FormattedMessage {...translations.ProgramCloneMessage} />
              )
            }
            cloneModalTitle={
              <FormattedMessage {...translations.CloneModalTitle} />
            }
          />
          <DeleteModal
            deleteMessage={
              <FormattedMessage
                {...translations.DeleteMessage}
                values={{ mark: "?" }}
              />
            }
            actionHandler={this.deleteDocument}
            setShowModal={this.setDeleteModalVisibility(false)}
            showModal={this.state.deleteModalVisibility}
          />
          <FormattedMessage
            {...translations.NotificationModalMessagePlaceholder}
          >
            {(message) => (
              <SendNotificationModal
                title={
                  <FormattedMessage {...translations.NotificationModalTitle} />
                }
                description={
                  <FormattedMessage
                    {...translations.NotificationModalDescription}
                  />
                }
                placeholder={message[0]}
              />
            )}
          </FormattedMessage>
          <LessonRelocationModal
            visible={this.state.showLessonRelocationModal}
            hideModal={this.updateLessonRelocationModalVisibility(false)}
            programs={props.programContent}
            actualProgramId={this.state.lessonRelocationData.fromProgramId}
            onConfirm={(programId) => this.moveLessonToProgram(programId)}
            degreeLanguage={LANGUAGES.find((x) => x.Code === lang).Name}
            degreeName={props.publishDegree.title}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  currentEditingProgram: state.degrees.currentEditingProgram,
  isProgramEditorVisible: state.degrees.isProgramEditorVisible,
  visibleTagSelector: state.degrees.visibleTagSelector,
  programContent: state.degrees.programContent,
  activeMenuLessonId: state.degrees.activeMenuLessonId,
  showDuplicationModal: state.duplication.showModal,
  duplicationData: state.duplication.duplicationData,
  currentEditingProgramId: state.degrees.currentEditingProgramId,
  isLoaderActive: state.navigation.isLoaderActive,
  documentListWorkflow: state.documents.listWorkflow,
  publishDegree: state.degrees.publishDegree,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  UpdateProgramTitle: (title, currentEditingProgram) => {
    currentEditingProgram.title = title;
    dispatch(SetCurrentEditProgramInfo({ ...currentEditingProgram }));
  },
  UpdateProgramSumarry: (summary, currentEditingProgram) => {
    currentEditingProgram.summary = summary;
    dispatch(SetCurrentEditProgramInfo({ ...currentEditingProgram }));
  },
  AddOrUpdateProgram: (
    degreeId,
    lang,
    currentEditingProgram,
    handlers,
    refetch,
    isMasterDegree,
  ) => {
    if (!currentEditingProgram.programId) {
      let title = currentEditingProgram.title;
      handlers.createProgram(degreeId, lang, title).then(({ data }) => {
        const newProgramId = data.admin.program.create.programId;
        if (isMasterDegree) {
          handlers.updateProgramTags(
            newProgramId,
            lang,
            clientConfig.MASTER_DEGREE_TAG_ID
              ? [clientConfig.MASTER_DEGREE_TAG_ID]
              : [],
          );
        }
        currentEditingProgram.programId = newProgramId;
        const dispatcher = () => {
          dispatch(SetCurrentEditProgramInfo({ ...currentEditingProgram }));
          dispatch(
            SetCurrentEditProgramInfo({
              title: "",
              summary: "",
              programId: undefined,
            }),
          );
          dispatch(ChangeCurrentEditProgramInfoVisibility(false));
          refetch();
        };
        if (currentEditingProgram.summary) {
          handlers
            .updateSummary(
              currentEditingProgram.programId,
              lang,
              currentEditingProgram.summary,
            )
            .then(() => {
              dispatcher();
            });
        } else {
          dispatcher();
        }
      });
    } else {
      handlers.updateTitle(
        currentEditingProgram.programId,
        lang,
        currentEditingProgram.title,
      );
      if (currentEditingProgram.summary) {
        handlers.updateSummary(
          currentEditingProgram.programId,
          lang,
          currentEditingProgram.summary,
        );
      }
    }
  },
  SetCurrentDegreeProgramContent: (content) => {
    dispatch(SetCurrentDegreeProgramContent(content));
  },
  AddOrUpdateLesson: (
    currentEditingProgram,
    lang,
    title,
    degreeId,
    handlers,
    refetch,
    isMasterContent,
  ) => {
    if (!currentEditingProgram.programId) {
      handlers.createProgram(degreeId, lang, "").then(({ data }) => {
        let programId = data.admin.program.create.programId;
        currentEditingProgram.programId = programId;
        dispatch(SetCurrentEditProgramInfo({ ...currentEditingProgram }));
        dispatch(SetCurrentEditProgramInfo({ ...currentEditingProgram }));
        handlers.createLesson(programId, lang, title).then(({ data }) => {
          if (isMasterContent) {
            handlers.addLessonTags(
              data.admin.lesson.create.lessonId,
              lang,
              clientConfig.MASTER_DEGREE_TAG_ID
                ? [clientConfig.MASTER_DEGREE_TAG_ID]
                : [],
            );
          }
        });
        refetch();
      });
    } else {
      handlers
        .createLesson(currentEditingProgram.programId, lang, title)
        .then(({ data }) => {
          if (isMasterContent) {
            handlers.addLessonTags(
              data.admin.lesson.create.lessonId,
              lang,
              clientConfig.MASTER_DEGREE_TAG_ID
                ? [clientConfig.MASTER_DEGREE_TAG_ID]
                : [],
            );
          }
        });
    }
  },
  EditProgramTitle: (title, program, programsList) => {
    program.title = title;
    let index = programsList.findIndex(
      (x) => x.programId === program.programId,
    );
    programsList[index] = { ...program };
    dispatch(SetCurrentDegreeProgramContent([...programsList]));
  },
  EditProgramSummary: (summary, program, programsList) => {
    if (summary !== program.summary) {
      program.summary = summary;
      let index = programsList.findIndex(
        (x) => x.programId === program.programId,
      );
      programsList[index] = { ...program };
      dispatch(SetCurrentDegreeProgramContent([...programsList]));
    }
  },
  UpdateProgramInfo: (program, lang, handlers) => {
    if (program.title) {
      handlers.updateTitle(program.programId, lang, program.title);
    }
    if (program.summary) {
      handlers.updateSummary(program.programId, lang, program.summary);
    }
  },
  AddProgramLesson: (
    title,
    program,
    programsList,
    lang,
    handlers,
    isMasterContent,
    marketId,
  ) => {
    handlers
      .createLesson(program.programId, lang, title)
      .then(async ({ data }) => {
        const { lessonId } = data.admin.lesson.create;
        handlers.updatePicCover(lessonId, lang, PlaceHolderDegree);
        handlers.updatePicCard(lessonId, lang, PlaceHolderDegree);
        handlers.updatePicThumb(lessonId, lang, PlaceHolderDegree);
        if (marketId) {
          await handlers.addLessonTags(
            data.admin.lesson.create.lessonId,
            lang,
            marketId,
          );
        }
        if (isMasterContent) {
          handlers.addLessonTags(
            data.admin.lesson.create.lessonId,
            lang,
            clientConfig.MASTER_DEGREE_TAG_ID
              ? [clientConfig.MASTER_DEGREE_TAG_ID]
              : [],
          );
        }
        dispatch(ReFetchProgramLessons(program.programId, lang));
      });
  },
  DeleteLesson: (lessonId, program, lang, updateStatus) => {
    updateStatus(lang, lessonId, DOC_WORKFLOW_ENUM.ARCHIVED, () => {
      dispatch(ReFetchProgramLessons(program.programId, lang));
    });
  },
  MoveLessonToProgram: (
    lessonId,
    fromProgramId,
    toProgramId,
    lang,
    addLessonRequest,
    removeLessonRequest,
    onSuccess,
  ) => {
    addLessonRequest(toProgramId, lang, lessonId, () => {
      removeLessonRequest(fromProgramId, lang, lessonId).then(() => {
        dispatch(ReFetchProgramLessons(fromProgramId, lang)).then(() => {
          dispatch(ReFetchProgramLessons(toProgramId, lang)).then(() => {
            onSuccess();
            dispatch(
              SetSuccessMessage(
                <FormattedMessage {...translations.LessonRelocationMessage} />,
              ),
            );
          });
        });
      });
    });
  },
  DeleteProgram: (programId, lang, deleteProgram, refetch) => {
    deleteProgram(programId, lang, () => {
      dispatch(
        SetSuccessMessage(
          <FormattedMessage
            {...translations.SuccessfullyDeleteProgramMessage}
          />,
        ),
      );
      refetch();
    });
  },
  HandleEditLesson: (degreeId, lessonId, language) => {
    dispatch(SetCurrentLessonId(lessonId));
    window.open(`/lessons/${degreeId}/${lessonId}/${language}`, "_blank");
  },
  ToggleProgramEditorVisibility: (isVisible) => {
    dispatch(
      SetCurrentEditProgramInfo({
        title: "",
        summary: "",
        programId: undefined,
      }),
    );
    dispatch(ChangeCurrentEditProgramInfoVisibility(isVisible));
  },
  setActiveMenuLessonId: (lessonId) => {
    dispatch(SetActiveMenuLessonId(lessonId));
  },
  setActiveMenuProgramId: (programId) => {
    dispatch(SetCurrentEditProgramId(programId));
  },
  DuplicateLesson: (lessonId, sourceLang, destLang, duplicate, refetch) => {
    duplicate(lessonId, sourceLang, destLang, () => {
      refetch();
      dispatch(
        SetSuccessMessage(
          <FormattedMessage {...translations.LessonDuplicatedMessage} />,
        ),
      );
    });
  },
  CloneLesson: (lessonId, lang, programId, clone, refetch) => {
    clone(lessonId, lang, programId, () => {
      refetch();
      dispatch(
        SetSuccessMessage(
          <FormattedMessage {...translations.LessonSuccessfulCloneMessage} />,
        ),
      );
    });
  },
  CloneProgram: (programId, lang, clone, refetch) => {
    clone(programId, lang, () => {
      refetch();
      dispatch(
        SetSuccessMessage(
          <FormattedMessage {...translations.ProgramSuccessfulCloneMessage} />,
        ),
      );
    });
  },
  DuplicateProgram: (
    programId,
    sourceLang,
    destLang,
    duplicate,
    updateStatus,
    refetch,
  ) => {
    duplicate(programId, sourceLang, destLang, () => {
      updateStatus(programId, destLang, DOC_WORKFLOW_ENUM.PUBLISHED, () => {
        refetch();
        dispatch(
          SetSuccessMessage(
            <FormattedMessage {...translations.ProgramDuplicatedMessage} />,
          ),
        );
      });
    });
  },
  SetShowModal: (newValue) => {
    dispatch(SetShowModal(newValue));
  },
  SetDuplicationData: (newValue) => {
    dispatch(SetDuplicationData(newValue));
  },
  SetNotificationData: (newValue) => {
    dispatch(SetNotificationData(newValue));
  },
  SetShowNotificationModal: (newValue) => {
    dispatch(SetShowNotificationModal(newValue));
  },
  GetDegreePrograms: (degreeId, lang, onSuccess) => {
    dispatch(GetDegreePrograms(degreeId, lang, onSuccess));
  },
  ExpandCollapseProgram: (programId, lang, isExpanded) => {
    if (isExpanded) {
      dispatch(SetLastExpandedProgramId(undefined));
      dispatch(SetProgramExpandedStatus(programId, !isExpanded));
    } else {
      dispatch(SetLastExpandedProgramId(programId));
      dispatch(
        SetProgramLessons(programId, lang, () => {
          dispatch(SetProgramExpandedStatus(programId, !isExpanded));
        }),
      );
    }
  },
  LessonMoveUp: (lang, programId, lessonId) => {
    dispatch(
      LessonMoveUp(lang, programId, lessonId, () => {
        dispatch(ReFetchProgramLessons(programId, lang));
      }),
    );
  },
  LessonMoveDown: (lang, programId, lessonId) => {
    dispatch(
      LessonMoveDown(lang, programId, lessonId, () => {
        dispatch(ReFetchProgramLessons(programId, lang));
      }),
    );
  },
  SetAsDraftLesson: (programId, lessonId, lang, updateStatus) => {
    updateStatus(lang, lessonId, DOC_WORKFLOW_ENUM.DRAFT, () => {
      dispatch(
        SetSuccessMessage(
          <FormattedMessage {...translations.StatusUpdateMessage} />,
        ),
      );
      dispatch(ReFetchProgramLessons(programId, lang));
    });
  },
  SetAsReadyLesson: (programId, lessonId, lang, updateStatus) => {
    updateStatus(lang, lessonId, DOC_WORKFLOW_ENUM.PUBLISHED, () => {
      dispatch(
        SetSuccessMessage(
          <FormattedMessage {...translations.StatusUpdateMessage} />,
        ),
      );
      dispatch(ReFetchProgramLessons(programId, lang));
    });
  },
  SetCloningContext: (degreeId, programId, lessonId) => {
    dispatch(SetCurrentCloningIds(degreeId, programId, lessonId));
  },
});

const Container = compose(
  updateTitle,
  updateSummary,
  createProgram,
  createLesson,
  updatePicCover,
  updatePicCard,
  updatePicThumb,
  addTagsToLesson,
  updateLessonStatus,
  duplicateLessonRequest,
  duplicateProgramRequest,
  updateProgramStatus,
  deleteProgram,
  cloneLessonRequest,
  cloneProgramRequest,
  addLessonToProgram,
  removeLessonFromProgram,
  updateProgramTags,
)(withThemedStyle(DegreesBuilderStyle)(DegreeBuilder));

export default withRouter(
  connect(mapStateToProp, mapDispatchToProps)(Container),
);
