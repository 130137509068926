import React from "react";
import PropTypes from "prop-types";

export const getUri = (source, format, res = "hd") => {
  if (typeof source !== "string" || source.includes(".gif")) return source;

  const extracted = source.match(
    /^(http[s]?:\/\/)?([^/\s]+\/)?([^/\s]+\/)(.*)$/,
  );

  if (
    !extracted ||
    !extracted[2].match(/mediastore(.+).blob.core.windows.net\//)
  )
    return source;

  const cdnUrl = `${extracted[1]}${extracted[2]}`;
  const container = extracted[3].slice(0, -1);
  const dimension = format !== "original" ? `${res}_${format}_` : "";
  const encodedFilename = encodeURIComponent(decodeURIComponent(extracted[4]))
    .split("%2F")
    .join("/");

  return `${cdnUrl}${container}/${dimension}${encodedFilename}`;
};

const ImageOpti = ({ source, format, res }) => {
  const uri = getUri(source, format, res);

  return <img src={uri} />;
};

ImageOpti.propTypes = {
  source: PropTypes.string.isRequired,
  format: PropTypes.oneOf([
    "original",
    "fullScreen",
    "card",
    "thumb",
    "smallThumb",
    "profile",
  ]).isRequired,
  res: PropTypes.string,
};

export default ImageOpti;
