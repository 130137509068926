import { useRef, useState } from "react";

const useQuestions = (questions, createNewQuestion, handleChange) => {
  const questionsRef = useRef([]);
  questionsRef.current = questions;
  const [editedQuestionIndex, setEditedQuestionIndex] = useState(null);

  const handleQuestionChange = (
    changedQuestionIndex,
    changedQuestionData,
    questionsToUse,
  ) =>
    handleChange({
      questions: (questionsToUse || questionsRef.current).map(
        (question, index) =>
          changedQuestionIndex === index
            ? {
                ...question,
                ...changedQuestionData,
              }
            : question,
      ),
    });

  const removeQuestion = (removedQuestionIndex) =>
    handleChange(
      {
        questions: questionsRef.current.filter(
          (question, index) => removedQuestionIndex !== index,
        ),
      },
      true,
    );

  const addQuestion = (index) => {
    const newQuestions = [
      ...questionsRef.current.slice(0, index),
      ...createNewQuestion(),
      ...questionsRef.current.slice(index, questionsRef.current.length),
    ].map((question) => ({
      ...question,
      timer: 20,
      score: 0,
    }));
    handleChange({ questions: newQuestions }, true);
  };

  const addQuestionBefore = (index) => addQuestion(index);
  const addQuestionAfter = (index) => addQuestion(index + 1);

  return {
    editedQuestionIndex,
    setEditedQuestionIndex,
    handleQuestionChange,
    removeQuestion,
    addQuestionBefore,
    addQuestionAfter,
  };
};

export default useQuestions;
