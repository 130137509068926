import { SetFetchIndicator } from "../common/actions";
import client from "../../graphql/client";
import {
  DOC_WORKFLOW_ENUM,
  DOCUMENT_TYPES,
  DOCUMENT_WORKFLOW_IDS,
} from "../../configs/constants";
import { SetErrorMessage } from "../notification/actions";
import {
  GET_ALL_DOCUMENTS,
  GET_DOCUMENTS_BY_TYPE,
} from "../../graphql/remote/documents/queries";
import { getFormattedFilteredDocuments } from "./transformers";
import {
  SET_FILTERS,
  SET_MASTER_DOCUMENTS,
  UPDATE_PAGE,
  RESET_MASTER_DOCUMENTS_STORE,
  RESET_MASTER_DOCUMENTS,
} from "../../constants/store/masterDocuments";
import clientConfig from "../../configs/client";
import {
  INSIGHT_COPY_FOR_LANGUAGE,
  INSIGHT_UPDATE_WORFLOW,
} from "../../graphql/remote/insights/mutations";
import {
  CARD_COPY_FOR_LANGUAGE,
  CARD_UPDATE_WORKFLOW,
} from "../../graphql/remote/cards/mutations";
import { BATTLE_UPDATE_WORFLOW } from "../../graphql/remote/battle/mutations/UpdateBattleWorkflow";
import { POLL_UPDATE_WORKFLOW } from "../../graphql/remote/poll/mutations/UpdatePollWorkflow";
import { COPY_BATTLE } from "../../graphql/remote/battle/mutations/CopyBattle";
import { COPY_POLL } from "../../graphql/remote/poll/mutations/CopyPoll.graphql";

export const SetMasterDocuments = (data) => (dispatch) => {
  dispatch({ type: SET_MASTER_DOCUMENTS, data });
};

export const ResetMasterDocuments = (data) => (dispatch) => {
  dispatch({ type: RESET_MASTER_DOCUMENTS, data });
};

export const ResetMasterContent = () => (dispatch) => {
  dispatch({ type: RESET_MASTER_DOCUMENTS_STORE });
};

export const getMasterDocuments =
  (reset = false) =>
  async (dispatch, getState) => {
    const {
      filters: {
        language: { value: langCode },
        contentType: { value: docType },
      },
      limit,
      page,
    } = getState().masterDocuments;
    try {
      dispatch(SetFetchIndicator(true));
      const result = await client.query({
        fetchPolicy: "network-only",
        query: docType ? GET_DOCUMENTS_BY_TYPE : GET_ALL_DOCUMENTS,
        variables: {
          type: docType,
          skip: page * limit,
          limit,
          lang: langCode,
          tagIds: clientConfig.MASTER_DEGREE_TAG_ID
            ? [clientConfig.MASTER_DEGREE_TAG_ID]
            : [],
          workflowIds: [
            DOCUMENT_WORKFLOW_IDS.DRAFT,
            DOCUMENT_WORKFLOW_IDS.PUBLISHED,
            DOCUMENT_WORKFLOW_IDS.UNPUBLISHED,
          ],
        },
      });
      const formattedDocs = getFormattedFilteredDocuments(
        langCode,
        result.data.admin.documents,
      );
      dispatch(
        reset
          ? ResetMasterDocuments(formattedDocs)
          : SetMasterDocuments(formattedDocs),
      );
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while fetching the master documents",
          e,
        ),
      );
    } finally {
      dispatch(SetFetchIndicator(false));
    }
  };

export const SetFilters = (type, data) => (dispatch) => {
  dispatch({
    type: SET_FILTERS,
    data: { type, data },
  });
};

export const UpdatePage = () => (dispatch) => {
  dispatch({ type: UPDATE_PAGE });
};

export const DeleteDocument =
  (docId, docType, callback) => async (dispatch, getState) => {
    const {
      filters: {
        language: { value: lang },
      },
    } = getState().masterDocuments;
    let mutation = "";
    let variables = { status: DOC_WORKFLOW_ENUM.ARCHIVED };

    switch (docType) {
      case DOCUMENT_TYPES.POLL:
        mutation = POLL_UPDATE_WORKFLOW;
        variables = { ...variables, pollId: docId, lang };
        break;
      case DOCUMENT_TYPES.BATTLE:
        mutation = BATTLE_UPDATE_WORFLOW;
        variables = { ...variables, battleId: docId, lang };
        break;
      case DOCUMENT_TYPES.INSIGHT:
        mutation = INSIGHT_UPDATE_WORFLOW;
        variables = { ...variables, insightId: docId, lang };
        break;
      case DOCUMENT_TYPES.CARD:
        mutation = CARD_UPDATE_WORKFLOW;
        variables = { ...variables, cardId: docId, lang };
        break;
    }

    await client.mutate({
      mutation,
      variables,
      update: () => callback && callback(),
    });
  };

export const TranslateDocument =
  (docId, docType, destLang, callback) => async (dispatch, getState) => {
    const {
      filters: {
        language: { value: sourceLang },
      },
    } = getState().masterDocuments;
    let mutation = "";
    let variables = { sourceLang, destLang };

    switch (docType) {
      case DOCUMENT_TYPES.POLL:
        mutation = COPY_POLL;
        variables = { ...variables, pollId: docId };
        break;
      case DOCUMENT_TYPES.BATTLE:
        mutation = COPY_BATTLE;
        variables = { ...variables, battleId: docId };
        break;
      case DOCUMENT_TYPES.INSIGHT:
        mutation = INSIGHT_COPY_FOR_LANGUAGE;
        variables = { ...variables, insightId: docId };
        break;
      case DOCUMENT_TYPES.CARD:
        mutation = CARD_COPY_FOR_LANGUAGE;
        variables = { ...variables, cardId: docId };
        break;
    }

    await client.mutate({
      mutation,
      variables,
      update: () => callback && callback(),
    });
  };

export const UpdateDocumentWorkflow =
  (docId, docType, lang, status, callback) => async () => {
    let mutation = "";
    let variables = { status, lang };

    switch (docType) {
      case DOCUMENT_TYPES.POLL:
        mutation = POLL_UPDATE_WORKFLOW;
        variables = { ...variables, pollId: docId };
        break;
      case DOCUMENT_TYPES.BATTLE:
        mutation = BATTLE_UPDATE_WORFLOW;
        variables = { ...variables, battleId: docId };
        break;
      case DOCUMENT_TYPES.INSIGHT:
        mutation = INSIGHT_UPDATE_WORFLOW;
        variables = { ...variables, insightId: docId };
        break;
      case DOCUMENT_TYPES.CARD:
        mutation = CARD_UPDATE_WORKFLOW;
        variables = { ...variables, cardId: docId };
        break;
    }

    await client.mutate({
      mutation,
      variables,
      update: () => callback && callback(),
    });
  };
