import React from "react";
import { REACT_APP_CLIENT_NAME } from "../../configs/client";
import ShiseidoPublishPage from "./SHISEIDO/PublishDegree";
import DefaultPublishPage from "./PublishDegree";
import PublishPage from "../publish/index";

const PublishDegree = (props) => {
  switch (REACT_APP_CLIENT_NAME) {
    case "SHISEIDO":
    case "SHISEIDO-PREPROD":
    case "SHISEIDO-PROD":
      return <ShiseidoPublishPage />;
    default:
      // TODO: We should use this one
      // return (<PublishPage {...props} />)
      return <DefaultPublishPage />;
  }
};

export default PublishDegree;
