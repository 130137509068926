import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonSize,
  ButtonVariation,
  Icon,
  Icons,
  StylelessModal,
  TagVariation,
  Tag,
  TagWithIcon,
} from "genius-ui";
import { createUseStyles } from "react-jss";
import style from "./SendNotificationModal.style";
import { FormattedMessage } from "react-intl";
import MultiLineTextInput from "../MultiLineTextInput";
import { connect } from "react-redux";
import {
  SendNotificationMessageWithRecipientProfiles,
  SetNotificationData,
  SetShowModal,
  SetMarketAndBrandTags,
} from "../../../store/sendNotification/actions";
import { SetSuccessMessage } from "../../../store/notification/actions";
import { translations } from "./SendNotificationModal.translations";
import IntersectTagsWithUserTags from "../../IntersectTagsWithUserTags";
import clientConfig from "../../../configs/client";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const SendNotificationModal = (props) => {
  const {
    visible,
    hideModal,
    SetNotificationData,
    notificationData,
    title,
    description,
    placeholder,
    SendNotificationMessage,
    SetMarketAndBrandTags,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  useEffect(() => {
    if (notificationData.docId && visible) {
      SetMarketAndBrandTags(Number(notificationData.docId));
    }
  }, [notificationData.docId, visible]);

  const setTagIds = (newTagId) => {
    const newTagIds = notificationData.tagIds.includes(newTagId)
      ? notificationData.tagIds.filter((tagId) => tagId !== newTagId)
      : [...notificationData.tagIds, newTagId];
    SetNotificationData({ ...notificationData, tagIds: newTagIds });
  };

  return (
    <StylelessModal
      className={classes.modalFrameStyle}
      show={visible}
      onClose={hideModal}
      centered
    >
      <div className={classes.modalContent}>
        <div className={classes.header}>
          <div className={classes.title}>{title}</div>
          <Icon
            handleClick={hideModal}
            iconName={Icons.slimCloseIcon}
            style={classes.close}
          />
        </div>
        <div className={classes.body}>
          <div className={classes.description}>{description}</div>
          <div className={classes.messageLabel}>
            <FormattedMessage {...translations.MessageLabel} />
          </div>
          <MultiLineTextInput
            containerClassName={classes.notificationInput}
            label={placeholder}
            value={notificationData.message}
            onChange={(e) =>
              SetNotificationData({
                ...notificationData,
                message: e.target.value,
              })
            }
          />
          <IntersectTagsWithUserTags
            tags={notificationData.selectableTags.map((tag) => ({
              ...tag,
              Id: tag.id,
            }))}
            userTagClusterIds={clientConfig.USER_MARKET_CLUSTER_IDS}
          >
            {({ filteredTags }) =>
              filteredTags.length ? (
                <div className={classes.tags}>
                  {filteredTags.map((tag) => {
                    const isSelected = notificationData.tagIds.includes(tag.id);
                    if (isSelected) {
                      return (
                        <TagWithIcon
                          label={tag.label}
                          key={tag.key}
                          variation={TagVariation.blue}
                          onClickHandler={() => setTagIds(tag.id)}
                          isSelected
                          iconName={Icons.check}
                        />
                      );
                    } else {
                      return (
                        <Tag
                          label={tag.label}
                          key={tag.key}
                          variation={TagVariation.gray}
                          onClickHandler={() => setTagIds(tag.id)}
                          isSelected
                        />
                      );
                    }
                  })}
                </div>
              ) : null
            }
          </IntersectTagsWithUserTags>
          <div className={classes.sendButton}>
            <Button
              disabled={!notificationData.message}
              handleClick={() => SendNotificationMessage()}
              variation={ButtonVariation.primary}
              size={ButtonSize.small}
            >
              <FormattedMessage {...translations.SendButtonLabel} />
            </Button>
          </div>
        </div>
      </div>
    </StylelessModal>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  SetNotificationData: (newData) => {
    dispatch(SetNotificationData(newData));
  },
  SetMarketAndBrandTags: (docId) => {
    dispatch(SetMarketAndBrandTags(docId));
  },
  hideModal: () => {
    dispatch(SetShowModal(false));
  },
  SendNotificationMessage: (notificationData) => {
    dispatch(
      SendNotificationMessageWithRecipientProfiles((send, mute) => {
        dispatch(
          SetSuccessMessage(
            <FormattedMessage
              {...translations.SendNotificationMessageSuccessMessage}
              values={{ send, mute }}
            />,
          ),
        );
      }),
    );
  },
});

const mapStateToProp = (state) => ({
  notificationData: state.sendNotification.notificationData,
  visible: state.sendNotification.showModal,
});

const PropTypeStringOrObject = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
]);

SendNotificationModal.propTypes = {
  /** Notification modal title */
  title: PropTypeStringOrObject.isRequired,
  /** Notification modal description */
  description: PropTypeStringOrObject.isRequired,
  /** Notification text input placeholder */
  placeholder: PropTypeStringOrObject,
};

export default connect(
  mapStateToProp,
  mapDispatchToProps,
)(SendNotificationModal);
