import { DefaultVideoCover } from "assets/icons";

export const VideoStyle = (theme) => ({
  videoContainer: {
    width: (props) => props.width,
    height: (props) => props.height,
    display: "flex",
    flexDirection: "column",
    // border: props => (props.isSelected ? 'solid 2px #468ee3' : 'none'),
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    position: "relative",
  },
  uploadControlsBox: {
    position: "absolute",
    fontFamily: theme.FontFamily,
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: 0.07,
    color: theme.Video.uploadControlsBoxColor,
    zIndex: 99998,
    display: "flex",
    justifyContent: "flex-start",
    top: 0,
    left: 0,
    width: "100%",
  },
  uploadBtnWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
    },
  },
  uploadInput: {
    fontSize: "100px",
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  btnUpload: {
    border: "none",
    fontSize: "14px",
    background: "transparent",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    color: theme.Video.btnUploadColor,
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
    },
    padding: 10,
    "& > span": {
      paddingRight: 5,
      "&:hover::before": {
        color: theme.Colors.lightBlue,
        cursor: "pointer",
      },
    },
    "&:hover *": {
      color: theme.Colors.lightBlue,
      cursor: "pointer",
      "&::before": {
        color: theme.Colors.lightBlue,
        cursor: "pointer",
      },
    },
  },
  iconAction: {
    cursor: "pointer",
    "&:hover::before": {
      color: `${theme.Colors.lightBlue}`,
      fontSize: 15,
      cursor: "pointer",
    },
    "&::before": {
      color: `${theme.Colors.white}`,
      fontSize: 15,
      cursor: "pointer",
    },
  },
  progressContainer: {
    backgroundImage: `url("${DefaultVideoCover}")`,
    width: "100%",
    height: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    zIndex: 99999,
    paddingLeft: 10,
    paddingRight: 10,
  },
  titleProgress: {
    fontFamily: theme.FontFamily,
    fontSize: 24,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: 0.08,
    textAlign: "center",
    color: theme.Video.titleProgressColor,
  },
  descriptionProgress: {
    fontFamily: theme.FontFamily,
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: 0.07,
    color: theme.Video.descriptionProgressColor,
  },
});
