import React from "react";
import PropTypes from "prop-types";
import { REACT_APP_CLIENT_NAME } from "../../../configs/client";
import ShiseidoFilterHeader from "./ShiseidoFilterHeader";
import DefaultFilterHeader from "./DefaultFilterHeader";

const FilterHeader = (props) => {
  switch (REACT_APP_CLIENT_NAME) {
    case "SHISEIDO":
    case "SHISEIDO-PREPROD":
    case "SHISEIDO-PROD":
      return <ShiseidoFilterHeader {...props} />;
    default:
      return <DefaultFilterHeader {...props} />;
  }
};

FilterHeader.propTypes = {
  /** function executed after any of the filters change **/
  onFilterChange: PropTypes.func,
};

export default FilterHeader;
