import React from "react";
import {
  DefaultSlider1,
  DefaultSlider2,
  DefaultSlider3,
  DefaultVideo,
  DefaultVideoCover,
  SimpleSlideTemplateImage,
  SlideWithMarkerTemplateImage,
} from "assets/icons";
import SliderPageEditorReadOnly from "../../sliderPageEditorReadOnly";
import SliderReadOnly from "../../../common/Slider/sliderReadOnly";
import MarkerSlideHorizontalReadOnly from "../../../common/MarkerSlideHorizontal/markerSliderHorizontalReadOnly";
import { FILE_TYPES, PAGE_TYPES } from "../../../../configs/constants";
import {
  IMAGE_FULL_SCREEN_NAME,
  IMAGE_FULL_SCREEN_MARKER_NAME,
} from "../../../../utils/ui-generator/sliderFullScreen.template";
import { decodeNewLines } from "../../../../utils/string";
import { translations } from "../Horizontal.translations";

const DefaultSliderImages = [DefaultSlider1, DefaultSlider2, DefaultSlider3];

const defaultNewElement = {
  type: IMAGE_FULL_SCREEN_NAME,
  title: "",
  description: "",
  image: "",
  defaultImage: DefaultSliderImages[0],
};

const defaultNewMarkerElement = {
  type: IMAGE_FULL_SCREEN_MARKER_NAME,
  image: "",
  defaultImage: DefaultSliderImages[0],
  points: [],
};

const availableSlideTemplates = [
  {
    id: defaultNewElement.type,
    name: translations.Simple,
    image: SimpleSlideTemplateImage,
    defaultProps: defaultNewElement,
  },
  {
    id: defaultNewMarkerElement.type,
    name: translations.WithMarker,
    image: SlideWithMarkerTemplateImage,
    defaultProps: defaultNewMarkerElement,
  },
];

const getDefaultNewElement = (slide) => {
  switch (slide.type) {
    case IMAGE_FULL_SCREEN_MARKER_NAME:
      return defaultNewMarkerElement;
    default:
      return defaultNewElement;
  }
};

const GetDecodedCardsArray = (sliderJsonData) => {
  const dataObject = JSON.parse(sliderJsonData);
  const cardsArray =
    dataObject.data && dataObject.data.cards ? dataObject.data.cards : [];
  if (cardsArray !== undefined && cardsArray.length > 0) {
    return cardsArray.map((x) => {
      let fileType = FILE_TYPES.VIDEO;
      let image = DefaultSlider1;
      let videoElement = null;
      switch (x.type) {
        case IMAGE_FULL_SCREEN_MARKER_NAME:
          return {
            type: x.type,
            image: x.image && x.image.url ? x.image.url : DefaultSlider1,
            points: x.points.map((point) => ({
              ...point,
              description: point.description
                ? decodeNewLines(point.description)
                : null,
            })),
          };
        default:
          fileType = x.video ? FILE_TYPES.VIDEO : FILE_TYPES.IMAGE;
          image = x.image && x.image.url ? x.image.url : DefaultSlider1;
          if (fileType === FILE_TYPES.VIDEO) {
            image = x.video.urlBackgroundFull
              ? x.video.urlBackgroundFull
              : DefaultSlider1;
          }
          videoElement = x.video
            ? {
                videoSource: x.video.url ? x.video.url : DefaultVideo,
                posterCover: x.video.urlBackgroundFull
                  ? x.video.urlBackgroundFull
                  : DefaultVideoCover,
                formatType: x.formatType,
              }
            : null;

          return {
            type: x.type,
            title: decodeNewLines(x.title),
            description: decodeNewLines(x.description),
            image: image,
            video: videoElement,
            fileType: fileType,
          };
      }
    });
  } else {
    return [{ ...defaultNewElement }];
  }
};

const renderSlideItem = (
  slide,
  index,
  key,
  isSelected,
  addAfter,
  addBefore,
  removeSlide,
  handleChange,
  selectSlide,
  updateCurrentOpenedMarker,
  currentOpenedMarker,
  pageId,
) => {
  let points = slide.points;
  switch (slide.type) {
    case IMAGE_FULL_SCREEN_MARKER_NAME:
      points = !currentOpenedMarker
        ? slide.points
        : slide.points.map((marker, index) =>
            index !== currentOpenedMarker.markerIndex
              ? marker
              : {
                  ...marker,
                  isOpened: true,
                },
          );
      return (
        <MarkerSlideHorizontalReadOnly
          key={key}
          isSelected={isSelected}
          type={slide.type}
          points={points}
          image={slide.image}
          defaultSliderImage={slide.defaultImage}
          onAddToRightHandle={addAfter}
          onAddToLeftHandle={addBefore}
          onRemoveClick={removeSlide}
          onChange={handleChange}
          onClick={selectSlide}
          availableTemplates={availableSlideTemplates}
          onMarkerOpened={updateCurrentOpenedMarker}
          slideId={`${pageId}-${index}`}
        />
      );
    default:
      return (
        <SliderReadOnly
          key={key}
          isSelected={isSelected}
          type={slide.type}
          title={slide.title}
          description={slide.description}
          fileType={slide.fileType}
          image={slide.image}
          video={slide.video}
          defaultSliderImage={slide.defaultImage}
          onAddToRightHandle={addAfter}
          onAddToLeftHandle={addBefore}
          onRemoveClick={removeSlide}
          onChange={handleChange}
          onClick={selectSlide}
          availableTemplates={availableSlideTemplates}
          slideId={`${pageId}-${index}`}
        />
      );
  }
};

const HorizontalSliderPageEditor = (props) => (
  <SliderPageEditorReadOnly
    GetDecodedCardsArray={GetDecodedCardsArray}
    getDefaultNewElement={getDefaultNewElement}
    defaultSliderImages={DefaultSliderImages}
    renderSlideItem={renderSlideItem}
    pageType={PAGE_TYPES.SLIDERFULLSCREEN}
    {...props}
  />
);

HorizontalSliderPageEditor.propTypes = {
  /**
   * All the props accepted by SliderPageEditor
   */
};

export default HorizontalSliderPageEditor;
