import gql from "graphql-tag";

export const POLL_UPDATE_WORKFLOW = gql`
  mutation pollUpdateWorkflow(
    $pollId: ID
    $lang: LangEnum
    $status: DocWorkflowEnum!
    $dateIn: Date
    $dateOut: Date
  ) {
    admin {
      poll(pollId: $pollId, lang: $lang) {
        workflow {
          set(status: $status, dateIn: $dateIn, dateOut: $dateOut)
        }
      }
    }
  }
`;
