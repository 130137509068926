import React from "react";
import { useIntl } from "react-intl";
import { createUseStyles } from "react-jss";

import useThemedStyle from "../../../hooks/style/useThemedStyle";
import WidgetLineStyle from "./WidgetLine.style";
import { WidgetContainer, WidgetSection, QuantityWidget } from "..";

const useStyle = createUseStyles(WidgetLineStyle);

const WidgetLine = (props) => {
  const classes = useThemedStyle(useStyle, props);
  const intl = useIntl();

  return (
    <WidgetSection>
      {props.cards &&
        props.cards.map((card, key) => {
          return (
            <WidgetContainer
              width={100 / props.cards.length}
              className={classes.WidgetContainer}
              key={key}
            >
              <QuantityWidget
                title={intl.formatMessage(
                  { ...card.message },
                  { b: (chunks) => <b>{chunks}</b> },
                )}
                counter={card.value}
                info={card.info}
                infoMultiple={card.infoMultiple}
              />
            </WidgetContainer>
          );
        })}
    </WidgetSection>
  );
};

export default WidgetLine;
