import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { createUseStyles } from "react-jss";
import Style from "./RoundButton.style.js";
import { SIZES, VARIATIONS } from "./RoundButton.constants";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

/** style wrapper around ReactDOM.button **/
const RoundButton = (props) => {
  const { variation, size, className, ...restProps } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <button
      {...restProps}
      className={classNames([classes.container, className])}
    />
  );
};

RoundButton.VARIATIONS = VARIATIONS;

RoundButton.SIZES = SIZES;

RoundButton.propTypes = {
  variation: PropTypes.oneOf(Object.values(RoundButton.VARIATIONS)),
  size: PropTypes.oneOf(Object.values(RoundButton.SIZES)),
  /** + all the props accepted by ReactDOM.button **/
};

RoundButton.defaultProps = {
  variation: RoundButton.VARIATIONS.PRIMARY,
  size: RoundButton.SIZES.NORMAL,
};

export default RoundButton;
