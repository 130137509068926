import gql from "graphql-tag";

export const PROGRAM_COPY_FOR_LANGUAGE = gql`
  mutation programDuplicate(
    $programId: ID
    $sourceLang: LangEnum
    $destLang: LangEnum!
  ) {
    admin {
      program(programId: $programId, lang: $sourceLang) {
        copy(lang: $destLang) {
          programId
          versions {
            lang
            title
            summary
            workflow
            lessons {
              title
            }
          }
        }
      }
    }
  }
`;
