import React, { Component } from "react";
import { connect } from "react-redux";
import { CreateCardStyle } from "../CreateCard.style";
import { getPath } from "../../../core/paths";
import PeanutPageEditorReadOnly from "../../../components/PeanutPageEditor/peanutEditorReadOnly";
import withThemedStyle from "../../../components/hoc/withThemedStyle";
import { Notification } from "genius-ui";
import { FormattedMessage } from "react-intl";
import { translations } from "../CreateCard.translations";
import SideBar from "../../../components/SideBarNavigation";

class CreateCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationIsOpen: true,
    };
  }

  handleBack = () => this.props.history.push(getPath("masterContentManager"));

  closeNotification = () => this.setState({ notificationIsOpen: false });

  render() {
    const props = this.props;
    const { classes } = props;

    return (
      <div>
        <Notification
          message={<FormattedMessage {...translations.ReadOnlyMessage} />}
          status="warning"
          isOpen={this.state.notificationIsOpen}
          handleClose={this.closeNotification}
        />
        <SideBar handleBackClick={this.handleBack} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className={classes.cardEditorContainer}>
            <PeanutPageEditorReadOnly
              cards={props.currentCards}
              pageId={props.currentCardPageId}
              documentId={props.cardId}
              language={props.lang}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  currentCardId: state.cards.currentCardId,
  currentCardPageId: state.cards.currentPageId,
  currentCardLanguage: state.cards.currentLanguage,
  currentCards: state.cards.cards,
  documentListWorkflow: state.documents.listWorkflow,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  navigateToPublishPage: (cardId, language) => {
    ownProps.history.push(getPath("cardPublish", cardId, language));
  },
});

const Container = withThemedStyle(CreateCardStyle)(CreateCard);

export default connect(mapStateToProp, mapDispatchToProps)(Container);
