import gql from "graphql-tag";

export const LOCATIONS_DEFAULT = gql`
  fragment LocationFields on LocationObject {
    locationId
    title
    type
    boutiqueType {
      tagId
      clusterId
      title
    }
    picCover
    addressBuilding
    vmSegmentation {
      tagId
      clusterId
      title
    }
    addressCountry {
      tagId
      clusterId
      title
    }
    addressZone {
      title
      clusterId
      tagId
    }
    addressCity {
      title
      tagId
      clusterId
    }
  }
`;
