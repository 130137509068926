import React from "react";
import PropTypes from "prop-types";
import { BadgeVariations } from "genius-ui";
import styles from "./Badge.style";
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(styles);

const Badge = (props) => {
  const { label, date, variation, picture } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.container}>
      {variation === BadgeVariations.completed && (
        <div className={classes.checkmark}>
          <Checkmark width={27} height={27} />
        </div>
      )}
      <img className={classes.badge} src={picture} alt="" />
      {label && <div className={classes.label}>{label}</div>}
      {date && <div className={classes.date}>{date}</div>}
    </div>
  );
};

Badge.propTypes = {
  /** Badge size */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Badge variation */
  variation: PropTypes.string.isRequired,
  /** Badge label */
  label: PropTypes.string,
  /** Badge date */
  date: PropTypes.string,
  /** Badge picture */
  picture: PropTypes.string.isRequired,
};

Badge.defaultProps = {
  size: 130,
  variation: BadgeVariations.completed,
};

export default Badge;
