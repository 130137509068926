import * as constants from "../../constants/store/referential";
import { CONFIG_CLUSTER } from "../../configs/client";
import { SetErrorMessage } from "../notification/actions";
import { GET_MOCK_DATA } from "../../graphql/remote/mock/queries";
import { MOCK_TYPE_ENUM, NOTION_TYPE_ENUM } from "../../configs/constants";
import client from "../../graphql/client";

export const SetCurrentTags = (tags, tagType) => (dispatch) => {
  let reducerConst = "";
  switch (tagType) {
    case CONFIG_CLUSTER.EXPERTISE:
      reducerConst = constants.SET_REFERENTIAL_TAGS;
      break;
    case CONFIG_CLUSTER.COUNTRY:
      reducerConst = constants.SET_REFERENTIAL_COUNTRIES;
      break;
    case CONFIG_CLUSTER.USER_CATEGORY:
      reducerConst = constants.SET_REFERENTIAL_USERCATEGORIES;
      break;
    case CONFIG_CLUSTER.BRAND:
      reducerConst = constants.SET_REFERENTIAL_BRANDS;
      break;
    case CONFIG_CLUSTER.RETAILER:
      reducerConst = constants.SET_REFERENTIAL_RETAILERS;
      break;
    default:
      break;
  }

  dispatch({
    type: reducerConst,
    data: tags,
  });
};

export const GetEventTypes = () => async (dispatch) => {
  try {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_MOCK_DATA,
      variables: {
        notion: NOTION_TYPE_ENUM.EVENT,
        type: MOCK_TYPE_ENUM.FILTER,
      },
    });

    dispatch({
      type: constants.SET_REFERENTIAL_EVENT_TYPES,
      data: ExtractEventTypesJsonResult(result.data.mock),
    });
  } catch (e) {
    dispatch(
      SetErrorMessage("An error occurred while fetching the event types", e),
    );
  }
};

const ExtractEventTypesJsonResult = (data) => {
  let eventTypeConfig = [];
  try {
    const configurationArray = JSON.parse(data);
    const eventsConfigurationElement = configurationArray.find(
      (x) => x.template === "SelectEventTypeTemplate",
    );
    if (
      eventsConfigurationElement &&
      eventsConfigurationElement.data &&
      eventsConfigurationElement.data.types
    ) {
      eventTypeConfig = eventsConfigurationElement.data.types;
    }
  } catch (e) {
    return eventTypeConfig;
  }
  return eventTypeConfig;
};
