import gql from "graphql-tag";

export const CREATE_BATTLE = gql`
  mutation createBattle($lang: LangEnum, $title: String!) {
    admin {
      battle(lang: $lang) {
        create(title: $title) {
          battleId
        }
      }
    }
  }
`;
