export const SIMPLE_QUIZ_NO_IMAGE = "simpleQuizNoImage";
export const SIMPLE_QUIZ_COVER = "simpleQuizCover";
export const SIMPLE_QUIZ_FULL_SCREEN = "simpleQuizFullScreen";

export const SimpleQuizTemplate = {
  template: "SimpleQuizzTemplate",
  data: {},
};

export const MadlibTemplate = {
  template: "MadLibTemplate",
  data: {},
};

export const MadlibQuestionTemplate = {
  text: "[#]",
  answers: [],
};

export const DragAndDropActivityTemplate = {
  template: "DragAndDropTemplate",
  data: {},
};
