import uuidV4 from "uuid/dist/v4";
import { EmptyNinjaPageTemplate } from "../../utils/ui-generator/ninja.template";
import { cleanUpAndEncodeText, decodeNewLines } from "../../utils/string";

export const NinjaDocumentQuestionChoiceToNinjaItemQuestionChoice = ({
  id,
  answer,
  isTrue,
  type,
  url,
}) => ({
  id: id || uuidV4(),
  text: decodeNewLines(answer),
  isTrue: isTrue,
  type,
  url,
});

export const NinjaDocumentQuestionToNinjaItemQuestion = (
  ninjaDocumentQuestion,
) => {
  const { id, type, text, image, description, answers, timer } =
    ninjaDocumentQuestion;

  return {
    timer,
    id: id || uuidV4(),
    type,
    title: decodeNewLines(text),
    image,
    description: decodeNewLines(description),
    choices: answers.map(NinjaDocumentQuestionChoiceToNinjaItemQuestionChoice),
    originalData: ninjaDocumentQuestion,
  };
};

export const NinjaDocumentToNinjaItem = (ninjaDocument) => {
  const editedPageJSONData = JSON.parse(ninjaDocument.pages[0].data).data;

  return {
    editedPageId: ninjaDocument.pages[0].pageId,
    editedPageJSONData,
    questions: editedPageJSONData.questions.map(
      NinjaDocumentQuestionToNinjaItemQuestion,
    ),
  };
};

export const NinjaItemQuestionChoiceToNinjaDocumentQuestionChoice = ({
  id,
  text,
  isTrue,
  type,
  url,
}) => ({
  id: id || uuidV4(),
  answer: cleanUpAndEncodeText(text),
  isTrue: typeof isTrue !== "undefined" ? isTrue : false,
  type,
  url,
});

export const NinjaItemQuestionToNinjaDocumentQuestion = ({
  id,
  type,
  text,
  image,
  choices,
  timer,
  originalData,
}) => ({
  ...originalData,
  id,
  type,
  text,
  image,
  answers: choices.map(NinjaItemQuestionChoiceToNinjaDocumentQuestionChoice),
  timer,
});

export const NinjaItemToNinjaDocument = (ninjaItem) => {
  return JSON.stringify({
    ...EmptyNinjaPageTemplate,
    data: {
      ...EmptyNinjaPageTemplate.data,
      questions: ninjaItem.questions.map(
        NinjaItemQuestionToNinjaDocumentQuestion,
      ),
    },
  });
};
