export const publishBannerHeight = 60;

export const PublishPreviewBannerStyle = ({
  PublishPreviewBanner,
  FontFamily,
}) => ({
  headerContainer: {
    display: "flex",
    height: publishBannerHeight,
    left: 0,
    width: "100%",
    backgroundColor: PublishPreviewBanner.containerBackgroundColor,
    position: "fixed",
    top: 0,
    justifyContent: "flex-end",
    alignItems: "center",
    zIndex: 99998,
  },
  spacer: {
    width: 20,
  },
  spacerRight: {
    width: 80,
  },
  publishButton: {
    padding: "0 !important",
    width: 125,
    height: 30,
    borderRadius: 27,
    fontFamily: FontFamily,
    fontSize: 16,
    letterSpacing: 0.2,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "condensed",
    lineHeight: "normal",
    textAlign: "center",
  },
});
