const rowHeight = 50;

export default (theme) => ({
  container: {
    fontFamily: theme.FontFamily,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  heading: {
    position: "relative",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: theme.UserProfilePendingQuestions.headingBackground,
    padding: "0 65px 0 17px",
    fontSize: 14,
    color: theme.UserProfilePendingQuestions.headingColor,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: rowHeight,
    justifyContent: "space-between",
  },
  row: {
    backgroundColor: theme.UserProfilePendingQuestions.rowBackground,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: rowHeight,
    justifyContent: "space-between",
    padding: "0 20px 0 17px",
  },
  questioner: {
    fontSize: 14,
  },
  question: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.UserProfilePendingQuestions.questionColor,
  },
  delay: {
    fontWeight: 500,
    color: theme.UserProfilePendingQuestions.delayColor,
  },
  arrow: {
    cursor: "pointer",
    position: "absolute",
    top: 15,
    right: 20,
    "&:before": {
      color: theme.UserProfilePendingQuestions.arrowColor,
      fontSize: 7,
      fontWeight: "bold",
    },
  },
  delayBtn: {
    cursor: "pointer",
  },
  noData: {
    justifyContent: "center",
    color: theme.UserProfilePendingQuestions.noDataColor,
  },
});
