import React, { useCallback } from "react";
import classNames from "classnames";
import { createUseStyles } from "react-jss";
import { NavigationStyle } from "./Navigation.style";
import { Icon, Icons, Menu, MenuItem, UserAvatar } from "genius-ui";
import { connect } from "react-redux";
import {
  SetActiveNavigationMenuItem,
  SetExpandedNavigationMenuItem,
} from "../../store/navigation/actions";
import { withRouter } from "react-router";
import { graphql } from "react-apollo";
import composeApollo from "lodash/flowRight";
import { DEGREE_CREATE } from "../../graphql/remote/degrees/mutations";
import {
  INSIGHT_CREATE,
  INSIGHT_CREATE_PAGE,
} from "../../graphql/remote/insights/mutations";
import { PAGE_TYPES } from "../../configs/constants";
import { SetCurrentInsightId } from "../../store/insight/actions";
import { SetCurrentDegreeId } from "../../store/degree/actions";
import { SignoutUser } from "../../store/auth/actions";
import { getPath } from "../../core/paths";
import { FormattedMessage, injectIntl } from "react-intl";
import compose from "lodash/fp/compose";
import clientConfig, { HAS_FUSIONAUTH, isShiseido } from "../../configs/client";
import { translations } from "./Navigation.translations";
import useFilterPaths from "../../hooks/accessControl/useFilterPaths";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const createInsight = graphql(INSIGHT_CREATE, {
  props: ({ mutate, ownProps: { intl } }) => ({
    createInsight: (lang, redirect, createPage, updateCurrentInsight) =>
      mutate({
        variables: {
          lang: lang,
          title: intl.formatMessage({ ...translations.NewInsightTitle }),
        },
        update: (cache, data) => {
          const insightId = data.data.admin.insight.create.insightId;
          updateCurrentInsight(insightId);
          createPage(insightId, lang, "{}", PAGE_TYPES.ARTICLE, redirect);
        },
      }),
  }),
});

const createInsightPage = graphql(INSIGHT_CREATE_PAGE, {
  props: ({ mutate }) => ({
    insightCreatePage: (insightId, lang, data, pageType, redirect) =>
      mutate({
        variables: {
          insightId: insightId,
          lang: lang,
          type: pageType,
          data: data,
        },
        update: () => {
          const redirectLocation = `/insight/create/${insightId}/${lang}`;
          redirect(redirectLocation);
        },
      }),
  }),
});

const createDegree = graphql(DEGREE_CREATE, {
  props: ({ mutate, ownProps: { intl } }) => ({
    createDegree: (lang, redirect, updateCurrentDegree) =>
      mutate({
        variables: {
          lang: lang,
          title: intl.formatMessage({ ...translations.NewDegreeTitle }),
        },
        update: (cache, data) => {
          const degreeId = data.data.admin.degree.create.degreeId;
          updateCurrentDegree(degreeId);
          redirect(`/degree/create/${degreeId}/${lang}`);
        },
      }),
  }),
});

const getKpiMenuItems = (configMenus, navigateHandler) => {
  const kpiMenuItems = [];
  if (configMenus.general) {
    kpiMenuItems.push({
      Name: <FormattedMessage {...translations.GeneralMenuText} />,
      HandleClick: () => navigateHandler(configMenus.general),
    });
  }

  if (configMenus.application) {
    kpiMenuItems.push({
      Name: <FormattedMessage {...translations.ApplicationMenuText} />,
      HandleClick: () => navigateHandler(configMenus.application),
    });
  }

  if (configMenus.learning) {
    kpiMenuItems.push({
      Name: <FormattedMessage {...translations.LearningMenuText} />,
      HandleClick: () => navigateHandler(configMenus.learning),
    });
  }

  if (configMenus.feed) {
    kpiMenuItems.push({
      Name: <FormattedMessage {...translations.FeedMenuText} />,
      HandleClick: () => navigateHandler(configMenus.feed),
    });
  }

  if (configMenus.ask) {
    kpiMenuItems.push({
      Name: <FormattedMessage {...translations.AskMenuText} />,
      HandleClick: () => navigateHandler(configMenus.ask),
    });
  }

  if (configMenus.mobileAnalytics) {
    kpiMenuItems.push({
      Name: <FormattedMessage {...translations.SessionAnalyticsText} />,
      HandleClick: () => navigateHandler(configMenus.mobileAnalytics),
    });
  }

  if (configMenus.kpiBattleHome) {
    kpiMenuItems.push({
      Name: <FormattedMessage {...translations.BattleMenuText} />,
      HandleClick: () => navigateHandler(configMenus.kpiBattleHome),
    });
  }

  if (configMenus.kpiNinja) {
    kpiMenuItems.push({
      Name: <FormattedMessage {...translations.NinjaGameText} />,
      HandleClick: () => navigateHandler(configMenus.kpiNinja),
    });
  }

  if (configMenus.document) {
    kpiMenuItems.push({
      Name: <FormattedMessage {...translations.KpiDocumentMenuText} />,
      HandleClick: () => navigateHandler(configMenus.document),
    });
  }

  if (configMenus.product) {
    kpiMenuItems.push({
      Name: <FormattedMessage {...translations.KpiProductMenuText} />,
      HandleClick: () => navigateHandler(configMenus.product),
    });
  }

  return kpiMenuItems;
};

const getDegreeMenuItems = ({
  createDegree,
  NavigateTo,
  currentDegreeLanguage,
  UpdateCurrentDegreeId,
}) => {
  let items = [
    {
      Name: <FormattedMessage {...translations.AllDegreeMenuText} />,
      HandleClick: () => {
        NavigateTo("/degrees");
      },
    },
  ];
  if (isShiseido) {
    items = [
      {
        Name: <FormattedMessage {...translations.MasterTemplatesMenuText} />,
        HandleClick: () => {
          NavigateTo("/masterTemplates");
        },
      },
      ...items,
    ];
  }

  if (!isShiseido) {
    items = [
      {
        Name: <FormattedMessage {...translations.NewDegreeMenuText} />,
        HandleClick: () => {
          createDegree(
            currentDegreeLanguage,
            NavigateTo,
            UpdateCurrentDegreeId,
          );
        },
      },
      {
        Name: <FormattedMessage {...translations.DeletedCoursesMenuText} />,
        HandleClick: () => {
          NavigateTo("/degrees/history");
        },
      },
      ...items,
    ];
  }

  return items;
};

const getContentMenuItems = ({ NavigateTo, isTrainer }) => {
  let items = [
    {
      Name: <FormattedMessage {...translations.AllContentsMenuText} />,
      HandleClick: () => NavigateTo(getPath("contentManager")),
    },
  ];
  if (isShiseido && !isTrainer) {
    items = [
      {
        Name: <FormattedMessage {...translations.MasterTemplatesMenuText} />,
        HandleClick: () => {
          NavigateTo("/masterContentManager");
        },
      },
      ...items,
    ];
  }

  if (!isShiseido) {
    items = [
      {
        Name: <FormattedMessage {...translations.NewContentMenuText} />,
        HandleClick: () => NavigateTo(getPath("contentType")),
      },
      {
        Name: <FormattedMessage {...translations.DeletedContentsMenuText} />,
        HandleClick: () => NavigateTo(getPath("deletedContent")),
      },
      ...items,
    ];
  }

  return items;
};

const SubMenus = (props) => {
  return {
    ContentManager: {
      Header: <FormattedMessage {...translations.ContentManagerHeader} />,
      Items: getContentMenuItems(props),
    },
    Degrees: {
      Header: <FormattedMessage {...translations.LearningBuilderHeader} />,
      Items: getDegreeMenuItems(props),
    },
    Users: {
      Header: <FormattedMessage {...translations.UsersHeader} />,
      Items: [
        {
          Name: <FormattedMessage {...translations.UsersMenuText} />,
          HandleClick: () => {
            props.NavigateTo("/users");
          },
        },
      ],
    },
    KPI:
      Object.keys(clientConfig.KPI.menu).length > 1
        ? {
            Header: <FormattedMessage {...translations.KPIHeader} />,
            Items: getKpiMenuItems(clientConfig.KPI.menu, props.NavigateTo),
          }
        : undefined,
    Badges: {
      Header: <FormattedMessage {...translations.BadgesHeader} />,
      Items: [
        {
          Name: <FormattedMessage {...translations.MasterBadges} />,
          HandleClick: () => {
            props.NavigateTo(getPath("masterBadges"));
          },
        },
        {
          Name: <FormattedMessage {...translations.Badges} />,
          HandleClick: () => {
            props.NavigateTo(getPath("badges"));
          },
        },
      ],
    },
  };
};

const MenuItems = (props) =>
  [
    {
      Key: 1,
      Name: "ContentManager",
      Icon: Icons.sidebar,
      Path: getPath("contentManager"),
      SubMenu: SubMenus(props).ContentManager,
      isAvailable: !!clientConfig.MODULES.IS_CONTENT_MANAGEMENT_AVAILABLE,
      order: clientConfig.MODULES.IS_CONTENT_MANAGEMENT_AVAILABLE,
    },
    {
      Key: 2,
      Name: "Degrees",
      Icon: Icons.school,
      Path: "/degrees",
      SubMenu: SubMenus(props).Degrees,
      isAvailable: !!clientConfig.MODULES.IS_LEARNING_MANAGEMENT_AVAILABLE,
      order: clientConfig.MODULES.IS_LEARNING_MANAGEMENT_AVAILABLE,
    },
    {
      Key: 3,
      Name: "Users",
      Icon: Icons.group,
      Path: "/users",
      // SubMenu: SubMenus(props).Users,
      isAvailable: !!clientConfig.MODULES.IS_USERS_MANAGEMENT_AVAILABLE,
      order: clientConfig.MODULES.IS_USERS_MANAGEMENT_AVAILABLE,
    },
    {
      Key: 4,
      Name: "KPI",
      Icon: Icons.stats,
      Path: clientConfig.KPI.defaultMenuPage,
      SubMenu: SubMenus(props).KPI,
      isAvailable: !!clientConfig.KPI.isAvailable,
      order: clientConfig.KPI.isAvailable,
    },
    {
      Key: 10,
      Name: "Library",
      Icon: Icons.library,
      customIconClass: "iconLibrary",
      Path: "/library",
      isAvailable: isShiseido || props.isSuperAdmin,
      order: clientConfig.KPI.isAvailable,
    },
    {
      Key: 11,
      Name: "Badges",
      Icon: Icons.reward,
      customIconClass: "iconReward",
      Path: "/badges",
      SubMenu: SubMenus(props).Badges,
      isAvailable: props.profileInfo.mail?.match(/@metyis/),
    },
  ]
    .filter(({ isAvailable }) => isAvailable)
    .map((menuItem) => ({
      ...menuItem,
      path: menuItem.Path,
    }))
    .sort(({ order: first }, { order: second }) => first - second);

const useStyle = createUseStyles(NavigationStyle);

const displayMenu = {
  shops: "Shops",
  kpi: "KPI",
  users: "Users",
  contentmanager: "ContentManager",
  degrees: "Degrees",
  library: "Library",
  badges: "Badges",
};

const Navigation = (props) => {
  const classes = useThemedStyle(useStyle, props);
  const { filteredPaths: FilteredMenuItems } = useFilterPaths(MenuItems(props));
  const { profileInfo, avatarColor } = props;
  const voidLink = "#"; // variable used to trick the linter
  const actualPage =
    displayMenu[props.location.pathname.match(/\/(.*?)(\/|$)/)[1]];
  const handleLogout = useCallback(() => {
    if (HAS_FUSIONAUTH) props.history.push("/logout");
    else props.handleSignOut();
  }, []);

  return (
    <nav className={classNames([classes.navigation, props.containerClassName])}>
      <ul className={classes.ul}>
        <li className={classes.profile}>
          <UserAvatar
            className={classes.profileAvatar}
            firstName={profileInfo.firstName}
            lastName={profileInfo.lastName}
            image={profileInfo.pic}
            color={avatarColor}
          />
        </li>
        {FilteredMenuItems.map(
          (menuItem) =>
            menuItem.isAvailable && (
              <li className={classes.li} key={menuItem.Key}>
                <a
                  href={voidLink}
                  onClick={() => {
                    props.SetActiveMenu(menuItem);
                    props.SetExpandedNavigationMenuItem("");
                  }}
                  onMouseOver={() =>
                    props.SetExpandedNavigationMenuItem(menuItem.Key)
                  }
                >
                  {menuItem.Name === "Training" ? (
                    <Icon
                      iconName={menuItem.Icon}
                      style={
                        menuItem.Name === props.currentMenuItem ||
                        menuItem.Name === actualPage
                          ? classes.iconQrCodeSelected
                          : classes.iconQrCode
                      }
                    />
                  ) : (
                    <Icon
                      iconName={menuItem.Icon}
                      style={classNames([
                        classes[menuItem.customIconClass],
                        menuItem.Name === props.currentMenuItem ||
                        menuItem.Name === actualPage
                          ? classes.iconMenuSelected
                          : classes.iconMenu,
                      ])}
                    />
                  )}
                </a>
                {menuItem.SubMenu && (
                  <div className={classes.subMenu}>
                    <Menu
                      isExpanded={
                        props.activeExpandedMenuItem &&
                        props.activeExpandedMenuItem === menuItem.Key
                      }
                      headerText={menuItem.SubMenu.Header}
                      handleFocusLeave={() => {
                        props.SetExpandedNavigationMenuItem("");
                      }}
                      animationName={
                        Menu.ANIMATION_TYPES.MENU_SLIDE_FROM_LEFT_TO_RIGHT
                      }
                    >
                      {menuItem.SubMenu.Items.map((x, index) => (
                        <MenuItem
                          key={index}
                          label={x.Name}
                          handleClick={() => {
                            props.SetActiveMenu(menuItem);
                            x.HandleClick();
                          }}
                        />
                      ))}
                    </Menu>
                  </div>
                )}
              </li>
            ),
        )}
        <li className={classes.logout}>
          <a
            href={voidLink}
            onClick={handleLogout}
            className={classes.logoutLink}
          >
            <Icon iconName={Icons.logout} />
          </a>
        </li>
      </ul>
    </nav>
  );
};

const mapStateToProp = (state) => ({
  currentMenuItem: state.navigation.currentMenuItem,
  currentInsightLanguage: state.insights.currentLanguage,
  currentDegreeLanguage: state.degrees.currentLanguage,
  activeExpandedMenuItem: state.navigation.activeExpandedMenuItem,
  profileInfo: state.auth.profileInfo,
  avatarColor: state.auth.color,
  isTrainer: state.auth.profileInfo.roles.some((role) => role === "trainer"),
  isSuperAdmin: state.auth.profileInfo.roles.some(
    (role) => role === "superadmin",
  ),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  NavigateTo: (location) => {
    dispatch(SetExpandedNavigationMenuItem(""));
    ownProps.history.push(location);
  },
  SetActiveMenu: (menuItem) => {
    dispatch(SetActiveNavigationMenuItem(menuItem.Name));
    ownProps.history.push(menuItem.Path);
  },
  SetExpandedNavigationMenuItem: (menuItem) => {
    dispatch(SetExpandedNavigationMenuItem(menuItem));
  },
  UpdateCurrentInsightId: (insightId) => {
    dispatch(SetCurrentInsightId(insightId));
  },
  UpdateCurrentDegreeId: (degreeId) => {
    dispatch(SetCurrentDegreeId(degreeId));
  },
  handleSignOut: () => {
    dispatch(SignoutUser());
  },
});

export default compose(
  withRouter,
  injectIntl,
  connect(mapStateToProp, mapDispatchToProps),
  composeApollo(createInsightPage, createDegree, createInsight),
)(Navigation);
