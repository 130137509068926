export const PageNotFoundStyle = ({ PageNotFoundScreen }) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  image: {
    width: "50%",
    alignItems: "center",
    marginTop: 5,
  },
  title: {
    width: 309,
    height: 53,
    fontFamily: "Roboto",
    fontSize: 45,
    fontWeight: "bold",
    textAlign: "center",
    color: PageNotFoundScreen.titleColor,
    marginBottom: 5,
  },
  description: {
    width: 356,
    height: 21,
    fontFamily: "Roboto",
    fontSize: 18,
    textAlign: "center",
    color: PageNotFoundScreen.descriptionColor,
    marginTop: 15,
  },
  button: {
    alignItems: "center",
    marginTop: 15,
    fontFamily: "RobotoCondensed-Regular",
  },
  buttonText: {
    width: "100%",
    height: 16,
    fontFamily: "Roboto",
    fontSize: 14,
    fontStretch: "condensed",
    letterSpacing: 0.15,
    textAlign: "center",
    color: PageNotFoundScreen.buttonTextColor,
    margin: 0,
  },
});
