import React from "react";
import PropTypes from "prop-types";
import style from "./Spinner.style";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const Spinner = (props) => {
  const classes = useThemedStyle(useStyle, props);

  return <div className={classes.spinner} />;
};

Spinner.propTypes = {
  /** Size of spinner */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default Spinner;
