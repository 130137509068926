export const MarkerSlideStyle = ({ MarkerSlideHorizontal, FontFamily }) => ({
  sliderContainer: {
    minHeight: 737,
    maxHeight: 737,
    height: 737,
    minWidth: 414,
    maxWidth: 414,
    width: 414,
    display: "flex",
    flexDirection: "column",
    backgroundImage: (props) =>
      props.image && props.image !== ""
        ? `${MarkerSlideHorizontal.containerBackgroundImage}, url("${props.image}")`
        : `${MarkerSlideHorizontal.containerBackgroundImage}, url("${props.defaultSliderImage}")`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    border: (props) =>
      props.isSelected
        ? `solid 1px ${MarkerSlideHorizontal.selectedBorderColor}`
        : "none",
  },
  actionButtonContainer: {
    display: ({ isSelected }) => (isSelected ? "block" : "none"),
  },
  actionButton: {
    fontFamily: FontFamily,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "baseline",
    alignSelf: "flex-start",
    position: "absolute",
    cursor: "pointer",
    "& > span": {
      fontSize: 14,
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: 0.07,
      color: MarkerSlideHorizontal.actionButtonColor,
      "&::before": {
        color: MarkerSlideHorizontal.actionButtonColor,
        fontSize: 15,
        padding: 5,
      },
    },
    "&:hover *": {
      color: MarkerSlideHorizontal.actionButtonHoverColor,
      "&::before": {
        color: MarkerSlideHorizontal.actionButtonHoverColor,
      },
    },
  },
  uploadButton: {
    top: 20,
    left: 10,
  },
  deleteBox: {
    top: 20,
    right: 10,
  },
  addBoxRight: {
    position: "absolute",
    top: "calc(100%/2+ 5)",
    right: -25,
    zIndex: 1,
    cursor: "pointer",
  },
  addBoxLeft: {
    position: "absolute",
    top: "calc(100%/2+ 5)",
    left: -24,
    zIndex: 1,
    cursor: "pointer",
  },
  partWillBeCropped: {
    position: "absolute",
    left: 91,
    bottom: 31,
    color: MarkerSlideHorizontal.croppedPartColor,
    width: 232,
    textAlign: "center",
  },
  uploadBtnWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
    },
  },
  btnUpload: {
    border: "none",
    fontFamily: FontFamily,
    fontSize: 14,
    background: "transparent",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    color: MarkerSlideHorizontal.actionButtonColor,
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
    },
    paddingLeft: 15,
    marginRight: 10,
    "& > span": {
      paddingRight: 5,
      "&:hover::before": {
        color: MarkerSlideHorizontal.actionButtonHoverColor,
        cursor: "pointer",
      },
    },
    "&:hover *": {
      color: MarkerSlideHorizontal.actionButtonHoverColor,
      cursor: "pointer",
      "&::before": {
        color: MarkerSlideHorizontal.actionButtonHoverColor,
        cursor: "pointer",
      },
    },
    outline: "none",
  },
  iconAction: {
    cursor: "pointer",
    "&:hover::before": {
      color: MarkerSlideHorizontal.actionButtonHoverColor,
      fontSize: 15,
      cursor: "pointer",
    },
    "&::before": {
      color: MarkerSlideHorizontal.actionButtonColor,
      fontSize: 15,
      cursor: "pointer",
    },
  },
  cropContainer: {
    position: "absolute",
    top: 20,
    left: 130,
  },
});
