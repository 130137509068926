import React from "react";
import { Button } from "genius-ui";
import { getPath } from "../../core/paths";
import PropTypes from "prop-types";
import NoContentStyle from "./NoContent.style";
import { createUseStyles } from "react-jss";
import { FormattedMessage } from "react-intl";
import { NoContentImage } from "assets/icons";
import { translations } from "./NoContent.tranlsations";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(NoContentStyle);

const NoContent = (props) => {
  const { history, hideCreateButton, noContentMessage } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.rootContainer}>
      <div className={classes.noContentImage} />
      <div className={classes.noContentText}>{noContentMessage}</div>
      {!hideCreateButton && (
        <div className={classes.buttonContainer}>
          <Button
            variation="primary"
            size="small"
            handleClick={() => history.push(getPath("contentType"))}
          >
            <FormattedMessage {...translations.CreateContentButtonText} />
          </Button>
        </div>
      )}
    </div>
  );
};

NoContent.propTypes = {
  history: PropTypes.object,
  hideCreateButton: PropTypes.bool,
  imagePath: PropTypes.string,
  noContentMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

NoContent.defaultProps = {
  hideCreateButton: false,
  imagePath: NoContentImage,
};

export default NoContent;
