import { defineMessages } from "react-intl";

export const translations = defineMessages({
  EditOption: {
    id: "Degrees.MarketView.EditOption",
    defaultMessage: "Edit Degree",
  },
  CloneOption: {
    id: "Degrees.MarketView.CloneOption",
    defaultMessage: "Clone",
  },
  DeleteOption: {
    id: "Degrees.MarketView.DeleteOption",
    defaultMessage: "Delete",
  },
  Counters: {
    id: "Degrees.MarketView.Counters",
    defaultMessage: "{programs} Programs {lessons} Lessons",
  },
  MarketFilterPlaceholder: {
    id: "Degrees.MarketView.MarketFilterPlaceholder",
    defaultMessage: "Market",
  },
  DuplicatedDegreeMessage: {
    id: "Degrees.MarketView.DuplicatedDegreeMessage",
    defaultMessage:
      "Your degree has been successfully duplicated for the language {lang}",
  },
  DeleteMessage: {
    id: "Degrees.MarketView.DeleteMessage",
    defaultMessage: "Are you sure you want to delete this item?",
  },
  AllMarketsFilterOption: {
    id: "Degrees.MarketView.AllMarketsFilterOption",
    defaultMessage: "All markets",
  },
  EditBadges: {
    id: "Degrees.MarketView.EditBadges",
    defaultMessage: "Edit badges",
  },
});
