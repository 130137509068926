import React, { useEffect } from "react";
import DefaultQuickQuizEditor from "./EditQuickQuiz";
import QuickQuizEditorReadOnly from "./editQuickQuizReadOnly";
import clientConfig from "../../configs/client";
import { USER_ROLE_NAMES } from "../../configs/constants";
import { connect } from "react-redux";
import { FullscreenLoader } from "genius-ui";
import {
  GetPageData,
  ResetPageData,
  SaveCardData,
} from "../../store/quickQuiz/actions";
import { getPath } from "../../core/paths";

const EditBattle = (props) => {
  const {
    match: {
      params: { quickQuizId, lang },
    },
    userRoles,
    ResetPageData,
    GetPageData,
    isMasterDocument,
  } = props;

  useEffect(() => {
    GetPageData(quickQuizId, lang);
  }, [quickQuizId, lang]);

  // reset store data when leaving the page
  useEffect(() => ResetPageData, []);

  if (!userRoles || !userRoles.length) {
    return <FullscreenLoader />;
  }

  if (
    clientConfig.IS_ROLE_READ_ONLY_MODE_ACTIVE &&
    userRoles.includes(USER_ROLE_NAMES.TRAINER) &&
    isMasterDocument
  ) {
    return <QuickQuizEditorReadOnly {...props} />;
  }

  return <DefaultQuickQuizEditor {...props} />;
};

const mapStateToProps = (state) => ({
  userRoles: state.auth.profileInfo.roles,
  cardId: state.quickQuiz.cardId,
  pageId: state.quickQuiz.card.pageId,
  answers: state.quickQuiz.card.answers,
  endMessage: state.quickQuiz.card.endMessage,
  endImage: state.quickQuiz.card.endImage,
  image: state.quickQuiz.card.image,
  question: state.quickQuiz.card.question,
  documentListWorkflow: state.documents.listWorkflow,
  isMasterDocument: state.quickQuiz.isMasterDocument,
  documentLevel: state.documents.documentLevel,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  GetPageData: (quickQuizId, lang) => dispatch(GetPageData(quickQuizId, lang)),
  ResetPageData: () => dispatch(ResetPageData()),
  NavigateToPreviousPage: () => {
    ownProps.history.push(getPath("contentManager"));
  },
  SaveChanges: (cardId, lang, pageId, changes) =>
    dispatch(SaveCardData(cardId, lang, pageId, changes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBattle);
