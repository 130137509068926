import React from "react";
import PropTypes from "prop-types";
import uuidv4 from "uuid/dist/v4";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import SlideMarker, {
  SLIDE_MARKER_SIZE,
  SLIDE_MARKER_DRAG_AREA_SIZE,
} from "../../SlideMarker";
import DragAndDropLayer from "../../DragAndDropLayer";
import { SetWarningMessage } from "../../../../store/notification/actions";

class MarkerLayer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      markers: [],
    };

    this.dndLayerId = `DNDLayer${uuidv4()}`;
  }

  componentDidMount() {
    this.setState({
      markers: this.props.markers.map(this.pointToMarker),
    });
  }

  pointToMarker = (point) => ({
    id: uuidv4(),
    width: SLIDE_MARKER_SIZE.width,
    height: SLIDE_MARKER_SIZE.height,
    dragAreaWidth: SLIDE_MARKER_DRAG_AREA_SIZE.width,
    dragAreaHeight: SLIDE_MARKER_DRAG_AREA_SIZE.height,
    ...point,
    isStatic: point.isOpened || false,
    isOpened: point.isOpened || false,
    x: point.x * this.props.safeAreaSize.width, // convert value between 0 and 1 to css coordinate
    y: point.y * this.props.safeAreaSize.height, // convert value between 0 and 1 to css coordinate
  });

  toggleMarkerStaticState = (changedMarkerId) =>
    this.setState(
      (state) => ({
        markers: this.changePropsOfMarker(
          changedMarkerId,
          (currentState) => ({
            isStatic: !currentState.isStatic,
            isOpened: !currentState.isOpened,
          }),
          state.markers,
          (marker) => ({
            // toggling the state of other markers to false(don't allow to open tow markers at the same time)
            isStatic: false,
            isOpened: false,
          }),
        ),
      }),
      () => {
        const changedMarkerIndex = this.state.markers.findIndex(
          (marker) => marker.id === changedMarkerId,
        );
        const changedMarker = this.state.markers[changedMarkerIndex];
        this.props.onMarkerOpened &&
          this.props.onMarkerOpened(changedMarkerIndex, changedMarker.isOpened);
      },
    );

  changePropsOfMarker = (
    changedMarkerId,
    changedProps,
    markers,
    unchangedMarkerCallback = null,
  ) =>
    markers.map((marker) =>
      changedMarkerId !== marker.id
        ? {
            ...marker,
            ...(typeof unchangedMarkerCallback === "function"
              ? unchangedMarkerCallback(marker)
              : {}),
          }
        : {
            ...marker,
            ...(typeof changedProps !== "function"
              ? changedProps
              : changedProps(marker)),
          },
    );

  getMarkerContainerProps = (marker) =>
    marker.isOpened
      ? {
          style: {
            zIndex: 1,
          },
        }
      : undefined;

  renderMarker = (
    marker,
    isDragged = false,
    isDropTargetSafe = true,
    uniqueId = "slideMarker",
  ) => (
    <span onMouseUp={(e) => e.stopPropagation()}>
      <SlideMarker
        uniqueId={`${this.props.uniqueId}-${uniqueId}`}
        id={marker.id}
        isOpened={marker.isOpened}
        onOpenedStateToggled={this.toggleMarkerStaticState}
        image={marker.image}
        description={marker.description}
        isDropTargetSafe={isDropTargetSafe}
        onCrop={() => null}
      />
    </span>
  );

  render() {
    let props = this.props;
    let { safeAreaSize, safeAreaPosition, layerSize } = props;

    return (
      <>
        <DragAndDropLayer
          id={this.dndLayerId}
          elements={this.state.markers}
          renderElement={this.renderMarker}
          getDraggedElementContainerProps={this.getMarkerContainerProps}
          setElementPosition={() => null}
          validateElementPosition={() => null}
          size={layerSize}
          safeAreaSize={safeAreaSize}
          safeAreaPosition={safeAreaPosition}
          isActive={this.props.isLayerActive}
        />
      </>
    );
  }
}

MarkerLayer.propTypes = {
  /** String that uniquely identifies marker layer on the same page and it's consistent across multiple page refreshes **/
  uniqueId: PropTypes.string.isRequired,
  /** Whether the markers are editable or not **/
  isLayerActive: PropTypes.bool.isRequired,
  /** Size of the marker layer **/
  layerSize: PropTypes.object.isRequired,
  /** Size of the safe area(area in which markers are allowed to be dragged) **/
  safeAreaSize: PropTypes.object.isRequired,
  /** Position of the safe area inside the marker layer **/
  safeAreaPosition: PropTypes.object.isRequired,
  /** The initial markers **/
  markers: PropTypes.array.isRequired,
  /** Injected react-intl object **/
  intl: PropTypes.object.isRequired,
  /** function executed when a marker is opened or closed **/
  onMarkerOpened: PropTypes.func,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  WarningAlert: (message) => dispatch(SetWarningMessage(message)),
});

export default compose(
  injectIntl,
  connect(undefined, mapDispatchToProps),
)(MarkerLayer);
