import * as constants from "../../constants/store/duplication";

export const SetShowModal = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_SHOW_MODAL,
    data: data,
  });
};

export const SetDuplicationData = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_DUPLICATION_DATA,
    data: data,
  });
};
