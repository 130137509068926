import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import Style from "./UploadPdf.style";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import { ENV } from "../../../configs/constants";
import MediaService from "../../../core/mediaService";
import { useDropzone } from "react-dropzone";
import { translations } from "./UploadPdf.translations";
import { FormattedMessage, useIntl } from "react-intl";
import AddCoverModal from "./partials/AddCoverModal";
import { sleep } from "utils/sleep";
import { UploadProgress } from "../SliderVideoPlayer";

const useStyle = createUseStyles(Style);

const mediaService = new MediaService();

const DisplayUploadProgress = ({
  uploadProgress,
  uploadProgresses,
  compressionProgress,
  fileType,
}) => {
  const intl = useIntl();

  const [fakeProgress, setFakeProgress] = useState(0);
  // TODO: Keep for v3
  // const uploadProgress = useMemo(() => uploadProgresses.reduce((a, b) => a + b, 0) / (uploadProgresses.length || 1), [uploadProgresses])
  const { progress, title, description } = useMemo(() => {
    if (uploadProgress < 100 || fileType !== "video") {
      return {
        progress: uploadProgress,
        title: translations.VideoUpload,
        description: translations.Import,
      };
    }
    if (compressionProgress === 0) {
      return {
        progress: fakeProgress,
        title: translations.Optimising,
        description: translations.OptimisingFile,
      };
    }
    return {
      progress: compressionProgress,
      title: translations.VideoConversion,
      description: translations.Compression,
    };
  }, [uploadProgress, compressionProgress, fileType, fakeProgress]);

  useEffect(() => {
    if (uploadProgress !== 100) return;
    if (compressionProgress !== 0) return;
    const fakeProgressInterval = setInterval(
      () =>
        setFakeProgress((current) => (current < 100 ? current + 1 : current)),
      30000 / 100,
    );
    return () => clearInterval(fakeProgressInterval);
  }, [uploadProgress, compressionProgress]);
  return (
    <UploadProgress
      percentage={progress}
      titleVideo={intl.formatMessage(title)}
      descriptionVideo={intl.formatMessage(description)}
      progressBarContainerPosition="inherit"
      backgroundImage="none"
      titleColor="black"
    />
  );
};

const MIME_TYPES = {
  audio: { key: "audio/*", value: [] },
  video: { key: "video/*", value: [] },
  pdf: { key: "application/pdf", value: [".pdf"] },
  image: { key: "image/*", value: [] },
};

const FILE_TYPES = ["audio", "video", "pdf", "image"];
const WARNING_FILE_SIZE = 15000000;

const retryOnce = async (func) => {
  try {
    return await func();
  } catch (e) {
    return func();
  }
};

const uploadFile = async (
  isVideo,
  handleInitUploadProgress,
  file,
  handleUploadProgress,
  handleCompressionProgress,
) => {
  if (isVideo) {
    // TODO: Keep for v3
    // return mediaService.uploadVideoV3(
    //   handleInitUploadProgress,
    //   file,
    //   handleUploadProgress,
    //   handleCompressionProgress
    // )
    return mediaService.uploadVideoV2(
      file,
      handleUploadProgress,
      handleCompressionProgress,
    );
  } else {
    return mediaService.uploadMedia(file, handleUploadProgress);
  }
};

const UploadFile = ({
  fileTypes = FILE_TYPES,
  setFileType,
  fileType,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useThemedStyle(useStyle, props);
  const { onUploadSuccess, onUploadFail, onHeavyFile, handleCoverChange } =
    props;

  // TODO: Replace 0 by [] for v3
  const [uploadProgress, setUploadProgress] = useState(0);
  const [compressionProgress, setCompressionProgress] = useState(0);

  const handleInitUploadProgress = useCallback(
    (fragmentNb) => setUploadProgress(Array(fragmentNb).fill(0)),
    [setUploadProgress],
  );

  // TODO: Keep for v3
  // const handleProgress = useCallback((index) => (progressEvent) => setUploadProgress((previous) => {
  //   previous[index] = (progressEvent.loaded * 100) / progressEvent.total
  //   return [...previous]
  // }), [setUploadProgress])
  const handleProgress = useCallback((progressEvent) => {
    setUploadProgress(
      Math.round((progressEvent.loaded * 100) / progressEvent.total),
    );
  }, []);
  const handleMediaUpload = async (file, shouldGeneratePicCover) => {
    const isVideo = file.type.includes("video");
    try {
      setFileType(fileTypes.find((fileType) => file.type.includes(fileType)));
      // setUploadProgress([])
      setIsLoading(true);
      const result = await uploadFile(
        isVideo,
        handleInitUploadProgress,
        file,
        handleProgress,
        setCompressionProgress,
      );

      if (result.status === 200) {
        if (file.size > WARNING_FILE_SIZE) {
          onHeavyFile(true);
        }
        if (shouldGeneratePicCover) {
          await sleep(200);
          const { data } = await retryOnce(() =>
            mediaService.uploadPdfCover(result.data),
          );
          handleCoverChange(data[0][data[0].length - 1]);
        }
        onUploadSuccess &&
          onUploadSuccess(isVideo ? result.data.url : result.data);
      }
      setIsLoading(false);
    } catch (e) {
      setFileType(undefined);
      setIsLoading(false);
      onUploadFail("Upload failed");
      if (ENV.IS_DEV) {
        console.error("Couldn't upload file", e);
      }
    }
  };
  const handleContinue = useCallback(
    (doReplacePic) => {
      handleMediaUpload(acceptedFile, doReplacePic);
    },
    [handleMediaUpload, acceptedFile],
  );

  // Drop file action
  const onDrop = useCallback(
    (acceptedFiles, filesErrors, un, deux) => {
      if (filesErrors.length) {
        onUploadFail(`File should be a ${fileTypes.join(" or ")}.`);
        return;
      }
      const fileType = fileTypes.find((fileType) =>
        acceptedFiles[0].type.includes(fileType),
      );
      if (fileType === "pdf") {
        setAcceptedFile(acceptedFiles[0]);
        setIsModalOpen(true);
      } else {
        handleMediaUpload(acceptedFiles[0], false);
      }
    },
    [onUploadFail, fileTypes, setAcceptedFile, setIsModalOpen],
  );

  const dropZoneAccept = useMemo(() => {
    return fileTypes.reduce((acc, fileType) => {
      acc[MIME_TYPES[fileType].key] = MIME_TYPES[fileType].value;
      return acc;
    }, {});
  }, [fileTypes]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: dropZoneAccept,
  });

  // Modal actions
  const handleCancel = useCallback(() => {
    setAcceptedFile(null);
  }, []);

  return (
    <div className={classes.main}>
      {!isLoading && (
        <>
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{ width: "100%", height: "100%" }}
          >
            <input className="input-zone" {...getInputProps()} />
            <div className={classes.textCenter}>
              <p className="dropzone-content">
                <FormattedMessage {...translations.DragAndDropText} />
                <br />
                <FormattedMessage {...translations.OrText} />
                <br />
                <span className={classes.importDocument}>
                  <FormattedMessage {...translations.ImportText} />
                </span>
              </p>
            </div>
          </div>
          <AddCoverModal
            visible={isModalOpen}
            setVisible={setIsModalOpen}
            onCancel={handleCancel}
            onContinue={handleContinue}
          />
        </>
      )}
      {isLoading && (
        <DisplayUploadProgress
          fileType={fileType}
          // uploadProgresses={uploadProgresses}
          uploadProgress={uploadProgress}
          compressionProgress={compressionProgress}
        />
      )}
    </div>
  );
};

export default UploadFile;
