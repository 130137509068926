import React from "react";
import { MultimediaPreviews } from "assets/icons";
import { VIDEO_CONTROL_POSITION } from "../../../../configs/constants";
import { FormattedMessage } from "react-intl";
import {
  multimediaTemplateNamesTranslations,
  translations,
} from "./Multimedia.translations";

export const MultimediaTemplateNames = multimediaTemplateNamesTranslations;

const MultimediaTemplate = (props) => ({
  title: <FormattedMessage {...translations.TemplateTitle} />,
  items: [
    {
      name: (
        <FormattedMessage {...MultimediaTemplateNames.VideoFullWidthText} />
      ),
      thumbnail: MultimediaPreviews.videoFullWidth,
      onSelect: () => props.handleVideoClick(VIDEO_CONTROL_POSITION.FULLWIDTH),
    },
    {
      name: (
        <FormattedMessage {...MultimediaTemplateNames.VideoInContainerText} />
      ),
      thumbnail: MultimediaPreviews.videoInContainer,
      onSelect: () => props.handleVideoClick(VIDEO_CONTROL_POSITION.BOXED),
    },
  ],
});

export default MultimediaTemplate;
