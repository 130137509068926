import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import WidgetContainerStyle from "./WidgetContainer.style";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(WidgetContainerStyle);

const WidgetContainer = (props) => {
  const { children, className } = props;
  const classes = useThemedStyle(useStyle, props);
  return (
    <div className={classNames([classes.container, className])}>{children}</div>
  );
};

WidgetContainer.propTypes = {
  /** custom style can be provided **/
  className: PropTypes.string,
  /** Widget container width in percentage */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** It puts margin if it is enabled */
  isMarginRightEnabled: PropTypes.bool,
};

WidgetContainer.defaultProps = {
  width: 100,
  isMarginRightEnabled: true,
};

export default WidgetContainer;
