import * as constants from "../../constants/store/auth";
import theme from "../../constants/theme/default";

const initialState = {
  forgotMsg: "",
  error: "",
  timestamp: "",
  loading: false,
  authenticated: false,
  profileInfo: {
    ready: false,
    firstName: null,
    lastName: null,
    pic: null,
    tags: [],
    roles: [],
  },
  color:
    theme.Colors.navigationMenu.userColors[
      Math.floor(Math.random() * theme.Colors.navigationMenu.userColors.length)
    ],
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.CLEARDOWN:
      return {
        ...state,
        ...initialState,
      };
    case constants.AUTH_USER:
      return {
        ...state,
        authenticated: true,
      };
    case constants.FORGOT_PASSWORD_IN_PROGRESS:
    case constants.AUTH_IN_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case constants.UNAUTH_USER:
      return {
        ...state,
        ...initialState,
      };
    case constants.FORGOT_SUCCESS:
      return {
        ...state,
        forgotMsg: data,
      };
    case constants.AUTH_ERROR:
      return {
        ...state,
        error: data.error,
        timestamp: data.timestamp,
      };
    case constants.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        error: data.error,
        timestamp: data.timestamp,
      };
    case constants.SET_PROFILE_INFO:
      return {
        ...state,
        profileInfo: data,
      };
    default:
      return state;
  }
}
