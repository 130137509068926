import { defineMessages } from "react-intl";

export const translations = defineMessages({
  NoImage: {
    id: "SimpleQuizQuestion.NoImage",
    defaultMessage: "No Image",
  },
  Cover: {
    id: "SimpleQuizQuestion.Cover",
    defaultMessage: "Cover",
  },
  Background: {
    id: "SimpleQuizQuestion.Background",
    defaultMessage: "Background",
  },
  QuestionPlaceholder: {
    id: "SimpleQuizQuestion.QuestionPlaceholder",
    defaultMessage: "Enter a question title here...",
  },
  CorrectColumnLabel: {
    id: "SimpleQuizQuestion.CorrectColumnLabel",
    defaultMessage: "Correct",
  },
  ChoiceTextColumnLabel: {
    id: "SimpleQuizQuestion.ChoiceTextColumnLabel",
    defaultMessage: "Choice text",
  },
  AddAChoiceButtonLabel: {
    id: "SimpleQuizQuestion.AddAChoiceButtonLabel",
    defaultMessage: "Add a Choice (max {maxNrOfAnswers} answers)",
  },
  AddAQuestionButtonLabel: {
    id: "SimpleQuizQuestion.AddAQuestionButtonLabel",
    defaultMessage: "Add a question",
  },
  SuccessMessageInputPlaceholder: {
    id: "SimpleQuizQuestion.SuccessMessageInputPlaceholder",
    defaultMessage: "Enter success message",
  },
  FailedMessageInputPlaceholder: {
    id: "SimpleQuizQuestion.FailedMessageInputPlaceholder",
    defaultMessage: "Enter failed message",
  },
  ExplanationsInputPlaceholder: {
    id: "SimpleQuizQuestion.ExplanationsInputPlaceholder",
    defaultMessage: "Explanations",
  },
  DeleteButtonLabel: {
    id: "SimpleQuizQuestion.DeleteButtonLabel",
    defaultMessage: "Delete",
  },
});
