import gql from "graphql-tag";

export const GET_INTERVALS = gql`
  query kpiGetIntervals {
    allCoreIntervals {
      id
      name
    }
  }
`;
