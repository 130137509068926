import uidv4 from "uuid/dist/v4";

export const kpiFeedDocumentToKpiFeedPageData = (feedDocument) => ({
  ...feedDocument,
  popularContent:
    feedDocument.mostPopularContent &&
    feedDocument.mostPopularContent.length > 0
      ? feedDocument.mostPopularContent.map((contentPopularityItem) => ({
          id: uidv4(),
          ...contentPopularityItem,
        }))
      : [],
  topTags:
    feedDocument.topTags && feedDocument.topTags.length > 0
      ? feedDocument.topTags.map((tagItem) => ({
          id: uidv4(),
          ...tagItem,
        }))
      : [],
  totalNumberOfContent:
    feedDocument.insight +
    feedDocument.lesson +
    feedDocument.slider +
    feedDocument.socialMedia,
});
