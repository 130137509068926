import React, { useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AddNewContainer, { DIRECTION_VERTICAL } from "../AddNewContainer";
import { FormattedMessage } from "react-intl";
import { Icon, Icons } from "genius-ui";
import Style from "./DragAndDropActivityQuestion.style.js";
import ContentEditableUncontrolled from "../ContentEditableUncontrolled";
import DragAndDropActivityAnswer from "../DragAndDropActivityAnswer";
import { translations } from "./DragAndDropActivityQuestion.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

// half of fixed height
const ADD_BUTTON_SWITCH_BREAKPOINT = 368;

const DragAndDropActivityQuestion = (props) => {
  const {
    index,
    text,
    zones,
    isEditable,
    onChange,
    onClick,
    onAddQuestionBefore,
    onAddQuestionAfter,
    onRemove,
  } = props;
  const zonesRef = useRef();
  zonesRef.current = zones;
  const classes = useThemedStyle(useStyle, props);

  const handleChange = (attributeName, data) =>
    onChange(index, { [attributeName]: data });

  // handle text change
  const handleQuestionChange = (newQuestion) =>
    handleChange("text", newQuestion);

  // handle zone changes
  const handleZoneChange = (changedZoneId, changedData) =>
    handleChange(
      "zones",
      zonesRef.current.map((zone) =>
        zone.zoneId === changedZoneId
          ? {
              ...zone,
              ...changedData,
            }
          : zone,
      ),
    );
  // zone placeholder changes
  const handleZoneTextChange = (changedZoneId, answer) =>
    handleZoneChange(changedZoneId, { answer });
  // zone cards changes
  const handleZoneCardsChange = (changedZoneId, cards) =>
    handleZoneChange(changedZoneId, { cards });

  return (
    <div className={classes.container} onClick={onClick}>
      <AddNewContainer
        className={classes.innerPageContainer}
        enabled={isEditable}
        direction={DIRECTION_VERTICAL}
        beforeAfterBreakPoint={ADD_BUTTON_SWITCH_BREAKPOINT}
        onAddBefore={() => onAddQuestionBefore(index)}
        onAddAfter={() => onAddQuestionAfter(index)}
      >
        {isEditable && (
          <div className={classes.deleteButton} onClick={() => onRemove(index)}>
            <FormattedMessage {...translations.DeleteButtonLabel} />
            <Icon iconName={Icons.delete} />
          </div>
        )}
        <FormattedMessage {...translations.QuestionInputLabel}>
          {(message) => (
            <ContentEditableUncontrolled
              className={classNames([classes.questionInput, classes.textInput])}
              initialValue={text}
              onChange={handleQuestionChange}
              setContentOnChange
              placeholder={message[0]}
              disabled={!isEditable}
            />
          )}
        </FormattedMessage>
        <div className={classes.zonesContainer}>
          {zones.map(({ zoneId, answer }, index) => (
            <div key={zoneId}>
              <FormattedMessage
                {...translations.ZoneTextInputLabel}
                values={{
                  zoneNumber: index + 1,
                }}
              >
                {(message) => (
                  <ContentEditableUncontrolled
                    className={classNames([
                      classes.zoneTextInput,
                      classes.textInput,
                    ])}
                    initialValue={answer}
                    onChange={(zoneText) =>
                      handleZoneTextChange(zoneId, zoneText)
                    }
                    setContentOnChange
                    placeholder={message[0]}
                    disabled={!isEditable}
                  />
                )}
              </FormattedMessage>
              <div className={classes.zonePlaceholderBox} />
            </div>
          ))}
        </div>
      </AddNewContainer>
      {zones.map(({ zoneId, cards }, index) => (
        <DragAndDropActivityAnswer
          key={zoneId}
          id={zoneId}
          index={index + 1}
          cards={cards}
          onChange={handleZoneCardsChange}
          isEditable={isEditable}
          className={classes.innerPageContainer}
        />
      ))}
    </div>
  );
};

DragAndDropActivityQuestion.propTypes = {
  question: PropTypes.string,
  zones: PropTypes.arrayOf(
    PropTypes.shape({
      zoneId: PropTypes.string.isRequired,
      answer: PropTypes.string,
      placeholder: PropTypes.string,
      cards: PropTypes.array.isRequired,
    }),
  ).isRequired,
  /** the index of the question. questions are added, changed and removed based on the index **/
  index: PropTypes.number.isRequired,
  /** whether the question is editable or not **/
  isEditable: PropTypes.bool.isRequired,
  /** function executed when one or more attributes of the question are changed **/
  onChange: PropTypes.func.isRequired,
  /** function executed when clicked on the question **/
  onClick: PropTypes.func,
  /** function executed when the 'Add before' button is clicked **/
  onAddQuestionBefore: PropTypes.func.isRequired,
  /** function executed when the 'Add after' button is clicked **/
  onAddQuestionAfter: PropTypes.func.isRequired,
  /** function executed when the 'Delete' button is clicked **/
  onRemove: PropTypes.func.isRequired,
};

export default DragAndDropActivityQuestion;
