import { FontFamily } from "../../../constants";

const firstColWidth = "45%";
const secondColWidth = "24%";

export default (theme) => ({
  container: {},
  header: {
    display: "flex",
    padding: "12px 20px",
    fontSize: 14,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.ProgramsWidgetShiseido.headerColor,
    "& > span:first-of-type": {
      width: firstColWidth,
    },
  },
  accordionHeading: {
    display: "flex",
    alignItems: "center",
    "& > span:first-of-type": {
      width: firstColWidth,
    },
  },
  headingCompletionRate: {
    width: secondColWidth,
  },
  accordionContentContainer: {
    padding: "9px 20px 15px 0",
    backgroundColor: theme.ProgramsWidgetShiseido.lessonBackgroundColor,
  },
  sortableColumn: {
    cursor: "pointer",
    "& > span:nth-of-type(2)": {
      marginLeft: 8,
      fontSize: 10,
    },
  },
  lessonHeader: {
    fontSize: 10,
    color: theme.ProgramsWidgetShiseido.lessonHeaderColor,
    padding: "10px 0 20px 40px",
    float: "left",
    width: "100%",
    boxSizing: "border-box",
    "& > div": {
      float: "left",
    },
    "& > div:nth-of-type(1)": {
      width: `calc(${firstColWidth} - ${lessonTitleMarginRight}px)`,
    },
    "& > div:nth-of-type(2)": {
      width: secondColWidth,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
      "& > div:nth-of-type(1)": {
        textAlign: "center",
        marginRight: 8,
      },
    },
    "& > div:nth-of-type(3)": {
      paddingLeft: 60,
      width: 140,
      textAlign: "center",
    },
    "& > div:nth-of-type(4)": {
      paddingLeft: 50,
      width: 77,
      textAlign: "center",
    },
  },
  noLesson: {
    paddingLeft: 20,
  },
});

const lessonTitleMarginRight = 10;

export const LessonRowStyle = (theme) => ({
  container: {
    display: "flex",
    margin: 0,
    paddingLeft: 40,
    alignItems: "center",
    color: theme.ProgramsWidgetShiseido.lessonDataColor,
    fontSize: 12,
    fontWeight: 500,
    minHeight: 45,
    "& > span:first-of-type": {
      width: `calc(${firstColWidth} - ${lessonTitleMarginRight}px)`,
    },
    backgroundColor: theme.ProgramsWidgetShiseido.lessonBackgroundColor,
  },
  completionRate: {
    width: secondColWidth,
  },
  usersQuitContainer: {
    paddingLeft: 60,
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
    "& > span:first-of-type": {
      textAlign: "center",
      minWidth: 133,
      marginRight: 10,
      fontFamily: FontFamily,
      fontSize: 12,
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.ProgramsWidgetShiseido.lessonDataColorLight,
      "& > span:nth-of-type(2)": {
        color: theme.ProgramsWidgetShiseido.lessonCompletionRateColor,
        marginLeft: 5,
      },
    },
    "& > span:nth-of-type(2)": {
      color: theme.ProgramsWidgetShiseido.lessonCompletionRateColor,
    },
    "& > span:nth-of-type(3)": {
      cursor: "pointer",
    },
  },
  progressBar: {
    "& > div": {
      backgroundColor: theme.ProgramsWidgetShiseido.lessonCompletionRateColor,
    },
  },
  completionRateContainer: {
    "& > div:nth-of-type(1) > span:nth-of-type(1)": {
      color: theme.ProgramsWidgetShiseido.lessonCompletionRateColor,
    },
  },
});
