import { NoImageAvailable } from "assets/icons";
import clientConfig from "../../configs/client";

export const getFormattedDegrees = (
  {
    data: {
      admin: { draft, published },
    },
  },
  { language },
) => {
  return [...published, ...draft]
    .reduce((templates, { versions }) => {
      const actualVersion = versions.find(
        (version) => version.lang === language.selectedValue,
      );
      const availableLanguages = versions.map((version) => version.lang);

      if (actualVersion) {
        /** check the masterDegreeTagId */
        const tagIds = actualVersion.tags.map((tag) => parseInt(tag.tagId));
        if (!tagIds.includes(clientConfig.MASTER_DEGREE_TAG_ID)) {
          templates.push({
            ...actualVersion,
            id: actualVersion.degreeId,
            picCover: actualVersion.picCover || NoImageAvailable,
            date: actualVersion.updateDate,
            // TODO: get program and lesson numbers from the graphql
            programs: 6,
            lessons: 30,
            availableLanguages,
          });
        }
      }
      return templates;
    }, [])
    .sort((x, y) => (Number(x.id) > Number(y.id) ? -1 : 1));
};
