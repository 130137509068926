import gql from "graphql-tag";

export const GET_INSIGHTS_BY_STATUS = gql`
  query insightsFilterByStatus($status: DocWorkflowEnum) {
    admin {
      insights(workflow: $status) {
        insightId
        versions {
          title
          lang
          picThumb
          updateDate
          workflow
          author {
            userId
          }
        }
      }
    }
  }
`;
