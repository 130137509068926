export default ({ TextSizes, FontFamily }) => ({
  inputContainer: {
    marginTop: 30,
    maxHeight: 50,
  },
  forgotPasswordButton: {
    alignSelf: "flex-end",
    fontFamily: FontFamily,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "right",
    fontSize: TextSizes.Regular,
    marginTop: 20,
    textDecoration: "none",
  },
  loginButton: {
    marginTop: 60,
    textTransform: "none !important",
    padding: "0 !important",
    borderRadius: "27px",
    width: 105,
    height: 34,
    fontFamily: FontFamily,
    fontSize: 18,
    letterSpacing: 0.2,
  },
  errorMessage: {
    fontSize: TextSizes.Regular,
    color: "red",
    marginTop: 20,
    marginBottom: 20,
  },
  fullWidth: {
    width: "100%",
  },
});
