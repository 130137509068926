export default (theme) => ({
  container: {
    padding: 20,
  },
  title: {
    marginBottom: 30,
    fontFamily: theme.FontFamily,
    fontSize: 14,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.TagCloudWidget.titleColor,
  },
});
