import { defineMessages } from "react-intl";

export const NinjaMessages = defineMessages({
  NinjaNoImageQuestionTemplateTitle: {
    id: "Ninja.NinjaNoImageQuestionTemplateTitle",
    defaultMessage: "No Image",
  },
  NinjaCoverQuestionTemplateTitle: {
    id: "Ninja.NinjaCoverQuestionTemplateTitle",
    defaultMessage: "Cover Image",
  },
  NinjaBackgroundQuestionTemplateTitle: {
    id: "Ninja.NinjaBackgroundQuestionTemplateTitle",
    defaultMessage: "Background Image",
  },
});
