import React, { useEffect, useState } from "react";
import { LANGUAGES } from "../../../../configs/referential";
import { Dropdown } from "genius-ui";
import style from "./Filters.style";
import { createUseStyles } from "react-jss";
import { connect } from "react-redux";
import clientConfig from "../../../../configs/client";
import FilterLanguages from "../../../../components/FilterLanguages";
import useThemedStyle from "../../../../hooks/style/useThemedStyle";
import {
  GetDegrees,
  UpdateDegreeFilters,
  GetMarketFilterTags,
  UpdateUserMarketIds,
} from "../../../../store/degree/actions";
import { translations } from "../DegreesShiseido.translations";
import { FormattedMessage } from "react-intl";
import { USER_ROLE_NAMES } from "../../../../configs/constants";

const useStyle = createUseStyles(style);

const DefaultFilters = (props) => {
  const {
    UpdateFilters,
    currentLanguage,
    markets,
    selectedMarket,
    GetFilterTags,
    userRoles,
    userProfileTags,
    GetDegrees,
    UpdateUserMarketIds,
  } = props;
  const [marketOptions, setMarketOptions] = useState([]);
  const classes = useThemedStyle(useStyle, props);
  let selectedValue = LANGUAGES.find((x) => x.Code === currentLanguage);

  if (!selectedValue) {
    selectedValue = LANGUAGES.find(
      (x) => x.Code === clientConfig.DEFAULT_LANGUAGE_CODE,
    );
  }

  useEffect(() => {
    GetFilterTags(() => {
      if (userRoles && userRoles.length) {
        let marketOptions = markets;

        if (userRoles.includes(USER_ROLE_NAMES.TRAINER)) {
          marketOptions = markets.filter((x) =>
            userProfileTags.find((y) => y.tagId === x.value),
          );
          UpdateUserMarketIds(marketOptions.map((option) => option.value));
          UpdateFilters("market", marketOptions?.[0]);
          setMarketOptions(marketOptions);
        } else {
          setMarketOptions([
            {
              value: null,
              label: (
                <FormattedMessage {...translations.AllMarketsFilterOption} />
              ),
            },
            ...marketOptions,
          ]);
        }
        GetDegrees();
      }
    });
  }, [userRoles, userProfileTags?.length, markets?.length]);

  return (
    <div className={classes.rootContainer}>
      <Dropdown
        selectedOption={selectedMarket}
        onChange={(selectedOption) => {
          UpdateFilters("market", selectedOption);
        }}
        options={marketOptions}
        placeholder={
          <FormattedMessage {...translations.AllMarketsFilterOption} />
        }
        disabled={marketOptions.length === 1}
      />
      <FilterLanguages languages={LANGUAGES}>
        {(languages) => (
          <Dropdown
            selectedOption={{
              value: selectedValue.Code,
              label: selectedValue.Name,
            }}
            onChange={(selectedOption) => {
              UpdateFilters("language", selectedOption.value);
            }}
            options={languages.map((x) => ({ value: x.Code, label: x.Name }))}
            disabled={languages.length === 1}
          />
        )}
      </FilterLanguages>
    </div>
  );
};

const mapStateToProp = (state) => ({
  currentLanguage: state.degrees.degreeFilters.language.selectedValue,
  markets: state.degrees.markets,
  selectedMarket: state.degrees.degreeFilters.market.selectedValue,
  userProfileTags: state.auth.profileInfo.tags,
  userRoles: state.auth.profileInfo.roles,
});

const mapDispatchToProps = (dispatch) => ({
  UpdateFilters: (type, data) => {
    data = type === "market" && !data.value ? null : data;
    dispatch(UpdateDegreeFilters(type, data));
    dispatch(GetDegrees());
  },
  GetFilterTags: (callback) => {
    dispatch(GetMarketFilterTags(callback));
  },
  UpdateUserMarketIds: (marketIds) => dispatch(UpdateUserMarketIds(marketIds)),
  GetDegrees: () => dispatch(GetDegrees()),
});

export default connect(mapStateToProp, mapDispatchToProps)(DefaultFilters);
