import React, { useEffect } from "react";
import { jsonToString } from "../../utils/authentification";
import { FUSIONAUTH_CONFIG } from "../../configs/client";
import AuthService from "../../core/authService";
import { FullscreenLoader } from "genius-ui";

const handleClearSession = () => {
  const logoutParams = jsonToString({
    client_id: FUSIONAUTH_CONFIG.clientId,
    tenant_id: FUSIONAUTH_CONFIG.tenantId,
    post_logout_redirect_uri: FUSIONAUTH_CONFIG.postLogoutRedirectUri,
  });
  const logoutUrl = `${FUSIONAUTH_CONFIG.issuer}${FUSIONAUTH_CONFIG.routes.logout}?${logoutParams}`;

  AuthService.clearCache();
  window.location.href = logoutUrl;
};

const Logout = () => {
  useEffect(() => {
    handleClearSession();
  }, []);
  return <FullscreenLoader />;
};

export default Logout;
