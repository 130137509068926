import { defineMessages } from "react-intl";

export const translations = defineMessages({
  Title: {
    id: "AddToMarketScreen.Title",
    defaultMessage: "Add to market",
  },
  CancelBtnText: {
    id: "AddToMarketScreen.CancelBtnText",
    defaultMessage: "Cancel",
  },
  NextBtnText: {
    id: "AddToMarketScreen.NextBtnText",
    defaultMessage: "Next",
  },
  ContentSubtitle: {
    id: "AddToMarketScreen.ContentSubtitle",
    defaultMessage:
      "Add this Degree/Program/Lesson to your market to publish it on the app",
  },
  ContentSubtitleInsight: {
    id: "AddToMarketScreen.ContentSubtitleInsight",
    defaultMessage: "Add this Insight to your market to publish it on the app",
  },
  ContentSubtitlePoll: {
    id: "AddToMarketScreen.ContentSubtitlePoll",
    defaultMessage: "Add this Poll to your market to publish it on the app",
  },
  ContentSubtitleBattle: {
    id: "AddToMarketScreen.ContentSubtitleBattle",
    defaultMessage: "Add this Battle to your market to publish it on the app",
  },
  ContentSubtitleCard: {
    id: "AddToMarketScreen.ContentSubtitleCard",
    defaultMessage: "Add this Card to your market to publish it on the app",
  },
  ContentTitle: {
    id: "AddToMarketScreen.ContentTitle",
    defaultMessage: "Add {lang} to market",
  },
  DegreeDeploySuccessMessage: {
    id: "AddToMarketScreen.DegreeDeploySuccessMessage",
    defaultMessage: "Your master degree has been deployed",
  },
  LessonDeploySuccessMessage: {
    id: "AddToMarketScreen.LessonDeploySuccessMessage",
    defaultMessage: "Your master lesson has been deployed",
  },
  InsightDeploySuccessMessage: {
    id: "AddToMarketScreen.InsightDeploySuccessMessage",
    defaultMessage: "Your master insight has been deployed",
  },
  CardDeploySuccessMessage: {
    id: "AddToMarketScreen.CardDeploySuccessMessage",
    defaultMessage: "Your master card has been deployed",
  },
  BattleDeploySuccessMessage: {
    id: "AddToMarketScreen.BattleDeploySuccessMessage",
    defaultMessage: "Your master battle has been deployed",
  },
  PollDeploySuccessMessage: {
    id: "AddToMarketScreen.PollDeploySuccessMessage",
    defaultMessage: "Your master poll has been deployed",
  },
});
