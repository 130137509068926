import gql from "graphql-tag";

export const LESSON_CLONE_TO_MARKET = gql`
  mutation cloneLesson(
    $lessonId: ID
    $lang: LangEnum!
    $marketTagId: Int
    $parentLearningId: ID
  ) {
    admin {
      lesson(lessonId: $lessonId) {
        clone(
          lang: $lang
          parentLearningId: $parentLearningId
          marketTagId: $marketTagId
          cloneTags: false
          cloneTagsFeature: true
        ) {
          lessonId
        }
      }
    }
  }
`;
