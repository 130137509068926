import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { RegularStyle } from "./Regular.style";
import ContentEditable from "react-contenteditable";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(RegularStyle);

const Regular = (props) => {
  const classes = useThemedStyle(useStyle, props);

  return (
    <ContentEditable
      html={props.value}
      disabled={false}
      onBlur={props.handleChange}
      onKeyDown={props.handleKeyDown}
      tagName="article"
      className={classes.input}
    />
  );
};

Regular.propTypes = {
  /**  value by default shiiped with the input */
  value: PropTypes.string,
  /**  place holder value */
  placeholder: PropTypes.string,
  /**  handle input change */
  handleChange: PropTypes.func,
  /** the method that controls the keydown of the input */
  handleKeyDown: PropTypes.func,
  /** font family, that overrides the default font-family used by the editor **/
  fontFamily: PropTypes.string,
};

Regular.defaultProps = {
  value: "",
};

export default Regular;
