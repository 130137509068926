import React from "react";
import DegreesDefault from "./DegreesDefault";
import DegreesShiseido from "./Shiseido";
import { isShiseido } from "../../configs/client";

const Degrees = (props) => {
  if (isShiseido) {
    return <DegreesShiseido {...props} />;
  }
  return <DegreesDefault {...props} />;
};

export default Degrees;
