const contentEditablePlaceholderStyle = (color) => ({
  "&[contenteditable=true]:empty:before": {
    content: "attr(placeholder)",
    display: "block",
    color: color,
  },
});

export default () => {
  return {
    rootContainer: {
      paddingTop: "150px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
    center: {
      position: "relative",
      width: 952,
      display: "flex",
      justifyContent: "space-between",
    },
    left: {
      width: 900,
      display: "flex",
      flexDirection: "column",
    },
    textInputTitle: {
      border: 0,
      outline: 0,
      background: "transparent",
      marginLeft: 50,
      marginRight: 50,
      fontSize: "22px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "0.1px",
      color: "#252525",
      ...contentEditablePlaceholderStyle("#7c7c7c"),
    },
    descriptionInput: {
      borderRadius: "2px !important",
      padding: "0 10px !important",
      border: "none !important",
      width: "100% !important",
      height: "40px !important",
      background: "white !important",
      "&::placeholder": {
        color: "#CFCFCF !important",
      },
    },
    closeIcon: {
      right: 0,
      top: 0,
      height: "fit-content",
      "&::before": {
        fontSize: 15,
        cursor: "pointer",
      },
      "&:hover::before": {
        fontSize: 15,
        cursor: "pointer",
      },
    },
    detailsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingTop: 30,
      paddingBottom: 30,
      paddingLeft: 50,
      width: "100%",
    },
    leftPart: {
      width: "40%",
      display: "flex",
      flexDirection: "column",
    },
    rightPart: {
      width: "60%",
      display: "flex",
      flexDirection: "column",
    },
    postingDate: {
      display: "flex",
      width: "100%",
      marginTop: 45,
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    postingDateButton: {
      paddingRight: 10,
    },
    brand: {
      display: "flex",
      flexDirection: "row",
      paddingBottom: 10,
    },
    brandTagsContainer: {
      width: "100%",
      marginRight: 20,
    },
    tagSelectContainer: {
      position: "relative",
    },
    tagList: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      paddingBottom: 10,
    },
    sectionTitle: {
      fontSize: 16,
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#252525",
      marginBottom: 10,
    },
    pdfUploaded: {
      color: "green",
      paddingTop: 20,
    },
    heavyFile: {
      color: "#ffcc00",
      paddingTop: 20,
    },
    pdfIFrame: {
      width: "100%",
      height: 500,
      marginRight: 20,
    },
  };
};
