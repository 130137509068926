import React, { useMemo } from "react";
import { tagIdTitleToDropDown } from "utils/transformers/tags";
import { useLazyQuery } from "react-apollo";
import { loadTags } from "store/tag/actions";
import DropDown from "components/Dropdown/Dropdown";
import { GET_TAGS } from "pages/badges/graphs";

const TagFilter = ({ clusterId, handleChange, value, ...props }) => {
  const [query, { data, loading }] = useLazyQuery(GET_TAGS, {
    variables: { tagIds: value },
  });

  const defaultValue = useMemo(() => {
    if (!data) return undefined;
    return tagIdTitleToDropDown(data?.tags?.[0]);
  }, [data]);
  if (value && !data) {
    if (!loading) query();
    return null;
  }
  return (
    <div style={{ width: 200 }}>
      <DropDown
        additional={{ clusterIds: clusterId }}
        searchable={false}
        loadOptions={loadTags}
        handleChange={handleChange}
        defaultValue={defaultValue}
        {...props}
      />
    </div>
  );
};

export default TagFilter;
