import XLSX from "xlsx";
import { excelSanitizer } from "../sanitizerFactory/SanitizerFactory";

export const createXml = (data, fileName = "kpiExport.xlsx") => {
  // Create worksheet from data
  const worksheet = XLSX.utils.aoa_to_sheet(excelSanitizer(data));
  // Resize columns to be readable
  worksheet["!cols"] = getColumnSizes(data);
  // Create document
  const newWorkbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(newWorkbook, worksheet, "");
  // Save to file with the given or default filename.
  XLSX.writeFile(newWorkbook, fileName);
};

// Data contains objects with name (sheet name) and data (array of array which presents the fields in the xml)
export const createMultipleSheetXml = (data, fileName = "export.xlsx") => {
  // Create document
  const newWorkbook = XLSX.utils.book_new();
  // Create sheets and append to the document
  data.forEach((current) => {
    const worksheet = XLSX.utils.aoa_to_sheet(excelSanitizer(current.data));
    worksheet["!cols"] = getColumnSizes(current.data);
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, current.name);
  });

  // Save document
  XLSX.writeFile(newWorkbook, fileName);
};

// Returns an array wich contains the length of columns
const getColumnSizes = (array) => {
  const sizes = [];
  for (let i = 0; i < getWidthOfDataArray(array); i++) {
    sizes.push(getLengthOfColumnIntoWidthObject(array, i));
  }
  return sizes;
};

// Size of the longest row. With other words olumns number.
const getWidthOfDataArray = (array) => {
  return Math.max(...array.map((element) => element.length));
};

// Returns a longest row length in an object from the selected column to the following format: {wch: number}
const getLengthOfColumnIntoWidthObject = (array, column) => {
  const size = Math.max(
    ...array.map((element) =>
      element[column] && element.length > column
        ? element[column].toString().length
        : 0,
    ),
  );

  if (size < 4) {
    return { wch: 4 };
  }
  if (size > 50) {
    return { wch: 50 };
  }
  return { wch: size };
};
