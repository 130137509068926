import gql from "graphql-tag";

export const INSIGHT_CREATE = gql`
  mutation adminCreateInsight($lang: LangEnum, $title: String!) {
    admin {
      insight(lang: $lang) {
        create(title: $title) {
          insightId
          versions {
            title
            lang
          }
        }
      }
    }
  }
`;
