/* global localStorage */
export const jsonToString = (obj = {}) => {
  const toRet = Object.keys(obj).map((key) => `${key}=${obj[key]}`);
  return toRet.join("&");
};

export const parseQueryString = () => {
  const str = window.location.search;
  let objURL = null;
  str.replace(/([^?=&]+)(=([^&]*))?/g, (_a, key, _b, value) => {
    if (!objURL) objURL = {};
    objURL[key] = value;
  });
  return objURL;
};

export const handleToken = (params) => {
  params.expires_at = JSON.stringify(
    params.expires_in * 1000 + new Date().getTime(),
  );
  Object.keys(params).forEach((key) => {
    localStorage.setItem(key, params[key]);
  });
};
