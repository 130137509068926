import React from "react";
import DefaultMasterTemplates from "./DefaultMasterTemplates";
import MasterTemplatesReadOnly from "./masterTemplatesReadOnly";
import { USER_ROLE_NAMES } from "../../configs/constants";
import { connect } from "react-redux";
import { FullscreenLoader } from "genius-ui";

const MasterTemplates = ({ userRoles, ...restOfProps }) => {
  if (!userRoles || !userRoles.length) {
    return <FullscreenLoader />;
  }

  if (userRoles.includes(USER_ROLE_NAMES.TRAINER)) {
    return <MasterTemplatesReadOnly {...restOfProps} />;
  }

  return <DefaultMasterTemplates {...restOfProps} />;
};

const mapStateToProps = (state) => ({
  userRoles: state.auth.profileInfo.roles,
});

export default connect(mapStateToProps)(MasterTemplates);
