import { GetAllTags } from "../tag/actions";
import * as constants from "../../constants/store/common";
import { GetProfileInfo } from "../auth/actions";

export const FetchCommonData = () => async (dispatch) => {
  dispatch(GetAllTags());
  dispatch(GetProfileInfo());
};

export const SetSaveIndicator = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_SAVE_INDICATOR,
    data: data,
  });
};

export const SetUpdateIndicator = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_UPDATE_INDICATOR,
    data: data,
  });
};

export const SetDeleteIndicator = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_DELETE_INDICATOR,
    data: data,
  });
};

export const SetFetchIndicator = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_FETCH_INDICATOR,
    data: data,
  });
};

export const ResetRequestErrorData = () => (dispatch) => {
  dispatch({ type: constants.RESET_REQUEST_ERROR_DATA });
};
