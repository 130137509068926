import gql from "graphql-tag";

export const ADD_TAG = gql`
  mutation addTag($pollId: ID, $lang: LangEnum, $tagIds: [ID!]) {
    admin {
      poll(pollId: $pollId, lang: $lang) {
        tags {
          add(tagIds: $tagIds)
        }
      }
    }
  }
`;
