import { defineMessages } from "react-intl";

export const defaultTranslations = defineMessages({
  Period: {
    id: "KpiFilterHeader.Export.Default.Period",
    defaultMessage: "Period",
  },
  Title: {
    id: "KpiFilterHeader.Export.Default.Title",
    defaultMessage: "Filters",
  },
  Market: {
    id: "KpiFilterHeader.Export.Default.Market",
    defaultMessage: "Market",
  },
  Relationship: {
    id: "KpiFilterHeader.Export.Default.Relationship",
    defaultMessage: "Relationship",
  },
  Retailer: {
    id: "KpiFilterHeader.Export.Default.Retailer",
    defaultMessage: "Retailer",
  },
  Degree: {
    id: "KpiFilterHeader.Export.Default.Degree",
    defaultMessage: "Degree",
  },
});

export const shiseidoTranslations = defineMessages({
  Title: {
    id: "KpiShiseidoFilterHeader.Export.Title",
    defaultMessage: "Filters",
  },
  Relationship: {
    id: "KPIShiseidoFilterHeader.Export.RelationshipFilterLabel",
    defaultMessage: "Relationship",
  },
  Retailer: {
    id: "KPIShiseidoFilterHeader.Export.RetailerFilterLabel",
    defaultMessage: "Retailer",
  },
  Country: {
    id: "KPIShiseidoFilterHeader.Export.CountryFilterLabel",
    defaultMessage: "Country",
  },
  Brand: {
    id: "KPIShiseidoFilterHeader.Export.BrandFilterLabel",
    defaultMessage: "Brand",
  },
  Period: {
    id: "KPIShiseidoFilterHeader.Export.Period",
    defaultMessage: "Period",
  },
  Degree: {
    id: "KPIShiseidoFilterHeader.Export.Degree",
    defaultMessage: "Degree",
  },
});
