import gql from "graphql-tag";

export const DELETE_LESSON_PIC_RECAP = gql`
  mutation deleteLessonPicRecap($lessonId: ID, $lang: LangEnum) {
    admin {
      lesson(lessonId: $lessonId, lang: $lang) {
        delete(type: picLessonRecap)
      }
    }
  }
`;
