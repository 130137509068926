import React from "react";
import DegreesDefault from "./DegreeShiseidoDefault";
import { isShiseido } from "../../../configs/client";
import DegreesTrainer from "./trainer";
import { connect } from "react-redux";
import { FullscreenLoader } from "genius-ui";
import { USER_ROLE_NAMES } from "../../../configs/constants";
import { useUserRoles } from "../../../store/auth/hooks";

const DegreeBuilder = ({ ...otherProps }) => {
  const { userRoles, getRoles } = useUserRoles();

  if (!userRoles || !userRoles.length) {
    getRoles();
    return <FullscreenLoader />;
  }

  if (isShiseido && userRoles.includes(USER_ROLE_NAMES.TRAINER)) {
    return <DegreesTrainer {...otherProps} />;
  }
  return <DegreesDefault {...otherProps} />;
};

const mapStateToProps = (state) => ({
  userRoles: state.auth.profileInfo.roles,
});

export default connect(mapStateToProps)(DegreeBuilder);
