import React, { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Icon, Icons } from "genius-ui";
import { createUseStyles } from "react-jss";
import Style from "./PeriodSelector.style";
import ReactDatePicker from "react-datepicker";
import { translations } from "./PeriodSelector.translations";
import { FormattedMessage } from "react-intl";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import {
  formatDate,
  addWeek,
  addMonth,
  addYear,
  getYearBeginningDate,
} from "../../../utils/dateUtils";

const dateFormat = "MMM.dd yyyy";

const useStyle = createUseStyles(Style);

export const getPredefinedDate = ({ value }) => {
  const today = new Date();
  let periodStart = today;

  switch (value) {
    case 1:
    case 9:
      periodStart = today;
      break;
    case 2:
    case 10:
      periodStart = addWeek(today, -1);
      break;
    case 3:
      periodStart = addMonth(today, -1);
      break;
    case 4:
    case 11:
      periodStart = addMonth(today, -3);
      break;
    case 5:
    case 12:
      periodStart = addMonth(today, -6);
      break;
    case 6:
    case 13:
      periodStart = addMonth(today, -9);
      break;
    case 7:
    case 14:
      periodStart = addYear(today, -1);
      break;
    case 15:
      periodStart = addYear(today, -2);
      break;
    case 8:
      periodStart = getYearBeginningDate(today);
      break;
    default:
      break;
  }
  periodStart = formatDate(periodStart, dateFormat);

  return `${periodStart} - ${formatDate(today, dateFormat)}`;
};

const PeriodSelector = ({
  selectedOption,
  startDate,
  endDate,
  options,
  selectOptionHandler,
  selectStartDateHandler,
  selectEndDateHandler,
  onFilterChange,
}) => {
  const classes = useThemedStyle(useStyle);

  const optionListEl = useRef(null);

  const [showStartDateCalendar, setStartDateCalendar] = useState(false);
  const [showEndDateCalendar, setEndDateCalendar] = useState(false);
  const [isOptionsVisible, setOptionsVisibility] = useState(false);

  const selectedPeriod =
    startDate && endDate
      ? `${formatDate(startDate, dateFormat)} - ${formatDate(
          endDate,
          dateFormat,
        )}`
      : startDate
      ? `${formatDate(startDate, dateFormat)} - ${formatDate(
          startDate,
          dateFormat,
        )}`
      : getPredefinedDate(selectedOption || {});

  const showStartDateCalendarHandler = useCallback(() => {
    setStartDateCalendar(true);
    setEndDateCalendar(false);
  }, []);
  const showEndDateCalendarHandler = useCallback(() => {
    setEndDateCalendar(true);
    setStartDateCalendar(false);
  }, []);

  const hideEndDateCalendarHandler = () => setEndDateCalendar(false);
  const hideStartDateCalendarHandler = () => setStartDateCalendar(false);

  const changeOptionsVisibility = () => {
    setOptionsVisibility(!isOptionsVisible);
    if (isOptionsVisible) {
      setEndDateCalendar(false);
      setStartDateCalendar(false);
    }
  };
  const hideOptions = () => {
    setOptionsVisibility(false);
    setEndDateCalendar(false);
    setStartDateCalendar(false);
  };

  const onPeriodChange = (period) => {
    if (period && period.value) {
      var newStartDate = new Date();
      var newEndDate = new Date();
      switch (period.value) {
        case 1:
        case 9:
          newStartDate.setDate(newStartDate.getDate() - 1);
          break;
        case 2:
        case 10:
          newStartDate.setDate(newStartDate.getDate() - 7);
          break;
        case 3:
          newStartDate.setMonth(newStartDate.getMonth() - 1);
          break;
        case 4:
        case 11:
          newStartDate.setMonth(newStartDate.getMonth() - 3);
          break;
        case 5:
        case 12:
          newStartDate.setMonth(newStartDate.getMonth() - 6);
          break;
        case 6:
        case 13:
          newStartDate.setMonth(newStartDate.getMonth() - 9);
          break;
        case 7:
        case 14:
          newStartDate.setYear(newStartDate.getFullYear() - 1);
          break;
        case 15:
          newStartDate.setYear(newStartDate.getFullYear() - 2);
          break;
        case 8: {
          newStartDate.setMonth(0);
          newStartDate.setDate(1);
          newStartDate.setHours(0);
          newStartDate.setMinutes(0);
          newStartDate.setSeconds(0);
          break;
        }
        default:
          newStartDate.setDate(newStartDate.getDate() - 1);
          break;
      }
    }
    selectOptionHandler(period);
    selectStartDateHandler(newStartDate);
    selectEndDateHandler(newEndDate);
    hideOptions();
  };

  const onStartDateChange = (date) => {
    selectStartDateHandler(date);
    selectOptionHandler(undefined);
    onFilterChange && date && endDate && onFilterChange();
    hideStartDateCalendarHandler();
  };

  const onEndDateChange = (date) => {
    selectEndDateHandler(date);
    selectOptionHandler(undefined);
    onFilterChange && startDate && date && onFilterChange();
    hideEndDateCalendarHandler();
  };

  useEffect(() => {
    const listener = (event) => {
      if (
        !optionListEl.current ||
        optionListEl.current.contains(event.target)
      ) {
        return;
      }
      setOptionsVisibility(false);
      setEndDateCalendar(false);
      setStartDateCalendar(false);
    };
    document.addEventListener("click", listener);

    return () => {
      document.removeEventListener("click", listener);
    };
  }, []);

  useEffect(() => {
    selectedOption && onPeriodChange(selectedOption);
  }, [selectedOption]);

  return (
    <div className={classes.rootContainer} ref={optionListEl}>
      <div className={classes.label} onClick={changeOptionsVisibility}>
        <div className={classes.leftSide}>
          <div>
            <Icon iconName={Icons.calendar} style={classes.calendarIcon} />
          </div>
          <div>{selectedPeriod}</div>
        </div>
        <div>
          <Icon iconName={Icons.arrowDown} style={classes.arrowIcon} />
        </div>
      </div>
      {isOptionsVisible && (
        <div className={classes.optionsContainer}>
          <div className={classes.periodSelectors}>
            <div>
              <div className={classes.inputLabel}>
                <FormattedMessage {...translations.StartDateLabel} />
              </div>
              <input
                className={classes.input}
                placeholder="mm/dd/yyyy"
                onFocus={showStartDateCalendarHandler}
                value={startDate ? formatDate(startDate, dateFormat) : ""}
                onChange={() => null}
              />
            </div>
            <div>
              <div className={classes.inputLabel}>
                <FormattedMessage {...translations.EndDateLabel} />
              </div>
              <input
                className={classes.input}
                placeholder="mm/dd/yyyy"
                onFocus={showEndDateCalendarHandler}
                value={endDate ? formatDate(endDate, dateFormat) : ""}
                onChange={() => null}
                disabled={!startDate}
              />
            </div>
          </div>
          {showStartDateCalendar && (
            <div className={classes.calendar}>
              <ReactDatePicker
                inline
                selected={startDate || new Date()}
                onChange={onStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          )}
          {showEndDateCalendar && (
            <div className={classes.calendar}>
              <ReactDatePicker
                inline
                selected={endDate || startDate}
                onChange={onEndDateChange}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          )}
          {!showStartDateCalendar && !showEndDateCalendar && (
            <div className={classes.options}>
              {options.map((option) => (
                <div
                  key={option.value}
                  onClick={() => selectOptionHandler(option)}
                >
                  {option.label}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

PeriodSelector.propTypes = {
  selectedOption: PropTypes.object,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.array.isRequired,
  selectOptionHandler: PropTypes.func.isRequired,
  selectStartDateHandler: PropTypes.func.isRequired,
  selectEndDateHandler: PropTypes.func.isRequired,
};

export default PeriodSelector;
