import gql from "graphql-tag";

export const GET_BRAND_TAGS_BY_MARKET_ID = gql`
  query GetBrandTagsByMarketId($clusterIds: [ID!], $tagIds: [ID!]) {
    tags(clusterIds: $clusterIds, tagIds: $tagIds) {
      pic
      tagId
      title
      clusterName
      clusterId
      tagsToCopyMultiple {
        pic
        tagId
        title
        clusterName
        clusterId
      }
    }
  }
`;
