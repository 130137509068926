import gql from "graphql-tag";

export const LESSON_CREATE = gql`
  mutation adminCreateLesson($lang: LangEnum, $title: String!, $programId: ID) {
    admin {
      lesson(lang: $lang) {
        create(title: $title, programId: $programId) {
          lessonId
        }
      }
    }
  }
`;
