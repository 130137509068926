import gql from "graphql-tag";
import { PAGE_TYPES } from "../../../../configs/constants";

export const POLL_CREATE_PAGE = gql`
mutation createPollPage(
  $pollId: ID
  $lang: LangEnum
  $data: String!
) {
  admin {
    poll(pollId: $pollId, lang: $lang) {
      pages {
        create(type: ${PAGE_TYPES.POLL}, data: $data) {
          pageId
        }
      }
    }
  }
}`;
