import DocumentPageHeader from "components/common/DocumentPageHeader";
import NinjaQuestionEditor from "components/Ninja/NinjaQuestionEditor";
import ActivityIntro from "../common/ActivityIntroComponent";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  SetNinjaFromData,
  SetEditedNinjaPageId,
  changeTitle,
  onChangeCorrect,
  changeChoice,
  addChoice,
  removeChoice,
  AddNinjaQuestionBefore,
  AddNinjaQuestionAfter,
  onRemove,
  changeImage,
  changeType,
} from "../../store/editNinja/actions";
import Style from "./NinjaActivityPageEditor.style.js";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const NinjaActivityPageEditor = (props) => {
  const {
    pageId,
    data,
    orderId,
    documentId,
    language,
    intro,
    onRemovePage,
    onOrderDownClick,
    onOrderUpClick,
    setNinjaFromData,
    setEditedNinjaPageId,
    onChangeTitle,
    onChangeCorrect,
    onChangeText,
    addChoice,
    onRemoveChoice,
    questions,
    addQuestionBefore,
    addQuestionAfter,
    onRemoveClick,
    onChangeImage,
    onChangeType,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  useEffect(() => {
    setEditedNinjaPageId(pageId);
    setNinjaFromData(data, documentId, language);
  }, []);

  const handleRemovePage = useCallback(() => {
    onRemovePage(documentId, language, pageId);
  }, [onRemovePage, documentId, language, pageId]);

  const handleOrderDownClick = useCallback(() => {
    onOrderDownClick(documentId, language, pageId);
  }, [onOrderDownClick, documentId, language, pageId]);

  const handleOrderUpClick = useCallback(() => {
    onOrderUpClick(documentId, language, pageId);
  }, [onOrderUpClick, documentId, language, pageId]);

  const [selectedIndex, setSelectedIndex] = useState(null);

  return (
    <div className={classes.pageBox}>
      <DocumentPageHeader
        pageNumber={orderId}
        onRemoveClick={handleRemovePage}
        onOrderDownClick={handleOrderDownClick}
        onOrderUpClick={handleOrderUpClick}
      />
      <div className={classes.ninjaIntro}>
        <ActivityIntro
          activityId={pageId}
          theme={intro.theme}
          title={intro.title}
          description={intro.description}
          image={intro.image}
          isSelected
          onChange={() => {}}
        />
      </div>
      {questions?.map(({ id, text, choices }, index) => (
        <NinjaQuestionEditor
          key={id}
          id={id}
          title={text}
          choices={choices}
          onSelect={() => setSelectedIndex(index)}
          isSelected={index === selectedIndex}
          onChangeCorrect={onChangeCorrect}
          onChangeTitle={onChangeTitle}
          onChangeText={onChangeText}
          onRemoveChoice={onRemoveChoice}
          addChoice={addChoice}
          addQuestionBefore={addQuestionBefore}
          addQuestionAfter={addQuestionAfter}
          onRemoveClick={onRemoveClick}
          onChangeImage={onChangeImage}
          onChangeType={onChangeType}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  questions: state.editNinja.questions,
  intro: state.editNinja.intro,
});

const mapDispatchToProps = (dispatch) => ({
  setNinjaFromData: (data, documentId, language) =>
    dispatch(SetNinjaFromData(data, documentId, language)),
  setEditedNinjaPageId: (pageId) => dispatch(SetEditedNinjaPageId(pageId)),
  onChangeTitle: (id, value) => dispatch(changeTitle(id, value)),
  onChangeCorrect: (id, index) => dispatch(onChangeCorrect(id, index)),
  onChangeText: (id, index, value) => dispatch(changeChoice(id, index, value)),
  addChoice: (id) => dispatch(addChoice(id)),
  onRemoveChoice: (id, index) => dispatch(removeChoice(id, index)),
  addQuestionBefore: (id) => dispatch(AddNinjaQuestionBefore(id)),
  addQuestionAfter: (id) => dispatch(AddNinjaQuestionAfter(id)),
  onRemoveClick: (id) => dispatch(onRemove(id)),
  onChangeImage: (id, index, value) => dispatch(changeImage(id, index, value)),
  onChangeType: (id, index) => dispatch(changeType(id, index)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NinjaActivityPageEditor);
