import { Icons } from "genius-ui";

const selectTemplateWidth = 360;

const selectTemplateVisible = { opacity: 1, width: selectTemplateWidth };
const selectTemplateHidden = { opacity: 0, width: 0 };
const selectTemplateAnimationProps = {
  animationDuration: "300ms",
  animationTimingFunction: "ease-in",
  animationFillMode: "forwards",
};

export const SideComponentBarStyle = ({
  SideComponentBar,
  FontFamily,
  Fonts,
  TextSizes,
  Colors,
}) => ({
  "@keyframes selectTemplateAnimateIn": {
    from: selectTemplateHidden,
    to: selectTemplateVisible,
  },
  "@keyframes selectTemplateAnimateOut": {
    from: selectTemplateVisible,
    to: selectTemplateHidden,
  },
  selectTemplateAnimateIn: {
    animationName: "$selectTemplateAnimateIn",
    ...selectTemplateAnimationProps,
  },
  selectTemplateAnimateOut: {
    animationName: "$selectTemplateAnimateOut",
    ...selectTemplateAnimationProps,
  },
  mainContainer: {
    display: "flex",
    height: "100%",
    "& > .NavMainContent": {
      width: 60,
    },
  },
  navigation: {
    backgroundColor: `${Colors.white}`,
    boxShadow: `-3px 0 24px 0 ${SideComponentBar.navigation.boxShadowColor}`,
    border: `solid 1px ${SideComponentBar.navigation.borderColor}`,
    position: "fixed",
    height: "100vh",
    top: 0,
    left: 0,
    zIndex: 99999,
  },
  ul: {
    left: 0,
    margin: 0,
    padding: 0,
    listStyleType: "none",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    "&:first-child": {
      paddingTop: "1.5rem",
    },
  },
  li: {
    height: 60,
    width: 60,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&  a": {
      textDecoration: "none",
    },
    "&:hover": {
      color: Colors.lightBlue,
      cursor: "pointer",
    },
    "& > span": {
      "&:hover::before": {
        color: Colors.lightBlue,
        cursor: "pointer",
      },
    },
    "&:hover *": {
      color: Colors.lightBlue,
      cursor: "pointer",
      "&::before": {
        color: Colors.lightBlue,
        cursor: "pointer",
      },
    },
  },
  iconText: {
    cursor: "pointer",
    color: `${Colors.lightBlue}`,
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 35,
      cursor: "pointer",
    },
    "&::before": {
      color: `${Colors.navigationMenu.inactiveItem}`,
      fontSize: 35,
      cursor: "pointer",
    },
  },
  iconImage: {
    cursor: "pointer",
    color: `${Colors.lightBlue}`,
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 18,
    },
    "&::before": {
      color: `${Colors.navigationMenu.inactiveItem}`,
      fontSize: 18,
    },
  },
  iconVideo: {
    cursor: "pointer",
    color: `${Colors.lightBlue}`,
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 12,
    },
    "&::before": {
      color: `${Colors.navigationMenu.inactiveItem}`,
      fontSize: 12,
    },
  },
  back: {
    display: "flex",
    flexDirection: "column",
    height: 80,
    width: 60,
    alignItems: "center",
    justifyContent: "center",
    "&::after": {
      content: '""',
      display: "block",
      borderBottom: `1px solid ${SideComponentBar.navigation.borderColor}`,
      width: 40,
      margin: "0 auto",
      paddingTop: 25,
    },
    "&:hover": {
      color: Colors.lightBlue,
      cursor: "pointer",
    },
    "& > span": {
      "&::before": {
        color: SideComponentBar.navigation.backButtonColor,
      },
      "&:hover::before": {
        color: Colors.lightBlue,
        cursor: "pointer",
      },
    },
    "&:hover *": {
      color: Colors.lightBlue,
      cursor: "pointer",
      "&::before": {
        color: Colors.lightBlue,
        cursor: "pointer",
      },
    },
  },
  iconLabel: {
    marginTop: 6.5,
    fontFamily: FontFamily,
    fontSize: 10,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "condensed",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: SideComponentBar.navigation.iconLabelColor,
    whiteSpace: "nowrap",
  },
  iconSlider: {
    cursor: "pointer",
    color: `${Colors.lightBlue}`,
    height: "20px !important",
  },
  iconSettings: {
    "&::before": {
      color: `${Colors.navigationMenu.inactiveItem}`,
      fontSize: 20,
    },
  },
  sliderPath1: {
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 20,
    },
    "&::before": {
      color: `${Colors.navigationMenu.inactiveItem}`,
      fontSize: 20,
    },
  },
  sliderPath2: {
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 20,
    },
    "&::before": {
      color: `${Colors.navigationMenu.inactiveItem}`,
      fontSize: 20,
    },
  },
  sliderPath3: {
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 20,
    },
    "&::before": {
      color: `${Colors.navigationMenu.inactiveItem}`,
      fontSize: 20,
    },
  },
  scrollImages: {
    cursor: "pointer",
    color: `${Colors.lightBlue}`,
    [`& .${Icons.scrollImages}`]: {
      fontSize: 22,
    },
    "&:hover .path1::before, &:hover .path2::before, &:hover .path3::before": {
      color: `${Colors.lightBlue}`,
    },
    "& .path1::before,  .path2::before, .path3::before": {
      color: `${Colors.navigationMenu.inactiveItem}`,
    },
  },
  selectTemplate: {
    width: selectTemplateWidth,
    height: "100%",
    backgroundColor: Colors.selectTemplate.backgroundColor,
    fontFamily: Fonts.fontFamilyCondensed,
    fontSize: TextSizes.Regular,
    fontStretch: "condensed",
    "& > .selectTemplateTitle": {
      backgroundColor: Colors.lightBlue,
      color: Colors.text.white,
      fontWeight: "bold",
      letterSpacing: 0.05,
      padding: "21px 0 20px 22px",
      height: 19,
      zIndex: "99999 !important",
    },
    "& > .scrollableTemplateContainer": {
      height: "100%",
      overflow: "auto",
      "& > .selectTemplateItem": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 40,
        marginBottom: 40,
        cursor: "pointer",
        "& img": {
          width: 200,
          height: "auto",
        },
        "& p": {
          lineHeight: 1.25,
          fontWeight: 300,
          margin: "0 0 10px 0",
        },
      },
    },
  },
});
