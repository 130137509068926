import { defineMessages } from "react-intl";

export const translations = defineMessages({
  NoContentText: {
    id: "BattleLeaderBoardTable.NoContentText",
    defaultMessage: "No data yet",
  },
  UsersColumnTitle: {
    id: "BattleLeaderBoardTable.UsersColumnTitle",
    defaultMessage: "Users",
  },

  AttemptsColumnTitle: {
    id: "BattleLeaderBoardTable.AttemptsColumnTitle",
    defaultMessage: "Number of attempts",
  },
  TotalScoreColumnTitle: {
    id: "BattleLeaderBoardTable.TotalScoreColumnTitle",
    defaultMessage: "Total score",
  },
  HighestScore: {
    id: "BattleLeaderBoardTable.HighestScore",
    defaultMessage: "Highest Score",
  },
  AverageScoreColumnTitle: {
    id: "BattleLeaderBoardTable.AverageScoreColumnTitle",
    defaultMessage: "Average score",
  },
  EmailTitle: {
    id: "BattleLeaderBoardTable.EmailTitle",
    defaultMessage: "E-mail",
  },
  RelationshipTitle: {
    id: "BattleLeaderBoardTable.RelationshipTitle",
    defaultMessage: "Relationship with Shiseido",
  },
  MarketTitle: {
    id: "BattleLeaderBoardTable.MarketTitle",
    defaultMessage: "Market",
  },
  RetailerTitle: {
    id: "BattleLeaderBoardTable.RetailerTitle",
    defaultMessage: "Retailer",
  },
  BrandsTitle: {
    id: "BattleLeaderBoardTable.BrandsTitle",
    defaultMessage: "Brands",
  },
});
