import { REACT_APP_CLIENT_NAME } from "../../configs/client";

export default async () => {
  let clientName = "SHISEIDO";

  switch (REACT_APP_CLIENT_NAME) {
    case "SHISEIDO":
    case "SHISEIDO-PREPROD":
    case "SHISEIDO-PROD":
      clientName = "SHISEIDO";
      break;
    case "PLURIS":
      clientName = "PLURIS";
      break;
    default:
      clientName = "SHISEIDO";
  }
  // dynamically importing the translations file of the client
  const messages = await import(
    `../clientTranslations/translations.${clientName}.json`
  );

  return messages.default;
};
