import React from "react";
import "constants/scss/style.scss";

const CheckboxCustom = ({
  label,
  value,
  checked,
  handleChange,
  rounded,
  disabled,
}) => {
  let style = "radio";
  if (checked) {
    style = disabled ? "radioCheckedDisabled" : "radioChecked";
  }

  return (
    <button className="radioContainer " onClick={handleChange}>
      <input
        type="checkbox"
        value={label}
        label={label}
        checked
        onChange={handleChange}
      />
      <label
        className={style}
        style={{ borderRadius: rounded ? "50%" : "2px" }}
        value={value}
      />
      <span>{label}</span>
    </button>
  );
};

export default React.memo(CheckboxCustom);
