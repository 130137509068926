import { defineMessages } from "react-intl";

export const translations = defineMessages({
  RemoveAnnouncement: {
    id: "DocGridView.RemoveAnnouncement",
    defaultMessage: "Remove announcement",
  },
  AddAnnouncement: {
    id: "DocGridView.AddAnnouncement",
    defaultMessage: "Add announcement",
  },
  TranslateOption: {
    id: "DocGridView.TranslateOption",
    defaultMessage: "Translate",
  },
  DeleteOption: {
    id: "DocGridView.DeleteOption",
    defaultMessage: "Delete",
  },
  SeeMoreButtonDefaultTitle: {
    id: "DocGridView.SeeMoreButtonDefaultTitle",
    defaultMessage: "Load more",
  },
  SendNotificationOption: {
    id: "DocGridView.SendNotificationTitle",
    defaultMessage: "Send Notification",
  },
  PublishOption: {
    id: "DocGridView.PublishOption",
    defaultMessage: "Publication settings",
  },
});
