import { VARIATIONS as RoundButtonVariations } from "../../components/common/RoundButton/RoundButton.constants";
import {
  FontFamily,
  FontFamilyCondensed,
  IconsFontFamily,
  TextSizes,
} from "../typography";
import { Colors } from "genius-ui";

const defaultTheme = {
  Fonts: {
    baseFontFamily: FontFamily,
    fontFamilyCondensed: FontFamilyCondensed,
    iconsFontFamily: IconsFontFamily,
  },
  TextSizes,
  Colors: {
    lightBlue: "#468ee3",
    fadedBlue: "#61a6d0",
    white: "#ffffff",
    backgroundColor: "#f4f4f4",
    navigationMenu: {
      inactiveItem: "#a0a0a0",
      userColors: [
        "#d55c8b",
        "#ed7291",
        "#fc92ac",
        "#ffb5bd",
        "#f2a9d6",
        "#d592e6",
        "#b77eed",
        "#9479f7",
        "#737cf6",
        "#618ff3",
        "#57a2ec",
        "#68bcf7",
        "#5dccec",
        "#64d6db",
        "#64dfb4",
        "#69cc91",
        "#78d974",
        "#a4e079",
        "#b4d35d",
        "#cce138",
        "#e9ec09",
        "#f5db50",
        "#ffb861",
        "#ff8a70",
        "#fd6e6e",
        "#e0dddd",
        "#d88787",
        "#ae8b89",
        "#e0c5b6",
      ],
    },
    selectTemplate: {
      backgroundColor: "#f3f7fd",
    },
    table: {
      backgroundColor: "#ffffff",
      headerTextColor: "#a0a0a0",
      rowColor: "#454545",
      rowDividerColor: "#f4f4f4",
    },
    text: {
      title: "#252525",
      darker: "#0a1f44",
      dark: "#53627c",
      medium: "#8a94a6",
      light: "#a6aebc",
      lighter: "#c9ced6",
      white: "#fff",
      black: "#000",
      tag: "#6c7680",
      menuItem: "#6c7680",
      selectedMenuItem: "#1e86f4",
      buttonBlue: "#468ee3",
      cardDate: "#777777",
      settingsButton: "#d7d7d7",
      editableSectionBorder: "#468ee3",
      placeholder: "#7c7c7c",
      error: "#ed5553",
    },
  },
  Transitions: {
    slowTransitionTimeMS: 600,
    normalTransitionTimeMS: 300,
    fastTransitionTimeMS: 150,
  },
  Opacities: {
    hover: 0.5,
  },
  SimpleModal: {
    backgroundColor: "#ffffff",
    closeIconColor: "#000000",
  },
  RoundButton: {
    backgroundColor: {
      [RoundButtonVariations.PRIMARY]: "#468ee3",
    },
    color: {
      [RoundButtonVariations.PRIMARY]: "#ffffff",
    },
    boxShadowColor: {
      [RoundButtonVariations.PRIMARY]: "rgba(156, 156, 156, 0.24)",
    },
  },
  MediaSelection: {
    selectedItemOverlayColor: "rgba(70, 142, 227, 0.85)",
    hoveredItemOverlayColor: "rgba(70, 142, 227, 0.75)",
    itemOverlayTextColor: "#ffffff",
  },
  MediaSelectionPopup: {
    textColor: "#454545",
    dividerColor: "#d8d8d8",
  },
  ImageVideoUploader: {
    containerBorderColor: "#468ee3",
    actionButtonColor: "#ffffff",
    actionButtonHoverColor: "#468ee3",
    uploadControlsBoxColor: "#ffffff",
    uploadControlsBoxBackgroundFirst: "rgba(42, 42, 42, 0.4)",
    uploadControlsBoxBackgroundSecond: "rgba(42, 42, 42, 0)",
    uploadBtnWrapperBackground: "transparent",
    iconActionHoverBlue: "#468ee3",
    iconAction: "#ffffff",
  },
  EventFilter: {
    backgroundColor: "#ffffff",
    inputBorderColor: "#e6e6e6",
    inputPlaceholderColor: "#979797",
    borderColor: "rgb(160,160,160)",
  },
  EventDetail: {
    tagBackgroundColor: "rgba(0, 0, 0, 0.5)",
    tagColor: "#ffffff",
    titleColor: "#000000",
    subTitleColor: "#a0a0a0",
    dateColor: "#a0a0a0",
    descriptionColor: "#a0a0a0",
    boutiqueSelector: {
      borderColor: "#f4f4f4",
      color: "#a0a0a0",
      indicatorColor: "#979797",
    },
    menuBGColor: "#ffffff",
    associatedLinkColor: "#a0a0a0",
    guidelineBGImage:
      "linear-gradient(to top, rgba(42, 42, 42, 0.4), rgba(42, 42, 42, 0))",
    guidelineColor: "#ffffff",
    attachment: {
      borderColor: "#f4f4f4",
      color: "#468ee3",
    },
  },
  EditEventLink: {
    titleColor: "#000000",
    labelColor: "#000000",
  },
  DomainItemSmallCard: {
    primaryColor: "#000000",
    secondaryColor: "#8a8a8f",
  },
  SelectFromList: {
    titleColor: "#000000",
    filterIconColor: "#000000",
    filterFocusedIconColor: "#468ee3",
    filters: {
      borderColor: "#e6e6e6",
      backgroundColor: "#ffffff",
      okButtonColor: "#468ee3",
    },
    searchInput: {
      backgroundColor: "#f5f5f5",
      iconColor: "#999999",
    },
    listItem: {
      separatorColor: "#f0f0f0",
    },
  },
  GrayStrokedRSDropdown: {
    backgroundColor: "#ffffff",
    inputBorderColor: "#e6e6e6",
    inputPlaceholderColor: "#979797",
  },
  AssociatedEventItemList: {
    titleColor: "#000000",
    itemSeparatorColor: "#f0f0f0",
    removeItemIconColor: "#a0a0a0",
    noItemsAddedContentColor: "#a0a0a0",
  },
  EventImageInput: {
    imageOverlayColor:
      "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))",
    buttonBackgroundColor: "rgba(69, 69, 69, 0.7)",
    buttonColor: "#ffffff",
  },
  GrayDatePicker: {
    borderColor: "#e6e6e6",
    backgroundColor: "#ffffff",
    disabledBackgroundColor: "#e1e1e1",
    textColor: "#000000",
  },
  EditEvent: {
    inputLabelColor: "#000000",
    titleColor: "#000000",
  },
  EventLinkListItem: {
    color: "#468ee3",
  },
  DayEventsModal: {
    modalWidth: 428,
    boxShadowColor: "rgba(0, 0, 0, 0.1)",
    backgroundColor: "#ffffff",
    closeIconColor: "#000000",
  },
  EventsScreen: {
    calendarBackgroundColor: "#ffffff",
    filterBoxShadowColor: "rgba(0, 0, 0, 0.1)",
  },
  EventDetailScreen: {
    modalWidth: 450,
    boxShadowColor: "rgba(0, 0, 0, 0.1)",
    backgroundColor: "#ffffff",
    actionIconColor: "#ffffff",
  },
  CreateEventScreen: {
    closePopupButtonColor: "#ffffff",
  },
  AddEventItemModal: {
    backgroundColor: "#ffffff",
  },
  JournalProfileScreen: {
    container: {
      color: "#000000",
    },
    leftSide: {
      backgroundColor: "#ffffff",
    },
  },
  LastConnections: {
    rootContainer: {
      backgroundColor: "#ffffff",
    },
    title: {
      color: "#000000",
    },
    row: {
      color: "rgba(60, 60, 67, 0.6)",
    },
  },
  LastEvents: {
    rootContainer: {
      backgroundColor: "#ffffff",
    },
    title: {
      color: "#000000",
    },
  },
  LastEventsElement: {
    rootContainer: {
      borderBottomColor: "#f0f0f0",
    },
    eventTitle: {
      color: "#000000",
    },
    users: {
      color: "rgba(60, 60, 67, 0.3)",
    },
    optionsIcon: {
      color: "#d8d8d8",
    },
  },
  InstagramCard: {
    rootContainer: {
      backgroundColor: "#ffffff",
    },
    header: {
      backgroundColor: "#ffffff",
    },
    footer: {
      backgroundColor: "#ffffff",
    },
    name: {
      color: "#373737",
    },
    text: {
      color: "#000000",
    },
    tags: {
      color: "#0078ff",
    },
  },
  NoteInput: {
    rootContainer: {
      backgroundColor: "#ffffff",
    },
    inputContainer: {
      textAreaFontColor: "#a0a0a0",
    },
    optionsIcon: {
      color: "#c4c4c4",
    },
    date: {
      color: "#c4c4c6",
    },
  },
  EditJournalistProfileScreen: {
    editPhotoBtn: {
      backgroundColor: "rgba(69, 69, 69, 0.7)",
      color: "#ffffff",
      editBtnFontColor: "#ffffff",
    },
    closeIcon: {
      color: "#000000",
    },
    inputContainer: {
      titleFontColor: {
        color: "#252525",
      },
    },
  },
  EditShopHeader: {
    header: {
      backgroundColor: "#fff",
      boxShadow: "rgba(0, 0, 0, 0.05)",
    },
    name: {
      color: "#000",
    },
    back: {
      color: "#000000",
    },
  },
  PublishBattleScreen: {
    customInputStyle: {
      color: "#7c7c7c",
      backgroundColor: "transparent",
    },
    photo: {
      backgroundColor: "#116eed",
    },
    uploadBtn: {
      backgroundColor: "rgba(42, 42, 42, 0.59)",
      borderColor: "#ffffff",
      color: "#ffffff",
    },
    close: {
      color: "#7c7c7c",
    },
    title: {
      color: "#252525",
    },
  },
  PublishPollScreen: {
    customInputStyle: {
      color: "#7c7c7c",
      backgroundColor: "transparent",
    },
    photo: {
      backgroundColor: "#116eed",
    },
    uploadBtn: {
      backgroundColor: "rgba(42, 42, 42, 0.59)",
      borderColor: "#ffffff",
      color: "#ffffff",
    },
    close: {
      color: "#7c7c7c",
    },
    title: {
      color: "#252525",
    },
  },
  UserSummary: {
    text: "#a0a0a0",
    name: "#000",
  },
  UserTag: {
    backgroundColor: "#e7e7e7",
    removeButtonColor: "#484848",
  },
  WhiteAccordion: {
    buttonColor: "#000000",
    buttonBorderColor: "#f4f4f4",
    buttonHoverBackgroundColor: "#E0EEFF!important",
    panelBackgroundColor: "#ffffff",
    caretColor: "#979797",
  },
  ViewSwitcher: {
    activeColor: "#468ee3",
    inActiveColor: "#d8d8d8",
  },
  Video: {
    uploadControlsBoxColor: "#ffffff",
    btnUploadColor: "#ffffff",
    titleProgressColor: "#ffffff",
    descriptionProgressColor: "#468ee3",
  },
  UserInfo: {
    nameColor: "#454545",
    positionColor: "#a0a0a0",
  },
  UploadImageButton: {
    baseColor: "#fff",
    hoverColor: "#468ee3",
  },
  TransparentButton: {
    textColor: "#bdbbbb",
  },
  Title: {},
  TextArea: {
    color: "#454545",
    placeHolderColor: "#d1d1d1",
  },
  SwitchButton: {
    backgroundColor: "#fff",
    boxShadowColor: "rgba(10, 10, 10, 0.29)",
    labelColorOn: "#468ee3",
    labelColorOff: "#fff",
    buttonBoxShadowColor: "rgba(182, 182, 182, 0.5)",
    buttonBackgroundColor: "#ffffff",
  },
  SliderVideoPlayer: {
    titleProgressColor: "#ffffff",
    descriptionProgressColor: "#468ee3",
  },
  HorizontalSlider: {
    selectedSlideBorderColor: "#468ee3",
    imageGradientBG:
      "linear-gradient(to bottom, rgba(42, 42, 42, 0), rgba(42, 42, 42, 0.4))",
    noImageGradientBG:
      "linear-gradient(to bottom, rgba(42, 42, 42, 0), rgba(42, 42, 42, 0.4))",
    uploadControlsBoxColor: "#ffffff",
    uploadControlsBoxBackgroundImage:
      "linear-gradient(to bottom, rgba(42, 42, 42, 0.4), rgba(42, 42, 42, 0))",
    uploadButtonColor: "#ffffff",
    inputColor: "#ffffff",
    inputPlaceholderColor: "#ffffff",
    focusedInputBorderBottomColor: "#ffffff",
  },
  SlideMarker: {
    backgroundColor: "rgba(42, 42, 42, 0.4)",
    iconColor: "#fff",
    safeDropTargetColor: "rgba(70, 142, 227, 0.3)",
    unSafeDropTargetColor: "rgba(198, 0, 0, 0.3)",
    popUpBoxShadowColor: "rgba(125, 125, 125, 0.27)",
    popUpBackgroundColor: "#ffffff",
    actionButtonColor: "#ffffff",
    imageContainerBackgroundColor: "#f4f4f4",
    descriptionInputColor: "#373737",
  },
  SimpleQuizQuestion: {
    feedbackColor: "#468ee3",
    feedbackFullScreenColor: "#ffffff",
    radioHeaderLabelColor: "#cfcfcf",
    innerPageBackgroundColor: "#ffffff",
    deleteButton: {
      color: "#ffffff",
      hoverColor: "#468ee3",
    },
    deleteButtonDark: {
      color: "#7c7c7c",
      hoverColor: "#468ee3",
    },
    questionInput: {
      color: "#7c7c7c",
      fullScreenColor: "#ffffff",
    },
    explanationInput: {
      color: "#cfcfcf",
      fullScreenColor: "#ffffff",
    },
    uploadButtonColor: "#ffffff",
  },
  SimpleQuizAnswer: {
    choiceColorDark: "#ffffff",
    choiceColor: "#dbdbdb",
    choiceInputColorDark: "#ffffff",
    choiceInputColor: "#373737",
    addNewAnswerChoiceColorDark: "#ffffff",
    addNewAnswerChoiceColor: "#dbdbdb",
  },
  SendNotificationModal: {
    backgroundColor: "#ffffff",
    textColor: "#252525",
    profileIconColor: "#929292",
    profileIconHoverColor: "#468ee3",
  },
  AuthorInfo: {
    nameColor: "#252525",
    companyColor: "#252525",
    changeColor: "#468ee3",
  },
  Badge: {
    backgroundColor: "#eaeaea",
    color: "#468ee3",
  },
  BulletTextInput: {
    inputIconWrapBorderBottom: "#d8d9d8",
    inputIconBackgroundColor: "transparent",
    inputIconSpanBeforeColor: "#7c7c7c",
    inputWithIconBackground: "transparent",
    inputWithIconColor: "#7c7c7c",
  },
  ChangeTemplate: {
    containerBoxShadow: "rgba(0, 0, 0, 0.15)",
    containerBackgroundColor: "#ffffff",
    containerAfterBorderTopColor: "#ffffff",
    containerAfterBorderLeftColor: "transparent",
    containerAfterBorderRightColor: "transparent",
    templateItemColor: "#99a2ac",
    templateItemHoverColor: "#468ee3",
    selectedTemplateItemColor: "#468ee3",
  },
  CloneModal: {
    modalContentBackgroundColor: "#ffffff",
    titleColor: "#252525",
    messageLabelColor: "#252525",
  },
  CompletionRate: {
    completionRateColor: "#cbcbcb",
    progressBarBackgroundColor: "#F4F4F4!important",
  },
  ContentNotHandled: {
    pageBoxColor: "#a0a0a0",
    containerMessageBackgroundColor: "#f3f7fd",
    borderColor: "#468ee3",
  },
  ActivityIntroComponentStyle: {
    imageHandlerContainerBackgroundImageFirst: "rgba(42, 42, 42, 0.4)",
    imageHandlerContainerBackgroundImageSecond: "rgba(42, 42, 42, 0))",
    imageHandlerContainerColor: "#fff",
    actionIconBeforeColor: "#fff",
    imageActionContainerHoverColor: "#468ee3",
    imageActionContainerHoverSpanBeforeColor: "#468ee3",
    themeInputColor: "#fff",
    titleInputColor: "#ffffff",
    descriptionInputColor: "#fff",
    descriptionContainerColor: "#ffff",
    btnUploadColor: "#ffffff",
    lightBlue: "#468ee3",
    white: "#fff",
  },
  DeleteModal: {
    contentColor: "#252525",
  },
  PeriodSelectorModal: {
    contentColor: "#252525",
    error: "red",
  },
  DocumentPageHeader: {
    pageTitleBoxColor: "#d8d8d8",
    pageTitleBoxBorderBottomColor: "#dbdbdb",
    deleteIconBackgroundColor: "rgba(216, 216, 216, 0.1)",
    deleteIconHoverBeforeColor: "#468ee3",
    arrowIconBackgroundColor: "rgba(216, 216, 216, 0.1)",
    arrowIconHoverBeforeColor: "#468ee3",
  },
  DragAndDropActivityAnswer: {
    titleColor: "#cfcfcf",
    cardContainerBorderBottomColor: "#dadada",
    cardImageEditorBackgroundColor: "#f4f4f4",
    cardImageUploadColor: "#4a90e2",
    cardImageUploadedSpanIconColor: "#ffffff",
    removeCardImageIconColor: "#ffffff",
    cardTextInputColor: "#373737",
    cardRemoveButtonColor: "#dbdbdb",
    cropCardImageButtonIconColor: "#ffffff",
    addItemButtonColor: "#468ee3",
    disabledButtonColor: "#dadada",
  },
  DragAndDropActivityQuestion: {
    innerPageContainerBorderColor: "#468ee3",
    innerPageContainerBackgroundColor: "#ffffff",
    deleteButtonColor: "#7c7c7c",
    deleteButtonHoverColor: "#468ee3",
    textInputColor: "#7c7c7c",
    zonePlaceholderBoxBorderColor: "#dadada",
  },
  DuplicationModal: {
    titleColor: "#252525",
  },
  RadioGroup: {
    checkedLabelColor: "#373737",
    checkedColor: "#468ee3",
    checkedBackgroundColor: "rgba(70, 142, 227, 0.1)",
    unCheckedBorderColor: "#c7c7c7",
    unCheckedBackgroundColor: "#ffffff",
  },
  PublishDateHandler: {
    titleColor: "#252525",
    activeIconColor: "#468ee3",
    disabledIconColor: "#a6aebc",
  },
  Peanut: {
    switcherColor: "#99a2ac",
    switcherColorHover: "#468ee3",
    input: {
      color: "#ffffff",
      placeHolderColor: "#ffffff",
      focusedBorderColor: "#ffffff",
    },
    legendInputColor: "#7c7c7c",
    defaultBackgroundImage:
      "linear-gradient(to bottom, rgba(42, 42, 42, 0), rgba(42, 42, 42, 0.4))",
    selectedBorderColor: "#468ee3",
    actionButtonColor: "#ffffff",
    actionButtonHoverColor: "#468ee3",
    actionButtonsContainerBackgroundImage:
      "linear-gradient(to bottom, rgba(42, 42, 42, 0.4), rgba(42, 42, 42, 0))",
    cardIndex: {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      color: "#fbfbfb",
    },
    cardTypeSwitcher: {
      activeColor: "#468ee3",
      boxShadowColor: "rgba(0, 0, 0, 0.15)",
      backgroundColor: "#ffffff",
    },
  },
  MultiProgressChart: {
    progressItem: {
      color: "#a0a0a0",
      progressBarBackgroundColor: "#eaeaea",
    },
  },
  MultiLineTextInput: {
    backgroundColor: "#ffffff",
    placeHolderColor: "#d8d8d8",
    inputColor: "#252525",
    focusedBorderColor: "#468ee3",
    invalidBorderColor: "#c60000",
  },
  MarkerSlideVertical: {
    actionButtonColor: "#ffffff",
    actionButtonHoverColor: "#468ee3",
    croppedPartColor: "#4a90e2",
  },
  MarkerSlideHorizontal: {
    containerBackgroundImage:
      "linear-gradient(to bottom, rgba(42, 42, 42, 0), rgba(42, 42, 42, 0.4))",
    selectedBorderColor: "#468ee3",
    actionButtonColor: "#ffffff",
    actionButtonHoverColor: "#468ee3",
    croppedPartColor: "#4a90e2",
  },
  MadLib: {
    titleColor: "#7c7c7c",
    radioHeaderColor: "#cfcfcf",
    selectedBorderColor: "#468ee3",
    backgroundColor: "#fff",
    choiceColor: "#373737",
    choiceBorderColor: "#468ee3",
    actionButtonColor: "#dbdbdb",
    actionButtonHoverColor: "#468ee3",
  },
  VideoPageEditor: {
    backgroundColor: "#ffffff",
  },
  UserStatisticsTable: {
    row: {
      rankColumnColor: "#468ee3",
    },
  },
  Toolbar: {
    iconColor: "#7c7c7c",
  },
  SliderPageEditor: {
    pageBoxColor: "#a0a0a0",
    itemTextColor: "#7c7c7c",
  },
  SimpleQuizPageEditor: {
    pageBoxColor: "#a0a0a0",
  },
  SideComponentBar: {
    navigation: {
      boxShadowColor: "rgba(0, 0, 0, 0.05)",
      borderColor: "#f4f4f4",
      backButtonColor: "#000000",
      iconLabelColor: "#7c7c7c",
    },
  },
  PublishPreviewBanner: {
    containerBackgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  PeanutPageEditor: {
    pageBoxColor: "#a0a0a0",
    itemTextColor: "#7c7c7c",
  },
  PageEditor: {
    pageBoxColor: "#a0a0a0",
    placeHolderSeparatorColor: "#468ee3",
    placeHolderBackgroundColor: "rgba(70, 142, 227, 0.1)",
  },
  NoTraining: {
    messageColor: "#252525",
  },
  NoContent: {
    messageColor: "#252525",
  },
  Navigation: {
    boxShadowColor: "rgba(0, 0, 0, 0.05)",
    separatorColor: "#f4f4f4",
  },
  MainAppContainer: {
    backgroundColor: "#f4f4f4",
  },
  MadlibPageEditor: {
    pageBoxColor: "#a0a0a0",
  },
  MediaInfo: {
    imageContainerColor: "#a0a0a0",
    detailsContainer: {
      titleColor: "#000000",
      descriptionLabelColor: "#454545",
      descriptionDetailColor: "#a0a0a0",
      tagBackgroundColor: "#fafafa",
      tagColor: "#454545",
    },
  },
  MediaFilter: {
    filterIconColor: "#000000",
    tagFilterBorderColor: "#e6e6e6",
    tagFilterContainerBackgroundColor: "#ffffff",
  },
  EditMedia: {
    imageContainerColor: "#a0a0a0",
    detailsContainerColor: "#454545",
  },
  ImageCropper: {
    modalFrameBackgroundColor: "#ffffff",
    actonButtonsBackgroundColor: "#ffffff",
  },
  ImageSelector: {
    borderSelectedColor: "#468ee3",
    uploadControlsBoxColor: "#ffffff",
    uploadControlsBoxBackgroundImageFirst: "rgba(42, 42, 42, 0.4)",
    uploadControlsBoxBackgroundImageSecond: "rgba(42, 42, 42, 0))",
    hoverColor: "#468ee3",
    btnUploadBackgroundColor: "transparent",
    btnUploadColor: "#ffffff",
    iconActionBeforeColor: "#ffffff",
    removeColor: "#ffffff",
  },
  ImageViewer: {
    containerBeforeBackgroundFirst: "rgba(42, 42, 42, 0)",
    containerBeforeBackgroundSecond: "rgba(42, 42, 42, 0.2)",
    containerIconSearchColor: "#ffffff",
    containerIconSearchBackgroundColor: "rgba(0, 0, 0, 0.5)",
    cropButtonBackgroundColor: "transparent",
    cropButtonColor: "#ffffff",
    cropButtonHoverColor: "#468ee3",
    cropButtonHoverBeforeColor: "#468ee3",
    cropButtonBeforeColor: "#ffffff",
    downloadBtnSpanBeforeColor: "#ffffff",
  },
  InstagramCardComponent: {
    containerBackgroundColor: "#ffffff",
    containerBoxShadow: "rgba(0, 0, 0, 0.1)",
    likesColor: "#ffffff",
    likesTextShadow: "#000000",
  },
  LessonRelocationModal: {
    containerBackground: "#ffffff",
    containerColor: "#252525",
  },
  Link: {
    popupBackground: "#ffffff",
    popupColor: "#7c7c7c",
    popupBoxShadow: "rgba(0, 0, 0, 0.15)",
    popupAfterBorderTop: "#ffffff",
    popupAfterSideBorder: "transparent",
    popupSpanBeforeColor: "#000000",
    linkTitleColor: "#468ee3",
    linkColor: "#7c7c7c",
    linkBackgroundColor: "transparent",
  },
  LinkButton: {
    textColor: "#468ee3",
  },
  AuthFlowFormContainer: {
    rootContainerBackgroundColor: "#fff",
    titleColor: "#252525",
    subTitleColor: "#252525",
    versionNumberColor: "#252525",
  },
  BattleQuestionChoiceEditor: {
    darkChoice: "#ffffff",
    notDarkChoice: "#dbdbdb",
    darkChoiceInput: "#ffffff",
    notDarkChoiceInput: "#373737",
    darkAddNewAnswerChoice: "#ffffff",
    notDarkdAddNewAnswerChoice: "#dbdbdb",
  },
  PollQuestionChoiceEditor: {
    darkChoice: "#ffffff",
    notDarkChoice: "#dbdbdb",
    darkChoiceInput: "#ffffff",
    notDarkChoiceInput: "#373737",
    darkAddNewAnswerChoice: "#ffffff",
    notDarkdAddNewAnswerChoice: "#dbdbdb",
  },
  NinjaQuestionChoiceEditor: {
    darkChoice: "#ffffff",
    notDarkChoice: "#dbdbdb",
    darkChoiceInput: "#ffffff",
    notDarkChoiceInput: "#373737",
    darkAddNewAnswerChoice: "#ffffff",
    notDarkdAddNewAnswerChoice: "#dbdbdb",
  },
  BattleQuestionEditor: {
    innerPageContainerSelectedColor: "#468ee3",
    innerPageContainerColor: "transparent",
    innerPageContainerBackgroundColor: "#ffffff",
    deleteButtonColor: "#6b6b6b",
    deleteButtonHoverColor: "#468ee3",
    mediaBackgroundColor: "rgba(70, 142, 227, 0.1)",
    mediaOverlayBackgroundFirst: "rgba(0, 0, 0, 0.5)",
    mediaOverlayBackgroundSecond: "rgba(0, 0, 0, 0)",
    cropImageButtonBackground: "transparent",
    cropImageButtonColor: "#ffffff",
    cropImageButtonIcon: "#ffffff",
    cropImageButtonIconHover: "#468ee3",
    questionInputColor: "#7c7c7c",
    descriptionInputColor: "#6b6b6b",
    radioHeaderColor: "#cfcfcf",
  },
  PollQuestionEditor: {
    innerPageContainerSelectedColor: "#468ee3",
    innerPageContainerColor: "transparent",
    innerPageContainerBackgroundColor: "#ffffff",
    deleteButtonColor: "#6b6b6b",
    deleteButtonHoverColor: "#468ee3",
    mediaBackgroundColor: "rgba(70, 142, 227, 0.1)",
    mediaOverlayBackgroundFirst: "rgba(0, 0, 0, 0.5)",
    mediaOverlayBackgroundSecond: "rgba(0, 0, 0, 0)",
    cropImageButtonBackground: "transparent",
    cropImageButtonColor: "#ffffff",
    cropImageButtonIcon: "#ffffff",
    cropImageButtonIconHover: "#468ee3",
    questionInputColor: "#7c7c7c",
    descriptionInputColor: "#6b6b6b",
    radioHeaderColor: "#cfcfcf",
  },
  NinjaQuestionEditor: {
    innerPageContainerSelectedColor: "#468ee3",
    innerPageContainerColor: "transparent",
    innerPageContainerBackgroundColor: "#ffffff",
    deleteButtonColor: "#6b6b6b",
    deleteButtonHoverColor: "#468ee3",
    mediaBackgroundColor: "rgba(70, 142, 227, 0.1)",
    mediaOverlayBackgroundFirst: "rgba(0, 0, 0, 0.5)",
    mediaOverlayBackgroundSecond: "rgba(0, 0, 0, 0)",
    cropImageButtonBackground: "transparent",
    cropImageButtonColor: "#ffffff",
    cropImageButtonIcon: "#ffffff",
    cropImageButtonIconHover: "#468ee3",
    questionInputColor: "#7c7c7c",
    descriptionInputColor: "#6b6b6b",
    radioHeaderColor: "#cfcfcf",
  },
  ContentTypeCard: {
    containerBackground: "#ffffff",
    titleColor: "#252525",
    descriptionColor: "#252525",
    layerBackground: "rgba(103,103,103,0.25)",
  },
  Header: {
    containerBackground: "#ffffff",
    containerColor: "#000000",
    boxShadowColor: "rgba(0, 0, 0, 0.05)",
  },
  ImagePageEditor: {
    background: "#ffffff",
  },
  DataNotAvailable: {
    noContentText: "#252525",
  },
  DownloadButton: {
    borderColor: "#e6e6e6",
    background: "#ffffff",
    icon: "#a0a0a0",
  },
  DefaultFilterHeader: {
    containerBackground: "#ffffff",
    clearButtonColor: "#468ee3",
    periodFilterBorderColor: "#e6e6e6",
    boxShadowColor: "rgba(0, 0, 0, 0.05)",
  },
  KeyDetailWidget: {
    titleColor: "#454545",
    availabilityColor: "#468ee3",
    availabilityBorderColor: "#468ee3",
    availabilityBackground: "#fff",
    primaryDataColor: "#468ee3",
    secondaryDataColor: "#c9e2ff",
    percentDataColorLow: "#c60000",
    percentDataColor: "#64db00",
  },
  MultiProgressWidget: {
    titleBackground: "#f8fbfe",
    titleColor: "#468ee3",
    titleRightColor: "#a0a0a0",
  },
  ProgramsWidgetDior: {
    headerColor: "#cbcbcb",
  },
  ProgramsWidgetDiorLessonRow: {
    containerColor: "#a0a0a0",
    usersQuitContainerColor: "#cbcbcb",
  },
  TooltipKpi: {
    containerBackground: "#ffffff",
    borderColor: "#f2f2f2",
    boxShadowColor: "rgba(0, 0, 0, 0.05)",
    labelInforColor: "#000000",
  },
  ProgramsWidgetShiseido: {
    headerColor: "#cbcbcb",
    lessonCompletionRateColor: "#99e7db",
    lessonHeaderColor: "#918e8e",
    lessonBackgroundColor: "#fbfbfb",
    lessonDataColor: "#a0a0a0",
    lessonDataColorLight: "#cbcbcb",
  },
  QuantityWidget: {
    leftSideColor: "#2D8EFF",
    leftSideBackground: "#E0EEFF",
    titleColor: "#468ee3",
    percentDataColor: "#c9e2ff",
  },
  TagCloudWidget: {
    titleColor: "#a0a0a0",
  },
  WidgetContainer: {
    containerBackground: "#ffffff",
  },
  WidgetSection: {
    titleColor: "#000000",
  },
  ContentManagerScreen: {
    filterColor: "#a0a0a0",
    createContentColor: "#468ee3",
  },
  ContentTypeScreen: {
    backButtonColor: "#000000",
  },
  CreateCardScreen: {
    selectTemplate: {
      boxShadowColor: "rgba(0, 0, 0, 0.1)",
    },
  },
  CreateCardInstagramScreen: {
    backButtonColor: "#000000",
  },
  CreateInsightScreen: {
    placeHolderColor: "#468ee3",
    placeHolderBackgroundColor: "rgba(70, 142, 227, 0.1)",
    closeIconColor: "#252525",
    closeIconHoverColor: "#468ee3",
  },
  LessonHandler: {
    iconBackgroundColor: "rgba(216, 216, 216, 0.1)",
    titleColor: "#252525",
    statusColorPublished: "#468ee3",
    statusColorUnPublished: "#a0a0a0",
    moreIconColorHover: "#468ee3",
    moreIconColor: "#b2b3b3",
  },
  NoOneProgramHandler: {
    noContentTextColor: "#252525",
  },
  ProgramHandler: {
    backgroundColor: "#fff",
    iconColor: "#929292",
    iconHoverColor: "#468ee3",
    headerSeparatorColor: "#f4f4f4",
    textInputColor: "#000000",
    textInputPlaceholderColor: "#a0a0a0",
    collapseIconColor: "#929292",
    lessonNumberColor: "#000000",
    createLessonButtonColor1: "#468ee3",
    createLessonButtonColor2: "#a0a0a0",
  },
  DegreesBuilderScreen: {
    closeIconColor: "#252525",
    closeIconColorHover: "#468ee3",
    headerBackgroundColor: "#fff",
    headerColor: "#454545",
  },
  CreateProgram: {
    inputColor: "#7c7c7c",
  },
  ProgramInfo: {
    iconColor: "#929292",
    iconHoverColor: "#468ee3",
    textInputColor: "#252525",
    inputIconColor: "#7c7c7c",
    lessonItemSeparatorColor: "#d8d9d8",
    statusPublishedColor: "#468ee3",
    statusDraftColor: "#99a2ac",
    lessonAuthorColor: "#7c7c7c",
    orderArrowBackgroundColor: "#d8d8d8",
    orderArrowColor: "#7c7c7c",
  },
  DegreesBuilderBackupScreen: {
    closeIconColor: "#252525",
    closeIconHoverColor: "#468ee3",
  },
  DegreesScreen: {
    linkColor: "#468ee3",
    otherDegreeTitleColor: "#252525",
  },
  PeriodSelector: {
    rootContainer: {
      backgroundColor: "#ffffff",
      borderColor: "#e6e6e6",
    },
    label: {
      color: "#454545",
    },
    calendarIcon: {
      color: "#468ee3",
    },
    arrowIcon: {
      color: "#a0a0a0",
    },
    options: {
      borderTopColor: "#e6e6e6",
      color: "#ffffff",
      backgroundColor: "#468ee3",
    },
    optionsContainer: {
      boxShadowColor: "rgba(0, 0, 0, 0.1)",
      backgroundColor: "#ffffff",
    },
    input: {
      borderColor: "#e6e6e6",
      placeholderColor: "rgba(0, 0, 0, 0.24)",
    },
    calendar: {
      react_datepicker: {
        borderTopColor: "#e6e6e6",
      },
      react_datepicker_day: {
        color: "#000000",
      },
      react_datepicker_day_selected: {
        backgroundColor: "#468ee3",
        color: "#ffffff",
      },
      react_datepicker_day_in_range: {
        color: "#ffffff",
      },
      react_datepicker_header: {
        backgroundColor: "#ffffff",
      },
      react_datepicker_day_name: {
        color: "#a0a0a0",
      },
      react_datepicker_navigation: {
        color: "#000000",
      },
    },
  },
  EditBattleScreen: {
    closeIconColor: "#252525",
    closeIconHoverColor: "#468ee3",
  },
  EditPollScreen: {
    closeIconColor: "#252525",
    closeIconHoverColor: "#468ee3",
  },
  EditNinjaScreen: {
    closeIconColor: "#252525",
    closeIconHoverColor: "#468ee3",
  },
  EditQuickQuizScreen: {
    closeIconColor: "#252525",
    closeIconHoverColor: "#468ee3",
  },
  InsightScreen: {
    headSectionColor: "#252525",
    createLinkColor: "#468ee3",
  },
  JournalistFilters: {
    filterColor: "#a0a0a0",
    filterBorderColor: "rgb(230, 230, 230)",
  },
  JournalistOptionsPopup: {
    borderColor: "#e6e6e6",
  },
  JournalistTable: {
    headerColor: "#a0a0a0",
    rowBorderColor: "#f4f4f4",
    rowColor: "#454545",
    rowHoverBackgroundColor: "#468ee3",
    rowHoverColor: "#fff",
    iconColor: "#d8d8d8",
  },
  KPIShopDataTableRow: {
    hoverBackgroundColor: "#f8fbfe",
    eventButtonColor: "#dbdbdb",
    eventButtonHoverColor: "#468ee3",
  },
  KPIShopClassTrainingTable: {
    containerColor: "#cbcbcb",
    rowColor: "#000000",
    noDataContentBackgroundColor: "#ffffff",
    noDataContentColor: "#a0a0a0",
  },
  KPIShopELearning: {
    containerColor: "#a0a0a0",
    accordionButtonColor: "#000000",
    accordionSeparatorColor: "#f4f4f4",
    accordionTriggerColor: "#979797",
    accordionTriggerHoverColor: "#e5e5e5",
    accordionBackgroundColor: "#fbfbfb",
    headerSeparatorColor: "#f4f4f4",
    progressColumnColor: "#cbcbcb",
    progressBarBackgroundColor: "#eaeaea",
    noContentColor: "#a0a0a0",
  },
  KPIShopHeader: {
    backgroundColor: "#fff",
    boxShadowColor: "rgba(0, 0, 0, 0.05)",
    nameColor: "#000",
    backIconColor: "#000000",
  },
  KPIShopStaffTable: {
    containerColor: "#a0a0a0",
    accordionButtonColor: "#000000",
    accordionSeparatorColor: "#f4f4f4",
    accordionTriggerColor: "#979797",
    accordionTriggerHoverColor: "#f8fbfe",
    accordionBackgroundColor: "#fbfbfb",
    headerSeparatorColor: "#f4f4f4",
    progressColumnColor: "#cbcbcb",
    progressBarBackgroundColor: "#eaeaea",
    noContentColor: "#a0a0a0",
    userNameColor: "#454545",
    userPositionColor: "#a0a0a0",
    viewProfileIconColor: "#dbdbdb",
    viewProfileIconHoverColor: "#468ee3",
  },
  KPIShopScreen: {
    backgroundColor: "#ffffff",
    color: "#000000",
    collapseSectionsIconColor: "#000000",
  },
  ChartSummary: {
    titleColor: "rgba(0,0,0,0.59)",
    valueColor: "rgba(0,0,0,0.9)",
    valueIncreasedColor: "rgb(77, 122, 20)",
    valueDecreasedColor: "rgb(219, 13, 31)",
  },
  AppAnalyticsFilters: {
    boxShadowColor: "rgba(0,0,0,0.05)",
    backgroundColor: "#ffffff",
    filterLabelColor: "#a3a3a3",
  },
  Users: {
    globalFilterBackground: "#ffffff",
    exportIconBorder: "#e6e6e6",
    exportIconBorderHover: "#468ee3",
    exportIconHoverColor: "#468ee3",
    rowHoverBackground: "#468ee3",
    rowHoverColor: "#fff",
    deleteButtonHoverColor: "#fff",
    infoIconColor: "#468ee3",
    userStatusesTooltipBackground: "#ffffff",
    userStatusesTooltipBorder: "#e6e6e6",
    userStatusesTooltipColor: "#000000",
    tableTextStyleColor: "#454545",
    searchInputCommonStyleBorderColor: "#e6e6e6",
  },
  UserProfileBadge: {
    labelColor: "#000000",
    dateColor: "rgba(60, 60, 67, 0.3)",
    checkmarkCircleFill: "#fff",
    checkmarkPathFill: "#70b734",
  },
  UserProfileBadgeSlide: {
    dotCommonBackground: "#a0a0a0",
    dotCommonBorderColor: "#a0a0a0",
    containerBorderBottomColor: "#f2f2f2",
    titleColor: "#000000",
    pointsColor: "#c4c4c6",
    badgeColor: "red",
    dotEmptyBackground: "transparent",
  },
  UserProfilePendingQuestions: {
    headingBackground: "#f8fbfe",
    headingColor: "#468ee3",
    rowBackground: "#fff",
    questionColor: "#a0a0a0",
    delayColor: "#468ee3",
    arrowColor: "#468ee3",
    noDataColor: "#a0a0a0",
  },
  UserProfilePrograms: {
    containerColor: "#a0a0a0",
    accordionColor: "#000000",
    accordionBorderBottom: "#f4f4f4",
    accordionAfterBorderBottom: "#979797",
    accordionAfterBorderRight: "#979797",
    accordionHoverColor: "#e5e5e5",
    accordionPanelBackground: "#fbfbfb",
    headerBorderBottom: "#f4f4f4",
    columnProgressColor: "#cbcbcb",
    columnUpdateColor: "#cbcbcb",
    columnPointsColor: "#cbcbcb",
    progressBarBackground: "#eaeaea",
    noContentColor: "#a0a0a0",
  },
  UserProfileDefault: {
    containerColor: "#000000",
    leftSideBackground: "#fff",
    quantityWidgetBackground: "#fff",
    learningBackground: "#fff",
  },
  UserProfileDataTable: {
    containerColor: "$cbcbcb",
    tbodyColor: "#000000",
    noDataContent: "#ffffff",
    loaderColor: "#454545",
  },
  UserProfileDataTableRow: {
    tableTextColor: "#454545",
    hoverBackground: "#f8fbfe",
    eventButtonColor: "#dbdbdb",
    eventButtonHoverColor: "#468ee3",
  },
  UserProfileTrainee: {
    containerColor: "#000000",
    leftSideBackground: "#fff",
    quantityWidgetBackground: "#fff",
    learningBackground: "#fff",
  },
  UserProfileTraineePrograms: {
    containerColor: "#a0a0a0",
    accordionButtonColor: "#000000",
    accrodionButtonBorderColor: "#f4f4f4",
    accordionButtonAfterColor: "#979797",
    accordionButtonHoverBackground: "#e5e5e5",
    accordionPanelBackground: "#fbfbfb",
    headerBorder: "#f4f4f4",
    progressColor: "#cbcbcb",
    progressBarBackground: "#eaeaea",
    noContentColor: "#a0a0a0",
  },
  UserProfileTrainer: {
    containerColor: "#000000",
    leftSideBackground: "#fff",
    quantityWidgetBackground: "#fff",
    learningBackground: "#fff",
  },
  UserProfileHeader: {
    headerbackground: "#fff",
    headerBoxShadow: "rgba(0, 0, 0, 0.05)",
    lastConnectionColor: "#a0a0a0",
    nameColor: "#000",
    backColor: "#000000",
    backHover: "#468ee3",
  },
  CreateTraining: {
    textInputBorderColor: "#e6e6e6",
    multiTextInputBorder: "#e6e6e6",
    closeIconhover: "#468ee3",
  },
  ListTrainingCard: {
    cardBackground: Colors.text.white,
    titleColor: Colors.text.title,
    cardDate: Colors.text.cardDate,
    sectionBackground: "#c1c1c1",
    sectionColor: Colors.text.white,
    topicColor: Colors.text.cardDate,
    settingsButtonBeforeColor: Colors.horizontalCard.settingsButtonColor,
    settingsButtonBeforeHoverColor: Colors.buttons.primary.background,
    statisticIconColor: "#000000",
    statisticIconBeforeColor: "#000000",
    settingsIconColor: "#929292",
    settingsIconHoverColor: "#468ee3",
  },
  ListTraining: {
    noConnexionColor: "#252525",
    createLinkColor: "#468ee3",
    createLinkSpanColor: "#468ee3",
  },
  ListTrainingRow: {
    tableTextStyleColor: "#454545",
    rowHoverBackground: "#f8fbfe",
    deleteButton: "#d8d8d8",
    deleteButtonHover: "#468ee3",
  },
  SessionHandler: {
    commonIconStylesColor: "#929292",
    commonIconStylesHoverColor: "#468ee3",
    textInputAddTitleBorder: "#464646",
    textInputAddTitleBackground: "#ffffff",
    textInputAddTitleColor: "#252525",
    textInputAddTitlePlaceholderColor: "#a0a0a0",
    zoneSelectorBorder: "#e6e6e6",
    positionDateBtnIconColor: "#468ee3",
    calendarBtnBorder: "#e6e6e6",
    sessionSettingsMenuIconColor: "#d8d8d8",
    sessionSettingsMenuIconHoverColor: "#468ee3",
  },
  ViewTrainingStyle: {
    qrCodeBoxCommonBackground: "#ffffff",
    qrCodeCustomBoxBackground: "#000000",
    qrCodeCustomBoxColor: "#ffffff",
    eventInfoBoxBackgroung: "#ffffff",
    sectionBoxBackground: "#c1c1c1",
    sectionBoxColor: "#ffffff",
    titleColor: "#252525",
    topicColor: "#c4c4c4",
    sectionLabelColor: "#000000",
    loaderColor: "#454545",
    filterActionButtonColor: "#a0a0a0",
    filterActionButtonHoverColor: "#468ee3",
    filterActionButtonHoverBeforeColor: "#468ee3",
    userTotalLabel: "#a0a0a0",
    totalNumber: "#468ee3",
    settingsIconColor: "#d8d8d8",
    settingsIconHoverColor: "#468ee3",
    headerBackground: "#fff",
    headerColor: "#454545",
    closeIconColor: "#252525",
    closeIconBeforeColor: "#252525",
    CloseIconHoverColor: "#468ee3",
  },
  ViewTrainingUserRow: {
    tableTextColor: "#454545",
    rowHoverBackground: "#f8fbfe",
  },
  ViewTrainingSession: {
    titleColor: "#000000",
    counterColor: "#a0a0a0",
    quantity: "#468ee3",
    noSessionColor: "#252525",
    sessionsTableBackground: "#ffffff",
    sessionsTableColor: "#cbcbcb",
    sessionsTableHoverColor: "#468ee3",
    sessionsTableHoverBeforeColor: "#468ee3",
    sessionsTdBorderTop: "#f4f4f4",
    sessionsTdColor: "#454545",
    sessionsTBodyHoverBackground: "#f8fbfe",
  },
  RegisterTraining: {
    rootContainerBackground: "#ffffff",
    rootContainerColor: "#000000",
    labelColor: "rgba(60, 60, 67, 0.6)",
    inputBackground: "#fdfdfd",
    inputBorder: "#f0f0f0",
    inputColor: "#000000",
    submitButtonContainerBackground: "#2a3298",
    submitButtonContainerHoverBackground: "#2a3298",
    titleOverlayBackgroundFirst: "rgba(0, 0, 0, 0)",
    titleOverlayBackgroundSecond: "rgba(0, 0, 0, 0.65)",
    titleOverlaySpanColor: "#ffffff",
    error: "#ed5553",
  },
  RegisterTrainingRegistrationPage: {
    rootContainerBackground: "#ffffff",
    rootContainerColor: "#000000",
    labelColor: "rgba(60, 60, 67, 0.6)",
    inputBackground: "#fdfdfd",
    inputBorder: "#f0f0f0",
    inputColor: "#000000",
    inputSectionBorder: "#f0f0f0",
    inputSectionBorderHover: "#f0f0f0",
    submitBtnContainerBackground: "#2a3298",
    submitBtnContainerHoverBackground: "#2a3298",
    dropdownBorder: "#f0f0f0",
    titleOverlayBackgroundFirst: "rgba(0, 0, 0, 0)",
    titleOverlayBackgroundSecond: "rgba(0, 0, 0, 0.65)",
    titleOverlaySpanColor: "#ffffff",
  },
  RegisterTrainingSelectSession: {
    rootContainerBackground: "#ffffff",
    rootContainerColor: "#000000",
    submitBtnContainerBackground: "#2a3298",
    submitBtnContainerHoverBackground: "#2a3298",
    titleOverlayBackgroundFirst: "rgba(0, 0, 0, 0)",
    titleOverlayBackgroundSecond: "rgba(0, 0, 0, 0.65)",
    titleOverlaySpanColor: "#ffffff",
    titleColor: "#000000",
  },
  RegisterTrainingSelectSessionSessionItem: {
    rootContainerBackground: "#fdfdfd",
    rootContainerBorder: "#f0f0f0",
    rootContainerColor: "#000000",
    rootContainerCheckCircleColor: "#eeeeee",
    rootContainerFullCircleColor: "#281d8f",
  },
  RegisterTrainingRegistrationSuccessfull: {
    rootContainerColor: "#000000",
    titleColor: "#000000",
    subTitleColor: "#000000",
  },
  ShopListPage: {
    headerBackground: "#ffffff",
    clearButtonColor: "#468ee3",
  },
  ShopEditRemovableIcon: {
    closeIconColor: "#000000",
    closeIconBackground: "#f0f0f0",
    areaSizeColor: "#000000",
  },
  ShopEditSearchUser: {
    optionsContainerborder: "#eeeeee",
    optionsContainerBackground: "#ffffff",
    optionsContainerHoverBackground: "#fafafa",
    nameColor: "#000000",
    titleColor: "#a0a0a0",
  },
  ShopEditSelectedUser: {
    containerBackgroundColor: "#ffffff",
    containerBackgroundColorReadOnly: "#c1c1c1",
    nameColor: "#000000",
    nameColorReadOnly: "#000000",
  },
  EditShop: {
    labelColor: "#000000",
    editButtonBackground: "rgba(69, 69, 69, 0.7)",
    editButtonBeforeColor: "#ffffff",
    editButtonColor: "#ffffff",
    addBtnBackground: "#468ee3",
    addBtnColor: "#ffffff",
    selectedProductTypeColor: "#000000",
    selectedAreaSizeColor: "#000000",
    lastSelectedProductTypeBorder: "#a0a0a0",
  },
  ShopTable: {
    loaderColor: "#454545",
  },
  ShopRow: {
    rowHoverBackground: "#f8fbfe",
    deleteButtonColor: "#d8d8d8",
    deleteButtonHoverColor: "#468ee3",
    rowColor: "#454545",
  },
  ShopVerticalCard: {
    rootContainerBackground: "#ffffff",
    rootContainerColor: "#fff",
    imageBackground: "rgba(0, 0, 0, 0.5)",
    detailsSpan1Color: "#a0a0a0",
    detailsSpan2Color: "#454545",
    settingButtonColor: "#b2b3b3",
    settingButtonHoverColor: "#468ee3",
  },
  PublishLesson: {
    textInputTitleBackground: "transparent",
    textInputTitleColor: "#252525",
    textInputSummaryBackground: "transparent",
    textInputSummaryColor: "#252525",
    firstTitleColor: "#252525",
    sectionTitleColor: "#252525",
    closeIconHoverColor: "#468ee3",
    publicLabelColor: "#252525",
    recapCheckboxContainerColor: "#373737",
    contentEditablePlaceholderColor: "#7c7c7c",
  },
  PublishInsightScreen: {
    inputPlaceholderColor: "#7c7c7c",
    inputColor: "#252525",
    closeIconHoverColor: "#468ee3",
  },
  PublishDegreeScreen: {
    inputPlaceholderColor: "#7c7c7c",
    inputColor: "#252525",
    closeIconHoverColor: "#468ee3",
  },
  PublishCardScreen: {
    inputPlaceholderColor: "#7c7c7c",
    inputColor: "#252525",
    closeIconHoverColor: "#468ee3",
  },
  PageNotFoundScreen: {
    titleColor: "#000000",
    descriptionColor: "#aeaeae",
    buttonTextColor: "#ffffff",
  },
  LessonPageEditor: {
    placeHolderBorderColor: "#468ee3",
    placeHolderBackgroundColor: "rgba(70, 142, 227, 0.1)",
  },
  EditMasterTemplateScreen: {
    textInputPlaceHolderColor: "#7c7c7c",
    closeIconHoverColor: "#468ee3",
    textInputColor: "#252525",
    firstTitleColor: "#252525",
    sectionTitleColor: "#252525",
    uploadBtn: {
      backgroundColor: "rgba(42, 42, 42, 0.59)",
      color: "#ffffff",
    },
  },
  EditMasterTemplateLessonScreen: {
    textInputPlaceHolderColor: "#7c7c7c",
    closeIconHoverColor: "#468ee3",
    textInputColor: "#252525",
    firstTitleColor: "#252525",
    sectionTitleColor: "#252525",
    publicLabelColor: "#252525",
    recapLabelColor: "#373737",
  },
  DegreeBadgeLessonScreen: {
    lessonStatusColor: "#a0a0a0",
    lessonTitleColor: "#252525",
    badgeContainerBackgroundColor: "#ffffff",
    badgeArrowIconColor: "#b2b3b3",
    badgeContentBorderTopColor: "#f4f4f4",
  },
  KpiInfoTooltipStyle: {
    infoIconColor: "#468ee3",
    detailsBackgroundColor: "#ffffff",
    detailsColor: "#a0a0a0",
  },
  ResultDetailsCard: {
    dateColor: "#c4c4c4",
    containerBackgroundColor: "#fff",
    documentNameColor: "#252525",
    documentStatusColor: "#468ee3",
  },
  PollResultsScreen: {
    numberOfParticipantLabelColor: "#a0a0a0",
    participantCounterColor: "#468ee3",
  },
  PollResultsTable: {
    backgroundColor: "#fff",
    accordionButtonColor: "#000000",
    accordionButtonHoverColor: "#fff",
    accordionButtonBorderBottomColor: "#f4f4f4",
    arrowColor: "#979797",
    contentBackgroundColor: "#fbfbfb",
    contentBorderBottomColor: "#fff",
    answerColor: "#a0a0a0",
    completedPercentageColor: "#a0a0a0",
  },
  BattleResultsScreen: {
    leaderBoardTitleColor: "#000000",
    seeMoreButtonColor: "#468ee3",
  },
  BattleLeaderBoardTable: {
    dataColor: "#000000",
    tableBackgroundColor: "#fff",
    detailsIconColor: "#979797",
    detailsIconHoverColor: "#468ee3",
  },
};

// creating aliases
defaultTheme.FontFamily = defaultTheme.Fonts.baseFontFamily;

export default defaultTheme;
