import { PAGE_TEMPLATES } from "configs/constants";
import uuidv4 from "uuid/dist/v4";

const madlibUUIDInjector = (data) => {
  const {
    data: { questions },
  } = data;

  questions.forEach((question) => {
    if (!question.key) {
      question.key = uuidv4();
    }
    const { answers } = question;

    answers.forEach((answer) => {
      if (!answer.key) {
        answer.key = uuidv4();
      }
    });
  });
  return JSON.stringify(data);
};

const injectors = {
  [PAGE_TEMPLATES.MADLIB_TEMPLATE]: madlibUUIDInjector,
};

/**
 * Add some keys to differentiate content and allow correct rendering
 * during editing
 */

export const injectUUID = (data) => {
  if (data.pages) {
    data.pages.forEach((page) => {
      if (!injectors[page.template]) return;
      page.data = injectors[page.template](JSON.parse(page.data));
    });
  }
  return data;
};
