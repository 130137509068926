import { CARD_TYPES } from "../../configs/constants";
import { formatDate } from "../../utils/dateUtils";
import { Instagram as InstagramLogo, NoImageAvailable } from "assets/icons";
import clientConfig from "../../configs/client";

export const getFormattedFilteredDocuments = (language, documents) => {
  return documents.reduce((docs, item) => {
    const currentVersion = item.versions.find((x) => x.lang === language);
    const availableLanguages = item.versions.map((x) => x.lang);
    if (currentVersion) {
      const pageType = currentVersion.pages.length
        ? currentVersion.pages[0].type
        : undefined;
      const tagIds = currentVersion.tags.map((tag) => parseInt(tag.tagId));
      const isMasterDoc = tagIds.includes(clientConfig.MASTER_DEGREE_TAG_ID);

      // if (isMasterDoc) {
      docs.push({
        id: item.docId,
        language: currentVersion.lang,
        title: currentVersion.title,
        updateDate: formatDate(currentVersion.updateDate, "MMMM dd, yyyy"),
        picThumb: currentVersion.picThumb
          ? currentVersion.picThumb
          : NoImageAvailable,
        picCover: currentVersion.picCover
          ? currentVersion.picCover
          : pageType === CARD_TYPES.INSTAGRAM
          ? InstagramLogo
          : NoImageAvailable,
        type: currentVersion.type,
        workflow: currentVersion.workflow,
        vote: currentVersion.all ? currentVersion.all.vote : 0,
        read: currentVersion.all ? currentVersion.all.read : 0,
        comment: currentVersion.all ? currentVersion.all.comment : 0,
        pageType: pageType,
        availableLanguages,
      });
    }
    // }
    return docs;
  }, []);
};
