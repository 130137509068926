export default (theme) => ({
  container: {
    display: "flex",
    height: 90,
    fontFamily: theme.FontFamily,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    flex: 1,
  },
  leftSide: {
    maxWidth: 120,
    minWidth: 100,
    fontSize: 35,
    color: theme.QuantityWidget.leftSideColor,
    fontWeight: "bold",
    float: "left",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f8fbfe",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  rightSide: {
    height: "100%",
    minWidth: 80,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
    flex: 1,
    borderRadius: 15,
  },
  title: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.QuantityWidget.titleColor,
  },
  percentData: {
    fontSize: 15,
    fontWeight: 600,
    color: theme.QuantityWidget.percentDataColor,
  },
  tooltip: {
    marginRight: 5,
    width: 20,
    height: 20,
    borderRadius: 15,
    border: `1px solid #2D8EFF`,
    color: "#2D8EFF",
    position: "relative",
    top: 65,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    cursor: "default",
    "&:hover div": {
      visibility: "visible",
      opacity: 1,
    },
    "& div": {
      visibility: "hidden",
      boxShadow: `0 0 10px 10px rgb(0, 0, 0, 0.05)`,
      color: "#b6b6b6",
      borderRadius: 6,
      padding: 5,
      position: "absolute",
      zIndex: 1,
      top: 29,
      right: -10,
      fontWeight: "normal",
      marginLeft: -150,
      opacity: 0,
      transition: "opacity 0.3s",
    },
  },
});
