/* global localStorage */
import React from "react";
import LoginStyle from "./Login.style";
import { createUseStyles } from "react-jss";
import { FormattedMessage } from "react-intl";
import { Button } from "genius-ui";
import LinkButton from "../../components/common/LinkButton";
import AuthFlowFormContainer from "../../components/AuthFlowFormContainer";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { renderInputField } from "../../store/reduxForms/renders";
import { SignIn } from "../../store/auth/actions";
import { translations } from "./Login.translations";
import useThemedStyle from "../../hooks/style/useThemedStyle";
import { jsonToString } from "../../utils/authentification";
import { FUSIONAUTH_CONFIG, HAS_FUSIONAUTH } from "../../configs/client";

const useStyle = createUseStyles(LoginStyle);

// Login handler
const handleLogin = () => {
  const loginParams = jsonToString({
    client_id: FUSIONAUTH_CONFIG.clientId,
    redirect_uri: FUSIONAUTH_CONFIG.redirectUrl,
    idp_hint: FUSIONAUTH_CONFIG.idp,
    response_type: "code",
    scope: encodeURIComponent(FUSIONAUTH_CONFIG.scope),
  });

  /** Redirect to fusionAuth login **/
  window.location.href = `${FUSIONAUTH_CONFIG.issuer}${FUSIONAUTH_CONFIG.routes.authorize}?${loginParams}`;
};

const LoginScreen = (props) => {
  HAS_FUSIONAUTH && localStorage.clear();
  const classes = useThemedStyle(useStyle, props);

  return (
    <AuthFlowFormContainer
      actionSubmit={props.signInUser}
      title={<FormattedMessage {...translations.Title} />}
      titlePart2={<FormattedMessage {...translations.TitlePart2} />}
      titlePart3={<FormattedMessage {...translations.TitlePart3} />}
      subTitle={<FormattedMessage {...translations.SubTitle} />}
    >
      {!HAS_FUSIONAUTH && (
        <div className={classes.fullWidth}>
          <FormattedMessage {...translations.EmailInputLabel}>
            {(message) => (
              <Field
                name="email"
                type="email"
                containerClassName={classes.inputContainer}
                label={message[0]}
                required
                component={renderInputField}
              />
            )}
          </FormattedMessage>
          <FormattedMessage {...translations.PasswordInputLabel}>
            {(message) => (
              <Field
                name="password"
                type="password"
                containerClassName={classes.inputContainer}
                label={message[0]}
                required
                component={renderInputField}
              />
            )}
          </FormattedMessage>
          <LinkButton
            className={classes.forgotPasswordButton}
            handleClick={props.redirectToForgotPassword}
          >
            <FormattedMessage {...translations.ForgotPasswordText} />
          </LinkButton>
          {props.error !== "" ? (
            <span className={classes.errorMessage}>{props.error}</span>
          ) : (
            ""
          )}
        </div>
      )}
      <Button
        variation="primary"
        size="big"
        type="submit"
        className={classes.loginButton}
        handleClick={HAS_FUSIONAUTH ? handleLogin : () => {}}
        autoPreventDefault={false}
      >
        <FormattedMessage {...translations.LoginButtonText} />
      </Button>
    </AuthFlowFormContainer>
  );
};

const mapStateToProps = (state) => ({
  error: state.auth.error,
  timestamp: state.auth.timestamp,
  forgotMsg: state.auth.forgotMsg,
  loading: state.auth.loading,
  initialValues: { email: "", password: "" },
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  signInUser: (values) => {
    const { email, password } = values;
    dispatch(
      SignIn({ email, password }, () => {
        return ownProps.history.push("/");
      }),
    );
  },
  redirectToForgotPassword: () => {
    ownProps.history.push("/forgot-password");
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
