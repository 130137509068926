import { defineMessages } from "react-intl";

export const translations = defineMessages({
  TitlePlaceholderText: {
    id: "PageEditor.TitlePlaceholderText",
    defaultMessage: "Enter new title",
  },
  SubTitlePlaceholderText: {
    id: "PageEditor.SubTitlePlaceholderText",
    defaultMessage: "Enter new subtitle",
  },
  RegularPlaceholderText: {
    id: "PageEditor.RegularPlaceholderText",
    defaultMessage: "Enter new paragraph",
  },
  ImageSelectorButtonLabel: {
    id: "PageEditor.ImageSelectorButtonLabel",
    defaultMessage: "Change image",
  },
  LinkTitlePlaceholder: {
    id: "PageEditor.LinkTitlePlaceholder",
    defaultMessage: "Link Title",
  },
  LinkPlaceholder: {
    id: "PageEditor.LinkPlaceholder",
    defaultMessage: "Paste or type a link",
  },
});
