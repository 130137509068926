import React from "react";
import SliderStyle from "./VerticalSlider.style";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Icons, Icon } from "genius-ui";
import {
  DefaultVSlider1,
  AddSlide,
  DefaultVideo,
  DefaultVideoCover,
} from "assets/icons";
import Mask from "../../../common/Mask";
import ContentEditable from "react-contenteditable";
import ChangeTemplate from "../../ChangeTemplate";
import CropImage from "../../../common/CropImage";
import {
  CROP_VARIATION,
  FILE_TYPES,
  VIDEO_FORMAT,
} from "../../../../configs/constants";
import SliderVideoPlayer from "../../SliderVideoPlayer";
import UploadMedia from "../../UploadMedia";
import { translations } from "./Vertical.translations";
import withThemedStyle from "../../../hoc/withThemedStyle";

// half of fixed height
const BUTTONSWITCH_BREAKPOINT = 368;

class VerticalSlider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      addPosition: "top",
      title: "",
      description: "",
      image: "",
      video: {
        videoSource: DefaultVideo,
        posterCover: DefaultVideoCover,
        formatType: VIDEO_FORMAT.PORTRAIT,
      },
      fileType: FILE_TYPES.IMAGE,
      currentUploadProgress: 0,
      currentConversionProgress: 0,
      isUploading: false,
      isConverting: false,
      isCropVisible: false,
    };

    this.handleCoverUploadCompleted =
      this.handleCoverUploadCompleted.bind(this);
    this.handleVideoFormatChanged = this.handleVideoFormatChanged.bind(this);
  }

  checkToChangeAddPosition = (position) => {
    if (position < BUTTONSWITCH_BREAKPOINT && position > 1) {
      this.setState({ addPosition: "top" });
    } else {
      this.setState({ addPosition: "bottom" });
    }
  };

  changeTemplate = (selectedTemplateId) => {
    this.setState((state, { availableTemplates, onChange }) => {
      const selectedTemplate = availableTemplates.find(
        ({ id }) => id === selectedTemplateId,
      );
      const changedState = {
        ...selectedTemplate.defaultProps,
        // we're changing the template so we should reset the title and description
        title: "",
        description: "",
      };

      onChange({
        ...changedState,
        image: state.image, // keeping the old image
      });

      return changedState;
    });
  };

  changeTitleValue = (evt) => {
    this.setState({ title: evt.target.value });
  };

  changeDescriptionValue = (evt) => {
    this.setState({ description: evt.target.value });
  };

  handleUploadSuccess = (url, fileType = FILE_TYPES.IMAGE) => {
    let changedValue = {
      title: this.state.title,
      description: this.state.description,
      image: fileType === FILE_TYPES.IMAGE ? url : this.state.image,
      fileType: this.state.fileType,
      video: fileType === FILE_TYPES.VIDEO ? url : this.state.video,
    };

    const changedStateAttribute =
      fileType === FILE_TYPES.IMAGE ? "image" : "video";

    this.setState({ [changedStateAttribute]: url });
    this.props.onChange(changedValue);
  };

  handleValuesChanged = () => {
    let changedValue = {
      title: this.state.title,
      description: this.state.description,
      image: this.state.image,
      fileType: this.state.fileType,
      video: this.state.video,
    };
    this.props.onChange(changedValue);
  };

  handleCoverUploadCompleted = (imageFile) => {
    let newVideo = {
      ...this.state.video,
      posterCover: imageFile,
    };

    this.setState({
      image: imageFile,
      video: newVideo,
    });

    let changedValue = {
      title: this.state.title,
      description: this.state.description,
      image: imageFile,
      fileType: this.state.fileType,
      video: newVideo,
    };

    this.props.onChange(changedValue);
  };

  handleVideoFormatChanged = (videoFormat) => {
    let newVideo = {
      ...this.state.video,
      formatType: videoFormat,
    };

    this.setState({ video: newVideo });

    let changedValue = {
      title: this.state.title,
      description: this.state.description,
      image: this.state.image,
      fileType: this.state.fileType,
      video: newVideo,
    };

    this.props.onChange(changedValue);
  };

  changeCropVisibility = (state) => {
    this.setState({ isCropVisible: state });
  };

  componentDidMount() {
    this.setState({
      title: this.props.title,
      description: this.props.description,
      image: this.props.image,
      fileType: this.props.fileType,
      video:
        this.props.video !== null && this.props.video
          ? this.props.video
          : {
              videoSource: DefaultVideo,
              posterCover: DefaultVideoCover,
              formatType: VIDEO_FORMAT.PORTRAIT,
            },
    });
  }

  render() {
    let props = this.props;
    let { slideId, classes, type, isFirst, isSelected, availableTemplates } =
      props;

    return (
      <div
        className={classes.sliderContainer}
        onMouseMove={(e) =>
          props.isSelected
            ? this.checkToChangeAddPosition(e.nativeEvent.offsetY)
            : {}
        }
        onClick={props.onClick}
      >
        <div className={classes.backgroundSlider} />
        {isSelected && (
          <ChangeTemplate
            selectedTemplateId={type}
            templates={availableTemplates}
            onTemplateSelect={this.changeTemplate}
          />
        )}
        {!props.image && <Mask />}
        {this.state.addPosition === "top" && props.isSelected ? (
          <div
            className={classes.addBoxTop}
            onClick={props.onAddToTopHandle}
            onMouseMove={(event) => event.stopPropagation()}
          >
            <img src={AddSlide} alt="add slide before" />
          </div>
        ) : (
          ""
        )}
        {this.state.addPosition === "bottom" && props.isSelected ? (
          <div
            className={classes.addBoxBottom}
            onClick={props.onAddToBottomHandle}
            onMouseMove={(event) => event.stopPropagation()}
          >
            <img src={AddSlide} alt="add slide after" />
          </div>
        ) : (
          ""
        )}
        <div className={classes.uploadControlsBox}>
          <div className={classes.leftButtonsBox}>
            <UploadMedia
              id={slideId}
              className={classes.uploadBtnWrapper}
              onUploadSuccess={this.handleUploadSuccess}
              onFileTypeDetected={(fileType) => this.setState({ fileType })}
              onVideoUploading={(isUploading) => this.setState({ isUploading })}
              onVideoUploadProgress={(currentUploadProgress) =>
                this.setState({ currentUploadProgress })
              }
              onVideoConverting={(isConverting) =>
                this.setState({ isConverting })
              }
              onVideoConversionProgress={(currentConversionProgress) =>
                this.setState({ currentConversionProgress })
              }
              isVideoUploadEnabled
            >
              <button className={classes.btnUpload}>
                <Icon iconName={Icons.upload} style={classes.iconAction} />
                <FormattedMessage {...translations.UploadImageVideoText} />
              </button>
            </UploadMedia>
            {this.state.fileType === FILE_TYPES.VIDEO ? (
              <UploadMedia
                id={`thumbnail-${slideId}`}
                className={classes.uploadBtnWrapper}
                onUploadSuccess={this.handleCoverUploadCompleted}
              >
                <button className={classes.btnUpload}>
                  <Icon iconName={Icons.thumbnail} style={classes.iconAction} />
                  <span>Add thumbnail</span>
                </button>
              </UploadMedia>
            ) : (
              <CropImage
                {...props}
                sourceImage={this.state.image}
                isCropVisible={this.state.isCropVisible}
                cropSize={CROP_VARIATION.BACKGROUND}
                onCropClick={() => this.changeCropVisibility(true)}
                onCancel={() => this.changeCropVisibility(false)}
                onCropCompleted={(url, id) => this.handleUploadSuccess(url)}
              />
            )}
          </div>
          <button className={classes.btnUpload} onClick={props.onRemoveClick}>
            <span>Delete</span>
            <Icon iconName={Icons.delete} style={classes.iconAction} />
          </button>
        </div>
        {this.state.fileType === FILE_TYPES.VIDEO ? (
          <SliderVideoPlayer
            source={this.state.video.videoSource}
            poster={
              this.state.video.posterCover
                ? this.state.video.posterCover
                : DefaultVideoCover
            }
            onClick={this.props.onClick}
            onAspectRatioChanged={this.handleVideoFormatChanged}
            formatType={
              this.state.video.formatType
                ? this.state.video.formatType
                : VIDEO_FORMAT.PORTRAIT
            }
            isConverting={this.state.isConverting}
            isUploading={this.state.isUploading}
            progress={
              this.state.isConverting
                ? this.state.currentConversionProgress
                : this.state.currentUploadProgress
            }
            isActive={props.isSelected}
          />
        ) : (
          ""
        )}
        <div
          className={classes.textEditing}
          onMouseMove={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.checkToChangeAddPosition(500);
          }}
        >
          <ContentEditable
            key="title"
            html={this.state.title ? this.state.title : ""}
            disabled={!props.isSelected}
            onChange={this.changeTitleValue}
            onBlur={this.handleValuesChanged}
            tagName="article"
            className={props.classes.titleInput}
          />
          <br />
          <ContentEditable
            key="description"
            html={this.state.description ? this.state.description : ""}
            disabled={!props.isSelected}
            onChange={this.changeDescriptionValue}
            onBlur={this.handleValuesChanged}
            tagName="article"
            className={props.classes.descriptionInput}
          />
        </div>
        {isFirst && (
          <div className={classes.scrollDownIndicator}>
            <FormattedMessage {...translations.ScrollDown} />
            <span className={classes.scrollDownIcon}>
              <Icon iconName={Icons.dropdownIndicator} />
            </span>
          </div>
        )}
      </div>
    );
  }
}

VerticalSlider.propTypes = {
  /** unique id of the slide **/
  slideId: PropTypes.string.isRequired,
  /** The current selected image */
  image: PropTypes.string,
  /** Slide type **/
  type: PropTypes.string,
  /** title value */
  title: PropTypes.string,
  /** description */
  description: PropTypes.string,
  /** Label for the chnage image selection button */
  buttonLabel: PropTypes.string,
  /** On upload completed image change */
  onAddToBottomHandle: PropTypes.func,
  /** On handle addLeft event */
  onAddToTopHandle: PropTypes.func,
  /** on remove click event */
  onRemoveClick: PropTypes.func,
  /** onValueChange */
  onChange: PropTypes.func,
  /** is selected */
  isSelected: PropTypes.bool,
  /** on click */
  onClick: PropTypes.func,
  /** default sliderImage */
  defaultSliderImage: PropTypes.string,
  /** if it is the first slide, we display the scroll down indicator **/
  isFirst: PropTypes.bool,
  /** templates available to change the current slide with **/
  availableTemplates: PropTypes.array,
};

VerticalSlider.defaultProps = {
  defaultSliderImage: DefaultVSlider1,
  isFirst: false,
};

export default withThemedStyle(SliderStyle)(VerticalSlider);
