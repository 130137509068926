import * as constants from "../../constants/store/auth";
import AuthService from "../../core/authService";
import client from "../../graphql/client";
import { GET_MY_PROFILE_INFO } from "../../graphql/remote/profile/queries";
import { SetErrorMessage } from "../notification/actions";
import { SET_CURRENT_DOCUMENT_LANGUAGE } from "../../constants/store/documents";
import { UPDATE_DEGREE_FILTERS } from "../../constants/store/degrees";
import { LANGUAGES_BY_MARKET } from "../../configs/referential";

const auth = new AuthService();
const DEFAULT_LANGUAGE = "en";

export const SignIn =
  ({ email, password }, callback) =>
  (dispatch) => {
    dispatch({ type: constants.AUTH_IN_PROGRESS });
    auth
      .signIn(email, password, callback)
      .then(() => {
        dispatch({ type: constants.RESET_STORE });
        dispatch({ type: constants.AUTH_USER });
        return callback();
      })
      .catch((error) => {
        const errorMsg =
          error.description || error.message || "Unspecified error";
        return dispatch(AuthError(errorMsg));
      });
  };

export const ForgotPassword = (email) => (dispatch) => {
  dispatch({ type: constants.FORGOT_PASSWORD_IN_PROGRESS });
  auth.forgotPassword(
    email,
    () => {
      dispatch({
        type: constants.FORGOT_SUCCESS,
        data: "Password reset mail sent. Please check your inbox",
      });
    },
    (error) => {
      dispatch(ForgotError(error.message || "Error occurred"));
    },
  );
};

export const AuthError = (error) => {
  const timestamp = Date.now();
  return {
    type: constants.AUTH_ERROR,
    data: { error, timestamp },
  };
};

export const ForgotError = (error) => {
  const timestamp = Date.now();
  return {
    type: constants.FORGOT_PASSWORD_ERROR,
    data: { error, timestamp },
  };
};

export const SignoutUser = () => {
  auth.signOut();
  return { type: constants.UNAUTH_USER };
};

export const Cleardown = () => {
  return { type: constants.CLEARDOWN };
};

export const HandleAuthentication = () => (dispatch) => {
  auth
    .handleAuthentication()
    .then(() => {
      dispatch({ type: constants.AUTH_USER });
    })
    .catch(() => {
      dispatch({ type: constants.UNAUTH_USER });
    });
};

const GetTrainerLanguage = (tokenInfo, me) => {
  if (!tokenInfo["https://roles"].includes("trainer")) return me.profile.lang;
  const marketTagId = me.tags.find(({ clusterId }) => clusterId === "2")?.tagId;

  return LANGUAGES_BY_MARKET[marketTagId].includes(me.profile.lang)
    ? me.profile.lang
    : LANGUAGES_BY_MARKET[marketTagId][0];
};

export const GetProfileInfo = () => async (dispatch) => {
  try {
    const profileInfo = await client.query({
      fetchPolicy: "network-only",
      query: GET_MY_PROFILE_INFO,
    });
    const tokenInfo = await new AuthService().getAuthTokenEncodedData();
    const filterLanguage =
      GetTrainerLanguage(tokenInfo, profileInfo.data.me) || DEFAULT_LANGUAGE;

    dispatch({
      type: SET_CURRENT_DOCUMENT_LANGUAGE,
      data: filterLanguage,
    });
    dispatch({
      type: UPDATE_DEGREE_FILTERS,
      data: {
        type: "language",
        value: filterLanguage,
      },
    });
    dispatch({
      type: constants.SET_PROFILE_INFO,
      data: {
        ...profileInfo.data.me.profile,
        userId: profileInfo.data.me.userId,
        tags: profileInfo.data.me.tags,
        roles: tokenInfo["https://roles"],
        ready: true,
      },
    });
  } catch (e) {
    dispatch(
      SetErrorMessage("An error occurred while fetching the profile info", e),
    );
  }
};
