import gql from "graphql-tag";

export const GET_PUBLIC_TAGS = gql`
  query getCluster($clusterId: ID) {
    tags(clusterId: $clusterId) {
      tagId
      title
      clusterId
    }
  }
`;
