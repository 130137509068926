const navigationBarBorderWidth = 1;
const barWidth = 60;

export const navigationBarWidth = barWidth + navigationBarBorderWidth;

export const NavigationStyle = ({ Navigation, Colors }) => ({
  navigation: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: `${Colors.white}`,
    boxShadow: `-3px 0 24px 0 ${Navigation.boxShadowColor}`,
    border: `solid ${navigationBarBorderWidth}px ${Navigation.separatorColor}`,
    position: "fixed",
    width: barWidth,
    height: "100vh",
    top: 0,
    left: 0,
    zIndex: 998,
    textAlign: "center",
  },
  ul: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    left: 0,
    margin: 0,
    padding: 0,
    listStyleType: "none",
    width: "100%",
  },
  li: {
    padding: 0,
    left: 0,
    transition: "background 400ms",
    height: "60px",
    width: "60px",
    margin: 0,
    "&:hover": {
      "&:before": {
        color: `${Colors.lightBlue}`,
      },
      svg: {
        color: `${Colors.lightBlue}`,
      },
    },
    "&  a": {
      textDecoration: "none",
    },
  },
  iconMenu: {
    color: `${Colors.lightBlue}`,
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 55,
    },
    "&::before": {
      color: `${Colors.navigationMenu.inactiveItem}`,
      fontSize: 55,
    },
  },
  iconMenuSelected: {
    color: `${Colors.lightBlue}`,
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 55,
    },
    "&::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 55,
    },
  },
  profile: {
    paddingTop: 15,
    height: 60,
    width: "100%",
    "&::after": {
      content: '""',
      display: "block",
      borderBottom: `1px solid ${Navigation.separatorColor}`,
      width: 40,
      margin: "0 auto",
      paddingTop: 15,
    },
  },
  profileAvatar: {
    margin: "0 auto",
  },
  logout: {
    marginTop: "auto",
    padding: 15,
    height: 60,
    width: "100%",
    "&::before": {
      content: '""',
      display: "block",
      borderTop: `1px solid ${Navigation.separatorColor}`,
      width: 40,
      margin: "0 auto",
      paddingTop: 15,
    },
  },
  logoutLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    color: Colors.navigationMenu.inactiveItem,
    fontSize: 22,
    "&:hover": {
      color: Colors.lightBlue,
    },
  },
  subMenu: {
    width: 172,
    left: 60,
    position: "relative",
    top: -56,
  },
  iconQrCode: {
    padding: 15,
    color: `${Colors.navigationMenu.inactiveItem}`,
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 30,
    },
    "&::before": {
      color: `${Colors.navigationMenu.inactiveItem}`,
      fontSize: 30,
    },
    position: "absolute",
  },
  iconQrCodeSelected: {
    padding: 15,
    color: `${Colors.lightBlue}`,
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 30,
    },
    "&::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 30,
    },
    position: "absolute",
  },
  iconMediaMenu: {
    padding: "15px 20px",
    "&:before, &:hover:before": {
      fontSize: 26,
    },
    position: "absolute",
  },
  iconEventsMenu: {
    "&:before, &:hover:before": {
      marginLeft: -2.5,
      fontSize: 63,
    },
    position: "absolute",
  },
  iconShopMenu: {
    "&:before, &:hover:before": {
      marginLeft: 7.5,
      fontSize: 62,
    },
  },
  iconJournalistListMenu: {
    padding: "15px 16px",
    "&:before, &:hover:before": {
      fontSize: 32,
    },
    position: "absolute",
  },
  iconReward: {
    padding: "15px 16px",
    "&:before, &:hover:before": {
      fontSize: 32,
    },
  },
  iconLibrary: {
    padding: "15px 10px",
    "&:before, &:hover:before": {
      fontSize: 55,
    },
  },
});
