import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { CachePersistor } from "apollo-cache-persist";
import { withClientState } from "apollo-link-state";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { ENV } from "../configs/constants";
import errorLink from "./links/errorLink";
import { getAccessTokenV2 } from "../utils/authUtils";

/**
 * CONFIGS APOLLO STATE, CLIENT, CACHE & PERSISTENCE
 */

const ENV_API_URL =
  window.env && window.env.REACT_APP_API_URL
    ? window.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL;

// Set Apollo Cache Provider
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [], // no types provided
    },
  },
});
const cache = new InMemoryCache({ fragmentMatcher });
// Persist
export const persistor = new CachePersistor({
  cache,
  storage: window.localStorage,
  debug: ENV.IS_DEV,
});

// Set Apollo API Link
const stateLink = withClientState({
  cache,
});

const authLink = setContext(async (_, { headers }) => {
  //   // get the environments token from local storage if it exists
  const token = await getAccessTokenV2();

  //   // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const httpLink = createHttpLink({ uri: ENV_API_URL });

const link = ApolloLink.from([
  // Error Handling
  errorLink,
  // Auth Link
  authLink,
  // Local State Link
  stateLink,
  // Terminating link
  httpLink,
]);

// Setup Apollo Client
const client = new ApolloClient({
  link,
  cache,
  connectToDevTools: ENV.IS_DEV,
  fetchOptions: {
    mode: "no-cors",
  },
});

client.onResetStore(stateLink.writeDefaults);

export default client;
