import PropTypes from "prop-types";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { ExportUsers as ExportUsersAction } from "../../store/user/actions";
import { columnLabels } from "./Users.messages";

const ExportUsers = ({ children, intl, ExportUsers }) => {
  const doExport = () => {
    const columnNames = Object.keys(columnLabels).reduce(
      (columnNames, columnName) => {
        columnNames[columnName] = intl.formatMessage(columnLabels[columnName]);
        return columnNames;
      },
      {},
    );
    ExportUsers(columnNames);
  };

  return children ? children(doExport) : null;
};

ExportUsers.propTypes = {
  children: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  ExportUsers: (columnNames) => {
    dispatch(ExportUsersAction(columnNames));
  },
});

export default compose(
  connect(undefined, mapDispatchToProps),
  injectIntl,
)(ExportUsers);
