import React, { useCallback } from "react";
import { Checkbox } from "genius-ui";

const Market = ({ title, tagId, linked, onSelectMarket }) => {
  const handleChange = useCallback(() => {
    onSelectMarket(tagId);
  }, [onSelectMarket, tagId]);

  return (
    <div>
      <Checkbox
        label={title}
        handleChange={handleChange}
        checked={linked}
        disabled={linked}
      />
    </div>
  );
};

export default Market;
