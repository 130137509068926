import gql from "graphql-tag";

export const LESSON_COPY_FOR_LANGUAGE = gql`
  mutation lessonCopy(
    $lessonId: ID
    $sourceLang: LangEnum
    $destLang: LangEnum!
  ) {
    admin {
      lesson(lessonId: $lessonId, lang: $sourceLang) {
        copy(lang: $destLang) {
          lessonId
          versions {
            title
            lang
          }
        }
      }
    }
  }
`;
