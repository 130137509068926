export const SideBarNavigationStyle = ({ SideComponentBar, Colors }) => ({
  navigation: {
    backgroundColor: `${Colors.white}`,
    boxShadow: `-3px 0 24px 0 ${SideComponentBar.navigation.boxShadowColor}`,
    border: `solid 1px ${SideComponentBar.navigation.borderColor}`,
    position: "fixed",
    height: "100vh",
    top: 0,
    left: 0,
    zIndex: 99999,
  },
  mainContainer: {
    display: "flex",
    height: "100%",
    "& > .NavMainContent": {
      width: 60,
    },
  },
  back: {
    display: "flex",
    flexDirection: "column",
    height: 80,
    width: 60,
    alignItems: "center",
    justifyContent: "center",
    "&::after": {
      content: '""',
      display: "block",
      borderBottom: `1px solid ${SideComponentBar.navigation.borderColor}`,
      width: 40,
      margin: "0 auto",
      paddingTop: 25,
    },
    "&:hover": {
      color: Colors.lightBlue,
      cursor: "pointer",
    },
    "& > span": {
      "&::before": {
        color: SideComponentBar.navigation.backButtonColor,
      },
      "&:hover::before": {
        color: Colors.lightBlue,
        cursor: "pointer",
      },
    },
    "&:hover *": {
      color: Colors.lightBlue,
      cursor: "pointer",
      "&::before": {
        color: Colors.lightBlue,
        cursor: "pointer",
      },
    },
  },
});
