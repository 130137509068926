import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { createUseStyles } from "react-jss";
import { FormattedMessage } from "react-intl";
import { Icons, Icon } from "genius-ui";
import ContentEditable from "react-contenteditable";
import Style, { markerSize, dragAreaSize } from "./SlideMarker.style";
import UploadMedia from "../UploadMedia";
import { translations } from "./SildeMarker.translations";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import CropImage from "../../common/CropImage";
import { transformString, getTextInfos } from "./utils";

const useStyle = createUseStyles(Style);

const SlideMarker = (props) => {
  const {
    uniqueId,
    id,
    isOpened,
    onOpenedStateToggled,
    onDelete,
    onImageUploadSuccess,
    description,
    onDescriptionChanged,
    onDescriptionEdited,
    isEditable,
    isCropVisible,
    onCropClick,
    onCancel,
    cropSize,
    image,
  } = props;

  // important
  const [isError, setIsError] = useState(0);

  const iconName = !isOpened ? Icons.plus : Icons.mediumCloseIcon;
  const classes = useThemedStyle(useStyle, props);

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value?.replace(
        /(?<!<br>|<div><br><\/div>)<div><br><\/div>$/,
        "<br><div><br></div>",
      );
      const parsedValue = transformString(value);
      const parsedDescription = transformString(description);

      const isCopyPaste = parsedValue.length - parsedDescription.length > 1;
      const { croppedString, isOverflowing } = getTextInfos(
        parsedValue,
        "bold 14px Roboto",
        125.703,
        10,
      );

      if (isOverflowing && !isCopyPaste) {
        setIsError((prev) => prev + 1);
      } else {
        setIsError(0);
        onDescriptionChanged &&
          onDescriptionChanged(id, croppedString.replaceAll("\n", "<br>"));
      }
    },
    [isError, setIsError, description],
  );

  const handleBlur = useCallback((e) => {
    onDescriptionEdited(e);
    setIsError(false);
  }, []);

  return (
    <div className={classes.container}>
      <span className={classes.dragArea} />
      <span
        className={classes.markerCircle}
        onClick={() => onOpenedStateToggled && onOpenedStateToggled(id)}
      >
        <Icon iconName={iconName} style={classes.markerIcon} />
      </span>
      <div
        className={classNames([
          classes.popUp,
          {
            [classes.popUpVisible]: isOpened,
          },
        ])}
      >
        {isEditable && (
          <span className={classes.deleteButton} onClick={() => onDelete(id)}>
            <Icon iconName={Icons.delete} />
          </span>
        )}
        <div className={classes.imageContainer}>
          {isEditable && (
            <FormattedMessage {...translations.UploadButtonLabel}>
              {(message) => (
                <UploadMedia
                  id={`slideMarkerUploadMediaInput-${uniqueId}`}
                  onUploadSuccess={(...args) =>
                    onImageUploadSuccess(id, ...args)
                  }
                  className={classes.uploadButton}
                  title={message[0]}
                  isVideoUploadEnabled={false}
                >
                  <Icon iconName={Icons.upload} />
                </UploadMedia>
              )}
            </FormattedMessage>
          )}
          {isEditable && (
            <FormattedMessage {...translations.CropButtonLabel}>
              {(message) => (
                <CropImage
                  className={classes.cropButton}
                  sourceImage={image}
                  isCropVisible={isCropVisible}
                  cropSize={cropSize}
                  onCropClick={onCropClick}
                  onCancel={onCancel}
                  onCropCompleted={(...args) =>
                    onImageUploadSuccess(id, ...args)
                  }
                >
                  <Icon iconName={Icons.crop} />
                </CropImage>
              )}
            </FormattedMessage>
          )}
        </div>
        <div className={classes.descriptionInputContainer}>
          <FormattedMessage {...translations.DefaultMarkerDescription}>
            {(message) => (
              <ContentEditable
                key="description"
                id="contentEditable"
                html={description}
                disabled={!isEditable}
                onChange={handleChange}
                onBlur={handleBlur}
                tagName="div"
                placeholder={message[0]}
                className={classes.descriptionInput}
              />
            )}
          </FormattedMessage>
        </div>
      </div>
    </div>
  );
};

/** Fully controlled component: isOpened, image and description are set from the outside and can be controlled with their
 * callback functions
 * **/
SlideMarker.propTypes = {
  /** String that uniquely identifies marker on the same page and it's consistent across multiple page refreshes **/
  uniqueId: PropTypes.string.isRequired,
  /** Marker id **/
  id: PropTypes.any.isRequired,
  /** Whether the marker pop-up is opened or not **/
  isOpened: PropTypes.bool.isRequired,
  /** Function called when the opened state of the marker is changed **/
  onOpenedStateToggled: PropTypes.func,
  /** Image in pop-up **/
  image: PropTypes.string,
  /** Callback function of the image (called after image was successfully uploaded) **/
  onImageUploadSuccess: PropTypes.func,
  /** Description in pop-up **/
  description: PropTypes.string,
  /** Function called when the delete marker button is pressed **/
  onDelete: PropTypes.func,
  /** Callback function of the description **/
  onDescriptionChanged: PropTypes.func,
  /** Function fired when the description field looses focus **/
  onDescriptionEdited: PropTypes.func,
  /** Whether the marker is dragged or not **/
  isDragged: PropTypes.bool,
  /** Whether the area where the marker will be possibly dropped is safe or not **/
  isDropTargetSafe: PropTypes.bool,
  /** Whether the contents(image and description) of the marker are editable or not(default: false) **/
  isEditable: PropTypes.bool,
  /** Function called when the user clicks on the crop image button inside the pop-up **/
  onCrop: PropTypes.func.isRequired,
};

SlideMarker.defaultProps = {
  isDragged: false,
  isDropTargetSafe: true,
  isEditable: false,
};

export const SLIDE_MARKER_SIZE = {
  width: markerSize.width,
  height: markerSize.height,
};

export const SLIDE_MARKER_DRAG_AREA_SIZE = {
  width: dragAreaSize.width,
  height: dragAreaSize.height,
};

export default SlideMarker;
