import App from "../App";
import MainPage from "../pages/main";
import CreateInsight from "../pages/createInsight/index";
import PublishInsight from "../pages/publishInsight/index";
import Users from "../pages/users";
import Degrees from "../pages/degrees";
import PublishDegree from "../pages/publishDegree";
import DegreeBuilderOldVersion from "../pages/degreeBuilderBackup";
import LessonPageEditor from "../pages/lessonEditor";
import PublishLesson from "../pages/publishLesson";
import LessonBadges from "../pages/lessonBadges/LessonsBadges";
import LoginScreen from "../pages/login";
import ForgotPasswordScreen from "../pages/forgotPassword";
import ContentType from "../pages/contentType";
import { getPath } from "./paths";
import CreateCardPeanut from "../pages/createCard";
import ContentManager from "../pages/contentManager";
import PublishCard from "../pages/publishCard";
import Logout from "../pages/logout/Logout";
import KPIComponentsPage from "../pages/kpiComponentsPage";
import KPIShiseidoGeneralDetail from "../pages/KPI/Shiseido/generalDetail";
import KpiLearningShiseido from "../pages/kpiLearningShiseido";
import KpiApplicationShiseido from "../pages/kpiApplicationShiseido";
import KpiAskShiseido from "../pages/kpiAskShiseido";
import KpiFeedShiseido from "../pages/kpiFeedShiseido";
import CreateCardInstagram from "../pages/createCardInstagram";
import clientConfig, { REACT_APP_CLIENT_NAME } from "../configs/client";
import QuickQuiz from "../pages/editQuickQuiz";
import DeletedContent from "../pages/deletedContent";
import UserProfile from "../pages/userProfile";
import PageNotFound from "../pages/pageNotFound";
import EditBattle from "../pages/editBattle";
import PublishBattlePage from "../pages/publishBattle";
import AppAnalytics from "../pages/appAnalytics";
import EditPoll from "../pages/editPoll";
import EditNinja from "../pages/editNinja";
import PublishPoll from "../pages/publishPoll";
import MasterTemplates from "../pages/masterTemplates";
import EditMasterTemplateScreen from "../pages/editMasterTemplate";
import EditMasterTemplateLessonScreen from "../pages/editMasterTemplateLesson";
import AddDegreeToMarket from "../pages/masterTemplates/addToMarket";
import MasterDegreeBadges from "../pages/masterTemplates/selectBadgeLessons";
import MasterContentManager from "../pages/masterContentmanager";
import PublishMasterInsight from "../pages/publicationMasterDocuments/publishMasterInsight";
import PublishMasterBattlePage from "../pages/publicationMasterDocuments/publishMasterBattle";
import PublishMasterCard from "../pages/publicationMasterDocuments/publishMasterCard";
import PublishMasterPoll from "../pages/publicationMasterDocuments/publishMasterPoll";
import PollResults from "../pages/results/poll";
import BattleResults from "../pages/results/battle";
import KPIBattleHome from "../pages/kpiBattle";
import KPIBattleGlobal from "../pages/kpiBattle/kpiBattleGlobal";
import CreateCardTwitter from "../pages/createCardTwitter";
import LoginRedirection from "../pages/loginRedirection/LoginRedirection";
import Library from "../pages/library";
import CreateFile from "../pages/createFile";
import KpiNinja from "pages/kpiNinja";
import EditFile from "pages/editFile/EditFile";
import BadgesList from "pages/badges/BadgesList/BadgesList";
import EditMarketBadge from "pages/badges/EditMarketBadge/EditMarketBadge";
import MasterBadgesList from "pages/badges/MasterBadgesList/MasterBadgesList";
import CloneToMarket from "pages/badges/CloneToMarket/CloneToMarket";
import CreateMasterBadge from "pages/badges/CreateMasterBadge/CreateMasterBadge";
// NOT USED FOR THE MOMENT WE WILL SEE IF WE KEEP IT
// import DegreeBuilder from '../pages/degreeBuilder'

const KpiGeneralScreens = {
  SHISEIDO: KPIShiseidoGeneralDetail,
  "SHISEIDO-PREPROD": KPIShiseidoGeneralDetail,
  "SHISEIDO-PROD": KPIShiseidoGeneralDetail,
  PLURIS: KPIShiseidoGeneralDetail,
};

const KpiLearningScreens = {
  SHISEIDO: KpiLearningShiseido,
  "SHISEIDO-PREPROD": KpiLearningShiseido,
  "SHISEIDO-PROD": KpiLearningShiseido,
  PLURIS: KpiLearningShiseido,
};

const KpiApplicationScreens = {
  SHISEIDO: KpiApplicationShiseido,
  "SHISEIDO-PREPROD": KpiApplicationShiseido,
  "SHISEIDO-PROD": KpiApplicationShiseido,
  PLURIS: KpiApplicationShiseido,
};

const KpiFeedScreens = {
  SHISEIDO: KpiFeedShiseido,
  "SHISEIDO-PREPROD": KpiFeedShiseido,
  "SHISEIDO-PROD": KpiFeedShiseido,
  PLURIS: KpiFeedShiseido,
};

const KpiAskScreens = {
  SHISEIDO: KpiAskShiseido,
  "SHISEIDO-PREPROD": KpiAskShiseido,
  "SHISEIDO-PROD": KpiAskShiseido,
  PLURIS: KpiAskShiseido,
};

const DegreeBuilderScreens = {
  SHISEIDO: DegreeBuilderOldVersion,
  "SHISEIDO-PREPROD": DegreeBuilderOldVersion,
  "SHISEIDO-PROD": DegreeBuilderOldVersion,
  PLURIS: DegreeBuilderOldVersion,
};

const routes = {
  app: {
    path: getPath("root"),
    exact: false,
    isPrivate: false,
    component: App,
    routes: [
      {
        path: getPath("root"),
        exact: true,
        isPrivate: true,
        component: MainPage,
      },
      {
        path: getPath("deletedContent"),
        exact: true,
        isPrivate: true,
        component: DeletedContent,
      },
      {
        path: getPath("createInsight"),
        exact: true,
        isPrivate: true,
        component: CreateInsight,
      },
      {
        path: getPath("publishInsight"),
        exact: true,
        isPrivate: true,
        component: PublishInsight,
      },
      {
        path: getPath("pageNotFound"),
        exact: true,
        isPrivate: false,
        component: PageNotFound,
      },
      {
        path: getPath("users"),
        exact: true,
        isPrivate: true,
        component: Users,
      },
      {
        path: getPath("degreesHistoryParametered"),
        exact: true,
        isPrivate: true,
        component: Degrees,
      },
      {
        path: getPath("createDegree"),
        exact: true,
        isPrivate: true,
        component: PublishDegree,
      },
      {
        path: getPath("buildDegree"),
        exact: true,
        isPrivate: true,
        component: DegreeBuilderScreens[REACT_APP_CLIENT_NAME],
      },
      {
        path: getPath("lessons"),
        exact: true,
        isPrivate: true,
        component: LessonPageEditor,
      },
      {
        path: getPath("degreeBadges"),
        exact: true,
        isPrivate: true,
        component: LessonBadges,
      },
      {
        path: getPath("lessonBadges"),
        exact: true,
        isPrivate: true,
        component: LessonBadges,
      },
      {
        path: getPath("publishLesson"),
        exact: true,
        isPrivate: true,
        component: PublishLesson,
      },
      {
        path: getPath("login"),
        exact: true,
        isPrivate: false,
        component: LoginScreen,
      },
      {
        path: getPath("forgotPassword"),
        exact: true,
        isPrivate: false,
        component: ForgotPasswordScreen,
      },
      {
        path: getPath("contentType"),
        exact: true,
        isPrivate: false,
        component: ContentType,
      },
      {
        path: getPath("createCardPeanut"),
        exact: true,
        isPrivate: true,
        component: CreateCardPeanut,
      },
      {
        path: getPath("createCardInstagram"),
        exact: true,
        isPrivate: true,
        component: CreateCardInstagram,
      },
      {
        path: getPath("contentManager"),
        exact: true,
        isPrivate: true,
        component: ContentManager,
      },
      {
        path: getPath("cardPublish"),
        exact: true,
        isPrivate: true,
        component: PublishCard,
      },
      {
        path: getPath("quickQuizEdit"),
        exact: true,
        isPrivate: true,
        component: QuickQuiz,
      },
      {
        path: getPath("logout"),
        exact: false,
        isPrivate: false,
        component: Logout,
      },
      {
        path: getPath("kpiComponents"),
        exact: true,
        isPrivate: false,
        component: KPIComponentsPage,
      },
      {
        path: getPath("kpiGeneral"),
        exact: true,
        isPrivate: true,
        component: KpiGeneralScreens[REACT_APP_CLIENT_NAME],
      },
      {
        path: getPath("kpiLearning"),
        exact: true,
        isPrivate: true,
        component: KpiLearningScreens[REACT_APP_CLIENT_NAME],
      },
      {
        path: getPath("kpiApplication"),
        exact: true,
        isPrivate: true,
        component: KpiApplicationScreens[REACT_APP_CLIENT_NAME],
      },
      {
        path: getPath("kpiFeed"),
        exact: true,
        isPrivate: true,
        component: KpiFeedScreens[REACT_APP_CLIENT_NAME],
      },
      {
        path: getPath("kpiAsk"),
        exact: true,
        isPrivate: true,
        component: KpiAskScreens[REACT_APP_CLIENT_NAME],
      },
      {
        path: getPath("userProfile"),
        exact: true,
        isPrivate: true,
        component: UserProfile,
      },
      {
        path: getPath("publishBattle"),
        exact: true,
        isPrivate: true,
        component: PublishBattlePage,
      },
      {
        path: getPath("pollResults"),
        exact: true,
        isPrivate: true,
        component: PollResults,
      },
      {
        path: getPath("battleResults"),
        exact: true,
        isPrivate: true,
        component: BattleResults,
      },
      {
        path: getPath("kpiBattleHome"),
        exact: true,
        isPrivate: true,
        component: KPIBattleHome,
      },
      {
        path: getPath("kpiNinja"),
        exact: true,
        isPrivate: true,
        component: KpiNinja,
      },
      {
        path: getPath("kpiBattleGlobal"),
        exact: true,
        isPrivate: true,
        component: KPIBattleGlobal,
      },
      {
        path: getPath("createCardTwitter"),
        exact: true,
        isPrivate: true,
        component: CreateCardTwitter,
      },
      {
        path: getPath("redirection"),
        exact: true,
        isPrivate: false,
        component: LoginRedirection,
      },
      {
        path: getPath("library"),
        exact: true,
        isPrivate: true,
        component: Library,
      },
      {
        path: getPath("createFile"),
        exact: false,
        isPrivate: true,
        component: CreateFile,
      },
      {
        path: getPath("editFile"),
        exact: true,
        isPrivate: true,
        component: EditFile,
      },
      {
        path: getPath("badges"),
        exact: true,
        isPrivate: true,
        component: BadgesList,
      },
      {
        path: getPath("masterBadges"),
        exact: true,
        isPrivate: true,
        component: MasterBadgesList,
      },
      {
        path: getPath("cloneBadgeToMarket"),
        exact: true,
        isPrivate: true,
        component: CloneToMarket,
      },
      {
        path: getPath("createMasterBadge"),
        exact: true,
        isPrivate: true,
        component: CreateMasterBadge,
      },
      {
        path: getPath("editBadge"),
        exact: true,
        isPrivate: true,
        component: EditMarketBadge,
      },
    ],
  },
};

if (clientConfig.MODULES.IS_BATTLE_MANAGEMENT_AVAILABLE) {
  routes.app.routes.push({
    path: getPath("editBattle"),
    exact: true,
    isPrivate: true,
    component: EditBattle,
  });
}

if (clientConfig.MODULES.IS_POLL_MANAGEMENT_AVAILABLE) {
  routes.app.routes = routes.app.routes.concat([
    {
      path: getPath("editPoll"),
      exact: true,
      isPrivate: true,
      component: EditPoll,
    },
    {
      path: getPath("publishPoll"),
      exact: true,
      isPrivate: true,
      component: PublishPoll,
    },
  ]);
}

if (clientConfig.MODULES.IS_NINJA_MANAGEMENT_AVAILABLE) {
  routes.app.routes = routes.app.routes.concat([
    {
      path: getPath("editNinja"),
      exact: true,
      isPrivate: true,
      component: EditNinja,
    },
  ]);
}

if (clientConfig.MODULES.APP_ANALYTICS.AVAILABLE) {
  routes.app.routes.push({
    path: getPath("kpiMobileAnalytics"),
    exact: true,
    isPrivate: true,
    component: AppAnalytics,
  });
}

if (clientConfig.MODULES.MASTER_TEMPLATES.AVAILABLE) {
  routes.app.routes = routes.app.routes.concat([
    {
      path: getPath("masterTemplates"),
      exact: true,
      isPrivate: true,
      component: MasterTemplates,
    },
    {
      path: getPath("editMasterTemplate"),
      exact: true,
      isPrivate: true,
      component: EditMasterTemplateScreen,
    },
    {
      path: getPath("editMasterTemplateLesson"),
      exact: true,
      isPrivate: true,
      component: EditMasterTemplateLessonScreen,
    },
    {
      path: getPath("documentAddToMarket"),
      exact: true,
      isPrivate: true,
      component: AddDegreeToMarket,
    },
    {
      path: getPath("masterDegreeBadges"),
      exact: true,
      isPrivate: true,
      component: MasterDegreeBadges,
    },
    {
      path: getPath("masterContentManager"),
      exact: true,
      isPrivate: true,
      component: MasterContentManager,
    },
    {
      path: getPath("publishMasterInsight"),
      exact: true,
      isPrivate: true,
      component: PublishMasterInsight,
    },
    {
      path: getPath("publishMasterBattle"),
      exact: true,
      isPrivate: true,
      component: PublishMasterBattlePage,
    },
    {
      path: getPath("publishMasterCard"),
      exact: true,
      isPrivate: true,
      component: PublishMasterCard,
    },
    {
      path: getPath("publishMasterPoll"),
      exact: true,
      isPrivate: true,
      component: PublishMasterPoll,
    },
  ]);
}

export default routes;
