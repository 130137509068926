import { defineMessages } from "react-intl";

export const translations = defineMessages({
  AddBeforeAltMessage: {
    id: "AddNewContainer.AddBeforeAltMessage",
    defaultMessage: "Add before",
  },
  AddAfterAltMessage: {
    id: "AddNewContainer.AddAfterAltMessage",
    defaultMessage: "Add after",
  },
});
