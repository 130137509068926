import * as constants from "../../constants/store/notification";
import { RESET_STORE } from "../../constants/store/auth";

const initialState = {
  message: "",
  status: "success",
  isOpen: false,
  timeout: 5000,
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.SET_SUCCESS_MESSAGE:
      return {
        ...state,
        message: data,
        status: "success",
        isOpen: true,
        timeout: 5000,
      };
    case constants.SET_ERROR_MESSAGE:
      return {
        ...state,
        message: data,
        status: "error",
        isOpen: true,
        timeout: 5000,
      };
    case constants.SET_WARNING_MESSAGE:
      return {
        ...state,
        message: data,
        status: "warning",
        isOpen: true,
        timeout: 5000,
      };
    case constants.CLOSE_AND_RESET_NOTIFICATION:
      return {
        ...state,
        message: data,
        status: "",
        isOpen: false,
        timeout: 5000,
      };
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
