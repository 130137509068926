import { FontFamily } from "../../../constants";

const column1Width = "13%";
const column2Width = "12%";
const column3Width = "12%";
const column4Width = "27%";
const column5Width = "20%";
const column6Width = "10%";

export default (theme) => ({
  accordionContainer: {
    "& .accordion__heading": {
      display: "none",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "12px 20px",
    fontSize: 14,
    fontWeight: 500,
    color: theme.ProgramsWidgetShiseido.headerColor,
    "& > span": {
      paddingRight: 20,
    },
    "& > span:nth-of-type(1)": {
      width: column1Width,
    },
    "& > span:nth-of-type(2)": {
      width: column2Width,
    },
    "& > span:nth-of-type(3)": {
      width: column3Width,
    },
    "& > span:nth-of-type(4)": {
      textAlign: "center",
      width: column4Width,
    },
    "& > span:nth-of-type(5)": {
      textAlign: "center",
      width: column5Width,
    },
    "& > span:nth-of-type(6)": {
      textAlign: "center",
      width: column6Width,
    },
  },
  accordionContentContainer: {
    backgroundColor: theme.ProgramsWidgetShiseido.lessonBackgroundColor,
  },
  sortableColumn: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    "& > div:nth-of-type(2)": {
      marginLeft: 8,
      fontSize: 10,
    },
  },
});

export const LessonRowStyle = (theme) => ({
  container: {
    fontFamily: FontFamily,
    display: "flex",
    padding: "0 20px",
    alignItems: "center",
    color: theme.ProgramsWidgetShiseido.lessonDataColor,
    fontSize: 12,
    fontWeight: 500,
    minHeight: 45,
    backgroundColor: theme.ProgramsWidgetShiseido.lessonBackgroundColor,
    "& > span": {
      paddingRight: 20,
    },
    "& > span:nth-of-type(1)": {
      width: column1Width,
    },
    "& > span:nth-of-type(2)": {
      width: column2Width,
    },
    "& > span:nth-of-type(3)": {
      width: column3Width,
    },
    "& > span:nth-of-type(4)": {
      textAlign: "center",
      width: column4Width,
    },
    "& > span:nth-of-type(5)": {
      textAlign: "center",
      color: theme.ProgramsWidgetShiseido.lessonDataColorLight,
      width: column5Width,
      "& > span:nth-of-type(2)": {
        color: theme.ProgramsWidgetShiseido.lessonCompletionRateColor,
        marginLeft: 5,
      },
    },
    "& > span:nth-of-type(6)": {
      textAlign: "center",
      color: theme.ProgramsWidgetShiseido.lessonCompletionRateColor,
      width: column6Width,
    },
    "& > span:nth-of-type(7)": {
      padding: 0,
      cursor: "pointer",
    },
  },

  progressBar: {
    "& > div": {
      backgroundColor: theme.ProgramsWidgetShiseido.lessonCompletionRateColor,
    },
  },
  completionRateContainer: {
    "& > div:nth-of-type(1) > span:nth-of-type(1)": {
      color: theme.ProgramsWidgetShiseido.lessonCompletionRateColor,
    },
  },
});
