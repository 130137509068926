import { DefaultUserImage, PlaceholderImage } from "assets/icons";
import uuidV4 from "uuid/dist/v4";
import { formatDate } from "../../utils/dateUtils";
import clientConfig from "../../configs/client";

export const GetFormattedGlobalBattleData = (users) =>
  users.map((user) => ({
    key: user.user.userId,
    userName: `${user.user.profile.firstName} ${user.user.profile.lastName}`,
    userPhoto: user.user.profile.pic || DefaultUserImage,
    attempts: user.numberOfAttempts,
    totalScore: user.totalScore,
    highestScore: user.bestScore,
    averageScore: user.averageScore,
  }));

export const GetFormattedPublishedBattleData = (battles) =>
  battles
    .filter((battle) => !!battle.gameTemplate)
    .map((battle) => ({
      key: uuidV4(),
      id: battle.battleId,
      battleName: battle.gameTemplate ? battle.gameTemplate.title : "",
      numberOfParticipatedUsers: battle.gameTemplate
        ? battle.gameTemplate.participantsNumber
        : "",
      averageNumberOfPoints: battle.gameTemplate
        ? battle.gameTemplate.averageScore
        : "",
    }));

const filterTagsByClusterIds = (tags, clusterIds) =>
  tags
    .filter((tag) => clusterIds.includes(parseInt(tag.clusterId)))
    .map((tag) => ({
      key: tag.tagId,
      label: tag.title,
    }));

export const GetFormattedBattleInfo = ({ gameTemplate, versions }) => {
  return {
    photo: versions[0].picCover ? versions[0].picCover : PlaceholderImage,
    name: versions[0].title ? versions[0].title : "",
    status: versions[0].workflow,
    creationDate: formatDate(versions[0].updateDate, "dd MMM yyyy"),
    publicationDate: formatDate(versions[0].workflowScheduledIn, "dd MMM yyyy"),
    markets: filterTagsByClusterIds(
      versions[0].tags,
      clientConfig.MARKET_CLUSTER_IDS,
    ),
    brands: filterTagsByClusterIds(
      versions[0].tags,
      clientConfig.BRAND_TAGS_CLUSTER_IDS,
    ),
    participants: gameTemplate ? gameTemplate.participantsNumber : 0,
    participantsAverage: gameTemplate ? gameTemplate.averageScore : 0,
  };
};
