export const SearchButtonStyle = (theme) => ({
  searchButton: {
    marginRight: 20,
    border: `1px solid ${theme.EventFilter.inputBorderColor}`,
    borderRadius: 5,
    display: "flex",
    flexShrink: 0,
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 38,
    "& .icon-search": {
      color: theme.EventFilter.inputPlaceholderColor,
      fontSize: 20,
    },
    cursor: "pointer",
  },
});
