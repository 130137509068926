import { createXml } from "../excelFactory";
import { ninjaTranslations as translations } from "./Kpi.translations";
import client from "../../../graphql/client";
import { GET_NINJA_KPI } from "graphql/remote/ninja/queries/GetNinjaRanking.graphql";

export const exportSelectedNinja =
  (ninjaId, intl) => async (dispatch, getState) => {
    const { ninjas } = getState().ninja;

    const ninja = ninjas.find((ninja) => ninja.ninjaId === ninjaId);

    const date = new Date();
    const exportFileName = `kpi game-${
      ninja.title
    } | ${date.getFullYear()}-${date.getMonth()}-${date.getDate()}.xls`;

    let userData = [];
    let programTitle = "";

    try {
      const result = await client.query({
        fetchPolicy: "network-only",
        query: GET_NINJA_KPI,
        variables: {
          lessonId: ninjaId,
        },
      });
      userData = result.data.rankings?.map((ranking) => {
        const bestScoreDate = new Date(ranking.date);
        return [
          ranking.name,
          ranking.user.profile.mail,
          ranking.score,
          `${bestScoreDate.getDate()}/${bestScoreDate.getMonth()}/${bestScoreDate.getFullYear()}`,
        ];
      });
      programTitle = result?.data?.rankings?.[0]?.parent?.title;
    } catch (e) {
      console.warn(e);
    }

    const docData = [
      [intl.formatMessage(translations.NinjaGameName), ninja.title],
      [intl.formatMessage(translations.NinjaMarket), ninja.market],
      [intl.formatMessage(translations.NinjaBrand), ninja.brand],
      [intl.formatMessage(translations.NinjaProgramName), programTitle],
      [],
      [],
    ];

    const userHeader = [
      [
        intl.formatMessage(translations.NinjaUserName),
        intl.formatMessage(translations.NinjaUserMail),
        intl.formatMessage(translations.NinjaBestScore),
        intl.formatMessage(translations.NinjaDateBestScore),
      ],
    ];

    createXml(docData.concat(userHeader).concat(userData), exportFileName);
  };
