export default ({ SendNotificationModal, FontFamily }) => ({
  modalFrameStyle: {
    width: "98vw",
    height: "100vh",
    zIndex: "999!important",
    "& > div": {
      cursor: "default",
    },
  },
  modalContent: {
    padding: "15px 20px 20px 20px",
    zIndex: 999,
    width: 686,
    minHeight: 358,
    backgroundColor: SendNotificationModal.backgroundColor,
    borderRadius: 4,
    fontFamily: FontFamily,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 0.07,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: 22,
    fontWeight: "bold",
    color: SendNotificationModal.textColor,
    marginBottom: 12,
  },
  body: {
    display: "flex",
    flexDirection: "column",
  },
  description: {
    fontSize: 17,
    fontWeight: "300",
    letterSpacing: 0.06,
    color: SendNotificationModal.textColor,
    marginBottom: 40,
  },
  messageLabel: {
    fontSize: 16,
    fontWeight: "normal",
    letterSpacing: "normal",
    color: SendNotificationModal.textColor,
    marginBottom: 10,
  },
  notificationInput: {
    marginBottom: 20,
  },
  sendButton: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    "& > div": {
      minWidth: 80,
    },
  },
  close: {
    cursor: "pointer",
  },
  tags: {
    display: "block",
    marginBottom: 10,
    "& div": {
      float: "left",
    },
  },
  tagSelectContainer: {
    position: "relative",
    marginBottom: 20,
  },
});
