import { defineMessages } from "react-intl";

export const translations = defineMessages({
  LeaderboardTitle: {
    id: "KPIBattleScreen.LeaderboardTitle",
    defaultMessage: "Global leaderboard",
  },
  SeeMoreButtonLabel: {
    id: "KPIBattleScreen.SeeMoreButtonLabel",
    defaultMessage: "See more",
  },
  PublishedBattleTitle: {
    id: "KPIBattleScreen.PublishedBattleTitle",
    defaultMessage: "Battles published",
  },
  LoadMoreButton: {
    id: "KPIBattleScreen.LoadMoreButton",
    defaultMessage: "Load more",
  },
  InformUsers: {
    id: "KPIBattleScreen.InformUsers",
    defaultMessage:
      "Dates selected before September, 2019 will hold no data, and data before end of January 2020 includes only testing or pre-launch training. I also cannot select dates in the future.",
  },
});
