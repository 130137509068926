import gql from "graphql-tag";

export const DEGREES_GET_SHORT_DETAIL_LIST = gql`
  query degrees($tagIds: [ID!]) {
    admin {
      degrees(workflow: PUBLISHED, tagIds: $tagIds) {
        degreeId
        title
      }
    }
  }
`;
