import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import Style from "./UploadMedia.style";
import { ENV, FILE_TYPES, VIDEO_FORMAT } from "../../../configs/constants";
import MediaService from "../../../core/mediaService";
import { DefaultVideo, DefaultVideoCover } from "assets/icons";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import { SET_ERROR_MESSAGE } from "../../../constants/store/notification";
import { useDispatch } from "react-redux";
import { translations } from "./UploadMedia.translations";
import { FormattedMessage } from "react-intl";

const useStyle = createUseStyles(Style);

const mediaService = new MediaService();

const UploadMedia = (props) => {
  const {
    id,
    className,
    onUploadSuccess,
    children,
    isVideoUploadEnabled,
    onUploadMouseMove,
    onVideoConversionProgress,
    onVideoUploadProgress,
    onVideoUploading,
    onVideoConverting,
    onFileTypeDetected,
    enableMediaSelectionPopup,
    setLoading,
    ...restProps
  } = props;
  const [video, setVideo] = useState({
    videoSource: DefaultVideo,
    posterCover: DefaultVideoCover,
    formatType: VIDEO_FORMAT.PORTRAIT,
  });
  const dispatch = useDispatch();
  const classes = useThemedStyle(useStyle, props);
  const inputFile = useRef(null);

  /** function executed when the input is clicked/touched **/
  const handleFocus = (e) => {
    if (restProps.disabled) {
      // don't do anything when the input is disabled
      return;
    }

    // don't propagate further the click/mouse button push events
    return e.stopPropagation();
  };

  /** function executed periodically during the video conversion progress **/
  const updateConversionProgress = (videoUrl) => {
    mediaService.checkVideoStatus(
      videoUrl,
      (conversionStatus) => {
        const isConverting = !(
          conversionStatus.status === "ACTIVE" ||
          conversionStatus.status === "REJECT" ||
          conversionStatus.progress === 100
        );

        onVideoConversionProgress &&
          onVideoConversionProgress(conversionStatus.progress);
        onVideoConverting && onVideoConverting(isConverting);

        if (isConverting) {
          setTimeout(
            updateConversionProgress(videoUrl, handleVideoUploadCompleted),
            2000,
          );
        } else {
          setVideo({
            ...video,
            videoSource: conversionStatus.url,
          });
          onVideoConversionProgress &&
            onVideoConversionProgress(conversionStatus.progress);

          handleVideoUploadCompleted(conversionStatus.url);
        }
      },
      (error) => window.alert(error),
    );
  };

  /** function executed periodically during the video upload progress **/
  const updateUploadProgress = (progress) => {
    onVideoUploadProgress &&
      onVideoUploadProgress(progress !== 100 ? progress : 0);
    onVideoUploading && onVideoUploading(progress !== 100);
    if (progress === 100) {
      onVideoConversionProgress && onVideoConversionProgress(0);
      onVideoConverting && onVideoConverting(true);
    }
  };

  /** function executed after the video conversion process has finished **/
  const handleVideoUploadCompleted = (videoFile) => {
    const newVideo = {
      ...video,
      videoSource: videoFile,
    };
    setVideo(newVideo);

    onUploadSuccess && onUploadSuccess(newVideo, FILE_TYPES.VIDEO);
  };

  /** function executed when a video is selected for upload **/
  const handleVideoUpload = (videoFile) => {
    onVideoUploading && onVideoUploading(true);
    mediaService.uploadVideo(
      videoFile,
      (response) => {
        onVideoConverting && onVideoConverting(true);
        updateConversionProgress(response.url);
      },
      (error) => {
        window.alert(error);
      },
      updateUploadProgress,
    );
  };

  const handleMediaUpload = async (file) => {
    try {
      const result = await mediaService.uploadMedia(file);
      if (result.status === 200) {
        onUploadSuccess && onUploadSuccess(result.data, FILE_TYPES.IMAGE);
      }
    } catch (e) {
      setLoading && setLoading(false);
      if (e?.response?.status === 502) {
        dispatch({
          type: SET_ERROR_MESSAGE,
          data: <FormattedMessage {...translations.UploadFail} />,
        });
      } else {
        dispatch({
          type: SET_ERROR_MESSAGE,
          data: <FormattedMessage {...translations.InvalidFormat} />,
        });
      }
      if (ENV.IS_DEV) {
        console.error("Couldn't upload file", e);
      }
    }
  };

  // function executed when the value of the file input is changed
  const handleChange = ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (validity.valid) {
      if (file.type.includes("video")) {
        if (isVideoUploadEnabled) {
          onFileTypeDetected && onFileTypeDetected(FILE_TYPES.VIDEO);
          handleVideoUpload(file);
        }
      } else {
        onFileTypeDetected && onFileTypeDetected(FILE_TYPES.IMAGE);
        handleMediaUpload(file);
      }
    }
  };

  return (
    <div
      className={classNames([classes.uploadBtnWrapper, className])}
      onMouseMove={onUploadMouseMove}
      onClick={() => inputFile.current.click()}
    >
      {children}
      <input
        required
        {...restProps}
        className={classes.uploadInput}
        ref={inputFile}
        onMouseDown={handleFocus}
        onMouseUp={handleFocus}
        onClick={handleFocus}
        onChange={handleChange}
        type="file"
      />
    </div>
  );
};

UploadMedia.props = {
  /** unique identifier of the input on the same page. it is used to check if media on the select-media screen was
   * selected for this input. **/
  id: PropTypes.string.isRequired,
  /** function(mediaUrl, mediaType: {FILE_TYPES.IMAGE | FILE_TYPES.VIDEO}):
   * - executed after the upload mutation has finished **/
  onUploadSuccess: PropTypes.func,
  /** enable selecting video files **/
  isVideoUploadEnabled: PropTypes.bool,
  /** function(conversionProgress): executed when the video conversion progress is updated **/
  onVideoConversionProgress: PropTypes.func,
  /** function(convertingStatus): executed when the video converting status is changed **/
  onVideoConverting: PropTypes.func,
  /** function(uploadProgress): executed when the video upload progress is updated **/
  onVideoUploadProgress: PropTypes.func,
  /** function(uploadStatus): executed when the video upload status is changed **/
  onVideoUploading: PropTypes.func,
  /** function(fileType): executed when the type of the selected file gets determined **/
  onFileTypeDetected: PropTypes.func,
  /** custom content inside the input button **/
  children: PropTypes.node,
  /** function executed when the mouse is moved over the upload button container **/
  onUploadMouseMove: PropTypes.func,
  /** provide custom style for the container **/
  className: PropTypes.string,
  /** display media selection pop-up when clicking on the file input **/
  enableMediaSelectionPopup: PropTypes.bool,
  /** It's a wrapper around ReactDOM.input, so it accepts all the props that ReactDOM.input accepts, except:
   * - type: it has a locked value of type="file"
   * - className
   * - onMouseDown
   * - onMouseUp
   * - onClick
   * - onChange
   * **/
};

UploadMedia.defaultProps = {
  isVideoUploadEnabled: true,
  enableMediaSelectionPopup: true,
};

export default UploadMedia;
