import React, { useMemo } from "react";
import PageEditor from "../../components/PageEditor";
import { PAGE_TEMPLATES, PAGE_TYPES } from "../../configs/constants";
import HorizontalSliderPageEditor from "../../components/SliderPageEditor/Horizontal";
import VerticalSliderPageEditor from "../../components/SliderPageEditor/Vertical";
import { EmptyArticleTemplatePage } from "../../utils/ui-generator/defaultTemplates";
import ContentNotHandled from "../../components/common/ContentNotHandled";
import SimpleQuizPageEditor from "../../components/SimpleQuizPageEditor";
import MadlibPageEditor from "../../components/MadlibPageEditor";
import DragAndDropActivityPageEditor from "../../components/DragAndDropActivityPageEditor";
import { FullscreenLoader } from "genius-ui";
import NinjaActivityPageEditor from "../../components/NinjaActivityPageEditor";

const LessonContentHandler = ({ publishLessonData, ...props }) => {
  const {
    classes,
    lessonId,
    language,
    onDataChanged,
    onRemovePage,
    onOrderDown,
    onOrderUp,
    currentOpenedMarker,
    UpdateOpenedMarker,
    activePageId,
    onPageChange,
    onNewPageCreated,
    isLoaderActive,
  } = props;

  const isProgramQuiz = useMemo(() => {
    return publishLessonData?.pages.some(
      ({ data }) => JSON.parse(data).data.isProgramQuiz === true,
    );
  }, [publishLessonData?.pages]);

  if (isLoaderActive) {
    return <FullscreenLoader />;
  }

  return (
    <div className={classes.rootContainer}>
      {publishLessonData.pages
        .filter((x) => x.pageId !== "-1")
        .map(({ pageId, data, index, template }) => {
          switch (template) {
            case PAGE_TEMPLATES.SLIDERFULLSCREEN_TEMPLATE:
              return (
                <div className={classes.pageItemBox} key={pageId}>
                  <HorizontalSliderPageEditor
                    pageId={pageId}
                    data={data}
                    orderId={index}
                    documentId={lessonId}
                    language={language}
                    onDataChanged={onDataChanged}
                    onRemovePage={onRemovePage}
                    onOrderDownClick={onOrderDown}
                    onOrderUpClick={onOrderUp}
                    currentOpenedMarker={currentOpenedMarker}
                    UpdateOpenedMarker={(...args) =>
                      UpdateOpenedMarker(pageId, ...args)
                    }
                    isActivePage={activePageId === pageId}
                    onFocus={() => onPageChange(pageId)}
                  />
                </div>
              );
            case PAGE_TEMPLATES.FULLSCREENSCROLL_TEMPLATE:
              return (
                <div className={classes.pageItemBox} key={pageId}>
                  <VerticalSliderPageEditor
                    pageId={pageId}
                    data={data}
                    orderId={index}
                    documentId={lessonId}
                    language={language}
                    onDataChanged={onDataChanged}
                    onRemovePage={onRemovePage}
                    onOrderDownClick={onOrderDown}
                    onOrderUpClick={onOrderUp}
                    currentOpenedMarker={currentOpenedMarker}
                    UpdateOpenedMarker={(...args) =>
                      UpdateOpenedMarker(pageId, ...args)
                    }
                    isActivePage={activePageId === pageId}
                    onFocus={() => onPageChange(pageId)}
                  />
                </div>
              );
            case PAGE_TEMPLATES.ARTICLE_TEMPLATE:
              return (
                <div className={classes.pageItemBox} key={pageId}>
                  <PageEditor
                    pageId={pageId}
                    data={data}
                    orderId={index}
                    documentId={lessonId}
                    language={language}
                    onDataChanged={onDataChanged}
                    onRemovePage={onRemovePage}
                    onNewPageCreated={() =>
                      onNewPageCreated(
                        lessonId,
                        language,
                        JSON.stringify(EmptyArticleTemplatePage),
                        PAGE_TYPES.ARTICLE,
                      )
                    }
                    onPageChange={onPageChange}
                    isActivePage={activePageId === pageId}
                    onOrderDownClick={onOrderDown}
                    onOrderUpClick={onOrderUp}
                  />
                </div>
              );
            case PAGE_TEMPLATES.SIMPLE_QUIZ_TEMPLATE:
              return (
                <div className={classes.pageItemBox} key={pageId}>
                  <SimpleQuizPageEditor
                    pageId={pageId}
                    data={data}
                    orderId={index}
                    documentId={lessonId}
                    language={language}
                    onDataChanged={onDataChanged}
                    onRemovePage={onRemovePage}
                    isActivePage={activePageId === pageId}
                    onOrderDownClick={onOrderDown}
                    onOrderUpClick={onOrderUp}
                    isProgramQuiz={isProgramQuiz}
                  />
                </div>
              );
            case PAGE_TEMPLATES.MADLIB_TEMPLATE:
              return (
                <div className={classes.pageItemBox} key={pageId}>
                  <MadlibPageEditor
                    pageId={pageId}
                    data={data}
                    orderId={index}
                    documentId={lessonId}
                    language={language}
                    onDataChanged={onDataChanged}
                    onRemovePage={onRemovePage}
                    isActivePage={activePageId === pageId}
                    onOrderDownClick={onOrderDown}
                    onOrderUpClick={onOrderUp}
                    isProgramQuiz={isProgramQuiz}
                  />
                </div>
              );
            case PAGE_TEMPLATES.DRAG_AND_DROP_TEMPLATE:
              return (
                <div className={classes.pageItemBox} key={pageId}>
                  <DragAndDropActivityPageEditor
                    pageId={pageId}
                    data={data}
                    orderId={index}
                    documentId={lessonId}
                    language={language}
                    onDataChanged={onDataChanged}
                    onRemovePage={onRemovePage}
                    isActivePage={activePageId === pageId}
                    onOrderDownClick={onOrderDown}
                    onOrderUpClick={onOrderUp}
                    isProgramQuiz={isProgramQuiz}
                  />
                </div>
              );
            case PAGE_TEMPLATES.NINJA_TEMPLATE:
              return (
                <div className={classes.pageItemBox} key={pageId}>
                  <NinjaActivityPageEditor
                    pageId={pageId}
                    data={data}
                    orderId={index}
                    documentId={lessonId}
                    language={language}
                    onDataChanged={onDataChanged}
                    onRemovePage={onRemovePage}
                    isActivePage={activePageId === pageId}
                    onOrderDownClick={onOrderDown}
                    onOrderUpClick={onOrderUp}
                  />
                </div>
              );
            default:
              return (
                <div className={classes.pageItemBox} key={pageId}>
                  <ContentNotHandled
                    pageId={pageId}
                    orderId={index}
                    documentId={lessonId}
                    language={language}
                    onRemovePage={onRemovePage}
                  />
                </div>
              );
          }
        })}
    </div>
  );
};

export default LessonContentHandler;
