import { defineMessages } from "react-intl";

export const translations = defineMessages({
  ProgramNameLabel: {
    id: "ProfileLessons.ProgramNameLabel",
    defaultMessage: "Program name",
  },
  ProgressionLabel: {
    id: "ProfileLessons.ProgressionLabel",
    defaultMessage: "Progression",
  },
  UpdateLabel: {
    id: "ProfileLessons.UpdateLabel",
    defaultMessage: "Latest update",
  },
  PointsLabel: {
    id: "ProfileLessons.PointsLabel",
    defaultMessage: "Points",
  },
  LessonsLabel: {
    id: "ProfileLessons.LessonsLabel",
    defaultMessage: "{progress} lessons",
  },
  PagesLabel: {
    id: "ProfileLessons.PagesLabel",
    defaultMessage: "{progress} pages",
  },
  noDataText: {
    id: "ProfileLessons.noDataText",
    defaultMessage: "No one program yet",
  },
});
