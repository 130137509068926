export default (theme) => ({
  ninjaChoiceMain: {
    width: "100%",
    display: "flex !important",
    flex: 1,
    justifyContent: "space-between !important",
    alignItems: "center",
  },
  ninjaChoiceCheckbox: {
    width: "60px !important",
  },
  ninjaChoiceType: {
    width: 50,
    backgroundColor: "white",
    color: "grey",
    borderRadius: 5,
  },
  ninjaChoiceText: {
    width: "175px !important",
    fontSize: "14px !important",
    marginRight: 10,
    marginLeft: 15,
    marginBottom: 5,
    marginTop: 5,
  },
  ninjaChoiceDelete: {
    fontSize: 12,
    color: "#bdbdbd",
    cursor: "pointer",
  },
  ninjaChoiceValue: {
    width: 200,
  },
  ninjaImage: {
    width: 150,
    height: 100,
    display: "flex",
    alignItems: "center",
    marginLeft: 15,
    backgroundImage:
      'url("https://mediastoresopi.blob.core.windows.net/shiseido-upload/d466b0c9-7e17-427a-a02f-cd0323eb9ba3logo.svg")',
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  choice: {
    display: "flex !important",
    justifyContent: "space-between",
    "& span.icon-recycle": {
      fontSize: 14,
      color: ({ isBGDark }) =>
        isBGDark
          ? theme.NinjaQuestionChoiceEditor.darkChoice
          : theme.NinjaQuestionChoiceEditor.notDarkChoice,
      marginLeft: 20,
      marginRight: 10,
    },
  },
  choiceInput: {
    fontSize: 18,
    minHeight: 21,
    maxHeight: 45,
    marginBottom: 20,
    overflow: "hidden",
    outline: "unset",
    color: ({ isBGDark }) =>
      isBGDark
        ? theme.NinjaQuestionChoiceEditor.darkChoiceInput
        : theme.NinjaQuestionChoiceEditor.notDarkChoiceInput,
    "&[contenteditable]:empty:before": {
      content: "attr(placeholder)",
      display: "block",
    },
  },
  addNewAnswerChoice: {
    fontFamily: theme.FontFamily,
    fontSize: "16px !important",
    fontWeight: "normal !important",
    fontStyle: "normal !important",
    fontStretch: "condensed !important",
    lineHeight: "normal !important",
    letterSpacing: "normal !important",
    textDecoration: "underline",
    textDecorationColor: ({ isBGDark }) =>
      isBGDark
        ? theme.NinjaQuestionChoiceEditor.darkAddNewAnswerChoice
        : theme.NinjaQuestionChoiceEditor.notDarkdAddNewAnswerChoice,
    "& > span": {
      color: ({ isBGDark }) =>
        isBGDark
          ? theme.NinjaQuestionChoiceEditor.darkAddNewAnswerChoice
          : theme.NinjaQuestionChoiceEditor.notDarkdAddNewAnswerChoice,
    },
  },
  cardImageUpload: {
    textAlign: "center",
    cursor: "pointer",
    "& > span.icon-upload": {
      fontSize: 16,
    },
    "& > div": {
      fontSize: 14,
      marginTop: 10,
    },
    "& > input": {
      width: "100%",
      padding: 40,
    },
  },
  cardImageUploaded: {
    position: "absolute",
    left: 10,
    bottom: 8,
    "& > span.icon-upload:before": {},
    "& > div": {
      display: "none",
    },
    "& > input": {
      padding: 10,
    },
  },
});
