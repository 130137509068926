import gql from "graphql-tag";

export const GET_TAGS = gql`
  query queryTagsByClusterId($clusterIds: [ID!]) {
    tags(clusterIds: $clusterIds) {
      pic
      tagId
      title
      clusterName
      clusterId
    }
  }
`;
