import { defineMessages } from "react-intl";

export const multimediaTemplateNamesTranslations = defineMessages({
  VideoFullWidthText: {
    id: "SideBarMultimediaTemplate.VideoFullWidthText",
    defaultMessage: "Video full width",
  },
  VideoInContainerText: {
    id: "SideBarMultimediaTemplate.VideoInContainerText",
    defaultMessage: "Video in container",
  },
});

export const translations = defineMessages({
  TemplateTitle: {
    id: "SideBarMultimediaTemplate.TemplateTitle",
    defaultMessage: "Multimedia",
  },
});
