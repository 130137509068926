import * as constants from "../../constants/store/kpi";
import client from "../../graphql/client";
import { SetErrorMessage } from "../notification/actions";
import {
  extractFilterValues,
  getFormattedTagList,
} from "../actions/common/kpi";
import { GET_GENERAL_DETAILS } from "../../graphql/remote/kpi/queries/general.graphql";
import { USER_ROLE_NAMES } from "../../configs/constants";
import { isShiseido } from "../../configs/client";
import {
  extendTagsWithUserTags,
  filterTagsByTrainerTags,
} from "../../utils/transformers";
import { SetIsLoading } from "../kpiFilter/actions";

const getPercentData = (actual, previous) =>
  (100 - (previous * 100) / actual).toFixed();

export const SetGeneralDetails = (data) => (dispatch) => {
  const { application, learning, feed, ask } = data;

  const applicationData = {
    activeUsers: 0,
    connections: 0,
    dailyUsage: 0,
    totalUsers: 0,
    previousPeriod: {
      activeUsers: 0,
      connections: 0,
      dailyUsage: 0,
    },
  };
  if (application) {
    applicationData.activeUsers = application.activeUsers
      ? application.activeUsers
      : 0;
    applicationData.connections = application.connections
      ? application.connections
      : 0;
    applicationData.dailyUsage = application.dailyUsage
      ? application.dailyUsage
      : 0;
    applicationData.totalUsers = application.totalUsers
      ? application.totalUsers
      : 0;
    if (application.previousPeriod) {
      applicationData.previousPeriod.activeUsers = application.previousPeriod
        .activeUsers
        ? application.previousPeriod.activeUsers
        : 0;
      applicationData.previousPeriod.connections = application.previousPeriod
        .connections
        ? application.previousPeriod.connections
        : 0;
      applicationData.previousPeriod.dailyUsage = application.previousPeriod
        .dailyUsage
        ? application.previousPeriod.dailyUsage
        : 0;
    }
  }
  dispatch({
    type: constants.SET_GENERAL_DETAILS,
    data: {
      application: applicationData,
      activeUsersPercentData: getPercentData(
        applicationData.activeUsers,
        applicationData.previousPeriod.activeUsers,
      ),
      connectionsPercentData: getPercentData(
        applicationData.connections,
        applicationData.previousPeriod.connections,
      ),
      dailyUsagePercentData: getPercentData(
        applicationData.dailyUsage,
        applicationData.previousPeriod.dailyUsage,
      ),
      actualAverageMin: (
        (applicationData.dailyUsage ? applicationData.dailyUsage : 0) / 60
      ).toFixed(1),
      previousAverageMin: (
        (applicationData.previousPeriod.dailyUsage
          ? applicationData.previousPeriod.dailyUsage
          : 0) / 60
      ).toFixed(1),
      feedTotal:
        (feed && feed.insights ? feed.insights : 0) +
        (feed && feed.lessons ? feed.lessons : 0) +
        (feed && feed.socialMedias ? feed.socialMedias : 0) +
        (feed && feed.sliders ? feed.sliders : 0),
      learning: learning,
      ask: ask || { geniusQuestion: 0, expertQuestion: 0, expertAnswer: 0 },
      feed: feed || { insights: 0, lessons: 0, socialMedias: 0, sliders: 0 },
    },
  });
};

export const GetGeneralDetails = () => async (dispatch, getState) => {
  const { profileInfo } = getState().auth;
  const { filters } = getState().kpi;
  if (filters.isLoading) return;

  try {
    dispatch(SetIsLoading(true));
    const generalFilterValues = extractFilterValues(filters);

    /** If user is a trainer always get data from own markets */
    if (isShiseido && profileInfo.roles.includes(USER_ROLE_NAMES.TRAINER)) {
      const markets = filterTagsByTrainerTags(
        profileInfo.tags,
        getState().tags.markets,
      );
      generalFilterValues.market = extendTagsWithUserTags(
        generalFilterValues.market,
        markets,
      );
    }
    const tagList = getFormattedTagList(generalFilterValues);

    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_GENERAL_DETAILS,
      variables: {
        fromDate: generalFilterValues.startDate,
        toDate: generalFilterValues.endDate,
        tags: tagList.length ? tagList : undefined,
      },
      notifyOnNetworkStatusChange: true,
    });

    const generalFilterValuesNew = extractFilterValues(getState().kpi.filters);

    /** If user is a trainer always get data from own markets */
    if (isShiseido && profileInfo.roles.includes(USER_ROLE_NAMES.TRAINER)) {
      const markets = filterTagsByTrainerTags(
        profileInfo.tags,
        getState().tags.markets,
      );
      generalFilterValuesNew.market = extendTagsWithUserTags(
        generalFilterValuesNew.market,
        markets,
      );
    }
    if (
      JSON.stringify(generalFilterValues) ===
      JSON.stringify(generalFilterValuesNew)
    ) {
      dispatch(SetGeneralDetails(result.data.generalKPI));
      dispatch(SetIsLoading(false));
    }
  } catch (e) {
    dispatch(
      SetErrorMessage("An error occured while fetching the general details"),
    );
  }
};
