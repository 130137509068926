import React from "react";
import PropTypes from "prop-types";
import DataTable from "../../../../components/common/Table";
import { defaultStyles } from "./BattleLeaderboard.style";
import withThemedStyle from "../../../../components/hoc/withThemedStyle";
import RoundedProfilePicture from "../../../../components/common/RoundedProfilePicture";
import { FormattedMessage } from "react-intl";
import { translations } from "./BattleLeaderboard.translations";
import { roundPtsNbr } from "utils/string";
import { getUri } from "components/common/ImageOpti/ImageOpti";

const columnsConfiguration = [
  {
    name: "userName",
    label: translations.UsersColumnTitle,
    sortable: false,
    isDisplayed: true,
  },
  {
    name: "attempts",
    label: translations.AttemptsColumnTitle,
    sortable: true,
    isDisplayed: true,
  },
  {
    name: "totalScore",
    label: translations.TotalScoreColumnTitle,
    sortable: true,
    isDisplayed: true,
    dataSuffix: "pts",
  },
  {
    name: "highestScore",
    label: translations.HighestScore,
    sortable: true,
    isDisplayed: true,
    dataSuffix: "pts",
  },
  {
    name: "averageScore",
    label: translations.AverageScoreColumnTitle,
    sortable: true,
    isDisplayed: true,
    dataSuffix: "pts",
  },
];

const BattleLeaderBoard = (props) => {
  const { classes, data, onSort, columnSort } = props;

  const renderItem = (item, index) => {
    return (
      <tr key={item.key} className={classes.row}>
        <td>
          <div className="userName">
            <RoundedProfilePicture
              imageUrl={getUri(item.userPhoto, "profile", "hd")}
              size={30}
            />
            <span>{item.userName}</span>
          </div>
        </td>
        <td>{item.attempts}</td>
        <td>
          {item.totalScore}
          {columnsConfiguration[2].dataSuffix}
        </td>
        <td>
          {item.highestScore}
          {columnsConfiguration[3].dataSuffix}
        </td>
        <td>
          {roundPtsNbr(item.averageScore)}
          {columnsConfiguration[4].dataSuffix}
        </td>
      </tr>
    );
  };

  const noDataContent = (
    <div className={classes.noContent}>
      <FormattedMessage {...translations.NoContentText} />
    </div>
  );

  return (
    <DataTable
      className={classes.dataTableContainer}
      columns={columnsConfiguration}
      data={data}
      renderItem={renderItem}
      loadingIndicator={<div>Loading...</div>}
      noDataContent={noDataContent}
      columnSort={columnSort}
      onSort={onSort}
      isLoading={false}
      displaySeeMoreButton={false}
    />
  );
};

BattleLeaderBoard.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      userName: PropTypes.string,
      userPhoto: PropTypes.string,
      attempts: PropTypes.number,
      totalScore: PropTypes.number,
      highestScore: PropTypes.number,
      averageScore: PropTypes.number,
    }),
  ).isRequired,
  onSort: PropTypes.func,
  columnSort: PropTypes.object,
};

export default withThemedStyle(defaultStyles)(BattleLeaderBoard);
