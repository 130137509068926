const EXCEL_HARMFUL_INITIAL_CHARACTERS = ["=", "+", "-", "@"];

// Removes the harmful characters from the initial of the fields from every excel element
// The HARMFUL_INITIAL_CHARACTERS are characters what ignores the function. It is easy to add more.
export const excelSanitizer = (data) => {
  return data.map((subArray) => {
    return subArray.map((element) => {
      let newElement = element;
      if (typeof newElement === "string") {
        while (
          EXCEL_HARMFUL_INITIAL_CHARACTERS.filter(
            (character) => newElement[0] === character,
          ).length > 0
        ) {
          newElement = newElement.substr(1);
        }
      }
      return newElement;
    });
  });
};
