import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { Icon, Icons } from "genius-ui";
import TableStyle from "./Table.style";
import withThemedStyle from "../../hoc/withThemedStyle";

class DataTable extends React.PureComponent {
  static SORT_METHODS = {
    ASC: "ASC",
    DESC: "DESC",
    // NONE: void (0)
  };

  componentDidMount() {
    // temporally disabled until the infinite scroll condition is clarified
    // window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    // temporally disabled until the infinite scroll condition is clarified
    // window.removeEventListener('scroll', this.handleScroll)
  }

  /**
   * When clicked on a column, and the column is sortable, the sorting method of the column is changed:
   * * from DataTable.SORT_METHODS.NONE to DataTable.SORT_METHODS.ASC
   * * from DataTable.SORT_METHODS.ASC to DataTable.SORT_METHODS.DESC
   * * from DataTable.SORT_METHODS.DESC to DataTable.SORT_METHODS.NONE
   * Also if provided, the @param onSort function is called, passing over the current sorting state of the sorted column
   * to it.
   * @param column
   */
  handleSort = (column) => {
    if (column.sortable) {
      let newSortMethod = DataTable.SORT_METHODS.ASC;
      const columnSort =
        this.props.columnSort && this.props.columnSort[column.name];
      if (columnSort) {
        newSortMethod =
          columnSort === DataTable.SORT_METHODS.ASC
            ? DataTable.SORT_METHODS.DESC
            : DataTable.SORT_METHODS.ASC;
      }

      this.props.onSort && this.props.onSort(column.name, newSortMethod);
    }
  };

  handleScroll = (e) => {
    // detect if scrolled to the end
    if (
      !this.props.displaySeeMoreButton &&
      document.body.scrollHeight - e.currentTarget.scrollY <=
        document.body.offsetHeight
    ) {
      this.props.onNextPage && this.props.onNextPage();
    }
  };

  renderColumn = (column) => {
    const { renderHeaderItem, classes } = this.props;
    const columnSort =
      this.props.columnSort && this.props.columnSort[column.name];
    let sortIcon = (
      <span className={classes.sortIcon}>
        <Icon iconName={Icons.dropdownIndicator} />
      </span>
    );
    if (columnSort) {
      if (columnSort === DataTable.SORT_METHODS.DESC) {
        sortIcon = <Icon iconName={Icons.dropdownIndicator} />;
      } else if (columnSort === DataTable.SORT_METHODS.ASC) {
        const className = classes.sortDescIcon;
        sortIcon = (
          <Icon iconName={Icons.dropdownIndicator} style={className} />
        );
      }
      sortIcon = <span className={classes.sortIcon}>{sortIcon}</span>;
    }

    const containerClassName = classnames([
      column.sortable
        ? classes.headerColumnItem
        : classes.headerColumnItemNotSortable,
      {
        [classes.sortableHeaderColumnItem]: column.sortable,
      },
    ]);

    return (
      <th
        key={column.name}
        onClick={() => this.handleSort(column)}
        className={classes.headerColumnItemContainer}
      >
        <div className={containerClassName}>
          {renderHeaderItem ? (
            renderHeaderItem(column)
          ) : column.label && typeof column.label === "object" ? (
            // if column label is object, rendering it as ReactIntl.FormattedMessage
            <FormattedMessage {...column.label} />
          ) : (
            column.label
          )}
          {column.sortable && sortIcon}
        </div>
      </th>
    );
  };

  render() {
    const {
      columns,
      data,
      renderItem,
      isLoading,
      noDataContent,
      className,
      classes,
    } = this.props;

    const containerClassName = classnames([className]);

    return (
      <div className={containerClassName}>
        <table className={classes.table}>
          <thead className={classes.columnContainer}>
            <tr>
              {columns.map((column, index) => this.renderColumn(column, index))}
            </tr>
          </thead>
          <tbody>{data.map((data, index) => renderItem(data, index))}</tbody>
        </table>
        {!isLoading && data.length === 0 && noDataContent}
      </div>
    );
  }
}

const ColumnShape = PropTypes.shape({
  /** column identifier. it is used for operations with the column(for ex. sorting). should be unique accross the columns **/
  name: PropTypes.string.isRequired,
  /** {string|reactIntlMessage|ReactElement}: column title displayed in the table header **/
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element,
  ]),
  /** if true, the column will be sortable **/
  sortable: PropTypes.bool,
});

DataTable.propTypes = {
  /** the definition of the columns **/
  columns: PropTypes.arrayOf(ColumnShape).isRequired,
  /** its items are rendered as rows **/
  data: PropTypes.array.isRequired,
  /** called on each item. it must return a single rendered row**/
  renderItem: PropTypes.func.isRequired,
  /** customize the rendering of header items **/
  renderHeaderItem: PropTypes.func,
  /** func(currentSortingState): this function is called every time the sorting method of the column is changed **/
  onSort: PropTypes.func,
  /** object containing the sort state of the table - sorting is controlled**/
  columnSort: PropTypes.object,
  /** displays the loading indicator **/
  isLoading: PropTypes.bool,
  /** component displayed, when the data is empty **/
  noDataContent: PropTypes.element,
  /** class name of the container element **/
  className: PropTypes.string,
  /** whether display the see more button or not **/
  displaySeeMoreButton: PropTypes.bool,
  /** function executed when clicked on the see more button or scrolled to the end of the table. if not provided, the
   * "see more" button is not displayed **/
  onNextPage: PropTypes.func,
};

export default withThemedStyle(TableStyle)(DataTable);
