import clientConfig, { REACT_APP_CLIENT_NAME } from "../configs/client";
import { ENV } from "../configs/constants";

/**
 * Returns value associated to the current client.
 * @param clientValueAssociations: object containing the value associated with all the clients.
 *   - object key: the name of the client. possible values: loreal, dior, shiseido, diorVm, vca
 *   - object value: the value that must be returned for the client with key
 * @param defaultValue: value returned if no associated value for the current client is specified in @clientValueAssociations
 *    or the current client is a new client that's not configured yet inside this utility function(also an error is
 *    displayed inside the console in this case).
 * @returns {*}: value associated with the current client or the @defaultValue
 * @constructor
 */
export const GetCurrentClientValue = (
  clientValueAssociations,
  defaultValue,
) => {
  switch (REACT_APP_CLIENT_NAME) {
    case "SHISEIDO":
    case "SHISEIDO-PREPROD":
    case "SHISEIDO-PROD":
      return clientValueAssociations.shiseido || defaultValue;
    default:
      if (ENV.IS_DEV) {
        console.error(
          `Client name ${REACT_APP_CLIENT_NAME} is not configured yet inside GetCurrentClientValue utility function`,
        );
      }
      return defaultValue;
  }
};

export const GetClientSubModuleLocation = (moduleName, subNoduleName) =>
  clientConfig.MODULES[moduleName].SUBMODULES[subNoduleName].LOCATION;
