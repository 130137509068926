import React from "react";
import { Icon, Icons } from "genius-ui";
import { SideBarNavigationStyle } from "./SideBarNavigation.style";
import withThemedStyle from "../hoc/withThemedStyle";

const SideBarNavigation = ({ classes, ...props }) => {
  return (
    <nav className={classes.navigation}>
      <div className={classes.mainContainer}>
        <div className="NavMainContent">
          <div className={classes.back} onClick={props.handleBackClick}>
            <Icon iconName={Icons.arrowLeft} alt="" />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default withThemedStyle(SideBarNavigationStyle)(SideBarNavigation);
