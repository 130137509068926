import gql from "graphql-tag";

export const GET_NINJA_KPI = gql`
  query getNinjaKpi($lessonId: ID!) {
    rankings(lessonId: $lessonId) {
      rank
      score
      documentId
      date
      name
      parent {
        title
      }
      document(langId: 3) {
        title
      }
      userId
      user {
        profile {
          mail
        }
      }
    }
  }
`;
