export const defaultStyles = (theme) => {
  return {
    root: {
      fontFamily: theme.FontFamily,
      backgroundColor: theme.PollResultsTable.backgroundColor,
      borderRadius: 10,
      overflow: "hidden",
      "& .accordion__button": {
        fontSize: 14,
        fontWeight: 500,
        color: theme.PollResultsTable.accordionButtonColor,
        padding: 20,
        "&:hover": {
          backgroundColor: `${theme.PollResultsTable.accordionButtonHoverColor}!important`,
        },
        "&:focus": {
          outline: "none",
        },
        "&:after": {
          top: "20px!important",
          right: "20px!important",
          borderRight: `2px solid ${theme.PollResultsTable.arrowColor}!important`,
          borderBottom: `2px solid ${theme.PollResultsTable.arrowColor}!important`,
        },
        borderBottom: `solid 1px ${theme.PollResultsTable.accordionButtonBorderBottomColor}!important`,
      },
    },
    content: {
      padding: "15px 80px 15px 40px",
      backgroundColor: theme.PollResultsTable.contentBackgroundColor,
      borderBottom: `solid 1px ${theme.PollResultsTable.contentBorderBottomColor}`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      "& > .answer": {
        fontSize: 14,
        fontWeight: 500,
        color: theme.PollResultsTable.answerColor,
      },
      "& > .right": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& .completedPercentage": {
          fontSize: 14,
          fontWeight: 500,
          color: theme.PollResultsTable.completedPercentageColor,
          marginRight: 20,
        },
      },
    },
    progressBar: {
      width: "520px!important",
      height: "8px!important",
      "& .mpb-legend": {
        display: "none",
      },
    },
  };
};
