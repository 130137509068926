import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonSize, ButtonVariation, Modal } from "genius-ui";
import style from "./DuplicationModal.style";
import { LANGUAGES } from "../../../configs/referential";
import { FormattedMessage } from "react-intl";
import { translations } from "./DuplicationModal.translations";
import FilterLanguages from "../../FilterLanguages";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const DuplicationModal = (props) => {
  const {
    visible,
    hideModal,
    sourceLang,
    availableItemLanguages,
    duplicationHandler,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <FormattedMessage {...translations.DuplicationModalTitle}>
      {(message) => (
        <Modal
          className={classes.modalFrameStyle}
          show={visible}
          onClose={hideModal}
          title={<span className={classes.modalTitleStyle}>{message[0]}</span>}
          content={
            visible ? (
              <FilterLanguages languages={LANGUAGES}>
                {(languages) =>
                  languages.map((lang) => (
                    <div key={lang.Code} className={classes.modalLabelStyle}>
                      <Button
                        handleClick={() => {
                          duplicationHandler(lang.Code);
                          hideModal();
                        }}
                        disabled={
                          !availableItemLanguages.includes(lang.Code) ||
                          lang.Code === sourceLang
                        }
                        variation={ButtonVariation.secondary}
                        size={ButtonSize.small}
                      >
                        {lang.Name}
                      </Button>
                    </div>
                  ))
                }
              </FilterLanguages>
            ) : null
          }
        />
      )}
    </FormattedMessage>
  );
};

DuplicationModal.propTypes = {
  /** Show or hide modal */
  visible: PropTypes.bool,
  /** Hide modal event handler */
  hideModal: PropTypes.func.isRequired,
  /** Translate from */
  sourceLang: PropTypes.string,
  /** Available destination languages */
  availableItemLanguages: PropTypes.array,
  /** Duplication action handler */
  duplicationHandler: PropTypes.func,
};

DuplicationModal.defaultProps = {
  visible: false,
  availableItemLanguages: [],
};

export default DuplicationModal;
