const contentPadding = 30;
const globalWidth = 1320 + 2 * contentPadding;

export default (theme) => ({
  header: {
    backgroundColor: theme.UserProfileHeader.headerbackground,
    boxShadow: `0 2px 20px 0 ${theme.UserProfileHeader.headerBoxShadow}`,
    "& > div": {
      maxWidth: globalWidth,
      display: "flex",
      flexDirection: "row",
      height: 60,
      margin: "auto",
      alignItems: "center",
      justifyContent: "space-between",
      padding: `0 ${contentPadding}px`,
      backgroundColor: theme.UserProfileHeader.headerbackground,
    },
  },
  lastConnection: {
    fontSize: 14,
    color: theme.UserProfileHeader.lastConnectionColor,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  name: {
    marginLeft: 24,
    fontWeight: "bold",
    fontSize: 18,
    color: theme.UserProfileHeader.nameColor,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  back: {
    cursor: "pointer",
    color: theme.UserProfileHeader.backColor,
    fontSize: 17,
    "&:hover": {
      color: theme.UserProfileHeader.backHover,
    },
  },
  exportButton: {
    marginLeft: 15,
  },
});
