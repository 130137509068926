import {
  SET_IS_MULTI_SELECT,
  SET_TARGET_ROUTE,
  SET_TARGET_COMPONENT,
  SELECT_MEDIA,
  RESET_SELECT_MEDIA,
} from "../../constants/store/selectMedia";
import { RESET_STORE } from "../../constants/store/auth";

const initialState = {
  isMultiSelect: false,
  targetRoute: undefined,
  targetComponent: undefined,
  selectedMedia: [],
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case SET_IS_MULTI_SELECT:
      return {
        ...state,
        isMultiSelect: data,
      };
    case SET_TARGET_ROUTE:
      return {
        ...state,
        targetRoute: data,
      };
    case SET_TARGET_COMPONENT:
      return {
        ...state,
        targetComponent: data,
      };
    case SELECT_MEDIA:
      let selectedMedia = [data];
      const mediaAlreadySelected = state.selectedMedia.find(
        (media) => media.id === data.id,
      );
      if (mediaAlreadySelected) {
        selectedMedia = state.selectedMedia.filter(
          (media) => media.id !== data.id,
        );
      } else if (state.isMultiSelect) {
        selectedMedia = [...state.selectedMedia, data];
      }

      return {
        ...state,
        selectedMedia,
      };
    case RESET_SELECT_MEDIA: {
      return { ...initialState };
    }
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
