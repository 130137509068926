import gql from "graphql-tag";

export const GET_INSIGHT_BY_ID = gql`
  query insightGetById($insightId: ID) {
    admin {
      insights(insightId: $insightId) {
        insightId
        versions {
          isPrivate
          title
          summary
          lang
          picThumb
          picCover
          videoCover
          author {
            userId
          }
          tags {
            tagId
            title
            clusterId
            clusterMetaTagId
            parentTag {
              tagId
            }
          }
          predictTags {
            tagId
            title
            clusterId
          }
          pages {
            pageId
            index
            template
            data
          }
          workflow
          workflowScheduledIn
          workflowScheduledOut
        }
      }
    }
  }
`;
