import React from "react";
import PropTypes from "prop-types";
import { defaultStyles } from "./PollResultTable.style";
import withThemedStyle from "../../../../components/hoc/withThemedStyle";
import { MultiProgressBar, Accordion } from "genius-ui";

const PollResultTable = (props) => {
  const { classes, questions } = props;

  const renderContent = (item) => (
    <div>
      {item.content.map((data) => (
        <div className={classes.content} key={data.key}>
          <div className="answer">{data.answer}</div>
          <div className="right">
            <div className="completedPercentage">
              {data.completedPercentage}%
            </div>
            <MultiProgressBar
              className={classes.progressBar}
              progresses={[
                {
                  id: 1,
                  percentage: data.completedPercentage,
                  color: "#44dc74",
                },
                {
                  id: 2,
                  percentage: 100 - data.completedPercentage,
                  color: "#eaeaea",
                },
              ]}
            />
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <Accordion
      preExpanded={[questions[0].uuid]}
      className={classes.root}
      items={questions}
      renderContent={renderContent}
    />
  );
};

PollResultTable.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      heading: PropTypes.string,
      content: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          answer: PropTypes.string,
          completedPercentage: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
};

export default withThemedStyle(defaultStyles)(PollResultTable);
