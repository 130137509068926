import gql from "graphql-tag";

export const FILTER_SHOPS = gql`
  query filterShops(
    $search: String
    $tagIds: [ID!]
    $skip: Int
    $limit: Int
    $tagMultipleIntersectIds: [ObjectCoreInputMultipleTags!]
  ) {
    Location(
      search: $search
      tagIds: $tagIds
      skip: $skip
      limit: $limit
      tagMultipleIntersectIds: $tagMultipleIntersectIds
      previewOnly: true
    ) {
      ocId
      title
      picCover
      addressBuilding
      addressCity {
        tagId
        title
      }
      addressZone {
        tagId
        title
      }
      addressCountry {
        tagId
        title
      }
    }
  }
`;
