import { defineMessages } from "react-intl";

export const programsWidgetShiseidoTranslations = defineMessages({
  Programs: {
    id: "ProgramsWidgetShiseido.Header.Program",
    defaultMessage: "Programs",
  },
  Completion: {
    id: "ProgramsWidgetShiseido.Header.Completion",
    defaultMessage: "Completion",
  },
  LessonHeadingCRLabel: {
    id: "ProgramsWidgetShiseido.LessonHeadingCR",
    defaultMessage: "{completionRate} lessons",
  },
  LessonNameLabel: {
    id: "ProgramsWidgetShiseido.LessonNameLabel",
    defaultMessage: "Lessons",
  },
  LessonCompletionRateLabel: {
    id: "ProgramsWidgetShiseido.LessonCompletionRateLabel",
    defaultMessage:
      "# of users who have completed the lesson/users who have attempted the lesson",
  },
  LessonUserStoppedLabel: {
    id: "ProgramsWidgetShiseido.LessonUserStoppedLabel",
    defaultMessage:
      "% of users that have stopped the lesson and its page number",
  },
  TotalPageLabel: {
    id: "ProgramsWidgetShiseido.TotalPageLabel",
    defaultMessage: "Total number of pages",
  },
  NoOneLessonText: {
    id: "ProgramsWidgetShiseido.NoOneLessonText",
    defaultMessage: "No one lesson yet",
  },
});

export const lessonRowTranslations = defineMessages({
  LessonDetailCRLabel: {
    id: "ProgramsWidgetShiseido.LessonDetailCR",
    defaultMessage: "{completionRate} Users",
  },
  UsersQuitLabel: {
    id: "ProgramsWidgetShiseido.UsersQuitLabel",
    defaultMessage: "{ratio} users quit at page",
  },
});
