import React from "react";
import Toolbar from "../../components/Toolbar";
import { PageEditorStyle } from "./PageEditor.style";
import Title from "../../components/common/Title";
import Regular from "../../components/common/Regular";
import SubTitle from "../../components/common/SubTitle";
import { decodeNewLines, encodeNewLines } from "../../utils/string";
import uuidv4 from "uuid/dist/v4";
import { GetDesignPageEncodeData } from "../../utils/ui-generator/generator";
import {
  CONTROL_TYPES,
  EMPTY_CONTENT,
  IMAGE_CONTROL_POSITION,
  PAGE_TYPES,
  VIDEO_CONTROL_POSITION,
} from "../../configs/constants";
import { Icon, Icons } from "genius-ui";
import DocumentPageHeader from "../../components/common/DocumentPageHeader";
import PropTypes from "prop-types";
import { EditorFontFamily } from "../../constants";
import Link from "../common/Link";
import { DefaultVideo, DefaultVideoCover } from "assets/icons";
import { connect } from "react-redux";
import { SetErrorMessage } from "../../store/notification/actions";
import { FormattedMessage, injectIntl } from "react-intl";
import ImagePageEditor from "../ImagePageEditor";
import VideoPageEditor from "../VideoPageEditor";
import { translations } from "./PageEditor.translations";
import withThemedStyle from "../hoc/withThemedStyle";

const DELETE_KEY = 46;
const BACKSPACE_KEY = 8;
const placeholder = document.createElement("div");

const GetControlType = (controlValue) => {
  switch (controlValue.type) {
    case "text":
      switch (controlValue.theme) {
        case "title":
          return CONTROL_TYPES.TITLE;
        case "subTitle":
          return CONTROL_TYPES.SUBTITLE;
        case "regular":
          return CONTROL_TYPES.REGULAR;
        default:
          return "notdefined";
      }
    case "image":
      return CONTROL_TYPES.IMAGE;
    case "video":
      return CONTROL_TYPES.VIDEO;
    case "link":
      return CONTROL_TYPES.LINK;
    default:
      return "notdefined";
  }
};

const GetValueForControlType = (controlValue) => {
  switch (controlValue.type) {
    case "video":
      return {
        sourceVideo: controlValue.url,
        videoCover: controlValue.on_pause_url,
      };
    case "image":
      return controlValue.url;
    case "link":
      return {
        content: controlValue.content,
        text: controlValue.text,
        type: "link",
        theme: "extra",
      };
    default:
      return controlValue.content === EMPTY_CONTENT
        ? ""
        : decodeNewLines(controlValue.content);
  }
};

class PageEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      children: [],
      componentValues: {},
      currentSelectedChildrenId: "",
      currentPageDocument: "",
      currentActiveIconId: "",
    };

    this.handleAddComponentClick = this.handleAddComponentClick.bind(this);
    this.handleTextComponentValueChange =
      this.handleTextComponentValueChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleNewPageClick = this.handleNewPageClick.bind(this);
    this.handleVideoChange = this.handleVideoChange.bind(this);
  }

  getControlsToRenderFromData(data, intl) {
    let dataObject = JSON.parse(data);
    let contentArray =
      dataObject.data && dataObject.data.content ? dataObject.data.content : [];
    if (dataObject.data && dataObject.data.header) {
      contentArray.splice(0, 0, dataObject.data.header);
    }
    if (contentArray !== undefined) {
      return contentArray
        .map((x, index) => {
          let control = {};
          control.RenderItem = this.getNewComponent(
            GetControlType(x),
            GetValueForControlType(x),
            intl,
            x.theme,
            index,
          );
          control.ValueItem = {};
          control.ValueItem.ControlType = GetControlType(x);
          control.ValueItem.ControlValue = GetValueForControlType(x);
          control.ValueItem.ControlVariation = x.theme;
          control.ValueItem.Index = index;

          return control;
        })
        .filter((x) => x.ValueItem.ControlType !== "notdefined");
    } else {
      return [];
    }
  }

  getNewComponent(controlType, currentValue, intl, controlVariation, index) {
    let newComponent = {};
    let newId = uuidv4();
    newComponent.Id = newId;
    newComponent.ControlType = controlType;

    switch (controlType) {
      case CONTROL_TYPES.TITLE:
        newComponent.RenderControl = (
          <FormattedMessage {...translations.TitlePlaceholderText}>
            {(message) => (
              <Title
                key={newId}
                id={newId}
                value={currentValue}
                placeholder={message[0]}
                handleChange={this.handleTextComponentValueChange(newId)}
                handleKeyDown={this.handleKeyDown(newId)}
                fontFamily={EditorFontFamily}
              />
            )}
          </FormattedMessage>
        );
        break;
      case CONTROL_TYPES.SUBTITLE:
        newComponent.RenderControl = (
          <FormattedMessage {...translations.SubTitlePlaceholderText}>
            {(message) => (
              <SubTitle
                key={newId}
                id={newId}
                value={currentValue}
                placeholder={message[0]}
                handleChange={this.handleTextComponentValueChange(newId)}
                handleKeyDown={this.handleKeyDown(newId)}
                fontFamily={EditorFontFamily}
              />
            )}
          </FormattedMessage>
        );
        break;
      case CONTROL_TYPES.REGULAR:
        newComponent.RenderControl = (
          <FormattedMessage {...translations.RegularPlaceholderText}>
            {(message) => (
              <Regular
                key={newId}
                id={newId}
                value={currentValue}
                placeholder={message[0]}
                handleChange={this.handleTextComponentValueChange(newId)}
                handleKeyDown={this.handleKeyDown(newId)}
                fontFamily={EditorFontFamily}
              />
            )}
          </FormattedMessage>
        );
        break;
      case CONTROL_TYPES.IMAGE:
        newComponent.RenderControl = () => (
          <FormattedMessage {...translations.ImageSelectorButtonLabel}>
            {(message) => (
              <ImagePageEditor
                key={newId}
                uniqueId={`${this.props.pageId}-${index}`}
                id={newId}
                buttonLabel={message[0]}
                sourceImage={currentValue}
                onImageChange={this.handleImageChange}
                onClick={() => this.handleItemClick(newId)}
                isSelected={
                  this.props.isActivePage &&
                  this.state.currentSelectedChildrenId === newId
                }
                type={controlVariation}
              />
            )}
          </FormattedMessage>
        );
        break;
      case CONTROL_TYPES.VIDEO:
        newComponent.ControlVariation = VIDEO_CONTROL_POSITION.FULLWIDTH;
        newComponent.RenderControl = () => (
          <VideoPageEditor
            key={newId}
            uniqueId={`${this.props.pageId}-${index}`}
            id={newId}
            sourceVideo={
              currentValue && currentValue.sourceVideo
                ? currentValue.sourceVideo
                : DefaultVideo
            }
            sourcePosterImage={
              currentValue && currentValue.videoCover
                ? currentValue.videoCover
                : DefaultVideoCover
            }
            onVideoUpload={this.handleVideoChange}
            onClick={() => this.handleItemClick(newId)}
            isSelected={
              this.props.isActivePage &&
              this.state.currentSelectedChildrenId === newId
            }
            showErrorMessageHandler={this.props.showErrorMessageHandler}
            type={controlVariation}
          />
        );
        break;
      case CONTROL_TYPES.LINK:
        newComponent.RenderControl = (
          <Link
            key={newId}
            id={newId}
            onBlur={(newValues) => this.handleLinkChange(newValues, newId)}
            titlePlaceholder={intl.formatMessage({
              ...translations.LinkTitlePlaceholder,
            })}
            linkPlaceholder={intl.formatMessage({
              ...translations.LinkPlaceholder,
            })}
            onItemClick={() => this.handleItemClick(newId)}
            linkTitleValue={currentValue ? currentValue.text : ""}
            linkValue={currentValue ? currentValue.content : ""}
            handleKeyDown={this.handleKeyDown(newId)}
          />
        );
        break;
      default:
        break;
    }

    return newComponent;
  }

  handleAddComponentClick(controlType, intl, theme) {
    let currentChildrens = [...this.state.children];
    let currentValues = { ...this.state.componentValues };
    let component = this.getNewComponent(
      controlType,
      undefined,
      intl,
      theme,
      currentChildrens.length,
    );
    currentChildrens.push(component);
    currentValues[component.Id] = { Index: currentChildrens.length - 1 };
    this.setState({
      children: currentChildrens,
      componentValues: currentValues,
    });
    this.props.onPageChange(this.props.pageId);
  }

  handleTextComponentValueChange = (id) => (evt) => {
    let currentValues = { ...this.state.componentValues };

    if (!currentValues[id]) {
      currentValues[id] = {};
    }

    let currentControl = this.state.children.find((x) => x.Id === id);
    currentValues[id].ControlValue = evt.target.innerHTML;
    currentValues[id].ControlType = currentControl.ControlType;
    currentValues[id].ControlVariation = currentControl.ControlVariation;

    currentValues = Object.keys(currentValues).reduce(
      (currentValuesNew, key) => {
        const currentControl = currentValues[key];
        if (
          [
            CONTROL_TYPES.REGULAR,
            CONTROL_TYPES.SUBTITLE,
            CONTROL_TYPES.TITLE,
          ].includes(currentValues[key].ControlType)
        ) {
          currentControl.ControlValue = encodeNewLines(
            currentControl.ControlValue,
          );
        }

        currentValuesNew[key] = currentControl;
        return currentValuesNew;
      },
      {},
    );

    this.setState({
      componentValues: currentValues,
      currentSelectedChildrenId: id,
    });

    this.handleSave(currentValues);
  };

  handleImageChange(imageUrl, imageId, controlVariation) {
    let currentChildrens = [...this.state.children];
    let currentValues = { ...this.state.componentValues };
    let imageSelectorItemIndex = currentChildrens.findIndex(
      (x) => x.Id === imageId,
    );
    let imageSelectorItem = currentChildrens[imageSelectorItemIndex];
    imageSelectorItem.RenderControl = () => (
      <FormattedMessage {...translations.ImageSelectorButtonLabel}>
        {(message) => (
          <ImagePageEditor
            key="image"
            uniqueId={`${this.props.pageId}-${imageSelectorItemIndex}`}
            id={imageId}
            buttonLabel={message[0]}
            onImageChange={this.handleImageChange}
            onClick={() => this.handleItemClick(imageId)}
            sourceImage={imageUrl}
            isSelected={
              this.props.isActivePage &&
              this.state.currentSelectedChildrenId === imageId
            }
            type={controlVariation}
          />
        )}
      </FormattedMessage>
    );

    if (!currentValues[imageId]) {
      currentValues[imageId] = {};
    }

    currentValues[imageId].ControlValue = imageUrl;
    currentValues[imageId].ControlType = imageSelectorItem.ControlType;
    currentValues[imageId].ControlVariation = controlVariation;

    this.setState({
      children: currentChildrens,
      componentValues: currentValues,
    });

    this.handleSave(currentValues);
  }

  handleVideoChange(videoUrl, videoCover, videoId, controlVariation) {
    let currentChildrens = [...this.state.children];
    let currentValues = { ...this.state.componentValues };
    let videoSelectorItemIndex = currentChildrens.findIndex(
      (x) => x.Id === videoId,
    );
    let videoSelectorItem = currentChildrens[videoSelectorItemIndex];
    videoSelectorItem.RenderControl = () => (
      <VideoPageEditor
        key={videoId}
        uniqueId={`${this.props.pageId}-${videoSelectorItemIndex}`}
        id={videoId}
        sourceVideo={videoUrl}
        sourcePosterImage={videoCover}
        onVideoUpload={this.handleVideoChange}
        onClick={() => this.handleItemClick(videoId)}
        isSelected={
          this.props.isActivePage &&
          this.state.currentSelectedChildrenId === videoId
        }
        showErrorMessageHandler={this.props.showErrorMessageHandler}
        type={controlVariation}
      />
    );

    if (!currentValues[videoId]) {
      currentValues[videoId] = {};
    }

    currentValues[videoId].ControlValue = {
      sourceVideo: videoUrl,
      videoCover: videoCover,
    };
    currentValues[videoId].ControlType = videoSelectorItem.ControlType;
    currentValues[videoId].ControlVariation = controlVariation;

    this.setState({
      children: currentChildrens,
      componentValues: currentValues,
    });
    this.handleSave(currentValues);
  }

  handleLinkChange = (newValues, id) => {
    let currentValues = { ...this.state.componentValues };

    if (!currentValues[id]) {
      currentValues[id] = {};
    }

    currentValues[id].ControlValue = {
      content: newValues.content,
      text: newValues.text,
      type: "link",
      theme: "extra",
    };
    currentValues[id].ControlType = CONTROL_TYPES.LINK;

    this.setState({
      componentValues: currentValues,
      currentSelectedChildrenId: id,
    });

    this.handleSave(currentValues);
  };

  handleKeyDown = (id) => (evt) => {
    this.props.onPageChange(this.props.pageId);
    this.setState({ currentSelectedChildrenId: id });

    let currentValues = { ...this.state.componentValues };
    const currentChildrens = [...this.state.children];
    const currentControl = currentValues[id];
    const innerHtml = evt.currentTarget.innerHTML;
    const targetValue = evt.target.value;

    if (
      (evt.keyCode === DELETE_KEY || evt.keyCode === BACKSPACE_KEY) &&
      (!currentControl || (!innerHtml && !targetValue))
    ) {
      delete currentValues[id];
      this.setState({
        children: currentChildrens.filter((x) => x.Id !== id),
        componentValues: currentValues,
      });
      this.handleSave(currentValues);
    }
  };

  handleItemClick(id) {
    this.props.onPageChange(this.props.pageId);
    this.setState({ currentSelectedChildrenId: id });
  }

  handleSave(controlValues) {
    if (!Object.entries(controlValues).length) {
      this.props.onRemovePage(
        this.props.documentId,
        this.props.language,
        this.props.pageId,
      );
    } else {
      let controlArray = [];
      for (const [key, value] of Object.entries(controlValues)) {
        if (
          value.hasOwnProperty("ControlValue") &&
          (!!value.ControlValue || value.ControlValue === "")
        ) {
          controlArray.push(value);
        }
      }
      controlArray = controlArray.sort((x, y) => (x.Index > y.Index ? 1 : -1));
      let formattedSaveValue = GetDesignPageEncodeData(
        controlArray,
        PAGE_TYPES.ARTICLE,
      );
      this.setState({ currentPageDocument: formattedSaveValue });

      this.props.onDataChanged(
        this.props.documentId,
        this.props.language,
        formattedSaveValue,
        this.props.pageId,
      );
    }
  }

  onDragStart = (evt) => {
    this.dragged = evt.currentTarget;
    evt.dataTransfer.effectAllowed = "move";
    evt.dataTransfer.setData("text/html", this.dragged);
  };

  onDragEnd = (evt) => {
    if (
      this.over &&
      this.over.draggable &&
      placeholder.nextElementSibling !== null &&
      placeholder.nextElementSibling.dataset !== null
    ) {
      let currentChildrens = [...this.state.children];
      let currentValues = { ...this.state.componentValues };

      this.dragged.style.display = "flex";

      // Reorder array of controls after drag operation
      // let fromElementIndex = currentChildrens.findIndex(x => x.Id === this.dragged.dataset.id)
      let toElementIndex = currentChildrens.findIndex(
        (x) => x.Id === placeholder.nextElementSibling.dataset.id,
      );
      let childrenToMove = currentChildrens.find(
        (x) => x.Id === this.dragged.dataset.id,
      );
      currentChildrens = currentChildrens.filter(
        (x) => x.Id !== childrenToMove.Id,
      );
      currentChildrens.splice(toElementIndex, 0, childrenToMove);
      currentChildrens.forEach((x, index) => {
        if (currentValues[x.Id]) {
          currentValues[x.Id].Index = index;
        }
      });

      // try to remove temporary placeholder element
      try {
        this.dragged.parentNode.removeChild(placeholder);
        document.getElementById("drag-container").removeChild(placeholder);
      } catch (error) {}

      this.setState({
        children: currentChildrens,
        componentValues: currentValues,
      });

      this.handleSave(currentValues);
    } else {
      // try to remove temporary placeholder element
      try {
        this.dragged.parentNode.removeChild(placeholder);
        document.getElementById("drag-container").removeChild(placeholder);
      } catch (error) {}
    }
  };

  onDragOver = (evt) => {
    evt.preventDefault();
    if (evt.target && this.dragged) {
      if (evt.target.draggable) {
        this.dragged.style.display = "none";
        if (evt.target.className.includes("placeholder")) return;
        this.over = evt.target;
        evt.target.parentNode.insertBefore(placeholder, evt.target);
      }
    }
  };

  onDocumentKeyDown = (event) => {
    if (this.props.isActivePage) {
      let currentChildIdToDelete = this.state.currentSelectedChildrenId;
      let currentChildrens = [...this.state.children];
      let currentValues = { ...this.state.componentValues };
      switch (event.keyCode) {
        case DELETE_KEY:
        case BACKSPACE_KEY:
          if (
            currentChildIdToDelete &&
            currentChildIdToDelete !== "" &&
            currentValues[currentChildIdToDelete]
          ) {
            const deletedValue = currentChildrens.find(
              (x) => x.Id === currentChildIdToDelete,
            );
            if (
              deletedValue.ControlType === "image" ||
              deletedValue.ControlType === "video"
            ) {
              currentChildrens = currentChildrens.filter(
                (x) => x.Id !== currentChildIdToDelete,
              );
              delete currentValues[currentChildIdToDelete];
              this.setState({
                children: currentChildrens,
                componentValues: currentValues,
                currentSelectedChildrenId: "",
              });
              this.handleSave(currentValues);
            }
          }
          break;
        default:
          break;
      }
      return true;
    } else {
      return false;
    }
  };

  showActiveDragIcon = (id) => {
    this.setState({
      currentActiveIconId: id,
    });
  };

  hideActiveDragIcon = (id) => {
    this.setState({
      currentActiveIconId: "",
    });
  };

  handleNewPageClick = () => {
    this.setState({ currentSelectedChildrenId: "" });
    this.props.onPageChange(this.props.pageId);
  };

  componentDidMount() {
    let controlsToRender = this.getControlsToRenderFromData(
      this.props.data,
      this.props.intl,
    );
    let children = controlsToRender.map((x) => x.RenderItem);
    let componentValues = {};
    controlsToRender.forEach((x) => {
      componentValues[x.RenderItem.Id] = x.ValueItem;
    });

    this.setState({
      children: children,
      componentValues: componentValues,
    });
    document.addEventListener("keydown", this.onDocumentKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onDocumentKeyDown);
  }

  render() {
    let { classes, intl } = this.props;
    placeholder.className = classes.placeholder;

    return (
      <div className={classes.pageBox}>
        <DocumentPageHeader
          pageNumber={this.props.orderId}
          onRemoveClick={() =>
            this.props.onRemovePage(
              this.props.documentId,
              this.props.language,
              this.props.pageId,
            )
          }
          onOrderDownClick={() =>
            this.props.onOrderDownClick(
              this.props.documentId,
              this.props.language,
              this.props.pageId,
            )
          }
          onOrderUpClick={() =>
            this.props.onOrderUpClick(
              this.props.documentId,
              this.props.language,
              this.props.pageId,
            )
          }
        />
        <div
          className={classes.componentsBox}
          id="drag-container"
          onDragOver={this.onDragOver.bind(this)}
        >
          {this.state.children.map((x) => (
            <div
              className={classes.draggableDiv}
              onClick={() => this.showActiveDragIcon(x.Id)}
              onBlur={() => this.hideActiveDragIcon(x.Id)}
              data-id={x.Id}
              key={x.Id}
              draggable="true"
              onDragEnd={this.onDragEnd.bind(this)}
              onDragStart={this.onDragStart.bind(this)}
            >
              {(typeof x.RenderControl === "function" && x.RenderControl()) ||
                x.RenderControl}
              {this.state.currentActiveIconId === x.Id ? (
                <Icon iconName={Icons.menu} style={classes.dragIcon} />
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
        <Toolbar
          onTitleClick={() => this.handleAddComponentClick(CONTROL_TYPES.TITLE)}
          onSubTitleClick={() =>
            this.handleAddComponentClick(CONTROL_TYPES.SUBTITLE)
          }
          onParagraphClick={() =>
            this.handleAddComponentClick(CONTROL_TYPES.REGULAR)
          }
          onImageClick={() =>
            this.handleAddComponentClick(
              CONTROL_TYPES.IMAGE,
              intl,
              IMAGE_CONTROL_POSITION.FULLWIDTH,
            )
          }
          onMediaClick={() =>
            this.handleAddComponentClick(
              CONTROL_TYPES.VIDEO,
              intl,
              VIDEO_CONTROL_POSITION.FULLWIDTH,
            )
          }
          onLinkClick={() =>
            this.handleAddComponentClick(CONTROL_TYPES.LINK, intl)
          }
          onNewPageClick={this.props.onNewPageCreated}
          onRemoveClick={() => this.props.onPageChange("")}
          onAddClick={() => this.handleNewPageClick()}
          isEditModeVisible={this.props.isActivePage}
        />
      </div>
    );
  }
}

const mapStateToProp = (state) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  showErrorMessageHandler: (message) => {
    dispatch(SetErrorMessage(message));
  },
});

PageEditor.propTypes = {
  /** page Id  */
  pageId: PropTypes.string,
  /** page order id */
  orderId: PropTypes.number,
  /** current page data */
  data: PropTypes.string,
  /** on data changed handler */
  onDataChanged: PropTypes.func,
  /** id of current document */
  documentId: PropTypes.string,
  /** current language */
  language: PropTypes.string,
  /**  event to trigger when a new page is added */
  onNewPageCreated: PropTypes.func,
  /**  event to trigger when a new page is deleted */
  onRemovePage: PropTypes.func,
  /** handle for editing page change */
  onPageChange: PropTypes.func,
  /** is active page  */
  isActivePage: PropTypes.bool,
  /** order up page handler */
  onOrderUpClick: PropTypes.func,
  /** order down page handler */
  onOrderDownClick: PropTypes.func,
  /** Show error message handler  */
  showErrorMessageHandler: PropTypes.func,
};

const Container = injectIntl(withThemedStyle(PageEditorStyle)(PageEditor));

export default connect(mapStateToProp, mapDispatchToProps)(Container);
