import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CROP_VARIATION } from "../../../configs/constants";
import Style from "./NinjaQuestionEditor.style";
import { FormattedMessage } from "react-intl";
import { NinjaQuestionPropTypes } from "../../../constants/domain_items/ninja/ninja_question";
import NinjaQuestionChoiceEditor from "../NinjaQuestionChoiceEditor";
import AddNewContainer, {
  DIRECTION_VERTICAL,
} from "../../common/AddNewContainer";
import UploadImageButton from "../../common/UploadImageButton";
import CropImage from "../../common/CropImage";
import { Icon, Icons } from "genius-ui";
import translations from "./NinjaQuestionEditor.translations";
import ContentEditableUncontrolled from "../../common/ContentEditableUncontrolled";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import { decodeEntities } from "utils/string";

const useStyle = createUseStyles(Style);

// half of fixed height
const ADD_BUTTON_SWITCH_BREAKPOINT = 368;

const NinjaQuestionEditor = (props) => {
  const {
    id,
    title,
    image,
    description,
    choices,
    isSelected,
    onSelect,
    onImageChange,
    onDescriptionChange,
    onRemoveClick,
    addQuestionBefore,
    addQuestionAfter,
    onChangeTitle,
    addChoice,
    onRemoveChoice,
    onChangeText,
    onChangeCorrect,
    onChangeImage,
    onChangeType,
  } = props;

  const classes = useThemedStyle(useStyle, props);
  // manage crop visibility
  const [isCropVisible, setIsCropVisible] = useState(false);

  const showCropper = useCallback(
    () => setIsCropVisible(true),
    [setIsCropVisible],
  );
  const hideCropper = useCallback(
    () => setIsCropVisible(false),
    [setIsCropVisible],
  );

  // manage image
  const handleImageChange = useCallback(
    (newImage) => onImageChange(id, newImage, image),
    [onImageChange, id, image],
  );

  // manage description
  const handleDescriptionChange = useCallback(
    (newDescription) => onDescriptionChange(id, newDescription, description),
    [onDescriptionChange, id, description],
  );

  // manage adding questions
  const handleAddQuestionBefore = useCallback(() => {
    addQuestionBefore(id);
  }, [addQuestionBefore, id]);
  const handleAddQuestionAfter = useCallback(() => {
    addQuestionAfter(id);
  }, [addQuestionAfter, id]);

  // manage remove
  const handleRemoveQuestion = useCallback(() => {
    if (isSelected) {
      onRemoveClick(id);
    }
  }, [onRemoveClick, isSelected, id]);
  const handleRemoveChoice = useCallback(
    (questionIndex) => {
      onRemoveChoice(id, questionIndex);
    },
    [onRemoveChoice, id],
  );

  // manage text
  const handleChangeQuestion = useCallback(
    (e) => {
      onChangeTitle(id, decodeEntities(e));
    },
    [onChangeTitle, id],
  );
  const handleChangeChoice = useCallback(
    (value, questionIndex) => {
      onChangeText(id, questionIndex, value);
    },
    [onChangeText, id],
  );

  // manage checkbox
  const handleChangeCorrect = useCallback(
    (questionIndex) => {
      onChangeCorrect(id, questionIndex);
    },
    [onChangeCorrect, id],
  );

  // manage add choice
  const handleAddChoice = useCallback(() => {
    addChoice(id);
  }, [addChoice, id]);

  // manage image
  const handleChangeImage = useCallback(
    (url, questionIndex) => {
      onChangeImage(id, questionIndex, url.replaceAll(" ", "%20"));
    },
    [onChangeImage, id],
  );

  // manage type
  const handleChangeType = useCallback(
    (questionIndex) => {
      onChangeType(id, questionIndex);
    },
    [onChangeType, id],
  );

  // manage choices
  const choiceOptions = useMemo(() => {
    const choiceOptions = [
      ...choices?.map((choice) => ({
        isCorrect: choice.isTrue,
        id: choice.id,
        label: choice.text,
        type: choice.type,
        url: choice.url,
        originalData: choice,
      })),
    ];
    return choiceOptions;
  }, [choices]);

  return (
    <div className={classes.container} onClick={() => onSelect(id)}>
      <AddNewContainer
        className={classes.innerPageContainer}
        enabled={isSelected}
        direction={DIRECTION_VERTICAL}
        beforeAfterBreakPoint={ADD_BUTTON_SWITCH_BREAKPOINT}
        onAddBefore={handleAddQuestionBefore}
        onAddAfter={handleAddQuestionAfter}
      >
        <div className={classes.deleteButton} onClick={handleRemoveQuestion}>
          <Icon iconName={Icons.delete} />
        </div>
        <div className={classes.media}>
          <div className={classes.mediaOverlay} />
          <UploadImageButton
            id={`NinjaQuestion-${id}-image`}
            containerClass={classes.uploadImageButton}
            disabled={!isSelected}
            onUploadSuccess={handleImageChange}
          />
          <CropImage
            sourceImage={image}
            isCropVisible={isCropVisible}
            cropSize={CROP_VARIATION.NINJA}
            onCropClick={showCropper}
            onCancel={hideCropper}
            onCropCompleted={handleImageChange}
            className={classes.cropImageButton}
            disabled={!isSelected}
          />
        </div>
        <div className={classes.spacer} />
        <div className={classes.questionContainer}>
          <FormattedMessage {...translations.NinjaEmptyQuestionTitle}>
            {(placeHolder) => (
              <ContentEditableUncontrolled
                key={title || "title"}
                initialValue={title}
                disabled={!isSelected}
                onChange={handleChangeQuestion}
                tagName="article"
                placeholder={placeHolder[0]}
                className={classes.questionInput}
              />
            )}
          </FormattedMessage>
        </div>
        <div className={classes.descriptionContainer}>
          <FormattedMessage {...translations.NinjaEmptyDescriptionTitle}>
            {(placeHolder) => (
              <ContentEditableUncontrolled
                key={description || "description"}
                initialValue={description}
                disabled={!isSelected}
                onChange={handleDescriptionChange}
                tagName="article"
                placeholder={placeHolder[0]}
                className={classes.descriptionInput}
              />
            )}
          </FormattedMessage>
        </div>
        <div className={classes.spacer} />
        <div>
          {!!choiceOptions.length && (
            <div className={classes.radioHeaderContainer}>
              <div
                className={classNames([
                  classes.radioHeaderLabel,
                  classes.correctLabel,
                ])}
              >
                <FormattedMessage {...translations.CorrectColumnLabel} />
              </div>
              <div
                className={classNames([
                  classes.radioHeaderLabel,
                  classes.choiceTypeLabel,
                ])}
              >
                <FormattedMessage {...translations.ChoiceTextColumnLabel} />
              </div>
            </div>
          )}
          <div className={classes.radioContainer}>
            {choiceOptions.map((option) => (
              <NinjaQuestionChoiceEditor
                key={option.id}
                option={option}
                isCorrect={option.isCorrect}
                onChangeCorrect={handleChangeCorrect}
                onRemove={handleRemoveChoice}
                onChangeText={handleChangeChoice}
                onChangeImage={handleChangeImage}
                onChangeType={handleChangeType}
              />
            ))}
            <div className={classes.addChoice} onClick={handleAddChoice}>
              <FormattedMessage {...translations.AddAChoiceButtonLabel} />
            </div>
          </div>
        </div>
      </AddNewContainer>
    </div>
  );
};

NinjaQuestionEditor.propTypes = {
  ...NinjaQuestionPropTypes,
  /** if selected, the question is editable: "template selection"- and "question add"- buttons, the inputs are enabled
   * and question is highlighted with a border **/
  isSelected: PropTypes.bool,
  /** func(questionId): function executed when a question is selected **/
  onSelect: PropTypes.func,
  /** func(questionId, newType, oldType): function executed when an option inside the type selector is clicked **/
  onTypeChange: PropTypes.func,
  /** func(questionId, newTitle, oldTitle): function executed when the title input is blurred **/
  onTitleChange: PropTypes.func,
  /** func(questionId, newDescription, oldDescription): function executed when the description input is blurred **/
  onDescriptionChange: PropTypes.func,
  /** func(questionId, newImage, oldImage): function executed when the image is changed **/
  onImageChange: PropTypes.func,
  /** func(questionId, newChoices, oldChoices): function executed when the choices are changed **/
  onChoicesChange: PropTypes.func,
  /** func(questionId): function executed when the "Add question before" button is clicked **/
  onAddQuestionBefore: PropTypes.func,
  /** func(questionId): function executed when the "Add question after" button is clicked **/
  onAddQuestionAfter: PropTypes.func,
  /** func(questionId): function executed when the "Remove question" button is clicked **/
  onRemove: PropTypes.func,
};

export default NinjaQuestionEditor;
