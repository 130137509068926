import React, { useCallback } from "react";
import { ContentNotHandledStyle } from "./ContentNotHandle.style";
import PropTypes from "prop-types";
import DocumentPageHeader from "../DocumentPageHeader";
import { FormattedMessage } from "react-intl";
import { translations } from "./ContentNotHandled.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(ContentNotHandledStyle);

const ContentNotHandled = (props) => {
  const classes = useThemedStyle(useStyle, props);

  const onRemoveClick = useCallback(() => {
    props.onRemovePage(props.documentId, props.language, props.pageId);
  }, [props]);

  return (
    <div className={classes.pageBox}>
      <DocumentPageHeader
        pageNumber={props.orderId}
        onRemoveClick={onRemoveClick}
      />
      <div className={classes.containerMessage}>
        <span>
          <FormattedMessage {...translations.ContentNotHandledText} />
        </span>
      </div>
    </div>
  );
};

ContentNotHandled.propTypes = {
  /** pageId **/
  pageId: PropTypes.number,
  /** id of current document */
  documentId: PropTypes.string,
  /** current language */
  language: PropTypes.string,
  /**  content type */
  contentType: PropTypes.string,
  /** page order id */
  orderId: PropTypes.number,
  /** remove function */
  onRemovePage: PropTypes.func,
};

export default ContentNotHandled;
