import { defineMessages } from "react-intl";

export const translations = defineMessages({
  SelectFileButtonText: {
    id: "DataTable.SelectFileButtonText",
    defaultMessage: "Select file",
  },
  SaveFilesButtonText: {
    id: "DataTable.SaveFilesButtonText",
    defaultMessage: "Save files",
  },
  NoFileSelectedMessage: {
    id: "DataTable.NoFileSelectedMessage",
    defaultMessage: "No selected file",
  },
});
