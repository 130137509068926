export default (theme) => ({
  modalContent: {
    padding: "15px 20px 0 20px",
    zIndex: 99999,
    width: 686,
    minHeight: 338,
    backgroundColor: theme.CloneModal.modalContentBackgroundColor,
    borderRadius: 4,
    fontFamily: theme.Fonts.baseFontFamily,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 0.07,
    "& .icon-slim-close-icon": {
      cursor: "pointer",
    },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 32,
  },
  title: {
    fontSize: 22,
    fontWeight: "bold",
    color: theme.CloneModal.titleColor,
  },
  messageLabel: {
    fontSize: 17,
    fontWeight: "300",
    letterSpacing: 0.06,
    color: theme.CloneModal.messageLabelColor,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 226,
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  cancelBtn: {
    marginRight: 30,
  },
});
