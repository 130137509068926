import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { createUseStyles } from "react-jss";
import Style from "./SelectedFilterValues.style";
import { Tag } from "genius-ui";
import { FormattedMessage } from "react-intl";
import translations from "./SelectedFilterValues.translations";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const SelectedFilterValues = (props) => {
  const { filterValues, onRemove, onClearAll, className, isTrainer } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classNames([classes.container, className])}>
      {filterValues.map((filterValue) => (
        <Tag
          key={filterValue.value}
          label={filterValue.label}
          variation="blue"
          handleRemoveClick={() => onRemove && onRemove(filterValue)}
          readOnly={isTrainer && filterValue.filter.name === "MARKET"}
        />
      ))}
      {filterValues.length - (isTrainer ? 1 : 0) ? (
        <Tag
          key="__clear-all-filters"
          label={<FormattedMessage {...translations.ClearAllFiltersLabel} />}
          variation="gray"
          handleRemoveClick={onClearAll}
        />
      ) : null}
    </div>
  );
};

SelectedFilterValues.propTypes = {
  filterValues: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.node,
    }),
  ).isRequired,
  /** func(filterValue): executed when clicking on the remove button of a filter value **/
  onRemove: PropTypes.func,
  /** func(): executed when clicking on the 'Clear all' button **/
  onClearAll: PropTypes.func,
  /** provide custom style **/
  className: PropTypes.string,
};

export default SelectedFilterValues;
