import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Icon, Icons } from "genius-ui";
import { InfoTooltipStyle } from "./InfoTooltip.style";
import withThemedStyle from "../../hoc/withThemedStyle";

const InfoTooltip = ({ classes, title, children }) => {
  const [detailsIsVisible, setDetailsVisibility] = useState(false);
  const changeDetailsVisibility = () => setDetailsVisibility(!detailsIsVisible);
  const hideDetails = () => setDetailsVisibility(false);
  const ref = useRef();

  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      hideDetails();
    };

    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, []);

  return (
    <div ref={ref} className={classes.rootContainer}>
      <Icon
        iconName={Icons.info}
        style={classes.infoIcon}
        handleClick={changeDetailsVisibility}
      />
      {detailsIsVisible && (
        <div className={classes.details}>
          {title && <div className={classes.title}>{title}</div>}
          {children}
        </div>
      )}
    </div>
  );
};

InfoTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default withThemedStyle(InfoTooltipStyle)(InfoTooltip);
