const contentEditablePlaceholderStyle = (color) => ({
  "&[contenteditable=true]:empty:before": {
    content: "attr(placeholder)",
    display: "block",
    color: color,
  },
});

export const PublishLessonStyle = ({ PublishLesson, FontFamily }) => ({
  rootContainer: {
    paddingTop: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  center: {
    position: "relative",
    width: 952,
    display: "flex",
    justifyContent: "space-between",
  },
  left: {
    width: 900,
    display: "flex",
    flexDirection: "column",
  },
  right: {
    right: 0,
    top: 0,
    width: 20,
  },
  textInputTitle: {
    border: 0,
    outline: 0,
    background: PublishLesson.textInputTitleBackground,
    marginLeft: 50,
    marginRight: 50,
    fontFamily: FontFamily,
    fontSize: "22px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    color: PublishLesson.textInputTitleColor,
    ...contentEditablePlaceholderStyle(
      PublishLesson.contentEditablePlaceholderColor,
    ),
  },
  textInputSummary: {
    border: 0,
    outline: 0,
    background: PublishLesson.textInputSummaryBackground,
    marginLeft: 50,
    marginRight: 50,
    fontFamily: FontFamily,
    fontSize: "17px",
    fontWeight: "300",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    color: PublishLesson.textInputSummaryColor,
    ...contentEditablePlaceholderStyle(
      PublishLesson.contentEditablePlaceholderColor,
    ),
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 50,
    width: "100%",
  },
  leftPart: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
  },
  rightPart: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  firstTitle: {
    fontFamily: FontFamily,
    fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: PublishLesson.firstTitleColor,
  },
  sectionTitle: {
    fontFamily: FontFamily,
    fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: PublishLesson.sectionTitleColor,
    paddingTop: 30,
    marginBottom: 10,
  },
  postingDate: {
    display: "flex",
    width: "100%",
    marginTop: 45,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  postingDateButton: {
    paddingRight: 10,
  },
  sectionUserCategory: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    "& > *": {
      paddingRight: 20,
    },
  },
  closeIcon: {
    right: 0,
    top: 0,
    height: "fit-content",
    "&::before": {
      fontSize: 15,
      cursor: "pointer",
    },
    "&:hover::before": {
      color: PublishLesson.closeIconHoverColor,
      fontSize: 15,
      cursor: "pointer",
    },
  },
  tagSelectSection: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 10,
  },
  tagSelection: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      marginLeft: 0,
    },
  },
  autoSearchBox: {
    width: 300,
    zIndex: 99999,
    "& > *": {
      zIndex: 99999,
    },
  },
  tagSelectContainer: {
    position: "relative",
  },
  tagSelectContainerError: {
    position: "relative",
    "& > div": {
      border: "1px solid #c60000",
      borderRadius: 2,
    },
  },
  tagList: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  rememberContainer: {
    display: "flex",
    flexDirection: "row",
    height: 120,
  },
  publicLabel: {
    fontFamily: FontFamily,
    fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: PublishLesson.publicLabelColor,
    marginRight: 10,
  },
  langPublicContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 10,
  },
  publicSwitcherContainer: {
    display: "flex",
    flexDirection: "row",
  },
  brandTagsContainer: {
    width: "100%",
    marginRight: 20,
  },
  retailerTagsContainer: {
    width: "100%",
  },
  brandAndRetailer: {
    display: "flex",
    flexDirection: "row",
  },
  recapCheckboxContainer: {
    marginTop: 30,
    "& > div > label": {
      fontSize: 16,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: PublishLesson.recapCheckboxContainerColor,
    },
    "& > div > input": {
      width: 22,
    },
  },
  leftTagsContainer: {
    width: "100%",
    marginRight: 20,
  },
  rightTagsContainer: {
    width: "100%",
  },
  multipleSelectTagsContainer: {
    display: "flex",
    flexDirection: "row",
  },
  attachments: {
    marginTop: 30,
  },
});
