import { userFactoryTranslations as translations } from "./UserProfile.translations";

export const createBadgeArray = (badges, intl) => {
  return [
    [
      intl.formatMessage(translations.BadgeName),
      intl.formatMessage(translations.BadgeCompletedPoints),
      intl.formatMessage(translations.BadgeTotalPoints),
      intl.formatMessage(translations.BadgeName),
      intl.formatMessage(translations.BadgeStatus),
      intl.formatMessage(translations.BadgeScore),
    ],
  ].concat(
    concatProgramsArray(
      badges.map((element) => {
        return [
          [
            element.name,
            element.completedPoints,
            element.totalPoints,
            "",
            "",
            "",
          ],
        ].concat(
          element.badgeList.map((badgeElement) => {
            return [
              "",
              "",
              "",
              badgeElement.name,
              badgeElement.status,
              badgeElement.score,
            ];
          }),
        );
      }),
    ),
  );
};

export const createUserArray = (user, points, intl) => {
  return [
    [intl.formatMessage(translations.UserName), user.name],
    [intl.formatMessage(translations.UserPosition), user.position],
    [intl.formatMessage(translations.UserEmail), user.email],
    [intl.formatMessage(translations.UserLocation), user.location],
    [intl.formatMessage(translations.UserLocationUrl), user.locationUrl],
    [intl.formatMessage(translations.UserBirthday), user.birthday],
    [intl.formatMessage(translations.UserPointOfSale), user.pointOfSale],
    [],
    [intl.formatMessage(translations.UserTotalPoints), points],
  ];
};

export const createPendingArray = (pending, intl) => {
  if (pending.length > 0) {
    return pending.map((current) => {
      return [
        [intl.formatMessage(translations.Questioner), current.questioner],
        [intl.formatMessage(translations.Question), current.question],
      ];
    });
  } else {
    return [[intl.formatMessage(translations.NoQuestionYet)]];
  }
};

export const createActivityArray = (activities, intl) => {
  return [
    [intl.formatMessage(translations.ActivitiesTitle)],
    [intl.formatMessage(translations.QuestionAsked), activities.questionAsked],
    [
      intl.formatMessage(translations.QuestionAskedToExperts),
      activities.questionAskedToExpert,
    ],
    [
      intl.formatMessage(translations.LessonFinished),
      activities.lessonFinished,
    ],
    [intl.formatMessage(translations.LessonStarted), activities.lessonStarted],
  ];
};

export const createLearningArray = (programs, intl) => {
  return [
    [intl.formatMessage(translations.LearningTitle)],
    [
      intl.formatMessage(translations.ProgramName),
      intl.formatMessage(translations.ProgressionPercentage),
      intl.formatMessage(translations.Completed),
      intl.formatMessage(translations.TotalNumberOfLessons),
      intl.formatMessage(translations.LatestUpdate),
      intl.formatMessage(translations.Points),
      intl.formatMessage(translations.Lesson),
      intl.formatMessage(translations.ProgressionPercentage),
      intl.formatMessage(translations.Completed),
      intl.formatMessage(translations.TotalNumberOfPages),
      intl.formatMessage(translations.LatestUpdate),
      intl.formatMessage(translations.Points),
    ],
  ].concat(
    concatProgramsArray(
      programs.map((element) => {
        return [
          [
            element.name,
            (element.completedLessons * 100) / element.allLessons + "%",
            element.completedLessons,
            element.allLessons,
            element.latestUpdate,
            element.points,
            "",
            "",
            "",
            "",
            "",
            "",
          ],
        ].concat(
          element.lessons.map((lessonElement) => {
            return [
              "",
              "",
              "",
              "",
              "",
              "",
              lessonElement.name,
              (lessonElement.completedPages * 100) / lessonElement.pages + "%",
              lessonElement.completedPages,
              lessonElement.pages,
              lessonElement.latestUpdate,
              lessonElement.points,
            ];
          }),
        );
      }),
    ),
  );
};

const concatProgramsArray = (array) => {
  var concattedArray = array[0];
  for (let i = 1; i < array.length; i++) {
    for (let j = 0; j < array[i].length; j++) {
      concattedArray.push(array[i][j]);
    }
  }
  return concattedArray;
};
