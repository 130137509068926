import { defineMessages } from "react-intl";

export const translations = defineMessages({
  PollTitlePlaceholder: {
    id: "PublishMasterPoll.PollTitlePlaceholder",
    defaultMessage: "Enter your title",
  },
  UploadImage: {
    id: "PublishMasterPoll.UploadImage",
    defaultMessage: "Upload Image",
  },
  PollDescriptionPlaceholder: {
    id: "PublishMasterPoll.PollDescriptionPlaceholder",
    defaultMessage: "Describe your poll",
  },
  AddCountry: {
    id: "PublishMasterPoll.AddCountry",
    defaultMessage: "Add a country",
  },
  PublicationCountries: {
    id: "PublishMasterPoll.PublicationCountries",
    defaultMessage: "Publication Countries",
  },
  AssociatedTags: {
    id: "PublishMasterPoll.AssociatedTags",
    defaultMessage: "Associated Tags",
  },
  AddTag: {
    id: "PublishMasterPoll.AddTag",
    defaultMessage: "Add a tag",
  },
  Language: {
    id: "PublishMasterPoll.Language",
    defaultMessage: "Language",
  },
  StartPostingDateLabel: {
    id: "PublishMasterPoll.StartPostingDateLabel",
    defaultMessage: "Start posting date",
  },
  EndPostingDateLabel: {
    id: "PublishMasterPoll.EndPostingDateLabel",
    defaultMessage: "End posting date",
  },
  NoEndPostingDateLabel: {
    id: "PublishMasterPoll.NoEndPostingDateLabel",
    defaultMessage: "No end posting date",
  },
  BrandLabel: {
    id: "PublishMasterPoll.BrandLabel",
    defaultMessage: "Brand",
  },
  AddBrandPlaceholder: {
    id: "PublishMasterPoll.AddBrandPlaceholder",
    defaultMessage: "Add a brand",
  },
  RetailerLabel: {
    id: "PublishMasterPoll.RetailerLabel",
    defaultMessage: "Retailer",
  },
  AddRetailerPlaceholder: {
    id: "PublishMasterPoll.AddRetailerPlaceholder",
    defaultMessage: "Add a retailer",
  },
  IndicateBrandsWarning: {
    id: "PublishMasterPoll.IndicateBrandsWarning",
    defaultMessage:
      "Please indicate which brand or brands this content should be associated with before publishing.",
  },
});
