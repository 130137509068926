const globalPadding = 30;
const globalWidth = 1280 + 2 * globalPadding;

export default (theme) => ({
  headerContainer: {
    minHeight: 60,
    backgroundColor: theme.DefaultFilterHeader.containerBackground,
    display: "flex",
    zIndex: 1,
    boxSizing: "border-box",
    boxShadow: `0 2px 20px 0 ${theme.DefaultFilterHeader.boxShadowColor}`,
  },
  header: {
    width: "100%",
  },
  filterContainer: {
    maxWidth: globalWidth,
    padding: `10px ${globalPadding}px 0 ${globalPadding}px`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "auto",
  },
  leftSide: {
    display: "flex",
    alignItems: "center",
  },
  backButton: {
    marginRight: 30,
    cursor: "pointer",
    fontSize: 18,
  },
});
