import { USER_PROFILE_KPI_DEFAULT_SHISEIDO } from "./UserProfileKPIShiseido.graphql";
import { USER_PROFILE_KPI_DEFAULT } from "./UserProfileKPI.DEFAULT.graphql";
import { isShiseido, isDior } from "../../../../../configs/client";

let UserProfileKPIFragment = USER_PROFILE_KPI_DEFAULT;
if (isShiseido) {
  UserProfileKPIFragment = USER_PROFILE_KPI_DEFAULT_SHISEIDO;
}

export { UserProfileKPIFragment };
