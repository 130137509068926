import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { sizePropType, positionPropType } from "../../../utils/customPropTypes";
import HoledMask from "../HoledMask";
import Container, { ContainerUnconnected } from "../../DragAndDrop/Container";
import CustomDragLayer from "../../DragAndDrop/CustomDragLayer";

const DragAndDropLayer = ({
  size,
  safeAreaSize,
  safeAreaPosition,
  elements,
  renderElement,
  setElementPosition,
  validateElementPosition,
  coverUnsafeArea,
  getDraggedElementContainerProps,
  isActive,
  ...restProps
}) => {
  const dragLayerStyle = useMemo(
    () => ({
      position: "absolute",
      top: safeAreaPosition.y,
      left: safeAreaPosition.x,
      right: safeAreaPosition.x + safeAreaSize.width,
      bottom: safeAreaPosition.y + safeAreaSize.height,
    }),
    [safeAreaPosition, safeAreaSize],
  );

  const ContainerComponent = isActive ? Container : ContainerUnconnected;

  return (
    <div {...restProps}>
      {coverUnsafeArea && (
        <HoledMask
          size={size}
          holeSize={safeAreaSize}
          holePosition={safeAreaPosition}
        />
      )}
      <div style={dragLayerStyle}>
        <ContainerComponent
          elements={elements}
          setElementPosition={setElementPosition}
          renderDraggedElement={(element, index) =>
            renderElement(element, undefined, undefined, `${index}`)
          }
          validateElementPosition={validateElementPosition}
          containerSize={safeAreaSize}
          getDraggedElementContainerProps={getDraggedElementContainerProps}
        />
        <CustomDragLayer
          renderDraggedElement={renderElement}
          validateElementPosition={validateElementPosition}
        />
      </div>
    </div>
  );
};

DragAndDropLayer.propTypes = {
  /** Parameters of the draggable elements **/
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      /** Unique identifier of the draggable element **/
      id: PropTypes.any.isRequired,
      /** Left position of the draggable element relative to the top-left corner of the safe-area **/
      x: PropTypes.number.isRequired,
      /** Top position of the draggable element relative to the top-left corner of the safe-area **/
      y: PropTypes.number.isRequired,
      /** Whether the element can be dragged or not **/
      isStatic: PropTypes.bool,
    }),
  ).isRequired,
  /** Function which should create & return a React.Element for a single dragged element **/
  renderElement: PropTypes.func.isRequired,
  /** Function, that can be used to pass extra props to the container of each dragged element, because we're not able to
   * pass extra props through the renderElement func(renderElement is called inside the container) **/
  getDraggedElementContainerProps: PropTypes.func,
  /** Function through which we control the position of the elements. Called when an element is dragged. **/
  setElementPosition: PropTypes.func.isRequired,
  /** Function used to check if an element's current position is valid. This value is used when deciding whether the
   * dragged element can be dropped on a certain position, or not **/
  validateElementPosition: PropTypes.func,
  /** Drag & Drop layer size **/
  size: sizePropType.isRequired,
  /** Size of the area in which the elements can be safely dragged **/
  safeAreaSize: sizePropType.isRequired,
  /** Position of the area in which the elements can be safely dragged. It is the relative position to the top left
   * corner of the drag & drop layer **/
  safeAreaPosition: positionPropType.isRequired,
  /** Whether to put a mask or not over the area over which dragging is not allowed **/
  coverUnsafeArea: PropTypes.bool,
  /** turn on & off drag & drop events and capturing of these events **/
  isActive: PropTypes.bool,
  /** Rest of the props is passed to the drag layer container, which is a ReactDom.div **/
};

DragAndDropLayer.defaultProps = {
  coverUnsafeArea: true,
};

export default DragAndDropLayer;
