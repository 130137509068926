import React from "react";
import DefaultFilters from "./DefaultFilters";
import { REACT_APP_CLIENT_NAME } from "../../../configs/client";

const Filters = () => {
  switch (REACT_APP_CLIENT_NAME) {
    default:
      return <DefaultFilters />;
  }
};

export default Filters;
