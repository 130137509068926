import { RESET_STORE } from "../../constants/store/auth";
import {
  CLEAR_CITIES_TAGS,
  CLEAR_COUNTRY_TAGS,
  CLEAR_RETAILER_TAGS,
  CLEAR_SHOPS_TAGS,
  REMOVE_FILTER_SELECTED_VALUES,
  RESET_FILTERS,
  UPDATE_FILTER_SELECTED_VALUES,
  UPDATE_FILTER_ORDER_BY,
  UPDATE_FILTER_SKIP,
  UPDATE_INTERVALS,
  UPDATE_SELECTED_INTERVAL,
  SET_IS_LOADING,
  SET_IS_EXPORT_LOADING,
} from "../../constants/store/kpiFilters";
import { KPI_AVAILABLE_PERIODS } from "../../configs/KPI/constants";
import { addMonth } from "../../utils/dateUtils";

const today = new Date();

const initialState = {
  isLoading: false,
  isExportLoading: false,
  availablePeriods: KPI_AVAILABLE_PERIODS,
  startDate: {
    selectedValues: addMonth(today, -3),
  },
  endDate: {
    selectedValues: new Date(),
  },
  period: {
    selectedValues: [KPI_AVAILABLE_PERIODS[3]],
  },
  interval: {
    selectedValues: [],
  },
  sortBy: undefined,
  sortOrder: undefined,
  skip: 0,
  intervals: [],
  selectedInterval: { value: 11, label: "Last 3 months" },
  zone: {
    selectedValues: [],
  },
  market: {
    selectedValues: [],
  },
  relationship: {
    selectedValues: [],
  },
  retailer: {
    selectedValues: [],
  },
  roles: {
    selectedValues: [],
  },
  countries: {
    selectedValues: [],
  },
  cities: {
    selectedValues: [],
  },
  shops: {
    selectedValues: [],
  },
  brands: {
    selectedValues: [],
  },
  division: {
    selectedValues: [],
  },
  entity: {
    selectedValues: [],
  },
  department: {
    selectedValues: [],
  },
  service: {
    selectedValues: [],
  },
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: data,
      };
    case SET_IS_EXPORT_LOADING:
      return {
        ...state,
        isExportLoading: data,
      };
    case UPDATE_FILTER_SELECTED_VALUES:
      return {
        ...state,
        [data.filterName]: {
          ...state[data.filterName],
          selectedValues: data.selectedValues,
        },
      };
    case UPDATE_FILTER_ORDER_BY:
      return {
        ...state,
        ...data,
      };
    case UPDATE_FILTER_SKIP:
      return {
        ...state,
        skip: data,
      };
    case UPDATE_INTERVALS:
      return {
        ...state,
        intervals: data,
      };
    case UPDATE_SELECTED_INTERVAL:
      return {
        ...state,
        selectedInterval: data,
      };
    case REMOVE_FILTER_SELECTED_VALUES:
      return {
        ...state,
        [data.filterName]: {
          ...state[data.filterName],
          selectedValues: state[data.filterName].selectedValues.filter(
            (selectedValue) => selectedValue !== data.removedValue,
          ),
        },
      };
    case CLEAR_COUNTRY_TAGS:
      return {
        ...state,
        countries: initialState.countries,
      };
    case CLEAR_CITIES_TAGS:
      return {
        ...state,
        cities: initialState.cities,
      };
    case CLEAR_SHOPS_TAGS:
      return {
        ...state,
        shops: initialState.shops,
      };
    case CLEAR_RETAILER_TAGS:
      return {
        ...state,
        retailer: initialState.retailer,
      };
    case RESET_FILTERS:
      return {
        ...state,
        period: initialState.period,
        sortBy: initialState.sortBy,
        sortOrder: initialState.sortOrder,
        skip: initialState.skip,
        zone: initialState.zone,
        market: initialState.market,
        relationship: initialState.relationship,
        retailer: initialState.retailer,
        countries: initialState.countries,
        roles: initialState.roles,
        cities: initialState.cities,
        shops: initialState.shops,
        brands: initialState.brands,
        division: initialState.division,
        entity: initialState.entity,
        department: initialState.department,
        service: initialState.department,
      };
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
