import { defineMessages } from "react-intl";

export const translations = defineMessages({
  AllContentOption: {
    id: "ContentManagerMarket.AllContentOption",
    defaultMessage: "All Content",
  },
  AllMarketOptions: {
    id: "ContentManagerMarket.AllMarketOptions",
    defaultMessage: "All Markets",
  },
  MarketFilterPlaceholder: {
    id: "ContentManagerMarket.MarketFilterPlaceholder",
    defaultMessage: "Market",
  },
  NewContentText: {
    id: "ContentManagerMarket.NewContentText",
    defaultMessage: "New Content",
  },
  InsightDuplicationMessage: {
    id: "ContentManagerMarket.InsightDuplicationMessage",
    defaultMessage:
      "Your insight has been successfully duplicated for the language {lang}",
  },
  CardDuplicationMessage: {
    id: "ContentManagerMarket.CardDuplicationMessage",
    defaultMessage:
      "Your card has been successfully duplicated for the language {lang}",
  },
  BattleDuplicationMessage: {
    id: "ContentManagerMarket.BattleDuplicationMessage",
    defaultMessage:
      "Your battle has been successfully duplicated for the language {lang}",
  },
  PollDuplicationMessage: {
    id: "ContentManagerMarket.PollDuplicationMessage",
    defaultMessage:
      "Your poll has been successfully duplicated for the language {lang}",
  },
  MarketMissingWarning: {
    id: "ContentManagerMarket.MarketMissingWarning",
    defaultMessage: "Please select a market before creating a document",
  },
  ChooseLanguageModalHeader: {
    id: "ContentManagerMarket.ChooseLanguageModalHeader",
    defaultMessage: "Select a language",
  },
  ChooseLanguageModalButton: {
    id: "ContentManagerMarket.ChooseLanguageModalButton",
    defaultMessage: "Next",
  },
});
