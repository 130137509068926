import gql from "graphql-tag";

export const EDIT_POLL_PAGE = gql`
  mutation editPollPage(
    $pollId: ID
    $lang: LangEnum
    $pageId: ID
    $data: String!
  ) {
    admin {
      poll(pollId: $pollId, lang: $lang) {
        pages(pageId: $pageId) {
          data(data: $data)
        }
      }
    }
  }
`;
