import * as constants from "../../constants/store/lesson";
import client from "../../graphql/client";
import { SetErrorMessage } from "../notification/actions";
import { LESSON_GET_BY_ID } from "../../graphql/remote/lessons/queries";
import { ENV, OBJECT_ACTION_ENUM } from "../../configs/constants";
import { UPDATE_LOCATIONS } from "../../graphql/remote/lessons/mutations";
import { formatPublishLessonData } from "./transformers";
import { ADD_ATTACHMENT } from "../../graphql/remote/lessons/mutations/AddAttachment.graphql";
import { REMOVE_ATTACHMENT } from "../../graphql/remote/lessons/mutations/RemoveAttachment.graphql";
import clientConfig from "../../configs/client";
import {
  GET_MARKET_BRAND_BADGES,
  GET_LESSON_BADGES,
} from "../../graphql/remote/badges/queries/GetMarketBrandBadges.graphql";
import {
  REMOVE_DEGREE_BADGES,
  ADD_DEGREE_ACTIVITY_BADGES,
  ADD_DEGREE_READ_BADGES,
} from "../../graphql/remote/degrees/mutations";
import {
  formatBadgesToMutate,
  getLessonsToRemove,
  getLessonsToAdd,
} from "../../utils/store/badges";
import { GET_LESSON_PAGINATED } from "./graphs";

export const UpdatePublishLessonData = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_PUBLISH_LESSON_DATA,
    data: data,
  });
};

export const UpdateLessonPageData = (pageId, pageData) => (dispatch) => {
  dispatch({
    type: constants.SET_LESSON_PAGE_DATA,
    data: { pageId, pageData },
  });
};

export const RealUpdatePublishLessonData = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_PUBLISH_LESSON_DATA,
    data: data,
  });
};

export const GetLessonData =
  (lessonId, currentLang, callback) => async (dispatch, getState) => {
    try {
      const { lessonLocationsSkip } = getState().lesson;
      const response = await client.query({
        fetchPolicy: "network-only",
        query: LESSON_GET_BY_ID,
        variables: { lessonId },
      });

      let locations = null;
      const lesson = response.data.admin.lessons[0].versions.find(
        (x) => x.lang === currentLang,
      );

      if (
        lesson.tags.findIndex(
          (x) => parseInt(x.tagId) === clientConfig.MASTER_DEGREE_TAG_ID,
        ) >= 0
      ) {
        dispatch(SetLessonMasterFlag(true));
      } else {
        dispatch(SetLessonMasterFlag(false));
      }

      if (lesson) {
        const formattedLessonDataToDisplay = formatPublishLessonData(
          response,
          lesson,
          lessonId,
          currentLang,
          locations,
        );
        dispatch(UpdatePublishLessonData(formattedLessonDataToDisplay));
        callback && callback(formattedLessonDataToDisplay);
      }
    } catch (e) {
      dispatch(
        SetErrorMessage("An error occurred while fetching the lesson data", e),
      );
    }
  };

export const SetSelectedShop = (data) => (dispatch) =>
  dispatch({
    type: constants.SET_SELECTED_SHOPS,
    data,
  });

export const SetLessonMasterFlag = (data) => (dispatch) =>
  dispatch({
    type: constants.SET_LESSON_MASTER_FLAG,
    data,
  });

export const SetPublishedLessonIsProgramQuiz =
  (isProgramQuiz) => (dispatch) => {
    dispatch({
      type: constants.UPDATE_PUBLISH_LESSON_DATA,
      data: {
        isProgramQuiz,
      },
    });
  };

export const DeleteLocations =
  (deleteLocationIds, onSuccess) => async (dispatch, getState) => {
    const { publishLessonData } = getState().lesson;
    const { id, lang } = publishLessonData;
    try {
      await client.mutate({
        mutation: UPDATE_LOCATIONS,
        variables: {
          lessonId: id,
          lang: lang,
          sens: OBJECT_ACTION_ENUM.REMOVE,
          locationIds: deleteLocationIds,
        },
        update: () => {
          onSuccess && onSuccess();
        },
      });
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while deleting the lesson's locations",
          e,
        ),
      );
    }
  };

export const AddLocations =
  (newLocations, callback) => async (dispatch, getState) => {
    if (!newLocations.length) {
      callback && callback();
      return;
    }
    const { publishLessonData } = getState().lesson;
    const { id, lang } = publishLessonData;
    try {
      await client.mutate({
        mutation: UPDATE_LOCATIONS,
        variables: {
          lessonId: id,
          lang: lang,
          sens: OBJECT_ACTION_ENUM.SET,
          locationIds: newLocations.map((location) => location.id),
        },
        update: () => {
          callback && callback();
        },
      });
    } catch (e) {
      dispatch(
        SetErrorMessage("An error occurred while adding lesson's locations", e),
      );
    }
  };

export const AddAttachments =
  (files, callback) => async (dispatch, getState) => {
    const { publishLessonData } = getState().lesson;
    const { id, lang } = publishLessonData;
    try {
      await files.map(async (file) => {
        await client.mutate({
          mutation: ADD_ATTACHMENT,
          variables: {
            lessonId: id,
            lang,
            link: file.url,
            name: file.name,
            type: file.type,
          },
        });
      });
      callback && callback();
    } catch (e) {
      dispatch(
        SetErrorMessage("An error occurred while adding attachment(s)", e),
      );
    }
  };

export const RemoveAttachments = (uploadId) => async (dispatch, getState) => {
  const { publishLessonData } = getState().lesson;
  const { id, lang } = publishLessonData;
  try {
    await client.mutate({
      mutation: REMOVE_ATTACHMENT,
      variables: {
        lessonId: id,
        lang,
        uploadId,
      },
    });
  } catch (e) {
    dispatch(SetErrorMessage("An error occurred while removing attachment", e));
  }
};

export const UpdateLessonLocationsLimit = () => (dispatch) => {
  dispatch({ type: constants.UPDATE_LESSON_LOCATIONS_LIMIT });
};

export const ResetLessonStore = () => (dispatch) => {
  dispatch({ type: constants.RESET_LESSON_STORE });
};

const formatBadgesByType = (lessonBadges, type, badgeId) => {
  if (!lessonBadges) return [];

  const lessons = [];
  Object.keys(lessonBadges).forEach((key) => {
    if (lessonBadges[key][type].includes(Number(badgeId))) {
      lessons.push(Number(key));
    }
  });
  return lessons;
};

const getFormatedDegreeBadges = (badges, lessonBadges) =>
  badges.map((badge) => ({
    badgeId: parseInt(badge.badgeId),
    badgeImage: badge.pic,
    badgeName: badge.title,
    lessonIds: formatBadgesByType(lessonBadges, "read", badge.badgeId),
    quizz: formatBadgesByType(lessonBadges, "activity", badge.badgeId),
  }));

const formatBadgesLessons = (lessons) =>
  lessons.reduce((acc, { lessonId, badges }) => {
    acc[lessonId] = {
      activity: badges
        .filter(({ audit }) => audit === "activity")
        .map(({ badgeId }) => Number(badgeId)),
      read: badges
        .filter(({ audit }) => audit === "read")
        .map(({ badgeId }) => Number(badgeId)),
    };
    return acc;
  }, {});

const getBadgesLessons = (ids) => async (dispatch) => {
  try {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_LESSON_BADGES,
      variables: { lessonIds: ids },
    });
    dispatch({
      type: constants.SET_LESSON_BADGES,
      data: formatBadgesLessons(result.data.admin.lessons),
    });
  } catch (e) {
    console.error("error lesson/actions.js getBadgesLessons", e);
  }
};

export const GetLessonBadges =
  (language, callback) => async (dispatch, getState) => {
    const { brands, countries, id, tags, picThumb, title, summary, workflow } =
      getState().lesson.publishLessonData;
    try {
      const result = await client.query({
        fetchPolicy: "network-only",
        query: GET_MARKET_BRAND_BADGES,
        variables: {
          tagIds: [
            ...brands.map(({ Id }) => Number(Id)),
            ...countries.map(({ Id }) => Number(Id)),
          ].concat(
            tags.find(
              ({ Id }) => Number(Id) === clientConfig.MASTER_DEGREE_TAG_ID,
            )
              ? [clientConfig.MASTER_DEGREE_TAG_ID]
              : [],
          ),
        },
      });

      await dispatch(getBadgesLessons([Number(id)]));
      // keep this declaration here
      const { lessonsBadges } = getState().lesson;
      dispatch({
        type: constants.SET_BADGES,
        data: getFormatedDegreeBadges(result.data.admin.badges, lessonsBadges),
      });
      dispatch({
        type: constants.SET_ALL_LESSONS,
        data: [
          {
            id: Number(id),
            lessonImage: picThumb,
            lessonTitle: title,
            lessonDescription: summary,
            status: workflow,
          },
        ],
      });
      callback && callback();
    } catch (e) {
      console.error("error GetLessonBadges lesson/actions.js", e);
      dispatch(
        SetErrorMessage("An error occurred while fetching the degree's badges"),
      );
    }
  };

export const SetBadgeLessonIds =
  (badgeId, lessonId, isQuizz) => async (dispatch) => {
    dispatch({
      type: constants.SET_BADGE_LESSON_IDS,
      data: { badgeId, lessonId, isQuizz },
    });
  };

export const SaveLessonBadges =
  (score = 1) =>
  async (dispatch, getState) => {
    const { badges, initialBadges } = getState().lesson;
    const toRemove = getLessonsToRemove(badges, initialBadges);
    const { lessonsIdsToAdd, quizzIdsToAdd } = getLessonsToAdd(
      badges,
      initialBadges,
    );

    try {
      await client.mutate({
        mutation: REMOVE_DEGREE_BADGES,
        variables: { data: formatBadgesToMutate(toRemove, "doc") },
      });
      await client.mutate({
        mutation: ADD_DEGREE_ACTIVITY_BADGES,
        variables: { data: formatBadgesToMutate(quizzIdsToAdd, "quiz", score) },
      });
      await client.mutate({
        mutation: ADD_DEGREE_READ_BADGES,
        variables: {
          data: formatBadgesToMutate(lessonsIdsToAdd, "lesson", score),
        },
      });
      dispatch({
        type: constants.SET_BADGES,
        data: badges,
      });
    } catch (e) {
      console.error("error lesson/actions.js SaveLessonBadges", e);
    }
  };

export const ResetBadges = () => (dispatch) => {
  dispatch({
    type: constants.RESET_BADGES,
    data: null,
  });
};

const fetchLessons = async (search, page, props) => {
  try {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_LESSON_PAGINATED,
      variables: {
        search: search,
        skip: page * props.lessonsOnPage,
        limit: props.lessonsOnPage,
        tagMultipleIntersectIds: {
          tagIds: props.tagIds,
        },
      },
    });

    return result.data.admin.lessons;
  } catch (e) {
    if (ENV.IS_DEV) {
      console.error(`[Application error]: ${e}`);
    }
  }

  return [];
};

const formatLessons = (lessons) =>
  lessons.map((lesson) => ({
    value: lesson.lessonId,
    label: lesson.versions[0].title,
  }));

export const loadLessons = async (
  search,
  prevOptions,
  { page, lessonsOnPage, tagIds, defaultOptions },
) => {
  const lessons = await fetchLessons(search, page, {
    lessonsOnPage: lessonsOnPage || 10,
    tagIds,
  });
  const options = formatLessons(lessons);

  if (defaultOptions) options.unshift(defaultOptions);

  return {
    options,
    hasMore: options.length >= (lessonsOnPage || 10),
    additional: {
      page: page + 1,
      tagIds,
      lessonsOnPage,
    },
  };
};
