import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Style from "./MadlibPageEditor.style";
import DocumentPageHeader from "../../components/common/DocumentPageHeader";
import ActivityIntro from "../../components/common/ActivityIntroComponent";
import { GetDesignPageEncodeData } from "../../utils/ui-generator/generator";
import { PAGE_TYPES } from "../../configs/constants";
import MadLib from "../../components/common/Madlib";
import { MadlibQuestionTemplate } from "../../utils/ui-generator/activity.template";
import uuidv4 from "uuid/dist/v4";
import { decodeNewLines } from "../../utils/string";
import activityTranslations from "../../configs/translation/activityTranslations";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const decodeContent = (encodedContent) => {
  const decodedContent = JSON.parse(encodedContent);
  return {
    ...decodedContent,
    data: {
      ...decodedContent.data,
      questions: decodedContent.data.questions.map(
        ({ text, answers, key }) => ({
          key,
          text: decodeNewLines(text.trim()),
          partOne: decodeNewLines(text.split("[#]")[0].trim()),
          partTwo: decodeNewLines(text.split("[#]")[1].trim()),
          answers: answers.map(({ answer, isTrue }) => ({
            answer: answer ? decodeNewLines(answer) : "",
            isTrue,
          })),
        }),
      ),
      activityDescription: decodeNewLines(
        decodedContent.data.activityDescription,
      ),
      title: decodeNewLines(decodedContent.data.title),
      description: decodeNewLines(decodedContent.data.description),
    },
  };
};

const encodeContent = (unEncodedContent, language) => {
  const data = {
    ...unEncodedContent,
    activityName: activityTranslations[language].Madlibs.activityName,
  };
  return GetDesignPageEncodeData(data, PAGE_TYPES.MADLIB);
};

const MadlibPageEditor = (props) => {
  const {
    orderId,
    documentId,
    pageId,
    language,
    onRemovePage,
    onOrderDownClick,
    onOrderUpClick,
    onDataChanged,
    data: dataProp,
  } = props;
  const [selected, setSelected] = useState(0);
  const classes = useThemedStyle(useStyle, props);

  const { data } = decodeContent(dataProp);

  const {
    activityDescription,
    title,
    description,
    image: lastImage,
    questions,
  } = data;
  const [enhancedQuestions, setQuestions] = useState(questions);

  const handleIntroChange = ({ image: url, theme, title, description }) => {
    const encodedContent = encodeContent(
      {
        ...data,
        activityDescription: theme,
        title,
        description,
        image: {
          ...lastImage,
          url,
        },
      },
      language,
    );

    onDataChanged(documentId, language, encodedContent, pageId);
  };

  const handleMadlabDataChange = (
    { partOne, partTwo, options, rightChoice },
    index,
  ) => {
    const questionText = `${partOne} [#] ${partTwo}`;
    const answers = options.map((option) => ({
      answer: option.label,
      isTrue: option.value === rightChoice.value,
    }));

    questions[index].text = questionText;
    questions[index].answers = answers;

    const encodedContent = encodeContent(
      {
        ...data,
        questions,
        activityDescription: data.activityDescription
          ? data.activityDescription
          : "",
        title: data.title ? data.title : "",
        description: data.description ? data.description : "",
      },
      language,
    );

    onDataChanged(documentId, language, encodedContent, pageId);
  };

  useEffect(() => {
    if (props.isProgramQuiz && data.isProgramQuiz !== props.isProgramQuiz) {
      onDataChanged(
        documentId,
        language,
        encodeContent(
          {
            ...data,
            isProgramQuiz: true,
          },
          language,
        ),
        pageId,
      );
    }
  }, []);
  const getFormattedOptions = (options) =>
    options.map((option) => ({
      value: uuidv4(),
      label: option.answer,
      isTrue: option.isTrue,
    }));

  const setSelectedState = (index) => () =>
    selected !== index && setSelected(index);

  const addNewQuestion = (position) => () => {
    const currentQuestions = [...questions];
    currentQuestions.splice(position, 0, MadlibQuestionTemplate);
    const encodedContent = encodeContent(
      {
        ...data,
        questions: currentQuestions,
      },
      language,
    );
    onDataChanged(documentId, language, encodedContent, pageId);
    setQuestions(currentQuestions);
    setSelected(position);
  };

  const handleDeleteQuestion = (index) => {
    if (questions.length > 1) {
      const currentQuestions = [...questions];
      currentQuestions.splice(index, 1);
      const encodedContent = encodeContent(
        {
          ...data,
          questions: currentQuestions,
        },
        language,
      );
      setQuestions(currentQuestions);
      onDataChanged(documentId, language, encodedContent, pageId);
    }
  };

  return (
    <div className={classes.pageBox}>
      <DocumentPageHeader
        pageNumber={orderId}
        onRemoveClick={() => onRemovePage(documentId, language, pageId)}
        onOrderDownClick={() => onOrderDownClick(documentId, language, pageId)}
        onOrderUpClick={() => onOrderUpClick(documentId, language, pageId)}
      />
      <ActivityIntro
        activityId={pageId}
        theme={activityDescription}
        title={title}
        description={description}
        image={lastImage.url}
        isSelected
        onChange={handleIntroChange}
        onClick={() => {}}
      />
      <div className={classes.madlibContainer}>
        {enhancedQuestions.map(({ partOne, partTwo, answers, key }, index) => (
          <MadLib
            key={key}
            isSelected={selected === index}
            onClick={setSelectedState(index)}
            partOne={partOne}
            partTwo={partTwo}
            onDataChanged={(newValues) =>
              handleMadlabDataChange(newValues, index)
            }
            onAddTopHandler={addNewQuestion(index)}
            onAddBottomHandler={addNewQuestion(index + 1)}
            options={getFormattedOptions(answers)}
            onDelete={() => handleDeleteQuestion(index)}
          />
        ))}
      </div>
    </div>
  );
};

MadlibPageEditor.propTypes = {
  /** page Id  */
  pageId: PropTypes.string,
  /** current page data */
  data: PropTypes.string,
  /** page order id */
  orderId: PropTypes.number,
  /** id of current document */
  documentId: PropTypes.string,
  /** current language */
  language: PropTypes.string,
  /** on data changed handler */
  onDataChanged: PropTypes.func,
  /**  event to trigger when a new page is added */
  onRemovePage: PropTypes.func,
  /** whether we are currently editing this page or not **/
  isActivePage: PropTypes.bool,
  /** order up page handler */
  onOrderUpClick: PropTypes.func,
  /** order down page handler */
  onOrderDownClick: PropTypes.func,
};

export default MadlibPageEditor;
