export default (theme) => ({
  modalFrameStyle: {
    width: 350,
  },
  modalTitleStyle: {
    color: theme.DuplicationModal.titleColor,
    fontSize: 16,
    fontStyle: "normal",
    fontFamily: theme.FontFamily,
    fontWeight: 300,
    lineHeight: "normal",
    fontStretch: "normal",
    letterSpacing: 0.05,
  },
  modalLabelStyle: {
    marginBottom: 5,
  },
});
