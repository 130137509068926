import { defineMessages } from "react-intl";

export const translations = defineMessages({
  TextMenuText: {
    id: "SideComponentBar.TextMenuText",
    defaultMessage: "Text",
  },
  ImageMenuText: {
    id: "SideComponentBar.ImageMenuText",
    defaultMessage: "Image",
  },
  MultimediaMenuText: {
    id: "SideComponentBar.MultimediaMenuText",
    defaultMessage: "Multimedia",
  },
  SliderMenuText: {
    id: "SideComponentBar.SliderMenuText",
    defaultMessage: "Slider",
  },
  ScrollImagesMenuText: {
    id: "SideComponentBar.ScrollImagesMenuText",
    defaultMessage: "Scroll Images",
  },
  ActivitiesMenuText: {
    id: "SideComponentBar.ActivitiesMenuText",
    defaultMessage: "Activities",
  },
  TemplateThumbnailAltMessage: {
    id: "SideComponentBar.TemplateThumbnailAltMessage",
    defaultMessage: "Template thumbnail image",
  },
});
