import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ContentEditable from "react-contenteditable";
import { debounce } from "../../../utils/sleep";

/**
 * Component, that can be used to use ContentEditable in an uncontrolled way.
 * @param {function} onChange - Function executed when the input is blurred
 * @param {string} initialValue='' - Value the input is initialized with
 * @param {function} [onEdition] - Function executed when the user starts typing
 * @param {boolean} setContentOnChange=false - If true, the onChange callback will be called on every change
 * @param {number} debounceMs=500 - Debounce time to call onChange if the edition is over, in ms
 * @param {object} restProps - Props passed to the underlying ContentEditable
 **/
const ContentEditableUncontrolled = ({
  onChange,
  initialValue = "",
  onEdition,
  setContentOnChange = false,
  debounceMs = 500,
  ...restProps
}) => {
  const [content, setContent] = useState(initialValue);
  const [isTyping, setIsTyping] = useState(false);

  const firstRender = useRef(true);
  const timerRef = useRef(null);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      // don't call the onChange on the first render
      return;
    }

    if (isTyping) {
      // don't call the onChange callback while typing
      return;
    }

    onChange(content);
  }, [content, isTyping]);

  /**
   * Call onEdition if the user starts typing or if he finished.
   * If he finished, debounce the call to onEdition.
   */
  useEffect(() => {
    if (!onEdition) return;
    if (isTyping) onEdition(true);
    else debounce(() => onEdition(false), debounceMs)();
  }, [isTyping, setIsTyping]);

  const handleContentChange = useCallback(
    (e) => {
      setIsTyping(true);
      setContent(e.target.value);
      if (setContentOnChange) {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
          setIsTyping(false);
          return onChange(e.target.value);
        }, 500);
      }
    },
    [setIsTyping, setContent],
  );

  const handleBlur = useCallback(() => setIsTyping(false), [setIsTyping]);

  return (
    <ContentEditable
      tagName="article"
      {...restProps}
      html={content}
      onChange={handleContentChange}
      onBlur={handleBlur}
    />
  );
};

ContentEditableUncontrolled.propTypes = {
  /** Value the input is initialized with **/
  initialValue: PropTypes.string,
  /** Function executed when the input is blurred **/
  onChange: PropTypes.func,
  /** + all the props accepted by react-contenteditable, except:
   * - html
   * - onChange
   * - onBlur **/
};

export default ContentEditableUncontrolled;
