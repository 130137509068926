import React from "react";
import { SliderStyle } from "../Slider.style";
import SliderReadOnlyStyle from "./SliderReadOnly.style";
import PropTypes from "prop-types";
import ContentEditable from "react-contenteditable";
import { DefaultSlider1, DefaultVideo, DefaultVideoCover } from "assets/icons";
import { FILE_TYPES, VIDEO_FORMAT } from "../../../../configs/constants";
import SliderVideoPlayer from "../../SliderVideoPlayer";
import withThemedStyle from "../../../hoc/withThemedStyle";

class Slider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      image: "",
      video: {
        videoSource: DefaultVideo,
        posterCover: DefaultVideoCover,
        formatType: VIDEO_FORMAT.PORTRAIT,
      },
      fileType: FILE_TYPES.IMAGE,
    };
  }

  componentDidMount() {
    this.setState({
      title: this.props.title,
      description: this.props.description,
      image: this.props.image,
      fileType: this.props.fileType,
      video:
        this.props.video !== null && this.props.video
          ? this.props.video
          : {
              videoSource: DefaultVideo,
              posterCover: DefaultVideoCover,
              formatType: VIDEO_FORMAT.PORTRAIT,
            },
    });
  }

  render() {
    const props = this.props;
    const { classes } = props;

    return (
      <div className={classes.sliderContainer} onClick={props.onClick}>
        <div className={classes.backgroundSlider} />

        {this.state.fileType === FILE_TYPES.VIDEO ? (
          <SliderVideoPlayer
            source={this.state.video.videoSource}
            poster={
              this.state.video.posterCover
                ? this.state.video.posterCover
                : DefaultVideoCover
            }
            onClick={this.props.onClick}
            formatType={
              this.state.video.formatType
                ? this.state.video.formatType
                : VIDEO_FORMAT.PORTRAIT
            }
            isActive={props.isSelected}
          />
        ) : (
          ""
        )}

        <div className={classes.textEditing}>
          <ContentEditable
            key="title"
            html={this.state.title ? this.state.title : ""}
            disabled
            tagName="article"
            className={props.classes.titleInput}
          />
          <br />
          <ContentEditable
            key="description"
            html={this.state.description ? this.state.description : ""}
            disabled
            tagName="article"
            className={props.classes.descriptionInput}
          />
        </div>
      </div>
    );
  }
}

Slider.propTypes = {
  /** unique id of the slide **/
  slideId: PropTypes.string.isRequired,
  /** The current selected image */
  image: PropTypes.string,
  /** Slide type **/
  type: PropTypes.string,
  /** title value */
  title: PropTypes.string,
  /** description */
  description: PropTypes.string,
  /** Label for the chnage image selection button */
  buttonLabel: PropTypes.string,
  /** is selected */
  isSelected: PropTypes.bool,
  /** on click */
  onClick: PropTypes.func,
};

Slider.defaultProps = {
  defaultSliderImage: DefaultSlider1,
};

export default withThemedStyle(SliderStyle, SliderReadOnlyStyle)(Slider);
