import gql from "graphql-tag";

export const USER_PROFILE_KPI_DEFAULT = gql`
  fragment UserProfileKPIFields on UserProfileKPI {
    activity {
      geniusQuestionNb
      expertQuestionNb
      startedLessonNb
      finishedLessonNb
    }
    learningPrograms {
      id
      name
      degreeName
      lessons {
        id
        name
        pageNb
        stats {
          date
          score
          leftPage
          percent
        }
      }
    }
    expertPendingQuestions {
      expertName
      question
      date
    }
    general {
      staffTrained
      programCompleted
      classTraining
      floorCoaching
    }
    classTraining {
      date
      name
      trainer
      format
      section
      attendees
    }
  }
`;
