export const ContentTypeStyle = ({ ContentTypeScreen, Colors }) => ({
  rootContainer: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  back: {
    position: "absolute",
    top: 40,
    left: 40,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      color: Colors.lightBlue,
      cursor: "pointer",
    },
    "& > span": {
      "&::before": {
        color: ContentTypeScreen.backButtonColor,
        fontSize: 17,
      },
      "&:hover::before": {
        color: Colors.lightBlue,
        cursor: "pointer",
      },
    },
    "&:hover *": {
      color: Colors.lightBlue,
      cursor: "pointer",
      "&::before": {
        color: Colors.lightBlue,
        cursor: "pointer",
      },
    },
  },
  cardContainerStyle: {
    marginRight: 15,
  },
});
