export const defaultStyles = (theme) => {
  return {
    dataTableContainer: {
      "& > table td:nth-child(1), table th:nth-child(1)": {
        paddingLeft: 20,
      },
      "& th": {
        border: "none",
      },
    },
    row: {
      height: 60,
      "& > td": {
        border: "none",
        fontSize: 14,
        fontWeight: 500,
        color: theme.BattleLeaderBoardTable.dataColor,
      },
      "& .userName": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > div": {
          marginRight: 10,
        },
      },
    },
    noContent: {
      textAlign: "center",
      padding: 20,
      backgroundColor: theme.BattleLeaderBoardTable.tableBackgroundColor,
    },
  };
};
