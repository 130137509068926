import * as constants from "../../constants/store/degrees";
import { RESET_STORE } from "../../constants/store/auth";
import { DATE_SELECTOR_TYPE } from "../../configs/constants";
import clientConfig from "../../configs/client";
import { handleBadgeSelection } from "../../utils/store/badges";

const currentLang = clientConfig.DEFAULT_LANGUAGE_CODE;

const initialState = {
  currentLanguage: currentLang,
  currentDegreeId: null,
  publishDegree: {
    id: "",
    lang: currentLang,
    dateSelectorType: DATE_SELECTOR_TYPE.NOW,
    publishDate: "",
    languages: [],
    tags: [],
    countries: [],
    userCategories: [],
    brands: [],
    retailers: [],
    universes: [],
    zones: [],
    roles: [],
    title: "",
    summary: "",
    cover: "",
    picThumb: "",
    tagsIsEditable: true,
  },
  programContent: [],
  visibleTagSelector: null,
  currentEditingProgram: {
    title: "",
    summary: "",
    programId: undefined,
  },
  currentEditingProgramId: undefined,
  isProgramEditorVisible: false,
  activeMenuDegreeId: undefined,
  activeMenuLessonId: undefined,
  isDegreePublishedSuccesfully: undefined,
  currentLessonId: null,
  currentPageId: null,
  currentOpenedMarker: {
    pageId: null,
    slideIndex: null,
    markerIndex: null,
  },
  publishLesson: {
    id: "",
    lang: currentLang,
    dateSelectorType: DATE_SELECTOR_TYPE.NOW,
    publishDate: "",
    languages: [],
    tags: [],
    predictTags: [],
    countries: [],
    userCategories: [],
    brands: [],
    retailers: [],
    title: "",
    summary: "",
    cover: "",
    picThumb: "",
    lessonRecap: "",
    tagsIsEditable: true,
    pages: [],
    showProgramQuizCheckbox: false,
    isProgramQuiz: false,
  },
  lastExpandedProgramId: undefined,
  filters: {
    universe: null,
  },
  degreeFilters: {
    language: {
      selectedValue: clientConfig.DEFAULT_LANGUAGE_CODE,
    },
    market: {
      selectedValue: null,
    },
  },
  degrees: [],
  markets: [],
  userMarketIds: undefined,
  badges: [],
  initialBadges: [],
  allLessons: [],
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.SET_CURRENT_DEGREE_LANGUAGE:
      return {
        ...state,
        currentLanguage: data,
      };
    case constants.SET_CURRENT_DEGREE_ID:
      return {
        ...state,
        currentDegreeId: data,
      };
    case constants.SET_CURRENT_DEGREE_PAGEID:
      return {
        ...state,
        currentPageId: data,
      };
    case constants.SET_PUBLISH_DEGREE_INFO:
      return {
        ...state,
        publishDegree: data,
      };
    case constants.UPDATE_VISIBLE_DEGREE_TAG_SELECTOR:
      return {
        ...state,
        visibleTagSelector: data,
      };
    case constants.SET_CURRENT_EDIT_PROGRAM_INFO:
      return {
        ...state,
        currentEditingProgram: data,
      };
    case constants.SET_CURRENT_EDIT_PROGRAM_ID:
      return {
        ...state,
        currentEditingProgramId: data,
      };
    case constants.CHANGE_CURRENT_EDIT_PROGRAM_INFO_VISIBILITY:
      return {
        ...state,
        isProgramEditorVisible: data,
      };
    case constants.SET_CURRENT_DEGREE_PROGRAM_CONTENT:
      return {
        ...state,
        programContent: data,
      };
    case constants.SET_ACTIVE_MENU_FOR_DEGREE_ID:
      return {
        ...state,
        activeMenuDegreeId: data,
      };
    case constants.SET_ACTIVE_MENU_FOR_LESSON_ID:
      return {
        ...state,
        activeMenuLessonId: data,
      };
    case constants.SET_DEGREE_PUBLICATION_STATUS:
      return {
        ...state,
        isDegreePublishedSuccesfully: data,
      };
    case constants.SET_CURRENT_LESSON_ID:
      return {
        ...state,
        currentLessonId: data,
      };
    case constants.SET_PUBLISH_LESSON_INFO:
      return {
        ...state,
        publishLesson: data,
      };
    case constants.SET_CURRENT_LESSON_PAGEID:
      return {
        ...state,
        currentPageId: data,
      };
    case constants.UPDATE_CURRENT_OPENED_MARKER_LESSON:
      return {
        ...state,
        currentOpenedMarker: data,
      };
    case constants.SET_LAST_EXPANDED_PROGRAM_ID:
      return {
        ...state,
        lastExpandedProgramId: data,
      };
    case constants.SET_DEGREE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [data.name]: data.value,
        },
      };
    case constants.UPDATE_DEGREES:
      return {
        ...state,
        degrees: data,
      };
    case constants.UPDATE_DEGREE_FILTERS:
      return {
        ...state,
        degreeFilters: {
          ...state.degreeFilters,
          [data.type]: {
            selectedValue: data.value,
          },
        },
      };
    case constants.SET_MARKETS:
      return {
        ...state,
        markets: data,
      };
    case constants.SET_DEGREE_BADGE_LESSON_IDS:
      return {
        ...state,
        badges: handleBadgeSelection(state, data),
      };
    case constants.SET_DEGREE_BADGES:
      return {
        ...state,
        badges: data,
        initialBadges: data,
      };
    case constants.RESET_DEGREE_BADGES:
      return {
        ...state,
        badges: state.initialBadges,
      };
    case constants.SET_DEGREE_ALL_LESSONS:
      return {
        ...state,
        allLessons: data,
      };
    case constants.UPDATE_USER_MARKET_IDS:
      return {
        ...state,
        userMarketIds: data,
      };
    case constants.RESET_DEGREE_STORE: {
      return { ...initialState };
    }
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
