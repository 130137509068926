import { navigationBarWidth } from "../../components/Navigation/Navigation.style";

const contentWidth = 970;

export const DegreesStyle = ({ DegreesScreen, FontFamily, Fonts }) => ({
  rootContainer: {
    paddingLeft: navigationBarWidth,
  },
  degreesContainer: {
    width: "100%",
  },
  cardsRootContainer: {
    margin: "auto",
    width: contentWidth,
  },
  cardsContainer: {
    width: "100%",
    float: "left",
    "& > div:nth-of-type(4n)": {
      paddingRight: 0,
    },
  },
  cardContainer: {
    float: "left",
    padding: "0 30px 30px 0",
  },
  actionContainer: {
    height: 44,
    width: contentWidth,
    margin: "auto",
    padding: "80px 0 60px 0",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  createLink: {
    fontFamily: Fonts.fontFamilyCondensed,
    display: "flex",
    flexDirection: "row",
    fontSize: "16px",
    fontWeight: 400,
    fontStyle: "normal",
    fontStretch: "condensed",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    color: DegreesScreen.linkColor,
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "space-between",
    "& > span::before": {
      color: DegreesScreen.linkColor,
      fontSize: 25,
      marginRight: 10,
    },
    textDecoration: "none",
  },
  createProgramContainer: {
    padding: 20,
  },
  otherDegreeTitle: {
    fontFamily: FontFamily,
    fontSize: 16,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.05px",
    color: DegreesScreen.otherDegreeTitleColor,
    marginBottom: 30,
    textTransform: "uppercase",
  },
  addDegree: {
    float: "right",
  },
  degreeSettingsMenu: {
    width: 200,
    left: 185,
    position: "relative",
    top: -135,
    zIndex: 99999,
  },
});
