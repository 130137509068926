export default ({ PublishBattleScreen, FontFamily }) => {
  const customInputStyle = {
    outline: "none",
    border: "none",
    cursor: "pointer",
    backgroundColor: PublishBattleScreen.customInputStyle.backgroundColor,
    padding: 0,
    fontSize: 22,
    fontWeight: "bold",
    letterSpacing: 0.07,
    color: PublishBattleScreen.customInputStyle.color,
    "&:focus": {
      border: "none",
    },
    "&::placeholder": {
      color: PublishBattleScreen.customInputStyle.color,
    },
  };

  return {
    rootContainer: {
      fontFamily: FontFamily,
      display: "flex",
      justifyContent: "center",
    },
    content: {
      paddingTop: 116,
      width: 1214,
      display: "flex",
      flexDirection: "row",
    },
    rightSide: {
      width: 533,
    },
    photo: {
      marginRight: 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 414,
      height: 736,
      backgroundImage: ({ publishData }) => `url('${publishData.picCover}')`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundColor: PublishBattleScreen.photo.backgroundColor,
    },
    uploadBtn: {
      width: 108,
      height: 36,
      borderRadius: 3,
      cursor: "pointer",
      backgroundColor: PublishBattleScreen.uploadBtn.backgroundColor,
      border: `solid 1px ${PublishBattleScreen.uploadBtn.borderColor}`,
      letterSpacing: 0.07,
      color: PublishBattleScreen.uploadBtn.color,
      fontSize: 14,
      outline: "none",
    },
    close: {
      textAlign: "right",
      "& > span": {
        fontSize: 17,
        color: PublishBattleScreen.close.color,
        cursor: "pointer",
      },
      marginBottom: 75,
    },
    battleTitle: customInputStyle,
    battleDescription: {
      ...customInputStyle,
      fontWeight: 300,
      fontSize: 17,
      letterSpacing: 0.06,
      marginBottom: 45,
    },
    title: {
      marginBottom: 10,
      fontSize: 16,
      color: PublishBattleScreen.title.color,
    },
    simpleDropdown: {
      marginRight: "0!important",
      height: "unset!important",
      marginBottom: 30,
    },
    tagSelectContainer: {
      position: "relative",
      marginBottom: 30,
    },
    tagSelectContainerError: {
      position: "relative",
      marginBottom: 30,
      "& > div": {
        border: "1px solid #c60000",
        borderRadius: 2,
      },
    },
    languages: {
      display: "inline-block",
      "& > div": {
        float: "left",
      },
    },
    dateSelectors: {
      marginBottom: 30,
    },
    postingDate: {
      display: "flex",
      width: "100%",
      marginTop: 45,
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      zIndex: 10,
    },
    postingDateButton: {
      paddingRight: 10,
    },
    simpleInput: {
      marginBottom: 30,
    },
  };
};
