import gql from "graphql-tag";

export const CARD_SET_PRIVACY = gql`
  mutation cardSetPrivacy($cardId: ID, $lang: LangEnum) {
    admin {
      card(cardId: $cardId, lang: $lang) {
        privacy {
          set
        }
      }
    }
  }
`;
