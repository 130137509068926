import { useDispatch } from "react-redux";
import { DocumentTagsAction } from "./actions";
import { useCallback } from "react";

export const useTags = () => {
  const dispatch = useDispatch();
  const handleTag = useCallback(
    (addAction, documentType, docId, lang, tagIds, onError) => {
      dispatch(
        DocumentTagsAction(
          addAction,
          documentType,
          docId,
          lang,
          tagIds,
          onError,
        ),
      );
    },
    [],
  );

  return {
    handleTag,
  };
};
