import React, { useState } from "react";
import PropTypes from "prop-types";
import style from "./Link.style";
import { Icon, Icons } from "genius-ui";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const Link = (props) => {
  const {
    titlePlaceholder,
    linkPlaceholder,
    linkTitleValue,
    linkValue,
    onBlur,
    onItemClick,
    handleKeyDown,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  const [linkTitle, setLinkTitle] = useState(linkTitleValue);
  const [link, setLink] = useState(linkValue);
  const [linkPopupIsVisible, setLinkPopup] = useState(false);

  const onBlurEventHandler = () =>
    onBlur({
      content: link,
      text: linkTitle,
    });

  return (
    <div className={classes.container} onClick={onItemClick}>
      {linkPopupIsVisible && (
        <div className={classes.popup}>
          <div>
            <input
              placeholder={linkPlaceholder}
              onBlur={onBlurEventHandler}
              value={link}
              onChange={(e) => setLink(e.target.value)}
              className={classes.link}
            />
          </div>
          <Icon
            iconName={Icons.mediumCloseIcon}
            handleClick={() => setLinkPopup(false)}
          />
        </div>
      )}
      <div onClick={() => setLinkPopup(true)}>
        <input
          placeholder={titlePlaceholder}
          onKeyDown={handleKeyDown}
          onBlur={onBlurEventHandler}
          value={linkTitle}
          onChange={(e) => setLinkTitle(e.target.value)}
          className={classes.linkTitle}
        />
      </div>
    </div>
  );
};

const StringOrObject = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
]);

Link.propTypes = {
  /** Placeholder text for the link title */
  titlePlaceholder: StringOrObject,
  /** Placeholder text for the link */
  linkPlaceholder: StringOrObject,
  /** Value of the link title */
  linkTitleValue: PropTypes.string,
  /** Value of the link */
  linkValue: PropTypes.string,
  /** onBlur event handler */
  onBlur: PropTypes.func.isRequired,
  /** On component click event handler */
  onItemClick: PropTypes.func,
  /** OnKeyDown event handler */
  handleKeyDown: PropTypes.func,
};

Link.defaultProps = {
  titlePlaceholder: "Link Title",
  linkPlaceholder: "Paste or type a link",
  linkTitleValue: "",
  linkValue: "",
};

export default Link;
