import { defineMessages } from "react-intl";

export const translations = defineMessages({
  ModalPdfToImgTitle: {
    id: "AddCoverModal.ModalPdfToImgTitle",
    defaultMessage: "Change cover",
  },
  ModalPdfToImgMessage: {
    id: "AddCoverModal.ModalPdfToImgMessage",
    defaultMessage:
      "Would you like to use the first page of the pdf as the cover picture ?",
  },
  CancelBtnLabel: {
    id: "AddCoverModal.CancelBtnLabel",
    defaultMessage: "Cancel",
  },
  KeepBtnLabel: {
    id: "AddCoverModal.KeepBtnLabel",
    defaultMessage: "No, ignore and continue",
  },
  ReplaceBtnLabel: {
    id: "AddCoverModal.ReplaceBtnLabel",
    defaultMessage: "Yes, use as cover picture",
  },
});
