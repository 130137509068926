import React, { useCallback } from "react";
import Style from "./NinjaQuestionChoiceEditor.style.js";
import { Icon, Icons } from "genius-ui";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import UploadMedia from "components/common/UploadMedia/index.js";
import translations from "./NinjaQuestionChoiceEditor.translations";
import { FormattedMessage } from "react-intl";

const useStyle = createUseStyles(Style);

const NinjaQuestionChoiceEditor = (props) => {
  const {
    onRemove,
    option,
    onChangeCorrect,
    onChangeText,
    isCorrect,
    onChangeImage,
    onChangeType,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  const handleChangeCorrect = useCallback(() => {
    onChangeCorrect(option.id);
  }, [option, onChangeCorrect]);

  const remove = useCallback(() => {
    onRemove(option.id);
  }, [option, onRemove]);

  const changeText = useCallback(
    (e) => {
      onChangeText(e.target.value, option.id);
    },
    [option, onChangeText],
  );

  const changeImage = useCallback(
    (url) => {
      onChangeImage(url, option.id);
    },
    [option, onChangeImage],
  );

  const changeType = useCallback(() => {
    onChangeType(option.id);
  }, [option, onChangeType]);

  return (
    <div className={classes.ninjaChoiceMain}>
      <input
        className={classes.ninjaChoiceCheckbox}
        type="checkbox"
        checked={isCorrect}
        onChange={handleChangeCorrect}
      />
      <button onClick={changeType} className={classes.ninjaChoiceType}>
        {option.type}
      </button>
      <div className={classes.ninjaChoiceValue}>
        {option.type === "text" ? (
          <input
            className={classes.ninjaChoiceText}
            type="text"
            value={option.label}
            onChange={changeText}
            placeholder="Enter new choice"
          />
        ) : (
          <div
            className={classes.ninjaImage}
            style={{ backgroundImage: `url(${option.url})` }}
          >
            <UploadMedia
              id={`dnd-activity-answer-${option.id}-upload-image`}
              disabled={false}
              onUploadSuccess={changeImage}
              isVideoUploadEnabled={false}
            >
              <Icon iconName={Icons.upload} />
              <FormattedMessage {...translations.AnswerImageInputLabel} />
            </UploadMedia>
          </div>
        )}
      </div>
      <span onClick={remove} className={classes.ninjaChoiceDelete}>
        <Icon iconName={Icons.delete} />
      </span>
    </div>
  );
};

export default NinjaQuestionChoiceEditor;
