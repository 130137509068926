import { defineMessages } from "react-intl";

export const translations = defineMessages({
  UploadButtonLabel: {
    id: "SlideMarker.UploadButtonLabel",
    defaultMessage: "Upload Image",
  },
  CropButtonLabel: {
    id: "SlideMarker.CropButtonLabel",
    defaultMessage: "Crop Image",
  },
  DefaultMarkerDescription: {
    id: "SlideMarker.DefaultMarkerDescription",
    defaultMessage: "Text",
  },
});
