import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import {
  GET_ALL_DOCUMENTS,
  GET_DOCUMENTS_BY_TYPE,
} from "../../../../graphql/remote/documents/queries";
import { FullscreenLoader } from "genius-ui";
import { NoImageAvailable, Instagram as InstagramLogo } from "assets/icons";
import NoContent from "../../../../components/noContent";
import {
  DOC_WORKFLOW_ENUM,
  DOCUMENT_TYPES,
  DOCUMENT_WORKFLOW_IDS,
  DOCUMENT_VIEW_TYPES,
  CARD_TYPES,
} from "../../../../configs/constants";
import { getPath } from "../../../../core/paths";
import DuplicationModal from "../../../../components/common/DuplicationModal";
import SendNotificationModal from "../../../../components/common/SendNotificationModal";
import { LANGUAGES } from "../../../../configs/referential";
import { FormattedMessage } from "react-intl";
import injectSheet from "react-jss";
import ContentsStyle from "./Contents.style";
import GridView from "./views/grid";
import ListView from "./views/list";
import DeleteModal from "../../../../components/common/DeleteModal";
import { translations } from "./Contents.translations";
import { formatDate } from "../../../../utils/dateUtils";
import clientConfig from "../../../../configs/client";

const DOCS_ON_PAGE = 50;

const GetReformatedItem = (item, currentLanguage) => {
  const currentVersion = item.versions.find((x) => x.lang === currentLanguage);
  const availableLanguages = item.versions
    .filter(
      (y) =>
        y.lang !== currentLanguage && y.workflow !== DOC_WORKFLOW_ENUM.ARCHIVED,
    )
    .map((x) => x.lang);
  const pageType = currentVersion.pages.length
    ? currentVersion.pages[0].type
    : undefined;
  const { firstName, lastName } = currentVersion.author.profile;

  return {
    id: item.docId,
    language: currentVersion.lang,
    title: currentVersion.title,
    updateDate: formatDate(currentVersion.updateDate, "MMMM dd, yyyy"),
    picThumb: currentVersion.picThumb
      ? currentVersion.picThumb
      : NoImageAvailable,
    picCover: currentVersion.picCover
      ? currentVersion.picCover
      : pageType === CARD_TYPES.INSTAGRAM
      ? InstagramLogo
      : NoImageAvailable,
    availableLanguages: availableLanguages,
    type: currentVersion.type,
    workflow: currentVersion.workflow,
    vote: currentVersion.all ? currentVersion.all.vote : 0,
    read: currentVersion.all ? currentVersion.all.read : 0,
    comment: currentVersion.all ? currentVersion.all.comment : 0,
    pageType: pageType,
    visibility: (
      <FormattedMessage
        {...translations[currentVersion.isPrivate ? "Private" : "Public"]}
      />
    ),
    hasAnnouncementTag: currentVersion.tags.some(
      ({ tagId }) => Number(tagId) === 6,
    ),
    authorName: `${firstName} ${lastName}`,
  };
};

const GetFormattedFilteredResults = (language, documents) => {
  const filteredList = documents.filter((x) =>
    x.versions.find(
      (y) =>
        y.lang === language &&
        [
          DOCUMENT_TYPES.CARD,
          DOCUMENT_TYPES.INSIGHT,
          DOCUMENT_TYPES.BATTLE,
          DOCUMENT_TYPES.POLL,
        ].includes(y.type),
    ),
  );

  return filteredList.map((x) => GetReformatedItem(x, language));
};

const getFilteredDocNumber = (allDocNumber, language, docType) => {
  let nb = 0;
  allDocNumber.forEach((doc) => {
    if (
      doc.lang === language &&
      (doc.workflow === DOC_WORKFLOW_ENUM.PUBLISHED ||
        doc.workflow === DOC_WORKFLOW_ENUM.DRAFT)
    ) {
      if (docType) {
        if (doc.type === docType) {
          nb += doc.nb;
        }
      } else {
        nb += doc.nb;
      }
    }
  });
  return nb;
};

const editDocument = (props, item) => () => {
  props.onItemSettingsClicked(undefined);
  switch (item.type) {
    case DOCUMENT_TYPES.INSIGHT:
      props.history.push(getPath("createInsight", item.id, item.language));
      break;
    case DOCUMENT_TYPES.CARD:
      switch (item.pageType) {
        case CARD_TYPES.QUICK_QUIZ:
          props.history.push(getPath("quickQuizEdit", item.id, item.language));
          break;
        case CARD_TYPES.INSTAGRAM:
          props.history.push(
            getPath("createCardInstagram", item.id, item.language),
          );
          break;
        case CARD_TYPES.PEANUT:
          props.history.push(
            getPath("createCardPeanut", item.id, item.language),
          );
          break;
        case CARD_TYPES.TWITTER:
          props.history.push(
            getPath("createCardTwitter", item.id, item.language),
          );
          break;
        default:
          break;
      }
      break;
    case DOCUMENT_TYPES.BATTLE:
      props.history.push(getPath("editBattle", item.id, item.language));
      break;
    case DOCUMENT_TYPES.POLL:
      props.history.push(getPath("editPoll", item.id, item.language));
      break;
    default:
      break;
  }
};

const getEditLabel = (type) => {
  switch (type) {
    case DOCUMENT_TYPES.INSIGHT:
      return <FormattedMessage {...translations.EditInsightOption} />;
    case DOCUMENT_TYPES.CARD:
      return <FormattedMessage {...translations.EditCardOption} />;
    case DOCUMENT_TYPES.BATTLE:
      return <FormattedMessage {...translations.EditBattleOption} />;
    default:
      return <FormattedMessage {...translations.EditOption} />;
  }
};

/**
 * Insight/card's destination language is available if doesn't exist the current
 * insight/card on the destination language
 * */
const showDuplicateModal = (props, currentItem) => () => {
  props.onItemSettingsClicked(undefined);
  props.setDuplicationData({
    itemId: currentItem.id,
    availableLanguages: LANGUAGES.filter(
      (lang) => !currentItem.availableLanguages.includes(lang.Code),
    ).map((lang) => lang.Code),
    type: currentItem.type,
  });
  props.setShowDuplicationModal(true);
};

const showNotificationModal = (props, currentItem) => () => {
  props.onItemSettingsClicked(undefined);
  props.setNotificationData({
    docId: currentItem.id,
    lang: currentItem.language,
    docType: currentItem.type,
  });
  props.setShowNotificationModal(true);
};

const hideModal = (setModalStat) => () => setModalStat(false);

const ContentsQuery = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [deleteDocumentData, setDeleteDocumentData] = useState();

  // If docType or the language has changed we need to reset the current page number
  useEffect(() => setCurrentPage(1), [props.docType, props.language]);

  const changeDeleteModalVisibility = (value) => () =>
    setDeleteModalVisibility(value);

  const loadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  const Frame =
    props.viewType === DOCUMENT_VIEW_TYPES.GRID ? GridView : ListView;

  const deleteDocument = (refetch) => () => {
    setDeleteModalVisibility(false);
    switch (deleteDocumentData.type) {
      case DOCUMENT_TYPES.INSIGHT:
        props.handleDeleteInsight(
          deleteDocumentData.id,
          deleteDocumentData.language,
          refetch,
        );
        break;
      case DOCUMENT_TYPES.CARD:
        props.handleDeleteCard(
          deleteDocumentData.id,
          deleteDocumentData.language,
          refetch,
        );
        break;
      case DOCUMENT_TYPES.BATTLE:
        props.handleDeleteBattle(
          deleteDocumentData.id,
          deleteDocumentData.language,
          refetch,
        );
        break;
      case DOCUMENT_TYPES.POLL:
        props.handleDeletePoll(
          deleteDocumentData.id,
          deleteDocumentData.language,
          refetch,
        );
        break;
      default:
        break;
    }
  };

  const excludeTagIds = clientConfig.MASTER_DEGREE_TAG_ID
    ? [...clientConfig.EXCLUDE_TAG_IDS, clientConfig.MASTER_DEGREE_TAG_ID]
    : clientConfig.EXCLUDE_TAG_IDS;

  return (
    <Query
      fetchPolicy="cache-and-network"
      query={props.docType ? GET_DOCUMENTS_BY_TYPE : GET_ALL_DOCUMENTS}
      variables={{
        type: props.docType,
        skip: 0,
        limit: DOCS_ON_PAGE * currentPage,
        lang: props.language,
        excludeTagIds,
        workflowIds: props.archivedContents
          ? [DOCUMENT_WORKFLOW_IDS.ARCHIVED, DOCUMENT_WORKFLOW_IDS.DELETED]
          : [
              DOCUMENT_WORKFLOW_IDS.DRAFT,
              DOCUMENT_WORKFLOW_IDS.PUBLISHED,
              DOCUMENT_WORKFLOW_IDS.UNPUBLISHED,
            ],
      }}
      notifyOnNetworkStatusChange
    >
      {({ loading, _error, data, refetch, _networkStatus }) => {
        if (!data || !data.admin) return <FullscreenLoader />;

        const { classes, allDocNumber, language, docType } = props;
        const filteredDocNumber = getFilteredDocNumber(
          allDocNumber,
          language,
          docType,
        );
        const showLoadMore = filteredDocNumber > DOCS_ON_PAGE * currentPage;
        const contentResults = GetFormattedFilteredResults(
          language,
          data.admin.documents,
        );
        const noContent = loading ? null : (
          <NoContent
            history={props.history}
            noContentMessage={
              <FormattedMessage {...translations.NoContentMessage} />
            }
          />
        );

        return (
          <div className={classes.contentsContainer}>
            {loading && <FullscreenLoader />}
            {contentResults.length ? (
              <>
                <Frame
                  props={props}
                  items={contentResults}
                  refetch={refetch}
                  editDocument={editDocument}
                  getEditLabel={getEditLabel}
                  showDuplicateModal={showDuplicateModal}
                  showDeleteModal={changeDeleteModalVisibility(true)}
                  showNotificationModal={showNotificationModal}
                  deleteDocument={deleteDocument(refetch)}
                  showLoadMore={showLoadMore}
                  loading={loading}
                  loadMore={loadMore}
                  allowDuplication={!props.archivedContents}
                  allowNotification={!props.archivedContents}
                  allowDelete={!props.archivedContents}
                  setDeleteDocumentData={setDeleteDocumentData}
                  navigateToInsightPublishPage={
                    props.navigateToInsightPublishPage
                  }
                  navigateToCardPublishPage={props.navigateToCardPublishPage}
                  navigateToBattlePublishPage={
                    props.navigateToBattlePublishPage
                  }
                  navigateToPollPublishPage={props.navigateToPollPublishPage}
                />
                {!props.archivedContents && (
                  <DuplicationModal
                    hideModal={hideModal(props.setShowDuplicationModal)}
                    visible={props.showDuplicationModal}
                    sourceLang={props.language}
                    availableItemLanguages={
                      props.duplicationData.availableLanguages
                    }
                    currentItemId={props.duplicationData.itemId}
                    duplicationHandler={(destLang) => {
                      switch (props.duplicationData.type) {
                        case DOCUMENT_TYPES.INSIGHT:
                          props.handleDuplicateInsight(
                            props.duplicationData.itemId,
                            props.language,
                            destLang,
                          );
                          break;
                        case DOCUMENT_TYPES.CARD:
                          props.handleDuplicateCard(
                            props.duplicationData.itemId,
                            props.language,
                            destLang,
                          );
                          break;
                        case DOCUMENT_TYPES.BATTLE:
                          props.handleDuplicateBattle(
                            props.duplicationData.itemId,
                            props.language,
                            destLang,
                          );
                          break;
                        case DOCUMENT_TYPES.POLL:
                          props.handleDuplicatePoll(
                            props.duplicationData.itemId,
                            props.language,
                            destLang,
                          );
                          break;
                        default:
                          break;
                      }
                    }}
                  />
                )}
                <DeleteModal
                  deleteMessage={
                    <FormattedMessage
                      {...translations.DeleteMessage}
                      values={{ mark: "?" }}
                    />
                  }
                  actionHandler={deleteDocument(refetch)}
                  setShowModal={changeDeleteModalVisibility(false)}
                  showModal={deleteModalVisibility}
                />
                {!props.archivedContents && (
                  <FormattedMessage
                    {...translations.NotificationModalMessagePlaceholder}
                  >
                    {(message) => (
                      <SendNotificationModal
                        title={
                          <FormattedMessage
                            {...translations.NotificationModalTitle}
                          />
                        }
                        description={
                          <FormattedMessage
                            {...translations.NotificationModalDescription}
                          />
                        }
                        placeholder={message[0]}
                      />
                    )}
                  </FormattedMessage>
                )}
              </>
            ) : (
              noContent
            )}
          </div>
        );
      }}
    </Query>
  );
};

ContentsQuery.propTypes = {
  archivedContents: PropTypes.bool,
};

ContentsQuery.defaultProps = {
  archivedContents: false,
};

export default injectSheet(ContentsStyle)(ContentsQuery);
