import React, { useEffect } from "react";
import DefaultPollEditor from "./EditPoll";
import PollEditorReadOnly from "./pollEditorReadOnly";
import clientConfig from "../../configs/client";
import { DOCUMENT_LEVEL, USER_ROLE_NAMES } from "../../configs/constants";
import { connect } from "react-redux";
import { FullscreenLoader } from "genius-ui";
import { GetPollInfo, SavePollQuestions } from "../../store/editPoll/actions";
import { getPath } from "../../core/paths";

const EditPoll = (props) => {
  const { isLoading, isMasterDocument, userRoles, GetPollInfo } = props;

  useEffect(() => {
    GetPollInfo();
  }, []);

  if (!userRoles || !userRoles.length || isLoading) {
    return <FullscreenLoader />;
  }

  if (
    clientConfig.IS_ROLE_READ_ONLY_MODE_ACTIVE &&
    userRoles.includes(USER_ROLE_NAMES.TRAINER) &&
    isMasterDocument
  ) {
    return <PollEditorReadOnly {...props} />;
  }

  return <DefaultPollEditor {...props} />;
};

const mapStateToProps = (state) => ({
  userRoles: state.auth.profileInfo.roles,
  isLoading: state.common.fetchIndicator,
  questions: state.editPoll.questions,
  isMasterDocument: state.editPoll.isMasterDocument,
  documentLevel: state.documents.documentLevel,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  GetPollInfo: () =>
    dispatch(GetPollInfo(ownProps.match.params.id, ownProps.match.params.lang)),
  SetQuestions: (questions) =>
    dispatch(
      SavePollQuestions(
        ownProps.match.params.id,
        ownProps.match.params.lang,
        questions,
      ),
    ),
  NavigateToPreviousPage: () => {
    ownProps.history.push(getPath("contentManager"));
  },
  NavigateToPollPublishPage: (isMasterDocument) => {
    if (isMasterDocument) {
      ownProps.history.push(
        getPath(
          "publishMasterPoll",
          ownProps.match.params.id,
          ownProps.match.params.lang,
        ),
      );
    } else {
      ownProps.history.push(
        getPath(
          "publishPoll",
          ownProps.match.params.id,
          ownProps.match.params.lang,
        ),
      );
    }
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(EditPoll);
