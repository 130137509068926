import { DOCUMENT_VIEW_TYPES } from "../../../configs/constants";

export default ({ ViewSwitcher }) => {
  const viewButtonStyle = {
    cursor: "pointer",
    marginLeft: 40,
    "&:hover:before": {
      color: ViewSwitcher.activeColor,
    },
  };

  return {
    gridViewButton: {
      ...viewButtonStyle,
      "&::before": {
        fontSize: 25,
        color: ({ viewType }) =>
          viewType === DOCUMENT_VIEW_TYPES.GRID
            ? ViewSwitcher.activeColor
            : ViewSwitcher.inActiveColor,
      },
    },
    listViewButton: {
      ...viewButtonStyle,
      "&::before": {
        fontSize: 25,
        color: ({ viewType }) =>
          viewType === DOCUMENT_VIEW_TYPES.LIST
            ? ViewSwitcher.activeColor
            : ViewSwitcher.inActiveColor,
      },
    },
  };
};
