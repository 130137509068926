import gql from "graphql-tag";

export const INSIGHT_COPY_FOR_LANGUAGE = gql`
  mutation insightCopy(
    $insightId: ID
    $sourceLang: LangEnum
    $destLang: LangEnum!
  ) {
    admin {
      insight(insightId: $insightId, lang: $sourceLang) {
        copy(lang: $destLang) {
          insightId
          versions {
            title
            lang
          }
        }
      }
    }
  }
`;
