import React from "react";
import { createUseStyles } from "react-jss";
import { ToolbarStyle } from "./Toolbar.style";
import PropTypes from "prop-types";
import { Icon, Icons } from "genius-ui";
import { FormattedMessage } from "react-intl";
import { translations } from "./Toolbar.translations";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(ToolbarStyle);

const ToolbarItems = (props, classes) => [
  {
    Key: 1,
    Name: <FormattedMessage {...translations.TitleText} />,
    Icon: Icons.title,
    OnClick: props.onTitleClick,
    Style: classes.iconItem,
  },
  {
    Key: 2,
    Name: <FormattedMessage {...translations.SubtitleText} />,
    Icon: Icons.title,
    OnClick: props.onSubTitleClick,
    Style: classes.iconSubtitle,
  },
  {
    Key: 3,
    Name: <FormattedMessage {...translations.ParagraphText} />,
    Icon: "icon-paragraph",
    OnClick: props.onParagraphClick,
    Style: classes.iconItem,
  },
  {
    Key: 4,
    Name: <FormattedMessage {...translations.PictureText} />,
    Icon: Icons.images,
    OnClick: props.onImageClick,
    Style: classes.iconItem,
  },
  {
    Key: 5,
    Name: <FormattedMessage {...translations.VideoText} />,
    Icon: Icons.multimedia,
    OnClick: props.onMediaClick,
    Style: classes.iconVideo,
  },
  {
    Key: 6,
    Name: <FormattedMessage {...translations.LinkText} />,
    Icon: Icons.link,
    OnClick: props.onLinkClick,
    Style: classes.iconItem,
  },
  {
    Key: 7,
    Name: <FormattedMessage {...translations.NewPageText} />,
    Icon: Icons.newPage,
    OnClick: props.onNewPageClick,
    Style: classes.iconItem,
  },
];

const Toolbar = (props) => {
  const classes = useThemedStyle(useStyle, props);

  return props.isEditModeVisible ? (
    <div className={classes.toolbarContainer}>
      <div className={classes.removeIconBox}>
        <Icon
          iconName={Icons.roundClose}
          style={classes.iconHideToolbar}
          handleClick={props.onRemoveClick}
        />
      </div>
      <div className={classes.barItems}>
        {ToolbarItems(props, classes).map((toolbarItem) => (
          <div
            className={classes.barItem}
            key={toolbarItem.Key}
            onClick={toolbarItem.OnClick}
          >
            <Icon iconName={toolbarItem.Icon} style={toolbarItem.Style} />
            <span className={classes.itemText}>{toolbarItem.Name}</span>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className={classes.toolbarContainer}>
      <div className={classes.addIconBox} onClick={props.onAddClick}>
        <Icon iconName={Icons.roundSmallPlus} style={classes.iconAddContent} />
        <span>
          <FormattedMessage {...translations.ContinueWritingText} />
          ...
        </span>
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  /** handle title click */
  onTitleClick: PropTypes.func,
  /** handle sub title click */
  onSubTitleClick: PropTypes.func,
  /** handle paragraph click */
  onParagraphClick: PropTypes.func,
  /** handle paragraph click */
  onImageClick: PropTypes.func,
  /** on new pageclick */
  onNewPageClick: PropTypes.func,
  /** on remove bar click */
  onRemoveClick: PropTypes.func,
  /** on add bar click */
  onAddClick: PropTypes.func,
  /** on media click */
  onMediaClick: PropTypes.func,
  /** on link click */
  onLinkClick: PropTypes.func,
  /** if edit mode is visible */
  isEditModeVisible: PropTypes.bool,
};

export default Toolbar;
