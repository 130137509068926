export const ImagePageEditorStyle = (theme) => ({
  imageContainer: {
    position: "relative",
    backgroundColor: theme.ImagePageEditor.background,
    width: 414,
    minHeight: 316,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  templateSelector: {
    minWidth: 240,
  },
});
