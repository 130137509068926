import { defineMessages } from "react-intl";

export const translations = defineMessages({
  UploadButtonLabel: {
    id: "HorizontalMarkerSlide.UploadButtonLabel",
    defaultMessage: "Upload Image",
  },
  DeleteButtonLabel: {
    id: "HorizontalMarkerSlide.DeleteButtonLabel",
    defaultMessage: "Delete",
  },
  PartPossiblyCropped: {
    id: "HorizontalMarkerSlide.PartPossiblyCropped",
    defaultMessage:
      "Partie possiblement croppée en fonction de la résolution de l’écran",
  },
});
