import { navigationBarWidth } from "../../components/Navigation/Navigation.style";

export const resultsContentWidth = 1340;
export const resultsContentPadding = 30;
export const resultsContentPaddingAndNavBar = 30 + navigationBarWidth;

export const defaultStyles = (theme) => ({
  root: {
    fontFamily: theme.FontFamily,
  },
  content: {
    maxWidth: resultsContentWidth,
    padding: `130px ${resultsContentPadding}px 40px ${resultsContentPaddingAndNavBar}px`,
    margin: "auto",
    "& .title": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: 23,
      fontWeight: "bold",
      color: theme.BattleResultsScreen.leaderBoardTitleColor,
      marginBottom: 10,
      textTransform: "uppercase",
    },
    "& .seeMoreButton": {
      fontSize: 16,
      fontWeight: "bold",
      letterSpacing: 0.05,
      color: theme.BattleResultsScreen.seeMoreButtonColor,
      textTransform: "none",
      textDecoration: "underline",
      cursor: "pointer",
    },
    "& .tableContainer": {
      marginBottom: 40,
    },
  },
  loadMoreButton: {
    textAlign: "center",
    padding: 30,
  },
  shiseidoContainerPlaceHolder: {
    display: "flex",
    justifyContent: "end",
    height: 60,
  },
  shiseidoPlaceHolder: {
    width: 445,
    paddingBottom: 10,
    color: "#a0a0a0",
  },
});
