import React from "react";
import PropTypes from "prop-types";
import { SliderPageEditorStyle } from "./SliderPageEditor.style";
import DocumentPageHeader from "../../components/common/DocumentPageHeader";
import { GetDesignPageEncodeData } from "../../utils/ui-generator/generator";
import { PAGE_TYPES } from "../../configs/constants";
import uuidv4 from "uuid/dist/v4";
import classnames from "classnames";
import withThemedStyle from "../hoc/withThemedStyle";

class SliderPageEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      currentActiveSlide: 0,
    };
  }

  handleAddBefore = (sliderIndex) => {
    this.setState((state, props) => {
      let newElement = {
        ...props.getDefaultNewElement(state.cards[sliderIndex]),
      };
      newElement.defaultImage =
        props.defaultSliderImages[(sliderIndex + 1) % 3];
      newElement.image = newElement.defaultImage;
      let currentCards = [...state.cards];
      let newSlidePosition = sliderIndex;
      currentCards.splice(newSlidePosition, 0, newElement);

      let formattedSaveValue = GetDesignPageEncodeData(
        currentCards,
        this.props.pageType,
      );
      props.onDataChanged(
        props.documentId,
        props.language,
        formattedSaveValue,
        props.pageId,
      );

      return {
        cards: currentCards,
        currentActiveSlide: newSlidePosition,
      };
    });
  };

  handleAddAfter = (sliderIndex) => {
    this.setState((state, props) => {
      let newElement = {
        ...props.getDefaultNewElement(state.cards[sliderIndex]),
      };
      newElement.defaultImage =
        props.defaultSliderImages[(sliderIndex + 1) % 3];
      newElement.image = newElement.defaultImage;
      let currentCards = [...state.cards];
      let newSlidePosition = Number(sliderIndex) + 1;
      currentCards.splice(newSlidePosition, 0, newElement);

      let formattedSaveValue = GetDesignPageEncodeData(
        currentCards,
        props.pageType,
      );
      props.onDataChanged(
        props.documentId,
        props.language,
        formattedSaveValue,
        props.pageId,
      );

      return {
        cards: currentCards,
        currentActiveSlide: newSlidePosition,
      };
    });
  };

  handleSelectSlide = (sliderIndex) => {
    this.setState({ currentActiveSlide: sliderIndex });
  };

  handleRemoveSlide = (sliderIndex) => {
    let currentCards = [...this.state.cards];
    currentCards = currentCards.filter((x, index) => index !== sliderIndex);
    let newSlidePosition =
      sliderIndex > 0 ? sliderIndex : Number(sliderIndex) - 1;
    this.setState({
      cards: currentCards,
      currentActiveSlide: newSlidePosition,
    });
    if (currentCards.length > 0) {
      let formattedSaveValue = GetDesignPageEncodeData(
        currentCards,
        this.props.pageType,
      );
      this.props.onDataChanged(
        this.props.documentId,
        this.props.language,
        formattedSaveValue,
        this.props.pageId,
      );
    } else {
      this.props.onRemovePage(
        this.props.documentId,
        this.props.language,
        this.props.pageId,
      );
    }
  };

  handleChange = (sliderValue, index) => {
    let currentValues = [...this.state.cards];

    if (sliderValue.image === "") {
      sliderValue.image = sliderValue.defaultImage;
    }

    currentValues[index] = sliderValue;
    this.setState({
      cards: currentValues,
    });
    let formattedSaveValue = GetDesignPageEncodeData(
      currentValues,
      this.props.pageType,
    );
    this.props.onDataChanged(
      this.props.documentId,
      this.props.language,
      formattedSaveValue,
      this.props.pageId,
    );
  };

  componentDidMount() {
    this.setState({
      cards: this.props.GetDecodedCardsArray(this.props.data),
    });
  }

  render() {
    let {
      slideContainerClassName,
      renderSlideItem,
      UpdateOpenedMarker,
      classes,
    } = this.props;

    const slideContainerClass = classnames([
      classes.sliderContainer,
      slideContainerClassName,
    ]);

    return (
      <div
        className={classes.pageBox}
        onClick={() => this.props.onFocus && this.props.onFocus()}
      >
        <DocumentPageHeader
          pageNumber={this.props.orderId}
          onRemoveClick={() =>
            this.props.onRemovePage(
              this.props.documentId,
              this.props.language,
              this.props.pageId,
            )
          }
          onOrderDownClick={() =>
            this.props.onOrderDownClick(
              this.props.documentId,
              this.props.language,
              this.props.pageId,
            )
          }
          onOrderUpClick={() =>
            this.props.onOrderUpClick(
              this.props.documentId,
              this.props.language,
              this.props.pageId,
            )
          }
        />
        <div className={slideContainerClass}>
          {this.state.cards.map((slide, index) =>
            renderSlideItem(
              slide,
              index,
              uuidv4(),
              this.state.currentActiveSlide === index &&
                this.props.isActivePage,
              (event) => {
                event.preventDefault();
                event.stopPropagation();
                this.handleAddAfter(index);
              },
              (event) => {
                event.preventDefault();
                event.stopPropagation();
                this.handleAddBefore(index);
              },
              (event) => {
                event.preventDefault();
                event.stopPropagation();
                this.handleRemoveSlide(index);
              },
              (value) => this.handleChange(value, index),
              () => this.handleSelectSlide(index),
              (markerIndex, openedState) =>
                UpdateOpenedMarker(index, markerIndex, openedState),
              this.props.currentOpenedMarker &&
                index === this.props.currentOpenedMarker.slideIndex &&
                this.props.pageId === this.props.currentOpenedMarker.pageId &&
                this.props.currentOpenedMarker,
              this.props.pageId,
            ),
          )}
        </div>
      </div>
    );
  }
}

SliderPageEditor.propTypes = {
  /** page Id  */
  pageId: PropTypes.string,
  /** current page is active **/
  isActivePage: PropTypes.bool,
  /** function executed when this page is clicked */
  onFocus: PropTypes.func,
  /** page order id */
  orderId: PropTypes.number,
  /** current page data */
  data: PropTypes.string,
  /** on data changed handler */
  onDataChanged: PropTypes.func,
  /** id of current document */
  documentId: PropTypes.string,
  /** current language */
  language: PropTypes.string,
  /**  event to trigger when a new page is added */
  onRemovePage: PropTypes.func,
  /** class appended to the slide container's classes **/
  slideContainerClassName: PropTypes.string,
  /** extracts each slide's props from the encoded JSON **/
  GetDecodedCardsArray: PropTypes.func.isRequired,
  /** Function that must return an object used to create a new empty slide **/
  getDefaultNewElement: PropTypes.func.isRequired,
  /** images used to create new empty slides **/
  defaultSliderImages: PropTypes.array.isRequired,
  /** function used to render a single slide item **/
  renderSlideItem: PropTypes.func.isRequired,
  /**  the page type to be used for slider  **/
  pageType: PropTypes.oneOf([
    PAGE_TYPES.SLIDERFULLSCREEN,
    PAGE_TYPES.FULLSCREENSCROLL,
  ]),
  /** order up page handler */
  onOrderUpClick: PropTypes.func,
  /** order down page handler */
  onOrderDownClick: PropTypes.func,
  /** the currently opened marker **/
  currentOpenedMarker: PropTypes.object.isRequired,
  /** updates the currently opened marker **/
  UpdateOpenedMarker: PropTypes.func.isRequired,
};

export default withThemedStyle(SliderPageEditorStyle)(SliderPageEditor);
