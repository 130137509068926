export const LinkButtonStyle = (theme) => ({
  linkButton: {
    fontFamily: theme.FontFamily,
    border: "none",
    background: "none",
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "condensed",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    color: theme.LinkButton.textColor,
    cursor: "pointer",
    padding: "0!important",
    textDecoration: "underline",
  },
});
