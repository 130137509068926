import gql from "graphql-tag";

export const LESSON_UPDATE_RECAP = gql`
  mutation lessonUpdateRecap($lessonId: ID, $lang: LangEnum, $data: String!) {
    admin {
      lesson(lessonId: $lessonId, lang: $lang) {
        set(type: lessonRecap, data: $data)
      }
    }
  }
`;
