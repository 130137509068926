export default {
  pageBox: {
    width: 800,
    position: "relative",
    paddingBottom: 30,
  },
  ninjaIntro: {
    paddingBottom: 30,
  },
};
