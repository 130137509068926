import React from "react";
import { DocumentPageHeaderStyle } from "./DocumentPageHeader.style";
import PropTypes from "prop-types";
import { Icons, Icon } from "genius-ui";

import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(DocumentPageHeaderStyle);

const DocumentPageHeader = (props) => {
  const showOrderButtons = props.onOrderDownClick && props.onOrderUpClick;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.pageTitleBox}>
      <div>
        <span className={classes.pageTitle}>Page {props.pageNumber}</span>
      </div>
      <div>
        {showOrderButtons && (
          <Icon
            iconName={Icons.arrowOrderUp}
            handleClick={props.onOrderUpClick}
            style={classes.arrowIcon}
          />
        )}
        {showOrderButtons && (
          <Icon
            iconName={Icons.arrowOrderDown}
            handleClick={props.onOrderDownClick}
            style={classes.arrowIcon}
          />
        )}
        {props.onRemoveClick && (
          <Icon
            iconName={Icons.delete}
            handleClick={props.onRemoveClick}
            style={classes.deleteIcon}
          />
        )}
      </div>
    </div>
  );
};

DocumentPageHeader.propTypes = {
  /** page number of the page  */
  pageNumber: PropTypes.number,
  /**  event to triggger when remove icon is clicked */
  onRemoveClick: PropTypes.func,
  /** order up handler */
  onOrderUpClick: PropTypes.func,
  /** order down handler */
  onOrderDownClick: PropTypes.func,
};

export default DocumentPageHeader;
