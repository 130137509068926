export const LessonPageEditorStyle = ({ LessonPageEditor, Colors }) => ({
  rootContainer: {
    minWidth: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "80px",
  },
  pageItemBox: {
    paddingBottom: 10,
    paddingTop: 10,
  },
  componentsBox: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    width: 422,
  },
  draggableDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    paddingTop: 10,
    paddingBottom: 10,
  },
  dragIcon: {
    color: `${Colors.lightBlue}`,
    cursor: "pointer",
    alignSelf: "flex-start",
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 10,
    },
    "&::before": {
      color: `${Colors.navigationMenu.inactiveItem}`,
      fontSize: 10,
    },
  },
  placeholder: {
    width: 415,
    minHeight: 50,
    mixBlendMode: "source-in",
    border: `solid 1px ${LessonPageEditor.placeHolderBorderColor}`,
    backgroundColor: LessonPageEditor.placeHolderBackgroundColor,
  },
});
