import { RESET_STORE } from "../../constants/store/auth";
import {
  SET_EDITED_NINJA_PAGE_ID,
  SET_NINJA_DOCUMENT_ID,
  SET_NINJA_DOCUMENT_LANG,
  SET_NINJA_MASTER_FLAG,
  SET_NINJA_QUESTIONS,
  SET_NINJA_INTRO,
} from "../../constants/store/editNinja";

const initialState = {
  documentId: null,
  lang: null,
  editedPageId: null,
  intro: {
    theme: "",
    title: "",
    description: "",
    image: "",
  },
  questions: [],
};

export default (state = initialState, action = {}) => {
  const { data, type } = action;

  switch (type) {
    case SET_EDITED_NINJA_PAGE_ID: {
      return {
        ...state,
        editedPageId: data,
      };
    }
    case SET_NINJA_QUESTIONS: {
      return {
        ...state,
        questions: data,
      };
    }
    case SET_NINJA_MASTER_FLAG: {
      return {
        ...state,
        isMasterDocument: data,
      };
    }
    case SET_NINJA_DOCUMENT_ID: {
      return {
        ...state,
        documentId: data,
      };
    }
    case SET_NINJA_DOCUMENT_LANG: {
      return {
        ...state,
        lang: data,
      };
    }
    case SET_NINJA_INTRO: {
      return {
        ...state,
        intro: data,
      };
    }
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
