import gql from "graphql-tag";

export const LESSON_UPDATE_VIDEO_COVER = gql`
  mutation lessonUpdateVideoCover(
    $lessonId: ID
    $lang: LangEnum
    $data: String!
  ) {
    admin {
      lesson(lessonId: $lessonId, lang: $lang) {
        videoCover(data: $data)
      }
    }
  }
`;
