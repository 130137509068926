import gql from "graphql-tag";

export const CARD_UPDATE_PIC_COVER = gql`
  mutation cardUpdatePicCover($cardId: ID, $lang: LangEnum, $data: String!) {
    admin {
      card(cardId: $cardId, lang: $lang) {
        picCard(data: $data)
        picCover(data: $data)
        picThumb(data: $data)
      }
    }
  }
`;
