import React from "react";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";
import { HikerManGrey } from "assets/icons";
import { PageNotFoundStyle } from "./style";
import { KPIPageContainer } from "../../components/KPI";
import { Button } from "genius-ui";
import { withRouter } from "react-router-dom";
import { translations } from "./PageNotFound.translations";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(PageNotFoundStyle);

const PageNotFound = (props) => {
  const { history } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <>
      <KPIPageContainer>
        <div className={classes.container}>
          <img src={HikerManGrey} className={classes.image} />

          <FormattedMessage {...translations.TitleLabel}>
            {(message) => <p className={classes.title}>{message[0]}</p>}
          </FormattedMessage>
          <FormattedMessage {...translations.DescriptionLabel}>
            {(message) => <p className={classes.description}>{message[0]}</p>}
          </FormattedMessage>
          <Button
            variation="primary"
            size="big"
            handleClick={() => history.push("/")}
            className={classes.button}
          >
            <FormattedMessage {...translations.ButtonLabel}>
              {(message) => <p className={classes.buttonText}>{message[0]}</p>}
            </FormattedMessage>
          </Button>
        </div>
      </KPIPageContainer>
    </>
  );
};

export default withRouter(PageNotFound);
