import { defineMessages } from "react-intl";

export const translations = defineMessages({
  ReadyToUseLabel: {
    id: "PublicationButtons.ReadyToUseLabel",
    defaultMessage: "Ready to use",
  },
  SaveButton: {
    id: "PublicationButtons.SaveButton",
    defaultMessage: "Save",
  },
});
