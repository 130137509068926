import { defineMessages } from "react-intl";

export const PollMessages = defineMessages({
  PollSimpleQuestionTemplateTitle: {
    id: "Poll.PollSimpleQuestionTemplateTitle",
    defaultMessage: "Small text",
  },
  PollLongQuestionTemplateTitle: {
    id: "Poll.PollLongQuestionTemplateTitle",
    defaultMessage: "Long text",
  },
  PollQuestionWithMediaTemplateTitle: {
    id: "Poll.PollQuestionWithMediaTemplateTitle",
    defaultMessage: "With media",
  },
});
