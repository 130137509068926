export const ButtonStyle = ({ TransparentButton }) => ({
  button: {
    height: 35,
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "condensed",
    lineHeight: "normal",
    letterSpacing: 0.16,
    color: TransparentButton.textColor,
    textTransform: "uppercase",
    margin: 0,
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      transform: "scale(1.01) translateY(-2px)",
    },
  },
});
