import gql from "graphql-tag";

export const MOVE_DOWN = gql`
  mutation lessonMoveDown($lang: LangEnum, $programId: ID, $lessonId: ID!) {
    admin {
      program(programId: $programId, lang: $lang) {
        downLesson(lessonId: $lessonId) {
          programId
        }
      }
    }
  }
`;
