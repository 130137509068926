import React, { useCallback } from "react";
import TagFilter from "../TagFilter/TagFilter";
import clientConfig from "configs/client";
import StatusFilter from "../StatusFilter/StatusFilter";

const FilterHeader = ({
  handleParamsChange,
  brandId,
  marketId,
  brand,
  market,
  status,
  setStatus,
}) => {
  const handleMarketChange = useCallback(
    (tag) => {
      handleParamsChange("marketId", tag?.value);
    },
    [handleParamsChange],
  );
  const handleBrandChange = useCallback(
    (tag) => {
      handleParamsChange("brandId", tag?.value);
    },
    [handleParamsChange],
  );
  const handleStatusChange = useCallback(
    (status) => {
      setStatus(status.map(({ value }) => value));
    },
    [setStatus],
  );

  return (
    <div style={{ display: "flex", width: "100%" }}>
      {!!market && (
        <TagFilter
          clusterId={clientConfig.USER_MARKET_CLUSTER_IDS}
          isClearable={false}
          handleChange={handleMarketChange}
          value={marketId}
        />
      )}
      {!!brand && (
        <TagFilter
          clusterId={clientConfig.BRAND_TAGS_CLUSTER_IDS}
          handleChange={handleBrandChange}
          value={brandId}
        />
      )}
      {!!status && <StatusFilter handleChange={handleStatusChange} />}
    </div>
  );
};

export default FilterHeader;
