import React, { useState } from "react";
import { getPath } from "../../core/paths";
import { createUseStyles } from "react-jss";
import Style from "./EditBattle.style";
import { Icon, Icons } from "genius-ui";
import BattleEditor from "../../components/Battle/BattleEditor";
import PublishPreviewBanner from "../../components/PublishPreviewBanner";
import { Redirect } from "react-router-dom";
import useThemedStyle from "../../hooks/style/useThemedStyle";
import { DOCUMENT_LEVEL } from "../../configs/constants";

const useStyle = createUseStyles(Style);

const EditBattle = (props) => {
  const {
    questions,
    SetQuestions,
    NavigateToPreviousPage,
    match,
    documentLevel,
  } = props;
  const classes = useThemedStyle(useStyle, props);
  const [redirectToPublishPage, setRedirectToPublishPage] = useState(false);

  return (
    <>
      {redirectToPublishPage && (
        <Redirect
          to={
            documentLevel === DOCUMENT_LEVEL.MASTER
              ? getPath(
                  "publishMasterBattle",
                  match.params.id,
                  match.params.lang,
                )
              : getPath("publishBattle", match.params.id, match.params.lang)
          }
        />
      )}

      <PublishPreviewBanner
        onPreviewButtonClick={() => {}}
        onPublishButtonClick={() => setRedirectToPublishPage(true)}
      >
        <Icon
          iconName={Icons.arrowLeft}
          style={classes.closeIcon}
          handleClick={() => NavigateToPreviousPage()}
        />
      </PublishPreviewBanner>
      <div className={classes.battleEditorContainer}>
        <BattleEditor
          className={classes.battleEditor}
          questions={questions}
          onQuestionsChange={SetQuestions}
          isActive
        />
      </div>
    </>
  );
};

export default EditBattle;
