import { RESET_STORE } from "../../constants/store/auth";
import { UPDATE_PAGE_DATA } from "../../constants/store/kpiAskDetails";

const initialState = {
  geniusQuestion: null,
  expertQuestion: null,
  expertAnswer: null,
  pendingQuestionsToExperts: null,
  topTags: {
    genius: [],
    expert: [],
  },
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case UPDATE_PAGE_DATA: {
      return {
        ...state,
        ...data,
      };
    }
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
