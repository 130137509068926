export const APP_ANALYTICS_NAMESPACE = "APP_ANALYTICS";
export const APP_ANALYTICS_SET_ACTIVE_DEVICES = `${APP_ANALYTICS_NAMESPACE}_SET_ACTIVE_DEVICES`;
export const APP_ANALYTICS_SET_DAILY_SESSIONS_PER_DEVICE = `${APP_ANALYTICS_NAMESPACE}_SET_DAILY_SESSIONS_PER_DEVICE`;
export const APP_ANALYTICS_SET_SESSIONS_DURATION_DISTRIBUTION = `${APP_ANALYTICS_NAMESPACE}_SET_SESSIONS_DURATION_DISTRIBUTION`;

export const OS_FILTER_VALUES = {
  ALL_OS: "all",
  IOS: "iOS",
  ANDROID: "android",
};

export const OS_FILTER_NAME_STORE_ATTRIBUTE_MAPPING = {
  [OS_FILTER_VALUES.ALL_OS]: "allOS",
  [OS_FILTER_VALUES.IOS]: "ios",
  [OS_FILTER_VALUES.ANDROID]: "android",
};

export const TIME_FILTER_VALUES = {
  LAST_7_DAYS: 7,
  LAST_30_DAYS: 30,
  LAST_60_DAYS: 60,
  LAST_90_DAYS: 90,
};

export const VERSION_FILTER_DEFAULT_VERSION_VALUE = {
  value: null,
  label: "AllVersions",
};

export const FILTERS = {
  os: {
    name: "os",
    initialState: {
      value: OS_FILTER_VALUES.ALL_OS,
      label: "AllOS",
    },
    availableValues: [
      {
        value: OS_FILTER_VALUES.ALL_OS,
        label: "AllOS",
      },
      {
        value: OS_FILTER_VALUES.IOS,
        label: "iOS",
      },
      {
        value: OS_FILTER_VALUES.ANDROID,
        label: "Android",
      },
    ],
  },
  iOSVersion: {
    name: "iOSVersion",
    initialState: VERSION_FILTER_DEFAULT_VERSION_VALUE,
    availableValues: [VERSION_FILTER_DEFAULT_VERSION_VALUE],
  },
  androidVersion: {
    name: "androidVersion",
    initialState: VERSION_FILTER_DEFAULT_VERSION_VALUE,
    availableValues: [VERSION_FILTER_DEFAULT_VERSION_VALUE],
  },
  time: {
    name: "time",
    initialState: {
      value: TIME_FILTER_VALUES.LAST_7_DAYS,
      label: "Last7Days",
    },
    availableValues: [
      {
        value: TIME_FILTER_VALUES.LAST_7_DAYS,
        label: "Last7Days",
      },
      {
        value: TIME_FILTER_VALUES.LAST_30_DAYS,
        label: "Last30Days",
      },
      {
        value: TIME_FILTER_VALUES.LAST_60_DAYS,
        label: "Last60Days",
      },
      {
        value: TIME_FILTER_VALUES.LAST_90_DAYS,
        label: "Last90Days",
      },
    ],
  },
};
