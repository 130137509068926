import gql from "graphql-tag";

export const UPDATE_LESSON_PIC_COVER = gql`
  mutation updateLessonPicCover(
    $lessonId: ID
    $lang: LangEnum
    $data: String!
  ) {
    admin {
      lesson(lessonId: $lessonId, lang: $lang) {
        picCard(data: $data)
        picCover(data: $data)
        picThumb(data: $data)
      }
    }
  }
`;
