import client from "../../graphql/client";
import { SetErrorMessage } from "../notification/actions";
import { SetFetchIndicator, SetUpdateIndicator } from "../common/actions";
import {
  UPDATE_PUBLISH_DATA,
  RESET_STORE,
} from "../../constants/store/publishPoll";
import { UPDATE_POLL_INFO } from "../../graphql/remote/poll/mutations/UpdatePollInfo.graphql";
import { POLL_UPDATE_WORKFLOW } from "../../graphql/remote/poll/mutations/UpdatePollWorkflow";
import { DOC_WORKFLOW_ENUM } from "../../configs/constants";
import { ADD_TAG } from "../../graphql/remote/poll/mutations/AddTag.graphql";
import { REMOVE_TAG } from "../../graphql/remote/poll/mutations/RemoveTag.graphql";
import { isAfter, parseISODate } from "../../utils/dateUtils";
import { GET_POLL_BY_ID } from "../../graphql/remote/poll/queries/GetPollInfo.graphql";
import { formatPollPublishData } from "./transformers";

export const UpdatePublishData = (data) => (dispatch) =>
  dispatch({
    type: UPDATE_PUBLISH_DATA,
    data,
  });

export const ResetStore = () => (dispatch) => dispatch({ type: RESET_STORE });

export const GetPollInfo = (pollId, language) => async (dispatch) => {
  try {
    dispatch(SetFetchIndicator(true));
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_POLL_BY_ID,
      variables: {
        pollId,
      },
    });

    const currentLanguagePollVersion = result.data.admin.polls[0].versions.find(
      (version) => version.lang === language,
    );
    dispatch(
      UpdatePublishData(formatPollPublishData(currentLanguagePollVersion)),
    );
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while fetching the pol info for poll with id ${pollId}`,
      ),
    );
  }
  dispatch(SetFetchIndicator(false));
};

export const UpdatePollInfo =
  (type, data, onSuccess) => async (dispatch, getState) => {
    const {
      publishData: { pollId, lang },
    } = getState().publishPoll;
    try {
      await client.mutate({
        mutation: UPDATE_POLL_INFO,
        variables: { pollId, lang, type, data },
      });
      onSuccess && onSuccess();
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while updating the poll info with id ${pollId}`,
        ),
      );
    }
  };

export const UpdatePollWorkflow = (onSuccess) => async (dispatch, getState) => {
  const {
    publishData: { pollId, lang, startPostingDate, endPostingDate },
  } = getState().publishPoll;
  const status = isAfter(parseISODate(startPostingDate), new Date())
    ? DOC_WORKFLOW_ENUM.DRAFT
    : DOC_WORKFLOW_ENUM.PUBLISHED;

  try {
    dispatch(SetUpdateIndicator(true));
    await client.mutate({
      mutation: POLL_UPDATE_WORKFLOW,
      variables: {
        pollId,
        lang,
        status,
        dateIn: startPostingDate,
        dateOut: endPostingDate,
      },
    });
    onSuccess && onSuccess();
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while updating the workflow of the poll ${pollId}`,
        e,
      ),
    );
  } finally {
    dispatch(SetUpdateIndicator(false));
  }
};

export const UpdateMasterPollWorkflow = () => async (dispatch, getState) => {
  const {
    publishData: { pollId, lang, workflow },
  } = getState().publishPoll;
  const status =
    workflow === DOC_WORKFLOW_ENUM.DRAFT
      ? DOC_WORKFLOW_ENUM.PUBLISHED
      : DOC_WORKFLOW_ENUM.DRAFT;

  try {
    await client.mutate({
      mutation: POLL_UPDATE_WORKFLOW,
      variables: {
        pollId,
        lang,
        status,
      },
    });
    dispatch(UpdatePublishData({ workflow: status }));
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while updating the workflow of the poll ${pollId}`,
        e,
      ),
    );
  }
};

export const AddTags = (tagIds, onSuccess) => async (dispatch, getState) => {
  const {
    publishData: { pollId, lang },
  } = getState().publishPoll;
  try {
    await client.mutate({
      mutation: ADD_TAG,
      variables: {
        pollId,
        lang,
        tagIds,
      },
    });
    onSuccess && onSuccess();
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while adding tagIds to poll with id ${pollId}`,
        e,
      ),
    );
  }
};

export const AddPollTags =
  (pollId, lang, tagIds, onSuccess) => async (dispatch) => {
    try {
      await client.mutate({
        mutation: ADD_TAG,
        variables: {
          pollId,
          lang,
          tagIds,
        },
      });
      onSuccess && onSuccess();
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while adding tagIds to poll with id ${pollId}`,
          e,
        ),
      );
    }
  };

export const RemoveTags = (tagIds, onSuccess) => async (dispatch, getState) => {
  const {
    publishData: { pollId, lang },
  } = getState().publishPoll;
  try {
    await client.mutate({
      mutation: REMOVE_TAG,
      variables: {
        pollId,
        lang,
        tagIds,
      },
    });
    onSuccess && onSuccess();
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while removing tagIds from poll with id ${pollId}`,
        e,
      ),
    );
  }
};
