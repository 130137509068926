import gql from "graphql-tag";

export const INSIGHT_UPDATE_WORFLOW = gql`
  mutation insightUpdateWorkflow(
    $insightId: ID
    $lang: LangEnum
    $status: DocWorkflowEnum!
    $dateIn: Date
    $dateOut: Date
  ) {
    admin {
      insight(insightId: $insightId, lang: $lang) {
        workflow {
          set(status: $status, dateIn: $dateIn, dateOut: $dateOut)
        }
      }
    }
  }
`;
