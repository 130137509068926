import React from "react";
import PropTypes from "prop-types";
import { SliderPageEditorStyle } from "../SliderPageEditor.style";
import DocumentPageHeader from "../../../components/common/DocumentPageHeader";
import uuidv4 from "uuid/dist/v4";
import classnames from "classnames";
import withThemedStyle from "../../hoc/withThemedStyle";

class SliderPageEditorReadOnly extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      currentActiveSlide: 0,
    };
  }

  handleSelectSlide = (sliderIndex) => {
    this.setState({ currentActiveSlide: sliderIndex });
  };

  componentDidMount() {
    this.setState({
      cards: this.props.GetDecodedCardsArray(this.props.data),
    });
  }

  render() {
    let { slideContainerClassName, renderSlideItem, classes } = this.props;

    const slideContainerClass = classnames([
      classes.sliderContainer,
      slideContainerClassName,
    ]);

    return (
      <div
        className={classes.pageBox}
        onClick={() => this.props.onFocus && this.props.onFocus()}
      >
        <DocumentPageHeader
          pageNumber={this.props.orderId}
          showOrderButtons={false}
        />
        <div className={slideContainerClass}>
          {this.state.cards.map((slide, index) =>
            renderSlideItem(
              slide,
              index,
              uuidv4(),
              this.state.currentActiveSlide === index &&
                this.props.isActivePage,
              undefined,
              undefined,
              undefined,
              undefined,
              () => this.handleSelectSlide(index),
              undefined,
              this.props.currentOpenedMarker &&
                index === this.props.currentOpenedMarker.slideIndex &&
                this.props.pageId === this.props.currentOpenedMarker.pageId &&
                this.props.currentOpenedMarker,
              this.props.pageId,
            ),
          )}
        </div>
      </div>
    );
  }
}

SliderPageEditorReadOnly.propTypes = {
  /** page Id  */
  pageId: PropTypes.string,
  /** current page is active **/
  isActivePage: PropTypes.bool,
  /** function executed when this page is clicked */
  onFocus: PropTypes.func,
  /** page order id */
  orderId: PropTypes.number,
  /** current page data */
  data: PropTypes.string,
  /** class appended to the slide container's classes **/
  slideContainerClassName: PropTypes.string,
  /** extracts each slide's props from the encoded JSON **/
  GetDecodedCardsArray: PropTypes.func.isRequired,
  /** function used to render a single slide item **/
  renderSlideItem: PropTypes.func.isRequired,
  /** the currently opened marker **/
  currentOpenedMarker: PropTypes.object.isRequired,
};

export default withThemedStyle(SliderPageEditorStyle)(SliderPageEditorReadOnly);
