import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

const messages = defineMessages({
  ThisDay: {
    id: "KPITimePeriods.ThisDay",
    defaultMessage: "This day",
  },

  ThisWeek: {
    id: "KPITimePeriods.ThisWeek",
    defaultMessage: "This week",
  },
  ThisMonth: {
    id: "KPITimePeriods.ThisMonth",
    defaultMessage: "This month",
  },
  LastThreeMonth: {
    id: "KPITimePeriods.LastThreeMonth",
    defaultMessage: "Last 3 months",
  },
  LastSixMonth: {
    id: "KPITimePeriods.LastSixMonth",
    defaultMessage: "Last 6 months",
  },
  LastNineMonth: {
    id: "KPITimePeriods.LastNineMonth",
    defaultMessage: "Last 9 months",
  },
  LastYear: {
    id: "KPITimePeriods.LastYear",
    defaultMessage: "Last year",
  },
  YearToDate: {
    id: "KPITimePeriods.YearToDate",
    defaultMessage: "Year-to-date",
  },
});

export const KPI_AVAILABLE_PERIODS = [
  { value: 1, label: <FormattedMessage {...messages.ThisDay} /> },
  { value: 2, label: <FormattedMessage {...messages.ThisWeek} /> },
  { value: 3, label: <FormattedMessage {...messages.ThisMonth} /> },
  { value: 4, label: <FormattedMessage {...messages.LastThreeMonth} /> },
  { value: 5, label: <FormattedMessage {...messages.LastSixMonth} /> },
  { value: 6, label: <FormattedMessage {...messages.LastNineMonth} /> },
  { value: 7, label: <FormattedMessage {...messages.LastYear} /> },
  { value: 8, label: <FormattedMessage {...messages.YearToDate} /> },
];
