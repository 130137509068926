import gql from "graphql-tag";

export const DEGREE_CLONE_TO_MARKETS = gql`
  mutation DegreeCloneToMarkets(
    $degreeId: ID
    $lang: LangEnum!
    $marketTagId: Int
  ) {
    admin {
      degree(degreeId: $degreeId) {
        cloneDegree(
          lang: $lang
          cloneDegreeItself: true
          cloneTags: false
          cloneTagsFeature: true
          marketTagId: $marketTagId
        )
      }
    }
  }
`;
