import React from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem } from "genius-ui";
import uuidV4 from "uuid/dist/v4";

const SettingsMenu = (props) => {
  const { isVisible, className, isExpanded, handleFocusLeave, menuItems } =
    props;

  return isVisible ? (
    <div className={className}>
      <Menu isExpanded={isExpanded} handleFocusLeave={handleFocusLeave}>
        {menuItems.map(
          (menuItem) =>
            !menuItem.hidden && (
              <MenuItem
                key={uuidV4()}
                label={menuItem.label}
                icon={menuItem.icon}
                handleClick={(e) => {
                  menuItem.onItemClick();
                  handleFocusLeave();
                  e.stopPropagation();
                }}
                disabled={menuItem.disabled}
                iconStyle={menuItem.iconStyle}
              />
            ),
        )}
      </Menu>
    </div>
  ) : null;
};

SettingsMenu.propTypes = {
  isVisible: PropTypes.bool,
  className: PropTypes.string,
  isExpanded: PropTypes.bool,
  handleFocusLeave: PropTypes.func.isRequired,
  menuItems: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      icon: PropTypes.string,
      onItemClick: PropTypes.func,
      hidden: PropTypes.bool,
      disabled: PropTypes.bool,
      /** className for styling */
      iconStyle: PropTypes.string,
    }),
  ),
};

SettingsMenu.defaultProps = {
  menuItems: [],
  isExpanded: true,
};

export default SettingsMenu;
