import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import { Accordion } from "genius-ui";
import Style from "./WhiteAccordion.style.js";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const WhiteAccordion = (props) => {
  const classes = useThemedStyle(useStyle, props);

  return (
    <Accordion
      {...props}
      className={classNames([classes.accordion, props.className])}
    />
  );
};

WhiteAccordion.propTypes = {
  /* item headings have a bottom border */
  striped: PropTypes.bool,
  // Style wrapper around Accordion. Accepts all the props of Accordion...
};

WhiteAccordion.defaultProps = {
  striped: false,
};

export default WhiteAccordion;
