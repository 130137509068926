import { defineMessages } from "react-intl";

export const translations = defineMessages({
  TitleVideoUpload: {
    id: "Video.TitleVideoUpload",
    defaultMessage: "Video upload",
  },
  DescriptionVideoImport: {
    id: "Video.DescriptionVideoImport",
    defaultMessage: "Import",
  },
  TitleVideoConversion: {
    id: "Video.TitleVideoConversion",
    defaultMessage: "Video conversion",
  },
  DescriptionVideoCompress: {
    id: "Video.DescriptionVideoCompress",
    defaultMessage: "Compress",
  },
  AddThumbnailText: {
    id: "Video.AddThumbnailText",
    defaultMessage: "Add a thumbnail",
  },
  UploadVideoText: {
    id: "Video.UploadVideoText",
    defaultMessage: "Upload Video",
  },
  InvalidFileMessage: {
    id: "Video.InvalidFileMessage",
    defaultMessage: "Invalid file",
  },
  InvalidFileFormatMessage: {
    id: "Video.InvalidFileFormatMessage",
    defaultMessage: "Invalid file format",
  },
  InvalidFileSizeMessage: {
    id: "Video.InvalidFileSizeMessage",
    defaultMessage: "Invalid file size",
  },
});
