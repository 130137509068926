import { defineMessages } from "react-intl";

export const translations = defineMessages({
  TitlePlaceholder: {
    id: "CreateFile.TitlePlaceholder",
    defaultMessage: "Name your document",
  },
  ScheduleButtonText: {
    id: "CreateFile.ScheduleButtonText",
    defaultMessage: "Schedule to publish",
  },
  CountrySelectorLabel: {
    id: "CreateFile.CountrySelectorLabel",
    defaultMessage: "Publication Country",
  },
  LangSelectorLabel: {
    id: "CreateFile.LangSelectorLabel",
    defaultMessage: "Language",
  },
  CategoryLabel: {
    id: "CreateFile.CategoryLabel",
    defaultMessage: "Category",
  },
  SubCategoryLabel: {
    id: "CreateFile.SubCategoryLabel",
    defaultMessage: "Sub category",
  },
  BrandLabel: {
    id: "CreateFile.BrandLabel",
    defaultMessage: "Brand",
  },
  AddBrandPlaceholder: {
    id: "CreateFile.AddBrandPlaceholder",
    defaultMessage: "Add a brand",
  },
  DescriptionLabel: {
    id: "CreateFile.DescriptionLabel",
    defaultMessage: "Description",
  },
  AddDescriptionPlaceholder: {
    id: "CreateFile.AddDescriptionPlaceholder",
    defaultMessage: "Add a description",
  },
  StartPostingDateLabel: {
    id: "CreateFile.StartPostingDateLabel",
    defaultMessage: "Posting Date",
  },
  FileUploaded: {
    id: "CreateFile.FileUploadedText",
    defaultMessage: "File uploaded",
  },
  FileHeavy: {
    id: "CreateFile.FileHeavyText",
    defaultMessage: "File is heavy (more than 15mo)",
  },
});
