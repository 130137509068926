import * as constants from "../../constants/store/pollResults";
import client from "../../graphql/client";
import { SetErrorMessage } from "../notification/actions";
import { GET_POLL_RESULT } from "../../graphql/remote/poll/queries/GetPollResult.graphql";
import {
  GetFormattedPollInfo,
  GetFormattedPollQuestions,
} from "./transformers";
import { getFormattedSopiDate } from "../../utils/dateUtils";

export const UpdatePollResults = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_POLL_RESULTS,
    data,
  });
};

export const UpdateSelectedPeriodPollResult = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_SELECTED_PERIOD_POLL_RESULT,
    data,
  });
};

export const UpdatePollInfo = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_POLL_INFO,
    data,
  });
};

export const ResetStore = () => (dispatch) => {
  dispatch({ type: constants.RESET_STORE });
};

export const GetPollResults =
  (pollId, callback) => async (dispatch, getState) => {
    try {
      const { selectedPeriod, selectedStartDate, selectedEndDate } =
        getState().pollResults.info;
      if (!selectedPeriod && !selectedEndDate) {
        callback && callback();
        return null;
      }
      const variables = {
        pollId,
        intervalId: selectedPeriod?.value,
        startDate: selectedPeriod
          ? ""
          : getFormattedSopiDate(selectedStartDate),
        endDate: selectedPeriod ? "" : getFormattedSopiDate(selectedEndDate),
      };
      const response = await client.query({
        fetchPolicy: "network-only",
        query: GET_POLL_RESULT,
        variables,
        notifyOnNetworkStatusChange: true,
      });

      dispatch(
        UpdatePollResults(
          GetFormattedPollQuestions(
            response.data.admin.pollByInterval[0].stats.instances?.length
              ? response.data.admin.pollByInterval[0].stats.instances?.[0]
              : { questions: [] },
          ),
        ),
      );
      dispatch(
        UpdatePollInfo(
          GetFormattedPollInfo(response.data.admin.pollByInterval[0]),
        ),
      );
      callback && callback();
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while fetching the poll result data",
          e,
        ),
      );
      console.error("error", e);
    }
  };
