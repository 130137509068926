import { defineMessages } from "react-intl";

export const translations = defineMessages({
  PublicationSettings: {
    id: "Publish.PublicationSettings",
    defaultMessage: "Publication settings",
  },
  LanguageLabel: {
    id: "Publish.LanguageLabel",
    defaultMessage: "Language",
  },
  BrandLabel: {
    id: "Publish.BrandLabel",
    defaultMessage: "Brand",
  },
  EntityLabel: {
    id: "Publish.EntityLabel",
    defaultMessage: "Entity",
  },
  RetailerLabel: {
    id: "Publish.RetailerLabel",
    defaultMessage: "Retailer",
  },
  DivisionLabel: {
    id: "Publish.DivisionLabel",
    defaultMessage: "Division",
  },
  PublicationCountriesLabel: {
    id: "Publish.PublicationCountriesLabel",
    defaultMessage: "Countries",
  },
  PublicationZonesLabel: {
    id: "Publish.PublicationZonesLabel",
    defaultMessage: "Zones",
  },
  ProfessionsLabel: {
    id: "Publish.ProfessionsLabel",
    defaultMessage: "Profession",
  },
  AssociatedTagsLabel: {
    id: "Publish.AssociatedTagsLabel",
    defaultMessage: "Associated Tags",
  },
  UserCategoryLabel: {
    id: "Publish.UserCategoryLabel",
    defaultMessage: "User Category",
  },
  PublicButtonLabel: {
    id: "Publish.PublicButtonLabel",
    defaultMessage: "Public",
  },
  ScheduleButtonText: {
    id: "Publish.ScheduleButtonText",
    defaultMessage: "Schedule to PublishInsight",
  },
  CancelScheduleButtonText: {
    id: "Publish.CancelScheduleButtonText",
    defaultMessage: "Cancel scheduling",
  },
  AddBrandPlaceholder: {
    id: "Publish.AddBrandPlaceholder",
    defaultMessage: "Add a brand",
  },
  UniverseLabel: {
    id: "Publish.UniverseLabel",
    defaultMessage: "Universe",
  },
  AddEntityPlaceholder: {
    id: "Publish.AddEntityPlaceholder",
    defaultMessage: "Add an entity type",
  },
  AddRetailerPlaceholder: {
    id: "Publish.AddRetailerPlaceholder",
    defaultMessage: "Add a retailer",
  },
  AddDivisionPlaceholder: {
    id: "Publish.AddDivisionPlaceholder",
    defaultMessage: "Add a division",
  },
  SelectAssociatedTagsPlaceholder: {
    id: "Publish.SelectAssociatedTagsPlaceholder",
    defaultMessage: "Select associated tags",
  },
  SelectUserCategoriesPlaceholder: {
    id: "Publish.SelectUserCategoriesPlaceholder",
    defaultMessage: "Select user categories",
  },
  SelectCountriesPlaceholder: {
    id: "Publish.SelectCountriesPlaceholder",
    defaultMessage: "Select countries",
  },
  SelectZonesPlaceholder: {
    id: "Publish.SelectZonesPlaceholder",
    defaultMessage: "Select zones",
  },
  SelectProfessionsPlaceholder: {
    id: "Publish.SelectProfessionsPlaceholder",
    defaultMessage: "Select professions",
  },
  SuccessfulPublishInsightedMessage: {
    id: "Publish.SuccessfulPublishInsightedMessage",
    defaultMessage: "Your insight has been PublishInsighted",
  },
  TitlePlaceholder: {
    id: "Publish.TitlePlaceholder",
    defaultMessage: "Enter title",
  },
  SummaryPlaceholder: {
    id: "Publish.SummaryPlaceholder",
    defaultMessage: "Enter summary",
  },
  IncorrectDateMessage: {
    id: "Publish.IncorrectDateMessage",
    defaultMessage: "End posting date can't be before the start posting date",
  },
  StartPostingDateLabel: {
    id: "Publish.StartPostingDateLabel",
    defaultMessage: "Start posting date",
  },
  EndPostingDateLabel: {
    id: "Publish.EndPostingDateLabel",
    defaultMessage: "End posting date",
  },
  NoEndPostingDateLabel: {
    id: "Publish.NoEndPostingDateLabel",
    defaultMessage: "No end posting date",
  },
  RoleLabel: {
    id: "Publish.RoleLabel",
    defaultMessage: "Roles",
  },
  AddRolesPlaceholder: {
    id: "Publish.AddRolesPlaceholder",
    defaultMessage: "Select roles",
  },
  WhatRememberLabel: {
    id: "Publish.WhatRememberLabel",
    defaultMessage: "What to Remember",
  },
  WhatRememberPlaceholder: {
    id: "Publish.WhatRememberPlaceholder",
    defaultMessage: "Enter remarks",
  },
  BoutiqueLabel: {
    id: "Publish.BoutiqueLabel",
    defaultMessage: "Location",
  },
  NoBoutiquesAddedText: {
    id: "Publish.NoBoutiquesAddedText",
    defaultMessage: "No locations added",
  },
  AddCustomRecapLabel: {
    id: "Publish.AddCustomRecapLabel",
    defaultMessage: "Add a custom recap",
  },
  IndicateBrandsWarning: {
    id: "Publish.IndicateBrandsWarning",
    defaultMessage:
      "Please indicate which brand or brands this content should be associated with before PublishInsighting.",
  },
});
