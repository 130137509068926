export const mapTagToCheckDropdownOptionCreator =
  (withClusterId = true) =>
  ({ tagId, title, clusterId }) => ({
    value: withClusterId ? `${tagId}-${clusterId}` : `${tagId}`,
    label: title,
  });

export const mapTagToCheckDropdownOption = mapTagToCheckDropdownOptionCreator();
export const mapTagToCheckDropdownOptionWithoutClusterId =
  mapTagToCheckDropdownOptionCreator(false);

export const mapTagToCheckDropdownOptionWithPicture = ({
  tagId,
  title,
  clusterId,
  pic,
}) => ({
  value: tagId,
  label: title,
  picture: pic,
});

export const extractTagIdFromCheckDropdownOption = (checkDropdownValue) =>
  checkDropdownValue.value;

export const generateSelectValueKey = (selectedValue) =>
  selectedValue
    ? selectedValue.map(extractTagIdFromCheckDropdownOption).join("_")
    : "";

export const mapCheckDropdownOptionToTKpiTagParameter = (value) => {
  return {
    tagIds: value.map((y) => parseInt(y.value.split("-")[0])),
    clusterIds: [...new Set(value.map((y) => parseInt(y.value.split("-")[1])))],
  };
};

export const calculateTagRank = (tags, rankAttributeName = "nb") => {
  /** determine the rank of each tag: if tags are sorted in ascending order by the attribute with the name
   * @rankAttributeName, the rank is the index of the tag inside the sorted array */
  /** copy the original tags because they will be mutated by the rank determination algorithm */
  const copiedTags = tags.map((tag) => ({ ...tag }));
  /** copy tags again, to keep the original order(the sort will mutate the original array thus the order of the tags) */
  const rankedTags = [...copiedTags];
  copiedTags
    .sort((tag1, tag2) => tag1[rankAttributeName] - tag2[rankAttributeName])
    .map((tag, index) => {
      tag.rank = index + 1;
      return tag;
    });

  return rankedTags;
};

export const getSelectedFilterTagIds = (filters) =>
  Object.values(filters).reduce(
    (tagIds, { selectedValues }) =>
      selectedValues && selectedValues.length
        ? [
            ...tagIds,
            {
              tagIds: selectedValues.map(({ value }) =>
                parseInt(value.split("-")[0]),
              ),
            },
          ]
        : tagIds,
    [],
  );

export const filterTagsByTrainerTags = (userProfileTags, tags) => {
  const userProfileTagIds = userProfileTags.map((tag) => tag.tagId);
  return tags.filter((tag) => userProfileTagIds.includes(tag.tagId));
};

export const extendTagsWithUserTags = (targetTags, userProfileTags) => {
  return targetTags.tagIds.length
    ? targetTags
    : {
        tagIds: userProfileTags.map((tag) => parseInt(tag.tagId)),
        clusterIds: userProfileTags.map((tag) => parseInt(tag.clusterId)),
      };
};

export const mapTagToGetTagId = (tag) => parseInt(tag.value.split("-")[0]);
