export const PeanutPageEditorStyle = ({
  PeanutPageEditor,
  FontFamily,
  Colors,
}) => ({
  pageBox: {
    display: "flex",
    flexDirection: "column",
    color: PeanutPageEditor.pageBoxColor,
    width: 800,
  },
  sliderContainer: {
    display: "flex",
    maxHeight: 737,
    marginTop: 10,
    marginBottom: 10,
  },
  itemText: {
    padding: 20,
    fontFamily: FontFamily,
    fontSize: 15,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "condensed",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: PeanutPageEditor.itemTextColor,
    textTransform: "uppercase",
    "&:hover": {
      color: `${Colors.lightBlue}`,
    },
  },
  iconSelected: {
    padding: 2,
    fontSize: 35,
    color: `${Colors.lightBlue}`,
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 25,
    },
    "&::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 25,
    },
  },
});
