// INSIGHTS
export const SET_CURRENT_INSIGHT_PAGEID = "SET_CURRENT_INSIGHT_PAGEID";
export const SET_CURRENT_INSIGHT_ID = "SET_CURRENT_INSIGHT_ID";
export const SET_CURRENT_INSIGHT_LANGUAGE = "SET_CURRENT_INSIGHT_LANGUAGE";
export const SET_PUBLISH_INSIGHT_INFO = "SET_PUBLISH_INSIGHT_INFO";
export const UPDATE_PUBLISH_INSIGHT_INFO = "UPDATE_PUBLISH_INSIGHT_INFO";
export const SET_CURRENT_INSIGHT_PAGES = "SET_CURRENT_INSIGHT_PAGES";
export const UPDATE_CURRENT_INSIGHT_PAGE = "UPDATE_CURRENT_INSIGHT_PAGE";
export const ADD_CURRENT_INSIGHT_PAGE = "ADD_CURRENT_INSIGHT_PAGE";
export const REMOVE_CURRENT_INSIGHT_PAGE = "REMOVE_CURRENT_INSIGHT_PAGE";
export const UPDATE_CURRENT_OPENED_MARKER = "UPDATE_CURRENT_OPENED_MARKER";
export const UPDATE_VISIBLE_TAG_SELECTOR = "UPDATE_VISIBLE_TAG_SELECTOR";
export const SET_ACTIVE_MENU_FOR_INSIGHT_ID = "SET_ACTIVE_MENU_FOR_INSIGHT_ID";
export const SET_INSIGHT_MASTER_FLAG = "SET_INSIGHT_MASTER_FLAG";
