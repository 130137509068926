export const SET_ALL_TAGS = "SET_ALL_TAGS";
export const SET_USER_TAGS = "SET_USER_TAGS";
export const SET_COUNTRY_TAGS = "SET_COUNTRY_TAGS";
export const SET_RELATIONSHIP_TAGS = "SET_RELATIONSHIP_TAGS";
export const SET_MARKET_TAGS = "SET_MARKET_TAGS";
export const SET_ZONE_TAGS = "SET_ZONE_TAGS";
export const SET_RETAILER_TAGS = "SET_RETAILER_TAGS";
export const SET_BRAND_TAGS = "SET_BRAND_TAGS";
export const SET_CITIES_TAGS = "SET_CITIES_TAGS";
export const SET_SHOPS_TAGS = "SET_SHOPS_TAGS";
export const SET_DIVISION_TAGS = "SET_DIVISION_TAGS";
export const SET_ENTITY_TAGS = "SET_ENTITY_TAGS";
export const SET_DEPARTMENT_TAGS = "SET_DEPARTMENT_TAGS";
export const SET_SERVICE_TAGS = "SET_SERVICE_TAGS";
export const SET_ROLE_TAGS = "SET_ROLE_TAGS";
export const SET_UNIVERSE_TAGS = "SET_UNIVERSE_TAGS";
export const SET_VMSEGMENTATION_TAGS = "SET_VMSEGMENTATION_TAGS";
export const SET_JOURNAL_TAGS = "SET_JOURNAL_TAGS";
export const SET_OCCUPATION_TAGS = "SET_OCCUPATION_TAGS";
export const RESET_TAGS = "RESET_TAGS";
