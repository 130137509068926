export default ({ SimpleQuizAnswer, FontFamily }) => ({
  choice: {
    display: "flex !important",
    justifyContent: "space-between",
    alignItems: "center",
    width: 21,
    paddingLeft: "0px !important",
    "& span.icon-recycle": {
      fontSize: 14,
      color: ({ isBGDark }) =>
        isBGDark
          ? SimpleQuizAnswer.choiceColorDark
          : SimpleQuizAnswer.choiceColor,
      marginLeft: 20,
    },
  },
  choiceInput: {
    minHeight: 21,
    maxHeight: 45,
    overflow: "hidden",
    outline: "unset",
    width: 243,
    color: ({ isBGDark }) =>
      isBGDark
        ? SimpleQuizAnswer.choiceInputColorDark
        : SimpleQuizAnswer.choiceInputColor,
    "&[contenteditable]:empty:before": {
      content: "attr(placeholder)",
      display: "block",
    },
  },
  addNewAnswerChoice: {
    fontFamily: FontFamily,
    fontSize: "16px !important",
    fontWeight: "normal !important",
    fontStyle: "normal !important",
    fontStretch: "condensed !important",
    lineHeight: "normal !important",
    letterSpacing: "normal !important",
    textDecoration: "underline",
    textDecorationColor: ({ isBGDark }) =>
      isBGDark
        ? SimpleQuizAnswer.addNewAnswerChoiceColorDark
        : SimpleQuizAnswer.addNewAnswerChoiceColor,
    "& > span": {
      color: ({ isBGDark }) =>
        isBGDark
          ? SimpleQuizAnswer.addNewAnswerChoiceColorDark
          : SimpleQuizAnswer.addNewAnswerChoiceColor,
    },
  },
  label: {
    position: "relative",
    marginLeft: 81,
    cursor: "pointer",
    display: "inline-block",
    fontFamily: FontFamily,
    fontSize: 18,
  },
  delete: {
    cursor: "pointer",
  },
});
