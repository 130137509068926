export const Permissions = {
  PAGE_DEGREE: {
    CREATE_BUTTON: "CREATE_BUTTON",
  },
  PAGE_PUBLISH_LESSON: {
    SET_PRIVACY_BUTTON: "SET_PRIVACY_BUTTON",
  },
};

export const ExecutionRights = {
  EXECUTE: "EXECUTE",
  DISABLED: "DISABLED",
  NO_ACCESS: "NO_ACCESS",
};
