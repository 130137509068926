export const backgroundGradient =
  "linear-gradient(rgba(42, 42, 42, 0.3), rgba(42, 42, 42, 0.3))";

const getBackgroundPropsBasedOnBackgroundType = (background) => {
  if (!background?.theme) return {};

  if (background.theme === "header") {
    return {
      backgroundPosition: "top",
      backgroundSize: "100% 220px",
    };
  }

  if (background.theme === "fullScreen") {
    return {
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  }
};

const innerPageContainerPadding = 20;

export default ({ SimpleQuizQuestion, FontFamily, Colors }) => {
  const feedbackMessageColor = ({ endMessage: { background } }) =>
    background && background.theme === "fullScreen"
      ? SimpleQuizQuestion.feedbackFullScreenColor
      : SimpleQuizQuestion.feedbackColor;

  const radioHeaderLabel = {
    fontFamily: FontFamily,
    fontSize: 18,
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    textAlign: "center",
    color: SimpleQuizQuestion.radioHeaderLabelColor,
  };

  return {
    container: {
      display: "flex",
    },
    innerPageContainer: {
      position: "relative",
      margin: "40px 40px 40px 0",
      border: ({ isEditable }) => (isEditable ? "1px solid #468ee3" : "none"),
      padding: `0 ${innerPageContainerPadding}px 0 ${innerPageContainerPadding}px`,
      width: 374,
      height: 737,
      minWidth: 374,
      minHeight: 737,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundRepeat: "no-repeat",
      backgroundColor: SimpleQuizQuestion.innerPageBackgroundColor,
      boxSizing: "content-box",
    },
    questionContainer: ({ background }) => ({
      ...getBackgroundPropsBasedOnBackgroundType(background),
    }),
    resultContainer: ({ endMessage: { background } }) =>
      getBackgroundPropsBasedOnBackgroundType(background),
    uploadImageButton: {
      position: "absolute",
      top: 0,
      left: 0,
    },
    deleteButton: {
      fontFamily: FontFamily,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "baseline",
      alignSelf: "flex-start",
      position: "absolute",
      top: 20,
      right: 10,
      cursor: "pointer",
      "& > span": {
        fontSize: 14,
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: 0.07,
        color: SimpleQuizQuestion.deleteButton.color,
        "&::before": {
          color: SimpleQuizQuestion.deleteButton.color,
          fontSize: 15,
          padding: 5,
        },
      },
      "&:hover *": {
        color: SimpleQuizQuestion.deleteButton.hoverColor,
        "&::before": {
          color: SimpleQuizQuestion.deleteButton.hoverColor,
        },
      },
    },
    deleteButtonDark: {
      "& > span": {
        color: SimpleQuizQuestion.deleteButtonDark.color,
        "&::before": {
          color: SimpleQuizQuestion.deleteButtonDark.color,
        },
      },
      "&:hover *": {
        color: SimpleQuizQuestion.deleteButtonDark.hoverColor,
        "&::before": {
          color: SimpleQuizQuestion.deleteButtonDark.hoverColor,
        },
      },
    },
    changeTemplate: {
      minWidth: 224,
    },
    textInput: {
      fontFamily: FontFamily,
      fontSize: 23,
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.17,
      letterSpacing: "normal",
      textAlign: "center",
      outline: "unset",
      "&[contenteditable]:empty:before": {
        content: "attr(placeholder)",
        display: "block",
      },
    },
    questionInput: ({ background }) => ({
      marginTop: 220,
      width: 374,
      marginBottom: 55,
      minHeight: 27,
      maxHeight: 54,
      overflow: "hidden",
      color:
        background && background.theme === "fullScreen"
          ? SimpleQuizQuestion.questionInput.fullScreenColor
          : SimpleQuizQuestion.questionInput.color,
      opacity: background && background.theme === "fullScreen" ? 0.7 : 1,
    }),
    successMessageInput: {
      color: feedbackMessageColor,
      minHeight: 28,
      maxHeight: 52,
      width: 355,
      overflow: "hidden",
      overflowWrap: "break-word",
      marginTop: 270,
    },
    failedMessageInput: {
      marginTop: 35,
      marginBottom: 35,
      minHeight: 28,
      maxHeight: 52,
      width: 355,
      overflow: "hidden",
      overflowWrap: "break-word",
      color: feedbackMessageColor,
    },
    explanationsInput: {
      fontSize: 18,
      color: ({ endMessage: { background } }) =>
        background && background.theme === "fullScreen"
          ? SimpleQuizQuestion.explanationInput.fullScreenColor
          : SimpleQuizQuestion.explanationInput.color,
      fontWeight: "normal",
      minHeight: 21,
      maxHeight: 42,
      width: 355,
      overflow: "hidden",
      overflowWrap: "break-word",
    },
    answerContainer: {
      width: 374,
    },
    radioHeaderContainer: {
      marginBottom: 20,
      display: "flex",
      flexDirection: "row",
    },
    correctLabel: {
      ...radioHeaderLabel,
      marginRight: 60,
    },
    choiceTextLabel: {
      ...radioHeaderLabel,
    },
    radioContainer: {
      marginLeft: 20,
      "& > div": {
        height: 45,
      },
    },
    coverContainerHidden: {
      display: "none",
    },
    uploadBtnWrapper: {
      top: 19,
      position: "relative",
      overflow: "hidden",
      display: "inline-block",
      cursor: "pointer",
      "&:hover": {
        cursor: "pointer",
      },
    },
    btnUpload: {
      border: "none",
      fontFamily: FontFamily,
      fontSize: 14,
      background: "transparent",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "0.1px",
      color: SimpleQuizQuestion.uploadButtonColor,
      cursor: "pointer",
      "&:hover": {
        cursor: "pointer",
      },
      paddingLeft: 15,
      marginRight: 10,
      "& > span": {
        paddingRight: 5,
        "&:hover::before": {
          color: Colors.lightBlue,
          cursor: "pointer",
        },
      },
      "&:hover *": {
        color: Colors.lightBlue,
        cursor: "pointer",
        "&::before": {
          color: Colors.lightBlue,
          cursor: "pointer",
        },
      },
      outline: "none",
    },
    iconAction: {
      cursor: "pointer",
      "&:hover::before": {
        color: `${Colors.lightBlue}`,
        fontSize: 15,
        cursor: "pointer",
      },
      "&::before": {
        color: `${Colors.white}`,
        fontSize: 15,
        cursor: "pointer",
      },
    },
  };
};
