import gql from "graphql-tag";

export const PROGRAM_CLONE_TO_MARKET = gql`
  mutation cloneProgram(
    $programId: ID
    $lang: LangEnum!
    $marketTagId: Int
    $parentLearningId: ID
  ) {
    admin {
      program(programId: $programId) {
        clone(
          lang: $lang
          parentLearningId: $parentLearningId
          marketTagId: $marketTagId
        ) {
          programId
        }
      }
    }
  }
`;
