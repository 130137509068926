import { defineMessages } from "react-intl";

export const translations = defineMessages({
  SideBarTitle: {
    id: "CreateCardPage.SideBarTitle",
    defaultMessage: "Feed Slider Elements",
  },
  ReadOnlyMessage: {
    id: "CreateCardPage.ReadOnlyMessage",
    defaultMessage: "You are only able to view this document",
  },
});
