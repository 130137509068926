export default ({ Opacities, Colors }) => ({
  "@keyframes selectTagsMustHaveAtLeastOneChoiceInfoAnim": {
    from: { opacity: 0, transform: "translateY(20px)" },
    to: { opacity: 1, transform: "translateY(0)" },
  },
  selectTagsMustHaveAtLeastOneChoice: {
    position: "relative",
    "& .IOBotTagSelectInput__value-container": {
      overflow: "visible",
    },
    "& .IOBotTagSelectInput__multi-value__remove": {
      cursor: "not-allowed",
      position: "relative",
      overflow: "visible",
      "&:hover::after": {
        content: ({ mustHaveAtLeastOneChoice }) =>
          `"${mustHaveAtLeastOneChoice}"`,
        fontSize: "12px",
        position: "absolute",
        width: "200px",
        overflow: "visible",
        textAlign: "center",
        backgroundColor: Colors.white,
        top: "-40px",
        left: "-100px",
        zIndex: "1",
        boxShadow: "rgba(0,0,0,0.1) 0px 0px 10px 1px",
        padding: "5px",
        borderRadius: "2.5px",
        color: `${Colors.text.black} !important`,
        animation:
          "0.3s ease-out 0s 1 normal both running $selectTagsMustHaveAtLeastOneChoiceInfoAnim",
      },
      "&:active, & *": {
        pointerEvents: "none",
      },
      "& *": {
        opacity: Opacities.hover,
      },
    },
  },
});
