import React from "react";
import PropTypes from "prop-types";
import { Accordion, ProgressBar } from "genius-ui";
import styles from "./Programs.style";
import uuidV4 from "uuid/dist/v4";
import { FormattedMessage } from "react-intl";
import { translations } from "./Programs.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(styles);

const Programs = (props) => {
  const { programs } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.column_name}>
          <FormattedMessage {...translations.ProgramNameLabel} />
        </div>
        <div className={classes.column_progress}>
          <FormattedMessage {...translations.ProgressionLabel} />
        </div>
        <div className={classes.column_update}>
          <FormattedMessage {...translations.UpdateLabel} />
        </div>
        <div className={classes.column_points}>
          <FormattedMessage {...translations.PointsLabel} />
        </div>
      </div>
      <Accordion
        className={classes.accordion}
        items={
          programs && programs.length
            ? programs.map((program) => ({
                uuid: uuidV4(),
                heading: (
                  <div>
                    <div className={classes.column_name}>{program.name}</div>
                    <div className={classes.column_progress}>
                      <div>
                        <FormattedMessage
                          {...translations.LessonsLabel}
                          values={{
                            progress: `${program.completedLessons}/${program.allLessons}`,
                          }}
                        />
                      </div>
                      <ProgressBar
                        showPercentageValue
                        percentage={parseInt(
                          program.completedLessons /
                            (program.allLessons * 0.01),
                        )}
                        progressBarClassName={classes.progressBar}
                      />
                    </div>
                    <div className={classes.column_update}>
                      {program.latestUpdate}
                    </div>
                    <div className={classes.column_points}>
                      {program.points}
                    </div>
                  </div>
                ),
                content: program.lessons,
              }))
            : [
                {
                  uuid: "no_content",
                  heading: (
                    <div className={classes.noContent}>
                      <FormattedMessage {...translations.noDataText} />
                    </div>
                  ),
                  content: [],
                },
              ]
        }
        renderContent={(item) =>
          item.content.map((lesson) => (
            <div key={uuidV4()}>
              <div className={classes.column_name}>{lesson.name}</div>
              <div className={classes.column_progress}>
                <div>
                  <FormattedMessage
                    {...translations.PagesLabel}
                    values={{
                      progress: `${lesson.completedPages}/${lesson.pages}`,
                    }}
                  />
                </div>
                <ProgressBar
                  showPercentageValue
                  percentage={lesson.percent}
                  progressBarClassName={classes.progressBar}
                />
              </div>
              <div className={classes.column_update}>{lesson.latestUpdate}</div>
              <div className={classes.column_points}>{lesson.points}</div>
            </div>
          ))
        }
      />
    </div>
  );
};

Programs.propTypes = {
  /** List of programs */
  programs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Programs;
