import gql from "graphql-tag";

export const GET_PUBLISHED_BATTLES = gql`
  query GetPublishedBattles(
    $intervalId: Int
    $startDate: String
    $endDate: String
    $tagIds: [ID!]
    $skip: Int
    $limit: Int
    $sortTotalScore: SortDirectionEnum
    $sortParticipantsNumber: SortDirectionEnum
    $sortAverageScore: SortDirectionEnum
  ) {
    admin {
      battles(
        intervalId: $intervalId
        startDate: $startDate
        endDate: $endDate
        workflow: PUBLISHED
        tagIds: $tagIds
        skip: $skip
        limit: $limit
        sortTotalScore: $sortTotalScore
        sortParticipantsNumber: $sortParticipantsNumber
        sortAverageScore: $sortAverageScore
      ) {
        battleId
        gameTemplate {
          docId
          title
          maxScore
          participantsNumber
          averageScore
          totalScore
        }
      }
    }
  }
`;
