import React, { useCallback } from "react";
import { translations } from "../../PublishInsight.translation";
import { FormattedMessage } from "react-intl";
import useThemedStyle from "../../../../hooks/style/useThemedStyle";
import { usePublishInsightStyle } from "../../PublishInsight.style";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateNewInsightTag,
  UpdateInsightTags,
} from "../../../../store/insight/actions";
import { useCurrentInsightId } from "../../hooks/useCurrentInsightId";
import { useCurrentInsightLang } from "../../hooks/useCurrentInsightLang";
import SelectTagsByClusterId from "../../../../containers/Apollo/SelectTagsByClusterId";
import clientConfig, { CONFIG_CLUSTER } from "../../../../configs/client";
import {
  formatOptionsForSave,
  formatOptionsForSelect,
} from "../../../../utils/dataTransformation/tags";

const selectors = {
  tags: (state) => state.referential.tags,
  selectedTags: (state) => state.insights.publishInsight.tags,
  predictTags: (state) => state.insights.publishInsight.predictTags,
};

const EditInsightAssociatedTags = (props) => {
  const dispatch = useDispatch();
  const tags = useSelector(selectors.tags);
  const selectedTags = useSelector(selectors.selectedTags);
  const predictTags = useSelector(selectors.predictTags);
  const currentInsightId = useCurrentInsightId();
  const currentInsightLang = useCurrentInsightLang();
  const classes = useThemedStyle(usePublishInsightStyle, props);

  const UpdateTags = useCallback(
    (selection) =>
      dispatch(
        UpdateInsightTags(
          currentInsightId,
          currentInsightLang,
          formatOptionsForSave(selection),
          "tags",
        ),
      ),
    [currentInsightId, currentInsightLang, dispatch],
  );

  const CreateNewTag = useCallback(
    (inputValue) =>
      dispatch(
        CreateNewInsightTag(currentInsightId, currentInsightLang, inputValue),
      ),
    [currentInsightId, currentInsightLang, dispatch],
  );

  const FetchSuggestedTags = useCallback(() => predictTags, [predictTags]);

  return (
    <FormattedMessage {...translations.SelectAssociatedTagsPlaceholder}>
      {(message) => (
        <SelectTagsByClusterId
          isEditable
          tags={formatOptionsForSelect(tags)}
          className={classes.tagSelectContainer}
          id="expertise"
          clusterIds={clientConfig.EXPERTISE_CLUSTERIDS}
          tagType={CONFIG_CLUSTER.EXPERTISE}
          selectedItems={formatOptionsForSelect(selectedTags)}
          placeholder={message[0]}
          onSelectionChanged={UpdateTags}
          onCreateOption={CreateNewTag}
          fetchSuggestedTags={FetchSuggestedTags}
        />
      )}
    </FormattedMessage>
  );
};

export default React.memo(EditInsightAssociatedTags);
