export default (theme) => ({
  container: {
    marginRight: 15,
    height: "100%",
  },
  control: {
    cursor: "pointer",
    borderRadius: 5,
    border: `1px solid ${theme.GrayStrokedRSDropdown.inputBorderColor} !important`,
    height: "100%",
  },
  placeholder: {
    fontFamily: theme.FontFamily,
    fontSize: 16,
    fontWeight: 500,
    color: theme.GrayStrokedRSDropdown.inputPlaceholderColor,
  },
  dropdownIndicator: {
    display: "block !important",
    color: theme.GrayStrokedRSDropdown.inputPlaceholderColor,
  },
});
