import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Style from "./Madlib.style";
import ContentEditable from "react-contenteditable";
import RadioGroup from "../../common/RadioGroup";
import { Icon, Icons } from "genius-ui";
import uuidv4 from "uuid/dist/v4";
import { AddSlide } from "assets/icons";
import { FormattedMessage } from "react-intl";
import { translations } from "./Madlib.translations";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const maxOptions = 4;
const BUTTONSWITCH_BREAKPOINT = 368;

const MadLibOption = ({
  classes,
  option,
  isSelected,
  updateOptionLabel,
  endTypingHandler,
  removeOption,
  onChange,
}) => {
  const selectOption = useCallback(() => onChange(option), [option, onChange]);

  return (
    <>
      <label className={classes.radioLabel} onClick={selectOption}>
        <div />
      </label>
      <div className={classes.RadioLabelContainer}>
        <FormattedMessage {...translations.EnterChoiceText}>
          {(message) => (
            <ContentEditable
              onClick={(e) => e.stopPropagation()}
              html={option.label}
              disabled={!isSelected}
              onChange={(e) => updateOptionLabel(option.value, e.target.value)}
              onBlur={endTypingHandler}
              tagName="article"
              className={classes.radioChoiceLabel}
              placeholder={message[0]}
            />
          )}
        </FormattedMessage>
        <div onClick={(e) => removeOption(e, option.value)}>
          <Icon iconName={Icons.delete} style={classes.deleteIcon} />
        </div>
      </div>
    </>
  );
};

const MadLib = (props) => {
  const {
    isSelected,
    onClick,
    onDataChanged,
    onAddTopHandler,
    onAddBottomHandler,
    partOne,
    partTwo,
    options,
    onDelete,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  const rightAnswer = options.find((option) => option.isTrue === true);
  const [stateChoice, setChoice] = useState(rightAnswer || {});
  const [stateOptions, setOptions] = useState(options);
  const [addPositionToTop, setAddPositionToTop] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [statePartOne, setPartOne] = useState(partOne);
  const [statePartTwo, setPartTwo] = useState(partTwo);

  const firstRender = useRef(true);
  useEffect(() => {
    // componentDidMount
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (isTyping) {
      return;
    }

    // componentDidUpdate
    if (!isTyping) {
      const newValues = {
        partOne: statePartOne,
        partTwo: statePartTwo,
        options: stateOptions,
        rightChoice: stateChoice,
      };
      onDataChanged(newValues);
    }
  }, [statePartOne, statePartTwo, isTyping, stateOptions, stateChoice]);

  const addChoice = () => {
    if (stateOptions.length < maxOptions && isSelected) {
      const newOptions = [
        ...stateOptions,
        {
          value: uuidv4(),
          label: "",
        },
      ];
      setOptions(newOptions);
    }
  };

  const removeOption = (e, index) => {
    e.stopPropagation();
    if (isSelected) {
      const newOptions = stateOptions.filter(
        (option) => option.value !== index,
      );
      setOptions(newOptions);
      if (index === stateChoice.value) {
        setChoice({});
      }
    }
  };

  const updateOptionLabel = (index, label) => {
    setIsTyping(true);
    const newOptions = stateOptions.map((option) => {
      if (option.value === index) {
        option.label = label;
      }
      return option;
    });
    setOptions(newOptions);
  };

  const checkToChangeAddPosition = (e) => {
    const position = e.nativeEvent.offsetY;
    if (isSelected && position < BUTTONSWITCH_BREAKPOINT && position > 1) {
      setAddPositionToTop(true);
    } else {
      setAddPositionToTop(false);
    }
  };

  const endTypingHandler = () => setIsTyping(false);

  const onPartOneChangeHandler = (e) => {
    setIsTyping(true);
    setPartOne(e.target.value);
  };

  const onPartTwoChangeHandler = (e) => {
    setIsTyping(true);
    setPartTwo(e.target.value);
  };

  return (
    <div
      className={classes.madlibContainer}
      onClick={onClick}
      onMouseMove={checkToChangeAddPosition}
    >
      {isSelected && (
        <div className={classes.deleteBox} onClick={onDelete}>
          <span>Delete</span>
          <Icon iconName={Icons.delete} />
        </div>
      )}
      <FormattedMessage {...translations.PartOne}>
        {(message) => (
          <ContentEditable
            key="partOne"
            html={statePartOne}
            disabled={!isSelected}
            onChange={onPartOneChangeHandler}
            onBlur={endTypingHandler}
            tagName="article"
            className={classes.sentencePartOne}
            placeholder={message[0]}
          />
        )}
      </FormattedMessage>
      <FormattedMessage {...translations.CorrectChoicePlaceholder}>
        {(message) => (
          <ContentEditable
            key="choice"
            html={stateChoice.label ? stateChoice.label : ""}
            disabled
            tagName="article"
            className={classes.choice}
            placeholder={message[0]}
          />
        )}
      </FormattedMessage>
      <FormattedMessage {...translations.PartTwo}>
        {(message) => (
          <ContentEditable
            key="partTwo"
            html={statePartTwo}
            disabled={!isSelected}
            onChange={onPartTwoChangeHandler}
            onBlur={endTypingHandler}
            tagName="article"
            className={classes.sentencePartTwo}
            placeholder={message[0]}
          />
        )}
      </FormattedMessage>
      <div className={classes.radioHeaderContainer}>
        <div className={classes.correctLabel}>
          <FormattedMessage {...translations.Correct} />
        </div>
        <div className={classes.choiceTextLabel}>
          <FormattedMessage {...translations.ChoiceText} />
        </div>
      </div>
      <div className={classes.radioContainer}>
        <RadioGroup
          options={stateOptions}
          renderOptionLabel={(option, index, options, value, onChange) => (
            <MadLibOption
              key={option.key}
              classes={classes}
              option={option}
              isSelected={isSelected}
              updateOptionLabel={updateOptionLabel}
              endTypingHandler={endTypingHandler}
              removeOption={removeOption}
              onChange={onChange}
            />
          )}
          onChange={(select) => (isSelected ? setChoice(select) : null)}
          value={stateChoice.value ? stateChoice.value : ""}
        />
        <div className={classes.addChoice} onClick={addChoice}>
          <FormattedMessage {...translations.AddChoice} />
        </div>
      </div>

      {isSelected && (
        <div
          className={
            addPositionToTop ? classes.addBoxTop : classes.addBoxBottom
          }
          onClick={addPositionToTop ? onAddTopHandler : onAddBottomHandler}
          onMouseMove={(e) => e.stopPropagation()}
        >
          <img src={AddSlide} alt="" />
        </div>
      )}
    </div>
  );
};

MadLib.propTypes = {
  /** Is selected */
  isSelected: PropTypes.bool,
  /** On Madlib click event handler */
  onClick: PropTypes.func.isRequired,
  /** On data changed event handler */
  onDataChanged: PropTypes.func.isRequired,
  /** Sentence part one */
  partOne: PropTypes.string,
  /** Sentence part two */
  partTwo: PropTypes.string,
  /** Add slide to top event handler */
  onAddTopHandler: PropTypes.func.isRequired,
  /** Add slide to bottom event handler */
  onAddBottomHandler: PropTypes.func.isRequired,
  /** Answers list */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  /** Right choice */
  choice: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    isTrue: PropTypes.bool,
  }),
  /** Delete question handler */
  onDelete: PropTypes.func.isRequired,
};

MadLib.defaultProps = {
  isSelected: false,
  partOne: "",
  partTwo: "",
  options: [],
  choice: {},
};

export default MadLib;
