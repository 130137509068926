import { NoImageAvailable } from "assets/icons";

export default {
  container: {
    cursor: ({ onClickHandler }) => (onClickHandler ? "pointer" : "default"),
    width: ({ size }) => size,
    height: ({ size }) => size,
    borderRadius: ({ size }) => size,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundImage: ({ imageUrl }) => `url('${imageUrl || NoImageAvailable}')`,
  },
};
