import gql from "graphql-tag";

export const CREATE_SHOPS_FILE = gql`
  query (
    $format: String
    $filterLocationTypeIds: [Int!]
    $filterLocationCities: [Int!]
    $filterLocationCountries: [Int!]
    $filterLocationZones: [Int!]
    $filterLocationVMSegmentations: [Int!]
    $filterLocationUniverses: [Int!]
  ) {
    admin {
      coreObjectExtract {
        stores(
          format: $format
          filterLocation: {
            typeIds: $filterLocationTypeIds
            cities: $filterLocationCities
            countries: $filterLocationCountries
            zones: $filterLocationZones
            vmSegmentations: $filterLocationVMSegmentations
            universes: $filterLocationUniverses
          }
        ) {
          fileURL
        }
      }
    }
  }
`;
