import React from "react";
import PropTypes from "prop-types";
import { PeanutPageEditorStyle } from "./PeanutPageEditor.style";
import { GetDesignPageEncodeData } from "../../utils/ui-generator/generator";
import { PAGE_TYPES } from "../../configs/constants";
import uuidv4 from "uuid/dist/v4";
import classnames from "classnames";
import { SetCards, SetCurrentActiveSlide } from "../../store/card/actions";
import { connect } from "react-redux";
import withThemedStyle from "../hoc/withThemedStyle";

class PeanutPageEditor extends React.PureComponent {
  handleAddBefore = (sliderIndex) => {
    let newElement = { ...this.props.defaultNewElement };
    newElement.defaultImage =
      this.props.defaultSliderImages[(sliderIndex + 1) % 2];
    newElement.image = newElement.defaultImage;
    let currentCards = [...this.props.currentCards];
    let newSlidePosition = sliderIndex;
    currentCards.splice(newSlidePosition, 0, newElement);
    this.props.SetCurrentCards(currentCards);
    this.props.SetCurrentActiveSlide(newSlidePosition);
    let formattedSaveValue = GetDesignPageEncodeData(
      currentCards,
      PAGE_TYPES.PEANUT,
    );
    this.props.onDataChanged(
      this.props.documentId,
      this.props.language,
      formattedSaveValue,
      this.props.pageId,
    );
  };

  handleAddAfter = (sliderIndex) => {
    let newElement = { ...this.props.defaultNewElement };
    newElement.defaultImage =
      this.props.defaultSliderImages[(sliderIndex + 1) % 2];
    newElement.image = newElement.defaultImage;
    let currentCards = [...this.props.currentCards];
    let newSlidePosition = Number(sliderIndex) + 1;
    currentCards.splice(newSlidePosition, 0, newElement);
    this.props.SetCurrentCards(currentCards);
    this.props.SetCurrentActiveSlide(newSlidePosition);
    let formattedSaveValue = GetDesignPageEncodeData(
      currentCards,
      PAGE_TYPES.PEANUT,
    );
    this.props.onDataChanged(
      this.props.documentId,
      this.props.language,
      formattedSaveValue,
      this.props.pageId,
    );
  };

  handleSelectSlide = (sliderIndex) => {
    this.props.SetCurrentActiveSlide(sliderIndex);
  };

  handleRemoveSlide = (sliderIndex) => {
    let currentCards = [...this.props.currentCards];
    currentCards = currentCards.filter((x, index) => index !== sliderIndex);
    let newSlidePosition =
      sliderIndex > 0 ? sliderIndex : Number(sliderIndex) - 1;
    this.props.SetCurrentCards(currentCards);
    this.props.SetCurrentActiveSlide(newSlidePosition);
    if (currentCards.length > 0) {
      let formattedSaveValue = GetDesignPageEncodeData(
        currentCards,
        PAGE_TYPES.PEANUT,
      );
      this.props.onDataChanged(
        this.props.documentId,
        this.props.language,
        formattedSaveValue,
        this.props.pageId,
      );
    } else {
      // handle it
    }
  };

  handleChange = (sliderValue, index) => {
    let currentValues = [...this.props.currentCards];
    currentValues[index] = sliderValue;
    this.props.SetCurrentCards(currentValues);
    let formattedSaveValue = GetDesignPageEncodeData(
      currentValues,
      PAGE_TYPES.PEANUT,
    );
    this.props.onDataChanged(
      this.props.documentId,
      this.props.language,
      formattedSaveValue,
      this.props.pageId,
    );
    if (index === 0) {
      this.props.onPicChanged(
        this.props.currentCardId,
        this.props.currentLanguage,
        currentValues[0].image,
      );
    }
  };

  render() {
    let { slideContainerClassName, renderSlideItem, classes } = this.props;

    const slideContainerClass = classnames([
      classes.sliderContainer,
      slideContainerClassName,
    ]);

    return (
      <div className={classes.pageBox}>
        <div className={slideContainerClass}>
          {this.props.currentCards.map((slide, index) =>
            renderSlideItem(
              slide,
              index,
              uuidv4(),
              this.props.currentActiveSlide === index,
              (event) => {
                event.preventDefault();
                event.stopPropagation();
                this.handleAddAfter(index);
              },
              (event) => {
                event.preventDefault();
                event.stopPropagation();
                this.handleAddBefore(index);
              },
              (event) => {
                event.preventDefault();
                event.stopPropagation();
                this.handleRemoveSlide(index);
              },
              (value) => this.handleChange(value, index),
              () => this.handleSelectSlide(index),
              this.props.currentCards.length,
              this.props.pageId,
            ),
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  currentCards: state.cards.cards,
  currentActiveSlide: state.cards.currentActiveSlide,
  currentCardId: state.cards.currentCardId,
  currentLanguage: state.cards.currentLanguage,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  SetCurrentCards: (cards) => {
    dispatch(SetCards(cards));
  },
  SetCurrentActiveSlide: (index) => {
    dispatch(SetCurrentActiveSlide(index));
  },
});

PeanutPageEditor.propTypes = {
  /** page Id  */
  pageId: PropTypes.string,
  /** page order id */
  orderId: PropTypes.number,
  /** current page data */
  data: PropTypes.string,
  /** on data changed handler */
  onDataChanged: PropTypes.func,
  /** on pic changed handler */
  onPicChanged: PropTypes.func,
  /** id of current document */
  documentId: PropTypes.string,
  /** current language */
  language: PropTypes.string,
  /** class appended to the slide container's classes **/
  slideContainerClassName: PropTypes.string,
  /** cards array **/
  cards: PropTypes.array.isRequired,
  /** object used to create a new empty slide **/
  defaultNewElement: PropTypes.object.isRequired,
  /** images used to create new empty slides **/
  defaultSliderImages: PropTypes.array.isRequired,
  /** function used to render a single slide item **/
  renderSlideItem: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProp,
  mapDispatchToProps,
)(withThemedStyle(PeanutPageEditorStyle)(PeanutPageEditor));
