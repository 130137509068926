import gql from "graphql-tag";

export const GET_GENERAL_DETAILS = gql`
  query stats($fromDate: String, $toDate: String, $tags: [kpiTagsType!]) {
    generalKPI(fromDate: $fromDate, toDate: $toDate, tags: $tags) {
      application {
        activeUsers
        connections
        dailyUsage
        totalUsers
        previousPeriod {
          activeUsers
          connections
          dailyUsage
        }
      }
      learning {
        programsNb
        lessonsNb
      }
      feed {
        insights
        lessons
        socialMedias
        sliders
      }
      ask {
        geniusQuestion
        expertQuestion
        expertAnswer
      }
    }
  }
`;
