import { PUBLISH_LESSON_DEFAULT } from "./DefaultLessonPublish.graphql";
import { REACT_APP_CLIENT_NAME } from "../../../../../configs/client";

const GetActualFragment = () => {
  switch (REACT_APP_CLIENT_NAME) {
    default:
      return PUBLISH_LESSON_DEFAULT;
  }
};

export const PublishLessonFragment = GetActualFragment();
