import * as constants from "../../constants/store/navigation";
import { RESET_STORE } from "../../constants/store/auth";

const initialState = {
  currentMenuItem: "Insights",
  activeExpandedMenuItem: null,
  isLoaderActive: false,
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.SET_CURRENT_ACTIVE_NAVIGATION_MENU:
      return {
        ...state,
        currentMenuItem: data,
      };
    case constants.SET_CURRENT_EXPANDED_NAVIGATION_MENU:
      return {
        ...state,
        activeExpandedMenuItem: data,
      };
    case constants.SET_LOADER_STATUS:
      return {
        ...state,
        isLoaderActive: data,
      };
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
