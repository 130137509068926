import React from "react";
import DataNotAvailableStyle from "./DataNotAvailable.style";
import { FormattedMessage } from "react-intl";
import { translations } from "./DataNotAvailable.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(DataNotAvailableStyle);

const DataNotAvailable = (props) => {
  const classes = useThemedStyle(useStyle, props);
  return (
    <div className={classes.rootContainer}>
      <div className={classes.noContentImage} />
      <div className={classes.noContentText}>
        <FormattedMessage {...translations.NoKpiDataMessage} />
      </div>
    </div>
  );
};

export default DataNotAvailable;
