import React, { useMemo } from "react";
import PropTypes from "prop-types";
import uuidv4 from "uuid/dist/v4";
import { decodeNewLines } from "../../../utils/string";
import ActivityIntroReadOnly from "../../common/ActivityIntroComponent/activityIntroReadOnly";
import DragAndDropActivityQuestionReadOnly from "../../common/DragAndDropActivityQuestion/dragAndDropQuestionReadOnly";
import Style from "../DragAndDropActivityPageEditor.style.js";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import DocumentPageHeader from "../../common/DocumentPageHeader";

const useStyle = createUseStyles(Style);

// In order handle backward compatibility for documents created without zoneId
const detectZoneId = (zoneId, index) => {
  return zoneId || (index === 0 ? "zone1" : "zone2");
};
/** Organize question data into a structure that's easier to work with **/
const normalizeQuestionArray = (questions) => {
  const { questions: normalizedQuestions } = questions.reduce(
    ({ questionMap, questions }, question) => {
      if (!questionMap[question.id]) {
        // group zones based on question id
        questionMap[question.id] = {
          uid: question.uid,
          id: question.id,
          text: question.text,
          zones: question.answers.map(
            ({ zoneId, answer, placeholder }, index) => ({
              zoneId: detectZoneId(zoneId, index),
              answer,
              placeholder,
              cards: [],
            }),
          ),
        };
        questions.push(questionMap[question.id]);
      }

      // group cards based on the zone
      const currentAnswersZone = question.answers.find(({ isTrue }) => isTrue);

      if (currentAnswersZone.zoneId) {
        questionMap[question.id].zones
          .find((zone) => zone.zoneId === currentAnswersZone.zoneId)
          .cards.push(question.card);
      } else {
        questionMap[question.id].zones
          .find(
            (zone) =>
              zone.answer.toLowerCase().trim() ===
              currentAnswersZone.answer.toLowerCase().trim(),
          )
          .cards.push(question.card);
      }

      return { questionMap, questions };
    },
    {
      questionMap: {},
      questions: [],
    },
  );

  return normalizedQuestions;
};

const decodeContent = (encodedContent) => {
  const contentObject = JSON.parse(encodedContent);
  return {
    ...contentObject,
    data: {
      ...contentObject.data,
      activityDescription: decodeNewLines(
        contentObject.data.activityDescription,
      ),
      title: decodeNewLines(contentObject.data.title),
      description: decodeNewLines(contentObject.data.description),
      questions: normalizeQuestionArray(
        contentObject.data.questions.map((question) => ({
          ...question,
          text: decodeNewLines(question.text),
          answers: question.answers.map((answer) => ({
            ...answer,
            answer: decodeNewLines(answer.answer),
          })),
          card: {
            ...question.card,
            text: decodeNewLines(question.card.text),
          },
        })),
      ),
    },
  };
};

const DragAndDropActivityPageEditor = (props) => {
  // EXTRACT VARIABLES
  const { pageId, data: dataProp, orderId } = props;
  const classes = useThemedStyle(useStyle, props);

  const { data } = useMemo(() => decodeContent(dataProp), [dataProp]);

  const {
    activityDescription,
    title,
    description,
    image: imageProp,
    questions: questionsProp,
  } = data;

  const questions = questionsProp.map((question) => ({
    ...question,
    uid: question.uid || uuidv4(), // generate a uid for backward compatibility purposes(old questions don't have an uid)
  }));

  return (
    <div className={classes.pageBox}>
      <DocumentPageHeader pageNumber={orderId} showOrderButtons={false} />
      <div className={classes.introContainer}>
        <ActivityIntroReadOnly
          activityId={pageId}
          theme={activityDescription}
          title={title}
          description={description}
          image={imageProp.url}
        />
      </div>
      {questions.map(({ uid, ...question }, index) => (
        <DragAndDropActivityQuestionReadOnly key={uid} {...question} />
      ))}
    </div>
  );
};

DragAndDropActivityPageEditor.propTypes = {
  /** page Id  */
  pageId: PropTypes.string,
  /** current page JSON data */
  data: PropTypes.string,
};

export default DragAndDropActivityPageEditor;
