import React from "react";
import { BigPlayButton, Player } from "video-react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import SliderVideoPlayerStyle from "./SliderVideoPlayer.style";
import { VIDEO_FORMAT } from "../../../configs/constants";
import { ProgressBar } from "genius-ui";
import { injectIntl } from "react-intl";
import compose from "lodash/fp/compose";
import { translations } from "./SliderVideoPlayer.translations";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import withThemedStyle from "../../hoc/withThemedStyle";

const useStyle = createUseStyles(SliderVideoPlayerStyle);

export const UploadProgress = (props) => {
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.progressContainer}>
      <span className={classes.titleProgress}>{props.titleVideo}</span>
      <ProgressBar percentage={props.percentage} />
      <span className={classes.descriptionProgress}>
        {props.descriptionVideo}
      </span>
    </div>
  );
};

class SliderVideoPlayer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      videoFormatType: VIDEO_FORMAT.PORTRAIT,
    };
  }

  componentDidMount() {
    this.setState({ videoFormatType: this.props.formatType });
    // subscribe state change
    if (
      this.player &&
      this.player.subscribeToStateChange &&
      this.props.isActive
    ) {
      this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    }
  }

  handleStateChange = (playerState) => {
    let format = this.state.videoFormatType;
    if (playerState.videoWidth > 0 && playerState.videoHeight > 0) {
      if (playerState.videoWidth > playerState.videoHeight) {
        format = VIDEO_FORMAT.LANDSCAPE;
      } else {
        format = VIDEO_FORMAT.PORTRAIT;
      }

      if (this.state.videoFormatType !== format) {
        this.setState({ videoFormatType: format });
        this.props.onAspectRatioChanged(format);
      }
    }
  };

  render() {
    let { classes, intl } = this.props;
    return (
      <div className={classes.videoContainer}>
        {!this.props.isUploading && !this.props.isConverting && (
          <Player
            ref={(player) => {
              this.player = player;
            }}
            playsInline
            poster={this.props.poster}
            fluid={false}
            width="100%"
            height="100%"
            onClick={this.props.onClick}
          >
            <source src={this.props.source} />
            <BigPlayButton position="center" />
          </Player>
        )}

        {this.props.isUploading && (
          <UploadProgress
            percentage={this.props.progress}
            titleVideo={intl.formatMessage({
              ...translations.TitleVideoUpload,
            })}
            descriptionVideo={`${intl.formatMessage({
              ...translations.DescriptionVideoImport,
            })}...`}
            classes={classes}
          />
        )}

        {this.props.isConverting && (
          <UploadProgress
            percentage={this.props.progress}
            titleVideo={intl.formatMessage({
              ...translations.TitleVideoConversion,
            })}
            descriptionVideo={`${intl.formatMessage({
              ...translations.DescriptionVideoCompress,
            })}...`}
            classes={classes}
          />
        )}
      </div>
    );
  }
}

SliderVideoPlayer.propTypes = {
  /** on click handler */
  onClick: PropTypes.func,
  /** on load poster cover image */
  poster: PropTypes.string,
  /** video source **/
  source: PropTypes.string,
  /** video format type **/
  formatType: PropTypes.oneOf([VIDEO_FORMAT.PORTRAIT, VIDEO_FORMAT.LANDSCAPE]),
  /** on aspect ratio changed **/
  onAspectRatioChanged: PropTypes.func,
  /** is player in conversion state **/
  isConverting: PropTypes.bool,
  /** is player in upload state **/
  isUploading: PropTypes.bool,
  /** upload progress **/
  progress: PropTypes.number,
  /** is the slide container active **/
  isActive: PropTypes.bool,
};

export default compose(
  injectIntl,
  withThemedStyle(SliderVideoPlayerStyle),
)(SliderVideoPlayer);
