import { defineMessages } from "react-intl";

export const translations = defineMessages({
  StartDateLabel: {
    id: "PeriodSelectorComponent.StartDateLabel",
    defaultMessage: "Start date",
  },
  EndDateLabel: {
    id: "PeriodSelectorComponent.EndDateLabel",
    defaultMessage: "End date",
  },
});
