import { PlaceHolderDegree } from "assets/icons";
import { getFormattedSopiDate } from "utils/dateUtils";
import {
  SET_PDF_TITLE,
  SET_PDF_COVER,
  SET_PDF_URL,
  RESET_PDF,
  SET_PDF_DATE,
  ADD_PDFS,
} from "../../constants/store/pdf";

const initialState = {
  title: null,
  cover: PlaceHolderDegree,
  pdf: null,
  publicationDate: getFormattedSopiDate(new Date()),
  pdfs: [],
};

export default (state = initialState, action = {}) => {
  const { data, type } = action;

  switch (type) {
    case SET_PDF_TITLE: {
      return {
        ...state,
        title: data,
      };
    }
    case SET_PDF_COVER: {
      return {
        ...state,
        cover: data,
      };
    }
    case SET_PDF_URL: {
      return {
        ...state,
        pdf: data,
      };
    }
    case SET_PDF_DATE: {
      return {
        ...state,
        publicationDate: data,
      };
    }
    case ADD_PDFS: {
      return {
        ...state,
        pdfs: [...state.pdfs, ...data],
      };
    }
    case RESET_PDF: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
