import { navigationBarWidth } from "../../Navigation/Navigation.style";

const globalPadding = 30;
const globalWidth = 1280 + 2 * globalPadding;

export default (theme) => ({
  headerContainer: {
    minHeight: 60,
    backgroundColor: theme.DefaultFilterHeader.containerBackground,
    display: "flex",
    zIndex: 1,
    marginLeft: navigationBarWidth,
    boxSizing: "border-box",
    boxShadow: `0 2px 20px 0 ${theme.DefaultFilterHeader.boxShadowColor}`,
  },
  header: {
    width: "100%",
  },
  filterContainer: {
    maxWidth: globalWidth,
    padding: `10px ${globalPadding}px 0 ${globalPadding}px`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "auto",
  },
  leftSide: {
    display: "flex",
    alignItems: "center",
  },
  rightSide: {
    display: "flex",
    flexDirection: "row",
    "& > div": {
      marginLeft: 15,
    },
  },
  filters: {
    display: "flex",
    flexDirection: "row",
    "& > div": {
      width: 180,
      marginRight: 15,
    },
  },
  filterTags: {
    maxWidth: globalWidth,
    marginTop: 10,
    padding: `0px ${globalPadding}px 0px ${globalPadding}px`,
    margin: "auto",
    display: "flex",
    "& > div": {
      float: "left",
    },
  },
  clearButton: {
    display: "flex",
    alignItems: "center",
    height: 28,
    cursor: "pointer",
    fontSize: 14,
    fontStretch: "condensed",
    letterSpacing: 0.15,
    color: theme.DefaultFilterHeader.clearButtonColor,
  },
  periodFilter: {
    width: 160,
    border: `solid 1px ${theme.DefaultFilterHeader.periodFilterBorderColor}`,
    borderRadius: 5,
    paddingLeft: 2,
    height: "38px !important",
    "& > div > div > div": {
      minHeight: "38px !important",
    },
  },
  rangeFilter: {
    width: 288,
  },
  backButton: {
    marginRight: 30,
    cursor: "pointer",
    fontSize: 18,
  },
  searchButton: {
    margin: 0,
    height: 40,
  },
  buttonContainer: {
    cursor: "pointer",
    width: 40,
    height: 38,
    borderRadius: 5,
    border: `solid 1px ${theme.DownloadButton.borderColor}`,
    backgroundColor: theme.DownloadButton.background,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.DefaultFilterHeader.clearButtonColor,
    "& > span:before": {
      fontSize: 18,
      color: theme.DownloadButton.icon,
    },
  },
});
