import { merge } from "lodash";
import { RESET_STORE } from "../../../constants/store/auth";
import {
  SET_IS_LOADING,
  SET_MEDIA_INFO,
  UPDATE_MEDIA_INFO,
  RESET_MEDIA_INFO,
} from "../../../constants/store/creators/mediaInfo";

export default (prefix, customInitialState) => {
  const initialState = merge(
    {},
    {
      isLoading: false,
      id: null,
      originalUrl: "",
      title: "",
      uploadDate: "",
      size: "",
      zone: null,
      country: null,
      city: null,
      boutique: null,
      tags: [],
    },
    customInitialState,
  );

  return (state = initialState, action = {}) => {
    const { data, type } = action;
    switch (type) {
      case SET_MEDIA_INFO(prefix): {
        return data;
      }
      case UPDATE_MEDIA_INFO(prefix): {
        return {
          ...state,
          ...data,
        };
      }
      case SET_IS_LOADING(prefix): {
        return {
          ...state,
          isLoading: data,
        };
      }
      case RESET_MEDIA_INFO(prefix): {
        return { ...initialState };
      }
      case RESET_STORE: {
        return { ...initialState };
      }
      default:
        return state;
    }
  };
};
