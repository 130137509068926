import { navigationBarWidth } from "../../components/Navigation/Navigation.style";

export const ContentManagerStyle = ({ ContentManagerScreen, Fonts }) => {
  const filterSelectorStyle = {
    width: 180,
    // zIndex: 997,
    textTransform: "capitalize",
    "& > div": {
      color: ContentManagerScreen.filterColor,
      "& > div > div > div": {
        color: ContentManagerScreen.filterColor,
      },
    },
  };

  return {
    modalLanguagesContainer: {
      minHeight: 200,
      width: "50%",
      backgroundColor: "white",
      borderRadius: 10,
      padding: 20,
      overflow: "hidden",
      zIndex: 100,
    },
    modalLanguagesHeaderContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 20,
      fontSize: 18,
    },
    modalLanguagesButtonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      padding: "15px 0px 5px",
    },
    closeBtn: {
      cursor: "pointer",
      fontSize: 8,
      fontWeight: "normal",
      padding: 8,
      width: 8,
      height: 8,
      display: "inline-block",
      backgroundColor: "#d8d8d8",
      borderRadius: 15,
      "&:before": {
        color: "#ffffff",
      },
    },
    rootContainer: {
      paddingLeft: navigationBarWidth,
    },
    actionsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      maxWidth: 1000,
      alignItems: "center",
      padding: "80px 0 60px 0",
      margin: "auto",
    },
    languageSelector: {
      ...filterSelectorStyle,
      marginRight: 20,
    },
    typeSelector: {
      ...filterSelectorStyle,
    },
    marketSelector: {
      ...filterSelectorStyle,
      paddingRight: 20,
    },
    switcher: {
      alignSelf: "center",
      marginRight: 60,
    },
    filtersContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    createLink: {
      fontFamily: Fonts.fontFamilyCondensed,
      display: "flex",
      flexDirection: "row",
      fontSize: "16px",
      fontWeight: 400,
      fontStyle: "normal",
      fontStretch: "condensed",
      lineHeight: "normal",
      letterSpacing: "0.1px",
      color: ContentManagerScreen.createContentColor,
      cursor: "pointer",
      alignItems: "center",
      width: 115,
      justifyContent: "space-between",
      "& > span::before": {
        color: ContentManagerScreen.createContentColor,
        fontSize: 25,
      },
      textDecoration: "none",
    },
    createButtonContainer: {
      bottom: 35,
      right: 35,
      zIndex: 1,
    },
  };
};
