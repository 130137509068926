import React, { useCallback } from "react";
import PropTypes from "prop-types";
import messages from "./BattleQuestionChoiceEditor.translations";
import Style from "./BattleQuestionChoiceEditor.style.js";
import ContentEditableUncontrolled from "../../common/ContentEditableUncontrolled";
import { Icon, Icons } from "genius-ui";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const BattleQuestionChoiceEditor = (props) => {
  const {
    index,
    text,
    isAddAChoice,
    onTextChange,
    onRemove,
    disabled,
    onChangeSelectedAnswer,
    option,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  const selectOption = useCallback(() => {
    onChangeSelectedAnswer(option, index);
  }, [option, onChangeSelectedAnswer]);
  const removeValue = useCallback(
    (e) => {
      if (!disabled) {
        e.stopPropagation();
        onRemove();
      }
    },
    [disabled, onRemove],
  );

  return (
    <>
      {isAddAChoice ? (
        <></>
      ) : (
        <label className={classes.choice} onClick={selectOption} />
      )}
      <div className={classes.label}>
        {isAddAChoice ? (
          text
        ) : (
          <FormattedMessage {...messages.BattleQuestionChoicePlaceHolder}>
            {(placeHolder) => (
              <ContentEditableUncontrolled
                className={classes.choiceInput}
                initialValue={text}
                onChange={(newText) => onTextChange(newText)}
                placeholder={placeHolder[0]}
                onClick={(e) => {
                  if (!disabled) {
                    e.stopPropagation();
                  }
                }}
                disabled={disabled}
              />
            )}
          </FormattedMessage>
        )}
      </div>
      {!isAddAChoice && (
        <span className={classes.delete} onClick={removeValue}>
          <Icon iconName={Icons.delete} />
        </span>
      )}
    </>
  );
};

BattleQuestionChoiceEditor.propTypes = {
  multiAnswerManagement: PropTypes.bool,
};

export default BattleQuestionChoiceEditor;
