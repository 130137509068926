import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Style from "./SimpleQuizAnswer.style.js";
import { FormattedMessage } from "react-intl";
import ContentEditableUncontrolled from "../ContentEditableUncontrolled";
import { Icon, Icons } from "genius-ui";
import { translations } from "./SimpleQuizAnswer.translations";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const SimpleQuizAnswer = (props) => {
  const {
    option,
    index,
    isAddAChoice,
    onChange,
    onRemove,
    disabled,
    multiAnswerManagement,
    canAddAnswer,
    onChangeSelectedAnswer,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  const selectOption = useCallback(
    () => onChangeSelectedAnswer(option),
    [option, onChangeSelectedAnswer],
  );

  if (isAddAChoice && !canAddAnswer) return null;
  return (
    <>
      {isAddAChoice ? <></> : <label onClick={selectOption} />}
      <div className={classes.label}>
        {isAddAChoice ? (
          option.label
        ) : (
          <FormattedMessage {...translations.ChoiceInputPlaceholder}>
            {(message) => (
              <ContentEditableUncontrolled
                className={classes.choiceInput}
                initialValue={option.label}
                onChange={(newLabel) => onChange(newLabel, index)}
                placeholder={message[0]}
                onClick={(e) => {
                  if (!disabled) {
                    e.stopPropagation();
                  }
                }}
                disabled={disabled}
              />
            )}
          </FormattedMessage>
        )}
      </div>
      {!isAddAChoice && multiAnswerManagement && (
        <span
          className={classes.delete}
          onClick={() => !disabled && onRemove(index)}
        >
          <Icon iconName={Icons.delete} />
        </span>
      )}
    </>
  );
};

SimpleQuizAnswer.propTypes = {
  multiAnswerManagement: PropTypes.bool,
};

SimpleQuizAnswer.defaultProps = {
  multiAnswerManagement: true,
};

export default SimpleQuizAnswer;
