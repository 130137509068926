import gql from "graphql-tag";

export const GET_FEED_DATA = gql`
  query getFeedData(
    $fromDate: String
    $toDate: String
    $tags: [kpiTagsType!]
    $intervalId: Int
  ) {
    feedKPI(
      fromDate: $fromDate
      toDate: $toDate
      tags: $tags
      intervalId: $intervalId
    ) {
      slider
      insight
      lesson
      socialMedia
      topTags {
        nb
        label
      }
      mostPopularContent {
        percent
        label
      }
    }
  }
`;

export const GET_DAA_WITH_INTERVAL = gql`
  query daaWithInterval($intervalId: Int!, $skip: Int, $limit: Int) {
    admin {
      daaWithInterval(
        intervalId: $intervalId
        skip: $skip
        limit: $limit
        documentTypes: [card, poll, battle, insight]
      ) {
        document {
          id
          title
          typeId
          typeName
        }
        nbBookmarks
        nbVotes
        nbReads
        nbValidatedUsers
      }
    }
  }
`;

export const GET_DAA_WITH_DATES = gql`
  query admindocuments(
    $docIds: [Int!]
    $from: String
    $to: String
    $skip: Int
    $limit: Int
  ) {
    admin {
      documents(docIds: $docIds, limit: $limit, skip: $skip) {
        docId
        versions {
          all(date: { from: $from, to: $to }) {
            read
            vote
            bookmark
            comment
          }
        }
      }
    }
  }
`;

export const GET_FEED_KPI_DATA = gql`
  query kpiGetFeedData(
    $workflows: [DocWorkflowEnum!]
    $types: [DocTypeEnum!]
    $skip: Int
    $limit: Int
    $tagMultipleIntersectIds: [QueryInputMultipleTags!]
    $from: String
    $to: String
  ) {
    admin {
      documents(
        workflows: $workflows
        types: $types
        skip: $skip
        limit: $limit
        tagMultipleIntersectIds: $tagMultipleIntersectIds
        excludeTagIds: 1114
        workflowsDate: { from: $from, to: $to }
        skipCache: true
      ) {
        docId
        versions {
          publicationDate
          archiveDate
          tags(clusterIds: [2]) {
            tagId
            title
          }
          brandTags: tags(clusterIds: [18]) {
            tagId
            title
          }
          pages {
            type
          }
          title
          updateDate
          all {
            vote
            read
            comment
            bookmark
          }
          lang
          workflow
          type
        }
      }
    }
  }
`;
