import React, { useEffect } from "react";
import DefaultBattleEditor from "./EditBattle";
import BattleEditorReadOnly from "./battleEditorReadOnly";
import clientConfig from "../../configs/client";
import { USER_ROLE_NAMES } from "../../configs/constants";
import { connect } from "react-redux";
import { FullscreenLoader } from "genius-ui";
import {
  GetBattleInfo,
  SaveBattleQuestions,
} from "../../store/editBattle/actions";
import { getPath } from "../../core/paths";

const EditBattle = (props) => {
  const { GetBattleInfo, isMasterDocument, isLoading, userRoles } = props;

  useEffect(() => {
    GetBattleInfo();
  }, []);

  if (!userRoles || !userRoles.length || isLoading) {
    return <FullscreenLoader />;
  }

  if (
    clientConfig.IS_ROLE_READ_ONLY_MODE_ACTIVE &&
    userRoles.includes(USER_ROLE_NAMES.TRAINER) &&
    isMasterDocument
  ) {
    return <BattleEditorReadOnly {...props} />;
  } else {
    return <DefaultBattleEditor {...props} />;
  }
};

const mapStateToProps = (state) => ({
  userRoles: state.auth.profileInfo.roles,
  isLoading: state.editBattle.isLoading,
  questions: state.editBattle.questions,
  isMasterDocument: state.editBattle.isMasterDocument,
  documentLevel: state.documents.documentLevel,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  GetBattleInfo: () =>
    dispatch(
      GetBattleInfo(ownProps.match.params.id, ownProps.match.params.lang),
    ),
  SetQuestions: (questions) =>
    dispatch(
      SaveBattleQuestions(
        ownProps.match.params.id,
        ownProps.match.params.lang,
        questions,
      ),
    ),
  NavigateToPreviousPage: () => {
    ownProps.history.push(getPath("contentManager"));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBattle);
