import React, { useEffect } from "react";
import Style from "../EditMasterTemplate.style";
import withThemedStyle from "../../../components/hoc/withThemedStyle";
import { Icon, Icons, Tag, FullscreenLoader } from "genius-ui";
import { FormattedMessage } from "react-intl";
import { translations } from "../EditMasterTemplate.translations";
import ContentEditable from "react-contenteditable";
import FilterLanguages from "../../../components/FilterLanguages";
import { LANGUAGES } from "../../../configs/referential";
import clientConfig, { TAG_TYPE } from "../../../configs/client";
import { connect } from "react-redux";
import compose from "lodash/fp/compose";
import {
  GetMasterTemplateInfo,
  ResetStore,
} from "../../../store/editMasterTemplate/actions";

const EditMasterTemplate = ({
  classes,
  history,
  isFetching,
  GetMasterTemplateInfo,
  match: {
    params: { id, lang },
  },
  ResetStore,
  masterTemplateInfo,
}) => {
  useEffect(() => {
    GetMasterTemplateInfo(id, lang);
    return () => ResetStore();
  }, []);

  const { title, summary } = masterTemplateInfo;

  const goBack = () => history.goBack();

  const getMasterDegreeTags = () =>
    masterTemplateInfo[TAG_TYPE.EXPERTISE].filter(
      (tag) => parseInt(tag.Value) !== clientConfig.MASTER_DEGREE_TAG_ID,
    );

  return (
    <div className={classes.rootContainer}>
      {isFetching && <FullscreenLoader />}
      <div className={classes.center}>
        <div className={classes.left}>
          <FormattedMessage {...translations.TitlePlaceholder}>
            {(message) => (
              <ContentEditable
                html={title}
                className={classes.textInputTitle}
                placeholder={message}
                tagName="article"
                disabled
              />
            )}
          </FormattedMessage>
          <br />
          <FormattedMessage {...translations.SummaryPlaceholder}>
            {(message) => (
              <ContentEditable
                html={summary}
                className={classes.textInputSummary}
                placeholder={`${message}...`}
                tagName="article"
                disabled
              />
            )}
          </FormattedMessage>
          <br />
          <div className={classes.detailsContainer}>
            <div className={classes.leftPart}>
              <div className={classes.photo} />
            </div>
            <div className={classes.rightPart}>
              <div className={classes.langPublicContainer}>
                <span className={classes.firstTitle}>
                  <FormattedMessage {...translations.LanguageLabel} />
                </span>
              </div>
              <div className={classes.tagList}>
                <FilterLanguages languages={LANGUAGES}>
                  {(filteredLanguages) => {
                    const languages = [
                      filteredLanguages
                        .map((x) => ({
                          Id: x.Code,
                          Value: x.Name,
                        }))
                        .find((x) => x.Id === lang),
                    ];

                    return languages.map((x) => (
                      <Tag key={x.Value} label={x.Value} readOnly />
                    ));
                  }}
                </FilterLanguages>
              </div>
              <div className={classes.brandAndRetailer}>
                <div className={classes.brandTagsContainer}>
                  <div className={classes.sectionTitle}>
                    <FormattedMessage {...translations.BrandTagsTitle} />
                  </div>
                  <div className={classes.readOnlyTags}>
                    {masterTemplateInfo[TAG_TYPE.BRAND].map((tag) => (
                      <Tag key={tag.Value} label={tag.Value} readOnly />
                    ))}
                    {!masterTemplateInfo[TAG_TYPE.BRAND].length && (
                      <FormattedMessage {...translations.NoOneTagMessage} />
                    )}
                  </div>
                </div>
                <div className={classes.retailerTagsContainer}>
                  <div className={classes.sectionTitle}>
                    <FormattedMessage {...translations.RetailerTagsTitle} />
                  </div>
                  <div className={classes.readOnlyTags}>
                    {masterTemplateInfo[TAG_TYPE.RETAILER].map((tag) => (
                      <Tag key={tag.Value} label={tag.Value} readOnly />
                    ))}
                    {!masterTemplateInfo[TAG_TYPE.RETAILER].length && (
                      <FormattedMessage {...translations.NoOneTagMessage} />
                    )}
                  </div>
                </div>
              </div>
              <span className={classes.sectionTitle}>
                <FormattedMessage {...translations.TagsTitle} />
              </span>
              <div className={classes.readOnlyTags}>
                {getMasterDegreeTags().map((tag) => (
                  <Tag key={tag.Value} label={tag.Value} readOnly />
                ))}
                {!masterTemplateInfo[TAG_TYPE.EXPERTISE].length && (
                  <FormattedMessage {...translations.NoOneTagMessage} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.right} onClick={goBack}>
          <Icon iconName={Icons.slimCloseIcon} style={classes.closeIcon} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.common.fetchIndicator,
  masterTemplateInfo: state.editMasterTemplate.masterTemplateInfo,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  ResetStore: () => dispatch(ResetStore()),
  GetMasterTemplateInfo: (id, lang) =>
    dispatch(GetMasterTemplateInfo(id, lang)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withThemedStyle(Style),
)(EditMasterTemplate);
