import gql from "graphql-tag";

export const GET_GLOBAL_BATTLE_LEADER_BOARD_DATA = gql`
  query getGlobalBattleLeaderBoardData(
    $intervalId: Int
    $startDate: String
    $endDate: String
    $tagIds: [Int!]
    $skip: Int
    $limit: Int
    $sortAverageScore: String
    $sortBestScore: String
    $sortTotalScore: String
    $sortNumberOfAttempts: String
  ) {
    admin {
      globalLeaderboard(
        intervalId: $intervalId
        startDate: $startDate
        endDate: $endDate
        documentType: battle
        tagIds: $tagIds
        sortAverageScore: $sortAverageScore
        sortBestScore: $sortBestScore
        sortTotalScore: $sortTotalScore
        sortNumberOfAttempts: $sortNumberOfAttempts
        skip: $skip
        limit: $limit
      ) {
        user {
          userId
          profile {
            pic
            firstName
            lastName
          }
        }
        averageScore
        totalScore
        bestScore
        numberOfAttempts
      }
    }
  }
`;
