import gql from "graphql-tag";

export const DEGREE_REMOVE_TAGS = gql`
  mutation degreeRemoveTags($degreeId: ID, $lang: LangEnum, $tagIdList: [ID!]) {
    admin {
      degree(degreeId: $degreeId, lang: $lang) {
        tags {
          remove(tagIds: $tagIdList)
        }
      }
    }
  }
`;
