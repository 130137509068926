import React from "react";
import PropTypes from "prop-types";
import client from "../../graphql/client";
import { GET_TAGS_BY_PARENT_META_CLUSTER_IDS } from "../../graphql/remote/tags/queries";
import { TagSelectWithPaginatedAutocomplete } from "genius-ui";
import { ENV } from "../../configs/constants";
import { defineMessages, injectIntl } from "react-intl";

const messages = defineMessages({
  SuggestedTagsLabel: {
    id: "SelectTagsByParentMetaClusterId.SuggestedTagsLabel",
    defaultMessage: "Suggested Tags",
  },
  OtherTagsLabel: {
    id: "SelectTagsByParentMetaClusterId.OtherTagsLabel",
    defaultMessage: "Other Tags",
  },
});

const fetchTags = async (search, page, props) => {
  const limit = props.fetchAll ? undefined : props.tagsOnPage;
  const skip = props.fetchAll ? undefined : page * props.tagsOnPage;

  try {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_TAGS_BY_PARENT_META_CLUSTER_IDS,
      variables: {
        clusterMetaTagIds: props.clusterIds,
        // autocomplete: search,
        skip: skip,
        limit: limit,
      },
    });

    return result.data.tags;
  } catch (e) {
    if (ENV.IS_DEV) {
      console.error(`[Application error]: ${e}`);
    }
  }

  return [];
};

const SelectTagsByParentMetaClusterId = (props) => {
  const {
    clusterIds,
    selectedItems,
    onSelectionChanged,
    tagsOnPage,
    className,
    fetchSuggestedTags,
    intl,
    ...restProps
  } = props;
  const getTags = async (search, prevOptions, { page }) => {
    let tags = [];
    const fetchTagsPromise = async () => {
      tags = await fetchTags(search, page, props);
    };

    let suggestedTags = [];
    const fetchSuggestedTagsPromise = async () => {
      if (fetchSuggestedTags) {
        suggestedTags = await fetchSuggestedTags(search, page, props);
      }
    };

    await Promise.all([fetchTagsPromise(), fetchSuggestedTagsPromise()]);

    const formattedTags = tags.map((tag) => ({
      value: tag.tagId,
      label: tag.title,
    }));

    const formattedSuggestedTags = suggestedTags.map((tag) => ({
      value: tag.tagId,
      label: tag.title,
    }));

    const options =
      formattedSuggestedTags.length > 0 && page === 0
        ? [
            {
              label: intl.formatMessage(messages.SuggestedTagsLabel),
              options: formattedSuggestedTags,
            },
            {
              label: intl.formatMessage(messages.OtherTagsLabel),
              options: formattedTags,
            },
          ]
        : formattedTags;

    return {
      options,
      hasMore: true,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <div className={props.className}>
      <TagSelectWithPaginatedAutocomplete
        additional={{ page: 0 }}
        {...restProps}
        value={selectedItems}
        loadOptions={getTags}
        onChange={onSelectionChanged}
      />
    </div>
  );
};

SelectTagsByParentMetaClusterId.propTypes = {
  /** cluster ids where the tags are loaded from**/
  clusterIds: PropTypes.array.isRequired,
  /** selected tags **/
  selectedItems: PropTypes.array,
  /** function called when a new tag is selected or a tag is unselected **/
  onSelectionChanged: PropTypes.func.isRequired,
  /** how many tags are displayed on a page **/
  tagsOnPage: PropTypes.number,
  /** custom style for the container can be provided **/
  className: PropTypes.string,
  /** function(search, page): suggested tags can be fetched with this function and the returned result will be displayed
   * in a separate option group: 'Suggested tags' **/
  fetchSuggestedTags: PropTypes.func,
  /** fetch all tags **/
  fetchAll: PropTypes.bool,
  /** + All the props accepted by TagSelectWithPaginatedAutocomplete except:
   *  - value
   *  - loadOptions
   *  - onChange
   * **/
};

SelectTagsByParentMetaClusterId.defaultProps = {
  tagsOnPage: 10,
  fetchAll: false,
};

export default injectIntl(SelectTagsByParentMetaClusterId);
