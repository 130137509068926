import React from "react";
import { ContentTypeCardStyle } from "./ContentTypeCard.style";
import classnames from "classnames";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(ContentTypeCardStyle);

const ContentTypeCard = (props) => {
  const { containerClassName, title, description, eventHandler, disabled } =
    props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div
      className={classnames([classes.container, containerClassName])}
      onClick={disabled ? undefined : eventHandler}
    >
      <div className={classes.imageContent} />
      <div className={classes.infoContent}>
        <div className={classes.title}>{title}</div>
        <div className={classes.description}>{description}</div>
      </div>
      {disabled && <div className={classes.layer} />}
    </div>
  );
};

ContentTypeCard.propTypes = {
  /** onClick event handler */
  eventHandler: PropTypes.func,
  /** custom styles for card container */
  containerClassName: PropTypes.string,
  /** card title */
  title: PropTypes.string.isRequired,
  /** card description */
  description: PropTypes.string.isRequired,
  /** path to the card image */
  image: PropTypes.string.isRequired,
  /** component is disabled or not */
  disabled: PropTypes.bool,
};

export default ContentTypeCard;
