import React from "react";
import PropTypes from "prop-types";
import Style from "./ImageVideoUploader.style";
import { FormattedMessage } from "react-intl";
import {
  CROP_VARIATION,
  FILE_TYPES,
  VIDEO_FORMAT,
} from "../../../configs/constants";
import {
  PlaceHolderDegree,
  DefaultVideo,
  DefaultVideoCover,
} from "assets/icons";
import UploadMedia from "../UploadMedia";
import { Icon, Icons } from "genius-ui";
import CropImage from "../CropImage";
import SliderVideoPlayer from "../SliderVideoPlayer";
import { translations } from "./ImageVideoUploader.translations";
import injectSheet from "react-jss";

class ImageVideoUploader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      image: "",
      video: "",
      fileType: null,
      currentUploadProgress: 0,
      currentConversionProgress: 0,
      isUploading: false,
      isConverting: false,
      videoFormatType: null,
      isCropVisible: false,
    };

    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleUploadSuccess = this.handleUploadSuccess.bind(this);
    this.handleCoverUploadCompleted =
      this.handleCoverUploadCompleted.bind(this);
    this.handleVideoFormatChanged = this.handleVideoFormatChanged.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      image: !state.image ? props.image : state.image,
      video: !state.video ? props.video : state.video,
      fileType: !state.fileType
        ? props.video
          ? FILE_TYPES.VIDEO
          : props.image
          ? FILE_TYPES.IMAGE
          : null
        : state.fileType,
    };
  }

  handleUploadSuccess(url, fileType = FILE_TYPES.IMAGE) {
    this.setState({
      fileType,
      image: fileType === FILE_TYPES.IMAGE ? url : "",
      video: fileType === FILE_TYPES.VIDEO ? url.videoSource : "",
    });

    if (fileType === FILE_TYPES.IMAGE) {
      this.props.updateImage(url);
      this.props.updateVideo("");
    } else if (fileType === FILE_TYPES.VIDEO) {
      this.props.updateVideo(url.videoSource);
    }
  }

  handleImageChange(url) {
    this.setState({
      fileType: FILE_TYPES.IMAGE,
      image: url,
      video: "",
    });

    this.props.updateImage(url);
    this.props.updateVideo("");
  }

  handleCoverUploadCompleted(imageFile) {
    this.setState({
      image: imageFile,
    });
    this.props.updateImage(imageFile);
  }

  handleVideoFormatChanged(videoFormat) {
    this.setState({ videoFormatType: videoFormat });
  }

  render() {
    const { props } = this;
    const { id, classes } = props;

    return (
      <div
        className={classes.container}
        style={{
          backgroundImage: `url("${
            this.state.image ||
            (this.state.fileType === FILE_TYPES.VIDEO
              ? DefaultVideoCover
              : PlaceHolderDegree)
          }")`,
        }}
      >
        <div className={classes.uploadControlsBox}>
          <div className={classes.leftButtonsBox}>
            <UploadMedia
              id={`${id}-ImageVideoUpload`}
              className={classes.uploadBtnWrapper}
              onUploadSuccess={this.handleUploadSuccess}
              onFileTypeDetected={(fileType) => this.setState({ fileType })}
              onVideoUploading={(isUploading) => this.setState({ isUploading })}
              onVideoUploadProgress={(currentUploadProgress) =>
                this.setState({ currentUploadProgress })
              }
              onVideoConverting={(isConverting) =>
                this.setState({ isConverting })
              }
              onVideoConversionProgress={(currentConversionProgress) =>
                this.setState({ currentConversionProgress })
              }
            >
              <button>
                <Icon iconName={Icons.upload} style={classes.iconAction} />
                <FormattedMessage {...translations.UploadImageVideoLabel} />
              </button>
            </UploadMedia>
            {this.state.fileType === FILE_TYPES.VIDEO && (
              <UploadMedia
                id={`thumbnail-${id}`}
                className={classes.uploadBtnWrapper}
                onUploadSuccess={this.handleCoverUploadCompleted}
                isVideoUploadEnabled={false}
              >
                <button className={classes.btnUpload}>
                  <Icon iconName={Icons.thumbnail} style={classes.iconAction} />
                  <FormattedMessage {...translations.AddThumbnailText} />
                </button>
              </UploadMedia>
            )}
            {this.state.fileType === FILE_TYPES.IMAGE && (
              <CropImage
                className={classes.uploadBtnWrapper}
                width={props.width}
                height={props.height}
                sourceImage={props.image}
                isCropVisible={this.state.isCropVisible}
                cropSize={CROP_VARIATION.PUBLISH_CARD}
                onCropClick={() => this.setState({ isCropVisible: true })}
                onCancel={() => this.setState({ isCropVisible: false })}
                onCropCompleted={this.handleImageChange}
              />
            )}
          </div>
        </div>
        {this.state.fileType === FILE_TYPES.VIDEO && (
          <SliderVideoPlayer
            source={props.video}
            poster={props.image || DefaultVideoCover}
            onAspectRatioChanged={this.handleVideoFormatChanged}
            formatType={this.state.videoFormatType || VIDEO_FORMAT.PORTRAIT}
            isConverting={this.state.isConverting}
            isUploading={this.state.isUploading}
            progress={
              this.state.isConverting
                ? this.state.currentConversionProgress
                : this.state.currentUploadProgress
            }
            isActive
          />
        )}
      </div>
    );
  }
}

const dimensionPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

ImageVideoUploader.propTypes = {
  id: PropTypes.string.isRequired,
  width: dimensionPropType,
  height: dimensionPropType,
  video: PropTypes.string,
  image: PropTypes.string,
  updateVideo: PropTypes.func.isRequired,
  updateImage: PropTypes.func.isRequired,
};

ImageVideoUploader.defaultProps = {
  sourceVideo: DefaultVideo,
  width: 306,
  height: 391,
};

export default injectSheet(Style)(ImageVideoUploader);
