export default {
  container: {
    position: "relative",
    opacity: ({ isExportLoading }) => (isExportLoading ? 0.5 : 1),
    pointerEvents: ({ isExportLoading }) => (isExportLoading ? "none" : "auto"),
  },
  spinner: {
    position: "absolute",
    top: 400,
    left: "50%",
    right: "50%",
    zIndex: 1,
    opacity: ({ isExportLoading }) => (isExportLoading ? 1 : 0),
  },
  activeUser: {
    width: "100%",
  },
};
