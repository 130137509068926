export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Debounce function.
 * @param {function} fn - function to debounce
 * @param {number} time - debounce time in ms
 */
export const debounce = (fn, time) => {
  let timeoutId;

  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  };
};
