import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonSize,
  ButtonVariation,
  Icon,
  Icons,
  StylelessModal,
} from "genius-ui";
import style from "./LessonRelocationModal.style";
import GrayStrokedRSDropdown from "../../../components/common/GrayStrokedRSDropdown";
import { FormattedMessage } from "react-intl";
import { translations } from "./LessonRelocationModal.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const LessonRelocationModal = (props) => {
  const {
    visible,
    hideModal,
    programs,
    actualProgramId,
    onConfirm,
    degreeName,
    degreeLanguage,
  } = props;
  const [dropDownOptions, setDropdownOptions] = useState([]);
  const [selectedProgram, setProgram] = useState(null);
  const classes = useThemedStyle(useStyle, props);

  useEffect(() => {
    setDropdownOptions(
      programs
        .filter(
          (program) =>
            parseInt(program.programId) !== parseInt(actualProgramId),
        )
        .map((program) => ({
          value: program.programId,
          label: program.title,
        })),
    );

    return () => {
      setProgram(null);
    };
  }, [actualProgramId]);

  return (
    <StylelessModal show={visible} onClose={hideModal} centered>
      <div className={classes.modalContent}>
        <div className={classes.header}>
          <div className={classes.title}>
            <FormattedMessage {...translations.ModalTitle} />
          </div>
          <Icon
            handleClick={hideModal}
            iconName={Icons.slimCloseIcon}
            style={classes.close}
          />
        </div>
        <div className={classes.body}>
          <div className={classes.description}>
            <FormattedMessage
              {...translations.Introduction}
              values={{
                degreeName,
                degreeLanguage,
              }}
            />
            .
          </div>
          <div className={classes.label}>
            <FormattedMessage {...translations.ProgramLabel} />
          </div>
          <GrayStrokedRSDropdown
            placeholder={
              <FormattedMessage {...translations.SelectProgramPlaceholder} />
            }
            value={selectedProgram}
            options={dropDownOptions}
            onChange={(selected) => setProgram(selected)}
            className={classes.dropdown}
          />
          <div className={classes.actions}>
            <Button
              disabled={!selectedProgram}
              variation={ButtonVariation.primary}
              size={ButtonSize.big}
              handleClick={() => onConfirm(selectedProgram.value)}
            >
              <FormattedMessage {...translations.ConfirmButton} />
            </Button>
          </div>
        </div>
      </div>
    </StylelessModal>
  );
};

LessonRelocationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  programs: PropTypes.array.isRequired,
  actualProgramId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onConfirm: PropTypes.func.isRequired,
  degreeName: PropTypes.string.isRequired,
  degreeLanguage: PropTypes.string.isRequired,
};

LessonRelocationModal.defaultProps = {
  visible: false,
};

export default LessonRelocationModal;
