export const PageEditorStyle = ({ PageEditor, Colors }) => ({
  pageBox: {
    display: "flex",
    flexDirection: "column",
    color: PageEditor.pageBoxColor,
    width: 800,
  },
  componentsBox: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    marginTop: 10,
    marginBottom: 10,
  },
  draggableDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    paddingTop: 10,
    paddingBottom: 10,
    justifyContent: "space-between",
  },
  dragIcon: {
    color: `${Colors.lightBlue}`,
    cursor: "pointer",
    alignSelf: "flex-start",
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 10,
    },
    "&::before": {
      color: `${Colors.navigationMenu.inactiveItem}`,
      fontSize: 10,
    },
  },
  placeholder: {
    width: 415,
    minHeight: 50,
    mixBlendMode: "source-in",
    border: `solid 1px ${PageEditor.placeHolderSeparatorColor}`,
    backgroundColor: PageEditor.placeHolderBackgroundColor,
  },
});
