import React from "react";
import PageEditorReadOnly from "../../../components/PageEditor/pageEditorReadOnly";
import { PAGE_TEMPLATES, PAGE_TYPES } from "../../../configs/constants";
import HorizontalSliderPageEditorReadOnly from "../../../components/SliderPageEditor/Horizontal/horizontalReadOnly";
import VerticalSliderPageEditorReadOnly from "../../../components/SliderPageEditor/Vertical/verticalReadOnly";
import { EmptyArticleTemplatePage } from "../../../utils/ui-generator/defaultTemplates";
import ContentNotHandled from "../../../components/common/ContentNotHandled";
import SimpleQuizPageEditorReadOnly from "../../../components/SimpleQuizPageEditor/simpleQuizPageEditorReadOnly";
import MadlibPageEditorReadOnly from "../../../components/MadlibPageEditor/madlibReadOnly";
import DragAndDropActivityPageEditorReadOnly from "../../../components/DragAndDropActivityPageEditor/dragAndDropPageEditorReadOnly";
import { FullscreenLoader } from "genius-ui";

const LessonContentHandler = ({ publishLessonData, ...props }) => {
  if (props.isLoaderActive) {
    return <FullscreenLoader />;
  }

  return (
    <div className={props.classes.rootContainer}>
      {publishLessonData.pages
        .filter((x) => x.pageId !== "-1")
        .map((item) => {
          switch (item.template) {
            case PAGE_TEMPLATES.SLIDERFULLSCREEN_TEMPLATE:
              return (
                <div className={props.classes.pageItemBox} key={item.pageId}>
                  <HorizontalSliderPageEditorReadOnly
                    pageId={item.pageId}
                    data={item.data}
                    orderId={item.index}
                    documentId={props.lessonId}
                    language={props.language}
                    onDataChanged={props.onDataChanged}
                    onRemovePage={props.onRemovePage}
                    onOrderDownClick={props.onOrderDown}
                    onOrderUpClick={props.onOrderUp}
                    currentOpenedMarker={props.currentOpenedMarker}
                    UpdateOpenedMarker={(...args) =>
                      props.UpdateOpenedMarker(item.pageId, ...args)
                    }
                    isActivePage={props.activePageId === item.pageId}
                    onFocus={() => props.onPageChange(item.pageId)}
                  />
                </div>
              );
            case PAGE_TEMPLATES.FULLSCREENSCROLL_TEMPLATE:
              return (
                <div className={props.classes.pageItemBox} key={item.pageId}>
                  <VerticalSliderPageEditorReadOnly
                    pageId={item.pageId}
                    data={item.data}
                    orderId={item.index}
                    documentId={props.lessonId}
                    language={props.language}
                    onDataChanged={props.onDataChanged}
                    onRemovePage={props.onRemovePage}
                    onOrderDownClick={props.onOrderDown}
                    onOrderUpClick={props.onOrderUp}
                    currentOpenedMarker={props.currentOpenedMarker}
                    UpdateOpenedMarker={(...args) =>
                      props.UpdateOpenedMarker(item.pageId, ...args)
                    }
                    isActivePage={props.activePageId === item.pageId}
                    onFocus={() => props.onPageChange(item.pageId)}
                  />
                </div>
              );
            case PAGE_TEMPLATES.ARTICLE_TEMPLATE:
              return (
                <div className={props.classes.pageItemBox} key={item.pageId}>
                  <PageEditorReadOnly
                    pageId={item.pageId}
                    data={item.data}
                    orderId={item.index}
                    documentId={props.lessonId}
                    language={props.language}
                    onDataChanged={props.onDataChanged}
                    onRemovePage={props.onRemovePage}
                    onNewPageCreated={() =>
                      props.onNewPageCreated(
                        props.lessonId,
                        props.language,
                        JSON.stringify(EmptyArticleTemplatePage),
                        PAGE_TYPES.ARTICLE,
                      )
                    }
                    onPageChange={props.onPageChange}
                    isActivePage={props.activePageId === item.pageId}
                    onOrderDownClick={props.onOrderDown}
                    onOrderUpClick={props.onOrderUp}
                  />
                </div>
              );
            case PAGE_TEMPLATES.SIMPLE_QUIZ_TEMPLATE:
              return (
                <div className={props.classes.pageItemBox} key={item.pageId}>
                  <SimpleQuizPageEditorReadOnly
                    orderId={item.index}
                    pageId={item.pageId}
                    data={item.data}
                  />
                </div>
              );
            case PAGE_TEMPLATES.MADLIB_TEMPLATE:
              return (
                <div className={props.classes.pageItemBox} key={item.pageId}>
                  <MadlibPageEditorReadOnly
                    orderId={item.index}
                    pageId={item.pageId}
                    data={item.data}
                  />
                </div>
              );
            case PAGE_TEMPLATES.DRAG_AND_DROP_TEMPLATE:
              return (
                <div className={props.classes.pageItemBox} key={item.pageId}>
                  <DragAndDropActivityPageEditorReadOnly
                    orderId={item.index}
                    pageId={item.pageId}
                    data={item.data}
                  />
                </div>
              );
            default:
              return (
                <div className={props.classes.pageItemBox} key={item.pageId}>
                  <ContentNotHandled orderId={item.index} />
                </div>
              );
          }
        })}
    </div>
  );
};

export default LessonContentHandler;
