import React from "react";
import PropTypes from "prop-types";
import KeyDetailWidgetStyle from "./KeyDetailWidget.style";
import { Icon, Icons } from "genius-ui";
import { isNanOrNull } from "../../../utils/general";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import InfoTooltip from "../InfoTooltip";
import { FormattedMessage } from "react-intl";
import { translations } from "./KeyDetailsWidget.translations";

const useStyle = createUseStyles(KeyDetailWidgetStyle);

const KeyDetailWidget = (props) => {
  const { ...rest } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>{rest.title}</div>
        {!rest.isAvailable && <div className={classes.availability}>Soon</div>}
      </div>
      <div className={classes.body}>
        <div className={classes.primaryData}>
          {isNanOrNull(rest.primaryData) ? "-" : rest.primaryData}
        </div>
        {!rest.hideOtherData && (
          <div className={classes.otherData}>
            <div className={classes.percentData}>
              {isNanOrNull(rest.percentData) ? (
                ""
              ) : (
                <Icon
                  iconName={
                    rest.lowPercent ? Icons.arrowOrderDown : Icons.arrowOrderUp
                  }
                />
              )}
              {isNanOrNull(rest.percentData) ? "-" : `${rest.percentData}%`}
            </div>
            <div className={classes.secondaryData}>
              {isNanOrNull(rest.secondaryData) ? "-" : rest.secondaryData}
            </div>
          </div>
        )}
        {!rest.hideInfoIcon && (
          <div className={classes.dataTooltip}>
            <InfoTooltip
              title={<FormattedMessage {...translations.TooltipTitle} />}
            >
              <div className={classes.tooltipContent}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <Icon iconName={Icons.fullCircle} />
                      </td>
                      <td>
                        <FormattedMessage
                          {...translations.ActiveUsersDescription}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <Icon iconName={Icons.arrowOrderUp} />
                        </div>
                        <div>
                          <Icon iconName={Icons.arrowOrderDown} />
                        </div>
                      </td>
                      <td>
                        <FormattedMessage
                          {...translations.PositiveAndNegativePercentageDescription}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Icon iconName={Icons.fullCircle} />
                      </td>
                      <td>
                        <FormattedMessage
                          {...translations.DifferenceNumberDescription}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </InfoTooltip>
          </div>
        )}
      </div>
    </div>
  );
};

const PropStringOrNumber = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);
const PropStringOrObject = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
]);

KeyDetailWidget.propTypes = {
  /** KeyDetail widget title */
  title: PropStringOrObject,
  /** KeyDetail widget primary data */
  primaryData: PropStringOrNumber,
  /** KeyDetail widget secondary data */
  secondaryData: PropStringOrNumber,
  /** Data is available flag */
  isAvailable: PropTypes.bool, // TODO: modify when this part is clear
  /** DeyDetail widget percent data */
  percentData: PropStringOrNumber,
  /** Too low percentage flag */
  lowPercent: PropTypes.bool,
  /** Display or not the info icon */
  hideInfoIcon: PropTypes.bool,
  /** Display or not other data */
  hideOtherData: PropTypes.bool,
};

export default KeyDetailWidget;
