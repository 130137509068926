import gql from "graphql-tag";

export const LESSON_GET_TAG_IDS = gql`
  query lessonGetTagsIds($lessonIds: [ID!]) {
    lessons(lessonIds: $lessonIds) {
      lessonId
      tags(isGranted: true) {
        tagId
      }
    }
  }
`;
