import gql from "graphql-tag";

export const INSIGHT_DELETE_CONTENTTYPE = gql`
  mutation insightRemoveContentType(
    $insightId: ID
    $lang: LangEnum
    $type: ContentTypeEnum!
  ) {
    admin {
      insight(insightId: $insightId, lang: $lang) {
        delete(type: $type)
      }
    }
  }
`;
