import React from "react";
import PropTypes from "prop-types";
import Style from "./Users.style.js";
import { Icon, Icons, StatusButton, UserAvatar } from "genius-ui";
import OverflowContainer from "../../components/common/OverflowContainer";
import { STATUS_BUTTON_VARIATIONS, STATUS_ENUM } from "../../configs/constants";
import { UserStatuses } from "./Users.messages";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../hooks/style/useThemedStyle";
import clientConfig from "../../configs/client";
import { getUri } from "components/common/ImageOpti/ImageOpti.js";

const useStyle = createUseStyles(Style);

const UsersTableRow = (props) => {
  const { user, navigateToProfilePage, setShowModal, setSelectedUserId } =
    props;
  const classes = useThemedStyle(useStyle, props);
  const deleteHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedUserId(user.userId);
    setShowModal(true);
  };
  const formatColumn = (columnName) => {
    if (columnName === "profile") {
      return (
        <Icon
          iconName={Icons.private}
          handleClick={navigateToProfilePage(user.userId)}
        />
      );
    }
    if (columnName === "view") {
      return (
        <UserAvatar
          onClick={navigateToProfilePage(user.userId)}
          className={classes.profileAvatar}
          firstName={user.name.split("").length > 0 && user.name.split("")[0]}
          lastName={user.name.split("").length > 1 && user.name.split("")[1]}
          image={getUri(user.pic, "profile", "hd")}
        />
      );
    }
    if (columnName === "status") {
      switch (user[columnName]) {
        case STATUS_ENUM.ACTIVE:
          return (
            <StatusButton variation={STATUS_BUTTON_VARIATIONS.COMPLETE}>
              <FormattedMessage {...UserStatuses.CompleteStatusLabel} />
            </StatusButton>
          );
        case STATUS_ENUM.DRAFT:
          return (
            <StatusButton variation={STATUS_BUTTON_VARIATIONS.INCOMPLETE}>
              <FormattedMessage {...UserStatuses.IncompleteStatusLabel} />
            </StatusButton>
          );
        case STATUS_ENUM.ARCHIVED:
          return (
            <StatusButton variation={STATUS_BUTTON_VARIATIONS.INACTIVE}>
              <FormattedMessage {...UserStatuses.DeletedStatusLabel} />
            </StatusButton>
          );
        default:
          return user[columnName];
      }
    }
    return user[columnName];
  };

  return (
    <tr
      key={user.userId}
      className={classes.row}
      onClick={navigateToProfilePage(user.userId)}
    >
      {clientConfig.USER_MANAGEMENT_DETAIL_COLUMNS.map(
        ({ name: columnName, notDataBound }) =>
          !notDataBound ? (
            <td key={columnName} title={user[columnName]}>
              <OverflowContainer
                className={classes.tagsContainer}
                isExpansionDisabled
              >
                {formatColumn(columnName)}
              </OverflowContainer>
            </td>
          ) : null,
      )}
      <td>
        <div className={classes.actionContainer}>
          <span className={classes.deleteButton} onClick={deleteHandler}>
            <Icon iconName={Icons.delete2} />
          </span>
        </div>
      </td>
    </tr>
  );
};

UsersTableRow.propTypes = {
  user: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    /** + other props that are variable across environments(clients) **/
  }).isRequired,
};

export default UsersTableRow;
