export default {
  imageBox: {
    borderRadius: (props) => props.borderRadius,
    width: (props) => props.width,
    height: (props) => props.height,
    backgroundImage: (props) => `url("${props.url}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
};
