import { RESET_STORE } from "../../constants/store/auth";
import {
  RESET_EDIT_MASTER_STORE,
  UPDATE_MASTER_TEMPLATE_INFO,
} from "../../constants/store/editMasterTemplate";
import { PlaceHolderDegree } from "assets/icons";
import { TAG_TYPE } from "../../configs/client";

const initialState = {
  masterTemplateInfo: {
    id: null,
    lang: null,
    title: "",
    summary: "",
    picCover: PlaceHolderDegree,
    [TAG_TYPE.BRAND]: [],
    [TAG_TYPE.RETAILER]: [],
    [TAG_TYPE.EXPERTISE]: [],
    tagsIsEditable: true,
    workflow: null,
  },
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case UPDATE_MASTER_TEMPLATE_INFO: {
      return {
        ...state,
        masterTemplateInfo: {
          ...state.masterTemplateInfo,
          ...data,
        },
      };
    }
    case RESET_EDIT_MASTER_STORE: {
      return { ...initialState };
    }
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
