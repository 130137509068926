import client from "graphql/client";
import clientConfig from "configs/client";
import PublishFile from "pages/createFile/partials/PublishFile";
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import {
  ADD_DOCUMENT_TAGS,
  DELETE_DOCUMENT_TAGS,
  EDIT_DOCUMENT_FIELD,
  EDIT_DOCUMENT_PAGE,
  GetDocument,
} from "store/publish/graphs";
import { tagIdTitleToDropDown } from "utils/transformers/tags";
import { UPDATE_FILE_WORKFLOW } from "graphql/remote/file/mutations/UpdateWorkflow.graphql";
import { getFormattedSopiDate } from "utils/dateUtils";

const updateFile = async (fileId, pageId, oldFile, newFile, lang, callback) => {
  if (oldFile.title !== newFile.title) {
    await client.mutate({
      mutation: EDIT_DOCUMENT_FIELD("file", "title"),
      variables: {
        id: fileId,
        lang,
        data: newFile.title,
      },
    });
  }
  const tagIdsToAdd = newFile.tagIds.filter(
    (id) => !oldFile.tagIds.includes(id),
  );
  if (tagIdsToAdd.length) {
    await client.mutate({
      mutation: ADD_DOCUMENT_TAGS("file"),
      variables: {
        id: fileId,
        lang,
        tagIds: tagIdsToAdd,
      },
    });
  }
  const tagIdsToDelete = oldFile.tagIds.filter(
    (id) => !newFile.tagIds.includes(id),
  );
  if (tagIdsToDelete.length) {
    await client.mutate({
      mutation: DELETE_DOCUMENT_TAGS("file"),
      variables: {
        id: fileId,
        lang,
        tagIds: tagIdsToDelete,
      },
    });
  }
  if (oldFile.pageData.cover !== newFile.pageData.cover) {
    await client.mutate({
      mutation: EDIT_DOCUMENT_FIELD("file", "picCover"),
      variables: {
        id: fileId,
        lang,
        data: newFile.pageData.cover,
      },
    });
  }
  if (
    oldFile.pageData.cover !== newFile.pageData.cover ||
    oldFile.pageData.description !== newFile.pageData.description ||
    oldFile.pageData.url !== newFile.pageData.url
  ) {
    await client.mutate({
      mutation: EDIT_DOCUMENT_PAGE("file"),
      variables: {
        fileId,
        lang,
        pageId,
        data: JSON.stringify(newFile.pageData),
      },
    });
  }
  if (
    oldFile.publicationDate.slice(0, 10) !==
    newFile.publicationDate.slice(0, 10)
  ) {
    const status =
      new Date(getFormattedSopiDate(new Date())) -
        new Date(newFile.publicationDate) <
      0
        ? "DRAFT"
        : "PUBLISHED";
    await client.mutate({
      mutation: UPDATE_FILE_WORKFLOW,
      variables: {
        fileId,
        lang,
        status,
        dateIn: newFile.publicationDate,
      },
    });
  }
  callback && callback();
};

const EditFile = (props) => {
  const { id } = props.match.params;
  const { data } = useQuery(GetDocument("file"), {
    variables: { fileId: Number(id) },
    fetchPolicy: "no-cache",
  });

  const [title, setTitle] = useState(null);
  const [cover, setCover] = useState(null);
  const [brandTags, setBrandTags] = useState(null);
  const [publicationDate, setPublicationDate] = useState(null);
  const [url, setUrl] = useState(null);
  const [marketTag, setMarketTag] = useState(null);
  const [description, setDescription] = useState(null);
  const [tagIds, setTagIds] = useState(null);
  const [language, setLanguage] = useState(null);
  const [fileType, setFileType] = useState(null);

  useEffect(() => {
    if (!data) return;
    const { title, pages, workflowScheduledIn, tags, lang } =
      data?.admin?.files?.[0].versions?.[0];
    const { url, cover, description } = JSON.parse(pages?.[0]?.data);

    setLanguage(lang);
    setTitle(title);
    setPublicationDate(workflowScheduledIn);
    setUrl(url);
    setCover(cover);
    setDescription(description);
    setBrandTags(
      tags
        .filter(({ clusterId }) =>
          clientConfig.BRAND_TAGS_CLUSTER_IDS.includes(Number(clusterId)),
        )
        .map(tagIdTitleToDropDown),
    );
    setMarketTag(
      tagIdTitleToDropDown(tags.find(({ clusterId }) => clusterId === "2")),
    );
    setTagIds(tags.map(({ tagId }) => tagId));
    setFileType(pages?.[0]?.type);
  }, [data]);

  const handleUpdateFile = useCallback(
    ({ newTagIds, toDeleteTagIds }, callback) => {
      const {
        title: oldTitle,
        pages,
        workflowScheduledIn,
        tags,
      } = data?.admin?.files?.[0].versions?.[0];
      const pageData = JSON.parse(pages?.[0]?.data);
      updateFile(
        id,
        pages?.[0]?.pageId,
        {
          title: oldTitle,
          cover: pageData.oldCover,
          publicationDate: workflowScheduledIn,
          tagIds: [
            ...tags
              .filter(({ clusterId }) =>
                clientConfig.BRAND_TAGS_CLUSTER_IDS.includes(Number(clusterId)),
              )
              .map(({ tagId }) => tagId),
            ...toDeleteTagIds,
          ],
          pageData,
        },
        {
          title,
          cover,
          publicationDate,
          tagIds: [...brandTags.map(({ value }) => value), ...newTagIds],
          pageData: {
            description,
            url,
            cover,
          },
        },
        language,
        callback,
      );
    },
    [id, data, title, cover, publicationDate, brandTags, url, description],
  );

  if (!marketTag) return null;

  return (
    <PublishFile
      title={title}
      setTitle={setTitle}
      cover={cover}
      setCover={setCover}
      url={url}
      setUrl={setUrl}
      publicationDate={publicationDate}
      setPublicationDate={setPublicationDate}
      selectedMarket={marketTag}
      brandTags={brandTags}
      tagIds={tagIds}
      setBrandTags={setBrandTags}
      onUpdateFile={handleUpdateFile}
      edit
      description={description}
      setDescription={setDescription}
      lang={language}
      fileType={fileType}
    />
  );
};

export default EditFile;
