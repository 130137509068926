import React, { useState } from "react";
import { Icon, Icons, Button, ButtonSize, ButtonVariation } from "genius-ui";
import style from "./BulletTextInput.style";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { translations } from "./BulletTextInput.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const BulletTextInput = (props) => {
  const { handleOnBlur } = props;
  const classes = useThemedStyle(useStyle, props);
  const [currentValue, setCurrentValue] = useState("");

  const onValueChange = (event) => {
    setCurrentValue(event.target.value);
  };
  const [validation, setValidation] = useState(false);

  return (
    <div className={classes.container}>
      <div className={classes.inputIconWrap}>
        <span className={classes.inputIcon}>
          <Icon iconName={Icons.fullCircle} />
        </span>
        <FormattedMessage {...translations.AddLessonPlaceholderText}>
          {(message) => (
            <input
              type="text"
              value={currentValue}
              className={classes.inputWithIcon}
              placeholder={message[0]}
              onChange={(event) => {
                onValueChange(event);
                if (event.target.value !== currentValue) {
                  setValidation(true);
                }
              }}
            />
          )}
        </FormattedMessage>
      </div>
      {validation && (
        <div>
          <Button
            variation={ButtonVariation.primary}
            size={ButtonSize.small}
            handleClick={(event) => {
              event.target.value = currentValue;
              handleOnBlur(event);
              setCurrentValue("");
              setValidation(false);
            }}
          >
            Create
          </Button>
        </div>
      )}
    </div>
  );
};

BulletTextInput.propTypes = {
  handleOnBlur: PropTypes.func.isRequired,
};

export default BulletTextInput;
