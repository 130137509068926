import React, { useEffect } from "react";
import axios from "axios";
import { FUSIONAUTH_CONFIG, AXIOS_CONFIG } from "../../configs/client";
import {
  handleToken,
  jsonToString,
  parseQueryString,
} from "../../utils/authentification";
import { FullscreenLoader } from "genius-ui";

const handleRedirect = async ({ code }) => {
  const tokenParams = jsonToString({
    code,
    client_id: FUSIONAUTH_CONFIG.clientId,
    grant_type: "authorization_code",
    redirect_uri: FUSIONAUTH_CONFIG.redirectUrl,
    scope: encodeURIComponent(FUSIONAUTH_CONFIG.scope),
  });
  const tokenUrl = `${FUSIONAUTH_CONFIG.issuer}${FUSIONAUTH_CONFIG.routes.token}?${tokenParams}`;

  try {
    const res = await axios.post(tokenUrl, {}, AXIOS_CONFIG);
    handleToken(res.data);
    window.location.href = "/";
  } catch (error) {
    window.location.href = "/";
    console.warn(error);
  }
};

const LoginRedirection = () => {
  useEffect(() => {
    const params = parseQueryString();

    if (!params) {
      window.location.href = "/";
      return null;
    }
    window.history.replaceState({}, document.title, "/redirection");
    handleRedirect(params);
  }, []);

  return <FullscreenLoader />;
};

export default LoginRedirection;
