import client from "../../graphql/client";
import {
  GET_TAGS_BY_CLUSTER_ID_PAGINATED,
  GET_TAGS_BY_PARENT_META_CLUSTER_IDS,
} from "../../graphql/remote/tags/queries";
import { ENV } from "../../configs/constants";

const createFetch =
  (
    query = GET_TAGS_BY_CLUSTER_ID_PAGINATED,
    buildVariables = (clusterIds, search, page, resultsOnPage) => ({
      clusterIds,
      autocomplete: search,
      skip: page * resultsOnPage,
      limit: resultsOnPage,
    }),
  ) =>
  async (search, page, clusterIds, resultsOnPage) => {
    try {
      const result = await client.query({
        fetchPolicy: "network-only",
        query,
        variables: buildVariables(clusterIds, search, page, resultsOnPage),
      });

      return result.data.tags;
    } catch (e) {
      if (ENV.IS_DEV) {
        console.error(`[Application error]: ${e}`);
      }
    }

    return [];
  };

export const fetchTags = createFetch();

export const fetchTagsByParentMetaClusterId = createFetch(
  GET_TAGS_BY_PARENT_META_CLUSTER_IDS,
  (clusterIds, search, page, resultsOnPage) => ({
    clusterMetaTagIds: clusterIds,
    autocomplete: search || undefined,
    skip: page * resultsOnPage,
    limit: resultsOnPage,
  }),
);

const useTagAutocomplete = (
  clusterIds,
  resultsOnPage = 10,
  mapTagResults = (tag) => ({
    value: tag.tagId,
    label: tag.title,
    originalTagData: tag,
  }),
  fetch = fetchTags,
) => {
  return async (search, prevOptions, { page }) => {
    const tagResults = await fetch(search, page, clusterIds, resultsOnPage);

    return {
      options: tagResults.map(mapTagResults),
      hasMore: tagResults.length !== 0,
      additional: {
        page: page + 1,
      },
    };
  };
};

export default useTagAutocomplete;
