import React from "react";
import PropTypes from "prop-types";
import Style from "./TagCloudWidget.style.js";
import { TagCloud } from "genius-charts";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const TagCloudWidget = (props) => {
  const { title, tags } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.container}>
      <div className={classes.title}>{title}</div>
      <TagCloud tags={tags} />
    </div>
  );
};

TagCloudWidget.propTypes = {
  title: PropTypes.node.isRequired,
  tags: PropTypes.array.isRequired,
};

export default TagCloudWidget;
