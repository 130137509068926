import gql from "graphql-tag";

export const TAG_CREATE = gql`
  mutation createTag($tag: String!, $clusterId: Int) {
    tag {
      createCustom(
        title: $tag
        skipCheckTagFeature: true
        clusterId: $clusterId
        checkExactMatch: true
      ) {
        tagId
        title
        clusterId
      }
    }
  }
`;
