import gql from "graphql-tag";

export const DEGREE_ADD_TAGS = gql`
  mutation degreeAddTags($degreeId: ID, $lang: LangEnum, $tagIdList: [ID!]) {
    admin {
      degree(degreeId: $degreeId, lang: $lang) {
        tags {
          add(tagIds: $tagIdList)
        }
      }
    }
  }
`;
