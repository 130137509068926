import { RESET_STORE } from "../../constants/store/auth";
import {
  UPDATE_QUICK_QUIZ_DATA,
  SET_QUICK_QUIZ_ID,
  SET_QUICK_QUIZ_LANGUAGE_ID,
  RESET_QUICK_QUIZ_DATA,
  SET_QUICK_QUIZ_MASTER_FLAG,
} from "../../constants/store/quickQuiz";

const initialState = {
  id: null,
  languageId: null,
  cardId: null,
  isMasterDocument: false,
  card: {
    pageId: null,
    answers: [
      { isTrue: null, title: "", message: "" },
      { isTrue: null, title: "", message: "" },
      { isTrue: null, title: "", message: "" },
    ],
    endMessage: "",
    image: {
      url: "",
      copyright: "",
      legend: "",
    },
    question: "",
  },
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case UPDATE_QUICK_QUIZ_DATA: {
      return {
        ...state,
        ...data,
      };
    }
    case SET_QUICK_QUIZ_ID: {
      return {
        ...state,
        id: data,
      };
    }
    case SET_QUICK_QUIZ_LANGUAGE_ID: {
      return {
        ...state,
        languageId: data,
      };
    }
    case SET_QUICK_QUIZ_MASTER_FLAG: {
      return {
        ...state,
        isMasterDocument: data,
      };
    }
    case RESET_QUICK_QUIZ_DATA: {
      return { ...initialState };
    }
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
