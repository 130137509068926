import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import createLogger from "redux-logger";
import thunk from "redux-thunk";
import reducers from "../reducers";
import { routerMiddleware, connectRouter } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import history from "./history";
import { reducer as reduxFormReducer } from "redux-form";
import { ENV } from "../../configs/constants";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["referential"],
};

const middlewareRouter = routerMiddleware(history);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let middlewares = [];
if (ENV.IS_DEV && !ENV.IS_TEST) {
  middlewares = [...middlewares, middlewareRouter, thunk, createLogger];
} else {
  middlewares = [...middlewares, middlewareRouter, thunk];
}
const store = createStore(
  persistReducer(
    persistConfig,
    combineReducers({
      ...reducers,
      router: connectRouter(history),
      form: reduxFormReducer,
    }),
  ),
  composeEnhancers(applyMiddleware(...middlewares)),
);

const configureStore = () => {
  const persist = persistStore(store);

  return { store, persist, history };
};

export { store, configureStore };
