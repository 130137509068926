import { NINJA_QUESTION_TYPE_ENUM } from "../../../configs/constants";

const transition = "all 300ms ease-out";

export default (theme) => ({
  container: {
    display: "flex",
    paddingBottom: 30,
  },
  innerPageContainer: ({ isSelected }) => ({
    position: "relative",
    border: `2px solid ${
      isSelected
        ? theme.NinjaQuestionEditor.innerPageContainerSelectedColor
        : theme.NinjaQuestionEditor.innerPageContainerColor
    }`,
    cursor: isSelected ? undefined : "pointer",
    padding: "50px 25px",
    width: 414,
    height: 737,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.NinjaQuestionEditor.innerPageContainerBackgroundColor,
    boxSizing: "border-box",
    transition,
  }),
  changeTemplate: {
    opacity: ({ isSelected }) => (isSelected ? 1 : 0),
    pointerEvents: ({ isSelected }) => (isSelected ? "auto" : "none"),
    minWidth: 258,
    transition,
  },
  deleteButton: {
    marginLeft: "auto",
    fontSize: 19,
    cursor: ({ isSelected }) => (isSelected ? "pointer" : "normal"),
    "& .icon-recycle:before": {
      color: theme.NinjaQuestionEditor.deleteButtonColor,
    },
    "&:hover .icon-recycle:before": {
      color: theme.NinjaQuestionEditor.deleteButtonHoverColor,
    },
  },
  media: ({ type, image }) => ({
    position: "relative",
    marginTop: 42,
    display:
      type === NINJA_QUESTION_TYPE_ENUM.BACKGROUND ? "inline-block" : "none",
    opacity: type === NINJA_QUESTION_TYPE_ENUM.BACKGROUND ? 1 : 0,
    minWidth: 374,
    minHeight: 255,
    backgroundImage: `url('${image}')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: theme.NinjaQuestionEditor.mediaBackgroundColor,
    transition,
  }),
  mediaOverlay: {
    display: "inline-block",
    position: "absolute",
    top: 0,
    right: 0,
    width: "100%",
    height: "20%",
    backgroundImage: `linear-gradient(to bottom, ${theme.NinjaQuestionEditor.mediaOverlayBackgroundFirst}, ${theme.NinjaQuestionEditor.mediaOverlayBackgroundSecond})`,
  },
  uploadImageButton: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  cropImageButton: {
    position: "absolute",
    top: 20,
    left: 140,
    "& > button": {
      border: "none",
      backgroundColor: theme.NinjaQuestionEditor.cropImageButtonBackground,
      color: theme.NinjaQuestionEditor.cropImageButtonColor,
      cursor: "pointer",
      fontSize: 14,
      transition,
      "& .icon-crop:before": {
        marginRight: 5,
        color: theme.NinjaQuestionEditor.cropImageButtonIcon,
        transition,
      },
      "&:hover": {
        color: theme.NinjaQuestionEditor.cropImageButtonIconHover,
        "& .icon-crop:before": {
          color: theme.NinjaQuestionEditor.cropImageButtonIconHover,
        },
      },
    },
  },
  spacer: {
    flex: 1,
  },
  questionContainer: {
    marginTop: 20,
    marginBottom: 20,
    overflow: "hidden",
  },
  questionInput: {
    fontSize: 25,
    fontWeight: "bold",
    color: theme.NinjaQuestionEditor.questionInputColor,
    wordBreak: "break-word",
    "&[contenteditable]:empty:before": {
      content: "attr(placeholder)",
      display: "block",
    },
  },
  descriptionContainer: {
    marginTop: 20,
    marginBottom: 20,
    overflow: "hidden",
  },
  descriptionInput: {
    display: ({ type }) =>
      type === NINJA_QUESTION_TYPE_ENUM.COVER ? "block" : "none",
    opacity: ({ type }) => (type === NINJA_QUESTION_TYPE_ENUM.COVER ? 1 : 0),
    fontSize: 18,
    color: theme.NinjaQuestionEditor.descriptionInputColor,
    wordBreak: "break-word",
    transition,
    "&[contenteditable]:empty:before": {
      content: "attr(placeholder)",
      display: "block",
    },
  },
  radioHeaderContainer: {
    marginBottom: 20,
    display: "flex",
    flexDirection: "row",
  },
  correctLabel: {
    marginRight: 10,
    flex: 0.1,
  },
  choiceTypeLabel: {
    flex: 0.1,
  },
  radioHeaderLabel: {
    fontFamily: theme.FontFamily,
    fontSize: 18,
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    textAlign: "center",
    color: theme.NinjaQuestionEditor.radioHeaderColor,
  },
  options: {
    width: 345,
  },
  radioContainer: {
    height: 230,
    overflowX: "hidden",
    overflowY: "auto",
  },
  addChoice: {
    textAlign: "center",
    fontSize: 18,
    marginTop: 15,
    cursor: "pointer",
    color: "rgb(124, 124, 124)",
  },
});
