import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Style from "./CompletionRate.style.js";
import { ProgressBar } from "genius-ui";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

const CompletionRate = (props) => {
  const {
    completed,
    total,
    formatLabel,
    className,
    progressBarClassname,
    completionPercentage,
  } = props;
  const percentage =
    completionPercentage || parseInt((completed / total) * 100);
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classNames([classes.container, className])}>
      <p
        className={classNames([
          "completion-rate-label",
          classes.completionRate,
        ])}
      >
        {formatLabel(completed, total)}
      </p>
      <ProgressBar
        percentage={percentage}
        showPercentageValue
        progressBarClassName={classNames([
          classes.progressBar,
          progressBarClassname,
        ])}
      />
    </div>
  );
};

CompletionRate.propTypes = {
  /** completed items **/
  completed: PropTypes.number.isRequired,
  /** total items **/
  total: PropTypes.number.isRequired,
  /** format how the completion rate is displayed above the progress bar **/
  formatLabel: PropTypes.func,
  /** custom style for the container **/
  className: PropTypes.string,
  /** custom style for the progress bar **/
  progressBarClassname: PropTypes.string,
};

CompletionRate.defaultProps = {
  formatLabel: (completed, total) => `${completed}/${total}`,
};

export default CompletionRate;
