import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { ProgressItemStyle } from "./MultiProgressChart.style.js";
import { ProgressBar } from "genius-ui";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(ProgressItemStyle);

const MultiProgressChartProgressItem = (props) => {
  const { name, progress } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.container}>
      <span>{name}</span>
      <ProgressBar
        percentage={progress}
        className={classes.progressBarContainer}
        progressBarClassName={classes.progressBar}
        fillerClassName={classes.filler}
        showPercentageValue
        showPercentageValueOnRight
        percentageLabelClassName={classes.percentageLabel}
      />
    </div>
  );
};

MultiProgressChartProgressItem.propTypes = {
  name: PropTypes.node.isRequired,
  progress: PropTypes.number.isRequired,
};

export default MultiProgressChartProgressItem;
