import insights from "../insight/reducers";
import navigation from "../navigation/reducers";
import degrees from "../degree/reducers";
import referential from "../referential/reducers";
import notification from "../notification/reducers";
import auth from "../auth/reducers";
import cards from "../card/reducers";
import quickQuiz from "../quickQuiz/reducers";
import documents from "../document/reducers";
import duplication from "../duplication/reducers";
import users from "../user/reducers";
import kpi from "../kpi/reducers";
import tags from "../tag/reducers";
import sendNotification from "../sendNotification/reducers";
import userProfile from "../userProfile/reducers";
import mediaList from "../mediaList/reducers";
import mediaInfo from "../mediaInfo/reducers";
import editMedia from "../editMedia/reducers";
import createMedia from "../createMedia/reducers";
import selectMedia from "../selectMedia/reducers";
import lesson from "../lesson/reducers";
import shops from "../shop/reducers";
import common from "../common/reducers";
import editJournalistProfile from "../editJournalistProfile/reducers";
import editBattle from "../editBattle/reducers";
import publishBattle from "../publishBattle/reducers";
import appAnalytics from "../appAnalytics/reducers";
import editNinja from "../editNinja/reducers";
import editPoll from "../editPoll/reducers";
import publishPoll from "../publishPoll/reducers";
import publishNinja from "../publishNinja/reducers";
import masterTemplates from "../masterTemplate/reducers";
import editMasterTemplate from "../editMasterTemplate/reducers";
import editLesson from "../editLesson/reducers";
import masterDocuments from "../masterDocument/reducers";
import pollResults from "../pollResult/reducers";
import battleResults from "../battleResult/reducers";
import kpiBattle from "../kpiBattle/reducers";
import publish from "../publish/reducers";
import pdf from "../pdf/reducers";
import ninja from "../ninja/reducers";

export default {
  appAnalytics,
  auth,
  battleResults,
  cards,
  common,
  createMedia,
  degrees,
  documents,
  duplication,
  editBattle,
  editLesson,
  editJournalistProfile,
  editMasterTemplate,
  editMedia,
  editNinja,
  editPoll,
  insights,
  kpi,
  kpiBattle,
  lesson,
  masterDocuments,
  masterTemplates,
  mediaInfo,
  mediaList,
  navigation,
  ninja,
  notification,
  pdf,
  pollResults,
  publish,
  publishBattle,
  publishPoll,
  publishNinja,
  quickQuiz,
  referential,
  selectMedia,
  sendNotification,
  shops,
  tags,
  userProfile,
  users,
};
