import { defineMessages } from "react-intl";

export const translations = defineMessages({
  AddToMarketOption: {
    id: "ProgramInfoTrainer.AddToMarketOption",
    defaultMessage: "Add to market",
  },
  ViewContentLabel: {
    id: "ProgramInfoTrainer.ViewContentLabel",
    defaultMessage: "View content",
  },
  EditContentLabel: {
    id: "ProgramInfoTrainer.EditContentLabel",
    defaultMessage: "Edit content",
  },
  PublishOption: {
    id: "ProgramInfoTrainer.PublishOption",
    defaultMessage: "Publication settings",
  },
  LessonAuthorLabel: {
    id: "ProgramInfoTrainer.LessonAuthorLabel",
    defaultMessage: "Author: {name}",
  },
  NoOneLessonText: {
    id: "ProgramInfoTrainer.NoOneLessonText",
    defaultMessage: "No one lesson",
  },
  DeleteOption: {
    id: "ProgramInfoTrainer.DeleteOption",
    defaultMessage: "Delete",
  },
  MoveToProgramOption: {
    id: "ProgramInfoTrainer.MoveToProgramOption",
    defaultMessage: "Change Program",
  },
  SendNotificationOption: {
    id: "ProgramInfoTrainer.SendNotificationTitle",
    defaultMessage: "Send Notification",
  },
  AvailableToUseLabel: {
    id: "ProgramInfoTrainer.AvailableToUseLabel",
    defaultMessage: "Available to use",
  },
  BadgeOption: {
    id: "ProgramInfoTrainer.BadgeOption",
    defaultMessage: "Edit badges",
  },
});
