import { defineMessages } from "react-intl";

export const translations = defineMessages({
  Title: {
    id: "ForgotPasswordScreen.Title",
    defaultMessage: "Forgotten password",
  },
  SubTitle: {
    id: "ForgotPasswordScreen.SubTitle",
    defaultMessage:
      "We'll send you a new password right away, just enter your email below.",
  },
  EmailInputLabel: {
    id: "ForgotPasswordScreen.EmailInputLabel",
    defaultMessage: "Email",
  },
  BackToLoginText: {
    id: "ForgotPasswordScreen.BackToLoginText",
    defaultMessage: "Return to log in",
  },
  SendButtonText: {
    id: "ForgotPasswordScreen.SendButtonText",
    defaultMessage: "Reset",
  },
});
