import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Style from "../EditBattle.style";
import { Notification } from "genius-ui";
import BattleEditorReadOnly from "../../../components/Battle/BattleEditorReadOnly";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import { FormattedMessage } from "react-intl";
import { translations } from "../EditBattle.translations";
import SideBarNavigation from "../../../components/SideBarNavigation";

const useStyle = createUseStyles(Style);

const EditBattle = (props) => {
  const { questions, NavigateToPreviousPage } = props;
  const classes = useThemedStyle(useStyle, props);
  const [isNotificationOpen, setNotificationStatus] = useState(true);

  return (
    <>
      <Notification
        message={<FormattedMessage {...translations.ReadOnlyMessage} />}
        status="warning"
        isOpen={isNotificationOpen}
        handleClose={setNotificationStatus}
      />
      <SideBarNavigation handleBackClick={NavigateToPreviousPage} />
      <div className={classes.battleEditorContainer}>
        <BattleEditorReadOnly
          className={classes.battleEditor}
          questions={questions}
        />
      </div>
    </>
  );
};

export default EditBattle;
