import client from "graphql/client";
import clientConfig from "configs/client";
import { SetErrorMessage } from "../notification/actions";
import { GET_LOCATIONS_BY_IDS } from "graphql/remote/lessons/queries";
import { DOC_WORKFLOW_ENUM, OBJECT_ACTION_ENUM } from "configs/constants";
import { UPDATE_LOCATIONS as updateLocationsGraph } from "graphql/remote/lessons/mutations";
import {
  GetDocument as GetDocumentGraph,
  EDIT_DOCUMENT_FIELD as EditDocumentGraph,
  EDIT_DOCUMENT_FIELDS as EditMultipleDocumentsGraph,
  ADD_DOCUMENT_TAGS,
  DELETE_DOCUMENT_TAGS,
  SWITCH_PRIVACY_DOCUMENT as SwitchPrivacyGraph,
  UPDATE_WORFLOW as publishDateGraph,
  SET_RECAP as publishRecapGraph,
} from "./graphs";
import {
  SET_PUBLISH_LOADED,
  SET_PUBLISH_DOCUMENT,
  UPDATE_PUBLISH_DOCUMENT,
  DELETE_TAG,
  SET_DOCUMENT_LOCATIONS,
  UPDATE_LESSON_LOCATIONS_SKIP,
  UPDATE_ATTACHMENTS,
} from "constants/store/publish";
import {
  formatDateTimeForSave,
  getFormattedSopiDate,
} from "../../utils/dateUtils";
import { ADD_ATTACHMENT } from "graphql/remote/lessons/mutations/AddAttachment.graphql";
import { REMOVE_ATTACHMENT } from "graphql/remote/lessons/mutations/RemoveAttachment.graphql";

const sortTags = (accumulator, currentTag) => {
  if (clientConfig.ZONE_CLUSTER_IDS.includes(Number(currentTag.clusterId))) {
    accumulator.zones.push(currentTag);
  }
  if (
    clientConfig.COUNTRY_TAGS_CLUSTERIDS.includes(Number(currentTag.clusterId))
  ) {
    accumulator.countries.push(currentTag);
  }
  if (
    clientConfig.BRAND_TAGS_CLUSTER_IDS.includes(Number(currentTag.clusterId))
  ) {
    accumulator.brand.push(currentTag);
  }
  if (
    clientConfig.RETAILER_TAGS_CLUSTER_IDS.includes(
      Number(currentTag.clusterId),
    )
  ) {
    accumulator.retailer.push(currentTag);
  }
  if (
    clientConfig.EXPERTISE_CLUSTERIDS.includes(Number(currentTag.clusterId))
  ) {
    accumulator.associated.push(currentTag);
  }
  if (
    clientConfig.UNIVERSE_CLUSTER_IDS.includes(Number(currentTag.clusterId))
  ) {
    accumulator.universe.push(currentTag);
  }

  return accumulator;
};

export const GetDocument = (id, type, lang) => async (dispatch) => {
  try {
    dispatch({ type: SET_PUBLISH_LOADED, data: false });
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GetDocumentGraph(type),
      variables: {
        id,
      },
    });
    const [{ versions }] = result.data.admin[`${type}s`];
    const settings = versions.find((version) => version.lang === lang);
    const { tags } = settings;
    const { zones, countries, brand, retailer, associated, universe } =
      tags.reduce(sortTags, {
        zones: [],
        countries: [],
        brand: [],
        retailer: [],
        associated: [],
        universe: [],
      });

    dispatch({
      type: SET_PUBLISH_DOCUMENT,
      data: {
        ...settings,
        id,
        zones,
        countries,
        brand,
        retailer,
        associated,
        universe,
      },
    });
  } catch (error) {
    console.error("err", error);
  }
  dispatch({ type: SET_PUBLISH_LOADED, data: true });
};

export const GetLocation = () => async (dispatch, getState) => {
  try {
    const { id, lessonLocationsSkip } = getState().publish;
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_LOCATIONS_BY_IDS,
      variables: {
        docIds: id,
        limit: 5,
        skip: lessonLocationsSkip,
      },
    });
    const locations = result.data.Location
      ? result.data.Location.map(
          ({
            ocId,
            picCover,
            title,
            addressZone,
            addressCity,
            addressCountry,
            addressBuilding,
          }) => ({
            id: ocId,
            thumbnail: picCover,
            name: title,
            location: `${addressZone ? addressZone.title : ""}${
              addressCountry ? ` - ${addressCountry.title}` : ""
            }${addressCity ? ` - ${addressCity.title}` : ""}`,
            address: addressBuilding,
          }),
        )
      : [];
    dispatch({ type: SET_DOCUMENT_LOCATIONS, data: { locations } });
  } catch (error) {
    console.error(error);
  }
};

export const EditDocument =
  (id, lang, type, fieldType, data) => async (dispatch) => {
    try {
      await client.mutate({
        mutation: EditDocumentGraph(type, fieldType),
        variables: {
          id,
          lang,
          data,
        },
      });
    } catch (error) {
      console.error("err", error);
    }
  };

export const EditMultipleDocument =
  (id, lang, type, data, variables, fields) => async (dispatch) => {
    try {
      await client.mutate({
        mutation: EditMultipleDocumentsGraph(type, variables, fields),
        variables: {
          id,
          lang,
          ...data,
        },
      });
    } catch (error) {
      console.error("err", error);
    }
  };

export const SwitchPrivacyDocument =
  (id, lang, type, isPrivate) => async (dispatch, getState) => {
    const currentValue = getState().publish.isPrivate;
    const command = isPrivate ? "set" : "remove";

    dispatch({ type: UPDATE_PUBLISH_DOCUMENT, data: { isPrivate } });
    try {
      await client.mutate({
        mutation: SwitchPrivacyGraph(type, command),
        variables: {
          id,
          lang,
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PUBLISH_DOCUMENT,
        data: { isPrivate: currentValue },
      });
      dispatch(SetErrorMessage(`An error occurred while updating the ${type}`));
      console.error("err", error);
    }
  };

export const AddTags =
  (id, lang, type, storeType, { value: tagId, label: title }) =>
  async (dispatch, getState) => {
    const currentTags = getState().publish[storeType];
    dispatch({
      type: UPDATE_PUBLISH_DOCUMENT,
      data: { [storeType]: [...currentTags, { tagId, title }] },
    });
    try {
      await client.mutate({
        mutation: ADD_DOCUMENT_TAGS(type),
        variables: {
          id,
          lang,
          tagIds: tagId,
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PUBLISH_DOCUMENT,
        data: { [storeType]: currentTags },
      });
      dispatch(SetErrorMessage(`An error occurred while updating the ${type}`));
      console.error("err", error);
    }
  };

export const DeleteTags =
  (id, lang, type, storeType, tagIds) => async (dispatch, getState) => {
    const currentState = getState().publish;
    dispatch({ type: DELETE_TAG, data: { tagIds } });
    try {
      await client.mutate({
        mutation: DELETE_DOCUMENT_TAGS(type),
        variables: {
          id,
          lang,
          tagIds,
        },
      });
    } catch (error) {
      dispatch({ type: UPDATE_PUBLISH_DOCUMENT, data: currentState });
      dispatch(SetErrorMessage(`An error occurred while updating the ${type}`));
      console.error("err", error);
    }
  };

export const setStartDate =
  (id, lang, type, startDate) => async (dispatch, getState) => {
    const currentState = getState().publish;
    const workflowScheduledIn = formatDateTimeForSave(startDate);
    const workflowScheduledOut = currentState.workflowScheduledOut;
    try {
      await client.mutate({
        mutation: publishDateGraph(type),
        variables: {
          id,
          lang,
          status: currentState.workflow,
          dateIn: workflowScheduledIn,
          dateOut: workflowScheduledOut,
        },
      });
      dispatch({
        type: UPDATE_PUBLISH_DOCUMENT,
        data: { workflowScheduledIn, workflowScheduledOut },
      });
    } catch (error) {
      dispatch({ type: UPDATE_PUBLISH_DOCUMENT, data: currentState });
      dispatch(
        SetErrorMessage("An error occurred because of workflow or start date"),
      );
      console.error("err", error);
    }
  };

export const setEndDate =
  (id, lang, type, endDate) => async (dispatch, getState) => {
    const currentState = getState().publish;
    const workflowScheduledOut = endDate
      ? formatDateTimeForSave(endDate)
      : null;

    try {
      await client.mutate({
        mutation: publishDateGraph(type),
        variables: {
          id,
          lang,
          status: currentState.workflow,
          dateIn: currentState.workflowScheduledIn,
          dateOut: workflowScheduledOut,
        },
      });
      dispatch({
        type: UPDATE_PUBLISH_DOCUMENT,
        data: { workflowScheduledOut },
      });
    } catch (error) {
      dispatch({ type: UPDATE_PUBLISH_DOCUMENT, data: currentState });
      dispatch(SetErrorMessage(`An error occurred while updating the ${type}`));
      console.error("err", error);
    }
  };

export const setWorkflow =
  (id, lang, type, publishSetting) => async (dispatch, getState) => {
    const currentState = getState().publish;
    const isChange = publishSetting === DOC_WORKFLOW_ENUM.SCHEDULED;
    const workflowScheduledIn = isChange
      ? formatDateTimeForSave(getFormattedSopiDate(new Date()))
      : null;
    const workflowScheduledOut = null;
    const workflow =
      publishSetting === DOC_WORKFLOW_ENUM.PUBLISHED
        ? DOC_WORKFLOW_ENUM.PUBLISHED
        : DOC_WORKFLOW_ENUM.DRAFT;

    try {
      await client.mutate({
        mutation: publishDateGraph(type),
        variables: {
          id,
          lang,
          status: workflow,
          dateIn: workflowScheduledIn,
          dateOut: workflowScheduledOut,
        },
      });
      dispatch({
        type: UPDATE_PUBLISH_DOCUMENT,
        data: { workflow, workflowScheduledIn, workflowScheduledOut },
      });
    } catch (error) {
      dispatch({ type: UPDATE_PUBLISH_DOCUMENT, data: currentState });
      dispatch(SetErrorMessage(`An error occurred while updating the ${type}`));
      console.error("err", error);
    }
  };

export const setRecap =
  (id, lang, type, recapType, data) => async (dispatch, getState) => {
    const currentState = getState().publish;

    try {
      await client.mutate({
        mutation: publishRecapGraph(type, recapType),
        variables: {
          id,
          lang,
          data,
        },
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_PUBLISH_DOCUMENT, data: { currentState } });
    }
  };

const deleteLocations = (id, lang, deleteLocationIds) => {
  return client.mutate({
    mutation: updateLocationsGraph,
    variables: {
      lessonId: id,
      lang: lang,
      sens: OBJECT_ACTION_ENUM.REMOVE,
      locationIds: deleteLocationIds,
    },
  });
};

export const DeleteLocations =
  (id, lang, deleteLocationIds) => async (dispatch) => {
    try {
      await deleteLocations(id, lang, deleteLocationIds);
      dispatch(GetLocation());
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while deleting the lesson's locations",
          e,
        ),
      );
    }
  };

const addLocations = (id, lang, addLocationIds) => {
  return client.mutate({
    mutation: updateLocationsGraph,
    variables: {
      lessonId: id,
      lang: lang,
      sens: OBJECT_ACTION_ENUM.SET,
      locationIds: addLocationIds,
    },
  });
};

export const AddLocations = (id, lang, addLocationIds) => async (dispatch) => {
  try {
    await addLocations(id, lang, addLocationIds);
  } catch (e) {
    dispatch(
      SetErrorMessage("An error occurred while adding lesson's locations", e),
    );
  }
};

export const UpdateLocations =
  (addIds, deleteIds) => async (dispatch, getState) => {
    const { id, lang } = getState().publish;
    const promises = [];

    try {
      if (addIds.length) {
        promises.push(addLocations(id, lang, addIds));
      }
      if (deleteIds.length) {
        promises.push(deleteLocations(id, lang, deleteIds));
      }
      await Promise.all(promises);
      dispatch(GetLocation());
    } catch (error) {
      console.error(error);
    }
  };

export const UpdateLessonLocationsSkip = () => (dispatch) => {
  dispatch({ type: UPDATE_LESSON_LOCATIONS_SKIP });
};

export const AddAttachments =
  (id, lang, addFiles, callback) => async (dispatch) => {
    try {
      const promises = addFiles.map(async (file) => {
        return {
          data: await client.mutate({
            mutation: ADD_ATTACHMENT,
            variables: {
              lessonId: id,
              lang,
              link: file.url,
              name: file.name,
              type: file.type,
            },
          }),
          name: file.name,
        };
      });
      const result = await Promise.all(promises);
      const add = result.map(({ name, data }) => ({
        name: name,
        id: data.data.admin.lesson.attachment.add,
      }));
      dispatch({ type: UPDATE_ATTACHMENTS, data: { add } });
      callback && callback();
    } catch (e) {
      dispatch(
        SetErrorMessage("An error occurred while adding attachment(s)", e),
      );
    }
  };

export const RemoveAttachments = (id, lang, uploadId) => async (dispatch) => {
  try {
    await client.mutate({
      mutation: REMOVE_ATTACHMENT,
      variables: {
        lessonId: id,
        lang,
        uploadId,
      },
    });
    dispatch({ type: UPDATE_ATTACHMENTS, data: { remove: uploadId } });
  } catch (e) {
    dispatch(SetErrorMessage("An error occurred while removing attachment", e));
  }
};
