import { shiseidoTranslations as translations } from "./FilterEnvironments.translations";
import { getFormattedSopiDate } from "../../../dateUtils";

const getPeriod = (data, intl) => {
  if (data.selectedInterval && data.selectedInterval.label)
    return data.selectedInterval.label;
  if (data.selectedPeriod && data.selectedPeriod.label) {
    return intl.formatMessage({
      id: data.selectedPeriod.label.props.id,
      defaultMessage: data.selectedPeriod.label.props.defaultMessage,
    });
  }
  if (data.startDate && data.endDate) {
    return `From ${getFormattedSopiDate(
      data.startDate,
    )} to ${getFormattedSopiDate(data.endDate)}`;
  }
  return "Period";
};

export const shiseidoFilterArrayToExport = (data, intl) => {
  let country = "";
  let retailer = "";
  let brand = "";
  let relationship = "";
  let degrees;
  if (data.selectedDegree !== undefined) {
    degrees = [intl.formatMessage(translations.Degree)];
    let degreeValue = "";
    data.selectedDegree.forEach((element) => {
      degreeValue = degreeValue + ", " + element;
    });
    if (degreeValue) degreeValue = degreeValue.substr(2, degreeValue.length);
    degrees.push(degreeValue);
  }

  data.selectedMarkets &&
    data.selectedMarkets.forEach((element) => {
      country = country + ", " + element.label;
    });

  data.selectedRetailers &&
    data.selectedRetailers.forEach((element) => {
      retailer = retailer + ", " + element.label;
    });

  data.selectedBrands &&
    data.selectedBrands.forEach((element) => {
      brand = brand + ", " + element.label;
    });

  data.selectedRelationships &&
    data.selectedRelationships.forEach((element) => {
      relationship = relationship + ", " + element.label;
    });

  country = country.substr(2, country.length);
  retailer = retailer.substr(2, retailer.length);
  brand = brand.substr(2, brand.length);
  relationship = relationship.substr(2, relationship.length);

  // TO DO review label data for custom period selection
  const period = getPeriod(data, intl);

  const returnValue = [
    [intl.formatMessage(translations.Title)],
    [intl.formatMessage(translations.Country), country],
    [intl.formatMessage(translations.Retailer), retailer],
    [intl.formatMessage(translations.Brand), brand],
    [intl.formatMessage(translations.Relationship), relationship],
    [intl.formatMessage(translations.Period), period],
  ];
  if (degrees) returnValue.push(degrees);
  returnValue.push([]);
  returnValue.push([]);
  return returnValue;
};
