import { navigationBarWidth } from "../../components/Navigation/Navigation.style";

const tableTextStyle = (theme) => ({
  fontFamily: theme.FontFamily,
  fontSize: 14,
  fontWeight: 500,
  color: theme.Users.tableTextStyleColor,
});

const searchInputCommonStyle = (theme) => ({
  height: "40px !important",
  border: `1px solid ${theme.Users.searchInputCommonStyleBorderColor} !important`,
  fontFamily: theme.FontFamily,
  fontWeight: 500,
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  fontSize: 16,
});

export default (theme) => ({
  userScreensContainer: {
    paddingLeft: navigationBarWidth + 1,
    position: "relative",
    opacity: ({ isPageLoading }) => (isPageLoading ? 0.5 : 1),
    pointerEvents: ({ isPageLoading }) => (isPageLoading ? "none" : "auto"),
  },
  globalFilterContainer: {
    marginBottom: 60,
    "& > div": {
      padding: "10px 30px",
      height: 40,
      display: "flex",
      backgroundColor: theme.Users.globalFilterBackground,
      "& > div": {
        marginRight: 20,
      },
      "& > .usersSearchContainer": {
        width: 320,
      },
      "& > .tagFilterContainer": {
        position: "relative",
        width: 220,
      },
    },
  },
  tagFilter: {
    ...searchInputCommonStyle(theme),
    "& *": {
      fontFamily: theme.FontFamily,
      fontSize: "16px !important",
    },
  },
  dropdown: {
    ...searchInputCommonStyle(theme),
  },
  searchAUserInput: {
    ...searchInputCommonStyle(theme),
    height: "42px!important",
  },
  totalUsers: {
    ...tableTextStyle(theme),
    marginLeft: "auto",
    paddingTop: 16,
    paddingLeft: 25,
    color: theme.Colors.table.headerTextColor,
    "& > span:last-of-type": {
      color: theme.Colors.table.rowColor,
    },
    position: "relative",
    display: "flex",
    flexDirection: "row",
  },
  exportIcon: {
    cursor: "pointer",
    borderRadius: 5,
    padding: 10,
    border: `1px solid ${theme.Users.exportIconBorder}`,
    "&:hover": {
      border: `1px solid ${theme.Users.exportIconBorderHover}`,
      "& span:before": {
        color: theme.Users.exportIconHoverColor,
      },
    },
  },
  row: {
    height: 60,
    ...tableTextStyle(theme),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.Users.rowHoverBackground,
      color: theme.Users.rowHoverColor,
      borderSpacing: "2px 0 2px 2px",
    },
  },
  firstCol: {
    width: 30,
    "& button": {
      display: "block",
      margin: "auto",
    },
  },
  tagsContainer: {
    maxWidth: 181,
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > span": {
      cursor: "pointer",
    },
  },
  deleteButton: {
    fontSize: 18,
    "&:hover span:before": {
      color: theme.Users.deleteButtonHoverColor,
    },
  },
  loader: {
    width: "100%",
    padding: "30px 0 30px",
    textAlign: "center",
    ...tableTextStyle(theme),
  },
  spinner: {
    position: "absolute",
    top: 400,
    left: "50%",
    right: "50%",
    zIndex: 1,
    opacity: ({ isPageLoading }) => (isPageLoading ? 1 : 0),
  },
  infoIcon: {
    cursor: "pointer",
    marginLeft: 8,
    "&:before": {
      color: theme.Users.infoIconColor,
    },
  },
  userStatusesTooltip: {
    minWidth: 226,
    position: "absolute",
    top: 33,
    right: 13,
    backgroundColor: theme.Users.userStatusesTooltipBackground,
    borderRadius: 5,
    border: `solid 1px ${theme.Users.userStatusesTooltipBorder}`,
    padding: "15px 15px 0 15px",
    "& > div": {
      marginBottom: 15,
      fontSize: 14,
      color: theme.Users.userStatusesTooltipColor,
      "& > span": {
        fontWeight: 500,
      },
    },
  },
  viewIcon: {
    fontSize: 15,
  },
  searchContainer: {
    width: "100%!important",
  },
  usersTable: {
    padding: "0 30px",
  },
  usersTableContainer: {
    "& td:nth-child(1), & th:nth-child(1)": {
      paddingLeft: "20px!important",
    },
    overflow: "auto",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
    },
    "&::-webkit-scrollbar:vertical": {
      width: 11,
    },
    "&::-webkit-scrollbar:horizontal": {
      height: 11,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 8,
      border:
        "2px solid white" /* should match background, can't be transparent */,
      backgroundColor: "rgba(0, 0, 0, .5)",
    },
  },
  loadMoreButton: {
    textAlign: "center",
    padding: 30,
  },
});
