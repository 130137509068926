import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Style from "../MadlibPageEditor.style";
import ActivityIntroReadOnly from "../../../components/common/ActivityIntroComponent/activityIntroReadOnly";
import MadLibReadOnly from "../../../components/common/Madlib/madlibReadOnly";
import uuidv4 from "uuid/dist/v4";
import { decodeNewLines } from "../../../utils/string";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import DocumentPageHeader from "../../common/DocumentPageHeader";

const useStyle = createUseStyles(Style);

const decodeContent = (encodedContent) => {
  const decodedContent = JSON.parse(encodedContent);
  return {
    ...decodedContent,
    data: {
      ...decodedContent.data,
      questions: decodedContent.data.questions.map(({ text, answers }) => ({
        text: decodeNewLines(text.trim()),
        partOne: decodeNewLines(text.split("[#]")[0].trim()),
        partTwo: decodeNewLines(text.split("[#]")[1].trim()),
        answers: answers.map(({ answer, isTrue }) => ({
          answer: answer ? decodeNewLines(answer) : "",
          isTrue,
        })),
      })),
      activityDescription: decodeNewLines(
        decodedContent.data.activityDescription,
      ),
      title: decodeNewLines(decodedContent.data.title),
      description: decodeNewLines(decodedContent.data.description),
    },
  };
};

const MadlibPageEditor = (props) => {
  const { pageId, data: dataProp, orderId } = props;
  const classes = useThemedStyle(useStyle, props);

  const { data } = decodeContent(dataProp);

  const {
    activityDescription,
    title,
    description,
    image: lastImage,
    questions,
  } = data;

  const getFormattedOptions = (options) =>
    options.map((option) => ({
      value: uuidv4(),
      label: option.answer,
      isTrue: option.isTrue,
    }));

  return (
    <div className={classes.pageBox}>
      <DocumentPageHeader pageNumber={orderId} showOrderButtons={false} />
      <ActivityIntroReadOnly
        activityId={pageId}
        theme={activityDescription}
        title={title}
        description={description}
        image={lastImage.url}
      />
      <div className={classes.madlibContainer}>
        {questions.map(({ partOne, partTwo, answers }) => {
          return (
            <MadLibReadOnly
              key={uuidv4()}
              isSelected={false}
              partOne={partOne}
              partTwo={partTwo}
              options={getFormattedOptions(answers)}
            />
          );
        })}
      </div>
    </div>
  );
};

MadlibPageEditor.propTypes = {
  /** page Id  */
  pageId: PropTypes.string,
  /** current page data */
  data: PropTypes.string,
};

export default MadlibPageEditor;
