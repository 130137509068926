export default ({ FontFamily, EditMasterTemplateScreen }) => {
  const contentEditablePlaceholderStyle = {
    "&[contenteditable=true]:empty:before": {
      content: "attr(placeholder)",
      display: "block",
      color: EditMasterTemplateScreen.textInputPlaceHolderColor,
    },
  };

  return {
    rootContainer: {
      paddingTop: "150px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
    center: {
      width: 952,
      display: "flex",
      justifyContent: "space-between",
    },
    left: {
      width: 900,
      display: "flex",
      flexDirection: "column",
    },
    right: {
      right: 0,
      top: 0,
      width: 20,
    },
    closeIcon: {
      "&::before": {
        fontSize: 15,
        cursor: "pointer",
      },
      "&:hover::before": {
        color: EditMasterTemplateScreen.closeIconHoverColor,
        fontSize: 15,
        cursor: "pointer",
      },
    },
    textInputTitle: {
      border: 0,
      outline: 0,
      background: "transparent",
      marginLeft: 50,
      marginRight: 50,
      fontFamily: FontFamily,
      fontSize: "22px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "0.1px",
      color: EditMasterTemplateScreen.textInputColor,
      ...contentEditablePlaceholderStyle,
    },
    textInputSummary: {
      border: 0,
      outline: 0,
      background: "transparent",
      marginLeft: 50,
      marginRight: 50,
      fontFamily: FontFamily,
      fontSize: "17px",
      fontWeight: "300",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "0.1px",
      color: EditMasterTemplateScreen.textInputColor,
      ...contentEditablePlaceholderStyle,
    },
    detailsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingTop: 30,
      paddingLeft: 50,
      width: "100%",
    },
    leftPart: {
      width: "30%",
      display: "flex",
      flexDirection: "column",
    },
    rightPart: {
      width: "60%",
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
    },
    langPublicContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: 10,
    },
    firstTitle: {
      fontFamily: FontFamily,
      fontSize: 16,
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: EditMasterTemplateScreen.firstTitleColor,
    },
    tagList: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    retailerTagsContainer: {
      width: "100%",
    },
    sectionTitle: {
      fontFamily: FontFamily,
      fontSize: 16,
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: EditMasterTemplateScreen.sectionTitleColor,
      paddingTop: 30,
      marginBottom: 10,
    },
    tagSelectContainer: {
      position: "relative",
    },
    tagSelectContainerError: {
      position: "relative",
      "& > div": {
        border: "1px solid #c60000",
        borderRadius: 2,
      },
    },
    brandAndRetailer: {
      display: "flex",
      flexDirection: "row",
    },
    brandTagsContainer: {
      width: "100%",
      marginRight: 20,
    },
    buttons: {
      marginTop: 45,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    photo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 306,
      height: 390,
      backgroundImage: ({ masterTemplateInfo: { picCover } }) =>
        `url('${picCover}')`,
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
    uploadBtn: {
      width: 108,
      height: 36,
      borderRadius: 3,
      cursor: "pointer",
      backgroundColor: EditMasterTemplateScreen.uploadBtn.backgroundColor,
      border: "solid 1px #ffffff",
      letterSpacing: 0.07,
      color: EditMasterTemplateScreen.uploadBtn.color,
      fontSize: 14,
      outline: "none",
    },
    readOnlyTags: {
      "& > div": {
        float: "left",
      },
    },
    switchBtn: {
      marginLeft: 30,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      fontSize: 14,
      "& > div": {
        marginLeft: 10,
      },
    },
  };
};
