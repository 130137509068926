import { defineMessages } from "react-intl";

export const translations = defineMessages({
  LanguageLabel: {
    id: "PublishMasterInsight.LanguageLabel",
    defaultMessage: "Language",
  },
  BrandLabel_REST_OF_CLIENTS: {
    id: "PublishMasterInsight.REST_OF_CLIENTS.BrandLabel",
    defaultMessage: "Brand",
  },
  RetailerLabel_REST_OF_CLIENTS: {
    id: "PublishMasterInsight.REST_OF_CLIENTS.RetailerLabel",
    defaultMessage: "Retailer",
  },
  EntityLabel: {
    id: "PublishMasterInsight.EntityLabel",
    defaultMessage: "Entity",
  },
  RetailerLabel: {
    id: "PublishMasterInsight.RetailerLabel",
    defaultMessage: "Retailer",
  },
  DivisionLabel: {
    id: "PublishMasterInsight.DivisionLabel",
    defaultMessage: "Division",
  },
  PublicationCountriesLabel: {
    id: "PublishMasterInsight.PublicationCountriesLabel",
    defaultMessage: "Countries",
  },
  PublicationZonesLabel: {
    id: "PublishMasterInsight.PublicationZonesLabel",
    defaultMessage: "Zones",
  },
  ProfessionsLabel: {
    id: "PublishMasterInsight.ProfessionsLabel",
    defaultMessage: "Profession",
  },
  AssociatedTagsLabel: {
    id: "PublishMasterInsight.AssociatedTagsLabel",
    defaultMessage: "Associated Tags",
  },
  UserCategoryLabel: {
    id: "PublishMasterInsight.UserCategoryLabel",
    defaultMessage: "User Category",
  },
  PublicationCountriesLabel_REST_OF_CLIENTS: {
    id: "PublishMasterInsight.REST_OF_CLIENTS.PublicationCountriesLabel",
    defaultMessage: "Publication Countries",
  },
  PublicButtonLabel: {
    id: "PublishMasterInsight.PublicButtonLabel",
    defaultMessage: "Public",
  },
  AddBrandPlaceholder_REST_OF_CLIENTS: {
    id: "PublishMasterInsight.REST_OF_CLIENTS.AddBrandPlaceholder",
    defaultMessage: "Add a brand",
  },
  AddRetailerPlaceholder_REST_OF_CLIENTS: {
    id: "PublishMasterInsight.REST_OF_CLIENTS.AddRetailerPlaceholder",
    defaultMessage: "Add a retailer",
  },
  AddEntityPlaceholder: {
    id: "PublishMasterInsight.AddEntityPlaceholder",
    defaultMessage: "Add an entity type",
  },
  AddRetailerPlaceholder: {
    id: "PublishMasterInsight.AddRetailerPlaceholder",
    defaultMessage: "Add a retailer",
  },
  AddDivisionPlaceholder: {
    id: "PublishMasterInsight.AddDivisionPlaceholder",
    defaultMessage: "Add a division",
  },
  SelectAssociatedTagsPlaceholder: {
    id: "PublishMasterInsight.SelectAssociatedTagsPlaceholder",
    defaultMessage: "Select associated tags",
  },
  SelectCountriesPlaceholder_REST_OF_CLIENTS: {
    id: "PublishMasterInsight.REST_OF_CLIENTS.SelectCountriesPlaceholder",
    defaultMessage: "Select countries",
  },
  SelectUserCategoriesPlaceholder: {
    id: "PublishMasterInsight.SelectUserCategoriesPlaceholder",
    defaultMessage: "Select user categories",
  },
  SelectCountriesPlaceholder: {
    id: "PublishMasterInsight.SelectCountriesPlaceholder",
    defaultMessage: "Select countries",
  },
  SelectZonesPlaceholder: {
    id: "PublishMasterInsight.SelectZonesPlaceholder",
    defaultMessage: "Select zones",
  },
  SelectProfessionsPlaceholder: {
    id: "PublishMasterInsight.SelectProfessionsPlaceholder",
    defaultMessage: "Select professions",
  },
  TitlePlaceholder: {
    id: "PublishMasterInsight.TitlePlaceholder",
    defaultMessage: "Enter title",
  },
  SummaryPlaceholder: {
    id: "PublishMasterInsight.SummaryPlaceholder",
    defaultMessage: "Enter summary",
  },
  StartPostingDateLabel: {
    id: "PublishMasterInsight.StartPostingDateLabel",
    defaultMessage: "Start posting date",
  },
  EndPostingDateLabel: {
    id: "PublishMasterInsight.EndPostingDateLabel",
    defaultMessage: "End posting date",
  },
  NoEndPostingDateLabel: {
    id: "PublishMasterInsight.NoEndPostingDateLabel",
    defaultMessage: "No end posting date",
  },
  RoleLabel: {
    id: "PublishMasterInsight.RoleLabel",
    defaultMessage: "Roles",
  },
  AddRolesPlaceholder: {
    id: "PublishMasterInsight.AddRolesPlaceholder",
    defaultMessage: "Select roles",
  },
  IndicateBrandsWarning: {
    id: "PublishMasterInsight.IndicateBrandsWarning",
    defaultMessage:
      "Please indicate which brand or brands this content should be associated with before publishing.",
  },
});
