/* global localStorage */
import auth0 from "auth0-js";
import clientConfig from "../configs/client";
import axios from "axios";
import client from "../graphql/client";
import jwtDecode from "jwt-decode";
import { USER_ROLE_NAMES } from "../configs/constants";
import { store } from "../store/config";
import { GetProfileInfo } from "../store/auth/actions";

export default class AuthService {
  auth0 = new auth0.WebAuth({
    domain: clientConfig.AUTH_CONFIGURATION.domain,
    clientID: clientConfig.AUTH_CONFIGURATION.clientId,
    audience: clientConfig.AUTH_CONFIGURATION.apiAudience,
    redirectUri: clientConfig.AUTH_CONFIGURATION.callbackUrl,
    scope: clientConfig.AUTH_CONFIGURATION.scope,
    responseType: "token id_token",
  });

  constructor() {
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  refreshToken() {
    const refreshToken = window.localStorage.getItem("refresh_token");

    if (!refreshToken) {
      AuthService.clearCache();
      window.location.href = "/login";
    }
    const options = {
      method: "POST",
      url: `https://${clientConfig.AUTH_CONFIGURATION.domain}/oauth/token`,
      data: {
        grant_type: "refresh_token",
        client_id: clientConfig.AUTH_CONFIGURATION.clientId,
        refresh_token: refreshToken,
        scope: "email openid profile offline_access",
      },
    };

    return axios
      .request(options)
      .then((response) => {
        this.setSession({
          accessToken: response.data.access_token,
          idToken: response.data.id_token,
          expiresIn: response.data.expires_in,
          refreshToken: response.data.refresh_token || refreshToken,
        });
        store.dispatch(GetProfileInfo());
        return response.data.access_token;
      })
      .catch(() => {});
  }

  signIn(username, password) {
    return new Promise((resolve, reject) => {
      this.auth0.client.login(
        { realm: clientConfig.AUTH_CONFIGURATION.realm, username, password },
        (err, authResult) => {
          if (err) {
            return reject(err);
          }
          let userDetails = jwtDecode(authResult.idToken);
          if (this.hasValidAccessRole(userDetails)) {
            this.setSession(authResult);
            client.clearStore();
            return resolve();
          } else {
            return reject(
              new Error(
                "Your role does not grant you access to this application.",
              ),
            );
          }
        },
      );
    });
  }

  signOut() {
    // Clear access token and ID token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("refresh_token");
  }

  static clearCache() {
    // Clear access token and ID token from local storage
    localStorage.clear();
    sessionStorage.clear();
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          return resolve();
        } else if (err) {
          return reject(err);
        }
      });
    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime(),
    );

    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
    localStorage.setItem("refresh_token", authResult.refreshToken);
  }

  isAuthenticated() {
    const access_token = localStorage.getItem("access_token");
    return !!access_token;
  }

  getAuthTokenEncodedData() {
    const encodedToken = localStorage.getItem("id_token");
    return jwtDecode(encodedToken);
  }

  hasValidAccessRole(userDetails) {
    return (
      userDetails["https://roles"].findIndex(
        (x) => x.toLowerCase() === "admin",
      ) >= 0 ||
      userDetails["https://roles"].findIndex(
        (x) => x.toLowerCase() === "trainer",
      ) >= 0 ||
      userDetails["https://roles"].findIndex((x) => x === USER_ROLE_NAMES.HQ) >=
        0
    );
  }

  forgotPassword(email, successCallback, errorCallback) {
    const values = {
      client_id: clientConfig.AUTH_CONFIGURATION.clientId,
      email: email,
      connection: clientConfig.AUTH_CONFIGURATION.realm,
    };

    axios
      .post(
        `https://${clientConfig.AUTH_CONFIGURATION.domain}/dbconnections/change_password`,
        values,
      )
      .then(() => {
        return successCallback();
      })
      .catch((error) => {
        return errorCallback(error);
      });
  }
}
