import { navigationBarWidth } from "../../../components/Navigation/Navigation.style";

const contentPadding = 30;
const globalWidth = 1320 + 2 * contentPadding;

export default (theme) => ({
  container: {
    fontFamily: theme.FontFamily,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.UserProfileDefault.containerColor,
    paddingLeft: navigationBarWidth,
  },
  content: {
    maxWidth: globalWidth,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: contentPadding,
    margin: "auto",
  },
  leftSide: {
    marginRight: 30,
    "& > div": {
      width: 420,
      marginBottom: 20,
      backgroundColor: theme.UserProfileDefault.leftSideBackground,
      borderRadius: 10,
      "& > div": {
        width: "unset",
      },
    },
  },
  rightSide: {
    "& > div": {
      minWidth: 870,
      marginBottom: 40,
    },
    overflow: "auto",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
    },
    "&::-webkit-scrollbar:vertical": {
      width: 11,
    },
    "&::-webkit-scrollbar:horizontal": {
      height: 11,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 8,
      border:
        "2px solid white" /* should match background, can't be transparent */,
      backgroundColor: "rgba(0, 0, 0, .5)",
    },
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
  },
  title: {
    textTransform: "uppercase",
    fontSize: 23,
    fontWeight: "bold",
    marginRight: 20,
  },
  dropdown: {
    width: 250,
  },
  downloadButtonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  quantityWidget: {
    backgroundColor: theme.UserProfileDefault.quantityWidgetBackground,
    borderRadius: 5,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    width: 202,
  },
  quantityWidgetContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  detailsCard: {
    "& > div > div": {
      fontFamily: theme.FontFamily,
    },
  },
  learning: {
    borderRadius: 10,
    backgroundColor: theme.UserProfileDefault.learningBackground,
  },
});
