export const AUTH_USER = "AUTH_USER";
export const UNAUTH_USER = "UNAUTH_USER";
export const AUTH_IN_PROGRESS = "AUTH_IN_PROGRESS";
export const SET_PROFILE_INFO = "SET_PROFILE_INFO";
export const AUTH_ERROR = "AUTH_ERROR";
export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const FORGOT_PASSWORD_IN_PROGRESS = "FORGOT_PASSWORD_IN_PROGRESS";
export const CLEARDOWN = "CLEARDOWN";
export const RESET_STORE = "RESET_STORE";
