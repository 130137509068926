import gql from "graphql-tag";

export const CARD_UPDATE_WORKFLOW = gql`
  mutation cardUpdateWorkflow(
    $cardId: ID
    $lang: LangEnum
    $status: DocWorkflowEnum!
    $dateIn: Date
    $dateOut: Date
  ) {
    admin {
      card(cardId: $cardId, lang: $lang) {
        workflow {
          set(status: $status, dateIn: $dateIn, dateOut: $dateOut)
        }
      }
    }
  }
`;
