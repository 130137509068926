import gql from "graphql-tag";
import { LocationsFragment } from "../fragments/locations";

export const GET_SHOPS = gql`
  query getShops(
    $limit: Int
    $skip: Int
    $locationId: [ID!]
    $tagMultipleIntersectIds: [ObjectCoreInputMultipleTags!]
    $search: String
    $typeIds: [Int!]
  ) {
    Location(
      skip: $skip
      limit: $limit
      ocIds: $locationId
      tagMultipleIntersectIds: $tagMultipleIntersectIds
      search: $search
      typeIds: $typeIds
    ) {
      ...LocationFields
    }
  }
  ${LocationsFragment}
`;

export const GET_SHOPS_DETAIL = gql`
  query getShops(
    $limit: Int
    $skip: Int
    $locationId: [ID!]
    $tagMultipleIntersectIds: [ObjectCoreInputMultipleTags!]
    $search: String
    $typeIds: [Int!]
  ) {
    Location(
      skip: $skip
      limit: $limit
      ocIds: $locationId
      tagMultipleIntersectIds: $tagMultipleIntersectIds
      search: $search
      typeIds: $typeIds
    ) {
      addressBuilding
      addressCity {
        title
      }
      addressCountry {
        title
      }
      addressZone {
        title
      }
      locationId
      picCover
      title
      type
    }
  }
`;

export const GET_SHOPS_TITLE = gql`
  query getShops(
    $limit: Int
    $skip: Int
    $locationId: [ID!]
    $tagIds: [ID!]
    $search: String
    $typeIds: [Int!]
  ) {
    Location(
      skip: $skip
      limit: $limit
      ocIds: $locationId
      tagIds: $tagIds
      search: $search
      typeIds: $typeIds
      previewOnly: true
    ) {
      locationId
      title
      addressCity {
        tagId
      }
    }
  }
`;
