import gql from "graphql-tag";

export const GET_LEARNING_KPI = gql`
  query stats(
    $degrees: [Int!]
    $tags: [kpiTagsType!]
    $date: String
    $fromDate: String
    $toDate: String
    $locationIds: [Int!]
    $aggregatedType: AggregatedKPITypeEnum
  ) {
    learningKPI(
      degrees: $degrees
      tags: $tags
      date: $date
      fromDate: $fromDate
      toDate: $toDate
      objectCoreIds: $locationIds
      aggregatedType: $aggregatedType
    ) {
      programs {
        id
        name
        degreeId
        degreeName
        lessons {
          id
          name
          pageNb
          stats {
            validationNb
            startedNb
            unstartedNb
            mostLeftPage {
              usersNb
              page
            }
          }
          allowedUsersNb
        }
        stats {
          allowedNb
          startNb
          startedLessonNb
          totalLessonNb
          validationNb
          validatedLessonNb
        }
      }
    }
  }
`;
