import { POLL_QUESTION_TYPE_ENUM } from "../../configs/constants";

export const EmptyPollPageTemplate = {
  template: "PollTemplate",
  data: {
    questions: [
      {
        type: POLL_QUESTION_TYPE_ENUM.SIMPLE,
        text: "",
        description: "",
        image: null,
        tip: {
          cover: null,
          title: "",
          description: "",
        },
        timer: 20,
        answers: [],
      },
    ],
  },
};
