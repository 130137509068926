import ContentEditorWrapper from "../../components/common/Peanut/Components/ContentEditorWrapper/ContentEditorWrapper";
import React, { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import gql from "graphql-tag";
import { FormattedMessage } from "react-intl";
import { translations } from "./PublishCard.translation";

const GET_CARD_DATA = gql`
  query card($cardId: ID!) {
    admin {
      cards(cardId: $cardId) {
        versions {
          lang
          card {
            pageId
            data
          }
        }
      }
    }
  }
`;

const EDIT_CARD_DATA = gql`
  mutation cardUpdatePageData(
    $cardId: ID
    $lang: LangEnum
    $data: String!
    $pageId: ID
  ) {
    admin {
      card(cardId: $cardId, lang: $lang) {
        pages(pageId: $pageId) {
          data(data: $data)
        }
      }
    }
  }
`;

const EditSummary = ({
  summaryOrigin,
  UpdateCardSummary,
  id,
  lang,
  ...props
}) => {
  const [summary, setSummary] = useState(summaryOrigin || "");
  const { data: serverData } = useQuery(GET_CARD_DATA, {
    variables: { cardId: id },
  });
  const card = useMemo(() => {
    if (serverData) {
      return serverData?.admin?.cards[0].versions.find(
        ({ lang: versionLang }) => versionLang === lang,
      ).card;
    }
    return null;
  }, [serverData]);
  const { cardData, pageId } = useMemo(
    () => ({ pageId: card.pageId, cardData: JSON.parse(card.data) }),
    [card],
  );
  const [editCard] = useMutation(EDIT_CARD_DATA);
  const editCardData = useCallback(
    async (event) => {
      await editCard({
        variables: {
          cardId: id,
          lang,
          data: JSON.stringify({
            ...cardData,
            showDetails: event.target.checked,
          }),
          pageId: pageId,
        },
      });
    },
    [cardData, pageId],
  );

  return (
    <>
      <ContentEditorWrapper
        html={summary}
        onChange={({ target }) => setSummary(target.value)}
        onBlur={() => UpdateCardSummary(summary, id, lang)}
        {...props}
      />
      <div
        style={{
          marginLeft: 50,
          marginRight: 50,
          marginTop: 20,
          display: "flex",
          alignItems: "center",
        }}
      >
        <input
          type="checkbox"
          onChange={editCardData}
          defaultChecked={cardData?.showDetails}
        />
        <div style={{ paddingLeft: 10 }}>
          <FormattedMessage {...translations.DisplayTitle} />
        </div>
      </div>
    </>
  );
};

export default EditSummary;
