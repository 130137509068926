import gql from "graphql-tag";

export const GET_DEGREE_CLONED_CHILDREN = gql`
  query getClonedDocuments($degreeId: Int) {
    admin {
      documents(cloneParentId: $degreeId, type: degree) {
        docId
        versions {
          lang
          tags {
            tagId
          }
          workflow
        }
      }
    }
  }
`;
