import { defineMessages } from "react-intl";

export const translations = defineMessages({
  MaximumMarkersWarning: {
    id: "MarkerLayer.MaximumMarkersWarning",
    defaultMessage:
      "A maximum of {maximumMarkers} markers can be added to a slide",
  },
  InvalidMarkerPositionWarning: {
    id: "MarkerLayer.InvalidMarkerPositionWarning",
    defaultMessage: "Invalid position for marker",
  },
});
