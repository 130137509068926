import gql from "graphql-tag";

export const SEND_DOCUMENT_NOTIFICATION_WITH_RECIPIENT_PROFILES = gql`
  mutation SendDocumentNotificationWithRecipientProfiles(
    $docIds: [Int!]!
    $lang: LangEnum!
    $text: String!
    $recipientProfiles: [NotificationProfileArgsInput!]!
  ) {
    admin {
      notification {
        sendDocumentNotification(
          docIds: $docIds
          lang: $lang
          recipientProfiles: $recipientProfiles
          contents: [{ type: text, value: $text }]
        ) {
          notifId
          nbRecipients
          nbOptInRecipients
          nbOptOutRecipients
        }
      }
    }
  }
`;
