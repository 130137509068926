import * as constants from "../../constants/store/sendNotification";
import client from "../../graphql/client";
import { INSIGHT_GET_TAG_IDS } from "../../graphql/remote/insights/queries";
import { SetErrorMessage } from "../notification/actions";
import {
  SEND_NOTIFICATION,
  SEND_DOCUMENT_NOTIFICATION_WITH_RECIPIENT_PROFILES,
  SEND_CORE_NOTIFICATION_WITH_RECIPIENT_PROFILES,
} from "../../graphql/remote/notifications/mutations";
import { GET_MARKET_AND_BRAND_TAGS } from "../../graphql/remote/notifications/queries";
import {
  DEGREE_PARTS,
  DOCUMENT_TYPES,
  LOCATION_OBJECT_USER_TYPE_ENUM,
} from "../../configs/constants";
import { CARD_GET_TAG_IDS } from "../../graphql/remote/cards/queries";
import { DEGREE_GET_TAG_IDS } from "../../graphql/remote/degrees/queries";
import { LESSON_GET_TAG_IDS } from "../../graphql/remote/lessons/queries";
import { mapTagToGetTagId } from "../../utils/transformers";
import clientConfig, { isShiseido } from "../../configs/client";
import { cleanUpAndEncodeText } from "../../utils/string";

const ALL_FIELDS = [
  "selectedDepartments",
  "selectedServices",
  "selectedRoles",
  "selectedZones",
  "selectedCountries",
  "selectedCities",
  "selectedBrands",
  "selectedMarkets",
];

export const SetShowModal = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_SHOW_MODAL,
    data: data,
  });
};

export const SetNotificationData = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_NOTIFICATION_DATA,
    data: data,
  });
};

export const ResetNotificationStore = () => (dispatch) => {
  dispatch({
    type: constants.RESET_STORE,
  });
};

export const GetTagIds = () => async (dispatch, getState) => {
  const { notificationData } = getState().sendNotification;
  const { docType, docId } = notificationData;

  let query = null;
  let variables = null;
  let responseEndpoint = null;

  switch (docType) {
    case DOCUMENT_TYPES.CARD:
      query = CARD_GET_TAG_IDS;
      variables = { cardIds: [docId] };
      responseEndpoint = "cards";
      break;
    case DOCUMENT_TYPES.INSIGHT:
      query = INSIGHT_GET_TAG_IDS;
      variables = { insightIds: [docId] };
      responseEndpoint = "insights";
      break;
    case DEGREE_PARTS.DEGREE:
      query = DEGREE_GET_TAG_IDS;
      variables = { degreeId: docId };
      responseEndpoint = "degrees";
      break;
    case DEGREE_PARTS.LESSON:
      query = LESSON_GET_TAG_IDS;
      variables = { lessonIds: [docId] };
      responseEndpoint = "lessons";
      break;
    default:
      break;
  }

  try {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: query,
      variables: variables,
      notifyOnNetworkStatusChange: true,
    });

    dispatch(
      SetNotificationData({
        ...notificationData,
        tagIds: result.data[responseEndpoint][0].tags.map(
          (tagIds) => tagIds.tagId,
        ),
      }),
    );
  } catch (e) {
    dispatch(SetErrorMessage("An error occurred while fetching the tag Ids"));
  }
};

export const SendNotificationMessage =
  (onSuccess) => async (dispatch, getState) => {
    // TODO: get the reason of the following operation
    // await dispatch(GetTagIds())
    const {
      notificationData: { docId, lang, message, send, tagIds, userIds },
    } = getState().sendNotification;

    try {
      await client.mutate({
        mutation: SEND_NOTIFICATION,
        variables: {
          docId: docId,
          lang: lang,
          message: cleanUpAndEncodeText(message),
          send: send,
          tagIds: tagIds,
          userIds: userIds,
        },
      });

      dispatch(ResetNotificationStore());
      onSuccess();
    } catch (e) {
      dispatch(
        SetErrorMessage("An error occurred while sending the notification", e),
      );
    }
  };

const getTagIds = (profile) => {
  const filteredFields = ALL_FIELDS.filter(
    (field) => !profile.fullChecked.includes(field),
  );
  const tagIds = filteredFields
    .map((field) => profile[field].map(mapTagToGetTagId))
    .filter((ids) => ids.length);

  return tagIds.length ? tagIds : undefined;
};

const filterSelectedValues = (selectedValues, sourceTags, toFilter) => {
  const selectedParentIds = [
    ...new Set(
      selectedValues.map((selectedValue) =>
        Number(
          sourceTags.find((tag) => selectedValue.title === tag.label)
            .clusterMetaTagId,
        ),
      ),
    ),
  ];
  return toFilter.filter(
    (tag) => !selectedParentIds.includes(mapTagToGetTagId(tag)),
  );
};

const filterLocation = (getState, profile) => {
  const { selectedZones, selectedCountries, selectedCities } = profile;
  const countries = getState().tags.countries;
  const cities = getState().tags.cities;
  const shops = getState().tags.shops;
  const locationTags = [
    /** Filter zones with countries selected */
    ...filterSelectedValues(selectedCountries, countries, selectedZones),
    /** Filter countries with cities selected */
    ...filterSelectedValues(selectedCities, cities, selectedCountries),
  ];

  // filter cities
  let citiesToAdd = [...profile.selectedCities];
  profile.selectedLocations.forEach((shop) => {
    // remove parent id
    const parentId = shops.find(
      (elem) => elem.title === shop.label,
    ).addressCityTagId;
    const parentTitle = cities.find((elem) => elem.tagId === parentId).title;
    citiesToAdd = citiesToAdd.filter((city) => city.label !== parentTitle);
  });

  locationTags.push(...citiesToAdd);

  return locationTags.map(mapTagToGetTagId);
};

const filterSpecific = (getState, profile) => {
  const { selectedDepartments, selectedServices, selectedRoles } = profile;
  const services = getState().tags.services;
  const roles = getState().tags.roles;
  const specificTags = [
    /** Filter zones with services selected */
    ...filterSelectedValues(selectedServices, services, selectedDepartments),
    /** Filter services with roles selected */
    ...filterSelectedValues(selectedRoles, roles, selectedServices),
  ];

  specificTags.push(...profile.selectedRoles);

  return specificTags.map(mapTagToGetTagId);
};

export const SendNotificationMessageWithRecipientProfiles =
  (onSuccess) => async (dispatch, getState) => {
    const {
      notificationData: { docId, ocId, lang, message, profiles },
    } = getState().sendNotification;
    const recipientProfiles = profiles.map((profile) => {
      const locationIds = profile.selectedLocations.map(mapTagToGetTagId);

      const locationTags = filterLocation(getState, profile);
      const specificTags = filterSpecific(getState, profile);

      return {
        ocIds: locationIds.length ? [locationIds] : undefined,
        relationType: locationIds.length
          ? LOCATION_OBJECT_USER_TYPE_ENUM.WORKING_AT
          : undefined,
        tagIds: isShiseido
          ? getTagIds(profile)
          : specificTags.length
          ? [specificTags]
          : undefined,
        ocDescriptionTagIds: locationTags.length ? locationTags : undefined,
      };
    });

    if (isShiseido && !recipientProfiles[0].tagIds) {
      dispatch(
        SetErrorMessage(
          "An error occurred while fetching the document's market and brand",
        ),
      );
      return;
    }
    try {
      const result = await client.mutate({
        mutation: ocId
          ? SEND_CORE_NOTIFICATION_WITH_RECIPIENT_PROFILES
          : SEND_DOCUMENT_NOTIFICATION_WITH_RECIPIENT_PROFILES,
        variables: {
          docIds: docId ? [Number(docId)] : undefined,
          ocIds: ocId ? [Number(ocId)] : undefined,
          lang,
          text: cleanUpAndEncodeText(message),
          recipientProfiles,
        },
      });

      dispatch(ResetNotificationStore());

      const { sendCoreObjectNotification, sendDocumentNotification } =
        result?.data?.admin?.notification;

      if (sendCoreObjectNotification) {
        onSuccess(
          sendCoreObjectNotification.nbOptInRecipients,
          sendCoreObjectNotification.nbOptOutRecipients,
        );
      } else {
        onSuccess(
          sendDocumentNotification.nbOptInRecipients,
          sendDocumentNotification.nbOptOutRecipients,
        );
      }
    } catch (e) {
      dispatch(
        SetErrorMessage("An error occurred while sending the notification", e),
      );
    }
  };

export const AddNewProfile = () => (dispatch) => {
  dispatch({
    type: constants.ADD_NEW_PROFILE,
  });
};

export const RemoveProfile = (id) => (dispatch) => {
  dispatch({
    type: constants.REMOVE_PROFILE,
    data: id,
  });
};

export const ResetProfile = (id) => (dispatch) => {
  dispatch({
    type: constants.RESET_PROFILE,
    data: id,
  });
};

export const ResetProfiles = () => (dispatch) => {
  dispatch({
    type: constants.RESET_PROFILES,
  });
};

export const RemoveTag = (uuid, key, value) => (dispatch) => {
  dispatch({
    type: constants.REMOVE_TAG,
    data: { uuid, key, value },
  });
};

export const UpdateProfileSelectedTags =
  (uuid, type, selections, fullChecked) => (dispatch) => {
    dispatch({
      type: constants.UPDATE_PROFILE_SELECTED_TAGS,
      data: { uuid, type, selections, fullChecked },
    });
  };

export const SetMarketAndBrandTags = (docId) => async (dispatch) => {
  const { BRAND_TAGS_CLUSTER_IDS, MARKET_CLUSTER_IDS } = clientConfig;

  try {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_MARKET_AND_BRAND_TAGS,
      variables: {
        docIds: [docId],
        clusterIds: [...BRAND_TAGS_CLUSTER_IDS, ...MARKET_CLUSTER_IDS],
        types: ["lesson", "insight", "card", "poll", "battle", "file"],
      },
    });
    const tagIds = result.data.admin.documents[0].versions[0].tags.reduce(
      (acc, { tagId, clusterId }) => {
        if (acc[clusterId]) {
          acc[clusterId].push({ value: `${tagId}-${clusterId}` });
        } else {
          acc[clusterId] = [{ value: `${tagId}-${clusterId}` }];
        }
        return acc;
      },
      {},
    );

    dispatch({
      type: constants.SET_MARKET_BRAND_TAGS,
      data: {
        brands: Object.keys(tagIds)
          .filter((clusterId) =>
            BRAND_TAGS_CLUSTER_IDS.includes(Number(clusterId)),
          )
          .reduce((acc, clusterId) => [...acc, ...tagIds[clusterId]], []),
        markets: Object.keys(tagIds)
          .filter((clusterId) => MARKET_CLUSTER_IDS.includes(Number(clusterId)))
          .reduce((acc, clusterId) => [...acc, ...tagIds[clusterId]], []),
      },
    });
  } catch (e) {
    dispatch(
      SetErrorMessage("An error occurred while sending the notification", e),
    );
  }
};
