import React from "react";
import PropTypes from "prop-types";
// import styles from './BadgeSlider.style'
import BadgeSlide from "./badgeSlide";
import uuidV4 from "uuid/dist/v4";
// import { createUseStyles } from 'react-jss'
// import useThemedStyle from '../../../../hooks/style/useThemedStyle'

// const useStyle = createUseStyles(styles)

const BadgeSlider = (props) => {
  const { degrees } = props;

  return (
    <div>
      {degrees.map(({ name, totalPoints, completedPoints, badgeList }) => (
        <BadgeSlide
          key={uuidV4()}
          name={name}
          totalPoints={totalPoints}
          completedPoints={completedPoints}
          badgeList={badgeList}
        />
      ))}
    </div>
  );
};

BadgeSlider.propTypes = {
  /** Degree list */
  degrees: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BadgeSlider;
