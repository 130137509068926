import { navigationBarWidth } from "../../../components/Navigation/Navigation.style";
import { resultsHeaderHeight } from "../resultsHeader/ResultsHeader.style";

export const resultsContentWidth = 1260;
export const resultsContentPadding = 30;

export const defaultStyles = (theme) => ({
  root: {
    paddingLeft: navigationBarWidth,
    fontFamily: theme.FontFamily,
  },
  content: {
    maxWidth: resultsContentWidth,
    padding: `${
      resultsHeaderHeight - 20
    }px ${resultsContentPadding}px 40px ${resultsContentPadding}px`,
    margin: "auto",
    "& .keyDetailWidget": {
      width: 406,
      float: "left",
      margin: "40px 20px 40px 0",
    },
    "& > .widgets": {
      float: "left",
      width: "100%",
    },
    "& > .leaderboardTitle": {
      fontSize: 23,
      fontWeight: "bold",
      color: theme.BattleResultsScreen.leaderBoardTitleColor,
      marginBottom: 10,
      textTransform: "uppercase",
    },
  },
  shiseidoContainerPlaceHolder: {
    display: "flex",
    justifyContent: "end",
    height: 60,
  },
  shiseidoPlaceHolder: {
    width: 445,
    paddingBottom: 10,
    color: "#a0a0a0",
  },
});
