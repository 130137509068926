import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import SettingsMenu from "components/common/SettingsMenu";
import { FormattedMessage } from "react-intl";
import injectSheet, { createUseStyles } from "react-jss";
import listStyle, { itemViewStyle } from "./List.style";
import { Button, ButtonSize, ButtonVariation, Icons, Icon } from "genius-ui";
import Image from "components/common/Image";
import { DOCUMENT_TYPES, STATUS_ENUM } from "configs/constants";
import { LANGUAGES } from "configs/referential";
import { translations } from "./List.translations";
import { formatDate } from "utils/dateUtils";
import { DocumentTypeTranslations } from "constants/domain_items/documents/document_types";
import { useTags } from "store/tag/hooks";
import useThemedStyle from "hooks/style/useThemedStyle";

const useStyle = createUseStyles(itemViewStyle);

const getType = ({ type }) => {
  return type;
};

const ItemView = ({
  props,
  classes,
  editDocument,
  getEditLabel,
  showDuplicateModal,
  showNotificationModal,
  item,
  allowEdit,
  allowDuplication,
  allowNotification,
  allowDelete,
  setDeleteDocumentData,
  showDeleteModal,
  navigateToInsightPublishPage,
  navigateToCardPublishPage,
  navigateToBattlePublishPage,
  navigateToPollPublishPage,
}) => {
  const [hasAnnouncementTag, setHasAnnouncementTag] = useState(
    item.hasAnnouncementTag,
  );
  const itemViewClasses = useThemedStyle(useStyle, { hasAnnouncementTag });
  const { handleTag } = useTags();
  const handleIconStatusClick = useCallback(() => {
    setHasAnnouncementTag(!hasAnnouncementTag);
    handleTag(!hasAnnouncementTag, item.type, item.id, item.language, [6], () =>
      setHasAnnouncementTag(hasAnnouncementTag),
    );
  }, [hasAnnouncementTag]);
  const statusIcon = useMemo(() => {
    return undefined;
  });

  return (
    <tr>
      <td className={classes.title} onClick={editDocument(props, item)}>
        <div>
          <div>
            <Image
              width={40}
              height={40}
              url={item.picCover}
              className={classes.image}
            />
          </div>
          <div>{item.title}</div>
        </div>
      </td>
      <td className={classes.type}>
        <div className={itemViewClasses.announcement}>
          <Icon iconName={statusIcon} style={itemViewClasses.icon} />
        </div>
      </td>
      <td className={classes.type}>{item.authorName || "-"}</td>
      <td className={classes.type}>
        <FormattedMessage {...DocumentTypeTranslations[getType(item)]} />
      </td>
      <td className={classes.date}>
        {formatDate(new Date(item.updateDate), "dd/MM/yy")}
      </td>
      <td className={classes.status}>{item.workflow}</td>
      <td className={classes.statistic}>{item.read}</td>
      <td className={classes.statistic}>{item.vote}</td>
      <td className={classes.statistic}>{item.comment}</td>
      <td>
        <div className={classes.settingsContainer}>
          <Icon
            iconName={Icons.roundMore}
            style={classes.settingsButton}
            handleClick={() => props.onItemSettingsClicked(item.id)}
          />
          <SettingsMenu
            handleFocusLeave={() => props.onItemSettingsClicked(undefined)}
            isVisible={
              props.activeMenuDocumentId &&
              props.activeMenuDocumentId === item.id
            }
            className={classes.docSettingsMenu}
            menuItems={[
              {
                label: <FormattedMessage {...translations.PublishOption} />,
                icon: Icons.settings,
                onItemClick: () => {
                  props.onItemSettingsClicked(undefined);
                  switch (item.type) {
                    case DOCUMENT_TYPES.INSIGHT:
                      navigateToInsightPublishPage(item.id);
                      break;
                    case DOCUMENT_TYPES.CARD:
                      navigateToCardPublishPage(item.id);
                      break;
                    case DOCUMENT_TYPES.BATTLE:
                      navigateToBattlePublishPage(item.id);
                      break;
                    case DOCUMENT_TYPES.POLL:
                      navigateToPollPublishPage(item.id);
                      break;
                    default:
                  }
                },
              },
              {
                label: getEditLabel(item.type),
                icon: Icons.settings,
                onItemClick: editDocument(props, item),
                disabled: !allowEdit,
              },
              {
                label: <FormattedMessage {...translations.TranslateOption} />,
                icon: Icons.translate,
                onItemClick: showDuplicateModal(props, item),
                disabled: !(LANGUAGES.length > 1) || !allowDuplication,
              },
              {
                label: (
                  <FormattedMessage {...translations.SendNotificationOption} />
                ),
                icon: Icons.notification,
                onItemClick: showNotificationModal(props, item),
                hidden:
                  item.type === DOCUMENT_TYPES.BATTLE ||
                  item.workflow === STATUS_ENUM.DRAFT ||
                  item.type === DOCUMENT_TYPES.POLL,
                disabled: !allowNotification,
              },
              {
                label: <FormattedMessage {...translations.DeleteOption} />,
                icon: Icons.delete,
                onItemClick: () => {
                  props.onItemSettingsClicked(undefined);
                  setDeleteDocumentData(item);
                  showDeleteModal();
                },
                disabled: !allowDelete,
              },
            ]}
          />
        </div>
      </td>
    </tr>
  );
};

const ListView = (allProps) => {
  const { items, classes, showLoadMore, loading, loadMore, ...restProps } =
    allProps;

  return (
    <div className={classes.container}>
      <table cellSpacing="0">
        <thead>
          <tr>
            <th>
              <FormattedMessage {...translations.NameHeaderTitle} />
            </th>
            <th>
              <FormattedMessage {...translations.AnnouncementHeaderTitle} />
            </th>
            <th>
              <FormattedMessage {...translations.AuthorHeaderTitle} />
            </th>
            <th>
              <FormattedMessage {...translations.TypeHeaderTitle} />
            </th>
            <th>
              <FormattedMessage {...translations.DateHeaderTitle} />
            </th>
            <th>
              <FormattedMessage {...translations.StatusHeaderTitle} />
            </th>
            <th>
              <Icon iconName={Icons.read} />
            </th>
            <th>
              <Icon iconName={Icons.vote} />
            </th>
            <th>
              <Icon iconName={Icons.comment} />
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {items.map((item, key) => (
            <ItemView item={item} classes={classes} {...restProps} key={key} />
          ))}
        </tbody>
      </table>
      {showLoadMore && (
        <div className={classes.loadMoreContainer}>
          <Button
            disabled={loading}
            variation={ButtonVariation.primary}
            size={ButtonSize.small}
            handleClick={loadMore}
          >
            <FormattedMessage {...translations.SeeMoreButtonDefaultTitle} />
          </Button>
        </div>
      )}
    </div>
  );
};

ListView.propTypes = {
  allowEdit: PropTypes.bool,
  allowDuplication: PropTypes.bool,
  allowNotification: PropTypes.bool,
  allowDelete: PropTypes.bool,
};

ListView.defaultProps = {
  allowEdit: true,
  allowDuplication: true,
  allowNotification: true,
  allowDelete: true,
};

export default injectSheet(listStyle)(ListView);
