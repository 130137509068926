import gql from "graphql-tag";

export const PROGRAM_CLONE = gql`
  mutation ProgramClone($programId: ID, $lang: LangEnum!) {
    admin {
      program(programId: $programId, lang: $lang) {
        cloneProgram(
          lang: $lang
          cloneProgramItself: true
          cloneTags: false
          cloneTagsFeature: true
        )
      }
    }
  }
`;
