import gql from "graphql-tag";

export const INSIGHT_UPDATE_SUMMARY = gql`
  mutation insightUpdateSummary(
    $insightId: ID
    $lang: LangEnum
    $data: String!
  ) {
    admin {
      insight(insightId: $insightId, lang: $lang) {
        summary(data: $data)
      }
    }
  }
`;
