import gql from "graphql-tag";

export const GET_LESSON_CLONED_CHILDREN = gql`
  query getClonedDocuments($lessonId: Int) {
    admin {
      documents(cloneParentId: $lessonId, type: lesson) {
        docId
        versions {
          lang
          tags {
            tagId
          }
          workflow
        }
      }
    }
  }
`;
