export default (theme) => ({
  postingDateSwitchers: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  sectionTitle: {
    fontFamily: theme.FontFamily,
    fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.PublishDateHandler.titleColor,
    paddingTop: 30,
    marginBottom: 10,
  },
  postingDateBtnContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  postingDateBtnIcon: {
    marginRight: 5,
    "&:before": {
      color: theme.PublishDateHandler.activeIconColor,
      fontSize: 18,
    },
  },
  postingDateDisabledBtnIcon: {
    marginRight: 5,
    "&:before": {
      color: theme.PublishDateHandler.disabledIconColor,
      fontSize: 18,
    },
  },
  noEndPostingDate: {
    marginBottom: 5,
  },
});
