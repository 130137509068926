export default ({ CreateProgram, FontFamily }) => ({
  center: {
    width: "100%",
    justifyContent: "space-between",
  },
  left: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textInputAddTitle: {
    border: 0,
    outline: 0,
    background: "transparent",
    fontFamily: FontFamily,
    fontSize: "32px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.11px",
    color: CreateProgram.inputColor,
    marginBottom: 40,
  },
  textInputAddSummary: {
    border: 0,
    outline: 0,
    background: "transparent",
    fontFamily: FontFamily,
    fontSize: "20px !important",
    fontWeight: "300 !important",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.07px",
    color: CreateProgram.inputColor,
    marginBottom: 40,
  },
  buttonContainer: {
    display: "flex",
  },
  buttonStyle: {
    marginLeft: "5px",
  },
});
