import { RESET_STORE } from "../../constants/store/auth";
import {
  SET_BATTLE_LOADING,
  SET_BATTLE_MASTER_FLAG,
  SET_BATTLE_QUESTIONS,
  SET_EDITED_BATTLE_PAGEID,
} from "../../constants/store/editBattle";

const initialState = {
  editedPageId: null,
  questions: [],
  isLoading: false,
  isMasterDocument: false,
};

export default (state = initialState, action = {}) => {
  const { data, type } = action;

  switch (type) {
    case SET_EDITED_BATTLE_PAGEID: {
      return {
        ...state,
        editedPageId: data,
      };
    }
    case SET_BATTLE_QUESTIONS: {
      return {
        ...state,
        questions: data,
      };
    }
    case SET_BATTLE_LOADING: {
      return {
        ...state,
        isLoading: data,
      };
    }
    case SET_BATTLE_MASTER_FLAG: {
      return {
        ...state,
        isMasterDocument: data,
      };
    }
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
