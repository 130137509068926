import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";
import Style from "./AddCoverModal.style";
import useThemedStyle from "../../../../../hooks/style/useThemedStyle";
import { translations } from "./AddCoverModal.translations";
import { FormattedMessage } from "react-intl";
import { Button, ButtonSize, ButtonVariation, StylelessModal } from "genius-ui";

const useStyle = createUseStyles(Style);

const AddCoverModal = (props) => {
  const classes = useThemedStyle(useStyle, props);
  const { visible, setVisible, onCancel, onContinue } = props;

  // Modal actions
  const hideModal = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleCancel = useCallback(() => {
    onCancel();
    hideModal();
  }, [onCancel, hideModal]);

  const handleKeepPicCover = useCallback(() => {
    onContinue(false);
    hideModal();
  }, [onContinue, hideModal]);

  const handleReplacePicCover = useCallback(() => {
    onContinue(true);
    hideModal();
  }, [onContinue, onContinue]);

  return (
    <StylelessModal show={visible} onClose={hideModal} centered>
      <div className={classes.modalContent}>
        <div className={classes.body}>
          <div className={classes.messageLabel}>
            <FormattedMessage {...translations.ModalPdfToImgMessage} />
          </div>
          <div className={classes.actions}>
            <Button
              handleClick={handleCancel}
              variation={ButtonVariation.secondary}
              size={ButtonSize.small}
              className={classes.cancelBtn}
            >
              <FormattedMessage {...translations.CancelBtnLabel} />
            </Button>
            <Button
              handleClick={handleKeepPicCover}
              variation={ButtonVariation.primary}
              size={ButtonSize.small}
              className={classes.cancelBtn}
            >
              <FormattedMessage {...translations.KeepBtnLabel} />
            </Button>
            <Button
              handleClick={handleReplacePicCover}
              variation={ButtonVariation.primary}
              size={ButtonSize.small}
            >
              <FormattedMessage {...translations.ReplaceBtnLabel} />
            </Button>
          </div>
        </div>
      </div>
    </StylelessModal>
  );
};

export default AddCoverModal;
