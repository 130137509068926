import { defineMessages } from "react-intl";

export const translations = defineMessages({
  UploadImageVideoLabel: {
    id: "ImageVideoUploader.UploadImageVideoLabel",
    defaultMessage: "Upload Image/Video",
  },
  AddThumbnailText: {
    id: "ImageVideoUploader.AddThumbnailText",
    defaultMessage: "Add a thumbnail",
  },
});
