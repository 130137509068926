import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { VideoPageEditorStyle } from "./VideoPageEditor.style";
import { ImageTypeBoxed, SimpleQuizCoverThumbnail } from "assets/icons";
import {
  VIDEO_CONTROL_CROP_VARIATIONS,
  VIDEO_CONTROL_POSITION,
} from "../../configs/constants";
import { MultimediaTemplateNames } from "../SideComponentBar/templates/multimedia";
import ChangeTemplate from "../common/ChangeTemplate";
import Video from "../common/Video";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(VideoPageEditorStyle);

const availableTemplates = [
  {
    id: VIDEO_CONTROL_POSITION.FULLWIDTH,
    name: MultimediaTemplateNames.VideoFullWidthText,
    image: SimpleQuizCoverThumbnail,
    defaultProps: {},
  },
  {
    id: VIDEO_CONTROL_POSITION.BOXED,
    name: MultimediaTemplateNames.VideoInContainerText,
    image: ImageTypeBoxed,
    defaultProps: {},
  },
];

const VideoPageEditor = (props) => {
  const { type, onVideoUpload, ...restProps } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.videoContainer}>
      {restProps.isSelected && (
        <ChangeTemplate
          selectedTemplateId={type}
          templates={availableTemplates}
          onTemplateSelect={(videoType) =>
            onVideoUpload(
              restProps.sourceVideo,
              restProps.sourcePosterImage,
              restProps.id,
              videoType,
            )
          }
          className={classes.templateSelector}
        />
      )}
      <Video
        {...restProps}
        onVideoChange={(videoSource, imageFile, id) =>
          onVideoUpload(videoSource, imageFile, id, type)
        }
        {...VIDEO_CONTROL_CROP_VARIATIONS[type]}
      />
    </div>
  );
};

VideoPageEditor.propTypes = {
  /** On video upload completed */
  onVideoUpload: PropTypes.func,
  /** image variation **/
  type: PropTypes.oneOf([
    VIDEO_CONTROL_POSITION.FULLWIDTH,
    VIDEO_CONTROL_POSITION.BOXED,
  ]).isRequired,
  /** + All the props of VideoSelector except:
   * - onVideoChange
   * - width
   * - height
   * **/
};

export default VideoPageEditor;
