export const MEDIA_LIST_NAMESPACE = "MEDIATHEQUE";

export const FILTERS = {
  associatedTags: {
    name: "associatedTags",
  },
  year: {
    name: "year",
    availableValues: [
      {
        value: "2020",
        label: "2020",
      },
      {
        value: "2019",
        label: "2019",
      },
      {
        value: "2018",
        label: "2018",
      },
      {
        value: "2017",
        label: "2017",
      },
      {
        value: "2016",
        label: "2016",
      },
      {
        value: "2015",
        label: "2015",
      },
      {
        value: "2014",
        label: "2014",
      },
    ],
  },
  month: {
    name: "month",
  },
  zone: {
    name: "zone",
  },
  country: {
    name: "country",
  },
  city: {
    name: "city",
  },
  boutique: {
    name: "boutique",
  },
};
