export const MasterContentManagerStyle = (theme) => ({
  rootContainer: {
    fontFamily: theme.FontFamily,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 80,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    paddingRight: 15,
  },
  title: {
    textTransform: "uppercase",
    fontSize: 22,
    fontWeight: "bold",
    letterSpacing: 0.09,
    color: "#252525",
  },
  grid: {
    "& > div": {
      float: "left",
      marginRight: 15,
      marginBottom: 10,
      position: "relative",
    },
  },
  filters: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > div:nth-of-type(1)": {
      width: 225,
      marginRight: 15,
    },
    "& > div:nth-of-type(2)": {
      width: 225,
    },
  },
  createButtonContainer: {
    bottom: 35,
    right: 35,
    zIndex: 1,
  },
  content: {
    minWidth: 960,
    maxWidth: 960,
  },
  docSettingsMenu: {
    width: 200,
    left: 455,
    position: "relative",
    top: -15,
    zIndex: 2,
  },
  loadMoreContainer: {
    margin: "40px 0",
    float: "left",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  cardMaster: {
    "& > div > div:nth-of-type(3)": {
      display: "none",
    },
  },
});
