import gql from "graphql-tag";

export const GET_DEGREE_TAG_IDS = gql`
  query getDegreeTagIds($degreeId: ID, $tagIds: [ID!]) {
    admin {
      degrees(degreeId: $degreeId, tagIds: $tagIds) {
        versions {
          degreeId
          lang
          tags {
            tagId
            title
            clusterId
          }
        }
      }
    }
  }
`;
