import gql from "graphql-tag";

export const POLL_CLONE_TO_MARKET = gql`
  mutation cloneToMarket(
    $pollId: ID
    $lang: LangEnum!
    $marketTagId: Int
    $marketTagIds: [Int!]
  ) {
    admin {
      poll(pollId: $pollId) {
        clone(
          lang: $lang
          marketTagId: $marketTagId
          marketTagIds: $marketTagIds
          cloneTags: false
          cloneTagsFeature: true
        ) {
          pollId
        }
      }
    }
  }
`;
