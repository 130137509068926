import React, { useMemo, useState } from "react";
import { useQuery } from "react-apollo";
import Badge from "../Badge/Badge";
import { GET_BADGES } from "../../graphs";

const MasterBadges = ({ brandId }) => {
  const { data, loading } = useQuery(GET_BADGES, {
    variables: { tagIds: [1114] },
  });
  const [activeMenuBadgeId, setActiveMenuBadgeId] = useState(undefined);
  const badges = useMemo(() => {
    if (!data) return [];
    if (!brandId) return data.admin.badges;
    return data.admin.badges.filter(({ tags }) =>
      tags.some(({ tagId }) => brandId === tagId),
    );
  }, [brandId, data]);
  if (loading) return null;
  return (
    <div
      style={{
        width: 940,
        display: "grid",
        gridGap: 10,
        gridTemplateColumns: "465px 465px",
      }}
    >
      {badges.map((badge, index) => (
        <Badge
          key={index}
          {...badge}
          isMaster
          activeMenuBadgeId={activeMenuBadgeId}
          setActiveMenuBadgeId={setActiveMenuBadgeId}
        />
      ))}
    </div>
  );
};

export default MasterBadges;
