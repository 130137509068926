import gql from "graphql-tag";

export const LESSON_CREATE_PAGE = gql`
  mutation lessonCreatePage(
    $lessonId: ID
    $lang: LangEnum
    $type: PageTypeEnum!
    $data: String!
  ) {
    admin {
      lesson(lessonId: $lessonId, lang: $lang) {
        pages {
          create(type: $type, data: $data) {
            pageId
            type
            template
            data
          }
        }
      }
    }
  }
`;
