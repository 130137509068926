import React, { useEffect, useState } from "react";
import { MasterContentManagerStyle } from "./MasterContentManager.style";
import withThemedStyle from "../../components/hoc/withThemedStyle";
import Navigation from "../../components/Navigation";
import { translations } from "./MasterContentManager.translations";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  Button,
  ButtonSize,
  ButtonVariation,
  Card,
  Dropdown,
  FullscreenLoader,
  Icon,
  Icons,
} from "genius-ui";
import FilterLanguages from "../../components/FilterLanguages";
import { LANGUAGES } from "../../configs/referential";
import ViewSwitcher from "../../components/common/ViewSwitcher";
import {
  CARD_TYPES,
  DOC_WORKFLOW_ENUM,
  DOCUMENT_LEVEL,
  DOCUMENT_TYPES,
  DOCUMENT_VIEW_TYPES,
  USER_ROLE_NAMES,
} from "../../configs/constants";
import ListView from "./listView";
import {
  SetActiveMenuDocumentId,
  SetCurrentDocumentLevel,
  SetCurrentLanguage,
  SetCurrentMarket,
  SetViewType,
} from "../../store/document/actions";
import { connect } from "react-redux";
import FixedContainer from "../../components/common/FixedContainer";
import Spinner from "../../components/common/Spinner";
import RoundButton from "../../components/common/RoundButton";
import {
  getMasterDocuments,
  UpdatePage,
  SetFilters,
  ResetMasterContent,
  DeleteDocument,
  TranslateDocument,
  UpdateDocumentWorkflow,
} from "../../store/masterDocument/actions";
import { DocumentTypeTranslations } from "../../constants/domain_items/documents/document_types";
import clientConfig from "../../configs/client";
import SettingsMenu from "../../components/common/SettingsMenu";
import { getPath } from "../../core/paths";
import DeleteModal from "../../components/common/DeleteModal";
import DuplicationModal from "../../components/common/DuplicationModal";
import { SetSuccessMessage } from "../../store/notification/actions";
import {
  SetNotificationData,
  SetShowModal as SetShowNotificationModal,
} from "../../store/sendNotification/actions";
import SendNotificationModal from "../../components/common/SendNotificationModal";

const editDocument = (history, item, onSettingsHandler) => () => {
  onSettingsHandler && onSettingsHandler(undefined);
  switch (item.type) {
    case DOCUMENT_TYPES.INSIGHT:
      history.push(getPath("createInsight", item.id, item.language));
      break;
    case DOCUMENT_TYPES.CARD:
      switch (item.pageType) {
        case CARD_TYPES.QUICK_QUIZ:
          history.push(getPath("quickQuizEdit", item.id, item.language));
          break;
        case CARD_TYPES.PEANUT:
          history.push(getPath("createCardPeanut", item.id, item.language));
          break;
        default:
          break;
      }
      break;
    case DOCUMENT_TYPES.BATTLE:
      history.push(getPath("editBattle", item.id, item.language));
      break;
    case DOCUMENT_TYPES.POLL:
      history.push(getPath("editPoll", item.id, item.language));
      break;
    default:
      break;
  }
};

const getEditLabel = (type) => {
  switch (type) {
    case DOCUMENT_TYPES.INSIGHT:
      return <FormattedMessage {...translations.EditInsightOption} />;
    case DOCUMENT_TYPES.CARD:
      return <FormattedMessage {...translations.EditCardOption} />;
    case DOCUMENT_TYPES.BATTLE:
      return <FormattedMessage {...translations.EditBattleOption} />;
    default:
      return <FormattedMessage {...translations.EditOption} />;
  }
};

const getReadOnlyLabel = (type) => {
  switch (type) {
    case DOCUMENT_TYPES.INSIGHT:
      return <FormattedMessage {...translations.ViewInsightOption} />;
    case DOCUMENT_TYPES.CARD:
      return <FormattedMessage {...translations.ViewCardOption} />;
    case DOCUMENT_TYPES.BATTLE:
      return <FormattedMessage {...translations.ViewBattleOption} />;
    default:
      return <FormattedMessage {...translations.ViewOption} />;
  }
};

const MasterContentManager = ({
  classes,
  viewType,
  SetViewType,
  GetDocuments,
  documents,
  selectedMarket,
  isFetching,
  isCreating,
  filters,
  intl,
  docLimit,
  history,
  userRoles,
  SetFilters,
  LoadMore,
  ResetStore,
  DeleteDocument,
  TranslateDocument,
  HandleCreateNewContent,
  SetActiveMenuDocumentId,
  SetCurrentLanguage,
  UpdateStatus,
}) => {
  useEffect(() => {
    GetDocuments(true);
  }, [filters, GetDocuments]);

  useEffect(() => {
    return () => {
      ResetStore();
    };
  }, [ResetStore]);

  const isGrid = viewType === DOCUMENT_VIEW_TYPES.GRID;
  const isList = viewType === DOCUMENT_VIEW_TYPES.LIST;
  const { language, contentType } = filters;

  const [activeMenuDocumentId, setActiveMenuDocId] = useState();
  const [deleteModalIsVisible, setDeleteModalVisibility] = useState(false);
  const [translateModalIsVisible, setTranslateModalVisibility] =
    useState(false);
  const [deleteItem, setDeleteItem] = useState();
  const [translateItem, setTranslateItem] = useState({
    availableLanguages: [],
  });

  const showDeleteModal = (docId, docType) => {
    setDeleteItem({ docId, docType });
    setDeleteModalVisibility(true);
  };

  const showTranslateModal = (docId, docType, existingLanguages) => {
    const availableLanguages = LANGUAGES.filter(
      (lang) => !existingLanguages.includes(lang.Code),
    ).map((lang) => lang.Code);
    setTranslateItem({ docId, docType, availableLanguages });
    setTranslateModalVisibility(true);
  };

  const onDelete = () => {
    DeleteDocument(deleteItem.docId, deleteItem.docType);
    setDeleteModalVisibility(false);
  };

  const onTranslate = (destLang) => {
    TranslateDocument(translateItem.docId, translateItem.docType, destLang);
    setTranslateModalVisibility(false);
  };

  const docTypeOptions = Object.keys(DOCUMENT_TYPES)
    .map((x) => ({
      value: DOCUMENT_TYPES[x],
      label: (
        <FormattedMessage {...DocumentTypeTranslations[DOCUMENT_TYPES[x]]} />
      ),
    }))
    .filter(
      (option) =>
        clientConfig.MODULES.IS_BATTLE_MANAGEMENT_AVAILABLE ||
        option.value !== DOCUMENT_TYPES.BATTLE,
    )
    .filter(
      (option) =>
        clientConfig.MODULES.IS_POLL_MANAGEMENT_AVAILABLE ||
        option.value !== DOCUMENT_TYPES.POLL,
    );
  docTypeOptions.unshift({
    value: undefined,
    label: intl.formatMessage({ ...translations.AllContentOption }),
  });

  const navigateToAddToMarketScreen = (id, type) => () =>
    history.push(getPath("documentAddToMarket", type, id, language.value));

  const navigateToPublishPage = (docId, docType) => () => {
    let path = "";
    switch (docType) {
      case DOCUMENT_TYPES.INSIGHT:
        path = "publishMasterInsight";
        break;
      case DOCUMENT_TYPES.CARD:
        path = "publishMasterCard";
        break;
      case DOCUMENT_TYPES.BATTLE:
        path = "publishMasterBattle";
        break;
      case DOCUMENT_TYPES.POLL:
        path = "publishMasterPoll";
        break;
      default:
        break;
    }
    history.push(getPath(path, docId, language.value));
  };

  const adminMenuItems = (doc) => {
    return [
      {
        label: <FormattedMessage {...translations.PublishOption} />,
        icon: Icons.settings,
        onItemClick: navigateToPublishPage(doc.id, doc.type),
      },
      {
        label: getEditLabel(doc.type),
        icon: Icons.settings,
        onItemClick: editDocument(history, doc, SetActiveMenuDocumentId),
      },
      {
        label: <FormattedMessage {...translations.AddToMarketOption} />,
        icon: Icons.reply,
        onItemClick: navigateToAddToMarketScreen(doc.id, doc.type),
        disabled: doc.workflow === DOC_WORKFLOW_ENUM.DRAFT,
      },
      {
        label: <FormattedMessage {...translations.TranslateOption} />,
        icon: Icons.translate,
        onItemClick: () =>
          showTranslateModal(doc.id, doc.type, doc.availableLanguages),
      },
      {
        label: <FormattedMessage {...translations.DeleteOption} />,
        icon: Icons.delete,
        onItemClick: () => showDeleteModal(doc.id, doc.type),
      },
      {
        label: (
          <FormattedMessage
            {...translations[
              doc.workflow === DOC_WORKFLOW_ENUM.PUBLISHED
                ? "SetAsDraftOption"
                : "SetAsReadyOption"
            ]}
          />
        ),
        icon: Icons.settings,
        onItemClick: () => updateStatus(doc),
      },
    ];
  };

  const trainerMenuItems = (doc) => {
    return [
      {
        label: getReadOnlyLabel(doc.type),
        icon: Icons.settings,
        onItemClick: editDocument(history, doc, SetActiveMenuDocumentId),
      },
      {
        label: <FormattedMessage {...translations.AddToMarketOption} />,
        icon: Icons.reply,
        onItemClick: navigateToAddToMarketScreen(doc.id, doc.type),
        disabled: doc.workflow === DOC_WORKFLOW_ENUM.DRAFT,
      },
    ];
  };

  const updateStatus = (doc) => {
    const newStatus =
      doc.workflow === DOC_WORKFLOW_ENUM.PUBLISHED
        ? DOC_WORKFLOW_ENUM.DRAFT
        : DOC_WORKFLOW_ENUM.PUBLISHED;
    UpdateStatus(newStatus, language.value, doc);
  };

  return (
    <div className={classes.rootContainer}>
      {isFetching && <FullscreenLoader />}
      <Navigation />

      {!userRoles.includes(USER_ROLE_NAMES.TRAINER) && (
        <FixedContainer className={classes.createButtonContainer}>
          {isCreating ? (
            <Spinner size={30} />
          ) : (
            <RoundButton onClick={HandleCreateNewContent}>
              <Icon iconName={Icons.plus} />
            </RoundButton>
          )}
        </FixedContainer>
      )}

      <div className={classes.content}>
        <div className={classes.header}>
          <div className={classes.title}>
            <FormattedMessage {...translations.Title} />
          </div>
          <div className={classes.filters}>
            <FilterLanguages languages={LANGUAGES}>
              {(languages) =>
                languages.length > 1 && (
                  <div className={classes.languageSelector}>
                    <Dropdown
                      selectedOption={language}
                      onChange={(selectedOption) => {
                        SetFilters("language", selectedOption);
                        SetCurrentLanguage(selectedOption.value);
                      }}
                      options={languages.map((x) => ({
                        value: x.Code,
                        label: x.Name,
                      }))}
                    />
                  </div>
                )
              }
            </FilterLanguages>
            <div className={classes.typeSelector}>
              <Dropdown
                selectedOption={{
                  value: contentType.value,
                  label:
                    contentType.label ||
                    intl.formatMessage({ ...translations.AllContentOption }),
                }}
                onChange={(selectedOption) => {
                  SetFilters("contentType", selectedOption);
                }}
                options={docTypeOptions}
              />
            </div>
            <ViewSwitcher viewType={viewType} changeViewType={SetViewType} />
          </div>
        </div>
        {isGrid && !!documents.length && (
          <div className={classes.grid}>
            {documents.map((doc) => (
              <div key={doc.id}>
                <Card
                  title={doc.title}
                  image_path={doc.picCover}
                  date={doc.updateDate}
                  contentType={
                    <FormattedMessage {...DocumentTypeTranslations[doc.type]} />
                  }
                  status={
                    doc.workflow === DOC_WORKFLOW_ENUM.PUBLISHED
                      ? intl.formatMessage({ ...translations.ReadyToUse })
                      : doc.workflow
                  }
                  handleOnClick={editDocument(
                    history,
                    doc,
                    SetActiveMenuDocumentId,
                  )}
                  handleOnClickSettingsButton={() => setActiveMenuDocId(doc.id)}
                  vote={doc.vote}
                  read={doc.read}
                  comment={doc.comment}
                />
                <SettingsMenu
                  handleFocusLeave={() => setActiveMenuDocId(undefined)}
                  isVisible={
                    activeMenuDocumentId && activeMenuDocumentId === doc.id
                  }
                  className={classes.docSettingsMenu}
                  menuItems={
                    userRoles.includes(USER_ROLE_NAMES.TRAINER)
                      ? trainerMenuItems(doc)
                      : adminMenuItems(doc)
                  }
                />
              </div>
            ))}
          </div>
        )}
        {isList && !!documents.length && (
          <ListView
            items={documents}
            editDocument={(doc) =>
              editDocument(history, doc, SetActiveMenuDocumentId)
            }
            showLoadMore={false}
            loading={false}
            loadMore={() => null}
            allowNotification
            allowDelete
            onItemSettingsClicked={setActiveMenuDocId}
            activeMenuDocumentId={activeMenuDocumentId}
            menuItems={(doc) =>
              userRoles.includes(USER_ROLE_NAMES.TRAINER)
                ? trainerMenuItems(doc)
                : adminMenuItems(doc)
            }
          />
        )}
        {!documents.length && (
          <div>
            <FormattedMessage {...translations.NoOneDocumentText} />
          </div>
        )}
        <div className={classes.loadMoreContainer}>
          {!!documents.length && documents.length % docLimit === 0 && (
            <Button
              disabled={isFetching}
              variation={ButtonVariation.primary}
              size={ButtonSize.small}
              handleClick={LoadMore}
            >
              <FormattedMessage {...translations.SeeMoreButtonTitle} />
            </Button>
          )}
        </div>
      </div>
      <DeleteModal
        deleteMessage={<FormattedMessage {...translations.DeleteMessage} />}
        actionHandler={onDelete}
        setShowModal={setDeleteModalVisibility}
        showModal={deleteModalIsVisible}
      />
      <DuplicationModal
        hideModal={() => setTranslateModalVisibility(false)}
        visible={translateModalIsVisible}
        sourceLang={language.value}
        availableItemLanguages={translateItem.availableLanguages}
        duplicationHandler={onTranslate}
      />
      <FormattedMessage {...translations.NotificationModalMessagePlaceholder}>
        {(message) => (
          <SendNotificationModal
            title={
              <FormattedMessage {...translations.NotificationModalTitle} />
            }
            description={
              <FormattedMessage
                {...translations.NotificationModalDescription}
              />
            }
            placeholder={message[0]}
          />
        )}
      </FormattedMessage>
    </div>
  );
};

const mapStateToProp = (state) => ({
  viewType: state.documents.viewType,
  isFetching: state.common.fetchIndicator,
  isCreating: state.common.saveIndicator,
  documents: state.masterDocuments.documents,
  filters: state.masterDocuments.filters,
  docLimit: state.masterDocuments.limit,
  userRoles: state.auth.profileInfo.roles,
  currentLanguage: state.documents.currentLanguage,
  currentDocumentType: state.documents.currentDocumentType,
  selectedMarket: state.documents.selectedMarket,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  SetViewType: (newValue) => {
    dispatch(SetViewType(newValue));
  },
  GetDocuments: (reset) => dispatch(getMasterDocuments(reset)),
  SetFilters: (type, data) => dispatch(SetFilters(type, data)),
  LoadMore: () => {
    dispatch(UpdatePage());
    dispatch(getMasterDocuments());
  },
  ResetStore: () => dispatch(ResetMasterContent()),
  DeleteDocument: (docId, docType) =>
    dispatch(
      DeleteDocument(docId, docType, () => {
        /** Update document list */
        dispatch(getMasterDocuments(true));
      }),
    ),
  TranslateDocument: (docId, docType, destLang) =>
    dispatch(
      TranslateDocument(docId, docType, destLang, () => {
        /** Recheck new existing languages */
        dispatch(getMasterDocuments(true));
        dispatch(
          SetSuccessMessage(
            <FormattedMessage
              {...translations.DocumentDuplicationMessage}
              values={{ lang: destLang }}
            />,
          ),
        );
      }),
    ),
  HandleCreateNewContent: () => {
    dispatch(SetCurrentDocumentLevel(DOCUMENT_LEVEL.MASTER));
    ownProps.history.push(getPath("contentType"));
  },
  SetActiveMenuDocumentId: (activeDocumentId) => {
    dispatch(SetActiveMenuDocumentId(activeDocumentId));
    dispatch(SetCurrentDocumentLevel(DOCUMENT_LEVEL.MASTER));
  },
  SetCurrentLanguage: (language) => {
    dispatch(SetCurrentLanguage(language));
  },
  SetCurrentMarket: (market) => {
    dispatch(SetCurrentMarket(market));
  },
  SetNotificationData: (newValue) => {
    dispatch(SetNotificationData(newValue));
  },
  SetShowNotificationModal: (newValue) => {
    dispatch(SetShowNotificationModal(newValue));
  },
  UpdateStatus: (newStatus, lang, { id, type, workflow }) => {
    dispatch(
      UpdateDocumentWorkflow(id, type, lang, newStatus, () => {
        dispatch(
          SetSuccessMessage(
            <FormattedMessage {...translations.UpdatedDocumentStatusMessage} />,
          ),
        );
        dispatch(getMasterDocuments(true));
      }),
    );
  },
});

export default injectIntl(
  connect(
    mapStateToProp,
    mapDispatchToProps,
  )(withThemedStyle(MasterContentManagerStyle)(MasterContentManager)),
);
