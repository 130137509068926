import { RESET_STORE } from "../../constants/store/auth";
import {
  SET_ALL_TAGS,
  SET_BRAND_TAGS,
  SET_CITIES_TAGS,
  SET_COUNTRY_TAGS,
  SET_MARKET_TAGS,
  SET_RELATIONSHIP_TAGS,
  SET_RETAILER_TAGS,
  SET_SHOPS_TAGS,
  SET_USER_TAGS,
  SET_ZONE_TAGS,
  SET_DIVISION_TAGS,
  SET_ENTITY_TAGS,
  SET_DEPARTMENT_TAGS,
  SET_SERVICE_TAGS,
  SET_ROLE_TAGS,
  SET_UNIVERSE_TAGS,
  SET_VMSEGMENTATION_TAGS,
  SET_JOURNAL_TAGS,
  SET_OCCUPATION_TAGS,
  RESET_TAGS,
} from "../../constants/store/tags";
import clientConfig from "../../configs/client";

const initialState = {
  userTags: [],
  countries: [],
  relationships: [],
  markets: [],
  zones: [],
  retailers: [],
  brands: [],
  cities: [],
  shops: [],
  roles: [],
  divisions: [],
  entities: [],
  departments: [],
  services: [],
  universes: [],
  vmSegmentations: [],
  journals: [],
  occuppations: [],
};

// TODO: merge tags store with referential store
export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case SET_ALL_TAGS: {
      return {
        ...state,
        ...data.reduce(
          (tagCategories, tag) => {
            if (
              clientConfig.USER_TAGS_CLUSTER_IDS.includes(
                parseInt(tag.clusterId),
              )
            ) {
              tagCategories.userTags.push(tag);
            }
            if (
              clientConfig.USER_COUNTRIES_CLUSTER_IDS.includes(
                parseInt(tag.clusterId),
              )
            ) {
              tagCategories.countries.push(tag);
            }
            if (
              clientConfig.USER_RELATIONSHIP_CLUSTER_IDS.includes(
                parseInt(tag.clusterId),
              )
            ) {
              tagCategories.relationships.push(tag);
            }
            if (
              clientConfig.USER_MARKET_CLUSTER_IDS.includes(
                parseInt(tag.clusterId),
              )
            ) {
              tagCategories.markets.push(tag);
            }
            if (
              clientConfig.ZONE_CLUSTER_IDS.includes(parseInt(tag.clusterId))
            ) {
              tagCategories.zones.push(tag);
            }
            if (
              clientConfig.RETAILER_CLUSTER_IDS.includes(
                parseInt(tag.clusterId),
              )
            ) {
              tagCategories.retailers.push(tag);
            }
            if (
              clientConfig.BRAND_TAGS_CLUSTER_IDS.includes(
                parseInt(tag.clusterId),
              )
            ) {
              tagCategories.brands.push(tag);
            }
            if (
              clientConfig.ROLE_CLUSTERS_ID.includes(parseInt(tag.clusterId))
            ) {
              tagCategories.roles.push(tag);
            }
            if (
              clientConfig.DIVISION_CLUSTER_IDS.includes(
                parseInt(tag.clusterId),
              )
            ) {
              tagCategories.divisions.push(tag);
            }
            if (
              clientConfig.ENTITY_CLUSTER_IDS.includes(parseInt(tag.clusterId))
            ) {
              tagCategories.entities.push(tag);
            }
            if (
              clientConfig.DEPARTMENT_CLUSTER_IDS.includes(
                parseInt(tag.clusterId),
              )
            ) {
              tagCategories.departments.push(tag);
            }
            return tagCategories;
          },
          {
            userTags: [],
            countries: [],
            relationships: [],
            markets: [],
            zones: [],
            retailers: [],
            brands: [],
            roles: [],
            divisions: [],
            entities: [],
            departments: [],
            services: [],
          },
        ),
      };
    }
    case SET_USER_TAGS: {
      return modifyFilterState(state, "userTags", data);
    }
    case SET_COUNTRY_TAGS: {
      return modifyFilterState(state, "countries", data);
    }
    case SET_RELATIONSHIP_TAGS: {
      return modifyFilterState(state, "relationships", data);
    }
    case SET_MARKET_TAGS: {
      return modifyFilterState(state, "markets", data);
    }
    case SET_ZONE_TAGS: {
      return modifyFilterState(state, "zones", data);
    }
    case SET_RETAILER_TAGS: {
      return modifyFilterState(state, "retailers", data);
    }
    case SET_BRAND_TAGS: {
      return modifyFilterState(state, "brands", data);
    }
    case SET_CITIES_TAGS: {
      return modifyFilterState(state, "cities", data);
    }
    case SET_SHOPS_TAGS: {
      return modifyFilterState(state, "shops", data);
    }
    case SET_DIVISION_TAGS: {
      return modifyFilterState(state, "divisions", data);
    }
    case SET_ENTITY_TAGS: {
      return modifyFilterState(state, "entities", data);
    }
    case SET_DEPARTMENT_TAGS: {
      return modifyFilterState(state, "departments", data);
    }
    case SET_SERVICE_TAGS: {
      return modifyFilterState(state, "services", data);
    }
    case SET_ROLE_TAGS: {
      return modifyFilterState(state, "roles", data);
    }
    case SET_UNIVERSE_TAGS: {
      return modifyFilterState(state, "universes", data);
    }
    case SET_JOURNAL_TAGS: {
      return modifyFilterState(state, "journals", data);
    }
    case SET_VMSEGMENTATION_TAGS: {
      return modifyFilterState(state, "vmSegmentations", data);
    }
    case SET_OCCUPATION_TAGS: {
      return modifyFilterState(state, "occuppations", data);
    }
    case RESET_TAGS: {
      return {
        ...state,
        countries: [],
        cities: [],
        shops: [],
        services: [],
        roles: [],
      };
    }
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}

const modifyFilterState = (state, filterName, value) => ({
  ...state,
  [filterName]: value,
});
