import gql from "graphql-tag";

export const LESSON_SET_PRIVACY = gql`
  mutation lessonsSetPrivacy($lessonId: ID, $lang: LangEnum) {
    admin {
      lesson(lessonId: $lessonId, lang: $lang) {
        privacy {
          set
        }
      }
    }
  }
`;
