import gql from "graphql-tag";

export const UPDATE_FILE_WORKFLOW = gql`
  mutation fileUpdateWorkflow(
    $fileId: ID
    $lang: LangEnum
    $status: DocWorkflowEnum!
    $dateIn: Date
  ) {
    admin {
      file(fileId: $fileId, lang: $lang) {
        workflow {
          set(status: $status, dateIn: $dateIn)
        }
      }
    }
  }
`;
