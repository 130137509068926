import { defineMessages } from "react-intl";

export const translations = defineMessages({
  QuestionListLabel: {
    id: "ProfilePendingQuestions.QuestionListLabel",
    defaultMessage: "List of Pending Questions",
  },
  QuestionDelayLabel: {
    id: "ProfilePendingQuestions.QuestionDelayLabel",
    defaultMessage: "delay",
  },
  noDataText: {
    id: "ProfilePendingQuestions.noDataText",
    defaultMessage: "No one pending question yet",
  },
  DayShortForm: {
    id: "ProfilePendingQuestions.DayShortForm",
    defaultMessage: "d",
  },
});
