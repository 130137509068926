import * as constants from "../../constants/store/userProfile";
import { FILTER_TYPES } from "../../configs/client";

const learningTypes = [
  {
    label: "Degrees",
    value: 1,
  },
];

const initialState = {
  userType: null,
  isLoading: true,
  badges: [],
  learningTypes: learningTypes,
  filters: {
    learningType: learningTypes[0],
    [FILTER_TYPES.UNIVERSE]: [],
  },
  profileData: {
    lastConnection: null,
    user: null,
    brands: [],
    interests: [],
    statistics: null,
    totalPoints: null,
    programs: [],
    questions: [],
    degrees: [],
  },
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.UPDATE_USER_PROFILE_DATA:
      return {
        ...state,
        profileData: data,
      };
    case constants.UPDATE_LOADING_STATUS:
      return {
        ...state,
        isLoading: data,
      };
    case constants.SET_BADGES:
      return {
        ...state,
        badges: data,
      };
    case constants.UPDATE_USER_TYPE:
      return {
        ...state,
        userType: data,
      };
    case constants.UPDATE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...data,
        },
      };
    case constants.RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
