import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { IntlProvider } from "react-intl";
import * as serviceWorker from "./serviceWorker";
import client from "./graphql/client";
import "./assets/fonts/FuturaPTBook.otf";
import "./assets/fonts/URWDINCond-Demi.ttf";
import "./index.scss";
import Router from "./containers/Router/Router";
import routes from "./core/routes";
import { configureStore } from "./store/config";
import { Provider as ReduxProvider } from "react-redux";
import LoadTranslationMessages from "./components/LoadTranslationMessages";

if (!Intl.PluralRules) {
  require("@formatjs/intl-pluralrules/polyfill");
  require("@formatjs/intl-pluralrules/locale-data/en"); // Add locale data for en
}
if (!Intl.RelativeTimeFormat) {
  require("@formatjs/intl-relativetimeformat/polyfill");
  require("@formatjs/intl-relativetimeformat/locale-data/en"); // Add locale data for en
}

const { store } = configureStore();

const locale = "en-GB";

// Root Configuration
const Root = () => (
  <LoadTranslationMessages>
    {(messages) => {
      if (!messages) return null;

      return (
        <IntlProvider
          locale={locale}
          messages={messages[locale]}
          textComponent="span"
        >
          <BrowserRouter>
            <ReduxProvider store={store}>
              <ApolloProvider client={client}>
                <Router routes={routes} />
              </ApolloProvider>
            </ReduxProvider>
          </BrowserRouter>
        </IntlProvider>
      );
    }}
  </LoadTranslationMessages>
);

// Inject App in DOM
render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
