import React from "react";
import PropTypes from "prop-types";
import { ImageSelectorStyle } from "../imageSelector.style";
import withThemedStyle from "../../../hoc/withThemedStyle";

class ImageSelector extends React.PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.imageContainer}>
        <div className={classes.uploadControlsBox} />
      </div>
    );
  }
}

ImageSelector.propTypes = {
  /** The current selected image */
  sourceImage: PropTypes.string,
};

export default withThemedStyle(ImageSelectorStyle)(ImageSelector);
