import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { GetPageData } from "../../store/kpiApplicationDetails/actions";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";
import style from "./ApplicationShiseido.style";
import {
  KeyDetailWidget,
  KPIPageContainer,
  WidgetContainer,
  WidgetSection,
} from "../../components/KPI";
import { exportApplication } from "../../utils/excelExport/kpi/application";
import { translations } from "./ApplicationShiseido.translations";
import compose from "lodash/fp/compose";
import { LineChart } from "genius-charts";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const KpiApplicationShiseido = (props) => {
  const {
    activeUsers,
    activeNumberOfConnections,
    averageTimeSpentPerDay,
    nrOfUsersChartData,
    GetPageData,
    Export,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  useEffect(() => {
    GetPageData();
  }, []);

  const exportFunction = useCallback(
    (filters, intl) =>
      Export(
        {
          activeUsers: activeUsers,
          activeNumberOfConnections: activeNumberOfConnections,
          averageTimeSpentPerDay: averageTimeSpentPerDay,
          nrOfUsersChartData: nrOfUsersChartData,
        },
        filters,
        intl,
      ),
    [
      activeUsers,
      activeNumberOfConnections,
      averageTimeSpentPerDay,
      nrOfUsersChartData,
    ],
  );

  return (
    <div className={classes.container}>
      <KPIPageContainer
        onFilterChange={GetPageData}
        exportFunction={exportFunction}
      >
        <WidgetSection width={35} title=" ">
          <WidgetContainer width={33.33}>
            <KeyDetailWidget
              title={<FormattedMessage {...translations.ActiveUsersLabel} />}
              primaryData={`${activeUsers.active}/${activeUsers.total}`}
              secondaryData={activeUsers.previousValue}
              percentData={Math.abs(activeUsers.tendency)}
              lowPercent={activeUsers.tendency < 0}
              isAvailable={activeUsers.isAvailable}
            />
          </WidgetContainer>
          {/* <WidgetContainer width={33.33}>
            <KeyDetailWidget
              title={<FormattedMessage {...translations.ActiveNumberOfConnectionsLabel} />}
              primaryData={`${activeNumberOfConnections.numberOfConnections}`}
              secondaryData={activeNumberOfConnections.previousValue}
              percentData={Math.abs(activeNumberOfConnections.tendency)}
              lowPercent={activeNumberOfConnections.tendency < 0}
              isAvailable={activeNumberOfConnections.isAvailable}
            />
          </WidgetContainer> */}
          {/* <WidgetContainer width={33.33}>
            <KeyDetailWidget
              title={<FormattedMessage {...translations.AverageTimeSpentOnTheAppLabel} />}
              primaryData={`${averageTimeSpentPerDay.averageTimeMin}min`}
              secondaryData={averageTimeSpentPerDay.previousValue}
              percentData={Math.abs(averageTimeSpentPerDay.tendency)}
              lowPercent={averageTimeSpentPerDay.tendency < 0}
              isAvailable={averageTimeSpentPerDay.isAvailable}
            />
          </WidgetContainer> */}
        </WidgetSection>
        <WidgetSection width={100}>
          <WidgetContainer width={100}>
            <FormattedMessage {...translations.NrOfUsersChartLabel}>
              {(message) => (
                <LineChart
                  selectedInterval="day"
                  verticalTitle={message[0]}
                  data={nrOfUsersChartData}
                  showRangeSelectorDropdown={false}
                />
              )}
            </FormattedMessage>
          </WidgetContainer>
        </WidgetSection>
      </KPIPageContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUsers: state.kpi.applicationDetails.activeUsers,
  activeNumberOfConnections:
    state.kpi.applicationDetails.activeNumberOfConnections,
  averageTimeSpentPerDay: state.kpi.applicationDetails.averageTimeSpentPerDay,
  nrOfUsersChartData: [
    {
      name: "Users",
      data: state.kpi.applicationDetails.nrOfUsersChartData.nrOfUsers,
    },
  ],
  isExportLoading: state.kpi.filters.isExportLoading,
});

const mapDispatchToProps = (dispatch) => ({
  GetPageData: () => dispatch(GetPageData()),
  Export: (exportData, filters, intl) => {
    exportApplication(exportData, filters, intl);
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  KpiApplicationShiseido,
);
