import React from "react";
import PropTypes from "prop-types";
import style from "./TwitterCard.style";
import { DefaultVideo } from "assets/icons";
import { Icon, Icons } from "genius-ui";
import Video from "../Video";
import RoundedProfilePicture from "../RoundedProfilePicture";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const TwitterCard = (props) => {
  const {
    description,
    mediaType,
    likes,
    profileName,
    profileUrl,
    video,
    twitterLink,
  } = props;
  const classes = useThemedStyle(useStyle, props);
  return (
    <div className={classes.cardContainer}>
      <div className={classes.header}>
        <div className={classes.profileContainer}>
          <RoundedProfilePicture imageUrl={profileUrl} size={30} />
          {profileName}
        </div>
        <a
          className={classes.logo}
          href={twitterLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </a>
      </div>
      {mediaType === "image" ? (
        <div className={classes.image}>
          <div className={classes.likes}>
            <Icon iconName={Icons.vote} alt="" />
            {likes}
          </div>
        </div>
      ) : (
        <Video
          width="100%"
          height="350px"
          sourceVideo={video || DefaultVideo}
          sourcePosterImage={undefined}
          onVideoChange={() => {}}
          onClick={() => {}}
          showErrorMessageHandler={() => {}}
          isSelected
          onImageChange={() => {}}
          isReadOnly
        />
      )}
      <div className={classes.description}>
        {description || "Social content not editable yet"}
      </div>
    </div>
  );
};

TwitterCard.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  video: PropTypes.string,
  mediaType: PropTypes.string,
  likes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  profileName: PropTypes.string,
  profileUrl: PropTypes.string,
  twitterLink: PropTypes.string,
};

export default TwitterCard;
