import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./PendingQuestions.style";
import uuidV4 from "uuid/dist/v4";
import { FormattedMessage } from "react-intl";
import { Icon, Icons } from "genius-ui";
import classNames from "classnames";
import { translations } from "./PendingQuestions.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../../hooks/style/useThemedStyle";
import {
  differenceInCalendarDays,
  isBefore,
  parseISODate,
} from "../../../../utils/dateUtils";

const useStyle = createUseStyles(styles);

const PendingQuestions = (props) => {
  const { questions } = props;
  const classes = useThemedStyle(useStyle, props);

  const [isDesc, setOrderType] = useState(false);
  const arrowType = isDesc ? Icons.arrowOrderDown : Icons.arrowOrderUp;

  const handleOrdering = () => {
    questions.sort((a, b) =>
      isBefore(parseISODate(a.created), parseISODate(b.created)) ? 1 : -1,
    );
    setOrderType(!isDesc);
  };

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        <div>
          <FormattedMessage {...translations.QuestionListLabel} />
        </div>
        <div onClick={handleOrdering} className={classes.delayBtn}>
          <FormattedMessage {...translations.QuestionDelayLabel} />
        </div>
        <Icon
          handleClick={handleOrdering}
          iconName={arrowType}
          style={classes.arrow}
        />
      </div>
      {questions.map((question) => (
        <div className={classes.row} key={uuidV4()}>
          <div>
            <div className={classes.questioner}>{question.questioner}</div>
            <div className={classes.question}>{question.question}</div>
          </div>
          <div className={classes.delay}>
            {question.created && (
              <FormattedMessage {...translations.DayShortForm}>
                {(message) =>
                  `${differenceInCalendarDays(
                    parseISODate(question.created),
                    new Date(),
                  )}${message[0]}`
                }
              </FormattedMessage>
            )}
          </div>
        </div>
      ))}
      {!questions.length && (
        <div className={classNames([classes.row, classes.noData])}>
          <FormattedMessage {...translations.noDataText} />
        </div>
      )}
    </div>
  );
};

PendingQuestions.propTypes = {
  /** List of questions */
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PendingQuestions;
