import React from "react";
import { REACT_APP_CLIENT_NAME } from "../../configs/client";
import ShiseidoContentPage from "./SHISEIDO";
import DefaultContentPage from "./DEFAULT";

const ContentManager = (props) => {
  switch (REACT_APP_CLIENT_NAME) {
    case "SHISEIDO":
    case "SHISEIDO-PREPROD":
    case "SHISEIDO-PROD":
      return <ShiseidoContentPage {...props} />;
    default:
      return <DefaultContentPage {...props} />;
  }
};

export default ContentManager;
