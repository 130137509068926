import React from "react";
import ForgotPasswordStyle from "./ForgotPassword.style";
import { createUseStyles } from "react-jss";
import { FormattedMessage } from "react-intl";
import LinkButton from "../../components/common/LinkButton";
import { Button } from "genius-ui";
import AuthFlowFormContainer from "../../components/AuthFlowFormContainer";
import { getPath } from "../../core/paths";
import { Field } from "redux-form";
import { renderInputField } from "../../store/reduxForms/renders";
import { ForgotPassword } from "../../store/auth/actions";
import { connect } from "react-redux";
import { translations } from "./ForgotPassword.translations";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(ForgotPasswordStyle);

const ForgotPasswordScreen = (props) => {
  const classes = useThemedStyle(useStyle, props);

  return (
    <AuthFlowFormContainer
      actionSubmit={props.forgotPassword}
      title={<FormattedMessage {...translations.Title} />}
      subTitle={<FormattedMessage {...translations.SubTitle} />}
    >
      <FormattedMessage {...translations.EmailInputLabel}>
        {(message) => (
          <Field
            name="email"
            type="email"
            containerClassName={classes.inputContainer}
            label={message[0]}
            required
            component={renderInputField}
          />
        )}
      </FormattedMessage>
      <LinkButton
        className={classes.backToLoginButton}
        onClick={props.redirectToLogin}
      >
        <FormattedMessage {...translations.BackToLoginText} />
      </LinkButton>
      {props.error !== "" ? (
        <span className={classes.errorMessage}>{props.error}</span>
      ) : (
        ""
      )}
      {props.forgotMsg !== "" ? (
        <span className={classes.forgotMessage}>{props.forgotMsg}</span>
      ) : (
        ""
      )}
      <Button
        variation="primary"
        size="big"
        type="submit"
        className={classes.sendButton}
        handleClick={() => {}}
        autoPreventDefault={false}
      >
        <FormattedMessage {...translations.SendButtonText} />
      </Button>
    </AuthFlowFormContainer>
  );
};

const mapStateToProps = (state) => ({
  error: state.auth.error,
  timestamp: state.auth.timestamp,
  forgotMsg: state.auth.forgotMsg,
  loading: state.auth.loading,
  initialValues: { email: "" },
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  forgotPassword: (values) => {
    const { email } = values;
    dispatch(ForgotPassword(email));
  },
  redirectToLogin: () => {
    ownProps.history.push(getPath("login"));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordScreen);
