/* global localStorage */
import React from "react";
import { Redirect } from "react-router-dom";
import { MAIN_PAGE_PATHS } from "../../core/paths";

const MainPage = () => {
  if (localStorage.getItem("access_token")) {
    return <Redirect to={MAIN_PAGE_PATHS.DEFAULT} />;
  }
  return <Redirect to="login" />;
};

export default MainPage;
