import { combineReducers } from "redux";
import appAnalyticsData from "../appAnalyticsData/reducers";
import createFilterReducer from "../reducers/creators/filters";
import {
  APP_ANALYTICS_NAMESPACE,
  FILTERS,
} from "../../constants/store/appAnalytics";

export default combineReducers({
  data: appAnalyticsData,
  filters: createFilterReducer(APP_ANALYTICS_NAMESPACE, Object.values(FILTERS)),
});
