import gql from "graphql-tag";

export const FILTER_LESSONS = gql`
  query filterLessons($search: String, $skip: Int, $limit: Int) {
    lessons(search: $search, skip: $skip, limit: $limit) {
      lessonId
      title
      picThumb
      picCover
      summary
      shortBio
    }
  }
`;
