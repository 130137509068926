import React from "react";
import { getPath } from "../../core/paths";
import { createUseStyles } from "react-jss";
import Style from "./EditQuickQuizPage.style";
import PublishPreviewBanner from "../../components/PublishPreviewBanner";
import QuickQuizPageEditor from "../../components/QuickQuizPageEditor";
import { Icon, Icons } from "genius-ui";
import { DOCUMENT_LIST_WORKFLOWS } from "../../store/document/reducers";
import useThemedStyle from "../../hooks/style/useThemedStyle";
import { DOCUMENT_LEVEL } from "../../configs/constants";

const useStyle = createUseStyles(Style);

const EditQuickQuizPage = (props) => {
  const {
    cardId,
    pageId,
    answers,
    endMessage,
    image,
    endImage,
    question,
    match: {
      params: { lang },
    },
    history,
    SaveChanges,
    documentListWorkflow,
    documentLevel,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  const navigateToPublishPage = () => {
    if (documentLevel === DOCUMENT_LEVEL.MASTER) {
      history.push(getPath("publishMasterCard", cardId, lang));
    } else {
      history.push(getPath("cardPublish", cardId, lang));
    }
  };

  const saveModifications = (changes) =>
    SaveChanges(cardId, lang, pageId, changes);

  return (
    <>
      <PublishPreviewBanner
        onPreviewButtonClick={() => {}}
        onPublishButtonClick={navigateToPublishPage}
      >
        <Icon
          iconName={Icons.arrowLeft}
          style={classes.closeIcon}
          handleClick={() =>
            history.push(
              documentListWorkflow === DOCUMENT_LIST_WORKFLOWS.DELETED
                ? getPath("deletedContent")
                : getPath("contentManager"),
            )
          }
        />
      </PublishPreviewBanner>
      {pageId && (
        <div className={classes.container}>
          <QuickQuizPageEditor
            pageId={pageId}
            onDataChanged={saveModifications}
            answers={answers}
            endMessage={endMessage}
            endImage={endImage}
            question={question}
            image={image}
          />
        </div>
      )}
    </>
  );
};

export default EditQuickQuizPage;
