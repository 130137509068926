import gql from "graphql-tag";

export const SEARCH_SHOPS = gql`
  query searchShops($search: String, $skip: Int, $limit: Int) {
    Location(search: $search, skip: $skip, limit: $limit) {
      ocId
      title
    }
  }
`;
