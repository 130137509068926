import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { ActivityIntroComponentStyles } from "../ActivityIntroComponentStyle";
import { FormattedMessage } from "react-intl";
import { DefaultSlider1 } from "assets/icons";
import ContentEditable from "react-contenteditable";
import { translations } from "../ActivityIntroComponent.translations";
import withThemedStyle from "../../../hoc/withThemedStyle";

class ActivityIntroComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      theme: "",
      title: "",
      description: "",
    };
  }

  componentDidMount() {
    this.setState({
      image: this.props.image ? this.props.image : "",
      theme: this.props.theme ? this.props.theme : "",
      title: this.props.title ? this.props.title : "",
      description: this.props.description ? this.props.description : "",
    });
  }

  handleChange = () => {
    let changedValue = {
      image: this.state.image,
      theme: this.state.theme,
      title: this.state.title,
      description: this.state.description,
    };
    this.props.onChange(changedValue);
  };

  render() {
    const props = this.props;
    const { classes } = props;

    return (
      <div className={classes.introContainer} onClick={props.onClick}>
        <div className={classes.descriptionContainer}>
          <FormattedMessage {...translations.Theme}>
            {(message) => (
              <ContentEditable
                key="theme"
                html={this.state.theme}
                disabled
                tagName="article"
                className={props.classes.themeInput}
                placeholder={message[0]}
              />
            )}
          </FormattedMessage>
          <div className={classes.titleAndDescriptionContainer}>
            <FormattedMessage {...translations.Title}>
              {(message) => (
                <ContentEditable
                  key="title"
                  html={this.state.title}
                  disabled
                  tagName="article"
                  className={props.classes.titleInput}
                  placeholder={message[0]}
                />
              )}
            </FormattedMessage>
            <FormattedMessage {...translations.Description}>
              {(message) => (
                <ContentEditable
                  key="description"
                  html={this.state.description}
                  disabled
                  tagName="article"
                  className={props.classes.descriptionInput}
                  placeholder={message[0]}
                />
              )}
            </FormattedMessage>
          </div>
        </div>
      </div>
    );
  }
}

ActivityIntroComponent.propTypes = {
  /** Unique identifier of the activity */
  activityId: PropTypes.string.isRequired,
  /** The current selected image */
  image: PropTypes.string,
  /** Intro theme */
  theme: PropTypes.string,
  /** Intro title */
  title: PropTypes.string,
  /** Intro description */
  description: PropTypes.string,
};

ActivityIntroComponent.defaultProps = {
  defaultImage: DefaultSlider1,
};

export default withThemedStyle(ActivityIntroComponentStyles)(
  ActivityIntroComponent,
);
