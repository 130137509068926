export const FILE_SIZES = {
  Bytes: "Bytes",
  KB: "KB",
  MB: "MB",
  GB: "GB",
  TB: "TB",
  PB: "PB",
  EB: "EB",
  ZB: "ZB",
  YB: "YB",
};

export const formatBytes = (bytes, destFileSize, decimals = 2) => {
  if (bytes === 0) return bytes;

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = Object.values(FILE_SIZES);

  const i = sizes.indexOf(destFileSize);

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
};
