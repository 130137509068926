export default ({ MadlibPageEditor }) => ({
  pageBox: {
    display: "flex",
    flexDirection: "column",
    color: MadlibPageEditor.pageBoxColor,
    width: 800,
    position: "relative",
  },
  madlibContainer: {
    marginTop: 40,
    marginBottom: 40,
  },
});
