import gql from "graphql-tag";

export const GET_POLL_RESULT = gql`
  query getPollResult(
    $pollId: ID!
    $intervalId: Int
    $startDate: String
    $endDate: String
  ) {
    admin {
      pollByInterval(
        pollId: $pollId
        intervalId: $intervalId
        startDate: $startDate
        endDate: $endDate
      ) {
        versions {
          title
          picCover
          tags {
            tagId
            clusterId
            status
            title
          }
          updateDate
          workflow
          workflowScheduledIn
        }
        stats {
          instances {
            ocId
            participantsNumber
            questions {
              id
              title
              choices {
                id
                title
                answersNumber
              }
            }
          }
        }
      }
    }
  }
`;
