import { defineMessages } from "react-intl";

export const translations = defineMessages({
  Title: {
    id: "MasterContentManager.Title",
    defaultMessage: "Master content",
  },
  NoOneDocumentText: {
    id: "MasterContentManager.NoOneDocumentText",
    defaultMessage: "No one document yet",
  },
  AllContentOption: {
    id: "MasterContentManager.AllContentOption",
    defaultMessage: "All content",
  },
  PublishOption: {
    id: "MasterContentManager.PublishOption",
    defaultMessage: "Publication settings",
  },
  AddToMarketOption: {
    id: "MasterContentManager.AddToMarketOption",
    defaultMessage: "Add to market",
  },
  TranslateOption: {
    id: "MasterContentManager.TranslateOption",
    defaultMessage: "Translate",
  },
  DeleteOption: {
    id: "MasterContentManager.DeleteOption",
    defaultMessage: "Delete",
  },
  SeeMoreButtonTitle: {
    id: "MasterContentManager.SeeMoreButtonTitle",
    defaultMessage: "Load more",
  },
  DeleteMessage: {
    id: "MasterContentManager.DeleteMessage",
    defaultMessage: "Are you sure you want to delete this item?",
  },
  DocumentDuplicationMessage: {
    id: "MasterContentManager.DocumentDuplicationMessage",
    defaultMessage:
      "Your document has been successfully duplicated for the language {lang}",
  },
  EditInsightOption: {
    id: "MasterContentManager.EditInsightOption",
    defaultMessage: "Edit master insight",
  },
  EditCardOption: {
    id: "MasterContentManager.EditCardOption",
    defaultMessage: "Edit master card",
  },
  EditBattleOption: {
    id: "MasterContentManager.EditBattleOption",
    defaultMessage: "Edit master battle",
  },
  EditOption: {
    id: "MasterContentManager.EditOption",
    defaultMessage: "Edit master document",
  },
  ViewInsightOption: {
    id: "MasterContentManager.ViewInsightOption",
    defaultMessage: "View master insight",
  },
  ViewCardOption: {
    id: "MasterContentManager.ViewCardOption",
    defaultMessage: "View master card",
  },
  ViewBattleOption: {
    id: "MasterContentManager.ViewBattleOption",
    defaultMessage: "View master battle",
  },
  ViewOption: {
    id: "MasterContentManager.ViewOption",
    defaultMessage: "View master document",
  },
  ReadyToUse: {
    id: "MasterContentManager.ReadyToUse",
    defaultMessage: "Ready to use",
  },
  SendNotificationOption: {
    id: "MasterContentManager.SendNotificationTitle",
    defaultMessage: "Send Notification",
  },
  NotificationModalTitle: {
    id: "MasterContentManager.NotificationModalTitleBackup",
    defaultMessage: "Send a Notification",
  },
  NotificationModalDescription: {
    id: "MasterContentManager.NotificationModalDescriptionBackup",
    defaultMessage:
      "Send a Push Notification to all users that this content is broadcast to. " +
      "This notification will only be sent once",
  },
  NotificationModalMessagePlaceholder: {
    id: "MasterContentManager.NotificationModalMessagePlaceholderBackup",
    defaultMessage: "There’s something new to see in Beauty Genius",
  },
  SetAsDraftOption: {
    id: "MasterContentManager.SetAsDraftOption",
    defaultMessage: "Set as draft",
  },
  SetAsReadyOption: {
    id: "MasterContentManager.SetAsReadyOption",
    defaultMessage: "Set as available",
  },
  UpdatedDocumentStatusMessage: {
    id: "MasterContentManager.UpdatedDocumentStatusMessage",
    defaultMessage: "Your document's status has been successfully updated",
  },
});
