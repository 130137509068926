import * as constants from "../../constants/store/battleResults";
import client from "../../graphql/client";
import {
  GetFormattedBattleInfo,
  GetFormattedGlobalBattleData,
} from "../kpiBattle/transformers";
import { SetErrorMessage } from "../notification/actions";
import { GET_BATTLE_RESULT } from "../../graphql/remote/battle/queries/getBattleResult.graphql";
import { getFormattedSopiDate } from "../../utils/dateUtils";

export const UpdateBattleResults = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_BATTLE_RESULTS,
    data,
  });
};

export const UpdateSelectedPeriodResult = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_SELECTED_PERIOD_RESULT,
    data,
  });
};

export const UpdateBattleInfo = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_BATTLE_INFO,
    data,
  });
};

export const ResetStore = () => (dispatch) => {
  dispatch({ type: constants.RESET_STORE });
};

export const extendWithGlobalSortVariables = (
  variables,
  leaderBoardColumnSort,
) => {
  const columnName = leaderBoardColumnSort
    ? Object.keys(leaderBoardColumnSort)[0]
    : null;
  const sortMethod =
    columnName && leaderBoardColumnSort[columnName]
      ? leaderBoardColumnSort[columnName].toLowerCase()
      : null;
  if (!sortMethod || !columnName) return variables;
  let sortVariableName = "";
  switch (columnName) {
    case "averageScore":
      sortVariableName = "sortAverageScore";
      break;
    case "highestScore":
      sortVariableName = "sortBestScore";
      break;
    case "attempts":
      sortVariableName = "sortNumberOfAttempts";
      break;
    case "totalScore":
      sortVariableName = "sortTotalScore";
      break;
    default:
      return variables;
  }
  return {
    ...variables,
    [sortVariableName]: sortMethod,
  };
};

export const GetBattleResults =
  (battleId, callback) => async (dispatch, getState) => {
    try {
      const { leaderBoardColumnSort } = getState().kpiBattle;
      const { selectedPeriod, selectedEndDate, selectedStartDate } =
        getState().battleResults.info;
      if (!selectedPeriod && !selectedEndDate) {
        callback && callback();
        return null;
      }
      let variables = {
        battleId,
        intervalId: selectedPeriod?.value,
        startDate: selectedPeriod
          ? ""
          : getFormattedSopiDate(selectedStartDate),
        endDate: selectedPeriod ? "" : getFormattedSopiDate(selectedEndDate),
      };
      variables = extendWithGlobalSortVariables(
        variables,
        leaderBoardColumnSort,
      );
      const response = await client.query({
        fetchPolicy: "network-only",
        query: GET_BATTLE_RESULT,
        variables,
        notifyOnNetworkStatusChange: true,
      });
      const formattedLeaderBoardData = response.data.admin.battles[0]
        .gameTemplate
        ? GetFormattedGlobalBattleData(
            response.data.admin.battles[0].gameTemplate.leaderboard,
          )
        : [];
      const battleInfo = GetFormattedBattleInfo(response.data.admin.battles[0]);

      dispatch(UpdateBattleResults(formattedLeaderBoardData));
      dispatch(UpdateBattleInfo(battleInfo));
      callback && callback();
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while fetching kpi battle result data",
          e,
        ),
      );
    }
  };
