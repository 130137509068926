import gql from "graphql-tag";

export const INSIGHT_UPDATE_PIC_THUMB = gql`
  mutation insightUpdatePicThumb(
    $insightId: ID
    $lang: LangEnum
    $data: String!
  ) {
    admin {
      insight(insightId: $insightId, lang: $lang) {
        picThumb(data: $data)
      }
    }
  }
`;
