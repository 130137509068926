export default (theme) => ({
  modalContent: {
    padding: "15px 20px 0 20px",
    zIndex: 99999,
    width: 686,
    minHeight: 311,
    backgroundColor: theme.LessonRelocationModal.containerBackground,
    color: theme.LessonRelocationModal.containerColor,
    borderRadius: 4,
    fontFamily: theme.FontFamily,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 0.07,
    "& .icon-slim-close-icon": {
      cursor: "pointer",
    },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 12,
  },
  title: {
    fontSize: 22,
    fontWeight: "bold",
  },
  body: {
    height: 226,
  },
  dropdown: {
    marginRight: "0 !important",
    height: "unset !important",
  },
  actions: {
    marginTop: 50,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  description: {
    fontSize: 17,
    fontWeight: 300,
    letterSpacing: 0.06,
    marginBottom: 30,
  },
  label: {
    fontSize: 16,
    marginBottom: 10,
  },
});
