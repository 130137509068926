export default ({ HorizontalSlider, FontFamily, TextSizes }) => ({
  titleInput: {
    width: "calc(100% - 60px)",
    maxHeight: 90,
    outline: "none",
    color: HorizontalSlider.inputColor,
    transition: "box-shadow .2s linear",
    overflow: "hidden",
    textOverflow: "ellipsis",
    font: {
      family: FontFamily,
      size: TextSizes.ImageTitle,
      weight: "bold",
      style: "normal",
      stretch: "normal",
    },
    lineHeight: 1,
    letterSpacing: "normal",
    "&:placeholder": {
      font: {
        family: FontFamily,
        size: TextSizes.ImageTitle,
      },
      color: HorizontalSlider.inputPlaceholderColor,
    },
    "&:empty:not(:focus):before": {
      color: HorizontalSlider.inputPlaceholderColor,
    },
    "&:focus": {
      borderBottom: `solid 2px ${HorizontalSlider.focusedInputBorderBottomColor}`,
    },
  },
  descriptionInput: {
    width: "calc(100% - 60px)",
    minHeight: 50,
    maxHeight: 100,
    outline: "none",
    color: HorizontalSlider.inputColor,
    transition: "box-shadow .2s linear",
    overflow: "hidden",
    textOverflow: "ellipsis",
    font: {
      family: FontFamily,
      size: TextSizes.ImageDescription,
      weight: "normal",
      style: "normal",
      stretch: "normal",
    },
    lineHeight: 1.33,
    letterSpacing: "normal",
    "&:placeholder": {
      font: {
        family: FontFamily,
        size: TextSizes.ImageDescription,
      },
      color: HorizontalSlider.inputPlaceholderColor,
    },
    "&:empty:not(:focus):before": {
      color: HorizontalSlider.inputPlaceholderColor,
    },
    "&:focus": {
      borderBottom: `solid 1px ${HorizontalSlider.focusedInputBorderBottomColor}`,
    },
  },
});
