import React, { useEffect, useState } from "react";
import Style from "./EditMasterTemplateLesson.style";
import withThemedStyle from "../../components/hoc/withThemedStyle";
import {
  Button,
  Icon,
  Icons,
  Tag,
  FullscreenLoader,
  Checkbox,
  ButtonSize,
} from "genius-ui";
import { FormattedMessage, useIntl } from "react-intl";
import { translations } from "./EditMasterTemplateLesson.translations";
import ContentEditable from "react-contenteditable";
import FilterLanguages from "../../components/FilterLanguages";
import { LANGUAGES } from "../../configs/referential";
import clientConfig, { TAG_TYPE } from "../../configs/client";
import SelectTagsByClusterId from "../../containers/Apollo/SelectTagsByClusterId";
import {
  formatOptionsForSave,
  formatOptionsForSelect,
  withMasterTag,
} from "../../utils/dataTransformation/tags";
import { connect, useDispatch } from "react-redux";
import compose from "lodash/fp/compose";
import ImageSelector from "../../components/common/ImageSelector";
import { CROP_VARIATION } from "../../configs/constants";
import MultiLineTextInput from "../../components/common/MultiLineTextInput";
import {
  GetLessonData,
  RemovePrivacy,
  ResetStore,
  ScheduleAvailability,
  SetPrivacy,
  UpdateEditLessonData,
  UpdateLessonInfo,
  UpdatePublishedLessonPageDataWithProgramQuizInfo,
  UpdateRecapStatus,
  UpdateTags,
  UpdateLessonPicCover,
} from "../../store/editLesson/actions";
import PublishDateHandler from "../../components/common/PublishDateHandler";
import TransparentButton from "../../components/common/TransparentButton";
import {
  SetSuccessMessage,
  SetWarningMessage,
} from "../../store/notification/actions";
import { cleanUpAndEncodeText } from "../../utils/string";

const EditMasterTemplateLesson = ({
  classes,
  history,
  isFetching,
  match: {
    params: { lessonId, lang },
  },
  ResetStore,
  GetLessonData,
  lessonInfo,
  UpdateLessonInfoInStore,
  UpdateLessonInfo,
  HandleChangeRecapStatus,
  UpdateIsProgramQuiz,
  UpdateTags,
  UpdateTitle,
  ScheduleAvailability,
  UpdateStartPublicationDate,
  UpdateEndPublicationDate,
  UpdateLessonPicCover,
}) => {
  useEffect(() => {
    GetLessonData(lessonId, lang);
    return ResetStore;
  }, []);
  const intl = useIntl();
  const dispatch = useDispatch();
  const [classNameError, setClassNameError] = useState(null);
  const goBack = () => history.goBack();

  const showRecap = !!lessonInfo.picLessonRecap || !!lessonInfo.lessonRecap;

  const handleClickSave = () => {
    if (lessonInfo.brands.length) ScheduleAvailability();
    else {
      dispatch(
        SetWarningMessage(
          intl.formatMessage(translations.IndicateBrandsWarning),
        ),
      );
      setClassNameError(classes.tagSelectContainerError);
    }
  };

  const updateTagsBrand = () => {
    if (lessonInfo.brands.length > 0) setClassNameError(null);
    return (selection) =>
      UpdateTags(formatOptionsForSave(selection), TAG_TYPE.BRAND);
  };

  return (
    <div className={classes.rootContainer}>
      {isFetching && <FullscreenLoader />}
      <div className={classes.center}>
        <div className={classes.left}>
          <FormattedMessage {...translations.TitlePlaceholder}>
            {(message) => (
              <ContentEditable
                html={lessonInfo.title}
                onChange={({ target }) =>
                  UpdateLessonInfoInStore("title", target.value)
                }
                onBlur={({ target }) => UpdateTitle(target.innerHTML)}
                className={classes.textInputTitle}
                placeholder={message}
                tagName="article"
              />
            )}
          </FormattedMessage>
          <br />
          <FormattedMessage {...translations.SummaryPlaceholder}>
            {(message) => (
              <ContentEditable
                html={lessonInfo.summary}
                onChange={({ target }) =>
                  UpdateLessonInfoInStore("summary", target.value)
                }
                onBlur={({ target }) =>
                  UpdateLessonInfo(
                    "summary",
                    cleanUpAndEncodeText(target.innerHTML),
                  )
                }
                className={classes.textInputSummary}
                placeholder={`${message}...`}
                tagName="article"
              />
            )}
          </FormattedMessage>
          <br />
          <div className={classes.detailsContainer}>
            <div className={classes.leftPart}>
              <ImageSelector
                width="306px"
                height="391px"
                sourceImage={lessonInfo.picCover}
                cropSize={CROP_VARIATION.PUBLISH_CARD}
                onImageChange={(url) => UpdateLessonPicCover("picCover", url)}
              />
            </div>
            <div className={classes.rightPart}>
              <div className={classes.langPublicContainer}>
                <span className={classes.firstTitle}>
                  <FormattedMessage {...translations.LanguageLabel} />
                </span>
              </div>
              <div className={classes.tagList}>
                <FilterLanguages languages={LANGUAGES}>
                  {(filteredLanguages) => {
                    const languages = [
                      filteredLanguages
                        .map((x) => ({
                          Id: x.Code,
                          Value: x.Name,
                        }))
                        .find((x) => x.Id === lang),
                    ];

                    return languages.map((x) => (
                      <Tag key={x.Value} label={x.Value} readOnly />
                    ));
                  }}
                </FilterLanguages>
              </div>
              <div className={classes.sectionTitle}>
                <FormattedMessage {...translations.BrandTagsTitle} />
              </div>
              <FormattedMessage {...translations.BrandPlaceholder}>
                {(message) => (
                  <SelectTagsByClusterId
                    className={classes.tagSelectContainer}
                    classNameError={classNameError}
                    clusterIds={clientConfig.BRAND_TAGS_CLUSTER_IDS}
                    selectedItems={formatOptionsForSelect(
                      lessonInfo[TAG_TYPE.BRAND],
                    )}
                    placeholder={`${message}...`}
                    onSelectionChanged={updateTagsBrand()}
                  />
                )}
              </FormattedMessage>
              <span className={classes.sectionTitle}>
                <FormattedMessage {...translations.TagsTitle} />
              </span>
              <FormattedMessage {...translations.TagsPlaceholder}>
                {(message) => (
                  <SelectTagsByClusterId
                    className={classes.tagSelectContainer}
                    clusterIds={clientConfig.EXPERTISE_CLUSTERIDS}
                    selectedItems={formatOptionsForSelect(
                      lessonInfo[TAG_TYPE.EXPERTISE],
                    )}
                    placeholder={`${message}...`}
                    onSelectionChanged={(selection) => {
                      UpdateTags(
                        formatOptionsForSave(withMasterTag(selection)),
                        TAG_TYPE.EXPERTISE,
                      );
                    }}
                  />
                )}
              </FormattedMessage>
              <div className={classes.recapCheckboxContainer}>
                <Checkbox
                  label={
                    <FormattedMessage {...translations.AddCustomRecapLabel} />
                  }
                  handleChange={HandleChangeRecapStatus}
                  checked={showRecap}
                />
              </div>
              {showRecap && (
                <div>
                  <div className={classes.sectionTitle}>
                    <FormattedMessage {...translations.WhatRememberLabel} />
                  </div>
                  <div className={classes.rememberContainer}>
                    <ImageSelector
                      width={CROP_VARIATION.LESSON_RECAP_PICTURE.width}
                      height={CROP_VARIATION.LESSON_RECAP_PICTURE.height}
                      sourceImage={lessonInfo.picLessonRecap}
                      cropSize={CROP_VARIATION.LESSON_RECAP_PICTURE}
                      onImageChange={(url) =>
                        UpdateLessonInfo("picLessonRecap", url)
                      }
                    />
                    <FormattedMessage {...translations.WhatRememberPlaceholder}>
                      {(message) => (
                        <MultiLineTextInput
                          label={message[0]}
                          value={lessonInfo.lessonRecap}
                          onChange={({ target }) =>
                            UpdateLessonInfoInStore("lessonRecap", target.value)
                          }
                          onBlur={({ target }) =>
                            UpdateLessonInfo("lessonRecap", target.value)
                          }
                        />
                      )}
                    </FormattedMessage>
                  </div>
                </div>
              )}
              {lessonInfo.showProgramQuizCheckbox && (
                <div className={classes.recapCheckboxContainer}>
                  <Checkbox
                    label={
                      <FormattedMessage
                        {...translations.ProgramQuizCheckboxLabel}
                      />
                    }
                    handleChange={(e) => UpdateIsProgramQuiz(e.target.checked)}
                    checked={lessonInfo.isProgramQuiz}
                  />
                </div>
              )}
              <PublishDateHandler
                startPostingDate={lessonInfo.startPublishDate}
                endPostingDate={lessonInfo.endPublishDate}
                updateStartPublicationDate={UpdateStartPublicationDate}
                updateEndPublicationDate={UpdateEndPublicationDate}
                startDateLabel={
                  <FormattedMessage {...translations.StartPostingDateLabel} />
                }
                endDateLabel={
                  <FormattedMessage {...translations.EndPostingDateLabel} />
                }
                noEndDateLabel={
                  <FormattedMessage {...translations.NoEndPostingDateLabel} />
                }
              />
              <div className={classes.buttons}>
                <Button
                  variation="primary"
                  size={ButtonSize.big}
                  handleClick={handleClickSave}
                >
                  <FormattedMessage {...translations.ScheduleButtonText} />
                </Button>
                <Button
                  variation="secondary"
                  size={ButtonSize.big}
                  handleClick={goBack}
                >
                  <FormattedMessage
                    {...translations.SaveWithoutScheduleButtonText}
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.right} onClick={goBack}>
          <Icon iconName={Icons.slimCloseIcon} style={classes.closeIcon} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.common.fetchIndicator,
  lessonInfo: state.editLesson.lessonInfo,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  GetLessonData: (id, lang) => dispatch(GetLessonData(id, lang)),
  ResetStore: () => dispatch(ResetStore()),
  UpdateLessonInfoInStore: (type, data) =>
    dispatch(UpdateEditLessonData({ [type]: data })),
  UpdateLessonInfo: (type, data) => {
    dispatch(UpdateEditLessonData({ [type]: data }));
    dispatch(UpdateLessonInfo(type, data));
  },
  UpdateLessonPicCover: (type, data) => {
    dispatch(UpdateEditLessonData({ [type]: data }));
    dispatch(UpdateLessonPicCover(data));
  },
  UpdatePrivacy: (isPublic) => {
    dispatch(isPublic ? RemovePrivacy() : SetPrivacy());
    dispatch(UpdateEditLessonData({ isPublic }));
  },
  HandleChangeRecapStatus: () => dispatch(UpdateRecapStatus()),
  UpdateIsProgramQuiz: (isProgramQuiz) => {
    dispatch(UpdateEditLessonData({ isProgramQuiz }));
    dispatch(UpdatePublishedLessonPageDataWithProgramQuizInfo());
  },
  UpdateTags: (tagList, tagType) => {
    dispatch(UpdateTags(tagList, tagType));
  },
  UpdateTitle: (title) => {
    dispatch(
      UpdateLessonInfo(
        "title",
        cleanUpAndEncodeText(title) || "NEW DRAFT LESSON",
      ),
    );
  },
  ScheduleAvailability: () =>
    dispatch(
      ScheduleAvailability(() => {
        dispatch(
          SetSuccessMessage(
            <FormattedMessage {...translations.SuccessfulScheduleMessage} />,
          ),
        );
        ownProps.history.goBack();
      }),
    ),
  UpdateStartPublicationDate: (startPublishDate) =>
    dispatch(UpdateEditLessonData({ startPublishDate })),
  UpdateEndPublicationDate: (endPublishDate) =>
    dispatch(UpdateEditLessonData({ endPublishDate })),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withThemedStyle(Style),
)(EditMasterTemplateLesson);
