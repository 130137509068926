import { FontFamily } from "../../../constants";

export default (theme) => ({
  rootContainer: {
    zIndex: 1,
    fontFamily: FontFamily,
    position: "relative",
    width: "100%",
    cursor: "pointer",
    backgroundColor: theme.PeriodSelector.rootContainer.backgroundColor,
    borderRadius: 5,
    border: `solid 1px ${theme.PeriodSelector.rootContainer.borderColor}`,
  },
  label: {
    height: 40,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 15px",
    fontSize: 13,
    color: theme.PeriodSelector.label.color,
  },
  calendarIcon: {
    marginRight: 8,
    color: theme.PeriodSelector.calendarIcon.color,
    fontSize: 18,
  },
  arrowIcon: {
    fontSize: 7,
    color: theme.PeriodSelector.arrowIcon.color,
  },
  leftSide: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  options: {
    "& > div": {
      padding: "17px 0 16px 24px",
      borderTop: `solid 1px ${theme.PeriodSelector.options.borderTopColor}`,
      "&:hover": {
        color: theme.PeriodSelector.options.color,
        backgroundColor: theme.PeriodSelector.options.backgroundColor,
      },
    },
  },
  optionsContainer: {
    overflow: "hidden",
    position: "absolute",
    top: 48,
    left: 0,
    width: "100%",
    borderRadius: 10,
    boxShadow: `0 10px 30px 0 ${theme.PeriodSelector.optionsContainer.boxShadowColor}`,
    backgroundColor: theme.PeriodSelector.optionsContainer.backgroundColor,
  },
  periodSelectors: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 15,
  },
  input: {
    width: 120,
    height: 31,
    borderRadius: 5,
    paddingLeft: 23,
    border: `solid 1px ${theme.PeriodSelector.input.borderColor}`,
    "&::placeholder": {
      color: theme.PeriodSelector.input.placeholderColor,
    },
    boxSizing: "border-box",
  },
  inputLabel: {
    marginBottom: 10,
  },
  calendar: {
    "& .react-datepicker-popper": {
      lineHeight: "normal",
      marginBottom: 0,
      marginTop: 0,
    },
    "& .react-datepicker-wrapper": {
      lineHeight: "normal",
    },
    "& .react-datepicker": {
      width: "100%",
      border: "none",
      borderTop: `solid 1px ${theme.PeriodSelector.calendar.react_datepicker.borderTopColor}`,
      borderRadius: 0,
      fontFamily: FontFamily,
    },
    "& .react-datepicker__triangle": {
      display: "none",
    },
    "& .react-datepicker__day": {
      margin: 5,
      width: 30,
      height: 30,
      fontSize: 13,
      color: theme.PeriodSelector.calendar.react_datepicker_day.color,
    },
    "& .react-datepicker__day--selected, .react-datepicker__day--range-end, .react-datepicker__day--range-start":
      {
        backgroundColor:
          theme.PeriodSelector.calendar.react_datepicker_day_selected
            .backgroundColor,
        color:
          theme.PeriodSelector.calendar.react_datepicker_day_selected.color,
      },
    "& .react-datepicker__day--in-range": {
      color: theme.PeriodSelector.calendar.react_datepicker_day_in_range.color,
    },
    "& .react-datepicker__header": {
      paddingTop: 0,
      backgroundColor:
        theme.PeriodSelector.calendar.react_datepicker_header.backgroundColor,
      borderBottom: "none",
    },
    "& .react-datepicker__day-name": {
      fontSize: 13,
      margin: 10,
      color: theme.PeriodSelector.calendar.react_datepicker_day_name.color,
    },
    "& .react-datepicker__day-names": {
      height: 35,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    "& .react-datepicker-time__caption": {
      display: "none !important",
    },
    "& .react-datepicker__navigation": {
      top: 16,
      outline: "none",
      color: theme.PeriodSelector.calendar.react_datepicker_navigation.color,
    },
    "& .react-datepicker__current-month": {
      height: 46,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 13,
      lineHeight: 1.29,
      letterSpacing: -0.41,
    },
    "& .react-datepicker__month-container": {
      width: "100%",
    },
  },
});
