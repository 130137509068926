import { LANGUAGES } from "../../configs/referential";
import { GetTagsForClusters } from "../../utils/dataTransformation/tags";
import clientConfig, { CONFIG_CLUSTER } from "../../configs/client";
import {
  BattleDocumentToBattleItem,
  BattleItemToBattleDocument,
} from "../editBattle/transformers";

const getTimePerQuestion = (data) => {
  const questions = BattleDocumentToBattleItem(data).questions;
  return questions.length
    ? {
        value: questions[0].timer,
        label: `${questions[0].timer}s`,
      }
    : null;
};

const getScorePerQuestion = (data) => {
  const questions = BattleDocumentToBattleItem(data).questions;
  return questions.length && questions[0].score ? questions[0].score : 0;
};

export const formatBattlePublishData = (data) => ({
  battleId: data.battleId,
  lang: data.lang,
  title: data.title ? data.title : "",
  summary: data.summary ? data.summary : "",
  picCover: data.picCover,
  language: LANGUAGES.find((x) => x.Code === data.lang).Name,
  startPostingDate: data.workflowScheduledIn,
  endPostingDate: data.workflowScheduledOut,
  countries: GetTagsForClusters(
    clientConfig[CONFIG_CLUSTER.COUNTRY],
    data.tags,
  ),
  retailers: GetTagsForClusters(
    clientConfig[CONFIG_CLUSTER.RETAILER],
    data.tags,
  ),
  brands: GetTagsForClusters(clientConfig[CONFIG_CLUSTER.BRAND], data.tags),
  timePerQuestion: getTimePerQuestion(data),
  pages: BattleDocumentToBattleItem(data),
  pageId: data.pages[0].pageId,
  scorePerQuestions: getScorePerQuestion(data),
  workflow: data.workflow,
});

export const getPageWithNewTimer = (pages, second) => {
  const questionsWithNewTimer = {
    ...pages,
    questions: pages.questions.map((question) => ({
      ...question,
      timer: second,
    })),
  };
  return BattleItemToBattleDocument(questionsWithNewTimer);
};

export const getPageWithNewScore = (pages, score) => {
  const questionsWithNewScore = {
    ...pages,
    questions: pages.questions.map((question) => ({
      ...question,
      score: score,
    })),
  };
  return BattleItemToBattleDocument(questionsWithNewScore);
};
