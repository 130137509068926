import { navigationBarWidth } from "../../components/Navigation/Navigation.style";

export default () => {
  return {
    rootContainer: {
      paddingLeft: navigationBarWidth,
    },
    docSettingsMenu: {
      width: 200,
      left: 475,
      position: "absolute",
      top: 107,
      zIndex: 2,
    },
    actionsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      maxWidth: 1000,
      alignItems: "center",
      padding: "80px 0 60px 0",
      margin: "auto",
    },
    filtersContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    marketSelector: {
      width: 180,
      textTransform: "capitalize",
      height: "38px !important",
      "& > div > div > div": {
        minHeight: "38px !important",
      },
    },
    languageSelector: {
      height: "38px !important",
      "& > div > div > div": {
        minHeight: "38px !important",
      },
    },
    createButtonContainer: {
      bottom: 35,
      right: 35,
      zIndex: 1,
    },
    pdfCards: {
      display: "flex",
      flexWrap: "wrap",
      width: 1000,
      margin: "auto",
      paddingTop: 30,
    },
    card: {
      display: "flex",
      position: "relative",
      width: 465,
      backgroundColor: "white",
      padding: 10,
      marginRight: 10,
      marginBottom: 10,
      height: 100,
      borderRadius: 5,
    },
    cover: {
      width: 100,
      height: 100,
    },
    cardMain: {
      paddingLeft: 20,
    },
    cardMainTop: {
      paddingBottom: 10,
    },
    cardBrand: {
      color: "#404040",
    },
    cardDate: {
      color: "grey",
    },
    cardMainBottom: {
      fontSize: 20,
    },
    cardRight: {
      display: "flex",
      width: 0,
    },
    dots: {
      position: "absolute",
      right: 0,
      bottom: 0,
      padding: 10,
      cursor: "pointer",
      color: "grey",
      fontSize: 5,
    },
  };
};
