import React, { useEffect } from "react";
import PropTypes from "prop-types";
import compose from "lodash/fp/compose";
import { mapTagToCheckDropdownOption } from "../../../utils/transformers";
import { CheckDropdown, Dropdown, Tag, TagVariation } from "genius-ui";
import { FormattedMessage, injectIntl } from "react-intl";
import DownloadButton from "../DownloadButton";
import uuIdV4 from "uuid/dist/v4";
import { connect } from "react-redux";
import style from "./FilterHeader.style";
import {
  UpdateFilterSelectedValues,
  RemoveFilterSelectedValue,
  ResetFilters,
  SetIsExportLoading,
} from "../../../store/kpiFilter/actions";
import { defaultFilterHeaderTranslations as translations } from "./FilterHeader.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(style);

const renderTags = (tags, removeFilteredTag) =>
  tags.map((tag) => (
    <Tag
      key={uuIdV4()}
      label={tag.label}
      variation={TagVariation.blue}
      handleRemoveClick={() => removeFilteredTag(tag)}
    />
  ));

const DefaultFilterHeader = (props) => {
  const {
    selectedPeriod,
    selectedZones,
    selectedMarkets,
    selectedRelationships,
    selectedRetailers,
    periods,
    relationships,
    markets,
    retailers,
    SetPeriodFilter,
    SetRelationshipFilter,
    SetMarketFilter,
    SetRetailerFilter,
    RemoveRelationshipFilteredTag,
    RemoveMarketFilteredTag,
    RemoveZoneFilteredTag,
    RemoveRetailerFilteredTag,
    ResetFilters,
    exportFunction,
    intl,
    SetIsDownloadLoading,
  } = props;
  const classes = useThemedStyle(useStyle, props);
  // Reset filters when the filter component mounts(when entering a KPI page)
  useEffect(() => {
    ResetFilters();
  }, []);

  const filterCounter =
    selectedZones.length +
    selectedMarkets.length +
    selectedRelationships.length +
    selectedRetailers.length;

  return (
    <div className={classes.headerContainer}>
      <div className={classes.header}>
        <div className={classes.filterContainer}>
          <div className={classes.leftSide}>
            <div className={classes.filters}>
              <CheckDropdown
                key="markets"
                onChangeHandler={(markets) => SetMarketFilter(markets)}
                options={markets}
                selectedValues={selectedMarkets}
                label={<FormattedMessage {...translations.MarketFilterLabel} />}
              />
              <CheckDropdown
                key="relationships"
                onChangeHandler={(relations) =>
                  SetRelationshipFilter(relations)
                }
                options={relationships}
                selectedValues={selectedRelationships}
                label={
                  <FormattedMessage {...translations.RelationshipFilterLabel} />
                }
              />
              <CheckDropdown
                key="retailers"
                onChangeHandler={(retailers) => SetRetailerFilter(retailers)}
                options={retailers}
                selectedValues={selectedRetailers}
                label={
                  <FormattedMessage {...translations.RetailerFilterLabel} />
                }
              />
            </div>
          </div>
          <div className={classes.rightSide}>
            <div className={classes.periodFilter}>
              <Dropdown
                options={periods}
                onChange={(period) => {
                  SetPeriodFilter(period);
                }}
                selectedOption={selectedPeriod}
              />
            </div>
            <DownloadButton
              onClickHandler={() => {
                SetIsDownloadLoading(true);
                exportFunction(
                  {
                    selectedMarkets,
                    selectedRelationships,
                    selectedRetailers,
                    selectedPeriod,
                  },
                  intl,
                );
                SetIsDownloadLoading(false);
              }}
            />
          </div>
        </div>
        <div className={classes.filterTags}>
          {renderTags(selectedZones, RemoveZoneFilteredTag)}
          {renderTags(selectedMarkets, RemoveMarketFilteredTag)}
          {renderTags(selectedRelationships, RemoveRelationshipFilteredTag)}
          {renderTags(selectedRetailers, RemoveRetailerFilteredTag)}
          {!!filterCounter && (
            <div className={classes.clearButton} onClick={() => ResetFilters()}>
              <FormattedMessage {...translations.ClearAllLabel} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

DefaultFilterHeader.propTypes = {
  /** function executed after any of the filters change **/
  onFilterChange: PropTypes.func,
};

const mapStateToProps = (state) => ({
  selectedPeriod: state.kpi.filters.period.selectedValues[0],
  selectedZones: state.kpi.filters.zone.selectedValues,
  selectedMarkets: state.kpi.filters.market.selectedValues,
  selectedRelationships: state.kpi.filters.relationship.selectedValues,
  selectedRetailers: state.kpi.filters.retailer.selectedValues,
  periods: state.kpi.filters.availablePeriods,
  relationships: state.tags.relationships.map(mapTagToCheckDropdownOption),
  markets: state.tags.markets.map(mapTagToCheckDropdownOption),
  retailers: state.tags.retailers.map(mapTagToCheckDropdownOption),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  SetPeriodFilter: (newPeriod) => {
    dispatch(UpdateFilterSelectedValues("period", [newPeriod]));
    ownProps.onFilterChange && ownProps.onFilterChange();
  },
  SetMarketFilter: (newMarkets) => {
    dispatch(UpdateFilterSelectedValues("market", newMarkets));
    ownProps.onFilterChange && ownProps.onFilterChange();
  },
  SetRelationshipFilter: (newRelations) => {
    dispatch(UpdateFilterSelectedValues("relationship", newRelations));
    ownProps.onFilterChange && ownProps.onFilterChange();
  },
  SetRetailerFilter: (newRetailers) => {
    dispatch(UpdateFilterSelectedValues("retailer", newRetailers));
    ownProps.onFilterChange && ownProps.onFilterChange();
  },
  RemoveZoneFilteredTag: (filteredTag) => {
    dispatch(RemoveFilterSelectedValue("zone", filteredTag));
    ownProps.onFilterChange && ownProps.onFilterChange();
  },
  RemoveMarketFilteredTag: (filteredTag) => {
    dispatch(RemoveFilterSelectedValue("market", filteredTag));
    ownProps.onFilterChange && ownProps.onFilterChange();
  },
  RemoveRelationshipFilteredTag: (filteredTag) => {
    dispatch(RemoveFilterSelectedValue("relationship", filteredTag));
    ownProps.onFilterChange && ownProps.onFilterChange();
  },
  RemoveRetailerFilteredTag: (filteredTag) => {
    dispatch(RemoveFilterSelectedValue("retailer", filteredTag));
    ownProps.onFilterChange && ownProps.onFilterChange();
  },
  ResetFilters: () => {
    dispatch(ResetFilters());
    ownProps.onFilterChange && ownProps.onFilterChange();
  },
  Export: () => {
    // TODO: implement export
  },
  SetIsDownloadLoading: (isLoading) => {
    dispatch(SetIsExportLoading(isLoading));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(DefaultFilterHeader);
