import gql from "graphql-tag";

export const LESSON_UPDATE_PAGE_DATA = gql`
  mutation lessonUpdatePageData(
    $lessonId: ID
    $lang: LangEnum
    $pageId: ID!
    $data: String!
  ) {
    admin {
      lesson(lessonId: $lessonId, lang: $lang) {
        pages(pageId: $pageId) {
          data(data: $data)
        }
      }
    }
  }
`;
