import gql from "graphql-tag";

export const USERS_DEFAULT = gql`
  fragment UserFields on User {
    tags {
      tagId
      title
      label
      clusterName
      clusterId
    }
    lessonPts
    userId
    status
    lastLogin
    totalLogins
    profile {
      other
      pic
      link
      firstName
      lastName
      picLink
      nickName
      localization
      gender
      position
      city
      phoneNumber
      age
      mail
      birthday
      lang
      posAddress
      seniorityDate
      pointOfSales
    }
    profileCustom {
      name
      description
      tags {
        tagId
        title
        label
        clusterName
        clusterId
        status
      }
      data
      badges {
        badgeId
        title
        pic
        status
        type
        description
        isUnlocked
        score
        original
        mention
        updateDate
      }
    }
    shortBio
    convRole
    creationDate
  }
`;
