import { defineMessages } from "react-intl";

export const translations = defineMessages({
  PublicationSettings: {
    id: "PublishLesson.PublicationSettings",
    defaultMessage: "Publication settings",
  },
  LanguageLabel: {
    id: "PublishLesson.LanguageLabel",
    defaultMessage: "Language",
  },
  BrandLabel_REST_OF_CLIENTS: {
    id: "PublishLesson.REST_OF_CLIENTS.BrandLabel",
    defaultMessage: "Brand",
  },
  BrandLabel_LOREAL: {
    id: "PublishLesson.LOREAL.BrandLabel",
    defaultMessage: "Entity Type",
  },
  RetailerLabel_REST_OF_CLIENTS: {
    id: "PublishLesson.REST_OF_CLIENTS.RetailerLabel",
    defaultMessage: "Retailer",
  },
  RetailerLabel_LOREAL: {
    id: "PublishLesson.LOREAL.RetailerLabel",
    defaultMessage: "Division",
  },
  EntityLabel: {
    id: "PublishLesson.EntityLabel",
    defaultMessage: "Entity",
  },
  RetailerLabel: {
    id: "PublishLesson.RetailerLabel",
    defaultMessage: "Retailer",
  },
  DivisionLabel: {
    id: "PublishLesson.DivisionLabel",
    defaultMessage: "Division",
  },
  PublicationCountriesLabel: {
    id: "PublishLesson.PublicationCountriesLabel",
    defaultMessage: "Countries",
  },
  PublicationZonesLabel: {
    id: "PublishLesson.PublicationZonesLabel",
    defaultMessage: "Zones",
  },
  ProfessionsLabel: {
    id: "PublishLesson.ProfessionsLabel",
    defaultMessage: "Profession",
  },
  AssociatedTagsLabel: {
    id: "PublishLesson.AssociatedTagsLabel",
    defaultMessage: "Associated Tags",
  },
  UserCategoryLabel: {
    id: "PublishLesson.UserCategoryLabel",
    defaultMessage: "User Category",
  },
  PublicButtonLabel: {
    id: "PublishLesson.PublicButtonLabel",
    defaultMessage: "Public",
  },
  ScheduleButtonText: {
    id: "PublishLesson.ScheduleButtonText",
    defaultMessage: "Schedule to publish",
  },
  CancelScheduleButtonText: {
    id: "PublishLesson.CancelScheduleButtonText",
    defaultMessage: "Cancel scheduling",
  },
  SaveWithoutScheduleButtonText: {
    id: "PublishLesson.SaveWithoutScheduleButtonText",
    defaultMessage: "Save without scheduling",
  },
  AddBrandPlaceholder_REST_OF_CLIENTS: {
    id: "PublishLesson.REST_OF_CLIENTS.AddBrandPlaceholder",
    defaultMessage: "Add a brand",
  },
  AddBrandPlaceholder_LOREAL: {
    id: "PublishLesson.LOREAL.AddBrandPlaceholder",
    defaultMessage: "Add an entity type",
  },
  AddRetailerPlaceholder_REST_OF_CLIENTS: {
    id: "PublishLesson.REST_OF_CLIENTS.AddRetailerPlaceholder",
    defaultMessage: "Add a retailer",
  },
  AddRetailerPlaceholder_LOREAL: {
    id: "PublishLesson.LOREAL.AddRetailerPlaceholder",
    defaultMessage: "Add a division",
  },
  AddEntityPlaceholder: {
    id: "PublishLesson.AddEntityPlaceholder",
    defaultMessage: "Add an entity type",
  },
  AddRetailerPlaceholder: {
    id: "PublishLesson.AddRetailerPlaceholder",
    defaultMessage: "Add a retailer",
  },
  AddDivisionPlaceholder: {
    id: "PublishLesson.AddDivisionPlaceholder",
    defaultMessage: "Add a division",
  },
  SelectAssociatedTagsPlaceholder: {
    id: "PublishLesson.SelectAssociatedTagsPlaceholder",
    defaultMessage: "Select associated tags",
  },
  SelectUserCategoriesPlaceholder: {
    id: "PublishLesson.SelectUserCategoriesPlaceholder",
    defaultMessage: "Select user categories",
  },
  SelectCountriesPlaceholder: {
    id: "PublishLesson.SelectCountriesPlaceholder",
    defaultMessage: "Select countries",
  },
  SelectZonesPlaceholder: {
    id: "PublishLesson.SelectZonesPlaceholder",
    defaultMessage: "Select zones",
  },
  SelectProfessionsPlaceholder: {
    id: "PublishLesson.SelectProfessionsPlaceholder",
    defaultMessage: "Select professions",
  },
  SuccessfulPublishedMessage: {
    id: "PublishLesson.SuccessfulPublishedMessage",
    defaultMessage: "Your lesson has been published",
  },
  TitlePlaceholder: {
    id: "PublishLesson.TitlePlaceholder",
    defaultMessage: "Enter title",
  },
  SummaryPlaceholder: {
    id: "PublishLesson.SummaryPlaceholder",
    defaultMessage: "Enter summary",
  },
  WhatRememberLabel: {
    id: "PublishLesson.WhatRememberLabel",
    defaultMessage: "What to Remember",
  },
  WhatRememberPlaceholder: {
    id: "PublishLesson.WhatRememberPlaceholder",
    defaultMessage: "Enter remarks",
  },
  AddCustomRecapLabel: {
    id: "PublishLesson.AddCustomRecapLabel",
    defaultMessage: "Add a custom recap",
  },
  ProgramQuizCheckboxLabel: {
    id: "PublishLesson.ProgramQuizCheckboxLabel",
    defaultMessage: "Set this lesson as Program Quiz",
  },
  CountrySelectorLabel: {
    id: "PublishLesson.CountrySelectorLabel",
    defaultMessage: "Publication Countries",
  },
  SelectCountryTagsPlaceholder: {
    id: "PublishLesson.SelectCountryTagsPlaceholder",
    defaultMessage: "Select publication countries",
  },
  IncorrectDateMessage: {
    id: "PublishLesson.IncorrectDateMessage",
    defaultMessage: "End posting date can't be before the start posting date",
  },
  StartPostingDateLabel: {
    id: "PublishLesson.StartPostingDateLabel",
    defaultMessage: "Start posting date",
  },
  EndPostingDateLabel: {
    id: "PublishLesson.EndPostingDateLabel",
    defaultMessage: "End posting date",
  },
  NoEndPostingDateLabel: {
    id: "PublishLesson.NoEndPostingDateLabel",
    defaultMessage: "No end posting date",
  },
  RoleLabel: {
    id: "PublishLesson.RoleLabel",
    defaultMessage: "Roles",
  },
  AddRolesPlaceholder: {
    id: "PublishLesson.AddRolesPlaceholder",
    defaultMessage: "Select roles",
  },
  BoutiqueLabel: {
    id: "PublishLesson.BoutiqueLabel",
    defaultMessage: "Location",
  },
  NoBoutiquesAddedText: {
    id: "PublishLesson.NoBoutiquesAddedText",
    defaultMessage: "No locations added",
  },
  SavedAttachmentMessage: {
    id: "PublishLesson.SavedAttachmentMessage",
    defaultMessage: "Attachment(s) successfully saved",
  },
  MissingLessonContentMessage: {
    id: "PublishLesson.MissingLessonContentMessage",
    defaultMessage: "You cannot publish a lesson without content",
  },
  IndicateBrandsWarning: {
    id: "PublishLesson.IndicateBrandsWarning",
    defaultMessage:
      "Please indicate which brand or brands this content should be associated with before publishing.",
  },
});
