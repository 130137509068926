import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { DefaultSlider1 } from "assets/icons";
import MarkerLayerReadOnly from "../../MarkerLayer/markerLayerReadOnly";
import { MarkerSlideStyle } from "../MarkerSlideHorizontal.style";
import { translations } from "../MarkerSlideHorizontal.translations";
import withThemedStyle from "../../../hoc/withThemedStyle";

const SLIDE_SIZE = {
  width: 414,
  height: 735,
};

const SAFE_AREA_SIZE = {
  width: 334,
  height: 560,
};

const SAFE_AREA_POSITION = {
  x: 40,
  y: 50,
};

const MAX_NR_OF_MARKERS_ON_SLIDE = 10;

class MarkerSlide extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      image: "",
      points: [],
    };
  }

  componentDidMount() {
    this.setState({
      type: this.props.type,
      image: this.props.image,
      points: this.props.points,
    });
  }

  render() {
    const props = this.props;
    const { slideId, classes, isSelected, points, onMarkerOpened } = props;

    return (
      <div className={classes.sliderContainer} onClick={props.onClick}>
        <MarkerLayerReadOnly
          uniqueId={slideId}
          isLayerActive={isSelected}
          layerSize={SLIDE_SIZE}
          safeAreaSize={SAFE_AREA_SIZE}
          safeAreaPosition={SAFE_AREA_POSITION}
          maxNrOfMarkers={MAX_NR_OF_MARKERS_ON_SLIDE}
          markers={points}
          onMarkerOpened={onMarkerOpened}
        />

        <span className={classes.partWillBeCropped}>
          <FormattedMessage {...translations.PartPossiblyCropped} />
        </span>
      </div>
    );
  }
}

MarkerSlide.propTypes = {
  /** The current selected image */
  image: PropTypes.string,
  /** Slide type **/
  type: PropTypes.string,
  /** Marker array **/
  points: PropTypes.array,
  /** Label for the chnage image selection button */
  buttonLabel: PropTypes.string,
  /** is selected */
  isSelected: PropTypes.bool,
  /** on click */
  onClick: PropTypes.func,
  /** default sliderImage */
  defaultSliderImage: PropTypes.string,
  /** function executed when a marker is opened or closed **/
  onMarkerOpened: PropTypes.func,
};

MarkerSlide.defaultProps = {
  /** unique id of the slide **/
  slideId: PropTypes.string.isRequired,
  defaultSliderImage: DefaultSlider1,
};

export default withThemedStyle(MarkerSlideStyle)(MarkerSlide);
