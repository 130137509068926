import { defineMessages } from "react-intl";

export const translations = defineMessages({
  Simple: {
    id: "VerticalSlideTemplates.Simple",
    defaultMessage: "Simple",
  },
  WithMarker: {
    id: "VerticalSlideTemplates.WithMarker",
    defaultMessage: "WithMarker",
  },
});
