import React from "react";
import PropTypes from "prop-types";
import { defaultStyles } from "./ResultsHeader.style";
import withThemedStyle from "../../../components/hoc/withThemedStyle";
import { Icon, Icons, Dropdown } from "genius-ui";
import DownloadButton from "../../../components/KPI/DownloadButton";
import PeriodSelector from "../../../components/KPI/PeriodSelector";
import { KPI_AVAILABLE_PERIODS } from "../../../configs/KPI/constants";

const ResultsHeader = (props) => {
  const {
    classes,
    onBack,
    exportHandler,
    selectedStartDate,
    selectedEndDate,
    selectedPeriod,
    selectStartDate,
    selectEndDate,
    selectPeriod,
    hideDateSelector,
    useIntervals,
    useIntervalsAndDateSelector,
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className="left">
          <Icon
            iconName={Icons.arrowLeft}
            style={classes.backButton}
            handleClick={onBack}
          />
        </div>
        <div className="right">
          {!useIntervals && !hideDateSelector && (
            <div className={classes.periodSelector}>
              <PeriodSelector
                options={useIntervalsAndDateSelector || KPI_AVAILABLE_PERIODS}
                selectOptionHandler={selectPeriod}
                selectStartDateHandler={selectStartDate}
                selectEndDateHandler={selectEndDate}
                selectedOption={selectedPeriod}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
              />
            </div>
          )}
          {useIntervals && !hideDateSelector && (
            <div className={classes.intervalSelector}>
              <Dropdown
                options={useIntervals}
                onChange={selectPeriod}
                selectedOption={selectedPeriod}
              />
            </div>
          )}
          <DownloadButton onClickHandler={exportHandler} />
        </div>
      </div>
    </div>
  );
};

ResultsHeader.propTypes = {
  onBack: PropTypes.func,
  exportHandler: PropTypes.func,
  selectedStartDate: PropTypes.string,
  selectedEndDate: PropTypes.string,
  selectedPeriod: PropTypes.object,
  selectStartDate: PropTypes.func,
  selectEndDate: PropTypes.func,
  selectPeriod: PropTypes.func,
  hideDateSelector: PropTypes.bool,
};

export default withThemedStyle(defaultStyles)(ResultsHeader);
