export const TitleStyle = ({ Colors, FontFamily, TextSizes }) => {
  const fontFamily = ({ fontFamily }) => fontFamily || FontFamily;

  return {
    input: {
      overflowWrap: "break-word",
      width: "415px",
      height: "100%",
      outline: "none",
      color: Colors.text.title,
      transition: "box-shadow .2s linear",
      fontFamily,
      fontSize: TextSizes.Title,
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "0.11px",
      "&:placeholder": {
        fontFamily,
        fontSize: TextSizes.Title,
        color: Colors.text.lighter,
      },
      "&:empty:not(:focus):before": {
        content: (props) => `"${props.placeholder}"`,
        color: Colors.text.placeholder,
      },
    },
  };
};
