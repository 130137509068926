import gql from "graphql-tag";

export const DEGREE_UPDATE_TITLE = gql`
  mutation degreeUpdateTitle($degreeId: ID, $lang: LangEnum, $data: String!) {
    admin {
      degree(degreeId: $degreeId, lang: $lang) {
        title(data: $data)
      }
    }
  }
`;
