import gql from "graphql-tag";
import { UserProfileKPIFragment } from "../fragments/userProfileKPI";

export const GET_USER_PROFILE_KPI_DATA = gql`
  query getUserProfileKPIData(
    $userId: Int
    $degreeId: [Int!]
    $userType: UserTypeTrainingEnum
    $date: String
  ) {
    userProfileKPI(
      userId: $userId
      degrees: $degreeId
      userType: $userType
      date: $date
    ) {
      ...UserProfileKPIFields
    }
  }
  ${UserProfileKPIFragment}
`;
