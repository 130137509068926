import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { translations } from "../../PublishInsight.translation";
import { FormattedMessage } from "react-intl";
import useThemedStyle from "../../../../hooks/style/useThemedStyle";
import { usePublishInsightStyle } from "../../PublishInsight.style";
import { useDispatch, useSelector } from "react-redux";
import {
  ScheduleInsightPublish,
  UpdatePublishInsight,
} from "../../../../store/insight/actions";
import { useCurrentInsightId } from "../../hooks/useCurrentInsightId";
import { useCurrentInsightLang } from "../../hooks/useCurrentInsightLang";
import PublishDateHandler from "../../../../components/common/PublishDateHandler";
import { Button } from "genius-ui";
import { Redirect } from "react-router-dom";
import { getPath } from "../../../../core/paths";
import { DATE_SELECTOR_TYPE } from "../../../../configs/constants";

const selectors = {
  startPostingDate: (state) => state.insights.publishInsight.startPostingDate,
  endPostingDate: (state) => state.insights.publishInsight.endPostingDate,
};

const EditInsightPublishDate = (props) => {
  const {
    isPublishDateHandlerDisabled,
    publishHandler,
    showCancelButton,
    checkBrands,
    alertBrands,
  } = props;
  const dispatch = useDispatch();
  const startPostingDate = useSelector(selectors.startPostingDate);
  const endPostingDate = useSelector(selectors.endPostingDate);
  const currentInsightId = useCurrentInsightId();
  const currentInsightLang = useCurrentInsightLang();
  const classes = useThemedStyle(usePublishInsightStyle, props);
  const [navigateBackToContentManager, setNavigateBackToContentManager] =
    useState(false);

  const UpdateStartPublicationDate = useCallback(
    (scheduledDate) =>
      dispatch(
        UpdatePublishInsight({
          startPostingDate: scheduledDate,
          dateSelectorType: DATE_SELECTOR_TYPE.SCHEDULE,
        }),
      ),
    [currentInsightId, currentInsightLang, dispatch],
  );

  const UpdateEndPublicationDate = useCallback(
    (scheduledDate) =>
      dispatch(
        UpdatePublishInsight({
          endPostingDate: scheduledDate,
          dateSelectorType: DATE_SELECTOR_TYPE.SCHEDULE,
        }),
      ),
    [currentInsightId, currentInsightLang, dispatch],
  );

  const handlePusblishInsight = () => {
    if (!checkBrands || checkBrands()) PublishInsight();
    else alertBrands();
  };

  const PublishInsight = useCallback(
    () =>
      publishHandler
        ? publishHandler()
        : dispatch(
            ScheduleInsightPublish(currentInsightId, currentInsightLang, () =>
              setNavigateBackToContentManager(true),
            ),
          ),
    [
      currentInsightId,
      currentInsightLang,
      setNavigateBackToContentManager,
      publishHandler,
      dispatch,
    ],
  );

  const CancelSchedule = useCallback(
    () => setNavigateBackToContentManager(true),
    [setNavigateBackToContentManager],
  );

  if (navigateBackToContentManager) {
    return <Redirect to={getPath("contentManager")} />;
  }

  return (
    <>
      <PublishDateHandler
        startPostingDate={startPostingDate}
        endPostingDate={endPostingDate}
        updateStartPublicationDate={UpdateStartPublicationDate}
        updateEndPublicationDate={UpdateEndPublicationDate}
        startDateLabel={
          <FormattedMessage {...translations.StartPostingDateLabel} />
        }
        endDateLabel={
          <FormattedMessage {...translations.EndPostingDateLabel} />
        }
        noEndDateLabel={
          <FormattedMessage {...translations.NoEndPostingDateLabel} />
        }
        disabled={isPublishDateHandlerDisabled}
      />
      <div className={classes.postingDate}>
        <div className={classes.postingDateButton}>
          <Button
            variation="primary"
            size="small"
            handleClick={handlePusblishInsight}
          >
            <FormattedMessage {...translations.ScheduleButtonText} />
          </Button>
        </div>
        {showCancelButton && (
          <div className={classes.postingDateButton}>
            <Button
              variation="secondary"
              size="small"
              handleClick={CancelSchedule}
              data-testid="edit-insight-publish-date-cancel-button"
            >
              <FormattedMessage
                {...translations.SaveWithoutScheduleButtonText}
              />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

EditInsightPublishDate.propTypes = {
  isPublishDateHandlerDisabled: PropTypes.bool,
  publishHandler: PropTypes.func,
  showCancelButton: PropTypes.bool,
};

EditInsightPublishDate.defaultProps = {
  isPublishDateHandlerDisabled: false,
  showCancelButton: true,
};

export default React.memo(EditInsightPublishDate);
