import * as constants from "../../constants/store/insights";
import client from "../../graphql/client";
import {
  GET_INSIGHT_BY_ID,
  GET_INSIGHTS_BY_STATUS,
} from "../../graphql/remote/insights/queries";
import clientConfig, {
  CONFIG_CLUSTER,
  isShiseido,
  TAG_TYPE,
} from "../../configs/client";
import { SetErrorMessage, SetSuccessMessage } from "../notification/actions";
import { cleanUpAndEncodeText, decodeNewLines } from "../../utils/string";
import { SetLoaderActive, SetLoaderInactive } from "../navigation/actions";
import { GetTagsForClusters } from "../../utils/dataTransformation/tags";
import { DOC_WORKFLOW_ENUM } from "../../configs/constants";
import {
  INSIGHT_ADD_TAGS,
  INSIGHT_REMOVE_PRIVACY,
  INSIGHT_REMOVE_TAGS,
  INSIGHT_SET_PRIVACY,
  INSIGHT_UPDATE_PIC_COVER,
  INSIGHT_UPDATE_PIC_THUMB,
  INSIGHT_UPDATE_SUMMARY,
  INSIGHT_UPDATE_TITLE,
  INSIGHT_UPDATE_VIDEO_COVER,
  INSIGHT_UPDATE_WORFLOW,
} from "../../graphql/remote/insights/mutations";
import { TAG_CREATE } from "../../graphql/remote/tags/mutations";
import {
  formatDateTimeForSave,
  isAfter,
  isBefore,
  parseISODate,
} from "../../utils/dateUtils";
import { FormattedMessage } from "react-intl";
import { translations } from "../../pages/publishInsight/PublishInsight.translation";
import React from "react";

export const SetCurrentLanguage = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_INSIGHT_LANGUAGE,
    data: data,
  });
};

export const SetCurrentPageId = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_INSIGHT_PAGEID,
    data: data,
  });
};

export const SetCurrentInsightId = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_INSIGHT_ID,
    data: data,
  });
};

export const SetCurrentInsightPages = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_INSIGHT_PAGES,
    data: data,
  });
};

export const SetPublishInsight = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_PUBLISH_INSIGHT_INFO,
    data: data,
  });
};

export const UpdatePublishInsight = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_PUBLISH_INSIGHT_INFO,
    data: data,
  });
};

export const ChangeTagSelectorVisibility = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_VISIBLE_TAG_SELECTOR,
    data: data,
  });
};

export const SetActiveMenuInsightId = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_ACTIVE_MENU_FOR_INSIGHT_ID,
    data: data,
  });
};

export const UpdateCurrentOpenedMarker = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_CURRENT_OPENED_MARKER,
    data: data,
  });
};

export const UpdateCurrentInsightPage = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_CURRENT_INSIGHT_PAGE,
    data: data,
  });
};

export const AddCurrentInsightPage = (data) => (dispatch) => {
  dispatch({
    type: constants.ADD_CURRENT_INSIGHT_PAGE,
    data: data,
  });
};

export const RemoveCurrentInsightPage = (data) => (dispatch) => {
  dispatch({
    type: constants.REMOVE_CURRENT_INSIGHT_PAGE,
    data: data,
  });
};

export const UpdateInsightData =
  (insightId, language, result) => async (dispatch) => {
    const insight = result.data.admin.insights[0].versions.find(
      (x) => x.lang === language,
    );
    if (insight) {
      const newTags = GetTagsForClusters(
        clientConfig[CONFIG_CLUSTER.EXPERTISE],
        insight.tags,
      );
      const newZones = GetTagsForClusters(
        clientConfig[CONFIG_CLUSTER.ZONE],
        insight.tags,
      );
      let newCountries = [];
      if (isShiseido) {
        newCountries = GetTagsForClusters(
          clientConfig[CONFIG_CLUSTER.COUNTRY],
          insight.tags,
        );
      } else {
        newCountries = GetTagsForClusters(
          newZones.map(({ Id }) => parseInt(Id)),
          insight.tags,
          true,
        );
      }
      const newUserCategories = GetTagsForClusters(
        clientConfig[CONFIG_CLUSTER.USER_CATEGORY],
        insight.tags,
      );
      const newBrands = GetTagsForClusters(
        clientConfig[CONFIG_CLUSTER.BRAND],
        insight.tags,
      );
      const newRetailers = GetTagsForClusters(
        clientConfig[CONFIG_CLUSTER.RETAILER],
        insight.tags,
      );
      const newEntities = GetTagsForClusters(
        clientConfig[CONFIG_CLUSTER.ENTITY],
        insight.tags,
      );
      const newDivisions = GetTagsForClusters(
        clientConfig[CONFIG_CLUSTER.DIVISION],
        insight.tags,
      );
      const newProfessions = GetTagsForClusters(
        clientConfig[CONFIG_CLUSTER.PROFESSION],
        insight.tags,
      );
      const newRoles = GetTagsForClusters(
        clientConfig[CONFIG_CLUSTER.ROLE],
        insight.tags,
      );

      const publishInsightInfo = {
        id: insightId,
        lang: language,
        languages: [],
        tags: newTags,
        predictTags: insight.predictTags,
        countries: newCountries,
        userCategories: newUserCategories,
        title: decodeNewLines(insight.title),
        summary: decodeNewLines(insight.summary),
        cover: insight.picCover,
        videoCover: insight.videoCover,
        picThumb: insight.picCard,
        isPublic: !insight.isPrivate,
        tagsIsEditable: true,
        brands: newBrands,
        retailers: newRetailers,
        zones: newZones,
        entities: newEntities,
        divisions: newDivisions,
        professions: newProfessions,
        roles: newRoles,
        startPostingDate: insight.workflowScheduledIn,
        endPostingDate: insight.workflowScheduledOut,
        isScreenReady: false,
        workflow: insight.workflow,
      };

      const pages = result.data.admin.insights[0].versions.find(
        (x) => x.lang === language,
      ).pages
        ? result.data.admin.insights[0].versions.find(
            (x) => x.lang === language,
          ).pages
        : [];

      dispatch(SetPublishInsight(publishInsightInfo));
      dispatch(SetCurrentInsightPages(pages));
    }
  };

export const GetInsightData =
  (insightId, language, callback) => async (dispatch) => {
    try {
      dispatch(SetLoaderActive());
      const result = await client.query({
        fetchPolicy: "network-only",
        query: GET_INSIGHT_BY_ID,
        variables: { insightId },
        notifyOnNetworkStatusChange: true,
      });

      dispatch(UpdateInsightData(insightId, language, result));

      if (
        result.data.admin.insights[0].versions
          .find((version) => version.lang === language)
          .tags.findIndex(
            (x) => parseInt(x.tagId) === clientConfig.MASTER_DEGREE_TAG_ID,
          ) >= 0
      ) {
        dispatch(SetInsightMasterFlag(true));
      } else {
        dispatch(SetInsightMasterFlag(false));
      }

      callback && callback(result);
    } catch (e) {
      dispatch(
        SetErrorMessage("An error occured while fetching the insight data"),
      );
    } finally {
      dispatch(SetLoaderInactive());
    }
  };

export const SetInsightMasterFlag = (data) => (dispatch) =>
  dispatch({
    type: constants.SET_INSIGHT_MASTER_FLAG,
    data,
  });

export const UpdateInsightWorkflow = () => async (dispatch, getState) => {
  const { publishInsight } = getState().insights;
  const { workflow, id, lang } = publishInsight;
  const status =
    workflow === DOC_WORKFLOW_ENUM.DRAFT
      ? DOC_WORKFLOW_ENUM.PUBLISHED
      : DOC_WORKFLOW_ENUM.DRAFT;

  try {
    await client.mutate({
      mutation: INSIGHT_UPDATE_WORFLOW,
      variables: {
        insightId: id,
        lang: lang,
        status: status,
      },
    });
    dispatch(SetPublishInsight({ ...publishInsight, workflow: status }));
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while updating the workflow of the insight ${id}`,
        e,
      ),
    );
  }
};

export const AddInsightTags =
  (insightId, lang, tagIdList, onUpdate) => async (dispatch) => {
    try {
      const result = await client.mutate({
        mutation: INSIGHT_ADD_TAGS,
        variables: {
          insightId: insightId,
          lang: lang,
          tagIdList: tagIdList,
        },
      });

      onUpdate && onUpdate(result.data.admin.insight.tags.add);
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while updating the tags of the insight ${insightId}`,
          e,
        ),
      );
    }
  };

export const SetInsightTitle = (insightId, lang, title) => async (dispatch) => {
  try {
    const data = cleanUpAndEncodeText(title);
    // TODO: optimistic ui update
    await client.mutate({
      mutation: INSIGHT_UPDATE_TITLE,
      variables: {
        insightId,
        lang,
        data,
      },
    });
    dispatch(UpdatePublishInsight({ title }));
  } catch (e) {
    dispatch(
      SetErrorMessage(
        `An error occurred while updating the title of the insight ${insightId}`,
        e,
      ),
    );
  }
};

export const SetInsightSummary =
  (insightId, lang, summary) => async (dispatch) => {
    try {
      const data = cleanUpAndEncodeText(summary);
      // TODO: optimistic ui update
      await client.mutate({
        mutation: INSIGHT_UPDATE_SUMMARY,
        variables: {
          insightId,
          lang,
          data,
        },
      });
      dispatch(UpdatePublishInsight({ summary }));
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while updating the summary of the insight ${insightId}`,
          e,
        ),
      );
    }
  };

export const SetInsightCoverImage =
  (insightId, lang, cover) => async (dispatch) => {
    try {
      const data = cover;
      // TODO: optimistic ui update
      await Promise.all([
        client.mutate({
          mutation: INSIGHT_UPDATE_PIC_COVER,
          variables: {
            insightId,
            lang,
            data,
          },
        }),
        client.mutate({
          mutation: INSIGHT_UPDATE_PIC_THUMB,
          variables: {
            insightId,
            lang,
            data,
          },
        }),
      ]);
      dispatch(
        UpdatePublishInsight({
          cover,
          picThumb: cover,
        }),
      );
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while updating the image cover of the insight ${insightId}`,
          e,
        ),
      );
    }
  };

export const SetInsightCoverVideo =
  (insightId, lang, videoCover) => async (dispatch) => {
    try {
      // TODO: optimistic ui update
      await client.mutate({
        mutation: INSIGHT_UPDATE_VIDEO_COVER,
        variables: {
          insightId,
          lang,
          data: videoCover,
        },
      });
      dispatch(UpdatePublishInsight({ videoCover }));
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while updating the video cover of the insight ${insightId}`,
          e,
        ),
      );
    }
  };

export const SetInsightPrivacy =
  (insightId, lang, isPublic) => async (dispatch) => {
    try {
      // TODO: optimistic ui update
      await client.mutate({
        mutation: isPublic ? INSIGHT_REMOVE_PRIVACY : INSIGHT_SET_PRIVACY,
        variables: {
          insightId,
          lang,
        },
      });
      dispatch(UpdatePublishInsight({ isPublic }));
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while updating the privacy of the insight ${insightId}`,
          e,
        ),
      );
    }
  };

const InsightAddTags = (insightId, lang, tagIdList) =>
  client.mutate({
    mutation: INSIGHT_ADD_TAGS,
    variables: { insightId, lang, tagIdList },
  });

const InsightRemoveTags = (insightId, lang, tagIdList) =>
  client.mutate({
    mutation: INSIGHT_REMOVE_TAGS,
    variables: { insightId, lang, tagIdList },
  });

export const UpdateInsightTags =
  (insightId, language, tagList, tagType) => async (dispatch, getState) => {
    try {
      const { publishInsight: publishData } = getState().insights;
      const beforeTagIds = publishData[tagType].map((x) => x.Id);
      const afterTagIds = tagList.map((x) => x.Id);
      let removeTagIds = beforeTagIds.filter(
        (beforeTagId) => !afterTagIds.includes(beforeTagId),
      );
      const newTagIds = afterTagIds.filter(
        (afterTagId) => !beforeTagIds.includes(afterTagId),
      );

      const updatedCountries = [];
      if (removeTagIds.length && tagType === TAG_TYPE.ZONE) {
        removeTagIds = removeTagIds.concat(
          // also remove countries belonging to removed zones
          publishData[TAG_TYPE.COUNTRY]
            .filter((countryTag) => {
              const countryIsRemoved = removeTagIds.includes(
                countryTag.originalTagData.parentTag.tagId,
              );
              if (!countryIsRemoved) updatedCountries.push(countryTag);

              return countryIsRemoved;
            })
            .map((x) => x.Id),
        );
      }

      if (publishData.tagsIsEditable) {
        const updateData = { [tagType]: tagList, tagsIsEditable: false };
        if (updatedCountries.length)
          updateData[TAG_TYPE.COUNTRY] = updatedCountries;
        dispatch(UpdatePublishInsight(updateData));
        const updateCallback = (success) => {
          const updateData = { ...publishData, tagsIsEditable: true };
          if (success) {
            // if update was successful keep selected tags, else roll back
            updateData[tagType] = tagList;
            if (updatedCountries.length)
              updateData[TAG_TYPE.COUNTRY] = updatedCountries;
          }
          dispatch(UpdatePublishInsight(updateData));
        };
        if (removeTagIds.length) {
          await InsightRemoveTags(insightId, language, removeTagIds);
        }
        if (newTagIds.length) {
          const result = await InsightAddTags(insightId, language, newTagIds);
          updateCallback(result.data.admin.insight.tags.add);
        } else {
          updateCallback(true);
        }
      }
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while updating the tags of the insight ${insightId}`,
          e,
        ),
      );
    }
  };

export const CreateNewInsightTag =
  (insightId, lang, tagName) => async (dispatch, getState) => {
    try {
      // TODO: optimistic ui update
      const result = await client.mutate({
        mutation: TAG_CREATE,
        variables: { tag: tagName },
      });
      const newTag = {
        Id: result.data.tag.createCustom.tagId,
        Value: result.data.tag.createCustom.title,
      };
      await InsightAddTags(insightId, lang, [newTag.Id]);
      const { tags } = getState().insights.publishInsight;
      dispatch(
        UpdatePublishInsight({
          tags: [...tags, newTag],
        }),
      );
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while creating a new tag for the insight ${insightId}`,
          e,
        ),
      );
    }
  };

export const ScheduleInsightPublish =
  (insightId, lang, onSuccess) => async (dispatch, getState) => {
    try {
      const insightInfo = getState().insights.publishInsight;
      const startDate = formatDateTimeForSave(insightInfo.startPostingDate);
      const endDate = insightInfo.endPostingDate
        ? formatDateTimeForSave(insightInfo.endPostingDate)
        : null;
      const workflow = isAfter(
        parseISODate(insightInfo.startPostingDate),
        new Date(),
      )
        ? DOC_WORKFLOW_ENUM.DRAFT
        : DOC_WORKFLOW_ENUM.PUBLISHED;

      if (
        endDate &&
        isBefore(
          parseISODate(insightInfo.endPostingDate),
          parseISODate(insightInfo.startPostingDate),
        )
      ) {
        dispatch(
          SetErrorMessage(
            <FormattedMessage {...translations.IncorrectDateMessage} />,
          ),
        );
      } else {
        await client.mutate({
          mutation: INSIGHT_UPDATE_WORFLOW,
          variables: {
            insightId,
            lang,
            status: workflow,
            dateIn: startDate,
            dateOut: endDate,
          },
          refetchQueries: () => [
            {
              query: GET_INSIGHTS_BY_STATUS,
              variables: { status: DOC_WORKFLOW_ENUM.DRAFT },
            },
            {
              query: GET_INSIGHTS_BY_STATUS,
              variables: { status: DOC_WORKFLOW_ENUM.PUBLISHED },
            },
          ],
        });

        dispatch(
          SetSuccessMessage(
            <FormattedMessage {...translations.SuccessfulPublishedMessage} />,
          ),
        );
        onSuccess && onSuccess();
      }
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while scheduling the publish of the insight ${insightId}`,
          e,
        ),
      );
    }
  };

export const ScheduleInsightPublishDiorVM =
  (insightId, lang, publicationSetting, onSuccess) =>
  async (dispatch, getState) => {
    try {
      const insightInfo = getState().insights.publishInsight;
      const startDate =
        publicationSetting !== DOC_WORKFLOW_ENUM.DRAFT
          ? formatDateTimeForSave(
              publicationSetting === DOC_WORKFLOW_ENUM.PUBLISHED
                ? new Date()
                : insightInfo.startPostingDate,
            )
          : null;
      const endDate =
        publicationSetting === DOC_WORKFLOW_ENUM.SCHEDULED &&
        insightInfo.endPostingDate
          ? formatDateTimeForSave(insightInfo.endPostingDate)
          : null;
      const workflow =
        publicationSetting === DOC_WORKFLOW_ENUM.PUBLISHED
          ? DOC_WORKFLOW_ENUM.PUBLISHED
          : DOC_WORKFLOW_ENUM.DRAFT;

      if (
        endDate &&
        isBefore(
          parseISODate(insightInfo.endPostingDate),
          parseISODate(insightInfo.startPostingDate),
        )
      ) {
        dispatch(
          SetErrorMessage(
            <FormattedMessage {...translations.IncorrectDateMessage} />,
          ),
        );
      } else {
        await client.mutate({
          mutation: INSIGHT_UPDATE_WORFLOW,
          variables: {
            insightId,
            lang,
            status: workflow,
            dateIn: startDate,
            dateOut: endDate,
          },
          refetchQueries: () => [
            {
              query: GET_INSIGHTS_BY_STATUS,
              variables: { status: DOC_WORKFLOW_ENUM.DRAFT },
            },
            {
              query: GET_INSIGHTS_BY_STATUS,
              variables: { status: DOC_WORKFLOW_ENUM.PUBLISHED },
            },
          ],
        });

        dispatch(
          SetSuccessMessage(
            <FormattedMessage {...translations.SuccessfulPublishedMessage} />,
          ),
        );
        onSuccess && onSuccess();
      }
    } catch (e) {
      dispatch(
        SetErrorMessage(
          `An error occurred while scheduling the publish of the insight ${insightId}`,
          e,
        ),
      );
    }
  };
