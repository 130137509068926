import gql from "graphql-tag";

export const CARD_ADD_TAGS = gql`
  mutation cardAddTags($cardId: ID, $lang: LangEnum, $tagIdList: [ID!]) {
    admin {
      card(cardId: $cardId, lang: $lang) {
        tags {
          add(tagIds: $tagIdList)
        }
      }
    }
  }
`;
