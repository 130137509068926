import { defineMessages } from "react-intl";

export const translations = defineMessages({
  DeleteYesOptionText: {
    id: "DeleteUserModal.DeleteYesOptionText",
    defaultMessage: "Yes",
  },
  DeleteNoOptionText: {
    id: "DeleteUserModal.DeleteNoOptionText",
    defaultMessage: "No",
  },
});
