import React from "react";
import { PeanutStyle } from "../Peanut.style";
import PropTypes from "prop-types";
import ContentEditable from "react-contenteditable";
import { DefaultSlider1 } from "assets/icons";
import { PEANUT_CARD_TYPES } from "../../../../configs/constants";
import { decodeNewLines } from "../../../../utils/string";
import withThemedStyle from "../../../hoc/withThemedStyle";

class Peanut extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: null,
      description: null,
      image: null,
      cardType: PEANUT_CARD_TYPES.WITHOUT_TEXT,
      legend: null,
    };
  }

  componentDidMount() {
    this.setState({
      title: decodeNewLines(this.props.title),
      description: decodeNewLines(this.props.description),
      image: this.props.image,
      legend: decodeNewLines(this.props.legend),
      cardType: this.props.cardType,
    });
  }

  render() {
    const props = this.props;
    const { classes } = props;
    const editorClass =
      this.state.cardType === PEANUT_CARD_TYPES.WITH_LEGEND
        ? classes.legendEditing
        : classes.textEditing;

    return (
      <div className={classes.sliderContainer}>
        <div className={classes.cardIndex}>
          <span>
            {props.cardIndex}/{props.allCardNumber}
          </span>
        </div>
        <div className={editorClass}>
          {this.state.cardType === PEANUT_CARD_TYPES.WITH_TEXT && (
            <>
              <ContentEditable
                key="title"
                html={this.state.title ? this.state.title : ""}
                disabled
                tagName="article"
                className={props.classes.titleInput}
              />
              <br />
              <ContentEditable
                key="description"
                html={this.state.description ? this.state.description : ""}
                disabled
                tagName="article"
                className={props.classes.descriptionInput}
              />
            </>
          )}
          {this.state.cardType === PEANUT_CARD_TYPES.WITH_LEGEND && (
            <ContentEditable
              key="legend"
              html={this.state.legend ? this.state.legend : ""}
              disabled
              tagName="article"
              className={props.classes.legendInput}
            />
          )}
        </div>
      </div>
    );
  }
}

Peanut.propTypes = {
  /** The current selected image */
  image: PropTypes.string,
  /** title value */
  title: PropTypes.string,
  /** description */
  description: PropTypes.string,
  /** Label for the chnage image selection button */
  buttonLabel: PropTypes.string,
  /** default sliderImage */
  defaultSliderImage: PropTypes.string,
  /** type of the peanut card */
  cardType: PropTypes.string.isRequired,
  /** legend */
  legend: PropTypes.string,
  /** all card number */
  allCardNumber: PropTypes.number.isRequired,
  /** card index */
  cardIndex: PropTypes.number.isRequired,
};

Peanut.defaultProps = {
  defaultSliderImage: DefaultSlider1,
};

export default withThemedStyle(PeanutStyle)(Peanut);
