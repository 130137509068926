import gql from "graphql-tag";

export const DEGREE_UPDATE_AUTHOR = gql`
  mutation degreeUpdateAuthor($degreeId: ID, $lang: LangEnum, $userId: ID!) {
    admin {
      degree(degreeId: $degreeId, lang: $lang) {
        author(userId: $userId) {
          userId
        }
      }
    }
  }
`;
