import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllNinjas } from "store/ninja/actions";
import { exportSelectedNinja } from "utils/excelExport/kpi/ninja";

export const useKpiNinja = () => {
  const ninjas = useSelector((state) => state.ninja.ninjas);

  const dispatch = useDispatch();
  const getPageData = useCallback(() => dispatch(GetAllNinjas()), [dispatch]);
  const exportNinjaXls = useCallback(
    (ninjaId, intl) => {
      dispatch(exportSelectedNinja(ninjaId, intl));
    },
    [dispatch],
  );

  return {
    ninjas,
    getPageData,
    exportNinjaXls,
  };
};
