import gql from "graphql-tag";

export const GET_TAGS_BY_CLUSTER_DEEPNESS = gql`
  query GetTagsByClusterDeepness($clusterDeepnesses: [ID!]) {
    tagClusters(clusterDeepnesses: $clusterDeepnesses) {
      clusterId
      clusterName
      clusterDeepness
      clusterMetaTagId
    }
  }
`;
