import React, { useEffect } from "react";
import { defaultStyles } from "./PollResults.style";
import withThemedStyle from "../../../components/hoc/withThemedStyle";
import Navigation from "../../../components/Navigation";
import ResultsHeader from "../resultsHeader";
import ResultDetailsCard from "../resultDetailsCard";
import PollResultTable from "./pollResultTable";
import { FormattedMessage, injectIntl } from "react-intl";
import { translations } from "./PollResults.translations";
import { connect } from "react-redux";
import compose from "lodash/fp/compose";
import {
  GetPollResults,
  ResetStore,
  UpdatePollInfo,
  UpdateSelectedPeriodPollResult,
} from "../../../store/pollResult/actions";
import { FullscreenLoader } from "genius-ui";
import { getPath } from "../../../core/paths";
import { SetFetchIndicator } from "../../../store/common/actions";
import { exportSpecifiedKpiPollData } from "../../../utils/excelExport/kpiPoll";
import { GetIntervals } from "../../../store/kpiFilter/actions";
import { isShiseido } from "../../../configs/client";

const PollResults = (props) => {
  const {
    match,
    classes,
    info,
    results,
    GetResults,
    ResetStore,
    isFetching,
    history,
    intl,
    UpdateSelectedPeriodPollResult,
    GetIntervals,
    intervals,
    UpdatePollInfoStartDate,
    UpdatePollInfoEndDate,
  } = props;

  useEffect(() => {
    GetIntervals();
    return () => ResetStore();
  }, []);

  useEffect(() => {
    if (info.selectedPeriod) {
      GetResults(match.params.pollId);
    }
  }, [info.selectedPeriod]);

  const goBack = () => history.push(getPath("contentManager"));

  return (
    <div className={classes.root}>
      {isFetching && <FullscreenLoader />}
      <Navigation />
      <ResultsHeader
        onBack={goBack}
        exportHandler={() => exportSpecifiedKpiPollData(results, info, intl)}
        selectedEndDate={info.selectedEndDate}
        selectedStartDate={info.selectedStartDate}
        selectedPeriod={info.selectedPeriod}
        selectStartDate={UpdatePollInfoStartDate}
        selectEndDate={UpdatePollInfoEndDate}
        selectPeriod={(period) => {
          UpdateSelectedPeriodPollResult(period);
          GetResults(match.params.pollId);
        }}
        useIntervalsAndDateSelector={intervals}
      />
      <div className={classes.content}>
        <div className={classes.shiseidoContainerPlaceHolder}>
          {isShiseido && (
            <div className={classes.shiseidoPlaceHolder}>
              <FormattedMessage {...translations.InformUsers} />
            </div>
          )}
        </div>
        <ResultDetailsCard
          photoUrl={info.photo}
          name={info.name}
          status={info.status}
          creationDate={info.creationDate}
          publicationDate={info.publicationDate}
          markets={info.markets}
          brands={info.brands}
        />
        <div className={classes.resultTableHeader}>
          <span className="resultTableTitle">
            <FormattedMessage {...translations.PollResultLabel} />
          </span>
          <span className="numberOfParticipant">
            <FormattedMessage {...translations.ParticipantsLabel} />:{" "}
            <span>{info.participants}</span>
          </span>
        </div>
        {!!results.length && <PollResultTable questions={results} />}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  GetResults: (pollId) => {
    dispatch(SetFetchIndicator(true));
    dispatch(GetPollResults(pollId, () => dispatch(SetFetchIndicator(false))));
  },
  UpdateSelectedPeriodPollResult: (option) => {
    dispatch(UpdateSelectedPeriodPollResult(option));
  },
  GetIntervals: () => {
    dispatch(GetIntervals());
  },
  UpdatePollInfoStartDate: (selectedStartDate) =>
    dispatch(UpdatePollInfo({ selectedStartDate })),
  UpdatePollInfoEndDate: (selectedEndDate) =>
    dispatch(UpdatePollInfo({ selectedEndDate })),
  ResetStore: () => dispatch(ResetStore()),
});

const mapStateToProps = (state) => ({
  info: state.pollResults.info,
  results: state.pollResults.results,
  isFetching: state.common.fetchIndicator,
  intervals: state.kpi.filters.intervals,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withThemedStyle(defaultStyles),
  injectIntl,
)(PollResults);
