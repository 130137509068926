import { useSelector } from "react-redux";

export const useKPIs = () => {
  const { filters } = useSelector((state) => state.kpi);

  return { filters };
};

export const useFeedKPIs = () => {
  const { allContent, isLoading } = useSelector(
    (state) => state.kpi.feedDetails,
  );

  return { allContent, isLoading };
};
