import clientConfig from "../configs/client";

export const FontFamily = "Roboto";
export const FontFamilyCondensed = "Roboto Condensed";
export const IconsFontFamily = "icomoon";
export const EditorFontFamily = clientConfig.CONTENT_EDITOR_FONT_NORMAL;
export const EditorFontFamilyBold = clientConfig.CONTENT_EDITOR_FONT_BOLD;

export const TextSizes = {
  Title: 32,
  SubTitle: 20,
  CardTitle: 18,
  CardDate: 14,
  Regular: 16,
  Quote: 5,
  ImageTitle: 44,
  PeanutImageTitle: 33,
  ImageDescription: 18,
};
