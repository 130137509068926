import gql from "graphql-tag";

export const COPY_BATTLE = gql`
  mutation battleCopy(
    $battleId: ID
    $sourceLang: LangEnum
    $destLang: LangEnum!
  ) {
    admin {
      battle(battleId: $battleId, lang: $sourceLang) {
        copy(lang: $destLang) {
          battleId
          versions {
            title
            lang
          }
        }
      }
    }
  }
`;
