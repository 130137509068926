import { FontFamily } from "../../constants";

export const MasterTemplateStyle = (props) => ({
  rootContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "calc(100% - 160px)",
    paddingLeft: 80,
    paddingRight: 80,
    fontFamily: FontFamily,
  },
  masterTemplatesContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 60,
    marginTop: 80,
    paddingLeft: 20,
  },
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 22,
  },
  createButtonContainer: {
    bottom: 35,
    right: 35,
    zIndex: 1,
  },
  cardsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    width: "100%",
    flexWrap: "wrap",
    "&::after": {
      padding: 20,
      flex: "auto",
      content: '""',
    },
  },
  cardContainer: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  vCardSettingsMenu: {
    width: 200,
    left: 185,
    position: "relative",
    top: -135,
    zIndex: 1,
  },
  navigationContainer: {
    zIndex: 1,
  },
});
