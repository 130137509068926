import * as constants from "../../constants/store/duplication";
import { RESET_STORE } from "../../constants/store/auth";

const initialState = {
  showModal: false,
  duplicationData: {
    availableLanguages: [],
    itemId: null,
    sourceLang: "",
    destLang: "",
  },
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.SET_SHOW_MODAL:
      return {
        ...state,
        showModal: data,
      };
    case constants.SET_DUPLICATION_DATA:
      return {
        ...state,
        duplicationData: data,
      };
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
