import { createXml } from "../excelFactory";
import { filterExportArray } from "./filter";
import { askTranslations as translations } from "./Kpi.translations";

export const exportAsk = (
  {
    questionsToGenius,
    questionsToExperts,
    answeredByExperts,
    pendingQuestionsToExperts,
    tagsInQuestionsToGenius,
    tagsInQuestionsToExperts,
  },
  filterData,
  intl,
) => {
  const data = filterExportArray(filterData, intl).concat(
    [
      [intl.formatMessage(translations.ASK)],
      [],
      [
        intl.formatMessage(translations.QuestionsAskedToGenius),
        questionsToGenius,
      ],
      [
        intl.formatMessage(translations.QuestionsAskedToExperts),
        questionsToExperts,
      ],
      [
        intl.formatMessage(translations.AnsweredQuestionsByExperts),
        answeredByExperts,
      ],
      [
        intl.formatMessage(translations.PendingQuestionsToExperts),
        pendingQuestionsToExperts,
      ],
      [],
      [],
      [intl.formatMessage(translations.TAGS)],
      [intl.formatMessage(translations.ToGenius)],
      [
        intl.formatMessage(translations.Name),
        intl.formatMessage(translations.Rank),
      ],
    ]
      .concat(
        tagsInQuestionsToGenius.map((element) => [
          element.content,
          element.rank,
        ]),
      )
      .concat([
        [],
        [],
        [intl.formatMessage(translations.ToExperts)],
        [
          intl.formatMessage(translations.Name),
          intl.formatMessage(translations.Rank),
        ],
      ])
      .concat(
        tagsInQuestionsToExperts.map((element) => [
          element.content,
          element.rank,
        ]),
      ),
  );

  createXml(data, "kpi-ask-export.xlsx");
};
