import { RESET_STORE } from "../../constants/store/auth";
import { UPDATE_PAGE_DATA } from "../../constants/store/kpiApplicationDetails";

const initialState = {
  activeUsers: {
    active: null,
    total: null,
    tendency: null,
    previousValue: null,
  },
  activeNumberOfConnections: {
    numberOfConnections: null,
    tendency: null,
    previousValue: null,
  },
  averageTimeSpentPerDay: {
    averageTimeMin: null,
    tendency: null,
    previousValue: null,
  },
  nrOfUsersChartData: {
    nrOfUsers: [],
  },
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case UPDATE_PAGE_DATA: {
      return {
        ...state,
        ...data,
      };
    }
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
