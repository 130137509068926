import * as constants from "../../constants/store/masterDocuments";
import { RESET_STORE } from "../../constants/store/auth";
import clientConfig from "../../configs/client";
import { LANGUAGES } from "../../configs/referential";

const initialState = {
  limit: 50,
  page: 0,
  documents: [],
  filters: {
    language: {
      value: clientConfig.DEFAULT_LANGUAGE_CODE,
      label: LANGUAGES.find(
        (x) => x.Code === clientConfig.DEFAULT_LANGUAGE_CODE,
      ).Name,
    },
    contentType: {
      value: undefined,
      label: undefined,
    },
  },
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.SET_MASTER_DOCUMENTS:
      return {
        ...state,
        documents: [...state.documents, ...data],
      };
    case constants.RESET_MASTER_DOCUMENTS:
      return {
        ...state,
        documents: data,
      };
    case constants.UPDATE_PAGE:
      return {
        ...state,
        page: state.page + 1,
      };
    case constants.RESET_MASTER_DOCUMENTS_STORE:
      return { ...initialState };
    case RESET_STORE: {
      return { ...initialState };
    }
    case constants.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [data.type]: data.data,
        },
        page: 0,
        documents: [],
      };
    default:
      return state;
  }
}
