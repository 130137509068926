import React, { useState } from "react";
import {
  WidgetContainer,
  KeyDetailWidget,
  QuantityWidget,
} from "../../components/KPI";
import { Tag, TagVariation, CheckDropdown } from "genius-ui";
import KPIPageContainer from "../../components/KPI/KPIPageContainer";
import WidgetSection from "../../components/KPI/WidgetSection";
import TagCloudWidget from "../../components/KPI/TagCloudWidget";
import MultiProgressWidget from "../../components/KPI/MultiProgressWidget";

const KPIComponentsPage = () => {
  const [checkValues, setCheckValues] = useState(null);

  const tags = [
    {
      id: "14",
      content: "tag number 14",
      rank: 4,
    },
    {
      id: "5",
      content: "tag number 05",
      rank: 13,
    },
    {
      id: "15",
      content: "tag number 15",
      rank: 3,
    },
    {
      id: "4",
      content: "tag number 04",
      rank: 14,
    },
    {
      id: "8",
      content: "tag number 08",
      rank: 10,
    },
    {
      id: "1",
      content: "tag number 01",
      rank: 17,
    },
    {
      id: "7",
      content: "tag number 07",
      rank: 11,
    },
    {
      id: "13",
      content: "tag number 13",
      rank: 5,
    },
    {
      id: "9",
      content: "tag number 09",
      rank: 9,
    },
    {
      id: "17",
      content: "tag number 17",
      rank: 1,
    },
    {
      id: "2",
      content: "tag number 02",
      rank: 16,
    },
    {
      id: "3",
      content: "tag number 03",
      rank: 15,
    },
    {
      id: "6",
      content: "tag number 06",
      rank: 12,
    },
    {
      id: "10",
      content: "tag number 10",
      rank: 8,
    },
    {
      id: "16",
      content: "tag number 16",
      rank: 2,
    },
    {
      id: "12",
      content: "tag number 12",
      rank: 6,
    },
    {
      id: "11",
      content: "tag number 11",
      rank: 7,
    },
  ];

  return (
    <KPIPageContainer>
      <WidgetSection width={100}>
        <WidgetContainer width={20}>
          <div>
            Widget container <br /> content
          </div>
        </WidgetContainer>
      </WidgetSection>
      <WidgetSection width={100}>
        <WidgetContainer width={33}>
          <KeyDetailWidget
            title="Average time spent on the app per day"
            primaryData="46.3min"
            secondaryData={48.4}
            percentData={8}
            lowPercent={false}
          />
        </WidgetContainer>
      </WidgetSection>
      <WidgetSection width={100}>
        <WidgetContainer width={25}>
          <QuantityWidget counter={1345} title="Programs" />
        </WidgetContainer>
      </WidgetSection>
      <WidgetSection width={100}>
        <WidgetContainer width={100}>
          <Tag
            label="Europe"
            variation={TagVariation.blue}
            handleRemoveClick={() => {}}
          />
          <Tag
            label="France"
            variation={TagVariation.blue}
            handleRemoveClick={() => {}}
          />
        </WidgetContainer>
      </WidgetSection>
      <WidgetSection>
        <WidgetContainer>
          <div style={{ width: 180 }}>
            <CheckDropdown
              onChangeHandler={(values) => setCheckValues(values)}
              options={[
                { value: "Paris", label: "Paris" },
                { value: "Bucharest", label: "Bucharest" },
                { value: "London", label: "London" },
                { value: "New York", label: "New York" },
                { value: "Targu Mures", label: "Targu Mures" },
              ]}
              selectedValues={checkValues}
              label="City"
            />
          </div>
        </WidgetContainer>
      </WidgetSection>
      <WidgetSection>
        <WidgetContainer width={50}>
          <TagCloudWidget
            title="Top TAGs used in Questions to Genius"
            tags={tags}
          />
        </WidgetContainer>
        <WidgetContainer width={50}>
          <TagCloudWidget
            title="Top TAGs used in Questions to Experts"
            tags={tags}
          />
        </WidgetContainer>
      </WidgetSection>
      <WidgetSection>
        <WidgetContainer width={50}>
          <MultiProgressWidget
            title="Most Popular Content"
            titleRight="hit ratio for content"
            progressItems={[
              {
                id: "1",
                name: "Content 1",
                progress: 50,
              },
              {
                id: "2",
                name: "Content 2",
                progress: 25,
              },
              {
                id: "3",
                name: "Content 3",
                progress: 35,
              },
              {
                id: "4",
                name: "Content 4",
                progress: 75,
              },
              {
                id: "5",
                name: "Content 5",
                progress: 75,
              },
            ]}
          />
        </WidgetContainer>
      </WidgetSection>
    </KPIPageContainer>
  );
};

export default KPIComponentsPage;
