import gql from "graphql-tag";

export const INSIGHT_SET_PRIVACY = gql`
  mutation insightSetPrivacy($insightId: ID, $lang: LangEnum) {
    admin {
      insight(insightId: $insightId, lang: $lang) {
        privacy {
          set
        }
      }
    }
  }
`;
