import React, { useEffect, useState } from "react";
import SideComponentBar from "../../components/SideComponentBar";
import PublishPreviewBanner from "../../components/PublishPreviewBanner";
import LessonContentHandler from "./LessonContentHandler";
import {
  CONTROL_TYPES,
  DOC_WORKFLOW_ENUM,
  PAGE_TYPES,
} from "../../configs/constants";
import { GetDesignPageEncodeData } from "../../utils/ui-generator/generator";
import { getPath } from "../../core/paths";
import { Notification } from "genius-ui";
import { FormattedMessage } from "react-intl";
import { translations as defaultTranslations } from "./LessonEditor.translations";
import "./LessonPageEditor.css";

const LessonPageEditor = (props) => {
  const { history, isMasterDocument, publishLessonData } = props;
  const { lessonId, lang, degreeId } = props.match.params;
  const [notificationIsOpen, setNotificationIsOpen] = useState(false);

  useEffect(() => {
    setNotificationIsOpen(
      publishLessonData.workflow === DOC_WORKFLOW_ENUM.PUBLISHED &&
        !isMasterDocument,
    );
  }, [publishLessonData]);

  const createArticle = (controlType, controlVariation = "") => {
    const { lessonId, lang } = props.match.params;
    const template = GetDesignPageEncodeData(
      [
        {
          ControlType: controlType,
          ControlValue: "",
          ControlVariation: controlVariation,
          Index: 0,
        },
      ],
      PAGE_TYPES.ARTICLE,
    );

    props.CreateLessonPage(
      lessonId,
      lang,
      template,
      PAGE_TYPES.ARTICLE,
      props.createLessonPageRequest,
    );
  };

  const navigateToPublishPage = () => {
    const pathName = isMasterDocument
      ? "editMasterTemplateLesson"
      : "publishLesson";
    history.push(getPath(pathName, degreeId, lessonId, lang));
  };

  return (
    <div>
      <Notification
        message={
          <FormattedMessage
            {...defaultTranslations.LessonAlreadyPublishedMessage}
          />
        }
        status="warning"
        isOpen={notificationIsOpen}
        handleClose={() => setNotificationIsOpen(false)}
      />
      <SideComponentBar
        handleBackClick={() =>
          props.history.push(`/degree/build/${degreeId}/${lang}`)
        }
        handleTextClick={(controlType) => createArticle(controlType)}
        handleImageClick={(controlVariation) =>
          createArticle(CONTROL_TYPES.IMAGE, controlVariation)
        }
        handleVideoClick={(controlVariation) =>
          createArticle(CONTROL_TYPES.VIDEO, controlVariation)
        }
        handleSliderClick={(template) =>
          props.CreateLessonPage(
            lessonId,
            lang,
            template,
            PAGE_TYPES.SLIDERFULLSCREEN,
            props.createLessonPageRequest,
          )
        }
        handleScrollImagesClick={(template) =>
          props.CreateLessonPage(
            lessonId,
            lang,
            template,
            PAGE_TYPES.FULLSCREENSCROLL,
            props.createLessonPageRequest,
          )
        }
        handleActivityClick={(template, pageType) =>
          props.CreateLessonPage(
            lessonId,
            lang,
            template,
            pageType,
            props.createLessonPageRequest,
          )
        }
      />
      <PublishPreviewBanner
        onPreviewButtonClick={() => {}}
        onPublishButtonClick={navigateToPublishPage}
      />
      <LessonContentHandler
        lessonId={lessonId}
        language={lang}
        activePageId={props.currentPageId}
        classes={props.classes}
        onDataChanged={(lessonId, lang, data, pageId, refresh = true) =>
          props.UpdateLessonPageData(
            lessonId,
            lang,
            data,
            pageId,
            props.updateLessonPageDataRequest,
            refresh,
          )
        }
        onNewPageCreated={(lessonId, lang, data, pageType) =>
          props.CreateLessonPage(
            lessonId,
            lang,
            data,
            pageType,
            props.createLessonPageRequest,
          )
        }
        onRemovePage={(lessonId, lang, pageId) =>
          props.RemoveLessonPage(lessonId, lang, pageId, props.removePage)
        }
        onLoad={props.UpdateLessonPublicationInfo}
        onPageChange={props.UpdateCurrentPageId}
        onOrderDown={(lessonId, lang, pageId) =>
          props.OnOrderDown(lessonId, lang, pageId, props.orderDownRequest)
        }
        onOrderUp={(lessonId, lang, pageId) =>
          props.OnOrderUp(lessonId, lang, pageId, props.orderUpRequest)
        }
        currentOpenedMarker={props.currentOpenedMarker}
        UpdateOpenedMarker={props.UpdateOpenedMarker}
        publishLessonData={props.publishLessonData}
        isLoaderActive={props.isLoaderActive}
      />
    </div>
  );
};

export default LessonPageEditor;
