import gql from "graphql-tag";

export const GET_CARDS_BY_STATUS = gql`
  query cardsFilterByStatus($status: DocWorkflowEnum) {
    admin {
      cards(workflow: $status) {
        cardId
        versions {
          title
          title
          langId
          author {
            userId
            status
            tags {
              tagId
              title
              pic
              description
              status
            }
          }
          tags {
            tagId
            pic
            title
            description
            status
          }
          workflow
          workflowId
          template
          card {
            pageId
            data
            type
            template
            lang
            activity
            index
          }
        }
      }
    }
  }
`;
