import React from "react";
import { PublishLessonStyle } from "../PublishLesson.style";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";
import {
  LESSON_UPDATE_TITLE,
  LESSON_UPDATE_SUMMARY,
  LESSON_UPDATE_PIC_COVER,
  LESSON_UPDATE_PIC_THUMB,
  LESSON_ADD_TAGS,
  LESSON_REMOVE_TAGS,
  LESSON_UPDATE_WORFLOW,
  LESSON_UPDATE_RECAP,
  LESSON_SET_PRIVACY,
  LESSON_REMOVE_PRIVACY,
  LESSON_UPDATE_PIC_CARD,
  LESSON_UPDATE_RECAP_PICTURE,
  DELETE_LESSON_RECAP,
  DELETE_LESSON_PIC_RECAP,
  LESSON_UPDATE_VIDEO_COVER,
} from "../../../graphql/remote/lessons/mutations";
import { connect } from "react-redux";
import {
  ChangeTagSelectorVisibility,
  SetCurrentLessonId,
  SetCurrentLanguage,
  SetCurrentDegreeId,
  UpdatePublishedLessonPageDataWithProgramQuizInfo,
} from "../../../store/degree/actions";
import {
  GetLessonData,
  RealUpdatePublishLessonData,
  UpdatePublishLessonData,
  SetPublishedLessonIsProgramQuiz,
} from "../../../store/lesson/actions";
import {
  Button,
  Icons,
  Icon,
  Tag,
  ButtonSize,
  Checkbox,
  FullscreenLoader,
} from "genius-ui";
import ImageSelector from "../../../components/common/ImageSelector";
import { withRouter } from "react-router";
import SelectTagsByClusterId from "../../../containers/Apollo/SelectTagsByClusterId";
import {
  CROP_VARIATION,
  DATE_SELECTOR_TYPE,
  DOC_WORKFLOW_ENUM,
} from "../../../configs/constants";
import clientConfig, {
  CONFIG_CLUSTER,
  isShiseido,
  REACT_APP_CLIENT_NAME,
} from "../../../configs/client";
import { SetCurrentTags } from "../../../store/referential/actions";
import {
  SetErrorMessage,
  SetSuccessMessage,
} from "../../../store/notification/actions";
import MultiLineTextInput from "../../../components/common/MultiLineTextInput";
import { getPath } from "../../../core/paths";
import SwitchButton from "../../../components/common/SwitchButton";
import ContentEditable from "react-contenteditable";
import { cleanUpAndEncodeText } from "../../../utils/string";
import { FormattedMessage, injectIntl } from "react-intl";
import { PlaceHolderDegree } from "assets/icons";
import { TAG_CREATE } from "../../../graphql/remote/tags/mutations";
import { GET_TAGS_BY_CLUSTER_ID } from "../../../graphql/remote/referential/queries";
import PublishDateHandler from "../../../components/common/PublishDateHandler";
import { translations } from "../PublishLesson.translation";
import {
  formatDateTimeForSave,
  isAfter,
  isBefore,
  parseISODate,
} from "../../../utils/dateUtils";
import FilterLanguages from "../../../components/FilterLanguages";
import FilterCountries from "../../../components/FilterCountries";
import withThemedStyle from "../../../components/hoc/withThemedStyle";
import { Permissions } from "../../../core/permissions/permissions";
import CanExecuteAction from "../../../core/permissions/canExecuteAction";
import UploadAttachmentToLesson from "../UploadAttachmentToLesson";
import { SetWarningMessage } from "../../../store/notification/actions";

const clientBasedTranslationNameSpace = {
  DIOR: "REST_OF_CLIENTS",
  "DIOR-PREPROD": "REST_OF_CLIENTS",
  "DIOR-PROD": "REST_OF_CLIENTS",
  "DIOR-VM": "REST_OF_CLIENTS",
  "DIOR-VM-PREPROD": "REST_OF_CLIENTS",
  "DIOR-VM-PROD": "REST_OF_CLIENTS",
  SHISEIDO: "REST_OF_CLIENTS",
  "SHISEIDO-PREPROD": "REST_OF_CLIENTS",
  "SHISEIDO-PROD": "REST_OF_CLIENTS",
  LOREAL: "LOREAL",
  "LOREAL-PROD": "LOREAL",
  VCA: "REST_OF_CLIENTS",
  "VCA-PROD": "REST_OF_CLIENTS",
  FENDI: "REST_OF_CLIENTS",
  PCD: "REST_OF_CLIENTS",
  PLURIS: "REST_OF_CLIENTS",
  MONTROSE: "REST_OF_CLIENTS",
  "MONTROSE-PROD": "REST_OF_CLIENTS",
};

const updateTitle = graphql(LESSON_UPDATE_TITLE, {
  props: ({ mutate }) => ({
    updateTitle: (lessonId, lang, data) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, data: data },
      }),
  }),
});

const updateSumarry = graphql(LESSON_UPDATE_SUMMARY, {
  props: ({ mutate }) => ({
    updateSummary: (lessonId, lang, data) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, data: data },
      }),
  }),
});

const updatePicCover = graphql(LESSON_UPDATE_PIC_COVER, {
  props: ({ mutate }) => ({
    updatePicCover: (lessonId, lang, data) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, data: data },
      }),
  }),
});

const updateVideoCover = graphql(LESSON_UPDATE_VIDEO_COVER, {
  props: ({ mutate }) => ({
    updateVideoCover: (lessonId, lang, data) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, data: data },
      }),
  }),
});

const updatePicCard = graphql(LESSON_UPDATE_PIC_CARD, {
  props: ({ mutate }) => ({
    updatePicCard: (lessonId, lang, data) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, data: data },
      }),
  }),
});

const updatePicThumb = graphql(LESSON_UPDATE_PIC_THUMB, {
  props: ({ mutate }) => ({
    updatePicThumb: (lessonId, lang, data) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, data: data },
      }),
  }),
});

const addTags = graphql(LESSON_ADD_TAGS, {
  props: ({ mutate }) => ({
    addTags: (lessonId, lang, tagIdList, onUpdate) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, tagIdList: tagIdList },
        update: (dataProxy, result) =>
          onUpdate(result.data.admin.lesson.tags.add),
      }),
  }),
});

const removeTags = graphql(LESSON_REMOVE_TAGS, {
  props: ({ mutate }) => ({
    removeTags: (lessonId, lang, tagIdList, onSuccess) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, tagIdList: tagIdList },
        update: () => onSuccess(),
      }),
  }),
});

const updateWorkflow = graphql(LESSON_UPDATE_WORFLOW, {
  props: ({ mutate }) => ({
    updateStatus: (lessonId, lang, status, dateIn, dateOut, onSuccess) =>
      mutate({
        variables: {
          lessonId: lessonId,
          lang: lang,
          status: status,
          dateIn: dateIn,
          dateOut: dateOut,
        },
        update: () => onSuccess(),
      }),
  }),
});

const updateRecap = graphql(LESSON_UPDATE_RECAP, {
  props: ({ mutate }) => ({
    updateRecap: (lessonId, lang, lessonRecap) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, data: lessonRecap },
      }),
  }),
});

const updateRecapPicture = graphql(LESSON_UPDATE_RECAP_PICTURE, {
  props: ({ mutate }) => ({
    updateRecapPicture: (lessonId, lang, picture) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, data: picture },
      }),
  }),
});

const deleteLessonPicRecap = graphql(DELETE_LESSON_PIC_RECAP, {
  props: ({ mutate }) => ({
    deleteLessonPicRecap: (lessonId, lang) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang },
      }),
  }),
});

const deleteLessonRecap = graphql(DELETE_LESSON_RECAP, {
  props: ({ mutate }) => ({
    deleteLessonRecap: (lessonId, lang) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang },
      }),
  }),
});

const setLessonPrivacy = graphql(LESSON_SET_PRIVACY, {
  props: ({ mutate }) => ({
    setPrivacy: (lessonId, lang, onSuccess) =>
      mutate({
        variables: { lessonId, lang },
        update: () => onSuccess(),
      }),
  }),
});

const removeLessonPrivacy = graphql(LESSON_REMOVE_PRIVACY, {
  props: ({ mutate }) => ({
    removePrivacy: (lessonId, lang, onSuccess) =>
      mutate({
        variables: { lessonId, lang },
        update: () => onSuccess(),
      }),
  }),
});

const createTag = graphql(TAG_CREATE, {
  props: ({ mutate }) => ({
    createTag: (tag, onSuccess) =>
      mutate({
        variables: { tag },
        update: (cache, data) => onSuccess(data),
        refetchQueries: (_fetchResult) => [
          {
            query: GET_TAGS_BY_CLUSTER_ID,
            variables: { clusterIds: clientConfig.EXPERTISE_CLUSTERIDS },
          },
        ],
      }),
  }),
});

class PublishLesson extends React.Component {
  state = {
    classNameError: null,
  };

  componentDidMount() {
    const { lessonId, lang, degreeId } = this.props.match.params;
    this.props.GetLessonData(lessonId, lang);
    this.props.UpdateCurrentLessonId(lessonId);
    this.props.SetCurrentLanguage(lang);
    this.props.UpdateCurrentDegreeId(degreeId);
  }

  componentWillUnmount() {
    this.props.SetIsScreenReady(false);
  }

  formatOptionsForSelect = (options) =>
    options
      ? options.map((option) => ({
          value: option.Id,
          label: option.Value,
        }))
      : [];

  formatOptionsForSave = (options) =>
    options
      ? options.map((option) => ({
          Id: option.value,
          Value: option.label,
        }))
      : [];

  updateTags = (tagType, handlers, extraTags = []) => {
    if (tagType === "brands" && this.props.publishLessonData.brands.length > 0)
      this.state.classNameError = null;
    return (tags) =>
      this.props.UpdateTags(
        extraTags.concat(this.formatOptionsForSave(tags)),
        { ...this.props.publishLessonData },
        this.props.currentLessonId,
        this.props.currentLanguage,
        handlers,
        tagType,
      );
  };

  createNewTag = (handlers) => (inputValue) =>
    this.props.CreateNewTag(
      inputValue,
      handlers,
      this.props.publishLessonData,
      this.props.currentLessonId,
      this.props.currentLanguage,
    );

  handleClickSave = (handlers, classes) => {
    if (this.props.publishLessonData.brands.length) {
      this.props.PublishLesson(
        this.props.currentLessonId,
        this.props.currentLanguage,
        this.props.publishLessonData,
        this.props.currentDegreeId,
        handlers,
      );
    } else {
      this.props.WarningAlert(
        this.props.intl.formatMessage(translations.IndicateBrandsWarning),
      );
      this.setState({ classNameError: classes.tagSelectContainerError });
    }
  };

  render() {
    const { classes, publishLessonData, UpdateIsProgramQuiz } = this.props;
    const props = this.props;
    let handlers = {
      updateTitle: props.updateTitle,
      updateSummary: props.updateSummary,
      updatePicCover: props.updatePicCover,
      updateVideoCover: props.updateVideoCover,
      updatePicThumb: props.updatePicThumb,
      addTags: props.addTags,
      removeTags: props.removeTags,
      updateStatus: props.updateStatus,
      updateRecap: props.updateRecap,
      setPrivacy: props.setPrivacy,
      removePrivacy: props.removePrivacy,
      updatePicCard: props.updatePicCard,
      updateRecapPicture: props.updateRecapPicture,
      createTag: props.createTag,
      deleteLessonPicRecap: props.deleteLessonPicRecap,
      deleteLessonRecap: props.deleteLessonRecap,
    };

    let languagesAndCountries = [...props.publishLessonData.languages];

    const showRecap =
      !!publishLessonData.picLessonRecap || !!publishLessonData.lessonRecap;

    if (this.props.isLoaderActive || !props.isScreenReady) {
      return <FullscreenLoader />;
    }

    return (
      <div className={classes.rootContainer}>
        <div className={classes.center}>
          <div className={classes.left}>
            <FormattedMessage {...translations.TitlePlaceholder}>
              {(message) => (
                <ContentEditable
                  html={
                    props.publishLessonData.title
                      ? props.publishLessonData.title
                      : ""
                  }
                  onChange={({ target }) =>
                    props.UpdateLessonTitle(
                      target.value,
                      { ...props.publishLessonData },
                      handlers,
                    )
                  }
                  className={classes.textInputTitle}
                  placeholder={message[0]}
                  tagName="article"
                />
              )}
            </FormattedMessage>
            <br />
            <FormattedMessage {...translations.SummaryPlaceholder}>
              {(message) => (
                <ContentEditable
                  html={
                    props.publishLessonData.summary
                      ? props.publishLessonData.summary
                      : ""
                  }
                  onChange={({ target }) =>
                    props.UpdateLessonSummary(
                      target.value,
                      { ...props.publishLessonData },
                      handlers,
                    )
                  }
                  className={classes.textInputSummary}
                  placeholder={message[0]}
                  tagName="article"
                />
              )}
            </FormattedMessage>
            <div className={classes.detailsContainer}>
              <div className={classes.leftPart}>
                <ImageSelector
                  id="image"
                  uniqueId="publishLessonImage"
                  buttonLabel="Change Cover"
                  width="306px"
                  height="391px"
                  sourceImage={props.publishLessonData.cover}
                  cropSize={CROP_VARIATION.PUBLISH_CARD}
                  onImageChange={(imageUrl, _imageId) => {
                    props.UpdatePicCover(
                      imageUrl,
                      { ...props.publishLessonData },
                      handlers,
                    );
                  }}
                />
              </div>
              <div className={classes.rightPart}>
                <div className={classes.langPublicContainer}>
                  <span className={classes.firstTitle}>
                    <FormattedMessage {...translations.LanguageLabel} />
                  </span>
                  <CanExecuteAction
                    permissionName={
                      Permissions.PAGE_PUBLISH_LESSON.SET_PRIVACY_BUTTON
                    }
                  >
                    <div className={classes.publicSwitcherContainer}>
                      <div className={classes.publicLabel}>
                        <FormattedMessage {...translations.PublicButtonLabel} />
                      </div>
                      <SwitchButton
                        isOn={props.publishLessonData.isPublic}
                        handleToggle={(isPublic) =>
                          props.UpdatePrivacy(
                            props.currentLessonId,
                            props.currentLanguage,
                            props.publishLessonData,
                            isPublic,
                            handlers,
                          )
                        }
                      />
                    </div>
                  </CanExecuteAction>
                </div>
                {
                  <div className={classes.tagList}>
                    <FilterLanguages
                      languages={languagesAndCountries.map((lang) => ({
                        ...lang,
                        Code: lang.Id,
                      }))}
                    >
                      {(filteredLanguages) =>
                        filteredLanguages.map((x) => (
                          <Tag
                            key={x.Value}
                            label={x.Value}
                            readOnly={x.readOnly}
                            handleRemoveClick={() =>
                              props.RemoveTag(
                                props.publishLessonData,
                                x.Id,
                                "countries",
                                handlers,
                              )
                            }
                          />
                        ))
                      }
                    </FilterLanguages>
                  </div>
                }
                <div className={classes.sectionTitle}>
                  <FormattedMessage {...translations.CountrySelectorLabel} />
                </div>

                <div className={classes.tagList}>
                  <FilterCountries
                    countries={props.publishLessonData.countries}
                  >
                    {({ filteredCountries }) =>
                      filteredCountries.map((x) => (
                        <Tag key={x.Value} label={x.Value} readOnly={true} />
                      ))
                    }
                  </FilterCountries>
                </div>
                <div className={classes.brandAndRetailer}>
                  <div className={classes.brandTagsContainer}>
                    <div className={classes.sectionTitle}>
                      <FormattedMessage
                        {...translations[
                          `BrandLabel_${clientBasedTranslationNameSpace[REACT_APP_CLIENT_NAME]}`
                        ]}
                      />
                    </div>
                    <FormattedMessage
                      {...translations[
                        `AddBrandPlaceholder_${clientBasedTranslationNameSpace[REACT_APP_CLIENT_NAME]}`
                      ]}
                    >
                      {(message) => (
                        <SelectTagsByClusterId
                          tags={this.formatOptionsForSelect(props.brands)}
                          saveTagsToState={props.SaveTagsToState}
                          className={classes.tagSelectContainer}
                          classNameError={this.state.classNameError}
                          id="brand"
                          clusterIds={clientConfig.BRAND_TAGS_CLUSTER_IDS}
                          tagType={CONFIG_CLUSTER.BRAND}
                          selectedItems={this.formatOptionsForSelect(
                            props.publishLessonData.brands,
                          )}
                          placeholder={message[0]}
                          onSelectionChanged={this.updateTags(
                            "brands",
                            handlers,
                          )}
                          mustHaveAtLeastOneChoice
                        />
                      )}
                    </FormattedMessage>
                  </div>
                  <div className={classes.retailerTagsContainer}>
                    <div className={classes.sectionTitle}>
                      <FormattedMessage
                        {...translations[
                          `RetailerLabel_${clientBasedTranslationNameSpace[REACT_APP_CLIENT_NAME]}`
                        ]}
                      />
                    </div>
                    <FormattedMessage
                      {...translations[
                        `AddRetailerPlaceholder_${clientBasedTranslationNameSpace[REACT_APP_CLIENT_NAME]}`
                      ]}
                    >
                      {(message) => (
                        <SelectTagsByClusterId
                          tags={this.formatOptionsForSelect(props.retailers)}
                          saveTagsToState={props.SaveTagsToState}
                          className={classes.tagSelectContainer}
                          id="retailer"
                          clusterMetaTag={
                            props.publishLessonData.countries &&
                            props.publishLessonData.countries[0]?.Id
                          }
                          tagType={CONFIG_CLUSTER.RETAILER}
                          selectedItems={this.formatOptionsForSelect(
                            props.publishLessonData.retailers,
                          )}
                          placeholder={message[0]}
                          onSelectionChanged={this.updateTags(
                            "retailers",
                            handlers,
                          )}
                        />
                      )}
                    </FormattedMessage>
                  </div>
                </div>
                <div className={classes.sectionTitle}>
                  <FormattedMessage {...translations.AssociatedTagsLabel} />
                </div>
                <FormattedMessage
                  {...translations.SelectAssociatedTagsPlaceholder}
                >
                  {(message) => (
                    <SelectTagsByClusterId
                      isEditable
                      tags={this.formatOptionsForSelect(props.tags)}
                      saveTagsToState={props.SaveTagsToState}
                      className={classes.tagSelectContainer}
                      id="expertise"
                      clusterIds={clientConfig.EXPERTISE_CLUSTERIDS}
                      tagType={CONFIG_CLUSTER.EXPERTISE}
                      selectedItems={this.formatOptionsForSelect(
                        props.publishLessonData.tags,
                      )}
                      placeholder={message[0]}
                      onSelectionChanged={this.updateTags("tags", handlers)}
                      onCreateOption={this.createNewTag(handlers)}
                      fetchSuggestedTags={() =>
                        props.publishLessonData.predictTags
                      }
                    />
                  )}
                </FormattedMessage>
                <div className={classes.recapCheckboxContainer}>
                  <Checkbox
                    label={
                      <FormattedMessage {...translations.AddCustomRecapLabel} />
                    }
                    handleChange={props.handleChangeRecapStatus(
                      props.currentLessonId,
                      props.currentLanguage,
                      handlers,
                      publishLessonData,
                    )}
                    checked={showRecap}
                  />
                </div>
                {showRecap && (
                  <>
                    <div className={classes.sectionTitle}>
                      <FormattedMessage {...translations.WhatRememberLabel} />
                    </div>
                    <div className={classes.rememberContainer}>
                      <ImageSelector
                        id="image"
                        buttonLabel="Change Cover"
                        width={CROP_VARIATION.LESSON_RECAP_PICTURE.width}
                        height={CROP_VARIATION.LESSON_RECAP_PICTURE.height}
                        sourceImage={props.publishLessonData.picLessonRecap}
                        cropSize={CROP_VARIATION.LESSON_RECAP_PICTURE}
                        onImageChange={(imageUrl) =>
                          props.UpdateRecapPic(
                            props.currentLessonId,
                            props.currentLanguage,
                            handlers,
                            publishLessonData,
                            imageUrl,
                          )
                        }
                      />
                      <FormattedMessage
                        {...translations.WhatRememberPlaceholder}
                      >
                        {(message) => (
                          <MultiLineTextInput
                            label={message[0]}
                            value={props.publishLessonData.lessonRecap}
                            onChange={(e) =>
                              props.SaveRecapToState(e.target.value, {
                                ...props.publishLessonData,
                              })
                            }
                            onBlur={(el) =>
                              props.UpdateRecap(
                                props.currentLessonId,
                                props.currentLanguage,
                                handlers,
                                publishLessonData,
                                el.target.value,
                              )
                            }
                          />
                        )}
                      </FormattedMessage>
                    </div>
                  </>
                )}
                {publishLessonData.showProgramQuizCheckbox && (
                  <div className={classes.recapCheckboxContainer}>
                    <Checkbox
                      label={
                        <FormattedMessage
                          {...translations.ProgramQuizCheckboxLabel}
                        />
                      }
                      handleChange={(e) =>
                        UpdateIsProgramQuiz(e.target.checked)
                      }
                      checked={publishLessonData.isProgramQuiz}
                    />
                  </div>
                )}
                <UploadAttachmentToLesson
                  containerClassName={classes.attachments}
                />
                <PublishDateHandler
                  startPostingDate={props.publishLessonData.startPostingDate}
                  endPostingDate={props.publishLessonData.endPostingDate}
                  updateStartPublicationDate={(date) =>
                    props.UpdateStartPublicationDate(
                      { ...props.publishLessonData },
                      date,
                    )
                  }
                  updateEndPublicationDate={(date) =>
                    props.UpdateEndPublicationDate(
                      { ...props.publishLessonData },
                      date,
                    )
                  }
                  startDateLabel={
                    <FormattedMessage {...translations.StartPostingDateLabel} />
                  }
                  endDateLabel={
                    <FormattedMessage {...translations.EndPostingDateLabel} />
                  }
                  noEndDateLabel={
                    <FormattedMessage {...translations.NoEndPostingDateLabel} />
                  }
                />
                <div className={classes.postingDate}>
                  <div className={classes.postingDateButton}>
                    <Button
                      variation="primary"
                      size={ButtonSize.big}
                      handleClick={() =>
                        this.handleClickSave(handlers, classes)
                      }
                    >
                      <FormattedMessage {...translations.ScheduleButtonText} />
                    </Button>
                  </div>
                  <div className={classes.postingDateButton}>
                    <Button
                      variation="secondary"
                      size={ButtonSize.big}
                      handleClick={() =>
                        props.history.push(
                          getPath(
                            "degreeBuild",
                            props.currentDegreeId,
                            props.currentLanguage,
                          ),
                        )
                      }
                    >
                      <FormattedMessage
                        {...translations.SaveWithoutScheduleButtonText}
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Icon
            handleClick={() => props.history.goBack()}
            iconName={Icons.slimCloseIcon}
            style={classes.closeIcon}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  currentDegreeId: state.degrees.currentDegreeId,
  currentLessonId: state.degrees.currentLessonId,
  currentPageId: state.degrees.currentPageId,
  publishLessonData: state.lesson.publishLessonData,
  currentLanguage: state.degrees.currentLanguage,
  tags: state.referential.tags,
  userCategories: state.referential.userCategories,
  retailers: state.referential.retailers,
  brands: state.referential.brands,
  lessonRecap: state.referential.lessonRecap,
  startPostingDate: state.referential.startPostingDate,
  endPostingDate: state.referential.endPostingDate,
  countries: state.referential.countries,
  isLoaderActive: state.navigation.isLoaderActive,
  isScreenReady: state.lesson.publishLessonData.isScreenReady,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const SetIsScreenReady = (isScreenReady) =>
    dispatch(RealUpdatePublishLessonData({ isScreenReady }));

  return {
    UpdateCurrentLessonId: (lessonId, _lang) => {
      dispatch(SetCurrentLessonId(lessonId));
    },
    SetCurrentLanguage: (language) => {
      dispatch(SetCurrentLanguage(language));
    },
    UpdateLessonTitle: (title, currentVersion, handlers) => {
      dispatch(UpdatePublishLessonData({ ...currentVersion, title }));
      handlers.updateTitle(
        currentVersion.id,
        currentVersion.lang,
        cleanUpAndEncodeText(title),
      );
    },
    UpdateLessonSummary: (summary, currentVersion, handlers) => {
      dispatch(UpdatePublishLessonData({ ...currentVersion, summary }));
      handlers.updateSummary(
        currentVersion.id,
        currentVersion.lang,
        cleanUpAndEncodeText(summary),
      );
    },
    UpdateDegreePublicationInfo: (degreeInfo) => {
      dispatch(UpdatePublishLessonData(degreeInfo));
    },
    UpdatePicCover: (image, currentVersion, handlers) => {
      currentVersion.cover = image;
      currentVersion.picThumb = image;
      handlers.updatePicCover(currentVersion.id, currentVersion.lang, image);
      handlers.updatePicThumb(currentVersion.id, currentVersion.lang, image);
      handlers.updatePicCard(currentVersion.id, currentVersion.lang, image);
      dispatch(UpdatePublishLessonData({ ...currentVersion }));
    },
    UpdateVideoCover: (videoCover, currentVersion, handlers) => {
      currentVersion.videoCover = videoCover;
      handlers.updateVideoCover(
        currentVersion.id,
        currentVersion.lang,
        videoCover,
      );
      dispatch(UpdatePublishLessonData({ ...currentVersion }));
    },
    ChangeTagSelectorVisibility: (selectorType) => {
      dispatch(ChangeTagSelectorVisibility(selectorType));
    },
    UpdateStartPublicationDate: (currentVersion, scheduledDate) => {
      currentVersion.startPostingDate = scheduledDate;
      currentVersion.dateSelectorType = DATE_SELECTOR_TYPE.SCHEDULE;
      dispatch(UpdatePublishLessonData({ ...currentVersion }));
    },
    UpdateEndPublicationDate: (currentVersion, scheduledDate) => {
      currentVersion.endPostingDate = scheduledDate;
      currentVersion.dateSelectorType = DATE_SELECTOR_TYPE.SCHEDULE;
      dispatch(UpdatePublishLessonData({ ...currentVersion }));
    },
    PublishLesson: (id, language, lessonInfo, degreeId, handlers) => {
      if (lessonInfo.pages.length) {
        const startDate = formatDateTimeForSave(lessonInfo.startPostingDate);
        const endDate = lessonInfo.endPostingDate
          ? formatDateTimeForSave(lessonInfo.endPostingDate)
          : null;
        const workflow = isAfter(
          parseISODate(lessonInfo.startPostingDate),
          new Date(),
        )
          ? DOC_WORKFLOW_ENUM.DRAFT
          : DOC_WORKFLOW_ENUM.PUBLISHED;

        if (
          endDate &&
          isBefore(
            parseISODate(lessonInfo.endPostingDate),
            parseISODate(lessonInfo.startPostingDate),
          )
        ) {
          dispatch(
            SetErrorMessage(
              <FormattedMessage {...translations.IncorrectDateMessage} />,
            ),
          );
        } else {
          // Rollback: hide LessonsBadges screen for now
          handlers.updateStatus(
            id,
            language,
            workflow,
            startDate,
            endDate,
            () => {
              let programQuizInfoUpdateCallback = () => {
                ownProps.history.push(`/degree/build/${degreeId}/${language}`);
                dispatch(
                  SetSuccessMessage(
                    <FormattedMessage
                      {...translations.SuccessfulPublishedMessage}
                    />,
                  ),
                );
              };
              if (isShiseido) {
                dispatch(
                  UpdatePublishedLessonPageDataWithProgramQuizInfo(
                    programQuizInfoUpdateCallback,
                  ),
                );
              } else {
                programQuizInfoUpdateCallback();
              }
            },
          );
          // Keep me in case we need LessonsBadges screen
          // handlers.updateStatus(id, language, workflow, startDate, endDate, () => {
          //   if (isShiseido) {
          //     dispatch(UpdatePublishedLessonPageDataWithProgramQuizInfo())
          //     ownProps.history.push(
          //       getPath('lessonBadges', degreeId, id, language),
          //       { wordingModalKey: 'SuccessfulPublishedMessage', backTo: `/degree/build/${degreeId}/${language}` }
          //     )
          //   } else {
          //     ownProps.history.push(`/degree/build/${degreeId}/${language}`)
          //     dispatch(SetSuccessMessage(<FormattedMessage {...translations.SuccessfulPublishedMessage} />))
          //   }
          // })
        }
      } else {
        dispatch(
          SetErrorMessage(
            <FormattedMessage {...translations.MissingLessonContentMessage} />,
          ),
        );
      }
    },
    SaveTagsToState: (tagList, tagType) => {
      dispatch(SetCurrentTags(tagList, tagType));
    },
    /** Save selected or default recap image when save the recap text */
    UpdateRecap: (id, language, handlers, currentVersion, lessonRecap) => {
      handlers.updateRecapPicture(id, language, currentVersion.picLessonRecap);
      handlers.updateRecap(id, language, lessonRecap);
      currentVersion.lessonRecap = lessonRecap;
      dispatch(UpdatePublishLessonData({ ...currentVersion }));
    },
    UpdateRecapPic: (id, language, handlers, currentVersion, imageUrl) => {
      handlers.updateRecapPicture(id, language, imageUrl);
      currentVersion.picLessonRecap = imageUrl;
      dispatch(UpdatePublishLessonData({ ...currentVersion }));
    },
    handleChangeRecapStatus: (id, language, handlers, currentVersion) => () => {
      if (currentVersion.picLessonRecap) {
        currentVersion.picLessonRecap = "";
        currentVersion.lessonRecap = "";
        // handlers.deleteLessonPicRecap(id, language)
        // handlers.deleteLessonRecap(id, language)
      } else {
        currentVersion.picLessonRecap = PlaceHolderDegree;
      }
      dispatch(UpdatePublishLessonData({ ...currentVersion }));
    },
    SaveRecapToState: (lessonRecap, currentVersion) => {
      currentVersion.lessonRecap = lessonRecap;
      dispatch(UpdatePublishLessonData({ ...currentVersion }));
    },

    UpdatePrivacy: (lessonId, lessonLang, publishData, isPublic, handlers) => {
      if (isPublic) {
        handlers.removePrivacy(lessonId, lessonLang, () => {
          dispatch(UpdatePublishLessonData({ ...publishData, isPublic }));
        });
      } else {
        handlers.setPrivacy(lessonId, lessonLang, () => {
          dispatch(UpdatePublishLessonData({ ...publishData, isPublic }));
        });
      }
    },
    RemoveTag: (publishData, removeTagId, tagType, handlers) => {
      const currentTags = publishData["countries"].filter(
        (x) => x.Id !== removeTagId,
      );
      handlers.removeTags(
        publishData.id,
        publishData.lang,
        [removeTagId],
        () => {
          dispatch(
            UpdatePublishLessonData({ ...publishData, [tagType]: currentTags }),
          );
        },
      );
    },
    UpdateTags: (tagList, publishData, id, language, handlers, tagType) => {
      const beforeTagIds = publishData[tagType].map((x) => x.Id);
      const afterTagIds = tagList.map((x) => x.Id);
      const removeTagIds = beforeTagIds.filter(
        (beforeTagId) => !afterTagIds.includes(beforeTagId),
      );
      const newTagIds = afterTagIds.filter(
        (afterTagId) => !beforeTagIds.includes(afterTagId),
      );

      if (publishData.tagsIsEditable) {
        dispatch(
          UpdatePublishLessonData({
            ...publishData,
            [tagType]: tagList,
            tagsIsEditable: false,
          }),
        );
        const updateCallback = (success) => {
          const updateData = { ...publishData, tagsIsEditable: true };
          if (success) {
            // if update was successful keep selected tags, else roll back
            updateData[tagType] = tagList;
          }
          dispatch(UpdatePublishLessonData(updateData));
        };
        if (removeTagIds.length) {
          handlers.removeTags(id, language, removeTagIds, () => {
            if (newTagIds.length) {
              handlers.addTags(id, language, newTagIds, updateCallback);
            } else {
              updateCallback(true);
            }
          });
        } else if (newTagIds.length) {
          handlers.addTags(id, language, newTagIds, updateCallback);
        }
      }
    },
    UpdateCurrentDegreeId: (degreeId) => {
      dispatch(SetCurrentDegreeId(degreeId));
    },
    UpdateIsProgramQuiz: (isProgramQuiz) => {
      dispatch(SetPublishedLessonIsProgramQuiz(isProgramQuiz));
    },
    CreateNewTag: (tagName, handlers, currentVersion, id, language) => {
      handlers.createTag(tagName, (data) => {
        let newTag = {
          Id: data.data.tag.createCustom.tagId,
          Value: data.data.tag.createCustom.title,
        };
        currentVersion.tags = [...currentVersion.tags, newTag];
        handlers.addTags(id, language, [newTag.Id], () => {
          dispatch(UpdatePublishLessonData({ ...currentVersion }));
        });
      });
    },
    GetLessonData: (lessonId, lang) => {
      dispatch(GetLessonData(lessonId, lang, () => SetIsScreenReady(true)));
    },
    SetIsScreenReady,
    WarningAlert: (message) => dispatch(SetWarningMessage(message)),
  };
};

const Container = compose(
  updateTitle,
  updateSumarry,
  updatePicCover,
  updateVideoCover,
  updatePicThumb,
  addTags,
  removeTags,
  updateWorkflow,
  updateRecap,
  setLessonPrivacy,
  removeLessonPrivacy,
  updatePicCard,
  updateRecapPicture,
  createTag,
  deleteLessonRecap,
  deleteLessonPicRecap,
  injectIntl,
)(withThemedStyle(PublishLessonStyle)(PublishLesson));

export default withRouter(
  connect(mapStateToProp, mapDispatchToProps)(Container),
);
