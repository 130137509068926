import React, { useEffect } from "react";
import { AddToMarketScreenStyle } from "./AddToMarket.style";
import withThemedStyle from "../../../components/hoc/withThemedStyle";
import Navigation from "../../../components/Navigation";
import { FullscreenLoader, Checkbox } from "genius-ui";
import { translations } from "./AddToMarket.translations";
import { FormattedMessage } from "react-intl";
import { LANGUAGES } from "../../../configs/referential";
import { GetMarketFilterTags } from "../../../store/degree/actions";
import { connect } from "react-redux";
import { SetFetchIndicator } from "../../../store/common/actions";
import clientConfig, { isShiseido } from "../../../configs/client";
import {
  AddBattleToMarket,
  AddCardToMarket,
  AddDegreeToMarket,
  AddInsightToMarket,
  AddLessonToMarket,
  AddPollToMarket,
  AddRemoveDegreeToMarket,
  GetMasterDegreeTagIds,
  GetMasterDocumentTagIds,
  GetMasterLessonTagIds,
  ResetStore,
  SetBadgesAsMaster,
  SetCurrentSetBadgesContext,
} from "../../../store/masterTemplate/actions";
import Header from "../addToMarketHeader";
import { getPath } from "../../../core/paths";
import { SetSuccessMessage } from "../../../store/notification/actions";
import {
  ADD_TO_MARKET_CONTEXT,
  DEGREE_PARTS,
  DOCUMENT_TYPES,
  USER_ROLE_NAMES,
} from "../../../configs/constants";

const AddToMarket = ({
  classes,
  history,
  match: {
    params: { lang, id, type },
  },
  markets,
  GetMarkets,
  isFetching,
  userMarketTagIds,
  existingDegreeMarketTagIds,
  AddRemoveDegreeToMarket,
  saveDegreeMarketTagIds,
  userRoles,
  originalCloneIds,
  AddDegreeToMarket,
  AddLessonToMarket,
  AddInsightToMarket,
  AddCardToMarket,
  AddPollToMarket,
  AddBattleToMarket,
  ResetStore,
}) => {
  useEffect(() => {
    GetMarkets(id, userMarketTagIds, type);
    return () => ResetStore();
  }, []);

  const goBack = (type) => {
    if (type === DEGREE_PARTS.LESSON || type === DEGREE_PARTS.DEGREE) {
      history.push(getPath("masterTemplates"));
    } else {
      history.push(getPath("masterContentManager"));
    }
  };

  const currentLangLabel = LANGUAGES.find((x) => x.Code === lang).Name;

  const marketIsSelected = (marketId) => {
    return (
      existingDegreeMarketTagIds.includes(marketId) ||
      saveDegreeMarketTagIds.includes(marketId)
    );
  };

  const marketIsDisabled = (marketId, userRoles) => {
    if (isShiseido && userRoles.includes(USER_ROLE_NAMES.TRAINER)) {
      return (
        !userMarketTagIds.includes(parseInt(marketId)) ||
        existingDegreeMarketTagIds.includes(marketId)
      );
    } else {
      return existingDegreeMarketTagIds.includes(marketId);
    }
  };

  const onNext = (_newDegreeId, _masterDegreeId) => {
    history.push(getPath("masterTemplates"));
    // history.push(getPath('masterDegreeBadges', newDegreeId, masterDegreeId, lang))
  };

  const onNextDocuments = (_newDegreeId, _masterDegreeId) => {
    history.push(getPath("masterContentManager"));
  };

  const addToMarket = () => {
    switch (type) {
      case DEGREE_PARTS.LESSON:
        return AddLessonToMarket(
          id,
          lang,
          originalCloneIds.programId,
          originalCloneIds.degreeId,
          saveDegreeMarketTagIds,
          onNext,
        );
      case DOCUMENT_TYPES.INSIGHT:
        return AddInsightToMarket(
          id,
          lang,
          saveDegreeMarketTagIds,
          onNextDocuments,
        );
      case DOCUMENT_TYPES.CARD:
        return AddCardToMarket(
          id,
          lang,
          saveDegreeMarketTagIds,
          onNextDocuments,
        );
      case DOCUMENT_TYPES.POLL:
        return AddPollToMarket(
          id,
          lang,
          saveDegreeMarketTagIds,
          onNextDocuments,
        );
      case DOCUMENT_TYPES.BATTLE:
        return AddBattleToMarket(
          id,
          lang,
          saveDegreeMarketTagIds,
          onNextDocuments,
        );
      default:
        return AddDegreeToMarket(id, lang, onNext);
    }
  };

  const getTranslations = (type) => {
    switch (type) {
      case DOCUMENT_TYPES.INSIGHT:
        return <FormattedMessage {...translations.ContentSubtitleInsight} />;
      case DOCUMENT_TYPES.CARD:
        return <FormattedMessage {...translations.ContentSubtitleCard} />;
      case DOCUMENT_TYPES.POLL:
        return <FormattedMessage {...translations.ContentSubtitlePoll} />;
      case DOCUMENT_TYPES.BATTLE:
        return <FormattedMessage {...translations.ContentSubtitleBattle} />;
      default:
        return <FormattedMessage {...translations.ContentSubtitle} />;
    }
  };

  return (
    <div className={classes.rootContainer}>
      {isFetching && <FullscreenLoader />}
      <Header
        goBack={() => goBack(type)}
        cancelBtnEventHandler={() => goBack(type)}
        submitBtnDisabled={!saveDegreeMarketTagIds.length}
        submitBtnEventHandler={addToMarket}
        title={<FormattedMessage {...translations.Title} />}
        cancelBtnText={<FormattedMessage {...translations.CancelBtnText} />}
        submitBtnText={<FormattedMessage {...translations.NextBtnText} />}
      />
      <div className={classes.contentContainer}>
        <div className="content">
          <div className="title">
            <FormattedMessage
              {...translations.ContentTitle}
              values={{ lang: currentLangLabel }}
            />
          </div>
          <div className="subtitle">{getTranslations(type)}</div>
          <div className="markets">
            {markets.map((market) => (
              <Checkbox
                key={market.value}
                label={market.label}
                handleChange={() => AddRemoveDegreeToMarket(market.value)}
                checked={marketIsSelected(parseInt(market.value))}
                disabled={marketIsDisabled(parseInt(market.value), userRoles)}
              />
            ))}
          </div>
        </div>
      </div>
      <Navigation />
    </div>
  );
};

const mapStateToProp = (state) => ({
  markets: state.degrees.markets,
  isFetching: state.common.fetchIndicator,
  userMarketTagIds: state.auth.profileInfo.tags
    .filter((tag) =>
      clientConfig.MARKET_CLUSTER_IDS.includes(parseInt(tag.clusterId)),
    )
    .map((tag) => parseInt(tag.tagId)),
  existingDegreeMarketTagIds: state.masterTemplates.existingDegreeMarketTagIds,
  saveDegreeMarketTagIds: state.masterTemplates.saveDegreeMarketTagIds,
  userRoles: state.auth.profileInfo.roles,
  originalCloneIds: state.masterTemplates.originalCloneIds,
});

const mapDispatchToProps = (dispatch) => ({
  GetMarkets: (id, userMarketTagIds, type) => {
    dispatch(SetFetchIndicator(true));
    dispatch(
      GetMarketFilterTags(() => {
        switch (type) {
          case DEGREE_PARTS.DEGREE:
            dispatch(
              GetMasterDegreeTagIds(id, userMarketTagIds, () => {
                dispatch(SetFetchIndicator(false));
              }),
            );
            break;
          case DEGREE_PARTS.LESSON:
            dispatch(
              GetMasterLessonTagIds(id, userMarketTagIds, () => {
                dispatch(SetFetchIndicator(false));
              }),
            );
            break;
          default:
            dispatch(
              GetMasterDocumentTagIds(id, userMarketTagIds, () => {
                dispatch(SetFetchIndicator(false));
              }),
            );
            break;
        }
      }),
    );
  },
  AddRemoveDegreeToMarket: (marketId) => {
    dispatch(AddRemoveDegreeToMarket(parseInt(marketId)));
  },
  AddDegreeToMarket: (masterDegreeId, lang, onNext) => {
    dispatch(SetFetchIndicator(true));
    dispatch(
      AddDegreeToMarket(masterDegreeId, (newDegreeId) => {
        dispatch(
          SetBadgesAsMaster(newDegreeId, lang, (newDegreeId) => {
            dispatch(
              SetSuccessMessage(
                <FormattedMessage
                  {...translations.DegreeDeploySuccessMessage}
                />,
              ),
            );
            dispatch(SetFetchIndicator(false));
            dispatch(SetCurrentSetBadgesContext(ADD_TO_MARKET_CONTEXT.DEGREE));
            onNext(newDegreeId, masterDegreeId);
          }),
        );
      }),
    );
  },
  AddLessonToMarket: (lessonId, lang, programId, degreeId, tagIds, onNext) => {
    dispatch(SetFetchIndicator(true));
    dispatch(
      AddLessonToMarket(
        lessonId,
        lang,
        programId,
        degreeId,
        tagIds,
        (newLessonId, masterDegreeId) => {
          dispatch(
            SetSuccessMessage(
              <FormattedMessage {...translations.LessonDeploySuccessMessage} />,
            ),
          );
          dispatch(SetFetchIndicator(false));
          dispatch(SetCurrentSetBadgesContext(ADD_TO_MARKET_CONTEXT.LESSON));
          onNext(newLessonId, masterDegreeId);
        },
      ),
    );
  },
  AddInsightToMarket: (insightId, lang, tagIds, onNext) => {
    dispatch(SetFetchIndicator(true));
    dispatch(
      AddInsightToMarket(insightId, lang, tagIds, (newInsightId) => {
        dispatch(
          SetSuccessMessage(
            <FormattedMessage {...translations.InsightDeploySuccessMessage} />,
          ),
        );
        dispatch(SetFetchIndicator(false));
        onNext(newInsightId);
      }),
    );
  },
  AddCardToMarket: (cardId, lang, tagIds, onNext) => {
    dispatch(SetFetchIndicator(true));
    dispatch(
      AddCardToMarket(cardId, lang, tagIds, (newCardId) => {
        dispatch(
          SetSuccessMessage(
            <FormattedMessage {...translations.CardDeploySuccessMessage} />,
          ),
        );
        dispatch(SetFetchIndicator(false));
        onNext(newCardId);
      }),
    );
  },
  AddBattleToMarket: (battleId, lang, tagIds, onNext) => {
    dispatch(SetFetchIndicator(true));
    dispatch(
      AddBattleToMarket(battleId, lang, tagIds, (newBattleId) => {
        dispatch(
          SetSuccessMessage(
            <FormattedMessage {...translations.BattleDeploySuccessMessage} />,
          ),
        );
        dispatch(SetFetchIndicator(false));
        onNext(newBattleId);
      }),
    );
  },
  AddPollToMarket: (pollId, lang, tagIds, onNext) => {
    dispatch(SetFetchIndicator(true));
    dispatch(
      AddPollToMarket(pollId, lang, tagIds, (newPollId) => {
        dispatch(
          SetSuccessMessage(
            <FormattedMessage {...translations.BattleDeploySuccessMessage} />,
          ),
        );
        dispatch(SetFetchIndicator(false));
        onNext(newPollId);
      }),
    );
  },
  ResetStore: () => dispatch(ResetStore()),
});

export default connect(
  mapStateToProp,
  mapDispatchToProps,
)(withThemedStyle(AddToMarketScreenStyle)(AddToMarket));
