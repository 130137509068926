import React, { useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import uuidv4 from "uuid/dist/v4";
import { decodeNewLines } from "../../utils/string";
import DocumentPageHeader from "../common/DocumentPageHeader";
import ActivityIntro from "../common/ActivityIntroComponent";
import { GetDesignPageEncodeData } from "../../utils/ui-generator/generator";
import { PAGE_TYPES } from "../../configs/constants";
import DragAndDropActivityQuestion from "../common/DragAndDropActivityQuestion";
import Style from "./DragAndDropActivityPageEditor.style.js";
import useQuestions from "../../hooks/activity/useQuestions";
import { createDragAndDropActivityQuestion } from "../../utils/ui-generator/defaultTemplates";
import activityTranslations from "../../configs/translation/activityTranslations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

// In order handle backward compatibility for documents created without zoneId
const detectZoneId = (zoneId, index) => {
  return zoneId || (index === 0 ? "zone1" : "zone2");
};
/** Organize question data into a structure that's easier to work with **/
const normalizeQuestionArray = (questions) => {
  const { questions: normalizedQuestions } = questions.reduce(
    ({ questionMap, questions }, question) => {
      if (!questionMap[question.id]) {
        // group zones based on question id
        questionMap[question.id] = {
          uid: question.uid,
          id: question.id,
          text: question.text,
          zones: question.answers.map(
            ({ zoneId, answer, placeholder }, index) => ({
              zoneId: detectZoneId(zoneId, index),
              answer,
              placeholder,
              cards: [],
            }),
          ),
        };
        questions.push(questionMap[question.id]);
      }

      // group cards based on the zone
      const currentAnswersZone = question.answers.find(({ isTrue }) => isTrue);

      if (currentAnswersZone.zoneId) {
        questionMap[question.id].zones
          .find((zone) => zone.zoneId === currentAnswersZone.zoneId)
          .cards.push(question.card);
      } else {
        questionMap[question.id].zones
          .find(
            (zone) =>
              zone.answer.toLowerCase().trim() ===
              currentAnswersZone.answer.toLowerCase().trim(),
          )
          .cards.push(question.card);
      }

      return { questionMap, questions };
    },
    {
      questionMap: {},
      questions: [],
    },
  );

  return normalizedQuestions;
};

/** Convert question data to the form required at save **/
const deNormalizeQuestionArray = (questions) =>
  questions
    .map((question, index) => ({
      // use the question index as question id to keep the same order for the questions on mobile
      ...question,
      id: index,
    }))
    .reduce((questions, question) => {
      question.zones.forEach((zone, currentZoneIndex) => {
        zone.cards.forEach((card) => {
          questions.push({
            uid: question.uid,
            id: question.id,
            text: question.text,
            answers: question.zones.map(
              ({ zoneId, answer, placeholder }, zoneIndex) => ({
                zoneId,
                answer,
                placeholder,
                isTrue: zoneIndex === currentZoneIndex,
              }),
            ),
            card,
          });
        });
      });

      return questions;
    }, []);

const decodeContent = (encodedContent) => {
  const contentObject = JSON.parse(encodedContent);
  return {
    ...contentObject,
    data: {
      ...contentObject.data,
      activityDescription: decodeNewLines(
        contentObject.data.activityDescription,
      ),
      title: decodeNewLines(contentObject.data.title),
      description: decodeNewLines(contentObject.data.description),
      questions: normalizeQuestionArray(
        contentObject.data.questions.map((question) => ({
          ...question,
          text: decodeNewLines(question.text),
          answers: question.answers.map((answer) => ({
            ...answer,
            answer: decodeNewLines(answer.answer),
          })),
          card: {
            ...question.card,
            text: decodeNewLines(question.card.text),
          },
        })),
      ),
    },
  };
};

const encodeContent = (unEncodedContent, language) => {
  const data = {
    ...unEncodedContent,
    activityName: activityTranslations[language].DragAndDrop.activityName,
  };
  return GetDesignPageEncodeData(
    {
      ...data,
      questions: deNormalizeQuestionArray(unEncodedContent.questions),
    },
    PAGE_TYPES.DRAGANDDROP,
  );
};

const createNewQuestion = (questionId, lang) =>
  normalizeQuestionArray(createDragAndDropActivityQuestion(questionId, lang));

const DragAndDropActivityPageEditor = (props) => {
  // EXTRACT VARIABLES
  const {
    orderId,
    documentId,
    pageId,
    data: dataProp,
    onDataChanged,
    onRemovePage,
    onOrderDownClick,
    onOrderUpClick,
    language,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  const { data } = useMemo(() => decodeContent(dataProp), [dataProp]);

  const {
    activityDescription,
    title,
    description,
    image: imageProp,
    questions: questionsProp,
  } = data;

  const dataRef = useRef([]);
  dataRef.current = data;

  const questions = questionsProp.map((question) => ({
    ...question,
    uid: question.uid || uuidv4(), // generate a uid for backward compatibility purposes(old questions don't have an uid)
  }));
  const questionsRef = useRef([]);
  questionsRef.current = questions;
  // CALLBACKS
  const handleChange = (changedProps) => {
    console.debug(
      "DragAndDropActivityPageEditor.handleChange:before",
      dataRef.current,
    );
    console.debug(
      "DragAndDropActivityPageEditor.handleChange:changedProps",
      changedProps,
    );

    const result = { ...dataRef.current, ...changedProps };
    console.debug("DragAndDropActivityPageEditor.handleChange:result", result);

    const encodedContent = encodeContent(result, language);

    onDataChanged(documentId, language, encodedContent, pageId);
  };
  useEffect(() => {
    if (
      props.isProgramQuiz &&
      dataRef.current.isProgramQuiz !== props.isProgramQuiz
    ) {
      handleChange({ ...dataRef.current, isProgramQuiz: true }, true);
    }
  }, [
    props.isProgramQuiz &&
      dataRef.current.isProgramQuiz !== props.isProgramQuiz,
  ]);
  const handleIntroChange = ({ image, theme, title, description }) =>
    handleChange({
      activityDescription: theme,
      title,
      description,
      image: {
        ...imageProp,
        url: image,
      },
    });

  const {
    editedQuestionIndex,
    setEditedQuestionIndex,
    handleQuestionChange,
    removeQuestion,
    addQuestionBefore,
    addQuestionAfter,
  } = useQuestions(
    questionsRef.current,
    () => createNewQuestion(questionsRef.current.length + 1, language),
    handleChange,
  );

  return (
    <div className={classes.pageBox}>
      <DocumentPageHeader
        pageNumber={orderId}
        onRemoveClick={() => onRemovePage(documentId, language, pageId)}
        onOrderDownClick={() => onOrderDownClick(documentId, language, pageId)}
        onOrderUpClick={() => onOrderUpClick(documentId, language, pageId)}
      />
      <div className={classes.introContainer}>
        <ActivityIntro
          activityId={pageId}
          theme={activityDescription}
          title={title}
          description={description}
          image={imageProp.url}
          isSelected
          onChange={handleIntroChange}
        />
      </div>
      {questions.map(({ uid, ...question }, index) => (
        <DragAndDropActivityQuestion
          key={uid}
          {...question}
          index={index}
          onChange={handleQuestionChange}
          isEditable={index === editedQuestionIndex}
          onClick={() => setEditedQuestionIndex(index)}
          onAddQuestionBefore={addQuestionBefore}
          onAddQuestionAfter={addQuestionAfter}
          onRemove={removeQuestion}
        />
      ))}
    </div>
  );
};

DragAndDropActivityPageEditor.propTypes = {
  /** page Id  */
  pageId: PropTypes.string,
  /** current page JSON data */
  data: PropTypes.string,
  /** page order id */
  orderId: PropTypes.number,
  /** id of current document */
  documentId: PropTypes.string,
  /** current language */
  language: PropTypes.string,
  /** on data changed handler */
  onDataChanged: PropTypes.func,
  /**  event to trigger when a new page is added */
  onRemovePage: PropTypes.func,
  /** whether we are currently editing this page or not **/
  isActivePage: PropTypes.bool,
  /** order up page handler */
  onOrderUpClick: PropTypes.func,
  /** order down page handler */
  onOrderDownClick: PropTypes.func,
};

export default DragAndDropActivityPageEditor;
