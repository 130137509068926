import React, { useEffect } from "react";
import DefaultLessonPageEditor from "./DefaultLessonPageEditor";
import LessonPageEditorReadOnly from "./lessonEditorReadOnly";
import clientConfig from "../../configs/client";
import { USER_ROLE_NAMES } from "../../configs/constants";
import { connect } from "react-redux";
import { FullscreenLoader } from "genius-ui";
import {
  SetCurrentDegreeId,
  SetCurrentLanguage,
  SetCurrentLessonId,
  SetCurrentLessonPageId,
  SetPublishLessonInformation,
  UpdateCurrentOpenedMarker,
} from "../../store/degree/actions";
import {
  GetLessonData,
  UpdateLessonPageData,
} from "../../store/lesson/actions";
import { graphql } from "react-apollo";
import {
  LESSON_CREATE_PAGE,
  LESSON_DELETE_PAGE,
  LESSON_UPDATE_PAGE_DATA,
} from "../../graphql/remote/degrees/mutations";
import { animateScroll } from "react-scroll";
import {
  LESSON_MOVE_PAGE_ORDER_DOWN,
  LESSON_MOVE_PAGE_ORDER_UP,
} from "../../graphql/remote/lessons/mutations";
import compose from "lodash/flowRight";
import withThemedStyle from "../../components/hoc/withThemedStyle";
import { LessonPageEditorStyle } from "./LessonPageEditor.style";

const updateLessonPageData = graphql(LESSON_UPDATE_PAGE_DATA, {
  props: ({ mutate }) => ({
    updateLessonPageDataRequest: (lessonId, lang, data, pageId, onSuccess) => {
      mutate({
        variables: {
          lessonId: lessonId,
          lang: lang,
          data: data,
          pageId: pageId,
        },
        update: () => {
          onSuccess && onSuccess();
        },
      });
    },
  }),
});

const createLessonPage = graphql(LESSON_CREATE_PAGE, {
  props: ({ mutate }) => ({
    createLessonPageRequest: (lessonId, lang, data, pageType, onSuccess) =>
      mutate({
        variables: {
          lessonId: lessonId,
          lang: lang,
          type: pageType,
          data: data,
        },
        update: () => {
          onSuccess && onSuccess();
          setTimeout(2000, animateScroll.scrollToBottom());
        },
      }),
  }),
});

const removeLessonPage = graphql(LESSON_DELETE_PAGE, {
  props: ({ mutate }) => ({
    removePage: (lessonId, lang, pageId, onSuccess) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, pageId: pageId },
        update: () => {
          onSuccess && onSuccess();
        },
      }),
  }),
});

const onOrderUp = graphql(LESSON_MOVE_PAGE_ORDER_UP, {
  props: ({ mutate }) => ({
    orderUpRequest: (lessonId, lang, pageId, onSuccess) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, pageId: pageId },
        update: () => {
          onSuccess && onSuccess();
        },
      }),
  }),
});

const onOrderDown = graphql(LESSON_MOVE_PAGE_ORDER_DOWN, {
  props: ({ mutate }) => ({
    orderDownRequest: (lessonId, lang, pageId, onSuccess) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, pageId: pageId },
        update: () => {
          onSuccess && onSuccess();
        },
      }),
  }),
});

const LessonPageEditor = (props) => {
  const { userRoles, isMasterDocument } = props;
  const { lessonId, lang, degreeId } = props.match.params;

  useEffect(() => {
    props.GetLessonData(lessonId, lang);
    props.UpdateCurrentLessonId(lessonId);
    props.SetCurrentLanguage(lang);
    props.UpdateCurrentDegreeId(degreeId);
  }, []);

  if (!userRoles || !userRoles.length) {
    return <FullscreenLoader />;
  }

  if (
    clientConfig.IS_ROLE_READ_ONLY_MODE_ACTIVE &&
    userRoles.includes(USER_ROLE_NAMES.TRAINER) &&
    isMasterDocument
  ) {
    return <LessonPageEditorReadOnly {...props} />;
  }

  return <DefaultLessonPageEditor {...props} />;
};

const mapStateToProps = (state) => ({
  userRoles: state.auth.profileInfo.roles,
  currentPageId: state.degrees.currentPageId,
  currentOpenedMarker: state.degrees.currentOpenedMarker,
  publishLessonData: state.lesson.publishLessonData,
  isMasterDocument: state.lesson.isMasterDocument,
  isLoaderActive: state.navigation.isLoaderActive,
});

const mapDispatchToProps = (dispatch, _ownProps) => ({
  UpdateCurrentLessonId: (lessonId) => {
    dispatch(SetCurrentLessonId(lessonId));
  },
  SetCurrentLanguage: (language) => {
    dispatch(SetCurrentLanguage(language));
  },
  UpdateLessonPublicationInfo: (lessonInfo) => {
    dispatch(SetPublishLessonInformation(lessonInfo));
  },
  UpdateCurrentPageId: (pageId) => {
    dispatch(SetCurrentLessonPageId(pageId));
  },
  UpdateOpenedMarker: (pageId, slideIndex, markerIndex, openedState) => {
    const props = openedState
      ? { pageId: pageId, markerIndex, slideIndex }
      : { pageId: null, markerIndex: null, slideIndex: null };
    dispatch(UpdateCurrentOpenedMarker(props));
  },
  UpdateCurrentDegreeId: (degreeId) => {
    dispatch(SetCurrentDegreeId(degreeId));
  },
  GetLessonData: (lessonId, lang) => {
    dispatch(GetLessonData(lessonId, lang));
  },
  CreateLessonPage: (lessonId, lang, data, pageType, handler) => {
    handler(lessonId, lang, data, pageType, () => {
      dispatch(GetLessonData(lessonId, lang));
    });
  },
  RemoveLessonPage: (lessonId, lang, pageId, handler) => {
    handler(lessonId, lang, pageId, () => {
      dispatch(GetLessonData(lessonId, lang));
    });
  },
  UpdateLessonPageData: (lessonId, lang, data, pageId, handler, refresh) => {
    dispatch(UpdateLessonPageData(pageId, data));
    handler(lessonId, lang, data, pageId);
  },
  OnOrderUp: (lessonId, lang, pageId, handler) => {
    handler(lessonId, lang, pageId, () => {
      dispatch(GetLessonData(lessonId, lang));
    });
  },
  OnOrderDown: (lessonId, lang, pageId, handler) => {
    handler(lessonId, lang, pageId, () => {
      dispatch(GetLessonData(lessonId, lang));
    });
  },
});

const Container = compose(
  updateLessonPageData,
  createLessonPage,
  removeLessonPage,
  onOrderUp,
  onOrderDown,
)(withThemedStyle(LessonPageEditorStyle)(LessonPageEditor));

export default connect(mapStateToProps, mapDispatchToProps)(Container);
