import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import GetMessages from "../../i18n/messages/index";

const GroupMessagesByLanguage = (messages) =>
  Object.keys(messages).reduce((messagesGroupedByLanguage, messageId) => {
    Object.keys(messages[messageId]).forEach((languageCode) => {
      if (!messagesGroupedByLanguage[languageCode]) {
        messagesGroupedByLanguage[languageCode] = {};
      }
      messagesGroupedByLanguage[languageCode][messageId] =
        messages[messageId][languageCode];
    });

    return messagesGroupedByLanguage;
  }, {});

const LoadTranslationMessages = ({ children }) => {
  const [messages, setMessages] = useState(null);

  useEffect(() => {
    (async () => {
      const messages = await GetMessages();
      setMessages(GroupMessagesByLanguage(messages));
    })();
  }, []);

  return children(messages);
};

LoadTranslationMessages.propTypes = {
  /** func(messages) **/
  children: PropTypes.func.isRequired,
};

export default LoadTranslationMessages;
