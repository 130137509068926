export default {
  cardContainer: {
    paddingRight: 15,
    paddingBottom: 10,
    maxWidth: 465,
    float: "left",
  },
  docSettingsMenu: {
    width: 200,
    left: 455,
    position: "relative",
    top: -15,
    zIndex: 2,
  },
  loadMoreContainer: {
    margin: "40px 0",
    float: "left",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  kpiIcon: {
    "&:before": {
      fontSize: 30,
      paddingRight: 10,
      width: 30,
      marginLeft: -10,
      color: "rgb(146, 146, 146)",
    },
  },
};
