import FilterHeaderContainer from "components/FilterHeaderContainer/FilterHeaderContainer";
import PageContainer from "components/PageContainer/PageContainer";
import React, { useMemo } from "react";
import { useQuery } from "react-apollo";
import { GET_BADGE_BY_ID_WITH_DEGREE_REF } from "../graphs";
import Markets from "../partials/Markets/Markets";

const CloneToMarket = ({
  match: {
    params: { id },
  },
}) => {
  const badge = useQuery(GET_BADGE_BY_ID_WITH_DEGREE_REF, {
    variables: { badgeId: id },
  });

  const { degreeRef, brandId, title } = useMemo(() => {
    if (!badge.data) return {};
    return {
      title: badge.data.admin.badges[0].title,
      degreeRef: badge.data.admin.badges[0].degreeRef.docId,
      brandId: badge.data.admin.badges[0].tags.find(
        ({ clusterId }) => clusterId === "18",
      ).tagId,
    };
  }, [badge.data]);
  return (
    <PageContainer>
      <FilterHeaderContainer canGoBack />
      <div>
        <Markets
          parentId={id}
          degreeRef={degreeRef}
          brandId={brandId}
          title={title}
        />
      </div>
    </PageContainer>
  );
};

export default CloneToMarket;
