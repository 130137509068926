import React from "react";
import { PeanutStyle } from "./Peanut.style";
import PropTypes from "prop-types";
import { Icons, Icon } from "genius-ui";
import ContentEditable from "react-contenteditable";
import {
  DefaultSlider1,
  AddSlide,
  PeanutWithTextSwitcherImage,
  PeanutWithoutTextSwitcherImage,
} from "assets/icons";
import { PEANUT_CARD_TYPES, CROP_VARIATION } from "../../../configs/constants";
import { decodeNewLines } from "../../../utils/string";
import CropImage from "../../common/CropImage";
import { FormattedMessage } from "react-intl";
import UploadMedia from "../UploadMedia";
import { translations } from "./Peanut.translations";
import withThemedStyle from "../../hoc/withThemedStyle";

// half of fixed width
const BUTTONSWITCH_BREAKPOINT = 214;

const switcherOptions = (props) => [
  {
    text: <FormattedMessage {...translations.PeanutWithText} />,
    src: PeanutWithTextSwitcherImage,
    type: PEANUT_CARD_TYPES.WITH_TEXT,
  },
  {
    text: <FormattedMessage {...translations.PeanutWithoutText} />,
    src: PeanutWithoutTextSwitcherImage,
    type: PEANUT_CARD_TYPES.WITHOUT_TEXT,
  },
  {
    text: <FormattedMessage {...translations.PeanutWithLegend} />,
    src: PeanutWithoutTextSwitcherImage,
    type: PEANUT_CARD_TYPES.WITH_LEGEND,
  },
];

class Peanut extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      addPosition: "right",
      title: null,
      description: null,
      image: null,
      cardType: PEANUT_CARD_TYPES.WITHOUT_TEXT,
      legend: null,
      isCropVisible: false,
    };
  }

  checkToChangeAddPosition = (position) => {
    if (position < BUTTONSWITCH_BREAKPOINT && position > 1) {
      this.setState({ addPosition: "left" });
    } else {
      this.setState({ addPosition: "right" });
    }
  };

  changeTitleValue = (evt) => {
    this.setState({
      title: evt.target.value,
      legend: null,
    });
  };

  changeDescriptionValue = (evt) => {
    this.setState({
      description: evt.target.value,
      legend: null,
    });
  };

  changeLegendValue = (evt) => {
    this.setState({
      legend: evt.target.value,
      title: null,
      description: null,
    });
  };

  changeCardTypeValue = (cardType, cardIndex) => {
    switch (cardType) {
      case PEANUT_CARD_TYPES.WITH_LEGEND:
        this.setState({
          title: null,
          description: null,
          cardType: cardType,
        });
        break;
      case PEANUT_CARD_TYPES.WITH_TEXT:
        this.setState({
          legend: null,
          cardType: cardType,
        });
        break;
      case PEANUT_CARD_TYPES.WITHOUT_TEXT:
        this.setState({
          legend: null,
          title: null,
          description: null,
          cardType: cardType,
        });
        break;
      default:
        break;
    }

    let changedValue = {
      title: this.state.title,
      description: this.state.description,
      image: this.state.image,
      legend: this.state.legend,
      cardType: cardType,
    };
    this.props.onChange(changedValue, cardIndex);
  };

  handleUploadSuccess = (url) => {
    let changedValue = {
      title: this.state.title,
      description: this.state.description,
      image: url,
      legend: this.state.legend,
      cardType: this.state.cardType,
    };
    this.setState({ image: url });
    this.setState({ isCropVisible: false });
    this.props.onChange(changedValue, this.props.cardIndex);
  };

  handleValuesChanged = () => {
    let changedValue = {
      title: this.state.title,
      description: this.state.description,
      image: this.state.image,
      legend: this.state.legend,
      cardType: this.state.cardType,
    };
    this.props.onChange(changedValue, this.props.cardIndex);
  };

  changeCropVisibility = (state) => {
    this.setState({ isCropVisible: state });
  };

  componentDidMount() {
    this.setState({
      title: decodeNewLines(this.props.title),
      description: decodeNewLines(this.props.description),
      image: this.props.image,
      legend: decodeNewLines(this.props.legend),
      cardType: this.props.cardType,
    });
  }

  render() {
    let props = this.props;
    let { uniqueId, classes } = props;
    const editorClass =
      this.state.cardType === PEANUT_CARD_TYPES.WITH_LEGEND
        ? classes.legendEditing
        : classes.textEditing;

    return (
      <div
        className={classes.sliderContainer}
        onMouseMove={(e) =>
          props.isSelected
            ? this.checkToChangeAddPosition(e.nativeEvent.offsetX)
            : {}
        }
        onClick={props.onClick}
      >
        {props.isSelected && (
          <div className={classes.cardTypeSwitcher}>
            {switcherOptions(props).map((option) => (
              <div
                key={option.type}
                className={
                  this.state.cardType === option.type
                    ? classes.switcherActive
                    : classes.switcherOptions
                }
                onClick={() => {
                  this.changeCardTypeValue(option.type, this.props.cardIndex);
                }}
              >
                <img alt="" src={option.src} />
                <div>{option.text}</div>
              </div>
            ))}
          </div>
        )}

        <div className={classes.uploadControlsBox}>
          <UploadMedia
            id={`change-peanut-image-${uniqueId}`}
            className={classes.uploadBtnWrapper}
            onUploadSuccess={this.handleUploadSuccess}
            isVideoUploadEnabled={false}
          >
            <button className={classes.btnUpload}>
              <Icon iconName={Icons.upload} style={classes.iconAction} />
              <FormattedMessage {...translations.UploadImageText} />
            </button>
          </UploadMedia>
          <CropImage
            {...props}
            sourceImage={this.state.image}
            isCropVisible={this.state.isCropVisible}
            cropSize={CROP_VARIATION.PEANUT}
            onCropClick={() => this.changeCropVisibility(true)}
            onCancel={() => this.changeCropVisibility(false)}
            onCropCompleted={(url, id) => this.handleUploadSuccess(url)}
          />
          <button className={classes.btnUpload} onClick={props.onRemoveClick}>
            <span>
              <FormattedMessage {...translations.DeleteText} />
            </span>
            <Icon iconName={Icons.delete} style={classes.iconAction} />
          </button>
        </div>

        <div className={classes.cardIndex}>
          <span>
            {props.cardIndex}/{props.allCardNumber}
          </span>
        </div>

        {this.state.addPosition === "left" && props.isSelected ? (
          <div
            className={classes.addBoxLeft}
            onClick={props.onAddToLeftHandle}
            onMouseMove={(event) => event.stopPropagation()}
          >
            <FormattedMessage {...translations.AddSlideLeftText}>
              {(message) => <img src={AddSlide} alt={message[0]} />}
            </FormattedMessage>
          </div>
        ) : (
          ""
        )}
        <div className={editorClass}>
          {this.state.cardType === PEANUT_CARD_TYPES.WITH_TEXT && (
            <>
              <ContentEditable
                key="title"
                html={this.state.title ? this.state.title : ""}
                disabled={!props.isSelected}
                onChange={this.changeTitleValue}
                onBlur={this.handleValuesChanged}
                tagName="article"
                className={props.classes.titleInput}
              />
              <br />
              <ContentEditable
                key="description"
                html={this.state.description ? this.state.description : ""}
                disabled={!props.isSelected}
                onChange={this.changeDescriptionValue}
                onBlur={this.handleValuesChanged}
                tagName="article"
                className={props.classes.descriptionInput}
              />
            </>
          )}
          {this.state.cardType === PEANUT_CARD_TYPES.WITH_LEGEND && (
            <ContentEditable
              key="legend"
              html={this.state.legend ? this.state.legend : ""}
              disabled={!props.isSelected}
              onChange={this.changeLegendValue}
              onBlur={this.handleValuesChanged}
              tagName="article"
              className={props.classes.legendInput}
            />
          )}
        </div>

        {this.state.addPosition === "right" && props.isSelected ? (
          <div
            className={classes.addBoxRight}
            onClick={props.onAddToRightHandle}
            onMouseMove={(event) => event.stopPropagation()}
          >
            <FormattedMessage {...translations.AddSlideRightText}>
              {(message) => <img src={AddSlide} alt={message[0]} />}
            </FormattedMessage>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

Peanut.propTypes = {
  /** string that uniquely identifies the peanut slide on a single page **/
  uniqueId: PropTypes.string.isRequired,
  /** The current selected image */
  image: PropTypes.string,
  /** title value */
  title: PropTypes.string,
  /** description */
  description: PropTypes.string,
  /** Label for the chnage image selection button */
  buttonLabel: PropTypes.string,
  /** On upload completed image change */
  onAddToRightHandle: PropTypes.func,
  /** On handle addLeft event */
  onAddToLeftHandle: PropTypes.func,
  /** on remove click event */
  onRemoveClick: PropTypes.func,
  /** onValueChange */
  onChange: PropTypes.func,
  /** is selected */
  isSelected: PropTypes.bool,
  /** on click */
  onClick: PropTypes.func,
  /** default sliderImage */
  defaultSliderImage: PropTypes.string,
  /** type of the peanut card */
  cardType: PropTypes.string.isRequired,
  /** legend */
  legend: PropTypes.string,
  /** all card number */
  allCardNumber: PropTypes.number.isRequired,
  /** card index */
  cardIndex: PropTypes.number.isRequired,
};

Peanut.defaultProps = {
  defaultSliderImage: DefaultSlider1,
};

export default withThemedStyle(PeanutStyle)(Peanut);
