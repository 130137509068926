import React from "react";
import { FormattedMessage } from "react-intl";
import {
  SimpleQuizActivityTemplateImage,
  QuizWithCoverActivityTemplateImage,
  FullImageQuizActivityTemplateImage,
  MadLibsActivityTemplateImage,
  DragAndDropActivityTemplateImage,
} from "assets/icons";
import {
  SimpleQuizActivityTemplateEmpty,
  QuizWithCoverActivityTemplateEmpty,
  QuizImageFullScreenActivityTemplateEmpty,
  MadLibActivityTemplateEmpty,
  DragAndDropActivityTemplateEmpty,
  NinjaActivityTemplateEmpty,
} from "../../../../utils/ui-generator/defaultTemplates";
import { PAGE_TYPES } from "../../../../configs/constants";
import { isShiseido } from "../../../../configs/client";
import { translations } from "./Activities.translations";

const ActivitiesTemplate = (props) => ({
  title: <FormattedMessage {...translations.TemplateTitle} />,
  items: [
    {
      name: <FormattedMessage {...translations.QuizText} />,
      thumbnail: SimpleQuizActivityTemplateImage,
      onSelect: () => {
        props.handleActivityClick(
          JSON.stringify(SimpleQuizActivityTemplateEmpty(props.currentDocLang)),
          PAGE_TYPES.SIMPLEQUIZZ,
        );
      },
    },
    {
      name: <FormattedMessage {...translations.QuizWithCoverText} />,
      thumbnail: QuizWithCoverActivityTemplateImage,
      onSelect: () => {
        props.handleActivityClick(
          JSON.stringify(
            QuizWithCoverActivityTemplateEmpty(props.currentDocLang),
          ),
          PAGE_TYPES.SIMPLEQUIZZ,
        );
      },
    },
    {
      name: <FormattedMessage {...translations.QuizImageFullScreenText} />,
      thumbnail: FullImageQuizActivityTemplateImage,
      onSelect: () => {
        props.handleActivityClick(
          JSON.stringify(
            QuizImageFullScreenActivityTemplateEmpty(props.currentDocLang),
          ),
          PAGE_TYPES.SIMPLEQUIZZ,
        );
      },
    },
    ...[
      {
        name: <FormattedMessage {...translations.MadlibText} />,
        thumbnail: MadLibsActivityTemplateImage,
        onSelect: () => {
          props.handleActivityClick(
            JSON.stringify(MadLibActivityTemplateEmpty(props.currentDocLang)),
            PAGE_TYPES.MADLIB,
          );
        },
      },
      {
        name: <FormattedMessage {...translations.DragAndDropText} />,
        thumbnail: DragAndDropActivityTemplateImage,
        onSelect: () => {
          props.handleActivityClick(
            JSON.stringify(
              DragAndDropActivityTemplateEmpty(props.currentDocLang),
            ),
            PAGE_TYPES.DRAGANDDROP,
          );
        },
      },
    ],
    ...(isShiseido
      ? [
          {
            name: <FormattedMessage {...translations.NinjaText} />,
            thumbnail: DragAndDropActivityTemplateImage,
            onSelect: () => {
              props.handleActivityClick(
                JSON.stringify(
                  NinjaActivityTemplateEmpty(props.currentDocLang),
                ),
                PAGE_TYPES.NINJA,
              );
            },
          },
        ]
      : []),
  ],
});

export default ActivitiesTemplate;
