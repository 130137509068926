import React, { Component } from "react";
import { connect } from "react-redux";
import {
  SetCards,
  SetCurrentActiveSlide,
  SetCurrentCardId,
  SetCurrentCardLanguage,
  SetCurrentCardPageId,
  SetCardLanguages,
  SetPublishData,
} from "../../store/card/actions";
import PublishPreviewBanner from "../../components/PublishPreviewBanner";
import { CreateCardStyle } from "./CreateCard.style";
import { Icon, Icons } from "genius-ui";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";
import { CARD_UPDATE_PAGE_DATA } from "../../graphql/remote/cards/mutations";
import { CARD_UPDATE_PIC_COVER } from "../../graphql/remote/cards/mutations";
import { GetDesignPageEncodeData } from "../../utils/ui-generator/generator";
import { DOCUMENT_LEVEL, PAGE_TYPES } from "../../configs/constants";
import { PeanutDefaultImage1, PeanutDefaultImage2 } from "assets/icons";
import { getPath } from "../../core/paths";
import { FormattedMessage } from "react-intl";
import PeanutPageEditor from "../../components/PeanutPageEditor";
import { DOCUMENT_LIST_WORKFLOWS } from "../../store/document/reducers";
import { translations } from "./CreateCard.translations";
import withThemedStyle from "../../components/hoc/withThemedStyle";
import TemplateSelector from "./Components/TemplateSelector/TemplateSelector";

const DefaultSliderImages = [
  PeanutDefaultImage1,
  PeanutDefaultImage2,
  PeanutDefaultImage1,
];

const updateCardPageData = graphql(CARD_UPDATE_PAGE_DATA, {
  props: ({ mutate }) => ({
    cardUpdatePageData: (cardId, lang, data, pageId) =>
      mutate({
        variables: { cardId: cardId, lang: lang, data: data, pageId: pageId },
      }),
  }),
});

const updateCardPicCover = graphql(CARD_UPDATE_PIC_COVER, {
  props: ({ mutate }) => ({
    updateCardPicCover: (cardId, lang, data) =>
      mutate({
        variables: { cardId: cardId, lang: lang, data: data },
      }),
  }),
});

class CreateCard extends Component {
  componentDidMount() {
    const { props } = this;
    const {
      match: { params },
    } = props;

    props.SetCurrentCardId(params.cardId);
    props.SetCurrentCardLanguage(params.lang);
  }

  handleBack = () =>
    this.props.history.push(
      this.props.documentListWorkflow === DOCUMENT_LIST_WORKFLOWS.DELETED
        ? getPath("deletedContent")
        : getPath("contentManager"),
    );

  addCard = (newElement) => () => {
    let currentCards = [...this.props.currentCards];
    const sliderIndex =
      this.props.currentActiveSlide !== null
        ? this.props.currentActiveSlide
        : currentCards.length - 1;
    newElement.defaultImage = DefaultSliderImages[(sliderIndex + 1) % 2];
    newElement.image = newElement.defaultImage;
    let newSlidePosition = Number(sliderIndex) + 1;
    currentCards.splice(newSlidePosition, 0, newElement);
    this.props.SetCurrentCards(currentCards);
    this.props.SetCurrentActiveSlide(newSlidePosition);
    let formattedSaveValue = GetDesignPageEncodeData(
      currentCards,
      PAGE_TYPES.PEANUT,
    );
    this.props.cardUpdatePageData(
      this.props.currentCardId,
      this.props.currentCardLanguage,
      formattedSaveValue,
      this.props.currentCardPageId,
    );
  };

  updatePublishData = (publishData) =>
    this.props.UpdatePublishData(publishData);

  render() {
    const props = this.props;
    const { classes } = props;

    return (
      <div>
        <PublishPreviewBanner
          onPreviewButtonClick={() => {}}
          onPublishButtonClick={() =>
            this.props.navigateToPublishPage(
              this.props.currentCardId,
              this.props.currentCardLanguage,
              this.props.documentLevel,
            )
          }
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className={classes.selectTemplate}>
            <div className="selectTemplateTitle">
              <span className={classes.backButton} onClick={this.handleBack}>
                <Icon iconName={Icons.arrowLeft} alt="" />
              </span>
              <span>
                <FormattedMessage {...translations.SideBarTitle} />
              </span>
            </div>
            <TemplateSelector addCard={this.addCard} />
          </div>
          <div className={classes.cardEditorContainer}>
            <PeanutPageEditor
              cards={props.currentCards}
              onDataChanged={props.cardUpdatePageData}
              onPicChanged={props.updateCardPicCover}
              pageId={props.currentCardPageId}
              documentId={props.cardId}
              language={props.lang}
              SetCurrentCards={props.SetCurrentCards}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  currentCardId: state.cards.currentCardId,
  currentCardPageId: state.cards.currentPageId,
  currentCardLanguage: state.cards.currentLanguage,
  currentCards: state.cards.cards,
  currentActiveSlide: state.cards.currentActiveSlide,
  documentListWorkflow: state.documents.listWorkflow,
  documentLevel: state.documents.documentLevel,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  SetCurrentCardId: (cardId) => {
    dispatch(SetCurrentCardId(cardId));
  },
  SetCurrentCardLanguage: (language) => {
    dispatch(SetCurrentCardLanguage(language));
  },
  SetCurrentCardPageId: (pageId) => {
    dispatch(SetCurrentCardPageId(pageId));
  },
  SetCurrentCards: (cards) => {
    dispatch(SetCards(cards));
  },
  SetCurrentActiveSlide: (index) => {
    dispatch(SetCurrentActiveSlide(index));
  },
  navigateToPublishPage: (cardId, language, documentLevel) => {
    if (documentLevel === DOCUMENT_LEVEL.MASTER) {
      ownProps.history.push(getPath("publishMasterCard", cardId, language));
    } else {
      ownProps.history.push(getPath("cardPublish", cardId, language));
    }
  },
  SetCardLanguages: (languages) => {
    dispatch(SetCardLanguages(languages));
  },
  UpdatePublishData: (publishData) => {
    dispatch(SetPublishData(publishData));
  },
});

const Container = compose(
  updateCardPageData,
  updateCardPicCover,
)(withThemedStyle(CreateCardStyle)(CreateCard));

export default connect(mapStateToProp, mapDispatchToProps)(Container);
