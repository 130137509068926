import gql from "graphql-tag";

export const INSIGHT_UPDATE_TITLE = gql`
  mutation insightUpdateTitle($insightId: ID, $lang: LangEnum, $data: String!) {
    admin {
      insight(insightId: $insightId, lang: $lang) {
        title(data: $data)
      }
    }
  }
`;
