import gql from "graphql-tag";

export const CHANGE_USER_VALIDATION_STATUS = gql`
  mutation changeUserValidationStatus($userId: ID!, $status: StatusEnum!) {
    admin {
      users(userId: $userId) {
        updateStatus(status: $status) {
          userId
        }
      }
    }
  }
`;
