import React, { useCallback, useMemo, useState } from "react";
import DropDown from "components/Dropdown/Dropdown";
import { useQuery } from "react-apollo";
import { degreeIdTitleToDropDown } from "utils/transformers/tags";
import PageContainer from "components/PageContainer/PageContainer";
import {
  ADD_TAGS_TO_BADGE,
  CREATE_BADGE,
  GET_DEGREE_BY_ID,
  GET_MASTER_DEGREES,
} from "../graphs";
import client from "graphql/client";
import { useHistory } from "react-router-dom";
import { getPath } from "core/paths";
import { Button } from "genius-ui";
import { FullscreenLoader } from "genius-ui";

const createMasterBadge = async (title, degreeId, history) => {
  const degree = await client.query({
    fetchPolicy: "network-only",
    query: GET_DEGREE_BY_ID,
    variables: {
      degreeId,
    },
  });

  if (!(title && degreeId && degree.data.admin.degrees.length)) return;
  const newBadge = await client.mutate({
    mutation: CREATE_BADGE,
    variables: {
      title,
      parentId: 0,
      degreeId: degreeId,
    },
  });
  await client.mutate({
    mutation: ADD_TAGS_TO_BADGE,
    variables: {
      badgeId: newBadge.data.admin.badge.create.badgeId,
      tagIds: [
        1114,
        degree.data.admin.degrees[0].tags.find(
          ({ clusterId }) => clusterId === "18",
        ).tagId,
      ],
    },
  });
  history.push(getPath("masterBadges"));
};

const MasterDegreeDropdown = ({ setDegreeId }) => {
  const { data, loading, error } = useQuery(GET_MASTER_DEGREES);

  const options = useMemo(() => {
    if (!data) return undefined;
    return data.admin.degrees.map(degreeIdTitleToDropDown);
  }, [data]);
  const handleChange = useCallback(({ value }) => {
    setDegreeId(value);
  }, []);
  if (loading) return null;
  return (
    <div style={{ width: 200 }}>
      <DropDown
        handleChange={handleChange}
        options={options}
        isClearable={false}
      />
    </div>
  );
};

const CreateMasterBadge = () => {
  const [title, setTitle] = useState("");
  const [degreeId, setDegreeId] = useState();
  const history = useHistory();

  const handleTitle = useCallback(
    (e) => {
      setTitle(e.target.value);
    },
    [setTitle],
  );
  const [fullscreenLoader, setFullscreenLoader] = useState(false);

  return (
    <PageContainer>
      {!!fullscreenLoader && <FullscreenLoader />}
      <div>
        <label>
          Title:
          <input
            name="firstName"
            type="text"
            value={title}
            onChange={handleTitle}
          />
        </label>
        Degree:
        <MasterDegreeDropdown setDegreeId={setDegreeId} />
        <Button
          variation="primary"
          size="small"
          handleClick={() => {
            setFullscreenLoader(true);
            createMasterBadge(title, degreeId, history);
          }}
        >
          Save
        </Button>
      </div>
    </PageContainer>
  );
};

export default CreateMasterBadge;
