import * as constants from "../../constants/store/kpi";
import { RESET_STORE } from "../../constants/store/auth";

const initialState = {
  generalDetails: null,
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.SET_GENERAL_DETAILS:
      return {
        ...state,
        generalDetails: data,
      };
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
