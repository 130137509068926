import gql from "graphql-tag";

export const GET_TAGS_BY_CLUSTER_ID_PAGINATED = gql`
  query queryTagsByClusterId(
    $clusterMetaTagIds: [ID!]
    $clusterIds: [ID!]
    $autocomplete: String
    $skip: Int
    $limit: Int
    $clusterMetaTag: ID
  ) {
    tags(
      clusterMetaTagIds: $clusterMetaTagIds
      clusterIds: $clusterIds
      autocompleteFull: $autocomplete
      skip: $skip
      limit: $limit
      clusterMetaTag: $clusterMetaTag
    ) {
      tagId
      title
      clusterName
      clusterId
      clusterMetaTagId
      parentTag {
        tagId
      }
    }
  }
`;
