import * as constants from "../../constants/store/documents";

export const SetCurrentLanguage = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_DOCUMENT_LANGUAGE,
    data: data,
  });
};

export const SetActiveMenuDocumentId = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_ACTIVE_MENU_FOR_DOCUMENT_ID,
    data: data,
  });
};

export const SetCurrentWorkflowStatus = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_WORKFLOW_STATUS,
    data: data,
  });
};

export const SetCurrentDocumentType = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_DOCUMENT_TYPE,
    data: data,
  });
};

export const SetCurrentMarket = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_DOCUMENT_MARKET,
    data: data,
  });
};

export const SetViewType = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_VIEW_TYPE,
    data: data,
  });
};

export const SetListWorkflow = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_LIST_WORKFLOW,
    data: data,
  });
};

export const SetCurrentDocumentLevel = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_DOCUMENT_LEVEL,
    data: data,
  });
};
