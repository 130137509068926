import { defineMessages } from "react-intl";

export const BattleMessages = defineMessages({
  BattleSimpleQuestionTemplateTitle: {
    id: "Battle.BattleSimpleQuestionTemplateTitle",
    defaultMessage: "Small text",
  },
  BattleLongQuestionTemplateTitle: {
    id: "Battle.BattleLongQuestionTemplateTitle",
    defaultMessage: "Long text",
  },
  BattleQuestionWithMediaTemplateTitle: {
    id: "Battle.BattleQuestionWithMediaTemplateTitle",
    defaultMessage: "With media",
  },
});
