const percentDataColor = ({ lowPercent }, theme) =>
  lowPercent
    ? theme.KeyDetailWidget.percentDataColorLow
    : theme.KeyDetailWidget.percentDataColor;

const containerHorizontalPaddingPx = 20;

export default (theme) => ({
  container: {
    width: "100%",
    fontFamily: theme.FontFamily,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    display: "flex",
    flexDirection: "column",
    padding: `20px ${containerHorizontalPaddingPx}px 30px`,
    boxSizing: "border-box",
    position: "relative",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.KeyDetailWidget.titleColor,
    maxWidth: 308,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  availability: {
    minWidth: 53,
    height: 28,
    color: theme.KeyDetailWidget.availabilityColor,
    fontSize: 16,
    fontWeight: 500,
    border: `solid 1px ${theme.KeyDetailWidget.availabilityBorderColor}`,
    backgroundColor: theme.KeyDetailWidget.availabilityBackground,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: (-1 * containerHorizontalPaddingPx) / 2,
  },
  body: {
    display: "flex",
    flexDirection: "row",
  },
  primaryData: {
    fontSize: 50,
    fontWeight: "bold",
    color: theme.KeyDetailWidget.primaryDataColor,
    lineHeight: "100%",
  },
  otherData: {
    marginLeft: 10,
  },
  secondaryData: {
    fontSize: 25,
    fontWeight: 500,
    color: theme.KeyDetailWidget.secondaryDataColor,
  },
  percentData: {
    fontSize: 20,
    fontWeight: 500,
    color: (props) => percentDataColor(props, theme),
    "& span": {
      display: "inline-block",
      position: "relative",
      width: 14,
      height: 15,
      "&::before": {
        position: "absolute",
        top: 5,
        color: (props) => percentDataColor(props, theme),
        fontSize: 5,
      },
    },
  },
  dataTooltip: {
    position: "absolute",
    bottom: 20,
    right: 20,
    width: 24,
  },
  tooltipContent: {
    "& > table > tbody": {
      "& tr": {
        height: 45,
        "& td:nth-of-type(1)": {
          width: 30,
        },
      },
      "& tr:nth-of-type(1) > td:nth-of-type(1) > span": {
        fontSize: 16,
        color: theme.KeyDetailWidget.availabilityColor,
      },
      "& tr:nth-of-type(2)": {
        "& > td div:nth-of-type(1) span:before": {
          fontSize: 7,
          color: theme.KeyDetailWidget.percentDataColor,
        },
        "& > td div:nth-of-type(2) span:before": {
          fontSize: 7,
          color: theme.KeyDetailWidget.percentDataColorLow,
        },
      },
      "& tr:nth-of-type(3) > td:nth-of-type(1) > span": {
        fontSize: 16,
        color: theme.KeyDetailWidget.secondaryDataColor,
      },
    },
  },
});
