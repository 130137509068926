export default ({ WhiteAccordion }) => ({
  accordion: {
    border: "none",
    borderRadius: 2,

    "& .accordion__button": {
      borderBottom: ({ striped }) =>
        striped ? `1px solid ${WhiteAccordion.buttonBorderColor}` : "none",
      padding: 20,
      fontSize: 14,
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: WhiteAccordion.buttonColor,
      width: `calc(100%-${20}px)`,
      "&:after": {
        top: "18px!important",
        right: "20px!important",
        height: "7px!important",
        width: "7px!important",
        borderBottom: `2px solid ${WhiteAccordion.caretColor}!important`,
        borderRight: `2px solid ${WhiteAccordion.caretColor}!important`,
      },
    },

    "& .accordion__button:hover": {
      backgroundColor: WhiteAccordion.buttonHoverBackgroundColor,
    },

    "& .accordion__panel": {
      padding: 0,
      backgroundColor: WhiteAccordion.panelBackgroundColor,
    },
  },
});
