import React, { useState } from "react";
import { useIntl } from "react-intl";
import { translations } from "../../publish.translate";
import { useSummary } from "store/publish/hooks";
import "constants/scss/style.scss";

const SummaryClients = ({ type }) => {
  const intl = useIntl();
  const { summary, EditSummary } = useSummary();
  const [text, setText] = useState(summary);
  const [rows, setRows] = useState(2);
  const minRows = 2;
  const maxRows = 20;

  const handleChange = (event) => {
    const textareaLineHeight = 14;
    const previousRows = event.target.rows;
    event.target.rows = minRows;
    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }
    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }
    setRows(currentRows < maxRows ? currentRows : maxRows);
    setText(event.target.value);
  };

  return (
    <div className="sumContainer">
      <form className="inputSubmitText">
        <textarea
          name="summary"
          value={text || ""}
          placeholder={intl.formatMessage(translations.SummaryPlaceholder)}
          onChange={handleChange}
          onBlur={(event) => EditSummary(type, event.target.value)}
          rows={rows}
        />
      </form>
    </div>
  );
};

export default React.memo(SummaryClients);
