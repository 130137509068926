import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import parseISO from "date-fns/parseISO";
import { Button, ButtonSize, Icon, Icons } from "genius-ui";
import styles from "./PublishDateHandler.style";
import {
  getFormattedPublicationDate,
  getFormattedSopiDate,
} from "../../../utils/dateUtils";
import withThemedStyle from "../../hoc/withThemedStyle";
import "constants/scss/style.scss";

const SinglePublishDate = (props) => {
  const {
    classes,
    startPostingDate,
    updateStartPublicationDate,
    startDateLabel,
    disabled,
  } = props;

  const [isStartDateCalendarOpen, setIsStartDateCalendarOpen] = useState(false);

  const toggleStartDateCalendar = useCallback(() => {
    setIsStartDateCalendarOpen(!isStartDateCalendarOpen);
  }, [setIsStartDateCalendarOpen, isStartDateCalendarOpen]);

  const formattedStartPostingDate =
    getFormattedPublicationDate(startPostingDate);

  return (
    <div className="PostingDate">
      <div className={classes.postingDateSwitchers}>
        <div className="startPostingDate">
          <div className={classes.sectionTitle}>{startDateLabel}</div>
          <div>
            <Button
              variation={"secondary"}
              size={ButtonSize.small}
              handleClick={toggleStartDateCalendar}
              disabled={disabled}
            >
              <div className={classes.postingDateBtnContent}>
                <div>
                  <Icon
                    iconName={Icons.calendar}
                    style={
                      disabled
                        ? classes.postingDateDisabledBtnIcon
                        : classes.postingDateBtnIcon
                    }
                  />
                </div>
                <div>{formattedStartPostingDate}</div>
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div>
        {isStartDateCalendarOpen && (
          <DatePicker
            selected={
              startPostingDate && startPostingDate !== ""
                ? parseISO(startPostingDate)
                : ""
            }
            onChange={(date) => {
              updateStartPublicationDate(
                date ? getFormattedSopiDate(date) : date,
              );
              toggleStartDateCalendar();
            }}
            withPortal
            inline
            minDate={new Date()}
            maxDate={undefined}
            onClickOutside={toggleStartDateCalendar}
          />
        )}
      </div>
    </div>
  );
};

const StringOrObject = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
]);

SinglePublishDate.propTypes = {
  startPostingDate: PropTypes.string,
  updateStartPublicationDate: PropTypes.func.isRequired,
  startDateLabel: StringOrObject.isRequired,
};

export default withThemedStyle(styles)(SinglePublishDate);
