export default (theme) => ({
  inputIconWrap: {
    paddingBottom: 30,
    paddingTop: 30,
    display: "flex",
    flexDirection: "row",
    alignItems: "line",
  },
  inputIcon: {
    background: theme.BulletTextInput.inputIconBackgroundColor,
    paddingRight: 25,
    marginTop: 7,
    "& > span": {
      "&::before": {
        color: theme.BulletTextInput.inputIconSpanBeforeColor,
        fontSize: 10,
      },
    },
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inputWithIcon: {
    border: 0,
    outline: 0,
    background: theme.BulletTextInput.inputWithIconBackground,
    fontFamily: theme.Fonts.baseFontFamily,
    fontSize: 17,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: 0.06,
    color: theme.BulletTextInput.inputWithIconColor,
    flex: 1,
  },
});
