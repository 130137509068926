export default (theme) => ({
  container: {
    width: "auto",
    minWidth: 120, // minimum 2 template items
    height: 84,
    borderRadius: 5,
    boxShadow: `0 7px 7px -3px ${theme.ChangeTemplate.containerBoxShadow}`,
    backgroundColor: theme.ChangeTemplate.containerBackgroundColor,
    marginBottom: 10,
    position: "absolute",
    top: -94,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 17,
    paddingRight: 17,
    "&:after": {
      top: "100%",
      left: "50%",
      width: 0,
      height: 0,
      content: '""',
      position: "absolute",
      borderTop: `solid 6px ${theme.ChangeTemplate.containerAfterBorderTopColor}`,
      marginLeft: -5,
      borderLeft: `solid 5px ${theme.ChangeTemplate.containerAfterBorderLeftColor}`,
      borderRight: `solid 5px ${theme.ChangeTemplate.containerAfterBorderRightColor}`,
    },
  },
  templateItem: {
    flex: 1,
    cursor: "pointer",
    fontFamily: "Roboto",
    fontSize: 10,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "condensed",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: theme.ChangeTemplate.templateItemColor,
    textTransform: "uppercase",
    "&:hover": {
      color: theme.ChangeTemplate.templateItemHoverColor,
      fontWeight: "bold",
    },
  },
  selectedTemplateItem: {
    color: theme.ChangeTemplate.selectedTemplateItemColor,
    fontWeight: "bold",
  },
});
