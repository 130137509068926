import gql from "graphql-tag";

export const GET_DEGREE_BADGES = gql`
  query getDegreeBadges($degreeIds: [ID!], $tagIds: [ID!]) {
    admin {
      badges(degreeIds: $degreeIds, tagIds: $tagIds) {
        badgeId
        title
        pic
        tags {
          tagId
          title
        }
      }
    }
  }
`;
