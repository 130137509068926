import React from "react";
import { isShiseido } from "../../configs/client";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Permissions } from "./permissions";
// import { USER_ROLE_NAMES } from '../../configs/constants'

// Temporary check method till we do the configuration files of all mapping between role
// permissions, actionright per client
const HasUserExecutionRights = (userRoles, permissionName) => {
  // TO DO Get permission name execution right EXECUTE, DISABLED, NO ACCESS
  // Example : executionRight && executionRight !== ExecutionRights.EXECUTE
  let hasExecution = true;

  if (
    isShiseido &&
    permissionName === Permissions.PAGE_PUBLISH_LESSON.SET_PRIVACY_BUTTON
  )
    hasExecution = false;

  return hasExecution;
};

const CanExecuteAction = (props) => {
  const { children, permissionName } = props;
  if (HasUserExecutionRights(props.userRoles, permissionName)) {
    return <>{children}</>;
  } else {
    return <></>;
  }
};

const mapStateToProps = (state) => ({
  userRoles: state.auth.profileInfo.roles,
});

CanExecuteAction.propTypes = {
  permissionName: PropTypes.string,
};

export default connect(mapStateToProps)(CanExecuteAction);
