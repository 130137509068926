import React from "react";
import Main from "./components/Main";
import Router from "./containers/Router/Router";
import { ThemeProvider } from "react-jss";
import theme from "./constants/theme";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import GetCommonData from "./components/GetCommonData";
import {
  StylelessModalContainer,
  ThemeProvider as GeniusUIThemeProvider,
} from "genius-ui";

const App = ({ routes }) => (
  <ThemeProvider theme={theme}>
    <GeniusUIThemeProvider>
      <DndProvider backend={HTML5Backend}>
        <div>
          <Main>
            <GetCommonData />
            <Router routes={routes} />
          </Main>
        </div>
        <StylelessModalContainer />
      </DndProvider>
    </GeniusUIThemeProvider>
  </ThemeProvider>
);

export default App;
