export const PeanutStyle = ({ Peanut, FontFamily, TextSizes, Colors }) => {
  const switcherOptions = {
    cursor: "pointer",
    fontFamily: "Roboto",
    fontSize: 10,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "condensed",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: Peanut.switcherColor,
    "&:hover": {
      color: Peanut.switcherColorHover,
      fontWeight: "bold",
    },
  };

  const inputsCommonStyle = {
    width: "calc(100% - 60px)",
    minHeight: 50,
    maxHeight: 100,
    outline: "none",
    color: Peanut.input.color,
    transition: "box-shadow .2s linear",
    overflow: "hidden",
    textOverflow: "ellipsis",
    font: {
      family: FontFamily,
      size: TextSizes.ImageDescription,
      weight: "normal",
      style: "normal",
      stretch: "normal",
    },
    lineHeight: 1.33,
    letterSpacing: "normal",
    "&:placeholder": {
      font: {
        family: FontFamily,
        size: TextSizes.ImageDescription,
      },
      color: Peanut.input.placeHolderColor,
    },
    "&:empty:not(:focus):before": {
      content: '"Description"',
      color: Peanut.input.placeHolderColor,
    },
    "&:focus": {
      borderBottom: `solid 1px ${Peanut.input.focusedBorderColor}`,
    },
  };

  return {
    sliderContainer: {
      minHeight: 487,
      maxHeight: 487,
      height: 487,
      minWidth: 384,
      maxWidth: 384,
      width: 384,
      display: "flex",
      flexDirection: "column",
      backgroundImage: (props) =>
        props.image && props.image !== ""
          ? `${Peanut.defaultBackgroundImage}, url("${props.image}")`
          : `${Peanut.defaultBackgroundImage}, url("${props.defaultSliderImage}")`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      border: (props) =>
        props.isSelected ? `solid 1px ${Peanut.selectedBorderColor}` : "none",
      borderRadius: 14,
    },
    uploadControlsBox: {
      position: "absolute",
      fontFamily: FontFamily,
      fontSize: 14,
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: 0.07,
      color: Peanut.actionButtonColor,
      zIndex: 1,
      display: ({ isSelected }) => (isSelected ? "flex" : "none"),
      justifyContent: "flex-start",
      top: 0,
      left: 0,
      width: "100%",
      paddingTop: 18,
      backgroundImage: Peanut.actionButtonsContainerBackgroundImage,
      borderRadius: "14px 14px 0px 0px",
    },
    leftButtonsBox: {
      display: "flex",
      flex: 1,
    },
    uploadBtnWrapper: {
      position: "relative",
      overflow: "hidden",
      display: ({ isSelected }) => (isSelected ? "inline-block" : "none"),
      cursor: "pointer",
      "&:hover": {
        cursor: "pointer",
      },
    },
    uploadInput: {
      fontSize: "100px",
      position: "absolute",
      left: 0,
      top: 0,
      opacity: 0,
      "&:hover": {
        cursor: "pointer",
      },
    },
    btnUpload: {
      border: "none",
      fontFamily: FontFamily,
      fontSize: 14,
      background: "transparent",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "0.1px",
      color: Peanut.actionButtonColor,
      cursor: "pointer",
      "&:hover": {
        cursor: "pointer",
      },
      paddingLeft: 15,
      marginRight: 10,
      "& > span": {
        paddingRight: 5,
        "&:hover::before": {
          color: Colors.lightBlue,
          cursor: "pointer",
        },
      },
      "&:hover *": {
        color: Colors.lightBlue,
        cursor: "pointer",
        "&::before": {
          color: Colors.lightBlue,
          cursor: "pointer",
        },
      },
    },
    iconAction: {
      cursor: "pointer",
      "&:hover::before": {
        color: `${Colors.lightBlue}`,
        fontSize: 15,
        cursor: "pointer",
      },
      "&::before": {
        color: `${Colors.white}`,
        fontSize: 15,
        cursor: "pointer",
      },
    },
    deleteBox: {
      fontFamily: FontFamily,
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "baseline",
      alignSelf: "flex-start",
      position: "absolute",
      top: 20,
      left: 20,
      cursor: "pointer",
      "& > span": {
        fontSize: 18,
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: Peanut.actionButtonColor,
        "&::before": {
          color: Peanut.actionButtonColor,
          fontSize: 15,
          padding: 5,
        },
      },
      "&:hover *": {
        color: Peanut.actionButtonHoverColor,
        "&::before": {
          color: Peanut.actionButtonHoverColor,
        },
      },
    },
    addBoxRight: {
      position: "absolute",
      top: "calc(100%/2+ 5)",
      right: -25,
      zIndex: 1,
      cursor: "pointer",
    },
    addBoxLeft: {
      position: "absolute",
      top: "calc(100%/2+ 5)",
      left: -24,
      zIndex: 1,
      cursor: "pointer",
    },
    textEditing: {
      position: "absolute",
      bottom: 60,
      height: 160,
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginLeft: 20,
      marginRight: 20,
      justifyContent: "flex-end",
    },
    legendEditing: {
      backgroundColor: Peanut.input.color,
      position: "absolute",
      bottom: 0,
      height: 135,
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      borderBottomLeftRadius: 14,
      borderBottomRightRadius: 14,
    },
    titleInput: {
      width: "calc(100% - 60px)",
      maxHeight: 90,
      outline: "none",
      color: Peanut.input.color,
      transition: "box-shadow .2s linear",
      overflow: "hidden",
      textOverflow: "ellipsis",
      font: {
        family: FontFamily,
        size: TextSizes.PeanutImageTitle,
        weight: "bold",
        style: "normal",
        stretch: "normal",
      },
      lineHeight: 1,
      letterSpacing: "normal",
      "&:placeholder": {
        font: {
          family: FontFamily,
          size: TextSizes.PeanutImageTitle,
        },
        color: Peanut.input.color,
      },
      "&:empty:not(:focus):before": {
        content: '"Title"',
        color: Peanut.input.placeHolderColor,
      },
      "&:focus": {
        borderBottom: `solid 2px ${Peanut.input.focusedBorderColor}`,
      },
    },
    descriptionInput: inputsCommonStyle,
    legendInput: {
      ...inputsCommonStyle,
      color: Peanut.legendInputColor,
      "&:placeholder": {
        color: Peanut.legendInputColor,
      },
      "&:empty:not(:focus):before": {
        content: '"Legend"',
        color: Peanut.legendInputColor,
      },
      "&:focus": {
        borderBottom: `solid 1px ${Peanut.legendInputColor}`,
      },
    },
    cardIndex: {
      display: "flex",
      position: "absolute",
      top: 20,
      right: 20,
      width: 39,
      height: 23,
      borderRadius: 15,
      backgroundColor: Peanut.cardIndex.backgroundColor,
      fontFamily: "Roboto",
      fontSize: 12,
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.08,
      letterSpacing: "normal",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      color: Peanut.cardIndex.color,
    },
    cardTypeSwitcher: {
      width: 258,
      height: 84,
      borderRadius: 5,
      boxShadow: `0 7px 7px -3px ${Peanut.cardTypeSwitcher.boxShadowColor}`,
      backgroundColor: Peanut.cardTypeSwitcher.backgroundColor,
      marginBottom: 10,
      position: "absolute",
      top: -94,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: 17,
      paddingRight: 17,
      "&:after": {
        top: "100%",
        left: "50%",
        width: 0,
        height: 0,
        content: '""',
        position: "absolute",
        borderTop: `solid 6px ${Peanut.cardTypeSwitcher.backgroundColor}`,
        marginLeft: -5,
        borderLeft: "solid 5px transparent",
        borderRight: "solid 5px transparent",
      },
    },
    switcherOptions,
    switcherActive: {
      ...switcherOptions,
      color: Peanut.cardTypeSwitcher.activeColor,
      fontWeight: "bold",
    },
  };
};
