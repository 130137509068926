import React from "react";
import Style from "./LessonsWidgetShiseido.style.js";
import { FormattedMessage } from "react-intl";
import WhiteAccordion from "../../common/WhiteAccordion/WhiteAccordion";
import ProgramsWidgetLessonRowShiseido from "./LessonRow";
import { lessonsWidgetShiseidoTranslations as translations } from "./LessonsWidgetShiseido.translations";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import { Icon, Icons } from "genius-ui";

const useStyle = createUseStyles(Style);

const LessonsWidgetShiseido = (props) => {
  const { allLessons, sortAllLessonByUserCompletion, exportLesson } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <FormattedMessage {...translations.LessonNameLabel} />
        <FormattedMessage {...translations.ProgramNameLabel} />
        <FormattedMessage {...translations.DegreeNameLabel} />
        <span
          className={classes.sortableColumn}
          onClick={sortAllLessonByUserCompletion}
        >
          <div>
            <FormattedMessage {...translations.LessonCompletionRateLabel} />
          </div>
          <div>
            <Icon iconName={Icons.sort} />
          </div>
        </span>
        <FormattedMessage {...translations.LessonUserStoppedLabel} />
        <FormattedMessage {...translations.TotalPageLabel} />
        <span />
      </div>
      <WhiteAccordion
        className={classes.accordionContainer}
        preExpanded={allLessons[0].uuid}
        items={allLessons}
        renderContent={(item) => (
          <div className={classes.accordionContentContainer}>
            {item.lessons.length
              ? item.lessons.map((lesson) => (
                  <ProgramsWidgetLessonRowShiseido
                    key={lesson.id + "_" + lesson.programId}
                    lesson={lesson}
                    exportLesson={() => exportLesson(lesson)}
                  />
                ))
              : null}
          </div>
        )}
      />
    </div>
  );
};

export default LessonsWidgetShiseido;
