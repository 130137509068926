import gql from "graphql-tag";

export const GET_DEGREE_LESSONS = gql`
  query getDegreeLessons($degreeId: ID) {
    admin {
      degrees(degreeId: $degreeId) {
        degreeId
        versions {
          lang
          programs {
            programId
            lessons {
              lessonId
              title
              picCover
              workflow
              summary
            }
          }
        }
      }
    }
  }
`;
