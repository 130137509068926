import uuidV4 from "uuid/dist/v4";
import {
  sort2LessonsByValidationNb,
  sort2ProgramsByValidationNb,
} from "./actions";

const getCompletionPercentage = (completed, total) =>
  parseInt((completed / total) * 100);

export const kpiLearningDocumentToKpiLearningPageData = (response) => {
  const kpiLearningPageData = {
    ...response,
    programs: response.programs
      ? response.programs
          .map((program) => {
            const allowedUsersTotalNb = program.lessons?.reduce(
              (acc, { allowedUsersNb }) => acc + allowedUsersNb,
              0,
            );
            return {
              ...program,
              uuid: uuidV4(),
              // TODO: Fix program.stats.totalLessonNb in back and apply it here: getCompletionPercentage(a, here)
              lessonCompletionPercentage: getCompletionPercentage(
                program.stats.validatedLessonNb,
                allowedUsersTotalNb,
              ),
              lessons: program.lessons
                .map((lesson) => ({
                  ...lesson,
                  usersCompletionPercentage: getCompletionPercentage(
                    lesson.stats.validationNb,
                    lesson.allowedUsersNb,
                  ),
                  programId: program.id,
                  programName: program.name,
                  degreeName: program.degreeName,
                }))
                .sort((a, b) => sort2LessonsByValidationNb(a, b, true)),
            };
          })
          .sort((a, b) => sort2ProgramsByValidationNb(a, b, true))
      : [],
    programsNb: response.programs ? response.programs.length : 0,
    lessonsNb:
      response.programs && response.programs.length > 0
        ? response.programs
            .map((a) =>
              a.lessons && a.lessons.length > 0 ? a.lessons.length : 0,
            )
            .reduce((a, b) => a + b)
        : 0,
  };
  kpiLearningPageData.allLessons = [
    {
      uuid: "1",
      lessons: kpiLearningPageData.programs
        .reduce((lessons, program) => {
          return [...lessons, ...program.lessons];
        }, [])
        .sort((a, b) => sort2LessonsByValidationNb(a, b, true)),
    },
  ];
  return kpiLearningPageData;
};
