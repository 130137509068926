import React from "react";
import { DegreesBuilderStyle } from "../../DegreeBuilder.style";
import { connect } from "react-redux";
import {
  SetCurrentLessonId,
  SetActiveMenuLessonId,
  SetCurrentEditProgramId,
  GetDegreePrograms,
  SetProgramLessons,
  SetLastExpandedProgramId,
  SetProgramExpandedStatus,
  DeleteLesson,
  DeleteProgram,
  ReFetchProgramLessons,
  LessonMoveUp,
  LessonMoveDown,
  SetCurrentDegreeProgramContent,
} from "../../../../store/degree/actions";
import {
  LESSON_ADD_TAGS,
  LESSON_UPDATE_PIC_CARD,
  LESSON_UPDATE_PIC_COVER,
  LESSON_UPDATE_PIC_THUMB,
} from "../../../../graphql/remote/lessons/mutations";
import { withRouter } from "react-router";
import { Icon, Icons, FullscreenLoader } from "genius-ui";
import ProgramInfo from "./programInfo";
import { getPath } from "../../../../core/paths";
import withThemedStyle from "../../../../components/hoc/withThemedStyle";
import clientConfig, { isShiseido } from "../../../../configs/client";
import { DEGREE_PARTS } from "../../../../configs/constants";
import { SetCurrentCloningIds } from "../../../../store/masterTemplate/actions";
import DeleteModal from "../../../../components/common/DeleteModal";
import { FormattedMessage } from "react-intl";
import { translations } from "../../DegreeBuilderBackup.translations";
import { SetSuccessMessage } from "../../../../store/notification/actions";
import LessonRelocationModal from "../../../../components/common/lessonRelocationModal";
import { LANGUAGES } from "../../../../configs/referential";
import { graphql } from "@apollo/react-hoc";
import {
  ADD_LESSON_TO_PROGRAM,
  LESSON_CREATE,
  PROGRAM_UPDATE_SUMMARY,
  PROGRAM_UPDATE_TITLE,
  REMOVE_LESSON_FROM_PROGRAM,
} from "../../../../graphql/remote/degrees/mutations";
import compose from "lodash/flowRight";
import SendNotificationModal from "../../../../components/common/SendNotificationModal";
import {
  SetNotificationData,
  SetShowModal as SetShowNotificationModal,
} from "../../../../store/sendNotification/actions";
import { PlaceHolderDegree } from "assets/icons";

const addLessonToProgram = graphql(ADD_LESSON_TO_PROGRAM, {
  props: ({ mutate }) => ({
    addLessonToProgram: (programId, lang, lessonId, onSuccess) =>
      mutate({
        variables: { programId, lang, lessonIds: [lessonId] },
        update: () => onSuccess(),
      }),
  }),
});

const removeLessonFromProgram = graphql(REMOVE_LESSON_FROM_PROGRAM, {
  props: ({ mutate }) => ({
    removeLessonFromProgram: (programId, lang, lessonId) =>
      mutate({
        variables: { programId, lang, lessonIds: [lessonId] },
      }),
  }),
});

const updateTitle = graphql(PROGRAM_UPDATE_TITLE, {
  props: ({ mutate }) => ({
    updateTitle: (programId, lang, data) =>
      mutate({
        variables: { programId: programId, lang: lang, data: data },
      }),
  }),
});

const updateSummary = graphql(PROGRAM_UPDATE_SUMMARY, {
  props: ({ mutate }) => ({
    updateSummary: (programId, lang, data) =>
      mutate({
        variables: { programId: programId, lang: lang, data: data },
      }),
  }),
});

const createLesson = graphql(LESSON_CREATE, {
  props: ({ mutate }) => ({
    createLesson: (programId, lang, title) =>
      mutate({
        variables: { programId: programId, lang: lang, title: title },
      }),
  }),
});

const updatePicCover = graphql(LESSON_UPDATE_PIC_COVER, {
  props: ({ mutate }) => ({
    updatePicCover: (lessonId, lang, data) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, data: data },
      }),
  }),
});

const updatePicCard = graphql(LESSON_UPDATE_PIC_CARD, {
  props: ({ mutate }) => ({
    updatePicCard: (lessonId, lang, data) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, data: data },
      }),
  }),
});

const updatePicThumb = graphql(LESSON_UPDATE_PIC_THUMB, {
  props: ({ mutate }) => ({
    updatePicThumb: (lessonId, lang, data) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, data: data },
      }),
  }),
});

const addTagsToLesson = graphql(LESSON_ADD_TAGS, {
  props: ({ mutate }) => ({
    addLessonTags: (lessonId, lang, tagIdList) =>
      mutate({
        variables: { lessonId: lessonId, lang: lang, tagIdList: tagIdList },
      }),
  }),
});

const getMarketTagId = (tags) => {
  const marketTag = tags.find(({ clusterId }) =>
    clientConfig.USER_MARKET_CLUSTER_IDS.includes(Number(clusterId)),
  );

  return marketTag?.tagId;
};

class DegreeBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteItemData: {
        id: null,
        type: null,
        language: this.props.match.params.lang,
      },
      showLessonRelocationModal: false,
      lessonRelocationData: {
        lessonId: null,
        fromProgramId: null,
      },
    };
  }

  componentDidMount() {
    const { props } = this;
    const {
      match: { params },
    } = this.props;
    props.GetDegreePrograms(params.degreeId, params.lang);
  }

  handleBack = () => this.props.history.goBack();

  navigateToLessonPublishPage = (lessonId) => {
    const {
      match: { params },
    } = this.props;
    const endpoint =
      isShiseido && this.props.publishDegree.isMasterDegree
        ? "editMasterTemplateLesson"
        : "publishLesson";
    this.props.history.push(
      getPath(endpoint, params.degreeId, lessonId, params.lang),
    );
  };

  navigateToAddToMarketPage = (lessonId, programId, degreeId) => {
    this.props.SetCloningContext(degreeId, programId, lessonId);
    const {
      match: {
        params: { lang },
      },
    } = this.props;
    this.props.history.push(
      getPath("documentAddToMarket", DEGREE_PARTS.LESSON, lessonId, lang),
    );
  };

  setDeleteItemData = (id, type, programId) => {
    this.setState({
      deleteItemData: { ...this.state.deleteItemData, id, type, programId },
    });
  };

  deleteItemHandler = () => {
    switch (this.state.deleteItemData.type) {
      case DEGREE_PARTS.LESSON:
        this.props.DeleteLesson(this.state.deleteItemData);
        break;
      case DEGREE_PARTS.PROGRAM:
        this.props.DeleteProgram(
          this.state.deleteItemData,
          this.props.match.params.degreeId,
        );
    }
    this.setDeleteItemData(null);
  };

  setLessonRelocationData = (lessonId, fromProgramId) => {
    this.setState(
      {
        lessonRelocationData: {
          lessonId,
          fromProgramId,
        },
      },
      () => this.setState({ showLessonRelocationModal: true }),
    );
  };

  moveLessonToProgram = (toProgramId) => {
    this.setState({ degreeIsUpdating: true });
    this.props.MoveLessonToProgram(
      this.state.lessonRelocationData.lessonId,
      this.state.lessonRelocationData.fromProgramId,
      toProgramId,
      this.props.match.params.lang,
      this.props.addLessonToProgram,
      this.props.removeLessonFromProgram,
      () => {
        this.setState({ showLessonRelocationModal: false });
        this.setState({ degreeIsUpdating: false });
      },
    );
  };

  updateLessonRelocationModalVisibility = (showLessonRelocationModal) => () =>
    this.setState({ showLessonRelocationModal });

  render() {
    if (this.props.isLoaderActive) {
      return <FullscreenLoader />;
    }

    const props = this.props;
    const {
      classes,
      match: {
        params: { degreeId, lang },
      },
    } = props;
    const marketTagId = getMarketTagId(props.publishDegree.tags);
    const handlers = {
      updateTitle: props.updateTitle,
      updateSummary: props.updateSummary,
      addLessonTags: props.addLessonTags,
      createLesson: props.createLesson,
      updatePicCover: props.updatePicCover,
      updatePicCard: props.updatePicCard,
      updatePicThumb: props.updatePicThumb,
    };

    return (
      <div className={classes.rootContainer}>
        <div className={classes.rootContent}>
          <div className={classes.closePage}>
            <Icon
              iconName={Icons.arrowLeft}
              style={classes.closeIcon}
              handleClick={this.handleBack}
            />
          </div>
          <div>
            {props.programContent.map((x) => (
              <ProgramInfo
                key={x.programId}
                program={x}
                handleLessonEdit={(lessonId) => {
                  props.HandleEditLesson(degreeId, lessonId, lang);
                }}
                setActiveMenuLessonId={(lessonId) =>
                  props.setActiveMenuLessonId(lessonId)
                }
                activeMenuLessonId={props.activeMenuLessonId}
                setActiveMenuProgramId={props.setActiveMenuProgramId}
                expandCollapseProgram={() =>
                  props.ExpandCollapseProgram(x.programId, lang, x.expanded)
                }
                navigateToLessonPublishPage={this.navigateToLessonPublishPage}
                navigateToAddToMarketPage={this.navigateToAddToMarketPage}
                programCnt={props.programContent.length}
                lessonMoveUp={(lessonId) =>
                  props.LessonMoveUp(lang, x.programId, lessonId)
                }
                lessonMoveDown={(lessonId) =>
                  props.LessonMoveDown(lang, x.programId, lessonId)
                }
                degreeId={degreeId}
                programId={x.programId}
                isMasterContent={props.publishDegree.isMasterDegree}
                isTraineRole={true}
                setDeleteItemData={this.setDeleteItemData}
                currentEditingProgramId={props.currentEditingProgramId}
                showLessonRelocationModal={(lessonId, fromProgramId) =>
                  this.setLessonRelocationData(lessonId, fromProgramId)
                }
                setNotificationData={props.SetNotificationData}
                setShowNotificationModal={props.SetShowNotificationModal}
                handleProgramInfoSave={() =>
                  props.UpdateProgramInfo(x, lang, handlers)
                }
                onTitleChange={(value) =>
                  props.EditProgramTitle(value, x, props.programContent)
                }
                onSummaryChange={(value) =>
                  props.EditProgramSummary(value, x, props.programContent)
                }
                handleLessonCreate={(lessonTitle) =>
                  props.AddProgramLesson(
                    lessonTitle,
                    x,
                    props.programContent,
                    lang,
                    handlers,
                    props.publishDegree.isMasterDegree,
                    marketTagId,
                  )
                }
                goToBadgesScreen={(lessonId) =>
                  this.props.history.push(
                    getPath("lessonBadges", degreeId, lessonId, lang),
                    {
                      wordingModalKey: "SuccessfulPublishedMessage",
                      backTo: `/degree/build/${degreeId}/${lang}`,
                    },
                  )
                }
              />
            ))}
          </div>
        </div>
        <DeleteModal
          deleteMessage={
            <FormattedMessage
              {...translations.DeleteMessage}
              values={{ mark: "?" }}
            />
          }
          actionHandler={this.deleteItemHandler}
          setShowModal={() => this.setDeleteItemData(null)}
          showModal={!!this.state.deleteItemData.id}
        />
        <LessonRelocationModal
          visible={this.state.showLessonRelocationModal}
          hideModal={this.updateLessonRelocationModalVisibility(false)}
          programs={props.programContent}
          actualProgramId={this.state.lessonRelocationData.fromProgramId}
          onConfirm={(programId) => this.moveLessonToProgram(programId)}
          degreeLanguage={LANGUAGES.find((x) => x.Code === lang).Name}
          degreeName={props.publishDegree.title}
        />
        <FormattedMessage {...translations.NotificationModalMessagePlaceholder}>
          {(message) => (
            <SendNotificationModal
              title={
                <FormattedMessage {...translations.NotificationModalTitle} />
              }
              description={
                <FormattedMessage
                  {...translations.NotificationModalDescription}
                />
              }
              placeholder={message[0]}
            />
          )}
        </FormattedMessage>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  programContent: state.degrees.programContent,
  activeMenuLessonId: state.degrees.activeMenuLessonId,
  isLoaderActive: state.navigation.isLoaderActive,
  publishDegree: state.degrees.publishDegree,
  currentEditingProgramId: state.degrees.currentEditingProgramId,
});

const mapDispatchToProps = (dispatch) => ({
  HandleEditLesson: (degreeId, lessonId, language) => {
    dispatch(SetCurrentLessonId(lessonId));
    window.open(`/lessons/${degreeId}/${lessonId}/${language}`, "_blank");
  },
  setActiveMenuLessonId: (lessonId) => {
    dispatch(SetActiveMenuLessonId(lessonId));
  },
  setActiveMenuProgramId: (programId) => {
    dispatch(SetCurrentEditProgramId(programId));
  },
  GetDegreePrograms: (degreeId, lang, onSuccess) => {
    dispatch(GetDegreePrograms(degreeId, lang, onSuccess));
  },
  ExpandCollapseProgram: (programId, lang, isExpanded) => {
    if (isExpanded) {
      dispatch(SetLastExpandedProgramId(undefined));
      dispatch(SetProgramExpandedStatus(programId, !isExpanded));
    } else {
      dispatch(SetLastExpandedProgramId(programId));
      dispatch(
        SetProgramLessons(programId, lang, () => {
          dispatch(SetProgramExpandedStatus(programId, !isExpanded));
        }),
      );
    }
  },
  SetCloningContext: (degreeId, programId, lessonId) => {
    dispatch(SetCurrentCloningIds(degreeId, programId, lessonId));
  },
  DeleteLesson: ({ id, language, programId }) => {
    dispatch(
      DeleteLesson(id, language, () => {
        dispatch(ReFetchProgramLessons(programId, language));
      }),
    );
  },
  DeleteProgram: ({ id, language }, degreeId) => {
    dispatch(
      DeleteProgram(id, language, () => {
        dispatch(
          GetDegreePrograms(degreeId, language, () => {
            dispatch(
              SetSuccessMessage(
                <FormattedMessage
                  {...translations.SuccessfullyDeleteProgramMessage}
                />,
              ),
            );
          }),
        );
      }),
    );
  },
  LessonMoveUp: (lang, programId, lessonId) => {
    dispatch(
      LessonMoveUp(lang, programId, lessonId, () => {
        dispatch(ReFetchProgramLessons(programId, lang));
      }),
    );
  },
  LessonMoveDown: (lang, programId, lessonId) => {
    dispatch(
      LessonMoveDown(lang, programId, lessonId, () => {
        dispatch(ReFetchProgramLessons(programId, lang));
      }),
    );
  },
  MoveLessonToProgram: (
    lessonId,
    fromProgramId,
    toProgramId,
    lang,
    addLessonRequest,
    removeLessonRequest,
    onSuccess,
  ) => {
    addLessonRequest(toProgramId, lang, lessonId, () => {
      removeLessonRequest(fromProgramId, lang, lessonId).then(() => {
        dispatch(ReFetchProgramLessons(fromProgramId, lang)).then(() => {
          dispatch(ReFetchProgramLessons(toProgramId, lang)).then(() => {
            onSuccess();
            dispatch(
              SetSuccessMessage(
                <FormattedMessage {...translations.LessonRelocationMessage} />,
              ),
            );
          });
        });
      });
    });
  },
  SetNotificationData: (newValue) => {
    dispatch(SetNotificationData(newValue));
  },
  SetShowNotificationModal: (newValue) => {
    dispatch(SetShowNotificationModal(newValue));
  },
  UpdateProgramInfo: (program, lang, handlers) => {
    handlers.updateTitle(program.programId, lang, program.title);
    if (program.summary) {
      handlers.updateSummary(program.programId, lang, program.summary);
    }
  },
  EditProgramTitle: (title, program, programsList) => {
    program.title = title;
    let index = programsList.findIndex(
      (x) => x.programId === program.programId,
    );
    programsList[index] = { ...program };
    dispatch(SetCurrentDegreeProgramContent([...programsList]));
  },
  EditProgramSummary: (summary, program, programsList) => {
    program.summary = summary;
    let index = programsList.findIndex(
      (x) => x.programId === program.programId,
    );
    programsList[index] = { ...program };
    dispatch(SetCurrentDegreeProgramContent([...programsList]));
  },
  AddProgramLesson: (
    title,
    program,
    programsList,
    lang,
    handlers,
    isMasterContent,
    marketId,
  ) => {
    handlers
      .createLesson(program.programId, lang, title)
      .then(async ({ data }) => {
        const { lessonId } = data.admin.lesson.create;
        handlers.updatePicCover(lessonId, lang, PlaceHolderDegree);
        handlers.updatePicCard(lessonId, lang, PlaceHolderDegree);
        handlers.updatePicThumb(lessonId, lang, PlaceHolderDegree);
        handlers.addLessonTags(
          data.admin.lesson.create.lessonId,
          lang,
          marketId,
        );
        if (isMasterContent) {
          handlers.addLessonTags(
            data.admin.lesson.create.lessonId,
            lang,
            clientConfig.MASTER_DEGREE_TAG_ID
              ? [clientConfig.MASTER_DEGREE_TAG_ID]
              : [],
          );
        }
        dispatch(ReFetchProgramLessons(program.programId, lang));
      });
  },
});

const Container = compose(
  addLessonToProgram,
  removeLessonFromProgram,
  updateTitle,
  updateSummary,
  addTagsToLesson,
  createLesson,
  updatePicCover,
  updatePicCard,
  updatePicThumb,
)(withThemedStyle(DegreesBuilderStyle)(DegreeBuilder));

export default withRouter(
  connect(mapStateToProp, mapDispatchToProps)(Container),
);
