import React, { useCallback, useEffect } from "react";
import { defaultStyles } from "./kpiBattle.style";
import withThemedStyle from "../../components/hoc/withThemedStyle";
import { connect } from "react-redux";
import compose from "lodash/fp/compose";
import { Button, ButtonVariation, ButtonSize } from "genius-ui";
import { FormattedMessage } from "react-intl";
import { translations } from "./kpiBattle.translations";
import BattleLeaderBoard from "../results/battle/battleLeaderboard";
import BattlePublishedTable from "./battlePublishedTable";
import { getPath } from "../../core/paths";
import { KPIPageContainer } from "components/KPI";

import {
  GetKPIBattleData,
  GetKPIBattlePublished,
  ClearKPIBattlePublished,
  ResetStore,
  UpdateLeaderBoardColumnSort,
  UpdatePublishedBattleColumnSort,
  getFilterTagIds,
  extendWithPublishedBattleSortVariables,
} from "../../store/kpiBattle/actions";
import {
  getDefaultUserData,
  GetUsersProfilesData,
} from "../../store/user/actions";
import { SetFetchIndicator } from "../../store/common/actions";
import { exportLeaderBoardAndPublishedBattles } from "../../utils/excelExport/kpiBattle";
import client from "../../graphql/client";
import { GET_GLOBAL_BATTLE_LEADER_BOARD_DATA } from "../../graphql/remote/kpi/queries/battleGlobal.graphql";
import {
  GetFormattedGlobalBattleData,
  GetFormattedPublishedBattleData,
} from "../../store/kpiBattle/transformers";
import { extendWithGlobalSortVariables } from "../../store/battleResult/actions";
import { GET_PUBLISHED_BATTLES } from "../../graphql/remote/battle/queries/GetPublishedBattleIds.graphql";
import clientConfig, { isShiseido } from "../../configs/client";
import { SetIsLoading } from "store/kpiFilter/actions";

const LIMIT = 50;

const getPublishedBattles = async (filters, publishedBattlesColumnSort) => {
  let resultContents = [];
  let lengthResult = 0;
  let skip = 0;

  do {
    const variables = {
      tagIds: getFilterTagIds(filters),
      skip: skip,
      limit: LIMIT,
      intervalId: filters.interval.selectedValues[0]?.value,
      ...extendWithPublishedBattleSortVariables({}, publishedBattlesColumnSort),
    };
    if (!filters.interval.selectedValues[0]) {
      variables.startDate = filters.startDate.selectedValues;
      variables.endDate = filters.endDate.selectedValues;
    }
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_PUBLISHED_BATTLES,
      variables,
      notifyOnNetworkStatusChange: true,
    });

    resultContents = resultContents.concat(
      GetFormattedPublishedBattleData(result.data.admin.battles),
    );
    lengthResult = result.data.admin.globalLeaderboard;
    skip += lengthResult;
  } while (lengthResult === LIMIT);
  return resultContents;
};

const format = (toFilter, clusterIds) =>
  toFilter
    .filter(({ clusterId }) => clusterIds.includes(Number(clusterId)))
    .map(({ title }) => title);

const getUsersData = async (filters, leaderBoardColumnSort) => {
  let resultContents = [];
  let lengthResult = 0;
  let skip = 0;

  do {
    let variables = {
      tagIds: getFilterTagIds(filters),
      skip: skip,
      limit: LIMIT,
      intervalId: filters.interval.selectedValues[0]?.value,
    };
    if (!filters.interval.selectedValues[0]) {
      variables.startDate = filters.startDate.selectedValues;
      variables.endDate = filters.endDate.selectedValues;
    }

    variables = extendWithGlobalSortVariables(variables, leaderBoardColumnSort);
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_GLOBAL_BATTLE_LEADER_BOARD_DATA,
      variables,
      notifyOnNetworkStatusChange: true,
    });

    resultContents = resultContents.concat(
      GetFormattedGlobalBattleData(result.data.admin.globalLeaderboard),
    );
    lengthResult = result.data.admin.globalLeaderboard;
    skip += lengthResult;
  } while (lengthResult === LIMIT);

  const userIds = resultContents.map(({ key }) => key);
  const usersResult = await getDefaultUserData(userIds);
  const profiles = usersResult.reduce((acc, cur) => {
    acc[cur.userId] = {
      user: {
        email: cur.profile.mail,
        market: [cur.profileCustom[1].tags.map(({ title }) => title).join()],
      },
      relationship: [
        cur.profileCustom[0].tags.map(({ title }) => title).join(),
      ],
      retailers: format(cur.tags, clientConfig.RETAILER_TAGS_CLUSTER_IDS),
      brands: format(cur.tags, clientConfig.BRAND_TAGS_CLUSTER_IDS),
    };
    return acc;
  }, {});
  return { results: resultContents, profiles };
};

const BattleResults = (props) => {
  const {
    classes,
    OnSortLeaderBoard,
    GetKPIBattleData,
    results,
    publishedBattles,
    leaderBoardColumnSort,
    publishedBattlesColumnSort,
    OnSortPublishedBattles,
    history,
    kpiFilters,
    ResetStore,
    GetUsersProfilesData,
    loadMoreBattlePublished,
    isFullLoaded,
  } = props;

  useEffect(() => ResetStore, []);

  useEffect(() => {
    GetUsersProfilesData(results.map((result) => Number(result.key)));
  }, [results.length]);

  const goToResultsPage = (battleId) =>
    window.open(getPath("battleResults", battleId), "_blank");
  const goToGlobalResultsPage = () => history.push(getPath("kpiBattleGlobal"));
  const exportFunction = useCallback(async (_filters, intl) => {
    try {
      const { profiles, results } = await getUsersData(
        kpiFilters,
        leaderBoardColumnSort,
      );
      const exportPublishedBattles = await getPublishedBattles(
        kpiFilters,
        leaderBoardColumnSort,
      );

      exportLeaderBoardAndPublishedBattles(
        results,
        profiles,
        exportPublishedBattles,
        intl,
      );
    } catch (e) {
      // TODO: Send Error message in red
      console.error("error export", e);
    }
  }, []);

  return (
    <div className={classes.root}>
      <KPIPageContainer
        onFilterChange={GetKPIBattleData}
        exportFunction={exportFunction}
        useIntervals
      >
        <div className={classes.content}>
          <div className={classes.shiseidoContainerPlaceHolder}>
            {isShiseido && (
              <div className={classes.shiseidoPlaceHolder}>
                <FormattedMessage {...translations.InformUsers} />
              </div>
            )}
          </div>
          <div className="title">
            <FormattedMessage {...translations.LeaderboardTitle} />
            <span className="seeMoreButton" onClick={goToGlobalResultsPage}>
              <FormattedMessage {...translations.SeeMoreButtonLabel} />
            </span>
          </div>
          <div className="tableContainer">
            <BattleLeaderBoard
              onSort={OnSortLeaderBoard}
              columnSort={leaderBoardColumnSort}
              data={results}
            />
          </div>
          <div className="title">
            <FormattedMessage {...translations.PublishedBattleTitle} />
          </div>
          <div className="tableContainer">
            <BattlePublishedTable
              onSort={OnSortPublishedBattles}
              columnSort={publishedBattlesColumnSort}
              data={publishedBattles}
              goToResultsPage={goToResultsPage}
            />
            {!isFullLoaded && (
              <div className={classes.loadMoreButton}>
                <Button
                  variation={ButtonVariation.primary}
                  size={ButtonSize.small}
                  handleClick={loadMoreBattlePublished}
                >
                  <FormattedMessage {...translations.LoadMoreButton} />
                </Button>
              </div>
            )}
          </div>
        </div>
      </KPIPageContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  results: state.kpiBattle.results,
  publishedBattles: state.kpiBattle.publishedBattles,
  leaderBoardColumnSort: state.kpiBattle.leaderBoardColumnSort,
  publishedBattlesColumnSort: state.kpiBattle.publishedBattlesColumnSort,
  isFetching: state.common.fetchIndicator,
  isFullLoaded: state.kpiBattle.publishedBattlesLoadingStatus.isFullLoaded,
  kpiFilters: state.kpi.filters,
  profiles: state.users.profiles,
});

const mapDispatchToProps = (dispatch) => ({
  GetUsersProfilesData: (userIds) => {
    dispatch(GetUsersProfilesData(userIds));
  },
  GetKPIBattleData: () => {
    dispatch(SetIsLoading(true));
    dispatch(SetFetchIndicator(true));
    dispatch(GetKPIBattleData());
    dispatch(
      ClearKPIBattlePublished(() =>
        dispatch(
          GetKPIBattlePublished(() => {
            dispatch(SetFetchIndicator(false));
            dispatch(SetIsLoading(false));
          }),
        ),
      ),
    );
  },
  loadMoreBattlePublished: () => dispatch(GetKPIBattlePublished()),
  ResetStore: () => dispatch(ResetStore()),
  OnSortLeaderBoard: (columnName, sortMethod) =>
    dispatch(UpdateLeaderBoardColumnSort(columnName, sortMethod)),
  OnSortPublishedBattles: (columnName, sortMethod) =>
    dispatch(UpdatePublishedBattleColumnSort(columnName, sortMethod)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withThemedStyle(defaultStyles),
)(BattleResults);
