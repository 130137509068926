import React, { useCallback, useMemo, useState } from "react";
import Market from "../Market/Market";
import clientConfig from "configs/client";
import {
  ADD_TAGS_TO_BADGE,
  CREATE_BADGE,
  GET_BADGES,
  GET_CLONED_DEGREE,
} from "pages/badges/graphs";
import { useQuery } from "react-apollo";
import { useGetTags } from "pages/badges/EditMarketBadge/EditMarketBadge";
import client from "graphql/client";
import { useHistory } from "react-router-dom";
import { getPath } from "core/paths";
import { FullscreenLoader } from "genius-ui";

const saveIt = async (
  title,
  parentBadgeId,
  degreeRef,
  brandId,
  newMarkets,
  history,
) => {
  const newMarketIds = Object.keys(newMarkets);

  if (!newMarketIds.length) return;
  const marketDegrees = await client.query({
    fetchPolicy: "network-only",
    query: GET_CLONED_DEGREE,
    variables: {
      tagIds: newMarketIds,
      cloneParentId: Number(degreeRef),
    },
  });

  for (const marketDegree of marketDegrees.data.admin.documents) {
    if (!(title && parentBadgeId && marketDegree.docId)) return;
    const newBadge = await client.mutate({
      mutation: CREATE_BADGE,
      variables: {
        title,
        parentId: parentBadgeId,
        degreeId: marketDegree.docId,
      },
    });
    await client.mutate({
      mutation: ADD_TAGS_TO_BADGE,
      variables: {
        badgeId: newBadge.data.admin.badge.create.badgeId,
        tagIds: [
          brandId,
          marketDegree.versions[0].tags.find(
            ({ clusterId }) => clusterId === "2",
          ).tagId,
        ],
      },
    });
  }
  history.push(getPath("masterBadges"));
};

const Markets = ({ parentId, degreeRef, brandId, title }) => {
  const { data, loading } = useQuery(GET_BADGES, { variables: { parentId } });
  const history = useHistory();
  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const currentMarkets = useMemo(() => {
    if (!data) return undefined;
    return data.admin.badges.reduce((acc, { tags }) => {
      acc[
        tags.find(({ clusterId }) =>
          clientConfig.USER_MARKET_CLUSTER_IDS.includes(Number(clusterId)),
        ).tagId
      ] = true;
      return acc;
    }, {});
  }, [data]);
  const { data: dd, loading: ll, error } = useGetTags({ clusterId: 2 });
  const [newMarkets, setNewMarkets] = useState({});

  const handleSelectMarket = useCallback((tagId) => {
    setNewMarkets((prevState) => {
      if (prevState[tagId]) delete prevState[tagId];
      else prevState[tagId] = true;
      return { ...prevState };
    });
  });
  if (loading || ll) return null;
  return (
    <div>
      {!!fullScreenLoader && <FullscreenLoader />}
      {dd.tags
        .sort((a, b) => {
          if (a.title < b.title) return -1;
          if (a.title > b.title) return 1;
          return 0;
        })
        .map((tag) => (
          <Market
            key={tag.tagId}
            {...tag}
            linked={currentMarkets[tag.tagId]}
            onSelectMarket={handleSelectMarket}
          />
        ))}
      <div
        onClick={() => {
          if (fullScreenLoader) return;
          setFullScreenLoader(true);
          saveIt(title, parentId, degreeRef, brandId, newMarkets, history);
        }}
      >
        Save
      </div>
    </div>
  );
};

export default Markets;
