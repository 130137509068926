export const DocumentPageHeaderStyle = (theme) => ({
  pageTitleBox: {
    color: theme.DocumentPageHeader.pageTitleBoxColor,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-end",
    borderBottom: `solid 1px ${theme.DocumentPageHeader.pageTitleBoxBorderBottomColor}`,
    "& > div": {
      marginBottom: 5,
    },
  },
  pageTitle: {
    fontFamily: theme.FontFamily,
    fontSize: 12,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  deleteIcon: {
    cursor: "pointer",
    display: "inline-block",
    textAlign: "center",
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: theme.DocumentPageHeader.deleteIconBackgroundColor,
    marginLeft: 10,
    "&:hover::before": {
      color: theme.DocumentPageHeader.deleteIconHoverBeforeColor,
      fontSize: 9,
    },
    "&::before": {
      fontSize: 9,
    },
  },
  arrowIcon: {
    cursor: "pointer",
    display: "inline-block",
    textAlign: "center",
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: theme.DocumentPageHeader.arrowIconBackgroundColor,
    marginLeft: 10,
    "&:hover::before": {
      color: theme.DocumentPageHeader.arrowIconHoverBeforeColor,
      fontSize: 6,
    },
    "&::before": {
      fontSize: 6,
    },
  },
});
