import gql from "graphql-tag";

export const SEND_CORE_NOTIFICATION_WITH_RECIPIENT_PROFILES = gql`
  mutation SendCoreNotificationWithRecipientProfiles(
    $ocIds: [Int!]!
    $text: String!
    $recipientProfiles: [NotificationProfileArgsInput!]!
  ) {
    admin {
      notification {
        sendCoreObjectNotification(
          ocIds: $ocIds
          recipientProfiles: $recipientProfiles
          contents: [{ type: text, value: $text }]
        ) {
          notifId
          nbRecipients
          nbOptInRecipients
          nbOptOutRecipients
        }
      }
    }
  }
`;
