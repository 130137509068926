import { defineMessages } from "react-intl";

export const translations = defineMessages({
  PdfLabel: {
    id: "PdfWidget.PdfLabel",
    defaultMessage: "Brand Name - Game Title",
  },
  NumberOfParticipantsLabel: {
    id: "PdfWidget.NumberOfParticipantsLabel",
    defaultMessage: "Number of users participated",
  },
});
