import React, { useCallback, useEffect } from "react";
import { defaultStyles } from "./kpiBattle.style";
import withThemedStyle from "../../components/hoc/withThemedStyle";
import { connect } from "react-redux";
import compose from "lodash/fp/compose";
import BattleLeaderBoard from "../results/battle/battleLeaderboard";
import {
  GetKPIBattleData,
  ResetStore,
  SearchUserOnBattleLeaderBoard,
  UpdateGlobalBattleLeaderBoardDataLimit,
  UpdateLeaderBoardColumnSort,
} from "../../store/kpiBattle/actions";
import { getPath } from "../../core/paths";
import { SetFetchIndicator } from "../../store/common/actions";
import { isShiseido } from "../../configs/client";
import { FormattedMessage } from "react-intl";
import { translations } from "./kpiBattle.translations";
import { SetIsLoading } from "store/kpiFilter/actions";
import { KPIPageContainer } from "components/KPI";

const nullFunction = () => null;

const BattleResults = (props) => {
  const {
    classes,
    OnSortLeaderBoard,
    ResetStore,
    GetBattleGlobalLeaderBoardData,
    results,
    leaderBoardColumnSort,
    history,
    SearchUserOnBattleLeaderBoard,
  } = props;

  useEffect(() => ResetStore, []);

  const goBack = () => history.push(getPath("kpiBattleHome"));
  const handleSearch = useCallback(
    (searchText) => SearchUserOnBattleLeaderBoard(searchText),
    [SearchUserOnBattleLeaderBoard],
  );

  return (
    <div className={classes.root}>
      <KPIPageContainer
        onFilterChange={GetBattleGlobalLeaderBoardData}
        exportFunction={nullFunction}
        hideExportButton
        goBack={goBack}
        onSearch={handleSearch}
        showRangeSelector
        useIntervals
      >
        <div className={classes.content}>
          <div className={classes.shiseidoContainerPlaceHolder}>
            {isShiseido && (
              <div className={classes.shiseidoPlaceHolder}>
                <FormattedMessage {...translations.InformUsers} />
              </div>
            )}
          </div>
          <div className="tableContainer">
            <BattleLeaderBoard
              onSort={OnSortLeaderBoard}
              columnSort={leaderBoardColumnSort}
              data={results}
            />
          </div>
        </div>
      </KPIPageContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  results: state.kpiBattle.results,
  leaderBoardColumnSort: state.kpiBattle.leaderBoardColumnSort,
});

const mapDispatchToProps = (dispatch) => ({
  GetBattleGlobalLeaderBoardData: () => {
    dispatch(SetIsLoading(true));
    dispatch(SetFetchIndicator(true));
    dispatch(UpdateGlobalBattleLeaderBoardDataLimit(undefined));
    dispatch(
      GetKPIBattleData(() => {
        dispatch(SetFetchIndicator(false));
        dispatch(SetIsLoading(false));
      }),
    );
  },
  ResetStore: () => dispatch(ResetStore()),
  OnSortLeaderBoard: (columnName, sortMethod) =>
    dispatch(UpdateLeaderBoardColumnSort(columnName, sortMethod)),
  SearchUserOnBattleLeaderBoard: (text) =>
    dispatch(SearchUserOnBattleLeaderBoard(text)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withThemedStyle(defaultStyles),
)(BattleResults);
