import React from "react";
import "constants/scss/style.scss";

const FixedTagsContainer = ({ label, children, ...props }) => {
  return (
    <div {...props} className="FixedTagsContainer">
      <label className="label">{label}</label>
      <div className="TagsContainer">{children}</div>
    </div>
  );
};

export default React.memo(FixedTagsContainer);
