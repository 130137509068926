import React from "react";
import { ImagePreviews } from "assets/icons";
import { IMAGE_CONTROL_POSITION } from "../../../../configs/constants";
import { FormattedMessage } from "react-intl";
import { imageTypeNamesTranslations, translations } from "./Image.translations";

export const imageTypeNames = imageTypeNamesTranslations;

const ImageTemplate = (props) => ({
  title: <FormattedMessage {...translations.TemplateTitle} />,
  items: [
    {
      name: <FormattedMessage {...imageTypeNames.FullWidthText} />,
      thumbnail: ImagePreviews.fullWidth,
      onSelect: () => {
        props.handleImageClick(IMAGE_CONTROL_POSITION.FULLWIDTH);
      },
    },
    {
      name: <FormattedMessage {...imageTypeNames.InContainerText} />,
      thumbnail: ImagePreviews.inContainer,
      onSelect: () => {
        props.handleImageClick(IMAGE_CONTROL_POSITION.BOXED);
      },
    },
    {
      name: <FormattedMessage {...imageTypeNames.FullScreenText} />,
      thumbnail: ImagePreviews.fullScreen,
      onSelect: () => {
        props.handleImageClick(IMAGE_CONTROL_POSITION.FULLSCREEN);
      },
    },
  ],
});

export default ImageTemplate;
