import { formatDate, getFormattedSopiDate } from "utils/dateUtils";
import { createXml } from "../excelFactory";
import { filterExportArray } from "./filter";
import { feedTranslations as translations } from "./Kpi.translations";

export const exportFeed = (
  {
    totalNumberOfContent,
    contentNumberByContentType,
    contentPopularity,
    topTagsInContent,
    allContent,
  },
  filterData,
  intl,
) => {
  const legend = [
    [
      intl.formatMessage(translations.Title),
      intl.formatMessage(translations.PublicationDate),
      intl.formatMessage(translations.ArchiveDate),
      intl.formatMessage(translations.Brands),
      intl.formatMessage(translations.StatusShiseido, {
        date: getFormattedSopiDate(new Date()),
      }),
      intl.formatMessage(translations.Market),
      intl.formatMessage(translations.Language),
      intl.formatMessage(translations.Template),
      intl.formatMessage(translations.Reads),
      intl.formatMessage(translations.Likes),
      intl.formatMessage(translations.Bookmarks),
      intl.formatMessage(translations.Comments),
    ],
  ];
  let data = filterExportArray(filterData, intl).concat(legend);

  data = data.concat(
    allContent.reduce((acc, doc) => {
      if (doc.versions[0].pages[0]?.type === "instagram") return acc;
      doc.versions &&
        doc.versions.forEach((version) => {
          acc.push([
            version.title,
            version.publicationDate &&
              formatDate(new Date(version.publicationDate), "dd/MM/yyyy"),
            version.archiveDate &&
              formatDate(new Date(version.archiveDate), "dd/MM/yyyy"),
            version.brandTags.map(({ title }) => title).join(", "),
            version.workflow.charAt(0).toUpperCase() +
              version.workflow.slice(1),
            version.tags.map(({ title }) => title).join(", "),
            version.lang.toUpperCase(),
            version.type.charAt(0).toUpperCase() + version.type.slice(1),
            version.all?.read || 0,
            version.all?.vote || 0,
            version.all?.bookmark || 0,
            version.all?.comment || 0,
          ]);
        });
      return acc;
    }, []),
  );
  createXml(data, "kpi-feed-export.xlsx");
};
