import { defineMessages } from "react-intl";

export const translations = defineMessages({
  TitleLabel: {
    id: "PageNotFound.Title",
    defaultMessage: "Page not found",
  },
  DescriptionLabel: {
    id: "PageNotFound.Description",
    defaultMessage: "The page you were looking for doesn't exist.",
  },
  ButtonLabel: {
    id: "PageNotFound.Button",
    defaultMessage: "BACK TO HOMEPAGE",
  },
});
