import { defineMessages } from "react-intl";

export default defineMessages({
  PollEmptyQuestionTitle: {
    id: "PollQuestionEditor.PollEmptyQuestionTitle",
    defaultMessage: "Enter a question title here...",
  },
  PollEmptyDescriptionTitle: {
    id: "PollQuestionEditor.PollEmptyDescriptionTitle",
    defaultMessage: "Enter a description here...",
  },
  CorrectColumnLabel: {
    id: "PollQuestionEditor.CorrectColumnLabel",
    defaultMessage: "Correct",
  },
  ChoiceTextColumnLabel: {
    id: "PollQuestionEditor.ChoiceTextColumnLabel",
    defaultMessage: "Choice text",
  },
  AddAChoiceButtonLabel: {
    id: "PollQuestionEditor.AddAChoiceButtonLabel",
    defaultMessage: "Add a Choice",
  },
});
