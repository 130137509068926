export default (theme) => ({
  container: {
    position: "relative",
    display: "flex",
    width: ({ width }) => width,
    height: ({ height }) => height,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    border: (props) =>
      props.isSelected
        ? `solid 2px ${theme.ImageVideoUploader.containerBorderColor}`
        : "none",
  },
  uploadControlsBox: {
    position: "absolute",
    fontFamily: theme.FontFamily,
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: 0.07,
    color: theme.ImageVideoUploader.uploadControlsBoxColor,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "baseline",
    top: 0,
    left: 0,
    width: "100%",
    height: 47,
    paddingTop: 18,
    backgroundImage: `linear-gradient(to bottom, ${theme.ImageVideoUploader.uploadControlsBoxBackgroundFirst}, ${theme.ImageVideoUploader.uploadControlsBoxBackgroundSecond})`,
    zIndex: 1,
  },
  leftButtonsBox: {
    display: "flex",
    flex: 1,
    paddingLeft: 15,
  },
  uploadBtnWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
    },
    "& button": {
      border: "none",
      fontFamily: theme.FontFamily,
      fontSize: 14,
      background: theme.ImageVideoUploader.uploadBtnWrapperBackground,
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "0.1px",
      color: theme.ImageVideoUploader.actionButtonColor,
      cursor: "pointer",
      "&:hover": {
        cursor: "pointer",
      },
      "& > span": {
        "&::before": {
          color: theme.ImageVideoUploader.actionButtonColor,
        },
        paddingRight: 5,
        "&:hover::before": {
          color: theme.ImageVideoUploader.actionButtonHoverColor,
          cursor: "pointer",
        },
      },
      "&:hover *": {
        color: theme.ImageVideoUploader.actionButtonHoverColor,
        cursor: "pointer",
        "&::before": {
          color: theme.ImageVideoUploader.actionButtonHoverColor,
          cursor: "pointer",
        },
      },
    },
  },
  iconAction: {
    cursor: "pointer",
    "&:hover::before": {
      color: theme.ImageVideoUploader.iconActionHoverBlue,
      fontSize: 15,
      cursor: "pointer",
    },
    "&::before": {
      color: theme.ImageVideoUploader.iconAction,
      fontSize: 15,
      cursor: "pointer",
    },
  },
});
