import React from "react";
import imageStyle from "./Image.style";
import classNames from "classnames";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(imageStyle);

const Image = (props) => {
  const { children, className } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classNames([classes.imageBox, className])}>{children}</div>
  );
};

const stringOrNumber = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

Image.propTypes = {
  /** Image path */
  url: PropTypes.string.isRequired,
  /** Image width */
  width: stringOrNumber.isRequired,
  /** Image height */
  height: stringOrNumber.isRequired,
  /** Image borderRadius */
  borderRadius: stringOrNumber.isRequired,
  /** Custom className for external styles */
  className: PropTypes.string,
};

Image.defaultProps = {
  borderRadius: 5,
};

export default Image;
