import { QuickQuizTemplate } from "../../utils/ui-generator/quickQuiz.template";
import { cleanUpAndEncodeText, decodeNewLines } from "../../utils/string";

export const CardDocumentToQuickQuizData = (fullCardInfo, cardDocument) => {
  const {
    card: { pageId, data },
  } = cardDocument;

  return {
    cardId: fullCardInfo.cardId,
    card: {
      pageId,
      ...DecodeQuickQuizData(data),
    },
  };
};

export const DecodeQuickQuizData = (encodedQuickQuizData) => {
  const quickQuizData = JSON.parse(encodedQuickQuizData).data;
  return {
    ...quickQuizData,
    image: {
      url: quickQuizData.image.url,
      copyright: decodeNewLines(quickQuizData.image.copyright),
      legend: decodeNewLines(quickQuizData.image.legend),
    },
    question: decodeNewLines(quickQuizData.question),
    answers: quickQuizData.answers.map((answer) => ({
      isTrue: answer.isTrue,
      title: decodeNewLines(answer.title),
      message: decodeNewLines(answer.message),
    })),
    endMessage: decodeNewLines(quickQuizData.endMessage),
  };
};

export const EncodeQuickQuizData = (quickQuizData) =>
  JSON.stringify({
    ...QuickQuizTemplate,
    data: {
      ...QuickQuizTemplate.data,
      image: {
        url: quickQuizData.image.url,
        copyright: cleanUpAndEncodeText(quickQuizData.image.copyright),
        legend: cleanUpAndEncodeText(quickQuizData.image.legend),
      },
      endImage: {
        url: quickQuizData.endImage.url,
        copyright: cleanUpAndEncodeText(quickQuizData.endImage.copyright),
        legend: cleanUpAndEncodeText(quickQuizData.endImage.legend),
      },
      question: cleanUpAndEncodeText(quickQuizData.question),
      answers: quickQuizData.answers.map((answer) => ({
        isTrue: answer.isTrue,
        title: cleanUpAndEncodeText(answer.title),
        message: cleanUpAndEncodeText(answer.message),
      })),
      endMessage: cleanUpAndEncodeText(quickQuizData.endMessage),
    },
  });
