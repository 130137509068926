export default (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: ({ width }) => `${width}%`,
    minWidth: 410,
    marginBottom: 20,
  },
  title: {
    fontFamily: theme.FontFamily,
    fontSize: 23,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.WidgetSection.titleColor,
    marginBottom: 10,
    width: "100%",
  },
});
