// DEGREES
export const SET_CURRENT_DEGREE_PAGEID = "SET_CURRENT_DEGREE_PAGEID";
export const SET_CURRENT_DEGREE_ID = "SET_CURRENT_DEGREE_ID";
export const SET_CURRENT_DEGREE_LANGUAGE = "SET_CURRENT_DEGREE_LANGUAGE";
export const SET_PUBLISH_DEGREE_INFO = "SET_PUBLISH_DEGREE_INFO";
export const UPDATE_VISIBLE_DEGREE_TAG_SELECTOR =
  "UPDATE_VISIBLE_DEGREE_TAG_SELECTOR";
export const SET_CURRENT_EDIT_PROGRAM_INFO = "SET_CURRENT_EDIT_PROGRAM_INFO";
export const SET_CURRENT_EDIT_PROGRAM_ID = "SET_CURRENT_EDIT_PROGRAM_ID";
export const CHANGE_CURRENT_EDIT_PROGRAM_INFO_VISIBILITY =
  "CHANGE_CURRENT_EDIT_PROGRAM_INFO_VISIBILITY";
export const SET_CURRENT_DEGREE_PROGRAM_CONTENT =
  "SET_CURRENT_DEGREE_PROGRAM_CONTENT";
export const SET_ACTIVE_MENU_FOR_DEGREE_ID = "SET_ACTIVE_MENU_FOR_DEGREE_ID";
export const SET_ACTIVE_MENU_FOR_LESSON_ID = "SET_ACTIVE_MENU_FOR_LESSON_ID";
export const SET_DEGREE_PUBLICATION_STATUS = "SET_DEGREE_PUBLICATION_STATUS";
export const SET_CURRENT_LESSON_ID = "SET_CURRENT_LESSON_ID";
export const SET_PUBLISH_LESSON_INFO = "SET_PUBLISH_LESSON_INFO";
export const SET_CURRENT_LESSON_PAGEID = "SET_CURRENT_LESSON_PAGEID";
export const UPDATE_CURRENT_OPENED_MARKER_LESSON =
  "UPDATE_CURRENT_OPENED_MARKER_LESSON";
export const RESET_DEGREE_STORE = "RESET_DEGREE_STORE";
export const SET_LAST_EXPANDED_PROGRAM_ID = "SET_LAST_EXPANDED_PROGRAM_ID";
export const SET_DEGREE_FILTER = "SET_DEGREE_FILTER";
export const UPDATE_DEGREES = "UPDATE_DEGREES";
export const UPDATE_DEGREE_FILTERS = "UPDATE_DEGREE_FILTERS";
export const SET_MARKETS = "SET_MARKETS";
export const UPDATE_USER_MARKET_IDS = "UPDATE_USER_MARKET_IDS";
export const SET_DEGREE_BADGES = "SET_DEGREE_BADGES";
export const SET_DEGREE_ALL_LESSONS = "SET_DEGREE_ALL_LESSONS";
export const SET_DEGREE_BADGE_LESSON_IDS = "SET_DEGREE_BADGE_LESSON_IDS";
export const RESET_DEGREE_BADGES = "RESET_DEGREE_BADGES";
