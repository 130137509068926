import React, { useState } from "react";
import PropTypes from "prop-types";
import Badge from "../badge";
import uuidV4 from "uuid/dist/v4";
import styles from "./BadgeSlide.style";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(styles);

const BadgeSlide = (props) => {
  const { name, completedPoints, totalPoints, badgeList } = props;
  const classes = useThemedStyle(useStyle, props);

  const pageSize = 3;
  const [slideIndex, setSlideIndex] = useState(0);
  const offset = slideIndex
    ? slideIndex * pageSize - slideIndex
    : slideIndex * pageSize;

  const sliderDots = (badgeList) => {
    const dots = [];
    const pageNumber = Math.ceil(badgeList.length / pageSize);

    for (let i = 0; i < pageNumber; ++i) {
      dots.push(
        <div
          key={uuidV4()}
          className={i === slideIndex ? classes.dotFull : classes.dotEmpty}
          onClick={() => setSlideIndex(i)}
        />,
      );

      if (i === pageNumber - 1 && badgeList.length % 2 !== 0) {
        dots.push(
          <div
            key={uuidV4()}
            className={
              i + 1 === slideIndex ? classes.dotFull : classes.dotEmpty
            }
            onClick={() => setSlideIndex(i + 1)}
          />,
        );
      }
    }

    return dots;
  };

  return (
    <div key={uuidV4()} className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>{name}</div>
        <div
          className={classes.points}
        >{`${completedPoints}/${totalPoints}pts`}</div>
      </div>
      <div className={classes.badges}>
        {badgeList
          .slice(offset)
          .map(({ type, status, name, date, picture }) => (
            <div key={uuidV4()} className={classes.badgeContainer}>
              <Badge
                badgeType={type}
                size={130}
                variation={status}
                label={name}
                date={date}
                picture={picture}
              />
            </div>
          ))}
      </div>
      <div className={classes.sliderDots}>{sliderDots(badgeList)}</div>
    </div>
  );
};

BadgeSlide.propTypes = {
  /** Degree name */
  name: PropTypes.string.isRequired,
  /** Degree total points */
  totalPoints: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /** Degree completed points */
  completedPoints: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /** Badge list */
  badgeList: PropTypes.array.isRequired,
};

export default BadgeSlide;
