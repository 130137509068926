import uuidV4 from "uuid/dist/v4";
import { EmptyBattlePageTemplate } from "../../utils/ui-generator/battle.template";
import { cleanUpAndEncodeText, decodeNewLines } from "../../utils/string";

export const BattleDocumentQuestionChoiceToBattleImtemQuestionChoice = ({
  id,
  answer,
  isTrue,
}) => ({
  id: id || uuidV4(),
  text: decodeNewLines(answer),
  isCorrect: isTrue,
});

export const BattleDocumentQuestionToBattleItemQuestion = (
  battleDocumentQuestion,
) => {
  const { id, type, text, image, description, answers, timer, score } =
    battleDocumentQuestion;

  return {
    timer,
    id: id || uuidV4(),
    type,
    title: decodeNewLines(text),
    image,
    description: decodeNewLines(description),
    choices: answers.map(
      BattleDocumentQuestionChoiceToBattleImtemQuestionChoice,
    ),
    originalData: battleDocumentQuestion,
    score,
  };
};

export const BattleDocumentToBattleItem = (battleDocument) => {
  const editedPageJSONData = JSON.parse(battleDocument.pages[0].data).data;

  return {
    editedPageId: battleDocument.pages[0].pageId,
    editedPageJSONData,
    questions: editedPageJSONData.questions.map(
      BattleDocumentQuestionToBattleItemQuestion,
    ),
  };
};

export const BattleItemQuestionChoiceToBattleDocumentQuestionChoice = ({
  id,
  text,
  isCorrect,
}) => ({
  id: id || uuidV4(),
  answer: cleanUpAndEncodeText(text),
  isTrue: isCorrect,
});

export const BattleItemQuestionToBattleDocumentQuestion = ({
  id,
  type,
  title,
  image,
  description,
  choices,
  timer,
  score,
  originalData,
}) => ({
  ...originalData,
  id,
  type,
  text: cleanUpAndEncodeText(title),
  image,
  description: cleanUpAndEncodeText(description),
  answers: choices.map(BattleItemQuestionChoiceToBattleDocumentQuestionChoice),
  timer,
  score,
});

export const BattleItemToBattleDocument = (battleItem) =>
  JSON.stringify({
    ...EmptyBattlePageTemplate,
    data: {
      ...EmptyBattlePageTemplate.data,
      questions: battleItem.questions.map(
        BattleItemQuestionToBattleDocumentQuestion,
      ),
    },
  });
