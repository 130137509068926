import gql from "graphql-tag";

export const CARD_DELETE_PAGE = gql`
  mutation cardDeletePage($cardId: ID, $lang: LangEnum, $pageId: ID) {
    admin {
      card(cardId: $cardId, lang: $lang) {
        pages(pageId: $pageId) {
          delete
        }
      }
    }
  }
`;
