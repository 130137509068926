export const ImageCropperStyle = (theme) => ({
  container: {
    "& span.cropper-view-box:after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: ({ customCropperViewBoxBG }) =>
        `url('${customCropperViewBoxBG}')`,
      display: ({ customCropperViewBoxBG }) =>
        customCropperViewBoxBG ? "block" : "none",
    },
  },
  modalFrameStyle: {
    width: "98vw",
    height: "100vh",
    zIndex: "99999 !important",
    backgroundColor: theme.ImageCropper.modalFrameBackgroundColor,
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "100%",
    height: "100%",
    zIndex: "99999 !important",
  },
  cropContainer: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 80,
    width: "100%",
    height: "90%",
    position: "relative",
    zIndex: "99999 !important",
    "& > .cropper-modal-react-image-cropper-container": {
      overflow: "hidden",
    },
  },
  actionButtons: {
    display: "flex",
    width: "calc(100% - 20px)",
    height: 60,
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: theme.ImageCropper.actonButtonsBackgroundColor,
    paddingRight: 20,
  },
});
