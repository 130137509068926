import gql from "graphql-tag";

export const DEGREES_GET_LIST_BY_STATUSES = gql`
  query degreesFilterByStatus(
    $statusPublished: DocWorkflowEnum
    $statusDraft: DocWorkflowEnum
    $tagIds: [ID!]
  ) {
    admin {
      published: degrees(workflow: $statusPublished, tagIds: $tagIds) {
        versions {
          degreeId
          workflow
          title
          summary
          lang
          picCover
          author {
            userId
          }
          tags {
            tagId
            title
            clusterId
          }
          updateDate
        }
      }
      draft: degrees(workflow: $statusDraft, tagIds: $tagIds) {
        versions {
          degreeId
          workflow
          title
          summary
          lang
          picCover
          author {
            userId
          }
          tags {
            tagId
            title
            clusterId
          }
          updateDate
        }
      }
    }
  }
`;
