import { UPDATE_PAGE_DATA } from "../../constants/store/kpiAskDetails";
import { SetErrorMessage } from "../notification/actions";
import {
  extractFilterValues,
  getFormattedTagList,
} from "../actions/common/kpi";
import client from "../../graphql/client";
import { GET_ASK_DATA } from "../../graphql/remote/kpi/queries/ask.graphql";
import uuIdV4 from "uuid/dist/v4";
import { USER_ROLE_NAMES } from "../../configs/constants";
import { isShiseido } from "../../configs/client";
import {
  extendTagsWithUserTags,
  filterTagsByTrainerTags,
} from "../../utils/transformers";
import { SetIsLoading } from "store/kpiFilter/actions";

export const UpdatePageData = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PAGE_DATA,
    data,
  });
};

const _formatPageData = ({ data: { askKPI } }) => ({
  ...askKPI,
  expertAnswer: askKPI.expertAnswerNb,
  expertQuestion: askKPI.expertQuestionNb,
  geniusQuestion: askKPI.geniusQuestionNb,
  pendingQuestionsToExperts: askKPI.expertQuestionNb - askKPI.expertAnswerNb,
  topTags: {
    expert: askKPI.popularExpertTags
      ? askKPI.popularExpertTags.map((tag) => ({
          id: uuIdV4(),
          name: tag.label,
          nb: tag.nb,
        }))
      : [],
    genius: askKPI.popularGeniusTags
      ? askKPI.popularGeniusTags.map((tag) => ({
          id: uuIdV4(),
          name: tag.label,
          nb: tag.nb,
        }))
      : [],
  },
});

export const GetPageData = () => async (dispatch, getState) => {
  const { profileInfo } = getState().auth;
  const { filters } = getState().kpi;
  if (filters.isLoading) return;
  dispatch(SetIsLoading(true));

  try {
    const generalFilterValues = extractFilterValues(filters);

    /** If user is a trainer always get data from own markets */
    if (isShiseido && profileInfo.roles.includes(USER_ROLE_NAMES.TRAINER)) {
      const markets = filterTagsByTrainerTags(
        profileInfo.tags,
        getState().tags.markets,
      );
      generalFilterValues.market = extendTagsWithUserTags(
        generalFilterValues.market,
        markets,
      );
    }

    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_ASK_DATA,
      variables: {
        fromDate: generalFilterValues.startDate,
        toDate: generalFilterValues.endDate,
        tags: getFormattedTagList(generalFilterValues),
      },
    });

    dispatch(SetIsLoading(false));
    dispatch(UpdatePageData(_formatPageData(result)));
  } catch (e) {
    dispatch(
      SetErrorMessage("An error occurred while fetching the kpi ask data", e),
    );
  }
};
