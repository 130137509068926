export default ({ TextSizes, FontFamily, Colors }) => ({
  table: {
    "& td:nth-child(1), & th:nth-child(1)": {
      paddingLeft: 60,
    },
    "& td, & th": {
      borderTopWidth: 0,
      borderRightWidth: 0,
      borderLeftWidth: 0,
      border: `1px solid ${Colors.table.rowDividerColor}`,
      paddingLeft: 20,
      paddingRight: 20,
    },
    borderRadius: "5px",
    backgroundColor: Colors.table.backgroundColor,
    width: "100%",
    borderSpacing: 0,
  },
  columnContainer: {
    fontFamily: FontFamily,
    fontSize: TextSizes.Regular,
    color: Colors.table.headerTextColor,
    borderSpacing: 0,
  },
  headerColumnItemContainer: {
    padding: 0,
    borderSpacing: 0,
  },
  headerColumnItem: {
    display: "flex",
    alignItems: "center",
    height: 50,
    lineHeight: 0.94,
    fontWeight: "500 !important",
    "&:hover": {
      color: Colors.text.editableSectionBorder,
    },
  },
  headerColumnItemNotSortable: {
    display: "flex",
    alignItems: "center",
    height: 50,
    lineHeight: 0.94,
    fontWeight: "500 !important",
  },
  sortableHeaderColumnItem: {
    cursor: "pointer",
  },
  sortIcon: {
    display: "inline-block",
    marginLeft: 7.5,
    width: 8,
    height: 4,
    fontSize: "5px",
    "& > span::before": {
      color: "inherit",
    },
  },
  sortDescIcon: {
    display: "inline-block",
    transform: "rotate(180deg)",
  },
  loadMoreButton: {
    textAlign: "center",
    padding: 30,
  },
});
