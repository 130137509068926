import React from "react";
import { FormattedMessage } from "react-intl";
import {
  ImageScrollTemplate1Thumbnail,
  ImageScrollTemplate2Thumbnail,
} from "assets/icons";
import {
  SliderFullScreenTemplateEmpty,
  SliderFullScreenMarkerTemplateEmpty,
} from "../../../../utils/ui-generator/defaultTemplates";
import { translations } from "./Slider.translations";

const SliderTemplate = (props) => ({
  title: <FormattedMessage {...translations.TemplateTitle} />,
  items: [
    {
      name: <FormattedMessage {...translations.SliderSimpleText} />,
      thumbnail: ImageScrollTemplate1Thumbnail,
      onSelect: () => {
        props.handleSliderClick(JSON.stringify(SliderFullScreenTemplateEmpty));
      },
    },
    {
      name: <FormattedMessage {...translations.SliderDottedText} />,
      thumbnail: ImageScrollTemplate2Thumbnail,
      onSelect: () => {
        props.handleSliderClick(
          JSON.stringify(SliderFullScreenMarkerTemplateEmpty),
        );
      },
    },
  ],
});

export default SliderTemplate;
