export default ({ MadLib, FontFamily }) => {
  const contentEditablePlaceholderStyle = {
    "&[contenteditable]:empty:before": {
      content: "attr(placeholder)",
      display: "block",
      color: MadLib.titleColor,
    },
  };

  const senteces = {
    overflow: "hidden",
    minHeight: 27,
    maxHeight: 55,
    fontFamily: FontFamily,
    fontSize: 23,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.17,
    letterSpacing: "normal",
    color: MadLib.titleColor,
    ...contentEditablePlaceholderStyle,
  };

  const radioHeaderLabel = {
    fontFamily: FontFamily,
    fontSize: 18,
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    textAlign: "center",
    color: MadLib.radioHeaderColor,
  };

  const addImageStyle = {
    position: "absolute",
    zIndex: 999,
    cursor: "pointer",
    left: "calc(100%/2 - 22px)",
  };

  return {
    madlibContainer: {
      width: 414,
      height: 737,
      border: (props) =>
        props.isSelected ? `solid 1px ${MadLib.selectedBorderColor}` : "none",
      position: "relative",
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 130,
      backgroundColor: MadLib.backgroundColor,
      boxSizing: "border-box",
    },
    sentencePartOne: {
      ...senteces,
    },
    sentencePartTwo: {
      ...senteces,
      marginTop: 20,
    },
    choice: {
      marginTop: 20,
      minHeight: 21,
      maxHeight: 45,
      fontFamily: FontFamily,
      fontSize: 18,
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: MadLib.choiceColor,
      borderBottom: `2px solid ${MadLib.choiceBorderColor}`,
      width: "fit-content",
      maxWidth: "100%",
      overflow: "hidden",
      boxSizing: "border-box",
      ...contentEditablePlaceholderStyle,
    },
    radioContainer: {
      marginLeft: 20,
      "& > div": {
        height: 45,
      },
    },
    radioHeaderContainer: {
      marginTop: 75,
      marginBottom: 20,
      display: "flex",
      flexDirection: "row",
    },
    correctLabel: {
      ...radioHeaderLabel,
      marginRight: 70,
    },
    choiceTextLabel: {
      ...radioHeaderLabel,
    },
    radioChoiceLabel: {
      marginLeft: 80,
      minWidth: 230,
      maxWidth: 230,
      minHeight: 21,
      maxHeight: 45,
      overflow: "hidden",
      fontFamily: FontFamily,
      fontSize: 18,
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: MadLib.choiceColor,
      ...contentEditablePlaceholderStyle,
    },
    RadioLabelContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    radioLabel: {
      width: "100%",
      boxSizing: "border-box",
    },
    deleteIcon: {
      "&:before": {
        color: MadLib.actionButtonColor,
        fontSize: 14,
      },
    },
    addChoice: {
      cursor: (props) => (props.isSelected ? "pointer" : "default"),
      marginTop: 40,
      marginLeft: 110,
      fontFamily: FontFamily,
      fontSize: 16,
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "condensed",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: MadLib.actionButtonColor,
      textDecoration: "underline",
    },
    addBoxTop: {
      ...addImageStyle,
      top: -25,
    },
    addBoxBottom: {
      ...addImageStyle,
      bottom: -25,
    },
    deleteBox: {
      fontFamily: FontFamily,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "baseline",
      alignSelf: "flex-start",
      position: "absolute",
      top: 10,
      right: 10,
      cursor: "pointer",
      "& > span": {
        fontSize: 18,
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: MadLib.actionButtonColor,
        "&::before": {
          color: MadLib.actionButtonColor,
          fontSize: 15,
          padding: 5,
        },
      },
      "&:hover *": {
        color: MadLib.actionButtonHoverColor,
        "&::before": {
          color: MadLib.actionButtonHoverColor,
        },
      },
    },
  };
};
