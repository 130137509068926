import gql from "graphql-tag";

export const PROGRAM_ADD_TAGS = gql`
  mutation programAddTags($programId: ID, $lang: LangEnum, $tagIds: [ID!]) {
    admin {
      program(programId: $programId, lang: $lang) {
        tags {
          add(tagIds: $tagIds)
        }
      }
    }
  }
`;
