import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import client from "graphql/client";
import { GET_TAGS_BY_CLUSTER_ID_PAGINATED } from "graphql/remote/tags/queries";
import { TagSelectWithPaginatedAutocomplete } from "genius-ui";
import { ENV } from "configs/constants";
import { defineMessages, injectIntl } from "react-intl";
import Style from "./SelectTagsByClusterId.style";
import useThemedStyle from "../../hooks/style/useThemedStyle";

// const HIDDEN_TAGID = 12

const useStyle = createUseStyles(Style);

const messages = defineMessages({
  SuggestedTagsLabel: {
    id: "SelectTagsByClusterId.SuggestedTagsLabel",
    defaultMessage: "Suggested Tags",
  },
  OtherTagsLabel: {
    id: "SelectTagsByClusterId.OtherTagsLabel",
    defaultMessage: "Other Tags",
  },
  MustHaveAtLeastOneChoice: {
    id: "SelectTagsByClusterId.MustHaveAtLeastOneChoice",
    defaultMessage: "You must provide at least one value for this field",
  },
});

const fetchTags = async (search, page, props) => {
  try {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_TAGS_BY_CLUSTER_ID_PAGINATED,
      variables: {
        clusterIds: props.clusterIds,
        autocomplete: search,
        skip: page * props.tagsOnPage,
        limit: props.tagsOnPage,
        clusterMetaTag: props.clusterMetaTag,
      },
    });

    return result.data.tags;
  } catch (e) {
    if (ENV.IS_DEV) {
      console.error(`[Application error]: ${e}`);
    }
  }

  return [];
};

const SelectTagsByClusterId = (props) => {
  const {
    selectedItems,
    onSelectionChanged,
    className,
    classNameError,
    fetchSuggestedTags,
    intl,
    mustHaveAtLeastOneChoice,
    ...restProps
  } = props;
  const getTags = async (search, prevOptions, { page }) => {
    let tags = [];
    const fetchTagsPromise = async () => {
      tags = await fetchTags(search, page, props);
    };

    let suggestedTags = [];
    const fetchSuggestedTagsPromise = async () => {
      if (fetchSuggestedTags) {
        suggestedTags = await fetchSuggestedTags(search, page, props);
      }
    };

    await Promise.all([fetchTagsPromise(), fetchSuggestedTagsPromise()]);

    const formattedTags = tags.map((tag) => ({
      value: tag.tagId,
      label: tag.title,
    }));

    const formattedSuggestedTags = suggestedTags.map((tag) => ({
      value: tag.tagId,
      label: tag.title,
    }));

    const options =
      formattedSuggestedTags.length > 0 && page === 0
        ? [
            {
              label: intl.formatMessage(messages.SuggestedTagsLabel),
              options: formattedSuggestedTags,
            },
            {
              label: intl.formatMessage(messages.OtherTagsLabel),
              options: formattedTags,
            },
          ]
        : formattedTags;

    return {
      options,
      hasMore: true,
      additional: {
        page: page + 1,
      },
    };
  };

  const classes = useThemedStyle(useStyle, {
    ...props,
    mustHaveAtLeastOneChoice: messages.MustHaveAtLeastOneChoice.defaultMessage,
  });
  const selectTagOneChoiceConditions =
    mustHaveAtLeastOneChoice && selectedItems.length < 2;

  return (
    <div
      className={`${classNameError || className} ${
        selectTagOneChoiceConditions
          ? classes.selectTagsMustHaveAtLeastOneChoice
          : ""
      }`}
    >
      <TagSelectWithPaginatedAutocomplete
        additional={{ page: 0 }}
        {...restProps}
        value={selectedItems}
        loadOptions={getTags}
        onChange={onSelectionChanged}
      />
    </div>
  );
};

SelectTagsByClusterId.propTypes = {
  /** cluster ids where the tags are loaded from**/
  clusterIds: PropTypes.array.isRequired,
  /** selected tags **/
  selectedItems: PropTypes.array,
  /** function called when a new tag is selected or a tag is unselected **/
  onSelectionChanged: PropTypes.func.isRequired,
  /** how many tags are displayed on a page **/
  tagsOnPage: PropTypes.number,
  /** custom style for the container can be provided **/
  className: PropTypes.string,
  /** function(search, page): suggested tags can be fetched with this function and the returned result will be displayed
   * in a separate option group: 'Suggested tags' **/
  fetchSuggestedTags: PropTypes.func,
  /** + All the props accepted by TagSelectWithPaginatedAutocomplete except:
   *  - value
   *  - loadOptions
   *  - onChange
   * **/
};

SelectTagsByClusterId.defaultProps = {
  tagsOnPage: 10,
};

export default injectIntl(SelectTagsByClusterId);
