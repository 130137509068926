export const SET_SHOW_MODAL = "SET_SHOW_NOTIFICATION_MODAL";
export const SET_NOTIFICATION_DATA = "SET_NOTIFICATION_DATA";
export const RESET_STORE = "RESET_NOTIFICATION_MODAL_STORE";
export const ADD_NEW_PROFILE = "ADD_NEW_PROFILE";
export const REMOVE_PROFILE = "REMOVE_PROFILE";
export const REMOVE_TAG = "REMOVE_TAG";
export const UPDATE_PROFILE_SELECTED_TAGS = "UPDATE_PROFILE_SELECTED_TAGS";
export const RESET_PROFILE = "RESET_PROFILE";
export const RESET_PROFILES = "RESET_PROFILES";
export const SET_MARKET_BRAND_TAGS = "SET_MARKET_BRAND_TAGS";
