import { useDispatch, useSelector } from "react-redux";
import { GetProfileInfo } from "./actions";

export const useUserRoles = () => {
  const dispatch = useDispatch();
  const userRoles = useSelector((state) => state.auth.profileInfo.roles);
  const getRoles = () => {
    dispatch(GetProfileInfo());
  };

  return {
    userRoles,
    getRoles,
  };
};
