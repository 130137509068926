import { defineMessages } from "react-intl";

export const translations = defineMessages({
  PollTitlePlaceholder: {
    id: "PublishPoll.PollTitlePlaceholder",
    defaultMessage: "Enter your title",
  },
  UploadImage: {
    id: "PublishPoll.UploadImage",
    defaultMessage: "Upload Image",
  },
  PollDescriptionPlaceholder: {
    id: "PublishPoll.PollDescriptionPlaceholder",
    defaultMessage: "Describe your poll",
  },
  AddCountry: {
    id: "PublishPoll.AddCountry",
    defaultMessage: "Add a country",
  },
  PublicationCountries: {
    id: "PublishPoll.PublicationCountries",
    defaultMessage: "Publication Countries",
  },
  AssociatedTags: {
    id: "PublishPoll.AssociatedTags",
    defaultMessage: "Associated Tags",
  },
  AddTag: {
    id: "PublishPoll.AddTag",
    defaultMessage: "Add a tag",
  },
  Language: {
    id: "PublishPoll.Language",
    defaultMessage: "Language",
  },
  StartPostingDateLabel: {
    id: "PublishPoll.StartPostingDateLabel",
    defaultMessage: "Start posting date",
  },
  EndPostingDateLabel: {
    id: "PublishPoll.EndPostingDateLabel",
    defaultMessage: "End posting date",
  },
  NoEndPostingDateLabel: {
    id: "PublishPoll.NoEndPostingDateLabel",
    defaultMessage: "No end posting date",
  },
  ScheduleButtonText: {
    id: "PublishPoll.ScheduleButtonText",
    defaultMessage: "Schedule to publish",
  },
  CancelScheduleButtonText: {
    id: "PublishPoll.CancelScheduleButtonText",
    defaultMessage: "Cancel scheduling",
  },
  SaveWithoutScheduleButtonText: {
    id: "PublishPoll.SaveWithoutScheduleButtonText",
    defaultMessage: "Save without scheduling",
  },
  PublicationMessage: {
    id: "PublishPoll.PublicationMessage",
    defaultMessage: "Your poll document has been published",
  },
  BrandLabel: {
    id: "PublishPoll.BrandLabel",
    defaultMessage: "Brand",
  },
  AddBrandPlaceholder: {
    id: "PublishPoll.AddBrandPlaceholder",
    defaultMessage: "Add a brand",
  },
  RetailerLabel: {
    id: "PublishPoll.RetailerLabel",
    defaultMessage: "Retailer",
  },
  AddRetailerPlaceholder: {
    id: "PublishPoll.AddRetailerPlaceholder",
    defaultMessage: "Add a retailer",
  },
  IndicateBrandsWarning: {
    id: "PublishPoll.IndicateBrandsWarning",
    defaultMessage:
      "Please indicate which brand or brands this content should be associated with before publishing.",
  },
});
