import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import style from "./QuantityWidget.style";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import classNames from "classnames";

const useStyle = createUseStyles(style);

const QuantityWidget = (props) => {
  const { className } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classNames([classes.container, className])}>
      <div className={classes.leftSide}>{props.counter}</div>
      <div className={classes.rightSide}>
        {props.percentData && (
          <div className={classes.percentData}>{`${props.percentData}%`}</div>
        )}
        <div className={classes.title}>{props.title}</div>
      </div>
      {props.info && (
        <div className={classes.tooltip}>
          i
          <div>
            <FormattedMessage {...props.info} />
          </div>
        </div>
      )}
      {props.infoMultiple && (
        <div className={classes.tooltip}>
          i
          <div>
            {props.infoMultiple.map((line, key) => (
              <p key={key}>{line}</p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const PropStringOrNumber = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);
const PropStringOrObject = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
]);

QuantityWidget.propTypes = {
  /** Counter value */
  counter: PropStringOrNumber,
  /** Quantity widget title */
  title: PropStringOrObject.isRequired,
  /** Quantity widget percent data */
  percentData: PropStringOrNumber,
};

export default QuantityWidget;
