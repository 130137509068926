const commonInputStyle = {
  border: "none",
  cursor: "pointer",
  "&:focus": {
    outline: "none",
  },
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontWeight: "normal",
};

export default (theme) => ({
  container: {
    width: "max-content",
    position: "relative",
    fontFamily: theme.FontFamily,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 0.07,
  },
  popup: {
    width: "100%",
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    top: -45,
    left: "50%",
    transform: "translate(-50%, 0)",
    backgroundColor: theme.Link.popupBackground,
    borderRadius: 5,
    fontSize: 16,
    letterSpacing: "normal",
    color: theme.Link.popupColor,
    padding: "8px 10px",
    boxShadow: `0 7px 7px -3px ${theme.Link.popupBoxShadow}`,
    "&:after": {
      top: "100%",
      left: "50%",
      width: 0,
      height: 0,
      content: '""',
      position: "absolute",
      borderTop: `solid 6px ${theme.Link.popupAfterBorderTop}`,
      marginLeft: -5,
      borderLeft: `solid 3px ${theme.Link.popupAfterSideBorder}`,
      borderRight: `solid 3px ${theme.Link.popupAfterSideBorder}`,
    },
    "& > span:before": {
      marginLeft: 40,
      color: theme.Link.popupSpanBeforeColor,
      fontSize: 10,
      cursor: "pointer",
    },
  },
  linkTitle: {
    ...commonInputStyle,
    backgroundColor: theme.Link.linkBackgroundColor,
    fontSize: "20px",
    color: theme.Link.linkTitleColor,
    "&::placeholder": {
      color: theme.Link.linkTitleColor,
    },
  },
  link: {
    ...commonInputStyle,
    backgroundColor: theme.Link.linkBackgroundColor,
    fontSize: "16px",
    color: theme.Link.linkColor,
    "&::placeholder": {
      color: theme.Link.linkColor,
    },
  },
});
