import React from "react";
import { PublishDegreeStyle } from "./PublishDegree.style";
import { graphql, Query } from "react-apollo";
import compose from "lodash/flowRight";
import {
  DEGREE_UPDATE_TITLE,
  DEGREE_UPDATE_SUMMARY,
  DEGREE_UPDATE_PIC_COVER,
  DEGREE_UPDATE_PIC_THUMB,
  DEGREE_ADD_TAGS,
  DEGREE_REMOVE_TAGS,
  DEGREE_UPDATE_WORFLOW,
  DEGREE_SET_PRIVACY,
  DEGREE_REMOVE_PRIVACY,
} from "../../graphql/remote/degrees/mutations";
import { connect } from "react-redux";
import {
  SetPublishDegree,
  SetCurrentDegreeId,
  SetCurrentLanguage,
} from "../../store/degree/actions";
import { Button, Icons, Icon, FullscreenLoader, Tag } from "genius-ui";
import ImageSelector from "../../components/common/ImageSelector";
import { withRouter } from "react-router";
import SelectTagsByClusterId from "../../containers/Apollo/SelectTagsByClusterId";
import {
  CROP_VARIATION,
  DATE_SELECTOR_TYPE,
  DOC_WORKFLOW_ENUM,
} from "../../configs/constants";
import clientConfig, {
  CONFIG_CLUSTER,
  REACT_APP_CLIENT_NAME,
} from "../../configs/client";
import {
  DEGREES_GET_LIST_BY_STATUSES,
  GET_DEGREE_BY_ID,
} from "../../graphql/remote/degrees/queries";
import { SetCurrentTags } from "../../store/referential/actions";
import {
  SetErrorMessage,
  SetSuccessMessage,
} from "../../store/notification/actions";
import { LANGUAGES } from "../../configs/referential";
import TransparentButton from "../../components/common/TransparentButton";
import SwitchButton from "../../components/common/SwitchButton";
import { cleanUpAndEncodeText, decodeNewLines } from "../../utils/string";
import ContentEditable from "react-contenteditable";
import { FormattedMessage } from "react-intl";
import { TAG_CREATE } from "../../graphql/remote/tags/mutations";
import { GET_TAGS_BY_CLUSTER_ID } from "../../graphql/remote/referential/queries";
import PublishDateHandler from "../../components/common/PublishDateHandler";
import { translations } from "./PublishDegree.translation";
import {
  formatDateTimeForSave,
  isAfter,
  isBefore,
  parseISODate,
} from "../../utils/dateUtils";
import FilterLanguages from "../../components/FilterLanguages";
import FilterCountries from "../../components/FilterCountries";
import withThemedStyle from "../../components/hoc/withThemedStyle";

const clientBasedTranslationNameSpace = {
  SHISEIDO: "REST_OF_CLIENTS",
  "SHISEIDO-PREPROD": "REST_OF_CLIENTS",
  "SHISEIDO-PROD": "REST_OF_CLIENTS",
  PLURIS: "REST_OF_CLIENTS",
};

const updateTitle = graphql(DEGREE_UPDATE_TITLE, {
  props: ({ mutate }) => ({
    updateTitle: (degreeId, lang, data) =>
      mutate({
        variables: { degreeId: degreeId, lang: lang, data: data },
      }),
  }),
});

const updateSumarry = graphql(DEGREE_UPDATE_SUMMARY, {
  props: ({ mutate }) => ({
    updateSummary: (degreeId, lang, data) =>
      mutate({
        variables: { degreeId: degreeId, lang: lang, data: data },
      }),
  }),
});

const updatePicCover = graphql(DEGREE_UPDATE_PIC_COVER, {
  props: ({ mutate }) => ({
    updatePicCover: (degreeId, lang, data) =>
      mutate({
        variables: { degreeId: degreeId, lang: lang, data: data },
      }),
  }),
});

const updatePicThumb = graphql(DEGREE_UPDATE_PIC_THUMB, {
  props: ({ mutate }) => ({
    updatePicThumb: (degreeId, lang, data) =>
      mutate({
        variables: { degreeId: degreeId, lang: lang, data: data },
      }),
  }),
});

const addTags = graphql(DEGREE_ADD_TAGS, {
  props: ({ mutate }) => ({
    addTags: (degreeId, lang, tagIdList, onUpdate) =>
      mutate({
        variables: { degreeId: degreeId, lang: lang, tagIdList: tagIdList },
        update: (dataProxy, result) =>
          onUpdate(result.data.admin.degree.tags.add),
      }),
  }),
});

const removeTags = graphql(DEGREE_REMOVE_TAGS, {
  props: ({ mutate }) => ({
    removeTags: (degreeId, lang, tagIdList, onSuccess) =>
      mutate({
        variables: { degreeId: degreeId, lang: lang, tagIdList: tagIdList },
        update: () => onSuccess(),
      }),
  }),
});

const updateWorlfow = graphql(DEGREE_UPDATE_WORFLOW, {
  props: ({ mutate }) => ({
    updateStatus: (
      degreeId,
      lang,
      status,
      dateIn,
      dateOut,
      onCompletedHandler,
    ) =>
      mutate({
        variables: {
          degreeId: degreeId,
          lang: lang,
          status: status,
          dateIn: dateIn,
          dateOut: dateOut,
        },
        awaitRefetchQueries: true,
        update: (data) => onCompletedHandler(),
        refetchQueries: (fetchResult) => [
          {
            query: DEGREES_GET_LIST_BY_STATUSES,
            variables: {
              statusPublished: DOC_WORKFLOW_ENUM.PUBLISHED,
              statusDraft: DOC_WORKFLOW_ENUM.DRAFT,
            },
          },
        ],
      }),
  }),
});

const setDegreePrivacy = graphql(DEGREE_SET_PRIVACY, {
  props: ({ mutate }) => ({
    setPrivacy: (degreeId, lang, onSuccess) =>
      mutate({
        variables: { degreeId, lang },
        update: () => onSuccess(),
      }),
  }),
});

const removeDegreePrivacy = graphql(DEGREE_REMOVE_PRIVACY, {
  props: ({ mutate }) => ({
    removePrivacy: (degreeId, lang, onSuccess) =>
      mutate({
        variables: { degreeId, lang },
        update: () => onSuccess(),
      }),
  }),
});

const createTag = graphql(TAG_CREATE, {
  props: ({ mutate }) => ({
    createTag: (tag, onSuccess) =>
      mutate({
        variables: { tag },
        update: (cache, data) => onSuccess(data),
        refetchQueries: (fetchResult) => [
          {
            query: GET_TAGS_BY_CLUSTER_ID,
            variables: { clusterIds: clientConfig.EXPERTISE_CLUSTERIDS },
          },
        ],
      }),
  }),
});

class PublishDegree extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isScreenReady: false,
    };
  }

  componentWillMount() {
    this.props.UpdateCurrentDegreeId(this.props.match.params.degreeId);
    this.props.SetCurrentLanguage(this.props.match.params.lang);
  }

  formatOptionsForSelect = (options) =>
    options
      ? options.map((option) => ({
          value: option.Id,
          label: option.Value,
        }))
      : [];

  formatOptionsForSave = (options) =>
    options
      ? options.map((option) => ({
          Id: option.value,
          Value: option.label,
        }))
      : [];

  render() {
    let { classes } = this.props;
    let props = this.props;
    let handlers = {
      updateTitle: props.updateTitle,
      updateSummary: props.updateSummary,
      updatePicCover: props.updatePicCover,
      updatePicThumb: props.updatePicThumb,
      addTags: props.addTags,
      removeTags: props.removeTags,
      updateStatus: props.updateStatus,
      setPrivacy: props.setPrivacy,
      removePrivacy: props.removePrivacy,
      createTag: props.createTag,
    };

    return (
      <div className={classes.rootContainer}>
        <Query
          fetchPolicy="cache-and-network"
          query={GET_DEGREE_BY_ID}
          variables={{ degreeId: props.currentDegreeId }}
          notifyOnNetworkStatusChange
          onCompleted={(data) => {
            let degree = data.admin.degrees[0].versions.find(
              (x) => x.lang === props.currentLanguage,
            );
            if (degree) {
              let newTags = degree.tags
                .filter((x) =>
                  clientConfig.EXPERTISE_CLUSTERIDS.includes(
                    parseInt(x.clusterId),
                  ),
                )
                .map((x) => ({ Id: x.tagId, Value: x.title }));
              let newCountries = degree.tags
                .filter((x) =>
                  clientConfig.COUNTRY_TAGS_CLUSTERIDS.includes(
                    parseInt(x.clusterId),
                  ),
                )
                .map((x) => ({ Id: x.tagId, Value: x.title }));
              let newUserCategories = degree.tags
                .filter((x) =>
                  clientConfig.USER_CATEGORY_CLUSTERIDS.includes(
                    parseInt(x.clusterId),
                  ),
                )
                .map((x) => ({ Id: x.tagId, Value: x.title }));
              let newBrands = degree.tags
                .filter((x) =>
                  clientConfig.BRAND_TAGS_CLUSTER_IDS.includes(
                    parseInt(x.clusterId),
                  ),
                )
                .map((x) => ({ Id: x.tagId, Value: x.title }));
              let newRetailers = degree.tags
                .filter((x) =>
                  clientConfig.RETAILER_TAGS_CLUSTER_IDS.includes(
                    parseInt(x.clusterId),
                  ),
                )
                .map((x) => ({ Id: x.tagId, Value: x.title }));

              let publishDegreeInfo = {
                id: props.currentDegreeId,
                lang: props.currentLanguage,
                languages: [],
                tags: newTags,
                countries: newCountries,
                userCategories: newUserCategories,
                brands: newBrands,
                retailers: newRetailers,
                title: decodeNewLines(degree.title),
                summary: decodeNewLines(degree.summary),
                cover: degree.picCover,
                isPublic: !degree.isPrivate,
                tagsIsEditable: true,
                startPostingDate: degree.workflowScheduledIn,
                endPostingDate: degree.workflowScheduledOut,
              };
              props.UpdateDegreePublicationInfo(publishDegreeInfo);

              this.setState({ isScreenReady: true });
            }
          }}
        >
          {({ loading }) => {
            if (loading || !this.state.isScreenReady)
              return <FullscreenLoader />;
            return (
              <div className={classes.center}>
                <div className={classes.left}>
                  <FormattedMessage {...translations.TitlePlaceholder}>
                    {(message) => (
                      <ContentEditable
                        html={
                          props.publishDegree.title
                            ? props.publishDegree.title
                            : ""
                        }
                        onChange={({ target }) =>
                          props.UpdateDegreeTitle(
                            target.value,
                            { ...props.publishDegree },
                            handlers,
                          )
                        }
                        className={classes.textInputTitle}
                        placeholder={message[0]}
                        tagName="article"
                      />
                    )}
                  </FormattedMessage>
                  <br />
                  <FormattedMessage {...translations.SummaryPlaceholder}>
                    {(message) => (
                      <ContentEditable
                        html={
                          props.publishDegree.summary
                            ? props.publishDegree.summary
                            : ""
                        }
                        onChange={({ target }) =>
                          props.UpdateDegreeSummary(
                            target.value,
                            { ...props.publishDegree },
                            handlers,
                          )
                        }
                        className={classes.textInputSummary}
                        placeholder={message[0]}
                        tagName="article"
                      />
                    )}
                  </FormattedMessage>
                  <br />
                  <div className={classes.detailsContainer}>
                    <div className={classes.leftPart}>
                      <ImageSelector
                        id="image"
                        uniqueId="publishDegreeImage"
                        buttonLabel="Change Cover"
                        width="306px"
                        height="391px"
                        sourceImage={props.publishDegree.cover}
                        cropSize={CROP_VARIATION.PUBLISH_CARD}
                        onImageChange={(imageUrl, imageId) => {
                          props.UpdatePicCover(
                            imageUrl,
                            { ...props.publishDegree },
                            handlers,
                          );
                        }}
                      />
                    </div>
                    <div className={classes.rightPart}>
                      <div className={classes.langPublicContainer}>
                        <span className={classes.firstTitle}>
                          <FormattedMessage {...translations.LanguageLabel} />
                        </span>
                        <div className={classes.publicSwitcherContainer}>
                          <div className={classes.publicLabel}>
                            <FormattedMessage
                              {...translations.PublicButtonLabel}
                            />
                          </div>
                          <SwitchButton
                            isOn={props.publishDegree.isPublic}
                            handleToggle={(isPublic) =>
                              props.UpdatePrivacy(
                                props.currentDegreeId,
                                props.currentLanguage,
                                props.publishDegree,
                                isPublic,
                                handlers,
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className={classes.tagList}>
                        <FilterLanguages languages={LANGUAGES}>
                          {(filteredLanguages) => {
                            let languages = [
                              filteredLanguages
                                .map((x) => ({
                                  Id: x.Code,
                                  Value: x.Name,
                                }))
                                .find((x) => x.Id === props.publishDegree.lang),
                            ];

                            return languages.map((x) => (
                              <Tag key={x.Value} label={x.Value} readOnly />
                            ));
                          }}
                        </FilterLanguages>
                      </div>
                      {clientConfig.SHOW_BRAND_AND_RETAILER ? (
                        <div className={classes.brandAndRetailer}>
                          <div className={classes.brandTagsContainer}>
                            <div className={classes.sectionTitle}>
                              <FormattedMessage
                                {...translations[
                                  `BrandLabel_${clientBasedTranslationNameSpace[REACT_APP_CLIENT_NAME]}`
                                ]}
                              />
                            </div>
                            <FormattedMessage
                              {...translations[
                                `AddBrandPlaceholder_${clientBasedTranslationNameSpace[REACT_APP_CLIENT_NAME]}`
                              ]}
                            >
                              {(message) => (
                                <SelectTagsByClusterId
                                  tags={this.formatOptionsForSelect(
                                    props.brands,
                                  )}
                                  saveTagsToState={props.SaveTagsToState}
                                  className={classes.tagSelectContainer}
                                  id="brand"
                                  clusterIds={
                                    clientConfig.BRAND_TAGS_CLUSTER_IDS
                                  }
                                  tagType={CONFIG_CLUSTER.BRAND}
                                  selectedItems={this.formatOptionsForSelect(
                                    props.publishDegree.brands,
                                  )}
                                  placeholder={message[0]}
                                  onSelectionChanged={(selection) =>
                                    props.UpdateTags(
                                      this.formatOptionsForSave(selection),
                                      { ...props.publishDegree },
                                      props.currentDegreeId,
                                      props.currentLanguage,
                                      handlers,
                                      "brands",
                                    )
                                  }
                                  mustHaveAtLeastOneChoice
                                />
                              )}
                            </FormattedMessage>
                          </div>
                          <div className={classes.retailerTagsContainer}>
                            <div className={classes.sectionTitle}>
                              <FormattedMessage
                                {...translations[
                                  `RetailerLabel_${clientBasedTranslationNameSpace[REACT_APP_CLIENT_NAME]}`
                                ]}
                              />
                            </div>
                            <FormattedMessage
                              {...translations[
                                `AddRetailerPlaceholder_${clientBasedTranslationNameSpace[REACT_APP_CLIENT_NAME]}`
                              ]}
                            >
                              {(message) => (
                                <SelectTagsByClusterId
                                  tags={this.formatOptionsForSelect(
                                    props.retailers,
                                  )}
                                  saveTagsToState={props.SaveTagsToState}
                                  className={classes.tagSelectContainer}
                                  id="retailer"
                                  clusterIds={
                                    clientConfig.RETAILER_TAGS_CLUSTER_IDS
                                  }
                                  tagType={CONFIG_CLUSTER.RETAILER}
                                  selectedItems={this.formatOptionsForSelect(
                                    props.publishDegree.retailers,
                                  )}
                                  placeholder={message[0]}
                                  onSelectionChanged={(selection) =>
                                    props.UpdateTags(
                                      this.formatOptionsForSave(selection),
                                      { ...props.publishDegree },
                                      props.currentDegreeId,
                                      props.currentLanguage,
                                      handlers,
                                      "retailers",
                                    )
                                  }
                                />
                              )}
                            </FormattedMessage>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className={classes.sectionTitle}>
                            <FormattedMessage
                              {...translations.UserCategoryLabel}
                            />
                          </div>
                          <FormattedMessage
                            {...translations.SelectUserCategoriesPlaceholder}
                          >
                            {(message) => (
                              <SelectTagsByClusterId
                                tags={this.formatOptionsForSelect(
                                  props.userCategories,
                                )}
                                saveTagsToState={props.SaveTagsToState}
                                className={classes.tagSelectContainer}
                                id="usercategory"
                                clusterIds={
                                  clientConfig.USER_CATEGORY_CLUSTERIDS
                                }
                                tagType={CONFIG_CLUSTER.USER_CATEGORY}
                                selectedItems={this.formatOptionsForSelect(
                                  props.publishDegree.userCategories,
                                )}
                                placeholder={message[0]}
                                onSelectionChanged={(selection) =>
                                  props.UpdateTags(
                                    this.formatOptionsForSave(selection),
                                    { ...props.publishDegree },
                                    props.currentDegreeId,
                                    props.currentLanguage,
                                    handlers,
                                    "userCategories",
                                  )
                                }
                              />
                            )}
                          </FormattedMessage>
                        </div>
                      )}
                      <span className={classes.sectionTitle}>
                        <FormattedMessage
                          {...translations.AssociatedTagsLabel}
                        />
                      </span>
                      <FormattedMessage
                        {...translations.SelectAssociatedTagsPlaceholder}
                      >
                        {(message) => (
                          <SelectTagsByClusterId
                            isEditable
                            tags={this.formatOptionsForSelect(props.tags)}
                            saveTagsToState={props.SaveTagsToState}
                            className={classes.tagSelectContainer}
                            id="expertise"
                            clusterIds={clientConfig.EXPERTISE_CLUSTERIDS}
                            tagType={CONFIG_CLUSTER.EXPERTISE}
                            selectedItems={this.formatOptionsForSelect(
                              props.publishDegree.tags,
                            )}
                            placeholder={message[0]}
                            onSelectionChanged={(selection) =>
                              props.UpdateTags(
                                this.formatOptionsForSave(selection),
                                { ...props.publishDegree },
                                props.currentDegreeId,
                                props.currentLanguage,
                                handlers,
                                "tags",
                              )
                            }
                            onCreateOption={(inputValue) =>
                              props.CreateNewTag(
                                inputValue,
                                handlers,
                                props.publishDegree,
                                props.currentDegreeId,
                                props.currentLanguage,
                              )
                            }
                          />
                        )}
                      </FormattedMessage>
                      <div>
                        <span className={classes.sectionTitle}>
                          <FormattedMessage
                            {...translations[
                              `PublicationCountriesLabel_${clientBasedTranslationNameSpace[REACT_APP_CLIENT_NAME]}`
                            ]}
                          />
                        </span>
                        <FormattedMessage
                          {...translations[
                            `SelectCountriesPlaceholder_${clientBasedTranslationNameSpace[REACT_APP_CLIENT_NAME]}`
                          ]}
                        >
                          {(message) => (
                            <FilterCountries
                              countries={props.publishDegree.countries}
                            >
                              {({
                                filteredCountries,
                                deletedCountries,
                                filterCountries,
                              }) => (
                                <SelectTagsByClusterId
                                  tags={this.formatOptionsForSelect(
                                    props.countries,
                                  )}
                                  saveTagsToState={props.SaveTagsToState}
                                  className={classes.tagSelectContainer}
                                  id="country"
                                  clusterIds={
                                    clientConfig.COUNTRY_TAGS_CLUSTERIDS
                                  }
                                  tagType={CONFIG_CLUSTER.COUNTRY}
                                  selectedItems={this.formatOptionsForSelect(
                                    filteredCountries,
                                  )}
                                  placeholder={message[0]}
                                  onSelectionChanged={(selection) =>
                                    props.UpdateTags(
                                      deletedCountries.concat(
                                        this.formatOptionsForSave(
                                          selection || [],
                                        ),
                                      ),
                                      { ...props.publishDegree },
                                      props.currentDegreeId,
                                      props.currentLanguage,
                                      handlers,
                                      "countries",
                                    )
                                  }
                                  filterOption={(option) =>
                                    filterCountries(
                                      this.formatOptionsForSave([option]),
                                    ).filteredCountries.length > 0
                                  }
                                />
                              )}
                            </FilterCountries>
                          )}
                        </FormattedMessage>
                      </div>
                      <PublishDateHandler
                        startPostingDate={props.publishDegree.startPostingDate}
                        endPostingDate={props.publishDegree.endPostingDate}
                        updateStartPublicationDate={(date) =>
                          props.UpdateStartPublicationDate(
                            { ...props.publishDegree },
                            date,
                          )
                        }
                        updateEndPublicationDate={(date) =>
                          props.UpdateEndPublicationDate(
                            { ...props.publishDegree },
                            date,
                          )
                        }
                        startDateLabel={
                          <FormattedMessage
                            {...translations.StartPostingDateLabel}
                          />
                        }
                        endDateLabel={
                          <FormattedMessage
                            {...translations.EndPostingDateLabel}
                          />
                        }
                        noEndDateLabel={
                          <FormattedMessage
                            {...translations.NoEndPostingDateLabel}
                          />
                        }
                      />
                      <div className={classes.postingDate}>
                        <div className={classes.postingDateButton}>
                          <Button
                            variation="primary"
                            size="small"
                            handleClick={() =>
                              props.PublishDegree(
                                props.currentDegreeId,
                                props.currentLanguage,
                                props.publishDegree,
                                handlers,
                              )
                            }
                          >
                            <FormattedMessage
                              {...translations.ScheduleButtonText}
                            />
                          </Button>
                        </div>
                        <div className={classes.postingDateButton}>
                          <TransparentButton
                            handleClick={() => props.history.goBack()}
                          >
                            <FormattedMessage
                              {...translations.CancelScheduleButtonText}
                            />
                          </TransparentButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classes.right}
                  onClick={() => props.history.goBack()}
                >
                  <Icon
                    iconName={Icons.slimCloseIcon}
                    style={classes.closeIcon}
                  />
                </div>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  currentDegreeId: state.degrees.currentDegreeId,
  currentPageId: state.degrees.currentPageId,
  publishDegree: state.degrees.publishDegree,
  currentLanguage: state.degrees.currentLanguage,
  visibleTagSelector: state.degrees.visibleTagSelector,
  tags: state.referential.tags,
  countries: state.referential.countries,
  brands: state.referential.brands,
  retailers: state.referential.retailers,
  userCategories: state.referential.userCategories,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  UpdateCurrentDegreeId: (degreeId) => {
    dispatch(SetCurrentDegreeId(degreeId));
  },
  SetCurrentLanguage: (language) => {
    dispatch(SetCurrentLanguage(language));
  },
  UpdateDegreeTitle: (title, currentVersion, handlers) => {
    dispatch(SetPublishDegree({ ...currentVersion, title }));
    handlers.updateTitle(
      currentVersion.id,
      currentVersion.lang,
      cleanUpAndEncodeText(title),
    );
  },
  UpdateDegreeSummary: (summary, currentVersion, handlers) => {
    dispatch(SetPublishDegree({ ...currentVersion, summary }));
    handlers.updateSummary(
      currentVersion.id,
      currentVersion.lang,
      cleanUpAndEncodeText(summary),
    );
  },
  UpdateDegreePublicationInfo: (degreeInfo) => {
    dispatch(SetPublishDegree(degreeInfo));
  },
  UpdatePicCover: (image, currentVersion, handlers) => {
    currentVersion.cover = image;
    currentVersion.picThumb = image;
    currentVersion.picCard = image;
    handlers.updatePicCover(currentVersion.id, currentVersion.lang, image);
    dispatch(SetPublishDegree({ ...currentVersion }));
  },
  UpdateStartPublicationDate: (currentVersion, scheduledDate) => {
    currentVersion.dateSelectorType = DATE_SELECTOR_TYPE.SCHEDULE;
    currentVersion.startPostingDate = scheduledDate;
    dispatch(SetPublishDegree({ ...currentVersion }));
  },
  UpdateEndPublicationDate: (currentVersion, scheduledDate) => {
    currentVersion.dateSelectorType = DATE_SELECTOR_TYPE.SCHEDULE;
    currentVersion.endPostingDate = scheduledDate;
    dispatch(SetPublishDegree({ ...currentVersion }));
  },
  UpdateTags: (tagList, publishData, id, language, handlers, tagType) => {
    const beforeTagIds = publishData[tagType].map((x) => x.Id);
    const afterTagIds = tagList.map((x) => x.Id);
    const removeTagIds = beforeTagIds.filter(
      (beforeTagId) => !afterTagIds.includes(beforeTagId),
    );
    const newTagIds = afterTagIds.filter(
      (afterTagId) => !beforeTagIds.includes(afterTagId),
    );

    if (publishData.tagsIsEditable) {
      dispatch(
        SetPublishDegree({
          ...publishData,
          [tagType]: tagList,
          tagsIsEditable: false,
        }),
      );
      const updateCallback = (success) => {
        const updateData = { ...publishData, tagsIsEditable: true };
        if (success) {
          // if update was successful keep selected tags, else roll back
          updateData[tagType] = tagList;
        }
        dispatch(SetPublishDegree(updateData));
      };
      if (removeTagIds.length) {
        handlers.removeTags(id, language, removeTagIds, () => {
          if (newTagIds.length) {
            handlers.addTags(id, language, newTagIds, updateCallback);
          } else {
            updateCallback(true);
          }
        });
      } else if (newTagIds.length) {
        handlers.addTags(id, language, newTagIds, updateCallback);
      }
    }
  },
  PublishDegree: (id, language, degreeInfo, handlers) => {
    const startDate = formatDateTimeForSave(degreeInfo.startPostingDate);
    const endDate = degreeInfo.endPostingDate
      ? formatDateTimeForSave(degreeInfo.endPostingDate)
      : null;
    const workflow = isAfter(
      parseISODate(degreeInfo.startPostingDate),
      new Date(),
    )
      ? DOC_WORKFLOW_ENUM.DRAFT
      : DOC_WORKFLOW_ENUM.PUBLISHED;

    if (
      endDate &&
      isBefore(
        parseISODate(degreeInfo.endPostingDate),
        parseISODate(degreeInfo.startPostingDate),
      )
    ) {
      dispatch(
        SetErrorMessage(
          <FormattedMessage {...translations.IncorrectDateMessage} />,
        ),
      );
    } else {
      handlers.updateStatus(id, language, workflow, startDate, endDate, () => {
        ownProps.history.push("/degrees");
        dispatch(
          SetSuccessMessage(
            <FormattedMessage {...translations.SuccessfulPublishedMessage} />,
          ),
        );
      });
    }
  },
  UpdatePrivacy: (degreeId, degreeLang, publishData, isPublic, handlers) => {
    if (isPublic) {
      handlers.removePrivacy(degreeId, degreeLang, () => {
        dispatch(SetPublishDegree({ ...publishData, isPublic }));
      });
    } else {
      handlers.setPrivacy(degreeId, degreeLang, () => {
        dispatch(SetPublishDegree({ ...publishData, isPublic }));
      });
    }
  },
  SaveTagsToState: (tagList, tagType) => {
    dispatch(SetCurrentTags([...tagList], tagType));
  },
  CreateNewTag: (tagName, handlers, currentVersion, id, language) => {
    handlers.createTag(tagName, (data) => {
      let newTag = {
        Id: data.data.tag.createCustom.tagId,
        Value: data.data.tag.createCustom.title,
      };
      currentVersion.tags = [...currentVersion.tags, newTag];
      handlers.addTags(id, language, [newTag.Id], () => {
        dispatch(SetPublishDegree({ ...currentVersion }));
      });
    });
  },
});

const Container = compose(
  updateTitle,
  updateSumarry,
  updatePicCover,
  updatePicThumb,
  addTags,
  removeTags,
  updateWorlfow,
  setDegreePrivacy,
  removeDegreePrivacy,
  createTag,
)(withThemedStyle(PublishDegreeStyle)(PublishDegree));

export default withRouter(
  connect(mapStateToProp, mapDispatchToProps)(Container),
);
