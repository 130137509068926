import gql from "graphql-tag";

export const GET_BADGES = gql`
  query GetBadges($tagIds: [ID!], $parentId: ID) {
    admin {
      badges(tagIds: $tagIds, parentId: $parentId) {
        badgeId
        title
        score
        status
        tags {
          tagId
          clusterId
        }
        degreeRef {
          title
        }
      }
    }
  }
`;

export const GET_BADGE_BY_ID_WITH_DEGREE_REF = gql`
  query GetBadgeById($badgeId: ID) {
    admin {
      badges(badgeId: $badgeId) {
        badgeId
        pic
        title
        tags {
          tagId
          title
          clusterId
        }
        degreeRef {
          docId
          clones {
            docId
            tags {
              tagId
              title
              clusterId
            }
          }
        }
      }
    }
  }
`;

export const GET_CLONED_DEGREE = gql`
  query GetClonedDegree($tagIds: [ID!], $cloneParentId: Int) {
    admin {
      documents(tagIds: $tagIds, cloneParentId: $cloneParentId, type: degree) {
        docId
        versions {
          tags {
            tagId
            clusterId
          }
        }
      }
    }
  }
`;

export const CREATE_BADGE = gql`
  mutation createBadge($title: String!, $degreeId: ID, $parentId: ID) {
    admin {
      badge {
        create(
          title: $title
          degreeId: $degreeId
          status: DRAFT
          type: internalMultipleRange
          pic: "https://mediastoreshiseido.blob.core.windows.net/shiseido-upload/newness.png"
          parentId: $parentId
        ) {
          badgeId
        }
      }
    }
  }
`;

export const ADD_TAGS_TO_BADGE = gql`
  mutation AddTagsToBadge($badgeId: ID, $tagIds: [ID!]) {
    admin {
      badge(badgeId: $badgeId) {
        tags {
          add(tagIds: $tagIds) {
            badgeId
          }
        }
        addRange(mention: "", score: 0) {
          badgeId
        }
      }
    }
  }
`;

export const GET_MASTER_DEGREES = gql`
  query GetMasterDegrees {
    admin {
      degrees(tagIds: 1114, workflow: PUBLISHED) {
        degreeId
        title
      }
    }
  }
`;

export const GET_DEGREE_BY_ID = gql`
  query GetDegreeById($degreeId: ID) {
    admin {
      degrees(degreeId: $degreeId) {
        degreeId
        tags {
          tagId
          clusterId
        }
      }
    }
  }
`;

export const ADD_LESSONS_TO_BADGE = gql`
  mutation AddLessonToBadge($badgeIds: [Int!], $lessonIds: [Int!]!) {
    admin {
      badges(badgeIds: $badgeIds) {
        setToLessons(lessonIds: $lessonIds)
      }
    }
  }
`;

export const ADD_QUIZZS_TO_BADGE = gql`
  mutation AddLessonToBadge($badgeIds: [Int!], $lessonIds: [Int!]!) {
    admin {
      badges(badgeIds: $badgeIds) {
        setToQuiz(lessonIds: $lessonIds)
      }
    }
  }
`;

export const REMOVE_LESSONS_TO_BADGE = gql`
  mutation AddLessonToBadge($badgeIds: [Int!], $lessonIds: [Int!]!) {
    admin {
      badges(badgeIds: $badgeIds) {
        removeFromLessons(lessonIds: $lessonIds)
      }
    }
  }
`;

export const GET_TAGS = gql`
  query GetTagsById($tagIds: [ID!], $clusterId: ID) {
    tags(tagIds: $tagIds, clusterId: $clusterId) {
      tagId
      title
    }
  }
`;

export const GET_BADGE_BY_ID = gql`
  query GetBadgeById($badgeId: ID) {
    admin {
      badges(badgeId: $badgeId) {
        badgeId
        pic
        title
        score
        tags {
          tagId
          title
          clusterId
        }
        badgeDocuments {
          docId
          auditTypeId
        }
      }
    }
  }
`;

export const GET_ACCESSIBLE_LESSONS = gql`
  query GET_DEGREE_BADGE($brandId: [ID!]!, $marketId: [ID!]) {
    admin {
      degrees(
        tagMultipleIntersectIds: { tagIds: $brandId }
        tagIds: $marketId
      ) {
        degreeId
        programs {
          programId
          versions {
            title
            lang
          }
          lessons {
            lessonId
            versions {
              title
              lang
              workflow
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_BADGE_STATUS = gql`
  mutation UpdateBadgeStatus($badgeId: ID, $status: StatusEnum) {
    admin {
      badge(badgeId: $badgeId) {
        update(status: $status) {
          badgeId
        }
      }
    }
  }
`;
