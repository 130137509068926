export const RESET_STORE = "RESET_KPI_BATTLE_STORE";
export const UPDATE_BATTLE_RESULTS = "UPDATE_KPI_BATTLE_RESULTS";
export const UPDATE_PUBLISHED_BATTLE_LIST = "UPDATE_PUBLISHED_BATTLE_LIST";
export const UPDATE_LEADER_BOARD_COLUMN_SORT =
  "UPDATE_LEADER_BOARD_COLUMN_SORT";
export const UPDATE_PUBLISHED_BATTLE_COLUMN_SORT =
  "UPDATE_PUBLISHED_BATTLE_COLUMN_SORT";
export const UPDATE_GLOBAL_BATTLE_LIMIT = "UPDATE_GLOBAL_BATTLE_LIMIT";
export const BACKUP_RESULTS = "BACKUP_RESULTS";
export const LOADING = "LOADING";
export const CLEAR_PUBLISHED_BATTLE = "CLEAR_PUBLISHED_BATTLE";
