import React from "react";
import { CLIENTS, REACT_APP_CLIENT_NAME } from "../../configs/client";
import DefaultPublishPage from "./PublishInsight";
import PublishInsightShiseido from "./SHISEIDO/PublishInsightShiseido";
import PublishPage from "../publish/index";

const PublishInsight = (props) => {
  switch (REACT_APP_CLIENT_NAME) {
    case CLIENTS.SHISEIDO.SHISEIDO:
    case CLIENTS.SHISEIDO.SHISEIDO_PREPROD:
    case CLIENTS.SHISEIDO.SHISEIDO_PROD:
      return <PublishInsightShiseido {...props} />;
    default:
      // TODO: We should use this one
      // return <PublishPage {...props} />
      return <DefaultPublishPage {...props} />;
  }
};

export default PublishInsight;
