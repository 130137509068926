import gql from "graphql-tag";

export const GET_CARD_BY_ID = gql`
  query cardGetById($cardId: ID) {
    admin {
      cards(cardId: $cardId) {
        cardId
        versions {
          isPrivate
          title
          picCover
          langId
          lang
          summary
          author {
            userId
            status
            tags {
              tagId
              title
              pic
              description
              status
            }
          }
          tags {
            tagId
            pic
            title
            description
            status
            clusterId
            clusterMetaTagId
            parentTag {
              tagId
            }
          }
          workflow
          workflowId
          template
          card {
            pageId
            data
            type
            template
            lang
            activity
            index
          }
          workflowScheduledIn
          workflowScheduledOut
          updateDate
        }
      }
    }
  }
`;
