import React, { useState } from "react";
import PropTypes from "prop-types";
import { BadgeStyle } from "./Badge.style";
import withThemedStyle from "../../../../components/hoc/withThemedStyle";
import { Icon, Icons } from "genius-ui";
import Image from "../../../../components/common/Image";
import { PlaceHolderDegree } from "assets/icons";
import LessonItem from "./lessonItem";
import { FormattedMessage } from "react-intl";
import { translations } from "../SelectBadgeLessons.translations";

const Badge = ({
  classes,
  badgeImage,
  badgeName,
  onCheck,
  badgeId,
  lessons,
  lessonIds,
  quizz,
  onCheckQuizz,
}) => {
  const [showContent, setContentVisibility] = useState(true);

  return (
    <div className={classes.rootContainer}>
      <div className="header">
        <div>
          <Image width={40} height={40} url={badgeImage} />
          <div className="badgeName">{badgeName}</div>
        </div>
        <div className="arrowIcon">
          <Icon
            iconName={Icons[showContent ? "arrowOrderUp" : "arrowOrderDown"]}
            handleClick={() => setContentVisibility(!showContent)}
          />
        </div>
      </div>
      {showContent && (
        <div className={classes.content}>
          {lessons.length ? (
            lessons.map((lesson) => (
              <LessonItem
                {...lesson}
                key={lesson.id}
                onCheck={() => onCheck(badgeId, lesson.id)}
                onCheckQuizz={() => onCheckQuizz(badgeId, lesson.id)}
                checked={lessonIds?.includes(lesson.id)}
                quizzChecked={quizz?.includes(lesson.id)}
              />
            ))
          ) : (
            <div className={classes.noLessonText}>
              <FormattedMessage {...translations.NoOneLessonText} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

Badge.propTypes = {
  badgeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  badgeImage: PropTypes.string,
  badgeName: PropTypes.string,
  onCheck: PropTypes.func.isRequired,
  onCheckQuizz: PropTypes.func,
  lessons: PropTypes.array,
  lessonIds: PropTypes.array,
};

Badge.defaultProps = {
  badgeImage: PlaceHolderDegree,
  badgeName: "Badge name",
  lessons: [],
};

export default withThemedStyle(BadgeStyle)(Badge);
