export const ContentNotHandledStyle = (theme) => ({
  pageBox: {
    display: "flex",
    flexDirection: "column",
    color: theme.ContentNotHandled.pageBoxColor,
    width: 800,
  },
  containerMessage: {
    backgroundColor: theme.ContentNotHandled.containerMessageBackgroundColor,
    border: `1px solid ${theme.ContentNotHandled.borderColor}`,
    height: 40,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > span": {
      fontFamily: theme.Fonts.baseFontFamily,
      fontSize: "18px",
    },
  },
});
