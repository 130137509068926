import { ENV } from "./constants";

import COUNTRY_LANGUAGES from "./country_languages";
// SHISEIDO client environments configurations
import SHISEIDO_DEV_CONFIGURATION from "./environments/dev/config-shisheido.json";
import SHISEIDO_PREPROD_CONFIGURATION from "./environments/preprod/config-shisheido.json";
import SHISEIDO_PROD_CONFIGURATION from "./environments/prod/config-shisheido.json";

// PLURIS client environments configurations
import PLURIS_PROD_CONFIGURATION from "./environments/prod/config-pluris";

const environment =
  window.env && window.env.REACT_APP_CLIENT_NAME ? window.env : process.env;

export const REACT_APP_CLIENT_NAME = environment.REACT_APP_CLIENT_NAME;

export const REACT_APP_VERSION = environment.REACT_APP_VERSION;

export const HAS_FUSIONAUTH = environment.REACT_APP_AUTH_TYPE === "fusionAuth";

/**
 * Max video size in Mb (2 decimal)
 * */
export const VIDEO_MAX_SIZE_IN_MB =
  environment.REACT_APP_VIDEO_MAX_SIZE_IN_MB || 100.0;

export const AXIOS_CONFIG = {
  headers: { "Access-Control-Allow-Origin": window.location.origin },
};

export const FUSIONAUTH_CONFIG = {
  issuer: environment.REACT_APP_AUTH_ISSUER,
  clientId: environment.REACT_APP_AUTH_CLIENT_ID,
  tenantId: environment.REACT_APP_AUTH_TENANT_ID,
  redirectUrl: `${window.origin}/redirection`,
  postLogoutRedirectUri: window.origin,
  scope: "openid offline_access",
  idp: environment.REACT_APP_AUTH_IDP,
  routes: {
    authorize: "/oauth2/authorize",
    token: "/oauth2/token",
    refresh: "/api/jwt/refresh",
    userInfo: "/oauth2/userinfo",
    logout: "/oauth2/logout",
  },
};

export const CLIENTS = {
  SHISEIDO: {
    SHISEIDO: "SHISEIDO",
    SHISEIDO_PREPROD: "SHISEIDO-PREPROD",
    SHISEIDO_PROD: "SHISEIDO-PROD",
  },
  PLURIS: "PLURIS",
};

const configs = {
  SHISEIDO: SHISEIDO_DEV_CONFIGURATION,
  "SHISEIDO-PREPROD": SHISEIDO_PREPROD_CONFIGURATION,
  "SHISEIDO-PROD": SHISEIDO_PROD_CONFIGURATION,
  PLURIS: PLURIS_PROD_CONFIGURATION,
};

export const isShiseido = [
  "SHISEIDO",
  "SHISEIDO-PREPROD",
  "SHISEIDO-PROD",
].includes(REACT_APP_CLIENT_NAME);
export const isNotProd = ["SHISEIDO", "SHISEIDO-PREPROD"].includes(
  REACT_APP_CLIENT_NAME,
);
export const isDev = ["SHISEIDO"].includes(REACT_APP_CLIENT_NAME);

export const CONFIG_CLUSTER = {
  ZONE: "ZONE_CLUSTER_IDS",
  EXPERTISE: "EXPERTISE_CLUSTERIDS",
  COUNTRY: "COUNTRY_TAGS_CLUSTERIDS",
  USER_CATEGORY: "USER_CATEGORY_CLUSTERIDS",
  BRAND: "BRAND_TAGS_CLUSTER_IDS",
  RETAILER: "RETAILER_TAGS_CLUSTER_IDS",
  ENTITY: "ENTITY_CLUSTER_IDS",
  DIVISION: "DIVISION_CLUSTER_IDS",
  PROFESSION: "PROFESSION_CLUSTER_IDS",
  UNIVERSE: "UNIVERSE_CLUSTER_IDS",
  JOURNAL: "JOURNAL_CLUSTER_IDS",
  OCCUPATION: "OCCUPATION_CLUSTER_IDS",
  ROLE: "ROLE_CLUSTERS_ID",
  MARKET: "MARKET_CLUSTER_IDS",
};

export const CONFIG_META_CLUSTER = {
  COUNTRY: "COUNTRY_TAGS_META_CLUSTER_IDS",
};

export const TAG_TYPE = {
  BRAND: "brands",
  RETAILER: "retailers",
  ENTITY: "entities",
  DIVISION: "divisions",
  ZONE: "zones",
  COUNTRY: "countries",
  EXPERTISE: "tags",
  PROFESSION: "professions",
  USER_CATEGORY: "userCategories",
  UNIVERSE: "universes",
  JOURNAL: "journals",
  OCCUPATION: "occupation",
  ROLE: "roles",
};

export const FILTER_TYPES = {
  UNIVERSE: "universes",
  DEGREE: "degrees",
  PERIOD: "period",
  FROM_DATE: "fromDate",
  TO_DATE: "toDate",
};

export const MEDIA_SERVER =
  window.env && window.env.REACT_APP_MEDIA_SERVER
    ? window.env.REACT_APP_MEDIA_SERVER
    : process.env.REACT_APP_MEDIA_SERVER;

const clientConfig = configs[REACT_APP_CLIENT_NAME];

const userManagementFilterClusterIdAssociation = {
  ZONES: clientConfig.ZONE_CLUSTER_IDS,
  TAGS: clientConfig.USER_TAGS_CLUSTER_IDS,
  COUNTRIES: clientConfig.USER_COUNTRIES_CLUSTER_IDS,
  RELATIONSHIP: clientConfig.USER_RELATIONSHIP_CLUSTER_IDS,
  MARKET: clientConfig.USER_MARKET_CLUSTER_IDS,
  ZONE: clientConfig.ZONE_CLUSTER_IDS,
  DIVISION: clientConfig.DIVISION_CLUSTER_IDS,
};

clientConfig.USER_MANAGEMENT_DETAIL_COLUMNS =
  clientConfig.USER_MANAGEMENT_COLUMNS.filter(({ isDisplayed }) => isDisplayed);

clientConfig.USER_MANAGEMENT_EXPORT_COLUMNS =
  clientConfig.USER_MANAGEMENT_COLUMNS.filter(({ isExported }) => isExported);

export const USER_MANAGEMENT_FILTERS = clientConfig.USER_MANAGEMENT_FILTERS.map(
  (filterName) => {
    const clusters = userManagementFilterClusterIdAssociation[filterName];
    if (!clusters) {
      console.error(
        `No clusters were accociated with the filter ${filterName}`,
      );
    }

    return { name: filterName, clusters };
  },
);

export const COUNTRY_AVAILABLE_LANGUAGES = Object.keys(
  COUNTRY_LANGUAGES,
).reduce((availableLanguages, countryCode) => {
  if (clientConfig.COUNTRY_IDS[countryCode]) {
    availableLanguages[clientConfig.COUNTRY_IDS[countryCode]] =
      COUNTRY_LANGUAGES[countryCode];
  } else {
    availableLanguages[clientConfig.COUNTRY_IDS[countryCode]] = ["en"];
    if (ENV.IS_DEV && !ENV.IS_TEST) {
      console.warn(
        `Country Id for ${countryCode} is not configured. Couldn't associate languages with it.`,
        availableLanguages[clientConfig.COUNTRY_IDS[countryCode]],
        "was configured as the default associated language",
      );
    }
  }

  return availableLanguages;
}, {});

export default clientConfig;
