import * as constants from "../../constants/store/cards";
import { RESET_STORE } from "../../constants/store/auth";
import clientConfig from "../../configs/client";

const initialState = {
  currentLanguage: clientConfig.DEFAULT_LANGUAGE_CODE,
  currentCardId: null,
  currentPageId: null,
  cards: [],
  currentActiveSlide: null,
  cardLanguages: [],
  publishData: {
    title: "",
    workflow: null,
  },
  currentCardData: null,
  isMasterDocument: false,
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.SET_CURRENT_CARD_LANGUAGE:
      return {
        ...state,
        currentLanguage: data,
      };
    case constants.SET_CURRENT_CARD_ID:
      return {
        ...state,
        currentCardId: data,
      };
    case constants.SET_CURRENT_CARD_PAGEID:
      return {
        ...state,
        currentPageId: data,
      };
    case constants.SET_CARDS:
      return {
        ...state,
        cards: data,
      };
    case constants.SET_CURRENT_ACTIVE_SLIDE:
      return {
        ...state,
        currentActiveSlide: data,
      };
    case constants.SET_CARD_LANGUAGES:
      return {
        ...state,
        cardLanguages: data,
      };
    case constants.SET_PUBLISH_DATA:
      return {
        ...state,
        publishData: data,
      };
    case constants.SET_CARD_IS_MASTER_FLAG:
      return {
        ...state,
        isMasterDocument: data,
      };
    case constants.RESET_CARDS_STORE: {
      return { ...initialState };
    }
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
