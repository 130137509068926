import React from "react";
import { createUseStyles } from "react-jss";
import { PublishPreviewBannerStyle } from "./PublishPreviewBanner.style";
import { Button } from "genius-ui";
import PropTypes from "prop-types";
import classnames from "classnames";
import useThemedStyle from "../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(PublishPreviewBannerStyle);

const PublishPreviewBanner = (props) => {
  const { containerClassName, children } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div className={classnames([classes.headerContainer, containerClassName])}>
      {children}
      {/* TEMPORARY DISABLED TIIL WE DECIDE ON FUNCTION
      <Button variation='secondary' size='big' handleClick={props.onPreviewButtonClick} >Preview </Button>
      <div className={classes.spacer} /> */}
      <Button
        variation="primary"
        size="big"
        handleClick={props.onPublishButtonClick}
        className={classes.publishButton}
      >
        Publish
      </Button>
      <div className={classes.spacerRight} />
    </div>
  );
};

PublishPreviewBanner.propTypes = {
  /** handle preview button click */
  onPreviewButtonClick: PropTypes.func,
  /** handle publish button click */
  onPublishButtonClick: PropTypes.func,
  /** container className for custom styles */
  containerClassName: PropTypes.string,
};

export default PublishPreviewBanner;
