import { SimpleQuizCoverDefault } from "assets/icons";

export const QuickQuizTemplate = {
  template: "FeedQuizzTemplate",
  data: {
    image: {
      url: null,
      copyright: "Sopi",
      legend: "Quick quizz",
    },
    endImage: {
      url: null,
      copyright: "South Pigalle",
      legend: "Quick quizz",
    },
    question: "",
    answers: [
      {
        isTrue: true,
        title: "",
        message: "",
      },
      {
        isTrue: false,
        title: "",
        message: "",
      },
      {
        isTrue: false,
        title: "",
        message: "",
      },
    ],
    endMessage: "",
  },
};
