import React from "react";
import PropTypes from "prop-types";
import clientConfig from "../../configs/client";
import IntersectTagsWithUserTags from "../IntersectTagsWithUserTags";

/** Usability(and backward compatibility) wrapper around IntersectTagsWithUserTags **/
const FilterCountries = ({ countries, children }) => (
  <IntersectTagsWithUserTags
    tags={countries}
    userTagClusterIds={clientConfig.COUNTRY_TAGS_CLUSTERIDS}
  >
    {({ filteredTags, deletedTags, intersectWithUserTags }) =>
      children({
        filteredCountries: filteredTags,
        deletedCountries: deletedTags,
        filterCountries: (countries) => {
          const { filteredTags, deletedTags } =
            intersectWithUserTags(countries);

          return {
            filteredCountries: filteredTags,
            deletedCountries: deletedTags,
          };
        },
      })
    }
  </IntersectTagsWithUserTags>
);

FilterCountries.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  children: PropTypes.func.isRequired,
};

FilterCountries.defaultProps = {
  countries: [],
};

export default FilterCountries;
