/* global localStorage */
import atob from "atob";
import AuthService from "../core/authService";
import axios from "axios";
import { handleToken } from "./authentification";
import {
  AXIOS_CONFIG,
  FUSIONAUTH_CONFIG,
  HAS_FUSIONAUTH,
} from "../configs/client";
import jwtDecode from "jwt-decode";
import { store } from "../store/config";
import { GetProfileInfo } from "../store/auth/actions";

const handleRefreshFusionAuth = async () => {
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) window.location.href = "/logout";

  const token = localStorage.getItem("access_token");
  const refreshUrl = `${FUSIONAUTH_CONFIG.issuer}${FUSIONAUTH_CONFIG.routes.refresh}`;

  try {
    const { data } = await axios.post(
      refreshUrl,
      {
        refreshToken,
        token,
      },
      AXIOS_CONFIG,
    );

    if (!data) {
      window.location.href = "/logout";
      return;
    }

    const decodedAccessToken = jwtDecode(data.token);
    const expiresIn = decodedAccessToken.exp - decodedAccessToken.iat;

    handleToken({
      refresh_token: data.refreshToken,
      access_token: data.token,
      expires_in: expiresIn,
    });
    store.dispatch(GetProfileInfo());
    return data.token;
  } catch (err) {
    console.error(err);
    window.location.href = "/logout";
  }
};

export const getIdToken = () => {
  const idToken = window.localStorage.getItem("id_token");

  if (!idToken) {
    return "";
  }
  return idToken;
};

export const getAccessToken = () => {
  const accessToken = window.localStorage.getItem("access_token");
  if (!accessToken) {
    throw new Error("No access token found");
  }
  return accessToken;
};

const auth = new AuthService();

export const getAccessTokenV2 = async () => {
  const expiresAt = JSON.parse(localStorage.getItem("expires_at"));

  if (!(new Date().getTime() < expiresAt)) {
    if (HAS_FUSIONAUTH) return await handleRefreshFusionAuth();
    return auth.refreshToken();
  }

  const accessToken = window.localStorage.getItem("access_token");

  if (!accessToken) {
    throw new Error("No access token found");
  }
  return accessToken;
};

export const getClaimFromToken = (token, claim) => {
  const payload = token.split(".")[1];
  const bin = atob(payload);
  const obj = JSON.parse(bin);
  return obj[claim];
};
