import gql from "graphql-tag";

export const CARD_CREATE = gql`
  mutation adminCreateCard($lang: LangEnum, $title: String!) {
    admin {
      card(lang: $lang) {
        create(title: $title) {
          cardId
          versions {
            title
            lang
          }
        }
      }
    }
  }
`;
