import React from "react";
import PropTypes from "prop-types";
import { PeanutPageEditorStyle } from "./PeanutPageEditor.style";
import uuidv4 from "uuid/dist/v4";
import classnames from "classnames";
import { connect } from "react-redux";
import withThemedStyle from "../hoc/withThemedStyle";

class PeanutPageEditor extends React.PureComponent {
  render() {
    const { slideContainerClassName, renderSlideItem, classes } = this.props;

    const slideContainerClass = classnames([
      classes.sliderContainer,
      slideContainerClassName,
    ]);

    return (
      <div className={classes.pageBox}>
        <div className={slideContainerClass}>
          {this.props.currentCards.map((slide, index) =>
            renderSlideItem(
              slide,
              index,
              uuidv4(),
              this.props.currentActiveSlide === index,
              () => null,
              () => null,
              () => null,
              () => null,
              () => null,
              this.props.currentCards.length,
              this.props.pageId,
            ),
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  currentCards: state.cards.cards,
  currentActiveSlide: state.cards.currentActiveSlide,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

PeanutPageEditor.propTypes = {
  /** page Id  */
  pageId: PropTypes.string,
  /** page order id */
  orderId: PropTypes.number,
  /** current page data */
  data: PropTypes.string,
  /** id of current document */
  documentId: PropTypes.string,
  /** current language */
  language: PropTypes.string,
  /** class appended to the slide container's classes **/
  slideContainerClassName: PropTypes.string,
  /** cards array **/
  cards: PropTypes.array.isRequired,
  /** function used to render a single slide item **/
  renderSlideItem: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProp,
  mapDispatchToProps,
)(withThemedStyle(PeanutPageEditorStyle)(PeanutPageEditor));
