import React, { useCallback } from "react";
import { usePublishInsightStyle } from "./PublishInsight.style";
import { useDispatch, useSelector } from "react-redux";
import { UpdateInsightTags } from "../../store/insight/actions";
import { Icons, Icon, Tag, FullscreenLoader } from "genius-ui";
import SelectTagsByClusterId from "../../containers/Apollo/SelectTagsByClusterId";
import clientConfig, {
  CONFIG_CLUSTER,
  CONFIG_META_CLUSTER,
} from "../../configs/client";
import { LANGUAGES } from "../../configs/referential";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { FormattedMessage } from "react-intl";
import { translations } from "./PublishInsight.translation";
import FilterLanguages from "../../components/FilterLanguages";
import FilterCountries from "../../components/FilterCountries";
import SelectTagsByParentMetaClusterId from "../../containers/Apollo/SelectTagsByParentMetaClusterId";
import EditInsightTitle from "./components/editInsightTitle/EditInsightTitle";
import EditInsightSummary from "./components/editInsightSummary/EditInsightSummary";
import EditInsightCover from "./components/editInsightCover/EditInsightCover";
import EditInsightPrivacy from "./components/editInsightPrivacy/EditInsightPrivacy";
import EditInsightBrandAndRetailer from "./components/editInsightBrandAndRetailer/EditInsightBrandAndRetailer";
import EditInsightAssociatedTags from "./components/editInsightAssociatedTags/EditInsightAssociatedTags";
import EditInsightPublishDate from "./components/editInsightPublishDate/EditInsightPublishDate";
import useThemedStyle from "../../hooks/style/useThemedStyle";
import { useCurrentInsightGeneralInfo } from "./hooks/useCurrentInsightGeneralInfo";
import {
  formatOptionsForSave,
  formatOptionsForSelect,
} from "../../utils/dataTransformation/tags";

const selectors = {
  userCategories: (state) => state.referential.userCategories,
  selectedUserCategories: (state) =>
    state.insights.publishInsight.userCategories,
  selectedCountries: (state) => state.insights.publishInsight.countries,
};

const PublishInsight = (props) => {
  const dispatch = useDispatch();
  const userCategories = useSelector(selectors.userCategories);
  const selectedUserCategories = useSelector(selectors.selectedUserCategories);
  const selectedCountries = useSelector(selectors.selectedCountries);
  const classes = useThemedStyle(usePublishInsightStyle, props);
  const { isScreenReady, isLoaderActive, id, lang } =
    useCurrentInsightGeneralInfo(props);

  const currentLang = lang || clientConfig.DEFAULT_LANGUAGE_CODE;

  const UpdateTags = useCallback(
    (tagType, extraTags = []) =>
      (tags) =>
        dispatch(
          UpdateInsightTags(
            id,
            lang,
            extraTags.concat(formatOptionsForSave(tags)),
            tagType,
          ),
        ),
    [id, lang, dispatch],
  );

  if (!isScreenReady || isLoaderActive) {
    return <FullscreenLoader />;
  }

  return (
    <div className={classes.rootContainer}>
      <div className={classes.center}>
        <div className={classes.left}>
          <EditInsightTitle />
          <br />
          <EditInsightSummary />
          <br />
          <div className={classes.detailsContainer}>
            <div className={classes.leftPart}>
              <EditInsightCover />
            </div>
            <div className={classes.rightPart}>
              <div className={classes.langPublicContainer}>
                <div className={classes.firstTitle}>
                  <FormattedMessage {...translations.LanguageLabel} />
                </div>
                <EditInsightPrivacy />
              </div>
              <div className={classes.tagList}>
                <FilterLanguages languages={LANGUAGES}>
                  {(filteredLanguages) => {
                    const languages = [
                      filteredLanguages
                        .map((x) => ({
                          Id: x.Code,
                          Value: x.Name,
                        }))
                        .find((x) => x.Id === currentLang),
                    ];

                    return languages.map((x) => (
                      <Tag key={x.Value} label={x.Value} readOnly />
                    ));
                  }}
                </FilterLanguages>
              </div>
              {clientConfig.SHOW_BRAND_AND_RETAILER ? (
                <EditInsightBrandAndRetailer />
              ) : (
                <div>
                  <div>
                    <div className={classes.sectionTitle}>
                      <FormattedMessage {...translations.UserCategoryLabel} />
                    </div>
                    <FormattedMessage
                      {...translations.SelectUserCategoriesPlaceholder}
                    >
                      {(message) => (
                        <SelectTagsByClusterId
                          tags={formatOptionsForSelect(userCategories)}
                          className={classes.tagSelectContainer}
                          id="usercategory"
                          clusterIds={clientConfig.USER_CATEGORY_CLUSTERIDS}
                          tagType={CONFIG_CLUSTER.USER_CATEGORY}
                          selectedItems={formatOptionsForSelect(
                            selectedUserCategories,
                          )}
                          placeholder={message[0]}
                          onSelectionChanged={UpdateTags("userCategories")}
                        />
                      )}
                    </FormattedMessage>
                  </div>
                </div>
              )}
              <span className={classes.sectionTitle}>
                <FormattedMessage {...translations.AssociatedTagsLabel} />
              </span>
              <EditInsightAssociatedTags />
              <div>
                <span className={classes.sectionTitle}>
                  <FormattedMessage
                    {...translations.PublicationCountriesLabel}
                  />
                </span>
                <FormattedMessage {...translations.SelectCountriesPlaceholder}>
                  {(placeholder) => (
                    <FilterCountries countries={selectedCountries}>
                      {({
                        filteredCountries,
                        deletedCountries,
                        filterCountries,
                      }) => (
                        <SelectTagsByParentMetaClusterId
                          fetchAll
                          clusterIds={clientConfig[CONFIG_META_CLUSTER.COUNTRY]}
                          selectedItems={formatOptionsForSelect(
                            filteredCountries,
                          )}
                          placeholder={placeholder[0]}
                          className={classes.tagSelectContainer}
                          onSelectionChanged={UpdateTags(
                            "countries",
                            deletedCountries,
                          )}
                          filterOption={(option) =>
                            filterCountries(formatOptionsForSave([option]))
                              .filteredCountries.length > 0
                          }
                        />
                      )}
                    </FilterCountries>
                  )}
                </FormattedMessage>
              </div>
              <EditInsightPublishDate />
            </div>
          </div>
        </div>
        <div className={classes.right} onClick={() => props.history.goBack()}>
          <Icon iconName={Icons.slimCloseIcon} style={classes.closeIcon} />
        </div>
      </div>
    </div>
  );
};

export default PublishInsight;
