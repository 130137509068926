export default {
  container: {
    display: "block",
    cursor: "pointer",
  },
  containerClosed: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};
