import PropTypes from "prop-types";
import { POLL_QUESTION_TYPE_ENUM } from "../../../configs/constants";
import { PollQuestionChoicePropTypes } from "./poll_question_choice";
import uuidV4 from "uuid/dist/v4";
import { PollMessages } from "./index";
import {
  BattleLongTypeThumbnail,
  BattleSmallTypeThumbnail,
  BattleMediaTypeThumbnail,
} from "assets/icons";

export const PollQuestionPropTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(POLL_QUESTION_TYPE_ENUM)).isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  description: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.shape(PollQuestionChoicePropTypes))
    .isRequired,
};

export const CreatePollQuestion = (
  type = POLL_QUESTION_TYPE_ENUM.SIMPLE,
  title = "",
  description = "",
) => ({
  id: uuidV4(),
  type,
  title,
  description,
  image: null,
  choices: [],
});

export const PollQuestionTemplates = [
  {
    id: POLL_QUESTION_TYPE_ENUM.LONG,
    name: PollMessages.PollLongQuestionTemplateTitle,
    image: BattleLongTypeThumbnail,
  },
  {
    id: POLL_QUESTION_TYPE_ENUM.SIMPLE,
    name: PollMessages.PollSimpleQuestionTemplateTitle,
    image: BattleSmallTypeThumbnail,
  },
  {
    id: POLL_QUESTION_TYPE_ENUM.PICTURE,
    name: PollMessages.PollQuestionWithMediaTemplateTitle,
    image: BattleMediaTypeThumbnail,
  },
];
