import { merge } from "lodash";
import { RESET_STORE } from "../../../constants/store/auth";
import {
  SET_LIST_DATA,
  SET_LIST_IS_LOADING,
  SET_LIST_PAGINATION_METADATA,
  APPEND_TO_LIST_DATA,
} from "../../../constants/store/creators/list";

export default (prefix, customInitialState) => {
  const initialState = merge(
    {},
    {
      data: [],
      isLoading: false,
      pagination: {
        page: 0,
        limit: 10,
        hasMore: true,
      },
    },
    customInitialState,
  );

  return (state = initialState, action = {}) => {
    const { data, type } = action;
    switch (type) {
      case SET_LIST_DATA(prefix):
        return {
          ...state,
          data,
        };
      case SET_LIST_PAGINATION_METADATA(prefix):
        return {
          ...state,
          pagination: {
            ...state.pagination,
            ...data,
          },
        };
      case SET_LIST_IS_LOADING(prefix):
        return {
          ...state,
          isLoading: data,
        };
      case APPEND_TO_LIST_DATA(prefix):
        return {
          ...state,
          data: [...state.data, ...data],
        };
      case RESET_STORE: {
        return { ...initialState };
      }
      default:
        return state;
    }
  };
};
