import { USER_ROLE_NAMES } from "../configs/constants";
import clientConfig from "../configs/client";

const paths = {
  root: "/",
  pageNotFound: "/pageNotFound",
  test: "/test",
  login: "/login",
  logout: "/logout",
  forgotPassword: "/forgot-password",
  degreeBuild: (degreeId = ":id", lang = ":lang") =>
    `/degree/build/${degreeId}/${lang}`,
  degrees: "/degrees",
  degreesHistory: "/degrees/history",
  degreesHistoryParametered: (history = ":history?") => `/degrees/${history}`,
  createDegree: (degreeId = ":degreeId", lang = ":lang") =>
    `/degree/create/${degreeId}/${lang}`,
  buildDegree: (degreeId = ":degreeId", lang = ":lang") =>
    `/degree/build/${degreeId}/${lang}`,
  lessons: (degreeId = ":degreeId", lessonId = ":lessonId", lang = ":lang") =>
    `/lessons/${degreeId}/${lessonId}/${lang}`,
  publishLesson: (
    degreeId = ":degreeId",
    lessonId = ":lessonId",
    lang = ":lang",
  ) => `/lessons/publish/${degreeId}/${lessonId}/${lang}`,
  lessonBadges: (
    degreeId = ":degreeId",
    lessonId = ":lessonId",
    lang = ":lang",
  ) => `/lessons/badges/${degreeId}/${lessonId}/${lang}`,
  degreeBadges: (degreeId = ":degreeId", lang = ":lang") =>
    `/degree/badges/${degreeId}/${lang}`,
  lessonPublishPreview: (
    degreeId = ":degreeId",
    publishLessonId = ":publishLessonId",
    lang = ":lang",
  ) => `/lessons/${degreeId}/${publishLessonId}/${lang}`,
  contentType: "/content-type",
  createCardPeanut: (cardId = ":cardId", lang = ":lang") =>
    `/card/create/peanut/${cardId}/${lang}`,
  createCardInstagram: (cardId = ":cardId", lang = ":lang") =>
    `/card/create/instagram/${cardId}/${lang}`,
  cardPublish: (cardId = ":cardId", language = ":lang") =>
    `/card/publish/${cardId}/${language}`,
  quickQuizEdit: (quickQuizId = ":quickQuizId", language = ":lang") =>
    `/quick-quiz/edit/${quickQuizId}/${language}`,
  editBattle: (id = ":id", language = ":lang") =>
    `/battle/${id}/edit/${language}`,
  publishBattle: (id = ":id", language = ":lang") =>
    `/battle/${id}/publish/${language}`,
  contentManager: "/contentmanager",
  deletedContent: () => "/deleted-content",
  createInsight: (insightId = ":insightId", lang = ":lang") =>
    `/insight/create/${insightId}/${lang}`,
  publishInsight: (insightId = ":insightId", lang = ":lang") =>
    `/insight/publish/${insightId}/${lang}`,
  kpiComponents: "/kpiComponents",
  kpiGeneral: "/kpi/general",
  kpiLearning: "/kpi/learning",
  kpiApplication: "/kpi/application",
  kpiFeed: "/kpi/feed",
  kpiAsk: "/kpi/ask",
  media: "/media",
  mediaInfo: (id = ":id") => `/media/${id}`,
  createMedia: "/media/create",
  editMedia: (id = ":id") => `/media/edit/${id}`,
  selectMedia: "/media/select",
  users: "/users",
  userProfile: (id = ":id") => `/profile/${id}`,
  editJournalistProfile: (id = ":id") => `/journalist/edit/${id}`,
  viewJournalistProfile: (id = ":id") => `/journalist/view/${id}`,
  kpiShop: (id = ":id") => `/kpi/shop/${id}`,
  kpiMobileAnalytics: () => "/kpi/app-analytics",
  kpiDocument: () => "/kpi/document",
  kpiProduct: () => "/kpi/product",
  editPoll: (id = ":id", language = ":lang") => `/poll/${id}/edit/${language}`,
  editNinja: (id = ":id", language = ":lang") =>
    `/ninja/${id}/edit/${language}`,
  publishPoll: (id = ":id", language = ":lang") =>
    `/poll/${id}/publish/${language}`,
  masterTemplates: "/masterTemplates",
  editMasterTemplate: (id = ":id", lang = ":lang") =>
    `/masterTemplate/edit/${id}/${lang}`,
  editMasterTemplateLesson: (
    degreeId = ":degreeId",
    lessonId = ":lessonId",
    lang = ":lang",
  ) => `/masterTemplate/lesson/edit/${degreeId}/${lessonId}/${lang}`,
  documentAddToMarket: (type = ":type", id = ":id", lang = ":lang") =>
    `/${type}/addToMarket/${id}/${lang}`,
  masterDegreeBadges: (id = ":id", masterId = ":masterId", lang = ":lang") =>
    `/masterTemplate/badges/${id}/${masterId}/${lang}`,
  masterContentManager: "/masterContentManager",
  publishMasterInsight: (id = ":insightId", lang = ":lang") =>
    `/master/insight/publish/${id}/${lang}`,
  publishMasterBattle: (id = ":id", lang = ":lang") =>
    `/master/battle/publish/${id}/${lang}`,
  publishMasterCard: (id = ":cardId", lang = ":lang") =>
    `/master/card/publish/${id}/${lang}`,
  publishMasterPoll: (id = ":id", lang = ":lang") =>
    `/master/poll/publish/${id}/${lang}`,
  pollResults: (id = ":pollId") => `/results/poll/${id}`,
  battleResults: (id = ":battleId") => `/results/battle/${id}`,
  kpiBattleHome: () => "/kpi/battles",
  kpiNinja: () => "/kpi/ninja",
  kpiBattleGlobal: () => "/kpi/battles/global",
  createCardTwitter: (cardId = ":cardId", lang = ":lang") =>
    `/card/create/twitter/${cardId}/${lang}`,
  redirection: () => "/redirection",
  library: "/library",
  createFile: "/library/create",
  editFile: (id = ":id") => `/library/${id}/edit`,
  badges: "/badges",
  masterBadges: "/master/badges",
  createMasterBadge: "/master/badges/create",
  cloneBadgeToMarket: (id = ":id") => `/master/badges/clone/${id}`,
  editBadge: (id = ":id") => `/badges/${id}`,
};

export const getPath = (pathName, ...args) => {
  const desiredPath = paths[pathName];
  if (!desiredPath) {
    throw new Error(`Path ${pathName} doesn't exist`);
  }

  return !(typeof desiredPath === "function")
    ? desiredPath
    : desiredPath(...args);
};

export const MAIN_PAGE_PATHS = {
  DEFAULT: getPath(clientConfig.HOMEPAGE.DEFAULT),
  [USER_ROLE_NAMES.ADMIN]: getPath(clientConfig.HOMEPAGE.ADMIN),
  [USER_ROLE_NAMES.TRAINER]: getPath(clientConfig.HOMEPAGE.TRAINER),
  [USER_ROLE_NAMES.HQ]: getPath(clientConfig.HOMEPAGE.HQ),
};
