import React from "react";
import { SwitchButtonStyles } from "./SwitchButton.style";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import PropTypes from "prop-types";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(SwitchButtonStyles);

const SwitchButton = (props) => {
  const classes = useThemedStyle(useStyle, props);
  const { isOn, handleToggle, id } = props;

  return (
    <div className={classes.switchContainer}>
      <input
        checked={isOn}
        onChange={() => handleToggle(!isOn)}
        className={classes.switchCheckbox}
        type="checkbox"
        id={`switch_${id}`}
      />
      <label
        className={classNames([classes.switchLabel, "react-switch-label"])}
        htmlFor={`switch_${id}`}
      >
        <span
          className={classNames([classes.switchButton, "react-switch-button"])}
        />
      </label>
    </div>
  );
};

SwitchButton.propTypes = {
  /** value of the check status */
  isOn: PropTypes.bool.isRequired,
  /** check status handler */
  handleToggle: PropTypes.func.isRequired,
  /** key for multiple use */
  id: PropTypes.string,
};

export default SwitchButton;
