import { defineMessages } from "react-intl";

export const translations = defineMessages({
  TitleVideoUpload: {
    id: "SliderVideoPlayer.TitleVideoUpload",
    defaultMessage: "Video upload",
  },
  DescriptionVideoImport: {
    id: "SliderVideoPlayer.DescriptionVideoImport",
    defaultMessage: "Import",
  },
  TitleVideoConversion: {
    id: "SliderVideoPlayer.TitleVideoConversion",
    defaultMessage: "Video conversion",
  },
  DescriptionVideoCompress: {
    id: "SliderVideoPlayer.DescriptionVideoCompress",
    defaultMessage: "Compress",
  },
});
