import gql from "graphql-tag";

export const DEGREE_UPDATE_PIC_CARD = gql`
  mutation degreeUpdatePicCard($degreeId: ID, $lang: LangEnum, $data: String!) {
    admin {
      degree(degreeId: $degreeId, lang: $lang) {
        picCard(data: $data)
      }
    }
  }
`;
