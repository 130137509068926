import client from "../../graphql/client";
import { LESSON_GET_BY_ID } from "../../graphql/remote/lessons/queries";
import { SetErrorMessage } from "../notification/actions";
import { SetFetchIndicator } from "../common/actions";
import * as constants from "../../constants/store/lesson";
import { getFormattedLessonData } from "./transformers";
import { UPDATE_LESSON_INFO } from "../../graphql/remote/lessons/mutations/UpdateLessonInfo.graphql";
import {
  LESSON_ADD_TAGS,
  LESSON_REMOVE_PRIVACY,
  LESSON_REMOVE_TAGS,
  LESSON_SET_PRIVACY,
  LESSON_UPDATE_WORFLOW,
  UPDATE_LESSON_PIC_COVER,
} from "../../graphql/remote/lessons/mutations";
import { PlaceHolderDegree } from "assets/icons";
import { DOC_WORKFLOW_ENUM, PAGE_TEMPLATES } from "../../configs/constants";
import { LESSON_UPDATE_PAGE_DATA } from "../../graphql/remote/degrees/mutations";
import {
  formatDateTimeForSave,
  isAfter,
  parseISODate,
} from "../../utils/dateUtils";

export const UpdateEditLessonData = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_EDIT_LESSON_DATA,
    data: data,
  });
};

export const ResetStore = () => (dispatch) => {
  dispatch({ type: constants.RESET_LESSON_STORE });
};

export const GetLessonData = (lessonId, lang) => async (dispatch) => {
  try {
    dispatch(SetFetchIndicator(true));
    const response = await client.query({
      fetchPolicy: "network-only",
      query: LESSON_GET_BY_ID,
      variables: { lessonId },
    });
    const lesson = response.data.admin.lessons[0].versions.find(
      (x) => x.lang === lang,
    );
    dispatch(
      UpdateEditLessonData(
        getFormattedLessonData(response, lesson, lessonId, lang),
      ),
    );
  } catch (e) {
    dispatch(
      SetErrorMessage("An error occurred while fetching the lesson data", e),
    );
  } finally {
    dispatch(SetFetchIndicator(false));
  }
};

export const UpdateLessonInfo = (type, data) => async (dispatch, getState) => {
  const { id: lessonId, lang } = getState().editLesson.lessonInfo;
  await client.mutate({
    mutation: UPDATE_LESSON_INFO,
    variables: { lessonId, lang, type, data },
  });
};

export const RemovePrivacy = () => async (dispatch, getState) => {
  const { id: lessonId, lang } = getState().editLesson.lessonInfo;
  await client.mutate({
    mutation: LESSON_REMOVE_PRIVACY,
    variables: { lessonId, lang },
  });
};

export const SetPrivacy = () => async (dispatch, getState) => {
  const { id: lessonId, lang } = getState().editLesson.lessonInfo;
  await client.mutate({
    mutation: LESSON_SET_PRIVACY,
    variables: { lessonId, lang },
  });
};

export const UpdateRecapStatus = () => async (dispatch, getState) => {
  let { picLessonRecap, lessonRecap } = getState().editLesson.lessonInfo;
  if (picLessonRecap) {
    picLessonRecap = "";
    lessonRecap = "";
  } else {
    picLessonRecap = PlaceHolderDegree;
  }
  dispatch(UpdateEditLessonData({ lessonRecap, picLessonRecap }));
  dispatch(UpdateLessonInfo("picLessonRecap", ""));
  dispatch(UpdateLessonInfo("lessonRecap", ""));
};

const addTags = async (lessonId, lang, tagIdList, onSuccess) => {
  await client.mutate({
    mutation: LESSON_ADD_TAGS,
    variables: { lessonId, lang, tagIdList },
    update: (dataProxy, result) => onSuccess(result.data.admin.lesson.tags.add),
  });
};

const removeTags = async (lessonId, lang, tagIdList, onSuccess) => {
  await client.mutate({
    mutation: LESSON_REMOVE_TAGS,
    variables: { lessonId, lang, tagIdList },
    update: () => onSuccess(),
  });
};

export const UpdateTags = (tagList, tagType) => async (dispatch, getState) => {
  const { lessonInfo } = getState().editLesson;
  const { id, lang } = lessonInfo;

  const beforeTagIds = lessonInfo[tagType].map((x) => x.Id);
  const afterTagIds = tagList.map((x) => x.Id);
  const removeTagIds = beforeTagIds.filter(
    (beforeTagId) => !afterTagIds.includes(beforeTagId),
  );
  const newTagIds = afterTagIds.filter(
    (afterTagId) => !beforeTagIds.includes(afterTagId),
  );

  if (lessonInfo.tagsIsEditable) {
    dispatch(
      UpdateEditLessonData({ [tagType]: tagList, tagsIsEditable: false }),
    );
    const updateCallback = (success) => {
      const updateData = { tagsIsEditable: true };
      if (success) {
        updateData[tagType] = tagList;
      }
      dispatch(UpdateEditLessonData(updateData));
    };
    if (removeTagIds.length) {
      await removeTags(id, lang, removeTagIds, () => {
        if (newTagIds.length) {
          addTags(id, lang, newTagIds, updateCallback);
        } else {
          updateCallback(true);
        }
      });
    } else if (newTagIds.length) {
      await addTags(id, lang, newTagIds, updateCallback);
    }
  }
};

export const UpdatePublishedLessonPageDataWithProgramQuizInfo =
  () => async (dispatch, getState) => {
    try {
      const { isProgramQuiz, id, lang, pages } =
        getState().editLesson.lessonInfo;
      await Promise.all(
        pages.reduce((pageUpdates, page) => {
          if (
            [
              PAGE_TEMPLATES.SIMPLE_QUIZ_TEMPLATE,
              PAGE_TEMPLATES.MADLIB_TEMPLATE,
              PAGE_TEMPLATES.DRAG_AND_DROP_TEMPLATE,
            ].includes(page.template)
          ) {
            const pageData = JSON.parse(page.data);
            pageUpdates.push(
              client.mutate({
                mutation: LESSON_UPDATE_PAGE_DATA,
                variables: {
                  lessonId: id,
                  pageId: page.pageId,
                  lang,
                  data: JSON.stringify({
                    ...pageData,
                    data: {
                      ...pageData.data,
                      isProgramQuiz,
                    },
                  }),
                },
              }),
            );
          }
          return pageUpdates;
        }, []),
      );
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while updating page data of a lesson with program quiz data",
          e,
        ),
      );
    }
  };

export const UpdateWorkflow =
  (status, callback) => async (dispatch, getState) => {
    const { id: lessonId, lang } = getState().editLesson.lessonInfo;
    await client.mutate({
      mutation: LESSON_UPDATE_WORFLOW,
      variables: { lessonId, lang, status },
      update: () => callback && callback(),
    });
  };

export const ScheduleAvailability =
  (callback) => async (dispatch, getState) => {
    const {
      id: lessonId,
      lang,
      startPublishDate,
      endPublishDate,
    } = getState().editLesson.lessonInfo;

    const dateIn = formatDateTimeForSave(startPublishDate);
    const dateOut = endPublishDate
      ? formatDateTimeForSave(endPublishDate)
      : null;
    const status = isAfter(parseISODate(startPublishDate), new Date())
      ? DOC_WORKFLOW_ENUM.DRAFT
      : DOC_WORKFLOW_ENUM.PUBLISHED;

    await client.mutate({
      mutation: LESSON_UPDATE_WORFLOW,
      variables: { lessonId, lang, status, dateIn, dateOut },
      update: () => callback && callback(),
    });
  };

export const UpdateLessonPicCover = (data) => async (dispatch, getState) => {
  const { id: lessonId, lang } = getState().editLesson.lessonInfo;
  await client.mutate({
    mutation: UPDATE_LESSON_PIC_COVER,
    variables: { lessonId, lang, data },
  });
};
