import { RESET_STORE } from "../../constants/store/auth";
import {
  UPDATE_PAGE_DATA,
  UPDATE_KPI_FEED_DATAS,
  UPDATE_FILTER_AVAILABLE_VALUES,
  UPDATE_FILTER_SELECTED_VALUES,
  UPDATE_PAGE_DATA_LOADING,
} from "../../constants/store/kpiFeedDetails";

const initialState = {
  insigths: 0,
  lessons: 0,
  socialMedias: 0,
  sliders: 0,
  popularContent: [],
  topTags: [],
  filters: {
    degree: {
      availableValues: [],
      selectedValues: [],
    },
  },
  allContent: [],
  isLoading: false,
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case UPDATE_PAGE_DATA: {
      return {
        ...state,
        ...data,
      };
    }
    case UPDATE_KPI_FEED_DATAS: {
      return {
        ...state,
        allContent: data,
      };
    }
    case UPDATE_PAGE_DATA_LOADING: {
      return {
        ...state,
        isLoading: data,
      };
    }
    case UPDATE_FILTER_SELECTED_VALUES: {
      return getMergedFilterState(
        state,
        data.filterName,
        "selectedValues",
        data.selectedValues,
      );
    }
    case UPDATE_FILTER_AVAILABLE_VALUES: {
      return getMergedFilterState(
        state,
        data.filterName,
        "availableValues",
        data.availableValues,
      );
    }
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}

const getMergedFilterState = (
  state,
  filterName,
  filterAttributeName,
  filterAttributeNewValue,
) => ({
  ...state,
  filters: {
    ...state.filters,
    [filterName]: {
      ...state.filters[filterName],
      [filterAttributeName]: filterAttributeNewValue,
    },
  },
});
