export const CropImageStyle = {
  uploadBtnWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
    },
  },
  uploadInput: {
    fontSize: "100px",
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
};
