import * as constants from "../../constants/store/kpiBattle";
import { RESET_STORE } from "../../constants/store/auth";
import { KPI_AVAILABLE_PERIODS } from "../../configs/KPI/constants";

const initialState = {
  publishedBattlesLoadingStatus: {
    isFetching: false,
    isFullLoaded: false,
    skip: 0,
  },
  filters: {
    selectedEndDate: null,
    selectedStartDate: null,
    selectedPeriod: KPI_AVAILABLE_PERIODS[3],
  },
  periods: KPI_AVAILABLE_PERIODS,
  results: [],
  ogResults: [],
  publishedBattles: [],
  leaderBoardColumnSort: null,
  publishedBattlesColumnSort: null,
  globalBattleLimit: 10,
  publishedBattleLimit: 10,
  globalBattleSkip: 0,
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.LOADING:
      return {
        ...state,
        publishedBattlesLoadingStatus: {
          isFetching:
            data.isFetching === null
              ? state.publishedBattlesLoadingStatus.isFetching
              : data.isFetching,
          isFullLoaded: data.isFullLoaded,
          skip:
            state.publishedBattlesLoadingStatus.skip +
            (data.skip ? data.skip : 0),
        },
      };
    case constants.UPDATE_BATTLE_RESULTS:
      return {
        ...state,
        results: data,
      };
    case constants.BACKUP_RESULTS:
      return {
        ...state,
        ogResults: data,
      };
    case constants.UPDATE_PUBLISHED_BATTLE_LIST:
      return {
        ...state,
        publishedBattles: state.publishedBattles.concat(data),
      };
    case constants.CLEAR_PUBLISHED_BATTLE:
      return {
        ...state,
        publishedBattles: initialState.publishedBattles,
        publishedBattlesLoadingStatus:
          initialState.publishedBattlesLoadingStatus,
      };
    case constants.UPDATE_LEADER_BOARD_COLUMN_SORT:
      return {
        ...state,
        leaderBoardColumnSort: data,
      };
    case constants.UPDATE_PUBLISHED_BATTLE_COLUMN_SORT:
      return {
        ...state,
        publishedBattlesColumnSort: data,
      };
    case constants.UPDATE_GLOBAL_BATTLE_LIMIT:
      return {
        ...state,
        globalBattleLimit: data,
      };
    case constants.RESET_STORE:
      return { ...initialState };
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
