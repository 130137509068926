export default {
  uploadBtnWrapper: {
    overflow: "hidden",
    display: "inline-block",
    position: "relative",
  },
  uploadInput: {
    padding: 100,
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0,
    display: "block",
    cursor: ({ disabled }) => (disabled ? "default" : "pointer"),
  },
};
