import React, { Component } from "react";
import PropTypes from "prop-types";
import { ContentManagerStyle } from "../ContentManager.style";
import ContentsQuery from "./contentsQuery/Contents.query";
import { LANGUAGES, LANGUAGES_BY_MARKET } from "../../../configs/referential";
import {
  DOC_WORKFLOW_ENUM,
  DOCUMENT_LEVEL,
  DOCUMENT_TYPES,
  USER_ROLE_NAMES,
} from "../../../configs/constants";
import {
  SetActiveMenuDocumentId,
  SetCurrentLanguage,
  SetCurrentDocumentType,
  SetViewType,
  SetListWorkflow,
  SetCurrentMarket,
  SetCurrentDocumentLevel,
} from "../../../store/document/actions";
import { SetCurrentInsightId } from "../../../store/insight/actions";
import { connect } from "react-redux";
import Navigation from "../../../components/Navigation";
import {
  Dropdown,
  FullscreenLoader,
  Icon,
  Icons,
  StylelessModal,
  Button,
  ButtonSize,
  ButtonVariation,
} from "genius-ui";
import { getPath } from "../../../core/paths";
import { graphql, Query } from "react-apollo";
import compose from "lodash/flowRight";
import { INSIGHT_UPDATE_WORFLOW } from "../../../graphql/remote/insights/mutations";
import { CARD_UPDATE_WORKFLOW } from "../../../graphql/remote/cards/mutations";
import { FormattedMessage, injectIntl } from "react-intl";
import { GET_DOCUMENTS_NUMBER } from "../../../graphql/remote/documents/queries";
import clientConfig from "../../../configs/client";
import { DOCUMENT_LIST_WORKFLOWS } from "../../../store/document/reducers";
import ViewSwitcher from "../../../components/common/ViewSwitcher";
import { translations } from "../ContentManager.translations";
import { DocumentTypeTranslations } from "../../../constants/domain_items/documents/document_types";
import { BATTLE_UPDATE_WORFLOW } from "../../../graphql/remote/battle/mutations/UpdateBattleWorkflow";
import withThemedStyle from "../../../components/hoc/withThemedStyle";
import { POLL_UPDATE_WORKFLOW } from "../../../graphql/remote/poll/mutations/UpdatePollWorkflow";
import { GetMarketFilterTags } from "../../../store/degree/actions";
import FilterLanguages from "../../../components/FilterLanguages";
import FixedContainer from "../../../components/common/FixedContainer";
import Spinner from "../../../components/common/Spinner";
import RoundButton from "../../../components/common/RoundButton";
import { SetWarningMessage } from "../../../store/notification/actions";
import {
  SetNotificationData,
  SetShowModal as SetShowNotificationModal,
} from "../../../store/sendNotification/actions";
import RadioGroup from "components/common/RadioGroup";

const deleteInsightPageRequest = graphql(INSIGHT_UPDATE_WORFLOW, {
  props: ({ mutate }) => ({
    insightDelete: (insightId, lang, status, refetch) =>
      mutate({
        variables: { insightId: insightId, lang: lang, status: status },
        update: () => {
          refetch();
        },
      }),
  }),
});

const deleteCardPageRequest = graphql(CARD_UPDATE_WORKFLOW, {
  props: ({ mutate }) => ({
    cardDelete: (cardId, lang, status, refetch) =>
      mutate({
        variables: { cardId: cardId, lang: lang, status: status },
        update: () => {
          refetch();
        },
      }),
  }),
});

const deleteBattlePageRequest = graphql(BATTLE_UPDATE_WORFLOW, {
  props: ({ mutate }) => ({
    battleDelete: (battleId, lang, status, refetch) =>
      mutate({
        variables: { battleId, lang, status },
        update: () => {
          refetch();
        },
      }),
  }),
});

const deletePollPageRequest = graphql(POLL_UPDATE_WORKFLOW, {
  props: ({ mutate }) => ({
    pollDelete: (pollId, lang, status, refetch) =>
      mutate({
        variables: { pollId, lang, status },
        update: () => {
          refetch();
        },
      }),
  }),
});

class ContentManager extends Component {
  state = {
    contentTypeLabel: "",
    isModalVisible: false,
    selectedLanguage: null,
  };

  componentDidMount() {
    this.props.GetFilterMarketTags();
    this.props.SetListWorkflow(
      this.props.isArchivePage
        ? DOCUMENT_LIST_WORKFLOWS.DELETED
        : DOCUMENT_LIST_WORKFLOWS.ACTIVE,
    );
  }

  handleClickCreateNew = () => {
    this.props.HandleCreateNewContent();
  };

  closeModal = () => this.setState({ isModalVisible: false });

  handleLanguageChosen = () => {
    this.props.SetCurrentLanguage(this.state.selectedLanguage);
    this.props.HandleCreateNewContent();
  };

  navigateToInsightPublishPage = (insightId) =>
    this.props.history.push(
      `/insight/publish/${insightId}/${this.props.currentLanguage}`,
    );

  navigateToCardPublishPage = (cardId) =>
    this.props.history.push(
      `/card/publish/${cardId}/${this.props.currentLanguage}`,
    );

  navigateToBattlePublishPage = (battleId) =>
    this.props.history.push(
      getPath("publishBattle", battleId, this.props.currentLanguage),
    );

  navigateToPollPublishPage = (pollId) =>
    this.props.history.push(
      getPath("publishPoll", pollId, this.props.currentLanguage),
    );

  render() {
    const props = this.props;
    if (!props.isReady) return null;
    const { classes, isArchivePage = false } = props;
    let selectedValue = LANGUAGES.find((x) => x.Code === props.currentLanguage);
    if (!selectedValue) {
      selectedValue = LANGUAGES.find(
        (x) => x.Code === clientConfig.DEFAULT_LANGUAGE_CODE,
      );
    }
    let docTypeOptions = Object.keys(DOCUMENT_TYPES)
      .map((x) => ({
        value: DOCUMENT_TYPES[x],
        label: (
          <FormattedMessage {...DocumentTypeTranslations[DOCUMENT_TYPES[x]]} />
        ),
      }))
      .filter(
        (option) =>
          clientConfig.MODULES.IS_BATTLE_MANAGEMENT_AVAILABLE ||
          option.value !== DOCUMENT_TYPES.BATTLE,
      )
      .filter(
        (option) =>
          clientConfig.MODULES.IS_POLL_MANAGEMENT_AVAILABLE ||
          option.value !== DOCUMENT_TYPES.POLL,
      );
    docTypeOptions.unshift({
      value: undefined,
      label: props.intl.formatMessage({ ...translations.AllContentOption }),
    });

    let userMarkets = props.markets;

    if (props.userRoles.includes(USER_ROLE_NAMES.TRAINER)) {
      userMarkets = props.markets.filter((x) =>
        props.userProfileTags.find((y) => y.tagId === x.value),
      );
    }

    const userMarketIds = userMarkets.map((market) => market.value);

    if (userMarkets.length > 1) {
      userMarkets = [
        ...[
          {
            value: undefined,
            label: props.intl.formatMessage({
              ...translations.AllMarketOptions,
            }),
          },
        ],
        ...userMarkets,
      ];
    } else {
      props.SetCurrentMarket(userMarkets[0]);
    }

    const isMarketSelected = props.selectedMarket && props.selectedMarket.value;
    const adminLanguages =
      (props.selectedMarket &&
        props.selectedMarket.value &&
        props.isAdmin &&
        LANGUAGES.filter((lang) =>
          LANGUAGES_BY_MARKET[props.selectedMarket.value].includes(lang.Code),
        )) ||
      LANGUAGES;

    return (
      <div className={classes.rootContainer}>
        <Navigation />
        <FixedContainer className={classes.createButtonContainer}>
          {props.isCreating ? (
            <Spinner size={30} />
          ) : (
            <RoundButton
              onClick={
                isMarketSelected
                  ? this.handleClickCreateNew
                  : () =>
                      props.SetWarningMessage(
                        props.intl.formatMessage({
                          ...translations.MarketMissingWarning,
                        }),
                      )
              }
            >
              <Icon iconName={Icons.plus} />
            </RoundButton>
          )}
        </FixedContainer>
        <div className={classes.actionsContainer}>
          <div className={classes.filtersContainer}>
            <div className={classes.marketSelector}>
              <Dropdown
                selectedOption={props.selectedMarket}
                onChange={(selectedOption) =>
                  props.SetCurrentMarket(selectedOption)
                }
                options={userMarkets}
                placeholder={
                  <FormattedMessage {...translations.AllMarketOptions} />
                }
                disabled={!props.markets.length || userMarkets.length === 1}
              />
            </div>
            <div className={classes.marketSelector}>
              <Dropdown
                selectedOption={{
                  value: props.currentDocumentType,
                  label: props.currentDocumentType
                    ? props.intl.formatMessage(
                        docTypeOptions?.find(
                          (option) =>
                            option.value === props.currentDocumentType,
                        ).label.props,
                      )
                    : props.intl.formatMessage({
                        ...translations.AllContentOption,
                      }),
                }}
                onChange={(selectedOption) => {
                  props.SetCurrentDocumentType(selectedOption.value);
                  this.setState({ contentTypeLabel: selectedOption.label });
                }}
                options={docTypeOptions}
              />
            </div>
            <div className={classes.typeSelector}>
              <FilterLanguages
                languages={LANGUAGES}
                selectedMarket={props.selectedMarket}
                currentLanguage={selectedValue.Code}
                setCurrentLanguage={props.SetCurrentLanguage}
              >
                {(languages) => (
                  <div className={classes.languageSelector}>
                    <Dropdown
                      selectedOption={{
                        value: selectedValue.Code,
                        label: selectedValue.Name,
                      }}
                      onChange={(selectedOption) => {
                        props.SetCurrentLanguage(selectedOption.value);
                      }}
                      options={languages.map((x) => ({
                        value: x.Code,
                        label: x.Name,
                      }))}
                      disabled={languages.length === 1}
                    />
                  </div>
                )}
              </FilterLanguages>
            </div>
          </div>
          <div className={classes.switcher}>
            <ViewSwitcher
              viewType={props.viewType}
              changeViewType={props.SetViewType}
            />
          </div>
        </div>
        <Query
          fetchPolicy="cache-and-network"
          query={GET_DOCUMENTS_NUMBER}
          notifyOnNetworkStatusChange
        >
          {({ loading, data }) => {
            if (loading) return <FullscreenLoader />;
            const docsNumber = data.admin.dashboard.documents;

            return (
              <ContentsQuery
                language={props.currentLanguage}
                activeMenuDocumentId={props.activeMenuDocumentId}
                onItemSettingsClicked={props.SetActiveMenuDocumentId}
                docType={props.currentDocumentType}
                market={
                  props.selectedMarket && props.selectedMarket.value !== null
                    ? props.selectedMarket.value
                    : undefined
                }
                history={props.history}
                handleDeleteInsight={(insightId, language, refetch) =>
                  props.insightDelete(
                    insightId,
                    language,
                    DOC_WORKFLOW_ENUM.ARCHIVED,
                    refetch,
                  )
                }
                handleDeleteCard={(cardId, language, refetch) =>
                  props.cardDelete(
                    cardId,
                    language,
                    DOC_WORKFLOW_ENUM.ARCHIVED,
                    refetch,
                  )
                }
                handleDeleteBattle={(battleId, language, refetch) =>
                  props.battleDelete(
                    battleId,
                    language,
                    DOC_WORKFLOW_ENUM.ARCHIVED,
                    refetch,
                  )
                }
                handleDeletePoll={(pollId, language, refetch) =>
                  props.pollDelete(
                    pollId,
                    language,
                    DOC_WORKFLOW_ENUM.ARCHIVED,
                    refetch,
                  )
                }
                showDuplicationModal={props.showDuplicationModal}
                duplicationData={props.duplicationData}
                setNotificationData={props.SetNotificationData}
                setShowDuplicationModal={props.SetShowDuplicationModal}
                setShowNotificationModal={props.SetShowNotificationModal}
                setDuplicationData={props.SetDuplicationData}
                allDocNumber={docsNumber}
                viewType={props.viewType}
                archivedContents={isArchivePage}
                navigateToInsightPublishPage={this.navigateToInsightPublishPage}
                navigateToCardPublishPage={this.navigateToCardPublishPage}
                navigateToBattlePublishPage={this.navigateToBattlePublishPage}
                navigateToPollPublishPage={this.navigateToPollPublishPage}
                userMarketIds={userMarketIds}
              />
            );
          }}
        </Query>
        <StylelessModal
          show={this.state.isModalVisible}
          onClose={this.closeModal}
          centered
        >
          <div className={classes.modalLanguagesContainer}>
            <div className={classes.modalLanguagesHeaderContainer}>
              <FormattedMessage {...translations.ChooseLanguageModalHeader} />
              <Icon
                handleClick={this.closeModal}
                iconName={Icons.slimCloseIcon}
                style={classes.closeBtn}
              />
            </div>
            <RadioGroup
              options={(props.isAdmin ? adminLanguages : LANGUAGES).map(
                (x) => ({ value: x.Code, label: x.Name }),
              )}
              onChange={(selected) =>
                this.setState({ selectedLanguage: selected.value })
              }
              value={this.state.selectedLanguage}
              disabled={false}
            />
            <div className={classes.modalLanguagesButtonContainer}>
              <Button
                handleClick={this.handleLanguageChosen}
                variation={ButtonVariation.primary}
                size={ButtonSize.small}
                disabled={!this.state.selectedLanguage}
              >
                <FormattedMessage {...translations.ChooseLanguageModalButton} />
              </Button>
            </div>
          </div>
        </StylelessModal>
      </div>
    );
  }
}

ContentManager.propTypes = {
  isArchivePage: PropTypes.bool,
};

ContentManager.defaultProps = {
  isArchivePage: false,
};

const mapStateToProp = (state) => ({
  currentLanguage: state.documents.currentLanguage,
  currentDocumentType: state.documents.currentDocumentType,
  activeMenuDocumentId: state.documents.activeMenuDocumentId,
  viewType: state.documents.viewType,
  showDuplicationModal: state.duplication.showModal,
  duplicationData: state.duplication.duplicationData,
  showNotificationModal: state.sendNotification.showModal,
  currentInsightTagIds: state.insights.currentInsightTagIds,
  currentCardTagIds: state.cards.currentCardTagIds,
  selectedMarket: state.documents.selectedMarket,
  markets: state.degrees.markets,
  userProfileTags: state.auth.profileInfo.tags,
  userRoles: state.auth.profileInfo.roles,
  isReady: state.auth.profileInfo.ready,
  isCreating: state.common.saveIndicator,
  isAdmin: state.auth.profileInfo.roles.some((role) => role === "admin"),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  SetCurrentLanguage: (language) => {
    dispatch(SetCurrentLanguage(language));
  },
  SetActiveMenuDocumentId: (activeDocumentId) => {
    dispatch(SetActiveMenuDocumentId(activeDocumentId));
    dispatch(SetCurrentDocumentLevel(DOCUMENT_LEVEL.NORMAL));
  },
  SetCurrentDocumentType: (type) => {
    dispatch(SetCurrentDocumentType(type));
  },
  SetCurrentMarket: (market) => {
    dispatch(SetCurrentMarket(market));
  },
  UpdateCurrentInsightId: (insightId) => {
    dispatch(SetCurrentInsightId(insightId));
  },
  SetViewType: (newValue) => {
    dispatch(SetViewType(newValue));
  },
  SetListWorkflow: (newValue) => {
    dispatch(SetListWorkflow(newValue));
  },
  GetFilterMarketTags: () => {
    dispatch(GetMarketFilterTags());
  },
  HandleCreateNewContent: () => {
    dispatch(SetCurrentDocumentLevel(DOCUMENT_LEVEL.NORMAL));
    ownProps.history.push(getPath("contentType"));
  },
  SetWarningMessage: (message) => {
    dispatch(SetWarningMessage(message));
  },
  SetNotificationData: (newValue) => {
    dispatch(SetNotificationData(newValue));
  },
  SetShowNotificationModal: (newValue) => {
    dispatch(SetShowNotificationModal(newValue));
  },
});

const Container = compose(
  deleteInsightPageRequest,
  deleteCardPageRequest,
  deleteBattlePageRequest,
  deletePollPageRequest,
)(withThemedStyle(ContentManagerStyle)(ContentManager));

export default injectIntl(
  connect(mapStateToProp, mapDispatchToProps)(Container),
);
