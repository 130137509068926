import React, { Suspense } from "react";
import LazyLoadedModuleErrorBoundary from "./ErrorBoundary";
import LazyLoadedModuleFallback from "./Fallback";

const LazyLoadedModuleWrapper = ({ children }) => (
  <LazyLoadedModuleErrorBoundary>
    <Suspense fallback={<LazyLoadedModuleFallback />}>{children}</Suspense>
  </LazyLoadedModuleErrorBoundary>
);

export default LazyLoadedModuleWrapper;
