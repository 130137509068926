export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
};

export const decodeEntities = (encodedString) => {
  var textArea = document.createElement("textarea");
  textArea.innerHTML = encodedString;
  return textArea.value;
};

export const cleanupHtml = (s) => {
  // Remove the whole <style> tag
  let formattedString = removeStyleTagWithAllStyle(s);
  // Remove all inline styling from copy paste
  formattedString = formattedString.replace(/style="(.*?)"/g);
  // Remove all  html tags
  formattedString = formattedString.replace(/<\/?[^>]+(>|$)/g, "");
  return formattedString.trim();
};
// NEED TO ENCODE div and br as new lines for saving content
export const encodeNewLines = (s) => {
  /** when adding a new line, the editor puts a <div><br><\/div> into the content, so that has to be replaced with a
   * simple new line character **/
  if (s && typeof s.replace === "function") {
    let formattedString = s.replace(/<div><br><\/div>/g, "\n"); // replace <div><br><\/div>'s with a new line
    /** <div> at the beginning of the string has to be removed, because the content is put inside a div -
     * temporarly removed. seems useless **/
    // formattedString = formattedString.replace(/^<div>+/, '')// remove leading <div>s
    /** </div> shouldn't be treated as a new line **/
    formattedString = formattedString.replace(/<\/div>/g, ""); // remove </div>s
    /** only <div>s and <br>s should be treated as a new line **/
    formattedString = formattedString.replace(/<div>/g, "\n");
    formattedString = formattedString.replace(/<br>/g, "\n");

    // redecode & characters
    formattedString = formattedString.replace(/&amp;/g, "&");
    formattedString = formattedString.replace(/&nbsp;/g, " ");

    return formattedString;
  } else {
    return s;
  }
};

export const decodeNewLines = (s) => {
  if (s) {
    return s.split("\n").join("<br>");
  }

  return s;
};

export const excludeBreakLines = (s) => {
  if (s) {
    return s.replace(/\n/g, " ");
  }
  return s;
};

export const encapsulateWithSpaces = (content, contentToBeEncapsulated) => {
  const startIndex = content.indexOf(contentToBeEncapsulated);
  const endIndex = startIndex + contentToBeEncapsulated.length;
  const returnString = [
    content.slice(0, startIndex).trim(),
    " ",
    contentToBeEncapsulated,
    " ",
    content.slice(endIndex).trim(),
  ].join("");
  return returnString;
};

export const cleanUpAndEncodeText = (text) => cleanupHtml(encodeNewLines(text));

export const removeStyleTagWithAllStyle = (s) => {
  if (s) {
    return s
      .replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/g, "")
      .trim();
  }
  return s;
};

export const roundPtsNbr = (value) => Math.round(value * 10) / 10;
