export default {
  cardContainer: {
    paddingBottom: 10,
    width: 495,
    "& > div:nth-of-type(1)": {
      maxWidth: "unset",
      width: 495,
    },
  },
  docSettingsMenu: {
    width: 200,
    left: 490,
    position: "relative",
    top: -10,
    zIndex: 2,
  },
  loadMoreContainer: {
    margin: "40px 0",
    float: "left",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
};
