import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isShiseido } from "../../configs/client";
import { USER_ROLE_NAMES } from "../../configs/constants";

const IntersectTagsWithUserTags = ({
  userTagIds,
  userRoles,
  tags,
  children,
}) => {
  const intersectWithUserTags = (tags) => {
    let filteredTags = tags;
    const deletedTags = [];

    if (isShiseido && userRoles.includes(USER_ROLE_NAMES.TRAINER)) {
      filteredTags = tags.filter((tag) => {
        const tagShouldBeKept = userTagIds.includes(parseInt(tag.Id));
        if (!tagShouldBeKept) {
          deletedTags.push(tag);
        }

        return tagShouldBeKept;
      });
    }

    return {
      filteredTags,
      deletedTags,
    };
  };

  const { filteredTags, deletedTags } = intersectWithUserTags(tags);

  return children({
    filteredTags,
    deletedTags,
    intersectWithUserTags,
  });
};

IntersectTagsWithUserTags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  children: PropTypes.func.isRequired,
  userTagClusterIds: PropTypes.array.isRequired,
};

IntersectTagsWithUserTags.defaultProps = {
  tags: [],
};

const mapStateToProps = (state, ownProps) => ({
  userTagIds: state.auth.profileInfo.tags
    .filter((tag) =>
      ownProps.userTagClusterIds.includes(parseInt(tag.clusterId)),
    )
    .map((tag) => parseInt(tag.tagId)),
  userRoles: state.auth.profileInfo.roles,
});

export default connect(mapStateToProps)(IntersectTagsWithUserTags);
