import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import style from "./PublishMasterBattle.style";
import {
  Icon,
  Icons,
  InputText,
  Tag,
  TagVariation,
  FullscreenLoader,
} from "genius-ui";
import { FormattedMessage, useIntl } from "react-intl";
import { translations } from "./PublishMasterBattle.translations";
import GrayStrokedRSDropdown from "../../../components/common/GrayStrokedRSDropdown";
import UploadMedia from "../../../components/common/UploadMedia";
import SelectTagsByClusterId from "../../../containers/Apollo/SelectTagsByClusterId";
import clientConfig, {
  CONFIG_CLUSTER,
  TAG_TYPE,
} from "../../../configs/client";
import { getPath } from "../../../core/paths";
import { connect, useDispatch } from "react-redux";
import {
  AddTags,
  GetBattleInfo,
  RemoveTags,
  ResetStore,
  UpdateBattleInfo,
  UpdateBattlePage,
  UpdateMasterBattleWorkflow,
  UpdatePublishData,
} from "../../../store/publishBattle/actions";
import {
  formatOptionsForSave,
  formatOptionsForSelect,
} from "../../../utils/dataTransformation/tags";
import {
  getPageWithNewScore,
  getPageWithNewTimer,
} from "../../../store/publishBattle/transformers";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import PublicationButtons from "../../../components/PublicationMasterDocuments/PublicationButtons";
import { DOC_WORKFLOW_ENUM } from "../../../configs/constants";
import { SetWarningMessage } from "../../../store/notification/actions";

const useStyle = createUseStyles(style);

const PublishBattle = (props) => {
  const {
    history,
    match,
    fetchPublishData,
    resetStore,
    publishData,
    isFetching,
    intervals,
    updateBattleInfo,
    updateTimePerSecond,
    isUpdating,
    UpdateTags,
    updateScorePerSecond,
    UpdateWorkflow,
  } = props;
  const classes = useThemedStyle(useStyle, props);
  const [publishState, setPublishState] = useState(publishData);
  const [classNameError, setClassNameError] = useState(null);
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchPublishData(match.params.id, match.params.lang);
    return () => {
      resetStore();
    };
  }, []);

  useEffect(() => {
    setPublishState(publishData);
  }, [publishData]);

  const setScore = ({ target: { value } }) => {
    if (!value || value.match(/^[0-9]+$/)) {
      setPublishState({ ...publishState, scorePerQuestions: value });
    }
  };

  const updateScoreInPage = () => {
    const scorePerQuestions = parseInt(publishState.scorePerQuestions) || 0;
    updateScorePerSecond(publishData, scorePerQuestions);
  };

  const navigateToTheMasterContentsPage = () =>
    history.push(getPath("masterContentManager"));

  const handleClickSave = () => {
    if (publishData.brands.length) navigateToTheMasterContentsPage();
    else {
      dispatch(
        SetWarningMessage(
          intl.formatMessage(translations.IndicateBrandsWarning),
        ),
      );
      setClassNameError(classes.tagSelectContainerError);
    }
  };

  const updateTagsBrand = () => {
    if (publishData.brands.length > 0) setClassNameError(null);
    return (tags) => UpdateTags(publishData, tags, TAG_TYPE.BRAND);
  };

  return (
    <div className={classes.rootContainer}>
      {(isFetching || isUpdating) && <FullscreenLoader />}
      <div className={classes.content}>
        <div className={classes.photo}>
          <UploadMedia
            id="battleImage"
            isVideoUploadEnabled={false}
            onUploadSuccess={(src) => updateBattleInfo("picCover", src)}
          >
            <button className={classes.uploadBtn}>
              <FormattedMessage {...translations.UploadImage} />
            </button>
          </UploadMedia>
        </div>
        <div className={classes.rightSide}>
          <div className={classes.close}>
            <Icon
              iconName={Icons.slimCloseIcon}
              handleClick={navigateToTheMasterContentsPage}
            />
          </div>
          <FormattedMessage {...translations.BattleTitlePlaceholder}>
            {(message) => (
              <InputText
                className={classes.battleTitle}
                label={message[0]}
                onChange={(e) =>
                  setPublishState({ ...publishState, title: e.target.value })
                }
                onBlur={() => updateBattleInfo("title", publishState.title)}
                value={publishState.title}
                floatingLabel={false}
              />
            )}
          </FormattedMessage>
          <FormattedMessage {...translations.BattleDescriptionPlaceholder}>
            {(message) => (
              <InputText
                className={classes.battleDescription}
                label={`${message[0]}...`}
                onChange={(e) =>
                  setPublishState({ ...publishState, summary: e.target.value })
                }
                onBlur={() => updateBattleInfo("summary", publishState.summary)}
                value={publishState.summary}
                floatingLabel={false}
              />
            )}
          </FormattedMessage>
          <div className={classes.title}>
            <FormattedMessage {...translations.ScorePerQuestion} />
          </div>
          <FormattedMessage {...translations.ScorePlaceholder}>
            {(scorePlaceholder) => (
              <InputText
                value={publishState.scorePerQuestions}
                onChange={setScore}
                onBlur={updateScoreInPage}
                placeholder={scorePlaceholder[0]}
                className={classes.simpleInput}
                floatingLabel={false}
              />
            )}
          </FormattedMessage>
          <div className={classes.title}>
            <FormattedMessage {...translations.TimePerQuestion} />
          </div>
          <GrayStrokedRSDropdown
            value={publishData.timePerQuestion}
            options={intervals}
            onChange={(selected) => updateTimePerSecond(publishData, selected)}
            className={classes.simpleDropdown}
          />
          <div className={classes.title}>
            <FormattedMessage {...translations.Language} />
          </div>
          <div className={classes.languages}>
            {publishData.language && (
              <Tag
                label={publishData.language}
                variation={TagVariation.gray}
                readOnly
              />
            )}
          </div>
          <div className={classes.title}>
            <FormattedMessage {...translations.AssociatedTags} />
          </div>
          <FormattedMessage {...translations.AddTag}>
            {(message) => (
              <SelectTagsByClusterId
                className={classes.tagSelectContainer}
                clusterIds={clientConfig[CONFIG_CLUSTER.RETAILER]}
                selectedItems={formatOptionsForSelect(
                  publishData[TAG_TYPE.RETAILER],
                )}
                placeholder={`${message}...`}
                onSelectionChanged={(tags) =>
                  UpdateTags(publishData, tags, TAG_TYPE.RETAILER)
                }
              />
            )}
          </FormattedMessage>
          <div className={classes.title}>
            <FormattedMessage {...translations.BrandLabel} />
          </div>
          <FormattedMessage {...translations.AddBrandPlaceholder}>
            {(message) => (
              <SelectTagsByClusterId
                className={classes.tagSelectContainer}
                classNameError={classNameError}
                clusterIds={clientConfig[CONFIG_CLUSTER.BRAND]}
                selectedItems={formatOptionsForSelect(
                  publishData[TAG_TYPE.BRAND],
                )}
                placeholder={`${message}...`}
                onSelectionChanged={updateTagsBrand()}
              />
            )}
          </FormattedMessage>
          <PublicationButtons
            btnClickHandler={handleClickSave}
            switchHandler={UpdateWorkflow}
            isOn={publishData.workflow === DOC_WORKFLOW_ENUM.PUBLISHED}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProp = (state) => ({
  publishData: state.publishBattle.publishData,
  intervals: state.publishBattle.intervals,
  isFetching: state.common.fetchIndicator,
  isUpdating: state.common.updateIndicator,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchPublishData: (id, lang) => {
    dispatch(GetBattleInfo(id, lang));
  },
  UpdatePublishData: (type, data) => {
    dispatch(UpdatePublishData({ [type]: data }));
  },
  resetStore: () => dispatch(ResetStore()),
  updateBattleInfo: (type, data) => {
    dispatch(
      UpdateBattleInfo(type, data, () => {
        dispatch(UpdatePublishData({ [type]: data }));
      }),
    );
  },
  updateTimePerSecond: (publishData, second) => {
    const newPage = getPageWithNewTimer(publishData.pages, second.value);
    dispatch(
      UpdateBattlePage(newPage, () => {
        dispatch(
          UpdatePublishData({
            timePerQuestion: second,
            pages: {
              ...publishData.pages,
              questions: publishData.pages.questions.map((question) => ({
                ...question,
                timer: second.value,
              })),
            },
          }),
        );
      }),
    );
  },
  updateScorePerSecond: (publishData, score) => {
    const newPage = getPageWithNewScore(publishData.pages, score);
    dispatch(
      UpdateBattlePage(newPage, () => {
        dispatch(
          UpdatePublishData({
            scorePerQuestions: score,
            pages: {
              ...publishData.pages,
              questions: publishData.pages.questions.map((question) => ({
                ...question,
                score,
              })),
            },
          }),
        );
      }),
    );
  },
  UpdateTags: (publishData, tagList, tagType) => {
    const saveFormatTags = formatOptionsForSave(tagList);
    const beforeTagIds = publishData[tagType].map((x) => x.Id);
    const afterTagIds =
      tagList && tagList.length ? tagList.map((x) => x.value) : [];
    const removeTagIds = beforeTagIds.filter(
      (beforeTagId) => !afterTagIds.includes(beforeTagId),
    );
    const newTagIds = afterTagIds.filter(
      (afterTagId) => !beforeTagIds.includes(afterTagId),
    );

    if (removeTagIds.length) {
      dispatch(
        RemoveTags(removeTagIds, () => {
          if (newTagIds.length) {
            dispatch(
              AddTags(newTagIds, () => {
                dispatch(UpdatePublishData({ [tagType]: saveFormatTags }));
              }),
            );
          } else {
            dispatch(UpdatePublishData({ [tagType]: saveFormatTags }));
          }
        }),
      );
    } else {
      if (newTagIds.length) {
        dispatch(
          AddTags(newTagIds, () => {
            dispatch(UpdatePublishData({ [tagType]: saveFormatTags }));
          }),
        );
      }
    }
  },
  UpdateWorkflow: () => dispatch(UpdateMasterBattleWorkflow()),
});

export default connect(mapStateToProp, mapDispatchToProps)(PublishBattle);
