import gql from "graphql-tag";

export const GET_MY_PROFILE_INFO = gql`
  query myProfile {
    me {
      userId
      profile {
        lastName
        firstName
        mail
        lang
        pic
      }
      tags {
        tagId
        title
        clusterId
      }
    }
  }
`;
