import gql from "graphql-tag";

export const UPDATE_LESSON_INFO = gql`
  mutation updateLessonInfo(
    $lessonId: ID
    $lang: LangEnum
    $type: ContentTypeEnum!
    $data: String!
  ) {
    admin {
      lesson(lessonId: $lessonId, lang: $lang) {
        set(type: $type, data: $data)
      }
    }
  }
`;
