import { useEffect } from "react";
import { useDispatch, batch, useSelector } from "react-redux";
import {
  GetInsightData,
  SetCurrentInsightId,
  SetCurrentLanguage,
  UpdatePublishInsight,
} from "../../../store/insight/actions";

const selectors = {
  isScreenReady: (state) => state.insights.publishInsight.isScreenReady,
  isLoaderActive: (state) => state.navigation.isLoaderActive,
};

export const useCurrentInsightGeneralInfo = (props) => {
  const { insightId, lang } = props.match.params;
  const dispatch = useDispatch();
  const isScreenReady = useSelector(selectors.isScreenReady);
  const isLoaderActive = useSelector(selectors.isLoaderActive);

  useEffect(() => {
    batch(() => {
      dispatch(
        GetInsightData(insightId, lang, () =>
          dispatch(UpdatePublishInsight({ isScreenReady: true })),
        ),
      );
      dispatch(SetCurrentInsightId(insightId));
      dispatch(SetCurrentLanguage(lang));
    });

    return () => dispatch(UpdatePublishInsight({ isScreenReady: false }));
  }, [insightId, lang, dispatch]);

  return {
    isScreenReady,
    isLoaderActive,
    id: insightId,
    lang,
  };
};
