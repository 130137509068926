import PropTypes from "prop-types";
import uuidV4 from "uuid/dist/v4";

export const CreateBattleQuestionChoice = (text = "") => ({
  id: uuidV4(),
  text,
  isCorrect: false,
});

export const BattleQuestionChoicePropTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isCorrect: PropTypes.bool,
};
