import gql from "graphql-tag";

export const GET_TAGS_BY_PARENT_META_CLUSTER_IDS = gql`
  query getTagsByParentMetaClusterIds(
    $clusterMetaTagIds: [ID!]
    $autocomplete: String
    $skip: Int
    $limit: Int
  ) {
    tags(
      clusterMetaTagIds: $clusterMetaTagIds
      autocompleteFull: $autocomplete
      skip: $skip
      limit: $limit
    ) {
      tagId
      title
      clusterName
      clusterId
      clusterMetaTagId
      parentTag {
        tagId
      }
      pic
    }
  }
`;
