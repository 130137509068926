import { FILE_TYPES } from "../../../configs/constants";

export const SliderStyle = ({
  HorizontalSlider,
  FontFamily,
  TextSizes,
  Colors,
}) => ({
  sliderContainer: {
    minHeight: 737,
    maxHeight: 737,
    height: 737,
    minWidth: 414,
    maxWidth: 414,
    width: 414,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    border: (props) =>
      props.isSelected
        ? `solid 1px ${HorizontalSlider.selectedSlideBorderColor}`
        : "none",
  },
  backgroundSlider: {
    backgroundImage: (props) =>
      props.image && props.image !== ""
        ? `${HorizontalSlider.imageGradientBG}, url("${props.image}")`
        : `${HorizontalSlider.noImageGradientBG}, url("${props.defaultSliderImage}")`,
    filter: (props) =>
      props.fileType === FILE_TYPES.VIDEO ? "blur(10px)" : "none",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
    position: "absolute",
    zIndex: 0,
  },
  uploadControlsBox: {
    position: "absolute",
    fontFamily: FontFamily,
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: 0.07,
    color: HorizontalSlider.uploadControlsBoxColor,
    display: ({ isSelected }) => (isSelected ? "flex" : "none"),
    justifyContent: "flex-start",
    alignItems: "baseline",
    top: 0,
    left: 0,
    width: "100%",
    height: 47,
    paddingTop: 18,
    backgroundImage: HorizontalSlider.uploadControlsBoxBackgroundImage,
    zIndex: 1,
  },
  leftButtonsBox: {
    display: "flex",
    flex: 1,
    paddingLeft: 15,
  },
  uploadBtnWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
    },
  },
  uploadInput: {
    fontSize: "100px",
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  btnUpload: {
    border: "none",
    fontFamily: FontFamily,
    fontSize: 14,
    background: "transparent",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px",
    color: HorizontalSlider.uploadButtonColor,
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
    },
    "& > span": {
      paddingRight: 5,
      "&:hover::before": {
        color: Colors.lightBlue,
        cursor: "pointer",
      },
    },
    "&:hover *": {
      color: Colors.lightBlue,
      cursor: "pointer",
      "&::before": {
        color: Colors.lightBlue,
        cursor: "pointer",
      },
    },
  },
  iconAction: {
    cursor: "pointer",
    "&:hover::before": {
      color: `${Colors.lightBlue}`,
      fontSize: 15,
      cursor: "pointer",
    },
    "&::before": {
      color: `${Colors.white}`,
      fontSize: 15,
      cursor: "pointer",
    },
  },
  addBoxRight: {
    position: "absolute",
    top: "calc(100%/2+ 5)",
    right: -25,
    zIndex: 1,
    cursor: "pointer",
  },
  addBoxLeft: {
    position: "absolute",
    top: "calc(100%/2+ 5)",
    left: -24,
    zIndex: 1,
    cursor: "pointer",
  },
  textEditing: {
    position: "absolute",
    bottom: 60,
    height: 220,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginLeft: 30,
    marginRight: 30,
    justifyContent: "flex-end",
  },
  titleInput: {
    width: "calc(100% - 60px)",
    maxHeight: 90,
    outline: "none",
    color: HorizontalSlider.inputColor,
    transition: "box-shadow .2s linear",
    overflow: "hidden",
    textOverflow: "ellipsis",
    font: {
      family: FontFamily,
      size: TextSizes.ImageTitle,
      weight: "bold",
      style: "normal",
      stretch: "normal",
    },
    lineHeight: 1,
    letterSpacing: "normal",
    "&:placeholder": {
      font: {
        family: FontFamily,
        size: TextSizes.ImageTitle,
      },
      color: HorizontalSlider.inputPlaceholderColor,
    },
    "&:empty:not(:focus):before": {
      content: '"Title"',
      color: HorizontalSlider.inputPlaceholderColor,
    },
    "&:focus": {
      borderBottom: `solid 2px ${HorizontalSlider.focusedInputBorderBottomColor}`,
    },
  },
  descriptionInput: {
    width: "calc(100% - 60px)",
    minHeight: 50,
    maxHeight: 100,
    outline: "none",
    color: HorizontalSlider.inputColor,
    transition: "box-shadow .2s linear",
    overflow: "hidden",
    textOverflow: "ellipsis",
    font: {
      family: FontFamily,
      size: TextSizes.ImageDescription,
      weight: "normal",
      style: "normal",
      stretch: "normal",
    },
    lineHeight: 1.33,
    letterSpacing: "normal",
    "&:placeholder": {
      font: {
        family: FontFamily,
        size: TextSizes.ImageDescription,
      },
      color: HorizontalSlider.inputPlaceholderColor,
    },
    "&:empty:not(:focus):before": {
      content: '"Description"',
      color: HorizontalSlider.inputPlaceholderColor,
    },
    "&:focus": {
      borderBottom: `solid 1px ${HorizontalSlider.focusedInputBorderBottomColor}`,
    },
  },
});
