import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createUseStyles } from "react-jss";
import Style from "../createFile.style";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import { FormattedMessage, useIntl } from "react-intl";
import { translations } from "../createFile.translations";
import ContentEditable from "react-contenteditable";

import { Icon, Icons, Button, ButtonSize, Tag, Dropdown } from "genius-ui";
import ImageSelector from "components/common/ImageSelector";
import FilterCountries from "components/FilterCountries";
import SelectTagsByClusterId from "containers/Apollo/SelectTagsByClusterId";
import clientConfig, { CONFIG_CLUSTER } from "../../../configs/client";
import { useDispatch } from "react-redux";
import { useCreateFile } from "store/pdf/actions";
import { getPath } from "core/paths";
import UploadFile from "components/common/UploadFileDocument";
import {
  SetWarningMessage,
  SetSuccessMessage,
} from "store/notification/actions";
import SinglePublishDate from "components/common/SinglePublishDate";
import { cleanupHtml, decodeEntities } from "utils/string";
import { CROP_VARIATION } from "configs/constants";
import { PlaceHolderDegree } from "assets/icons";
import { useHistory } from "react-router-dom";
import FilterLanguages from "components/FilterLanguages";
import { useGetFileConfig } from "pages/library";
import Video from "components/common/Video";

const useStyle = createUseStyles(Style);

const PublishFile = ({
  title,
  setTitle,
  cover,
  setCover,
  url,
  setUrl,
  lang,
  publicationDate,
  setPublicationDate,
  brandTags,
  setBrandTags,
  setDescription,
  selectedMarket,
  edit,
  onUpdateFile,
  description,
  tagIds = [],
  fileType,
  setFileType,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const classes = useThemedStyle(useStyle, props);
  const descriptionRef = useRef();
  const { categoriesByCountryId } = useGetFileConfig();
  const categories = useMemo(() => {
    if (!categoriesByCountryId) return [];
    const categories = [
      ...(categoriesByCountryId?.[selectedMarket.value] ||
        categoriesByCountryId?.default),
    ];
    if (!fileType) return categories;
    return categories.map((category) => ({
      ...category,
      isDisabled: !category.fileTypes.includes(fileType),
      subCategories: category.subCategories?.map((subCategory) => ({
        ...subCategory,
        isDisabled: !subCategory.fileTypes.includes(fileType),
      })),
    }));
  }, [selectedMarket, categoriesByCountryId, fileType]);
  const { handleCreateFile } = useCreateFile();

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const category = categories?.find(({ id }) => tagIds.includes(String(id)));
    if (!category) return;
    setSelectedCategory(category);
    const subCategories = category.subCategories?.find(({ id }) =>
      tagIds.includes(String(id)),
    );
    if (!subCategories) return;
    setSelectedSubCategory(subCategories);
  }, [tagIds, categories]);

  const handleSetWarningMessage = useCallback(
    (message) => dispatch(SetWarningMessage(message)),
    [],
  );
  const handleSetSuccessMessage = useCallback(
    (message) => dispatch(SetSuccessMessage(message)),
    [],
  );

  const [heavyFile, setHeavyFile] = useState(false);

  const handleBrands = useCallback(
    (brands) => {
      if (!brands || brands?.length <= 1) {
        setBrandTags(brands);
      }
    },
    [brandTags, setBrandTags],
  );

  const handleClickSave = useCallback(() => {
    if (!title) {
      handleSetWarningMessage("Missing Title");
      return;
    }
    if (!selectedCategory) {
      handleSetWarningMessage("Missing Category");
      return;
    }
    if (selectedCategory.subCategories && !selectedSubCategory) {
      handleSetWarningMessage("Missing Sub category");
      return;
    }
    if (!brandTags.length) {
      handleSetWarningMessage("Missing Brand");
      return;
    }
    if (!url) {
      handleSetWarningMessage("No file uploaded");
      return;
    }
    const goToLibrary = () => history.push(getPath("library"));
    if (onUpdateFile) {
      const previousCategory = categories?.find(({ id }) =>
        tagIds.includes(String(id)),
      );
      const previousSubCategory = previousCategory?.subCategories?.find(
        ({ id }) => tagIds.includes(String(id)),
      );
      const newTagIds = [];
      const toDeleteTagIds = [];
      if (previousCategory?.id !== selectedCategory?.id) {
        if (selectedCategory?.id) newTagIds.push(selectedCategory.id);
        if (previousCategory?.id) toDeleteTagIds.push(previousCategory.id);
      }
      if (previousSubCategory?.id !== selectedSubCategory?.id) {
        if (selectedSubCategory?.id) newTagIds.push(selectedSubCategory.id);
        if (previousSubCategory?.id)
          toDeleteTagIds.push(previousSubCategory.id);
      }
      onUpdateFile({ newTagIds, toDeleteTagIds }, goToLibrary);
    } else {
      const data = { url, cover, description: descriptionRef.current.value };
      const tagIds = [
        ...brandTags.map((brand) => brand.value),
        selectedMarket.value,
        selectedCategory.id,
      ];
      if (selectedSubCategory) {
        tagIds.push(selectedSubCategory.id);
      }
      handleCreateFile(
        fileType,
        lang,
        title,
        tagIds,
        cover,
        JSON.stringify(data, null, 2),
        publicationDate,
        goToLibrary,
      );
    }
  }, [
    selectedMarket,
    title,
    url,
    brandTags,
    cover,
    handleSetWarningMessage,
    onUpdateFile,
    publicationDate,
    selectedCategory,
    selectedSubCategory,
    descriptionRef,
  ]);

  const availableFileTypes = useMemo(() => {
    if (!categories) return [];
    if (selectedSubCategory) {
      return selectedSubCategory.fileTypes;
    }
    if (selectedCategory && !selectedCategory.subCategories) {
      return selectedCategory.fileTypes;
    }
    if (selectedCategory) {
      return [...new Set(selectedCategory.fileTypes)];
    }
    return [...new Set(categories.flatMap(({ fileTypes }) => fileTypes))];
  }, [categories, selectedCategory, selectedSubCategory]);
  const handleClose = useCallback(() => {
    history.push(getPath("library"));
  }, []);

  const handleUploadSuccess = useCallback((url) => {
    setUrl(url);
    handleSetSuccessMessage("File upload success");
  }, []);

  const handleUploadFail = useCallback((message) => {
    handleSetWarningMessage(message);
  }, []);

  const handleTitleChange = useCallback(
    (value) => {
      setTitle(cleanupHtml(decodeEntities(value)));
    },
    [setTitle],
  );

  const handleCategoryChange = useCallback(
    (category) => {
      setSelectedCategory(category);
      setSelectedSubCategory(
        (category.subCategories?.length || 0) === 1
          ? category.subCategories[0]
          : null,
      );
    },
    [setSelectedCategory, setSelectedSubCategory],
  );

  return (
    <div className={classes.rootContainer}>
      <div className={classes.center}>
        <div className={classes.left}>
          <ContentEditable
            html={title}
            onChange={({ target }) => handleTitleChange(target.value)}
            className={classes.textInputTitle}
            placeholder={formatMessage(translations.TitlePlaceholder)}
            tagName="article"
          />
          <br />
          <div className={classes.detailsContainer}>
            <div className={classes.leftPart}>
              <ImageSelector
                id="image"
                uniqueId="pdfCover"
                buttonLabel="Change Cover"
                width="300px"
                height="300px"
                backgroundSize={
                  cover !== PlaceHolderDegree ? "contain" : undefined
                }
                sourceImage={cover}
                cropSize={CROP_VARIATION.PDF}
                onImageChange={setCover}
              />
            </div>
            <div className={classes.rightPart}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "50%" }}>
                  <div className={classes.sectionTitle}>
                    <FormattedMessage {...translations.CountrySelectorLabel} />
                  </div>
                  <div className={classes.tagList}>
                    <FilterCountries
                      countries={[
                        {
                          Id: selectedMarket?.value,
                          Value: selectedMarket?.label,
                        },
                      ]}
                    >
                      {({ filteredCountries }) =>
                        filteredCountries.map((country) => (
                          <Tag
                            key={country.Value}
                            label={country.Value}
                            readOnly
                          />
                        ))
                      }
                    </FilterCountries>
                  </div>
                </div>
                <div>
                  <div className={classes.sectionTitle}>
                    <FormattedMessage {...translations.LangSelectorLabel} />
                  </div>
                  <div className={classes.tagList}>
                    <FilterLanguages>
                      {(filteredLanguages) => {
                        const languages = filteredLanguages.filter(
                          (x) => x.Code === lang,
                        );

                        return languages.map((x) => (
                          <Tag key={x.Code} label={x.Name} readOnly />
                        ));
                      }}
                    </FilterLanguages>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", padding: "0 0 20px 0" }}>
                <div style={{ width: "50%" }}>
                  <div className={classes.sectionTitle}>
                    <FormattedMessage {...translations.CategoryLabel} />
                  </div>
                  <div style={{ width: 180, marginRight: 10 }}>
                    <Dropdown
                      selectedOption={selectedCategory}
                      onChange={handleCategoryChange}
                      options={categories}
                      label="Categories"
                    />
                  </div>
                </div>
                <div>
                  <div className={classes.sectionTitle}>
                    <FormattedMessage {...translations.SubCategoryLabel} />
                  </div>
                  <div style={{ width: 180 }}>
                    <Dropdown
                      selectedOption={selectedSubCategory}
                      onChange={setSelectedSubCategory}
                      options={selectedCategory?.subCategories}
                      label="Subcategories"
                      disabled={
                        (selectedCategory?.subCategories?.length || 0) < 2
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "50%" }}>
                  <div className={classes.brand}>
                    <div className={classes.brandTagsContainer}>
                      <div className={classes.sectionTitle}>
                        <FormattedMessage {...translations.BrandLabel} />
                      </div>
                      <SelectTagsByClusterId
                        className={classes.tagSelectContainer}
                        id="brand"
                        clusterIds={clientConfig.BRAND_TAGS_CLUSTER_IDS}
                        tagType={CONFIG_CLUSTER.BRAND}
                        placeholder={
                          brandTags?.length
                            ? ""
                            : formatMessage(translations.AddBrandPlaceholder)
                        }
                        onSelectionChanged={handleBrands}
                        selectedItems={brandTags}
                        mustHaveAtLeastOneChoice
                      />
                    </div>
                  </div>
                </div>
                <div style={{ width: 250 }}>
                  <div className={classes.brand}>
                    <div className={classes.brandTagsContainer}>
                      <div className={classes.sectionTitle}>
                        <FormattedMessage {...translations.DescriptionLabel} />
                      </div>
                      <input
                        type="text"
                        ref={descriptionRef}
                        defaultValue={description}
                        className={classes.descriptionInput}
                        onChange={(event) =>
                          setDescription && setDescription(event.target.value)
                        }
                        placeholder={formatMessage(
                          translations.AddDescriptionPlaceholder,
                        )}
                        contentEditable
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {heavyFile && (
                  <div className={classes.heavyFile}>
                    <FormattedMessage {...translations.FileHeavy} />
                  </div>
                )}
                {!url && (
                  <UploadFile
                    onUploadSuccess={handleUploadSuccess}
                    onUploadFail={handleUploadFail}
                    onHeavyFile={setHeavyFile}
                    handleCoverChange={setCover}
                    setFileType={setFileType}
                    fileType={fileType}
                    fileTypes={availableFileTypes}
                  />
                )}
                {!!url && fileType === "pdf" && (
                  <div className={classes.pdfUploaded}>
                    <iframe src={url} className={classes.pdfIFrame} />
                  </div>
                )}
                {!!url && fileType === "video" && (
                  <div className={classes.pdfUploaded}>
                    <Video
                      width="100%"
                      height="350px"
                      sourceVideo={url}
                      sourcePosterImage={undefined}
                      onVideoChange={() => {}}
                      onClick={() => {}}
                      showErrorMessageHandler={() => {}}
                      isSelected
                      onImageChange={() => {}}
                      isReadOnly
                    />
                  </div>
                )}
                {!!url && fileType === "image" && (
                  <div className={classes.pdfUploaded}>
                    <image src={url} className={classes.pdfIFrame} />
                  </div>
                )}
                {!!url && fileType === "audio" && (
                  <div
                    style={{
                      marginTop: 20,
                      height: 30,
                      border: "1px solid #a0a0a0",
                      borderRadius: 15,
                    }}
                  >
                    <video
                      src={url}
                      playsInline
                      controls
                      width="100%"
                      height="100%"
                    />
                  </div>
                )}
              </div>
              <SinglePublishDate
                startDateLabel={
                  <FormattedMessage {...translations.StartPostingDateLabel} />
                }
                startPostingDate={publicationDate}
                updateStartPublicationDate={setPublicationDate}
              />
              <div className={classes.postingDate}>
                <div className={classes.postingDateButton}>
                  <Button
                    variation="primary"
                    size={ButtonSize.big}
                    handleClick={handleClickSave}
                  >
                    <FormattedMessage {...translations.ScheduleButtonText} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Icon
          handleClick={handleClose}
          iconName={Icons.slimCloseIcon}
          style={classes.closeIcon}
        />
      </div>
    </div>
  );
};

export default PublishFile;
