import { defineMessages } from "react-intl";

export const translations = defineMessages({
  AddSlideLeftAltText: {
    id: "Slider.AddSlideLeftAltText",
    defaultMessage: "Add Slide Left",
  },
  AddSlideRightAltText: {
    id: "Slider.AddSlideRightAltText",
    defaultMessage: "Add Slide Right",
  },
  UploadImageVideoText: {
    id: "Slider.UploadImageVideoText",
    defaultMessage: "Upload Image/Video",
  },
  UploadImageText: {
    id: "Slider.UploadImageText",
    defaultMessage: "Upload Image",
  },
  AddThumbnailText: {
    id: "Slider.AddThumbnailText",
    defaultMessage: "Add thumbnail",
  },
  DeleteText: {
    id: "Slider.DeleteText",
    defaultMessage: "Delete",
  },
});
