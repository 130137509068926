import React from "react";
import { VideoStyle } from "./Video.style";
import PropTypes from "prop-types";
import { Player, BigPlayButton } from "video-react";
import "../../../../node_modules/video-react/dist/video-react.css";
import { Icon, Icons, ProgressBar } from "genius-ui";
import MediaService from "../../../core/mediaService";
import { VIDEO_VALID_FORMATS } from "../../../configs/constants";
import { VIDEO_MAX_SIZE_IN_MB } from "../../../configs/client";
import { FILE_SIZES, formatBytes } from "../../../utils/math";
import { FormattedMessage } from "react-intl";
import UploadMedia from "../UploadMedia";
import { translations } from "./Video.translations";
import withThemedStyle from "../../hoc/withThemedStyle";

const mediaService = new MediaService();

const UploadProgress = (props) => {
  let { classes } = props;
  return (
    <div className={classes.progressContainer}>
      <span className={classes.titleProgress}>{props.title}</span>
      <ProgressBar percentage={props.percentage} />
      <span className={classes.descriptionProgress}>{props.description}</span>
    </div>
  );
};

class Video extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentUploadProgress: 0,
      currentConversionProgress: 0,
      isUploading: false,
      isConverting: false,
      posterCover: "",
      videoSource: "",
    };

    this.updateConversionProgress = this.updateConversionProgress.bind(this);
    this.updateUploadProgress = this.updateUploadProgress.bind(this);
    this.handleVideoUpload = this.handleVideoUpload.bind(this);
    this.handleImageUploadCompleted =
      this.handleImageUploadCompleted.bind(this);
  }

  updateUploadProgress = (progress) => {
    this.setState({
      currentUploadProgress: progress !== 100 ? progress : 0,
      isUploading: progress !== 100,
    });
    if (progress === 100) {
      this.setState({
        currentConversionProgress: 0,
        isConverting: true,
      });
    }
  };

  updateConversionProgress = (
    videoUrl,
    onVideoChangeCallback,
    id,
    posterCover,
  ) => {
    mediaService.checkVideoStatus(
      videoUrl,
      (conversionStatus) => {
        let isConverting = !(
          conversionStatus.status === "ACTIVE" ||
          conversionStatus.status === "REJECT" ||
          conversionStatus.progress === 100
        );

        this.setState({
          currentConversionProgress: conversionStatus.progress,
          isConverting: isConverting,
        });

        if (isConverting) {
          setTimeout(
            this.updateConversionProgress(
              videoUrl,
              onVideoChangeCallback,
              id,
              posterCover,
            ),
            2000,
          );
        } else {
          this.setState({
            videoSource: conversionStatus.url,
            currentConversionProgress: conversionStatus.progress,
          });
          onVideoChangeCallback(conversionStatus.url, posterCover, id);
        }
      },
      (error) => {
        window.alert(error);
      },
    );
  };

  handleVideoUpload = (videoFile) => {
    this.setState({ isUploading: true });
    mediaService.uploadVideo(
      videoFile,
      (response) => {
        this.setState({ isConverting: true });
        this.updateConversionProgress(
          response.url,
          this.props.onVideoChange,
          this.props.id,
          this.state.posterCover,
        );
      },
      (error) => {
        window.alert(error);
      },
      this.updateUploadProgress,
    );
  };

  validateNewVideo = ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    let isValid = true;
    if (!validity.valid) {
      this.props.showErrorMessageHandler(
        <FormattedMessage {...translations.InvalidFileMessage} />,
      );
      isValid = false;
    }
    const { size } = file;

    if (formatBytes(size, FILE_SIZES.MB) > VIDEO_MAX_SIZE_IN_MB) {
      this.props.showErrorMessageHandler(
        <FormattedMessage
          {...translations.InvalidFileSizeMessage}
          values={{
            fileSizeMb: FILE_SIZES.MB,
            maxFileSizeMb: VIDEO_MAX_SIZE_IN_MB.MB,
          }}
        />,
      );
      isValid = false;
    }
    if (isValid) {
      this.handleVideoUpload(file);
    } else {
      this.props.onVideoChange(undefined, undefined, this.props.id, file);
    }
  };

  handleImageUploadCompleted = (imageFile) => {
    this.setState({
      posterCover: imageFile,
    });
    this.props.onVideoChange(this.state.videoSource, imageFile, this.props.id);
  };

  componentDidMount() {
    this.setState({
      posterCover: this.props.sourcePosterImage,
      videoSource: this.props.sourceVideo,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.sourceVideo !== prevState.sourceVideo) {
      this.player.load();
    }
  }

  render() {
    let { uniqueId, classes, uploadButtonLabel, enableMediaSelectionPopup } =
      this.props;

    return (
      <div className={classes.videoContainer} onClick={this.props.onClick}>
        {!this.props.isReadOnly ? (
          <div className={classes.uploadControlsBox}>
            <div className={classes.uploadBtnWrapper}>
              <button className={classes.btnUpload}>
                <Icon iconName={Icons.upload} style={classes.iconAction} />
                <span>
                  {uploadButtonLabel || (
                    <FormattedMessage {...translations.UploadVideoText} />
                  )}
                </span>
              </button>
              <input
                className={classes.uploadInput}
                type="file"
                accept={VIDEO_VALID_FORMATS.join()}
                required
                onChange={(props) => this.validateNewVideo(props)}
              />
            </div>
            <UploadMedia
              id={`add-thumbnail-${uniqueId}`}
              className={classes.uploadBtnWrapper}
              onUploadSuccess={this.handleImageUploadCompleted}
              isVideoUploadEnabled={false}
              enableMediaSelectionPopup={enableMediaSelectionPopup}
            >
              <button className={classes.btnUpload}>
                <Icon iconName={Icons.thumbnail} style={classes.iconAction} />
                <span>
                  <FormattedMessage {...translations.AddThumbnailText} />
                </span>
              </button>
            </UploadMedia>
          </div>
        ) : (
          ""
        )}
        {!this.state.isUploading && !this.state.isConverting && (
          <Player
            ref={(player) => {
              this.player = player;
            }}
            playsInline
            poster={this.state.posterCover}
            fluid={false}
            width="100%"
            height="100%"
            onClick={this.props.onClick}
          >
            <source src={this.state.videoSource} />
            <BigPlayButton position="center" />
          </Player>
        )}

        {this.state.isUploading && (
          <UploadProgress
            percentage={this.state.currentUploadProgress}
            title={<FormattedMessage {...translations.TitleVideoUpload} />}
            description={
              <FormattedMessage {...translations.DescriptionVideoImport} />
            }
            {...this.props}
          />
        )}

        {this.state.isConverting && (
          <UploadProgress
            percentage={this.state.currentConversionProgress}
            title={<FormattedMessage {...translations.TitleVideoConversion} />}
            description={
              <FormattedMessage {...translations.DescriptionVideoCompress} />
            }
            {...this.props}
          />
        )}
      </div>
    );
  }
}

Video.propTypes = {
  /** the  unique id of the video selector control which is consistent across page loads */
  uniqueId: PropTypes.string,
  /** the  unique id of the image selector control */
  id: PropTypes.string,
  /** The current selected video */
  sourceVideo: PropTypes.string,
  /** The current poster image */
  sourcePosterImage: PropTypes.string,
  /** On upload completed video change */
  onVideoChange: PropTypes.func,
  /** On upload completed image change */
  onImageChange: PropTypes.func,
  /** on key press event */
  onClick: PropTypes.func,
  /** isSelected */
  isSelected: PropTypes.bool,
  /** Show error message handler  */
  showErrorMessageHandler: PropTypes.func,
  /** isReadOnly */
  isReadOnly: PropTypes.bool,
  /** Label for the change video selection button */
  uploadButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default withThemedStyle(VideoStyle)(Video);
