import { defineMessages } from "react-intl";

export const translations = defineMessages({
  MessageLabel: {
    id: "SendNotificationModal.MessageLabel",
    defaultMessage: "Notification message",
  },
  SendButtonLabel: {
    id: "SendNotificationModal.SendButtonLabel",
    defaultMessage: "Send",
  },
  SendNotificationMessageSuccessMessage: {
    id: "SendNotificationModal.SendNotificationMessageSuccessMessage",
    defaultMessage: "Notification has been sent successfully",
  },
  SelectTagsPlaceholder: {
    id: "SendNotificationModal.SelectTagsPlaceholder",
    defaultMessage: "Select tags",
  },
  TagsLabel: {
    id: "SendNotificationModal.TagsLabel",
    defaultMessage: "Select tags",
  },
  CancelButtonLabel: {
    id: "SendNotificationModal.CancelButtonLabel",
    defaultMessage: "Cancel",
  },
  RecipientUsersLabel: {
    id: "SendNotificationModal.RecipientUsersLabel",
    defaultMessage: "Recipient users",
  },
  AddNewProfileButtonLabel: {
    id: "SendNotificationModal.AddNewProfileButtonLabel",
    defaultMessage: "Add profile",
  },
  ProfileTitle: {
    id: "SendNotificationModal.ProfileTitle",
    defaultMessage: "Profile {index}",
  },
  ServicesSelectorLabel: {
    id: "SendNotificationModal.ServicesSelectorLabel",
    defaultMessage: "Services",
  },
  RolesSelectorLabel: {
    id: "SendNotificationModal.RolesSelectorLabel",
    defaultMessage: "Roles",
  },
  ZonesSelectorLabel: {
    id: "SendNotificationModal.ZonesSelectorLabel",
    defaultMessage: "Zones",
  },
  CountriesSelectorLabel: {
    id: "SendNotificationModal.CountriesSelectorLabel",
    defaultMessage: "Countries",
  },
  CitiesSelectorLabel: {
    id: "SendNotificationModal.CitiesSelectorLabel",
    defaultMessage: "Cities",
  },
  LocationsSelectorLabel: {
    id: "SendNotificationModal.LocationsSelectorLabel",
    defaultMessage: "Locations",
  },
  DepartmentsSelectorLabel: {
    id: "SendNotificationModal.DepartmentsSelectorLabel",
    defaultMessage: "Departments",
  },
  SelectAllLabel: {
    id: "SendNotificationModal.SelectAllLabel",
    defaultMessage: "Select all",
  },
  Description: {
    id: "SendNotificationModal.Description",
    defaultMessage:
      "Please confirm you would like to send a Push Notification to all Wellbeing App users.",
  },
});
