import gql from "graphql-tag";

export const LESSON_MOVE_PAGE_ORDER_UP = gql`
  mutation lessonPageOrderUp($lessonId: ID!, $lang: LangEnum, $pageId: ID) {
    admin {
      lesson(lessonId: $lessonId, lang: $lang) {
        pages(pageId: $pageId) {
          upPosition
        }
      }
    }
  }
`;
