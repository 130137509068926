import * as constants from "../../constants/store/publishPoll";
import { RESET_STORE } from "../../constants/store/auth";

const initialState = {
  publishData: {
    pollId: null,
    lang: null,
    title: "",
    summary: "",
    picCover: null,
    language: null,
    startPostingDate: null,
    endPostingDate: null,
    countries: [],
    retailers: [],
    brands: [],
    tags: [],
    timePerQuestion: null,
    pages: null,
    pageId: null,
    workflow: null,
  },
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.UPDATE_PUBLISH_DATA:
      return {
        ...state,
        publishData: {
          ...state.publishData,
          ...data,
        },
      };
    case constants.RESET_STORE:
      return { ...initialState };
    case RESET_STORE:
      return { ...initialState };
    default:
      return state;
  }
}
