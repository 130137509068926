import React from "react";
import {
  Button,
  ButtonSize,
  ButtonVariation,
  Icon,
  Icons,
  Tag,
  OrderArrow,
} from "genius-ui";
import SettingsMenu from "../../../components/common/SettingsMenu";
import { DOC_WORKFLOW_ENUM, DEGREE_PARTS } from "../../../configs/constants";
import BulletTextInput from "../../../components/common/BulletTextInput";
import { LANGUAGES } from "../../../configs/referential";
import { FormattedMessage, injectIntl } from "react-intl";
import style from "./ProgramInfo.style";
import { createUseStyles } from "react-jss";
import uuidV4 from "uuid/dist/v4";
import { translations } from "./ProgramInfo.translations";
import FilterCountries from "../../../components/FilterCountries";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import { isShiseido } from "../../../configs/client";

const useStyle = createUseStyles(style);

/**
 * Lesson's destination language is available if exist the current program
 * on the destination language but current lesson don't
 * */
const setupLessonDuplicationModal = (props, currentItem) => () => {
  props.setActiveMenuLessonId(undefined);

  const programLanguages = props.program.availableProgramLanguages;
  const lessonLanguages = currentItem.availableLanguages;
  const availableLanguages =
    programLanguages && lessonLanguages
      ? programLanguages.filter((lang) => !lessonLanguages.includes(lang))
      : programLanguages || [];

  props.setDuplicationData({
    availableLanguages,
    itemId: currentItem.lessonId,
    sourceLang: props.language,
    type: DEGREE_PARTS.LESSON,
  });
  props.showModal();
};

/**
 * Program's destination language is available if exist the current degree
 * on the destination language but current program don't
 * */
const setupProgramDuplicationModal = (props, currentItem) => () => {
  props.setActiveMenuProgramId(undefined);
  const availableLanguages = LANGUAGES.filter(
    (lang) =>
      !currentItem.availableProgramLanguages.includes(lang.Code) &&
      currentItem.availableDegreeLanguages.includes(lang.Code),
  ).map((lang) => lang.Code);
  props.setDuplicationData({
    availableLanguages,
    itemId: currentItem.programId,
    sourceLang: props.language,
    type: DEGREE_PARTS.PROGRAM,
  });
  props.showModal();
};

const showCloneModal = (props, currentItem) => () => {
  props.setActiveMenuLessonId(undefined);
  props.setActiveMenuProgramId(undefined);
  props.setupCloneLessonData(currentItem);
  props.setShowCloneModal();
};

const ProgramInfo = (props) => {
  const { program, intl } = props;
  const classes = useThemedStyle(useStyle, props);
  const currentTitle =
    props.program && props.program.title ? props.program.title : "";
  const currentSummary =
    props.program && props.program.summary ? props.program.summary : "";
  const expandCollapseIconName = props.program.expanded
    ? Icons.arrowOrderUp
    : Icons.arrowOrderDown;
  const setAndShowNotificationModal = (lesson) => () => {
    props.setNotificationData({
      docId: lesson.lessonId,
      lang: lesson.lang,
      docType: DEGREE_PARTS.LESSON,
      selectableTags: lesson.marketTags,
    });
    props.setShowNotificationModal(true);
  };

  return (
    <div className={classes.center}>
      <div className={classes.left}>
        <div className={classes.programBox}>
          <div className={classes.programTitleWrap}>
            <FormattedMessage {...translations.ProgramNamePlaceholderText}>
              {(message) => (
                <input
                  type="text"
                  value={currentTitle}
                  className={classes.textInputEditTitle}
                  onChange={(e) => {
                    props.onTitleChange(e.target.value);
                  }}
                  onBlur={props.handleProgramInfoSave}
                  placeholder={message[0]}
                />
              )}
            </FormattedMessage>
            <div className={classes.programActionContainer}>
              {props.orderId === 0 ? (
                <Button
                  variation={ButtonVariation.secondary}
                  size={ButtonSize.small}
                  handleClick={props.handleCreateProgram}
                >
                  <FormattedMessage {...translations.AddProgramLabel} />
                </Button>
              ) : (
                ""
              )}
              <div className={classes.iconContainer}>
                <Icon
                  iconName={Icons.roundMore}
                  style={classes.settingsIcon}
                  handleClick={() =>
                    props.setActiveMenuProgramId(program.programId)
                  }
                />
                <SettingsMenu
                  handleFocusLeave={() =>
                    props.setActiveMenuProgramId(undefined)
                  }
                  isVisible={
                    props.currentEditingProgramId &&
                    props.currentEditingProgramId === program.programId
                  }
                  className={classes.programSettingsMenu}
                  menuItems={[
                    {
                      label: intl.formatMessage({
                        ...translations.TranslateOption,
                      }),
                      icon: Icons.translate,
                      onItemClick: setupProgramDuplicationModal(props, program),
                      disabled: !(LANGUAGES.length > 1),
                    },
                    {
                      label: intl.formatMessage({
                        ...translations.CloneOption,
                      }),
                      icon: Icons.duplicate,
                      onItemClick: showCloneModal(props, {
                        programId: program.programId,
                        type: DEGREE_PARTS.PROGRAM,
                      }),
                    },
                    {
                      label: intl.formatMessage({
                        ...translations.DeleteOption,
                      }),
                      icon: Icons.delete,
                      onItemClick: () => {
                        props.setActiveMenuProgramId(undefined);
                        props.setDeleteDocumentData({
                          type: DEGREE_PARTS.PROGRAM,
                          id: program.programId,
                        });
                        props.showDeleteModal();
                      },
                    },
                  ]}
                />
                <Icon
                  iconName={expandCollapseIconName}
                  style={classes.expandCollapseIcon}
                  handleClick={() => {
                    props.setActiveMenuProgramId(undefined);
                    props.expandCollapseProgram();
                  }}
                />
              </div>
            </div>
          </div>
          <FormattedMessage {...translations.ProgramDescriptionPlaceholderText}>
            {(message) => (
              <input
                type="text"
                value={currentSummary}
                className={classes.textInputEditSummary}
                onChange={(e) => props.onSummaryChange(e.target.value)}
                placeholder={message[0]}
                onBlur={props.handleProgramInfoSave}
              />
            )}
          </FormattedMessage>
          {!!props.program.expanded && (
            <div className={classes.lessonContainer}>
              {props.program.lessons.map((x, index) => {
                const lessonsNumber = props.program.lessons.length;
                let lessonMenuItems = [
                  {
                    label: <FormattedMessage {...translations.PublishOption} />,
                    icon: Icons.settings,
                    onItemClick: () => {
                      props.setActiveMenuLessonId(undefined);
                      props.navigateToLessonPublishPage(x.lessonId);
                    },
                  },
                  {
                    label: intl.formatMessage({
                      ...translations.TranslateOption,
                    }),
                    icon: Icons.translate,
                    onItemClick: setupLessonDuplicationModal(props, x),
                    disabled: !(LANGUAGES.length > 1),
                  },
                  {
                    label: intl.formatMessage({ ...translations.CloneOption }),
                    icon: Icons.duplicate,
                    onItemClick: showCloneModal(props, {
                      ...x,
                      programId: program.programId,
                      type: DEGREE_PARTS.LESSON,
                    }),
                  },
                  {
                    label: intl.formatMessage({ ...translations.DeleteOption }),
                    icon: Icons.delete,
                    onItemClick: () => {
                      props.setActiveMenuLessonId(undefined);
                      props.setDeleteDocumentData({
                        type: DEGREE_PARTS.LESSON,
                        id: x.lessonId,
                        program,
                      });
                      props.showDeleteModal();
                    },
                  },
                  {
                    label: intl.formatMessage({
                      ...translations.MoveToProgramOption,
                    }),
                    icon: Icons.reply,
                    onItemClick: () =>
                      props.showLessonRelocationModal(
                        x.lessonId,
                        program.programId,
                      ),
                    disabled: props.programCnt < 2,
                  },
                ];

                if (isShiseido && props.isMasterContent) {
                  lessonMenuItems = [
                    ...lessonMenuItems,
                    {
                      label: intl.formatMessage({
                        ...translations.AddToMarketOption,
                      }),
                      icon: Icons.duplicate,
                      onItemClick: () => {
                        props.navigateToAddToMarketPage(
                          x.lessonId,
                          props.programId,
                          props.degreeId,
                        );
                      },
                      disabled: x.workflow !== DOC_WORKFLOW_ENUM.PUBLISHED,
                    },
                    {
                      label: (
                        <FormattedMessage
                          {...translations[
                            x.workflow === DOC_WORKFLOW_ENUM.PUBLISHED
                              ? "SetAsDraftOption"
                              : "SetAsReadyOption"
                          ]}
                        />
                      ),
                      icon: Icons.settings,
                      onItemClick:
                        x.workflow === DOC_WORKFLOW_ENUM.PUBLISHED
                          ? props.setAsDraftEventHandler(
                              props.programId,
                              x.lessonId,
                            )
                          : props.setAsReadyEventHandler(
                              props.programId,
                              x.lessonId,
                            ),
                    },
                  ];
                } else if (isShiseido) {
                  lessonMenuItems = [
                    ...lessonMenuItems,
                    /* {
                      label: <FormattedMessage {...translations.EditBadgesOption} />,
                      icon: Icons.reward,
                      onItemClick: () => goToBadgesScreen(x.lessonId)
                    }, */
                    {
                      label: (
                        <FormattedMessage
                          {...translations.SendNotificationOption}
                        />
                      ),
                      icon: Icons.notification,
                      onItemClick: setAndShowNotificationModal(x),
                    },
                  ];
                }

                const { mail, firstName, lastName } = x.author.profile;

                return (
                  <div key={uuidV4()} className={classes.lessonItemContainer}>
                    <div key={x.lessonId} className={classes.lessonListItem}>
                      <div className={classes.inputStyle}>
                        <span className={classes.inputIcon}>
                          <Icon iconName={Icons.fullCircle} />
                        </span>
                        <div>
                          <FormattedMessage
                            {...translations.AddLessonPlaceholderText}
                          >
                            {(message) => (
                              <input
                                type="text"
                                className={classes.inputWithIcon}
                                placeholder={message[0]}
                                readOnly
                                value={x.title}
                              />
                            )}
                          </FormattedMessage>
                          <div className={classes.lessonAuthor}>
                            <FormattedMessage
                              {...translations.LessonAuthorLabel}
                              values={{
                                name:
                                  !firstName && !lastName
                                    ? mail
                                      ? `${mail}`
                                      : ""
                                    : `${firstName || ""} ${lastName || ""}`,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        {index > 0 && lessonsNumber > 1 && (
                          <OrderArrow
                            iconName={Icons.arrowOrderUp}
                            clickEvent={() => props.lessonMoveUp(x.lessonId)}
                            className={classes.orderArrow}
                          />
                        )}
                        {index < lessonsNumber - 1 && lessonsNumber > 1 && (
                          <OrderArrow
                            iconName={Icons.arrowOrderDown}
                            clickEvent={() => props.lessonMoveDown(x.lessonId)}
                            className={classes.orderArrow}
                          />
                        )}
                        {x.isPrivate && (
                          <Icon
                            iconName={Icons.private}
                            style={classes.privateIcon}
                          />
                        )}
                        <span
                          className={
                            x.workflow === DOC_WORKFLOW_ENUM.PUBLISHED
                              ? classes.statusPublished
                              : classes.statusDraft
                          }
                        >
                          {isShiseido &&
                          props.isMasterContent &&
                          x.workflow === DOC_WORKFLOW_ENUM.PUBLISHED ? (
                            <FormattedMessage
                              {...translations.AvailableLabel}
                            />
                          ) : (
                            x.workflow.toLowerCase()
                          )}
                        </span>
                        <Button
                          variation={ButtonVariation.primary}
                          size={ButtonSize.small}
                          handleClick={() => props.handleLessonEdit(x.lessonId)}
                        >
                          <FormattedMessage
                            {...translations.EditContentLabel}
                          />
                        </Button>
                        <Icon
                          iconName={Icons.roundMore}
                          style={classes.roundMoreIcon}
                          handleClick={() =>
                            props.setActiveMenuLessonId(x.lessonId)
                          }
                        />
                        <SettingsMenu
                          handleFocusLeave={() => {
                            props.setActiveMenuLessonId(undefined);
                          }}
                          isVisible={
                            props.activeMenuLessonId &&
                            props.activeMenuLessonId === x.lessonId
                          }
                          className={classes.lessonSettingsMenu}
                          menuItems={lessonMenuItems}
                        />
                      </div>
                    </div>
                    <FilterCountries
                      countries={x.marketTags.map((tag) => ({
                        ...tag,
                        Id: tag.id,
                      }))}
                    >
                      {({ filteredCountries: marketTags }) =>
                        marketTags && marketTags.length ? (
                          <div className={classes.tags}>
                            {marketTags.map((tag) => (
                              <Tag key={tag.key} label={tag.label} readOnly />
                            ))}
                          </div>
                        ) : (
                          ""
                        )
                      }
                    </FilterCountries>
                  </div>
                );
              })}
              <BulletTextInput
                handleOnBlur={(e) => props.handleLessonCreate(e.target.value)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ProgramInfo);
