export default ({ FontFamily, EditMasterTemplateLessonScreen }) => {
  const contentEditablePlaceholderStyle = {
    "&[contenteditable=true]:empty:before": {
      content: "attr(placeholder)",
      display: "block",
      color: EditMasterTemplateLessonScreen.textInputPlaceHolderColor,
    },
  };

  return {
    rootContainer: {
      fontFamily: FontFamily,
      paddingTop: "150px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
    center: {
      width: 952,
      display: "flex",
      justifyContent: "space-between",
    },
    left: {
      width: 900,
      display: "flex",
      flexDirection: "column",
    },
    right: {
      right: 0,
      top: 0,
      width: 20,
    },
    closeIcon: {
      "&::before": {
        fontSize: 15,
        cursor: "pointer",
      },
      "&:hover::before": {
        color: EditMasterTemplateLessonScreen.closeIconHoverColor,
        fontSize: 15,
        cursor: "pointer",
      },
    },
    textInputTitle: {
      border: 0,
      outline: 0,
      background: "transparent",
      marginLeft: 50,
      marginRight: 50,
      fontSize: "22px",
      fontWeight: "bold",
      letterSpacing: "0.1px",
      color: EditMasterTemplateLessonScreen.textInputColor,
      ...contentEditablePlaceholderStyle,
    },
    textInputSummary: {
      border: 0,
      outline: 0,
      background: "transparent",
      marginLeft: 50,
      marginRight: 50,
      fontSize: "17px",
      fontWeight: "300",
      letterSpacing: "0.1px",
      color: EditMasterTemplateLessonScreen.textInputColor,
      ...contentEditablePlaceholderStyle,
    },
    detailsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingTop: 30,
      paddingLeft: 50,
      width: "100%",
    },
    leftPart: {
      width: "30%",
      display: "flex",
      flexDirection: "column",
    },
    rightPart: {
      width: "60%",
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
    },
    langPublicContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: 10,
    },
    firstTitle: {
      fontSize: 16,
      color: EditMasterTemplateLessonScreen.firstTitleColor,
    },
    tagList: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    retailerTagsContainer: {
      width: "100%",
    },
    sectionTitle: {
      fontSize: 16,
      color: EditMasterTemplateLessonScreen.sectionTitleColor,
      paddingTop: 30,
      marginBottom: 10,
    },
    tagSelectContainer: {
      position: "relative",
    },
    tagSelectContainerError: {
      position: "relative",
      "& > div": {
        border: "1px solid #c60000",
        borderRadius: 2,
      },
    },
    brandAndRetailer: {
      display: "flex",
      flexDirection: "row",
    },
    brandTagsContainer: {
      width: "100%",
      marginRight: 20,
    },
    buttons: {
      padding: "45px 0",
      "& > button:first-child": {
        marginRight: 10,
      },
    },
    readOnlyTags: {
      "& > div": {
        float: "left",
      },
    },
    publicSwitcherContainer: {
      display: "flex",
      flexDirection: "row",
    },
    publicLabel: {
      fontSize: 16,
      color: EditMasterTemplateLessonScreen.publicLabelColor,
      marginRight: 10,
    },
    rememberContainer: {
      display: "flex",
      flexDirection: "row",
      height: 120,
    },
    recapCheckboxContainer: {
      marginTop: 30,
      "& > div > label": {
        fontSize: 16,
        color: EditMasterTemplateLessonScreen.recapLabelColor,
      },
      "& > div > input": {
        width: 22,
      },
    },
    switchBtn: {
      marginLeft: 30,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      fontSize: 14,
      "& > div": {
        marginLeft: 10,
      },
    },
  };
};
