import React from "react";
import DefaultEditMasterTemplate from "./DefaultEditMasterTemplate";
import EditMasterTemplateReadOnly from "./editMasterTemplateReadOnly";
import { USER_ROLE_NAMES } from "../../configs/constants";
import { connect } from "react-redux";
import { FullscreenLoader } from "genius-ui";

const EditMasterTemplate = ({ userRoles, ...restOfProps }) => {
  if (!userRoles || !userRoles.length) {
    return <FullscreenLoader />;
  }

  if (userRoles.includes(USER_ROLE_NAMES.TRAINER)) {
    return <EditMasterTemplateReadOnly {...restOfProps} />;
  }

  return <DefaultEditMasterTemplate {...restOfProps} />;
};

const mapStateToProps = (state) => ({
  userRoles: state.auth.profileInfo.roles,
});

export default connect(mapStateToProps)(EditMasterTemplate);
