import { DOCUMENT_VIEW_TYPES } from "../../configs/constants";
import * as constants from "../../constants/store/shops";
import { RESET_STORE } from "../../constants/store/auth";

const initialState = {
  viewType: DOCUMENT_VIEW_TYPES.GRID,
  shops: [],
  shopTableMetaData: {
    shopSearchTerm: "",
    sortState: {},
    shopsOnPage: 12,
    page: 1,
    isLoading: false,
  },
  shopSpecificities: null,
  limit: 10,
  filters: {
    zone: {
      selectedValues: [],
    },
    country: {
      selectedValues: [],
    },
    city: {
      selectedValues: [],
    },
    universe: {
      selectedValues: [],
    },
    vmSegmentation: {
      selectedValues: [],
    },
  },
  locationType: {
    selectedValues: [],
  },
  searchText: "",
  isExporting: false,
  hasMoreItem: true,
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.CHANGE_VIEW_TYPE:
      return {
        ...state,
        viewType: data,
      };
    case constants.RESET_SHOPS_WITH_DATA: {
      return {
        ...state,
        shops: data,
      };
    }
    case constants.SET_SHOPS: {
      return {
        ...state,
        shops: [...state.shops, ...data],
      };
    }
    case constants.UPDATE_SHOP_TABLE_META_DATA: {
      return {
        ...state,
        shopTableMetaData: { ...initialState.shopTableMetaData, ...data },
      };
    }
    case constants.UPDATE_FILTER_SELECTED_VALUES:
      return {
        ...state,
        filters: {
          ...state.filters,
          [data.filterName]: { selectedValues: data.selectedValues },
        },
      };
    case constants.UPDATE_FILTER_LOCATION_TYPE_SELECTED_VALUES:
      return {
        ...state,
        locationType: {
          selectedValues: data,
        },
      };
    case constants.RESET_FILTERS: {
      return {
        ...state,
        filters: initialState.filters,
        locationType: initialState.locationType,
      };
    }
    case constants.UPDATE_EXPORTING_STATUS: {
      return {
        ...state,
        isExporting: data,
      };
    }
    case constants.UPDATE_HAS_MORE_ITEM: {
      return {
        ...state,
        hasMoreItem: data,
      };
    }
    case constants.UPDATE_SEARCH_TEXT: {
      return {
        ...state,
        searchText: data,
      };
    }
    case constants.SET_SPECIFICITIES: {
      return {
        ...state,
        shopSpecificities: data,
      };
    }
    case constants.SET_SPECIFICITIES_FIELD: {
      state.shopSpecificities[data.field] = data.infos;
      return state;
    }
    case constants.RESET_STORE: {
      return { ...initialState };
    }
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
