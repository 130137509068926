import React from "react";
import classnames from "classnames";
import OverflowContainerStyle from "./OverflowContainer.style";
import withThemedStyle from "../../hoc/withThemedStyle";

const CHARACTERS_LIMIT = 21;

class OverflowContainer extends React.PureComponent {
  state = {
    isExpanded: false,
  };

  toggleExpansionState = () =>
    this.setState((state) => ({
      isExpanded: !state.isExpanded,
    }));

  render() {
    const { className, classes, children, isExpansionDisabled } = this.props;

    const classProp = classnames([
      className,
      classes.container,
      {
        [classes.containerClosed]: !this.state.isExpanded,
      },
    ]);

    return (
      <div
        onClick={(event) => {
          if (!isExpansionDisabled) {
            event.preventDefault();
            event.stopPropagation();
            this.toggleExpansionState();
          }
        }}
        className={classProp}
      >
        {children}
      </div>
    );
  }
}

export default withThemedStyle(OverflowContainerStyle)(OverflowContainer);
