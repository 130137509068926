import axios from "axios";
import { MEDIA_SERVER } from "../configs/client";
import { getAccessToken } from "../utils/authUtils";
import crypto from "crypto";
import { sleep } from "utils/sleep";

async function uploadFragment(
  axiosInstance,
  onUploadProgress,
  formData,
  tryNb = 0,
) {
  try {
    const result = await axiosInstance.post("/upload/fragment", formData, {
      onUploadProgress,
    });
    return result;
  } catch (error) {
    if (tryNb < 20) {
      await sleep(1000);
      return await uploadFragment(
        axiosInstance,
        onUploadProgress,
        formData,
        tryNb + 1,
      );
    }
    throw new Error("Network error");
  }
}

async function uploadVideoFragment(
  axiosInstance,
  fragment,
  part,
  uploadSessionId,
  onUploadProgress,
) {
  const arrayBuffer = await fragment.arrayBuffer();
  const buffer = Buffer.from(arrayBuffer);
  const hash = crypto.createHash("md5").update(buffer).digest("hex");

  const formData = new window.FormData();
  formData.append("hash", hash);
  formData.append("part", part);
  formData.append("uploadSessionId", uploadSessionId);
  formData.append("fragment", fragment);
  return await uploadFragment(axiosInstance, onUploadProgress(part), formData);
}

async function uploadVideoFragments(
  axiosInstance,
  fragments,
  uploadSessionId,
  onUploadProgress,
) {
  await Promise.all(
    fragments.map((fragment, index) =>
      uploadVideoFragment(
        axiosInstance,
        fragment,
        index,
        uploadSessionId,
        onUploadProgress,
      ),
    ),
  );
}

const fragmentSize = 1000000; // Size of each video fragment in bytes

export default class MediaService {
  constructor() {
    this.uploadVideo = this.uploadVideo.bind(this);
    this.checkVideoStatus = this.checkVideoStatus.bind(this);
    this.getResizedImage = this.getResizedImage.bind(this);
  }

  uploadVideo(file, successCallback, errorCallback, uploadProgressCallback) {
    const config = {
      headers: { Authorization: `Bearer-${getAccessToken()}` },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        uploadProgressCallback(percentCompleted);
      },
    };

    const formData = new window.FormData();
    formData.append("video_to_convert", file);

    axios
      .post(`${MEDIA_SERVER}/video/public`, formData, config)
      .then((response) => successCallback(response.data))
      .catch((error) => errorCallback(error));
  }

  // TODO: Delete when v3 is multi threaded
  async uploadVideoV2(file, onUploadProgress, handleCompressionProgress) {
    const config = {
      headers: { Authorization: `Bearer-${getAccessToken()}` },
      onUploadProgress,
    };

    const formData = new window.FormData();
    formData.append("video_to_convert", file);

    const result = await axios.post(
      `${MEDIA_SERVER}/video/public`,
      formData,
      config,
    );
    let isConverting = true;
    do {
      const res = await this.checkVideoStatusV2(result.data.url);
      const { status, progress } = res.data;
      handleCompressionProgress && handleCompressionProgress(progress);
      isConverting = !(
        ["ACTIVE", "REJECT"].includes(status) || progress === 100
      );
      await new Promise((resolve) => setTimeout(resolve, 200));
    } while (isConverting);
    return result;
  }

  // TODO: Delete when v3 is multi threaded
  async checkVideoStatusV2(videoUrl) {
    const url = `${MEDIA_SERVER}/video/status?file_url=${encodeURIComponent(
      videoUrl,
    )}`;

    const authorizationHeader = { Authorization: `Bearer-${getAccessToken()}` };
    return axios.get(url, { headers: authorizationHeader });
  }

  async uploadVideoV3(
    handleInitUploadProgress,
    file,
    onUploadProgress,
    handleCompressionProgress,
  ) {
    const axiosInstance = axios.create({
      baseURL: MEDIA_SERVER,
      headers: { Authorization: `Bearer-${getAccessToken()}` },
    });
    const fragments = [];
    const totalSize = file.size;
    let offset = 0;

    const arrayBuffer = await file.arrayBuffer();
    const buffer = Buffer.from(arrayBuffer);
    handleInitUploadProgress(Math.ceil(totalSize / fragmentSize));
    const hash = crypto.createHash("md5").update(buffer).digest("hex");
    while (offset < totalSize) {
      const fragment = file.slice(
        offset,
        Math.min(offset + fragmentSize, totalSize),
      );
      fragments.push(fragment);
      offset += fragmentSize;
    }

    const {
      data: { uploadSessionId },
    } = await axiosInstance.post("/upload/initialize", {
      hash,
      partNb: fragments.length,
      type: "video",
      filename: file.path,
    });

    await uploadVideoFragments(
      axiosInstance,
      fragments,
      uploadSessionId,
      onUploadProgress,
    );

    const result = await axiosInstance.post("/upload/complete", {
      uploadSessionId,
      hash,
    });

    let isConverting = true;
    do {
      const res = await this.checkVideoStatusV3(result.data.jobName);
      const { status, progress } = res.data;
      handleCompressionProgress && handleCompressionProgress(progress);
      isConverting = !(
        ["ACTIVE", "REJECT"].includes(status) || progress === 100
      );
    } while (isConverting);
    return result;
  }

  async checkVideoStatusV3(jobName) {
    const url = `${MEDIA_SERVER}/video/status?job_name=${jobName}`;

    const authorizationHeader = { Authorization: `Bearer-${getAccessToken()}` };
    return axios.get(url, { headers: authorizationHeader });
  }

  uploadPdfCover(fileUrl) {
    const config = {
      headers: { Authorization: `Bearer ${getAccessToken()}` },
    };

    return axios.post(
      `${MEDIA_SERVER}/pdf-to-img`,
      { fileUrl, pages: [1] },
      config,
    );
  }

  uploadMedia(files) {
    const config = {
      headers: { Authorization: `Bearer ${getAccessToken()}` },
    };

    const formData = new window.FormData();
    formData.append("files", files);

    return axios.post(`${MEDIA_SERVER}/upload`, formData, config);
  }

  checkVideoStatus(videoUrl, successCallback, errorCallback) {
    const url = `${MEDIA_SERVER}/video/status?file_url=${encodeURIComponent(
      videoUrl,
    )}`;

    const authorizationHeader = { Authorization: `Bearer-${getAccessToken()}` };
    axios
      .get(url, { headers: authorizationHeader })
      .then((response) => successCallback(response.data))
      .catch((error) => errorCallback(error));
  }

  getResizedImage(originalUrl /*, format, resolution */) {
    // TODO: uncomment code and delete artificial delay(timeout) when media server is set up ok
    /* const url = `${MEDIA_SERVER}/image/public?format=${format}&link=${encodeURIComponent(originalUrl)}&res=${resolution}`

    const authorizationHeader = { Authorization: `Bearer-${getAccessToken()}` }
    return axios.get(url, { headers: authorizationHeader }) */
    return new Promise((resolve) => {
      setTimeout(() => resolve(originalUrl), Math.random() * 750);
    });
  }
}
