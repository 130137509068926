import React, { useEffect } from "react";
import { defaultStyles } from "./BattleResults.style";
import Navigation from "../../../components/Navigation";
import ResultsHeader from "../resultsHeader";
import ResultDetailsCard from "../resultDetailsCard";
import { connect } from "react-redux";
import compose from "lodash/fp/compose";
import { FullscreenLoader } from "genius-ui";
import KeyDetailWidget from "../../../components/KPI/KeyDetailWidget";
import { WidgetContainer } from "../../../components/KPI";
import { FormattedMessage, injectIntl } from "react-intl";
import { translations } from "./BattleResults.translations";
import BattleLeaderBoard from "./battleLeaderboard";
import {
  GetBattleResults,
  ResetStore,
  UpdateBattleInfo,
  UpdateSelectedPeriodResult,
} from "../../../store/battleResult/actions";
import { getPath } from "../../../core/paths";
import { exportSpecifiedKpiBattleData } from "../../../utils/excelExport/kpiBattle";
import { UpdateLeaderBoardColumnSort } from "../../../store/kpiBattle/actions";
import { SetFetchIndicator } from "../../../store/common/actions";
import { GetUsersProfilesData } from "../../../store/user/actions";
import { GetIntervals } from "../../../store/kpiFilter/actions";
import { isShiseido } from "../../../configs/client";
import { roundPtsNbr } from "utils/string";
import { createUseStyles } from "react-jss";
import useThemedStyle from "hooks/style/useThemedStyle";

const useStyle = createUseStyles(defaultStyles);

const BattleResults = (props) => {
  const {
    match,
    isFetching,
    GetResults,
    ResetStore,
    results,
    info,
    leaderBoardColumnSort,
    OnSort,
    history,
    intl,
    GetUsersProfilesData,
    profiles,
    UpdateSelectedPeriodResult,
    intervals,
    GetIntervals,
    UpdateBattleInfoEndDate,
    UpdateBattleInfoStartDate,
  } = props;
  const classes = useThemedStyle(useStyle, props);

  useEffect(() => {
    if (info.selectedPeriod) {
      GetResults(match.params.battleId);
    }
  }, [leaderBoardColumnSort, info.selectedPeriod]);

  useEffect(() => {
    GetIntervals();
    return () => {
      ResetStore();
    };
  }, []);

  useEffect(() => {
    GetUsersProfilesData(results.map((result) => result.key));
  }, [results.length]);

  const goBack = () => history.push(getPath("contentManager"));

  return (
    <div className={classes.root}>
      {isFetching && <FullscreenLoader />}
      <Navigation />
      <ResultsHeader
        onBack={goBack}
        exportHandler={() =>
          exportSpecifiedKpiBattleData(results, profiles, info, intl)
        }
        selectedEndDate={info.selectedEndDate}
        selectedStartDate={info.selectedStartDate}
        selectedPeriod={info.selectedPeriod}
        selectStartDate={UpdateBattleInfoStartDate}
        selectEndDate={UpdateBattleInfoEndDate}
        selectPeriod={(period) => {
          UpdateSelectedPeriodResult(period);
          GetResults(match.params.battleId);
        }}
        useIntervalsAndDateSelector={intervals}
      />
      <div className={classes.content}>
        <div className={classes.shiseidoContainerPlaceHolder}>
          {isShiseido && (
            <div className={classes.shiseidoPlaceHolder}>
              <FormattedMessage {...translations.InformUsers} />
            </div>
          )}
        </div>
        <ResultDetailsCard
          photoUrl={info.photo}
          name={info.name}
          status={info.status}
          creationDate={info.creationDate}
          publicationDate={info.publicationDate}
          markets={info.markets}
          brands={info.brands}
        />
        <div className="widgets">
          <div className="keyDetailWidget">
            <WidgetContainer isMarginRightEnabled={false}>
              <KeyDetailWidget
                title={
                  <FormattedMessage
                    {...translations.NumberOfParticipantsLabel}
                  />
                }
                primaryData={info.participants}
                isAvailable
                hideInfoIcon
                hideOtherData
              />
            </WidgetContainer>
          </div>
          <div className="keyDetailWidget">
            <WidgetContainer isMarginRightEnabled={false}>
              <KeyDetailWidget
                title={
                  <FormattedMessage
                    {...translations.AverageParticipantsLabel}
                  />
                }
                primaryData={`${roundPtsNbr(info.participantsAverage)}pts`}
                isAvailable
                hideInfoIcon
                hideOtherData
              />
            </WidgetContainer>
          </div>
        </div>
        <div className="leaderboardTitle">
          <FormattedMessage {...translations.LeaderboardTitle} />
        </div>
        <BattleLeaderBoard
          onSort={OnSort}
          columnSort={leaderBoardColumnSort}
          data={results}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  info: state.battleResults.info,
  results: state.battleResults.results,
  leaderBoardColumnSort: state.kpiBattle.leaderBoardColumnSort,
  isFetching: state.common.fetchIndicator,
  profiles: state.users.profiles,
  intervals: state.kpi.filters.intervals,
});

const mapDispatchToProps = (dispatch) => ({
  GetUsersProfilesData: (userIds) => {
    dispatch(GetUsersProfilesData(userIds));
  },
  GetResults: (battleId) => {
    dispatch(SetFetchIndicator(true));
    dispatch(
      GetBattleResults(battleId, () => {
        dispatch(SetFetchIndicator(false));
      }),
    );
  },
  UpdateSelectedPeriodResult: (period) => {
    dispatch(UpdateSelectedPeriodResult(period));
  },
  GetIntervals: () => {
    dispatch(GetIntervals());
  },
  UpdateBattleInfoStartDate: (selectedStartDate) =>
    dispatch(UpdateBattleInfo({ selectedStartDate })),
  UpdateBattleInfoEndDate: (selectedEndDate) =>
    dispatch(UpdateBattleInfo({ selectedEndDate })),
  ResetStore: () => dispatch(ResetStore()),
  OnSort: (columnName, sortMethod) =>
    dispatch(UpdateLeaderBoardColumnSort(columnName, sortMethod)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(BattleResults);
