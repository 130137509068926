import * as constants from "../../constants/store/battleResults";
import { RESET_STORE } from "../../constants/store/auth";
import { KPI_AVAILABLE_PERIODS } from "../../configs/KPI/constants";

const initialState = {
  info: {
    selectedEndDate: null,
    selectedStartDate: null,
    selectedPeriod: null,
    photo: "",
    name: "",
    status: "",
    creationDate: null,
    publicationDate: null,
    markets: [],
    brands: [],
    participants: 0,
    participantsAverage: 0,
  },
  results: [],
  columnSort: null,
};

export default function (state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case constants.UPDATE_BATTLE_RESULTS:
      return {
        ...state,
        results: data,
      };
    case constants.SET_SELECTED_PERIOD_RESULT:
      return {
        ...state,
        info: {
          ...state.info,
          selectedPeriod: data,
        },
      };
    case constants.UPDATE_BATTLE_INFO:
      return {
        ...state,
        info: {
          ...state.info,
          ...data,
        },
      };
    case constants.UPDATE_COLUMN_SORT:
      return {
        ...state,
        columnSort: data,
      };
    case constants.RESET_STORE:
      return { ...initialState };
    case RESET_STORE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
