import * as constants from "../../constants/store/userProfile";
import client from "../../graphql/client";
import { SetErrorMessage } from "../notification/actions";
import {
  GET_USER_PROFILE_DATA,
  GET_USER_PROFILE_KPI_DATA,
  GET_USER_TAGS,
} from "../../graphql/remote/users/queries";
import { getFormattedUserProfileData } from "./transformers";
import { GET_ALL_BADGES } from "../../graphql/remote/badges/queries/GetAllBadges.graphql";
import { getFormattedTraineeUserProfileData } from "../transformers/traineeUserProfile";
import { getFormattedTrainerUserProfileData } from "../transformers/trainerUserProfile";
import { GetUniverseTags } from "../tag/actions";
import { USER_TYPES } from "../../configs/constants";
import clientConfig from "../../configs/client";
import { extractFilterValues, getPeriodValue } from "../actions/common/kpi";

export const UpdateUserProfileData = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_USER_PROFILE_DATA,
    data: data,
  });
};

export const UpdateLoadingStatus = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_LOADING_STATUS,
    data: data,
  });
};

export const SetBadges = (data) => (dispatch) => {
  dispatch({
    type: constants.SET_BADGES,
    data: data,
  });
};

export const ResetStore = () => (dispatch) => {
  dispatch({
    type: constants.RESET_STORE,
  });
};

export const UpdateUserType = (userType) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_USER_TYPE,
    data: userType,
  });
};

export const GetUserType = (userId) => async (dispatch) => {
  try {
    const response = await client.query({
      fetchPolicy: "network-only",
      query: GET_USER_TAGS,
      variables: {
        userId: userId,
        search: "",
      },
      notifyOnNetworkStatusChange: true,
    });

    const isTrainer = response.data.admin.users[0].tags.filter((tag) =>
      clientConfig.USER_TRAINER_ROLE_CLUSTER_IDS.includes(parseInt(tag.tagId)),
    ).length;

    dispatch(
      UpdateUserType(isTrainer ? USER_TYPES.TRAINER : USER_TYPES.TRAINEE),
    );
  } catch (e) {
    dispatch(
      SetErrorMessage("An error occurred while fetching the user profile data"),
    );
  }
};

export const GetUserProfileData = (userId) => async (dispatch, getState) => {
  try {
    const { badges } = getState().userProfile;
    const {
      learningDetails: {
        filters: {
          degree: { selectedValues },
        },
      },
      filters,
    } = getState().kpi;
    const period = getPeriodValue(filters.period);
    const degreeIds = selectedValues.map((item) => parseInt(item.value));
    const profileKPIData = await GetUserProfileKPIData(
      userId,
      degreeIds,
      period,
    );
    const result = await getDefaultUserData(userId);
    const tagIds = [];
    const marketTag = result.tags.find((x) => x.clusterId === "2");

    if (marketTag) {
      tagIds.push(parseInt(marketTag.tagId));
    }

    if (!badges.length) {
      await GetAllBadges(dispatch, tagIds);
    }

    dispatch(
      UpdateUserProfileData(
        getFormattedUserProfileData({
          ...result,
          badges: getState().userProfile.badges,
          kpi: profileKPIData,
        }),
      ),
    );
    dispatch(UpdateLoadingStatus(false));
  } catch (e) {
    dispatch(
      SetErrorMessage("An error occurred while fetching the user profile data"),
    );
  }
};

export const GetTraineeUserProfileData =
  (userId) => async (dispatch, getState) => {
    try {
      const {
        learningDetails: {
          filters: {
            degree: { selectedValues },
          },
        },
        filters,
      } = getState().kpi;
      const period = extractFilterValues(filters).period;
      const degreeIds = selectedValues.map((item) => parseInt(item.value));
      // const universes = getState().userProfile.filters[FILTER_TYPES.UNIVERSE]

      const profileKPIData = await GetUserProfileKPIData(
        userId,
        degreeIds,
        period,
        USER_TYPES.TRAINEE,
      );
      await dispatch(GetUniverseTags());
      const result = await getDefaultUserData(userId);

      dispatch(
        UpdateUserProfileData(
          getFormattedTraineeUserProfileData({
            ...result,
            kpi: profileKPIData,
          }),
        ),
      );
      dispatch(UpdateLoadingStatus(false));
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while fetching the user profile data",
        ),
      );
    }
  };

const getDefaultUserData = async (userId) => {
  const result = await client.query({
    fetchPolicy: "network-only",
    query: GET_USER_PROFILE_DATA,
    variables: {
      userId: userId,
      search: "",
    },
    notifyOnNetworkStatusChange: true,
  });
  return result.data.admin.users[0];
};

export const GetTrainerUserProfileData =
  (userId) => async (dispatch, getState) => {
    try {
      const {
        learningDetails: {
          filters: {
            degree: { selectedValues },
          },
        },
        filters,
      } = getState().kpi;
      const period = extractFilterValues(filters).period;
      const degreeIds = selectedValues.map((item) => parseInt(item.value));

      const profileKPIData = await GetUserProfileKPIData(
        userId,
        degreeIds,
        period,
        USER_TYPES.TRAINER,
      );
      await dispatch(GetUniverseTags());
      const result = await getDefaultUserData(userId);

      dispatch(
        UpdateUserProfileData(
          getFormattedTrainerUserProfileData({
            ...result,
            kpi: profileKPIData,
          }),
        ),
      );
      dispatch(UpdateLoadingStatus(false));
    } catch (e) {
      dispatch(
        SetErrorMessage(
          "An error occurred while fetching the user profile data",
        ),
      );
    }
  };

const GetAllBadges = async (dispatch, tagIds) => {
  try {
    const result = await client.query({
      fetchPolicy: "network-only",
      query: GET_ALL_BADGES,
      variables: {
        tagIds,
      },
      notifyOnNetworkStatusChange: true,
    });

    dispatch(SetBadges(result.data.admin.badges));
  } catch (e) {
    dispatch(SetErrorMessage("An error occurred while fetching badges"));
  }
};

const GetUserProfileKPIData = async (userId, degreeIds, date, userType) => {
  const result = await client.query({
    fetchPolicy: "network-only",
    query: GET_USER_PROFILE_KPI_DATA,
    variables: {
      userId: parseInt(userId),
      date,
      degreeId: degreeIds.length ? degreeIds : undefined,
      userType,
    },
    notifyOnNetworkStatusChange: true,
  });
  return result.data.userProfileKPI;
};

export const UpdateFilters = (data) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_FILTERS,
    data,
  });
};
