import React, { useCallback } from "react";
import { translations } from "../../PublishInsight.translation";
import { FormattedMessage } from "react-intl";
import useThemedStyle from "../../../../hooks/style/useThemedStyle";
import { usePublishInsightStyle } from "../../PublishInsight.style";
import { useDispatch, useSelector } from "react-redux";
import { UpdateInsightTags } from "../../../../store/insight/actions";
import { useCurrentInsightId } from "../../hooks/useCurrentInsightId";
import { useCurrentInsightLang } from "../../hooks/useCurrentInsightLang";
import clientConfig, { CONFIG_CLUSTER } from "../../../../configs/client";
import SelectTagsByClusterId from "../../../../containers/Apollo/SelectTagsByClusterId";
import {
  formatOptionsForSave,
  formatOptionsForSelect,
} from "../../../../utils/dataTransformation/tags";

const selectors = {
  brands: (state) => state.referential.brands,
  selectedBrands: (state) => state.insights.publishInsight.brands,
  retailers: (state) => state.referential.retailers,
  selectedRetailers: (state) => state.insights.publishInsight.retailers,
  marketId: (state) => Number(state.insights.publishInsight.countries[0].Id),
};

const EditInsightBrandAndRetailer = (props) => {
  const dispatch = useDispatch();
  const brands = useSelector(selectors.brands);
  const selectedBrands = useSelector(selectors.selectedBrands);
  const retailers = useSelector(selectors.retailers);
  const selectedRetailers = useSelector(selectors.selectedRetailers);
  const marketId = useSelector(selectors.marketId);
  const currentInsightId = useCurrentInsightId();
  const currentInsightLang = useCurrentInsightLang();
  const classes = useThemedStyle(usePublishInsightStyle, props);

  const handleSelection = useCallback(
    (selection, domain) =>
      dispatch(
        UpdateInsightTags(
          currentInsightId,
          currentInsightLang,
          formatOptionsForSave(selection),
          domain,
        ),
      ),
    [currentInsightId, currentInsightLang, formatOptionsForSave, dispatch],
  );

  const SelectBrand = useCallback(
    (selection) => handleSelection(selection, "brands"),
    [handleSelection],
  );

  const SelectRetailer = useCallback(
    (selection) => handleSelection(selection, "retailers"),
    [handleSelection],
  );

  return (
    <div className={classes.brandAndRetailer}>
      <div className={classes.brandTagsContainer}>
        <div className={classes.sectionTitle}>
          <FormattedMessage {...translations.BrandLabel} />
        </div>
        <FormattedMessage
          {...translations.AddBrandPlaceholder}
          data-testid="edit-insight-brand"
        >
          {(message) => (
            <SelectTagsByClusterId
              tags={formatOptionsForSelect(brands, false)}
              className={classes.tagSelectContainer}
              classNameError={props.classNameError}
              id="brand"
              clusterIds={clientConfig.BRAND_TAGS_CLUSTER_IDS}
              tagType={CONFIG_CLUSTER.BRAND}
              selectedItems={formatOptionsForSelect(selectedBrands, false)}
              placeholder={message[0]}
              onSelectionChanged={SelectBrand}
              mustHaveAtLeastOneChoice
            />
          )}
        </FormattedMessage>
      </div>
      <div className={classes.retailerTagsContainer}>
        <div className={classes.sectionTitle}>
          <FormattedMessage {...translations.RetailerLabel} />
        </div>
        <FormattedMessage
          {...translations.AddRetailerPlaceholder}
          data-testid="edit-insight-retailer"
        >
          {(message) => (
            <SelectTagsByClusterId
              tags={formatOptionsForSelect(retailers, false)}
              className={classes.tagSelectContainer}
              id="retailer"
              clusterIds={
                props.metaTagFilter
                  ? undefined
                  : clientConfig.RETAILER_TAGS_CLUSTER_IDS
              }
              clusterMetaTag={props.metaTagFilter ? marketId : undefined}
              tagType={CONFIG_CLUSTER.RETAILER}
              selectedItems={formatOptionsForSelect(selectedRetailers, false)}
              placeholder={message[0]}
              onSelectionChanged={SelectRetailer}
            />
          )}
        </FormattedMessage>
      </div>
    </div>
  );
};

export default React.memo(EditInsightBrandAndRetailer);
