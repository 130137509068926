import gql from "graphql-tag";

export const PROGRAM_REMOVE_LESSONS = gql`
  mutation programRemoveLessons(
    $programId: ID
    $lang: LangEnum
    $lessonIds: [ID!]!
  ) {
    admin {
      program(programId: $programId, lang: $lang) {
        removeLessons(lessonIds: $lessonIds) {
          programId
          programId
        }
      }
    }
  }
`;
