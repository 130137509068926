import React from "react";
import { AsyncPaginate, withAsyncPaginate } from "react-select-async-paginate";
import CreatableSelect from "react-select/creatable";
import PropTypes from "prop-types";
import { customDropdownStyle } from "./Dropdown.style";

const ClearIndicator = (props) => {
  return <></>;
};

const CreatableAsyncPaginate = withAsyncPaginate(CreatableSelect);

const Dropdown = ({
  isMulti,
  additional,
  isCreatable,
  handleChange,
  handleInputChange,
  clusterIds,
  storeType,
  ...props
}) => {
  const Component = isCreatable ? CreatableAsyncPaginate : AsyncPaginate;
  const components = {};

  if (!props.isClearable) components.ClearIndicator = ClearIndicator;
  return (
    <Component
      isMulti={isMulti}
      hideSelectedOptions
      styles={customDropdownStyle}
      additional={{ page: 0, ...additional }}
      onChange={handleChange}
      onInputChange={handleInputChange}
      components={components}
      blurInputOnSelect={false}
      createOptionPosition="first"
      closeMenuOnSelect={!isMulti}
      closeOnSelect={false}
      noOptionsMessage={() => null}
      {...props}
    />
  );
};

Dropdown.propTypes = {
  /** Specified if we can select one or many values */
  isMulti: PropTypes.bool,

  /** Specified if we can create a new value */
  creatable: PropTypes.bool,

  /** Specified if the component should cache the results */
  cacheOptions: PropTypes.bool,

  /** Specified if we can remove the selected value */
  isClearable: PropTypes.bool,
};

Dropdown.defaultProps = {
  isMulti: false,
  creatable: false,
  cacheOptions: true,
  isClearable: true,
};

export default React.memo(Dropdown);
