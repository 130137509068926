import clientConfig from "../../configs/client";
import { decodeNewLines } from "../../utils/string";
import { PlaceHolderDegree } from "assets/icons";

export const GetFormattedMasterTemplateInfo = (result, lang) => {
  const degree = result.data.admin.degrees[0].versions.find(
    (x) => x.lang === lang,
  );
  const tags = degree.tags
    .filter((x) =>
      clientConfig.EXPERTISE_CLUSTERIDS.includes(parseInt(x.clusterId)),
    )
    .map((x) => ({ Id: x.tagId, Value: x.title }));
  const brands = degree.tags
    .filter((x) =>
      clientConfig.BRAND_TAGS_CLUSTER_IDS.includes(parseInt(x.clusterId)),
    )
    .map((x) => ({ Id: x.tagId, Value: x.title }));
  const retailers = degree.tags
    .filter((x) =>
      clientConfig.RETAILER_TAGS_CLUSTER_IDS.includes(parseInt(x.clusterId)),
    )
    .map((x) => ({ Id: x.tagId, Value: x.title }));

  return {
    id: degree.degreeId,
    lang,
    tags,
    brands,
    retailers,
    title: degree.title ? decodeNewLines(degree.title) : "",
    summary: degree.summary ? decodeNewLines(degree.summary) : "",
    picCover: degree.picCover || PlaceHolderDegree,
    workflow: degree.workflow,
  };
};
