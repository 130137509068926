import React from "react";
import PropTypes from "prop-types";
import { Button } from "genius-ui";
import { FormattedMessage } from "react-intl";
import SwitchButton from "../../common/SwitchButton";
import { translations } from "./PublicationButtons.translations";
import { PublicationButtonsStyles } from "./PublicationButtons.style";
import withThemedStyle from "../../../components/hoc/withThemedStyle";
import classNames from "classnames";

const PublicationButtons = ({
  classes,
  isOn,
  btnClickHandler,
  switchHandler,
  className,
}) => {
  return (
    <div className={classNames([classes.buttons, className])}>
      <Button variation="primary" size="big" handleClick={btnClickHandler}>
        <FormattedMessage {...translations.SaveButton} />
      </Button>
      <div className={classes.switchBtn}>
        <SwitchButton id="workflow" isOn={isOn} handleToggle={switchHandler} />
        <div>
          <FormattedMessage {...translations.ReadyToUseLabel} />
        </div>
      </div>
    </div>
  );
};

PublicationButtons.propTypes = {
  isOn: PropTypes.bool.isRequired,
  btnClickHandler: PropTypes.func.isRequired,
  switchHandler: PropTypes.func.isRequired,
  /** custom className (optional) */
  className: PropTypes.string,
};

export default withThemedStyle(PublicationButtonsStyles)(PublicationButtons);
