import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { Icon, Icons } from "genius-ui";
import UploadMedia from "../UploadMedia";
import Style from "./UploadImageButton.style";
import { translations } from "./UploadImageButton.translations";

const useStyle = createUseStyles(Style);

const UploadImageButton = (props) => {
  const { containerClass, ...restProps } = props;
  const classes = useThemedStyle(useStyle, props);

  return (
    <div
      className={classNames([classes.imageHandlerContainer, containerClass])}
    >
      <UploadMedia
        isVideoUploadEnabled={false}
        {...restProps}
        className={classNames({
          [classes.uploadContainer]: !restProps.disabled,
          [classes.uploadContainerDisabled]: restProps.disabled,
        })}
      >
        <Icon iconName={Icons.upload} style={classes.uploadIcon} />
        <FormattedMessage {...translations.DefaultButtonLabel} />
      </UploadMedia>
    </div>
  );
};

UploadImageButton.propTypes = {
  /** Extra style can be applied on the container by passing this class name to the component **/
  containerClass: PropTypes.string,
  /** It's a wrapper around UploadMedia, so it accepts all the props that UploadFile accepts **/
};

export default UploadImageButton;
