import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CROP_VARIATION } from "../../../configs/constants";
import useQuestions from "../../../hooks/activity/useQuestions";
import Style from "./BattleQuestionEditor.style";
import { FormattedMessage } from "react-intl";
import {
  BattleQuestionPropTypes,
  BattleQuestionTemplates,
} from "../../../constants/domain_items/battle/battle_question";
import { CreateBattleQuestionChoice } from "../../../constants/domain_items/battle/battle_question_choice";
import BattleQuestionChoiceEditor from "../BattleQuestionChoiceEditor";
import AddNewContainer, {
  DIRECTION_HORIZONTAL,
} from "../../common/AddNewContainer";
import ChangeTemplate from "../../common/ChangeTemplate";
import UploadImageButton from "../../common/UploadImageButton";
import CropImage from "../../common/CropImage";
import { Icon, Icons } from "genius-ui";
import RadioGroup from "../../common/RadioGroup";
import translations from "./BattleQuestionEditor.translations";
import ContentEditableUncontrolled from "../../common/ContentEditableUncontrolled";
import { createUseStyles } from "react-jss";
import useThemedStyle from "../../../hooks/style/useThemedStyle";

const useStyle = createUseStyles(Style);

// half of fixed height
const ADD_BUTTON_SWITCH_BREAKPOINT = 368;
const MAX_NR_OF_ANSWERS = 3;
const ADD_NEW_OPTION_VALUE = "addNewOption";

const CreateNewChoice = () => [CreateBattleQuestionChoice()];

const BattleQuestionEditor = (props) => {
  const {
    id,
    type,
    title,
    image,
    description,
    choices: choicesProp,
    isSelected,
    onSelect,
    onTypeChange,
    onTitleChange,
    onImageChange,
    onDescriptionChange,
    onChoicesChange,
    onAddQuestionBefore,
    onAddQuestionAfter,
    onRemove,
  } = props;

  const classes = useThemedStyle(useStyle, props);
  // manage crop visibility
  const [isCropVisible, setIsCropVisible] = useState(false);

  const showCropper = useCallback(
    () => setIsCropVisible(true),
    [setIsCropVisible],
  );
  const hideCropper = useCallback(
    () => setIsCropVisible(false),
    [setIsCropVisible],
  );

  // manage add question
  const handleAddQuestionBefore = useCallback(
    () => onAddQuestionBefore(id),
    [onAddQuestionBefore, id],
  );
  const handleAddQuestionAfter = useCallback(
    () => onAddQuestionAfter(id),
    [onAddQuestionAfter, id],
  );

  // manage title
  const handleTitleChange = useCallback(
    (newTitle) => onTitleChange(id, newTitle, title),
    [onTitleChange, id, title],
  );

  // manage image
  const handleImageChange = useCallback(
    (newImage) => onImageChange(id, newImage, image),
    [onImageChange, id, image],
  );

  // manage description
  const handleDescriptionChange = useCallback(
    (newDescription) => onDescriptionChange(id, newDescription, description),
    [onDescriptionChange, id, description],
  );

  const [choices, setChoices] = useState(choicesProp);

  const {
    handleQuestionChange: handleChoiceChange,
    removeQuestion: removeChoice,
    addQuestionAfter: addChoiceAfter,
  } = useQuestions(choices, CreateNewChoice, ({ questions: newChoices }) =>
    handleChoicesChange(newChoices),
  );

  // manage choices
  const handleAddChoice = useCallback(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      setChoices((prev) => [
        ...prev,
        {
          id: "temporary" + Date.now(),
          isCorrect: false,
          text: "",
        },
      ]);
      addChoiceAfter(choices.length);
    },
    [choices, addChoiceAfter],
  );

  const handleRemoveChoice = useCallback(
    (index) => {
      setChoices((prev) => prev.filter((_, qIndex) => index !== qIndex));
      removeChoice(index);
    },
    [removeChoice],
  );

  const handleUpdateChoices = useCallback(
    (text, index) => {
      setChoices((prev) =>
        prev.map((question, qIndex) => ({
          ...question,
          text: index === qIndex ? text : question.text,
        })),
      );
      handleChoiceChange(index, { text });
    },
    [handleChoiceChange],
  );

  const choiceOptions = useMemo(() => {
    const choiceOptions = choices.map((choice) => ({
      value: choice.id,
      label: choice.text,
      originalData: choice,
    }));
    if (choiceOptions.length < MAX_NR_OF_ANSWERS) {
      choiceOptions.push({
        value: ADD_NEW_OPTION_VALUE,
        label: (
          <FormattedMessage
            {...translations.AddAChoiceButtonLabel}
            values={{ maxNrOfAnswers: MAX_NR_OF_ANSWERS }}
          />
        ),
        originalData: {
          id: ADD_NEW_OPTION_VALUE,
          text: (
            <div onClick={handleAddChoice} style={{ color: "#a0a0a0" }}>
              <FormattedMessage
                {...translations.AddAChoiceButtonLabel}
                values={{ maxNrOfAnswers: MAX_NR_OF_ANSWERS }}
              />
            </div>
          ),
        },
      });
    }
    return choiceOptions;
  }, [choices]);

  const correctChoice = useMemo(
    () => choices.find((choice) => choice.isCorrect),
    [choices],
  );

  const handleChoicesChange = useCallback(
    (newChoices) => onChoicesChange(id, newChoices, choices),
    [onChoicesChange, id, choices],
  );

  const handleChoiceOptionSelect = useCallback(
    (_, selectedIndex) => {
      const newChoices = choices.map((choice, index) => ({
        ...choice,
        isCorrect: index === selectedIndex,
      }));
      setChoices(newChoices);
      handleChoicesChange(newChoices);
    },
    [handleChoicesChange, choices],
  );

  return (
    <div className={classes.container} onClick={() => onSelect(id)}>
      <AddNewContainer
        className={classes.innerPageContainer}
        enabled={isSelected}
        direction={DIRECTION_HORIZONTAL}
        beforeAfterBreakPoint={ADD_BUTTON_SWITCH_BREAKPOINT}
        onAddBefore={handleAddQuestionBefore}
        onAddAfter={handleAddQuestionAfter}
      >
        <ChangeTemplate
          selectedTemplateId={type}
          templates={BattleQuestionTemplates}
          onTemplateSelect={(newType) => onTypeChange(id, newType, type)}
          className={classes.changeTemplate}
        />
        <div
          className={classes.deleteButton}
          onClick={() => isSelected && onRemove(id)}
        >
          <Icon iconName={Icons.delete} />
        </div>
        <div className={classes.media}>
          <div className={classes.mediaOverlay} />
          <UploadImageButton
            id={`BattleQuestion-${id}-image`}
            containerClass={classes.uploadImageButton}
            disabled={!isSelected}
            onUploadSuccess={handleImageChange}
          />
          <CropImage
            sourceImage={image}
            isCropVisible={isCropVisible}
            cropSize={CROP_VARIATION.BATTLE}
            onCropClick={showCropper}
            onCancel={hideCropper}
            onCropCompleted={handleImageChange}
            className={classes.cropImageButton}
            disabled={!isSelected}
          />
        </div>
        <div className={classes.spacer} />
        <div className={classes.questionContainer}>
          <FormattedMessage {...translations.BattleEmptyQuestionTitle}>
            {(placeHolder) => (
              <ContentEditableUncontrolled
                key={title || "title"}
                initialValue={title}
                disabled={!isSelected}
                onChange={handleTitleChange}
                tagName="article"
                placeholder={placeHolder[0]}
                setContentOnChange
                className={classes.questionInput}
              />
            )}
          </FormattedMessage>
        </div>
        <div className={classes.descriptionContainer}>
          <FormattedMessage {...translations.BattleEmptyDescriptionTitle}>
            {(placeHolder) => (
              <ContentEditableUncontrolled
                key={description || "description"}
                initialValue={description}
                disabled={!isSelected}
                setContentOnChange
                onChange={handleDescriptionChange}
                tagName="article"
                placeholder={placeHolder[0]}
                className={classes.descriptionInput}
              />
            )}
          </FormattedMessage>
        </div>
        <div className={classes.spacer} />
        <div>
          <div className={classes.radioHeaderContainer}>
            <div
              className={classNames([
                classes.radioHeaderLabel,
                classes.correctLabel,
              ])}
            >
              <FormattedMessage {...translations.CorrectColumnLabel} />
            </div>
            <div
              className={classNames([
                classes.radioHeaderLabel,
                classes.choiceTextLabel,
              ])}
            >
              <FormattedMessage {...translations.ChoiceTextColumnLabel} />
            </div>
          </div>
          <div className={classes.radioContainer}>
            <RadioGroup
              options={choiceOptions}
              onChange={handleChoiceOptionSelect}
              value={correctChoice && correctChoice.id}
              renderOptionLabel={(
                option,
                index,
                _options,
                _value,
                onChange,
              ) => (
                <BattleQuestionChoiceEditor
                  option={option}
                  index={index}
                  key={option.originalData.id}
                  {...option.originalData}
                  isAddAChoice={option.originalData.id === ADD_NEW_OPTION_VALUE}
                  onTextChange={(text) => handleUpdateChoices(text, index)}
                  onRemove={() => handleRemoveChoice(index)}
                  onChangeSelectedAnswer={onChange}
                  onAddNewChoice={() =>
                    choices.length < MAX_NR_OF_ANSWERS && addChoiceAfter(index)
                  }
                  disabled={!isSelected}
                />
              )}
              disabled={!isSelected}
            />
          </div>
        </div>
      </AddNewContainer>
    </div>
  );
};

BattleQuestionEditor.propTypes = {
  ...BattleQuestionPropTypes,
  /** if selected, the question is editable: "template selection"- and "question add"- buttons, the inputs are enabled
   * and question is highlighted with a border **/
  isSelected: PropTypes.bool,
  /** func(questionId): function executed when a question is selected **/
  onSelect: PropTypes.func,
  /** func(questionId, newType, oldType): function executed when an option inside the type selector is clicked **/
  onTypeChange: PropTypes.func,
  /** func(questionId, newTitle, oldTitle): function executed when the title input is blurred **/
  onTitleChange: PropTypes.func,
  /** func(questionId, newDescription, oldDescription): function executed when the description input is blurred **/
  onDescriptionChange: PropTypes.func,
  /** func(questionId, newImage, oldImage): function executed when the image is changed **/
  onImageChange: PropTypes.func,
  /** func(questionId, newChoices, oldChoices): function executed when the choices are changed **/
  onChoicesChange: PropTypes.func,
  /** func(questionId): function executed when the "Add question before" button is clicked **/
  onAddQuestionBefore: PropTypes.func,
  /** func(questionId): function executed when the "Add question after" button is clicked **/
  onAddQuestionAfter: PropTypes.func,
  /** func(questionId): function executed when the "Remove question" button is clicked **/
  onRemove: PropTypes.func,
};

export default BattleQuestionEditor;
